import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox, Space } from 'antd';

import { useMount, useUpdateEffect, useSetState } from 'ahooks';
import ExclusiveCheckboxWithTextInput from './ExclusiveCheckboxWithTextInput';
import { riskItems, protectiveItems, harmOthersItems } from './meta';

const SuicidalIdeationFormItems = (props) => {
  const { form, draft, setDraft, } = props;

  const onItemChange = (e, name, exclusive, items = []) => {
    if (e.target.checked) {
      let fields = {}
      items.forEach(v => {
        if (exclusive) {
          fields[v.value] = v.exclusive ? true : false;
          if (v.hasInput) fields[`${v.value}_text`] = '';
        } else {
          if (v.value == name) fields[v.value] = true;
          if (v.exclusive) fields[v.value] = false;
        }
      })
      if (Object.keys(fields).length > 0) {
        form.setFieldsValue(fields);
        setDraft(Object.assign({ ...draft }, fields));
      }
    }
  }

  return (
    <>
      <h4>Risk:</h4>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px'}} >
        {riskItems.map((v, index) => {
          return (
            <div key={index} style={{ flex: '0 1 auto', padding: '2px 20px 2px 0px' }}>
              <ExclusiveCheckboxWithTextInput name={v.value} label={v.label} exclusive={v.exclusive} hasInput={v.hasInput}
              onChange={(e) => onItemChange(e, v.value, v.exclusive, riskItems)} form={form} draft={draft} setDraft={setDraft} />
            </div>
          )
        })}
      </div>

      <h4>Protective factors:</h4>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px'}} >
        {protectiveItems.map((v, index) => {
          return (
            <div key={index} style={{ flex: '0 1 auto', padding: '2px 20px 2px 0px' }}>
              <ExclusiveCheckboxWithTextInput name={v.value} label={v.label} exclusive={v.exclusive} hasInput={v.hasInput}
                onChange={(e) => onItemChange(e, v.value, v.exclusive, protectiveItems)} form={form} draft={draft} setDraft={setDraft} />
            </div>
          )
        })}
      </div>

      <h4>Thoughts of Harm to others:</h4>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px'}} >
        {harmOthersItems.map((v, index) => {
          return (
            <div key={index} style={{ flex: '0 1 auto', padding: '2px 20px 2px 0px' }}>
              <ExclusiveCheckboxWithTextInput name={v.value} label={v.label} exclusive={v.exclusive} hasInput={v.hasInput}
                onChange={(e) => onItemChange(e, v.value, v.exclusive, harmOthersItems)} form={form} draft={draft} setDraft={setDraft} />
            </div>
          )
        })}
      </div>
    </>
  )
};

export default SuicidalIdeationFormItems;
