import {
  srvPharmacyManagement,
} from '@/services/admin';

const Model = {
  namespace: 'pharmacy_management',
  state: {
    data: {
      items: [],
      total: 0,
    },
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const res = yield call(srvPharmacyManagement, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveList', payload: res.data });
    },
  },
  reducers: {
    saveList(state, { payload }) {
      return { ...state, data: payload };
    },
  },
};

export default Model;
