import React, { useState, useEffect } from 'react';
import { Col, Row, Tooltip, Table, Progress, Space, Modal,Form,Input,Button,Descriptions} from 'antd';

import CheckboxFilter from '@/components/Filters/CheckboxFilter';
import { srvDoctorSurveyRatings, srvDoctorSurveyPatientFeedbacks,srvAddSurveyDispute } from '@/services/patient';

import { QuestionCircleOutlined } from '@ant-design/icons';

import styles from './patient_review.less';
import cn from 'classnames/bind';
const cx = cn.bind(styles);

import { useRequest, useEventEmitter } from 'ahooks';

const PatientReview = (props) => {
  const { parentProps } = props;
  const [form] = Form.useForm();
  let { year, month } = parentProps.location.query || {};
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState({});

  const [total, setTotal] = useState({ rating: 0.0, reviews: 0 })
  const [ratings, setRatings] = useState([]);
  const [feedbacks, setFeedbacks] = useState({ items: [], total: 0, comment_total: 0 });
  const [showDisputeModal, setShowDisputeModal] = useState(false)
  const [showApprovedModal, setShowApprovedModal] = useState(false)
  const [showRejectedModal, setShowRejectedModal] = useState(false)
  const [selectedReview, setSelectedReview] = useState({})
  const reset$ = useEventEmitter();

  const fetchDoctorSurveyRatingsReq = useRequest(srvDoctorSurveyRatings, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret && ret.data && ret.data.items) {
        const o = {};
        ret.data.items.forEach(v => {
          o[`r${v.rating}`] = v;
        })
        const items = [5, 4, 3, 2, 1].map(i => {
          if (o[`r${i}`]) return Object.assign({}, o[`r${i}`], { reviews_total: ret.data.total });
          return { rating: i, survey_total: 0, rating_total: 0, reviews_total: ret.data.total };
        })
        setRatings(items);
        setTotal({ rating: Number(ret.data.rating).toFixed(1), reviews: ret.data.total })
      }
    },
  });

  const fetchDoctorSurveyPatientFeedbacksReq = useRequest(srvDoctorSurveyPatientFeedbacks, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret && ret.data && ret.data.items) {
        setFeedbacks({ items: ret.data.items, total: ret.data.total, comment_total: ret.data.comment_total });
      }
    },
  });

  const loadDoctorSurveyRatings = (filters, params) => {
    let filtersData = filters ? filters : {};
    let filtersObj = { ...filtersData };
    fetchDoctorSurveyPatientFeedbacksReq.run({ filters: JSON.stringify(filtersObj), ...params });
  };

  useEffect(() => {
    fetchDoctorSurveyRatingsReq.run({ year, month });
    loadDoctorSurveyRatings(null, { year, month })
  }, []);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    setFeedbacks({ items: [], total: 0, comment_total: 0 });
    loadDoctorSurveyRatings(q, { year, month });
  };

  const addSurveyDispute = useRequest(srvAddSurveyDispute, {
    manual: true,
    onSuccess: (ret, params) => {
      setShowDisputeModal(false)
      form.resetFields()
      fetchDoctorSurveyRatingsReq.run({ year, month });
    loadDoctorSurveyRatings(null, { year, month })
    },
  });

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  }

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const columns = [
    {
      title: 'Ratings',
      dataIndex: 'rating',
      key: 'rating',
    },
    {
      title: 'Comments',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      render: (v, r) => {
        return (
          <Space>
            {
              r.dispute_status===0&&
              <Button style={{marginRight: '10px'}} onClick={()=>{
                setSelectedReview(r)
                //form.setFieldsValue({ 'comment': '' })
                setShowDisputeModal(true)
              }}>Dispute</Button>
            }
            {
              r.dispute_status===1&&
              <Button style={{marginRight: '10px'}} disabled={true}>In-Dispute</Button>
            }
            {
              r.dispute_status===2&&
              <Button style={{marginRight: '10px'}} onClick={()=>{
                setSelectedReview(r)
                
                setShowApprovedModal(true)
              }}>Approved</Button>
            }
            {
              r.dispute_status===3&&
              <Button style={{marginRight: '10px'}}onClick={()=>{
                setSelectedReview(r)
                
                setShowRejectedModal(true)
              }}>Rejected</Button>
            }
            
          </Space>
        )
      },
    },
  ];


  const renderRatins = (ratings) => {
    return ratings.map((r) => {
      return (
        <div key={r.rating} className={cx({ rating: true })}>
          <span>{r.rating}</span>
          <Progress percent={Math.round(r.survey_total/r.reviews_total * 100)} showInfo={false} />
          <span>{r.survey_total}</span>
        </div>
      )
    })
  }

  return (
    <div className={cx({ patientReviewContainer: true })}>
      <h3 className={cx({ title: true })}>Patient Review</h3>

      <div className={cx({ ratings: true })}>
        <h4>Ratings</h4>
        <Row>
          <Col span={8}>
            <h1>{ Number(total.rating).toFixed(1) }<small>/5</small></h1>
            <p>Overall rating ({year && month ? `${months[month - 1]} ${year}` : `Last ${total.reviews} reviews`})</p>
          </Col>
          <Col span={12} offset={4}>
            {ratings && ratings.length > 0 && renderRatins(ratings)}
          </Col>
        </Row>
      </div>

      <div className={cx({ feedback: true })}>
        <div className={cx({ header: true })}>
          <h3>
            <Space>
              Patient Feedback
              <Tooltip title={'We only show feedback with patient comments, not all ratings are included.'}>
                <QuestionCircleOutlined style={{ color: "#5469D4", fontSize: '14px', lineHeight: '28px', marginBottom: '6px' }} />
              </Tooltip>
            </Space>
          </h3>
          <Space>
            <CheckboxFilter title={'Rating'} name={'rating'} reset$={reset$} filters={[5,4,3,2,1].map(v => { return { label: v, value: v }})} onFilter={onFilter} />
          </Space>
        </div>
        
        <Table
          rowKey={'id'}
          dataSource={feedbacks.items}
          columns={columns}
          onChange={handleTableChange}
          pagination={{ pageSize: 10, current: page, total: feedbacks.comment_total, showSizeChanger: false }}
        />
        <Modal title=""
        visible={showDisputeModal}
        onCancel={()=>{
          form.resetFields()
            setShowDisputeModal(false)
        }}
        footer={[
          <div key="buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button key="cancel"  onClick={() => {
            form.resetFields()
            setShowDisputeModal(false)}}>
            Cancel
          </Button>
          <Button key="submit" type="primary" htmlType="submit" style={{width:200}} onClick={() => {
            form.submit();
          }}>
            Submit
          </Button>
          </div>
        ]}
      >
        <Descriptions
              title="Dispute Review"
              layout="vertical"
              column={1}
              size="small"
              bordered={true}
            >
              <Descriptions.Item label="Patient Review">{selectedReview&&selectedReview.comment}</Descriptions.Item>
              
            </Descriptions>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          style={{ marginTop: 20 }}
          onFinish={()=>{
            addSurveyDispute.run({ survey_result_id: selectedReview.survey_result_id,provider_comment:form.getFieldValue('comment') });
          }}
        >
          <Form.Item
            name="comment"
            rules={[{ required: true, message: 'Please provide reasons'}]}
          >
            <Input.TextArea
              showCount
              placeholder="Please provide reasons here"
              rows={4}
              maxLength={2000}
              style={{ width: '100%' }}
              required
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal title="Your review dispute has been approved"
        visible={showApprovedModal}
        closable={true}
        onCancel={()=>{setShowApprovedModal(false)}}
        footer={[

          <Button key="OK" type="primary"  style={{width:200}} onClick={() => {
            setShowApprovedModal(false)
          }}>
            OK
          </Button>
        ]}
      >
        <p>This review has been removed from your metrics.</p>
        <br/>
        <p>Kindly keep an eye on your service quality and efficiency to prevent any negative reviews.</p>
      </Modal>
      <Modal title="Your review dispute was denied."
        visible={showRejectedModal}
        closable={true}
        onCancel={()=>{setShowRejectedModal(false)}}
        footer={[
          <Button key="OK" type="primary"  style={{width:200}} onClick={() => {
            setShowRejectedModal(false)
          }}>
            OK
          </Button>
        ]}
      >
        <p>This is a valid patient review. Please correct the issue with the patient.</p>
        <br/>
        <p>Kindly keep an eye on your service quality and efficiency to prevent any negative reviews.</p>
        <br/>
        <p> <b>Admin Note:</b> {selectedReview.admin_comment}</p>
      </Modal>
      </div>
    </div>
  )
};

export default PatientReview;
