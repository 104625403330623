import React, { useCallback, useRef } from 'react';
import { history, useSelector } from 'umi';
import { Button, Select, Spin, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useDebounceFn, useInfiniteScroll, useSetState } from 'ahooks';

import { srvPatients } from '@/services/patient';
import { srvEsPatients } from '@/services/admin';
import moment from 'moment';

export default function UserSearch() {
  const { currentUser } = useSelector((state) => state.account);

  const [state, setState] = useSetState({
    value: undefined,
    current: 1,
    pageSize: 10,
  });
  const isDoctor = currentUser.role === 'doctor';
  const placeholder = isDoctor ? 'Patient name' : 'Patient/doctor name, email or phone';

  const fetchData = useCallback(async () => {
    const { current, pageSize } = state;
    let params = isDoctor
      ? {
          query: state.value.trim(),
          scope: 'all',
        }
      : {
          filters: JSON.stringify({
            querys: state.value.trim(),
          }),
        };
    let service = currentUser.role === 'doctor' ? srvPatients : srvEsPatients;
    const res = await service({ ...params, page: current, page_size: pageSize });
    let list = [];
    if (Array.isArray(res.data?.items)) {
      list = renderOptions(res.data.items);
    } else if (Array.isArray(res.data)) {
      list = renderOptions(res.data);
    }
    return { list, total: res.data?.total || 0 };
  }, [state, currentUser]);

  const { data, loadingMore, loadMore, reload } = useInfiniteScroll(fetchData, {
    manual: true,
    isNoMore: (pageData) => {
      return pageData?.total > 0 && pageData.list.length >= pageData.total;
    },
    reloadDeps: [state.value],
  });

  const { run: handleSearch } = useDebounceFn(
    (newValue) => {
      setState({ value: newValue, current: 1 });
    },
    {
      wait: 300,
    },
  );

  function handlePopupScroll(e) {
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
      setState({ current: state.current + 1 });
      loadMore();
    }
  }

  const onSelect = (value, option) => {
    history.push({
      pathname:
        option.role === 'doctor' ? `/doctor/detail/${option.doctor_id}` : `/patient/${option.id}`,
    });
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      history.push({
        pathname: '/patient',
        query: {
          q: state.value,
        },
      });
    }
  };
  function renderOptions(data) {
    return data.map((datum) => {
      let display;
      if (datum.birthdate && datum.birthdate === -62135596800) {
        display = datum.name + ' ' + 'N/A';
      } else {
        display = datum.name + ' ' + moment.unix(datum.birthdate).utc().format('MM/DD/YYYY');
      }
      let doctorName;
      if (Array.isArray(datum.userDoctor)) {
        var activeDoctors = datum.userDoctor.filter((each) => each.status === 1);
        if (activeDoctors.length > 0) {
          doctorName = activeDoctors[0].doctor_name;
        }
      }
      let highLight = '';

      for (let key in datum.high_light) {
        let value = datum.high_light[key];
        if (value != null) {
          highLight += `${key}:${value} <br/>`
        }
      }

      let doctorTypeName = ['Provider', 'Collab', 'Student','Therapist','','Coach'][datum.doctor_type];
      return {
        key: datum.id,
        value: datum.id,
        id: datum.id,
        name: datum.name,
        role: datum.role,
        doctor_id: datum.doctor_id,
        label: (
          <div style={{ marginLeft: '5px', padding: '5px' }}>
            {datum.role == 'doctor' && <Tag color="#2db7f5">{doctorTypeName}</Tag>}
            {display}
            {doctorName && <Tag color="#87d068">{doctorName}</Tag>}
            {highLight && <div dangerouslySetInnerHTML={{ __html: highLight }} />}
          </div>
        ),
      };
    });
  }

  return (
    <div>
      <Select
        loading={loadingMore}
        showSearch
        style={{ minWidth: 320,marginLeft: '12px' }}
        placeholder={placeholder}
        allowClear
        showArrow={false}
        optionLabelProp="name"
        options={data?.list}
        onPopupScroll={handlePopupScroll}
        onSearch={handleSearch}
        onSelect={onSelect}
        filterOption={false}
        defaultActiveFirstOption={false}
        onInputKeyDown={onSearch}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Spin style={{ margin: 'auto' }} spinning={loadingMore} size="small" />
            </div>
          </div>
        )}
      />
      <Button type="primary" icon={<SearchOutlined />} />
    </div>
  );
}
