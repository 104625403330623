import { create } from 'zustand';
import { queryCurrent } from '@/services/account';

export const useUserStore = create((set) => ({
  profile: {},
  setProfile: async () => {
    const res = await queryCurrent();
    console.log('🚀 ~ setProfile: ~ res:', res);
    set({ profile: res.data });
  },
}));
