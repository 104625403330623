import React, { useEffect, useState } from 'react';
import {
  initialCompletedOpts
} from '@/components/AddNote/meta.js';
import './TransferForm.less';
import {unixToMomentWithTZ,formatUnix } from '../../utils/xtime';
import {history} from "umi";
import {
  srvUploadToken,
  srvGetPaitentDocument,
  srvUploadDocument,
  srvAddDraftTreatmentPlan,
  srvAddTreatmentPlan,
  srvPatientDraftTreatmentPlan,
  srvEditPatientTreatmentPlanHistory,
  srcDischargeTransferInitialNote,
} from '@/services/patient';
import { getNoteTemplatesForPatient } from '@/utils/utils';
import {
  Button,
  Space,
  Input,
  DatePicker,
  Divider,
  Checkbox, Radio,
  Select,
  Form,
  message,
  Modal,
} from 'antd';
import Dragger from "antd/es/upload/Dragger";
import request from "umi-request";
import { useRequest } from 'ahooks';
import CPTCodesFormItem from '@/components/CPTCodesFormItem';
import LastRefillDateFormItem from '@/components/LastRefiiDateFormItem';
const { TextArea } = Input;

const TransferForm = (props) => {
  const { transferForm, patient, patientID, setNoteVisible,noteType,onRefresh,appointment} = props;
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [otherReasonChecked, setOtherReasonChecked] = useState(false);
  const [diagnosisWriteInRequired, setDiagnosisWriteInRequired] = useState(false);
  const [otherReason, setOtherReason] = useState('');
  const [bridgeRefillDate, setBridgeRefillDate] = useState(null);
  const [initialDate, setInitialDate] = useState(null);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [draft, setDraft] = useState({});
  const [draftSubmit, setDraftSubmit] = useState('');
  const draftNoteID = React.useRef(0);
  const [isEditDraft, setIsEditDraft] = useState(false);
  const [diagnosticCode, setDiagnosticCode] = useState([]);
  const [bridgeDateVisible, setBridgeDateVisible] = useState(false);
  const tmpl = getNoteTemplatesForPatient({});
  const [showTransferConfirmation,setShowTransferConfirmation]=useState(false);
  const [showDischargeConfirmation,setShowDischargeConfirmation]=useState(false);

  useEffect(() => {
    var note_type = 4;
    if (noteType === 'Discharge') {
      note_type = 5;
      setRecommendationReasons([
        {
          label: 'Exclusionary or Complex Medical Condition/ Diagnosis',
          checked: false,
          key: "r1",
          options: [
            {
              key: '7',
              checked: false,
              label: 'Currently Pregnant or Breastfeeding'
            },
            {
              key: '1',
              checked: false,
              label: 'History of Heart Attack or Arrhythmias'
            },
            {
              key: '2',
              checked: false,
              label: 'History of Stroke or Seizure Disorder'
            },
            {
              key: '3',
              checked: false,
              label: 'Uncontrolled Hypertension'
            },
            {
              key: '4',
              checked: false,
              label: 'Multiple Co-morbidities and/ or Polypharmacy'
            },
            {
              key: '5',
              checked: false,
              label: 'Other Cardiac Disorder'
            },
            {
              key: '6',
              checked: false,
              label: 'Other Neurological Disorder'
            },
          ]
        },
        {
          label: 'Exclusionary or Complex Psychiatric Condition / Diagnosis',
          checked: false,
          key: 'r2',
          options: [
            {
              key: '1',
              checked: false,
              label: 'Bipolar/ Mood Disordes'
            },
            {
              key: '2',
              checked: false,
              label: 'History of Psychiatric Hospitalization'
            },
            {
              key: '3',
              checked: false,
              label: 'Severe Depression/ Suicidality'
            },
          ]
        },
        {
          label: 'No ADHD diagnosis',
          key: 'r3',
          checked: false,
          options: [
            {
              key: '1',
              checked: false,
              label: 'Patient does not meet DSM-V Criteria for relevant Psychiatric Diagnosis'
            },
          ]
        },
        {
          label: 'Non-compliance',
          key: 'r4',
          checked: false,
          options: [
            {
              key: '1',
              checked: false,
              label: 'Patient sought care from provider outside of done platform'
            },
            {
              key: '2',
              checked: false,
              label: 'patient not attending appointments or minimum follow-up as required'
            },
            {
              key: '3',
              checked: false,
              label: 'patient not taking medications as advised'
            },
          ]
        },
        {
          label: 'Use of Multiple Controlled Substances',
          key: 'r5',
          checked: false,
          options: [
            {
              key: '1',
              checked: false,
              label: 'PDMP or pt reports regular use of benzodiazepines'
            },
            {
              key: '2',
              checked: false,
              label: 'PDMP or pt reports regular use of controlled pain medication'
            },
            {
              key: '3',
              checked: false,
              label: 'PDMP or pt reports use of other stimulant medication (e.g. phentermine)'
            },
            {
              key: '4',
              checked: false,
              label: 'PDMP or pt reports use of Suboxone/Buprenorphine/Methadone'
            },
            {
              key: '5',
              checked: false,
              label: 'PDMP or pt reports use of OTHER controlled substances'
            },
            {
              key: '6',
              checked: false,
              label: 'pt reports uncontrolled use of substances(e.g.alcohol,club drugs,etc.)'
            },
          ]
        },
        {
          label: 'Unacceptable Actions/ Behaviors',
          key: 'r6',
          checked: false,
          options: [
            {
              key: '1',
              checked: false,
              label: 'Aggressive, demanding, or threatening'
            },
            {
              key: '2',
              checked: false,
              label: 'Dishonesty! faulty reporting'
            },
            {
              key: '3',
              checked: false,
              label: 'Discriminatory'
            },
            {
              key: '4',
              checked: false,
              label: 'Overstepping boundaries'
            },
          ]
        }
      ]);
      console.log('123',recommendationReasons)
    }
    const data = { patient_id: patientID, note_type: note_type };
    getDraftReq.run({ ...data });
  }, [])
  const validateReasons = (_, value) => {
    if (recommendationReasons.some(item => item.checked === true) || otherReasonChecked) {
      return Promise.resolve();
    }
    return Promise.reject('Reasons For Recommendation is required');
  };

  const getDraftReq = useRequest(srvPatientDraftTreatmentPlan, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.data) {
        setDraft(ret.data);
        draftNoteID.current = ret.data.id;
        setIsEditDraft(true);
        if (ret.data.bridge_refill_attestation === 1) {
          setBridgeDateVisible(true);
        }
        transferForm.setFieldsValue({
          bridge_refill_attestation: ret.data.bridge_refill_attestation,
          medication_history: ret.data.medication_history,
          treatment_summary: ret.data.treatment_summary,
        });
        if (ret.data.initial_evaluation_date !== 0) {
          transferForm.setFieldsValue({
            initial_evaluation_date: unixToMomentWithTZ(ret.data.initial_evaluation_date),
          });
          setInitialDate(ret.data.initial_evaluation_date);
        }
        if (ret.data.diagnostic_code !== '') {
          transferForm.setFieldsValue({
            diagnostic_code: ret.data.diagnostic_code.split(';')
          })
          setDiagnosticCode(ret.data.diagnostic_code.split(';'))
        }
        if (ret.data.diagnostic_code_notes !== '') {
          transferForm.setFieldsValue({
            diagnostic_code_notes: ret.data.diagnostic_code_notes,
          })
          setCurrentDiagnosesShow(true)
        }
        if (ret.data.medication_history_notes !== '') {
          transferForm.setFieldsValue({
            medication_history_notes: ret.data.medication_history_notes,
          })
          setMedicationHistoryShow(true)
        }
        if (ret.data.bridge_refill_attestation_date !== 0) {
          setBridgeRefillDate(ret.data.bridge_refill_attestation_date);
          transferForm.setFieldsValue({
            bridge_refill_attestation_date: unixToMomentWithTZ(ret.data.bridge_refill_attestation_date),
          });
        }
        if (ret.data.recommendation_reasons !== '') {
          const reasons = JSON.parse(ret.data.recommendation_reasons);
          setOtherReason(reasons.other_reason);
        }
        if (ret.data.attach_files !== '') {
          setUploadFiles(JSON.parse(ret.data.attach_files));
        }
        if (ret.data.recommendation_reasons !== '') {
          var reasons = JSON.parse(ret.data.recommendation_reasons);
          const updateRecommendationReasons = recommendationReasons.map((item) => {
            if (Object.keys(reasons).includes(item.key)) {
              var updateChildItem = item;
              var tempValue = reasons[item.key];
              updateChildItem.options = item.options.map((childItem) => {
                if (tempValue.includes(childItem.key)) {

                  return { ...childItem, checked: !childItem.checked };
                }
                return childItem;
              });
              return { ...updateChildItem, checked: !updateChildItem.checked };
            }
            return item;
          });
          setRecommendationReasons(updateRecommendationReasons);
          if (Object.keys(reasons).includes('other_reason')) {
            setOtherReason(reasons.other_reason)
            setOtherReasonChecked(true);
          }
        }
        if (ret.data.attach_files != '' && ret.data.attach_files != '[]') {
          setAttachFileShow(true);
          setUploadFiles(JSON.parse(ret.data.attach_files));
        }
      } else {
        getDischargeTransferInitailNodeReq.run({ id: patientID });
      }
    },
  });
  const getDischargeTransferInitailNodeReq = useRequest(srcDischargeTransferInitialNote, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.data) {
        transferForm.setFieldsValue({
          medication_history: ret.data.medication_history,
          treatment_summary: tmpl.provider_notes.replace('DATE_OF_SERVICE',appointment?formatUnix(appointment.appointed_at, 'YYYY-MM-DD'):'DATE_OF_SERVICE'),
        });
        if (ret.data.diagnostic_code !== '') {
          transferForm.setFieldsValue({
            diagnostic_code: ret.data.diagnostic_code.split(';')
          })
          setDiagnosticCode(ret.data.diagnostic_code.split(';'))
        }
        if (ret.data.initial_evaluation_date !== 0) {
          transferForm.setFieldsValue({
            initial_evaluation_date: unixToMomentWithTZ(ret.data.initial_evaluation_date),
          })
          setInitialDate(ret.data.initial_evaluation_date);
        }
      }
    }
  })


  const updateBridgeDate = (date, dateString) => {
    if (date !== null) {
      setBridgeRefillDate(date.unix())
    } else {
      setBridgeRefillDate(null);
    }
  }
  const updateBridge = (event) => {
    if (event.target.value === 1) {
      setBridgeDateVisible(true);
    } else {
      setBridgeDateVisible(false);
      setBridgeRefillDate(null)
      transferForm.setFieldsValue({
        bridge_refill_attestation_date: null,
      })
    }
  }
  const updateInitialDate = (date, dateString) => {
    if (date !== null) {
      setInitialDate(date.unix())
    } else {
      setInitialDate(null);
    }
  }
  const handleChange = (value) => {
    if (value.includes('Other (please specify below)')) {
      setDiagnosisWriteInRequired(true)
    } else {
      setDiagnosisWriteInRequired(false)
    }
    transferForm.setFieldsValue({
      diagnostic_code: value,
    })
    setDiagnosticCode(value);
  };
  const handleCheckboxChange = (key) => {
    const updateRecommendationReasons = recommendationReasons.map((item) => {
      if (item.key === key) {
        return { ...item, checked: !item.checked };
      }
      return item;
    });
    setRecommendationReasons(updateRecommendationReasons);
  };
  const handleChildCheckboxChange = (key, childKey) => {
    const updateRecommendationReasons = recommendationReasons.map((item) => {
      if (item.key === key) {
        var updateChildItem = item;
        updateChildItem.options = item.options.map((childItem) => {
          if (childItem.key === childKey) {
            return { ...childItem, checked: !childItem.checked };
          }
          return childItem;
        });
        return updateChildItem;
      }
      return item;
    });
    setRecommendationReasons(updateRecommendationReasons);
  }
  const handleOtherReasonChange = (event) => {
    setOtherReason(event.target.value);
  }
  const [recommendationReasons, setRecommendationReasons] = useState([
    {
      label: 'Exclusionary or Complex Medical Condition/ Diagnosis',
      checked: false,
      key: "r1",
      options: [
        {
          key: '7',
          checked: false,
          label: 'Currently Pregnant or Breastfeeding'
        },
        {
          key: '1',
          checked: false,
          label: 'History of Heart Attack or Arrhythmias'
        },
        {
          key: '2',
          checked: false,
          label: 'History of Stroke or Seizure Disorder'
        },
        {
          key: '3',
          checked: false,
          label: 'Uncontrolled Hypertension'
        },
        {
          key: '4',
          checked: false,
          label: 'Multiple Co-morbidities and/ or Polypharmacy'
        },
        {
          key: '5',
          checked: false,
          label: 'Other Cardiac Disorder'
        },
        {
          key: '6',
          checked: false,
          label: 'Other Neurological Disorder'
        },
      ]
    },
    {
      label: 'Exclusionary or Complex Psychiatric Condition / Diagnosis',
      checked: false,
      key: 'r2',
      options: [
        {
          key: '1',
          checked: false,
          label: 'Bipolar/ Mood Disordes'
        },
        {
          key: '2',
          checked: false,
          label: 'History of Psychiatric Hospitalization'
        },
        {
          key: '3',
          checked: false,
          label: 'Severe Depression/ Suicidality'
        },
      ]
    },
    {
      label: 'No ADHD diagnosis',
      key: 'r3',
      checked: false,
      options: [
        {
          key: '1',
          checked: false,
          label: 'Patient does not meet DSM-V Criteria for relevant Psychiatric Diagnosis'
        },
      ]
    },
    {
      label: 'Non-compliance',
      key: 'r4',
      checked: false,
      options: [
        {
          key: '1',
          checked: false,
          label: 'Patient sought care from provider outside of done platform'
        },
        {
          key: '2',
          checked: false,
          label: 'patient not attending appointments or minimum follow-up as required'
        },
        {
          key: '3',
          checked: false,
          label: 'patient not taking medications as advised'
        },
      ]
    },
    {
      label: 'Use of Multiple Controlled Substances',
      key: 'r5',
      checked: false,
      options: [
        {
          key: '1',
          checked: false,
          label: 'PDMP or pt reports regular use of benzodiazepines'
        },
        {
          key: '2',
          checked: false,
          label: 'PDMP or pt reports regular use of controlled pain medication'
        },
        {
          key: '3',
          checked: false,
          label: 'PDMP or pt reports use of other stimulant medication (e.g. phentermine)'
        },
        {
          key: '4',
          checked: false,
          label: 'PDMP or pt reports use of Suboxone/Buprenorphine/Methadone'
        },
        {
          key: '5',
          checked: false,
          label: 'PDMP or pt reports use of OTHER controlled substances'
        },
        {
          key: '6',
          checked: false,
          label: 'pt reports uncontrolled use of substances(e.g.alcohol,club drugs,etc.)'
        },
      ]
    },
    {
      label: 'Unacceptable Actions/ Behaviors',
      key: 'r6',
      checked: false,
      options: [
        {
          key: '1',
          checked: false,
          label: 'Aggressive, demanding, or threatening'
        },
        {
          key: '2',
          checked: false,
          label: 'Dishonesty! faulty reporting'
        },
        {
          key: '3',
          checked: false,
          label: 'Discriminatory'
        },
        {
          key: '4',
          checked: false,
          label: 'Overstepping boundaries'
        },
      ]
    },
    {
      label: 'Provider left the practice',
      key: 'r8',
      checked: false,
      options: []
    },
  ]);
  const [currentDiagnosesShow, setCurrentDiagnosesShow] = useState(false);
  const [medicationHistoryShow, setMedicationHistoryShow] = useState(false);
  const [attachFileShow, setAttachFileShow] = useState(false);

  const uploadFile = (uploadPara) => {
    let file = uploadPara.file;
    let onSuccess = uploadPara.onSuccess;
    let onError = uploadPara.onError;
    setUploadFileLoading(true);
    let objectKey = '';
    srvUploadToken({ type: 1 }).then((res) => {
      objectKey = res.data.object_key;
      file.arrayBuffer().then((content) => {
        request
          .put(res.data.upload_url, {
            data: content,
            headers: { 'Content-Type': file.type },
          })
          .then(() => {
            saveUpload(objectKey, file, onSuccess, onError);
          })
          .catch((err) => {
            onError(err);
            setUploadFileLoading(false);
          });
      });
    });
  };
  const removeFile = (file) => {
    const updateFileList = uploadFiles.filter(item => item.uid !== file.uid);
    setUploadFiles(updateFileList);
  };
  const saveUpload = (objectKey, file, onSuccess, onError) => {
    var temp = uploadFiles;
    var fileTemp = { 'object_key': objectKey, 'name': file.name, 'uid': file.uid }
    temp.push(fileTemp);
    setUploadFiles(temp);
    srvUploadDocument({
      user_id: patientID,
      file_name: file.name,
      file_type: file.type,
      object_key: objectKey,
    })
      .then((res) => {
        srvGetPaitentDocument({
          uid: patientID,
        }).then((res) => {
          if (res.code === 200) {

          }
        });
        setUploadFileLoading(false);
        onSuccess('ok');
      })
      .catch((err) => {
        onError({ err });
        setUploadFileLoading(false);
      });
  }
  const saveDraftNote = useRequest(srvAddDraftTreatmentPlan, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        message.success('save success')
        setNoteVisible(false);
        setDraft({});
      }
    },
  });
  const editDraftNote = useRequest(srvEditPatientTreatmentPlanHistory, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        message.success('edit success')
        setNoteVisible(false);
        setDraft({});
      }
    },
  });
  const submitDraftNote = useRequest(srvAddTreatmentPlan, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        message.success('submit success')
        setNoteVisible(false);
        setDraft({});
        onRefresh();
        if (noteType === 'Transfer') {
          history.push({
            pathname: "/patient/"
          })
        }
      }
    },
  });
  const onFinish = (data) => {
    if (data.cpt_code && data.cpt_code.length > 5) {
      data.cpt_code = data.cpt_code.slice(0, 5)
    }
    if(transferForm.getFieldValue('diagnostic_code')){
      data.diagnostic_code = transferForm.getFieldValue('diagnostic_code').toString();
    }
    var recommendationReasonsTemp = {}
    recommendationReasons.map((item, index) => {
      if (item.checked) {
        var child = []
        item.options.map((childItem) => {
          if (childItem.checked) {
            child.push(childItem.key);
          }
        })
        recommendationReasonsTemp[item.key] = child;
      }
    })
    if (otherReasonChecked) {
      recommendationReasonsTemp['r7'] = [];
      recommendationReasonsTemp['other_reason'] = otherReason;
    }

    data.recommendation_reasons = JSON.stringify(recommendationReasonsTemp);
    data.bridge_refill_attestation_date = bridgeRefillDate;
    data.initial_evaluation_date = initialDate;
    const resultFiles = JSON.stringify(uploadFiles);
    data.attach_files = resultFiles;
    data.bridge_refill_attestation = +data.bridge_refill_attestation;
    data.note_type = 4;
    data.recommendations=1;
    if (noteType === 'Discharge') {
      data.note_type = 5;
      data.recommendations=2;
    }
    data.user_id = patientID;
    if (draftSubmit === 'save') {
      data.note_status = 1;
      if (isEditDraft) {
        data.id = draftNoteID.current;
        editDraftNote.run(data);
      } else {
        saveDraftNote.run(data);
      }
    } else if (draftSubmit === 'submit') {
      data.note_status = 2;
      submitDraftNote.run(data);
    }
  }
  function saveDraft() {
    transferForm.validateFields().then(() => {
      setDraftSubmit('save');
      transferForm.submit();
    })
  };
  const submitNote = () => {
    transferForm.validateFields().then(() => {
      if (noteType === 'Discharge') {
        setShowDischargeConfirmation(true);
      }else{
        setShowTransferConfirmation(true);
      }
    })
  }

  const handleDischargeConfirmationClose=()=>{
    setShowDischargeConfirmation(false);
  }
  const handleTransferConfirmationClose=()=>{
    setShowTransferConfirmation(false);
  }

  const handleConfirmationOK=()=>{
    setDraftSubmit('submit');
    transferForm.submit();
    setShowTransferConfirmation(false);
    setShowDischargeConfirmation(false);
  }

  return (
    <>
          <Modal
        visible={showTransferConfirmation}
        maskClosable={false}
        footer={[
          <div key={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button key="cancel" onClick={handleTransferConfirmationClose}>
              Cancel
            </Button>
            <Button key="confirm" type="primary" onClick={handleConfirmationOK}>
              Confirm
            </Button>
          </div>,
        ]}
        title="Are you sure?"
        onCancel={() => {
          setShowTransferConfirmation(false);
        }}
      >
        <p>
        If you submit the transfer note, the patient will no longer be under your panel. The patient will be auto transferred to another provider immediately.
        </p>
      </Modal>
      <Modal
        visible={showDischargeConfirmation}
        maskClosable={false}
        footer={[
          <div key={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button key="cancel" onClick={handleDischargeConfirmationClose}>
              Cancel
            </Button>
            <Button key="confirm" type="primary" onClick={handleConfirmationOK}>
              Confirm
            </Button>
          </div>,
        ]}
        title="Are you sure?"
        onCancel={() => {
          setShowDischargeConfirmation(false);
        }}
      >
        <p>
          Once you submit the discharge note, the admin will evaluate it and decide whether to discharge or transfer the patient. After the admin approves, the patient will no longer be under your panel.
        </p>
      </Modal>
    <div id='transferForm' style={{ paddingLeft: '16px', paddingRight: '16px', fontSize: '14px', paddingTop: '16px', color: '#333333' }}>
      <Form layout="vertical" onFinish={onFinish} form={transferForm}>
        <Form.Item
          style={{ color: '#333333' }}
          name="recommendation_reasons"
          label="Reasons For Recommendation"
          valuePropName="checked"
          rules={[
            {
              required: true,
              validator: validateReasons,
            },
          ]}
        >
          <Space direction="vertical">
            {recommendationReasons.map((item, index) => (
              <Checkbox value={item.key} style={{ color: '#333333' }} key={item.key} checked={item.checked}
                onChange={
                  () => handleCheckboxChange(item.key)
                }>{item.label}
                {item.checked && (<Space direction="vertical">
                  {item.options.map((childItem) => (
                    <Checkbox checked={childItem.checked} key={childItem.key} onChange={
                      () => handleChildCheckboxChange(item.key, childItem.key)
                    } style={{ color: '#333333', paddingLeft: '3ch' }}>{childItem.label}</Checkbox>
                  ))}
                </Space>)}
              </Checkbox>
            ))}
            <Checkbox rules={[
              {
                validator: validateReasons,
              },
            ]} style={{ color: '#333333' }} onChange={(e) => setOtherReasonChecked(e.target.checked)} checked={otherReasonChecked}>
              Other reason
              {otherReasonChecked && (<Form.Item name={"other_reason"} rules={[{ required: true, message: 'Other reason is required' }]} noStyle><Input.TextArea rows={3} value={otherReason} onChange={handleOtherReasonChange} maxLength={1000} /></Form.Item>)}
            </Checkbox>
          </Space>
        </Form.Item>
        <div style={{ borderTop: '1px solid #d9d9d9', marginBottom: '10px' }} />
        <Form.Item
          style={{ color: '#333333' }}
          name="bridge_refill_attestation"
          label="Bridge Refill Attestation"
          rules={[
            {
              required: true,
              message: 'Bridge Refill Attestation is required',
            },
          ]}>

          <Radio.Group onChange={updateBridge}>
            <Radio style={{ color: '#333333', whiteSpace: 'pre-wrap', display: 'flex', alignItems: 'flex-start' }} value={1}>
            I attest that I have reviewed the patient’s PDMP and issued a bridge refill for any medications this patient requires. The refill was sent on:</Radio>
            {bridgeDateVisible && (<Form.Item name='bridge_refill_attestation_date'><DatePicker value={unixToMomentWithTZ(bridgeRefillDate)} onChange={updateBridgeDate} style={{ width: '250px', marginTop: '10px', marginBottom: '5px' }} /></Form.Item>)}
            <Radio style={{ color: '#333333' }} value={2}>The patient does not require a bridge refill.</Radio>
          </Radio.Group>

        </Form.Item>
        <div style={{ borderTop: '1px solid #d9d9d9', marginBottom: '10px' }} />
        <Form.Item
          style={{ color: '#333333' }}
          name="initial_evaluation_date"
          label="Date of Initial Evaluation">
          <DatePicker value={unixToMomentWithTZ(initialDate)} onChange={updateInitialDate} style={{ width: '250px', marginTop: '10px', marginBottom: '5px' }} />
        </Form.Item>
        <div style={{ borderTop: '1px solid #d9d9d9', marginBottom: '10px' }} />
        <Form.Item
          style={{ color: '#333333', marginBottom: '0' }}
          name="diagnostic_code"
          label="Current Diagnoses"
        >
          <Select
            mode={'multiple'}
            placeholder="Select Diagnostic Code"
            options={initialCompletedOpts}
            onChange={handleChange}
            value={diagnosticCode}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          />
          {!currentDiagnosesShow && (<h3 style={{ marginTop: '20px', color: '#5469d4', fontSize: '14px', fontWeight: '400', cursor: 'pointer', marginBottom: '5px' }} onClick={() => setCurrentDiagnosesShow(true)}>Add more notes</h3>)}
        </Form.Item>
        {currentDiagnosesShow && (<Form.Item
          style={{ color: '#333333' }}
          name="diagnostic_code_notes">
          <TextArea style={{ marginTop: '10px' }} rows={5} placeholder="" maxLength={1000} />
        </Form.Item>)}

        <CPTCodesFormItem form={transferForm} patient={patient} noteType={'Discharge/Transfer'} initialValues={draft} />

        <Form.Item
          style={{ color: '#333333', marginBottom: '0' }}
          name="medication_history"
          label="Medication History">
          <Form.Item name="medication_history" ><TextArea value={transferForm.getFieldValue('medication_history')} rows={5} maxLength={5000} /></Form.Item>
          {!medicationHistoryShow && (<h3 style={{ marginTop: '20px', color: '#5469d4', fontSize: '14px', fontWeight: '400', cursor: 'pointer' }} onClick={() => setMedicationHistoryShow(true)}>Add more notes</h3>)}
        </Form.Item>
        {medicationHistoryShow && (<Form.Item
          style={{ color: '#333333' }}
          name="medication_history_notes">
          <TextArea value={transferForm.getFieldValue('medication_history_notes')} style={{ marginTop: '10px' }} rows={5} maxLength={5000} />
        </Form.Item>)}

        <Form.Item
          style={{ color: '#333333' }}
          name="treatment_summary"
          label="Treatment Summary" >
          <TextArea defaultValue={transferForm.getFieldValue('treatment_summary')} rows={5} maxLength={5000} />
        </Form.Item>
        <div>
          <h5 style={{ fontWeight: '600', fontSize: '14px', marginBottom: '20px' }}>Attach Files</h5>
          {!attachFileShow && (<div onClick={() => setAttachFileShow(true)} style={{ fontSize: '14px', color: '#5469d4', fontWeight: '400', paddingBottom: 0, cursor: 'pointer' }}>Upload files</div>)}
          {attachFileShow && (<div>
            <Dragger fileList={uploadFiles} onRemove={removeFile} accept={["application/pdf", "image/png", "image/jpg"]} className="upload-panel" style={{ paddingLeft: '30px', paddingRight: '30px' }} customRequest={uploadFile}>
              <p className="ant-upload-text">
                {uploadFileLoading ? (
                  <span>Uploading...</span>
                ) : (<>Drag and drop your file here{' '}
                  <span className="upload-link">or upload from your computer</span></>)}
              </p>
            </Dragger>
          </div>)}
        </div>
        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
        <div style={{ display: 'flex', position: 'relative', alignItems: 'center', height: '55px' }}>
          <span onClick={() => saveDraft()} style={{ position: 'absolute', color: '#5469d4', left: '0px', top: '10px', fontSize: '14px', fontWeight: '400', cursor: 'pointer' }}>Save Draft</span>
          <Button onClick={submitNote}
            type="primary"
            htmlType="button"
            loading={submitDraftNote.loading}
            style={{ position: 'absolute', right: '0px', top: '10px', height: '30px' }}
          >
            {'Sign and Submit'}
          </Button>
        </div>
      </Form>
    </div>
    </>
  )
}
export default TransferForm;


