import React, { useState, useEffect } from 'react';
import { Form, Drawer, Input, Button, Select, Spin } from 'antd';
import { history, Link } from 'umi';

import moment from 'moment-timezone';
import debounce from 'lodash.debounce';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';

import {
  srvPatientTreatmentPlanHistoryDetail,
  srvEditPatientTreatmentPlanHistory,
  srvSearchAyvaDrug,
} from '@/services/patient';

import InitialEvaluationNotesForm from './InitialEvaluationNotesForm';
import MultiNotesForm from './MultiNotesForm';

import cn from 'classnames/bind';
import styles from './ProviderNotes.less';
const cx = cn.bind(styles);

const EditNote = (props) => {
  const { visible, onClose, itemID } = props;
  const [item, setItem] = useState({});
  const [loadings, setLoadings] = useState(false);
  const [form] = Form.useForm();

  const loadHistoryReq = useRequest(srvPatientTreatmentPlanHistoryDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        const data = ret.data;
        if (data.rx && data.rx.length) {
          data.rx = data.rx.split(';');
        } else {
          data.rx = [];
        }
        if (data.note_type === 1) {
          if (data.prev_medications && data.prev_medications.length) {
            data.prev_medications = data.prev_medications.split(',');
          } else {
            data.prev_medications = [];
          }
          if (data.current_medications && data.current_medications.length && data.current_medications !== 'No') {
            data.current_medications = data.current_medications.split(',');
          } else {
            data.current_medications = [];
          }
          if (data.review_of_adhd && data.review_of_adhd.length) {
            data.review_of_adhd = data.review_of_adhd.split(',').map(n => Number(n));
          }
        }
        setItem(data);
        setLoadings(false);
      }
    },
  });

  useMount(() => {
    setLoadings(true);
    loadHistoryReq.run({ id: itemID });
  });

  const renderNoteFormDom = (item) => {
    if (item.note_type === 1)
      return <InitialEvaluationNotesForm form={form} item={item} onClose={onClose} />
    return <MultiNotesForm form={form} item={item} onClose={onClose} />
  }

  return (
    <Drawer
      className={cx({ noteDrawer: true })}
      title="Edit Provider Note"
      width={1000}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      destroyOnClose={true}
    >
      <Spin tip="Loading..." spinning={loadings}>
        {item.id && renderNoteFormDom(item)}
      </Spin>
    </Drawer>
  );
};

export default EditNote;
