import React, { useState, useEffect, useMemo } from 'react';
import {
  Space,
  Input,
  Select,
  Row,
  Col,
  Button,
  Form,
  Checkbox,
  Divider,
  Tooltip,
  Radio,
  Modal,
  Typography,
  DatePicker,
  InputNumber
} from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { useFlag } from '@unleash/proxy-client-react';

import cn from 'classnames/bind';
import styles from './index.less';
import { formatUnix } from '@/utils/xtime.js';
import { PRE_INITIAL_APPOINTMENT_START_REVIEW, PRE_INITIAL_APPOINTMENT_END_REVIEW } from '@/constants'
const cx = cn.bind(styles);

import { useRequest, useLocalStorageState, useMount,useUnmount } from 'ahooks';

import {srvAddTreatmentPlan, srvAddDraftTreatmentPlan,srvMarkAppointmentToBeTransfer, srvMarkNoDiagnosis,srvEditPatientTreatmentPlanHistory,srvPatientDraftTreatmentPlan} from '@/services/patient';

import {
  getRealReason,
  noDiagnoseReason,
  otherReason,
  seenPatientOptions
} from '@/components/AddNote/meta.js';
import { getNoteTemplatesForPatient } from '@/utils/utils';
import NextFollowUpDateFormItem from '@/components/NextFollowUpDateFormItem';
import CPTCodesFormItem from '@/components/CPTCodesFormItem';
import LastRefillDateFormItem from '@/components/LastRefiiDateFormItem';
import moment from "moment-timezone";
const { TextArea } = Input;
import FamilyHistoryFormItems from './FamilyHistoryFormItems';
import MoodFormItems from './MoodFormItems';
import PsychiatricHistoryFormItems from './PsychiatricHistoryFormItems';
import SuicidalIdeationFormItems from './SuicidalIdeationFormItems';
import SocialHistoryFormItems from './SocialHistoryFormItems';
import MSEFormItems from './MSEFormItems';
import TimeSpentFormItems from './TimeSpentFormItems';
import SubstanceUseHistoryFormItems from './SubstanceUseHistoryFormItems';
import MedicalHistoryFormItems from './MedicalHistoryFormItems';
import RatingScalesReviewedFormItems from './RatingScalesReviewedFormItems';
import ChiefComplaintFormItems from './ChiefComplaintFormItems';
import DiagnosticCodeFormItem from './DiagnosticCodeFormItem';

let exitFormValues = {};
let exitInitialValues = {};
let exitSubmit = false;
const InitialEvaluationNotesForm = (props) => {
  const {form, patient, appointment, setNoteVisible, onRefresh } = props;

  const tmpl = getNoteTemplatesForPatient(patient).InitialEvaluationV2;

  const flagNextFollowUpDate = useFlag('enable_next_follow_up_date');
  let flagDraftClinicianNote = useFlag('draft_clinician_note');
  const [draft, setDraft] =  useState({});
  const [initialValues, setInitialValues] = useState({});
  const [isNoDiagnosed, setIsNoDiagnosed] = useState(false);
  const [showMoreReason, setShowMoreReason] = useState(false);
  const [isShowConfirmTransfer, setIsShowConfirmTransfer] = useState(false);
  const [isNotRightPatient, setIsNotRightPatient] = useState(false);
  const [isSeenPatient, setIsSeenPatient] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isDraft, setIsDraft] = useState(flagDraftClinicianNote ? true : false);
  const [autoSaveTimer, setAutoSaveTimer] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [formValues, setFormValues] = useState({});
  const draftNoteID = React.useRef(0);
  let patientAge = patient.birthdate && moment().diff(moment.unix(patient.birthdate), 'years')
  const flagLockClinicianNote = useFlag('lock_clinician_note');
  const [message,setMessage]=useState(tmpl.message_to_patient.replace(
    'DONE_FIRST_NAME',
    patient&&patient.first_name ? patient.first_name : patient&&patient.preferred_name?patient.preferred_name:''
    ).replace(
      'PROVIDER_NAME',
      patient?.provider_name ?patient.provider_name:''
    ).replace(
      'PHARMACY_INFORMATION',
      patient?.pharmacy?.name ? `${patient?.pharmacy?.name} ${patient?.pharmacy?.address_1} ${patient?.pharmacy?.address_2 || ''} ${patient?.pharmacy?.city} ${patient?.pharmacy?.state} ${patient?.pharmacy?.country || ''}` : 'None',
    ).replace(
      'RX_NAME',''
    ).replace('PROVIDER_NAME_TITLE',patient?.provider_name ? `${patient?.provider_name} ${patient?.provider_title}`: ''));

  useEffect(async () => {
    if (patient && patient.id) {
      console.log('useEffect.patient:', patient)
      console.log('useMount.flagDraftClinicianNote:', flagDraftClinicianNote, patient)
      if (flagDraftClinicianNote) {
        await getDraftReq.runAsync({ patient_id: patient.id, note_type: 1 });
      }
      if (!draft || !Object.keys(draft).length) {
        var lifeLineContent = `National Suicide Prevention Lifeline URL: https://suicidepreventionlifeline.org/?utm_source=google&utm_medium=web&utm_campaign=onebox
      National Suicide Prevention Lifeline Phone: 1-800-273-8255`;
        if (patient && patient.country === 'ca') {
          lifeLineContent = `Canada Crisis Hotline：
        phone: 1-833-456-4566
        URL: https://www.crisisservicescanada.ca/`
        }

        const data = {
          provider_notes: tmpl.provider_notes
            .replace('AGE', patient ? patientAge : '')
            .replace('GENDER', patient ? patient.gender : '')
            .replace('DATE_OF_SERVICE', appointment ? formatUnix(appointment.appointed_at, 'YYYY-MM-DD') : 'DATE_OF_SERVICE'),
        }
        setDefaultValues(data)
        form.setFieldsValue(data);
      }
    }
  }, [patient]);

  // useMount(() => {
  //   // const initial = form.getFieldsValue();
  //   // setInitialValues(initial);
  //   // exitInitialValues = initial;
  // }, []);

  useEffect(() => {
    if (draft && Object.keys(draft).length) {
      form.setFieldsValue(draft);
      const appointmentStartTime = draft?.StructNoteCommonData?.appointment_start_time
      const appointmentEndTime = draft?.StructNoteCommonData?.appointment_end_time
      console.log('useEffect.appointmentStartTime:', appointmentStartTime, draft)
      if (appointmentStartTime) {
        form.setFieldValue(['struct_note_data', 'appointment_start_time'], moment(appointmentStartTime));
        form.setFieldsValue({
          'spent_day_date': moment(appointmentStartTime)
        })
      }
      if (appointmentEndTime) {
        form.setFieldValue(
          ['struct_note_data', 'appointment_end_time'],
          moment(appointmentEndTime)
        );
      }
    }
  }, [draft]);

  const formatRatingScalesReviewed = (type) => {
    let text = '';
    if (type === 'asrsa' && patient?.assessment) {
      text = (patient?.assessment?.score ? `Score:${patient?.assessment?.score} ` : '') + (patient?.assessment?.conclusion ? `${patient?.assessment?.conclusion}` : '');
    } else if (type === 'asrsb' && patient?.intake_form) {
      text = (patient?.intake_form?.score_asrsb ? `Score:${patient?.intake_form?.score_asrsb} ` : '') + (patient?.intake_form?.asrsb ? `${patient?.intake_form?.asrsb}` : '');
    } else if (type === 'phq_9' && patient?.intake_form) {
      text = (patient?.intake_form?.score_depression ? `Score:${patient?.intake_form?.score_depression} ` : '') + (patient?.intake_form?.phq_9 ? `${patient?.intake_form?.phq_9}` : '');
    } else if (type === 'gad_7' && patient?.intake_form) {
      text = (patient?.intake_form?.score_anxiety ? `Score:${patient?.intake_form?.score_anxiety} ` : '') + (patient?.intake_form?.gad_7 ? `${patient?.intake_form?.gad_7}` : '');
    }
    return text;
  }

  const setDefaultValues = (data) => {
    if (appointment && appointment.appointed_at > 0) {
      const startTime = moment.unix(appointment.appointed_at)
      const endTime = moment.unix(appointment.appointed_at + (60 * 25))
      if (data.StructNoteCommonData) {
        data.StructNoteCommonData.appointment_start_time = startTime;
        data.StructNoteCommonData.appointment_end_time = endTime;
      } else {
        data.StructNoteCommonData = {
          appointment_start_time: startTime,
          appointment_end_time: endTime,
        }
      }
    }
    return Object.assign(data, {
      presents_psychiatric_intake_appointment: true,
      consent_telemedicine_treatment_consultation: true,
      informed_received_limitations_telemedicin: true,
      reviewed_therapeutic_medication_symptoms: true,
      agrees_health_monitoring_maintenance: true,
      agrees_coordinate_care: true,
      discussed_various_treatment_options: true,
      agrees_treatment_plan: true,
      chief_complaint_firstname: patient?.first_name || '',
      chief_complaint_age: patientAge || 0,
      chief_complaint_gender: ['male', 'female'].includes(patient?.gender) ? patient?.gender : 'other',
      asrsa: formatRatingScalesReviewed('asrsa'),
      asrsb: formatRatingScalesReviewed('asrsb'),
      phq_9: formatRatingScalesReviewed('phq_9'),
      gad_7: formatRatingScalesReviewed('gad_7'),

      mood_irritability: 1,
      mood_impulsivity: 1,
      mood_depression: 1,
      mood_hx_denies: true,
      mood_anxiety_level: 1,
      mood_sleep_quality: data.mood_sleep_quality || '',

      psychiatric_history_denies: true,
      risk_none: true,
      protective_none: true,
      harm_others_none: true,
      medical_denies: true,
      patient_weight: data.patient_weight || 1.0,
      patient_height: data.patient_height || 1.0,
      medical_weight_change_6m: false,
      social_denies: true,
      family_mental_health_denies: true,
      family_medical_history_denies: true,
      substance_use_current_mat: false,
      substance_use_disorder_history: false,
    })
  }


  const handleClose = (e) => {
    if (flagDraftClinicianNote) {
      if (!exitSubmit && exitFormValues && Object.keys(exitFormValues).length > 0 && !Object.is(exitInitialValues, exitFormValues)) {
        e.returnValue = 'Are you sure you want to leave?';
      }
    }
  };
  useEffect(() => {
    const handleBeforeUnload = e => {
      handleClose(e);
      e.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, []);

  const addNoteReq = useRequest(srvAddTreatmentPlan, {
    manual: true,
    onSuccess: (ret) => {
      setDraft({});
      onRefresh();
      setNoteVisible(false);
    },
  });

  const addDraftNoteReq = useRequest(srvAddDraftTreatmentPlan, {
    manual: true,
    onSuccess: (ret) => {
      const splitArray = ret.data.split(', ');
      draftNoteID.current = splitArray[1];
    },
  });

  const editHistoryReq = useRequest(srvEditPatientTreatmentPlanHistory, {
    manual: true,
    onSuccess: (ret) => {
      if (!isDraft){
        setDraft({});
        onRefresh();
        setNoteVisible(false);
      }
    },
  });

  const getDraftReq = useRequest(srvPatientDraftTreatmentPlan, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.data) {
        const data = ret.data;

        // no has draft note
        if (data.initial_note_id === '0') {
          setDefaultValues(data);
        }

        if (data.StructNoteCommonData) {
          if (data.StructNoteCommonData.appointment_start_time) {
            data.StructNoteCommonData.appointment_start_time = moment(data.StructNoteCommonData.appointment_start_time);
          }
          if (data.StructNoteCommonData.appointment_end_time) {
            data.StructNoteCommonData.appointment_end_time = moment(data.StructNoteCommonData.appointment_end_time);
          }
        }

        if (data.diagnostic_code !== '') {
          data.diagnostic_code = data.diagnostic_code.split(';').filter(t => t.trim().length);
          if (!data.diagnostic_code.length) delete data.diagnostic_code;
        }
        if (data.medical_last_seen_date) data.medical_last_seen_date = moment(data.medical_last_seen_date);
        if (data.spent_day_date) data.spent_day_date = moment(data.spent_day_date);

        if (data.patient_review_no_concerns) {
          data.patient_review_no_concerns = 0;
        } else {
          data.patient_review_no_concerns = 1;
        }
        if (data.drug_allergy === true) data.drug_allergy = 1;
        if (data.drug_allergy === false) data.drug_allergy = 0;

        if (data.message_to_patient){
          setMessage(data.message_to_patient);
        }
        delete data.next_follow_up_date;
        delete data.next_follow_up_month;
        delete data.last_refill_date
        setDraft(data);
        draftNoteID.current = ret.data.id;
      } else {
        setDraft(setDefaultValues({}));
      }
    },
  });

  const markNoDiagnosisReq = useRequest(srvMarkNoDiagnosis, {
    manual: true,
    onSuccess: () => {
      setNoteVisible(false);
      onRefresh();
    },
  });

  const setNotContinuePatient = (e) => {
    const checked = e.target.checked;
    setIsNotRightPatient(checked)
  };

  const onSeenPatientChanged = (e) => {
    const op = e.target.value;
    if (op === 'Y') {
      setIsNoDiagnosed(true);
      setIsSeenPatient(true)
    } else {
      setIsNoDiagnosed(false);
      setIsSeenPatient(false)
    }
  };

  const markAppointmentToBeTransfer = useRequest(srvMarkAppointmentToBeTransfer, {
    manual: true,
    onSuccess: () => {
      if (!flagDraftClinicianNote || !isDraft) {
        setDraft({});
        setNoteVisible(false);
        onRefresh();
      }
    },
  });

  const onReasonChanged = (e) => {
    const reason = e.target.value;
    if (reason === 'c') {
      setShowMoreReason(true);
    } else {
      setShowMoreReason(false);
    }
  };

  const addNote = (values) => {
    values.note_type = 1; // InitialEvaluation
    values.note_status = 2;
    values.user_id = appointment ? appointment.patient.id : patient.id;
    if (appointment) values.appointment_id = appointment.id;
    if (values.no_diagnose) {
      values.id = values.user_id;
      let reason = getRealReason(values);
      values.reason = reason + '\n' + values.addition_notes_for_no_diagnose;
      markNoDiagnosisReq.run({ ...values });
    } else {
      addNoteReq.run({ ...values });
    }
  };

  const addNoteWithDraft = (values) => {
    values.note_type = 1; // InitialEvaluation
    values.user_id = appointment ? appointment.patient.id : patient.id;
    if (appointment) values.appointment_id = appointment.id;
    if (isDraft){
      if (draftNoteID.current!=0){
        const data = { ...values, id: draftNoteID.current };
        editHistoryReq.run(data);
      }else{
        addDraftNoteReq.run({ ...values });
      }
    }else{
      if (values.no_diagnose) {
        values.id = values.user_id;
        let reason = getRealReason(values);
        values.reason = reason + '\n' + values.addition_notes_for_no_diagnose;
        markNoDiagnosisReq.run({ ...values, note_id: draftNoteID.current ? draftNoteID.current.toString() : '0' });
      } else {
        const data = { ...values, note_id: draftNoteID.current ? draftNoteID.current.toString() : '0' };
        addNoteReq.run({ ...data });
      }
    }

  };

  useUnmount(() => {
    if (flagDraftClinicianNote) {
      if (!submitted && formValues && Object.keys(formValues).length > 0 && !Object.is(initialValues, formValues)) {
        setDraft(formValues);
        form.validateFields()
          .then(() => {
            setIsDraft(true);
            onFinish(formValues);
          })
      }
      // Clean up the timer on component unmount
      clearInterval(autoSaveTimer);
    }
  });


  const onFinish = (data) => {
    if (data.cpt_code && data.cpt_code.length > 5) {
      data.cpt_code = data.cpt_code.slice(0, 5)
    }
    if (data.medicines) {
      data.medicines = JSON.parse(data.medicines);
      data.rx = data.medicines
        .map((v) => {
          return [
            v.drug.drug_name,
            v.drug.drug_strength,
            v.drug.drug_strength_unit,
            v.drug.drug_route,
            v.drug.dosage_desc,
          ]
            .filter((t) => t !== '')
            .join(' ');
        })
        .join(';');
    }
    data.struct_note_data.appointment_start_time = data.struct_note_data.appointment_start_time.toString()
    data.struct_note_data.appointment_end_time = data.struct_note_data.appointment_end_time.toString()
    data.message_to_patient=message;
    if (data.patient_review_no_concerns === 1) data.patient_review_no_concerns = false
    if (data.patient_review_no_concerns === 0) data.patient_review_no_concerns = true
    if (data.drug_allergy === 1) data.drug_allergy = true
    if (data.drug_allergy === 0) data.drug_allergy = false

    if (Object.prototype.toString.call(data.diagnostic_code) === '[object Array]') {
      data.diagnostic_code = data.diagnostic_code.sort().join(';');
    }
    if (data.medical_last_seen_date) data.medical_last_seen_date = moment(data.medical_last_seen_date).format('MM/DD/YYYY');
    if (data.spent_day_date)  data.spent_day_date = moment(data.spent_day_date).format('MM/DD/YYYY');

    data.patient_height = data.patient_height ? Number(data.patient_height) : 0;
    data.patient_weight = data.patient_weight ? Number(data.patient_weight) : 0;
    if (flagDraftClinicianNote && isDraft){
      data.note_status = 1 //Draft 1
    } else {
      data.note_status = 2 //Submitted 2,
    }
    if (flagDraftClinicianNote){
      addNoteWithDraft(data);
    }else{
      addNote(data);
    }

  };

  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    // console.log({ values, errorFields, outOfDate });
    // setLoadings(false);
  };

  const onFieldsChange = (changedValues, allValues) => {
    // console.log('onFieldsChange:', changedValues, allValues)
  };

  const onValuesChange = (changedValues, allValues) => {
    // console.log('onValuesChange.allValues', allValues, changedValues)
    setFormValues(allValues);
    setDraft(allValues);
    exitFormValues = allValues;
  };



  const handleConfirmationOK = () =>{
    if (flagDraftClinicianNote){
      clearInterval(autoSaveTimer);
      setIsDraft(false)
    }
    setSubmitted(true);
    exitSubmit = true;
    form.submit();
    setShowConfirmation(false)
  }

  const handleConfirmationClose = () =>{
    setShowConfirmation(false)
  }

  const updateRx=(e)=>{
      setMessage(tmpl.message_to_patient.replace(
        'DONE_FIRST_NAME',
        patient&&patient.first_name ? patient.first_name : patient&&patient.preferred_name?patient.preferred_name:''
        ).replace(
          'PROVIDER_NAME',
          patient?.provider_name ?patient.provider_name:''
        ).replace(
          'PHARMACY_INFORMATION',
          patient?.pharmacy?.name ? `${patient?.pharmacy?.name} ${patient?.pharmacy?.address_1} ${patient?.pharmacy?.address_2 || ''} ${patient?.pharmacy?.city} ${patient?.pharmacy?.state} ${patient?.pharmacy?.country || ''}` : 'None',
        ).replace(
          'RX_NAME',e.target.value
        ).replace('PROVIDER_NAME_TITLE',patient?.provider_name ? `${patient?.provider_name} ${patient?.provider_title}`: ''))
  }
  const updateMessage=(e)=>{
    setMessage(e.target.value);
  }

  return (
    <div className={cx({ container: true })}>
      <Form
        className={cx({ form: true })}
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        onFieldsChange={onFieldsChange}
        scrollToFirstError={true}
      >
        <Modal
          visible={showConfirmation}
          maskClosable={false}
          footer={[
            <div key="buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button key="cancel" onClick={handleConfirmationClose}>
                Cancel
              </Button>
              <Button key="confirm" type="primary" onClick={handleConfirmationOK}>
                Confirm
              </Button>
            </div>
          ]}
          title="Are you sure?"
          onCancel={() => {
            setShowConfirmation(false);
          }}
        >
          <p>
            Once you submit this note, you will not be able to delete or make further modifications.
            Please ensure that the patient’s PDMP record has been reviewed before submitting the note. This step is critical to maintaining compliance and ensuring safe prescribing practices.
          </p>
        </Modal>
        <div className={cx({ header: true })} style={{ padding: '20px 0 20px 0' }}>
          <Space direction="vertical">
            {
              PRE_INITIAL_APPOINTMENT_START_REVIEW.map((item, index) => {
                return (
                  <Form.Item name={item.value} valuePropName="checked" noStyle key={item.value}>
                    <Checkbox>{item.label}</Checkbox>
                  </Form.Item>
                )
              })
            }
          </Space>
        </div>

        <div className={cx({ header: true })}>
          <h3>Chief Complaints</h3>
          <ChiefComplaintFormItems patient patientAge form={form} draft={draft} setDraft={setDraft} />
        </div>
        <Divider />

        <div className={cx({ header: true })}>
          <h3>Rating Scales Reviewed</h3>
          <RatingScalesReviewedFormItems form={form} draft={draft} setDraft={setDraft} patient={patient} />
        </div>
        <Divider />

        <div className={cx({ header: true })}>
          <h3>Mood</h3>
          <MoodFormItems form={form} draft={draft} setDraft={setDraft} />
        </div>
        <Divider />

        <div className={cx({ header: true })}>
          <h3>Psychiatric History</h3>
          <PsychiatricHistoryFormItems form={form} draft={draft} setDraft={setDraft} />
        </div>
        <Divider />


        <div className={cx({ header: true })}>
          <h3>Suicidal Ideation</h3>
          <SuicidalIdeationFormItems form={form} draft={draft} setDraft={setDraft} />
        </div>
        <Divider />

        <div className={cx({ header: true })}>
          <h3>Medical History</h3>
          <MedicalHistoryFormItems form={form} draft={draft} setDraft={setDraft} />
        </div>
        <Divider />

        <div className={cx({ header: true })}>
          <h3>Social History</h3>
          <SocialHistoryFormItems form={form} draft={draft} setDraft={setDraft} />
        </div>
        <Divider />

        <div className={cx({ header: true })}>
          <h3>Family History</h3>
          <FamilyHistoryFormItems form={form} draft={draft} setDraft={setDraft} />
        </div>
        <Divider />

        <div className={cx({ header: true })}>
          <h3>Substance use history</h3>
          <SubstanceUseHistoryFormItems form={form} draft={draft} setDraft={setDraft} />
        </div>
        <Divider />

        <div className={cx({ header: true })}>
          <h3>PDMP/Cures review</h3>
          <Form.Item
            name="pdmp_cures_review"
          >
            <TextArea rows={3} maxLength={1000} />
          </Form.Item>
        </div>
        <Divider />

        <div className={cx({ header: true })}>
          <h3>MSE</h3>
          <MSEFormItems form={form} draft={draft} setDraft={setDraft} />
        </div>
        <Divider />

        <div className={cx({ header: true })}>
          <h3>Time Spent</h3>
          <TimeSpentFormItems form={form} draft={draft} setDraft={setDraft} />
        </div>
        <Divider />

        <div className={cx({ header: true })}>

          <DiagnosticCodeFormItem label={'Diagnosis'} form={form} draft={draft} setDraft={setDraft} />

          <CPTCodesFormItem form={form} patient={patient} noteType={'InitialEvaluation'} initialValues={draft} />

          <Divider />

          <div className={cx({ header: true })}>
            <h3>Treatment & Prescription</h3>

            <LastRefillDateFormItem form={form} patient={patient} noteType={'InitialEvaluation'} initialValues={draft} />

            <div className={cx({ rxTitle: true })}>
              <label htmlFor="rx" title="Rx" className={cx({ rxLabel: true })}>
                Rx
              </label>
            </div>
            <Form.Item
              name="rx"
              rules={[
                {
                  required: false,
                  message: 'Rx is required',
                },
              ]}
            >
              <Input placeholder="Rx" onChange={updateRx}  maxLength={1000}/>
            </Form.Item>

            <Form.Item name="no_controlled_substance_needed" valuePropName="checked">
              <Checkbox>
                <span>
                  &nbsp;Patient does not need controlled substances (in-person evaluation/referral
                  not required).
                </span>
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="provider_notes"
              label={
                <Space align="baseline">
                  <h4>Treatment Notes</h4>
                  <Tooltip
                    placement="top"
                    title="Edit the template below with your patient's details"
                    className={cx({ tip: true })}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Space>
              }
              rules={[
                {
                  required: true,
                  message: 'Treatment notes is required',
                },
              ]}
              initialValue={tmpl.provider_notes
                .replace('AGE', patient ? patientAge : '')
                .replace('GENDER', patient ? patient.gender : '')
                .replace('DATE_OF_SERVICE',appointment?formatUnix(appointment.appointed_at, 'MM DD YYYY'):'DATE_OF_SERVICE')}
            >
              <TextArea rows={8} maxLength={5000} />
            </Form.Item>

            <NextFollowUpDateFormItem form={form} patient={patient} />

            <div className={cx({ header: true })} style={{ padding: '20px 0 15px 0' }}>
              <Space direction="vertical">
                {
                  PRE_INITIAL_APPOINTMENT_END_REVIEW.map((item, index) => {
                    return (
                      <Form.Item name={item.value} valuePropName="checked" noStyle key={item.value}>
                        <Checkbox>{item.label}</Checkbox>
                      </Form.Item>
                    )
                  })
                }
              </Space>
            </div>

          </div>
          <Divider />

        <div>
          <Space align="baseline">
            <h3>First Message to Patient</h3>
            <Tooltip
              placement="top"
              title="Edit the template below with your patient's details"
              className={cx({ tip: true })}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>

          <Form.Item  label="Message To Patient" initialValue={tmpl.message_to_patient}>
            <TextArea rows={8} value={message} onChange={updateMessage} maxLength={20000}/>
          </Form.Item>
        </div>
          <Form.Item name="clinician_accuracy_testament" valuePropName="checked" label="Clinician Accuracy Testament" rules={[
            {
              required: true,
              message: 'Clinician Accuracy testament is required'
            },
            {
              validator: (_, value) => {
                if (value === true) {
                  return Promise.resolve();
                } else {
                  return Promise.reject()
                }
              }
            }
          ]}>
            <Checkbox>I attest to the accuracy and completeness of this note.</Checkbox>
          </Form.Item>
          <Divider />

          <div className="add-note-confirm">
            { appointment && (
              <Form.Item name="no_diagnose" valuePropName="checked">
                <Checkbox
                  onChange={(e) => {
                    setNotContinuePatient(e);
                  }}
                >
                  <span className="add-note-checkbox">
                    I don't want to continue to see this patient
                  </span>
                </Checkbox>
              </Form.Item>
            )}
            {!isNoDiagnosed && !isNotRightPatient && (
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    form.validateFields().then(()=>{
                      if (flagLockClinicianNote){
                        setShowConfirmation(true)
                      }else{
                        form.submit()
                      }
                    })
                  }}
                  loading={
                    markNoDiagnosisReq.loading ||
                    addNoteReq.loading ||
                    markAppointmentToBeTransfer.loading
                  }
                >
                  {'Sign and Submit'}
                </Button>
              </Form.Item>
            )}
          </div>
          {isNotRightPatient && (
            <>
              <Form.Item
                name="seen_patient_options"
                className={cx({ noDiagnoseReason: true })}
                rules={[
                  {
                    required: true,
                    message: 'the option is required',
                  },
                ]}
              >
                <Radio.Group onChange={onSeenPatientChanged} options={seenPatientOptions} />
              </Form.Item>
            </>
          )}

          {!isSeenPatient && (
            <Form.Item>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  setIsShowConfirmTransfer(true);
                }}
              >
                Sign and Submit
              </Button>
            </Form.Item>
          )}

          <Modal
            title="Confirm Transfer"
            visible={isShowConfirmTransfer}
            okText="Submit"
            onOk={() => {
              form.validateFields().then(()=>{
                if (flagLockClinicianNote){
                  setShowConfirmation(true)
                }else{
                  form.submit()
                }
              })
            }}
            onCancel={() => {
              setIsShowConfirmTransfer(false);
            }}
          >
            <p>You are transferring the patient. Please inform your PA of the transferring request.</p>
          </Modal>

          {isNoDiagnosed && (
            <>
              <h3>Why do you want to remove this patient from your panel?</h3>
              <Form.Item
                name="no_diagnose_reason"
                className={cx({ noDiagnoseReason: true })}
                rules={[
                  {
                    required: true,
                    message: 'the reason is required',
                  },
                ]}
              >
                <Radio.Group onChange={onReasonChanged} options={noDiagnoseReason} />
              </Form.Item>
              {showMoreReason && (
                <Form.Item name="no_diagnose_more_reason" initialValue={['d']}>
                  <Checkbox.Group
                    className={cx({ noDiagnoseMoreReason: true })}
                    options={otherReason}
                  />
                </Form.Item>
              )}
              <Form.Item
                style={{ marginTop: 16, backgroundColor: '#F4F4F4' }}
                name="addition_notes_for_no_diagnose"
              >
                <TextArea
                  style={{ backgroundColor: '#F4F4F4', borderRadius: 4 }}
                  rows={3}
                  placeholder="Any additional notes or comments about this patient?"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    form.validateFields().then(()=>{
                      if (flagLockClinicianNote){
                        setShowConfirmation(true)
                      }else{
                        form.submit()
                      }
                    })
                  }}
                  loading={markNoDiagnosisReq.loading}
                >
                  Sign and Submit
                </Button>
              </Form.Item>
            </>
          )}
        </div>
      </Form>
    </div>
  );
};

export default InitialEvaluationNotesForm;
