import React, { useState, useEffect, useRef } from 'react';
import { message, Row, Col, Popconfirm, Tag, Space, Drawer, Input, Button, Descriptions, Collapse, DatePicker, Select } from 'antd';
import cn from 'classnames/bind';
import moment from 'moment-timezone';

import { useRequest, useMount } from 'ahooks';

import { srvGetAdminDoctorLocations } from '@/services/admin';

import styles from './index.less';
const { Panel } = Collapse;
const cx = cn.bind(styles);

const RescheduleAppointment = (props) => {
  const { visible, appointment, rescheduleCalendar, loadingReschedule, activeDoctor } = props;
  const [appointedAt, setAppointedAt] = useState(0);
  const [outOfAvailability, setOutOfAvailbility] = useState(false);
  const inputEl = useRef(null);
  const [reason, setReason] = useState('');
  const [doctorLocations, setDoctorLocations] = useState([]);
  const [doctorLocationId, setDoctorLocationId] = useState(null);

  const getDoctorLocationsReq = useRequest(srvGetAdminDoctorLocations, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (ret.data) {
          setDoctorLocations(ret.data)
          var matched = false
          for(var i = 0; i < ret.data.length; i ++) {
            if (appointment.doctor_location_id == ret.data[i].id) {
              setDoctorLocationId(appointment.doctor_location_id)
              matched = true
            }
          }
          if (!matched && ret.data.length > 0) {
            setDoctorLocationId(ret.data[0].id)
          }
        }
      }
    },
  });

  useMount(() => {
    if ([4, 7, 8, 14].includes(appointment.appointment_type)) {
      getDoctorLocationsReq.run({
        doctor_id: appointment.doctor_id
      })
    }
  })

  const onClose = () => {
    setAppointedAt(0);
    props.onClose && props.onClose();
  };
  const onFinish = () => {
    // const reason = inputEl.current.state.value ? inputEl.current.state.value.trim() : '';
    if (!reason.length) {
      message.error('Reason is required');
      return;
    }

    props.rescheduleAppointment &&
      props.rescheduleAppointment(
        {
          id: appointment.id,
          reason,
          time: appointedAt,
          timezone: appointment.timezone,
          from_admin: 1,
          out_of_availability: outOfAvailability,
          doctor_location_id: doctorLocationId,
        },
        () => {
          setAppointedAt(0);
          onClose();
        },
      );
  };

  const timezone = appointment.timezone;

  const fetchDates = (dir) => {
    setAppointedAt(0);
    props.loadRescheduleCalendar &&
      props.loadRescheduleCalendar({
        appointment_id: appointment.id,
        doctor_location_id: appointment.doctor_location_id,
        timezone: appointment.timezone,
        days: 4,
        date: dir == 'next' ? rescheduleCalendar.end_date : rescheduleCalendar.start_date,
        dir: dir,
        from_admin: 1,
      });
  };

  const changeCustomDateTime = (date, dateString) => {
    setAppointedAt(moment.tz(dateString, timezone).unix())
    setOutOfAvailbility(true)
  }

  let docterInfo = <div>
                      {appointment.doctor && appointment.doctor.name}{' '}
                      {appointment.doctor && appointment.doctor.title}
                    </div>

  if (activeDoctor != undefined) {
    docterInfo = <div>
                    {activeDoctor && activeDoctor.name}{' '}
                    {activeDoctor && activeDoctor.title}
                  </div>
  }

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      destroyOnClose={true}
      className={cx({ rescheduleDrawer: true })}
      title={
        <div className={cx({ title: true })}>
          <h3>Reschedule Appointment</h3>
          {appointedAt > 0 && (
            <Button type="primary" loading={loadingReschedule} onClick={onFinish}>
              Confirm
            </Button>
          )}
        </div>
      }
      width="600"
      placement="right"
      closable={false}
    >
      <div className={cx({ description: true })}>
        <Descriptions title="" layout="vertical" size="small" column={2}>
          <Descriptions.Item label="Doctor">
            {docterInfo}
          </Descriptions.Item>
          <Descriptions.Item label="Timezone">{appointment.timezone}</Descriptions.Item>
        </Descriptions>
      </div>

      <div className={cx({ reason: true })}>
        <Input.TextArea
          placeholder="Reason to reschedule? (Internal use only. Patients won't see this.)"
          rows={2}
          // ref={inputEl}
          onChange={(e) => setReason(e.target.value.trim())} />
      </div>

      { doctorLocations.length > 0 &&
        <div className={cx({ reason: true })}>
          <Row style={{ marginBottom: '10px' }}>
            <Col span={6}>Location:</Col>
            <Col span={18}>
              <Select
                name="timezone"
                id="timezone"
                style={{ width: '100%' }}
                value={doctorLocationId}
                onChange={(v) => {
                  setDoctorLocationId(v)
                  fetchDates('next')
                }}
              >
                {doctorLocations.map((v, i) => {
                  return (
                    <Select.Option key={v.id} value={v.id}>
                      In-person - Location {(i + 1)} {v.address_line1} {v.address_line1} {v.city} {v.state} {v.zipcode}
                    </Select.Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </div>
      }

      <Collapse
        defaultActiveKey={['1']}
        ghost={true}
        bordered={false}
        expandIconPosition="right"
      >
        <Panel header="Date&Time" key="1">
          {rescheduleCalendar.start_date && (
            <div className={cx({ calendar: true })}>
              <div className={cx({ calendarOp: true })}>
                <Button
                  disabled={!rescheduleCalendar.has_prev}
                  onClick={() => {
                    fetchDates('prev');
                  }}
                >
                  Prev
                </Button>
                <Button
                  disabled={!rescheduleCalendar.has_next}
                  onClick={() => {
                    fetchDates('next');
                  }}
                >
                  Next
                </Button>
              </div>
              <div className={cx({ calendarDates: true })}>
                {rescheduleCalendar.dates.map((v, idx) => {
                  const timesDom =
                    v.times &&
                    v.times.map((x, i) => {
                      return (
                        <div className={cx({ timeSlot: true })} key={i}>
                          <Button
                            size="small"
                            onClick={() => {
                              setAppointedAt(x);
                              setOutOfAvailbility(false)
                            }}
                            type={appointedAt > 0 && appointedAt === x ? 'danger' : 'default'}
                          >
                            {moment
                              .unix(x)
                              .tz(timezone)
                              .format('hh:mm A')}
                          </Button>
                        </div>
                      );
                    });
                  return (
                    <div className={cx({ dateColumn: true })} key={idx}>
                      <div className={cx({ dateSlot: true })}>
                        {moment(v.date, 'YYYYMMDD')
                          .format('MMM D')}
                      </div>
                      <div className={cx({ dateSlot: true })}>
                        {moment(v.date, 'YYYYMMDD')
                          .format('ddd')}
                      </div>
                      {timesDom}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Panel>
        <Panel header="Other time" key="2">
          <Row>
            <Col span={12}>
              <label>Date Time</label>
              <div>
                <DatePicker showTime format="YYYY-MM-DD HH:mm" minuteStep={5} size="default" style={{ width: '80%' }} onChange={changeCustomDateTime}/>
              </div>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Drawer>
  );
};

export default RescheduleAppointment;
