import React, { useEffect, useState } from 'react';
import { Button, Tooltip, Space, List, Spin } from 'antd';

import { ProviderDashboardSection } from './index';

import { srvGetApprovedReferrals } from '@/services/patient';

import moment from 'moment';

const InitialPrescription = (props) => {
  const [referralsData, setReferralsData] = useState({});

  const refresh = (_page) => {
    srvGetApprovedReferrals({
      page: _page,
      page_size: 10
    }).then((res) => {
      if (res.data !== null) {
        setReferralsData(res.data)
      }
    });
  }

  const renderItem = (referral) => {
    return (
      <div className="detail-table-item">
        <div className="detail-patient-name">
          { referral.user_name }
        </div>
        <div className="detail-patient-status">
          { referral.state_name } {referral.synced_rxnt && "(Prescribing)"}
        </div>
        <div className="detail-requested-date">{moment.unix(referral.created_at).fromNow()}</div>
        <div className="detail-appointment-notes">

        </div>
        <div className="detail-action">
          <Button
            type="primary"
            onClick={() => {
              let link = `/api/ehr/rxnt/sso?return_url=${Buffer.from(window.location.href).toString('base64')}&patient_id=${referral.user_id}&referral_id=${referral.id}&order_type=1`;
              window.location.href = link;
            }}
          >
            Prescribe
          </Button>
        </div>
      </div>
    )
  }

  const changePage = (_page) => {
    refresh(_page)
  }

  useEffect(() => {
    refresh(1);
  }, []);

  return (
    <ProviderDashboardSection
      sectionName="Initial Prescription"
      itemNumber={referralsData.total}
    >
      <List
        itemLayout="vertical"
        style={{overflowX: 'auto',overflowY:'hidden'}}
        pagination={{ defaultCurrent: 1, total: referralsData.total, onChange: changePage }}
        dataSource={referralsData.items}
        header={
          <div className="detail-table-header">
            <div className="detail-patient-name">Patient</div>
            <div className="detail-patient-status">
              Status
            </div>
            <div className="detail-requested-date">
              Time
            </div>
            <div className="detail-appointment-notes">Admin Notes</div>
            <div className="detail-action">Actions</div>
          </div>
        }
        renderItem={renderItem}
      />
    </ProviderDashboardSection>
  )
};

export default InitialPrescription;