import React, { useState } from 'react';
import { List, Table, Space, message, Form, Row, Col, Button } from 'antd';
import { Link } from 'umi';
import { useRequest, useMount } from 'ahooks';

import { srvDoctorRxntAccounts, srvUpdateDoctorRxntAccount } from '@/services/account';
import RxntAccountEdit from '../doctor/RxntAccountEdit';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

const EHRRXNT = () => {

  const [item, setItem] = useState({});
  const [selectStates, setSelectStates] = useState([]);

  const [form] = Form.useForm();

  const fetchDoctorRxntAccountsReq = useRequest(srvDoctorRxntAccounts, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data) {
          if (ret.data.support_states && ret.data.support_states.length > 0) {
            ret.data.support_states = ret.data.support_states.split(',')
            const selectedStates = (ret.data.rxnt_accounts || []).filter(a => a && !a.is_del).map(a => a.state);
            const selectStates = (ret.data.support_states || []).filter(s => !selectedStates.includes(s));
            setSelectStates(selectStates);
          }
          setItem(ret.data);
          form.setFieldsValue(ret.data);
        }
      }
    },
  });

  const updateDoctorRxntAccountReq = useRequest(srvUpdateDoctorRxntAccount, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        loadData();
        message.success('successfully updated');
      } else {
        message.error(ret.msg);
      }
    },
  });

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.support_states || changedValues.rxnt_accounts) {
      const selectedStates = (allValues.rxnt_accounts || []).filter(a => a && !a.is_del).map(a => a.state);
      const selectStates = (item.support_states || []).filter(s => !selectedStates.includes(s));
      setSelectStates(selectStates);
    }
  }

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        values.id = item.id;
        updateDoctorRxntAccountReq.run(values)
      })
  }

  const loadData = () => {
    fetchDoctorRxntAccountsReq.run();
  };

  useMount(() => {
    loadData();
  });

  return (
    <div className={cx({ accounts: true })}>
      <h2>RXNT Accounts</h2>

      <Form
        form={form}
        onValuesChange={onValuesChange}
        preserve={false}
        layout="vertical"
      >
        <RxntAccountEdit form={form} selectStates={selectStates} onOk={onOk} />
      </Form>

    </div>
  )
}

export default EHRRXNT;
