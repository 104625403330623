import { stringify } from 'querystring';
import { history } from 'umi';
import { message } from 'antd';
import { setAuthority } from '@/utils/authority';
import { getPageQuery } from '@/utils/utils';
import {
  srvEmailLogin,
  srvCheckEmailExist,
  srvEmailVerifyCode,
  srvLogin,
  srvLogout,
  srvCheckPhoneExist,
  srvVerifyCode,
  srvPasswordLogin,
} from './service';

const Model = {
  namespace: 'login',
  state: {
    data: {},
  },
  effects: {
    *logout({ payload, callback }, { call, put }) {
      const res = yield call(srvLogout, payload);
      if (!res.success) {
        return;
      }

      history.push('/user/login');
    },
    *login({ payload, callback }, { call, put }) {
      const res = yield call(srvLogin, payload);
      if (!res.success) {
        return;
      }

      if (res.data && res.data.redirect_url) {
        window.location.href = res.data.redirect_url;
        return;
      }

      yield put({ type: 'changeLoginStatus', payload: res.data });

      const urlParams = new URL(window.location.href);
      const params = getPageQuery();
      let { redirect } = params;
      if (redirect) {
        const redirectUrlParams = new URL(redirect);
        if (redirectUrlParams.origin === urlParams.origin) {
          redirect = redirect.substr(urlParams.origin.length);
          if (redirect.match(/^\/.*#/)) {
            redirect = redirect.substr(redirect.indexOf('#') + 1);
          }
        } else {
          window.location.href = '/';
          return;
        }
      }
      history.replace(redirect || '/');

      if (callback) callback();
    },
    *loginByEmail({ payload, callback }, { call, put }) {
      const res = yield call(srvEmailLogin, payload);
      if (!res.success) {
        return;
      }

      if (res.data && res.data.redirect_url) {
        window.location.href = res.data.redirect_url;
        return;
      }

      yield put({ type: 'changeLoginStatus', payload: res.data });

      const urlParams = new URL(window.location.href);
      const params = getPageQuery();
      let { redirect } = params;
      if (redirect) {
        const redirectUrlParams = new URL(redirect);
        if (redirectUrlParams.origin === urlParams.origin) {
          redirect = redirect.substr(urlParams.origin.length);
          if (redirect.match(/^\/.*#/)) {
            redirect = redirect.substr(redirect.indexOf('#') + 1);
          }
        } else {
          window.location.href = '/';
          return;
        }
      }
      history.replace(redirect || '/');

      if (callback) callback();
    },

    *loginByPassword({ payload, callback }, { call, put }) {
      const res = yield call(srvPasswordLogin, payload);
      if (!res.success) {
        return;
      }

      if (res.data && res.data.redirect_url) {
        window.location.href = res.data.redirect_url;
        return;
      }

      yield put({ type: 'changeLoginStatus', payload: res.data });

      const urlParams = new URL(window.location.href);
      const params = getPageQuery();
      let { redirect } = params;
      if (redirect) {
        const redirectUrlParams = new URL(redirect);
        if (redirectUrlParams.origin === urlParams.origin) {
          redirect = redirect.substr(urlParams.origin.length);
          if (redirect.match(/^\/.*#/)) {
            redirect = redirect.substr(redirect.indexOf('#') + 1);
          }
        } else {
          window.location.href = '/';
          return;
        }
      }
      history.replace(redirect || '/');

      if (callback) callback();
    },
    *sendVerifyCode({ payload, callback }, { call, put }) {
      const resPhone = yield call(srvCheckPhoneExist, payload);
      if (!resPhone.success) {
        return;
      }

      if (!resPhone.data) {
        message.error('phone not exist.');
        return;
      }

      const res = yield call(srvVerifyCode, payload);
      if (!res.success) {
        return;
      }

      if (callback) callback();
    },

    *sendEmailVerifyCode({ payload, callback }, { call, put }) {
      const resEmail = yield call(srvCheckEmailExist, payload);
      if (!resEmail.success) {
        return;
      }

      if (!resEmail.data) {
        message.error('email not exist.');
        return;
      }

      const res = yield call(srvEmailVerifyCode, payload);
      if (!res.success) {
        return;
      }

      if (callback) callback();
    },
  },
  reducers: {
    changeLoginStatus(state, { payload }) {
      setAuthority(payload.role, payload.id);
      return { ...state, data: payload };
    },
  },
};
export default Model;