import React, { useState, useEffect, useRef } from 'react';
import { connect, history, Link } from 'umi';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

import AppointmentRequestPane from './appointment_request_pane';

import styles from './appointment_request.less';
import cn from 'classnames/bind';
const cx = cn.bind(styles);

const AppointmentRequest = (props) => {
  const { account, dispatch, loading } = props;
  const [activeKey, setActiveKey] = useState('0');
  const onTabChange = (v) => {
    setActiveKey(v);
  };

  useEffect(() => {
    if (account && account?.currentUser) {
      if (account?.currentUser.doctor_type == 3||account?.currentUser.doctor_type == 5) {
        setActiveKey('2');
      } else {
        setActiveKey('1');
      }
    }
  }, [account?.currentUser])

  const renderTab = (doctor) => {
    if (doctor.doctor_type == 3 || doctor.doctor_type ===5) {
      return (
        <>
          <TabPane tab="Initial Therapy Session" key="2">
            <AppointmentRequestPane parentProps={props} activeKey={activeKey} active={activeKey === '2'} />
          </TabPane>
          <TabPane tab="Therapy Session" key="3">
            <AppointmentRequestPane parentProps={props} activeKey={activeKey} active={activeKey === '3'} />
          </TabPane>
        </>
      )
    } else {
      return (
        <TabPane tab="Initial Rx Appointment" key="1">
          <AppointmentRequestPane parentProps={props} activeKey={activeKey} active={activeKey === '1'} />
        </TabPane>
      )
    }
  }

  return (
    <div>
      <Tabs defaultActiveKey="1" activeKey={activeKey} onTabClick={onTabChange}>
        {account && account.currentUser && renderTab(account.currentUser)}
      </Tabs>
    </div>
  );
};

export default connect(({ account, loading }) => ({
  account,
  loading,
}))(AppointmentRequest);
