import React, { useState, useEffect } from 'react';
import {Button, Tag, Descriptions, Space, Image, Row} from 'antd';
import { EditOutlined,ReconciliationOutlined } from '@ant-design/icons';
import { history,useAccess } from 'umi';
import cn from 'classnames/bind';
import { AsYouType } from 'libphonenumber-js';

import { DescriptionsSkeleton } from '@/components/ProSkeleton';
import { formatUnix } from '../../utils/xtime';
import styles from './index.less';
import moment from 'moment';
import DoctorChangeLogsDrawer from "@/pages/doctor/DoctorChangeLogsDrawer";

const cx = cn.bind(styles);

const DoctorInfo = (props) => {
  moment.tz.setDefault('America/Los_Angeles')
  const access=useAccess();
  const { id, doctorInfo, loading } = props;

  const [supervisingDocs, setSupervisingDocs] = useState([]);
  const [supervisedDocs, setSupervisedDocs] = useState([])
  const [messagesVisible, setMessagesVisible] = useState(false);
  useEffect(() => {
    if(doctorInfo.supervised_doctors) {
      setSupervisedDocs(combineCollabs(doctorInfo.supervised_doctors));
    }
    if(doctorInfo.supervising_doctors) {
      setSupervisingDocs(combineCollabs(doctorInfo.supervising_doctors));
    }
  }, [doctorInfo]);

  const combineCollabs = (doctors) => {
    const collabs = [];

    doctors.forEach((doctor) => {
      const isCollab = !!doctor.collab_doctor_id;
      const id = isCollab ? doctor.collab_doctor_id : doctor.doctor_id;
      const collab = collabs.find((c) => {
        return c.id == id;
      })
      if (collab == undefined) {
        collabs.push({id: id, name: doctor.name, title: doctor.title, states: [doctor.state]});
      } else {
        collab.states.push(doctor.state);
      }
    });
    return collabs;
  }

  const admin = props.admin ? true : false;

  const doctorTypeName = (type) => {
    if (type == 0) {
      return 'Provider';
    } else if (type == 1) {
      return 'Collab';
    } else if (type == 2) {
      return 'Student';
    } else if (type == 3) {
      return 'Therapist';
    } else if (type == 5) {
      return 'Coach';
    }
  };

  const supervisedList = (collabs) => {
    return collabs.map((collab, i) => {
      return (
        <p key={i} style={{whiteSpace: 'pre'}}>
          <a href={`/doctor/detail/${collab.id}`}><u>{`${collab.name}, ${collab.title}`}</u></a>{`   ${collab.states.join(', ')}`}
        </p>
      );
    })
  }

  const doctorTagName = (tags) => {
    return tags?.map((tag,i)=>{
      return (
        <Tag  key={i}>{tag}</Tag>
      )
    })
  };

  const supportCollabList = (collabs) => {
    return collabs.map((collab, i) => {
      return (
        <Descriptions.Item key={i} style={{whiteSpace: 'pre'}}>
          <Space direction="vertical">
            <h4>{`Support State${collab.states.length > 1 ? 's' : ''} & Collab ${i+1}`}</h4>
            <p>
              {`${collab.states.join(', ')},   `}<a href={`/doctor/detail/${collab.id}`}><u>{`${collab.name}, ${collab.title}`}</u></a>
            </p>
          </Space>
        </Descriptions.Item>
      );
    })
  }

  const renderRxntAccountsDom = (rxnt_accounts) => {
    return rxnt_accounts.map((account) => (
      <Descriptions.Item key={account.id} style={{
        paddingBottom: '2px',
      }}>
        <span style={{ width: 100, }}> {account.state}</span>
        <span>{account.rxnt_user_name} </span>
      </Descriptions.Item>
    ));
  }

  const renderProviderCompensationDom = (incomes) => {
    return incomes.map((income) => (
      <Descriptions.Item key={income.id} style={{
        paddingBottom: '2px',
      }}>
        <span style={{ width: 100, }}> {income.date}</span>
        <span style={{ width: 100, }}>${income.hourly_rate.toFixed(2)} </span>
        <span style={{ width: 100, }}>${income.collab_fee.toFixed(2)} </span>
        <span style={{ width: 180, }}>${income.license_reimbursement.toFixed(2)} </span>
        <span style={{ width: 100, }}>${income.referral_bonus.toFixed(2)} </span>
        <span style={{ width: 100, }}>${income.transfer_fee.toFixed(2)} </span>
        <span style={{ width: 100, }}>${income.other_bonus.toFixed(2)} </span>
      </Descriptions.Item>
    ));
  }

  function goEditDoctor() {
    history.push({
      pathname: `/doctor/edit/${id}`,
    });
  }




  if (loading) {
    return <DescriptionsSkeleton active={loading} />;
  }


  return (
    <div className={cx({ subcontainer: true, admin: admin })}>
      <Descriptions
        title={
          <div>
            <span>Clinician Info</span>
            {!access.adminAccesser && (<>
                <Button type="link" icon={<EditOutlined />} onClick={goEditDoctor} />
                <Button type="link" icon={<ReconciliationOutlined />} onClick={() => {
                  setMessagesVisible(true);
                }} />
              </>)}
          </div>
        }
        layout="vertical">
        <Descriptions.Item label="Type">{doctorTypeName(doctorInfo.doctor_type)}</Descriptions.Item>
        <Descriptions.Item label="Name">{doctorInfo.name}</Descriptions.Item>
        <Descriptions.Item label="Gender">{doctorInfo.gender || 'unknown'}</Descriptions.Item>
        <Descriptions.Item label="Title">{doctorInfo.title}</Descriptions.Item>
        <Descriptions.Item label="Email">{doctorInfo.email}</Descriptions.Item>
        <Descriptions.Item label="Phone">
          {doctorInfo.phone && new AsYouType('US').input(doctorInfo.phone)}
        </Descriptions.Item>
        <Descriptions.Item label="Time Zone">{doctorInfo.time_zone}</Descriptions.Item>
        <Descriptions.Item label="NPI">{doctorInfo.npi}</Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag>{doctorInfo.status_name}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Active Patients Count">
          <Tag>{doctorInfo.patients_count}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Description">{doctorInfo.description}</Descriptions.Item>
        {doctorInfo.doctor_type === 5 && (
          <Descriptions.Item label="Support States">All States</Descriptions.Item>
        )}
        {doctorInfo.doctor_type != 5 && (
          <Descriptions.Item label="Support States">{doctorInfo.support_states}</Descriptions.Item>
        )}
        <Descriptions.Item label="Doctor Tags">{doctorTagName(doctorInfo.doctor_tag_names)}</Descriptions.Item>
        {doctorInfo.doctor_type === 5 && (
          <Descriptions.Item label="Provider Bio">{doctorInfo.provider_bio}</Descriptions.Item>
        )}
        {doctorInfo.doctor_type != 5 && (
          <Descriptions.Item label="Medical License/Furnishing Number">{doctorInfo?.medical_licenses?.join(', ')}</Descriptions.Item>
        )}
        <Descriptions.Item label="Pediatric Supported">
          <Tag>{doctorInfo.support_pediatric ? 'Yes' : 'No'}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Insomnia Supported">
          <Tag>{doctorInfo.support_insomnia ? 'Yes' : 'No'}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Accepted Transfer Patients">
          <Tag>{doctorInfo.accept_transfer_patients ? 'Yes' : 'No'}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Accept New Patients">
          <Tag>{doctorInfo.accept_new_patients ? 'Yes' : 'No'}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Agree to Provide Coverage for Other Providers and Accepts Coverage from Them">
          <Tag>{doctorInfo.accept_co_services ? 'Yes' : 'No'}</Tag>
        </Descriptions.Item>
        {doctorInfo.job_type != 2 && (
          <Descriptions.Item label="Supports withdrawals via EHR">
            <Tag>{doctorInfo.enable_payout_flag ? 'Yes' : 'No'}</Tag>
          </Descriptions.Item>
        )}
        {[0, 1].includes(doctorInfo.doctor_type) && (
          <Descriptions.Item label="Chart Audit">
            <Tag>{doctorInfo.enable_chart_audit ? 'Yes' : 'No'}</Tag>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Urgent To-Do List Link">{doctorInfo.task_url}</Descriptions.Item>
        <Descriptions.Item label="Created">
          {formatUnix(doctorInfo.created_at, 'ddd, MMMM D, h:mm A')}
        </Descriptions.Item>
        <Descriptions.Item label="Start Date">
          {doctorInfo.start_date != 0 && formatUnix(doctorInfo.start_date, 'MM/DD/YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label="Last Day">
          {doctorInfo.end_date != 0 && formatUnix(doctorInfo.end_date, 'MM/DD/YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label="Slack Support Channel ID">{doctorInfo.slack_support_channel_id}</Descriptions.Item>
        <Descriptions.Item label="PA">{doctorInfo.pa}</Descriptions.Item>
        <Descriptions.Item label="PRM">{doctorInfo.prm}</Descriptions.Item>
        <Descriptions.Item label="Support In Person">
        <Tag>{doctorInfo.support_in_person ? 'Yes' : 'No'}</Tag>
        </Descriptions.Item>
        {
          (doctorInfo.doctor_type == 3 || doctorInfo.doctor_type == 5) && <>
            <Descriptions.Item label="Professional Background">{doctorInfo.professional_background}</Descriptions.Item>
            <Descriptions.Item label="Expertise">{doctorInfo.expertise}</Descriptions.Item>
            <Descriptions.Item label="Provider Bio">{doctorInfo.provider_bio}</Descriptions.Item>
          </>
        }
        <Descriptions.Item label="Stripe Account">
          <Tag color='lime'>{doctorInfo.stripe_account}</Tag>
        </Descriptions.Item>
      </Descriptions>

      { doctorInfo.doctor_type === '0' && supervisingDocs && supervisingDocs.length > 0 &&
        <Descriptions title="Support States with Collabs" layout="vertical" style={{paddingBottom: "20px"}}>
          { supportCollabList(supervisingDocs) }
        </Descriptions>
      }

      { doctorInfo.doctor_type === '1' && supervisedDocs && supervisedDocs.length > 0 &&
        <Descriptions title="Clinicians Supervised" layout="vertical" style={{paddingBottom: "20px"}}>
          <Descriptions.Item style={{whiteSpace: 'pre'}}>
            <Space direction="vertical">
              { supervisedList(supervisedDocs) }
            </Space>
          </Descriptions.Item>
        </Descriptions>
      }

      <Descriptions title="Acuity" layout="vertical">
        <Descriptions.Item label="Calendar ID">{doctorInfo.external_calendar_id}</Descriptions.Item>
        <Descriptions.Item label="Calendar Link">
          {doctorInfo.external_calendar_link}
        </Descriptions.Item>
        <Descriptions.Item label="Appointment(25min) Type ID">
          {doctorInfo.external_appointment_type_id}
        </Descriptions.Item>
        <Descriptions.Item label="Appointment(50min) Type ID">
          {doctorInfo.external_appointment_50min_type_id}
        </Descriptions.Item>
        <Descriptions.Item label="FollowUp Appointment Type ID">
          {doctorInfo.external_follow_up_appointment_type_id}
        </Descriptions.Item>
        <Descriptions.Item label="Paid FollowUp(25min) Type ID">
          {doctorInfo.external_paid_follow_up_type_id}
        </Descriptions.Item>
        <Descriptions.Item label="Pediatric Consultation(50min) Type ID">
          {doctorInfo.external_pediatric_consultation_type_id}
        </Descriptions.Item>
        <Descriptions.Item label="Pediatric Follow-up(25min) Type ID">
          {doctorInfo.external_pediatric_follow_up_type_id}
        </Descriptions.Item>
        <Descriptions.Item label="Insomnia Consultation(40min) Type ID">
          {doctorInfo.external_insomnia_consultation_type_id}
        </Descriptions.Item>
        <Descriptions.Item label="Insomnia Follow-up(15min) Type ID">
          {doctorInfo.external_insomnia_follow_up_type_id}
        </Descriptions.Item>
        <Descriptions.Item label="External In-Person Appointment(25min) Type ID">
          {doctorInfo.in_person_appointment_type_id}
        </Descriptions.Item>
        <Descriptions.Item label="External In-Person Appointment(50min) Type ID">
          {doctorInfo.in_person_appointment_50min_type_id}
        </Descriptions.Item>
        <Descriptions.Item label="External Follow Up In-Person Appointment Type ID">
          {doctorInfo.in_person_follow_up_appointment_type_id}
        </Descriptions.Item>
        <Descriptions.Item label="External Therapy Session Type ID">
          {doctorInfo.external_therapy_session_45_mins_type_id}
        </Descriptions.Item>
        <Descriptions.Item label="Scheduling URL">
          {doctorInfo.scheduling_url}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Zoom" layout="vertical">
        <Descriptions.Item label="Zoom Personal Meeting ID">
          {doctorInfo.zoom_personal_meeting_id}
        </Descriptions.Item>
        <Descriptions.Item label="Online Consultation URL">
          {doctorInfo.online_consultation_url}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Doctor Home Location" layout="vertical" style={{paddingBottom: "20px"}}>
        <Descriptions.Item label="State">{doctorInfo.state}</Descriptions.Item>
        <Descriptions.Item label="City">{doctorInfo.city}</Descriptions.Item>
        <Descriptions.Item label="Zipcode">{doctorInfo.zipcode}</Descriptions.Item>
      </Descriptions>

        { doctorInfo.doctor_locations && doctorInfo.doctor_locations.map((location, index)=>{
          return <>
            <Descriptions title={"In Person - Location " + (index + 1)} layout="vertical">
              <Descriptions.Item label="External In-Person Appointment(25min) Type ID">{location.in_person_appointment_type_id}</Descriptions.Item>
              <Descriptions.Item label="External In-Person Appointment(50min) Type ID">{location.in_person_appointment_50min_type_id}</Descriptions.Item>
              <Descriptions.Item label="External Follow Up In-Person Appointment Type ID">{location.in_person_follow_up_appointment_type_id}</Descriptions.Item>
              <Descriptions.Item label="Address">
                {location.address_line1 + ' ' + location.address_line2}
              </Descriptions.Item>
              <Descriptions.Item label="City">{location.city}</Descriptions.Item>
              <Descriptions.Item label="State">{location.state}</Descriptions.Item>
              <Descriptions.Item label="Zipcode">{location.zipcode}</Descriptions.Item>
              <Descriptions.Item label="Latitude">{!!location.lat && location.lat}</Descriptions.Item>
              <Descriptions.Item label="Longitude">
                {!!location.lng && location.lng}
              </Descriptions.Item>
              <Descriptions.Item label="Navigation Instructions">
                {location.navigation_instruction}
              </Descriptions.Item>
              <Descriptions.Item label="When you arrive Instructions">
                {location.arrival_instructions}
              </Descriptions.Item>
              <Descriptions.Item label="Check-In Instructions">
                {location.check_in_instructions}
              </Descriptions.Item>
              <Descriptions.Item label="Office location images">
                <Image src={location.location_image1} alt={"office image location 1"}/>
                <Image src={location.location_image2} alt={"office image location 2"}/>
                <Image src={location.location_image3} alt={"office image location 3"}/>
                <Image src={location.location_image4} alt={"office image location 4"}/>
              </Descriptions.Item>
              <Descriptions.Item label="Useful Link 1">
                {location.useful_link1_name}&nbsp;&nbsp;{location.useful_link1_url}
              </Descriptions.Item>
              <Descriptions.Item label="Useful Link 2">
                  {location.useful_link2_name}&nbsp;&nbsp;{location.useful_link2_url}
              </Descriptions.Item>
              <Descriptions.Item label="Useful Link 3">
                  {location.useful_link3_name}&nbsp;&nbsp;{location.useful_link3_url}
              </Descriptions.Item>
            </Descriptions>
          </>
        })}

      <DoctorChangeLogsDrawer
        targetID={id}
        messagesVisible={messagesVisible}
        setMessagesVisible={setMessagesVisible}
      />
      <Descriptions title="RXNT Accounts" layout="vertical" column={1}>
        <Descriptions.Item key={0} style={{
          paddingBottom: '2px'
        }}>
          <span style={{ width: 100, }}>default</span>
          <span>{doctorInfo.rxnt_user_name} </span>
        </Descriptions.Item>
        { renderRxntAccountsDom(doctorInfo.rxnt_accounts || []) }
      </Descriptions>

      <Descriptions title="Provider Compensation" layout="vertical" column={1} style={{
        paddingBottom: '25px',
      }}>
        <Descriptions.Item key={0} style={{
          paddingBottom: '2px',
        }}>
          <span style={{ width: 100, }}>Date</span>
          <span style={{ width: 100, }}>Hourly Rate </span>
          <span style={{ width: 100, }}>Collab Fee</span>
          <span style={{ width: 180, }}>License Reimbursement</span>
          <span style={{ width: 100, }}>Referral Bonus</span>
          <span style={{ width: 100, }}>Transfer Fee</span>
          <span style={{ width: 100, }}>Other Bonus</span>
        </Descriptions.Item>
        {renderProviderCompensationDom(doctorInfo.doctor_incomes || [])}
      </Descriptions>
    </div>
  );
};

export default DoctorInfo;
