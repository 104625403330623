import "./index.less";

import React, { Component } from "react";
import { Breadcrumb } from "antd";
import { Link } from "umi";
import classNames from "classnames";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { isBrowser } from "@/utils/utils";
import RouteContext from "../RouteContext";

import UserSearch  from "@/layouts/UserSearch";

const defaultRenderCollapsedButton = collapsed => (collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />);

export default class GlobalHeader extends Component {
  static contextType = RouteContext;

  triggerResizeEvent = () => {
    if (isBrowser()) {
      const event = document.createEvent("HTMLEvents");
      event.initEvent("resize", true, false);
      window.dispatchEvent(event);
    }
  };

  toggle = () => {
    const { collapsed, onCollapse } = this.props;
    if (onCollapse) onCollapse(!collapsed);
    this.triggerResizeEvent();
  };

  renderCollapsedButton = () => {
    const { collapsed, collapsedButtonRender = defaultRenderCollapsedButton, menuRender } = this.props;

    if (collapsedButtonRender !== false && menuRender !== false) {
      return (
        <span className="aster-global-header-trigger" onClick={this.toggle}>
          {collapsedButtonRender(collapsed)}
        </span>
      );
    }

    return null;
  };

  renderBreadcrumb() {
    const breadcrumbs = this.context.breadcrumb.routes ? this.context.breadcrumb.routes : [];
    const children = breadcrumbs.map((item, i) => {
      if (item.component || item.path === "/") {
        return (
          <Breadcrumb.Item key={`breadcrumb-${i}`}>
            <Link to={item.path}>{item.breadcrumbName}</Link>
          </Breadcrumb.Item>
        );
      }
      return <Breadcrumb.Item key={`breadcrumb-${i}`}>{item.breadcrumbName}</Breadcrumb.Item>;
    });

    return <Breadcrumb>{children}</Breadcrumb>;
  }

  render() {
    const { rightContentRender, className: propClassName, style, location } = this.props;
    const className = classNames(propClassName, "aster-global-header", "aster-dark");

    return (
      <div className={className} style={style}>
        <UserSearch />
        <div style={{ flex: 1 }} />
        {rightContentRender && rightContentRender(this.props)}
      </div>
    );
  }
}
