import React, { useEffect, useMemo } from 'react';
import useMergeValue from 'use-merge-value';
import classNames from 'classnames';
import { stringify } from 'use-json-comparison';
import { Layout } from 'antd';
import useAntdMediaQuery from 'use-media-antd-query';
import Omit from 'omit.js';

import getMenuData from '@/utils/getMenuData';
import { useDeepCompareEffect } from '@/utils/utils';
import { getBreadcrumbProps } from '@/utils/getBreadcrumbProps';

import Header from './Header';
import RouteContext from './RouteContext';
import MenuCounter from './SiderMenu/Counter';
import PageLoading from './PageLoading';
import SiderMenu from './SiderMenu';
import WrapContent from './WrapContent';
import './index.less';

const getPaddingLeft = (hasLeftPadding, collapsed, siderWidth) => {
  if (hasLeftPadding) {
    return collapsed ? 50 : siderWidth;
  }
  return undefined;
};

const BasicLayout = (props) => {
  const {
    children,
    onCollapse: propsOnCollapse,
    location = { pathname: '/' },
    route = {
      routes: [],
    },
    menu = { locale: false },
    menuDataRender,
    siderWidth = 200,
    loading,
    style,
    ...rest
  } = props;

  const colSize = useAntdMediaQuery();
  const { routes = [] } = route;

  // Memoize menu data calculations
  const menuInfoData = useMemo(() => {
    return getMenuData(routes, null, menuDataRender);
  }, [routes, menuDataRender]);

  const renderMenuInfoData = useMemo(() => {
    return menuDataRender ? getMenuData(routes, menu, menuDataRender) : {};
  }, [routes, menu, menuDataRender]);

  const { breadcrumb = {}, breadcrumbMap, menuData = [] } = !menuDataRender
    ? menuInfoData
    : renderMenuInfoData;

  useDeepCompareEffect(() => {
    if (!menuDataRender) {
      const infoData = getMenuData(routes, menu, menuDataRender);
      // Update menu data synchronously
      setMenuInfoData(infoData);
    }
  }, [props.route, stringify(menu)]);

  const [collapsed, onCollapse] = useMergeValue(false, {
    value: props.collapsed,
    onChange: propsOnCollapse,
  });

  const defaultProps = Omit({ ...props, breadcrumb }, ['className', 'style']);

  const className = classNames(props.className, 'aster-basicLayout', {
    [`screen-${colSize}`]: colSize,
  });

  const breadcrumbProps = useMemo(() =>
    getBreadcrumbProps({ ...defaultProps, breadcrumbMap }),
    [defaultProps, breadcrumbMap]
  );

  const genLayoutStyle = useMemo(() => ({
    paddingLeft: getPaddingLeft(false, collapsed, siderWidth),
    position: 'relative',
    background: '#fff',
    maxHeight: '100vh',
    overflow: 'scroll',
  }), [collapsed, siderWidth]);

  const contentClassName = classNames('aster-basicLayout-content', {
    'aster-basicLayout-has-header': true,
  });

  // Optimized page change effect
  useEffect(() => {
    const { onPageChange } = props;
    if (onPageChange) {
      const currentPath = location.pathname;
      onPageChange(location);
      return () => {
        // Cleanup when path changes
        if (currentPath !== location.pathname) {
          onPageChange(null);
        }
      };
    }
  }, [location.pathname]);

  return (
    <MenuCounter.Provider>
      <RouteContext.Provider
        value={{
          ...defaultProps,
          breadcrumb: breadcrumbProps,
          menuData,
          collapsed,
          isChildrenLayout: true,
        }}
      >
        <div className={className}>
          <Layout style={{ minHeight: '100%', ...style }} hasSider>
            <SiderMenu
              {...defaultProps}
              menuData={menuData}
              onCollapse={onCollapse}
              collapsed={collapsed}
            />
            <Layout style={genLayoutStyle}>
              <Header collapsed={collapsed} onCollapse={onCollapse} {...props} />
              <WrapContent className={contentClassName} {...rest}>
                {loading ? <PageLoading /> : children}
              </WrapContent>
            </Layout>
          </Layout>
        </div>
      </RouteContext.Provider>
    </MenuCounter.Provider>
  );
};

export default React.memo(BasicLayout);
