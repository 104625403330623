import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { connect, Link } from 'umi';
import NumberFormat from 'react-number-format';
import ReactCodesInput from '@/components/CodeInput';
import cn from 'classnames/bind';

import { ReactComponent as MindfulLogo } from '@/assets/mindful-logo.svg';
import { ReactComponent as Logo } from '@/assets/logo.svg';
import styles from './login.less';
const cx = cn.bind(styles);


const CodeInput = (props) => {
  const onChangeCode = e => {
    const value = e.target.value
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '') {
      props.onChange(value);
    }
  };

  return (
  <Input
  name = "code"
  value = {props.value}
  onChange = {onChangeCode}
  maxLength={6}
  size = "large"
  />)
}

const Login = (props) => {
  const { dispatch, loading } = props;

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('vertical');
  const [phoneDisabled, setPhoneDisabled] = useState(true);
  const [phone, setPhone] = useState(true);
  const [code, setCode] = useState(true);
  const [codeDisabled, setCodeDisabled] = useState(true);
  const [valcodeFormEnabled, setValcodeFormEnabled] = useState(false);

  const isDone = window.location.href.includes('donefirst.com');

  const onPhoneChanged = (v) => {
    if (/\d{10}/.test(v.value)) {
      setPhone(v.value);
      setPhoneDisabled(false);
    } else {
      setPhone('');
      setPhoneDisabled(true);
    }
  };

  const onCodeChanged = (value) => {
    setCode(value);
    if (/\d{6}/.test(value)) {
      setCodeDisabled(false);
    } else {
      setCodeDisabled(true);
    }
  };

  const getVerificationCode = () => {
    dispatch({
      type: 'login/sendVerifyCode',
      payload: {
        phone,
      },
      callback: () => {
        setValcodeFormEnabled(true);
      },
    });
  };

  const doLogin = () => {
    if (/\d{10}/.test(phone) == false) {
      message.error('Please input your phone number.');
      return;
    }

    if (/\d{6}/.test(code) == false) {
      message.error('Please input your verification code.');
      return;
    }

    dispatch({
      type: 'login/login',
      payload: {
        phone,
        code,
      },
      callback: () => {},
    });
  };

  return (
    <div className={styles.loginRoot}>
      <div className={styles.loginHeader}>
        <div className={styles.loginWrap}>
          { isDone ? <Logo className={styles.logo} /> : <MindfulLogo /> }
        </div>
      </div>
      <div className={styles.loginWrap}>
        <div className={styles.loginBox}>
          <div className={styles.heading}>Sign in to your account</div>
          <Form layout={formLayout} form={form}>
            <div className={cx({ phoneForm: true, phoneFormShow: !valcodeFormEnabled })}>
              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number',
                  },
                ]}
              >
                <NumberFormat
                  className="cusInput"
                  format="(###) ###-####"
                  mask="_"
                  onValueChange={onPhoneChanged}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  size="large"
                  block
                  disabled={phoneDisabled}
                  loading={loading.effects['login/sendVerifyCode']}
                  onClick={getVerificationCode}
                >
                  Get Verification Code
                </Button>
              </Form.Item>
              <div className={cx({ links: true })}>
                <Link to="/user/email_login">Login by Email</Link>
              </div>
              <div className={cx({ links: true })}>
                <Link to="/user/password_login">Login by Password</Link>
              </div>
            </div>
            <div className={cx({ valcodeForm: true, valcodeFormShow: valcodeFormEnabled })}>
              <Form.Item
                label="Enter your 6-digit verification code"
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Please input your verificication code',
                  },
                ]}
              >
                 <CodeInput
                value={code}
                onChange={onCodeChanged} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                  disabled={codeDisabled}
                  onClick={doLogin}
                  loading={loading.effects['login/login']}
                >
                  Sign In
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default connect(({ login, loading }) => ({
  login,
  loading,
}))(Login);

export {CodeInput} ;
