import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link } from 'umi';
import { Drawer, Space, Popover, Input, Table, Row, Col, Button } from 'antd';
import moment from 'moment';
import { SearchOutlined, createFromIconfontCN } from '@ant-design/icons';
import numeral from 'numeral';
import { AsYouType } from 'libphonenumber-js';

import Filters from '@/components/Filters';
import defaultSettings from '../../../defaultSettings';

import ConsultationDrawer from '@/components/ConsultationDrawer';

import { formatUnix } from '../../utils/xtime';
import styles from './index.less';
const cx = cn.bind(styles);

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const filters = [
  {
    name: 'doctor_email',
    label: 'Doctor Email',
    type: 'text',
  },
  {
    name: 'doctor_phone',
    label: 'Doctor Phone',
    type: 'text',
  },
  {
    name: 'consultation_type',
    label: 'Type',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'created_at',
    label: 'Date',
    type: 'date',
  },
];

const Patient = (props) => {
  const {
    active,
    id,
    consultations,
    consultation_types,
    loadingConsultations,
    replyData,
    loadReplies,
    loadingReplies,
    loadingSendMessage,
  } = props;
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState('');
  const [selectedType,setSelectedType]=useState(-1);
  const inputEl = useRef(null);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
    setSelected('');
  };

  const loadDetail = (v) => {
    setVisible(true);
  };

  const selectRow = (record) => {
    setSelected(record.id);
    setSelectedType(record.consultation_type);
    setVisible(true);
    loadReplies({ id: record.id });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
    },
    {
      title: 'Type',
      dataIndex: 'consultation_type',
      key: 'consultation_type',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return r.consultation_type_name;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        if(r.status == 1) {
          return 'Open'
        } else if (r.status == 2) {
          return 'Solved'
        } else if (r.status == 3) {
          return 'Closed'
        }
      }
    },
    {
      title: 'Replies',
      dataIndex: 'reply_num',
      key: 'reply_num',
      ellipsis: true,
      width: 70,
    },
    {
      title: 'Clinician',
      dataIndex: 'doctor_id',
      key: 'doctor_id',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        if(!r.doctor){
          return '';
        }
        const userName = `${r.doctor.name} ${r.doctor.title}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/doctor/detail/${r.doctor.id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/doctor/detail/${r.doctor.id}`}>{r.doctor.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.doctor.phone)}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <a href="#">{userName}</a>
          </Popover>
        );
      },
    },

    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
    },
  ];

  useEffect(() => {
    setPage(1);
    props.loadConsultations({ page: 1, page_size: 20 }, () => {});
    props.loadConsultationTypes();
  }, [active, id]);

  const reloadReplyData = () => {
    loadReplies({ id: replyData.consultation.id });
    props.loadConsultations(
      { page: 1, page_size: 20, filters: JSON.stringify(query) },
      () => {},
    );
  }

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    props.loadConsultations({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => {});
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    props.loadConsultations(
      { page: pagination.current, page_size: pagination.pageSize, filters: JSON.stringify(query) },
      () => {},
    );
  };

  const f = () => {
    for (const v of filters) {
      if (v.name === 'consultation_type' && v.items.length === 0) {
        v.items = consultation_types;
        continue;
      }
    }
    return filters;
  };

  return (
    <div className={cx({ subcontainer: true })}>
      <div className={cx({ subheader: true })}>
        <h3>Consultations</h3>
        <Space>
          <Filters filters={f()} onFilter={onFilter} />
        </Space>
      </div>

      { selected != "" &&
        <ConsultationDrawer
          role="admin"
          visible={visible}
          replyData={replyData}
          selectedType={selectedType}
          onClose={onClose}
          onReply={() => { reloadReplyData() }}
        />
      }

      <Table
        columns={columns}
        dataSource={consultations.items}
        rowClassName={(r) => {
          if (r.id == selected) {
            return 'rowSelected';
            // return styles.rowSelected;
          }
          return '';
        }}
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
        rowKey="id"
        loading={loadingConsultations}
        onChange={handleTableChange}
        pagination={{ pageSize: 20, current: page, simple: true, total: consultations.total }}
      />
    </div>
  );
};

export default Patient;
