

import React, { useEffect, useState } from 'react';
import { Modal, Spin, Table, Space, Tooltip, Form, Input, Button, Select, Typography, Row, Col } from 'antd';
import { connect, history } from 'umi';
import { QuestionCircleOutlined, } from '@ant-design/icons';

import { useRequest } from 'ahooks';
import { useChartAuditStore } from '@/stores';
import styles from './ChartNotesAudit.less';
import { srvSubmitChartAuditAppraisal, srvChartAuditAppraisal, } from '@/services/patient';

const { TextArea } = Input;

const NotesAuditItems = {
  data1: [
    {
      id: 1,
      key: 'cc_hpi',
      label: 'CC and HPI',
      tip: '- Use of Quotes for CC\n- HPI includes onset and duration of symptoms\n- describes functional impairments across settings\n- what has been tried previously'
    },
    {
      id: 2,
      key: 'psychiatric_history_ros',
      label: 'Psychiatric History and ROS',
      tip: '-includes screening results\n- pertinent positives and negatives of symptomology\n- diagnostic differential(depression, anxiety, panic, insomnia, bipolar, etc)\n- past dx and past / current psych meds, dose, side effects or why they stopped\n- Current past therapy / counseling\n- hospitalizations\n- past trauma'
    },
    {
      id: 3,
      key: 'suicidal_risk_safety_assessment',
      label: 'Suicidal/ Risk/ Safety Assessment',
      tip: 'ask about past suicidal ideation/ attempts as well as current. Asks about self harm behaviors. violence, aggression, access'
    },
    {
      id: 4,
      key: 'substance_abuse_screening',
      label: 'Substance Abuse Screening',
      tip: 'ask about past suicidal ideation/ attempts as well as current. Asks about self harm behaviors. violence, aggression, access'
    },
    {
      id: 5,
      key: 'medical_history_ros',
      label: 'Medical History and ROS',
      tip: 'past and current dx and meds. allergies, ht/ wt, pcp, last exam, pertinent negatives- cardiac, neuro'
    },
    {
      id: 6,
      key: 'personal_social_history_psychostressors',
      label: 'Personal/ Social History/ Psychostressors',
      tip: 'relationships, career, general overview, idea of what kind of support they have, stressors they have'
    },
    {
      id: 7,
      key: 'family_medical_mental_health_history',
      label: 'Family Medical/ Mental Health History',
      tip: 'includes medical and mental- pertinent negatives'
    },
    {
      id: 8,
      key: 'mental_status_exam',
      label: 'Mental Status Exam',
      tip: 'is present and includes mood'
    },
    {
      id: 9,
      key: 'evidence_support_of_diagnosis',
      label: 'Evidence and Support of Diagnosis',
      tip: '-Has provider proven DSM-V criteria for the dx they chose with the documentation?\n- Did they do a sufficient dx differential to ensure dx they chose is correct?',
    },
    {
      id: 10,
      key: 'appropriateness_of_treatment_plan',
      label: 'Appropriateness of Treatment Plan',
      tip: '-Was medication choice appropriate for dx.\n- Was dosing appropriate.\n- Was the reason as to why they chose that medication given.\n- Follow up plan is discussed and appropriate',
    },
  ],
  data2: [
    {
      id: 1,
      key: 'cc_hpi',
      label: 'CC and HPI',
      tip: "CC in quotes\nHPI includes current report of medication's therpeutic effect, current history and report, sleep, appetite"
    },
    {
      id: 2,
      key: 'interim_history_current_rx',
      label: 'Interim History/Current Rx',
      tip: 'eports current medications including name/dose/frequency, any reported side effects, adherence, other reported issues/concerns\nAlso includes medication prescribed by other providers'
    },
    {
      id: 3,
      key: 'changes_in_medical_hx',
      label: 'Changes in Medical Hx',
      tip: 'Indicate change in medical history, allergies, lab/EKG results if applicable\nIF FEMALE: indicates if pregnant / planning pregnanct/ breastfeeding / LMP'
    },
    {
      id: 4,
      key: 'changes_in_social_hx',
      label: 'Changes in Social Hx',
      tip: 'Change in relationship status, employment, living situation, any significant stressors'
    },
    {
      id: 5,
      key: 'pdmp_cures_review',
      label: 'PDMP/Cures Review',
      tip: 'includes and adresses any red flags/concerns'
    },
    {
      id: 6,
      key: 'mental_status_exam',
      label: 'Mental Status Exam',
      tip: 'includes mood (if not included in HPI), thought process, appearance, affect, speech pattern, s/s of mania/psychosis, suicidal/homicidal ideations, presence of hallucinations'
    },
    {
      id: 7,
      key: 'diagnosis_treatment_plan',
      label: 'Diagnosis and Treatment Plan',
      tip: 'Indicates plan of care, provides evidence/support of treatment plan if any medication changes or diagnosis changes/additions, recommended followup'
    },
  ],
  data4: [
    {
      id: 1,
      key: 'date_of_initial_assessment_or_last_fu',
      label: 'Date of Initial Assessment / Date of Last Follow-up',
      tip: ''
    },
    {
      id: 2,
      key: 'cc_hpi',
      label: 'CC and HPI',
      tip: "CC in quotes\nHPI includes current report of medication's therapeutic effect, current history and report, sleep, appetite",
    },
    {
      id: 3,
      key: 'interim_history',
      label: 'Interim History',
      tip: 'Reports current medications including name/dose/frequency, any reported side effects, adherence, other reported issues/concerns'
    },
    {
      id: 4,
      key: 'currently_rx_medication',
      label: 'Currently Rx Medication',
      tip: 'Reports current medications including name/dose/frequency, any reported side effects, adherence, other reported issues/concerns\nAlso includes medication prescribed by other providers'
    },
    {
      id: 5,
      key: 'medical_hx',
      label: 'Medical Hx',
      tip: 'Indicate current reported or change in medical history, allergies, lab/EKG results if applicable, PCP name/date last seen\nIF FEMALE: indicates if pregnant / planning pregnanct/ breastfeeding / LMP'
    },
    {
      id: 6,
      key: 'changes_in_family_hx_mh_hx',
      label: 'Changes in Family Hx and MH Hx',
      tip: 'Includes any changes in current family hx and mental health hx since last visit, including recent psych hospitilizations and recent self injury and/or suicide attempts'
    },
    {
      id: 7,
      key: 'changes_in_social_hx',
      label: 'Changes in Social Hx',
      tip: 'Change in relationship status, employment, living situation, any significant stressors'
    },
    {
      id: 8,
      key: 'substance_use',
      label: 'Substance Use',
      tip: 'Current use of: \ncaffiene, nicotine, alcohol, MJ, other substance use\nPDMP/ CURES review'
    },
    {
      id: 9,
      key: 'mental_status_exam',
      label: 'Mental Status Exam',
      tip: 'includes mood (if not included in HPI), thought process, appearance, affect, speech pattern, s/s of mania/psychosis, suicidal/homicidal ideations, presence of hallucinations'
    },
    {
      id: 10,
      key: 'diagnosis_treatment_plan',
      label: 'Diagnosis and Treatment Plan',
      tip: 'Indicates plan of care, provides evidence/support of treatment plan if any medication changes or diagnosis changes/additions, recommended followup'
    },
  ],
}

const NotesAudit = (props) => {
  const { account, audit, onClose } = props;

  const { chartAuditEvent } = useChartAuditStore();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({});
  const [formDisabled, setFormDisabled] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState([])

  const submitChartAuditAppraisalReq = useRequest(srvSubmitChartAuditAppraisal, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setModalVisible(false);
        chartAuditEvent.emit('refresh');
        if (onClose) onClose();
      }
    }
  });

  const fetchChartAuditAppraisalReq = useRequest(srvChartAuditAppraisal, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setFormValues(ret.data)
        form.setFieldsValue(ret.data);
        setFormDisabled(true);
      }
    }
  });

  const renderArea = (r) => {
    return (
      <Space>
        {r.label}
        <Tooltip title={() => {
          if (r.tip == '') return r.label;
          return (<div dangerouslySetInnerHTML={{ __html: r.tip.split('\n').join('<br/>') }} />)
        }}>
          <QuestionCircleOutlined style={{ color: "#5469D4", fontSize: '14px', lineHeight: '20px', marginBottom: '4px' }} />
        </Tooltip>
      </Space>
    )
  }

  const renderAppraisal = (r) => {
    return (
      <>
        <Form.Item
          name={`${r.key}`}
          rules={[
            {
              required: true,
              message: 'appraisal is required.',
            },
          ]}
        >
          <Select
            disabled={formDisabled}
            style={{
              width: '100%',
            }}
            options={[
              {
                value: 3,
                label: 'Complete and Thorough',
              },
              {
                value: 2,
                label: 'Sufficient',
              },
              {
                value: 1,
                label: 'Insufficient',
              },
              {
                value: 0,
                label: 'Absent',
              },
            ]}
          />
        </Form.Item>
        <Typography.Text className={styles.ItemScore} >
          Score: {Object.keys(formValues).length > 0 && Number(formValues[`${r.key}`]) >= 0 ? formValues[`${r.key}`] : ''}
        </Typography.Text>
      </>
    )
  }

  const renderComments = (r) => {
    return (
      <Form.Item
        name={`${r.key}_comments`}
      >
        <TextArea rows={2} maxLength={500} disabled={formDisabled} />
      </Form.Item>
    )
  }

  const itemsDom = data.map((r) => {
    return (
      <Row className={styles.NotesAuditItemRow}>
        <Col span={7} className={styles.NotesAuditArea}>{renderArea(r)}</Col>
        <Col span={9} className={styles.NotesAuditAppraisal}>{renderAppraisal(r)}</Col>
        <Col span={8} className={styles.NotesAuditComments}>{renderComments(r)}</Col>
      </Row>
    )
  })

  const onValuesChange = (changedValues, allValues = {}) => {
    let total_score = Object.values(allValues)
      .filter(v => Object.prototype.toString.call(v) === '[object Number]')
      .reduce((sum, current) => sum + current, 0);
    setFormValues(Object.assign({}, allValues, { total_score }));
  };

  const onFinish = (values) => {
    if (audit && audit.id !== '0') {
      submitChartAuditAppraisalReq.run(Object.assign({}, values, { audit_id: audit.id, total_score: formValues.total_score }));
    }
  };


  useEffect(() => {
    if (audit && audit.note_type) {
      setData(NotesAuditItems[`data${audit.note_type}`]);
      if (audit.audit_status == 2) {
        fetchChartAuditAppraisalReq.run({ id: audit.id });
      }
    }
  }, [audit])

  return (
    <Spin tip="Loading..." spinning={fetchChartAuditAppraisalReq.loading || submitChartAuditAppraisalReq.loading}>
      <div className={styles.NotesAudit}>
        <Form
          form={form}
          layout='vertical'
          onValuesChange={onValuesChange}
          onFinish={onFinish}
          disabled={formDisabled}
        >

          <Row className={styles.NotesAuditHeader}>
            <Col span={7}><b>Documentation Area</b></Col>
            <Col span={9}><b>Appraisal</b></Col>
            <Col span={8}><b>Additional Comments</b></Col>
          </Row>

          {itemsDom}

          <div className={styles.TotalScore}>
            <Typography.Text >Score: {formValues.total_score}</Typography.Text>
          </div>

          <Form.Item
            name='overall_thoughts'
            label={(
              <Space>
                Overall Thoughts
                <Tooltip title={() => {
                  const tip = '- Professional presentation\n- Creates a clear clinic picture\n- Easy to read and follow\n- No confounding information\n- Unique, Personalized, Patient specific, Use of Quotes\n- No Grammatical errors\n- No use of non - standard abbreviations or terminology\n- Includes pertinent negative findings\n- Good Critical Thinking: Identifies Red Flags\n- Describes functional impairments across settings(interpersonal, academic, occupational, social)\n- Gathers more information when needed - past meds, side effects, etc.\n- Rationale for Diagnosis and Treatment Plan\n- Clear Plan\n- Discusses Education and Monitoring / follow up'
                  return (<div dangerouslySetInnerHTML={{ __html: tip.split('\n').join('<br/>') }} />)
                }}>
                  <QuestionCircleOutlined style={{ color: "#5469D4", fontSize: '14px', lineHeight: '20px', marginBottom: '4px' }} />
                </Tooltip>
              </Space>
            )}
          >
            <TextArea rows={2} maxLength={500} disabled={formDisabled} />
          </Form.Item>

          <br />
          {audit.audit_status == 1 && (
            <Form.Item>
              <Button type="primary" disabled={formDisabled} onClick={() => {
                form.validateFields().then(() => {
                  setModalVisible(true);
                })
              }}>
                Sign and Submit
              </Button>
            </Form.Item>
          )}
          {audit.audit_status == 2 && account?.currentUser?.role === 'admin' && (
            <div>
              <Typography.Text >Chart Auditor: {formValues.auditor_name}</Typography.Text>
            </div>
          )}

          {modalVisible && (
            <Modal title="Are you sure?" visible={modalVisible} okText="Confirm"
              onOk={() => { form.submit(); }}
              onCancel={() => { setModalVisible(false) }}
            >
              <p>Once you submit this note, you will not be able to delete or make further modifications.</p>
            </Modal>
          )}
        </Form>
      </div>
    </Spin>
  )
};

export default connect(({ account, loading }) => ({
  account,
  loading,
}))(NotesAudit);
