import { Redirect } from 'umi';
import { connect, history } from 'umi';
import React, {useState} from 'react';
import cn from 'classnames/bind';
import styles from './index.less';
import { Tabs } from 'antd';
import { useFlag } from '@unleash/proxy-client-react';
const { TabPane } = Tabs;
const cx = cn.bind(styles);

import AdminPatientList from '../patient/AdminPatientList';
import DoctorPatientList from '../ehr/DoctorPatient';
import AdminAwaitingTransferPatientList from '../patient/AdminAwaitingTransferPatientList';

const PatientListPage = (props) => {
  const { account, location } = props;
  const flagTransferFlowImprovement = useFlag('transfer_patient_flow_improvement');
  const [activeKey, setActiveKey] = useState('1');
  const onTabChange = (v) => {
    setActiveKey(v);
  };

  if (location.pathname.indexOf('/ehr_patient') !== -1) {
    return <Redirect to={`${location.pathname.replace('/ehr_patient', '/patient')}${location.search}`} />;
  }
  if (account.currentUser.role === 'admin') {
    if(flagTransferFlowImprovement){
      return (
        <div className={cx({ container: true })}>
          <Tabs defaultActiveKey="1" activeKey={activeKey} onTabClick={onTabChange}>
            <TabPane tab="All Patients" key="1">
              <AdminPatientList parentProps={props} activeKey={activeKey} active={activeKey === '1'} />
            </TabPane>
            <TabPane tab="Awaiting Transfer" key="2">
              <AdminAwaitingTransferPatientList parentProps={props} activeKey={activeKey} active={activeKey === '2'} />
            </TabPane>
          </Tabs>
        </div>
      )
    } else {
      return <AdminPatientList parentProps={props} />;
    }
  } else {
    return <DoctorPatientList parentProps={props} />;
  }
};

export default connect(({ account, loading }) => ({
  account,
  loading,
}))(PatientListPage);
