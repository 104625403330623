import React, { useState, useRef } from 'react';
import { Table, Badge, Drawer, Input, Button, Row, Col, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import { formatUnix } from '@/utils/xtime';

import {
  srvPatientConsultations,
  srvPatientConsultationReplies,
  srvSendConsultationReply,
  srvDoctorSendConsultation,
} from '@/services/patient';

import ConsultationDrawer from '@/components/ConsultationDrawer';

import styles from './Consultations.less';
const cx = cn.bind(styles);

const Consultations = (props) => {
  const { active, id, loadingReplies, loading, loadingSendMessage } = props;
  const [consultations, setConsultations] = useState([]);
  const [replyData, setReplyData] = useState({ consultation: {}, consultation_replies: [] });
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState('');
  const [selectedType,setSelectedType]=useState(-1);
  const inputEl = useRef(null);

  const consultationsReq = useRequest(srvPatientConsultations, {
    manual: true,
    onSuccess: (ret, params) => {
      setConsultations(ret.data);
      const urlParams = new URLSearchParams(window.location.search);
      const consultation_id = urlParams.get('consultation_id');
      if (consultation_id) {
        const consultation = ret.data.find((item) => item.id === consultation_id);
        if (consultation) {
          selectRow(consultation);
        }
      }
    },
  });

  const repliesReq = useRequest(srvPatientConsultationReplies, {
    manual: true,
    onSuccess: (ret, params) => {
      setReplyData(ret.data);
    },
  });

  const sendConsultationReq = useRequest(srvDoctorSendConsultation, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        inputEl.current.state.value = '';
        setVisible(false);
        loadData();
      }
    },
  });

  const sendReplyReq = useRequest(srvSendConsultationReply, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        inputEl.current.state.value = '';
        repliesReq.run({ id: replyData.consultation.id });
        loadData();
      }
    },
  });

  const loadData = () => {
    if (active) {
      consultationsReq.run({ id });
    }
  };

  useMount(() => {
    loadData();
  });

  useUpdateEffect(() => {
    loadData();
  }, [active, id]);


  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const showDrawerWithoutConsultation = () => {
    setReplyData({ consultation: {}, consultation_replies: [] });
    setVisible(true);
  };

  const loadDetail = (v) => {
    setVisible(true);
  };

  const selectRow = (record) => {
    setSelected(record.id);
    setSelectedType(record.consultation_type);
    setVisible(true);
    repliesReq.run({ id: record.id });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      render: (v, r) => {
        return v;
      },
    },
    {
      title: 'Type',
      ellipsis: true,
      dataIndex: 'consultation_type_name',
      width: 200,
      key: 'consultation_type_name',
    },
    {
      title: 'Status',
      ellipsis: true,
      dataIndex: 'status',
      width: 250,
      key: 'status',
      render: (v, r)=>{
        if (r.status == 1) {
          return (
            <>
              Open {r.assigned_to_id === 0 && <span className={cx({ assigned: true })}>Assigned to Care Team</span>}
            </>
          )
        } else if (r.status == 2) {
          return 'Solved'
        }
      }
    },
    {
      title: 'Reply',
      ellipsis: true,
      dataIndex: 'reply_num',
      width: 80,
      key: 'reply_num',
    },
    {
      title: 'Date',
      ellipsis: true,
      dataIndex: 'created_at',
      width: 200,
      key: 'created_at',
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
    },
  ];

  const reloadReplyData = () => {
    if(replyData.consultation.id) {
      repliesReq.run({ id: replyData.consultation.id });
    } else {
      setVisible(false)
    }
    consultationsReq.run({ id });
  }

  return (
    <div className={cx({ container: true })}>
      <Row justify="space-between" style={{ borderBottom: '1px solid #e3e8ee' }}>
        <Col>
          <h3 style={{ boxShadow: 'none' }}>Consultations</h3>
        </Col>
        <Col>
          <Button type="primary" onClick={showDrawerWithoutConsultation}>
            Send message
          </Button>
        </Col>
      </Row>
      {
        <ConsultationDrawer
          role="doctor"
          visible={visible}
          replyData={replyData}
          userId={id}
          onClose={onClose}
          selectedType={selectedType}
          onReply={() => { reloadReplyData() }}
        />
      }
      <Table
        columns={columns}
        dataSource={consultations}
        rowClassName={(r) => {
          if (r.id == selected) {
            return 'rowSelected';
          }
          return '';
        }}
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
        rowKey="id"
        loading={loading}
        pagination={{ pageSize: 5, simple: true }}
      />
    </div>
  );
};

export default Consultations;
