import React, { useState, useEffect } from 'react';
import { Modal, Descriptions, Form, Spin,Select,Radio,InputNumber } from 'antd';

import numeral from 'numeral';
import { useRequest } from 'ahooks';
import { srvPaymentItem } from '@/services/admin';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

const RefundModal = ({ item = {}, onOk, ...modalProps }) => {
  const [form] = Form.useForm();
  const [payment, setPayment] = useState({});
  const [loading, setLoading] = useState(false);
  const reasons=[
    {
      oneLevel: "Appointment issue",
      twoLevel: "Provider no show",
    },
    {
      oneLevel: "Appointment issue",
      twoLevel: "Scheduling issue",
    },
    {
      oneLevel: "Patient experience",
      twoLevel: "Not aware of the price",
    },
    {
      oneLevel: "Patient experience",
      twoLevel: "No longer needed the service",
    },
    {
      oneLevel: "Patient experience",
      twoLevel: "Unsatisfied with the provider",
    },
    {
      oneLevel: "Patient experience",
      twoLevel: "Unused months(Actively Paying without Receiving Service))",
    },
    {
      oneLevel: "Patient experience",
      twoLevel: "Unaware about the Monthly Membership Fee",
    },
    {
      oneLevel: "Patient experience",
      twoLevel: "Cannot Afford the service",
    },
    {
      oneLevel: "Patient experience",
      twoLevel: "Service has been provided but PT rejected it(50% refund)",
    },
    {
      oneLevel: "Medication issue",
      twoLevel: "Medication Delayed/no Rx sent",
    },
    {
      oneLevel: "Medication issue",
      twoLevel: "Pharmacy Issue(Out of stock/Pharmacy refused to fill)",
    },
    {
      oneLevel: "Payment issue",
      twoLevel: "Unauthorized payment for the annual fee",
    },
    {
      oneLevel: "System charge",
      twoLevel: "Duplicate charge",
    },
    {
      oneLevel: "Other",
      twoLevel: "Promo/Voucher",
    },
    {
      oneLevel: "Other",
      twoLevel: "No Diagnosis",
    },
    {
      oneLevel: "Other",
      twoLevel: "Threatens to file a complaint in BBB, etc",
    },
    {
      oneLevel: "Other",
      twoLevel: "seek treatment elsewhere",
    },
  ];
  const [oneLevelReasonList, setOneLevelReasonList] = useState([]);
  const [twoLevelReasonList, setTwoLevelReasonList] = useState([]);
  const [reasonMap, setReasonMap] = useState({});
  const [reason, setReason] = useState('');
  const [refundRadio, setRefundRadio] = useState('');
  const [inputAmount,setInputAmount]=useState('');
  const fetchPaymentReq = useRequest(srvPaymentItem, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data) {
          setPayment(ret.data);
          form.setFieldsValue({
            amount: numeral((ret.data.amount - ret.data.amount_refunded) / 100).format('0,0.00')
          });
        } else {
          setPayment({});
        }
      }
    },
  });

  useEffect(() => {
    classifyReason();
    if (item && item.id) {
      fetchPaymentReq.run({ id: item.id });
    }
  }, [item]);
  const classifyReason=()=>{
    const uniqueOneLevelReasons = [...new Set(reasons.map((item) => item.oneLevel))];
    setOneLevelReasonList(uniqueOneLevelReasons);
    const tempTwoLevelReasonMap = {};
    uniqueOneLevelReasons.forEach((reason) => {
      tempTwoLevelReasonMap[reason] = reasons.filter((item) => item.oneLevel === reason);
    });
    setReasonMap(tempTwoLevelReasonMap);
  }

  const changeOneLevelReason = (value) => {
    const temp=reasonMap[value];
    setTwoLevelReasonList(temp.map((reason) => ({
      label: reason.twoLevel,
      value: reason.twoLevel,
    })),);
    form.setFieldsValue({
      reason: '',
      amount: '',
    });
    setReason('');
    setRefundRadio('');
  };
  const changeRefund = (e) => {
    setInputAmount('');
    const value=e.target.value;
    setRefundRadio(value);
    if (value === 1) {
      form.setFieldsValue(
        { amount: (payment.amount - payment.amount_refunded)/100 * 0.2, }
      )
    }
    if (value === 2) {
      form.setFieldsValue(
        { amount: (payment.amount - payment.amount_refunded)/100 * 0.5, }
      )
    }
    if (value === 3) {
      form.setFieldsValue(
        { amount: (payment.amount - payment.amount_refunded)/100 * 1, }
      )
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const data = { id: payment.id, reason: values.reason };
        if (Number(values.amount) > 0) {
          data.amount = Number(Number(values.amount * 100).toFixed());
        }
        setLoading(true);
        onOk(data);
      })
      .catch((errorInfo) => {});
  };

  const onCheckRefundAmount = (_, value) => {
    console.log('aa',value)
    const refundAmount = Number(Number(value * 100).toFixed());
    if (refundAmount > 0 && refundAmount <= (payment.amount - payment.amount_refunded)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Amount is wrong'));
  }

  const modalOpts = {
    ...modalProps,
    onOk: handleOk,
  };
  const amount = numeral((payment.amount ? payment.amount : 0) / 100).format('0,0.00');
  const amount_refunded = numeral((payment.amount_refunded ? payment.amount_refunded : 0) / 100).format('0,0.00');
  return (
    <Modal
      {...modalOpts}
      className={cx({ refundModal: true })}
      title={
        <div>
          <p style={{color: '#1a1f36',fontWeight:'600',marginBottom:'20px'}}>Refund</p>
          <Descriptions title="" layout="vertical" column={2}>
            <Descriptions.Item label="Amount">
              <span style={{ color: '#1a1f36', fontWeight: 500 }}>{amount}</span>
            </Descriptions.Item>
            <Descriptions.Item label="Amount Refunded">
              <span style={{ color: '#1a1f36', fontWeight: 500 }}>{amount_refunded}</span>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title="" layout="vertical" column={2} style={{marginTop:'20px'}}>
          <Descriptions.Item label="Product">
          <span style={{ color: '#1a1f36', fontWeight: 500 }}>{item.order && item.order.product_id_name}</span>
            </Descriptions.Item>
            <Descriptions.Item label="Customer">
            <span style={{ color: '#1a1f36', fontWeight: 500 }}> {item.patient && item.patient.email}</span>
            </Descriptions.Item>
          </Descriptions>
        </div>
      }
    >
      <Spin tip="Loading..." spinning={fetchPaymentReq.loading || loading}>
        <Form
          form={form}
          preserve={false}
          className={cx({ modalForm: true })}
          layout="vertical"
        >
          <Form.Item
            label="Reason"
            name="reason"
            rules={[{ required: true, message: 'Reason is required' }]}
          >
          <Select 
            options={oneLevelReasonList.map(reason => ({ value: reason, label: reason }))}
            onChange={changeOneLevelReason}
            style={{ marginBottom: '20px',width:'47%',marginRight:'6%' }}
          />

          <Select
            value={reason}
            options={twoLevelReasonList}
            style={{ marginBottom: '20px',width:'47%' }}
            onChange={(value) => {
              form.setFieldsValue({
                reason: value,
              });
              setReason(value);
            }}
          />
          </Form.Item>
          <Form.Item
            label="Refund Amount"
            rules={[
              {
                required: true, message: 'Refund Amount is required'
              },
              {
                validator: onCheckRefundAmount,
              },
            ]}
          >
            <Radio.Group onChange={(e) => {
              changeRefund(e);
            }} value={refundRadio}>
              <Radio value={1}>20%</Radio>
              <Radio value={2}>50%</Radio>
              <Radio value={3}>100%</Radio>
              <Radio value={4}>Custom</Radio>
            </Radio.Group>
            <Form.Item name="amount" rules={[
              {
                required: true, message: 'Refund Amount is required'
              },
              {
                validator: onCheckRefundAmount,
              },
            ]}>
               {refundRadio === 4 &&<InputNumber style={{ width: '100%', marginTop: '10px' }} value={inputAmount} min={0} max={payment.amount - payment.amount_refunded} />}
            </Form.Item>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default RefundModal;
