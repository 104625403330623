import React, {useEffect, useState} from "react";
import {useRequest} from "ahooks";
import {srvChartAuditNote, srvChartAuditNotes} from "@/services/patient";
import {Button, Drawer, Layout, Table} from "antd";
import {formatUnix} from "@/utils/xtime";
import styles from "./index.less";
import DoctorChartAuditDrawer from "@/components/DoctorChartAuditDrawer";


function ProviderChartAuditNotes (props) {
  const { year, month, visible, setVisible } = props;
  const [data, setData] = useState({ total: 0, items: [] });
  const [query, setQuery] = useState({audit_status: [2]}); // default is completed
  const [sort, setSort] = useState({});
  const [page, setPage] = useState(1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [audit, setAudit] = useState({});

  const fetchChartAuditNotesReq = useRequest(srvChartAuditNotes, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setData(ret.data);
      }
    },
  });

  useEffect(() => {
    onRefresh();
  }, [visible]);

  const onRefresh = () => {
    loadList(query, sort, { page: 1, page_size: 20, year: year, month: month, current: 1 });
  }

  const loadList = (filters, sorters = {}, params) => {
    let filtersData = filters ? filters : {};
    let filtersObj = { ...filtersData };
    fetchChartAuditNotesReq.run({ filters: JSON.stringify(filtersObj), sorter: JSON.stringify(sorters),  ...params });
  };

  const handleTableChange = (pagination, filters, sorters) => {
    setPage(pagination.current);
    let sorter = {};
    if (sorters && sorters.order) {
      sorter[sorters.field] = sorters.order === "ascend" ? 'ASC' : 'DESC';
      setSort(sorter);
    }
    loadList(query, sorter, { page: pagination.current, page_size: pagination.pageSize, year: year, month: month, current: 1 });
  };

  const fetchChartAuditNoteReq = useRequest(srvChartAuditNote, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setAudit(ret.data);
        setDrawerVisible(true);
      }
    },
  });

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList(q, { page: 1, page_size: 20, year: year, month: month, current: 1 });
  };

  const filters = [
    {
      name: 'doctor_name',
      label: 'Provider',
      type: 'text',
    },
    {
      name: 'audit_status',
      label: 'Audit Status',
      type: 'checkbox',
      items: [
        { label: 'Qualified', value: 1 },
        { label: 'Unqualified', value: 2 },
      ],
    },
  ];

  const columns = [
    {
      title: 'NOTES TYPE',
      dataIndex: 'note_type_name',
      key: 'note_type_name',
      ellipsis: true,
      width: 40,
      render: (v, r) => {
        return r.note_type_name;
      },
    },
    {
      title: 'PATIENT',
      dataIndex: 'patient_name',
      key: 'patient_name',
      ellipsis: true,
      width: 20,
      render: (v, r) => {
          return r.patient_name;
      },
    },
    {
      title: 'DATE',
      ellipsis: true,
      dataIndex: 'audit_at',
      key: 'audit_at',
      width: 40,
      render: (v, r) => {
        if (!r.audit_at || r.audit_at == 0) {
          return '';
        }
        return formatUnix(r.audit_at, 'MM/DD/YYYY h:mm A');
      },
    },
    {
      title: 'NOTE AUDIT',
      dataIndex: 'audit_status_name',
      key: 'audit_status_name',
      ellipsis: true,
      width: 30,
      render: (v, r) => {
        return r.audit_status_name;
      },
    },
  ];


  return (
    <Drawer
      title="Audited Notes This Month"
      width={600}
      className={styles.AuditDrawer}
      visible={visible}
      closable={false}
      onClose={() =>
        setVisible(false)
      }
    >
      <Layout>
        <Table
          columns={columns}
          dataSource={data.items}
          rowClassName={(r) => {
            return '';
          }}
          scroll={{ x: 500 }}
          rowKey="id"
          loading={fetchChartAuditNotesReq.loading}
          onChange={handleTableChange}
          pagination={{ pageSize: 20, current: page, simple: true, total: data.total }}
          onRow={(r) => ({
            onClick: () => fetchChartAuditNoteReq.run({ id: r.id })
          })}
        />

        {drawerVisible && (
          <DoctorChartAuditDrawer
            visible={drawerVisible}
            setVisible={setDrawerVisible}
            audit={audit}
            onRefresh={() => {}}
          />
        )}
      </Layout>
    </Drawer>
  )

}

export default ProviderChartAuditNotes;
