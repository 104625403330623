import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { ProviderDashboardSection } from './index';

import { srvDoctorSurveyRatings, srvDoctorSurveyPatientFeedbacks } from '@/services/patient';
import { useRequest } from 'ahooks';

import styles from './PatientReview.less';
import cn from 'classnames/bind';
const cx = cn.bind(styles);

const PatientReview = (props) => {
  const [total, setTotal] = useState({ rating: 0.0, reviews: 0 })
  const [feedbacks, setFeedbacks] = useState({ items: [], total: 0, comment_total: 0 });

  const fetchDoctorSurveyRatingsReq = useRequest(srvDoctorSurveyRatings, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret && ret.data && ret.data.items) {
        setTotal({ rating: Number(ret.data.rating).toFixed(1), reviews: ret.data.total })
      }
    },
  });

  const fetchDoctorSurveyPatientFeedbacksReq = useRequest(srvDoctorSurveyPatientFeedbacks, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret && ret.data && ret.data.items) {
        setFeedbacks({ items: ret.data.items, total: ret.data.total, comment_total: ret.data.comment_total, });
      }
    },
  });

  const loadDoctorSurveyRatings = (filters, params) => {
    let filtersData = filters ? filters : {};
    let filtersObj = { ...filtersData };
    fetchDoctorSurveyPatientFeedbacksReq.run({ filters: JSON.stringify(filtersObj), ...params });
  };

  useEffect(() => {
    fetchDoctorSurveyRatingsReq.run({});
    loadDoctorSurveyRatings(null, {})
  }, []);

  const columns = [
    {
      title: 'Ratings',
      dataIndex: 'rating',
      key: 'rating',
    },

    {
      title: 'Comments',
      dataIndex: 'comment',
      key: 'comment',
    },
  ];

  return (
    <div className={cx({ PatientReviewContainer: true })}>
      <ProviderDashboardSection
        sectionName="Patient Review"
        viewAllUrl={"/ehr_dashboard/patient_review"}
      >
        <div className={cx({ PatientReviewRatings: true })}>
          <h3>{Number(total.rating).toFixed(1)}<small>/5 <span>(Last {total.reviews} reviews)</span></small></h3>
        </div>
        <Table
          rowKey={'id'}
          dataSource={feedbacks.items}
          columns={columns}
          pagination={{ pageSize: 10, total: feedbacks.comment_total, showSizeChanger: false }}
        />
      </ProviderDashboardSection>
    </div>
  );
};

export default PatientReview;
