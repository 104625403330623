import React, { useState, useEffect,useMount } from 'react';
import { Tooltip, Select, Checkbox, Form, Input, Button, Row, Col } from 'antd';
import cn from 'classnames/bind';
import moment from 'moment-timezone';

import styles from './index.less';
const cx = cn.bind(styles);

const SelectFilter = (props) => {
  const { form, name, label, items, hasError } = props;
  const defaultVisible = !!form.getFieldValue(name);
  const [visible, setVisible] = useState(defaultVisible);
  const [filteredItems, setFilteredItems] = useState(items);
  const [selectedItem, setSelectedItem] = useState('');

  props.reset$.useSubscription(() => {
    setVisible(false);
  });

  useEffect(() => {
    setVisible(defaultVisible);
    setFilteredItems(items)
  }, [defaultVisible]);

  useEffect(() => {
    setFilteredItems(items)
  }, [items]);

  const filterOptions = (inputValue) => {
    const filtered = items.filter(option =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredItems(filtered)
  };

  const onSelected = (v) => {
    setSelectedItem(v);
  };

  return (
    <div className={cx({ filtersItem: true })}>
      <div className={cx({ filtersItemSwitch: true })}>
        <Checkbox
          checked={visible}
          onChange={(v) => {
            setVisible(v.target.checked);
          }}
        >
          {label}
        </Checkbox>
      </div>
      <div className={cx({ filtersItemBox: true, visible: visible })}>
        {visible && (
          <Form.Item name={name} valuePropName="value" rules={[{ required: true }]}>
            <Select showSearch size="small" name={name} value= {selectedItem} options={filteredItems} filterOption={false} onChange={onSelected} onSearch={filterOptions}/>
          </Form.Item>
        )}
      </div>
    </div>
  );
};

export default SelectFilter;
