import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link,useAccess } from 'umi';
import { Tooltip, Dropdown, Tag, Space, Popover, Input, Table, Row, Col, Button } from 'antd';
import moment from 'moment';
import { EllipsisOutlined, SearchOutlined, createFromIconfontCN } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { formatAppointmentAt, formatUnix } from '@/utils/xtime';
import Filters from '@/components/Filters';
import defaultSettings from '../../../defaultSettings';
import { appointmentStatusColor } from '@/utils/tagColor';
import RescheduleAppointment from '@/components/RescheduleAppointment';
import CancelAppointment from '@/components/CancelAppointment';
import {
srvSyncAppointmentInfoToApero
} from '@/services/admin';
import styles from './index.less';
const cx = cn.bind(styles);

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const filters = [
  {
    name: 'user_email',
    label: 'Patient Email',
    type: 'text',
  },
  {
    name: 'user_phone',
    label: 'Patient Phone',
    type: 'text',
  },
  {
    name: 'appointed_at',
    label: 'Date',
    type: 'date',
  },
  {
    name: 'appointment_type',
    label: 'Type',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'status',
    label: 'Status',
    type: 'checkbox',
    items: [],
  },
];

const Appointment = (props) => {
  const { active, appointments, loadingAppointments, appointment_types, appointment_statuses } = props;
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [rescheduleVisible, setRescheduleVisible] = useState(false);
  const [cancelVisible, setCancelVisible] = useState(false);
  const [app, setApp] = useState({});
  const access=useAccess();
  const columns = [
    {
      title: 'Appointment Time',
      dataIndex: 'appointed_at',
      key: 'appointed_at',
      ellipsis: true,
      render: (v, r) => {
        return (
          <div className={cx({timeSlot: r.admin_flag })} style={{ color: '#1a1f36', fontWeight: 500 }}>
            <Tooltip
              title={
                <div>
                  {r.admin_flag && <p>schedule by: {r.action_user_name}</p>}
                  <p>Patient time and time zone:</p>
                  <p>{formatAppointmentAt(r, r.timezone)}</p>
                </div>
              }
              placement="bottom"
            >
              {formatAppointmentAt(r)}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'appointment_type',
      key: 'appointment_type',
      ellipsis: true,
      width: 250,
      render: (v, r) => {
        return r.appointment_type_name;
      },
    },
    {
      title: 'Patient',
      dataIndex: 'user_first_name',
      key: 'user_first_name',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        if(!r.patient){
          return '';
        }
        const userName = `${r.patient.first_name} ${r.patient.last_name}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.user_id}?admin=true`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/patient/${r.user_id}?admin=true`}>{r.patient.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {r.patient.phone}
                </div>
                <div className="popover-user-item address">
                  <IconFont className="icon" type="icon-address" />
                  {r.patient.zipcode}
                </div>
                <div className="popover-user-item sex">
                  <IconFont className="icon" type="icon-sex" />
                  {r.patient.gender}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <a href="#">{userName}</a>
          </Popover>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      ellipsis: true,
      width: 110,
      key: 'status',
      render: (v, r) => {
        return <Tag color={appointmentStatusColor(r.status)}>{r.status_name}</Tag>;
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 50,
      render: (v, r) => {
        if (access.adminAccesser) {
          return
        }
        return (
          <Dropdown
            overlay={
              <div className={cx({ appointmentOpt: true })}>
                {(r.status === 1 || r.status === 2) && (
                  <div
                    className={cx({ appointmentOptItem: true })}
                    onClick={() => showCancelPanel(r)}
                  >
                    <a href="#">Cancel</a>
                  </div>
                )}
                {r.status !== 6 && (
                  <div
                    className={cx({ appointmentOptItem: true })}
                    onClick={() => showReschedulePanel(r)}
                  >
                    <a href="#">Reschedule</a>
                  </div>
                )}
                <div className={cx({ appointmentOptItem: true })}>
                  <Link to={`/appointment/${r.id}`}>View Appointment Details</Link>
                </div>
                  <div
                    className={cx({ appointmentOptItem: true })}
                    // onClick={() => syncAppointmentToApero(r)}
                    aria-disabled="true"
                    style={{ pointerEvents: 'none', opacity: 0.5 }}
                  >
                    <a href="#">Sync to Apero</a>
                  </div>
              </div>
            }
            trigger={['click']}
          >
            <Button size="small">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    setPage(1);
    props.loadAppointments({ page: 1, page_size: 20 }, () => {});
    props.loadAppointmentTypes();
    props.loadAppointmentStatuses();
  }, [active]);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    props.loadAppointments({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => {});
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    props.loadAppointments(
      { page: pagination.current, page_size: pagination.pageSize, filters: JSON.stringify(query) },
      () => {},
    );
  };

  const f = () => {
    for (const v of filters) {
      if (v.name === 'appointment_type' && v.items.length === 0) {
        v.items = appointment_types;
        continue;
      }

      if (v.name === 'status' && v.items.length === 0) {
        v.items = appointment_statuses;
      }
    }
    return filters;
  };

  const showCancelPanel = (item) => {
    setApp(item);
    setCancelVisible(true);
  };

  const syncAppointmentToApero = (item) => {
    syncAppointmentInfoToApero.run({ id:item.id });
  };

  const syncAppointmentInfoToApero = useRequest(srvSyncAppointmentInfoToApero, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        refresh();
      }
    },
  });

  const showReschedulePanel = (item) => {
    setApp(item);
    props.loadRescheduleCalendar({
      appointment_id: item.id,
      timezone: item.timezone,
      days: 4,
      date: moment().format('YYYYMMDD'),
      dir: 'next',
      from_admin: 1,
    });
    setRescheduleVisible(true);
  };

  return (
    <div className={cx({ appointment: true })}>
      <div className={cx({ subheader: true })}>
        <h3>Appointments</h3>
        <Space>
          <Filters filters={f()} onFilter={onFilter} />
        </Space>
      </div>

      <Table
        columns={columns}
        dataSource={appointments.items}
        rowClassName={(r) => {
          if (r.id == app.id) {
            return 'rowSelected';
          }
          return '';
        }}
        rowKey="id"
        loading={loadingAppointments}
        onChange={handleTableChange}
        pagination={{ pageSize: 20, current: page, simple: true, total: appointments.total }}
      />
      {
        rescheduleVisible && (
          <RescheduleAppointment
            visible={rescheduleVisible}
            appointment={app}
            rescheduleCalendar={props.rescheduleCalendar}
            loadingReschedule={props.loadingRescheduleAppointment}
            onClose={() => {
              setRescheduleVisible(false);
            }}
            rescheduleAppointment={(payload, callback) => {
              props.rescheduleAppointment(payload, () => {
                props.loadAppointmentItem({ id: payload.id });
                callback && callback();
              });
            }}
            loadRescheduleCalendar={props.loadRescheduleCalendar}
          />
        )
      }

      <CancelAppointment
        visible={cancelVisible}
        appointment={app}
        loadingCancel={props.loadingCancelAppointment}
        onClose={() => {
          setCancelVisible(false);
        }}
        cancelAppointment={(payload, callback) => {
          props.cancelAppointment(payload, () => {
            props.loadAppointmentItem({ id: payload.id });
            callback && callback();
          });
        }}
      />
    </div>
  );
};

export default Appointment;
