import React, { useState, useEffect } from 'react';
import { Form, Input, Radio, Space, Row } from 'antd';
const { TextArea } = Input;

import { useMount, useUpdateEffect } from 'ahooks';
import ConsultationDrawer from '../ConsultationDrawer';

const DynamicRadioGroupInput = (props) => {

  const { name, label, form, draft, setDraft, checkboxValues, initialValue } = props;
  const [radioValue, setRadioValue] = useState('');

  const onChange = (e) => {
    setRadioValue(e.target.value);
    if (e.target.value === false) {
      const field = { }
      field[name] = undefined;
      form.setFieldsValue(field);
      setDraft(Object.assign({ ...draft }, field));
    }
  }
  const buildRowsFromProps = checkboxValues.map(function (passedInValue, i) {
    return <Radio key={i} value={passedInValue}>{passedInValue}</Radio>

  });

  // draft sync to medicines
  useUpdateEffect(() => {
    if (draft && draft[name]) setRadioValue(1);
    if (label === 'Patient endorses or denies other medical conditions'&&draft && draft['struct_note_data']&&draft['struct_note_data'].patient_endorsement==='Endorses'){
      setRadioValue('Endorses');
    }
  }, [draft]);


  return (
    <>
      <Form.Item
        label={label}
        name={name}
        rules={[
          {
            required: true,
            message: `selection in ${label} is required`,
          },
        ]}
        initialValue={initialValue}
      >
        <Radio.Group onChange={onChange} value={radioValue}>
          {buildRowsFromProps}
        </Radio.Group>
      </Form.Item>
      {label === 'Patient endorses or denies other medical conditions' &&
        (initialValue === 'Endorses' || radioValue === 'Endorses') && (
          <Form.Item name={['struct_note_data', '']}>
            <TextArea rows={3} maxLength={1000} />
          </Form.Item>
        )}
    </>
  );
};

export default DynamicRadioGroupInput;
