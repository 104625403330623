import React, { useState } from 'react';
import {CloseCircleFilled, LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {Row, Col, Button, Drawer, Select, Input, Form, Upload, message} from 'antd';
import { useMount, useRequest } from 'ahooks';
import styles from './index.less';
import cn from 'classnames/bind';

const cx = cn.bind(styles);

import Marker from './marker';

import GoogleMapReact from 'google-map-react';
import GeoSuggestAutoComplete from '@/components/GeoSuggest';

import {
  srvAddLocationImage,
  srvDeleteLocationImage,
  srvUpdateLocationImage,
  srvStates,
} from '@/services/admin';
import ImgCrop from "antd-img-crop";
import {
  srvUploadToken
} from "@/services/patient";

const DoctorLocation = (props) => {
  const form = props.form
  const index = props.index
  const deleteable = props.deleteable
  const remove = props.remove
  const support_in_person = props.support_in_person

  const [states, setStates] = useState([]);
  const [firstLocationImageURL, setFirstLocationImageURL] = useState(props.location.location_image1)
  const [secondLocationImageURL, setSecondLocationImageURL] = useState(props.location.location_image2)
  const [thirdLocationImageURL, setThirdLocationImageURL] = useState(props.location.location_image3)
  const [fourthLocationImageURL, setFourthLocationImageURL] = useState(props.location.location_image4)
  const [uploading, setUploading] = useState(false);
  const [draggable, setDraggable] = useState(true);
  const [center, setCenter] = useState({
    lat: props.center.lat,
    lng: props.center.lng,
  });

  const [marker, setMarker] = useState({
    position: {
      lat: 34.0522,
      lng: -118.2437,
    },
  });

  const stateOpts = states.map((v, i) => {
    return (
      <Select.Option key={i} value={v.state}>
        {v.state}
      </Select.Option>
    );
  });

  const statesReq = useRequest(srvStates, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setStates(ret.data);
      }
    },
  });

  useMount(async () => {
    await statesReq.runAsync();
    if(props.location && props.location.lat && props.location.lng && props.location.lat !== 0 && props.location.lng !== 0) {
      setMarker({
        position: {
          lat: props.location.lat,
          lng: props.location.lng
        }
      })
      setCenter({
        lat: props.location.lat,
        lng: props.location.lng
      })
    }
  });

  const onDragMarker = (childKey, childProps, mouse) => {
    setDraggable(false);
    setMarker({
      position: {
        lat: mouse.lat,
        lng: mouse.lng,
      },
    });
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const uploadButton = (
    <div>
      <PlusOutlined/>
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const uploadLocationImage = (options, locationImageNumber, locationUrl) => {
    const { onSuccess, onError, file, onProgress } = options;
    const file_name = `${props.location.id}_${props.location.address_line1.replaceAll(' ', '_')}_${props.location.address_line2.replaceAll(' ', '_')}_${props.location.city.replaceAll(' ', '_')}_${props.location.state}_${props.location.zipcode}_${locationImageNumber}.png`;
    srvUploadToken({ type: 5, file_name }).then((res) => {
      const { upload_url } = res.data;

      file.arrayBuffer().then((content) => {
        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': file.type},
          body: content,
        };

        if (locationUrl === ""){
          fetch(upload_url, requestOptions).then(() => {
            onSuccess("Ok");
            message.success('successfully uploaded new image').then(() => srvAddLocationImage({file_name})
            )}).catch((err) => {
              onError({ err });
              message.error(err);
            });
        } else {
          fetch(upload_url, requestOptions).then(() => {
            onSuccess("Ok");
            message.success('successfully uploaded updated image').then(srvUpdateLocationImage({file_name}))}).catch((err) => {
              onError({ err });
              message.error(err);
            });
        }
      });
    });
  };


  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPng && isLt2M;
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleLocationChange = (info, locationNumber) => {
    if (info.file.status === 'uploading') {
      setUploading(true);
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setUploading(false);
        if (locationNumber === 1) {
          setFirstLocationImageURL(url)

        } else if (locationNumber === 2) {
          setSecondLocationImageURL(url)

        } else if (locationNumber === 3) {
          setThirdLocationImageURL(url)

        } else if (locationNumber === 4) {
          setFourthLocationImageURL(url)

        }
      });
    }
  };

  const deleteLocationImage = (file, locationImageNumber) => {
    const file_name = `${props.location.id}_${props.location.address_line1.replaceAll(' ', '_')}_${props.location.address_line2.replaceAll(' ', '_')}_${props.location.city.replaceAll(' ', '_')}_${props.location.state}_${props.location.zipcode}_${locationImageNumber}.png`;

      srvDeleteLocationImage({file_name}).then(() => {if (locationImageNumber === 'Location_image_1') {
        setFirstLocationImageURL('')
      } else if (locationImageNumber === 'Location_image_2') {
        setSecondLocationImageURL('')
      } else if (locationImageNumber === 'Location_image_3') {
        setThirdLocationImageURL('')
      } else if (locationImageNumber === 'Location_image_4') {
        setFourthLocationImageURL('')}}).catch((err) => message.error(err))

    }

    const deleteButton = (imageURL, location_number) => {
    if ( imageURL !== ""){
      return <Button onClick={(v) => {
        deleteLocationImage(imageURL, location_number)
      }}>Delete</Button>
    } else {
    }
    }


  const onDragendMarker = async (childKey, childProps, mouse) => {
    setDraggable(true);

    const location = {
      lat: marker.position.lat,
      lng: marker.position.lng,
    };
    const geocoder = new window.google.maps.Geocoder();
    const res = await geocoder.geocode({ location });

    if (!res.results.length) {
      return;
    }

    const ret = res.results[0];

    let cityObj = ret.address_components.find((comp) => comp.types.includes('locality'));
    let stateObj = ret.address_components.find((comp) =>
      comp.types.includes('administrative_area_level_1'),
    );
    let zipObj = ret.address_components.find((comp) => comp.types.includes('postal_code'));
    const city = cityObj && cityObj.long_name;
    const state = stateObj && stateObj.long_name;
    const stateShort = (stateObj && stateObj.short_name) || state;
    const zip = (zipObj && zipObj.long_name) || '';

    let streetObj = ret.address_components.find((comp) => comp.types.includes('street_number'));
    let routeObj = ret.address_components.find((comp) => comp.types.includes('route'));
    const street = (streetObj && streetObj.long_name) || '';
    const route = (routeObj && routeObj.long_name) || '';
    const addressLine1 = `${street} ${route}`;

    setMarker({ ...marker, text: ret.formated_address });

    var data = {};
    if (zip && zip.length) {
      data.zipcode = zip;
    } else {
      data.zipcode = '';
    }

    if (stateShort && stateShort.length) {
      data.state = stateShort.toUpperCase();
    } else {
      data.state = '';
    }

    if (city && city.length) {
      data.city = city;
    } else {
      data.city = '';
    }

    const lat = ret.geometry.location.lat();
    const lng = ret.geometry.location.lng();
    data.lat = lat;
    data.lng = lng;
    data.address_line1 = addressLine1;

    form.setFields([
      {
        name: ['doctor_locations', index, 'zipcode'],
        value: data.zipcode
      },
      {
        name: ['doctor_locations', index, 'state'],
        value: data.state
      },
      {
        name: ['doctor_locations', index, 'city'],
        value: data.city
      },
      {
        name: ['doctor_locations', index, 'lat'],
        value: data.lat
      },
      {
        name: ['doctor_locations', index, 'lng'],
        value: data.lng
      },
      {
        name: ['doctor_locations', index, 'address_line1'],
        value: data.address_line1
      }
    ])
  };

  const onChildMouseClick = (key) => {
    setCenter({
      lat: key.lat,
      lng: key.lng,
    });
  };

  return (
    <>
      <div className={styles.inPersonVisit}>
        <div className={styles.inPersonVisitInner}>
          <Form.Item
            name={['doctor_locations', index, 'id']}
            noStyle
          >
            <Input type="hidden"></Input>
          </Form.Item>
          <Form.Item
            label="External In-Person Appointment(25min) Type ID"
            name={['doctor_locations', index, 'in_person_appointment_type_id']}
            rules={[
              { required: false, message: 'External In-Person Appointment(25min) Type ID is required' },
            ]}
          >
            <Input placeholder="External In-Person Appointment(25min) Type ID" />
          </Form.Item>
          <Form.Item
            label="External In-Person Appointment(50min) Type ID"
            name={['doctor_locations', index, 'in_person_appointment_50min_type_id']}
            rules={[
              { required: false, message: 'External In-Person Appointment(50min) Type ID is required' },
            ]}
          >
            <Input placeholder="External In-Person Appointment(50min) Type ID" />
          </Form.Item>
          <Form.Item
            label="External Follow Up In-Person Appointment Type ID"
            name={['doctor_locations', index, 'in_person_follow_up_appointment_type_id']}
            rules={[
              {
                required: false,
                message: 'External Follow Up In-Person Appointment Type ID is required',
              },
            ]}
          >
            <Input placeholder="External Follow Up In-Person Appointment Type ID" />
          </Form.Item>

          <Form.Item
            label="Address Line 1"
            name={ ['doctor_locations', index, 'address_line1'] }
            rules={[{ required: support_in_person, message: 'Address Line 1 is required' }]}
          >
            <GeoSuggestAutoComplete
              onAddressSelect={(v) => {
                var data = {};
                if (v.zip && v.zip.length) {
                  data.zipcode = v.zip;
                } else {
                  data.zipcode = '';
                }

                if (v.stateShort && v.stateShort.length) {
                  data.state = v.stateShort.toUpperCase();
                } else {
                  data.state = '';
                }

                if (v.city && v.city.length) {
                  data.city = v.city;
                } else {
                  data.city = '';
                }

                data.lat = v.lat;
                data.lng = v.lng;

                setMarker({ ...marker, position: { lat: data.lat, lng: data.lng } });
                setCenter({ lat: data.lat, lng: data.lng });

                if (Object.keys(data).length) {
                  form.setFields([
                    {
                      name: ['doctor_locations', index, 'zipcode'],
                      value: data.zipcode
                    },
                    {
                      name: ['doctor_locations', index, 'state'],
                      value: data.state
                    },
                    {
                      name: ['doctor_locations', index, 'city'],
                      value: data.city
                    },
                    {
                      name: ['doctor_locations', index, 'lat'],
                      value: data.lat
                    },
                    {
                      name: ['doctor_locations', index, 'lng'],
                      value: data.lng
                    },
                    {
                      name: ['doctor_locations', index, 'address_line1'],
                      value: data.address_line1
                    } 
                  ])
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Address Line 2"
            name={ ['doctor_locations', index, 'address_line2'] }
            rules={[{ required: false, message: 'Address Line2 is required' }]}
          >
            <Input placeholder="Address Line 2" />
          </Form.Item>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item
                label="State"
                name={ ['doctor_locations', index, 'state'] }
                rules={[{ required: support_in_person, message: 'City is required' }]}
              >
                <Select placeholder="State">{stateOpts}</Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="City"
                name={ ['doctor_locations', index, 'city'] }
                rules={[{ required: support_in_person, message: 'City is required' }]}
              >
                <Input placeholder="City" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Zip Code"
                name={ ['doctor_locations', index, 'zipcode'] }
                rules={[
                  { required: support_in_person, message: 'Zipcode is required' },
                  // { pattern: '^[0-9]{5}$', message: 'Invalid Zipcode' },
                ]}
              >
                <Input placeholder="Zip Code" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Lat"
                name={ ['doctor_locations', index, 'lat'] }
                rules={[{ required: false, message: 'lat is required' }]}
              >
                <Input disabled placeholder="Lat" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Lng"
                name={ ['doctor_locations', index, 'lng'] }
                rules={[{ required: false, message: 'Lng is required' }]}
              >
                <Input disabled placeholder="Lng" />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ height: '400px', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: props.apiKey, libraries: ['places'] }}
              draggable={draggable}
              rotateControl={true}
              scrollwheel={false}
              yesIWantToUseGoogleMapApiInternals
              center={center}
              defaultZoom={props.zoom}
              onChildMouseDown={onDragMarker}
              onChildMouseUp={onDragendMarker}
              onChildMouseMove={onDragMarker}
              onClick={onChildMouseClick}
            >
              <Marker lat={marker.position.lat} lng={marker.position.lng} text="My Marker" />
            </GoogleMapReact>
          </div>
          <Form.Item
            label="Navigation Instructions"
            name={ ['doctor_locations', index, 'navigation_instruction'] }
            rules={[{ required: support_in_person, message: 'In-Person Visit Location is required' }]}
            style={{padding: '15px 0'}}
          >
            <Input.TextArea rows={4} placeholder="Navigation Instructions" maxLength={1500} />
          </Form.Item>
          <Form.Item
            label="Arrival Instructions"
            name={ ['doctor_locations', index, 'arrival_instructions'] }
            rules={[{ required: false, message: 'In-Person Arrival Instructions is required' }]}
            style={{padding: '15px 0'}}
          >
            <Input.TextArea rows={4} placeholder="Arrival Instructions" maxLength={1500} />
          </Form.Item>
          <Form.Item
            label="Check-in Instructions"
            name={ ['doctor_locations', index, 'check_in_instructions'] }
            rules={[{ required: false, message: 'In-Person Check in instructions is required' }]}
            style={{padding: '15px 0'}}
          >
            <Input.TextArea rows={4} placeholder="Check-in Instructions" maxLength={1500} />
          </Form.Item>
        </div>
        <label>In-Person location images:</label>
        <br/>
        <br/>
        <Row>


          <Col span={6}>
            <label>1st location image</label>
            <ImgCrop rotationSlider showGrid>
              <Upload
                name="location_image1"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={(options) => {
                  uploadLocationImage(options, 'Location_image_1', firstLocationImageURL)
                }}
                beforeUpload={beforeUpload}
                onChange={(v) => {
                  handleLocationChange(v, 1)

                }}
                onPreview={onPreview}
              >
                {firstLocationImageURL ? <img src={firstLocationImageURL} alt="1st in-person location image" style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            </ImgCrop>
            {deleteButton(firstLocationImageURL, 'Location_image_1')}
          </Col>
          <Col span={6}>
            <label>2nd location image</label>
            <ImgCrop rotationSlider showGrid>
              <Upload
                name="location_image2"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={(options) => {
                  uploadLocationImage(options, 'Location_image_2', secondLocationImageURL)
                }}
                beforeUpload={beforeUpload}
                onChange={(v) => {
                  handleLocationChange(v, 2)

                }}
                onPreview={onPreview}
              >
                {secondLocationImageURL ? <img src={secondLocationImageURL} alt="2nd in-person location image" style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            </ImgCrop>
            {deleteButton(secondLocationImageURL, 'Location_image_2')}
          </Col>
          <Col span={6}>
            <label>3rd location image</label>
            <ImgCrop rotationSlider showGrid>
              <Upload
                name="location_image3"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={(options) => {
                  uploadLocationImage(options, 'Location_image_3', thirdLocationImageURL)
                }}
                beforeUpload={beforeUpload}
                onChange={(v) => {
                  handleLocationChange(v, 3)

                }}
                onPreview={onPreview}
              >
                {thirdLocationImageURL ? <img src={thirdLocationImageURL} alt="3rd in-person location image" style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            </ImgCrop>
            {deleteButton(thirdLocationImageURL, 'Location_image_3')}
          </Col>
          <Col span={6}>
            <label>4th location image</label>
            <ImgCrop rotationSlider showGrid>
              <Upload
                name="location_image4"
                listType="picture-card"
                className="location_image4"
                showUploadList={false}
                customRequest={(options) => {
                  uploadLocationImage(options, 'Location_image_4', fourthLocationImageURL)
                }}
                beforeUpload={beforeUpload}
                onChange={(v) => {
                  handleLocationChange(v, 4)

                }}
                onPreview={onPreview}
              >
                {fourthLocationImageURL ? <img src={fourthLocationImageURL} alt="4th in-person location image" style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            </ImgCrop>
            {deleteButton(fourthLocationImageURL, 'Location_image_4' )}
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={4}>
            <Form.Item
              label="1st Link Name"
              name={ ['doctor_locations', index, 'useful_link1_name'] }
            >
              <Input placeholder="1st Link Name" maxLength={50}/>
            </Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item
              label="1st Link URL"
              name={ ['doctor_locations', index, 'useful_link1_url'] }
            >
              <Input placeholder="1st URL, URL must start with http:// or https://" maxLength={100}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item
              label="2nd Link Name"
              name={ ['doctor_locations', index, 'useful_link2_name'] }
            >
              <Input placeholder="2nd Link Name" maxLength={50}/>
            </Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item
              label="2nd Link URL"
              name={ ['doctor_locations', index, 'useful_link2_url'] }
            >
              <Input placeholder="2nd URL, URL must start with http:// or https://" maxLength={100}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item
              label="3rd Link Name"
              name={ ['doctor_locations', index, 'useful_link3_name'] }
            >
              <Input placeholder="3rd Link Name" maxLength={50}/>
            </Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item
              label="3rd Link URL"
              name={ ['doctor_locations', index, 'useful_link3_url'] }
            >
              <Input placeholder="3rd URL, URL must start with http:// or https://" maxLength={100}/>
            </Form.Item>
          </Col>
        </Row>


        { deleteable && <>
          <Button danger icon={<CloseCircleFilled />} onClick={()=>{ remove(index) }}>
            Delete this location
          </Button>
        </>}
      </div>
    </>
  )
}

DoctorLocation.defaultProps = {
  apiKey: 'AIzaSyALFmDoVNb5DrQbvRkAUghtQw1dvqZgg6Q',
  center: {
    lat: 34.0522,
    lng: -118.2437,
  },
  zoom: 12,
};

export default DoctorLocation;
