import React, { useState, useEffect } from 'react';
import { connect } from 'umi';
import { useRequest,useMount } from 'ahooks';
import { Button, Modal, Space, Table, message, Dropdown, Input, Card,Tooltip } from 'antd';
import { ExclamationCircleOutlined, EllipsisOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Filters from '@/components/Filters';
import GoogleMapReact from 'google-map-react';
import { SearchOutlined } from '@ant-design/icons';
import cn from 'classnames/bind';
import styles from './PharmacyManagement.less'
import EditRxntPharmacyInfo from './EditRxntPharmacyInfo';
import { srvSaveRxntPharmacyInfo,srvStates } from '@/services/admin';
const cx = cn.bind(styles);

const PharmacyManagement = (props) => {
  const {
    dispatch,
    pharmacy_management: { data },
    loading,
    active,
  } = props;
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [pharmacy, setPharmacy] = useState({});
  const [draggable, setDraggable] = useState(true);
  const [anterQuery, setAnterQuery] = useState('');
  const [editPharmacyDrawerVisible,setEditPharmacyDrawerVisible]=useState(false);
  const [center, setCenter] = useState({
    lat: 34.0522,
    lng: -118.2437,
  });
  const [selectedPharmacy, setSelectedPharmacy] = useState(null);
  const [dropdownVisibleMap, setDropdownVisibleMap] = useState({});
  const [states, setStates] = useState([]);
  const filters = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
    },
    {
      name: 'city',
      label: 'City',
      type: 'text',
    },
    {
      name: 'state',
      label: 'State',
      type: 'text',
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'text',
    },
    {
      name: 'refill_status',
      label: 'Refill Status',
      type: 'checkbox',
      items: [
        { label: 'Successfully Refilled', value: 1 },
      ],
    },
    {
      name: 'delivery_support',
      label: 'Delivery Support',
      type: 'select',
      items: [],
    },
    {
      name: 'recommended_pharmacy',
      label: 'Recommended Pharmacy',
      type: 'checkbox',
      items: [
        { label: 'Yes', value: 1 },
        { label: 'No', value: 0 },
      ],
    },
  ];

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: (v, r) => (
        <Tooltip title={r.name} placement="top">
          <div style={{
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
          }}>
            {r.name}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'ADDRESS',
      dataIndex: 'address1',
      key: 'address1',
      render: (v, r) => (
        <Tooltip title={r.address1} placement="top">
          <div style={{
            paddingRight:'10px',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
          }}>
            {r.address1}
          </div>
        </Tooltip>
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 50,
      render: (v, r) => {
        return (
          <Dropdown
            overlay={
              <div className={cx({ doctorOpt: true })}>
                <div className={cx({ doctorOptItem: true })} onClick={() => {
                  setPharmacy(r);
                  setEditPharmacyDrawerVisible(true);
                  handleDropdownVisibleChange(r.id, false);
                }}>
                  <Button type='primary'>Edit</Button>
                </div>
              </div>
            }
            visible={dropdownVisibleMap[r.id] || false}
            onVisibleChange={(visible) => handleDropdownVisibleChange(r.id, visible)}
            trigger={['click']}
          >
            <Button size="small">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];
  const handleDropdownVisibleChange = (id, visible) => {
    setDropdownVisibleMap((prev) => ({
      ...prev,
      [id]: visible, 
    }));
  };
  const searchPharmacy = () => {
    const params = {
      page: 1,
      page_size: 10,
      filters: JSON.stringify({
        ...query,
        search: anterQuery, 
      }),
    };
    loadList(params, () => { });
  }
  const loadList = (payload, callback) => {
    dispatch({ type: 'pharmacy_management/fetchList', payload, callback });
  };

  useMount(async () => {
    await statesReq.runAsync();
  });

  useEffect(() => {
    if (active) {
      setPage(1);
      const params = {
        page: 1,
        page_size: 10,
        filters: JSON.stringify({
          ...query,
          search: anterQuery, 
        }),
      };
      loadList(params, () => { });
    }
  }, [active]);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    const params = {
      page: 1,
      page_size: 10,
      filters: JSON.stringify({
        ...q,
        search: anterQuery, 
      }),
    };
    loadList(params, () => { });
  };
  const handleMapClick=(key)=>{
    setSelectedPharmacy(null);
    setCenter({
      lat: key.lat,
      lng: key.lng,
    });
  }
  const onInputChange = (event) => {
    setAnterQuery(event.target.value);
  };
  const handleMarkerClick = (pharmacy) => {
    setCenter({
      lat: pharmacy.latitude,
      lng: pharmacy.longitude,
    });
    setSelectedPharmacy(pharmacy); 
  };
  const saveRxntPharmacyInfoReq = useRequest(srvSaveRxntPharmacyInfo, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        message.success('successfully save');
        const params = {
          page: page,
          page_size: 10,
          filters: JSON.stringify({
            ...query,
            search: anterQuery, 
          }),
        };
        loadList(params, () => { });
        setEditPharmacyDrawerVisible(false);
        if(selectedPharmacy){
          setSelectedPharmacy(null);
        }
      } else {
        message.error(ret.msg);
      }
    },
  });

  const saveRxntPharmacyInfo = (v, cb) => {
    if (v && Object.keys(v).length > 0) {
      saveRxntPharmacyInfoReq.runAsync(v).then(cb);
    }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    const params = {
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: JSON.stringify({
        ...query,
        search: anterQuery, 
      }),
    };
    loadList(params, () => { });
  };
  const LocationIcon = () => (
    <img
      src={require('./../../assets/address.png')}
      alt="Location"
      style={{ width: '30px', height: '30px', cursor: 'pointer',color:'blue' }}
    />
  );
  const InfoWindowTitle = ({ name }) => {
    return <h3 style={{ fontWeight: 'bold' }}>{name}</h3>;
  }
  const statesReq = useRequest(srvStates, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setStates(ret.data);
      }
    },
  });
  const f = () => {
    for (const v of filters) {
    if (v.name === 'delivery_support' && v.items.length === 0) {
        v.items = states.map((s) => ({ ...s, label: s.state, value: s.state }));
      }
    }
    return filters;
  };

  const InfoWindow = ({ pharmacy, onClose }) => (
    <Card
      style={{ width: '400px', borderRadius: '8px', position: 'fixed', boxShadow: '0 4px 12px rgba(0,0,0,0.2)', zIndex: 1000 }}
    >
      <CloseCircleOutlined
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          fontSize: '18px',
          color: '#999',
          cursor: 'pointer',
        }}
        onClick={(event) => {
          event.stopPropagation();
          onClose();
        }}
      />
      <Card.Meta
        title={<InfoWindowTitle name={pharmacy.name} />}
        onClose
        description={
          <div>
            <div style={{ marginBottom: 16, marginTop: 20 }}>
              <div>Address</div>
              <div style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.8)' }}>{pharmacy.address1}</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
              <div>
                <div>City</div>
                <div style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.8)' }}>{pharmacy.city}</div>
              </div>
              <div>
                <div>State</div>
                <div style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.8)' }}>{pharmacy.state}</div>
              </div>
              <div>
                <div>Zipcode</div>
                <div style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.8)' }}>{pharmacy.zipcode}</div>
              </div>
            </div>
            <div style={{ display: 'flex', gap: 16, marginBottom: 16}}>
              <div>
                <div>Phone</div>
                <div style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.8)' }}>{pharmacy.phone}</div>
              </div>
              <div style={{marginLeft:'130px'}}>
                <div>Fax</div>
                <div style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.8)' }}>{pharmacy.fax}</div>
              </div>
            </div>
            <div style={{ marginBottom: 16, justifyContent: 'flex-start' }}>
              <div>Website</div>
              <div style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.8)' }}>{pharmacy.website}</div>
            </div>
            <div style={{ marginBottom: 16 }}>
              <div>Note</div>
              <div style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.8)' }}>{pharmacy.note}</div>
            </div>
            <div style={{ marginBottom: 30 }}>
              <div>Other Information</div>
              <div style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.8)' }}>{pharmacy.is_accepts_insurance ? 'Insurance accepted' : ''}</div>
              <div style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.8)' }}>{pharmacy.is_delivery_available ? 'Delivery available: ' + pharmacy.delivery_fee : ''}</div>
              <div style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.8)' }}>{pharmacy.delivery_wide_states ? 'State wide delivery: '+pharmacy.delivery_wide_states : ''}</div>
              <div style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.8)' }}>{pharmacy.successful_refill_prescription ? 'Successfully Refilled' : ''}</div>
              <div style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.8)' }}>{pharmacy.is_only_cash ? 'Cash only' : ''}</div>
            </div>
            <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="primary" style={{ width: '180px', borderRadius: '4px', height: '32px' }}
                onClick={(event) => {
                  event.stopPropagation();
                  setPharmacy(pharmacy);
                  setEditPharmacyDrawerVisible(true);
                }}
              >Edit</Button>
            </div>
          </div>
        }
      />
    </Card>
  );

  return (
    <div className={cx({ pharmacyContainer: true })}>
      <div className={cx({ header: true })}>
        <h3>Pharmacy Management</h3>
        <Space>
          <Filters filters={f()} onFilter={onFilter} />
        </Space>
      </div>
      <div className={cx({ content: true })}>
        <div className={cx({ tableContainer: true })}>
          <Input.Search
            placeholder='Search'
            onChange={onInputChange}
            onPressEnter={() => searchPharmacy()}
            enterButton={
              <Button icon={<SearchOutlined onClick={() => searchPharmacy()} style={{ verticalAlign: 0 }} />} />
            }
            style={{ flex: 0.4}}
            defaultValue={anterQuery}
          />
          <Table
            className="pharmacyRowSelectTable"
            columns={columns}
            dataSource={data.items}
            rowKey="id"
            loading={loading.effects['pharmacy_recommended/fetchList']}
            onChange={handleTableChange}
            pagination={{
              pageSize: 10,
              current: page,
              simple: true,
              total: data.total,
            }}
            onRow={(record) => ({
              onClick: (evt) => {
                handleMarkerClick(record);
              },
            })}
          /></div>
        <div className={cx({ mapContainer: true })}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: `${REACT_APP_GOOGLE_MAPS_API_KEY}`, libraries: ['places'] }}
            draggable={draggable}
            rotateControl={true}
            scrollwheel={false}
            yesIWantToUseGoogleMapApiInternals
            center={center}
            onClick={handleMapClick}
            defaultZoom={12}
          >
            {data.items
              .filter(pharmacy => pharmacy.latitude && pharmacy.longitude)
              .map(pharmacy => (
                <div
                  key={pharmacy.id}
                  lat={pharmacy.latitude}
                  lng={pharmacy.longitude}
                  onClick={() => handleMarkerClick(pharmacy)}
                >
                  <LocationIcon color="blue" />
                  {selectedPharmacy && selectedPharmacy.id === pharmacy.id && (
                    <InfoWindow
                      pharmacy={selectedPharmacy}
                      onClose={() => setSelectedPharmacy(null)}
                    />
                  )}
                </div>
              ))}
          </GoogleMapReact>
        </div>
      </div>
      {editPharmacyDrawerVisible && (
        <EditRxntPharmacyInfo
          visible={editPharmacyDrawerVisible}
          loading={saveRxntPharmacyInfoReq.loading}
          onFinish={saveRxntPharmacyInfo}
          onClose={() => {
            setEditPharmacyDrawerVisible(false);
          }}
          initialValues={pharmacy}
        />
      )}
    </div>
  );
};

export default connect(({ pharmacy_management, loading }) => ({
  pharmacy_management,
  loading,
}))(PharmacyManagement);
