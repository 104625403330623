import React, { useState, useEffect, useRef } from 'react';
import { connect, Link, history, useRouteMatch, useParams } from 'umi';

import Home from './home';
import Notes from './notes';
import History from './history';


const EHRChartAudit = () => {

  const params = useParams();
  const [currentPage, setCurrentPage] = useState('home');

  useEffect(() => {
    if (params && params.id) {
      setCurrentPage(params.id);
    }
  }, [params]);

  const renderContent = () => {
    if (currentPage == 'notes') {
      return <Notes />
    } else if (currentPage == 'history') {
      return <History />
    } else {
      return <Home />
    }
  } 

  return (
    <>
      {renderContent()}
    </>
  )
}

export default EHRChartAudit;
