import React, { useEffect } from 'react';
import { Divider, Space, Typography, Tooltip } from 'antd';
import {
  ProFormDateTimeRangePicker,
  ProFormText,
  ProFormSelect,
  ProFormCheckbox,
  ProFormRadio,
  ProFormTextArea,
  ProFormDependency,
  ProFormDatePicker,
  ProFormDigit,
} from '@ant-design/pro-components';
import { QuestionCircleOutlined } from '@ant-design/icons';
import CPTCodesFormItem from '@/components/CPTCodesFormItem';
import NextFollowUpDateFormItem from '@/components/NextFollowUpDateFormItem';
import LastRefillDateFormItem from '@/components/LastRefiiDateFormItem';
import { useNoteStore, useUserStore, usePatientStore } from '@/stores';
import {
  PRE_CONSULTATION_REVIEW,
  INATTENTIVENESS,
  HYPERACTIVITY_IMPULSIVITY,
  SUBSTANCE_USE,
  PSYCHIATRIC_HISTORY,
  REPORTED_MEDICAL_CONDITIONS,
  FAMILY_PLANNING,
  MOOD,
} from '@/constants';
import { initialCompletedOpts } from '../meta';

const { Title } = Typography;

const MENTAL_STATUS_EXAM = [
  {
    title: 'Observation',
    items: [
      {
        name: 'observation_appearance',
        label: 'Appearance',
        options: ['Neat', 'Disheveled', 'Inappropriate', 'Bizarre', 'Other'],
        defaultValue: 'Neat',
      },
      {
        name: 'observation_speech',
        label: 'Speech',
        options: ['Normal', 'Tangential', 'Impoverished', 'Pressured', 'Other'],
        defaultValue: 'Normal',
      },
      {
        name: 'observation_eye_contact',
        label: 'Eye Contact',
        options: ['Normal', 'Intense', 'Avoidant', 'Other'],
        defaultValue: 'Normal',
      },
      {
        name: 'observation_motor_activity',
        label: 'Motor Activity',
        options: ['Normal', 'Restless', 'Tics', 'Slowed', 'Other'],
        defaultValue: 'Normal',
      },
      {
        name: 'observation_affect',
        label: 'Affect',
        options: ['Full', 'Constricted', 'Labile', 'Flat', 'Other'],
        defaultValue: 'Full',
      },
    ],
  },
  {
    title: 'Cognition',
    items: [
      {
        name: 'cognition_orientation_impair',
        label: 'Orientation Impairment',
        options: ['None', 'Person', 'Place', 'Time', 'Other'],
        defaultValue: 'None',
      },
      {
        name: 'cognition_memory_impair',
        label: 'Memory Impairment',
        options: ['Normal', 'Short-Term', 'Long-Term', 'Other'],
        defaultValue: 'Normal',
      },
      {
        name: 'cognition_attention',
        label: 'Attention',
        options: ['Normal', 'Distracted', 'Other'],
        defaultValue: 'Normal',
      },
    ],
  },
  {
    title: 'Perception',
    items: [
      {
        name: 'perception_hallucinations',
        label: 'Hallucinations',
        options: ['Normal', 'Auditory', 'Visual', 'Other'],
        defaultValue: 'Normal',
      },
      {
        name: 'perception_other',
        label: 'Other',
        options: ['None', 'Derealization', 'Depersonalization'],
        defaultValue: 'None',
      },
    ],
  },
  {
    title: 'Thoughts',
    items: [
      {
        name: 'thoughts_suicidality',
        label: 'Suicidality',
        options: ['None', 'Ideation', 'Plan', 'Intent', 'Self-Harm'],
        defaultValue: 'None',
      },
      {
        name: 'thoughts_homicidality',
        label: 'Homicidality',
        options: ['None', 'Agressive', 'Intent', 'Plan'],
        defaultValue: 'None',
      },
      {
        name: 'thoughts_delusions',
        label: 'Delusions',
        options: ['None', 'Grandiose', 'Paranoid', 'Religious', 'Plan'],
        defaultValue: 'None',
      },
    ],
  },
  {
    title: 'Clinical Assessment',
    items: [
      {
        name: 'behavior',
        label: 'Behavior',
        options: [
          'Cooperative',
          'Shy/Guarded',
          'Hyperactive',
          'Agitated',
          'Aggressive',
          'Paranoid',
          'Bizarre',
          'Withdrawn',
        ],
        defaultValue: 'Cooperative',
      },
      {
        name: 'insight',
        label: 'Insight',
        options: ['Good', 'Fair', 'Poor'],
        defaultValue: 'Good',
      },
      {
        name: 'judgement',
        label: 'Judgement',
        options: ['Good', 'Fair', 'Poor'],
        defaultValue: 'Good',
      },
    ],
  },
];

export default function InitialNote(props) {
  const { form } = props;
  const { patient } = usePatientStore();
  return (
    <>
      <ProFormDateTimeRangePicker
        name={['struct_note_data', 'appointment_time_range']}
        label="Appointment Time Range"
        format="MM/DD/YYYY hh:mm A"
        placeholder={['Start Time', 'End Time']}
      />
      <Title level={5}>Pre-consultation Review</Title>
      <Space direction="vertical">
        {PRE_CONSULTATION_REVIEW.map((item, index) => {
          return (
            <ProFormCheckbox key={item.value} name={item.value} valuePropName="checked" noStyle>
              {item.label}
            </ProFormCheckbox>
          );
        })}
      </Space>
      <ProFormRadio.Group
        name="patient_review_no_concerns"
        label="Patient Review"
        options={[
          {
            label: 'Reviewed with no Concerns',
            value: true,
          },
          {
            label: 'Reviewed with Concerns',
            value: false,
          },
        ]}
      />
      <ProFormDependency name={['patient_review_no_concerns']}>
        {({ patient_review_no_concerns }) => {
          if (!patient_review_no_concerns) {
            return (
              <ProFormTextArea
                name="patient_review_concerns"
                placeholder="Concerns about patient"
              />
            );
          }
          return null;
        }}
      </ProFormDependency>
      <ProFormTextArea
        name="chief_complaint"
        label="Chief Complaint"
        maxLength={1000}
        placeholder=""
      />
      <ProFormTextArea
        name="history_of_present_illness"
        label="History of Present Illness"
        maxLength={1000}
        placeholder=""
      />
      <Divider />
      <Space align="baseline">
        <Title level={5}>Presenting Psychiatric Symptoms</Title>
        <Tooltip
          placement="top"
          title="Select the items that met the patient's conditions and add more detail notes"
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </Space>
      <ProFormCheckbox name="adhd" valuePropName="checked">
        ADHD
      </ProFormCheckbox>
      <ProFormDependency name={['adhd']}>
        {({ adhd }) => {
          if (adhd) {
            return (
              <Space align="start">
                <Space direction="vertical">
                  <label>Inattentiveness</label>
                  {INATTENTIVENESS.map((item) => {
                    return (
                      <ProFormCheckbox
                        key={item.value}
                        name={item.value}
                        valuePropName="checked"
                        noStyle
                      >
                        {item.label}
                      </ProFormCheckbox>
                    );
                  })}
                </Space>
                <Space direction="vertical">
                  <label>Hyperactivity</label>
                  {HYPERACTIVITY_IMPULSIVITY.map((item) => {
                    return (
                      <ProFormCheckbox
                        key={item.value}
                        name={item.value}
                        valuePropName="checked"
                        noStyle
                      >
                        {item.label}
                      </ProFormCheckbox>
                    );
                  })}
                </Space>
              </Space>
            );
          }
          return null;
        }}
      </ProFormDependency>
      <Tooltip placement="top" title="Note down more details and symptoms">
        <QuestionCircleOutlined />
      </Tooltip>
      <ProFormTextArea name="adhd_notes" maxLength={1000} placeholder="" />
      {[
        {
          label: 'Depression',
          name: 'depression',
          inputName: 'depression_write_in',
          tip: 'Note down more details and symptoms. Record whether the patient endorses or denies any suicidal thoughts, suicidal intention or suicidal planning. If the patient endorses it, please refer them to provider leadership for further review.  ',
        },
        {
          label: 'Anxiety',
          name: 'anxiety',
          inputName: 'anxiety_write_in',
          tip: 'Note down more details and symptoms',
        },
        {
          label: 'Mania/Hypomania',
          name: ['struct_note_data', 'mania_hypomania'],
          inputName: 'mania_hypomania_write_in',
          tip: 'Note down more details and symptoms',
        },
      ].map((item) => {
        return (
          <div key={item.inputName}>
            <ProFormCheckbox name={item.name} valuePropName="checked">
              {item.label}
            </ProFormCheckbox>
            <Tooltip placement="top" title={item.tip}>
              <QuestionCircleOutlined />
            </Tooltip>
            <ProFormTextArea
              key={item.name}
              name={item.inputName}
              maxLength={1000}
              placeholder=""
            />
          </div>
        );
      })}
      <Title level={5}>Other Psychiatric Symptoms</Title>
      <Space>
        <ProFormCheckbox name={['struct_note_data', 'panic']} valuePropName="checked">
          Panic
        </ProFormCheckbox>
        <ProFormCheckbox name={['struct_note_data', 'insomnia']} valuePropName="checked">
          Insomnia
        </ProFormCheckbox>
        <ProFormCheckbox
          name={['struct_note_data', 'other_psych_symptoms']}
          valuePropName="checked"
        >
          Other symptoms of concern
        </ProFormCheckbox>
      </Space>
      <ProFormTextArea
        name={['struct_note_data', 'psychiatric_symptoms_write_in']}
        maxLength={1000}
        placeholder=""
      />
      <Title level={5}>Substance Use</Title>

      {SUBSTANCE_USE.map((item) => {
        return (
          <ProFormCheckbox
            key={item.value}
            name={['struct_note_data', item.value]}
            valuePropName="checked"
          >
            {item.label}
          </ProFormCheckbox>
        );
      })}
      <ProFormTextArea
        name={['struct_note_data', 'substance_abuse_write_in']}
        maxLength={1000}
        placeholder=""
      />
      <Title level={5}>Psychiatric History</Title>
      {PSYCHIATRIC_HISTORY.map((item) => {
        return (
          <ProFormCheckbox key={item.value} name={item.value} valuePropName="checked">
            {item.label}
          </ProFormCheckbox>
        );
      })}
      <ProFormTextArea name="psychiatric_history_write_in" maxLength={1000} placeholder="" />
      <ProFormRadio.Group
        name={['struct_note_data', 'patient_endorse_psych_history']}
        label="Patient endorses or denies any history of, or current concerns relating to: trauma or abuse, intrusive thoughts, paranoid or delusional thoughts, psychosis, A/V hallucinations, cognitive alterations or acute decline in memory, or eating disorder behaviors."
        options={[
          { label: 'Denies', value: false },
          { label: 'Endorses', value: true },
        ]}
      />
      <ProFormText
        name="current_psy_and_ther"
        label="Current Psychiatrist and Therapist"
        maxLength={200}
        placeholder=""
      />
      <ProFormTextArea
        name="prev_medications"
        label={
          <Space align="baseline">
            <h4>Previous Medications</h4>
            <Tooltip
              placement="top"
              title="Please input any medications patient has taken in the past"
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        }
        maxLength={1000}
        placeholder=""
      />
      <ProFormTextArea
        name="current_medications"
        label={
          <Space align="baseline">
            <h4>Current Medications and Supplements</h4>
            <Tooltip
              placement="top"
              title="Please input any medications and or supplements patient is currently taking"
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        }
        maxLength={1000}
        placeholder=""
      />
      <Title level={5}>Medication & Physical History</Title>
      <ProFormRadio.Group
        name="drug_allergy"
        label="Medicine allergy"
        options={[
          { label: 'No known drug allergies', value: false },
          { label: 'Allergic to', value: true },
        ]}
      />
      <ProFormDependency name={['drug_allergy']}>
        {({ drug_allergy }) => {
          if (drug_allergy) {
            return <ProFormTextArea name="drug_allergy_write_in" maxLength={1000} placeholder="" />;
          }
          return null;
        }}
      </ProFormDependency>
      <ProFormText
        name={['struct_note_data', 'current_pcp']}
        label="Current PCP"
        maxLength={200}
        placeholder="Primary Care Provider"
      />
      <ProFormDatePicker
        name={['struct_note_data', 'most_recent_pcp_appointment']}
        label="Last PCP appointment"
        format="MM/DD/YYYY"
        placeholder="MM/DD/YYYY"
      />
      <ProFormDigit
        name="patient_weight"
        label="Weight in pounds"
        placeholder="Weight in pounds"
        step="0.1"
        min="0"
        max="1000"
        stringMode
      />
      <ProFormDigit
        name="patient_height"
        label="Height in inches"
        placeholder="Height in inches"
        min="0"
        max="1000"
        step="0.1"
        stringMode
      />
      <Title level={5}>Reported Medical Conditions</Title>
      <Space wrap>
        {REPORTED_MEDICAL_CONDITIONS.map((item) => {
          return (
            <ProFormCheckbox
              key={item.value}
              name={['struct_note_data', item.value]}
              valuePropName="checked"
            >
              {item.label}
            </ProFormCheckbox>
          );
        })}
      </Space>
      <ProFormDependency name={['struct_note_data', 'other_major_neuro_or_cardio_disorders']}>
        {({ struct_note_data }) => {
          if (struct_note_data?.other_major_neuro_or_cardio_disorders) {
            return (
              <ProFormTextArea
                name="reported_medical_conditions_write_in"
                maxLength={1000}
                placeholder=""
              />
            );
          }
          return null;
        }}
      </ProFormDependency>
      <Title level={5}>
        Patient endorses or denies the presence of other physical medical conditions
      </Title>
      <ProFormRadio.Group
        name={['struct_note_data', 'patient_endorsement']}
        label="Patient endorses or denies other medical conditions"
        options={[
          { label: 'Denies', value: false },
          { label: 'Endorses', value: true },
        ]}
      />
      <ProFormDependency name={['struct_note_data', 'patient_endorsement']}>
        {({ struct_note_data }) => {
          if (struct_note_data?.patient_endorsement) {
            return (
              <ProFormTextArea
                name={['struct_note_data', 'patient_endorsement_detail']}
                maxLength={1000}
                placeholder=""
              />
            );
          }
          return null;
        }}
      </ProFormDependency>

      <Title level={5}>Family Planning</Title>
      {FAMILY_PLANNING.map((item) => {
        return (
          <ProFormCheckbox
            key={item.value}
            name={['struct_note_data', item.value]}
            valuePropName="checked"
          >
            {item.label}
          </ProFormCheckbox>
        );
      })}
      <ProFormTextArea
        name={['struct_note_data', 'family_planning_write_in']}
        maxLength={1000}
        placeholder=""
      />

      <Title level={5}>Social & Family History</Title>
      <ProFormTextArea
        name="social_family_history"
        label="Social & Family History"
        maxLength={1000}
        placeholder=""
      />

      <Title level={5}>Mental Status Exam</Title>
      {MENTAL_STATUS_EXAM.map((section) => (
        <React.Fragment key={section.title}>
          <span>{section.title}</span>
          {section.items.map((item) => (
            <ProFormRadio.Group
              key={item.name}
              name={['struct_note_data', item.name]}
              label={item.label}
              options={item.options.map((opt) => ({ label: opt, value: opt }))}
              initialValue={item.defaultValue}
            />
          ))}
          <Divider />
        </React.Fragment>
      ))}
      <Title level={5}>Mood</Title>
      <Space wrap>
        {MOOD.map((item) => {
          return (
            <ProFormCheckbox
              key={item.value}
              name={['struct_note_data', item.value]}
              valuePropName="checked"
              noStyle
            >
              {item.label}
            </ProFormCheckbox>
          );
        })}
      </Space>
      <ProFormTextArea
        name={['struct_note_data', 'mood_write_in']}
        maxLength={1000}
        placeholder=""
      />
      <Title level={5}>Diagnosis</Title>
      <ProFormSelect
        mode="multiple"
        name="diagnostic_code"
        label="Diagnosis"
        placeholder="Select Diagnostic Code"
        options={initialCompletedOpts}
        fieldProps={{
          filterOption: (inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1,
        }}
      />
      <ProFormTextArea name="other_diagnosis_notes" maxLength={1000} placeholder="" />

      <CPTCodesFormItem
        form={form}
        patient={patient}
        noteType={'InitialEvaluation'}
        initialValues={{}}
      />
      <Divider />
      <Title level={5}>Treatment & Prescription</Title>
      <LastRefillDateFormItem
        form={form}
        patient={patient}
        noteType={'InitialEvaluation'}
        initialValues={{}}
      />
      <ProFormText label="Rx" name="rx" maxLength={1000} placeholder="" />
      <ProFormCheckbox name="no_controlled_substance_needed" valuePropName="checked">
        &nbsp;Patient does not need controlled substances (in-person evaluation/referral not
        required).
      </ProFormCheckbox>
      <ProFormTextArea
        name="provider_notes"
        labelCol={
          <Space align="baseline">
            <h4>Treatment Notes</h4>
            <Tooltip placement="top" title="Edit the template below with your patient's details">
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        }
        rows={8}
        maxLength={5000}
        placeholder=""
      />
      <ProFormCheckbox
        name="patient_agree_treatment_plan"
        label="Patient Agree"
        valuePropName="checked"
      >
        Patient agrees to start the treatment plan
      </ProFormCheckbox>
      <NextFollowUpDateFormItem form={form} patient={patient} />
      <Divider />
      <Space align="baseline">
        <h3>First Message to Patient</h3>
        <Tooltip placement="top" title="Edit the template below with your patient's details">
          <QuestionCircleOutlined />
        </Tooltip>
      </Space>
      <ProFormTextArea
        label="Message To Patient"
        name="message_to_patient"
        rows={8}
        maxLength={20000}
      />
      <ProFormCheckbox
        name="clinician_accuracy_testament"
        valuePropName="checked"
        label="Clinician Accuracy Testament"
        rules={[
          {
            required: true,
            message: 'Treatment notes is required',
          },
        ]}
      >
        I attest to the accuracy and completeness of this note.
      </ProFormCheckbox>
    </>
  );
}
