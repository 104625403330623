import React, { useEffect } from 'react';
import cn from 'classnames/bind';
import { connect, history,useAccess } from 'umi';
import { Tabs } from 'antd';

import PatientInfo from '@/components/PatientInfoPane/PatientInfo';
import PatientTimeline from '@/components/Timeline/PatientTimeline';
import Appointments from './appointments';
import Consultations from './consultations';
import Payments from './payments';
import TreatmentPlans from './treatment_plans';
import TreatmentRequests from './treatment_requests';
import Memberships from './memberships';
import Order from './Order';
import VoicesPane from '@/pages/ehr_voice/index';

import styles from './index.less';
const { TabPane } = Tabs;
const cx = cn.bind(styles);

const AdminPatientDetail = (props) => {
  const { dispatch, patient_detail, loading, parentProps } = props;
  const { detail } = patient_detail;
  const id = parentProps.match.params.id;
  const access=useAccess();
  useEffect(() => {
    dispatch({ type: 'patient_detail/fetchData', payload: { id }, callback: () => {} });
  }, [id]);

  const loadPatientIdentity = (payload, callback) => {
    dispatch({ type: 'patient_detail/fetchPatientIdentity', payload: { id }, callback });
  };

  const sendReply = (payload, callback) => {
    dispatch({ type: 'patient_detail/replyConsultation', payload, callback });
  };

  const onTabChange = (v) => {
    let path = parentProps.route.path;
    if (parentProps.route.path.indexOf(':id') != -1) {
      path = parentProps.route.path.replaceAll(':id', id);
    } else {
      path = `${parentProps.route.path}/${id}`;
    }
    if (parentProps.location.query.admin){
      history.push({
        pathname: path,
        query: {
          tab: v,
          admin: true,
        },
      });
      return
    }
    history.push({
      pathname: path,
      query: {
        tab: v,
      },
    });
  };

  const activeKey =
    parentProps.history.location.query.tab && parentProps.history.location.query.tab.length
      ? parentProps.history.location.query.tab + ''
      : '1';

  return (
    <div className={cx({ container: true })}>
      <Tabs defaultActiveKey="1" activeKey={activeKey} onTabClick={onTabChange}>
        <TabPane tab="Timeline" key="8">
          <PatientTimeline admin={true} id={id} active={activeKey === '8'} />
        </TabPane>
        <TabPane tab="Patient Chart" key="1">
          <PatientInfo admin={!(history.location.query.admin && access.adminAccesser) ? true : false} id={id} active={activeKey === '1'} />
        </TabPane>
        <TabPane tab="Clinician Notes" key="2">
          <TreatmentPlans
            id={id}
            active={activeKey === '2'}
            loadTreatmentPlanHistories={(payload, callback) => {
              const filters = payload.filters ? payload.filters : '{}';
              let filtersObj = JSON.parse(filters);
              filtersObj = { ...filtersObj, user_id: id };
              payload.filters = JSON.stringify(filtersObj);
              dispatch({
                type: 'patient_detail/fetchTreatmentPlanHistories',
                payload,
                callback,
              });
            }}
            loadTreatmentPlanTypes={(payload, callback) => {
              dispatch({
                type: 'patient_detail/fetchTreatmentPlanTypes',
                payload: { ...payload },
                callback,
              });
            }}
            loadTreatmentPlanStatuses={(payload, callback) => {
              dispatch({
                type: 'patient_detail/fetchTreatmentPlanStatuses',
                payload: { ...payload },
                callback,
              });
            }}
            treatment_plan_histories={patient_detail.treatment_plan_histories}
            treatment_plan_statuses={patient_detail.treatment_plan_statuses}
            treatment_plan_types={patient_detail.treatment_plan_types}
            loadingTreatmentPlanHistories={
              loading.effects['patient_detail/fetchTreatmentPlanHistories']
            }
            loadingHistory={loading.effects['patient_detail/fetchTreatmentPlanHistory']}
            treatmentPlanHistory={patient_detail.treatmentPlanHistory}
            loadTreatmentPlanHistory={(payload, callback) => {
              dispatch({
                type: 'patient_detail/fetchTreatmentPlanHistory',
                payload: { ...payload },
                callback,
              });
            }}
          />
        </TabPane>
        <TabPane tab="Prescription" key="3">
          <TreatmentRequests id={id} active={activeKey === '3'} admin={true} />
        </TabPane>
        <TabPane tab="Appointments" key="4">
          <Appointments
            id={id}
            detail={detail}
            active={activeKey === '4'}
            loadAppointments={(payload, callback) => {
              const filters = payload.filters ? payload.filters : '{}';
              let filtersObj = JSON.parse(filters);
              filtersObj = { ...filtersObj, user_id: id };
              payload.filters = JSON.stringify(filtersObj);
              dispatch({
                type: 'patient_detail/fetchAppointments',
                payload,
                callback,
              });
            }}
            loadAppointmentTypes={(payload, callback) => {
              dispatch({
                type: 'patient_detail/fetchAppointmentTypes',
                payload: { ...payload },
                callback,
              });
            }}
            loadAppointmentStatuses={(payload, callback) => {
              dispatch({
                type: 'patient_detail/fetchAppointmentStatuses',
                payload: { ...payload },
                callback,
              });
            }}
            appointments={patient_detail.appointments}
            appointment_statuses={patient_detail.appointment_statuses}
            appointment_types={patient_detail.appointment_types}
            loadingAppointments={loading.effects['patient_detail/fetchAppointments']}
            rescheduleCalendar={patient_detail.rescheduleCalendar}
            loadingRescheduleAppointment={loading.effects['patient_detail/rescheduleAppointment']}
            loadRescheduleCalendar={(payload, callback) => {
              dispatch({
                type: 'patient_detail/fetchAppointmentRescheduleCalendar',
                payload: { ...payload },
                callback,
              });
            }}
            loadAppointmentItem={(payload, callback) => {
              dispatch({
                type: 'patient_detail/fetchAppointmentItem',
                payload: { ...payload },
                callback,
              });
            }}
            loadingCancelAppointment={loading.effects['patient_detail/cancelAppointment']}
            rescheduleAppointment={(payload, callback) => {
              dispatch({
                type: 'patient_detail/rescheduleAppointment',
                payload: { ...payload },
                callback,
              });
            }}
            cancelAppointment={(payload, callback) => {
              dispatch({
                type: 'patient_detail/cancelAppointment',
                payload: { ...payload },
                callback,
              });
            }}
            markAppointmentProviderNoShow={(payload, callback) => {
              dispatch({
                type: 'patient_detail/markAppointmentProviderNoShow',
                payload: { ...payload },
                callback,
              });
            }}
            changePastAppointmentStatus={(payload, callback) => {
              dispatch({
                type: 'patient_detail/changePastAppointmentStatus',
                payload: { ...payload },
                callback,
              });
            }}
          />
        </TabPane>
        <TabPane tab="Consultations" key="5">
          <Consultations
            id={id}
            active={activeKey === '5'}
            loadConsultations={(payload, callback) => {
              const filters = payload.filters ? payload.filters : '{}';
              let filtersObj = JSON.parse(filters);
              filtersObj = { ...filtersObj, user_id: id };
              payload.filters = JSON.stringify(filtersObj);
              dispatch({
                type: 'patient_detail/fetchConsultations',
                payload,
                callback,
              });
            }}
            loadConsultationTypes={(payload, callback) => {
              dispatch({
                type: 'patient_detail/fetchConsultationTypes',
                payload: { ...payload },
                callback,
              });
            }}
            consultations={patient_detail.consultations}
            consultation_types={patient_detail.consultation_types}
            loadingConsultations={loading.effects['patient_detail/fetchConsultations']}
            replyData={patient_detail.replyData}
            sendReply={sendReply}
            loadReplies={(payload, callback) => {
              dispatch({
                type: 'patient_detail/fetchConsultationReplies',
                payload: { ...payload },
                callback,
              });
            }}
            loadingReplies={loading.effects['patient_detail/fetchConsultationReplies']}
            loadingSendMessage={loading.effects['patient_detail/replyConsultation']}
          />
        </TabPane>
        <TabPane tab="Call Logs" key="10">
          <VoicesPane active={activeKey === '10'} id={id} />
        </TabPane>
        {!(history.location.query.admin && access.adminAccesser)&&(<>
        <TabPane tab="Memberships" key="6">
          <Memberships
            id={id}
            active={activeKey === '6'}
            detail={detail}
            loadMemberships={(payload, callback) => {
              const filters = payload.filters ? payload.filters : '{}';
              let filtersObj = JSON.parse(filters);
              filtersObj = { ...filtersObj, user_id: id };
              payload.filters = JSON.stringify(filtersObj);
              dispatch({
                type: 'patient_detail/fetchMemberships',
                payload,
                callback,
              });
            }}
            loadMembershipExtendHistorys={(payload, callback) => {
              payload.id = id;
              dispatch({
                type: 'patient_detail/fetchMembershipExtendHistorys',
                payload,
                callback,
              });
            }}
            loadMembershipTypes={(payload, callback) => {
              dispatch({
                type: 'patient_detail/fetchMembershipTypes',
                payload: { ...payload },
                callback,
              });
            }}
            loadMembershipStatuses={(payload, callback) => {
              dispatch({
                type: 'patient_detail/fetchMembershipStatuses',
                payload: { ...payload },
                callback,
              });
            }}
            memberships={patient_detail.memberships}
            membershipExtendHistorys={patient_detail.membershipExtendHistorys}
            membership_statuses={patient_detail.membership_statuses}
            membership_types={patient_detail.membership_types}
            loadingMemberships={loading.effects['patient_detail/fetchMemberships']}
          />
        </TabPane>
        <TabPane tab="Payments" key="7">
          <Payments
            id={id}
            active={activeKey === '7'}
            loadPayments={(payload, callback) => {
              const filters = payload.filters ? payload.filters : '{}';
              let filtersObj = JSON.parse(filters);
              filtersObj = { ...filtersObj, user_id: id };
              payload.filters = JSON.stringify(filtersObj);
              dispatch({
                type: 'patient_detail/fetchPayments',
                payload,
                callback,
              });
            }}
            loadPaymentProducts={(payload, callback) => {
              dispatch({
                type: 'patient_detail/fetchPaymentProducts',
                payload: { ...payload },
                callback,
              });
            }}
            loadPaymentStatuses={(payload, callback) => {
              dispatch({
                type: 'patient_detail/fetchPaymentStatuses',
                payload: { ...payload },
                callback,
              });
            }}
            refundPayment={(payload, callback) => {
              dispatch({
                type: 'patient_detail/refundPayment',
                payload: { ...payload },
                callback,
              });
            }}
            payments={patient_detail.payments}
            payment_products={patient_detail.payment_products}
            payment_statuses={patient_detail.payment_statuses}
            loadingPayments={loading.effects['patient_detail/fetchPayments']}
          />
        </TabPane>
        <TabPane tab="Orders" key="9">
          <Order
            id={id}
            payment_statuses={patient_detail.payment_statuses}
            active={activeKey === '9'}
          />
        </TabPane>
        </>)}
      </Tabs>
    </div>
  );
};

export default connect(({ patient_detail, loading }) => ({
  patient_detail,
  loading,
}))(AdminPatientDetail);
