import React, { useState, useEffect } from 'react';
import { Form, Input, Radio, Space, Row, Col } from 'antd';

import { useMount, useUpdateEffect } from 'ahooks';
import TextArea from "antd/es/input/TextArea";



const PatientReviewRadioGroupInput = (props) => {

  const { name, label, form, draft, setDraft, initialValue } = props;
  const [value, setValue] = useState(initialValue);
  const [isRequired, setIsRequired] = useState(false);

  const onRadioGroupChange = (e) => {
    if (e.target.value === 1){
      setIsRequired(true)
    }
    if (e.target.value === 0) {
      setIsRequired(false)
    }
    setValue(e.target.value);

    if (e.target.value === false) {
      const field = { }
      field[name] = undefined;
      form.setFieldsValue(field);
      setDraft(Object.assign({ ...draft }, field));
    }
  }

  const onInputFocus = (e) => {
    setValue(1);
  }

  // draft sync to medicines
  useUpdateEffect(() => {
    if (draft && draft[name]) setValue(0);
  }, [draft]);
  return (
    <Form.Item label={label} name={name} initialValue={initialValue} >
      <Radio.Group onChange={onRadioGroupChange} value={value} inline>
        <Radio value={0}>Reviewed with no Concerns</Radio>
        <Radio value={1}>Reviewed with Concerns</Radio>
        <Form.Item name={"patient_review_concerns"} noStyle rules={[
          {
            required: isRequired,
            message: 'Must specify concerns about patient'
          },
          {
            validator: (_, reviewValue) => {
              if (value === 1 && reviewValue === '') {
                return Promise.reject();
              } else {
                return Promise.resolve()
              }
            }
          }
        ]}>
          <Input.TextArea onFocus={onInputFocus} showCount={true} maxLength={200}/>
        </Form.Item>
      </Radio.Group>
    </Form.Item>

  )
};

export default PatientReviewRadioGroupInput;
