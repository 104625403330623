import React,{useEffect} from 'react';
import { Redirect, connect, useModel, useSelector } from 'umi';
import { stringify } from 'querystring';
import { Modal, Button, Row, Col } from 'antd';
import { useEventEmitter, useMount, useRequest } from 'ahooks';
import FlagProvider, { UnleashClient } from '@unleash/proxy-client-react';

import {
  srvGetDoctorAgreement,
  srvSignDoctorAgreement,
} from '@/services/account';

import PageLoading from '@/components/PageLoading';
import CallWidget from '@/components/CallWidget';
import DoctorAgreementModal from '@/components/DoctorAgreementModal';
import styles from './SecurityLayout.less';
import NewAddNote from '@/components/AddNote/New';
import ChartAuditDraggableWindow from '@/components/DoctorChartAuditDrawer/ChartAuditDraggableWindow';

const config = {
  url: `${UNLEASH_URL}`,
  clientKey: `${UNLEASH_CLIENT_KEY}`,
  refreshInterval: 15,
  appName: 'done-ehr',
  environment: `${UNLEASH_ENV}`,
};
const AccessControl = () => {
  const { currentUser } = useSelector((state) => state.account);
  const {setInitialState}= useModel('@@initialState');
  useEffect(()=>{
    setInitialState(currentUser)
  },[currentUser.id])
  return null;
}
class SecurityLayout extends React.Component {
  state = {
    isReady: false,
    showTermsAndConditions: false,
    termsAndConditionsData: {},
    showDoctorAgreement: false,
    doctorAgreement: {},
    checkedTerms: false
  };

  componentDidMount() {
    this.setState({
      isReady: true,
    });
    const { dispatch } = this.props;

    dispatch({
      type: 'account/fetchCurrent',
      callback: (data) => {
        if (data?.role == 'doctor') {
          dispatch({
            type: 'account/fetchTermsAndConditions',
            callback: (data) => {
              if (data && data.id && data.content) {
                this.setState({
                  showTermsAndConditions: true,
                  termsAndConditionsData: data,
                });
              } else {
                srvGetDoctorAgreement().then((resp)=>{
                  if(resp && resp.data) {
                    this.setState({
                      showDoctorAgreement: true,
                      doctorAgreement: resp.data
                    })
                    console.log('show modal')
                  }
                })
              }
            },
          });
          dispatch({ type: 'account/fetchDoctorStat', payload: { id: data.id } });
        } else if (data?.role == 'admin') {
          dispatch({ type: 'account/fetchAdminStat' });
        }
      },
    });
  }
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch({ type: 'account/clearInterval' });
  }

  agreeTermsAndConditions() {
    const { dispatch } = this.props;

    dispatch({
      type: 'account/agreeTermsAndConditions',
      payload: { id: this.state.termsAndConditionsData.id },
      callback: () => {
        this.setState({
          showTermsAndConditions: false,
        });
      },
    });
  }

  changeTermsChecked(value) {
    this.setState({checkedTerms: !this.state.checkedTerms})
  }

  render() {
    const { dispatch } = this.props;
    const { isReady } = this.state;
    const { children, loading, currentUser } = this.props;
    // You can replace it to your authentication rule (such as check token exists)
    const isLogin = currentUser && currentUser.id;
    const queryString = stringify({
      redirect: window.location.href,
    });

    if ((!isLogin && loading) || !isReady) {
      return <PageLoading />;
    }

    if (!isLogin && window.location.pathname !== '/user/login') {
      return <Redirect to={`/user/login?${queryString}`} />;
    }

    const client = new UnleashClient(config);

    return (
      <FlagProvider unleashClient={client}>
        {children}
        <AccessControl />
        <Modal
          title="We've updated our agreements"
          visible={this.state.showTermsAndConditions}
          closable={false}
          footer={null}
        >
          <div className={styles.termsAndConditionsContent}>
            <div dangerouslySetInnerHTML={{__html: this.state.termsAndConditionsData.content}}  />
          </div>

          <Row>
            <Col flex={3}>
              <label>
                <input type="checkbox" style={{marginRight: '5px'}} checked={this.state.checkedTerms} onChange={()=> { this.changeTermsChecked() }}/>
                I have read and agreed to the above amendment.
              </label>
            </Col>
            <Col flex={2} style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button type="primary" disabled={!this.state.checkedTerms} onClick={()=>{this.agreeTermsAndConditions()}}>I Agree</Button>
            </Col>
          </Row>
        </Modal>
        <CallWidget />
        <DoctorAgreementModal visible={this.state.showDoctorAgreement} doctorAgreement={this.state.doctorAgreement} onOk={()=>{ this.setState({showDoctorAgreement: false })}} />
        <NewAddNote/>
        <ChartAuditDraggableWindow />
      </FlagProvider>
    );
  }
}
export default connect(({ account, loading }) => ({
  currentUser: account.currentUser,
  loading: loading.models.account,
}))(SecurityLayout);
