// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/app/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/user",
    "component": require('/app/src/layouts/UserLayout').default,
    "routes": [
      {
        "name": "login",
        "path": "/user/login",
        "component": require('/app/src/pages/user/login').default,
        "exact": true
      },
      {
        "name": "login",
        "path": "/user/email_login",
        "component": require('/app/src/pages/user/login_email').default,
        "exact": true
      },
      {
        "name": "login",
        "path": "/user/password_login",
        "component": require('/app/src/pages/user/login_password').default,
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": require('/app/src/layouts/SecurityLayout').default,
    "routes": [
      {
        "component": require('/app/src/layouts/BasicLayout').default,
        "authority": [
          "admin",
          "doctor"
        ],
        "routes": [
          {
            "path": "/",
            "component": require('/app/src/pages/home').default,
            "exact": true
          },
          {
            "name": "Home",
            "path": "/home",
            "authority": [
              "doctor"
            ],
            "icon": "icon-home",
            "component": require('/app/src/pages/ehr_home/index').default,
            "exact": true
          },
          {
            "name": "Home",
            "authority": [
              "admin"
            ],
            "icon": "icon-home",
            "path": "/dashboard",
            "component": require('/app/src/pages/dashboard/index').default,
            "exact": true
          },
          {
            "name": "Appointments",
            "authority": [
              "doctor"
            ],
            "icon": "icon-calendar",
            "path": "/ehr_appointment",
            "component": require('/app/src/pages/ehr/index').default,
            "exact": true
          },
          {
            "name": "",
            "authority": [
              "doctor"
            ],
            "icon": "icon-calendar",
            "path": "/ehr_appointment/:id",
            "component": require('/app/src/pages/ehr/index').default,
            "exact": true
          },
          {
            "authority": [
              "doctor"
            ],
            "name": "Prescriptions",
            "icon": "icon-pill1",
            "path": "/ehr_renewal",
            "component": require('/app/src/pages/ehr/index').default,
            "exact": true
          },
          {
            "authority": [
              "doctor"
            ],
            "name": "",
            "icon": "icon-pill1",
            "path": "/ehr_renewal/:id",
            "component": require('/app/src/pages/ehr/index').default,
            "exact": true
          },
          {
            "authority": [
              "doctor"
            ],
            "name": "Consultations",
            "icon": "icon-email",
            "path": "/ehr_consultation",
            "component": require('/app/src/pages/ehr_consultation/index').default,
            "exact": true
          },
          {
            "authority": [
              "doctor"
            ],
            "name": "Medical Files",
            "icon": "icon-email",
            "path": "/ehr_medical_files",
            "component": require('/app/src/pages/ehr_medical_files/index').default,
            "exact": true
          },
          {
            "authority": [
              "patient_flag",
              "doctor"
            ],
            "name": "",
            "icon": "icon-users",
            "path": "/ehr_patient",
            "component": require('/app/src/pages/patient_container/index').default,
            "exact": true
          },
          {
            "authority": [
              "patient_flag",
              "doctor"
            ],
            "name": "",
            "icon": "icon-users",
            "path": "/ehr_patient/:id",
            "component": require('/app/src/pages/patient_container/detail').default,
            "exact": true
          },
          {
            "authority": [
              "patient_flag",
              "doctor"
            ],
            "name": "Patients",
            "icon": "icon-users",
            "path": "/patient",
            "component": require('/app/src/pages/patient_container/index').default,
            "exact": true
          },
          {
            "authority": [
              "doctor"
            ],
            "name": "Overdue Patients",
            "icon": "icon-users",
            "path": "/overdue_patient",
            "component": require('/app/src/pages/patient_container/index').default,
            "exact": true
          },
          {
            "name": "Extra Earning",
            "authority": [
              "doctor"
            ],
            "icon": "icon-message",
            "path": "/ehr_extra_earn",
            "component": require('/app/src/pages/ehr_extra_earn/index').default,
            "exact": true
          },
          {
            "name": "",
            "authority": [
              "doctor"
            ],
            "icon": "icon-message",
            "path": "/ehr_extra_earn/:id",
            "component": require('/app/src/pages/ehr_extra_earn/index').default,
            "exact": true
          },
          {
            "authority": [
              "patient_flag",
              "doctor"
            ],
            "name": "",
            "icon": "icon-users",
            "path": "/patient/:id",
            "component": require('/app/src/pages/patient_container/detail').default,
            "exact": true
          },
          {
            "authority": [
              "patient_flag",
              "doctor"
            ],
            "name": "",
            "icon": "icon-users",
            "path": "/overdue_patient/:id",
            "component": require('/app/src/pages/patient_container/detail').default,
            "exact": true
          },
          {
            "authority": [
              "doctor"
            ],
            "name": "Calendar",
            "icon": "icon-calendar",
            "path": "/ehr_calendar",
            "component": require('/app/src/pages/ehr_calendar/index').default,
            "exact": true
          },
          {
            "authority": [
              "doctor"
            ],
            "name": "",
            "icon": "icon-calendar",
            "path": "/ehr_calendar/add_availability",
            "component": require('/app/src/pages/ehr_calendar/AddAvailability').default,
            "exact": true
          },
          {
            "authority": [
              "doctor"
            ],
            "name": "",
            "icon": "icon-calendar",
            "path": "/ehr_calendar/manage_availability",
            "component": require('/app/src/pages/ehr_calendar/ManageAvailability').default,
            "exact": true
          },
          {
            "authority": [
              "doctor"
            ],
            "name": "Dashboard",
            "icon": "icon-analytics",
            "path": "/ehr_dashboard",
            "component": require('/app/src/pages/ehr_dashboard/index').default,
            "exact": true
          },
          {
            "authority": [
              "doctor"
            ],
            "name": "",
            "icon": "icon-analytics",
            "path": "/ehr_dashboard/:id",
            "component": require('/app/src/pages/ehr_dashboard/index').default,
            "exact": true
          },
          {
            "authority": [
              "doctor"
            ],
            "name": "Tips",
            "icon": "icon-tiptxt",
            "path": "/ehr_tips",
            "component": require('/app/src/pages/ehr_tips/index').default,
            "exact": true
          },
          {
            "authority": [
              "doctor"
            ],
            "name": "RXNT",
            "path": "/ehr_rxnt",
            "component": require('/app/src/pages/ehr_rxnt/index').default,
            "exact": true
          },
          {
            "authority": [
              "chartAuditor"
            ],
            "name": "Chart Audit",
            "icon": "icon-tiptxt",
            "path": "/ehr_chart_audit",
            "component": require('/app/src/pages/ehr_chart_audit/index').default,
            "exact": true
          },
          {
            "name": "",
            "authority": [
              "chartAuditor"
            ],
            "path": "/ehr_chart_audit/:id",
            "component": require('/app/src/pages/ehr_chart_audit/index').default,
            "exact": true
          },
          {
            "authority": [
              "clinicians_flag",
              "adminAccesser"
            ],
            "name": "Clinicians",
            "icon": "icon-users",
            "path": "/doctor",
            "component": require('/app/src/pages/doctor/index').default,
            "exact": true
          },
          {
            "authority": [
              "clinicians_flag"
            ],
            "path": "/doctor/edit/:id",
            "component": require('/app/src/pages/doctor/edit').default,
            "exact": true
          },
          {
            "authority": [
              "clinicians_flag"
            ],
            "path": "/doctor/add",
            "component": require('/app/src/pages/doctor/edit').default,
            "exact": true
          },
          {
            "authority": [
              "clinicians_flag",
              "adminAccesser"
            ],
            "path": "/doctor/detail/:id",
            "component": require('/app/src/pages/doctor/detail').default,
            "exact": true
          },
          {
            "authority": [
              "appointment_flag"
            ],
            "name": "Appointments",
            "icon": "icon-calendar",
            "path": "/appointment",
            "component": require('/app/src/pages/appointment/index').default,
            "exact": true
          },
          {
            "authority": [
              "appointment_flag"
            ],
            "name": "",
            "icon": "icon-calendar",
            "path": "/appointment/:id",
            "component": require('/app/src/pages/appointment/detail').default,
            "exact": true
          },
          {
            "authority": [
              "appt_request_flag"
            ],
            "name": "Appt. Request",
            "icon": "icon-message",
            "path": "/appointment_request",
            "component": require('/app/src/pages/appointment_request/index').default,
            "exact": true
          },
          {
            "authority": [
              "prescriptions_flag"
            ],
            "name": "Prescriptions",
            "icon": "icon-pill1",
            "path": "/renewal/request",
            "component": require('/app/src/pages/renewal/index').default,
            "exact": true
          },
          {
            "authority": [
              "care_team_inbox_flag"
            ],
            "name": "Care Team Inbox",
            "icon": "icon-message",
            "path": "/consultation",
            "component": require('/app/src/pages/consultation/index').default,
            "exact": true
          },
          {
            "authority": [
              "referral_uploads_flag"
            ],
            "name": "Referral Uploads",
            "icon": "icon-message",
            "path": "/referral",
            "component": require('/app/src/pages/referral/index').default,
            "exact": true
          },
          {
            "authority": [
              "medical_files_flag"
            ],
            "name": "Medical Files",
            "icon": "icon-message",
            "path": "/medical_files",
            "component": require('/app/src/pages/medical_files/index').default,
            "exact": true
          },
          {
            "authority": [
              "review_and_approve_flag",
              "approver"
            ],
            "name": "Review and Approve",
            "icon": "icon-calendar",
            "path": "/review_approve",
            "component": require('/app/src/pages/review_approve/index').default,
            "exact": true
          },
          {
            "authority": [
              "rein_application_flag"
            ],
            "name": "Rein. Application",
            "icon": "icon-message",
            "path": "/reinstatement_application",
            "component": require('/app/src/pages/reinstatement/index').default,
            "exact": true
          },
          {
            "authority": [
              "payments_flag"
            ],
            "name": "Payments",
            "icon": "icon-calendar",
            "path": "/payment",
            "component": require('/app/src/pages/payment/index').default,
            "exact": true
          },
          {
            "authority": [
              "surveys_flag"
            ],
            "name": "Surveys",
            "icon": "icon-calendar",
            "path": "/survey",
            "component": require('/app/src/pages/survey/index').default,
            "exact": true
          },
          {
            "authority": [
              "payments_flag"
            ],
            "name": "",
            "icon": "icon-calendar",
            "path": "/payment/:id",
            "component": require('/app/src/pages/payment/detail').default,
            "exact": true
          },
          {
            "authority": [
              "settings_flag",
              "doctor"
            ],
            "name": "",
            "icon": "icon-calendar",
            "path": "/account/settings",
            "component": require('/app/src/pages/user/settings').default,
            "exact": true
          },
          {
            "authority": [
              "rxnt_sync_status_flag"
            ],
            "name": "Rxnt Sync Status",
            "icon": "icon-calendar",
            "path": "/rxnt_status",
            "component": require('/app/src/pages/rxnt/SyncStatus').default,
            "exact": true
          },
          {
            "authority": [
              "settings_flag"
            ],
            "name": "Settings",
            "icon": "icon-calendar",
            "path": "/settings",
            "routes": [
              {
                "name": "Admin",
                "path": "/settings/admin",
                "component": require('/app/src/pages/admin/index').default,
                "exact": true
              },
              {
                "path": "/settings/admin/detail/:id",
                "component": require('/app/src/pages/admin/detail').default,
                "exact": true
              },
              {
                "name": "Areas",
                "path": "/settings/area",
                "component": require('/app/src/pages/area/index').default,
                "exact": true
              },
              {
                "name": "Impersonate Patient",
                "path": "/settings/impersonate_patient",
                "component": require('/app/src/pages/impersonate/patient').default,
                "exact": true
              },
              {
                "name": "Impersonate Clinician",
                "path": "/settings/impersonate_doctor",
                "component": require('/app/src/pages/impersonate/doctor').default,
                "exact": true
              },
              {
                "name": "Pharmacy Special List",
                "path": "/settings/pharmacies",
                "component": require('/app/src/pages/pharmacies/index').default,
                "exact": true
              },
              {
                "name": "Tags",
                "path": "/settings/tags",
                "component": require('/app/src/pages/tags/index').default,
                "exact": true
              }
            ]
          },
          {
            "component": require('/app/src/pages/404').default,
            "exact": true
          }
        ]
      },
      {
        "component": require('/app/src/pages/404').default,
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
