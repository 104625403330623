import React, { useEffect, useState } from 'react';
import { history,Link } from 'umi';
import {Button, Tooltip, Table, Modal, Image, Space, Popover} from 'antd';
import { LeftOutlined, RightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import CompensationModel from '@/assets/compensation-model.png';
import { queryCurrent, srvDoctorPatientStats, srvDoctorPayrollMetric } from '@/services/account';
import {
  srvGetDoctorDashboardMetric,
  srvDoctorDashboardProviderNoShow,
  srvCheckDoctorAcceptNewPatients, srvChartAuditCount,
} from '@/services/patient';
import moment from 'moment';
import { useRequest } from 'ahooks';
import { providerDataDescription } from './provider_data.js';
import './provider_dashboard.css';
import { formatUnix } from '../../utils/xtime';
import HomeWarningMetrics from '@/components/Dashboard/HomeWarningMetrics';
import DashboardUtil from '@/utils/dashboard.js';
import { useFlag } from '@unleash/proxy-client-react';
import WalletTable from "@/components/Dashboard/WalletTable";
import ProviderChartAuditNotes from "@/components/ProviderChartAuditNotes";



function secondsToDhms (seconds) {
  let sec = Number(seconds);
  var d = Math.floor(sec / (3600 * 24));
  var h = Math.floor((sec % (3600 * 24)) / 3600);
  var m = Math.floor((sec % 3600) / 60);
  let displayContent = <></>;
  if (d > 0) {
    displayContent = (
      <>
        <span> {d} </span>
        <span className="dashboard-items-number-unit"> {d == 1 ? 'day' : 'days'} </span>
        {h > 0 ? (
          <>
            <span> {h} </span>
            <span className="dashboard-items-number-unit"> {h == 1 ? 'hr' : 'hrs'} </span>
          </>
        ) : (
          <></>
        )}
      </>
    );
  } else if (h > 0) {
    displayContent = (
      <>
        <span> {h} </span>
        <span className="dashboard-item-number-unit"> {h == 1 ? 'hr' : 'hrs'} </span>
        {m > 0 ? (
          <>
            <span> {m} </span>
            <span className="dashboard-item-number-unit"> {m == 1 ? 'min' : 'mins'} </span>
          </>
        ) : (
          <></>
        )}
      </>
    );
  } else {
    displayContent = (
      <>
        <span> {m} </span>
        <span className="dashboard-item-number-unit"> {m > 0 ? (m == 1 ? 'min' : 'mins') : ''} </span>
      </>
    );
  }
  return displayContent;
}

function DashboardItem (props) {
  const { itemValue, itemName, itemDescription, ranking, warning, hours, mins, bigValue, forceShowWarning, rankingWarning } = props;
  var numberClassName = warning ? "dashboard-items-number warning" : "dashboard-items-number";
  var rankingClassName = rankingWarning ? "dashboard-items-ranking-warning" : "dashboard-items-ranking";
  if (bigValue) {
    numberClassName += " big-number";
  }
  var tooltip = itemDescription;
  // if (!warning) {
  //   tooltip = '';
  // }
  if (forceShowWarning) {
    tooltip = itemDescription;
  }
  return (
    <Tooltip placement="top" title={tooltip}>
      <div className="dashboard-items">
        <div className="dashboard-items-container">
          {itemValue && <div className={numberClassName}>{itemValue}</div>}
          {hours != null && (
            <>
              <div className={numberClassName}>{hours}</div>
              <div className="dashboard-items-number-unit">hrs</div>
            </>
          )}
          {mins != null && (
            <>
              <div className={numberClassName}>{mins}</div>
              <div className="dashboard-items-number-unit">mins</div>
            </>
          )}
          {ranking && <div className={rankingClassName}>{ranking}</div>}
        </div>
        <div className="dashboard-items-name">
          {itemName}
        </div>
      </div>
    </Tooltip>
  );
}

function PayrollItem (props) {
  const { itemPrefix = '', itemValue, itemName, itemDescription } = props;
  return (
    <Tooltip placement="top" title={itemDescription}>
      <div className="dashboard-items">
        <div className="dashboard-items-number"><span className="dollar">{itemPrefix}</span>{itemValue}</div>
        <div className="dashboard-items-name">{itemName}</div>
      </div>
    </Tooltip>
  );
}



function ProviderMetricTable (props) {
  const { providerMetrics, providerProfile, year, month } = props;
  const [providerChartAuditNotesVisible, setProviderChartAuditNotesVisible] = useState(false);
  const [dashboardChartAuditCount, setDashboardChartAuditCount] = useState({ audit_at: moment().unix(), pending: 0, completed: 0 });
  let hours = 0;
  let mins = 0;
  let isPartTime = providerProfile.job_type === 1;
  // providerMetrics?.effective_working_hours is mins
  if (providerMetrics?.effecttive_working_hours) {
    hours = Math.floor(providerMetrics.effecttive_working_hours / 60);
    mins = providerMetrics.effecttive_working_hours % 60;
  }
  const fetchDoctorChartAuditCount = useRequest(srvChartAuditCount, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data != null) {
          setDashboardChartAuditCount(ret.data);
        }
      }
    },
  });

  useEffect(() => {
    fetchDoctorChartAuditCount.run({ year: year, month: month, current: 1 });
  }, [year, month]);

  return (
    <div className="dashboards">
      {/* <div className="section-header">
        <div className="section-header-title">Key Metrics</div>
      </div> */}
      <div className={!isPartTime ? "dashboard-items-list" : "dashboard-items-list-left"}>
        <DashboardItem
          itemName={'New patients'}
          itemValue={(providerMetrics?.new_patient_for_payroll && providerMetrics?.new_patient_for_payroll > 0) ? providerMetrics?.new_patient_for_payroll || '0' : '-'}
          itemDescription={(providerMetrics?.new_patient_for_payroll && providerMetrics?.new_patient_for_payroll > 0) ? providerDataDescription.newPatient : ""}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Member panel'}
          itemValue={(providerMetrics?.member_panel && providerMetrics?.member_panel > 0) ? providerMetrics?.member_panel || '0' : '-'}
          itemDescription={(providerMetrics?.member_panel && providerMetrics?.member_panel > 0) ? providerDataDescription.memberPanel : ""}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Patient conversion'}
          itemValue={(providerMetrics?.patient_conversion_rate && providerMetrics?.patient_conversion_rate > 0) ? `${(providerMetrics?.patient_conversion_rate * 100  || 0).toFixed(0)}%` : '-'}
          itemDescription={(providerMetrics?.patient_conversion_rate && providerMetrics?.patient_conversion_rate > 0) ? (providerMetrics?.patient_conversion_rate_is_red ? providerDataDescription.patient_conversion_rate_warning : providerDataDescription.patientConversion) : providerDataDescription.patientConversion}
          warning={(providerMetrics?.patient_conversion_rate && providerMetrics?.patient_conversion_rate > 0) ? providerMetrics?.patient_conversion_rate_is_red : false}
          bigValue={true}
        />
        <DashboardItem
          itemName={(
            <Space>
              Patient rating

                <Button type="link" style={{ paddingLeft: '15px', paddingRight: '5px' }} onClick={() => {
                  history.push("/ehr_dashboard/patient_review");
                }}>View Details</Button>

            </Space>
          )}
          itemValue={(providerMetrics?.rating_average_star && providerMetrics?.rating_average_star>0) ? `${providerMetrics?.rating_average_star || 0}/5` : '-'}
          ranking={(providerMetrics?.follow_up_rating_5_star_rate && providerMetrics?.follow_up_rating_5_star_rate > 0) ? `(${(providerMetrics?.follow_up_rating_5_star_rate * 100 || 0).toFixed(0)}% 5-star)`: ''}
          rankingWarning={(providerMetrics?.follow_up_rating_5_star_rate && providerMetrics?.follow_up_rating_5_star_rate > 0) ?  providerMetrics?.follow_up_rating_5_star_rate_rate_is_red : false}
          itemDescription={(providerMetrics?.rating_average_star && providerMetrics?.rating_average_star>0) ? (providerMetrics?.rating_average_star_is_red ? providerDataDescription.rating_average_star_is_warning : providerDataDescription.patientReview): providerDataDescription.patientReview}
          warning={(providerMetrics?.rating_average_star && providerMetrics?.rating_average_star>0) ? providerMetrics?.rating_average_star_is_red : false}
          bigValue={true}
        />
      </div>

      <div className={!isPartTime ? "dashboard-items-list" : "dashboard-items-list-left"}>
        <DashboardItem
          itemName={'Refill response rate (within 72 hrs)'}
          itemValue={(providerMetrics?.refill_response_rate && providerMetrics?.refill_response_rate > 0) ? `${(providerMetrics?.refill_response_rate * 100 || 0).toFixed(0)}%` : '-'}
          itemDescription={(providerMetrics?.refill_response_rate && providerMetrics?.refill_response_rate > 0) ? (providerMetrics?.refill_response_rate_is_red ? providerDataDescription.refill_response_rate_warning : providerDataDescription.refill_response_rate) : providerDataDescription.refill_response_rate}
          warning={(providerMetrics?.refill_response_rate && providerMetrics?.refill_response_rate > 0) ? providerMetrics?.refill_response_rate_is_red : false}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Consultation response rate (within 72 hrs)'}
          itemValue={(providerMetrics?.consulation_response_rate && providerMetrics?.consulation_response_rate > 0) ? `${(providerMetrics?.consulation_response_rate * 100 || 0).toFixed(0)}%`: '-'}
          itemDescription={(providerMetrics?.consulation_response_rate && providerMetrics?.consulation_response_rate > 0) ? (providerMetrics?.consulation_response_rate_is_red ? providerDataDescription.consulation_response_rate_is_warning : providerDataDescription.consultation_response_rate) : providerDataDescription.consultation_response_rate}
          warning={(providerMetrics?.consulation_response_rate && providerMetrics?.consulation_response_rate > 0) ? providerMetrics?.consulation_response_rate_is_red : false}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Chart notes completion rate (within 24 hrs)'}
          itemValue={(providerMetrics?.note_completion_rate && providerMetrics?.note_completion_rate > 0) ? `${(providerMetrics?.note_completion_rate * 100 || 0).toFixed(0)}%` : '-'}
          itemDescription={(providerMetrics?.note_completion_rate && providerMetrics?.note_completion_rate > 0) ? (providerMetrics?.note_completion_rate_is_red ? providerDataDescription.note_completion_rate_is_warning : providerDataDescription.note_completion_rate) : providerDataDescription.note_completion_rate}
          warning={(providerMetrics?.note_completion_rate && providerMetrics?.note_completion_rate > 0) ? providerMetrics?.note_completion_rate_is_red : false}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Canceled member(Medication non-adherence/attrition rate)'}
          itemValue={(providerMetrics?.membership_cancellation && providerMetrics?.membership_cancellation > 0) ? providerMetrics?.membership_cancellation || '0' : '-'}
          ranking={(providerMetrics?.membership_cancellation_rate && providerMetrics?.membership_cancellation_rate > 0) ? `(${(providerMetrics?.membership_cancellation_rate * 100 || 0).toFixed(0)}%)` : ''}
          rankingWarning={(providerMetrics?.membership_cancellation_rate && providerMetrics?.membership_cancellation_rate > 0) ? providerMetrics?.membership_cancellation_rate_is_red : false}
          itemDescription={(providerMetrics?.membership_cancellation && providerMetrics?.membership_cancellation > 0) ? (providerMetrics?.membership_cancellation_is_red ? providerDataDescription.membership_cancellation_is_warning : providerDataDescription.membership_cancellation_rate) : providerDataDescription.membership_cancellation_rate}
          warning={(providerMetrics?.membership_cancellation && providerMetrics?.membership_cancellation > 0) ? providerMetrics?.membership_cancellation_is_red : false}
          bigValue={true}
        />
      </div>

      {!providerProfile.support_therapy && (
        <div className={!isPartTime ? "dashboard-items-list" : "dashboard-items-list-left"}>
          <DashboardItem
            itemName={'Average Zoom appointment duration(60 min)'}
            itemValue={(providerMetrics?.appointment_average_duration_60_mins && providerMetrics?.appointment_average_duration_60_mins > 0) ? `${(providerMetrics?.appointment_average_duration_60_mins || 0)}` : '-'}
            ranking={(providerMetrics?.appointment_average_duration_60_mins && providerMetrics?.appointment_average_duration_60_mins > 0) ? `min` : ''}
            itemDescription={providerDataDescription.appointment_average_duration_60_mins}
            warning={false}
            bigValue={true}
          />
          <DashboardItem
            itemName={'Average Zoom appointment duration(30 min)'}
            itemValue={(providerMetrics?.appointment_average_duration_30_mins && providerMetrics?.appointment_average_duration_30_mins > 0) ? `${(providerMetrics?.appointment_average_duration_30_mins || 0)}` : '-'}
            ranking={(providerMetrics?.appointment_average_duration_30_mins && providerMetrics?.appointment_average_duration_30_mins > 0) ? `min` : ''}
            itemDescription={providerDataDescription.appointment_average_duration_30_mins}
            warning={false}
            bigValue={true}
          />
          <DashboardItem
            itemName={'Average Zoom appointment duration(15 min)'}
            itemValue={(providerMetrics?.appointment_average_duration_15_mins && providerMetrics?.appointment_average_duration_15_mins > 0) ? `${(providerMetrics?.appointment_average_duration_15_mins || 0)}` : '-'}
            ranking={(providerMetrics?.appointment_average_duration_15_mins && providerMetrics?.appointment_average_duration_15_mins > 0) ? `min` : ''}
            itemDescription={providerDataDescription.appointment_average_duration_15_mins}
            warning={false}
            bigValue={true}
          />
          <DashboardItem
            itemName={(
              <Space>
                Chart audit score
                {dashboardChartAuditCount.completed > 0 && (
                <Button type="link" style={{ paddingLeft: '15px', paddingRight: '5px' }} onClick={() => {
                  setProviderChartAuditNotesVisible(true);
                }}>View Details</Button>
                )}
              </Space>
            )}
            itemValue={(providerMetrics?.chart_audit_average_score && providerMetrics?.chart_audit_average_score > 0) ? providerMetrics?.chart_audit_average_score || 0 : '-'}
            ranking={(providerMetrics?.chart_audit_average_score && providerMetrics?.chart_audit_average_score > 0) ? `/3` : ''}
            itemDescription={providerDataDescription.chart_audit_average_score}
            warning={false}
            bigValue={true}
          />
          <ProviderChartAuditNotes year={year} month={month} visible={providerChartAuditNotesVisible} setVisible={setProviderChartAuditNotesVisible} />
        </div>
      )}
    </div>
  );
}

function ProviderStatTable (props) {
  const { providerStat } = props;
  return (
    <div className="dashboards">
      <div className="dashboard-items-list">
        <DashboardItem
          itemName={'New patients'}
          itemValue={providerStat.new_patient_for_payroll}
          itemDescription={providerDataDescription.newPatient}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Member panel'}
          itemValue={providerStat.member_panel}
          itemDescription={providerDataDescription.memberPanel}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Patient conversion'}
          itemValue={providerStat.patient_conversion + '%'}
          itemDescription={providerDataDescription.patientConversion}
          bigValue={true}
        />

        <DashboardItem
          itemName={(
            <Space>
              Patient review
              <Button type="link" style={{ paddingLeft: '15px', paddingRight: '5px' }} onClick={() => {
                history.push(`/ehr_dashboard/patient_review?year=${providerStat.year}&month=${providerStat.month}`);
              }}>View details</Button>
            </Space>
          )}
          itemValue={Number(providerStat.provider_rating).toFixed(1) + '/5'}
          itemDescription={providerDataDescription.patientReview}
          bigValue={true}
        />
      </div>
      <div className="dashboard-items-list">
        <DashboardItem
          itemName={'Refill fulfillment'}
          itemValue={secondsToDhms(providerStat.refill_fulfillment)}
          itemDescription={providerDataDescription.refillFulfillment}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Message response'}
          itemValue={secondsToDhms(providerStat.message_response)}
          itemDescription={providerDataDescription.messageResponse}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Note completion'}
          itemValue={secondsToDhms(providerStat.note_completion)}
          itemDescription={providerDataDescription.noteCompletion}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Canceled member'}
          itemValue={providerStat.canceled_member}
          itemDescription={providerDataDescription.canceledMember}
          bigValue={true}
        />
      </div>
    </div>
  );
}

function ProviderPayrollTable (props) {
  const { providerStat, year, month, jobType,showCompensationModel } = props;
  const [payrollModalVisible, setPayrollModalVisible] = useState(false);
  const [payrollMetric, setPayrollMetric] = useState([]);
  const [providerNoShowData, setProviderNoShowData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [popoverVisible, setPopoverVisible] = useState(false);


  function GetProviderNoShowListReq (year, month, page, page_size) {
    return srvDoctorDashboardProviderNoShow({ year, month, page, page_size });
  }

  const GetProviderNoShowList = useRequest(srvDoctorDashboardProviderNoShow, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data != null && ret.data.items) {
          setProviderNoShowData(ret.data);
          return ret.data;
        }
      }
    },
  })

  const fetchDoctorPayrollMetric = useRequest(srvDoctorPayrollMetric, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data != null && ret.data.items) {
          setPayrollMetric(ret.data.items);
        }
      }
    },
  });

  useEffect(() => {
    if (providerStat && providerStat.year && providerStat.month) {
      fetchDoctorPayrollMetric.run({ year: providerStat.year, month: providerStat.month });
    }
  }, [providerStat]);

  const dealUrgentRefillColumns = [
    {
      title: 'PATIENT NAME',
      dataIndex: 'patient_name',
      key: 'patient_name',
      width: 120,
      render: (v, r) => {
        return (
          <Link to={`/patient/${r.patient_id}`} style={{ color: '#1a1f36', fontWeight: 500 }}>
            {r.patient_name}
          </Link>
        );
      },
    },
    {
      title: 'STATE',
      dataIndex: 'state',
      key: 'state',
      width: 220,
    },
    {
      title: 'Processing date',
      dataIndex: 'prescription_success_date',
      key: 'prescription_success_date',
      width: 220,
      render: (v, r) => {
        return formatUnix(r.prescription_success_date);
      },
    },
  ];
  const dealConsultationColumns = [
    {
      title: 'PATIENT NAME',
      dataIndex: 'patient_name',
      key: 'patient_name',
      width: 120,
      render: (v, r) => {
        return (
          <Link to={`/patient/${r.patient_id}`} style={{ color: '#1a1f36', fontWeight: 500 }}>
            {r.patient_name}
          </Link>
        );
      },
    },
    {
      title: 'STATE',
      dataIndex: 'state',
      key: 'state',
      width: 220,
    },
    {
      title: 'Solved date',
      dataIndex: 'solved_date',
      key: 'solved_date',
      width: 220,
      render: (v, r) => {
        return formatUnix(r.solved_date);
      },
    },
  ];

  useEffect(() => {
    if (year && month) {
      GetProviderNoShowList.run({ year: year, month: month, page: currentPage, page_size: 10 });
    }
  }, [year, month]);


  const columns = [
    {
      title: 'Date',
      dataIndex: 'day',
      key: 'day',
      width: 120,
      render: (v, r) => {
        return <div>{moment(`${r.year}-${r.month}-${r.day}`, 'YYYY-MM-DD').format("MMM DD")}</div>;
      },
    },
    {
      title: () => {
        return (
          <Space>
            New patients
            <Tooltip title={providerDataDescription.newPatient}>
              <QuestionCircleOutlined style={{ color: "#5469D4", fontSize: '14px', lineHeight: '20px', marginBottom: '4px' }} />
            </Tooltip>
          </Space>
        )
      },
      dataIndex: 'new_patient',
      key: 'new_patient',
      width: 220,
      render: (v, r) => {
        return (
          <Tooltip title={r.new_patient_names}>
            <span>{r.new_patient}</span>
          </Tooltip>
        )
      }
    },
    {
      title: () => {
        return (
          <Space>
            Member panel renewal
            <Tooltip title={providerDataDescription.memberPanel}>
              <QuestionCircleOutlined style={{ color: "#5469D4", fontSize: '14px', lineHeight: '20px', marginBottom: '4px' }} />
            </Tooltip>
          </Space>
        )
      },
      dataIndex: 'member_panel_renewal',
      key: 'member_panel_renewal',
      render: (v, r) => {
        let names = '';
        if (r.member_panel_one_day_incr_names != '') {
          names += '+ ' + r.member_panel_one_day_incr_names;
        }
        if (r.member_panel_one_day_incr_names != '' && r.member_panel_one_day_decr_names != '') {
          names += "\n"
        }
        if (r.member_panel_one_day_decr_names != '') {
          names += '- ' + r.member_panel_one_day_decr_names;
        }
        let finalNames = names;
        if (names != '') {
          finalNames = () => {
            return <span dangerouslySetInnerHTML={{ __html: names.split('\n').join('<br/>') }}></span>
          }
        }
        return (
          <Tooltip title={finalNames}>
            <span>{r.member_panel_renewal > 0 ? `+${r.member_panel_renewal}` : r.member_panel_renewal}</span>
          </Tooltip>
        )
      },
    },
    {
      title: 'Transfer/extended follow-up',
      dataIndex: 'transfer_extended_follow_up',
      key: 'transfer_extended_follow_up',
      render: (v, r) => {
        let namesArray = [].concat(
          r.extended_follow_up_names.split(","),
          r.transfer_of_care_names.split(",")
        ).filter((t) => t.trim());
        let nameCounts = new Map();
        namesArray.forEach(name => {
          name = name.trim();
          if (nameCounts.has(name)) {
            nameCounts.set(name, nameCounts.get(name) + 1);
          } else {
            nameCounts.set(name, 1);
          }
        });
        let finalNames = Array.from(nameCounts).map(([name, count]) => {
          if (count < 2) return name;
          return `${name}(${count})`;
        }).join(',');
        return (
          <Tooltip title={finalNames}>
            <span>{r.transfer_extended_follow_up}</span>
          </Tooltip>
        )
      },
    },
    {
      title: () => {
        return (
          <Space>
            Coverage(refill)
            <Tooltip title={providerDataDescription.urgentRefillApproveIncome}>
              <QuestionCircleOutlined style={{ color: "#5469D4", fontSize: '14px', lineHeight: '20px', marginBottom: '4px' }}/>
            </Tooltip>
          </Space>
        )
      },
      dataIndex: 'urgent_refill_approve',
      key: 'urgent_refill_approve',
      render: (v, r) => {
        return (<Popover
          content={
            <div className="popover-user">
              <Table columns={dealUrgentRefillColumns} rowKey="id" dataSource={r.urgent_refill_approve.items} bordered={false} pagination={false} />
            </div>
          }
          trigger="hover"
        >
          <span>{r.urgent_refill_approve ? r.urgent_refill_approve.total : 0}</span>
        </Popover>);
      },
    },
      {
        title: () => {
          return (
            <Space>
              Coverage(consultation)
              <Tooltip title={providerDataDescription.urgentConsultationSolvedIncome}>
                <QuestionCircleOutlined style={{ color: "#5469D4", fontSize: '14px', lineHeight: '20px', marginBottom: '4px' }}/>
              </Tooltip>
            </Space>
          )
        },
        dataIndex: 'urgent_consultation_solved',
        key: 'urgent_consultation_solved',
        render: (v, r) => {
          return (<Popover
            content={
              <div className="popover-user">
                <Table columns={dealConsultationColumns} rowKey="id" dataSource={r.urgent_consultation_solved.items} bordered={false} pagination={false} />
              </div>
            }
            trigger="hover"
          >
            <span>{r.urgent_consultation_solved ? r.urgent_consultation_solved.total : 0}</span>
          </Popover>);
        },
    }
  ];


  const providerNoShowColumns = [
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Patient',
      dataIndex: 'patient',
      key: 'patient',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Button type="link" onClick={() => handleButtonClick(record)}>
          View
        </Button>
      ),
    },
  ];

  const providerNoShowSource = providerNoShowData?.items
    ? providerNoShowData.items.map((item, index) => ({
      key: index,
      time: moment(item.appointed_at * 1000).format('MM/DD/YYYY'),
      type: item.appointment_type_name,
      patient: item.user_name,
      user_id: item.user_id,
    }))
    : [];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    GetProviderNoShowListReq(year, month, page, pageSize);
  };

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const handleButtonClick = (record) => {
    // redirect to patient appointment page
    history.push(`/patient/${record.user_id}?tab=4`);
  };

  return (
    <div className="dashboards">
    {jobType != 2 && (
      <>
      <div className="dashboard-items-list">
        <Tooltip placement="top" title={''}>
          <div className="dashboard-items dashboard-items-monthly-payroll">
            <div className="dashboard-items-number">
              <span className="dollar">$</span>
              {Number(providerStat.monthly_payroll).toFixed(2)}
            </div>
            <div className="dashboard-items-name">
              Monthly payment
                  {showCompensationModel && <Button
                    type="link"
                    onClick={() => {
                      setPayrollModalVisible(true);
                    }}
                  >
                    Compensation model
                  </Button>}
            </div>
          </div>
        </Tooltip>
        <Tooltip placement="top" title={providerNoShowData?.total > 0 ? "" : "# of appointments that were marked as provider no-show."}>
          <div className="dashboard-items dashboard-items-monthly-payroll">
            <div className={providerNoShowData?.total > 0 ? "dashboard-items-number warning" : "dashboard-items-number"}
            onMouseEnter={() => setPopoverVisible(true)}
            >
              <span className="dollar" />{providerNoShowData?.total ? Number(providerNoShowData?.total).toFixed(0) : 0}</div>
            <div className="dashboard-items-name">
              Provider no-show
            </div>
            <Popover
              content={
                <>
                <Table
                  columns={providerNoShowColumns}
                  dataSource={providerNoShowSource}
                  pagination={{
                    current: currentPage,
                    onChange: handlePageChange,
                    pageSize: 10,
                  }}
                />
                {providerNoShowData?.total > 0 && (<div  style={{ width: '300px', wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                 Any no-show significantly impacts the patient experience. Please avoid no-show or reschedule your appointment in advance if there's any emergencies.
                </div>)}
              </>
              }
              placement="bottom"
              trigger="hover"
              visible={popoverVisible}
              onVisibleChange={handlePopoverVisibleChange}
              />
          </div>
        </Tooltip>
        <Tooltip placement="top" title={""}>
          <div className="dashboard-items dashboard-items-hourly-rate">
            <div className="dashboard-items-number">
              <span className="dollar">$</span>
              {Number(providerStat.hourly_rate).toFixed(2)}
            </div>
            <div className="dashboard-items-name">Hourly rate</div>
          </div>
        </Tooltip>
      </div>
      <div className="dashboard-items-list">
        <PayrollItem
          itemName={'New patients'}
          itemPrefix={'$'}
          itemValue={Number(providerStat.new_patients_income).toFixed(2)}
          itemDescription={providerDataDescription.newPatient}
          />
        <PayrollItem
          itemName={'Member panel'}
          itemPrefix={'$'}
          itemValue={Number(providerStat.member_panel_income).toFixed(2)}
          itemDescription={providerDataDescription.memberPanel}
          />
        <PayrollItem
          itemName={'Transfer/extended follow-up'}
          itemPrefix={'$'}
          itemValue={Number(providerStat.transfer_extended_follow_up_income).toFixed(2)}
          itemDescription={providerDataDescription.transferExtendedFollowUpIncome}
          />
        <PayrollItem
          itemName={'Exceptions'}
          itemPrefix={'$'}
          itemValue={Number(providerStat.exception_income).toFixed(2)}
          itemDescription={providerDataDescription.exceptionIncome}
          />
      </div>
      </>
      )||null}
        <div className="dashboard-items-list">
          <PayrollItem
            itemName={'Coverage fee'}
            itemPrefix={'$'}
            itemValue={Number(providerStat.coverage_income).toFixed(2)}
            itemDescription={providerDataDescription.urgentRefillApproveIncome}
          />
        <PayrollItem
        />
        <PayrollItem
        />
        <PayrollItem
        />
        </div>
      <div className="dashboard-items-table">
        <Table
          columns={columns}
          rowKey="id"
          dataSource={payrollMetric}
          bordered={false}
          pagination={false}
        />
      </div>

      <Modal
        width={800}
        visible={payrollModalVisible}
        footer={null}
        onCancel={() => {
          setPayrollModalVisible(false);
        }}
      >
        <Image
          width="100%"
          className="compensation-model"
          preview={false}
          alt={'Compensation Model'}
          src={CompensationModel}
        />
      </Modal>
    </div>
  );
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function DashboardDateSelector (props) {
  const { currentIndex, numTotal, onClickPrev, onClickNext, month, year } = props;
  return (
    <div className="dashboard-date-selector">
      <Button
        className="dashboard-date-selector-button"
        disabled={currentIndex < 1}
        onClick={onClickPrev}
        type="primary"
        shape="circle"
        size="small"
      >
        <LeftOutlined />
      </Button>
      <div className="dashboard-date-selector-month">{months[month - 1] + ' ' + year}</div>
      <Button
        className="dashboard-date-selector-button"
        disabled={currentIndex >= numTotal - 1}
        onClick={onClickNext}
        type="primary"
        shape="circle"
        size="small"
      >
        <RightOutlined />
      </Button>
    </div>
  );
}


const ProviderDashboard = (props) => {
  const [providerProfile, setProviderProfile] = useState({})
  const [providerPatientStats, setProviderPatientStats] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [showWarning, setShowWarning] = useState(false);
  const [dashboardMetricsData, setDashboardMetricsData] = useState(null);
  const [dashboardWarningTwoDays, setDashboardWarningTwoDays] = useState(null);
  const [otherWarningData, setOtherWarningData] = useState(null);
  const [isYesterday, setIsYesterday] = useState(false);
  const [walletVisible, setWalletVisible] = useState(false);

  const flagShowUrgentRefill = useFlag('enable_ehr_show_urgent_refill');

  const fetchDoctorMetric = useRequest(srvGetDoctorDashboardMetric, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data) {
          let data = ret.data.items;
          let result = [];
          for (let i = 0; i < providerPatientStats.length; i++) {
            let item = providerPatientStats[i];
            let flag = false;
            for (let j = 0; j < data.length; j++) {
              let dataItem = data[j];
              if (item.year == dataItem.year && item.month == dataItem.month) {
                result.push(dataItem);
                flag = true;
                break;
              }
            }
            if (!flag) {
              result.push({});
            }
          }
          setDashboardMetricsData(result);
          let warning = DashboardUtil.getOtherWarningList(result);
          setOtherWarningData(warning);
        }
      }
    },
  });


  const fetchProviderProfile = useRequest(queryCurrent, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setProviderProfile(ret.data);
      }
    },
  });

  const fetchDoctorPatientData = useRequest(srvDoctorPatientStats, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data != null && ret.data.items && ret.data.items.length > 0) {
          setProviderPatientStats(ret.data.items);
          setCurrentIndex(ret.data.items.length - 1);
        }
      }
    },
  });


  const fetchCheckDoctorAcceptNewPatients = useRequest(srvCheckDoctorAcceptNewPatients, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data && (ret.data.rating_star_is_warning ||
          (ret.data.ugrent_refill_is_warning && flagShowUrgentRefill) ||
          ret.data.provider_no_show_is_warning ||
          ret.data.rating_star_is_lock ||
          ret.data.ugrent_refill_is_lock ||
          ret.data.provider_no_show_is_lock)) {
          setDashboardWarningTwoDays(ret.data);
          setShowWarning(true);
        }
      }
    }
  })

  useEffect(() => {
    fetchProviderProfile.run();
    processPatientStatsAndMetrics();
    fetchCheckDoctorAcceptNewPatients.run();
  }, []);




  const processPatientStatsAndMetrics = async () => {
    await fetchDoctorPatientData.runAsync();
    await fetchDoctorMetric.runAsync();
  }

  const getDashboardMetricsData = (currentIndex) => {
    if (dashboardMetricsData == null) {
      return null;
    }
    let data = dashboardMetricsData[currentIndex];
    if (data && data.year) {
      return data;
    }
  }

  const getOtherWarningData = (currentIndex) => {
    if (otherWarningData == null) {
      return null;
    }
    let data = otherWarningData[currentIndex];
    if (data && data.length > 0) {
      return data;
    }
  }

  const onWallet = () => {
    // todo
    setWalletVisible(true);
    // return (
    //     <WalletTable walletVisible={walletVisible} setWalletVisible={setWalletVisible} currentBalance={2099.56} bankAccount={'xxxxxxx'}  />
    // )
  }

  return (
    <>
    <div className="dashboard-container">
      <div className="dashboard-content">
        {providerPatientStats.length === 0 || currentIndex < 0 ? (
          <div>No available data now.</div>
        ) : (
          <>
            {(showWarning && dashboardWarningTwoDays) && <HomeWarningMetrics isHome={false} dashboardMetricsWarningData={dashboardWarningTwoDays} />}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
              <DashboardDateSelector
                currentIndex={currentIndex}
                numTotal={providerPatientStats.length}
                month={providerPatientStats[currentIndex].month}
                year={providerPatientStats[currentIndex].year}
                onClickPrev={() => setCurrentIndex(currentIndex - 1)}
                onClickNext={() => setCurrentIndex(currentIndex + 1)}
              />
              {(providerProfile.job_type !== 2 && providerProfile.enable_payout) && (<Button key="Wallet" type="primary" onClick={onWallet}>Wallet</Button>)}
            </div>
            <ProviderMetricTable providerMetrics={getDashboardMetricsData(currentIndex)}
              providerProfile={providerProfile}
                                 year = {providerPatientStats[currentIndex].year} month = {providerPatientStats[currentIndex].month} />
            {(providerProfile &&
              providerPatientStats.length > 0 &&
              (providerPatientStats[currentIndex].year >= 2023 || (providerPatientStats[currentIndex].year == 2022 && providerPatientStats[currentIndex].month > 10))) && (
                <ProviderPayrollTable providerStat={providerPatientStats[currentIndex]}
                  jobType={providerProfile.job_type}
                  showCompensationModel={providerProfile.show_compensation_flag}
                  month={providerPatientStats[currentIndex].month}
                  year={providerPatientStats[currentIndex].year} />
              )}
          </>
        )}
      </div>
    </div>
      <WalletTable
          walletVisible={walletVisible}
          setWalletVisible={setWalletVisible}
          providerProfile={providerProfile}
      />
    </>
  );
};

export default ProviderDashboard;
