import React, { useState } from 'react';

import { Modal, Input, Radio } from 'antd';
import isInteger from 'lodash/isInteger';
import isEmpty from 'lodash/isEmpty';

const AppointmentStatus = {
  NEW: 1,
  CONFIRMED: 2,
  CANCELED: 3,
  NOSHOW: 4,
  NODIAGNOSIS: 5,
  DIAGNOSED: 6,
  PROVIDERNOSHOW: 7,
};

const ChangePastAppointmentStatusModal = ({ appointment = {}, onOk, ...modalProps }) => {
  const [newStatus, setNewStatus] = useState();
  const [note, setNote] = useState();
  const [warningVisible, setWarningVisible] = useState(false);

  const onSelectionChange = (e) => {
    setWarningVisible(false);
    setNewStatus(e.target.value);
  };

  const onNoteChange = (e) => {
    setNote(e.target.value);
  };

  const onClose = () => {
    modalProps.onClose && modalProps.onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    if (!isInteger(newStatus) || isEmpty(note)) {
      setWarningVisible(true);
    } else {
      modalProps.changePastAppointmentStatus &&
        modalProps.changePastAppointmentStatus(
          {
            id: appointment.id,
            to_status: newStatus,
            note: note,
          },
          () => {
            onClose();
          },
        );
    }
  };

  const modalOpts = {
    ...modalProps,
    destroyOnClose: true,
    title: 'Change appointment status',
    okText: 'Confirm status update',
    width: 800,
    closable: false,
    onOk: handleOk,
    onCancel: handleCancel,
  };
  return (
    <Modal {...modalOpts}>
      <Radio.Group onChange={onSelectionChange} value={newStatus}>
        <>
          {appointment.status !== AppointmentStatus.NOSHOW && <Radio value={AppointmentStatus.NOSHOW}>No Show</Radio>}
          {appointment.status !== AppointmentStatus.NODIAGNOSIS && <Radio value={AppointmentStatus.NODIAGNOSIS}>No Diagnosis</Radio>}
          {appointment.status !== AppointmentStatus.DIAGNOSED && <Radio value={AppointmentStatus.DIAGNOSED}>Complete</Radio>}
          {appointment.status !== AppointmentStatus.PROVIDERNOSHOW && <Radio value={AppointmentStatus.PROVIDERNOSHOW}>Provider No Show</Radio>}
          {appointment.status !== AppointmentStatus.CANCELED && <Radio value={AppointmentStatus.CANCELED}>Canceled</Radio>}
        </>
      </Radio.Group>
      <Input
        style={{ marginTop: 10 }}
        placeholder="note is required"
        value={note}
        onChange={onNoteChange}
      />
      <p style={{ marginTop: 10 }}> *this doesn't change patient's membership status</p>
      {warningVisible && (
        <p style={{ marginTop: 10, color: 'red' }}> *missing status selection or note</p>
      )}
    </Modal>
  );
};

export default ChangePastAppointmentStatusModal;
