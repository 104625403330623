import { stringify } from 'querystring';
import { history } from 'umi';
import { message } from 'antd';
import {
  srvDoctorDetail,
  srvPatients,
  srvTherapistPatients,
  srvDoctorPatients,
  srvDoctorPatientStats,
  srvPatientIdentity,
  srvConsultations,
  srvAppointments,
  srvIntStatusItems,
  srvTreatmentRequests,
  srvPayments,
  srvMemberships,
  srvTreatmentPlanHistories,
  srvConsultationReplies,
  srvTreatmentPlanHistoryDetail,
  srvSendConsultationReply,
  srvCalendarAppointments,
  srvAppointmentDetail,
  srvAppointmentRescheduleCalendar,
  srvRescheduleAppointment,
  srvCancelAppointment,
  srvAppointmentItem,
  srvDoctorTransferOpts,
  srvDoctorAutoTransfer,
  srvMembershipExtendHistorys,
} from '@/services/admin';
import {srvDoctorBlockRemove, srvDoctorBlocks} from "@/services/patient";

const Model = {
  namespace: 'doctor_detail',
  state: {
    detail: {},
    appointment_types: [],
    appointment_statuses: [],
    appointments: {
      items: [],
      total: 0,
    },
    appointment_detail: {
      logs: [],
    },
    calendarAppointments: [],
    consultations: {
      items: [],
      total: 0,
    },
    saveCalendarBlocks: [],
    consultation_types: [],
    treatment_requests: {
      total: 0,
      items: [],
    },
    treatment_request_statuses: [],
    payments: {
      total: 0,
      items: [],
    },
    payment_products: [],
    payment_statuses: [],
    treatment_plan_histories: {
      total: 0,
      items: [],
    },
    treatment_plan_types: [],
    treatment_plan_statuses: [],
    memberships: {
      total: 0,
      items: [],
    },
    membershipExtendHistorys:{
      total: 0,
      items: [],
    },
    membership_types: [],
    membership_statuses: [],
    replyData: {
      consultation: {},
      consultation_replies: [],
    },
    treatmentPlanHistory: {},
    patients: {
      total: 0,
      items: [],
    },
    calendar: {},
    transferOpts: [],
    doctorPatientStats: [],
  },
  effects: {
    *fetchData({ payload }, { call, put }) {
      const res = yield call(srvDoctorDetail, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveData', payload: res.data });
    },

    *fetchPatients({ payload }, { call, put }) {
      const res = yield call(srvDoctorPatients, payload);
      if (!res.success) {
        return;
      }
      let items = []
      let total = 0
      if (res.data){
        items = res.data.items
        total = res.data.total
      }
      const res_therapist = yield call(srvTherapistPatients, payload);
      if (!res.success) {
        return;
      }
      if (res_therapist.data){
        items = items.concat(res_therapist.data.items)
        total = res_therapist.data.total+total
      }
      yield put({ type: 'savePatients', payload: {items:items,total:total} });
    },
    
    *fetchDoctorPatientStats({ payload }, { call, put }) {
      const res = yield call(srvDoctorPatientStats, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveDoctorPatientStats', payload: res.data });
    },


    *fetchConsultations({ payload }, { call, put }) {
      const res = yield call(srvConsultations, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveConsultations', payload: res.data });
    },
    *fetchConsultationTypes({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'consultation_type' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveConsultationTypes', payload: res.data });
    },
    *fetchAppointments({ payload }, { call, put }) {
      const res = yield call(srvAppointments, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveAppointments', payload: res.data });
    },
    *fetchCalendarAppointments({ payload }, { call, put }) {
      const res = yield call(srvCalendarAppointments, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveCalendarAppointments', payload: res.data });
    },
    *fetchDoctorBlocks({ payload }, { call, put }) {
      const res = yield call(srvDoctorBlocks, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveCalendarBlocks', payload: res.data });
    },
    *removeBlock({ payload, callback }, { call, put }) {
        const res = yield call(srvDoctorBlockRemove, payload);
        if (!res.success) {
            return;
        }
        if (callback) {
            callback();
        }
    },
    *fetchAppointmentTypes({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'appointment_type' });
      if (!res.success) {
        return;
      }
      res.data = res.data.filter((item) => item.value !== 9);
      yield put({ type: 'saveAppointmentTypes', payload: res.data });
    },
    *fetchAppointmentStatuses({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'appointment_status' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveAppointmentStatuses', payload: res.data });
    },
    *fetchTreatmentRequests({ payload }, { call, put }) {
      const res = yield call(srvTreatmentRequests, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveTreatmentRequests', payload: res.data });
    },
    *fetchTreatmentRequestStatuses({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'treatment_request_status' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveTreatmentRequestStatuses', payload: res.data });
    },

    *fetchPayments({ payload }, { call, put }) {
      const res = yield call(srvPayments, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'savePayments', payload: res.data });
    },
    *fetchPaymentProducts({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'product_id' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'savePaymentProducts', payload: res.data });
    },
    *fetchPaymentStatuses({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'payment_status' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'savePaymentStatuses', payload: res.data });
    },

    *fetchTreatmentPlanHistories({ payload }, { call, put }) {
      const res = yield call(srvTreatmentPlanHistories, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveTreatmentPlanHistories', payload: res.data });
    },
    *fetchTreatmentPlanTypes({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'treatment_plan_type' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveTreatmentPlanTypes', payload: res.data });
    },
    *fetchTreatmentPlanStatuses({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'treatment_plan_status' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveTreatmentPlanStatuses', payload: res.data });
    },
    *fetchMemberships({ payload }, { call, put }) {
      const res = yield call(srvMemberships, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveMemberships', payload: res.data });
    },
    *fetchMembershipExtendHistorys({ payload }, { call, put }) {
      const res = yield call(srvMembershipExtendHistorys, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveMembershipExtendHistorys', payload: res.data });
    },
    *fetchMembershipTypes({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'membership_type' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveMembershipTypes', payload: res.data });
    },
    *fetchMembershipStatuses({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'membership_status' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveMembershipStatuses', payload: res.data });
    },
    *fetchConsultationReplies({ payload }, { call, put, select }) {
      const res = yield call(srvConsultationReplies, { id: payload.id });
      if (!res.success) {
        yield put({
          type: 'saveConsultationReplies',
          payload: {
            consultation: {},
            consultation_replies: [],
          },
        });
        return;
      }
      yield put({ type: 'saveConsultationReplies', payload: res.data });
    },
    *fetchTreatmentPlanHistory({ payload }, { call, put, select }) {
      const res = yield call(srvTreatmentPlanHistoryDetail, { id: payload.id });
      if (!res.success) {
        yield put({ type: 'saveTreatmentPlanHistory', payload: {} });
      } else {
        yield put({ type: 'saveTreatmentPlanHistory', payload: res.data });
      }
    },
    *replyConsultation({ payload, callback }, { call, put, select }) {
      const res = yield call(srvSendConsultationReply, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },

    *fetchAppointmentDetail({ payload }, { call, put }) {
      const res = yield call(srvAppointmentDetail, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveAppointmentDetail', payload: res.data });
    },
    *rescheduleAppointment({ payload, callback }, { call, put, select }) {
      const res = yield call(srvRescheduleAppointment, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },

    *cancelAppointment({ payload, callback }, { call, put, select }) {
      const res = yield call(srvCancelAppointment, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *fetchAppointmentRescheduleCalendar({ payload }, { call, put }) {
      const res = yield call(srvAppointmentRescheduleCalendar, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveAppointmentRescheduleCalendar', payload: res.data });
    },

    *fetchAppointmentItem({ payload }, { call, put, select }) {
      const res = yield call(srvAppointmentItem, { id: payload.id });
      if (!res.success) {
        return;
      }
      const { appointments } = yield select((state) => state.doctor_detail);

      for (let i = 0; i < appointments.items.length; i++) {
        if (res.data.id === appointments.items[i].id) {
          appointments.items[i] = res.data;
        }
      }

      yield put({
        type: 'saveAppointments',
        payload: { total: appointments.total, items: [...appointments.items] },
      });
    },

    *fetchDoctorTransferOpts({ payload, callback }, { call, put }) {
      const res = yield call(srvDoctorTransferOpts, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveDoctorTransferOpts', payload: res.data });

      if (callback) {
        callback(res.data);
      }
    },

    *autoTransfer({ payload, callback }, { call, put, select }) {
      const res = yield call(srvDoctorAutoTransfer, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
  },
  reducers: {
    saveData(state, { payload }) {
      return { ...state, detail: payload };
    },
    savePatients(state, { payload }) {
      return { ...state, patients: payload };
    },
    saveDoctorPatientStats(state, { payload }) {
      return { ...state, doctorPatientStats: payload };
    },
    saveAppointments(state, { payload }) {
      return { ...state, appointments: payload };
    },
    saveCalendarAppointments(state, { payload }) {
      return { ...state, calendarAppointments: payload };
    },
    saveCalendarBlocks(state, { payload }) {
        return { ...state, saveCalendarBlocks: payload };
    },
    saveAppointmentTypes(state, { payload }) {
      return { ...state, appointment_types: payload };
    },
    saveAppointmentStatuses(state, { payload }) {
      return { ...state, appointment_statuses: payload };
    },
    saveConsultations(state, { payload }) {
      return { ...state, consultations: payload };
    },
    saveConsultationTypes(state, { payload }) {
      return { ...state, consultation_types: payload };
    },
    saveTreatmentRequests(state, { payload }) {
      return { ...state, treatment_requests: payload };
    },
    saveTreatmentRequestStatuses(state, { payload }) {
      return { ...state, treatment_request_statuses: payload };
    },
    savePayments(state, { payload }) {
      return { ...state, payments: payload };
    },
    savePaymentProducts(state, { payload }) {
      return { ...state, payment_products: payload };
    },
    savePaymentStatuses(state, { payload }) {
      return { ...state, payment_statuses: payload };
    },
    saveTreatmentPlanHistories(state, { payload }) {
      return { ...state, treatment_plan_histories: payload };
    },
    saveTreatmentPlanTypes(state, { payload }) {
      return { ...state, treatment_plan_types: payload };
    },
    saveTreatmentPlanStatuses(state, { payload }) {
      return { ...state, treatment_plan_statuses: payload };
    },
    saveMemberships(state, { payload }) {
      return { ...state, memberships: payload };
    },
    saveMembershipExtendHistorys(state, { payload }) {
      return { ...state, membershipExtendHistorys: payload };
    },
    saveMembershipTypes(state, { payload }) {
      return { ...state, membership_types: payload };
    },
    saveMembershipStatuses(state, { payload }) {
      return { ...state, membership_statuses: payload };
    },
    saveConsultationReplies(state, { payload }) {
      return { ...state, replyData: payload };
    },
    saveTreatmentPlanHistory(state, { payload }) {
      return { ...state, treatmentPlanHistory: payload };
    },
    saveAppointmentDetail(state, { payload }) {
      return { ...state, appointment_detail: payload };
    },
    saveAppointmentRescheduleCalendar(state, { payload }) {
      return { ...state, calendar: payload };
    },
    saveDoctorTransferOpts(state, { payload }) {
      return { ...state, transferOpts: payload };
    },
  },
};
export default Model;
