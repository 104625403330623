const CallModel = {
  namespace: 'call',
  state: {
    oncall$: null,
  },
  effects: {},
  reducers: {
    saveOnCall(state, action) {
      return { ...state, oncall$: action.payload };
    },
  },
  subscriptions: {},
};
export default CallModel;
