import React, {useEffect, useState} from 'react';
import {
    Button,
} from 'antd';
import {useRequest} from "ahooks";
import {srvGetGoogleClientId} from "@/services/account";
const GoogleCalendarAuth = (props) => {

    const [clientId, setClientId] = useState('');

    const {reAuth} = props;

    const handleGoogleClientId = useRequest(srvGetGoogleClientId, {
        manual: true,
        onSuccess: (ret) => {
            setClientId(ret.data);
        },
    })

    useEffect(() => {
        handleGoogleClientId.run();
    }, []);

    const handleAuthClick = () => {
        const redirectUri = `${GOOGLE_REDIRECT_URL}`;
        const scope = 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email';
        const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&access_type=offline&prompt=consent`;
        window.location.href = authUrl;
    };


    if (reAuth) {
        return (
            <div>
                <Button key="confirm" type="primary" onClick={handleAuthClick}>Re-authorization</Button>
            </div>
        );
    } else {
      return (
          <div>
              <Button key="confirm" type="primary" onClick={handleAuthClick}>Add</Button>
          </div>
      );
    }
};

export default GoogleCalendarAuth;
