import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Space, Select, Input, InputNumber, Form, message, Typography, DatePicker } from 'antd';
import moment from 'moment';

const ProviderIncomeEdit = (props) => {

  const { form } = props;
  const incomes = form.getFieldValue('doctor_incomes')
  const [nextDate, setNextDate] = useState(`${moment().year()}-${moment().month()+1}`);

  const onChange = (key, name) => {
    const doctor_incomes = form.getFieldValue('doctor_incomes')
    if (doctor_incomes[key] && doctor_incomes[key]['id']) {
      doctor_incomes[key]['is_update'] = true;
      form.setFieldsValue({ doctor_incomes })
    }
  }

  const onDatePickerChange = (key, time, ts) => {
    const doctor_incomes = form.getFieldValue('doctor_incomes');
    if (doctor_incomes[key]) {
      const date = moment(ts, 'YYYY-MM')
      doctor_incomes[key] = {};
      doctor_incomes[key]['year'] = date.year();
      doctor_incomes[key]['month'] = date.month() + 1;
      form.setFieldsValue({ doctor_incomes })
      const nextMonth = date.add(1, 'months');
      setNextDate(`${nextMonth.year()}-${nextMonth.month() + 1}`);
    }
  }

  const onAdd = (key) => {
    const doctor_incomes = form.getFieldValue('doctor_incomes');
    if (doctor_incomes[key] === undefined) {
      const date = moment(nextDate, 'YYYY-MM')
      doctor_incomes[key] = {};
      doctor_incomes[key]['year'] = date.year();
      doctor_incomes[key]['month'] = date.month() + 1;
      form.setFieldsValue({ doctor_incomes })
      const nextMonth = date.add(1, 'months');
      setNextDate(`${nextMonth.year()}-${nextMonth.month() + 1}`);
    }
    return nextDate;
  }

  useEffect(() => {
    if (incomes && incomes.length > 0) {
      const lastDateIncome = incomes[incomes.length - 1]
      const nextMonth = moment(lastDateIncome.date, 'YYYY-MM').add(1, 'months');
      const nextDate = `${nextMonth.year()}-${nextMonth.month() + 1}`
      setNextDate(nextDate);
    }
  }, []);

  return (
    <Row gutter={16}>
      <Col span={12}>

        <Space
          style={{
            display: 'flex',
            marginBottom: 5,
          }}
          align="baseline"
        >
          <Form.Item style={{
            width: 130,
            marginBottom: 5,
          }}>
            <Typography.Text>
              Date
            </Typography.Text>
          </Form.Item>
          <Form.Item style={{
            width: 180,
            marginBottom: 5,
          }}>
            <Typography.Text>
              Hourly Rate
            </Typography.Text>
          </Form.Item >
          <Form.Item style={{
            width: 180,
            marginBottom: 5,
          }}>
            <Typography.Text>
              Collab Fee
            </Typography.Text>
          </Form.Item>
          <Form.Item style={{
            width: 180,
            marginBottom: 5,
          }}>
            <Typography.Text>
              License Reimbursement
            </Typography.Text>
          </Form.Item>
          <Form.Item style={{
            width: 180,
            marginBottom: 5,
          }}>
            <Typography.Text>
              Referral Bonus
            </Typography.Text>
          </Form.Item>
          <Form.Item style={{
            width: 180,
            marginBottom: 5,
          }}>
            <Typography.Text>
              Transfer fee
            </Typography.Text>
          </Form.Item>
          <Form.Item style={{
            width: 180,
            marginBottom: 5,
          }}>
            <Typography.Text>
              Other bonus
            </Typography.Text>
          </Form.Item>
        </Space>

        <Form.List name="doctor_incomes">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    rules={[
                      {
                        required: true,
                        message: 'Missing Date',
                      },
                    ]}
                  >
                    <DatePicker picker="month"
                      disabled={form.getFieldValue(['doctor_incomes', key, 'date'])}
                      onChange={(t, ts) => {
                        onDatePickerChange(key, t, ts);
                      }}
                      defaultValue={() => {
                        let date = form.getFieldValue(['doctor_incomes', key, 'date']);
                        // if add new a row, date value default is undefined.
                        if (date === undefined) {
                          date = onAdd(key); // add key row
                        }
                        return moment(date, 'YYYY-MM');
                      }}
                      style={{
                        width: 130,
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[key, 'hourly_rate']}
                    onChange={() => onChange(key, 'hourly_rate')}
                    style={{
                      width: 180,
                      marginBottom: 5,
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Missing hourly rate',
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Hourly Rate"
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    onChange={() => onChange(key, 'collab_fee')}
                    name={[key, 'collab_fee']}
                    style={{
                      width: 180,
                      marginBottom: 5,
                    }}
                  >
                    <InputNumber placeholder="Collab Fee"
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    onChange={() => onChange(key, 'license_reimbursement')}
                    name={[key, 'license_reimbursement']}
                    style={{
                      width: 180,
                      marginBottom: 5,
                    }}
                  >
                    <InputNumber placeholder="License Reimbursement"
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    onChange={() => onChange(key, 'referral_bonus')}
                    name={[key, 'referral_bonus']}
                    style={{
                      width: 180,
                      marginBottom: 5,
                    }}
                  >
                    <InputNumber placeholder="Referral Bonus"
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                      {...restField}
                      onChange={() => onChange(key, 'transfer_fee')}
                      name={[key, 'transfer_fee']}
                      style={{
                      width: 180,
                      marginBottom: 5,
                      }}
                  >
                      <InputNumber placeholder="Transfer Fee"
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                      {...restField}
                      onChange={() => onChange(key, 'other_bonus')}
                      name={[key, 'other_bonus']}
                      style={{
                      width: 180,
                      marginBottom: 5,
                      }}
                  >
                      <InputNumber placeholder="Other Bonus"
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    hidden={true}
                    style={{
                      width: 100,
                      marginBottom: 5,
                    }}
                    name={[key, 'year']}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    hidden={true}
                    style={{
                      width: 100,
                      marginBottom: 5,
                    }}
                    name={[key, 'month']}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    hidden={true}
                    name={[key, 'doctor_id']}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    hidden={true}
                    name={[key, 'id']}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    hidden={true}
                    name={[key, 'is_update']}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Space>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => add()}>
                  + Add Next Month
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Col>
    </Row>
  );
};

export default ProviderIncomeEdit;
