import React from 'react';
import { Modal, Input, Form } from 'antd';

const UpdateAddressModal = ({ address, onOk, ...modalProps }) => {
  const [form] = Form.useForm();

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onOk(values)
      });
  };

  const modalOpts = {
    ...modalProps,
    onOk: onFinish,
  };

  return (
    <Modal {...modalOpts} destroyOnClose={true}>
      <Form
        form={form}
        layout="vertical"
        initialValues={address}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Address Line 1"
          name="street"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Address Line 2" name="street_1">
          <Input />
        </Form.Item>

        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="State"
          name="state"
          rules={[
            {
              required: true,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || /[A-Z].*[A-Z]/.test(value)) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('Must be two capital letters like CA'));
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Zipcode"
          name="zipcode"
          rules={[
            {
              required: true,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || /^\d{5}(-\d{4})?$/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Not valid zip code'));
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateAddressModal;
