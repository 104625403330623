import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link,useAccess } from 'umi';
import { Tooltip, Dropdown, Tag, Space, Popover, Input, Table, Row, Col, Button,Modal } from 'antd';
import moment from 'moment';
import { EllipsisOutlined, SearchOutlined, createFromIconfontCN } from '@ant-design/icons';
import { AsYouType } from 'libphonenumber-js';
import { useEventEmitter } from 'ahooks';

import Filters from '@/components/Filters';
import defaultSettings from '../../../defaultSettings';
import { appointmentStatusColor } from '@/utils/tagColor';
import RescheduleAppointment from '@/components/RescheduleAppointment';
import CancelAppointment from '@/components/CancelAppointment';
import ProviderNoShowModal from '@/components/ProviderNoShowModal';
import ChangePastAppointmentStatus from '@/components/ChangePastAppointmentStatus';
import ScheduleAppointment from '@/components/ScheduleAppointment';
import ScheduleAppointmentRequest from '@/components/ScheduleAppointmentRequest';
import { formatAppointmentAt, formatUnix } from '@/utils/xtime';
import { useFlag } from '@unleash/proxy-client-react';
import { useRequest } from 'ahooks';
import { srvAdminChangeFollowUpToTransferOfCare, srvAdminChangeInPersonFollowUpToTransferOfCare, srvSyncAppointmentInfoToApero } from '@/services/admin';
import AppointmentQuestionResponseDrawer from '@/components/AppointmentQuestionResponseDrawer';

import {
  srvGetPatientAppointmentQuestionResponse
} from '@/services/account';

import styles from './index.less';
const cx = cn.bind(styles);

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const filters = [
  {
    name: 'doctor_email',
    label: 'Doctor Email',
    type: 'text',
  },
  {
    name: 'doctor_phone',
    label: 'Doctor Phone',
    type: 'text',
  },
  {
    name: 'appointed_at',
    label: 'Date',
    type: 'date',
  },
  {
    name: 'appointment_type',
    label: 'Type',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'status',
    label: 'Status',
    type: 'checkbox',
    items: [],
  },
];

const Appointment = (props) => {
  const {
    active,
    id,
    detail,
    appointments,
    loadingAppointments,
    appointment_types,
    appointment_statuses,
  } = props;
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [rescheduleVisible, setRescheduleVisible] = useState(false);
  const [scheduleVisible, setScheduleVisible] = useState(false);
  const [cancelVisible, setCancelVisible] = useState(false);
  const [changeStatusVisible, setChangeStatusVisible] = useState(false);
  const [noShowModalVisible, setNoShowModalVisible] = useState(false);
  const [appointmentResponse, setAppointmentResponse] = useState(null);
  const [app, setApp] = useState({});
  const reset$ = useEventEmitter();
  const superAdmin = useFlag('super_admin');
  const access=useAccess();
  const [chargeFailVisible, setChargeFailVisible] = useState(false);
  const columns = [
    {
      title: 'Appointment Time',
      dataIndex: 'appointed_at',
      key: 'appointed_at',
      ellipsis: true,
      render: (v, r) => {
        const start = moment.unix(r.appointed_at);
        return (
          <div className={cx({timeSlot: r.admin_flag })} style={{ color: '#1a1f36', fontWeight: 500 }}>
            <Tooltip
              title={
                <div>
                  {r.admin_flag && <p>schedule by: {r.action_user_name}</p>}
                  <p>Patient time and time zone:</p>
                  <p>{formatAppointmentAt(r, r.timezone)}</p>
                </div>
              }
              placement="bottom"
            >
              {formatAppointmentAt(r)}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'appointment_type',
      key: 'appointment_type',
      ellipsis: true,
      width: 400,
      render: (v, r) => {
        return r.appointment_type_name;
      },
    },
    {
      title: 'Clinician',
      dataIndex: 'doctor_name',
      key: 'doctor_name',
      ellipsis: true,
      width: 220,
      render: (v, r) => {
        if(!r.doctor){
          return '';
        }
        const userName = `${r.doctor.name} ${r.doctor.title}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/doctor/detail/${r.doctor_id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/doctor/detail/${r.doctor_id}`}>{r.doctor.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.doctor.phone)}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <a href="#">{userName}</a>
          </Popover>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      ellipsis: true,
      width: 150,
      key: 'status',
      render: (v, r) => {
        return <Tag color={appointmentStatusColor(r.status)}>{r.status_name}</Tag>;
      },
    },
    {
      title: '',
      dataIndex: 'cancel_type_name',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        if (v.length) {
          return (
            <div>
              <Tooltip title={`Reason: ${r.cancel_reason}`} placement="bottom">
                {v} - {r.cancel_reason}
              </Tooltip>
            </div>
          );
        } else {
          return '';
        }
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 50,
      render: (v, r) => {
        if (history.location.query.admin && access.adminAccesser){
          return
        }
        return (
          <Dropdown
            overlay={
              <div className={cx({ appointmentOpt: true })}>
                {(r.status === 1 || r.status === 2) && (
                  <div
                    className={cx({ appointmentOptItem: true })}
                    onClick={() => showCancelPanel(r)}
                  >
                    <a href="#">Cancel</a>
                  </div>
                )}
                {r.status !== 6 && (
                  <div
                    className={cx({ appointmentOptItem: true })}
                    onClick={() => showReschedulePanel(r)}
                  >
                    <a href="#">Reschedule</a>
                  </div>
                )}
                {
                  r.appointment_type == 19 && (
                    <div className={cx({ appointmentOptItem: true })}
                      onClick={() => { showAppointmentResponse(r) } }
                    >
                      <a href="#">View Video</a>
                    </div>
                  )
                }
                {
                  (
                    <div
                      className={cx({ appointmentOptItem: true })}
                      onClick={() => showChangeStatusModal(r)}
                    >
                      <a href="#">Change Status</a>
                    </div>
                  )
                }
                {
                  superAdmin && r.appointment_type === 2 && (
                    <div className={cx({ appointmentOptItem: true })}
                      onClick={() => changeFollowUpToTransferOfCare(r)}
                    >
                      <a href="#"> Change To Transfer Of Care</a>
                    </div>
                  )
                }
                {
                  superAdmin && r.appointment_type === 7 && (
                    <div className={cx({ appointmentOptItem: true })}
                      onClick={() => changeFollowUpToInPersonTransferOfCare(r)}
                    >
                      <a href="#"> Change To In Person Transfer Of Care</a>
                    </div>
                  )
                }
                <div className={cx({ appointmentOptItem: true })}>
                  <Link to={`/appointment/${r.id}`}>View Appointment Details</Link>
                </div>
                <div
                  className={cx({ appointmentOptItem: true })}
                  // onClick={() => syncAppointmentToApero(r)}
                  aria-disabled="true"
                  style={{ pointerEvents: 'none', opacity: 0.5 }}
                >
                  <a href="#">Sync to Apero</a>
                </div>
              </div>
            }
            trigger={['click']}
          >
            <Button size="small">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const syncAppointmentToApero = (item) => {
    syncAppointmentInfoToApero.run({ id: item.id });
  };

  const syncAppointmentInfoToApero = useRequest(srvSyncAppointmentInfoToApero, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        refresh();
      }
    },
  });


  useEffect(() => {
    setPage(1);
    props.loadAppointments({ page: 1, page_size: 20 }, () => { });
    props.loadAppointmentTypes();
    props.loadAppointmentStatuses();
  }, [id, active]);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    props.loadAppointments({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => { });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    props.loadAppointments(
      { page: pagination.current, page_size: pagination.pageSize, filters: JSON.stringify(query) },
      () => { },
    );
  };

  const f = () => {
    for (const v of filters) {
      if (v.name === 'appointment_type' && v.items.length === 0) {
        v.items = appointment_types;
        continue;
      }

      if (v.name === 'status' && v.items.length === 0) {
        v.items = appointment_statuses;
      }
    }
    return filters;
  };

  const showCancelPanel = (item) => {
    setApp(item);
    setCancelVisible(true);
  };

  const showReschedulePanel = (item) => {
    setApp(item);
    props.loadRescheduleCalendar({
      appointment_id: item.id,
      timezone: item.timezone,
      days: 4,
      date: moment().format('YYYYMMDD'),
      dir: 'next',
      from_admin: 1,
    });
    setRescheduleVisible(true);
  };

  const showSchedulePanel = (item) => {
    setScheduleVisible(true);
  };

  const showProviderNoShowModal = (item) => {
    setApp(item);
    setNoShowModalVisible(true);
  };

  const showChangeStatusModal = (item) => {
    setApp(item);
    setChangeStatusVisible(true);
  };

  const changeFollowUpToTransferOfCareReq = useRequest(srvAdminChangeFollowUpToTransferOfCare, {
    manual: true,
    onFinally: (params) => {
      props.loadAppointmentItem({ id: params[0].id });
    }
  });

  const changeFollowUpToTransferOfCare = (item) => {
    changeFollowUpToTransferOfCareReq.run({ "id": item.id })
  };
  const changeFollowUpToInPersonTransferOfCare = (item) => {
    changeInPersonFollowUpToTransferOfCareReq.run({ "id": item.id })
  };

  const changeInPersonFollowUpToTransferOfCareReq = useRequest(srvAdminChangeInPersonFollowUpToTransferOfCare, {
    manual: true,
    onFinally: (params) => {
      props.loadAppointmentItem({ id: params[0].id });
    }
  });

  const fetchAppointmentResponse = useRequest(srvGetPatientAppointmentQuestionResponse, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if(ret.data) {
          setAppointmentResponse({
            questions: JSON.parse(ret.data.questions),
            answers: JSON.parse(ret.data.answers)
          })
          setProviderNote('AsyncVideoFollowUp')
        }
      }
    }
  })

  const showAppointmentResponse = (app) => {
    setApp(app)
    fetchAppointmentResponse.run({
      appointment_id: app.id
    })
  }

  return (
    <div className={cx({ appointment: true })}>
      <div className={cx({ subheader: true })}>
        <h3>Appointments</h3>
        <Space>
          {(detail && detail.id && !(history.location.query.admin && access.adminAccesser)) && (
            <Button type="primary" onClick={showSchedulePanel}>
              Schedule
            </Button>
          )}
          <Filters reset$={reset$} filters={f()} onFilter={onFilter} />
        </Space>
      </div>

      <Table
        columns={columns}
        dataSource={appointments.items}
        rowClassName={(r) => {
          if (r.id == app.id) {
            return 'rowSelected';
          }
          return '';
        }}
        rowKey="id"
        loading={loadingAppointments}
        onChange={handleTableChange}
        pagination={{ pageSize: 20, current: page, simple: true, total: appointments.total }}
      />

      {scheduleVisible && detail && detail.id && (
        <ScheduleAppointment
          visible={scheduleVisible}
          id={id}
          detail={detail}
          active={active}
          onClose={() => {
            setScheduleVisible(false);
            reset$.emit();
          }}
        />
      )}

      {rescheduleVisible &&
        <RescheduleAppointment
          visible={rescheduleVisible}
          appointment={app}
          rescheduleCalendar={props.rescheduleCalendar}
          loadingReschedule={props.loadingRescheduleAppointment}
          activeDoctor={props.activeDoctor}
          onClose={() => {
            setRescheduleVisible(false);
          }}
          rescheduleAppointment={(payload, callback) => {
            props.rescheduleAppointment(payload, () => {
              props.loadAppointmentItem({ id: payload.id });
              callback && callback();
            });
          }}
          loadRescheduleCalendar={props.loadRescheduleCalendar}
        />
      }
      <Modal
        visible={chargeFailVisible}
        onCancel={() => {
          setChargeFailVisible(false);
        }}
        footer={null}
      >
        <p
          style={{
            color: '#000000',
            fontSize: '16px',
            fontWeight: '600',
            marginBottom: '20px',
          }}
        >
          The charge failed.
        </p>
        <p style={{
          color: '#000000 80%', marginBottom: '20px'
        }}>
          Please inform the patient to update the payment method and try again.</p>
        <div style={{
          display: 'flex', justifyContent: 'space-between', color: '#000000', marginTop: '20px'
        }}>
          <p>
          </p>
          <Button
            key="confirm"
            style={{ width: '250px', borderRadius: '4px' }}
            onClick={() => {
              setChargeFailVisible(false);
            }}
            type="primary"
          >
           OK
          </Button>
        </div>
      </Modal>
      <CancelAppointment
        visible={cancelVisible}
        appointment={app}
        loadingCancel={props.loadingCancelAppointment}
        onClose={() => {
          setCancelVisible(false);
        }}
        cancelAppointment={(payload, callback) => {
          props.cancelAppointment(payload, () => {
            props.loadAppointmentItem({ id: payload.id });
            callback && callback();
          });
        }}
      />
      {noShowModalVisible && (
        <ProviderNoShowModal
          id={app.id}
          visible={noShowModalVisible}
          onOk={(payload, callback) => {
            setNoShowModalVisible(false);
            props.markAppointmentProviderNoShow(payload, () => {
              props.loadAppointmentItem({ id: payload.id });
              callback && callback();
            });
          }}
          onCancel={() => {
            setNoShowModalVisible(false);
          }}
        />
      )}
      {changeStatusVisible && (
        <ChangePastAppointmentStatus
          visible={changeStatusVisible}
          appointment={app}
          changePastAppointmentStatus={(payload, callback) => {
            props.changePastAppointmentStatus(payload, () => {
              props.loadAppointmentItem({ id: payload.id });
              callback && callback();
            });
          }}
          onClose={() => {
            setChangeStatusVisible(false);
          }}
        />
      )}

      {
        app && appointmentResponse &&
          <AppointmentQuestionResponseDrawer onClose={()=> { setAppointmentResponse(null)} } appointmentId={app.id} doctorName={app.doctor.name} />
      }

    </div>
  );
};

export default Appointment;
