import React, { useState } from 'react';
import { Checkbox, Modal, Input, Form } from 'antd';
const CheckboxGroup = Checkbox.Group;
import styles from './CallReasonModal.less';

const CallReasonModal = ({ address, onOk,filterFirstReason, ...modalProps }) => {
  const [form] = Form.useForm();
  const [otherCheckFlag, setOtherCheckFlag] = useState(false);

  const onFinish = () => {
    form.validateFields().then((values) => {
      var data = values.reason.slice();
      data.forEach((item, index, arr) => {
        if (item == 'OTHER') {
          arr[index] = values.otherReason;
        }
      });
      form.resetFields();
      onOk(data.join(','));
    });
  };

  const modalOpts = {
    ...modalProps,
    onOk: onFinish,
  };

  const onChangeOtherReason = (e) => {
    setOtherCheckFlag(e.target.checked);
  };

  return (
    <Modal {...modalOpts} destroyOnClose={true}>
      <Form
        form={form}
        layout="vertical"
        initialValues={address}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="What is the reason for this call?"
          name="reason"
          className={styles.reasonTitle}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CheckboxGroup style={{ width: '100%' }}>
            <ul className={styles.list}>
              {!filterFirstReason && (<li>
                <Checkbox value="NOT SHOW">Patient didn't show up at appointed time.</Checkbox>
              </li>)}
              <li>
                <Checkbox value="FOLLOW UP">Follow Up</Checkbox>
              </li>
              <li>
                <Checkbox value="TECHNICAL DIFFICULTIES">Technical Difficulties</Checkbox>
              </li>
              <li>
                <Checkbox value="EMERGENCY">Emergency</Checkbox>
              </li>
              <li>
                <Checkbox value="OTHER" onChange={onChangeOtherReason} style={{ display: 'block' }}>
                  Other{' '}
                  {otherCheckFlag ? (
                    <Form.Item
                      name="otherReason"
                      className={styles.otherInput}
                      rules={[
                        {
                          required: true,
                          message: 'content is required',
                        },
                      ]}
                    >
                      <Input.TextArea
                        showCount
                        maxLength={200}
                        placeholder="Please Explain"
                        rows={4}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  ) : null}
                </Checkbox>
              </li>
            </ul>
          </CheckboxGroup>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CallReasonModal;
