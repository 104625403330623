export default {
  InitialEvaluation: {
    provider_notes: `__ Patient present for initial psychiatric consultation
__ Patient consents to treatment and consultation via telemedicine (remote video visit); patient was informed of limitations of telemedicine; oral and written consent received.
Chief Complaint (Ideally in patient’s own words): [Insert Narrative Here]
Medical history reviewed from the intake form:
____  No significant findings
____   None known allergy
____   Allergic to ______________________________
Psychiatric History:
___ Depressive Symptoms:
___ Anxiety Symptoms:
___ Sleep:
___ Appetite:
___ History of Manic Symptoms: “ ”
___ Psychiatric Hospitalizations: Ry
___ Suicidal Ideation/Suicide Attempts: [List]
___ History of self harm: [Details]
Current psychiatrist and therapist: _________________________
___ Family History of Psychiatric disorder:
___ Substance use, including alcohol:
Age of onset and description of first psychiatric symptoms _______
Age at first mental health treatment and type of treatment _______
Medication trials for relevant psychiatric symptoms-include dosage and how long taken if possible
History of Mood Disorders-include manic and depressive episodes:
___ History of Psychotic symptoms:
“Add your own notes”
History of Present Illness: [Insert Narrative Here]
___ Patient seen via telemedicine.
___ ASRS and other screening results reviewed.
Social History:
Lives with:
Education:
Current employment, school or disability:
Marital status:
Children & ages:
Family involvement:
Financial issues:
Review of history and symptom patterns consistent with typical ADHD:
___ Easily distracted when trying to complete a task
___ Often starting multiple projects without completion
___ Has difficulty completing a task before distraction to start another.
___ Difficulty with motivation leading to procrastination regarding activities that are experienced as uninteresting or non stimulating.
___ Difficulty with reading, retention and recall of information.
___ Describes advancing through multiple pages without recall due to distraction, needing to repeat readings to retain information.
___ Experiences difficulty following conversations without being distracted.
___ Often experiences a general feeling of being restless, needing to move, difficulty sitting for prolonged periods of time.
___ OTHER: “Insert Narrative Here”
PMP Review:
___ No suspicious activity identified
___ Other:
Diagnosis:
___ F90.0 Predominantly inattentive ADHD
___ F90.1 Predominantly hyperactive ADHD
___ F90.2 Combined ADHD
___ F90.9 ADHD (unspecified)
___ Other: “Insert Narrative Here”
Treatment Plan: [Insert Medication Here]
Start/ Refill/ Adjust Dose / Med Change /None; Re-evaluate as needed for tolerance or potential need for adjustment in dosage vs. alteration in regimen.
Reviewed therapeutic duration of medication, dosing strategies, side effects, and assessing improvements in symptoms. Patient to follow up with PCP for routine health monitoring and maintenance; patient agrees to coordinate care as needed.
Discussed various treatment options in detail; Risk vs Benefits, potential side effects, alternatives including no treatment discussed. Patient agrees to start the treatment plan.
Follow-up in ____ weeks/months
___ Recommend Follow-up with PCP

A total of X minutes was spent on DATE_OF_SERVICE conducting the following (leave the ones that apply):
Chart preparation
Counseling
Reporting test results
Ordering medication
Visit documentation
Care coordination
Reviewing separately obtained history
Completing referrals
    `,
    message_to_patient: `Hi DONE_FIRST_NAME,

It was wonderful meeting with you!

This is our current treatment plan, as discussed at your visit:
COPY AND PASTE DIAGNOSIS AND PLAN

Your prescription has been sent to the following pharmacy of your choice:
COPY AND PASTE PHARMACY

FOLLOW UP RECOMMENDATIONS:
We can communicate asynchronously via the "Consultation" tab in your Mindfulhealth patient portal. You can feel free to message me at any time, especially if you suffer any side effects, if the treatment is not working well, or if you have any questions. However, please allow up to 72 hours for response. If experiencing an emergency , please seek out urgent care or call 911.

You can follow-up via a scheduled video follow- up appointment.
In order to closely monitor your progress as well as continue your ongoing medication management, I will AT LEAST need to see you via video follow up appointment every 6 months. Currently your 6 month video check-in is due sometime before:  MONTH/ YY

MEDICATION REFILLS:
You can request refills within your Mindfulhealth patient portal. You should be able to request a new refill within 5 days of when the last monthly refill was sent and as long as you are not overdue for your 6 month check-in.  If treatment is not going well and you feel we may need to make adjustments in your plan, then go ahead and message us or schedule a follow-up appointment as needed to address your needs. https://support.getmindfulhealth.com.
Thank you for being a member of Mindfulhealth, and we look forward to our ongoing partnership!
SIGNATURE



SIDE EFFECTS: Stimulant medication may raise your blood pressure. Check your blood pressure regularly and tell your provider if the results are high. Loss of appetite, weight loss, dry mouth, stomach upset/pain, nausea/vomiting, dizziness, headache, diarrhea, fever, nervousness, change in libido and trouble sleeping may occur. Tell your provider right away if you have any serious side effects including: signs of blood flow problems in the fingers or toes (such as coldness, numbness, pain, or skin color changes,) uncontrolled movements, continuous chewing movements/teeth grinding,  or any mood/behavior changes (such as agitation, aggression, mood swings, depression or abnormal thoughts.)

CAUTION: Get medical help right away if you have any very serious side effects, including: shortness of breath, chest/jaw/left arm pain, fainting, severe headache, fast/pounding/irregular heartbeat, seizures, weakness on one side of the body, trouble speaking, confusion, swollen ankles/feet, extreme tiredness, blurred vision, thoughts of suicide. This is not a complete list of possible side effects. If you notice other effects not listed above, contact your provider or pharmacist.
Misuse or abuse of stimulants may cause serious (possibly fatal) heart and blood pressure problems or risk of tolerance, dependence and addiction. Use only as directed. We do not provide emergency services, if having a psychiatric crisis or emergency crisis please call 911 or go to the nearest Emergency room.

LIFELINE_CONTENT

For more questions, concerns, or side effects please login through our website: https://www.getmindfulhealth.com
  `,
  },
  InitialEvaluationV2: {
    provider_notes: `
Treatment goals:
Pharmacological Interventions:
Start:
Continue:
Discontinue:
Non-Pharmacological Interventions:
1.
2.

The patient reports their symptoms are causing maladaptive functioning and impairment in their personal, occupational, and social settings. The patient has been unable to alleviate these symptoms on their own through non-pharmacological interventions and are in need of medication management which will require continued treatment. The patient currently denies suicidal or homicidal ideation.
Medication choices were discussed and the provider and patient agreed that MEDICATION will be beneficial to manage patient symptoms. The patient verbalized their consent and willingness to participate in the following proposed treatment plan.
We reviewed therapeutic duration of medication, dosing strategies, side effects, and assessing improvements in symptoms.

A total of X minutes was spent on DATE_OF_SERVICE conducting the following (leave the ones that apply):
Chart preparation
Counseling
Reporting test results
Ordering medication
Visit documentation
Care coordination
Reviewing separately obtained history
Completing referrals
    `,
    message_to_patient: `Hi DONE_FIRST_NAME,
It was wonderful meeting you today. Thank you for choosing Mindfulhealth to manage your care. Our mission is to help people reach their full potential by making ADHD treatment accessible for everyone - we are looking forward to being part of your ongoing care team!

This is our current treatment plan, as discussed with PROVIDER_NAME

1. Start: RX_NAME
2. Follow up: message us anytime through Mindfulhealth website if you suffer any side effects, if the treatment is not working well, or if you have any questions. We are here to listen. 
3. Need a Refill? Please visit  https://support.getmindfulhealth.com. If current treatment is working well, you can request a refill without the need for another visit. If your symptoms indicate the need for adjustments in treatment plan, we’ll help you schedule a follow-up appointment to address your needs.
4. Enjoy getting things Mindfulhealth! And let us know if there are any other ways we can help. Your feedback is important to us.

Your prescription has been sent to the following pharmacy of your choice:
PHARMACY_INFORMATION

Please be aware, side effects of stimulants can include decreased appetite, insomnia particularly if taken too late in the day, dry mouth, tics, tremor, agitation, elevated heart rate and blood pressure. Dangerous side effects could include psychotic symptoms, seizures, cardiovascular events and arrhythmia. There is increased risk of arrhythmia or cardiovascular problems in persons with personal history of cardiac disease or stroke or family history of cardiac disease, sudden death or stroke at an early age. There is risk of glaucoma, report vision changes or eye pain. Stimulants have a risk of dependence, tolerance and addiction. If possible, it is helpful to take the medication only on days in which it is needed.

For more questions, concerns, or side effects please login through our website: https://www.getmindfulhealth.com

Thank you for being a member of Mindfulhealth, and we look forward to our ongoing partnership!

PROVIDER_NAME_TITLE
`,
    chief_complaint:
      '{INITIALS} is a {AGE} y/o {GENDER} who presents for an initial psychiatric evaluation with chief complaints of:',

    history_of_present_illness:
      'Patient states they HAVE/ HAVE NOT been previously evaluated for or treated for ADHD. \n' +
      'Patient reports onset of symptoms occurred around the age of \n' +
      'Patient reports they are currently struggling with   \n' +
      'Non-pharmacological interventions that have been tried:',

    social_and_family_history:
      'Housing:\n' +
      'Currently lives with: \n' +
      'The patient reports their housing is stable and currently feels safe in their home environment.\n' +
      'Relationships/Support:\n' +
      'Partner:        feels safe in all relationships\n' +
      'Children:\n' +
      'Family/ Friends:\n' +
      'Career/School\n' +
      'Occupation/ Major:                        \n' +
      'Work/ School hours: \n' +
      'Legal or financial issues:\n' +
      'Physical Activity/ Hobbies:\n' +
      'Family psychiatric history:\n' +
      'Family medical history:',
  },
  FollowUp: {
    provider_notes: `Patient presents for a psychiatric transfer follow-up appointment. 

Patient consents to treatment and consultation via telemedicine (remote video visit); patient was informed of limitations of telemedicine; oral and written consent received.

Last seen for follow-up on **/**/**

Chief Complaint: <%= first_name %> is a <%= age %> y/o <%= gender %> who presents for a follow up evaluation with chief complaints of:

Medical history reviewed - **interim history since last visit**
Currently prescribed ** (name/dose/frequency, adherence, any other issues/concerns)
Side effects reported:
Mood:
Anxiety:
Sleep:
Appetite:

Changes in medical, social, or family hx: **any changes since previous visit, other medications by other prescribers, applicable lab results/EKG results**
Current PCP (if they did not previously have one):
Allergies:
Current Substance use: **any change in Caffeine, Nicotine,  ETOH, Other substance use**
Pregnancy/Planning Pregnancy (female only):
LMP (female only): **/**/**

Suicidal ideation:
Risk (make options in drop down menu to choose multiple options):  None, Suicidal ideation only, Suicidal intent, Suicidal planning, History of suicide attempts, History of impulsive self harm.
Protective factors (make options in drop down menu to choose multiple options): Married, Has children, Other strong interpersonal bonds family/friends, Future oriented, Social support system, Cultural/Spiritual/Religious beliefs that preclude suicide.
Thoughts of Harm to others (make options in drop down menu): None, Ideation only, Intent, Specific target, History of violence towards others
PDMP/Cures Reviewed - (no suspicious activity identified vs. custom notes)

Current Medications: <%= pre_prescription %>

ROS:  A&O x 3. Pleasant and cooperative. Affect is broad and congruent with mood. Neatly groomed, good hygiene. Speech clear with normal rate and rhythm, TP coherent and linear. Memory and cognition intact. I&J fair. No AH/VH, no delusions, no s/s of mania.

A total of X minutes was spent on <%= appointment_date %> conducting the following (leave the ones that apply): Chart preparation
Counseling
Reporting test results
Ordering medication
Visit documentation
Care coordination
Reviewing separately obtained history
Completing referrals

Treatment Plan:

  1. Medication plan:
  2. Follow up in ***

Re-evaluate as needed for tolerance or potential need for adjustment in dosage vs. alteration in regimen.

Reviewed therapeutic duration of medication, dosing strategies, side effects, and assessing improvements in symptoms. Patient to follow up with PCP for routine health monitoring and maintenance; patient agrees to coordinate care as needed.

Discussed various treatment options in detail; Risk vs Benefits, potential side effects, alternatives including no treatment discussed. Patient agrees to the treatment plan.`,
    message_to_patient: `Hello <%= first_name %>, 

It was great speaking with you again today. Thank you for continuing with Mindfulhealth to manage your care. Our mission is to help people reach their full potential by making ADHD treatment accessible for everyone, and we’re happy to have you!

This will be our next phase of treatment plan, as discussed with <%= provider_name %>

1. Start: Take the medication as prescribed
2. Follow up: message us anytime through Mindfulhealth website/App if you suffer any side effects, if the treatment is not working well, or if you have any questions. We are here to listen. 
3. Need a Refill? Please visit  https://support.getmindfulhealth.com. If current treatment is working well, you can request a refill without the need for another visit. If your symptoms indicate the need for adjustments in treatment plan, we’ll help you schedule a follow-up appointment to address your needs.
4. Enjoy getting things Mindfulhealth! And let us know if there are any other ways we can help. Your feedback is important to us.

Your prescription has been sent to the following pharmacy of your choice:

<%= pharmacy %>

Please be aware, side effects of stimulants can include decreased appetite, insomnia particularly if taken too late in the day, dry mouth, tics, tremor, agitation, elevated heart rate and blood pressure. Dangerous side effects could include psychotic symptoms, seizures, cardiovascular events and arrhythmia. There is increased risk of arrhythmia or cardiovascular problems in persons with personal history of cardiac disease or stroke or family history of cardiac disease, sudden death or stroke at an early age. There is risk of glaucoma, report vision changes or eye pain. Stimulants have a risk of dependence, tolerance and addiction. If possible, it is helpful to take the medication only on days in which it is needed.

For more questions, concerns, or side effects please login through our website: https://www.getmindfulhealth.com

Thank you for being a member of Mindfulhealth, and we look forward to our ongoing partnership!

<%= provider_name_with_title %>
`,
  },
  TransferOfCare: {
    provider_notes: `Patient presents for a psychiatric transfer of care follow-up appointment.

Patient consents to treatment and consultation via telemedicine (remote video visit); patient was informed of limitations of telemedicine; oral and written consent received.

Transferred from another Done provider.
Initial Evaluation Date: **/**/**
Last seen for face to face follow-up on **/**/**

Chief Complaint: <%= first_name %> is a <%= age %> y/o <%= gender %> who presents for a transfer of care follow-up evaluation with chief complaints of:

Medical history reviewed - **interim history since last visit**
Currently prescribed ** (name/dose/frequency, adherence, any other issues/concerns)
Side effects reported:
Mood:
Anxiety:
Sleep:
Appetite:

PCP:
	Name of provider-
	Date last seen-
Medical hx: **current/past medical dx, surgical hx, any changes since previous visit, other medications by other prescribers, other vitamins/supplements taken currently, applicable lab results/EKG results**

Social hx: no changes
Family hx: no changes
Psych hx (including any recent psych IP stays, recent self injury, or recent SA): no changes
Substance use: Caffeine -
                            Nicotine/Tobacco -
                            ETOH -
                            Other substances (opioids, meth, cocaine, benzos, etc) -
Pregnancy/Planning Pregnancy (female only):
LMP (female only): **/**/**

Suicidal ideation:
Risk (make options in drop down menu to choose multiple options):  None, Suicidal ideation only, Suicidal intent, Suicidal planning, History of suicide attempts, History of impulsive self harm.
Protective factors (make options in drop down menu to choose multiple options): Married, Has children, Other strong interpersonal bonds family/friends, Future oriented, Social support system, Cultural/Spiritual/Religious beliefs that preclude suicide.
Thoughts of Harm to others (make options in drop down menu): None, Ideation only, Intent, Specific target, History of violence towards others

PDMP/Cures Reviewed - (no suspicious activity identified vs. custom notes)

Current Medications: <%= pre_prescription %>

ROS: A&O x 3. Pleasant and cooperative. Affect is broad and congruent with mood. Neatly groomed, good hygiene. Speech clear with normal rate and rhythm, TP coherent and linear. Memory and cognition intact. I&J fair. No AH/VH, no delusions, no s/s of mania.

A total of X minutes was spent on <%= appointment_date %> conducting the following (leave the ones that apply): Chart preparation
Counseling
Reporting test results
Ordering medication
Visit documentation
Care coordination
Reviewing separately obtained history
Completing referrals

Treatment Plan:

  1. Medication plan:
  2. Follow up in ***

Re-evaluate as needed for tolerance or potential need for adjustment in dosage vs. alteration in regimen.

Reviewed therapeutic duration of medication, dosing strategies, side effects, and assessing improvements in symptoms. Patient to follow up with PCP for routine health monitoring and maintenance; patient agrees to coordinate care as needed.

Discussed various treatment options in detail; Risk vs Benefits, potential side effects, alternatives including no treatment discussed. Patient agrees to the treatment plan.`,
    message_to_patient: `Hello <%= first_name %>, 

It was great speaking with you again today. Thank you for continuing with Mindfulhealth to manage your care. Our mission is to help people reach their full potential by making ADHD treatment accessible for everyone, and we’re happy to have you!

This will be our next phase of treatment plan, as discussed with <%= provider_name %>

1. Start: Take the medication as prescribed
2. Follow up: message us anytime through Mindfulhealth website/App if you suffer any side effects, if the treatment is not working well, or if you have any questions. We are here to listen. 
3. Need a Refill? Please visit  https://support.getmindfulhealth.com. If current treatment is working well, you can request a refill without the need for another visit. If your symptoms indicate the need for adjustments in treatment plan, we’ll help you schedule a follow-up appointment to address your needs.
4. Enjoy getting things Mindfulhealth! And let us know if there are any other ways we can help. Your feedback is important to us.

Your prescription has been sent to the following pharmacy of your choice:

<%= pharmacy %>

Please be aware, side effects of stimulants can include decreased appetite, insomnia particularly if taken too late in the day, dry mouth, tics, tremor, agitation, elevated heart rate and blood pressure. Dangerous side effects could include psychotic symptoms, seizures, cardiovascular events and arrhythmia. There is increased risk of arrhythmia or cardiovascular problems in persons with personal history of cardiac disease or stroke or family history of cardiac disease, sudden death or stroke at an early age. There is risk of glaucoma, report vision changes or eye pain. Stimulants have a risk of dependence, tolerance and addiction. If possible, it is helpful to take the medication only on days in which it is needed.

For more questions, concerns, or side effects please login through our website: https://www.getmindfulhealth.com

Thank you for being a member of Mindfulhealth, and we look forward to our ongoing partnership!

<%= provider_name_with_title %>
`,
  },
  Transfer: {
    provider_notes: `A total of X minutes was spent on DATE_OF_SERVICE conducting the following (leave the ones that apply):
Chart preparation
Counseling
Reporting test results
Ordering medication
Visit documentation
Care coordination
Reviewing separately obtained history
Completing referrals
    `,
  },
  TherapistGeneric: {
    provider_notes: `i. Therapist Notes:
      \u2022 Client Information:
          \u25E6 Session Number: [Session number, if applicable]
          \u25E6 Diagnosis/Reason for Therapy: [Briefly state the client's diagnosis or reason for seeking therapy]
      \u2022 Session Summary:
          \u25E6 Presenting Concerns: [Summarize the main issues the client discussed during this session]
          \u25E6  Client's Emotional State: [Describe the client's emotional state throughout the session]
          \u25E6 Interventions Used: [List any therapeutic interventions, techniques, or exercises used during the session]
          \u25E6 Progress Since Last Session: [Note any progress the client has made since the last session]
          \u25E6 Challenges: [Highlight any challenges or difficulties the client faced during the session]
      \u2022 Assessment:
          \u25E6 Client's Insights: [Note any insights or realizations the client had during the session]
          \u25E6 Themes/Emerging Patterns: [Identify any recurring themes or patterns in the client's thoughts, feelings, or behaviors]
          \u25E6 Strengths: [Highlight the client's strengths that were evident during the session]
          \u25E6 Areas for Focus: [Outline the main areas that should be focused on in upcoming sessions]
      \u2022 Goals and Treatment Plan:
          \u25E6 Long-Term Goals: [Reiterate the long-term goals the client is working towards]
          \u25E6 Short-Term Goals: [List the specific short-term goals discussed in the session]
          \u25E6 Problem List: [Problem #1/LTG /Target Date/ STG/ Intervention Frequency of appts for this/Start Date/Target Date/Completion Date/Strengths/Barriers]
          \u25E6 Treatment Plan: [Outline the general plan for future sessions, including potential interventions and techniques]
      \u2022 Additional Notes:
          \u25E6 Unresolved Issues: [Note any unresolved issues or topics that need further exploration]
          \u25E6 Transference/Countertransference: [Reflect on any transference or countertransference dynamics that may have emerged]
          \u25E6 Collaborative Decisions: [Document any collaborative decisions made with the client regarding the course of therapy]
      \u2022 Plan for Next Session:
          \u25E6 Agenda for Next Session: [Specify the topics or goals for the next session]
          \u25E6 Preparation: [Indicate any materials or resources the therapist or client should prepare for the upcoming session]
          \u25E6 Next Treatment Plan [Update due time: i.e.30 or 60 or 90 days etc.]
      \u2022 Homework/Assignments:
          \u25E6 Homework Assigned: [Detail any homework assignments or tasks given to the client to work on before the next session]
          \u25E6 Rationale: [Explain the purpose and potential benefits of the assigned homework]
      \u2022 Therapist's Reflections:
          \u25E6 Therapist's Observations: [Reflect on the session from the therapist's perspective, including any observations or insights]
          \u25E6 Interventions Assessment: [Evaluate the effectiveness of the interventions used during the session]
          \u25E6 Personal Reactions: [Note any personal reactions, feelings, or thoughts the therapist experienced during the session]
    ii. Mental status exam:
      \u2022 Observation
          \u25E6 Appearance: Neat / Disheveled / Inappropriate / Bizarre / Other: Explain _____
          \u25E6 Behavior: Cooperative / Shy/ Guarded / Hyperactive / Agitated / Aggressive / Paranoid / Bizarre / Withdrawn
          \u25E6 Speech: Normal / Tangential / Impoverished / Pressured / Other: Explain _____
          \u25E6 Eye Contact: Normal / Intense / Avoidant / Other: Explain _____
          \u25E6 Motor Activity: Normal/ Decreased/ Restless/ Agitation / Other: Explain _____
          \u25E6 Movements: Normal / Tics / Slowed / Posture and Gait abnormalities / Other: Explain _____ (i.e. Tremors, Stereotypies...)
      \u2022 Cognition
          \u25E6 Consciousness: Normal Alertness / Drowsy / Lethargic / Confused / Other: Explain _____
          \u25E6 Orientation Impairment: None / Person / Place / Time / Other: Explain _____
          \u25E6 Memory Impairment: Normal / Short-Term / Long-Term / Other: Explain _____
          \u25E6 Attention/Concentration: Normal / Distracted / Other: Explain _____
          \u25E6 Abstract/Conceptualization: [Similarities: i,e. May ask how an apple and orange are similar; / Proverbs: May ask, meaning of 'People who live in a glass house shouldn't throw stones.]
      \u2022 Emotions
          \u25E6 Mood ("patient report"): Excited / Happy / Sad / Anxious / Angry / Depressed / Other: Explain _____
          \u25E6 Affect: Euthymic / Dysphoric / Guarded / Blunted / Anxious / Other: Explain _____
          \u25E6 Range of Affect: Full / Constricted / Labile / Flat / Other: Explain _____ Congruent / Incongruent
      \u2022 Thoughts
          \u25E6 Thought Process: Linear and goal-directed / Rapid / Circumstantial / Tangential / Perseverative / Incoherent / Illogical / Impoverished / Flight of Ideas / Rapid / Thought blocking / Perseveration / Neologisms / Loose associations / Word Salad
          \u25E6 Thought Content:
          \u25E6 Delusions: None / Bizarre/ Grandiose / Paranoid / Religious / Somatic/ Erotomanic / Other: Explain _____ (i.e. Obsessions, Worries, Ruminations, Phobias, ...);
          \u25E6 Suicidality: None / Ideation / Plan / Intent / Self-Harm;
          \u25E6 Homicidality: None / Aggressive / Intent / Plan____
      \u2022 Perceptions:
          \u25E6 Hallucinations: None / Auditory / Visual / Tactile / Olfactory / Gustatory;
          \u25E6 Illusions / Derealization / Depersonalization / Other: Explain _____
          \u25E6 Insight: Good / Fair / Poor
          \u25E6 Judgment: Good / Fair / Poor
          \u25E6 Impulse:  Normal / Impaired: Explain _____
          \u25E6 Reliability: Reliable / Unreliable: Explain _____
          \u25E6 Rapport: Good / Fair / Poor
    iii. Safety Plan and additional CSSRS. Available, but non-required:
      \u2022 C-SSRS: [Questionnaire results and highlights - refer to CODA for materials and links].
      \u2022 Safety Plan:
          \u25E6 Warning signs: [Thoughts, images, mood, situation, behavior that a crisis may be developing].
          \u25E6 Internal coping strategies: [Things patient can do to take my mind off my problems without contacting another person (relaxation techniques, physical activity, individual distraction, mindfulness)].
          \u25E6 People and social settings that provide distraction.
          \u25E6 People whom the patient can ask for help.
          \u25E6 Professionals or agencies the patient can contact during a crisis:
          \u25E6 Making the environment safe: [Ways to secure or limit access to these dangerous tools for the patient.].
    iv. Total time spent
      \u2022 A total of X minutes was spent on DATE_OF_SERVICE conducting face-to-face counseling.
    `,
    message_to_patient: '',
  },
  CoachingNote: {
    provider_notes: `Patient Note for ADHD Coaching Session

Patient Name: [Patient's Name]

Date: [Date]

Coach: [Coach's Name]

Session Number: [1, 2, 3, etc.]

---

Session Overview

Topics Discussed:
- [Topic 1]
- [Topic 2]

Goals:
- [Goal 1]
- [Goal 2]

---

Patient Status

Strengths:
- [Strength 1]
- [Strength 2]

Challenges:
- [Challenge 1]
- [Challenge 2]

---

Coaching Interventions

Strategies Used:
- [Strategy 1]
- [Strategy 2]

Homework:
- [Homework 1]

---

Feedback and Plan

Patient Feedback:
- [Feedback]

Next Session Focus:
- [Focus 1]
- [Focus 2]

Preparation:
- [Preparation if any]`,
    message_to_patient: '',
  },
  Refill: {
    provider_notes: `PDMP reviewed - no aberrant activities found; medical history and chart notes reviewed - NSF; refill request reviewed and sent. Patient reports of stable symptoms, no current side effects, and requests to continue current treatment regimen. Patient declined follow-up face-to-face consultation. `,
    message_to_patient: `Hi <%= first_name %>, I've reviewed your medical history and report. I will continue with your current regimen.

Please take medication as prescribed. Please check back with us if you are suffering any adverse symptoms or side effects. Please note that it is illegal to share or resale controlled medication unless as prescribed.

Please be aware of these potential side effects and/or adverse reactions including, but not limited to:

Decreased appetite, insomnia particularly if taken too late in the day, dry mouth, tics, tremor, agitation or psychosis, elevated heart rate and blood pressure. Dangerous side effects could include psychotic sx, seizures, cardiovascular events and arrhythmia. There is increased risk of arrhythmia or cardiovascular problems in persons with personal hx of cardiac disease or stroke or family history of cardiac or stroke at early age or sudden death. There is risk of worsening or s/e of glaucoma, report vision changes or eye pain. Stimulants have risk of dependency and addiction. People can develop tolerance necessitating increases in doses. If possible, it is helpful to take the medication only on days in which it is needed.

If you have any questions, concerns, or are suffering any side effects, please login through our website: https://www.getmindfulhealth.com

<%= provider_name_with_title %>
  `,
  urgent_refill_message_to_patient: `Hello PATIENT_NAME, I am COVER__NAME. We hope this message finds you well. We are reaching out to inform you of an important matter concerning your medication refill. Unfortunately, your healthcare provider is currently unavailable at this time. However, we are committed to ensuring that you receive uninterrupted access to your prescribed medication.
  
  To facilitate this, we have designated another qualified healthcare professional to provide medication renewal coverage during this period. You can rest assured that your medication needs will be promptly addressed by our team. Please accept our sincere apologies for any inconvenience this temporary situation may cause. Your health and well-being are our top priorities, and we are here to address any questions or concerns you may have. If you have any questions or need assistance, please do not hesitate to contact us.
    `,
    urgent_refill_provider_notes: `Coverage provided by COVER__NAME and approved by PRIMARY_NAME for patient continuity of care. 
    PDMP reviewed - no aberrant activities found; medical history and chart notes reviewed - NSF; refill request reviewed and sent. Patient reports of stable symptoms, no current side effects, and requests to continue current treatment regimen. Patient declined follow-up face-to-face consultation. `,
  },
  AsyncVideoFollowUp: {
    provider_notes: `Patient present for psychiatric follow-up appointment

Patient consents to treatment and consultation via telemedicine (remote video visit); patient was informed of limitations of telemedicine; oral and written consent received.

Chief Complaint: <%= first_name %> is a <%= age %> y/o <%= gender %> who presents for an follow up psychiatric evaluation with chief complaints of:

Medical history reviewed - 
No significant findings OR
“HCP’s own notes”

Cures Reviewed - (no suspicious activity identified vs. custom notes) 

Current Medications: <%= pre_prescription %>

ROS:
A total of X minutes was spent on <%= appointment_date %> conducting the following (leave the ones that apply):
Chart preparation
Counseling
Reporting test results
Ordering medication
Visit documentation
Care coordination
Reviewing separately obtained history
Completing referrals

Treatment Plan:

Start/ Refill/ None ; Re-evaluate as needed for tolerance or potential need for adjustment in dosage vs. alteration in regimen.

Reviewed therapeutic duration of medication, dosing strategies, side effects, and assessing improvements in symptoms. Patient to follow up with PCP for routine health monitoring and maintenance; patient agrees to coordinate care as needed.

Discussed various treatment options in detail; Risk vs Benefits, potential side effects, alternatives including no treatment discussed. Patient agrees to the treatment plan.`,
    message_to_patient: `Hello <%= first_name %>, 

It was great speaking with you again today. Thank you for continuing with Mindfulhealth to manage your care. Our mission is to help people reach their full potential by making ADHD treatment accessible for everyone, and we’re happy to have you!

This will be our next phase of treatment plan, as discussed with <%= provider_name %>

1. Start: Take the medication as prescribed
2. Follow up: message us anytime through Mindfulhealth website/App if you suffer any side effects, if the treatment is not working well, or if you have any questions. We are here to listen. 
3. Need a Refill? Please visit  https://support.getmindfulhealth.com. If current treatment is working well, you can request a refill without the need for another visit. If your symptoms indicate the need for adjustments in treatment plan, we’ll help you schedule a follow-up appointment to address your needs.
4. Enjoy getting things Mindfulhealth! And let us know if there are any other ways we can help. Your feedback is important to us.

Your prescription has been sent to the following pharmacy of your choice:

<%= pharmacy %>

Please be aware, side effects of stimulants can include decreased appetite, insomnia particularly if taken too late in the day, dry mouth, tics, tremor, agitation, elevated heart rate and blood pressure. Dangerous side effects could include psychotic symptoms, seizures, cardiovascular events and arrhythmia. There is increased risk of arrhythmia or cardiovascular problems in persons with personal history of cardiac disease or stroke or family history of cardiac disease, sudden death or stroke at an early age. There is risk of glaucoma, report vision changes or eye pain. Stimulants have a risk of dependence, tolerance and addiction. If possible, it is helpful to take the medication only on days in which it is needed.

For more questions, concerns, or side effects please login through our website: https://www.getmindfulhealth.com

Thank you for being a member of Mindfulhealth, and we look forward to our ongoing partnership!

<%= provider_name_with_title %>
`,
  },
};
