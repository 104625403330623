import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Spin, message } from 'antd';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import styles from './index.less';
const cx = cn.bind(styles);

const PDFViewer = ({ fileUrl, height = 'calc(100vh - 350px)', loading = false }) => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const handleDocumentLoad = (e) => {
    if (!e.doc) {
      console.error('PDF viewer error: Document failed to load');
      message.error('Failed to load PDF file');
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={cx({ pdfViewer: true })}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div style={{ height, overflow: 'hidden' }}>
          <div
            style={{
              alignItems: 'center',
              backgroundColor: '#eeeeee',
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              display: 'flex',
              justifyContent: 'center',
              padding: '4px',
            }}
          >
            <ZoomOutButton />
            <ZoomPopover />
            <ZoomInButton />
          </div>
          <Viewer
            defaultScale={SpecialZoomLevel.PageFit}
            fileUrl={fileUrl}
            plugins={[zoomPluginInstance]}
            onDocumentLoad={handleDocumentLoad}
          />
        </div>
      </Worker>
    </div>
  );
};

PDFViewer.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  height: PropTypes.string,
  loading: PropTypes.bool,
};

export default PDFViewer;
