import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, history,useAccess } from 'umi';
import cn from 'classnames/bind';
import moment from 'moment';
import { Calendar, momentLocalizer, Navigate } from 'react-big-calendar';
import 'moment-timezone';
import { Alert, Button, message, Space, TimePicker } from 'antd';
import { LeftOutlined, MinusCircleOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { useRequest, useSetState, useUnmount } from 'ahooks';
import { srvGetTimePlanMonthList } from '@/services/admin';
import { EditHoursModal } from './components';
import styles from './index.less';
import {
  srcTimePlanTypeList,
  srvDoctorFollowUpMonthlyTimeSlot,
  srcGetFollowupCalFlag, srvGetSixMonthFlag,
} from '@/services/account';
import isEmpty from 'lodash/isEmpty';
import AddAvailablity from './AddAvailability';

const cx = cn.bind(styles);
const ManageAvailability = (props) => {
  const { doctorDetail,updateSetUpShow } = props;
  const { currentUser } = useSelector((state) => state.account);
  const resultUser = doctorDetail ? doctorDetail : currentUser;
  moment.tz.setDefault(resultUser.timezone);
  const [timeSlot, setTimeSlot] = useState({});
  const [state, setState] = useSetState({
    currentMonth: moment().format('YYYY-MM'),
    currentDay: '',
    selectedData: [],
  });
  const editHoursModalRef = useRef(null);
  const [calculating, setCalculating] = useState(false);
  const [loadAddAvailability, setLoadAddAvailability] = useState(false);
  const [autoCreateMonth, setAutoCreateMonth] = useState(3); 
  const access=useAccess();
  const { data: calendarList, refresh } = useRequest(
    () => srvGetTimePlanMonthList({ doctor_id: resultUser.doctor_id, month: state.currentMonth }),
    {
      refreshDeps: [state.currentMonth],
    },
  );

  const fetchSixMonthFlag = useRequest(srvGetSixMonthFlag, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setAutoCreateMonth(ret.data === true ? 6 : 3);
      }
    },
  });

  const { defaultDate, getNow, localizer, myEvents, scrollToTime } = useMemo(() => {
    moment.tz.setDefault(resultUser.timezone);

    let events = [];
    calendarList?.data.forEach((item) => {
      item.items.forEach((i) => {
        events.push({
          start: moment.unix(item.date).toDate(),
          end: moment.unix(item.date).toDate(),
          title: `${i.appointment_type_name} ${i.date_range}`,
          appointment_type_id: i.appointment_type_id,
          startTime: i.date_range.split('-')[0],
          endTime: i.date_range.split('-')[1],
          dateRange: i.date_range,
          date: item.date,
        });
      });
    });
    return {
      defaultDate: moment().toDate(),
      getNow: () => moment().toDate(),
      localizer: momentLocalizer(moment),
      myEvents: events,
      scrollToTime: moment().toDate(),
    };
  }, [resultUser.timezone, calendarList]);
  useUnmount(() => {
    moment.tz.setDefault();
  });
  useEffect(() => {
    fetchSixMonthFlag.run({doctor_id: resultUser.id});
    if (doctorDetail) {
      return;
    }
    if (history.location.query.add_success) {
      setCalculating(true);
      checkFollowupCalFlag();
    } else {
      srvDoctorFollowUpMonthlyTimeSlot().then((res) => {
        if (res.data) {
          setTimeSlot(res.data);
        }
      });
    }
  }, []);
  function checkFollowupCalFlag() {
    srcGetFollowupCalFlag()
      .then((res) => {
        if (res.data) {
          setTimeout(checkFollowupCalFlag, 2000);
        } else {
          srvDoctorFollowUpMonthlyTimeSlot().then((res) => {
            if (res.data) {
              setTimeSlot(res.data);
              setCalculating(false);
            }
          });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  const { data: apptTypes } = useRequest(() => {
    if (doctorDetail) {
      return srcTimePlanTypeList({doctor_id: doctorDetail.doctor_id})
    } else {
      return srcTimePlanTypeList({doctor_id: 0})
    }
  });

  const closeAddAvail = () => {
    setLoadAddAvailability(false);
    updateSetUpShow(true);
    refresh();
  };

  const calculate = () => {
    if (doctorDetail) {
      return;
    }
    setCalculating(true);
    checkFollowupCalFlag();
  };

  const handleSelect = (event) => {
    if (doctorDetail && access.adminAccesser) {
      return
    }
    const selectedData = myEvents.filter((item) => item.date === event.date);
    setState({
      currentDay: event.date,
      selectedData: selectedData,
    });
    editHoursModalRef.current.toggle();
  };

  const onSelectSlot = (slotInfo) => {
    if (doctorDetail && access.adminAccesser) {
      return
    }
    if (moment(slotInfo.end).unix()+86400 < moment().unix()) {
      message.warning('The selected time slot is in the past. Please choose a future time slot.');
      return;
    }
    const selectedData = myEvents.filter(
      (item) =>
        moment(item.start).format('YYYY-MM-DD') === moment(slotInfo.start).format('YYYY-MM-DD'),
    );
    setState({
      currentDay: isEmpty(selectedData) ? moment(slotInfo.start).unix() : selectedData[0].date,
      selectedData,
    });
    editHoursModalRef.current.toggle();
  };
  function onNavigation(d) {
    setState({
      currentMonth: moment(d).format('YYYY-MM'),
    });
  }
  const CustomToolbar = ({ label, onNavigate }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <div>
          <Space>
            <Button
              icon={<LeftOutlined style={{ display: 'block', mariginBottom: '2px' }} />}
              onClick={() => {
                if (state.currentMonth === moment().format('YYYY-MM')) {
                  message.warn('You are already in this month');
                  return;
                }
                onNavigate(Navigate.PREVIOUS);
              }}
            />
            <strong style={{ minWidth: '120px', textAlign: 'center', display: 'block' }}>
              {label}
            </strong>
            <Button
              icon={<RightOutlined style={{ display: 'block', mariginBottom: '2px' }} />}
              onClick={() => {
                const nextThreeMonths = moment().add(autoCreateMonth, 'months').format('YYYY-MM');
                if (state.currentMonth >= nextThreeMonths) {
                  if (autoCreateMonth === 3) {
                    message.warn('You cannot navigate beyond the next three months');
                  } else {
                    message.warn('You cannot navigate beyond the next six months');
                  }
                  return;
                }
                onNavigate(Navigate.NEXT);
              }}
            />
          </Space>
        </div>
        {!(doctorDetail && access.adminAccesser) && (
            <Button
              onClick={() => {
                if (doctorDetail) {
                  updateSetUpShow(false);
                  setLoadAddAvailability(true);
                  return;
                }
                history.push(`/ehr_calendar/add_availability`);
              }}
            >
              Set regular hours
            </Button>
          )}
      </div>
    );
  };

  const EventWrapperComponent = ({ children }) => {
    return <div>{children}</div>;
  };

  function eventStyleGetter(Ï) {
    return {
      style: {
        backgroundColor: 'transparent',
        color: 'black',
        border: '0',
        display: 'block',
        overflow: 'auto',
      },
    };
  }

  return (
    <>
      {!doctorDetail && !loadAddAvailability && (
        <div className={cx({ ehrCalendar: true })}>
          <div className={cx({ top: true })}>
            <div className={cx({ container: true })}>
              <div className={cx({ header: true })}>
                <h3>Manage Availability</h3>
                <Button
                  onClick={() => {
                    history.push('/ehr_calendar');
                  }}
                >
                  Setup complete
                </Button>
              </div>
              <Alert
                style={{ marginBottom: '12px', backgroundColor: '#E6F7FF' }}
                message="The default hours are automatically generated by weekly hours. Select specific date to add, change or override hours for that date."
                type="info"
              />
              {(Object.keys(timeSlot).length === 0 ||
                timeSlot.to_be_booked <= timeSlot.remaining) &&
                !calculating && (
                  <Alert
                    style={{ marginBottom: '20px', backgroundColor: '#E6F7FF' }}
                    type="info"
                    message={
                      <p className="clearfix">
                        Remaining availability/Follow-up to be booked: {timeSlot.remaining}/
                        {timeSlot.to_be_booked}
                        {timeSlot.appointment_requests_count > 0 && (
                          <span>
                            ,requests for initial appointment:{' '}
                            <span
                              style={{ color: 'blue', fontWeight: '500', cursor: 'pointer' }}
                              onClick={() => {
                                history.push(`/ehr_extra_earn/appointment_request`);
                              }}
                            >
                              {timeSlot.appointment_requests_count}
                            </span>
                            .
                          </span>
                        )}
                      </p>
                    }
                  />
                )}
              {!calculating && timeSlot && timeSlot.to_be_booked > timeSlot.remaining && (
                <Alert
                  style={{
                    marginBottom: '20px',
                    backgroundColor: '#FFF1F0',
                    borderColor: '#EA445A59',
                  }}
                  type="info"
                  message={
                    <p className="clearfix">
                      Remaining availability/Follow-up to be booked:{' '}
                      <span
                        style={
                          timeSlot && timeSlot.to_be_booked > timeSlot.remaining
                            ? { color: '#E2445F' }
                            : {}
                        }
                      >
                        {timeSlot.remaining}
                      </span>
                      /{timeSlot.to_be_booked}
                      {timeSlot.appointment_requests_count > 0 && (
                        <span>
                          ,requests for initial appointment:{' '}
                          <span
                            style={{ color: 'blue', fontWeight: '500', cursor: 'pointer' }}
                            onClick={() => {
                              history.push(`/ehr_extra_earn/appointment_request`);
                            }}
                          >
                            {timeSlot.appointment_requests_count}
                          </span>
                          .
                        </span>
                      )}
                      <p>
                        You have limited availability for this month’s follow up(sync). To
                        accommodate more patients, please add more hours to your availability.
                      </p>
                    </p>
                  }
                />
              )}
              {calculating && (
                <Alert
                  style={{ marginBottom: '20px', backgroundColor: '#E6F7FF' }}
                  type="info"
                  message={
                    <p className="clearfix">
                      Remaining availability/Follow-up to be booked: calculating
                    </p>
                  }
                />
              )}
              <Calendar
                defaultDate={defaultDate}
                events={myEvents}
                getNow={getNow}
                localizer={localizer}
                scrollToTime={scrollToTime}
                views={['month']}
                step={60}
                startAccessor="start"
                endAccessor="end"
                popup
                onSelectEvent={handleSelect}
                onNavigate={onNavigation}
                components={{
                  toolbar: CustomToolbar,
                  eventWrapper: EventWrapperComponent,
                }}
                eventPropGetter={eventStyleGetter}
                onSelectSlot={onSelectSlot}
                selectable
              />
            </div>
          </div>
        </div>
      )}
      {doctorDetail && !loadAddAvailability && (
        <div className={cx({ ehrCalendar: true })}>
          {' '}
          <Calendar
            defaultDate={defaultDate}
            events={myEvents}
            getNow={getNow}
            localizer={localizer}
            scrollToTime={scrollToTime}
            views={['month']}
            step={60}
            startAccessor="start"
            endAccessor="end"
            popup
            onSelectEvent={handleSelect}
            onNavigate={onNavigation}
            components={{
              toolbar: CustomToolbar,
              eventWrapper: EventWrapperComponent,
            }}
            eventPropGetter={eventStyleGetter}
            onSelectSlot={onSelectSlot}
            selectable
          />
        </div>
      )}
         <EditHoursModal
            ref={editHoursModalRef}
            currentUser={resultUser}
            currentDay={state.currentDay}
            data={state.selectedData}
            onSave={refresh}
            apptTypes={apptTypes?.data}
            calculate={calculate}
          />
      {loadAddAvailability && (
        <AddAvailablity doctorDetail={doctorDetail} closeAddAvail={closeAddAvail} />
      )}
    </>
  );
};

export default ManageAvailability;
