import { request } from 'umi';
import { stringify } from 'querystring';

export async function srvPatients(param) {
  return request(`/api/ehr/patient/list?${stringify(param)}`);
}

export async function srvTherapistPatientsList(param) {
  return request(`/api/ehr/patient/list/therapist?${stringify(param)}`);
}

export async function srvRefillRequestPatients(param) {
  return request(`/api/ehr/treatment_request/list?${stringify(param)}`);
}

export async function srvPatientDetail(param) {
  return request(`/api/ehr/patient/get?${stringify(param)}`);
}

export async function srvTherapistPatientDetail(param) {
  return request(`/api/ehr/patient/therapist/get?${stringify(param)}`);
}


export async function srvPatientIdentity(param) {
  return request(`/api/ehr/patient/identity?${stringify(param)}`);
}

export async function srvPatientLastAppointment(param) {
  return request(`/api/ehr/patient/appointment/last?${stringify(param)}`);
}

export async function srvPatientLastConfirmedFollowUpAppointment(param) {
  return request(`/api/ehr/patient/appointment/confirmed_follow_up?${stringify(param)}`);
}

export async function srvPatientAppointmentDetail(param) {
  return request(`/api/ehr/patient/appointment/detail?${stringify(param)}`);
}

export async function srvPatientUpcomingAppointments(param) {
  return request(`/api/ehr/patient/appointment/upcoming?${stringify(param)}`);
}

export async function srvPatientPastAppointments(param) {
  return request(`/api/ehr/patient/appointment/past?${stringify(param)}`);
}

export async function srvMarkAppointmentNoShow(data) {
  return request('/api/ehr/patient/appointment/noshow', {
    method: 'post',
    data: data,
  });
}

export async function srvMarkProviderAppointmentNoShow(data) {
  return request('/api/ehr/patient/appointment/provider/noshow', {
    method: 'post',
    data: data,
  });
}

export async function srvMarkNoDiagnosis(data) {
  return request('/api/ehr/patient/treatment_plan/no_diagnosis', {
    method: 'post',
    data: data,
  });
}

export async function srvMarkAppointmentToBeTransfer(data) {
  return request('/api/ehr/patient/treatment_plan/to_be_transfer', {
    method: 'post',
    data: data,
  });
}

export async function srvAddTreatmentPlanAddendum(data) {
  return request('/api/ehr/patient/treatment_plan/add_addendum', {
    method: 'post',
    data: data,
  });
}

export async function srvAddTreatmentPlan(data) {
  return request('/api/ehr/patient/treatment_plan/add', {
    method: 'post',
    data: data,
  });
}

export async function srvAddDraftTreatmentPlan(data) {
  return request('/api/ehr/patient/treatment_plan/draft/add', {
    method: 'post',
    data: data,
  });
}

export async function srvSendConsultationReply(data) {
  return request('/api/ehr/patient/consultation/reply/add', {
    method: 'post',
    data: data,
  });
}

export async function srvAssignConsultation(data) {
  return request('/api/ehr/consultation/assign', {
    method: 'post',
    data: data,
  });
}

export async function srvDoctorSendConsultation(data) {
  return request('/api/ehr/patient/consultation/add', {
    method: 'post',
    data: data,
  });
}

export async function srvDoctorReinstatementApplyConsultation(data) {
  return request('/api/ehr/consultation/reinstatement/apply', {
    method: 'post',
    data: data,
  });
}

export async function srvUpdateDoctorWarningUnlockReinstatementRemind() {
  return request('/api/ehr/doctor/reinstatement/remind/update', {
    method: 'post',
  });
}

export async function srvCheckDoctorAcceptNewPatientReminder() {
  return request('/api/ehr/doctor/accept_new_patient/reminder/check', {
    method: 'get',
  });
}

export async function srvCheckDoctorAcceptNewPatients() {
  return request('/api/ehr/doctor/accept_new_patient/check', {
    method: 'get',
  });
}

export async function srvDoctorReinstatementLastUnread() {
  return request('/api/ehr/consultation/reinstatement/last_unread', {
    method: 'get',
  });
}

export async function srvDoctorDashboardProviderNoShow(param) {
  return request(`/api/ehr/doctor_dashboard_provider_noshow?${stringify(param)}`, {
    method: 'get',
  });
}

export async function srvDoctorRemind(param) {
  return request(`/api/ehr/user/remind?${stringify(param)}`);
}

export async function srvDoctorRemindMark(data) {
  return request('/api/ehr/user/remind/mark', {
    method: 'post',
    data: data,
  });
}

export async function srvSystemMigration(data) {
  return request('/api/ehr/user/migration', {
    method: 'post',
    data: data,
  });
}

export async function srvChangeConsultationStatus(data) {
  return request('/api/ehr/consultation/status/update', {
    method: 'post',
    data: data,
  });
}

// export async function srvGetDoctorDashboardMetricWarningDaysByLastTwoDays(data) {
//   return request('/api/ehr/doctor/dashboard/metric/warning_days', {
//     method: 'post',
//     data: data,
//   });
// }

export async function srvDoctorLastOpenReinstatementApplyConsultation() {
  return request('/api/ehr/consultation/reinstatement/last_open', {
    method: 'get',
  });
}

export async function srvGetDoctorDashboardMetric() {
  return request('/api/ehr/doctor_patient_stat', {
    method: 'get',
  });
}

export async function srvChartAuditCount(param) {
  return request(`/api/ehr/chart_audit/count?${stringify(param)}`);
}

export async function srvChartAuditNote(param) {
  return request(`/api/ehr/chart_audit/note?${stringify(param)}`);
}

export async function srvCheckChartAuditNote(param) {
  return request(`/api/ehr/chart_audit/check?${stringify(param)}`);
}

export async function srvChartAuditNotes(param) {
  return request(`/api/ehr/chart_audit/notes?${stringify(param)}`);
}

export async function srvChartAuditHistoryCount(param) {
  return request(`/api/ehr/chart_audit/history_count?${stringify(param)}`);
}

export async function srvChartAuditHistory(param) {
  return request(`/api/ehr/chart_audit/history?${stringify(param)}`);
}

export async function srvSubmitChartAuditAppraisal(data) {
  return request('/api/ehr/chart_audit/submit', {
    method: 'post',
    data: data,
  });
}

export async function srvChartAuditAppraisal(param) {
  return request(`/api/ehr/chart_audit/appraisal?${stringify(param)}`);
}

export async function srvPatientTreatmentPlanHistories(param) {
  return request(`/api/ehr/patient/treatment_plan/history/list?${stringify(param)}`);
}

export async function srvPatientTreatmentPlanHistoryDetail(param) {
  return request(`/api/ehr/patient/treatment_plan/history/get?${stringify(param)}`);
}

export async function srvDelPatientTreatmentPlanHistory(data) {
  return request('/api/ehr/patient/treatment_plan/history/del', { method: 'post', data: data });
}

export async function srvEditPatientTreatmentPlanHistory(data) {
  return request('/api/ehr/patient/treatment_plan/history/edit', { method: 'post', data: data });
}

export async function srvPatientTreatmentPlanHistoryEditLogs(param) {
  return request(`/api/ehr/patient/treatment_plan/history/logs/list?${stringify(param)}`);
}

export async function srvPatientTreatmentPlanHistoryEditLogDetail(param) {
  return request(`/api/ehr/patient/treatment_plan/history/logs/get?${stringify(param)}`);
}

export async function srvPatientTreatmentPlan(param) {
  return request(`/api/ehr/patient/treatment_plan/get?${stringify(param)}`);
}

export async function srvPatientDraftTreatmentPlan(param) {
  return request(`/api/ehr/patient/treatment_plan/history/draft/get?${stringify(param)}`);
}

export async function srvPatientTreatmentRequests(param) {
  return request(`/api/ehr/patient/treatment_request/list?${stringify(param)}`);
}

export async function srvRescheduleLogs(param) {
  return request(`/api/ehr/reschedule/logs?${stringify(param)}`);
}

export async function srvRescheduleCalendar(param) {
  return request(`/api/ehr/calendar/availability/reschedule?${stringify(param)}`);
}

export async function srvProviderReschedule(data) {
  return request('/api/ehr/auto_reschedule', {
    method: 'post',
    data: data,
  });
}

export async function srvPatientOnGoingTreatmentRequests(param) {
  return request(`/api/ehr/patient/treatment_request/ongoing?${stringify(param)}`);
}

export async function srvPatientPendingTreatmentRequest(param) {
  return request(`/api/ehr/patient/treatment_request/pending?${stringify(param)}`);
}

export async function srvApproveTreatmentRequest(data) {
  return request('/api/ehr/patient/treatment_request/approve', {
    method: 'post',
    data: data,
  });
}

export async function srvAddTreatmentRequestHistory(data) {
  return request('/api/ehr/patient/treatment_request_history/add', {
    method: 'post',
    data: data,
  });
}

export async function srvPatientConsultations(param) {
  return request(`/api/ehr/patient/consultation/list?${stringify(param)}`);
}

export async function srvPatientConsultationReplies(param) {
  return request(`/api/ehr/patient/consultation/reply/list?${stringify(param)}`);
}

export async function srvCalendarAppointments(param) {
  return request(`/api/ehr/patient/appointment/calendar?${stringify(param)}`);
}

export async function srvDoctorBlocks(param) {
  return request(`/api/ehr/timeplan/calendar/block/list?${stringify(param)}`);
}

export async function srvDoctorBlockRemove(param) {
  return request(`/api/ehr/timeplan/calendar/block/delete?${stringify(param)}`, {
    method: 'post'
  });
}

export async function srvFetchAllAppointments(param) {
  return request(`/api/ehr/appointment/list?${stringify(param)}`);
}

export async function srvDoctorAppointmentRequests(param) {
  return request(`/api/ehr/appointment_request/list?${stringify(param)}`);
}

export async function srvDoctorAppointmentRequestPreferredTimes(param) {
  return request(`/api/ehr/doctor/appointment_request/preferred_times?${stringify(param)}`);
}

export async function srvPatientPendingAppointmentRequest(param) {
  return request(`/api/ehr/patient/appointment_request/pending?${stringify(param)}`);
}

export async function srvDoctorAcceptAppointmentRequest(data) {
  return request('/api/ehr/appointment_request/accept', {
    method: 'post',
    data: data,
  });
}

export async function srvDoctorSurveyRatings(param) {
  return request(`/api/ehr/doctor_survey_ratings?${stringify(param)}`);
}

export async function srvDoctorSurveyPatientFeedbacks(param) {
  return request(`/api/ehr/doctor_survey_patient_feedbacks?${stringify(param)}`);
}

export async function srvDoctorTeams(param) {
  return request(`/api/ehr/team/list?${stringify(param)}`);
}

export async function srvTeamPatients(param) {
  return request(`/api/ehr/team/patient/list?${stringify(param)}`);
}

export async function srvAssignedConsultations(param) {
  return request(`/api/ehr/consultation/list?${stringify(param)}`);
}
export async function srvUrgentConsultations(param) {
  return request(`/api/ehr/patient/consultation/urgent_list?${stringify(param)}`);
}
export async function srvCheckPDMP(data) {
  return request('/api/ehr/pdmp/check', {
    method: 'post',
    data: data,
  });
}

export async function srcLogPDMP(data) {
  return request('/api/ehr/pdmp/log', {
    method: 'post',
    data: data,
  });
}

export async function srvUploadToken(data) {
  return request('/api/ehr/upload/token', {
    method: 'post',
    data: data,
  });
}

export async function srvUploadPatientIdentity(data) {
  return request('/api/ehr/patient/identity', {
    method: 'post',
    data: data,
  });
}

export async function srvGetPaitentDocument(param) {
  return request(`/api/ehr/patient/document?${stringify(param)}`);
}

export async function srvGetPaitentConsultationDocument(param) {
  return request(`/api/ehr/patient/document/consultation?${stringify(param)}`);
}

export async function srvDeletePaitentDocument(data) {
  return request('/api/ehr/patient/document', {
    method: 'delete',
    data: data,
  });
}

export async function srvUploadDocument(data) {
  return request('/api/ehr/patient/document', {
    method: 'post',
    data: data,
  });
}

export async function srvGetDocumentURL(param) {
  return request(`/api/ehr/patient/document/url?${stringify(param)}`);
}

export async function srvGetSurveyDisputeResultNotification(param) {
  return request(`/api/ehr/doctor_survey_dispute/result_notification?${stringify(param)}`);
}


export async function srvGetConsultationDocumentURL(param) {
  return request(`/api/ehr/patient/document/consultation/url?${stringify(param)}`);
}

export async function srvLastPendingEncounter(param) {
  return request(`/api/ehr/ayva/encounter/last_pending?${stringify(param)}`);
}

export async function srvGetEncounter(param) {
  return request(`/api/ehr/ayva/encounter/get?${stringify(param)}`);
}

export async function srvEncounterList(param) {
  return request(`/api/ehr/patient/medicine/list?${stringify(param)}`);
}

export async function srvGetPaitentInsurance(param) {
  return request(`/api/ehr/patient/insurance?${stringify(param)}`);
}

export async function srvUploadInsurance(data) {
  return request('/api/ehr/patient/insurance', {
    method: 'post',
    data: data,
  });
}

export async function revertAppointmentStatus(data) {
  return request('/api/ehr/patient/appointment/revert_status', {
    method: 'post',
    data: data,
  });
}

export async function srvIsV2CanaryDoctor(param) {
  return request(`/api/ehr/is_canary_v2_doctor?${stringify(param)}`);
}

export async function srvAddMedicines(data) {
  return request('/api/ehr/patient/medicine/add', {
    method: 'post',
    data: data,
  });
}

export async function srvAddEmptyMedicines(data) {
  return request('/api/ehr/patient/medicine/add_empty', {
    method: 'post',
    data: data,
  });
}

export async function srvChangePharmacy(data) {
  return request('/api/ehr/patient/pharmacy/change', {
    method: 'post',
    data: data,
  });
}

export async function srvChangeRXNTPharmacy(data) {
  return request('/api/ehr/rxnt/patient/pharmacy/change', {
    method: 'post',
    data: data,
  });
}

export async function srvChangePharmacyForCa(data) {
  return request('/api/ehr/rxnt/patient/pharmacy/change/ca', {
    method: 'post',
    data: data,
  });
}

export async function srvOverridePatientRefillDate(data) {
  return request('/api/ehr/patient/treatment_request/discharge/override_refill_date', {
    method: 'post',
    data: data,
  });
}
export async function srvOverridePatientRefillDateV2(data) {
  return request('/api/ehr/patient/treatment_request/discharge/override_refill_date_v2', {
    method: 'post',
    data: data,
  });
}

export async function srvGetPaitentDischargeInfo(param) {
  return request(`/api/ehr/patient/treatment_request/discharge?${stringify(param)}`);
}

export async function srvGetPatientCurrentMembership(param) {
  return request(`/api/ehr/patient/current_membership?${stringify(param)}`);
}

export async function srvUserFeatures(param) {
  return request(`/api/user/feature/list?${stringify(param)}`);
}

export async function srvGetRecommendDrug(param) {
  return request(`/api/es/recommend?${stringify(param)}`);
}

export async function srvGetTwilioVoiceToken(param) {
  return request(`/api/ehr/twilio/voice_token?${stringify(param)}`);
}

export async function srvGetTwilioCallLogPaged(param) {
  return request(`/api/ehr/twilio/list?${stringify(param)}`);
}

export async function srvSyncRxnt(data) {
  return request('/api/ehr/rxnt/sync', {
    method: 'post',
    data: data,
  });
}

export async function srvFetchRxntPrescriptionOrder(data) {
  return request('/api/ehr/rxnt/prescription/fetch_order', { method: 'post', data: data });
}

export async function srvFetchRxntPrescriptionHistories(data) {
  return request('/api/ehr/rxnt/prescription/fetch', { method: 'post', data: data });
}

export async function srvFetchDoctorPatientPharmacistNoteTemplate(param) {
  return request(`/api/ehr/patient/pharmacist_note_template?${stringify(param)}`);
}

export async function srvRxntStatus(param) {
  return request(`/api/ehr/rxnt/status?${stringify(param)}`);
}

export async function srvRxntLogin(data) {
  return request('/api/ehr/rxnt/login', { method: 'post', data: data });
}

export async function srvSearchRxntDrug(data) {
  return request('/api/ehr/rxnt/drug/search', { method: 'post', data: data });
}

export async function srvRxntPrescriptionHistory(param) {
  return request(`/api/ehr/rxnt/prescription/history?${stringify(param)}`);
}

export async function srvRxntActiveMedList(param) {
  return request(`/api/ehr/rxnt/active_med/list?${stringify(param)}`);
}
export async function srvAdminRxntActiveMedList(param) {
  return request(`/api/admin/rxnt/active_med/list?${stringify(param)}`);
}

export async function srvFetchRxntActiveMeds(data) {
  return request('/api/ehr/rxnt/active_med/fetch', { method: 'post', data: data });
}

export async function srvPatientComments(param) {
  return request(`/api/ehr/patient/comment/list?${stringify(param)}`);
}

export async function srvAddPatientComment(data) {
  return request(`/api/ehr/patient/comment`, { method: 'post', data: data });
}

export async function srvUpdatePatientComment(data) {
  return request(`/api/ehr/patient/comment`, { method: 'put', data: data });
}

export async function srvDeletePatientComment(data) {
  return request(`/api/ehr/patient/comment`, { method: 'delete', data: data });
}

export async function srvViewPatientReferrals(param) {
  return request(`/api/ehr/patient/referral/view?${stringify(param)}`);
}

export async function srvGetApprovedReferrals(param) {
  return request(`/api/ehr/referral/list?${stringify(param)}`);
}

export async function srvGetPatientApprovedReferrals(param) {
  return request(`/api/ehr/patient/referral?${stringify(param)}`);
}

export async function srvTransferPatients(param) {
  return request(`/api/ehr/patients?${stringify(param)}`);
}

export async function srvOverduePatients(param) {
  return request(`/api/ehr/patients/overdue?${stringify(param)}`);
}

export async function srvTherapistPatients(param) {
  return request(`/api/ehr/patients/therapist?${stringify(param)}`);
}

export async function srcDischargeTransferInitialNote(param) {
  return request(`/api/ehr/patient/treatment_plan/transfer_initial_note/get?${stringify(param)}`);
}

export async function srvDoctorRequests(param) {
  return request(`/api/ehr/request/list?${stringify(param)}`);
}

export async function srvDoctorDischargeOrTransfer(data) {
  return request('/api/ehr/discharge/patient', {
    method: 'post',
    data: data,
  });
}


export async function srvUrgentRefillRequests(param) {
  return request(`/api/ehr/patient/treatment_request/urgent_list?${stringify(param)}`);
}

export async function srvOccupyRequests(param) {
  return request(`/api/ehr/patient/treatment_request/occupy_request?${stringify(param)}`);
}

export async function srvGetUrgentRefillPopFlag(param) {
  return request(`/api/ehr/patient/show/urgent_refill/pop?${stringify(param)}`);
}

export async function srvReuqestInternalNotes(param) {
  return request(`/api/ehr/request/get/internal/notes?${stringify(param)}`);
}

export async function srvAddRequestInternalNotes(data) {
  return request('/api/ehr/request/add/internal/note', {
    method: 'post',
    data: data,
  });
}

export async function srvSubmitRefillDispute(data) {
  return request('/api/ehr/patient/refill_dispute/submit', {
    method: 'post',
    data: data,
  });
}

export async function srvAddSurveyDispute(param) {
  return request('/api/ehr/doctor_survey_dispute/add', {
    method: 'post',
    data: param,
  });
}
export async function srvGetPaitentDiagnosisProof(param) {
  return request(`/api/ehr/patient/diagnosis/proof?${stringify(param)}`);
}

export async function srvUploadDiagnosisProof(data) {
  return request('/api/ehr/patient/diagnosis/proof', {
    method: 'post',
    data: data,
  });
}

export async function srvGetMedicalFileRequests(param) {
  return request(`/api/ehr/medical_file_requests?${stringify(param)}`);
}

export async function srvDoctorApproveMedicalFileRequest(param) {
  return request(`/api/ehr/medical_file_request/approve?${stringify(param)}`, {
    method: 'post'
  });
}

export async function srvDoctorGetMedicalFileLink(param) {
  return request(`/api/ehr/medical_file/get_link?${stringify(param)}`);
}

export async function srvDoctorAddMedicalFileRevision(data) {
  return request('/api/ehr/medical_file_request/request_revision', {
    method: 'post',
    data: data,
  });
}
