import React, { useState, useEffect, createRef } from 'react';
import cn from 'classnames/bind';
import { connect, history } from 'umi';
import { Tabs, Badge } from 'antd';

import RenewalRequest from './RenewalRequest';
import styles from './index.less';
const { TabPane } = Tabs;
const cx = cn.bind(styles);

const PrescriptionIndex = (props) => {
  const { account } = props;
  const { adminStat } = account;
  const [activeKey, setActiveKey] = useState('1');

  const onTabChange = (v) => {
    setActiveKey(v);
  };

  return (
    <div className={cx({ container: true })}>
      <Tabs defaultActiveKey="1" activeKey={activeKey} onTabClick={onTabChange}>
        <TabPane tab="Prescription Renewals" key="1">
          <RenewalRequest active={activeKey === '1'} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default connect(({ global, account, settings }) => ({
  collapsed: global.collapsed,
  account,
  settings,
}))(PrescriptionIndex);
