import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link } from 'umi';
import { Tag, Space, Popover, Input, Table, Row, Col, Button, Menu, Dropdown } from 'antd';
import moment from 'moment';
import { EllipsisOutlined, SearchOutlined, createFromIconfontCN } from '@ant-design/icons';
import numeral from 'numeral';
import { AsYouType } from 'libphonenumber-js';
import { stringify } from 'querystring';

import Filters from '@/components/Filters';
import defaultSettings from '../../../defaultSettings';
import RefundModal from './RefundModal';

import { formatUnix } from '../../utils/xtime';
import styles from './index.less';
const cx = cn.bind(styles);

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const filters = [
  {
    name: 'user_email',
    label: 'Patient Email',
    type: 'text',
  },
  {
    name: 'user_phone',
    label: 'Patient Phone',
    type: 'text',
  },
  {
    name: 'amount',
    label: 'Amount',
    type: 'number',
  },
  {
    name: 'product_id',
    label: 'Product',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'status',
    label: 'Status',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'created_at',
    label: 'Date',
    type: 'date',
  },
];

const mapStatus2Color = (status) => {
  switch (status) {
    case 1:
      return 'processing';
    case 2:
      return 'success';
    case 3:
      return 'error';
    case 4:
      return 'error';
    case 5:
      return 'processing';
    case 6:
      return 'success';
    case 7:
      return 'error';
    case 8:
      return 'error';
    default:
      return 'success';
  }
};

const Patient = (props) => {
  const { dispatch, payment, loading } = props;
  const { data, payment_products, payment_statuses } = payment;
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [refundModalVisible, setRefundModalVisible] = useState(false);
  const [refund, setRefund] = useState({});

  const columns = [
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      ellipsis: true,
      width: 80,
      render: (v, r) => {
        const val = numeral(v / 100).format('0,0.00');
        return <span style={{ color: '#1a1f36', fontWeight: 500 }}>{val}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        if (r.status == 3) {
          return (
            <Popover
              content={<div className="popover-user">{r.reason}</div>}
              title="Reason"
              trigger="hover"
            >
              <Tag color={mapStatus2Color(r.status)}>{r.status_name}</Tag>
            </Popover>
          );
        }
        return <Tag color={mapStatus2Color(r.status)}>{r.status_name}</Tag>;
      },
    },
    {
      title: 'Product',
      dataIndex: 'id',
      key: 'id',
      width: 190,
      ellipsis: true,
      render: (v, r) => {
        if (r.order) {
          return <Tag>{r.order.product_id_name}</Tag>;
        }
        return '';
      },
    },

    {
      title: 'Stripe/Paypal ID',
      dataIndex: 'external_charge_id',
      key: 'external_charge_id',
      ellipsis: true,
    },
    {
      width: 150,
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: 'Patient',
      dataIndex: 'user_id',
      key: 'user_id',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        if(!r.patient){
          return '';
        }
        const userName = `${r.patient.first_name} ${r.patient.last_name}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.user_id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/patient/${r.user_id}`}>{r.patient.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.patient.phone)}
                </div>
                <div className="popover-user-item address">
                  <IconFont className="icon" type="icon-address" />
                  {r.patient.zipcode}
                </div>
                <div className="popover-user-item sex">
                  <IconFont className="icon" type="icon-sex" />
                  {r.patient.gender}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <Link to={`/patient/${r.patient.id}`}>{userName}</Link>
          </Popover>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      fixed: 'right',
      ellipsis: true,
      width: 50,
      render: (v, r) => {
        return (
          <Dropdown
            overlay={
              <div className={cx({ paymentOpt: true })}>
                {(r.status === 2 || r.status === 7 || r.status === 8 || (r.status === 6 && r.amount > r.amount_refunded)) && (
                  <div
                    className={cx({ paymentOptItem: true })}
                    onClick={() => {
                      showRefundModal(r);
                    }}
                  >
                    <a href="#">Refund</a>
                  </div>
                )}
                <div className={cx({ paymentOptItem: true })}>
                  <Link to={`/payment/${r.id}`}>View Payment Details</Link>
                </div>
              </div>
            }
            trigger={['click']}
          >
            <Button size="small">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    setPage(1);
    loadList({ page: 1, page_size: 20 }, () => {});
    dispatch({ type: 'payment/fetchPaymentProducts' });
    dispatch({ type: 'payment/fetchPaymentStatuses' });
  }, []);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => {});
  };

  const loadList = (payload, callback) => {
    dispatch({ type: 'payment/fetchList', payload, callback });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadList({
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: JSON.stringify(query),
    });
  };

  const f = () => {
    for (const v of filters) {
      if (v.name === 'product_id' && v.items.length === 0) {
        v.items = payment_products;
        continue;
      }

      if (v.name === 'status' && v.items.length === 0) {
        v.items = payment_statuses;
      }
    }
    return filters;
  };

  let modalProps = {
    visible: refundModalVisible,
    item: refund,
    maskClosable: false,
    closable: false,
    okText: 'Refund',
    width: 700,
    onOk: (payload) => {
      dispatch({
        type: 'payment/refundPayment',
        payload,
        callback: () => {
          dispatch({ type: 'payment/fetchPaymentItem', payload: { id: payload.id } });
          setRefund({});
          setRefundModalVisible(false);
        },
      });
    },
    onCancel: () => {
      setRefund({});
      setRefundModalVisible(false);
    },
    okButtonProps: {
      style: {
        with:'180px !important',
        border: 'none',
        borderRadius: '4px',
        marginBottom: '15px',
        marginTop: '10px',
      },
    },
    cancelButtonProps: {
      style: {
        backgroundColor: '#ffffff',
        color: '#566BCD',
        border: 'none',
        float: 'left',
        textAlign: 'left',
        marginBottom: '15px',
        marginTop: '10px',
      },
    },
  };

  const showRefundModal = (item) => {
    setRefund(item);
    setRefundModalVisible(true);
  };

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Payments</h3>
        <Space>
          <Filters filters={f()} onFilter={onFilter} />
        </Space>
      </div>

      <Table
        columns={columns}
        dataSource={data.items}
        scroll={{ x: 1200 }}
        rowClassName={(r) => {
          if (r.id == refund.id) {
            return 'rowSelected';
          }
          return '';
        }}
        rowKey="id"
        loading={loading.effects['payment/fetchList']}
        onChange={handleTableChange}
        pagination={{ pageSize: 20, current: page, simple: true, total: data.total }}
      />

      {refundModalVisible && <RefundModal {...modalProps} />}
    </div>
  );
};

export default connect(({ payment, loading }) => ({
  payment,
  loading,
}))(Patient);
