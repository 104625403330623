import { Modal } from 'antd';
import { create } from 'zustand';
import { srvGetPatientAppointmentQuestionResponse } from '@/services/account';
import { srvPatientDetail } from '@/services/patient';
import { useUserStore } from './useUserStore';
import { TREATMENT_PLAN_NOTE_TYPES } from '@/constants';

export const useNoteStore = create((set, get) => ({
  visible: false,
  loading: true,
  patient: {},
  noteFormInstance: null,
  noteTypeOptions: [],
  setNoteFormInstance: (noteFormInstance) => set({ noteFormInstance }),
  setNoteTypeOptions: () => {
    const { profile } = useUserStore.getState();
    const options = [];
    if (profile.doctor_type === 3) {
      options.push({
        value: TREATMENT_PLAN_NOTE_TYPES.THERAPIST_GENERIC,
        label: 'Generic Therapy Note',
      });
    } else if (profile.doctor_type === 5) {
      options.push({
        value: TREATMENT_PLAN_NOTE_TYPES.COACHING_NOTE,
        label: 'Coaching Note',
      });
    } else {
      options.push(
        { value: TREATMENT_PLAN_NOTE_TYPES.INITIAL_EVALUATION, label: 'Initial Evaluation' },
        { value: TREATMENT_PLAN_NOTE_TYPES.FOLLOW_UP_EVALUATION, label: 'Follow-Up Evaluation' },
        { value: TREATMENT_PLAN_NOTE_TYPES.TRANSFER_OF_CARE, label: 'Follow-Up: Transfer of Care' },
        { value: TREATMENT_PLAN_NOTE_TYPES.REFILL_REQUEST_NOTE, label: 'Refill Request Note' },
        { value: TREATMENT_PLAN_NOTE_TYPES.PLAIN_NOTE, label: 'Plain Note' },
        { value: TREATMENT_PLAN_NOTE_TYPES.TRANSFER, label: 'Transfer' },
        { value: TREATMENT_PLAN_NOTE_TYPES.DISCHARGE, label: 'Discharge' },
      );

      // if (appointmentResponse) {
      //   options.push({
      //     value: 'AsyncVideoFollowUp',
      //     label: 'Async Video Follow-Up',
      //   });
      // }
      set({ noteTypeOptions: options });
    }
  },
  open: async (patientId) => {
    console.log('open', patientId);
    // if modal is open
    if (get().visible) {
      Modal.confirm({
        title: 'You have an unfinished note',
        content: 'Please complete it before adding a new one.',
        onOk: () => {
          set({ visible: true, });
        },
      });
      return
    }
    set({ visible: true });
    if (patientId !== get().patient?.id) {
      set({ loading: true });
      const res = await srvPatientDetail({ id: patientId });
      set({ patient: res.data, loading: false });
      console.log('@@@@', get().noteFormInstance);
      get().noteFormInstance.setFieldsValue({
        note_type: TREATMENT_PLAN_NOTE_TYPES.FOLLOW_UP_EVALUATION,
      });
    }
  },
  toggle: () =>
    set((state) => ({
      visible: !state.visible,
    })),
}));
