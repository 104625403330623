import React, { useState } from 'react';
import { Select,Form,Checkbox,Space } from 'antd';
import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);
const { Option } = Select;

const AsyncSelectFilter = ({ form, name, label, fetchOptions }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const defaultVisible = !!form.getFieldValue(name);
  const [visible, setVisible] = useState(defaultVisible);
  const handleSearch = async (value) => {
    if (fetchOptions) {
      setLoading(true);
      const data = await fetchOptions(value);
      setOptions(data);
      setLoading(false);
    }
  };

  return (
    <div className={cx({ filtersItem: true })}>
    <div className={cx({ filtersItemSwitch: true })}>
      <Checkbox
        checked={visible}
        onChange={(v) => {
          setVisible(v.target.checked);
        }}
      >
        {label}
      </Checkbox>
    </div>
    <div className={cx({ filtersItemBox: true, visible: visible })}>
      {visible && (
            <Form.Item name={name}>
      <Select
        showSearch
        placeholder={`Search ${label}`}
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={handleSearch}
        loading={loading}
      >
        {options.map(option => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
      )}
    </div>
  </div>
  );
};

export default AsyncSelectFilter;