import React, { useState, useEffect, useRef } from 'react';
import { Space, Table, Button, message, Popover } from 'antd';
import { connect, Link, useAccess } from 'umi';

import { srvChartAuditNotes, srvCheckChartAuditNote } from '@/services/patient';
import { useRequest, useMount } from 'ahooks';
import { formatUnix } from '@/utils/xtime.js';
import moment from 'moment';

import Filters from '@/components/Filters';
import DoctorChartAuditDrawer from '@/components/DoctorChartAuditDrawer'

import defaultSettings from '../../../defaultSettings';
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });
import { createFromIconfontCN, EnvironmentFilled,  CalendarFilled, } from '@ant-design/icons';
import { useChartAuditStore } from '@/stores';
import cn from 'classnames/bind';
import styles from './index.less'
const cx = cn.bind(styles);

const EHRChartAuditNotes = () => {

  const { open, chartAuditEvent } = useChartAuditStore();
  const [data, setData] = useState({ total: 0, items: [] });
  const [query, setQuery] = useState({});
  const [sort, setSort] = useState({});
  const [page, setPage] = useState(1);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [audit, setAudit] = useState({});

  const filters = [
    {
      name: 'audit_status',
      label: 'Status',
      type: 'checkbox',
      items: [
        { label: 'PendingReview', value: 1 },
        { label: 'Complete', value: 2 },
      ],
    },
    {
      name: 'doctor_name',
      label: 'Provider',
      type: 'text',
    },
    {
      name: 'patient_name',
      label: 'Patient',
      type: 'text',
    },
    {
      name: 'auditor_name',
      label: 'Chart Auditor',
      type: 'text',
    },
  ];

  const columns = [
    {
      title: 'Provider',
      dataIndex: 'doctor_id',
      key: 'doctor_id',
      ellipsis: true,
      width: 120,
      render: (v, r) => {
        return r.doctor_name;
      },
    },
    {
      title: 'Patient',
      dataIndex: 'patient_id',
      key: 'patient_id',
      ellipsis: true,
      width: 120,
      render: (v, r) => {
        const userName = r.patient_name;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.patient_id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item birthdate">
                  <CalendarFilled className="icon" />
                  {r.patient.birthdate &&
                    moment.unix(r.patient.birthdate).utc().format('MM/DD/YYYY')}
                </div>
                <div className="popover-user-item sex">
                  <IconFont className="icon" type="icon-sex" />
                  {r.patient.gender}
                </div>
                <div className="popover-user-item state">
                  <EnvironmentFilled className="icon" />
                  {r.patient.state}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <Link to={`/patient/${r.patient_id}`} style={{ color: '#1a1f36', fontWeight: 500 }}>
              {userName}
            </Link>
          </Popover>
        );
      },
    },
    {
      title: 'Note Type',
      dataIndex: 'note_type_name',
      key: 'note_type_name',
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Created At',
      ellipsis: true,
      dataIndex: 'note_created_at',
      key: 'note_created_at',
      width: 150,
      render: (v, r) => {
        return formatUnix(r.note_created_at, 'MM/DD/YYYY h:mm A');
      },
    },
    {
      title: 'status',
      dataIndex: 'audit_status',
      key: 'audit_status',
      sorter: true,
      ellipsis: true,
      width: 120,
      render: (v, r) => {
        return r.audit_status_name;
      },
    },
    {
      title: 'score',
      dataIndex: 'score',
      key: 'score',
      sorter: true,
      ellipsis: true,
      width: 60,
      render: (v, r) => {
        return r.audit_status === 2 ? r.score : '';
      },
    },
    {
      title: 'Chart Auditor',
      dataIndex: 'auditor_id',
      key: 'auditor_id',
      ellipsis: true,
      width: 120,
      render: (v, r) => {
        return r.auditor_name;
      },
    },
    {
      title: 'Date',
      ellipsis: true,
      dataIndex: 'audit_at',
      sorter: true,
      key: 'audit_at',
      width: 150,
      render: (v, r) => {
        if (!r.audit_at) {
          return '';
        }
        return formatUnix(r.audit_at, 'MM/DD/YYYY h:mm A');
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        return (
          <Button
            size="small"
            type={r.audit_status === 2 ? 'default' : 'primary'}
            onClick={() => {
              setAudit(r);
              checkChartAuditNoteReq.run({ id: r.id });
            }}
          >
            {r.audit_status === 2 ? 'View' : 'Review'} Chart
          </Button>
        );
      },
    },
  ];

  const chartAuditNotesReq = useRequest(srvChartAuditNotes, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setData(ret.data);
      }
    },
  });

  const checkChartAuditNoteReq = useRequest(srvCheckChartAuditNote, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (ret.data.has_occupy_flag) {
          onRefresh();
          message.error('The current chart audit is locked, please try again later', 10);
        } else {
          if (audit.audit_status === 1) {
            open(audit);
          } else {
            setDrawerVisible(true);
          }
        }
      }
    },
  });
  chartAuditEvent.useSubscription((event) => {
      console.log('event', event);
      onRefresh();
  });

  useEffect(() => {
    onRefresh();

  }, []);

  const onRefresh = () => {
    loadList(query, sort, { page: 1, page_size: 20 });
  }

  const loadList = (filters, sorters = {}, params) => {
    let filtersData = filters ? filters : {};
    let filtersObj = { ...filtersData };
    chartAuditNotesReq.run({ filters: JSON.stringify(filtersObj), sorter: JSON.stringify(sorters),  ...params });
  };

  const handleTableChange = (pagination, filters, sorters) => {
    setPage(pagination.current);
    let sorter = {};
    if (sorters && sorters.order) {
      sorter[sorters.field] = sorters.order === "ascend" ? 'ASC' : 'DESC';
      setSort(sorter);
    }
    loadList(query, sorter, { page: pagination.current, page_size: pagination.pageSize });
  };

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList(q, sort, { page: 1, page_size: 20 });
  };

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Chart Audit</h3>
        <Space>
          <Filters filters={filters} onFilter={onFilter} />
        </Space>
      </div>

      <Table
        columns={columns}
        dataSource={data.items}
        rowClassName={(r) => {
          return '';
        }}
        scroll={{ x: 800 }}
        rowKey="id"
        loading={chartAuditNotesReq.loading}
        onChange={handleTableChange}
        pagination={{ pageSize: 20, current: page, simple: true, total: data.total }}
      />

      {drawerVisible && (
        <DoctorChartAuditDrawer
          visible={drawerVisible}
          setVisible={setDrawerVisible}
          audit={audit}
          onRefresh={onRefresh}
        />
      )}
    </div>
  )
}

export default EHRChartAuditNotes;
