import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, Link } from 'umi';
import { Space, Popover, Input, Table, Row, Col, Button, Tag, Modal } from 'antd';
import { createFromIconfontCN } from '@ant-design/icons';
import { useRequest, useMount } from 'ahooks';
import { getFilterContent, getPatientName } from '@/utils/utils';

import { srvAllDoctors } from '@/services/admin';

import Filters from '@/components/Filters';
import defaultSettings from '../../../defaultSettings';
import { AsYouType } from 'libphonenumber-js';

import { formatUnix } from '@/utils/xtime';
import styles from './index.less';
const cx = cn.bind(styles);

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const AdminPatientList = (props) => {
  const { dispatch, patient, loading, parentProps, activeKey } = props;
  const { data } = patient;
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [allDoctors, setAllDoctors] = useState([]);
  let searchQuery = parentProps.location.query.q || '';
  const filters = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
    },
    {
      name: 'email',
      label: 'Email',
      type: 'text',
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'text',
    },
    {
      name: 'created_at',
      label: 'Date',
      type: 'date',
    },
    {
      name: 'patient_type',
      label: 'Patient Type',
      type: 'checkbox',
      items: [
        { label: 'Normal', value: 0 },
        { label: 'Pediatric', value: 1 },
        { label: 'Insomnia', value: 3 },
      ],
    },
    {
      name: 'clinician',
      label: 'Clinician',
      type: 'select',
      items: ([...new Set(allDoctors.map(item => item.name))]).map((name) => {
        return { label: name, value: name };
      }),
    },
    {
      name: 'membership_status',
      label: 'Membership Status',
      type: 'checkbox',
      items: [
        { label: 'Active', value: 2 },
      ],
    },
    {
      name: 'patient_status',
      label: 'Patient Status',
      type: 'checkbox',
      items: [
        { label: 'Discharge', value: 3 },
      ],
    },
    {
      name: 'user_classification',
      label: 'Patient Origin',
      type: 'checkbox',
      items: [
        { label: 'Mindful', value: 1 },
        { label: 'Done', value: 0 },
      ],
    }
  ];

  const columns = [
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        const userName = getPatientName(r);
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/patient/${r.id}`}>{r.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.phone)}
                </div>
                <div className="popover-user-item address">
                  <IconFont className="icon" type="icon-address" />
                  {r.zipcode}
                </div>
                <div className="popover-user-item sex">
                  <IconFont className="icon" type="icon-sex" />
                  {r.gender}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <Link to={`/patient/${r.id}`} style={{ color: '#1a1f36', fontWeight: 500 }}>
              {new AsYouType('US').input(r.phone)}
            </Link>
          </Popover>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      width: 210,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (v, r) => renderNameColumn(r),
    },
    {
      title: 'Clinician',
      dataIndex: 'doctor_name',
      key: 'doctor_name',
      ellipsis: true,
      render: (v, r) => {
        if (!r.active_doctor) {
          return 'No Doctor';
        }
        const userName = `${r.active_doctor.name} ${r.active_doctor.title}`;
        return <Link to={`/doctor/detail/${r.active_doctor.id}`}>{userName}</Link>;
      },
    },
    {
      title: 'Membership',
      dataIndex: 'id',
      ellipsis: true,
      width: 100,
      key: 'id',
      render: (v, r) => {
        if (!r.membership) {
          return ' ';
        }

        const status = `${r.first_name} ${r.last_name}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item">
                  <Link to={`/patient/${r.id}`}>
                    {formatUnix(r.membership.begin_date, 'MM/DD/YYYY')} --{' '}
                    {formatUnix(r.membership.end_date, 'MM/DD/YYYY')}
                  </Link>
                </div>
              </div>
            }
            title="Membership"
            trigger="hover"
          >
            <a href="#">{r.membership.status_name}</a>
          </Popover>
        );
      },
    },
    {
      title: 'Date',
      ellipsis: true,
      dataIndex: 'created_at',
      width: 200,
      key: 'created_at',
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
    },
  ];

  useMount(async () => {
    fetchAllDoctorsReq.runAsync()
    if (searchQuery !== '') {
      const filterContent = getFilterContent(searchQuery);
      setQuery(filterContent);
      setPage(1);
      loadList({ page: 1, page_size: 20, filters: JSON.stringify(filterContent) }, () => { });
    }
  });

  useEffect(() => {
    const filterContent = getFilterContent(searchQuery);
    if (searchQuery !== '') {
      setQuery(filterContent);
    }
    setPage(1);
    loadList({ page: 1, page_size: 20, filters: JSON.stringify(filterContent) }, () => { });
  }, [parentProps.location, activeKey]);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => { });

  };

  const loadList = (payload, callback) => {
    dispatch({ type: 'patient/fetchList', payload, callback });
  };

  function renderNameColumn(r) {
    const isMindful = r.user_classification === 1;
    let name = r.name;
    if (isMindful && name.startsWith('Done')) {
      name = name.replace('Done', 'Mindful');
    }

    // only show pediatric and insomnia tag
    const patientTypes = {
      // 0: 'Normal',
      1: 'Pediatric',
      // 2: 'Diagnosed',
      3: 'Insomnia',
    };

    return (<>
      {getPatientName(r)}
      {patientTypes?.[r.patient_type] && <Tag color="magenta">{patientTypes[r.patient_type]}</Tag>}
      <Tag color="green" style={{ marginLeft: '5px' }}>{isMindful ? 'Mindful' : 'Done'}</Tag>
      {r.subscription_type===2&&<Tag color="red" style={{ marginLeft: '5px' }}>{'Annual'}</Tag>}
    </>)

  }

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadList({
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: JSON.stringify(query),
    });
  };

  const fetchAllDoctorsReq = useRequest(srvAllDoctors, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setAllDoctors(ret.data.items)
      }
    },
  });

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Patients</h3>
        <Space>
          <Filters filters={filters} onFilter={onFilter} />
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={data.items}
        rowKey="id"
        loading={loading.effects['patient/fetchList']}
        scroll={{ x: 1200 }}
        onChange={handleTableChange}
        pagination={{
          pageSize: 20,
          total: data.total,
          simple: true,
          current: page,
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} of ${total} items`;
          },
        }}
      />
    </div>
  );
};

export default connect(({ patient, loading }) => ({
  patient,
  loading,
}))(AdminPatientList);
