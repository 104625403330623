import { pathToRegexp } from 'path-to-regexp';

export const matchParamsPath = (pathname, breadcrumb, breadcrumbMap) => {
  // Internal logic use breadcrumbMap to ensure the order
  if (breadcrumbMap) {
    const pathKey = [...breadcrumbMap.keys()].find((key) => pathToRegexp(key).test(pathname));
    if (pathKey) {
      return breadcrumbMap.get(pathKey);
    }
  }

  // External uses use breadcrumb
  if (breadcrumb) {
    const pathKey = Object.keys(breadcrumb).find((key) => pathToRegexp(key).test(pathname));

    if (pathKey) {
      return breadcrumb[pathKey];
    }
  }

  return {
    path: '',
  };
};

const getPageTitleInfo = (props, ignoreTile) => {
  const {
    pathname = '/',
    breadcrumb,
    breadcrumbMap,
    title = 'Done EHR',
    menu = {
      locale: false,
    },
  } = props;
  const pageTitle = ignoreTile ? '' : title;
  const currRouterData = matchParamsPath(pathname, breadcrumb, breadcrumbMap);
  if (!currRouterData) {
    return {
      title: pageTitle,
      id: '',
      pageName: pageTitle,
    };
  }
  let pageName = currRouterData.name;

  if (!pageName) {
    return {
      title: pageTitle,
      id: currRouterData.locale || '',
      pageName: pageTitle,
    };
  }
  if (ignoreTile) {
    return {
      title: pageName,
      id: currRouterData.locale || '',
      pageName,
    };
  }
  return {
    title: `${pageName} - ${title}`,
    id: currRouterData.locale || '',
    pageName,
  };
};

export { getPageTitleInfo };

const getPageTitle = (props, ignoreTile) => {
  return getPageTitleInfo(props, ignoreTile).title;
};

export default getPageTitle;
