import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox, Divider, Radio } from 'antd';

import { useMount, useUpdateEffect, useSetState } from 'ahooks';
import CheckboxTextInputFormItem from './CheckboxTextInputFormItem';
import ExclusiveCheckboxWithTextInput from './ExclusiveCheckboxWithTextInput';
import { substanceUseHistoryItems, substanceUseHistoryFemaleItems } from './meta'
import RadioGroupWithTextInput from './RadioGroupWithTextInput';

const SubstanceUseHistoryFormItems = (props) => {
  const { form, draft, setDraft } = props;

  // const [radioGroupCurrentMATValue, setRadioGroupCurrentMATValue] = useState(null);
  // const [radioGroupDisorderHistoryValue, setRadioGroupDisorderHistoryValue] = useState(null)

  // useUpdateEffect(() => {
  //   if (draft?.substance_use_current_mat != "") {
  //     setRadioGroupCurrentMATValue(draft?.substance_use_current_mat === 'No' ? 'No' : 'Yes')
  //   }
  //   if (draft?.substance_use_disorder_history != "") {
  //     setRadioGroupDisorderHistoryValue(draft?.substance_use_disorder_history === 'No' ? 'No' : 'Yes')
  //   }
  // }, [draft]);

  // const onRadioGroupCurrentMATChange = (e) => {
  //   setRadioGroupCurrentMATValue(e.target.value);
  //   setDraft(Object.assign({ ...draft }, { substance_use_current_mat: e.target.value === 'No' ? 'No' : '' }));
  // }

  // const onRadioGroupDisorderHistoryChange = (e) => {
  //   setRadioGroupDisorderHistoryValue(e.target.value);
  //   setDraft(Object.assign({ ...draft }, { substance_use_disorder_history: e.target.value === 'No' ? 'No' : '' }));
  // }
  

  return (
    <>
      <h4 style={{ marginBottom: '15px' }}>Check box for substance then describe the amount/frequency of use</h4>
      {substanceUseHistoryItems.map((v, index) => {
        return <CheckboxTextInputFormItem key={index} name={v.value} label={v.label} form={form} draft={draft} setDraft={setDraft} />
      })}

      {/*       
      <Form.Item
        label={'Current use of Medication Assisted Treatment (MAT: Suboxone/buprenorphine, methadone, naltrexone, Vivitrol):'}
      >
        <Radio.Group onChange={onRadioGroupCurrentMATChange} value={radioGroupCurrentMATValue} style={{ margin: '3px auto' }}>
          <Radio value={'Yes'}>Yes</Radio>
          <Radio value={'No'}>No</Radio>
        </Radio.Group>
        <Form.Item name="substance_use_current_mat"
          validateTrigger={'onBlur'}
          rules={[
            {
              required: true,
              message: `required`,
            },
          ]}
          noStyle>
          <Input type={radioGroupCurrentMATValue !== 'No' ? 'text' : 'hidden'} style={{ marginLeft: '24px', width: 'calc(100% - 24px)' }} />
        </Form.Item>
      </Form.Item>

      <Form.Item
        label={'History of substance use disorder:'}
      >
        <Radio.Group onChange={onRadioGroupDisorderHistoryChange} value={radioGroupDisorderHistoryValue} style={{ margin: '3px auto' }}>
          <Radio value={'Yes'}>Yes</Radio>
          <Radio value={'No'}>No</Radio>
        </Radio.Group>
        <Form.Item name="substance_use_disorder_history"
          validateTrigger={'onBlur'}
          rules={[
            {
              required: true,
              message: `required`,
            },
          ]}
          noStyle>
          <Input type={radioGroupDisorderHistoryValue !== 'No' ? 'text' : 'hidden'} style={{ marginLeft: '24px', width: 'calc(100% - 24px)' }} />
        </Form.Item>
      </Form.Item>
      */}

      <RadioGroupWithTextInput
        name={'substance_use_current_mat'}
        label={'Current use of Medication Assisted Treatment (MAT: Suboxone/buprenorphine, methadone, naltrexone, Vivitrol):'}
        options={[
          { label: 'Yes', value: true, hasInput: true },
          { label: 'No', value: false },
        ]}
        form={form}
        draft={draft}
        setDraft={setDraft}
      />

      <RadioGroupWithTextInput
        name={'substance_use_disorder_history'}
        label={'History of substance use disorder:'}
        options={[
          { label: 'Yes', value: true, hasInput: true },
          { label: 'No', value: false },
        ]}
        form={form}
        draft={draft}
        setDraft={setDraft}
      />

      <Divider />
      <h3>Pregnancy/Planning Pregnancy/Breastfeeding (female only)</h3>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px'}} >
        {substanceUseHistoryFemaleItems.map((v, index) => {
          return (
            <div key={index} style={{ flex: '0 0 30%' }}>
              <ExclusiveCheckboxWithTextInput name={v.value} label={v.label} exclusive={v.exclusive} hasInput={v.hasInput}
                form={form} draft={draft} setDraft={setDraft} />
            </div>
          )
        })}
      </div>
    </>
  )
};

export default SubstanceUseHistoryFormItems;
