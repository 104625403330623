import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link } from 'umi';
import { Space, Popover, Input, Table, Row, Col, Button, Tag, Modal } from 'antd';
import { SyncOutlined, createFromIconfontCN } from '@ant-design/icons';

import { useRequest, useMount } from 'ahooks';
import { srvAllDoctors } from '@/services/admin';

import Filters from '@/components/Filters';
import defaultSettings from '../../../defaultSettings';
import { AsYouType } from 'libphonenumber-js';
import { getPatientName } from '@/utils/utils';
import { formatUnix } from '@/utils/xtime';

import styles from './index.less';
const cx = cn.bind(styles);

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const AdminAwaitingTransferPatientList = (props) => {
  const { dispatch, loading } = props;
  const { states, transfer_waitlist } = props.patient;

  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [allDoctors, setAllDoctors] = useState([]);

  const filters = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
    },
    {
      name: 'email',
      label: 'Email',
      type: 'text',
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'text',
    },
    {
      name: 'state',
      label: 'State',
      type: 'select',
      items: [],
    },
    {
      name: 'transfer_at',
      label: 'Transfer Time',
      type: 'date',
    },
    {
      name: 'transfer_cause',
      label: 'Cause Of Transfer',
      type: 'checkbox',
      items: [
        { label: 'Provider Off-Boarded', value: 1 },
        { label: 'Admin Transfer', value: 2 },
        { label: 'Provider Transfer', value: 3 },
        { label: 'Unhappy Experience', value: 4 },
        { label: 'Reactivate', value: 5 },
        { label: 'Pending Appt', value: 6 },
        { label: 'Others', value: 0 },
      ],
    },
    {
      name: 'patient_type',
      label: 'Patient Type',
      type: 'checkbox',
      items: [
        { label: 'Normal', value: 0 },
        { label: 'Pediatric', value: 1 },
        { label: 'Insomnia', value: 3 },
      ],
    },
    {
      name: 'clinician_id',
      label: 'Clinician',
      type: 'select',
      items: (allDoctors).map(item => {
        return { label: item.name, value: item.id };
      }),
    },
  ];

  const columns = [
    {
      title: 'Phone',
      dataIndex: 'patient_phone',
      key: 'patient_phone',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.patient_id}`}>{r.patient_name}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/patient/${r.patient_id}`}>{r.patient_email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.patient_phone)}
                </div>
                <div className="popover-user-item address">
                  <IconFont className="icon" type="icon-address" />
                  {r.patient_zipcode}
                </div>
                <div className="popover-user-item sex">
                  <IconFont className="icon" type="icon-sex" />
                  {r.patient_gender}
                </div>
              </div>
            }
            title={r.patient_name}
            trigger="hover"
          >
            <Link to={`/patient/${r.patient_id}`} style={{ color: '#1a1f36', fontWeight: 500 }}>
              {new AsYouType('US').input(r.patient_phone)}
            </Link>
          </Popover>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'patient_email',
      key: 'patient_email',
      ellipsis: true,
      width: 210,
    },
    {
      title: 'Name',
      dataIndex: 'patient_name',
      key: 'patient_name',
      width: 200,
      render: (v, r) => {
        const patientTypeMap = {
          1: { label: 'Pediatric', color: 'red' },
          3: { label: 'Insomnia', color: 'red' }
        };
        const patientType = patientTypeMap[r.patient_type];
        const patientName = getPatientName(r);

        return (
          <>
            {patientName} {patientType && <Tag color={patientType.color}>{patientType.label}</Tag>}
          </>
        );
      },
    },
    {
      title: 'Previous Clinician',
      dataIndex: 'prev_doctor_id',
      key: 'prev_doctor_id',
      ellipsis: true,
      render: (v, r) => {
        if (!r.prev_doctor_id) {
          return 'No Doctor';
        }
        const userName = `${r.prev_doctor_name} ${r.prev_doctor_title}`;
        return <Link to={`/doctor/detail/${r.prev_doctor_id}`}>{userName}</Link>;
      },
    },
    {
      title: 'Cause of transfer',
      dataIndex: 'transfer_cause_name',
      key: 'transfer_cause_name',
      ellipsis: true,
      width: 250,
    },
    {
      title: 'State',
      dataIndex: 'patient_state',
      key: 'patient_state',
      ellipsis: true,
      width: 200,
    },
    {
      title: 'Transfer Time',
      ellipsis: true,
      dataIndex: 'transfer_at',
      sorter: true,
      width: 200,
      key: 'transfer_at',
      render: (v, r) => {
        return formatUnix(r.transfer_at, 'YYYY-MM-DD');
      },
    },
  ];

  useMount(async () => {
    fetchAllDoctorsReq.runAsync()
  });

  useEffect(() => {
    setPage(1);
    dispatch({ type: 'patient/fetchStates', payload: {} });
    loadList({ page: 1, page_size: 20 }, () => { });
  }, []);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => { });
  };

  const loadList = (payload, callback) => {
    dispatch({ type: 'patient/fetchTransferWaitlist', payload, callback });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    const cond = {
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: JSON.stringify(query),
    }
    if (sorter && sorter.field == "transfer_at" && sorter.order) {
      cond.sort = sorter.order === "ascend" ? 1 : -1;
    }
    loadList(cond);
  };

  const fetchAllDoctorsReq = useRequest(srvAllDoctors, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setAllDoctors(ret.data.items)
      }
    },
  });

  const f = () => {
    for (const v of filters) {
      if (v.name === 'state' && v.items.length === 0) {
        v.items = states.map((s) => ({ ...s, label: s.state, value: s.state }));
        v.items.unshift({ label: 'P0 States', value: 'P0' })
      }
    }
    return filters;
  };

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>
          <Space>
            Patients Awaiting Transfer
            <Button
              className={cx({ syncBtn: true })}
              onClick={() => {
                setPage(1);
                loadList({ page: 1, page_size: 20 }, () => { });
              }}
              loading={loading.effects['patient/fetchTransferWaitlist']}
            >
              <SyncOutlined />
            </Button>
          </Space>
        </h3>
        <Space>
          <Filters filters={f()} onFilter={onFilter} />
        </Space>
      </div>

      <Table
        columns={columns}
        dataSource={transfer_waitlist.items}
        scroll={{ x: 1200 }}
        rowKey="patient_id"
        loading={loading.effects['patient/fetchTransferWaitlist']}
        onChange={handleTableChange}
        pagination={{ pageSize: 20, current: page, simple: true, total: transfer_waitlist.total }}
      />
    </div>
  );
}

export default connect(({ patient, loading }) => ({
  patient,
  loading,
}))(AdminAwaitingTransferPatientList);
