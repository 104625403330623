const intakeform = [
  {
    key: 'K0010',
    title:
      'Have you had bipolar, psychosis, schizophrenia, suicidal attempts or any mental hospitalization history in the past?',
    answer: '',
    type: 'assessment',
  },
  {
    key: 'F0007',
    title: 'Have you ever been treated for any behavioral health condition such as ADHD?',
    answer: '',
    type: 'intakeform',
  },
  {
    key: 'F0005',
    title:
      'Before I was 12 years old , my mother/father/guardian complained about my poor focus, impulsivity and/or hyperactivity.',
    answer: '',
    type: 'intakeform',
  },
  {
    key: 'F0006',
    title:
      'Before I was 12 years old, my teacher or coach noted my poor focus, impulsivity, and or hyperactivity. ',
    answer: '',
    type: 'intakeform',
  },
  {
    key: 'F0004',
    title: 'Do you have difficulties with anxiety or depression?',
    answer: '',
    type: 'intakeform',
  },
  {
    key: 'F0029',
    title: 'Do you have any experience with ADHD medication? If so, describe your experience.',
    answer: '',
    type: 'intakeform',
  },
  {
    key: 'D0002',
    title: 'When was your last psychiatric appointment for your ADHD?',
    answer: '',
    type: 'assessment',
  },

  {
    key: 'D0003',
    title: 'How many psychiatric medications are you currently taking?',
    answer: '',
    type: 'assessment',
  },

  {
    key: 'D0004',
    title: 'How long have you been on this medication plan for ADHD?',
    answer: '',
    type: 'assessment',
  },
];

function initIntakeformInfo() {
  let intakeformInfo = intakeform;

  return intakeformInfo;
}

function findIntakeFormAnswer(question, intakeformAnswers, assessmentAnswers) {
  let answer = '';
  if (question.type === 'assessment') {
    if (assessmentAnswers !== null) {
      for (let assessment of assessmentAnswers) {
        if (assessment.key === question.key) {
          answer = assessment.value;
          break;
        }
      }
    }
  }
  if (question.type === 'intakeform') {
    if (intakeformAnswers !== null) {
      for (let intakeform of intakeformAnswers) {
        if (intakeform.key === question.key) {
          answer = intakeform.value;
          break;
        }
      }
    }
  }
  return answer;
}

export { initIntakeformInfo, findIntakeFormAnswer };
