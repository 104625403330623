import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Badge, Form, Dropdown, Input, Button, Checkbox, Row, Col } from 'antd';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import clone from 'clone';
import { useEventEmitter } from 'ahooks';

import TextFilter from './TextFilter';
import NumberFilter from './NumberFilter';
import DateFilter from './DateFilter';
import CheckboxGroupFilter from './CheckboxGroupFilter';
import SelectFilter from './SelectFilter';
import AsyncSelectFilter from './AsyncSelectFilter';
import styles from './index.less';
const cx = cn.bind(styles);

// const filters = [
//   {
//     name: 'name',
//     label: 'Name',
//     type: 'text',
//   },
//   {
//     name: 'created_at',
//     label: 'Date',
//     type: 'date',
//   },
//   {
//     name: 'status',
//     label: 'Status',
//     type: 'select',
//     items: [
//       { label: 'A', value: 1 },
//       { label: 'B', value: 2 },
//       { label: 'C', value: 3 },
//     ],
//   },
//   {
//     name: 'type',
//     label: 'Type',
//     type: 'checkbox',
//     items: [
//       { label: 'A', value: 1 },
//       { label: 'B', value: 2 },
//       { label: 'C', value: 3 },
//     ],
//   },
//   {
//     name: 'amount',
//     label: 'Amount',
//     type: 'number',
//   },
// ];

const Filters = forwardRef((props, ref) => {
  const { filters, initialFilters } = props;
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [count, setCount] = useState(0);
  const toggleVisible = () => {
    setVisible(!visible);
  };

  if (props.reset$) {
    props.reset$.useSubscription(() => {
      reset();
    });
  }

  const reset$ = useEventEmitter();

  const itemsDom = filters.map((v, i) => {
    switch (v.type) {
      case 'date':
        return <DateFilter reset$={reset$} form={form} {...v} key={i} />;
      case 'select':
        return <SelectFilter reset$={reset$} form={form} {...v} key={i} />;
      case 'number':
        return <NumberFilter reset$={reset$} form={form} {...v} key={i} />;
      case 'checkbox':
        return <CheckboxGroupFilter reset$={reset$} form={form} {...v} key={i} />;
      case 'text':
        return <TextFilter reset$={reset$} form={form} {...v} key={i} />;
      case 'select_with_input':
        return <SelectFilter reset$={reset$} form={form} {...v} key={i} />;
      case 'async_select': 
        return <AsyncSelectFilter reset$={reset$} form={form} {...v} key={i} />;
    }

    return '';
  });

  const onFinish = (values) => {
    const data = collectFilters(values);
    setCount(data.filterCount);
    props.onFilter && props.onFilter(data.query);
    setVisible(false);
  };

  const collectFilters = (values) => {
    var query = {};
    let filterCount = 0;
    for (const v of filters) {
      switch (v.type) {
        case 'text':
          if (values[v.name] != null) {
            if (values[v.name].trim) {
              query[v.name] = values[v.name].trim();
            } else {
              query[v.name] = values[v.name];
            }
            filterCount++;
          }
          break;
        case 'checkbox':
          if (values[v.name] && values[v.name].length) {
            query[v.name] = values[v.name];
            filterCount++;
          }
          break;
        case 'number':
          if (values.hasOwnProperty(v.name)) {
            query[v.name] = values[v.name];
            filterCount++;
          }
          if (values.hasOwnProperty(`${v.name}[gte]`) && values.hasOwnProperty(`${v.name}[lte]`)) {
            query[`${v.name}[gte]`] = values[`${v.name}[gte]`];
            query[`${v.name}[lte]`] = values[`${v.name}[lte]`];
            filterCount++;
          } else {
            if (values.hasOwnProperty(`${v.name}[gte]`)) {
              query[`${v.name}[gte]`] = values[`${v.name}[gte]`];
              filterCount++;
            }
            if (values.hasOwnProperty(`${v.name}[lte]`)) {
              query[`${v.name}[lte]`] = values[`${v.name}[lte]`];
              filterCount++;
            }

            if (values.hasOwnProperty(`${v.name}[gt]`)) {
              query[`${v.name}[gt]`] = values[`${v.name}[gt]`];
              filterCount++;
            }
            if (values.hasOwnProperty(`${v.name}[lt]`)) {
              query[`${v.name}[lt]`] = values[`${v.name}[lt]`];
              filterCount++;
            }
          }
          break;
        case 'date':
          if (values.hasOwnProperty('last_type')) {
            const val = values[v.name];
            const tz = values.timezone == 'pdt' ? 'America/Los_Angeles' : 'Etc/GMT';
            const dt = moment()
              .tz(tz)
              .add(-(1 * val), values.last_type);

            const startStr = dt.format('YYYY-MM-DDT00:00:00Z');
            const endStr = moment().tz(tz).add(1, 'days').format('YYYY-MM-DDT00:00:00Z');

            let start = moment.tz(startStr, tz);
            let end = moment.tz(endStr, tz);

            query[`${v.name}[gte]`] = start.unix();
            query[`${v.name}[lte]`] = end.unix();
            filterCount++;
          } else if (values.hasOwnProperty(`${v.name}[eq]`)) {
            const tz = values.timezone == 'pdt' ? 'America/Los_Angeles' : 'Etc/GMT';
            const valStr = moment(values[`${v.name}[eq]`]).format('YYYY-MM-DD');
            const start = moment.tz(valStr, tz);
            const end = moment.tz(valStr, tz).add(1, 'days');
            query[`${v.name}[gte]`] = start.unix();
            query[`${v.name}[lte]`] = end.unix();
            filterCount++;
          } else {
            const tz = values.timezone == 'pdt' ? 'America/Los_Angeles' : 'Etc/GMT';
            if (
              values.hasOwnProperty(`${v.name}[gte]`) &&
              values.hasOwnProperty(`${v.name}[lte]`)
            ) {
              const valStr1 = moment(values[`${v.name}[gte]`]).format('YYYY-MM-DD');
              const valStr2 = moment(values[`${v.name}[lte]`]).format('YYYY-MM-DD');
              let val1 = moment.tz(valStr1, tz).unix();
              let val2 = moment.tz(valStr2, tz).unix();

              if (val1 > val2) {
                query[`${v.name}[gte]`] = val2;
                query[`${v.name}[lte]`] = val1;
              } else if (val1 < val2) {
                query[`${v.name}[gte]`] = val1;
                query[`${v.name}[lte]`] = val2;
              } else {
                query[`${v.name}[gte]`] = val1;
                query[`${v.name}[lt]`] = val2 + 86400;
              }

              filterCount++;
            } else {
              if (values.hasOwnProperty(`${v.name}[gte]`)) {
                const valStr = moment(values[`${v.name}[gte]`]).format('YYYY-MM-DD');
                query[`${v.name}[gte]`] = moment.tz(valStr, tz).unix();
                filterCount++;
              }

              if (values.hasOwnProperty(`${v.name}[lte]`)) {
                const valStr = moment(values[`${v.name}[lte]`]).format('YYYY-MM-DD');
                query[`${v.name}[lte]`] = moment.tz(valStr, tz).add(1, 'days').unix();
                filterCount++;
              }

              if (values.hasOwnProperty(`${v.name}[gt]`)) {
                const valStr = moment(values[`${v.name}[gt]`]).format('YYYY-MM-DD');
                query[`${v.name}[gt]`] = moment.tz(valStr, tz).add(1, 'days').unix();
                filterCount++;
              }
              if (values.hasOwnProperty(`${v.name}[lt]`)) {
                const valStr = moment(values[`${v.name}[lt]`]).format('YYYY-MM-DD');
                query[`${v.name}[lt]`] = moment.tz(valStr, tz).unix();
                filterCount++;
              }
            }
          }
          break;
        case 'select':
          if (values.hasOwnProperty(v.name)) {
            query[v.name] = values[v.name];
            filterCount++;
          }
          break;
        case 'async_select':
          if (values.hasOwnProperty(v.name)) {
            query[v.name] = values[v.name];
            filterCount++;
          }
          break;
      }
    }

    return {
      query,
      filterCount,
    };
  };

  const onFinishFailed = (errorInfo) => {};

  const reset = () => {
    form.resetFields();
    reset$.emit();
    setCount(0);
    props.onFilter && props.onFilter({});
  };

  const onVisibleChange = (v) => {
    setVisible(v);
  };

  useImperativeHandle(ref, () => ({
    setFilters: (fieldsValue) => {
      form.setFieldsValue(fieldsValue);
      onFinish(fieldsValue);
    },
  }));

  useEffect(() => {
    if (initialFilters) {
      form.setFieldsValue(initialFilters);
      const data = collectFilters(initialFilters);
      setCount(data.filterCount);
    }
  }, [initialFilters]);

  return (
    <div className={cx({ filters: true })}>
      <Dropdown
        visible={visible}
        onVisibleChange={onVisibleChange}
        forceRender
        overlay={
          <div className={cx({ filtersDropdown: true })}>
            <Form layout="vertical" form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <div className={cx({ filtersHeader: true })}>
                <Button size="small" onClick={reset}>
                  Clear
                </Button>
                <div>Filters</div>
                <Button size="small" type="primary" htmlType="submit">
                  Done
                </Button>
              </div>
              <div className={cx({ filtersContent: true })}>{itemsDom}</div>
            </Form>
          </div>
        }
        trigger={['click']}
      >
        <Badge count={count}>
          <Button onClick={toggleVisible}>Filters</Button>
        </Badge>
      </Dropdown>
    </div>
  );
});
Filters.defaultProps = {
  filters: [],
};
export default Filters;
