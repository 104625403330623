import React, { useState, useEffect } from 'react';
import { connect, Link } from 'umi';
import { useRequest } from 'ahooks';
import { Drawer, Form, Button, Input, Modal, Row, Col, Space, Switch, Table, message,Divider } from 'antd';
import cn from 'classnames/bind';
import { useFlag } from '@unleash/proxy-client-react';

import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { AsYouType } from 'libphonenumber-js';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { srvAddAdmin, srvUpdateAdmin } from '@/services/admin';

import styles from './index.less';

const Admin = (props) => {
  const [form] = Form.useForm();
  const cx = cn.bind(styles);
  const {
    dispatch,
    admin: { data },
    loading,
  } = props;
  const [selectedUser, setSelectedUser] = useState({});
  const [page, setPage] = useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const flagSuperAdmin = useFlag('super_admin');

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 180,
      render: (v, r) => {
        return (
          <Link to={`/settings/admin/detail/${r.id}`}>
            {r.name} {r.title}
          </Link>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      width: 180,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
      width: 120,
      render: (v, r) => {
        return new AsYouType('US').input(r.phone);
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 70,
      render: (v, r) => <Switch checked={r.status === 1} onChange={() => handleStatusChange(r)} />,
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 20,
      render: (v, r) => (
        <Button
          icon={<EditOutlined />}
          size="small"
          type="text"
          onClick={() => handleDrawerOpen(r)}
        />
      ),
    },
  ];
  const permissionswitches = [
    { key: 'patient_flag', label: 'Patient' },
    { key: 'clinicians_flag', label: 'Clinicians' },
    { key: 'appointment_flag', label: 'Appointment' },
    { key: 'appt_request_flag', label: 'Appt.Request' },
    { key: 'prescriptions_flag', label: 'Prescriptions' },
    { key: 'care_team_inbox_flag', label: 'Care Team Inbox' },
    { key: 'referral_uploads_flag', label: 'Referral Uploads' },
    { key: 'medical_files_flag', label: 'Medical Files' },
    { key: 'review_and_approve_flag', label: 'Review and Approve' },
    { key: 'rein_application_flag', label: 'Rein. Application' },
    { key: 'payments_flag', label: 'Payments' },
    { key: 'surveys_flag', label: 'Surveys' },
    { key: 'rxnt_sync_status_flag', label: 'Rxnt Sync Status' },
    { key: 'settings_flag', label: 'Settings' },
  ];
  
  const loadList = (payload, callback) => {
    dispatch({ type: 'admin/fetchList', payload, callback });
  };

  useEffect(() => {
    if (!flagSuperAdmin) {
      return;
    }
    setPage(1);
    loadList({ page: 1, page_size: 20 }, () => {});
  }, []);

  const generatePhoneNo = () => {
    return Math.floor(Math.random() * (10000000000 - 1000000000) + 1000000000);
  };

  const updateUser = useRequest(srvUpdateAdmin, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        message.success('update success');
        loadList({ page, page_size: 20 }, () => {});
      } else {
        message.error(ret.msg);
      }
    },
  });

  const handleDrawerOpen = (user) => {
    form.resetFields();
    setIsDrawerOpen(true);

    if (user && user.id) {
      setSelectedUser(user);
      form.setFieldsValue(user);
    } else {
      setSelectedUser({});
      form.setFieldsValue({ name: '', email: '' });
    }
  };

  const confirmActivate = (r) => {
    Modal.confirm({
      title: 'Do you want to activate these admin?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch({
          type: 'admin/activateAdmin',
          payload: { id: r.id },
          callback: () => {
            loadList({ page, page_size: 20 }, () => {});
          },
        });
      },
      onCancel() {},
    });
  };

  const confirmDeactivate = (r) => {
    Modal.confirm({
      title: 'Do you want to deactivate these admin?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch({
          type: 'admin/deactivateAdmin',
          payload: { id: r.id },
          callback: () => {
            loadList({ page, page_size: 20 }, () => {});
          },
        });
      },
      onCancel() {},
    });
  };

  const handleStatusChange = (record) => {
    if (record.status === 1) {
      confirmDeactivate(record);
    } else {
      confirmActivate(record);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadList({
      page: pagination.current,
      page_size: pagination.pageSize,
    });
  };

  const handleDrawerClose = () => {
    setSelectedUser({});
    setIsDrawerOpen(false);
  };

  const handleSave = async () => {
    form
      .validateFields()
      .then((values) => {
        if (selectedUser.id) {
          values.id = selectedUser.id;
        }

        if (values.id) {
          updateUser.run(values);
        } else {
          Promise.all(
            values.users.map(async (user) => {
              await srvAddAdmin({
                ...user,
                phone: generatePhoneNo().toString(),
              });
            }),
          )
            .then(() => {
              message.success('Admin(s) successfully added');
              loadList({ page, page_size: 20 }, () => {});
            })
            .catch((err) => {
              console.error(err.message);
            });
        }

        handleDrawerClose();
      })
      .catch((errorInfo) => {});
  };

  const initialValues = selectedUser.id ? selectedUser : { users: [{ name: '', email: '' }] };

  if (!flagSuperAdmin) {
    return <>This feature is not enabled.</>;
  }

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Admin</h3>
        <Space>
          <Button
            type="primary"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => handleDrawerOpen(null)}
          >
            Add
          </Button>
          <Drawer
            title={<h3>{selectedUser.id ? `Edit (${selectedUser.name})` : `Add a new account`}</h3>}
            width={720}
            onClose={handleDrawerClose}
            visible={isDrawerOpen}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form
              form={form}
              initialValues={initialValues}
              preserve={false}
              className={cx({ modalForm: true })}
              layout="vertical"
            >
              {selectedUser.id ? (
                <>
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[{ required: true, message: 'Name is required' }]}
                    >
                      <Input placeholder="Name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        { required: true, message: 'Email is required' },
                        { type: 'email', message: 'Invalid Email' },
                      ]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                  </Col>
                </Row>
                  <span style={{ fontWeight: '600', color: '#000000' }}>Access Permissions</span>
                  <Row justify="space-between" style={{ marginBottom: 16, fontWeight: '400', marginTop: '15px' }}>
                    <Col span={8}><h2 style={{ color: '#000000' }}>Menu</h2></Col>
                    <Col span={8} style={{ textAlign: 'right' }}><h2 style={{ color: '#000000' }}>Permissions</h2></Col>
                  </Row>
                  <Divider style={{ margin: '16px 0' }} />
                  {permissionswitches.map(switchItem => (
                    <>
                      <Form.Item
                        name={switchItem.key}
                        style={{ marginBottom: 0 }}
                        valuePropName="checked"
                      >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <span>{switchItem.label}</span>
                          <Switch defaultChecked={selectedUser[switchItem.key]}
                            onChange={(checked) => {
                              form.setFieldsValue({ [switchItem.key]: checked })
                            }} />
                        </div>
                      </Form.Item>
                      <Divider style={{ margin: '16px 0' }} />
                    </>
                  ))}
                </>
              ) : (
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Row key={key} gutter={16}>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              {...restField}
                              name={[name, 'name']}
                              rules={[{ required: true, message: 'Name is required' }]}
                            >
                              <Input placeholder="Name" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              {...restField}
                              name={[name, 'email']}
                              rules={[
                                { required: true, message: 'Email is required' },
                                { type: 'email', message: 'Invalid Email' },
                              ]}
                            >
                              <div className={cx({ formItem: true })}>
                                <Input placeholder="Email" style={{ flex: 1 }} />
                                {fields.length > 1 ? (
                                  <MinusCircleOutlined
                                    className={cx({ minusButton: true })}
                                    onClick={() => remove(name)}
                                  />
                                ) : null}
                              </div>
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          className={cx({ addButton: true })}
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add field
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              )}
            </Form>

            <div className={cx({ footer: true })}>
              <Button onClick={handleDrawerClose}>Cancel</Button>
              <Button onClick={handleSave} type="primary">
                Save
              </Button>
            </div>
          </Drawer>
        </Space>
      </div>

      <Table
        className="rowSelectTable"
        columns={columns}
        dataSource={data.items}
        rowKey="id"
        loading={loading.effects['admin/fetchList']}
        onChange={handleTableChange}
        pagination={{
          pageSize: 20,
          current: page,
          simple: true,
          total: data.total,
        }}
      />
    </div>
  );
};

export default connect(({ admin, loading }) => ({
  admin,
  loading,
}))(Admin);
