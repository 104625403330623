import React, { useState, useEffect } from 'react';
import cn from 'classnames/bind';
import { connect, history } from 'umi';
import { Empty, Tabs } from 'antd';
import PatientList from '@/components/PatientList';
import OverduePatientList from '@/components/OverduePatientList';
import PatientInfo from '@/components/PatientInfoPane/PatientInfo';
import AppointmentsPane from '@/components/AppointmentsPane/Appointments';
import ConsultationsPane from '@/components/ConsultationsPane/Consultations';
import ProviderNotesPane from '@/components/ProviderNotesPane/ProviderNotes';
import PrescriptionPane from '@/components/PrescriptionPane/Medications';
import VoicesPane from '@/pages/ehr_voice/index';

import { InfoCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { usePatientStore } from '@/stores';
import { srvGetPatientCurrentMembership } from '@/services/patient';
import { formatUnix } from '../../utils/xtime';

import styles from './index.less';
const { TabPane } = Tabs;
const cx = cn.bind(styles);


const DoctorPatientPage = (props) => {
  const { dispatch, ehr, loading, parentProps } = props;
  const { setPatient } = usePatientStore();
  const [currentMembership, setCurrentMembership] = useState();
  const [showMembershipPausedInfo, setShowMembershipPausedInfo] = useState(false);
  const { id = '' } = parentProps.match.params;

  let path = parentProps.route.path;
  let query = parentProps.location.query.q || '';

  useEffect(() => {

    if (id) {
      setPatient(id);
      srvGetPatientCurrentMembership({ id }).then((res) => {
        if (res.success) {
          setCurrentMembership(res.data);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (currentMembership && currentMembership.status === 4) {
      setShowMembershipPausedInfo(true);
    } else {
      setShowMembershipPausedInfo(false);
    }
  }, [currentMembership]);

  const { patients } = ehr;

  const activeKey =
    parentProps.history.location.query.tab && parentProps.history.location.query.tab.length
      ? parentProps.history.location.query.tab + ''
      : '1';

  const getDynamicInfo = () => {
    switch (parentProps.route.path) {
      case '/ehr_appointment':
      case '/ehr_appointment/:id':
        return {
          group: 'recent',
          scope: 'my',
          defaultTab: '4',
        };
      case '/ehr_consultation':
      case '/ehr_consultation/:id':
        return {
          group: 'consultation',
          scope: 'my',
          defaultTab: '5',
        };
      case '/ehr_renewal':
      case '/ehr_renewal/:id':
        return {
          group: 'request',
          scope: 'my',
          defaultTab: '3',
        };
      case '/ehr_team':
        return {
          group: '',
          scope: '',
          defaultTab: '4',
        };
      case '/ehr_patient':
      case '/ehr_patient/:id':
      case '/patient':
      case '/patient/:id':
        return {
          group: '',
          scope: 'my',
          defaultTab: '1',
        };
      default:
        return {
          group: '',
          scope: 'all',
          defaultTab: '1',
        };
    }
  };

  const info = getDynamicInfo();

  const OperationsSlot = {
    left: <div className={cx({ emptySlot: true })}>&nbsp;</div>,
  };

  const onSelect = (v) => {
    let path = parentProps.route.path;
    if (parentProps.route.path.indexOf(':id') != -1) {
      path = parentProps.route.path.replaceAll(':id', v);
    } else {
      path = `${parentProps.route.path}/${v}`;
    }
    history.push({
      pathname: path,
      query: {
        tab: info.defaultTab,
        q: query,
      },
    });
  };

  const onTabChange = (v) => {
    let path = parentProps.route.path;
    if (parentProps.route.path.indexOf(':id') != -1) {
      path = parentProps.route.path.replaceAll(':id', id);
    } else {
      path = `${parentProps.route.path}/${id}`;
    }
    history.push({
      pathname: path,
      query: {
        tab: v,
      },
    });
  };

  return (
    <div className={cx({ allpatient: true })}>
      {showMembershipPausedInfo && (
        <div className={cx({ membershipInfo: true })}>
          <div>
            <InfoCircleOutlined style={{ fontSize: '24px', color: '#08c' }} />
          </div>
          <div className={cx({ membershipContent: true })}>
            <p style={{ fontSize: '20px' }}>Membership is paused</p>
            <p>
              This patient’s membership is paused, resume date is
              {' ' + formatUnix(currentMembership?.end_date, 'MMM DD, YYYY')}. In this time, the
              patient is not able to request a renewal, send a message, or book an appointment. If
              you have any questions, contact provider support team.
            </p>
          </div>
          <div
            style={{ marginRight: '15px', color: '#8c8c8c' }}
            onClick={() => setShowMembershipPausedInfo(false)}
          >
            <CloseOutlined />
          </div>
        </div>
      )}
      <div className={cx({ list: true, appointments: path.indexOf('/ehr_appointment') != -1 })}>
        { /overdue_patient/.test(path) &&
          <OverduePatientList
            searchable={path.indexOf('/patient') == -1}
            query={query}
            group={info.group}
            scope={info.scope}
            patients={patients}
            onSelect={onSelect}
            selectedID={id}
          />
        }
        { !/overdue_patient/.test(path) &&
          <PatientList
            searchable={path.indexOf('/patient') == -1}
            query={query}
            group={info.group}
            scope={info.scope}
            patients={patients}
            onSelect={onSelect}
            selectedID={id}
          />
        }      </div>
      <div className={cx({ info: true, appointments: path.indexOf('/ehr_appointment') != -1 })}>
        {id && id.length && (
          <Tabs
            defaultActiveKey="1"
            activeKey={activeKey}
            tabBarExtraContent={OperationsSlot}
            onTabClick={onTabChange}
          >
            <TabPane tab="Patient Chart" key="1">
              <PatientInfo active={activeKey === '1'} id={id} />
            </TabPane>
            <TabPane tab="Clinician Notes" key="2">
              <ProviderNotesPane active={activeKey === '2'} id={id} />
            </TabPane>
            <TabPane tab="Prescription" key="3">
              <PrescriptionPane id={id} active={activeKey === '3'} />
            </TabPane>
            {path !== '/ehr_team' && (
              <TabPane tab="Appointments" key="4">
                <AppointmentsPane active={activeKey === '4'} id={id} />
              </TabPane>
            )}
            <TabPane tab="Consultations" key="5">
              <ConsultationsPane active={activeKey === '5'} id={id} />
            </TabPane>

            <TabPane tab="Call Logs" key="6">
              <VoicesPane active={activeKey === '6'} id={id} />
            </TabPane>
          </Tabs>
        )}
        {(!id || !id.length) && (
          <div className={cx({ empty: true })}>
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(({ ehr, loading }) => ({
  ehr,
  loading,
}))(DoctorPatientPage);
