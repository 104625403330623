import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'umi';
import classNames from 'classnames';
import { ReactComponent as Logo } from '@/assets/logo.svg';
import { ReactComponent as MindfulLogo } from '@/assets/mindful-logo.svg';
import MenuCounter from './Counter';
import BaseMenu from './BaseMenu';
import './index.less';

const { Sider } = Layout;

const renderLogoAndTitle = () => {
  const isDone = window.location.href.includes('donefirst.com');
  return (
    <Link to="/">
      {isDone ? <Logo style={{ width: '53%', marginTop: '10px', }} /> : <MindfulLogo style={{ width: '70%', marginTop: '10px', marginLeft: '-10px' }} />}
    </Link>
  );
};

const SiderMenu = (props) => {
  const {
    collapsed,
    fixSiderbar,
    onCollapse,
    siderWidth = 200,
    // isMobile,
    onMenuHeaderClick,
    breakpoint = 'lg',
    style,
    links,
    onOpenChange,
    // className,
    theme,
  } = props;

  const { flatMenus } = MenuCounter.useContainer();
  const siderClassName = classNames('aster-sider-menu-sider', {
    'fix-sider-bar': fixSiderbar,
    light: theme === 'light',
  });

  const headerDom = renderLogoAndTitle();
  return (
    <Sider
      collapsible
      trigger={null}
      collapsed={collapsed}
      breakpoint={breakpoint === false ? undefined : breakpoint}
      onCollapse={(collapse) => {
        if (onCollapse) {
          onCollapse(collapse);
        }
      }}
      collapsedWidth="64"
      style={style}
      width={siderWidth}
      className={siderClassName}
      theme={theme}
    >
      {headerDom && (
        <div className="aster-sider-menu-logo" onClick={onMenuHeaderClick} id="logo">
          {headerDom}
        </div>
      )}

      {flatMenus && (
        <BaseMenu
          {...props}
          mode="inline"
          handleOpenChange={onOpenChange}
          style={{ padding: '16px 0', width: '100%' }}
        />
      )}
      {links && links.length > 0 && (
        <div className="aster-sider-menu-links">
          <Menu
            theme={theme}
            className="aster-sider-menu-link-menu"
            selectedKeys={[]}
            openKeys={[]}
            mode="inline"
          >
            {(links || []).map((node, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Menu.Item key={index}>{node}</Menu.Item>
            ))}
          </Menu>
        </div>
      )}
    </Sider>
  );
};

export default SiderMenu;
