import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link,useAccess } from 'umi';
import { Form, Drawer, Select, Space, Popover, Input, Table, Modal, Button, Divider, Tooltip, } from 'antd';
import { QuestionCircleOutlined, createFromIconfontCN } from '@ant-design/icons';
import { useMount } from 'ahooks';
import { useEventEmitter } from 'ahooks';

import Filters from '@/components/Filters';
import defaultSettings from '../../../defaultSettings';
import TransferPatient from '@/components/TransferPatient';
import AutoTransferConfirmOrWaitingModal from '@/components/AutoTransferConfirmOrWaitingModal';
import { AsYouType } from 'libphonenumber-js';
import { getPatientName } from '@/utils/utils';
import { formatUnix } from '../../utils/xtime';
import styles from './index.less';
const cx = cn.bind(styles);

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const filters = [
  {
    name: 'email',
    label: 'Email',
    type: 'text',
  },
  {
    name: 'phone',
    label: 'Phone',
    type: 'text',
  },
  {
    name: 'created_at',
    label: 'Date',
    type: 'date',
  },
  {
    name: 'membership_status',
    label: 'Membership Status',
    type: 'checkbox',
    items: [
      { label: 'Active', value: 2 },
    ],
  },
];

const Patient = (props) => {
  const { id: doctor_id, active, patients, loadPatients, loadingPatients } = props;
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [patient, setPatient] = useState({});
  const [autoTransferVisible, setAutoTransferVisible] = useState(false);
  const [batchTransferVisible, setBatchTransferVisible] = useState(false);
  const [transferVisible, setTransferVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const [form] = Form.useForm();
  const reset$ = useEventEmitter();
  const access=useAccess();
  useMount(() => { });

  const columns = [
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        const userName = `${r.first_name} ${r.last_name}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.id}?admin=true`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/patient/${r.id}?admin=true`}>{r.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.phone)}
                </div>
                <div className="popover-user-item address">
                  <IconFont className="icon" type="icon-address" />
                  {r.zipcode}
                </div>
                <div className="popover-user-item sex">
                  <IconFont className="icon" type="icon-sex" />
                  {r.gender}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <Link to={`/patient/${r.id}?admin=true`} style={{ color: '#1a1f36', fontWeight: 500 }}>
              {new AsYouType('US').input(r.phone)}
            </Link>
          </Popover>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      width: 210,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        return getPatientName(r);
      },
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        return r.state;
      },
    },
    {
      title: 'Membership',
      dataIndex: 'id',
      ellipsis: true,
      width: 100,
      key: 'id',
      render: (v, r) => {
        if (!r.membership) {
          return ' ';
        }

        const status = `${r.first_name} ${r.last_name}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item">
                  <Link to={`/patient/${r.id}`}>
                    {formatUnix(r.membership.begin_date, 'MM/DD/YYYY')} --{' '}
                    {formatUnix(r.membership.end_date, 'MM/DD/YYYY')}
                  </Link>
                </div>
              </div>
            }
            title="Membership"
            trigger="hover"
          >
            <a href="#">{r.membership.status_name}</a>
          </Popover>
        );
      },
    },
    {
      title: 'Date',
      ellipsis: true,
      dataIndex: 'created_at',
      width: 200,
      key: 'created_at',
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 80,
      render: (v, r) => {
        if (access.adminAccesser) {
          return
        }
        return (
          <Button
            size="small"
            onClick={() => {
              setPatient(r);
              setTransferVisible(true);
            }}
          >
            Transfer
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    onRefresh();
  }, [active]);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    props.loadPatients({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => { });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    props.loadPatients(
      { page: pagination.current, page_size: pagination.pageSize, filters: JSON.stringify(query) },
      () => { },
    );
  };

  const f = () => {
    for (const v of filters) {
      if (v.name === 'status' && v.items.length === 0) {
        v.items = treatment_plan_statuses;
        continue;
      }
      if (v.name === 'plan_type' && v.items.length === 0) {
        v.items = treatment_plan_types;
        continue;
      }
    }
    return filters;
  };

  const onRefresh = () => {
    setPage(1);
    props.loadPatients({ page: 1, page_size: 20 }, () => { });
  }

  const onBatchTransfer = (values) => {
    const data = values.items.map((v) => {
      return {
        doctor_id: v.doctor_id,
        state: v.state,
      };
    });

    props.autoTransfer(
      {
        items: data,
      },
      () => {
        setBatchTransferVisible(false);
        reset$.emit();
      },
    );
  };

  const openBatchTransfer = () => {
    props.loadTransferOpts({}, (v) => {
      const data = {
        items: v,
      };
      setFormData(data);
      setBatchTransferVisible(true);
    });
  };

  const submitForm = () => { };

  return (
    <div className={cx({ subcontainer: true })}>
      <div className={cx({ subheader: true })}>
        <h3>Patients</h3>
        <Space>
          {!access.adminAccesser && (
              <Button type="danger" onClick={openBatchTransfer}>
                Batch Transfer
              </Button>
            )}
          <Filters reset$={reset$} filters={filters} onFilter={onFilter} />
        </Space>
      </div>

      <Table
        columns={columns}
        dataSource={patients.items}
        rowKey="id"
        loading={loadingPatients}
        onChange={handleTableChange}
        pagination={{
          pageSize: 20,
          total: patients.total,
          simple: true,
          current: page,
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} of ${total} items`;
          },
        }}
      />
      <Drawer
        className={cx({ batchTransferDrawer: true })}
        title={`Batch Transfer`}
        width="500"
        placement="right"
        closable={false}
        onClose={() => { setBatchTransferVisible(false) }}
        visible={batchTransferVisible}
        destroyOnClose={true}
        rowClassName={(r) => {
          if (r.id == patient.id) {
            return 'rowSelected';
          }
          return '';
        }}
      >
        <Form className={styles.batchTransfer} layout="vertical" form={form} onFinish={onBatchTransfer} initialValues={formData}>
          <div className={styles.title}>Transfer to target clinician</div>
          <Form.List name="items">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => {
                  const doctors = formData.items[field.key].doctors;
                  return (
                    <Space
                      key={field.key}
                      style={{ display: 'flex', marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...field}
                        name={[field.name, 'state']}
                        fieldKey={[field.fieldKey, 'state']}
                        rules={[{ required: true, message: 'State is required' }]}
                      >
                        <Input placeholder="State" disabled />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'doctor_id']}
                        fieldKey={[field.fieldKey, 'doctor_id']}
                        rules={[{ required: true, message: 'Doctor is required' }]}
                      >
                        <Select style={{ width: '200px' }}>
                          {doctors.map((v, i) => (
                            <Select.Option value={v.id} key={i}>
                              {v.name} {v.title}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Space>
                  );
                })}
              </>
            )}
          </Form.List>
          <div className={styles.btnBox}>
            <Button type="primary" htmlType="submit" loading={props.loadingAutoTransfer}>
              Confirm
            </Button>
          </div>
          <Divider />
          <Space className={styles.title}>
            Auto-transfer
            <Tooltip title={'If you select auto-transfer, we will automatically match the patients with appropriate providers. If there is no available provider, the patients will be added to the awaiting transfer list, until a provider becomes available.'}>
              <QuestionCircleOutlined style={{ color: "#5469D4", fontSize: '14px', lineHeight: '28px', marginBottom: '6px' }} />
            </Tooltip>
          </Space>
          <div className={styles.btnBox}>
            <Button type="primary" onClick={() => {
              setAutoTransferVisible(true)
            }}>
              Auto-Transfer
            </Button>
          </div>
        </Form>
      </Drawer>
      {autoTransferVisible && (
        <AutoTransferConfirmOrWaitingModal
          onOk={() => {
            onRefresh()
            if (autoTransferVisible) setAutoTransferVisible(false)
            if (batchTransferVisible) setBatchTransferVisible(false)
            if (transferVisible) setTransferVisible(false)
          }}
          onCancel={() => {
            if (autoTransferVisible) setAutoTransferVisible(false)
            if (batchTransferVisible) setBatchTransferVisible(false)
            if (transferVisible) setTransferVisible(false)
          }}
          visible={autoTransferVisible}
          doctor_id={doctor_id}
          patient_id={patient.id}
        />
      )}
      {transferVisible && patient.id && (
        <TransferPatient
          visible={transferVisible}
          patientID={patient.id}
          refresh={() => {
            reset$.emit();
          }}
          onAutoTransfer={() => {
            setAutoTransferVisible(true);
          }}
          onClose={() => {
            setTransferVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default Patient;
