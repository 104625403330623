import { request } from 'umi';

const isDone = window.location.href.includes('donefirst.com');

export async function srvLogout(data) {
    return request('/api/ehr/oauth/logout', {
        method: 'post',
        data: {
        },
    });
}

export async function srvLogin(data) {
    return request('/api/oauth/login', {
        method: 'post',
        data: {
            phone: data.phone,
            code: data.code,
            user_classification: Number(!isDone),
            client_id: '77eae7aaebf39fd0c8bef84e58b37cfd',
        },
    });
}

export async function srvCheckPhoneExist(data) {
    return request('/api/user/check_phone_exist', {
        method: 'post',
        data: {
            phone: data.phone,
        },
    });
}

export async function srvVerifyCode(data) {
    return request('/api/valcode/mobile', {
        method: 'post',
        data: {
            code_type: 1,
            phone: data.phone,
            user_classification: Number(!isDone),
        },
    });
}

export async function srvEmailVerifyCode(data) {
    return request('/api/valcode/email', {
        method: 'post',
        data: {
            code_type: 1,
            email: data.email,
            user_classification: Number(!isDone),
        },
    });
}

export async function srvCheckEmailExist(data) {
    return request('/api/user/check_email_exist', {
        method: 'post',
        data: {
            email: data.email,
        },
    });
}

export async function srvEmailLogin(data) {
    return request('/api/oauth/email_login', {
        method: 'post',
        data: {
            email: data.email,
            code: data.code,
            user_classification: Number(!isDone),
            client_id: '77eae7aaebf39fd0c8bef84e58b37cfd',
        },
    });
}

export async function srvPasswordLogin(data) {
    return request('/api/oauth/password_login', {
        method: 'post',
        data: {
            identity: data.identity,
            password: data.password,
            user_classification: Number(!isDone),
            client_id: '5ef03ed374d091c0a0e2b1eb1d065ce7',
        },
    });
}
