import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, Link,useAccess } from 'umi';
import {
  Table,
  Layout,
  Menu,
  Button,
  Modal,
  Descriptions,
  Drawer,
  message,
  Form,
  Input,
  Popover,
  Tabs,
  Tooltip,
} from 'antd';
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });
import defaultSettings from '../../../defaultSettings';
import { formatUnix } from '../../utils/xtime';
import styles from './index.less';
import { CPTCodesMap } from '@/components/CPTCodesFormItem';
import { srvTreatmentPlanHistoryDetail, srvDischargeOrTransfer } from '@/services/admin';
import {
  srvPatientTreatmentPlanHistoryDetail,
  srvDoctorDischargeOrTransfer,
  srvReuqestInternalNotes,
  srvAddRequestInternalNotes,
} from '@/services/patient';
import {
  CloseCircleOutlined,
  createFromIconfontCN,
  EnvironmentFilled,
  CalendarFilled,
} from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { downloadFile } from '@/utils/utils';
import { srvGetDocumentURL } from '@/services/patient';
import { AsYouType } from 'libphonenumber-js';
import moment from 'moment';
const { TextArea } = Input;
const { TabPane } = Tabs;
const Request = (props) => {
  const { dispatch, loading } = props;
  const { requests } = props.admin;
  const [page, setPage] = useState(1);
  const [currentTab, setCurrentTab] = useState('0');
  const [columns, setColumns] = useState(defaultColumns);
  const [showDischaregeModel, setShowDischaregeModel] = useState(false);
  const [showTransferModel, setShowTransferModel] = useState(false);
  const [item, setItem] = useState({});
  const cx = cn.bind(styles);
  const { Sider, Content } = Layout;
  const [historyVisible, setHistoryVisible] = useState(false);
  const [treatmentPlanHistory, setTreatmentPlanHistory] = useState({});
  const [reasons, setReasons] = useState('');
  const [bridgeRefill, setBridgeRefill] = useState('');
  const [attachFiles, setAttachFiles] = useState([]);
  const [recommendations, setRecommendations] = useState('');
  const [selected, setSelected] = useState('');
  const [previewFileType, setPreviewFileType] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [imgVisible, setImgVisible] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [internalNotes, setInternalNotes] = useState([]);
  const internalInputEl = useRef(null);
  const [notes, setNotes] = useState('');
  const access=useAccess();
  const allReasons = [
    {
      label: 'Exclusionary or Complex Medical Condition/ Diagnosis',
      checked: false,
      key: 'r1',
      options: [
        {
          key: '7',
          checked: false,
          label: 'Currently Pregnant or Breastfeeding',
        },
        {
          key: '1',
          checked: false,
          label: 'History of Heart Attack or Arrhythmias',
        },
        {
          key: '2',
          checked: false,
          label: 'History of Stroke or Seizure Disorder',
        },
        {
          key: '3',
          checked: false,
          label: 'Uncontrolled Hypertension',
        },
        {
          key: '4',
          checked: false,
          label: 'Multiple Co-morbidities and/ or Polypharmacy',
        },
        {
          key: '5',
          checked: false,
          label: 'Other Cardiac Disorder',
        },
        {
          key: '6',
          checked: false,
          label: 'Other Neurological Disorder',
        },
      ],
    },
    {
      label: 'Exclusionary or Complex Psychiatric Condition / Diagnosis',
      checked: false,
      key: 'r2',
      options: [
        {
          key: '1',
          checked: false,
          label: 'Bipolar/ Mood Disordes',
        },
        {
          key: '2',
          checked: false,
          label: 'History of Psychiatric Hospitalization',
        },
        {
          key: '3',
          checked: false,
          label: 'Severe Depression/ Suicidality',
        },
      ],
    },
    {
      label: 'No ADHD diagnosis',
      key: 'r3',
      checked: false,
      options: [
        {
          key: '1',
          checked: false,
          label: 'Patient does not meet DSM-V Criteria for relevant Psychiatric Diagnosis',
        },
      ],
    },
    {
      label: 'Non-compliance',
      key: 'r4',
      checked: false,
      options: [
        {
          key: '1',
          checked: false,
          label: 'Patient sought care from provider outside of done platform',
        },
        {
          key: '2',
          checked: false,
          label: 'patient not attending appointments or minimum follow-up as required',
        },
        {
          key: '3',
          checked: false,
          label: 'patient not taking medications as advised',
        },
      ],
    },
    {
      label: 'Use of Multiple Controlled Substances',
      key: 'r5',
      checked: false,
      options: [
        {
          key: '1',
          checked: false,
          label: 'PDMP or pt reports regular use of benzodiazepines',
        },
        {
          key: '2',
          checked: false,
          label: 'PDMP or pt reports regular use of controlled pain medication',
        },
        {
          key: '3',
          checked: false,
          label: 'PDMP or pt reports use of other stimulant medication (e.g. phentermine)',
        },
        {
          key: '4',
          checked: false,
          label: 'PDMP or pt reports use of Suboxone/Buprenorphine/Methadone',
        },
        {
          key: '5',
          checked: false,
          label: 'PDMP or pt reports use of OTHER controlled substances',
        },
        {
          key: '6',
          checked: false,
          label: 'pt reports uncontrolled use of substances(e.g.alcohol,club drugs,etc.)',
        },
      ],
    },
    {
      label: 'Unacceptable Actions/ Behaviors',
      key: 'r6',
      checked: false,
      options: [
        {
          key: '1',
          checked: false,
          label: 'Aggressive, demanding, or threatening',
        },
        {
          key: '2',
          checked: false,
          label: 'Dishonesty! faulty reporting',
        },
        {
          key: '3',
          checked: false,
          label: 'Discriminatory',
        },
        {
          key: '4',
          checked: false,
          label: 'Overstepping boundaries',
        },
      ],
    },
    {
      label: 'Provider left the practice',
      key: 'r8',
      checked: false,
      options: [],
    },
  ];
  const defaultColumns = [
    {
      title: 'DIOGNOSTIC CODE',
      dataIndex: 'diagnostic_code',
      key: 'diagnostic_code',
      ellipsis: true,
      width: 300,
      render: (v, r) => {
        return (
          <Tooltip placement="topLeft" title={r.diagnostic_code}>
            <div style={{ width: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {r.diagnostic_code}
            </div>
          </Tooltip>
        )
      }
    },
    {
      title: 'PROVIDER',
      dataIndex: 'doctor',
      key: 'doctor',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        return r.doctor.name;
      },
    },
    {
      title: 'PATIENT',
      dataIndex: 'patient_name',
      key: 'patient_name',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        if (!r.patient) {
          return null;
        }
        const userName = `${r.patient.first_name} ${r.patient.last_name}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.patient_id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/patient/${r.patient_id}`}>{r.patient.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.patient.phone)}
                </div>
                <div className="popover-user-item birthdate">
                  <CalendarFilled className="icon" />
                  {r.patient.birthdate &&
                    moment.unix(r.patient.birthdate).utc().format('MM/DD/YYYY')}
                </div>
                <div className="popover-user-item sex">
                  <IconFont className="icon" type="icon-sex" />
                  {r.patient.gender}
                </div>
                <div className="popover-user-item state">
                  <EnvironmentFilled className="icon" />
                  {r.patient.state}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <Link to={`/patient/${r.patient_id}`} style={{ color: '#1a1f36', fontWeight: 500 }}>
              {userName}
            </Link>
          </Popover>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 70,
      render: (v, r) => {
        return v === 0 ? 'New' : v === 1 ? 'Discharged' : 'Transferred';
      },
    },
    {
      title: 'CREATED AT',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: 160,
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      fixed: 'right',
      width: 260,
      render: (v, r) => {
        return (
          <div style={{ display: 'flex' }}>
            <Button
              style={{ marginRight: '10px' }}
              size="small"
              onClick={() => {
                dischargePatient(r.id);
              }}
            >
              Discharge
            </Button>
            <Button
              size="small"
              style={{ marginRight: '10px' }}
              onClick={() => {
                transferPatient(r.id);
              }}
            >
              Transfer
            </Button>
            <Button
              size="small"
              onClick={() => {
                comment(r.id);
              }}
            >
              Comment
            </Button>
          </div>
        );
      },
    },
  ];

  const reviewedColumn = [
    {
      title: 'DEAL AT',
      dataIndex: 'deal_time',
      key: 'deal_time',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return formatUnix(r.deal_time);
      },
    },
  ];

  const switchTab = (e) => {
    setCurrentTab(e.key);
    if (e.key === '1') {
      defaultColumns.pop();
      setColumns(defaultColumns.concat(reviewedColumn));
    } else {
      setColumns(defaultColumns);
    }
    setPage(1);
    loadList({ status: parseInt(e.key), page: 1, page_size: 20 }, () => {});
  };

  useEffect(() => {
    setPage(1);
    setColumns(defaultColumns);
    loadList({ status: parseInt(currentTab), page: 1, page_size: 20 }, () => {});
  }, []);

  const loadList = (payload, callback) => {
    dispatch({ type: 'admin/fetchRequest', payload, callback });
  };

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    loadList({
      status: parseInt(currentTab),
      page: pagination.current,
      page_size: pagination.pageSize,
    });
  };

  const dischargePatient = (id) => {
    setSelected(id);
    setShowDischaregeModel(true);
  };
  const transferPatient = (id) => {
    setSelected(id);
    setShowTransferModel(true);
  };
  const openComment = () => {
    setHistoryVisible(false);
    setShowComment(true);
    getInternalNotesTags.run({ id: selected });
  };
  const comment = (id) => {
    setSelected(id);
    setShowComment(true);
    getInternalNotesTags.run({ id: id });
  };
  const getInternalNotesTags = useRequest(srvReuqestInternalNotes, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (ret.data) {
          setInternalNotes(ret.data.internal_notes);
        }
      }
    },
  });
  const subInternalNoteReq = useRequest(srvAddRequestInternalNotes, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        // if (internalInputEl.current) {
        //   internalInputEl.current.state.value = '';
        // }
        setNotes('');
        getInternalNotesTags.run({ id: selected });
      }
    },
  });
  const submitInternalNotes = () => {
    // var message = internalInputEl.current.state.value;
    let message = notes;
    if (message && message != '' && message.trim().length) {
      subInternalNoteReq.run({
        note: message,
        id: selected,
      });
    } else {
      alert("Can't send reply with empty message!");
      return;
    }
  };
  const onInternalNoteClose = () => {
    setShowComment(false);
    setSelected('');
    setInternalNotes([]);
  };

  const selectRow = (record) => {
    setSelected(record.id);
    setHistoryVisible(true);
    loadHistoryReq.run({ id: record.note_id });
  };
  const onHistoryClose = () => {
    setHistoryVisible(false);
    setSelected('');
  };
  const toPreview = (file) => {
    srvGetDocumentURL({
      object_key: file.object_key,
    }).then((res) => {
      if (res.success) {
        fetchPreviewInfo(res.data);
      }
    });
  };
  const fetchPreviewInfo = (url) => {
    fetch(url)
      .then((res) => {
        const contentType = res.headers.get('Content-Type');
        if (contentType === 'application/pdf') {
          setPreviewFileType(contentType);
        }
        setImgUrl(url);
        setImgVisible(true);
      })
      .catch((e) => {
        window.sendErrorLog(e);
      });
  };
  const submitDischarge = () => {
    submitDischargeTags.run({ id: selected, deal_type: 1 });
  };
  const submitDischargeTags = useRequest(
    access.approver
      ? srvDoctorDischargeOrTransfer
      : srvDischargeOrTransfer,
    {
      manual: true,
      onSuccess: (ret, params) => {
        if (ret.success) {
          message.success('the member has been discharged.');
          setShowDischaregeModel(false);
          setHistoryVisible(false);
          loadList({ status: parseInt(currentTab), page: 1, page_size: 20 }, () => {});
        }
      },
      onError: () => {
        setShowDischaregeModel(false);
      },
    },
  );
  const submitTransferTags = useRequest(
    access.approver
      ? srvDoctorDischargeOrTransfer
      : srvDischargeOrTransfer,
    {
      manual: true,
      onSuccess: (ret, params) => {
        if (ret.success) {
          if (ret.data?.transfer_deal_result === 3) {
            message.success('the member has been added onto the transfer waiting list');
          } else if (ret.data?.transfer_deal_result === 2) {
            message.success('the member has been auto transferred');
          } else {
            message.success('transfer success');
          }
          setShowTransferModel(false);
          setHistoryVisible(false);
          loadList({ status: parseInt(currentTab), page: 1, page_size: 20 }, () => {});
        }
      },
    },
  );

  const submitTransfer = () => {
    submitTransferTags.run({ id: selected, deal_type: 2 });
  };
  const loadHistoryReq = useRequest(
    access.approver
      ? srvPatientTreatmentPlanHistoryDetail
      : srvTreatmentPlanHistoryDetail,
    {
      manual: true,
      onSuccess: (ret, params) => {
        if (ret.success) {
          setTreatmentPlanHistory(ret.data);
          if (ret.data !== '') {
            if (ret.data.recommendation_reasons != '') {
              var reasonTemp = '';
              var select = JSON.parse(ret.data.recommendation_reasons);
              allReasons.map((item) => {
                if (Object.keys(select).includes(item.key)) {
                  reasonTemp = reasonTemp + item.label;
                  if (select[item.key].length === 0) {
                    reasonTemp = reasonTemp + ':\n';
                  } else {
                    var selectOptions = select[item.key];
                    if (item.options.length > 0) {
                      reasonTemp = reasonTemp + ': ';
                      item.options.map((childItem) => {
                        if (selectOptions.includes(childItem.key)) {
                          reasonTemp = reasonTemp + childItem.label + '; ';
                        }
                      });
                    }
                  }
                }
              });
              if (Object.keys(select).includes('r7')) {
                if (Object.keys(select).includes('other_reason')) {
                  reasonTemp = reasonTemp + 'Other reason:\n' + select['other_reason'];
                } else {
                  reasonTemp = reasonTemp + 'Other reason:\n';
                }
              }
              setReasons(reasonTemp);
            }
            if (ret.data.recommendations === 1) {
              setRecommendations('Recommend a transfer to another provider');
            } else if (ret.data.recommendations === 2) {
              setRecommendations('Recommend permanent discharge from the platform');
            }
            if (ret.data.bridge_refill_attestation === 1) {
              if (
                ret.data.bridge_refill_attestation_date === 0 ||
                ret.data.bridge_refill_attestation_date === null
              ) {
                setBridgeRefill(
                  'I attest that I have reviewed the patient’s PDMP and issued a bridge refill for any medications this patient requires. The refill was sent on: ',
                );
              } else {
                setBridgeRefill(
                  'I attest that I have reviewed the patient’s PDMP and issued a bridge refill for any medications this patient requires. The refill was sent on: ' +
                    formatUnix(ret.data.bridge_refill_attestation_date),
                );
              }
            } else if (ret.data.bridge_refill_attestation === 2) {
              setBridgeRefill('The patient does not require a bridge refill.');
            }
            if (ret.data.attach_files != '' && ret.data.attach_files != '[]') {
              setAttachFiles(JSON.parse(ret.data.attach_files));
            }
          }
        }
      },
    },
  );
  const renderAddendum = () => {
    return treatmentPlanHistory &&
      treatmentPlanHistory.Addendums &&
      treatmentPlanHistory.Addendums.length ? (
      treatmentPlanHistory.Addendums.map((addendum) => (
        <Descriptions title="Addendums" layout="vertical" column={1} size="small" bordered={true}>
          <Descriptions.Item
            label={
              addendum.creator_type +
              ' Addendum - ' +
              addendum.creator_name +
              ' ' +
              addendum.creator_title +
              ' ' +
              moment.unix(addendum.created_at).format('MM/DD/YYYY')
            }
          >
            <div className={styles.lineBreak}>{addendum.addendum}</div>
          </Descriptions.Item>
        </Descriptions>
      ))
    ) : (
      <></>
    );
  };
  const renderDescriptions = () => {
    return (
      <>
        <Descriptions layout="vertical" column={1} size="small" bordered={true}>
          <Descriptions.Item label="Note type">Discharge Note</Descriptions.Item>

          <Descriptions.Item label="Recommendations">{recommendations}</Descriptions.Item>
          <Descriptions.Item
            style={{ whiteSpace: 'pre-line' }}
            label="Reasons For Recommendation(Discharge/Transfer)"
          >
            {reasons}
          </Descriptions.Item>
          <Descriptions.Item label="Bridge Refill Attestation">{bridgeRefill}</Descriptions.Item>
          <Descriptions.Item label="Date of Initial Evaluation">
            {treatmentPlanHistory.initial_evaluation_date > 0
              ? formatUnix(treatmentPlanHistory.initial_evaluation_date)
              : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Current Diagnoses">
            {treatmentPlanHistory.diagnostic_code}
          </Descriptions.Item>
          <Descriptions.Item label="Diagnosis Notes">
            {treatmentPlanHistory.diagnostic_code_notes}
          </Descriptions.Item>
          <Descriptions.Item label="CPT Code">
            {CPTCodesMap[treatmentPlanHistory.cpt_code]
              ? CPTCodesMap[treatmentPlanHistory.cpt_code]
              : treatmentPlanHistory.cpt_code}
          </Descriptions.Item>
          {treatmentPlanHistory.addon_code && (
            <Descriptions.Item label="Add-on Code">
              {CPTCodesMap[treatmentPlanHistory.addon_code]
                ? CPTCodesMap[treatmentPlanHistory.addon_code]
                : treatmentPlanHistory.addon_code}
            </Descriptions.Item>
          )}
          <Descriptions.Item style={{ whiteSpace: 'pre-line' }} label="Medication History">
            {treatmentPlanHistory.medication_history}
          </Descriptions.Item>
          <Descriptions.Item label="Medication Notes">
            {treatmentPlanHistory.medication_history_notes}
          </Descriptions.Item>
          <Descriptions.Item label="Treatment Summary">
            {treatmentPlanHistory.treatment_summary}
          </Descriptions.Item>
        </Descriptions>
        {renderAddendum()}
        <Descriptions
          layout="vertical"
          column={1}
          size="small"
          bordered={true}
          style={{ marginBottom: '80px' }}
        >
          <Descriptions.Item label="Attach File">
            {attachFiles.length > 0 &&
              attachFiles.map((file) => (
                <>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div style={{ marginRight: 'auto' }}>{file.name}</div>
                    <div
                      style={{
                        color: '#5469d4',
                        fontSize: '16px',
                        cursor: 'pointer',
                        marginRight: '10px',
                      }}
                    >
                      <span onClick={() => toPreview(file)}>Preview</span>
                    </div>
                    <div style={{ color: '#5469d4', fontSize: '16px', cursor: 'pointer' }}>
                      <span onClick={() => downloadFile(file)}>Download</span>
                    </div>
                  </div>
                </>
              ))}
          </Descriptions.Item>
        </Descriptions>
        {currentTab === '0' && (
          <>
            <Form layout="vertical">
              <div
                style={{
                  position: 'fixed',
                  bottom: 0,
                  background: 'white',
                  height: '80px',
                  width: '550px',
                  paddingTop: '10px',
                  paddingRight: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  type="link"
                  class="left-button"
                  onClick={() => {
                    transferPatient(selected);
                  }}
                >
                  Reject Discharge and Transfer
                </Button>
                <Button
                  type="primary"
                  class="right-button"
                  onClick={() => {
                    dischargePatient(selected);
                  }}
                >
                  Approve Discharge
                </Button>
              </div>
            </Form>
          </>
        )}
      </>
    );
  };

  const renderMessagesList = () => {
    var messageDom = internalNotes.map((v, index) => {
      return renderMessageItem(v);
    });
    return messageDom;
  };
  const renderMessageItem = (v) => {
    return (
      <div
        className={cx({ messageRight: v.add_by_role != 1, messageLeft: v.add_by_role == 1 })}
        key={v.id}
      >
        <div className={cx({ avatar: true })}>
          {v.add_by_role != 1 && <img src={v.add_by_avatar_url} />}
          {v.add_by_role == 1 && (
            <div className={cx({ avatar: true })}>
              <div />
            </div>
          )}
        </div>
        <div className={cx({ bubble: true, internalNote: true })}>
          <p dangerouslySetInnerHTML={{ __html: v.note.split('\n').join('<br/>') }} />

          <span className={cx({ time: true })}>
            {moment.unix(v.created_at).fromNow()}
            {v.add_by_role != 1 && renderSendBy(v)}
          </span>
        </div>
      </div>
    );
  };
  const renderSendBy = (v) => {
    var addBy = ' by ' + v.add_name;
    if (v.add_by_role == 2) {
      return addBy + ' ' + 'Care Team';
    } else {
      return addBy;
    }
  };
  return (
    <Layout>
      <Sider theme="light">
        <Menu selectedKeys={[currentTab]} onClick={switchTab}>
          <Menu.Item key="0">New</Menu.Item>
          <Menu.Item key="1">Reviewed</Menu.Item>
        </Menu>
      </Sider>
      <Content theme="light">
        <div className={cx({ container: true })}>
          <div className={cx({ header: true })}>
            <h3>Discharge Notes</h3>
          </div>
          <Table
            onRow={(record) => ({
              onClick: (evt) => {
                evt.stopPropagation();
                if (evt.target && evt.target.tagName.toUpperCase() === 'TD') {
                  selectRow(record);
                }
              },
            })}
            rowClassName={(r) => {
              if (r.id == selected) {
                return 'rowSelected';
              }
              return '';
            }}
            columns={columns}
            dataSource={requests.items}
            size={'small'}
            scroll={{ x: 1000 }}
            rowKey="id"
            loading={loading.effects['admin/fetchRequest']}
            onChange={handleTableChange}
            pagination={{ pageSize: 20, current: page, simple: true, total: requests.total }}
          />
          <Modal
            visible={showDischaregeModel}
            onCancel={() => {
              setShowDischaregeModel(false);
            }}
            onOk={() => {
              setShowDischaregeModel(false);
            }}
            footer={null}
          >
            <p
              style={{
                color: '#000000',
                fontSize: '20px',
                fontWeight: '600',
                marginBottom: '20px',
              }}
            >
              Approve Discharge
            </p>
            <p style={{ color: '#000000 80%', marginBottom: '20px' }}>
              Once approved, the patient will be removed from the provider’s panel and will be
              discharged from the platform.
            </p>
            <div style={{ display: 'flex', justifyContent: 'space-between', color: '#000000' }}>
              <Button
                key="cancel"
                onClick={() => {
                  setShowDischaregeModel(false);
                }}
                type="link"
              >
                Cancel
              </Button>
              ,
              <Button
                key="confirm"
                onClick={() => {
                  submitDischarge();
                }}
                type="primary"
                loading={submitDischargeTags.loading}
              >
                Discharge the Patient
              </Button>
            </div>
          </Modal>
          <Modal
            visible={showTransferModel}
            onOk={() => {
              setShowTransferModel(false);
            }}
            onCancel={() => {
              setShowTransferModel(false);
            }}
            footer={null}
          >
            <p
              style={{
                color: '#000000',
                fontSize: '20px',
                fontWeight: '600',
                marginBottom: '20px',
              }}
            >
              Reject Discharge
            </p>
            <p style={{ color: '#000000 80%', marginBottom: '20px' }}>
              Once rejected, the patient will be removed from the provider’s panel and will be
              automatically transferred to another provider. If there are no available providers,
              the patient will be added to the awaiting transfer list.
            </p>
            <div style={{ display: 'flex', justifyContent: 'space-between', color: '#000000' }}>
              <Button
                type="link"
                key="cancel"
                onClick={() => {
                  setShowTransferModel(false);
                }}
              >
                Cancel
              </Button>
              ,
              <Button
                key="confirm"
                onClick={() => {
                  submitTransfer();
                }}
                type="primary"
                loading={submitTransferTags.loading}
              >
                Transfer the Patient
              </Button>
            </div>
          </Modal>
          <Drawer
            onClose={onHistoryClose}
            className={cx({ historyDrawer: true })}
            title={
              <div className={cx({ title: true })} style={{display:'flex',justifyContent: 'space-between'}}>
                <h3>Clinician Note</h3>
                <Button
                  onClick={() => {
                    openComment();
                  }}
                >
                  Comment
                </Button>
              </div>
            }
            width="600"
            placement="right"
            closable={false}
            visible={historyVisible}
          >
            {renderDescriptions()}
          </Drawer>
          {imgVisible && (
            <>
              <div
                className="img-preview-container"
                style={{
                  position: 'absolute',
                  zIndex: '9999',
                  top: '0',
                  bottom: '0',
                  left: '0',
                  right: '0',
                  margigin: 'auto',
                  width: '90%',
                  height: '90%',
                }}
              >
                <CloseCircleOutlined
                  className="close-button"
                  onClick={() => {
                    setImgVisible(false);
                  }}
                />
                {previewFileType === 'application/pdf' ? (
                  <object
                    className="identity-img-large"
                    data={imgUrl}
                    type="application/pdf"
                   />
                ) : (
                  <img className="identity-img-large" src={imgUrl} />
                )}
              </div>
              <div
                onClick={() => {
                  setImgVisible(false);
                }}
                className="upload-mask"
               />
            </>
          )}
          <Drawer
            className={cx({ messageDrawer: true })}
            width="500"
            placement="right"
            closable={false}
            onClose={onInternalNoteClose}
            visible={showComment}
          >
            <div className={cx({ messageContainer: true })}>{renderMessagesList()}</div>
            <div className={cx({ sendbox: true })}>
              <Tabs>
                <TabPane tab="Internal note">
                  <Input.TextArea
                    // ref={internalInputEl}
                    rows={5}
                    className={cx({ internalTextInput: true })}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </TabPane>
              </Tabs>
              <div className={cx({ sendboxBtnBox: true })}>
                <Button
                  type="primary"
                  onClick={submitInternalNotes}
                  loading={subInternalNoteReq.loading}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Drawer>
        </div>
      </Content>
    </Layout>
  );
};

export default connect(({ admin, loading }) => ({
  admin,
  loading,
}))(Request);
