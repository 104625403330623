import React, { useState } from 'react';
import { Device, Call } from '@twilio/voice-sdk';
import { Button } from 'antd';
import { connect } from 'umi';
import Draggable from 'react-draggable';
import { useMount, useEventEmitter } from 'ahooks';

import { srvGetTwilioVoiceToken } from '@/services/patient';
import { ReactComponent as CallIcon } from '@/assets/call.svg';
import useStopwatch from '@/utils/useStopwatch';
import useInterval from '@/utils/useInterval';
import styles from './index.less';

const CallWidget = (props) => {
  const { dispatch } = props;
  const [device, setDevice] = useState(null);
  const [tokenInterval, setTokenInterval] = useState(20000);
  const [call, setCall] = useState(null);
  const [name, setName] = useState('');
  const { seconds, minutes, reset } = useStopwatch({ autoStart: true });
  const oncall$ = useEventEmitter();

  useInterval(async () => {
    if (device) {
      try {
        const { data } = await srvGetTwilioVoiceToken();
        device.updateToken(data);
      } catch (err) {
        console.error(err);
      }
    }
  }, tokenInterval);

  const ensureDevice = (token) => {
    if (!device) {
      const dev = new Device(token, {
        // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
        // providing better audio quality in restrained network conditions. Opus will be default in 2.0.
        codecPreferences: ['opus', 'pcmu'],
        // Use fake DTMF tones client-side. Real tones are still sent to the other end of the call,
        // but the client-side DTMF tones are fake. This prevents the local mic capturing the DTMF tone
        // a second time and sending the tone twice. This will be default in 2.0.
        fakeLocalDTMF: true,
        // Use `enableRingingState` to enable the device to emit the `ringing`
        // state. The TwiML backend also needs to have the attribute
        // `answerOnBridge` also set to true in the `Dial` verb. This option
        // changes the behavior of the SDK to consider a call `ringing` starting
        // from the connection to the TwiML backend to when the recipient of
        // the `Dial` verb answers.
        enableRingingState: true,

        debug: false,
      });

      dev.on('error', (e) => {
        console.error(e);
      });
      setDevice(dev);
      return dev;
    }

    return device;
  };

  const onCallHangUp = () => {
    setCall(null);
    if (device) {
      device.disconnectAll();
      device.destroy();
      setDevice(null);
    }
    setTokenInterval(-1);
  };

  const makeCall = async (vals) => {
    reset();
    try {
      const { data } = await srvGetTwilioVoiceToken();
      const dev = ensureDevice(data);
      var params = {
        patient_id: vals.patient_id,
        from_user_id: vals.from_user_id,
        target: vals.target,
        reason: vals.reason,
      };

      setTokenInterval(5000);
      await dev.disconnectAll();
      const call = await dev.connect({ params });
      call.on('cancel', onCallHangUp);
      call.on('disconnect', onCallHangUp);
      call.on('reject', onCallHangUp);
      call.on('error', onCallHangUp);
      setName(`${vals.first_name} ${vals.last_name}`);
      setCall(call);
    } catch (err) {
      console.error(err);
    }
  };

  oncall$.useSubscription((vals) => {
    makeCall(vals);
  });

  const handleHangUp = () => {
    setTokenInterval(-1);
    if (device) {
      device.disconnectAll();
      device.destroy();
      setCall(null);
      setDevice(null);
    }
  };

  useMount(() => {
    dispatch({ type: 'call/saveOnCall', payload: oncall$ });
  });

  const showCall = () => {
    if (!call) {
      return false;
    }
    const status = call.status();
    if (
      status == 'connecting' ||
      status == 'open' ||
      status == 'pending' ||
      status == 'reconnecting' ||
      status == 'ringing'
    ) {
      return true;
    }

    return false;
  };

  if (!showCall()) {
    return <></>;
  }
  return (
    <Draggable>
      <div className={styles.call}>
        <p>In call with {name}</p>
        <div className={styles.btnBox}>
          <div className={styles.time}>
            {`${minutes}`.padStart(2, '0')}:{`${seconds}`.padStart(2, '0')}
          </div>
          <Button
            type="danger"
            className={styles.btn}
            onClick={handleHangUp}
            shape="circle"
            icon={<CallIcon />}
          />
        </div>
      </div>
    </Draggable>
  );
};

export default connect(({ account, call, loading }) => ({
  currentUser: account.currentUser,
  loading: loading.models.account,
  call: call,
}))(CallWidget);
