import React, {useEffect, useState} from 'react';
import {Table, Statistic, Button, Row, Col, Tag, Drawer, Typography, Spin, Space, message, Tooltip} from 'antd';
import cn from 'classnames/bind';
import styles from './WalletTable.css';
import './WalletTable.css';
const cx = cn.bind(styles);
import WalletAccountSettingForm from "@/components/Dashboard/WalletAccountSettingForm";
import CashOutPopover from "@/components/Dashboard/CashOutPopover";
import {
    srvGetWalletDetail, srvRetryPayout,
    srvUpdatePayoutStatus,
} from "@/services/account";
import {useRequest} from "ahooks";
import moment from "moment";
import {QuestionCircleOutlined} from "@ant-design/icons";
import { stringify } from 'querystring';

const WalletTable = (props) => {
    const { walletVisible, setWalletVisible, providerProfile} = props;
    const [accountSettingVisible, setAccountSettingVisible ] = useState(false)
    const [showCashOutModel, setShowCashOutModel] = useState(false);
    const [currentBalance, setCurrentBalance] = useState(0);
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [pendingCashOut, setPendingCashOut] = useState(0);
    const [data, setData] = useState([]);
    const [accountListVisible, setAccountListVisible ] = useState(false);
    const [hasCanUseAccount,setHasCanUseAccount]=useState(false);
    const columns = [
        {
            title: 'AMOUNT',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'STATUS',
            dataIndex: 'strip_payout_status',
            key: 'strip_payout_status',
            render: (v, r) => {
                if (v != 'failed' && v != 'canceled') {
                    return v;
                }
                return (<>
                    <span>{v}&nbsp;&nbsp; </span><Tooltip title={r.strip_reason}>
                        <QuestionCircleOutlined style={{ color: "red", fontSize: '14px', lineHeight: '20px', marginBottom: '4px',position:'absolute',top:'20%' }} />
                    </Tooltip>
                </>)

            },
        },
        {
            title: 'BANK ACCOUNT',
            dataIndex: 'bank_account',
            key: 'bank_account',
        },
        {
            title: 'CASH-OUT DATE',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'ACTION',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    {record.strip_payout_status === 'failed'|| record.strip_payout_status === 'canceled' ||record.strip_payout_id==='' ?(
                    <Button onClick={retryPayout(record)} loading={doRetryPayout.loading}>Retry</Button>
                    ):null}
                    {record.strip_payout_status === 'paid'&&providerProfile.enable_payout_invoice&&record.invoice_flag?(
                    <Button onClick={openInvoice(record)}>Invoice</Button>
                    ):null}
                </Space>
            ),
        }
    ];
    
    const retryPayout = (record) => {
        return async () => {
            await doRetryPayout.run({id: record.id});
        }
    }
    const openInvoice=(record)=>{
        return () => {
            const param = stringify({
                id: record.id
            });
            window.open(`/api/payout/invoice/pdf?${param}`, '_blank');
        }
    }
    
    const doRetryPayout = useRequest(srvRetryPayout, {
        manual: true,
        onSuccess: (ret) => {
            if (ret.success) {
                message.success('Successfully');
                getWalletDetail.run();
            } else {
                message.error(ret.message);
            }
        }
    });
    
    const OnAccountSetting = () => {
        setAccountSettingVisible(true);
        setAccountListVisible(true);
    }
    const closeAccountList=()=>{
        setAccountListVisible(false);
    }
    
    const getWalletDetail = useRequest(srvGetWalletDetail, {
        manual: true,
        onSuccess: (ret) => {
            if (ret.success) {
                const {current_balance, total_earnings, pending_cash_out, history, accounts} = ret.data;
                setCurrentBalance(current_balance);
                setTotalEarnings(total_earnings);
                setPendingCashOut(pending_cash_out);
                if(accounts){
                    const hasStatusOne = accounts.some((item) => item.account_status === 'verified');
                    if(hasStatusOne){
                        setHasCanUseAccount(true);
                    }
                }
                setData(history.map((item, index) => ({
                    id: item.id,
                    key: index + 1,
                    amount: item.amount,
                    strip_payout_status: item.strip_payout_status,
                    strip_payout_id: item.strip_payout_id,
                    strip_reason: item.strip_reason,
                    bank_account: item.bank_account,
                    created_at: item.created_at ? moment.unix(item.created_at).format('MM/DD/YYYY') : '',
                    invoice_flag: item.invoice_flag,
                })));
            }
        }
    });
    
    const cashOutCallback = () => {
        getWalletDetail.run();
    }
    
    useEffect(() => {
        if (walletVisible) {
            getWalletDetail.run();
        }
    }, [walletVisible]);

    const OnCashOut = () => {
        getWalletDetail.run();
        if (hasCanUseAccount) {
            setShowCashOutModel(true);
        } else {
            message.error("No available accounts. Please add or update your account.");
            setAccountSettingVisible(true);
            setAccountListVisible(true);
        }
    }

    const updatePayoutStatusRequest = useRequest(srvUpdatePayoutStatus, {
        manual: true,
        onSuccess: (ret) => {
            if (ret.success) {
                message.success('Refresh successfully');
                getWalletDetail.run();
            } else {
                message.error(ret.message);
            }
        }
    });
    
    
    const OnRefresh = () => {
        updatePayoutStatusRequest.run();
    }
    
    const tableDom = () => {
        return (
            <div className={cx({ "wallet-container": true })}>
               <Spin spinning={getWalletDetail.loading} tip="Loading...">
                <Row gutter={16}>
                    <Col span={12}>
                        <Statistic
                            title={
                            <Space>
                                <span className="stat-title">Current Balance</span>
                                <Tooltip title="The balance from the previous month is updated at 12:00 AM (PT) on the 3rd of each month.">
                                    <QuestionCircleOutlined style={{ color: "#5469D4", fontSize: '14px', lineHeight: '20px', marginBottom: '4px' }} />
                                </Tooltip>
                            </Space>
                            }
                            value={currentBalance}
                            precision={2}
                            className="stat-value"
                            valueStyle={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}
                        />
                        
                    </Col>
                    <Col span={12}>
                        <Statistic
                            title={<span className="stat-title">Total Earnings</span>}
                            value={totalEarnings}
                            precision={2}
                            className="stat-value"
                            valueStyle={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}
                        />
                    </Col>
                </Row>
                <Row gutter={16} className="margin-top-24">
                    <Col span={12}>
                        <Statistic
                            title={<span className="stat-title">Pending Cash Out</span>}
                            value={pendingCashOut}
                            precision={2}
                            className="stat-value"
                            valueStyle={{ fontWeight: 'bold', fontSize: '14px', marginTop: '10px' }}
                        />
                    </Col>
                </Row>
                <Typography.Title level={4} className="margin-top-24" style={{fontSize: '14px', fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span>Cash-Out History</span>
                    <Button type="primary" onClick={OnRefresh} loading={updatePayoutStatusRequest.loading}>Refresh</Button>
                </Typography.Title>
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    className="custom-table"
                    bordered={false}
                />
                </Spin>
            </div>
        );
    };

    const onTableClose = () => {
        setWalletVisible(false);
    };

    return (
        <>
            <Drawer
                className={cx({ walletDrawer: true })}
                width={600}
                title={<div>
                    <div className={cx({ title: true })} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h1 style={{ fontWeight: 'bold' }}>Wallet&nbsp;&nbsp;
                            {(false) && (
                                <Tooltip title={personDetail}>
                                    <Tag color={"red"}>Restricted ㊀</Tag>
                                </Tooltip>
                            )}</h1>
                        <div>
                            <Button type="default" onClick={OnAccountSetting} style={{ marginRight: 8 }}>Account</Button>
                            <Button type="primary" disabled={currentBalance <= 0} onClick={OnCashOut}>Cash Out</Button>
                        </div>
                    </div>
                    </div>
                }
                placement="right"
                closable={false}
                onClose={onTableClose}
                visible={walletVisible}
                destroyOnClose={true}
            >
                {tableDom()}
            </Drawer>
            <CashOutPopover
                showCashOutModel={showCashOutModel}
                setShowCashOutModel={setShowCashOutModel}
                currentBalance={currentBalance}
                cashOutCallback={cashOutCallback}
            />
            <WalletAccountSettingForm
                accountSettingVisible={accountSettingVisible}
                setAccountSettingVisible={setAccountSettingVisible}
                providerProfile={providerProfile}
                accountListVisible={accountListVisible}
                closeAccountList={closeAccountList}
            />
        </>
    );
};

export default WalletTable;
