import React from 'react';
import { Row, Col, Modal, Select, Descriptions, Input, Form } from 'antd';
import cn from 'classnames/bind';
import numeral from 'numeral';

import styles from './index.less';
const cx = cn.bind(styles);

const PasswordModal = ({ item = {}, states = [], onOk, ...modalProps }) => {
  const [form] = Form.useForm();
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (item.id) {
          values.id = item.id;
        }
        onOk(values);
      })
      .catch((errorInfo) => {});
  };

  const modalOpts = {
    ...modalProps,
    onOk: handleOk,
  };
  return (
    <Modal {...modalOpts} className={cx({ doctorModal: true })}>
      <Form
        form={form}
        initialValues={item}
        preserve={false}
        className={cx({ modalForm: true })}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Password is required' }]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PasswordModal;
