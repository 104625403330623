import React, { useState, useEffect, useMemo } from 'react';
import {
  Space,
  Input,
  Select,
  Row,
  Col,
  Button,
  Form,
  Checkbox,
  Divider,
  Tooltip,
  Radio,
  Modal,
  DatePicker,
  InputNumber
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useFlag } from '@unleash/proxy-client-react';

import cn from 'classnames/bind';
import styles from './index.less';
import { formatUnix } from '@/utils/xtime.js';
import { PRE_CONSULTATION_REVIEW } from '@/constants'
const cx = cn.bind(styles);


import { useRequest, useLocalStorageState, useMount,useUnmount } from 'ahooks';

import {srvAddTreatmentPlan, srvAddDraftTreatmentPlan,srvMarkAppointmentToBeTransfer, srvMarkNoDiagnosis,srvEditPatientTreatmentPlanHistory,srvPatientDraftTreatmentPlan} from '@/services/patient';

import {
  initialCompletedOpts,
  getRealReason,
  noDiagnoseReason,
  otherReason,
  seenPatientOptions
} from '@/components/AddNote/meta.js';
import { getNoteTemplatesForPatient } from '@/utils/utils';
import NextFollowUpDateFormItem from '@/components/NextFollowUpDateFormItem';
import CheckboxInput from './CheckboxInput';
import RadioGroupInput from './RadioGroupInput';
import CPTCodesFormItem from '@/components/CPTCodesFormItem';
import LastRefillDateFormItem from '@/components/LastRefiiDateFormItem';
import PatientReviewRadioGroupInput from "@/components/InitialEvaluationNote/PatientReviewRadioGroupInput";
import DynamicRadioGroupInput from "@/components/InitialEvaluationNote/DynamicRadioGroupInput";
import AddADHDSubmenu from "@/components/InitialEvaluationNote/ADHDsubmenu"
import moment from "moment-timezone";
const { TextArea } = Input;
import debounce from 'lodash/debounce';
let exitFormValues = {};
let exitInitialValues = {};
let exitSubmit = false;
const InitialEvaluationNotesForm = (props) => {
  const {form, patient, appointment, setNoteVisible, onRefresh} = props;

  const tmpl = getNoteTemplatesForPatient(patient).InitialEvaluationV2;

  const flagNextFollowUpDate = useFlag('enable_next_follow_up_date');
  let flagDraftClinicianNote = useFlag('draft_clinician_note');
  const [draft, setDraft] =  useState({});
  const [initialValues, setInitialValues] = useState({});
  const [diagnosisWriteInRequired, setDiagnosisWriteInRequired] = useState(false)
  const [isNoDiagnosed, setIsNoDiagnosed] = useState(false);
  const [showMoreReason, setShowMoreReason] = useState(false);
  const [isShowConfirmTransfer, setIsShowConfirmTransfer] = useState(false);
  const [isNotRightPatient, setIsNotRightPatient] = useState(false);
  const [isSeenPatient, setIsSeenPatient] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isDraft, setIsDraft] = useState(flagDraftClinicianNote ? true : false);
  const [autoSaveTimer, setAutoSaveTimer] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [formValues, setFormValues] = useState({});
  const draftNoteID = React.useRef(0);
  let patientAge = patient.birthdate && moment().diff(moment.unix(patient.birthdate), 'years')
  const flagLockClinicianNote = useFlag('lock_clinician_note');
  const [message,setMessage]=useState(tmpl.message_to_patient.replace(
    'DONE_FIRST_NAME',
    patient&&patient.first_name ? patient.first_name : patient&&patient.preferred_name?patient.preferred_name:''
    ).replace(
      'PROVIDER_NAME',
      patient?.provider_name ?patient.provider_name:''
    ).replace(
      'PHARMACY_INFORMATION',
      patient?.pharmacy?.name ? `${patient?.pharmacy?.name} ${patient?.pharmacy?.address_1} ${patient?.pharmacy?.address_2 || ''} ${patient?.pharmacy?.city} ${patient?.pharmacy?.state} ${patient?.pharmacy?.country || ''}` : 'None',
    ).replace(
      'RX_NAME',''
    ).replace('PROVIDER_NAME_TITLE',patient?.provider_name ? `${patient?.provider_name} ${patient?.provider_title}`: ''));

  useEffect(() => {
    if (!draft || !Object.keys(draft).length) {
      var lifeLineContent = `National Suicide Prevention Lifeline URL: https://suicidepreventionlifeline.org/?utm_source=google&utm_medium=web&utm_campaign=onebox
      National Suicide Prevention Lifeline Phone: 1-800-273-8255`;
      if (patient && patient.country==='ca'){
        lifeLineContent = `Canada Crisis Hotline：
        phone: 1-833-456-4566
        URL: https://www.crisisservicescanada.ca/`
      }
      const data = {
        screening_results_reviewed: true,
        patient_pdmp_reviewed: true,
        consent_telemedicine: true,
        provider_notes: tmpl.provider_notes
          .replace('AGE', patient ? patientAge : '')
          .replace('GENDER', patient ? patient.gender : '')
          .replace('DATE_OF_SERVICE', appointment ? formatUnix(appointment.appointed_at, 'YYYY-MM-DD') : 'DATE_OF_SERVICE'),
      }
      form.setFieldsValue(data);
      if (appointment && appointment.appointed_at > 0) {
        const appointmentStartTime = moment.unix(appointment.appointed_at)
        const appointmentEndTime = moment.unix(appointment.appointed_at + (60 * 25))
        if (appointmentStartTime) {
          form.setFieldValue(
            ['struct_note_data', 'appointment_start_time'],
            appointmentStartTime
          );
        }
        if (appointmentEndTime) {
          form.setFieldValue(
            ['struct_note_data', 'appointment_end_time'],
            moment(appointmentEndTime)
          );
        }
      }
    }
  }, [patient]);

  useMount(() => {
    if (flagDraftClinicianNote){
      const data = { patient_id: patient.id, note_type: 1 };
      getDraftReq.run({ ...data });
    }

    const initial = form.getFieldsValue();
    setInitialValues(initial);
    exitInitialValues = initial;
  }, []);

  useEffect(() => {
    if (draft && Object.keys(draft).length) {
      form.setFieldsValue(draft);
      const appointmentStartTime = draft?.StructNoteCommonData?.appointment_start_time
      const appointmentEndTime = draft?.StructNoteCommonData?.appointment_end_time
      if (appointmentStartTime) {
        form.setFieldValue(
          ['struct_note_data', 'appointment_start_time'],
          moment(appointmentStartTime)
        );
      }
      if (appointmentEndTime) {
        form.setFieldValue(
          ['struct_note_data', 'appointment_end_time'],
          moment(appointmentEndTime)
        );
      }
    }
  }, [draft]);

  const handleClose = (e) => {
    if (flagDraftClinicianNote) {
      if (!exitSubmit && exitFormValues && Object.keys(exitFormValues).length > 0 && !Object.is(exitInitialValues, exitFormValues)) {
        e.returnValue = 'Are you sure you want to leave?';
      }
    }
  };
  useEffect(() => {
    const handleBeforeUnload = e => {
      handleClose(e);
      e.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, []);

  const addNoteReq = useRequest(srvAddTreatmentPlan, {
    manual: true,
    onSuccess: (ret) => {
      setDraft({});
      onRefresh();
      setNoteVisible(false);
    },
  });

  const addDraftNoteReq = useRequest(srvAddDraftTreatmentPlan, {
    manual: true,
    onSuccess: (ret) => {
      const splitArray = ret.data.split(', ');
      draftNoteID.current = splitArray[1];
    },
  });

  const editHistoryReq = useRequest(srvEditPatientTreatmentPlanHistory, {
    manual: true,
    onSuccess: (ret) => {
      if (!isDraft){
        setDraft({});
        onRefresh();
        setNoteVisible(false);
      }
    },
  });

  const getDraftReq = useRequest(srvPatientDraftTreatmentPlan, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.data) {
        const data = ret.data;
        data.struct_note_data = ret.data.StructNoteCommonData;
        if (ret.data.diagnostic_code !== '') {
          data.diagnostic_code = ret.data.diagnostic_code.split(';');
        }
        if (ret.data.struct_note_data.most_recent_pcp_appointment) {
          data.struct_note_data.most_recent_pcp_appointment = numberToDate(
            ret.data.struct_note_data.most_recent_pcp_appointment,
          );
        }
        if (data.patient_review_no_concerns) {
          data.patient_review_no_concerns = 0;
        } else {
          data.patient_review_no_concerns = 1;
        }
        if (data.drug_allergy === true) data.drug_allergy = 1;
        if (data.drug_allergy === false) data.drug_allergy = 0;
        if (data.struct_note_data.patient_endorse_psych_history === false) {
          data.struct_note_data.patient_endorse_psych_history = 0;
        }
        if (data.struct_note_data.patient_endorse_psych_history === true) {
          data.struct_note_data.patient_endorse_psych_history = 1;
        }
        if (data.struct_note_data.patient_endorsement === true) {
          data.struct_note_data.patient_endorsement = 'Endorses';
        } else {
          data.struct_note_data.patient_endorsement = 'Denies';
        }
        if (data.message_to_patient){
          setMessage(data.message_to_patient);
        }
        delete data.next_follow_up_date;
        delete data.next_follow_up_month;
        delete data.last_refill_date
        setDraft(data);
        draftNoteID.current = ret.data.id;
      }
    },
  });

  const markNoDiagnosisReq = useRequest(srvMarkNoDiagnosis, {
    manual: true,
    onSuccess: () => {
      setNoteVisible(false);
      onRefresh();
    },
  });

  const setNotContinuePatient = (e) => {
    const checked = e.target.checked;
    setIsNotRightPatient(checked)
  };

  const onSeenPatientChanged = (e) => {
    const op = e.target.value;
    if (op === 'Y') {
      setIsNoDiagnosed(true);
      setIsSeenPatient(true)
    } else {
      setIsNoDiagnosed(false);
      setIsSeenPatient(false)
    }
  };

  const markAppointmentToBeTransfer = useRequest(srvMarkAppointmentToBeTransfer, {
    manual: true,
    onSuccess: () => {
      if (!flagDraftClinicianNote || !isDraft) {
        setDraft({});
        setNoteVisible(false);
        onRefresh();
      }
    },
  });

  const onReasonChanged = (e) => {
    const reason = e.target.value;
    if (reason === 'c') {
      setShowMoreReason(true);
    } else {
      setShowMoreReason(false);
    }
  };

  const addNote = (values) => {
    values.note_type = 1; // InitialEvaluation
    values.note_status = 2;
    values.user_id = appointment ? appointment.patient.id : patient.id;
    if (appointment) values.appointment_id = appointment.id;
    if (values.no_diagnose) {
      values.id = values.user_id;
      let reason = getRealReason(values);
      values.reason = reason + '\n' + values.addition_notes_for_no_diagnose;
      markNoDiagnosisReq.run({ ...values });
    } else {
      addNoteReq.run({ ...values });
    }
  };

  const addNoteWithDraft = (values) => {
    values.note_type = 1; // InitialEvaluation
    values.user_id = appointment ? appointment.patient.id : patient.id;
    if (appointment) values.appointment_id = appointment.id;
    if (isDraft){
      if (draftNoteID.current!=0){
        const data = { ...values, id: draftNoteID.current };
        editHistoryReq.run(data);
      }else{
        addDraftNoteReq.run({ ...values });
      }
    }else{
      if (values.no_diagnose) {
        values.id = values.user_id;
        let reason = getRealReason(values);
        values.reason = reason + '\n' + values.addition_notes_for_no_diagnose;
        markNoDiagnosisReq.run({ ...values, note_id: draftNoteID.current ? draftNoteID.current.toString() : '0' });
      } else {
        const data = { ...values, note_id: draftNoteID.current ? draftNoteID.current.toString() : '0' };
        addNoteReq.run({ ...data });
      }
    }

  };

  useUnmount(() => {
    if (flagDraftClinicianNote) {
      if (!submitted && formValues && Object.keys(formValues).length > 0 && !Object.is(initialValues, formValues)) {
        setDraft(formValues);
        form.validateFields()
          .then(() => {
            setIsDraft(true);
            onFinish(formValues);
          })
      }
      // Clean up the timer on component unmount
      clearInterval(autoSaveTimer);
    }
  });

  const numberToDate = (number) => {
    const year = number.toString().substr(0, 4);
    const month = number.toString().substr(4, 2);
    const day = number.toString().substr(6, 2);

    const date = `${year}-${month}-${day}`;
    return date;
  };

  const onFinish = (data) => {
    if (data.cpt_code && data.cpt_code.length > 5) {
      data.cpt_code = data.cpt_code.slice(0, 5)
    }
    if (data.medicines) {
      data.medicines = JSON.parse(data.medicines);
      data.rx = data.medicines
        .map((v) => {
          return [
            v.drug.drug_name,
            v.drug.drug_strength,
            v.drug.drug_strength_unit,
            v.drug.drug_route,
            v.drug.dosage_desc,
          ]
            .filter((t) => t !== '')
            .join(' ');
        })
        .join(';');
    }
    data.struct_note_data.appointment_start_time = data.struct_note_data.appointment_start_time.toString()
    data.struct_note_data.appointment_end_time = data.struct_note_data.appointment_end_time.toString()
    data.message_to_patient=message;
    if (data.patient_review_no_concerns === 1) data.patient_review_no_concerns = false
    if (data.patient_review_no_concerns === 0) data.patient_review_no_concerns = true
    data.struct_note_data.patient_endorsement = data.struct_note_data.patient_endorsement === 'Endorses'
    if(!data.struct_note_data.patient_endorsement){
      data.struct_note_data.patient_endorsement_detail='';
    }
    if (data.drug_allergy === 1) data.drug_allergy = true
    if (data.drug_allergy === 0) data.drug_allergy = false
    if (data.struct_note_data.patient_endorse_psych_history === 0) data.struct_note_data.patient_endorse_psych_history = false
    if (data.struct_note_data.patient_endorse_psych_history === 1) data.struct_note_data.patient_endorse_psych_history = true
    data.struct_note_data.most_recent_pcp_appointment = data.struct_note_data.most_recent_pcp_appointment ? Number(data.struct_note_data.most_recent_pcp_appointment.toString().replaceAll('-','')):0

    if (Object.prototype.toString.call(data.diagnostic_code) === '[object Array]') {
      data.diagnostic_code = data.diagnostic_code.sort().join(';');
    }

    data.patient_height = data.patient_height ? Number(data.patient_height) : 0;
    data.patient_weight = data.patient_weight ? Number(data.patient_weight) : 0;
    if (flagDraftClinicianNote && isDraft){
      //Draft 1
      data.note_status=1
    }else{
      //Submitted 2,
      data.note_status=2
    }
    if (flagDraftClinicianNote){
      addNoteWithDraft(data);
    }else{
      addNote(data);
    }

  };

  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    // console.log({ values, errorFields, outOfDate });
    // setLoadings(false);
  };

  const onFieldsChange = (changedValues, allValues) => {
    // console.log('onFieldsChange:', changedValues, allValues)
  };

  const onValuesChange = (changedValues, allValues) => {
    setFormValues(allValues);
    setDraft(allValues);
    exitFormValues = allValues;
  };
  let valueString;
  const handleChange = (value) => {
    if (value.includes('Other (please specify below)')){
      setDiagnosisWriteInRequired(true)
    } else {
      setDiagnosisWriteInRequired(false)
    }
    valueString = value.toString()
    form.setFieldsValue({
      diagnostic_code: valueString
    })
  }

  const handleConfirmationOK = () =>{
    if (flagDraftClinicianNote){
      clearInterval(autoSaveTimer);
      setIsDraft(false)
    }
    setSubmitted(true);
    exitSubmit = true;
    form.submit();
    setShowConfirmation(false)
  }

  const handleConfirmationClose = () =>{
    setShowConfirmation(false)
  }

  const updateRx=(e)=>{
      setMessage(tmpl.message_to_patient.replace(
        'DONE_FIRST_NAME',
        patient&&patient.first_name ? patient.first_name : patient&&patient.preferred_name?patient.preferred_name:''
        ).replace(
          'PROVIDER_NAME',
          patient?.provider_name ?patient.provider_name:''
        ).replace(
          'PHARMACY_INFORMATION',
          patient?.pharmacy?.name ? `${patient?.pharmacy?.name} ${patient?.pharmacy?.address_1} ${patient?.pharmacy?.address_2 || ''} ${patient?.pharmacy?.city} ${patient?.pharmacy?.state} ${patient?.pharmacy?.country || ''}` : 'None',
        ).replace(
          'RX_NAME',e.target.value
        ).replace('PROVIDER_NAME_TITLE',patient?.provider_name ? `${patient?.provider_name} ${patient?.provider_title}`: ''))
  }
  const updateMessage=(e)=>{
    setMessage(e.target.value);
  }

  return (
    <div className={cx({ container: true })}>
      <Form
        className={cx({ form: true })}
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        onFieldsChange={onFieldsChange}
        scrollToFirstError={true}
      >
        <Modal
          visible={showConfirmation}
          maskClosable={false}
          footer={[
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button key="cancel" onClick={handleConfirmationClose}>
                Cancel
              </Button>
              ,
              <Button key="confirm" type="primary" onClick={handleConfirmationOK}>
                Confirm
              </Button>
            </div>,
          ]}
          title="Are you sure?"
          onCancel={() => {
            setShowConfirmation(false);
          }}
        >
          <p>
            Once you submit this note, you will not be able to delete or make further modifications.
            Please ensure that the patient’s PDMP record has been reviewed before submitting the note. This step is critical to maintaining compliance and ensuring safe prescribing practices.
          </p>
        </Modal>
        <div className={cx({ header: true })}>
          <Row>
            <Space />
            <Space />
          </Row>
          <h3>Pre-consultation Review</h3>
          <Space direction="vertical">
            {
              PRE_CONSULTATION_REVIEW.map((item, index) => {
                return (
                  <Form.Item name={item.value} valuePropName="checked" noStyle key={item.value}>
                    <Checkbox>{item.label}</Checkbox>
                  </Form.Item>
                )
              })
            }
            <Row>
              <PatientReviewRadioGroupInput
                name="patient_review_no_concerns"
                label="Patient Review"
                form={form}
                draft={draft}
                setDraft={setDraft}
                initialValue={0}
              />
            </Row>
          </Space>
        </div>
        <Divider />

        <div className={cx({ header: true })}>
          <h3>Chief Complaints</h3>
          <Form.Item
            name="chief_complaint"
            initialValue={tmpl.chief_complaint
              .replace('{INITIALS}', patient ? patient.first_name : '')
              .replace('{AGE}', patient ? patientAge : '')
              .replace('{GENDER}', patient ? patient.gender : '')}
          >
            <TextArea rows={3} maxLength={1000} />
          </Form.Item>
          <Form.Item
            name="history_of_present_illness"
            label={'history of present illness'}
            initialValue={tmpl.history_of_present_illness}
          >
            <TextArea rows={3} maxLength={1000} />
          </Form.Item>
        </div>
        <Divider />

        <div className={cx({ header: true })}>
          <Space align="baseline">
            <h3>Presenting Psychiatric Symptoms</h3>
            <Tooltip
              placement="top"
              title="Select the items that met the patient’s conditions and add more detail notes"
              className={cx({ tip: true })}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
          <AddADHDSubmenu
            name="adhd"
            form={form}
            draft={draft}
            setDraft={setDraft}
            isClickedDefault={false}
          />
          <Tooltip
            placement="top"
            title="Note down more details and symptoms"
            className={cx({ tip: true })}
          >
            <QuestionCircleOutlined />
          </Tooltip>

          <Form.Item name="adhd_write_in" required={true}>
            <TextArea rows={5} span={2} placeholder="" maxLength={1000} />
          </Form.Item>
          <Divider />
          <Row>
            <Col span={10}>
              <CheckboxInput
                name="depression"
                label="Depression"
                form={form}
                draft={draft}
                setDraft={setDraft}
                initialValue={false}
              />
            </Col>
          </Row>
          <Tooltip
            placement="top"
            title="Note down more details and symptoms. Record whether the patient endorses or denies any suicidal thoughts, suicidal intention or suicidal planning. If the patient endorses it, please refer them to provider leadership for further review."
            className={cx({ tip: true })}
          >
            <QuestionCircleOutlined />
          </Tooltip>
          <Form.Item name="depression_write_in">
            <TextArea rows={5} placeholder="" maxLength={1000} />
          </Form.Item>
          <Divider />
          <Row>
            <Col span={10}>
              <CheckboxInput
                name="anxiety"
                label="Anxiety"
                form={form}
                draft={draft}
                setDraft={setDraft}
                initialValue={false}
              />
            </Col>
          </Row>
          <Tooltip
            placement="top"
            title="Note down more details and symptoms"
            className={cx({ tip: true })}
          >
            <QuestionCircleOutlined />
          </Tooltip>
          <Form.Item name="anxiety_write_in">
            <TextArea rows={5} placeholder="" maxLength={1000} />
          </Form.Item>
          <Divider />
          <CheckboxInput
            name={['struct_note_data', 'mania_hypomania']}
            label="Mania/Hypomania"
            form={form}
            draft={draft}
            setDraft={setDraft}
            initialValue={false}
          />
          <Tooltip
            placement="top"
            title="Note down more details and symptoms"
            className={cx({ tip: true })}
          >
            <QuestionCircleOutlined />
          </Tooltip>
          <Form.Item name="mania_hypomania_write_in">
            <TextArea rows={5} placeholder="" maxLength={1000} />
          </Form.Item>
          <h3>Other Psychiatric Symptoms</h3>
          <Row>
            <CheckboxInput
              name={['struct_note_data', 'panic']}
              label="Panic"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name={['struct_note_data', 'insomnia']}
              label="Insomnia"
              form={form}
              draft={draft}
              setDraft={setDraft}
            />
            <CheckboxInput
              name={['struct_note_data', 'other_psych_symptoms']}
              label="Other symptoms of concern"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
          </Row>
          <Form.Item
            name={['struct_note_data', 'psychiatric_symptoms_write_in']}
            rules={[
              {
                required: form.getFieldValue(['struct_note_data', 'other_psych_symptoms']),
                message: 'Additional details regarding patient psychiatric symptoms are required',
              },
              {
                validator: (_, reviewValue) => {
                  if (
                    form.getFieldValue(['struct_note_data', 'other_psych_symptoms']) &&
                    reviewValue === ''
                  ) {
                    return Promise.reject();
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <TextArea rows={2} placeholder="" maxLength={1000} span={2} />
          </Form.Item>
          <h3>Substance Use</h3>
          <Row>
            <CheckboxInput
              name={['struct_note_data', 'caffeine_use']}
              label="Caffeine"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name={['struct_note_data', 'alcohol_use']}
              label="Alcohol"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name={['struct_note_data', 'nicotine_product_use']}
              label="Nicotine Products"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name={['struct_note_data', 'marijuana_product_use']}
              label="Marijuana Products"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
          </Row>
          <Row>
            <CheckboxInput
              name={['struct_note_data', 'other_substance_use']}
              label="Any other substance including, but not limited to: sedatives; opioids; hallucinogens; club drugs"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
          </Row>
          <Row>
            <CheckboxInput
              name={['struct_note_data', 'substance_abuse_program']}
              label="Currently in a substance abuse program"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
          </Row>
          <Form.Item
            name={['struct_note_data', 'substance_abuse_write_in']}
            rules={[
              {
                required: true,
                message: 'Substance use additional detail is required',
              },
            ]}
          >
            <TextArea rows={2} placeholder="" maxLength={1000} />
          </Form.Item>

          <Row>
            <h3>Psychiatric History</h3>
          </Row>
          <Row>
            <CheckboxInput
              name="past_history_of_suicide_attempts"
              label="Past History of suicide attempts"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name="homicidal_ideation"
              label="Homicidal ideation"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name="history_of_trauma_or_abuse"
              label="History of trauma/abuse"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
          </Row>
          <Row>
            <CheckboxInput
              name="self_harm"
              label="Self-injurious behaviors (ie: cutting)"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name={['struct_note_data', 'psych_hospitalization']}
              label="Past History of Psychiatric Hospitalizations"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
          </Row>
          <Row>
            <CheckboxInput
              name="previous_adhd_diagnosis"
              label="Previous diagnosis of ADHD"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name="family_adhd_history"
              label="Family history of ADHD"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
          </Row>
          <Form.Item name="psychiatric_history_write_in">
            <TextArea rows={5} span={2} placeholder="" maxLength={1000} />
          </Form.Item>
          <Form.Item
            label={
              'Patient endorses or denies any history of, or current concerns relating to: ' +
              'trauma or abuse, intrusive thoughts, paranoid or delusional thoughts, psychosis, A/V hallucinations, ' +
              'cognitive alterations or acute decline in memory, or eating disorder behaviors.'
            }
            name={['struct_note_data', 'patient_endorse_psych_history']}
            initialValue={0}
          >
            <Radio.Group>
              <Radio value={0} col={10}>
                Denies
              </Radio>
              <Radio value={1} col={10} span={2}>
                Endorses
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="current_psy_and_ther" label={'Current Psychiatrist and Therapist'}>
            <Input maxLength={200} />
          </Form.Item>
          <Row>
            <Col span={10}>
              <Row>
                <Form.Item
                  name="prev_medications"
                  label={
                    <Space align="baseline">
                      <h4>Previous Medications</h4>
                      <Tooltip
                        placement="right"
                        title="Please input any medications patient has taken in the past"
                        className={cx({ tip: true })}
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Space>
                  }
                >
                  <TextArea defaultValue={patient.prev_medications} maxLength={1000} />
                </Form.Item>
              </Row>
            </Col>
            <Col span={10} offset={2}>
              <Row>
                <Form.Item
                  name="current_medications"
                  label={
                    <Space align="baseline">
                      <h4>Current Medications and Supplements</h4>
                      <Tooltip
                        placement="right"
                        title="Please input any medications and or supplements patient is currently taking"
                        className={cx({ tip: true })}
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Space>
                  }
                >
                  <TextArea defaultValue={patient.current_medications} maxLength={1000} />
                </Form.Item>
              </Row>
            </Col>
          </Row>
          <Divider />
          <h3>Medication & Physical History</h3>
          <Row>
            <RadioGroupInput
              name="drug_allergy"
              label="Medicine allergy"
              form={form}
              draft={draft}
              setDraft={setDraft}
              allergy={patient.allergic_drugs}
              isAllergy={true}
            />
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item name={['struct_note_data', 'current_pcp']} label={'Current PCP'}>
                <Input placeholder={'Primary Care Provider'} maxLength={200} />
              </Form.Item>
            </Col>
            <Col span={10} offset={2}>
              <Form.Item
                name={['struct_note_data', 'most_recent_pcp_appointment']}
                label={'Last PCP appointment'}
              >
                <Input placeholder={'MM/DD/YYYY'} type={'date'} format={'MM/DD/YYYY'} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item name="patient_weight" label="Weight in pounds" >
                <InputNumber
                defaultValue="1"
                min="0"
                max="1000"
                step="0.1"
                stringMode
                style={{ width: '100%' }}/>
              </Form.Item>
            </Col>
            <Col span={10} offset={2}>
              <Form.Item name="patient_height" label="Height in inches">
              <InputNumber defaultValue="1"
                min="0"
                max="1000"
                step="0.1"
                stringMode
                style={{ width: '100%' }}/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <h4>Reported Medical Conditions</h4>
          </Row>
          <Row>
            <CheckboxInput
              name={['struct_note_data', 'heart_arrhythmias']}
              label="Heart arrhythmias"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name={['struct_note_data', 'hypertension']}
              label="Hypertension"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name={['struct_note_data', 'stroke']}
              label="Stroke"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
          </Row>
          <Row>
            <CheckboxInput
              name={['struct_note_data', 'seizure']}
              label="Seizures"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name={['struct_note_data', 'other_major_neuro_or_cardio_disorders']}
              label="Major neurological, cardiac or other disorder(s)"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
          </Row>
          {form.getFieldValue(['struct_note_data', 'other_major_neuro_or_cardio_disorders']) && (
            <Form.Item
              name={['struct_note_data', 'reported_medical_conditions_write_in']}
              rules={[
                {
                  required: form.getFieldValue([
                    'struct_note_data',
                    'other_major_neuro_or_cardio_disorders',
                  ]),
                  message: 'Reported Medical conditions is required when other is selected',
                },
                {
                  validator: (_, reviewValue) => {
                    if (
                      form.getFieldValue([
                        'struct_note_data',
                        'other_major_neuro_or_cardio_disorders',
                      ]) &&
                      reviewValue === ''
                    ) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <TextArea rows={5} span={2} placeholder="" maxLength={1000} />
            </Form.Item>
          )}

          <Row>
            <h4>Patient endorses or denies the presence of other physical medical conditions</h4>
          </Row>
          <DynamicRadioGroupInput
              name={['struct_note_data', 'patient_endorsement']}
              label="Patient endorses or denies other medical conditions"
              form={form}
              draft={draft}
              setDraft={setDraft}
              checkboxValues={['Endorses', 'Denies']}
              initialValue={'Denies'}
          />
          <Row>
            <h4>Family Planning</h4>
          </Row>
          <Row>
            <CheckboxInput
              name={['struct_note_data', 'pregnant_or_family_planning']}
              label="Currently pregnant or family planning"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name={['struct_note_data', 'currently_breastfeeding']}
              label="Currently Breastfeeding"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name={['struct_note_data', 'currently_using_birth_control']}
              label="Currently Using Birth Control"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
          </Row>
          <Form.Item name={['struct_note_data', 'family_planning_write_in']}>
            <TextArea rows={2} placeholder="" maxLength={1000} />
          </Form.Item>
          <Divider />
          <h3>Social & Family History</h3>
          <Form.Item name="social_family_history" initialValue={tmpl.social_and_family_history}>
            <TextArea rows={10} maxLength={1000} />
          </Form.Item>
          <Divider />

          <h3>Mental Status Exam</h3>
          <Row>
            <label> Observation</label>
            <DynamicRadioGroupInput
              name={['struct_note_data', 'observation_appearance']}
              label="Appearance"
              form={form}
              draft={draft}
              setDraft={setDraft}
              checkboxValues={['Neat', 'Disheveled', 'Inappropriate', 'Bizarre', 'Other']}
              initialValue={'Neat'}
            />
          </Row>
          <DynamicRadioGroupInput
            name={['struct_note_data', 'observation_speech']}
            label="Speech"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Normal', 'Tangential', 'Impoverished', 'Pressured', 'Other']}
            initialValue={'Normal'}
          />
          <DynamicRadioGroupInput
            name={['struct_note_data', 'observation_eye_contact']}
            label="Eye Contact"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Normal', 'Intense', 'Avoidant', 'Other']}
            initialValue={'Normal'}
          />
          <DynamicRadioGroupInput
            name={['struct_note_data', 'observation_motor_activity']}
            label="Motor Activity"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Normal', 'Restless', 'Tics', 'Slowed', 'Other']}
            initialValue={'Normal'}
          />
          <DynamicRadioGroupInput
            name={['struct_note_data', 'observation_affect']}
            label="Affect"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Full', 'Constricted', 'Labile', 'Flat', 'Other']}
            initialValue={'Full'}
          />
          <Divider />
          <label>Cognition</label>

          <DynamicRadioGroupInput
            name={['struct_note_data', 'cognition_orientation_impair']}
            label="Orientation Impairment"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['None', 'Person', 'Place', 'Time', 'Other']}
            initialValue={'None'}
          />
          <DynamicRadioGroupInput
            name={['struct_note_data', 'cognition_memory_impair']}
            label="Memory Impairment"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Normal', 'Short-Term', 'Long-Term', 'Other']}
            initialValue={'Normal'}
          />
          <DynamicRadioGroupInput
            name={['struct_note_data', 'cognition_attention']}
            label="Attention"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Normal', 'Distracted', 'Other']}
            initialValue={'Normal'}
          />
          <Divider />
          <label>Perception</label>

          <DynamicRadioGroupInput
            name={['struct_note_data', 'perception_hallucinations']}
            label="Hallucinations"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Normal', 'Auditory', 'Visual', 'Other']}
            initialValue={'Normal'}
          />
          <DynamicRadioGroupInput
            name={['struct_note_data', 'perception_other']}
            label="Other"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['None', 'Derealization', 'Depersonalization']}
            initialValue={'None'}
          />
          <Divider />
          <label>Thoughts</label>

          <DynamicRadioGroupInput
            name={['struct_note_data', 'thoughts_suicidality']}
            label="Suicidality"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['None', 'Ideation', 'Plan', 'Intent', 'Self-Harm']}
            initialValue={'None'}
          />
          <DynamicRadioGroupInput
            name={['struct_note_data', 'thoughts_homicidality']}
            label="Homicidality"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['None', 'Agressive', 'Intent', 'Plan']}
            initialValue={'None'}
          />
          <DynamicRadioGroupInput
            name={['struct_note_data', 'thoughts_delusions']}
            label="Delusions"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['None', 'Grandiose', 'Paranoid', 'Religious', 'Plan']}
            initialValue={'None'}
          />
          <Divider />
          <DynamicRadioGroupInput
            name={['struct_note_data', 'behavior']}
            label="Behavior"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={[
              'Cooperative',
              'Shy/Guarded',
              'Hyperactive',
              'Agitated',
              'Aggressive',
              'Paranoid',
              'Bizarre',
              'Withdrawn',
            ]}
            initialValue={'Cooperative'}
          />
          <DynamicRadioGroupInput
            name={['struct_note_data', 'insight']}
            label="Insight"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Good', 'Fair', 'Poor']}
            initialValue={'Good'}
          />
          <DynamicRadioGroupInput
            name={['struct_note_data', 'judgement']}
            label="Judgement"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Good', 'Fair', 'Poor']}
            initialValue={'Good'}
          />
          <label>Mood: </label>
          <Row>
            <CheckboxInput
              name={['struct_note_data', 'mood_euthymic']}
              label="Euthymic"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name={['struct_note_data', 'mood_anxious']}
              label="Anxious"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name={['struct_note_data', 'mood_angry']}
              label="Angry"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name={['struct_note_data', 'mood_depressed']}
              label="Depressed"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name={['struct_note_data', 'mood_euphoric']}
              label="Euphoric"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name={['struct_note_data', 'mood_irritable']}
              label="Irritable"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
            <CheckboxInput
              name={['struct_note_data', 'mood_other']}
              label="Other"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={false}
            />
          </Row>
          <Form.Item
            name={['struct_note_data', 'mood_write_in']}
            rules={[
              {
                required: true,
                message: 'Mental status exam additional detail is required',
              },
            ]}
          >
            <TextArea rows={5} span={2} placeholder="" maxLength={1000} />
          </Form.Item>

          <div className={cx({ header: true })}>
            <h3>Diagnosis*</h3>
            <Form.Item
              name="diagnostic_code"
              rules={[
                {
                  required: true,
                  message: 'Diagnostic Code is required',
                },
              ]}
            >
              <Select
                mode={'multiple'}
                placeholder="Select Diagnostic Code"
                options={initialCompletedOpts}
                onChange={handleChange}
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
            <Form.Item
              name="other_diagnosis_notes"
              rules={[
                {
                  required: diagnosisWriteInRequired,
                  message: 'Must provide additional diagnosis notes',
                },
                {
                  validator: (_, diagnosisValue) => {
                    if (diagnosisWriteInRequired && diagnosisValue === '') {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <TextArea rows={10} placeholder="" maxLength={1000} />
            </Form.Item>
          </div>

          <CPTCodesFormItem form={form} patient={patient} noteType={'InitialEvaluation'} initialValues={draft} />

          <Divider />

          <div className={cx({ header: true })}>
            <h3>Treatment & Prescription</h3>

            <LastRefillDateFormItem form={form} patient={patient} noteType={'InitialEvaluation'} initialValues={draft} />

            <div className={cx({ rxTitle: true })}>
              <label htmlFor="rx" title="Rx" className={cx({ rxLabel: true })}>
                Rx
              </label>
            </div>
            <Form.Item
              name="rx"
              rules={[
                {
                  required: false,
                  message: 'Rx is required',
                },
              ]}
            >
              <Input placeholder="Rx" onChange={updateRx}  maxLength={1000}/>
            </Form.Item>
            <Form.Item name="no_controlled_substance_needed" valuePropName="checked">
              <Checkbox>
                <span>
                  &nbsp;Patient does not need controlled substances (in-person evaluation/referral
                  not required).
                </span>
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="provider_notes"
              label={
                <Space align="baseline">
                  <h4>Treatment Notes</h4>
                  <Tooltip
                    placement="top"
                    title="Edit the template below with your patient's details"
                    className={cx({ tip: true })}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Space>
              }
              rules={[
                {
                  required: true,
                  message: 'Treatment notes is required',
                },
              ]}
              initialValue={tmpl.provider_notes
                .replace('AGE', patient ? patientAge : '')
                .replace('GENDER', patient ? patient.gender : '')
                .replace('DATE_OF_SERVICE',appointment?formatUnix(appointment.appointed_at, 'MM DD YYYY'):'DATE_OF_SERVICE')}
            >
              <TextArea rows={8} maxLength={5000} />
            </Form.Item>

            <Form.Item
              name="patient_agree_treatment_plan"
              label="Patient Agree"
              valuePropName="checked"
            >
              <Checkbox>Patient agrees to start the treatment plan</Checkbox>
            </Form.Item>

            <NextFollowUpDateFormItem form={form} patient={patient} />
          </div>
          <Divider />

        <div>
          <Space align="baseline">
            <h3>First Message to Patient</h3>
            <Tooltip
              placement="top"
              title="Edit the template below with your patient's details"
              className={cx({ tip: true })}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>

          <Form.Item  label="Message To Patient" initialValue={tmpl.message_to_patient}>
            <TextArea rows={8} value={message} onChange={updateMessage} maxLength={20000}/>
          </Form.Item>
        </div>
          <Form.Item name="clinician_accuracy_testament" valuePropName="checked" label="Clinician Accuracy Testament" rules={[
            {
              required: true,
              message: 'Clinician Accuracy testament is required'
            },
            {
              validator: (_, value) => {
                if (value === true) {
                  return Promise.resolve();
                } else {
                  return Promise.reject()
                }
              }
            }
          ]}>
            <Checkbox>I attest to the accuracy and completeness of this note.</Checkbox>
          </Form.Item>
          <Divider />

          <div className="add-note-confirm">
            { appointment && (
              <Form.Item name="no_diagnose" valuePropName="checked">
                <Checkbox
                  onChange={(e) => {
                    setNotContinuePatient(e);
                  }}
                >
                  <span className="add-note-checkbox">
                    I don't want to continue to see this patient
                  </span>
                </Checkbox>
              </Form.Item>
            )}
            {!isNoDiagnosed && !isNotRightPatient && (
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    form.validateFields().then(()=>{
                      if (flagLockClinicianNote){
                        setShowConfirmation(true)
                      }else{
                        form.submit()
                      }
                    })
                  }}
                  loading={
                    markNoDiagnosisReq.loading ||
                    addNoteReq.loading ||
                    markAppointmentToBeTransfer.loading
                  }
                >
                  {'Sign and Submit'}
                </Button>
              </Form.Item>
            )}
          </div>
          {isNotRightPatient && (
            <>
              <Form.Item
                name="seen_patient_options"
                className={cx({ noDiagnoseReason: true })}
                rules={[
                  {
                    required: true,
                    message: 'the option is required',
                  },
                ]}
              >
                <Radio.Group onChange={onSeenPatientChanged} options={seenPatientOptions} />
              </Form.Item>
            </>
          )}

          {!isSeenPatient && (
            <Form.Item>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  setIsShowConfirmTransfer(true);
                }}
              >
                Sign and Submit
              </Button>
            </Form.Item>
          )}

          <Modal
            title="Confirm Transfer"
            visible={isShowConfirmTransfer}
            okText="Submit"
            onOk={() => {
              form.validateFields().then(()=>{
                if (flagLockClinicianNote){
                  setShowConfirmation(true)
                }else{
                  form.submit()
                }
              })
            }}
            onCancel={() => {
              setIsShowConfirmTransfer(false);
            }}
          >
            <p>You are transferring the patient. Please inform your PA of the transferring request.</p>
          </Modal>

          {isNoDiagnosed && (
            <>
              <h3>Why do you want to remove this patient from your panel?</h3>
              <Form.Item
                name="no_diagnose_reason"
                className={cx({ noDiagnoseReason: true })}
                rules={[
                  {
                    required: true,
                    message: 'the reason is required',
                  },
                ]}
              >
                <Radio.Group onChange={onReasonChanged} options={noDiagnoseReason} />
              </Form.Item>
              {showMoreReason && (
                <Form.Item name="no_diagnose_more_reason" initialValue={['d']}>
                  <Checkbox.Group
                    className={cx({ noDiagnoseMoreReason: true })}
                    options={otherReason}
                  />
                </Form.Item>
              )}
              <Form.Item
                style={{ marginTop: 16, backgroundColor: '#F4F4F4' }}
                name="addition_notes_for_no_diagnose"
              >
                <TextArea
                  style={{ backgroundColor: '#F4F4F4', borderRadius: 4 }}
                  rows={3}
                  placeholder="Any additional notes or comments about this patient?"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    form.validateFields().then(()=>{
                      if (flagLockClinicianNote){
                        setShowConfirmation(true)
                      }else{
                        form.submit()
                      }
                    })
                  }}
                  loading={markNoDiagnosisReq.loading}
                >
                  Sign and Submit
                </Button>
              </Form.Item>
            </>
          )}
        </div>
      </Form>
    </div>
  );
};

export default InitialEvaluationNotesForm;
