import React, { useEffect, useState } from 'react';
import { ProviderDashboardSection } from './index';

import Iframe from 'react-iframe';
import { srvDoctorTaskURL } from '@/services/account';

const AllAssignedTaskSection = (props) => {
  const [taskUrl, setTaskUrl] = useState('');

  useEffect(() => {
    srvDoctorTaskURL()
      .then((res) => {
        if (res.data !== '') {
          setTaskUrl(res.data);
        }
      })
      .catch(() => {
        setTaskUrl('');
      });
  }, []);
  return (
    <ProviderDashboardSection sectionName="Your Other Tasks">
      {taskUrl !== '' && (
        <Iframe
          url={taskUrl}
          width="100%"
          height="600px"
          id="myId"
          display="initial"
          position="relative"
        />
      )}
    </ProviderDashboardSection>
  );
};

export default AllAssignedTaskSection;