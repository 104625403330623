import React, { useState } from 'react';
import { Modal, Table, Space, List, Tooltip } from 'antd';
import cn from 'classnames/bind';
import orderBy from 'lodash/orderBy';
import { useFlag } from '@unleash/proxy-client-react';
import { useMount, useRequest, useUpdateEffect } from 'ahooks';
import { history } from 'umi';

import styles from './PrescriptionHistory.less';
import { formatUnix } from '@/utils/xtime';
import { srvRxntPrescriptionHistory, srvFetchRxntPrescriptionOrder } from '@/services/patient';
const cx = cn.bind(styles);

const pageSize = 10;

const RxntPrescriptionHistory = (props) => {
  const { admin, uid, active, reloadRxnt$, onData } = props;
  const [page, setPage] = useState(1);
  const [data, setData] = useState({ items: [], total: 0 });
  const flagShowDeliveryFailedReason = useFlag('show_delivery_failed_reason');
  const [requestDeliveryFailedReason, setRequestDeliveryFailedReason] = useState('');
  const [requestDeliveryFailedVisible, setRequestDeliveryFailedVisible] = useState(false);
  const syncRxntPrescription = useRequest(srvFetchRxntPrescriptionOrder, {
    manual: true,
    onSuccess: (ret, params) => {},
  });

  const historyReq = useRequest(srvRxntPrescriptionHistory, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setData(ret.data);
        onData(ret.data);
      }
    },
  });

  reloadRxnt$ &&
    reloadRxnt$.useSubscription(() => {
      setPage(1);
      loadData();
    });

  const loadData = () => {
    historyReq.run({
      page: 1,
      page_size: pageSize,
      filters: JSON.stringify({ user_id: uid }),
    });
  };

  useMount(() => {
    historyReq.run({
      page: 1,
      page_size: pageSize,
      filters: JSON.stringify({ user_id: uid }),
    });
    if (history.location.query.ord_id && String(history.location.query.ord_id).trim() != '' && /^\d+$/.test(history.location.query.ord_id)) {
      syncRxntPrescription
        .runAsync({ order_id: String(history.location.query.ord_id).trim() })
        .then(() => {
          reloadRxnt$ && reloadRxnt$.emit();
          return historyReq.runAsync({
            page: 1,
            page_size: pageSize,
            filters: JSON.stringify({ user_id: uid }),
          });
        })
        .then(() => {
          history.replace(`${history.location.pathname}?tab=3`);
        });
    } else {
    }
  });

  useUpdateEffect(() => {
    if (active) {
      setPage(1);
      historyReq.run({
        page: 1,
        page_size: pageSize,
        filters: JSON.stringify({ user_id: uid }),
      });
    }
  }, [active, uid]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    historyReq.run({
      page: pagination.current,
      page_size: pageSize,
      filters: JSON.stringify({ user_id: uid }),
    });
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'start_date',
      key: 'start_date',
      width: 90,
      render: (v, r) => {
        return (
          <div className={cx({ discharged: r.is_discharged })}>{formatUnix(v, 'MM/DD/YYYY')}</div>
        );
      },
    },
    {
      title: 'Prescription',
      dataIndex: 'id',
      key: 'id',
      render: (v, r) => {
        return (
          <div className={cx({discharged: r.is_discharged})}>
            <div>
              <b>{r.drug?.name}</b>
            </div>
            <div>{r.dosage}</div>
            <div>
              <b>Qty/Dur:</b> {r.duration_amount} {r.duration_unit} <b>Refills:</b>{' '}
              {r.no_of_refills}
            </div>
            <div>
              <b>Use Generic:</b> {r.is_use_generic ? 'Yes' : 'No'}
            </div>
            <div>
              <b>Day Supply:</b> {r.days_supply}
            </div>
            <div>Id: {r.id}</div>
            {r.is_discharged && (
              <>
                <div>
                  <b>Discharge Date:</b> {r.discharge_date}
                </div>
                <div>
                  <b>Reason: </b>
                  {r.discharge_reason}
                </div>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: 'Prescriber',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      render: (v, r) => {
        return (
          <div className={cx({ discharged: r.is_discharged })}>
            <div>{r.doctor_first_name}</div>
            <div>{r.doctor_last_name}</div>
          </div>
        );
      },
    },
    {
      title: 'Delivery',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      render: (v, r) => {
        if (!r.status) {
          return <></>;
        }
        let delivery_status = r.status.delivery_status;
        if (flagShowDeliveryFailedReason && r.status.delivery_status == 'DELIVERY_FAILED') {
          delivery_status = (
            <a style={{ color: "blue" }} onClick={() => {
              setRequestDeliveryFailedReason(r.status.error_message);
              setRequestDeliveryFailedVisible(true);
            }}>
              {r.status.delivery_status}
            </a>
          )
        }
        return (
          <div className={cx({ discharged: r.is_discharged })}>
            <div>{r.status.delivery_type}</div>
            <div>{delivery_status} at {formatUnix(r.status.response_date)}
            </div>
          </div>
        );
      },
    },
    {
      title: 'Pharamcy',
      dataIndex: 'id',
      key: 'id',
      width: 200,
      render: (v, r) => {
        if (!r.pharmacy) {
          return <></>;
        }
        return (
          <div className={cx({ discharged: r.is_discharged })}>
            <div>{r.pharmacy.name}</div>
            <div>{r.pharmacy.address1}</div>
            <div>{r.pharmacy.city}</div>
            <div>{r.pharmacy.state}</div>
            <div>{r.pharmacy.phone}</div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={orderBy(data.items, ['start_date'], ['desc']) }
        rowKey="id"
        loading={history.loading || syncRxntPrescription.loading}
        onChange={handleTableChange}
        pagination={{ pageSize: pageSize, current: page, simple: true, total: data.total }}
      />

      {requestDeliveryFailedVisible && (
        <Modal
          title="Failed Reason"
          visible={requestDeliveryFailedVisible}
          width={600}
          destroyOnClose={true}
          footer={false}
          onCancel={() => {
            setRequestDeliveryFailedVisible(false);
          }}
          onOk={(v) => {
            setRequestDeliveryFailedVisible(false);
          }}
          okText="Ok"
        >
          <div>{requestDeliveryFailedReason}</div>
        </Modal>
      )}
    </>
  );
};
RxntPrescriptionHistory.defaultProps = {
  admin: false,
};

export default RxntPrescriptionHistory;
