import React, { useState, useEffect } from 'react';

import {Table, Drawer} from 'antd';

import { formatUnix } from '@/utils/xtime';
import { useRequest, useMount } from 'ahooks';

import cn from 'classnames/bind';
import styles from './index.less';
import {srvGetChangeLogs} from "@/services/admin";
const cx = cn.bind(styles);

const DoctorChangeLogsDrawer = (props) => {

  const {targetID, messagesVisible, setMessagesVisible } = props;

  const [fieldChangeLogs, setFieldChangeLogs] = useState({ total: 0, items: [], });

  const fetchFieldChangeLog = useRequest(srvGetChangeLogs, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data && ret.data.items) {
          setFieldChangeLogs(ret.data);
        }
      }
    },
  });

  useEffect(() => {
    if (messagesVisible) {
      fetchFieldChangeLog.run({
        page: 1, page_size: 10000, filters: JSON.stringify({ "object_id": targetID })
      });
    }
  }, [messagesVisible])

  const columns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: false,
      width: 180,
      render: (v, r) => {
        const time =  r.created_at ;
        return formatUnix(time);
      },
    },
    {
      title: 'Changed Field',
      dataIndex: 'field_label',
      key: 'field_label',
      ellipsis: false,
      width: 180,
    },
    {
      title: 'Old Value',
      dataIndex: 'old_value',
      key: 'old_value',
      ellipsis: false,
      width: 180,
    },
    {
      title: 'New Value',
      dataIndex: 'new_value',
      key: 'new_value',
      ellipsis: false,
      width: 180,
    },
  ];



  return (

    <Drawer width={700} title="Change Logs" visible={messagesVisible} size="large" placement="right" onClose={() => { setMessagesVisible(false) }}>
      <Table
        columns={columns}
        dataSource={fieldChangeLogs.items}
        bordered={false}
        rowKey="id"
        loading={fetchFieldChangeLog.loading}
      />

    </Drawer>
  );
};

export default DoctorChangeLogsDrawer;
