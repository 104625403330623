import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox } from 'antd';

import { useMount, useUpdateEffect } from 'ahooks';

import cn from 'classnames/bind';
import styles from './CheckboxInput.less';
const cx = cn.bind(styles);

const CheckboxInput = (props) => {
  const { name, label, form, draft, setDraft, adhd, initialValue, onClick } = props;

  const [checked, setChecked] = useState(false);

  useUpdateEffect(() => {
    if (draft && draft[name]) setChecked(true);
  }, [draft]);

  if (adhd) {
    return (
      <Form.Item name={name} form={form} valuePropName="checked" initialValue={initialValue}>
        <Checkbox onClick={onClick}>{label}</Checkbox>
      </Form.Item>
    )
  }

  return (

    <Form.Item name={name} form={form} valuePropName="checked" initialValue={initialValue}>
      <Checkbox checked={checked}>{label}</Checkbox>
    </Form.Item>
  )
};

export default CheckboxInput;
