import React, { useState, useEffect, useRef } from 'react';

import { Modal, Form, Select, Radio, Spin, message, Input } from 'antd';

import moment from 'moment';
import { useRequest, useMount, useUpdate } from 'ahooks';

import { srvPatientAppointmentDetail, srvRescheduleCalendar, srvProviderReschedule } from '@/services/patient';

import DateTimeRangePickerList from './DateTimeRangePickerList';


const RescheduleAppointmentWithTimeSlotsModal = (props) => {

  const { visible, onCancel, onRefresh, activeAppointmentId } = props;

  const [form] = Form.useForm();
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [activeAppointment, setActiveAppointment] = useState(false);
  const [additionalVisible, setAdditionalVisible] = useState(false);
  const [rescheduleCalendarDates, setRescheduleCalendarDates] = useState(false);

  useEffect(() => {
    if (visible && activeAppointmentId) {
      setLoadings(true);
      fetchAppointmentReq.run({ id: activeAppointmentId });
    }
  }, [visible, activeAppointmentId]);

  useEffect(() => {
    if (activeAppointment && activeAppointment.appointment_id && activeAppointment.timezone) {
      setLoadings(true);
      fetchRescheduleCalendarReq.run({
        appointment_id: activeAppointment.appointment_id,
        timezone: activeAppointment.timezone,
        days: 5,
        date: moment().format('YYYYMMDD'),
        dir: 'next',
      })
    }
  }, [activeAppointment]);

  const fetchAppointmentReq = useRequest(srvPatientAppointmentDetail, {
    manual: true,
    onSuccess: (ret) => {
      setLoadings(false);
      if (ret.success) {
        setActiveAppointment(handleActiveAppointment(ret.data));
      }
    },
  });

  const fetchRescheduleCalendarReq = useRequest(srvRescheduleCalendar, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setLoadings(false);
        if (ret.data && ret.data.dates) {
          setRescheduleCalendarDates(ret.data.dates);
          if (ret.data.dates.length > 0) {
            form.setFieldsValue({ reschedule_type: 'remaining' });
          } else {
            message.info('There is no availability within 120 hours. Please offer additional available time for the patient.');
            handleNoAvailableTime();
          }
        }
      }
    },
  });

  const providerRescheduleReq = useRequest(srvProviderReschedule, {
    manual: true,
    onSuccess: (ret) => {
      setLoadings(false);
      setConfirmVisible(false);
      if (ret.success) {
        setActiveAppointment(false);
        if (onCancel) onCancel();
        onRefresh();
      }
    },
  });

  const handleActiveAppointment = (v) => {
    const start = moment.unix(v.appointed_at);
    let end = moment.unix(v.appointed_at);

    if (v.appointment_type === 2 || v.appointment_type === 7 || v.appointment_type === 13 || v.appointment_type === 14) {
      end = end.add('10', 'minutes');
    } else if (v.appointment_type === 5 || v.appointment_type === 20 || v.appointment_type === 21) {
      end = end.add('50', 'minutes');
    } else if (v.appointment_type === 6) {
      end = end.add('25', 'minutes');
    } else if (v.appointment_type === 11) {
      end = end.add('40', 'minutes');
    } else if (v.appointment_type === 15 || v.appointment_type === 17 || v.appointment_type === 22 || v.appointment_type === 23) {
      end = end.add('45', 'minutes');
    } else if (v.appointment_type === 16 || v.appointment_type === 18) {
      end = end.add('30', 'minutes');
    } else {
      end = end.add('25', 'minutes');
    }

    return {
      start: start.toDate(),
      end: end.toDate(),
      title: `${v.patient.first_name} ${v.patient.last_name} (${v.appointment_type_name})`,
      patient_id: v.user_id,
      patient_name: `${v.patient.first_name} ${v.patient.last_name}`,
      appointment_id: v.id,
      appointment_status: v.status,
      appointment_type_name: v.appointment_type_name,
      timezone: v.timezone,
      resource: v.id,
    };
  }

  const handleNoAvailableTime = () => {
    if (!additionalVisible) setAdditionalVisible(true);
    form.setFieldsValue({ reschedule_type: 'additional' });
    // console.log('handleNoAvailableTime:', additional_dates)
  }

  const onValuesChange = (changedValues, allValues) => {
    // console.log('onValuesChange.allValues', allValues, changedValues, rescheduleCalendarDates.length)
    if (changedValues.reschedule_type === 'remaining') {
      if (additionalVisible) setAdditionalVisible(false);
      if (rescheduleCalendarDates && !rescheduleCalendarDates.length) {
        message.info('All time slots have been booked. Please add available time.');
        handleNoAvailableTime();
      }
    }
    if (changedValues.reschedule_type === 'additional') {
      if (!additionalVisible) setAdditionalVisible(true);
    }
  };

  const onConfirm = () => {
    form.validateFields().then(values => {
      setConfirmVisible(true);
    });
  }

  const onFormFinish = (values) => {
    values.user_id = activeAppointment.patient_id;
    values.appointment_id = activeAppointment.appointment_id;
    values.items = [];
    if (values.reschedule_type === 'additional') {
      if (values.additional_dates) {
        for (let index = 0; index < values.additional_dates.length; index++) {
          const dates = values.additional_dates[index];
          const date = moment(dates.date).format('YYYY-MM-DD')
          const start = moment(dates.time_range[0]).format('HH:mm:00');
          const end = moment(dates.time_range[1]).format('HH:mm:00');
          values.items.push({ start_time: moment(`${date} ${start}`, 'YYYY-MM-DD HH:mm:ss').unix(), end_time: moment(`${date} ${end}`, 'YYYY-MM-DD HH:mm:ss').unix() })
        }
      }
      values.reschedule_type = 2;
    } else {
      values.reschedule_type = 1;
    }
    setLoadings(true);
    providerRescheduleReq.runAsync({ ...values }).then(() => {
      if (onCancel) onCancel();
    }).catch(r => {
      setLoadings(false);
      setConfirmVisible(false);
    });
  }

  return (
    <>
      <Modal title="Reschedule"
        width={800}
        zIndex={1000}
        destroyOnClose={true}
        maskClosable={false}
        visible={visible}
        onOk={onConfirm}
        okText="Confirm"
        onCancel={() => {
          setActiveAppointment(false)
          if (onCancel) onCancel()
        }}>
        <Spin tip="Loading..." spinning={loadings}>
          <h2 style={{ marginBottom: '20px' }}>
            {activeAppointment.patient_name} ({String(activeAppointment.appointment_type_name).replace('(', ' ').replace(')', '')}),
            {moment(activeAppointment.start).format('MMM.D')} {moment(activeAppointment.start).format('ddd')},
            {moment(activeAppointment.start).format('h:mmA')}-{moment(activeAppointment.end).format('h:mmA')}
          </h2>
          <Form
            form={form}
            onValuesChange={onValuesChange}
            preserve={false}
            layout={"vertical"}
            onFinish={onFormFinish}
          >
            <Form.Item
              name="reschedule_reason"
              label="Rescheduling Reason"
              style={{
                width: 400,
              }}
              rules={[
                {
                  required: true,
                  message: 'why do you want to reschedule',
                },
              ]}
            >
              <Select placeholder="why do you want to reschedule">
                <Select.Option value="Emergency">Emergency / Force Majeure Event</Select.Option>
                <Select.Option value="Missing">Missing the Appointment</Select.Option>
                <Select.Option value="TechnicalIssue">Technical Issue</Select.Option>
                <Select.Option value="SchedulingError">Scheduling Error</Select.Option>
                <Select.Option value="TransferPatient">Patient in a Different State (Transfer the Patient)</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="reschedule_type"
              label="Available Time"
              rules={[
                {
                  required: true,
                  message: 'select reschedule type',
                },
              ]}
            >
              <Radio.Group>
                <Radio value="remaining" disabled={!rescheduleCalendarDates.length}>Offer all of my remaining available time</Radio>
                <br />
                <Radio value="additional">Additional available time</Radio>
              </Radio.Group>
            </Form.Item>

            <DateTimeRangePickerList form={form} rescheduleType={form.getFieldValue('reschedule_type')} activeAppointment={activeAppointment} />

          </Form>
        </Spin>
      </Modal>
      <Modal
        title="Are you sure you want to reschedule?"
        zIndex={1200}
        visible={confirmVisible}
        okText="I understand"
        confirmLoading={loadings}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          setConfirmVisible(false);
          setLoadings(false);
        }}
      >
        <p>You have changed the time of the appointment booked by {activeAppointment.patient_name}.</p>
        <p>The patient has been notified of your request and was offered several options to reschedule the appointment. We will notify you via email or text if the patient has selected and confirmed one of the new times.</p>
      </Modal>
    </>
  );
};

export default RescheduleAppointmentWithTimeSlotsModal;
