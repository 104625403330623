import hash from 'hash.js';
import isEqual from 'lodash.isequal';
import { pathToRegexp } from 'path-to-regexp';
import { parse, stringify } from 'querystring';
import { useEffect, useRef } from 'react';
import { srvGetDocumentURL } from '@/services/patient';
import {message} from "antd";

import template from '@/components/PrescriptionPane/template';
import mindfulTemplate from '@/components/PrescriptionPane/mindfulTemplate';

const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = (path) => reg.test(path);

const isNode =
  typeof process !== 'undefined' && process.versions != null && process.versions.node != null;

export const isBrowser = () =>
  typeof window !== 'undefined' && typeof window.document !== 'undefined' && !isNode;

export const delay = (ms) => new Promise((res, rej) => setTimeout(res, ms));

export function guid() {
  return 'xxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function debounce(func, wait, immediate) {
  let timeout;
  let args;
  let context;
  let timestamp;
  let result;
  let debounceFunction;
  // eslint-disable-next-line no-var
  var later = function later() {
    const last = Date.now() - timestamp;
    if (last < wait && last >= 0) {
      timeout = window.setTimeout(later, wait - last);
      debounceFunction.id = timeout;
    } else {
      timeout = null;
      if (!immediate) {
        result = func.apply(context, args);
        // eslint-disable-next-line no-multi-assign
        if (!timeout) context = args = null;
      }
    }
  };
  // eslint-disable-next-line func-names
  debounceFunction = function () {
    // @ts-ignore
    context = this;
    // eslint-disable-next-line prefer-rest-params
    args = arguments;
    timestamp = Date.now();
    const callNow = immediate && !timeout;
    if (!timeout) {
      timeout = window.setTimeout(later, wait);
      debounceFunction.id = timeout;
    }
    if (callNow) {
      result = func.apply(context, args);
      // eslint-disable-next-line no-multi-assign
      context = args = null;
    }
    return result;
  };
  return debounceFunction;
}

export const getKeyByPath = (item) => {
  const { path, name } = item;
  if (path && path !== '/') {
    return path;
  }
  if (name) {
    return name;
  }
  try {
    return hash.sha256().update(stringify(item)).digest('hex');
  } catch (error) {
    // dom some thing
  }
  return guid();
};

export const getOpenKeysFromMenuData = (menuData) => {
  if (!menuData) {
    return undefined;
  }
  return menuData.reduce((pre, item) => {
    if (item.key) {
      pre.push(item.key);
    }
    if (item.children) {
      const newArray = pre.concat(getOpenKeysFromMenuData(item.children) || []);
      return newArray;
    }
    return pre;
  }, []);
};
function deepCompareEquals(a, b) {
  return isEqual(a, b);
}
function useDeepCompareMemoize(value) {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier
  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

export const getPageQuery = () => parse(window.location.href.split('?')[1]);
/**
 * props.route.routes
 * @param router [{}]
 * @param pathname string
 */
export const getAuthorityFromRouter = (router = [], pathname) => {
  const authority = router.find(
    ({ routes, path = '/' }) =>
      (path && pathToRegexp(path).exec(pathname)) ||
      (routes && getAuthorityFromRouter(routes, pathname)),
  );
  if (authority) return authority;
  return undefined;
};

export const getRouteAuthority = (path, routeData) => {
  let authorities;
  routeData.forEach((route) => {
    // match prefix
    if (pathToRegexp(`${route.path}/(.*)`).test(`${path}/`)) {
      if (route.authority) {
        authorities = route.authority;
      } // exact match

      if (route.path === path) {
        authorities = route.authority || authorities;
      } // get children authority recursively

      if (route.routes) {
        authorities = getRouteAuthority(path, route.routes) || authorities;
      }
    }
  });
  return authorities;
};

export function useDeepCompareEffect(effect, dependencies) {
  useEffect(effect, useDeepCompareMemoize(dependencies));
}

export const downloadDocument = (object_key) => {
  srvGetDocumentURL({
    object_key,
  }).then((res) => {
    let url = res.data;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'download');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

export const downloadFile = (file) => {
  const object_key = file.object_key;
  srvGetDocumentURL({
    object_key,
  }).then((res) => {
    fetch(res.data, {
      method: 'get',
      responseType: 'arraybuffer',
    }).then(res => {
      if (res.status !== 200) {
        return res.json()
      }
      return res.arrayBuffer()
    }).then(blobRes => {
      const e = new Blob([blobRes], {
        type: 'application/octet-stream',
        'Content-Disposition': 'attachment'
      })
      const link = window.URL.createObjectURL(e)
      let a = document.createElement('a');
      a.href = link;
      a.download = file.name;
      a.click();
    }).catch(err => {
      console.error(err)
    })
  });
}

export function formatPhoneNumber(str) {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3];
  }

  return null;
}

function formatPhoneNumberToOnlyNumber(phoneNumberString) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + match[2] + match[3];
  }
  return phoneNumberString;
}

export function validateEmail(email) {
  return String(email.trim())
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
}

export function validatePhone(phone) {
  return String(phone.trim())
    .toLowerCase()
    .match(/^[]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im);
}

export function validateName(name){
  return String(name.trim())
    .toLowerCase()
    .match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/);
}

export function validateClinician(clinician){
  return String(clinician.trim())
    .toLowerCase()
    .match(/^[a-z ]*$/);
}

export function validateState(state){
  return String(state.trim())
    .toLowerCase()
    .match(/^[a-z ]*$/);
}


export function invalidErrorMessage(value){
// function takes in passed in value that failed other validations determines whether it was attempting to be an
  // email name or phone number and returns the corresponding error message
  let invalidValue = String(value).trim().toLowerCase()
  let invalidChars = ""
  let invalidErrorMessage = ""
  // if cleaned passed in value contains @ assume it was supposed to be an email but wasn't formatted as a valid email
  if (invalidValue.includes('@')){
    invalidErrorMessage = `unable to search for email: ${value}`
  } else {
    for (let char of invalidValue) {
      // everything else is assumed to be phone number or name if its a number or contains "." as invalid search entry
      if (String(char).match(/^[0-9 a-z.]*$/)) {
        if (String(char).match(/^[0-9.]*$/)) {
          invalidErrorMessage = `invalid search: ${value}`
          break;
        }
      } else {
        // all other special characters are caught in this string and appended to for invalid character error message
        // if it gets to this point it is assumed the search was supposed to be a name but was invalid
        invalidChars = invalidChars.concat(char + ' ')
      }
    }
    if (invalidChars !== "" && invalidErrorMessage === ""){
      invalidErrorMessage = `the following characters are invalid:  ${invalidChars}`
    }
  }
  if (value !== "" && invalidErrorMessage !== ""){
    return message.error(invalidErrorMessage)
  }
}
export function getFilterContent(value) {
  // validates if passed in value falls into email, phone, or name buckets or is invalid if invalid ant error is created
  // otherwise key in filter content is query type and value is the passed in value formatted and cleaned based on bucket
  const filterContent = {};
  if (validateEmail(value)) {
    filterContent.email = value.trim();
  } else if (validatePhone(value)) {
    filterContent.phone = formatPhoneNumberToOnlyNumber(value.trim());
  } else if (validateName(value)) {
    filterContent.name = value.trim();
  } else if (validateClinician(value)){
    filterContent.doctor_name = value.trim();
  } else if (validateState(value)){
    filterContent.state = value.trim();
  } else {
    invalidErrorMessage(value)
    filterContent.invalidInput = value.trim();
  }


  return filterContent;
}

export function isJSON(str) {
  if (typeof str == 'string') {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
}

export const getDoctorAvatarURL = (id, name, title) => {
  const fileName = `${id}_${name}${title}.png`;
  const storageUrl =
    process.env.GOOGLE_STORAGE_URL || 'https://storage.cloud.google.com/done_api_static/';

  return storageUrl + fileName;
};

export const getNoteTemplatesForPatient = (patient) => {
  if(patient.user_classification == 1) {
    return mindfulTemplate
  } else {
    return template
  }
}

export const getPatientName = (patient) => {
  if (!patient) return '';
  if(patient.first_name && patient.first_name.length > 0) {
    return `${patient.first_name} ${patient.last_name}`
  } else if(['Done user', 'Done User', 'Mindful user', 'Mindful User'].includes(patient.patient_name || patient.name)) {
    return patient.preferred_name
  } else if(patient.patient_name) {
    return patient.patient_name
  } else if(patient.name) {
    return patient.name
  }  if(patient.preferred_name) {
    return patient.preferred_name
  } else {
    return ''
  }
}

export const getLabelFromValue = (value, options) => {
  console.log('@@@@',value, options);
  const option = options.find((option) => option.value === value);
  return option ? option.label : value;
};
