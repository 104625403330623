import React, { useEffect, useState } from 'react';
import { Form, Space, Select, Input, Checkbox, Tooltip } from 'antd';
import { formatUnix } from '@/utils/xtime';
import moment from 'moment';

import { useRequest } from 'ahooks';
import { srvPatientDetail } from '@/services/patient';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

import { QuestionCircleOutlined } from '@ant-design/icons';

const NextFollowUpDateFormItem = (props) => {
  const { form, patient } = props;

  const nextFollowUpDateOpts = [
    { label: '1 week', value: 0.25 },
    { label: '2 weeks', value: 0.5 },
    { label: '1 month', value: 1 },
    { label: '2 months', value: 2 },
    { label: '3 months', value: 3 },
    { label: '4 months', value: 4 },
    { label: '5 months', value: 5 },
    { label: '6 months', value: 6 }
  ]

  const [patientNextFollowUpAt, setPatientNextFollowUpAt] = useState(0);
  const [nextFollowUpDate, setNextFollowUpDate] = useState(0);
  const [nextFollowUpMonth, setNextFollowUpMonth] = useState(0);

  const loadPatientInfoReq = useRequest(srvPatientDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setPatientNextFollowUpAt(ret.data?.next_follow_up_date)
        if (ret.data && ret.data.next_follow_up_date > moment().unix()) {
          setNextFollowUpDate(ret.data.next_follow_up_date)
        }
      }
    },
  });

  const onCheckboxChange = (e) => {
    setChecked(e.target.checked);
    setDisabled(!e.target.checked);
    if (!e.target.checked) {
      setNextFollowUpMonth(0)
      setNextFollowUpDate(patientNextFollowUpAt)
      form.setFieldsValue({ next_follow_up_date: 0, next_follow_up_month: 0 })
    }
  };

  const onNextFollowUpDateChanged = (n) => {
    setNextFollowUpMonth(n)
    let next_follow_up_date
    if (n > 0) {
      if (n == 0.25) {
        next_follow_up_date = moment().add(1, 'weeks').unix()
      } else if (n == 0.5) {
        next_follow_up_date = moment().add(2, 'weeks').unix()
      } else {
        next_follow_up_date = moment().add(n, 'months').unix()
      }
      setNextFollowUpDate(next_follow_up_date)
    } else {
      next_follow_up_date = -1
      setNextFollowUpDate(0)
    }
    form.setFieldsValue({ next_follow_up_date, next_follow_up_month: n > 0 ? n : -1 })
  }

  useEffect(() => {
    if (patient && patient.id) {
      loadPatientInfoReq.run({ id: patient.id })
    }
  }, [patient])

  return (
    <>
      <div className={"ant-col ant-form-item-label"}>
        <label htmlFor="next_follow_up_date" className="ant-form-item-required" title="Next Follow Up Date">
          Next Follow-up(sync)
        </label>
      </div>

      <p>
        <label>
          I‘d like to remind this patient to schedule their next follow-up appointment in
        </label>
      </p>

      <Space className={cx({ nextFollowUpFormItemSelect: true })}>
        <Select
          placeholder="select months"
          style={{ width: '250px' }}
          value={nextFollowUpMonth === -1 || nextFollowUpMonth > 0 ? nextFollowUpMonth : null}
          options={nextFollowUpDateOpts}
          onChange={onNextFollowUpDateChanged}
        />
        <Tooltip
          placement="top"
          title={"After you select a date, this patient will receive a reminder one month before this date to schedule their next follow up(sync), which will not disrupt their care."}
          className={cx({ tip: true })}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </Space>

      <div className={cx({ nextFollowUpFormItem: true })}>
        <Form.Item
          name="next_follow_up_date"
          required
          rules={[
            {
              validator: (_, value) => {
                if (value === -1 || (value > 0 && value > moment().unix())) {
                  return Promise.resolve()
                } else {
                  return Promise.reject()
                }
              },
              message: 'next follow up date is required',
            }
          ]}
        >
          <Input hidden={true} />
        </Form.Item>

        <Form.Item
          name="next_follow_up_month"
        >
          <Input hidden={true} />
        </Form.Item>
      </div>

      <p style={{ marginBottom: '10px' }}>
        Estimated next follow up(sync) date: {nextFollowUpDate > 0 ? formatUnix(nextFollowUpDate, 'MMM D, YYYY') : ' '}. The reminder will be automatically sent one month before this date.
      </p>

      <div className={"ant-col ant-form-item-label"} style={{display: 'none'}}>
        <label htmlFor="next_follow_up_date"
          className={patientNextFollowUpAt > moment().unix() ? "ant-form-item" : "ant-form-item-required"}
          title="Next Follow Up Date"
          style={{ color: '#697386', paddingTop: '8px' }}>
          I‘d like to remind this patient to schedule their next follow-up appointment in
        </label>
      </div>
    </>
  )
};

export default NextFollowUpDateFormItem;
