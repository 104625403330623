import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';

import { useRequest } from 'ahooks';

import { srvDoctorAutoTransferCheck } from '../../services/admin';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

const AutoTransferConfirmOrWaitingModal = (props) => {

  const { onOk, onCancel, visible, doctor_id, patient_id } = props;

  const [loading, setLoading] = useState(false);
  const [confirmTransferModalVisible, setConfirmTransferModalVisible] = useState(visible);

  const autoTransferCheckReq = useRequest(srvDoctorAutoTransferCheck, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setTimeout(() => {
          setLoading(false)
          setConfirmTransferModalVisible(false)
          message.success('Patient transfer completed.')
          if (onOk) onOk()
        }, patient_id ? 1000: 3000);
      }
    },
  });

  const onAutoTransferConfirm = () => {
    setLoading(true)
    autoTransferCheckReq.run({ doctor_id, patient_id })
  }

  const onAutoTransferCancel = () => {
    setConfirmTransferModalVisible(false)
    if (onCancel) onCancel()
  }

  return (
    <Modal
      title="Confirm the Automatic Transfer"
      className={cx({ autoTransferConfirmOrWaitingModal: true })}
      visible={confirmTransferModalVisible}
      destroyOnClose={true}
      onCancel={onAutoTransferCancel}
      footer={false}
    >
      <p>
        We will automatically match the patient{patient_id ? '' : 's'} with appropriate provider{patient_id ? '' : 's'}. If the patient has a follow-up appointment, it will be automatically canceled. If there is no available provider, the patient{patient_id ? '' : 's'} will be added to the awaiting transfer list, until a provider becomes available.
      </p>
      <div className={cx({ modalButtonGroup: true })}>
        <Button key="cancel" type="text" onClick={onAutoTransferCancel}>Cancel</Button>
        <Button key="confirm" style={{ width: 160 }} type="primary" loading={loading} onClick={onAutoTransferConfirm}>Confirm</Button>
      </div>
    </Modal>
  );
};

export default AutoTransferConfirmOrWaitingModal;
