import React, { useEffect, useState } from 'react';
import { Button, Table, Modal, Space, Input, Form, Col, Row, Divider, Tooltip } from 'antd';
import { ExclamationCircleOutlined, RightOutlined, DownOutlined, StarOutlined, StarFilled } from '@ant-design/icons';
import { history,useAccess } from 'umi';
import moment from 'moment';

const { TextArea } = Input;

import styles from './InternalComments.less'

import cn from 'classnames/bind';
const cx = cn.bind(styles);

import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import { formatUnix } from '@/utils/xtime';

import {
  srvPatientComments,
  srvAddPatientComment,
  srvUpdatePatientComment,
  srvDeletePatientComment,
  srvPinPatientComment,
} from '@/services/patient';

import {
  srvAdminPatientComments,
  srvAdminAddPatientComment,
  srvAdminUpdatePatientComment,
  srvAdminDeletePatientComment,
} from '@/services/admin';

const InternalComments = (props) => {
  const [form] = Form.useForm();
  const access=useAccess();
  const { id, admin } = props;
  const [comments, setComments] = useState([]);
  const [showModal, setShowModal] = useState(false)
  const [selectedComment, setSelectedComment] = useState(null)
  const [showTable, setShowTable] = useState(false)
  const [page, setPage] = useState(1);
  const [data, setData] = useState(null);

  const loadCommentsReq = useRequest(admin ? srvAdminPatientComments : srvPatientComments, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setData(ret.data)
        setComments(ret.data.items)
      }
    },
  });

  const addCommentReq = useRequest(admin ? srvAdminAddPatientComment : srvAddPatientComment, {
    manual: true,
    onSuccess: (ret, params)=> {
      loadCommentsReq.run({ patient_id: id, page: page, page_size: 5 })
      setShowModal(false)
      setShowTable(true)
    }
  })

  const updateCommentReq = useRequest(admin ? srvAdminUpdatePatientComment : srvUpdatePatientComment, {
    manual: true,
    onSuccess: (ret, params)=> {
      loadCommentsReq.run({ patient_id: id, page: page, page_size: 5 })
      setShowModal(false)
    }
  })

  const deleteCommentReq = useRequest(admin ? srvAdminDeletePatientComment : srvDeletePatientComment, {
    manual: true,
    onSuccess: (ret, params)=> {
      loadCommentsReq.run({ patient_id: id, page: page, page_size: 5 })
      setShowModal(false)
    }
  })

  useMount(() => {
    loadCommentsReq.run({ patient_id: id, page: page, page_size: 5 })
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadCommentsReq.run({
      patient_id: id,
      page: pagination.current,
      page_size: pagination.pageSize
    });
  };

  const columns = [
    {
      title: 'Author Name',
      dataIndex: 'user_name',
      key: 'user_name',
      width: 200,
    },
    {
      title: 'Time Added',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
      render: (v, r) => {
        return formatUnix(v)
      },
    },
    {
      title: 'Comment',
      dataIndex: 'content',
      key: 'content',
      width: 200,
      render: (v, r) => {
        return <>
          <Tooltip title={v}>
            {v.substring(0, 120)}...
          </Tooltip>
          { r.last_edited_at != 0 && <span style={{color: '#6B7384'}}> &nbsp; edited on {formatUnix(r.last_edited_at)}</span> }
        </>
      }
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (v, r) => {
        if (!r.is_editable) {
          return <></>
        }
        return (
          <Space>
            <Button style={{marginRight: '10px'}} onClick={()=>{
              setSelectedComment(r)
              form.setFieldsValue({ 'comment': r.content })
              setShowModal(true)
            }}>Edit</Button>
            <Button onClick={()=>{
              setSelectedComment(r)
              confirmDelete(r)
            }}>Delete</Button>
          </Space>
        )
      },
    },
  ]

  const confirmDelete = (r) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to delete this comment?',
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: ()=> {
        deleteCommentReq.run({ id: r.id })
      }
    });
  }

  const onFinish = () => {

  }

  return (
    <div className={cx({ commentsContainer: true })}>
      <Row>
        <Col span={4}>
          <h2>Internal Comments</h2>
        </Col>
        <Col span={2}>
        {!(history.location.query.admin &&access.adminAccesser) && (
          <Button
            type="primary"
            htmlType="button"
            onClick={() => {
              form.setFieldsValue({'comment': ''})
              setSelectedComment(null)
              setShowModal(true)
            }}
          >
            Add Comment
          </Button>
          )}
        </Col>
        <Col offset={17}>
          { !showTable && <RightOutlined onClick={()=> { setShowTable(true) }} /> }
          { showTable && <DownOutlined onClick={()=> { setShowTable(false) }} /> }
        </Col>
      </Row>
      { showTable &&
        <>
          <Divider />
          <Table
            columns={columns}
            dataSource={comments}
            rowKey="id"
            // loading={upcomingReq.loading}
            onChange={handleTableChange}
            pagination={{
              pageSize: 5,
              total: data.total,
              simple: true,
              current: page,
              showTotal: (total, range) => {
                return `${range[0]}-${range[1]} of ${total} items`;
              },
            }}
          />
        </>
      }

      <Modal title=""
        visible={showModal}
        okText="Submit"
        onOk={() => {
          if (selectedComment) {
            updateCommentReq.run({
              id: selectedComment.id,
              content: form.getFieldValue('comment')
            })
          } else {
            addCommentReq.run({
              target_patient_id: id,
              content: form.getFieldValue('comment')
            })
          }
        } }
        onCancel={()=>{
          setShowModal(false)
        }}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          style={{ margin: 0 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Comment"
            name="comment"
            rules={[{ required: true }]}
          >
            <Input.TextArea
              showCount
              placeholder="Comment"
              rows={4}
              maxLength={2000}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default InternalComments;
