import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'umi';
import {
  Row,
  Col,
  message,
  Steps,
  Popconfirm,
  Tag,
  Select,
  Space,
  Drawer,
  Input,
  Button,
  Table,
  Radio,
  Switch,
  Descriptions,
} from 'antd';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import { FormOutlined } from '@ant-design/icons';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import { SearchOutlined } from '@ant-design/icons';
import { DescriptionsSkeleton } from '@/components/ProSkeleton';

import { srvRxntSyncError, srvSyncRxntStatusList, srvSync2Rxnt } from '@/services/admin';
import { formatUnix } from '../../utils/xtime';
import styles from './SyncStatus.less';
const cx = cn.bind(styles);

const SyncStatusList = (props) => {
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [errlog, setErrlog] = useState({});
  const [selected, setSelected] = useState('');
  const [errorVisible, setErrorVisible] = useState(false);

  const onErrorClose = () => {
    setErrorVisible(false);
    setSelected('');
  };

  const listReq = useRequest(srvSyncRxntStatusList, {
    manual: true,
    onSuccess: (ret, params) => {
      setData(ret.data);
    },
  });

  const errorReq = useRequest(srvRxntSyncError, {
    manual: true,
    onSuccess: (ret, params) => {
      setErrlog(ret.data);
    },
  });

  const syncReq = useRequest(srvSync2Rxnt, {
    manual: true,
    onSuccess: (ret, params) => {
      listReq.run({
        page: page,
        page_size: 20,
      });
    },
    onError: (ret, params) => {
      listReq.run({
        page: page,
        page_size: 20,
      });
    },
  });

  const onSync = (id) => {
    syncReq.run({ id });
  };

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        const userName = `${r.first_name} ${r.last_name}`;
        return <Link to={`/patient/${r.id}`}>{userName}</Link>;
      },
    },
    {
      title: 'RxntID',
      dataIndex: 'rxnt_id',
      key: 'rxnt_id',
      ellipsis: true,
      width: 80,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
      width: 150,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
    },
    {
      title: 'Last Sync Time',
      ellipsis: true,
      dataIndex: 'last_sync_time',
      width: 140,
      key: 'last_sync_time',
      render: (v, r) => {
        if (r.last_sync_time == 0) {
          return '';
        }
        return formatUnix(r.last_sync_time, 'MMM D h:mm A');
      },
    },
    {
      title: 'Success',
      dataIndex: 'success',
      key: 'success',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        return r.success ? (
          'True'
        ) : (
          <a
            className={cx({ linkdetail: true })}
            onClick={() => {
              setErrorVisible(true);
              errorReq.run({ user_id: r.id });
            }}
          >
            False
            <SearchOutlined />
          </a>
        );
      },
    },
    {
      title: 'Manual',
      ellipsis: true,
      dataIndex: 'id',
      width: 100,
      key: 'id',
      render: (v, r) => {
        return (
          <Button
            size="small"
            loading={syncReq.loading}
            onClick={() => {
              onSync(r.id);
            }}
          >
            Sync
          </Button>
        );
      },
    },
  ];

  useMount(() => {
    listReq.run({ page: 1, page_size: 20 });
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    listReq.run({
      page: pagination.current,
      page_size: pagination.pageSize,
    });
  };

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Sync Status</h3>
      </div>
      <Table
        dataSource={data.items}
        columns={columns}
        rowKey="id"
        scroll={{ x: 800 }}
        rowClassName={(r) => {
          if (r.id == selected) {
            return 'rowSelected';
          }
          return '';
        }}
        onChange={handleTableChange}
        loading={listReq.loading}
        pagination={{ pageSize: 20, current: page, simple: true, total: data.total }}
      />
      <Drawer
        className={cx({ errorDrawer: true })}
        title="Recent Sync Error"
        width="600"
        placement="right"
        closable={false}
        onClose={onErrorClose}
        visible={errorVisible}
      >
        {errorReq.loading ? (
          <DescriptionsSkeleton />
        ) : (
          <Descriptions title="Error" layout="vertical" column={1} size="small" bordered={true}>
            <Descriptions.Item label="Error">{errlog && errlog.error_msg}</Descriptions.Item>
          </Descriptions>
        )}
      </Drawer>
    </div>
  );
};
export default SyncStatusList;
