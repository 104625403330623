import React, { useState } from 'react';

import { Button, Select, Tooltip } from 'antd';
import { srvPatientStateDoctors, srvDoctorAssign } from '@/services/admin';
import { useRequest } from 'ahooks';

const AssignProvider = ({ patientId, onFinish, inTransferWaitlist }) => {
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [availableProvider, setAvailableProvider] = useState([]);

  const [selectedProviderId, setselectedProviderId] = useState();

  const stateDoctorsReq = useRequest(srvPatientStateDoctors, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        const providers = ret.data;
        providers.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
        setAvailableProvider(providers);
        setDropDownVisible(true);
      }
    },
  });

  const assignDoctorReq = useRequest(srvDoctorAssign, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        onFinish && onFinish();
      }
    },
  });

  return (
    <div style={{ width: '100%' }}>
      {!dropDownVisible && (
        <Button
          size="small"
          type="primary"
          onClick={() => {
            stateDoctorsReq.run({ id: patientId });
          }}
        >
          Assign Clinician
        </Button>
      )}

      {dropDownVisible && (
        <div>
          <Select
            defaultValue=""
            style={{ width: '60%' }}
            value={selectedProviderId}
            onChange={(value) => {
              setselectedProviderId(value);
            }}
          >
            {availableProvider.map((provider) => (
              <Select.Option key={provider.id} value={provider.id}>
                {provider.name} {!provider.accept_transfer_patients ? '(Not Accept Transfer)' : ''}
              </Select.Option>
            ))}
          </Select>
          <Button
            style={{
              marginLeft: '10px',
            }}
            size="small"
            type="primary"
            onClick={() => {
              if (selectedProviderId !== undefined && selectedProviderId.length > 0) {
                assignDoctorReq.run({
                  patient_id: patientId,
                  target_doctor_id: selectedProviderId,
                });
              }
            }}
          >
            Confirm
          </Button>
        </div>
      )}
    </div>
  );
};

export default AssignProvider;
