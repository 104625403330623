import React from 'react';
import { Button, Space } from 'antd';
import { ProCard } from '@ant-design/pro-components';
import { ShrinkOutlined, CloseOutlined, ArrowsAltOutlined } from '@ant-design/icons';
import { useEventEmitter, useMount } from 'ahooks';
import { useChartAuditStore } from '@/stores';
import DraggableResizableWindow from '@/components/common/DraggableResizableWindow';
import NotesView from '@/components/DoctorChartAuditDrawer/ChartNotesView';
import NotesAudit from '@/components/DoctorChartAuditDrawer/ChartNotesAudit';

const ChartAuditDraggableWindow = () => {
  const chartAuditEvent = useEventEmitter();
  const { visible, isMinimized, close, toggleMinimize, auditInfo, setChartAuditEvent } = useChartAuditStore();

  useMount(() => {
    setChartAuditEvent(chartAuditEvent);
  });

  if (!visible) {
    return null;
  }

  const minimizedContent = (
    <div
      className="handle"
      style={{
        width: '100%',
        display: 'inline-flex',
        padding: '13px 20px',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#FBFBFB',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
        cursor: 'move',
      }}
    >
      <bold style={{ fontSize: '14px', fontWeight: 'bold' }}>Chart Audit</bold>
      <span style={{ fontSize: '12px' }}>{auditInfo.patient_name}</span>
      <div>
        <Button type="text" icon={<ArrowsAltOutlined />} onClick={toggleMinimize} />
        <Button type="text" icon={<CloseOutlined />} onClick={close} />
      </div>
    </div>
  );

  return (
    <DraggableResizableWindow
      visible={visible}
      isMinimized={isMinimized}
      minWidth={1100}
      minHeight={500}
      dragHandleClassName={isMinimized ? 'handle' : 'ant-pro-card-header'}
      minimizedContent={minimizedContent}
    >
      <ProCard
        title={auditInfo.patient_name}
        bordered
        headStyle={{ background: '#FBFBFB', paddingBottom: '18px', cursor: 'move' }}
        bodyStyle={{ overflow: 'hidden' }}
        style={{
          height: '100%',
          boxShadow:
            '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
        }}
        extra={
          <Space>
            <Button type="text" icon={<ShrinkOutlined />} onClick={toggleMinimize} />
            <Button type="text" icon={<CloseOutlined />} onClick={close} />
          </Space>
        }
        split="vertical"
      >
        <ProCard
          colSpan={'40%'}
          style={{
            height: '100%',
          }}
          bodyStyle={{ overflowY: 'scroll', padding: '14px 0 24px 24px' }}
        >
          {auditInfo && auditInfo.note_id && <NotesView id={auditInfo.note_id} />}
        </ProCard>

        <ProCard
          colSpan={'60%'}
          style={{
            height: '100%',
          }}
          bodyStyle={{ overflowY: 'scroll', padding: '14px 0' }}
        >
          {auditInfo && auditInfo.note_id && (
            <NotesAudit
              audit={auditInfo}
              onClose={() => {
                // if (onRefresh) onRefresh();
                // if (setVisible) setVisible(false);
                close();
              }}
            />
          )}
        </ProCard>
      </ProCard>
    </DraggableResizableWindow>
  );
};

export default ChartAuditDraggableWindow;
