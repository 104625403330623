import React, { useState } from 'react';
import { Modal, Button } from 'antd';

import { useRequest } from 'ahooks';

import { srvUpdateSetting } from '../../services/account';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

const RemindProviderAcceptTransferPatientModal = (props) => {

  const { onRefresh } = props;
  const [modalVisible, setModalVisible] = useState(true);

  const updateSettingReq = useRequest(srvUpdateSetting, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setModalVisible(false);
        if (onRefresh) {
          onRefresh();
        }
      }
    },
  });

  const onSetting = (value) => {
    updateSettingReq.run({ key: 'AcceptTransferPatients', value: String(value) })
  }

  return (
    <>
      <Modal
        title="Do you want to accept transfer patient?"
        zIndex={1200}
        closeIcon={<></>}
        className={cx({ remindProviderAcceptTransferPatientModal: true })}
        visible={modalVisible}
        footer={false}
      >
        <p>Just wanted to let you know that there are some patients in your state who are waiting for a transfer.</p>
        <p>Do you want to turn on the toggle for accepting transfer patients now?</p>
        <div className={cx({ modalButtonGroup: true })}>
          <Button key="cancel" onClick={() => onSetting(false)}>Not now</Button>
          <Button key="confirm" type="primary" onClick={() => onSetting(true)}>Yes, turn on</Button>
        </div>
      </Modal>
    </>
  );
};

export default RemindProviderAcceptTransferPatientModal;
