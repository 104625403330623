import React, { useState, useRef, useEffect } from 'react';
import { Button, Spin, Drawer, Slider, Input, Form, message } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
const { TextArea } = Input;
import {useMount, useRequest} from 'ahooks';
import {
  srvGetDoctorAppointmentQuestion,
  srvUpdateDoctorAppointmentQuestion
} from '@/services/account';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

const AppointmentQuestionDrawer = (props)=>{
  const { onClose } = props
  const [questions, setQuestions] = useState([])
  const [additionalQuestions, setAdditionalQuestions] = useState([])

  useEffect(()=>{
    fetchQuestions.run()
  }, [])

  const fetchQuestions = useRequest(srvGetDoctorAppointmentQuestion, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        var _questions = ret.data.filter((q) => { return q.doctor_id == 0 })
        setQuestions(_questions)
        var _additionalQuestions = ret.data.filter((q) => { return q.doctor_id != 0 })
        setAdditionalQuestions(_additionalQuestions)
      }
    }
  })

  const updateQuestions = useRequest(srvUpdateDoctorAppointmentQuestion, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        message.success('update additional questions successfully')
      }
    }
  })

  const addAdditionalQuestion = () => {
    setAdditionalQuestions(
      [...additionalQuestions, {}]
    )
  }

  const handleInputChange = (index, text) => {
    setAdditionalQuestions(prevState => {
      let data = [...prevState];
      data[index] = {
        ...data[index],
        title: text,
      };
      return data;
    });
  }

  const removeAdditionalQuestion = (index) => {
    setAdditionalQuestions([...additionalQuestions.slice(0, index), ...additionalQuestions.slice(index + 1)]);
  }

  const saveAdditionalQuestion = () => {
    const hasEmptyTitle = additionalQuestions.some((item) => !item.title || item.title.length == 0);

    if(hasEmptyTitle) {
      message.error("Question title can't be blank!");
      return
    }
    var items = additionalQuestions.map((q, index)=>{
      return {
        id: q.id,
        title: q.title,
        delete: q.delete
      }
    })

    updateQuestions.run({ items: items })
  }

  return (
    <Drawer
      title={
        <div>
          <h3>Customize Your Questionnaire</h3>
        </div>
      }
      visible={true}
      onClose={onClose}
      width="600"
    >
      <h3>Video Questions</h3>
      <ul
        className={cx({ appointmentQuestionsList: true })}
      >
        {
          questions.map((question, index)=>{
            return <li>
              {index+1}. {question.title}
            </li>
          })
        }
      </ul>
      <p
        className={cx({ tipText: true })}
      >
        We provide a standardized video questionnaire to patients, if you have additional questions please customize your questionnaire below. For additional questions, the patients answer with typed text.
      </p>

      <h3
        className={cx({ additionalQuestionsTitle: true })}
      >Additional Questions</h3>
      <ul
        className={cx({ additionalQuestionsList: true })}
        >
        {
          additionalQuestions.filter((q)=>{return !q.delete}).map((question, index)=>{
            return <li key={index}>
              <span
                className={cx({additionalQuestionLabel: true})}
              >Question {index + 1}</span>
              <TextArea
                style={{marginRight: '10px'}}
                rows={1}
                value={question.title}
                placeholder=""
                maxLength={300}
                onChange={(e) => handleInputChange(index, e.target.value)}
              />
              <Button onClick={() => removeAdditionalQuestion(index)} icon={<MinusCircleOutlined />} />
            </li>
          })
        }
      </ul>
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        disabled={additionalQuestions.length == 3}
        onClick={()=> { addAdditionalQuestion() }}
      >
        Question
      </Button>
      <hr style={{marginTop: '10px', marginBottom: '10px'}}/>
      <div
        className={cx({saveButtonWraper: true})}
      >
        <Button type="primary" onClick={()=> { saveAdditionalQuestion() }}>
          Save
        </Button>
      </div>
    </Drawer>
  )
}

export default AppointmentQuestionDrawer;
