import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link } from 'umi';
import { Popover, Card, Table, Descriptions, Tag, Space, Button, Spin } from 'antd';
import moment from 'moment';
import { CreditCardFilled, createFromIconfontCN } from '@ant-design/icons';
import numeral from 'numeral';
import { AsYouType } from 'libphonenumber-js';
import { stringify } from 'querystring';

import defaultSettings from '../../../defaultSettings';
import RefundModal from './RefundModal';
import { orderStatusColor, paymentStatusColor, refundStatusColor } from '@/utils/tagColor';

import { formatUnix } from '../../utils/xtime';
import styles from './index.less';
const cx = cn.bind(styles);

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const columns = [
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    ellipsis: true,
    width: 80,
    render: (v, r) => {
      const val = numeral(v / 100).format('0,0.00');
      return <span style={{ color: '#1a1f36', fontWeight: 500 }}>{val}</span>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true,
    width: 150,
    render: (v, r) => {
      if (r.status == 3) {
        return (
          <Popover
            content={<div className="popover-user">{r.reason}</div>}
            title="Reason"
            trigger="hover"
          >
            <Tag color={refundStatusColor(r.status)}>{r.status_name}</Tag>
          </Popover>
        );
      }
      return <Tag color={refundStatusColor(r.status)}>{r.status_name}</Tag>;
    },
  },
  {
    title: 'Stripe Refund ID',
    dataIndex: 'external_refund_id',
    key: 'external_refund_id',
    ellipsis: true,
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    ellipsis: true,
    width: 200,
    render: (v, r) => {
      return formatUnix(r.created_at);
    },
  },
];

const PatientDetail = (props) => {
  const { dispatch, payment_detail, loading } = props;
  const { data, appointment, membership, therapy_subscription, payment_products, payment_statuses } = payment_detail;
  const id = props.match.params.id;
  const [refundModalVisible, setRefundModalVisible] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    dispatch({
      type: 'payment_detail/fetchData',
      payload: { id },
      callback: (data) => {
        dispatch({
          type: 'payment_detail/fetchRefundHistories',
          payload: { id: data.id },
        });
        if(data.order.hand_order_flag){
          return;
        }
        if (data.order.product_id === 5) {
          return;
        }
        if (data.order.product_id === 4) {
          dispatch({
            type: 'payment_detail/fetchMembership',
            payload: { id: data.order.target_id },
          });
        } else if (data.order.product_id === 54) {
          dispatch({
            type: 'payment_detail/fetchTherapySubscription',
            payload: { id: data.order.target_id },
          });
        } else {
          dispatch({
            type: 'payment_detail/fetchAppointment',
            payload: { id: data.order.target_id },
          });
        }
      },
    });
  };

  const loadList = (payload, callback) => {};

  const amount = data.amount ? data.amount : 0;
  const val = numeral(amount / 100).format('0,0.00');

  const orderAmount = data.order ? data.order.amount : 0;
  const orderVal = numeral(orderAmount / 100).format('0,0.00');

  let modalProps = {
    visible: refundModalVisible,
    item: data,
    maskClosable: false,
    closable: false,
    okText: 'Refund',
    width: 700,
    onOk: (payload) => {
      dispatch({
        type: 'payment/refundPayment',
        payload,
        callback: () => {
          setTimeout(() => {
            loadData();
            setRefundModalVisible(false);
          }, 3000);
        },
      });
    },
    onCancel: () => {
      setRefundModalVisible(false);
    },
    okButtonProps: {
      style: {
        with:'180px !important',
        border: 'none',
        borderRadius: '4px',
        marginBottom: '15px',
        marginTop: '10px',
      },
    },
    cancelButtonProps: {
      style: {
        backgroundColor: '#ffffff',
        color: '#566BCD',
        border: 'none',
        float: 'left',
        textAlign: 'left',
        marginBottom: '15px',
        marginTop: '10px',
      },
    },
  };

  const showRefundModal = () => {
    setRefundModalVisible(true);
  };

  return (
    <div className={cx({ container: true })}>
      <Spin tip="Loading..." spinning={loading.effects['payment_detail/fetchData']}>
        <div className={cx({ header: true })}>
          <div>
            <div className={cx({ paymentSubheader: true })}>
              <Space>
                <CreditCardFilled />
                Payment
              </Space>
            </div>
            <div className={cx({ paymectTitle: true })}>
              <Space>
                <span className={cx({ paymentAmount: true })}>${val} USD</span>
                <Tag color={paymentStatusColor(data.status)}>{data.status_name}</Tag>
              </Space>
            </div>
          </div>
          <Space>
            {(data.status === 2 || data.status === 7 || data.status === 8 || (data.status === 6 && data.amount > data.amount_refunded)) && (
              <Button type="primary" onClick={showRefundModal}>
                Refund
              </Button>
            )}
          </Space>
        </div>

        <div className={cx({ content: true })}>
          <Descriptions title="" layout="vertical" size="small" column={3}>
            <Descriptions.Item label="Date">{formatUnix(data.created_at)}</Descriptions.Item>
            <Descriptions.Item label="Product">
              {data.order && data.order.product_id_name}
            </Descriptions.Item>
            <Descriptions.Item label="Stripe/Paypal ID">
              <Tag>{data.external_charge_id}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Patient Name">
              {data.patient && (
                <Link to={`/patient/${data.user_id}`}>
                  {data.patient.first_name} {data.patient.last_name}
                </Link>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Patient Email">
              {data.patient && <Link to={`/patient/${data.user_id}`}>{data.patient.email}</Link>}
            </Descriptions.Item>
            <Descriptions.Item label="Patient Phone">
              {data.patient && <Link to={`/patient/${data.user_id}`}>{data.patient.phone}</Link>}
            </Descriptions.Item>
          </Descriptions>
          <div className={cx({ sectionHeader: true })}>
            <h4>Order</h4>
          </div>
          <Descriptions title="" layout="horizontal" column={3}>
            <Descriptions.Item label="ID">{data.order && data.order.id}</Descriptions.Item>
            <Descriptions.Item label="Date">
              {data.order && formatUnix(data.order.created_at)}
            </Descriptions.Item>
            <Descriptions.Item label="Amount">{orderVal}</Descriptions.Item>
            <Descriptions.Item label="Due Date">
              {data.order && formatUnix(data.order.final_payment_at)}
            </Descriptions.Item>
            <Descriptions.Item label="Order Status">
              {data.order && (
                <Tag color={orderStatusColor(data.order.status)}>{data.order.status_name}</Tag>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Product">
              {data.order && data.order.product_id_name}
            </Descriptions.Item>
            {(data.order&&data.order.hand_order_flag)&&<Descriptions.Item label="Note">
              {data.order.reason}
            </Descriptions.Item>}
          </Descriptions>
          {data.order && data.order.product_id !== 4&&!data.order.hand_order_flag && (
            <Card title="Appointment" size="small">
              <Descriptions title="" layout="horizontal" column={3}>
                <Descriptions.Item label="Appointed At">
                  {formatUnix(appointment.appointed_at)}
                </Descriptions.Item>
                <Descriptions.Item label="Type">
                  {appointment.appointment_type_name}
                </Descriptions.Item>
                <Descriptions.Item label="Doctor">
                  {appointment.doctor && (
                    <Link to={`/doctor/${appointment.doctor_id}`}>
                      {appointment.doctor.name} {appointment.doctor.title}
                    </Link>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  <Tag>{appointment.status_name}</Tag>
                </Descriptions.Item>
                <Descriptions.Item label="Timezone"> {appointment.timezone} </Descriptions.Item>
                <Descriptions.Item label="Online URL">
                  {' '}
                  {appointment.online_consultation_url}{' '}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          )}
          {data.order && data.order.product_id === 4&&!data.order.hand_order_flag && (
            <Card title="Membership" size="small">
              <Descriptions title="" layout="horizontal" column={3}>
                <Descriptions.Item label="Start Date">
                  {formatUnix(membership.begin_date)}
                </Descriptions.Item>
                <Descriptions.Item label="End Date">
                  {formatUnix(membership.end_date)}
                </Descriptions.Item>
                <Descriptions.Item label="Type">{membership.membership_type_name}</Descriptions.Item>
                <Descriptions.Item label="Status">
                  <Tag>{membership.status_name}</Tag>
                </Descriptions.Item>
                <Descriptions.Item label="Follow Up">
                  <Tag>{membership.followup ? 'Used' : 'Not Used'}</Tag>
                </Descriptions.Item>
                <Descriptions.Item label="Used Consultation">
                  <Tag>{membership.used_consultation_num}</Tag>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          )}
          {data.order && data.order.product_id === 54 && !data.order.hand_order_flag && (
            <Card title="Therapy Membership" size="small">
              <Descriptions title="" layout="horizontal" column={3}>
                <Descriptions.Item label="Start Date">
                  {formatUnix(therapy_subscription.start_time)}
                </Descriptions.Item>
                <Descriptions.Item label="End Date">
                  {formatUnix(therapy_subscription.start_time)}
                </Descriptions.Item>
                <Descriptions.Item label="Type">{therapy_subscription.subscription_type_name}</Descriptions.Item>
                <Descriptions.Item label="Status">
                  <Tag>{membership.status_name}</Tag>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          )}

          <div className={cx({ sectionHeader: true })}>
            <h4>Refund Histories</h4>
          </div>

          <Table
            columns={columns}
            dataSource={payment_detail.refundHistories}
            rowKey="id"
            loading={loading.effects['payment/fetchRefundHistories']}
            pagination={false}
          />
          {refundModalVisible && <RefundModal {...modalProps} />}
        </div>
      </Spin>
    </div>
  );
};

export default connect(({ payment_detail, loading }) => ({
  payment_detail,
  loading,
}))(PatientDetail);
