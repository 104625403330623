import React, { useState, useEffect } from 'react';
import cn from 'classnames/bind';
import { useMount, useUpdateEffect, useAntdTable } from 'ahooks';
import { Tag, Space, Table } from 'antd';
import numeral from 'numeral';
import { useDispatch, useSelector } from 'umi';

import { srvGetOrderList } from '../../services/admin';
import Filters from '@/components/Filters';
import { paymentStatusColor } from '@/utils/tagColor';

import { formatUnix } from '../../utils/xtime';
import styles from './index.less';
const cx = cn.bind(styles);

const Order = (props) => {
  const { id, active } = props;

  const dispatch = useDispatch();
  const patient_detail = useSelector((state) => state.patient_detail);

  const [query, setQuery] = useState({});
  const { tableProps, refresh } = useAntdTable(
    ({ current, pageSize }) =>
      srvGetOrderList({
        user_id: id,
        page: current,
        page_size: pageSize,
        filters: JSON.stringify(query),
      }).then((res) => {
        return { total: res.data.total, list: res.data.items };
      }),
    {
      refreshDeps: [query],
    },
  );

  useUpdateEffect(() => {
    if (active) {
      refresh();
    }
  }, [active]);

  useMount(() => {
    dispatch({ type: 'patient_detail/fetchPaymentStatuses' });
  });

  const filters = [
    {
      name: 'status',
      label: 'Status',
      type: 'checkbox',
      items: patient_detail.payment_statuses,
    },
  ];

  const columns = [
    {
      title: 'Product',
      dataIndex: 'product_name',
      key: 'product_name',
      width: 160,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      ellipsis: true,
      width: 80,
      render: (v, r) => {
        const val = numeral(v / 100).format('0,0.00');
        return <span style={{ color: '#1a1f36', fontWeight: 500 }}>{val}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 80,
      render: (v, r) => {
        return <Tag color={paymentStatusColor(r.status)}>{r.status_name}</Tag>;
      },
    },

    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      ellipsis: true,
      width: 280,
    },
    {
      title: 'Final Payment time',
      dataIndex: 'final_payment_at',
      key: 'final_payment_at',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        return formatUnix(r.final_payment_at);
      },
    },
    {
      title: 'Charges Times',
      dataIndex: 'charge_times',
      key: 'charge_times',
      width: 80,
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
    },
  ];

  const onFilter = (q) => {
    setQuery(q);
    // setPage(1);
    // props.loadPayments({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => {});
  };

  return (
    <div className={cx({ subcontainer: true })}>
      <div className={cx({ header: true })}>
        <h3>Orders</h3>
        <Space>
          <Filters filters={filters} onFilter={onFilter} />
        </Space>
      </div>
      <Table scroll={{ x: 800 }} columns={columns} rowKey="id" {...tableProps} />
    </div>
  );
};

export default Order;
