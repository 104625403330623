export const providerDataDescription = {
  newPatient: "Initial appointments that are diagnosed, no-diagnosed, patients no-show",
  memberPanel:"Patients who have paid membership fee for at least one month",
  patientConversion:"new member/ number of diagnosed initial last month",
  patientReview:"Average rating by patients from post-appointment survey.",
  refillFulfillment:"Average response time for your patients on refill fulfillments.",
  messageResponse:"Average response time for your patients on message replies.",
  noteCompletion:"Average response time for your patients on complete notes after appointments.",
  canceledMember: "# of patients who have canceled their membership this month.",
  newPatientsIncome: "",
  memberPanelIncome: "",
  transferExtendedFollowUpIncome: "",
  exceptionIncome: "Manually updated by care-team, including collab fees, license reimbursements and referral bonus. There might be some delay of update.",
  urgentRefillApproveIncome: "By successfully providing coverage(refill and consultation) for other providers",
  urgentRefillLose: "Fee deduction due to the requirement for coverage assistance from other providers.",
  patient_conversion_rate_warning: "Your new patient conversion rate is below average. Kindly consult your Lead NP for guidance on enhancing your patient experience.",
  rating_average_star_is_warning: "Your average patient review rating is lower than 4.7 baseline. Please consult your Lead NP for guidance on improving your patient experience.",
  refill_response_rate_warning: "Your refill request fulfillment rate within 72 hours is below average. If you encounter any issues, please contact your care team on Slack.",
  refill_response_rate: "The percentage of request fulfillment that are completed within 72 hours.",
  consulation_response_rate_is_warning: "Less than 85% of your consultations were responded to within 72 hours. Kindly respond to patients as soon as possible to address their concerns.",
  consultation_response_rate: "The percentage of consultation messages that are responded within 72 hours.",
  note_completion_rate_is_warning: "Your chart notes completion rate within 24 hours is below average. Please ensure timely processing of chart notes.",
  note_completion_rate: "The percentage of chart notes that are completed within 24 hours.",
  membership_cancellation_is_warning: "Your metrics is lower than our baseline. High cancellation rate can be caused by various factors. Please reach out to your Lead NP to identify the issues.",
  membership_cancellation_rate: "# of patients who have canceled their membership this month. (Canceled members/member size from last month.",
  appointment_average_duration_60_mins: "Initial Appointment.",
  appointment_average_duration_30_mins: "Re-initial / Transfer / Extended Follow-Up Appointment.",
  appointment_average_duration_15_mins: "Follow-Up Appointment.",
  chart_audit_average_score: "",
}

