import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link } from 'umi';
import moment from 'moment';
import { Drawer, Space, Popover, Input, Table, Row, Col, Button, Tabs, Layout, Menu, Dropdown } from 'antd';
import { EllipsisOutlined, SearchOutlined, createFromIconfontCN, UpOutlined } from '@ant-design/icons';
import { AsYouType } from 'libphonenumber-js';
import styles from './index.less';

import DataSorter from '@/components/DataSorter';

import { useRequest } from 'ahooks';

import { srvChangeConsultationStatus } from '@/services/patient';

import defaultSettings from '../../../defaultSettings';
import { formatUnix } from '../../utils/xtime';
import { getPatientName } from '@/utils/utils';

import ConsultationDrawer from '@/components/ConsultationDrawer';

const { Sider, Content } = Layout;
const cx = cn.bind(styles);
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const { TabPane } = Tabs;

const EhrConsultation = (props) => {
  const { dispatch, ehr_consultation, loading } = props;
  const { data, consultation_types, replyData } = ehr_consultation;
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState('');
  const [messageTab, setMessageTab] = useState('0');
  const [currentTab, setCurrentTab] = useState('1');
  const inputEl = useRef(null);
  const internalInputEl = useRef(null);
  const [sortedInfo, setSortedInfo] = useState({});
  const [columns, setColumns] = useState(defaultcolumns);

  const defaultcolumns = [
    {
      title: 'Subject',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
    },
    {
      title: 'Type',
      dataIndex: 'consultation_type',
      key: 'consultation_type',
      ellipsis: true,
      width: 300,
      render: (v, r) => {
        return String(r.consultation_type_name).toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
      },
    },
    {
      title: 'Replies',
      dataIndex: 'reply_num',
      key: 'reply_num',
      ellipsis: true,
      width: 70,
    },
    {
      title: 'Patient',
      dataIndex: 'user_id',
      key: 'user_id',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        if (r.consultation_type == 13) {
          return ""
        }
        const userName = getPatientName(r.patient);
        return (
          <Link to={`/patient/${r.user_id}`} onClick={(e) => {e.stopPropagation()}} style={{color: '#5469d4'}}>{userName}</Link>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: 200,
      sorter: true,
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
    },
  ];
  const medicationColumns = [
    {
      title: 'Status',
      dataIndex: 'status_name',
      key: 'status_name',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        if (r.status === 1) {
          if (r.assigned_to_id!='0') {
            return <p>Assigned</p>;
          } else if (r.reply_num > 0) {
            return <p>Pending Assign</p>;
          } else {
            return (
              <p style={r.is_urgent_flag ? { color: '#EC5C55' } : {}}>
                {String(r.status_name)
                  .toLowerCase()
                  .replace(/( |^)[a-z]/g, (L) => L.toUpperCase())}
              </p>
            );
          }
        } else {
          return (
            <p style={r.is_urgent_flag ? { color: '#EC5C55' } : {}}>
              {String(r.status_name)
                .toLowerCase()
                .replace(/( |^)[a-z]/g, (L) => L.toUpperCase())}
            </p>
          );
        }
      },
    },
    {
      title: 'Type',
      dataIndex: 'consultation_type',
      key: 'consultation_type',
      ellipsis: true,
      width: 250,
      render: (v, r) => {
        return String(r.consultation_type_name).toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
      },
    },
    {
      title: 'Medication Issue Type',
      dataIndex: 'consultation_type',
      key: 'consultation_type',
      ellipsis: true,
      width: 400,
      render: (v, r) => {
        if (r.content?.split('\n\n')[0].includes('Other')) {
          return <p>Other</p>;
        } else {
          return (
            <p>
              {r.content?.split('\n\n')[0].split('Describe the issue:')[0]}
            </p>
          );
        }
      },
    },
    {
      title: 'Note',
      dataIndex: 'consultation_type',
      key: 'consultation_type',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        if (r.content?.split('\n\n')[0].includes('Other:')) {
          return (
            <p
              style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }
              }
            >
              {r.content?.split('\n\n')[0].split('Other:')[1]}
            </p>
          );
        } else {
          return (
            <p
              style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }
              }
            >
              {r.content?.split('\n\n')[0].split('Describe the issue:')[1]}
            </p>
          );
        }
      },
    },
    {
      title: 'Replies',
      dataIndex: 'reply_num',
      key: 'reply_num',
      ellipsis: true,
      width: 70,
    },
    {
      title: 'Patient',
      dataIndex: 'user_id',
      key: 'user_id',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        if (r.consultation_type == 13) {
          return ""
        }
        const userName = getPatientName(r.patient);
        return (
          <Link to={`/patient/${r.user_id}`} onClick={(e) => {e.stopPropagation()}} style={{color: '#5469d4'}}>{userName}</Link>
        );
      },
    },
    {
      title: 'Sent Date',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return <p>{formatUnix(r.created_at)}</p>;
      },
    },
  ];

  const selectRow = (record) => {
    setSelected(record.id);
    record.read = true;
    setVisible(true);
    dispatch({ type: 'ehr_consultation/fetchConsultationReplies', payload: { id: record.id } });
  };

  const onClose = () => {
    setVisible(false);
    setSelected('');
  };

  const reloadReplyData = () => {
    loadList({ status: currentTab, page: 1, page_size: 20 }, () => {});
    dispatch({
      type: 'ehr_consultation/fetchConsultationReplies',
      payload: { id: replyData.consultation.id },
    });
  }

  useEffect(() => {
    setPage(1);
    setColumns(defaultcolumns);
    loadList({ status: currentTab, page: 1, page_size: 20 }, () => {});
  }, []);

  const loadList = (payload, callback) => {
    dispatch({ type: 'ehr_consultation/fetchList', payload, callback });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSortedInfo(sorter)
    loadList({
      status: currentTab,
      page: pagination.current,
      page_size: pagination.pageSize,
      sort: sorter.order
    });
  };

  const switchTab = (e) => {
    setPage(1);
    setCurrentTab(e.key);
    if (e.key === '3') {
      setColumns(medicationColumns);
    } else{
      setColumns(defaultcolumns);
    }
    loadList({ status: parseInt(e.key), page: 1, page_size: 20 }, () => {});
  }

  return (
    <Layout>
      <Sider theme="light">
        <Menu selectedKeys={[currentTab]} onClick={switchTab}>
          <Menu.Item key="1">Inbox</Menu.Item>
          <Menu.Item key="2">Solved</Menu.Item>
          <hr style={{ marginLeft: '3px', marginRight: '3px' }} />
          <Menu.Item key="3">Medication Issue</Menu.Item>
        </Menu>
      </Sider>
      <Content theme="light">
        <div className={cx({ container: true })}>
          <div className={cx({ header: true })}>
            <h3>Consultations</h3>
          </div>

          { selected != "" &&
            <ConsultationDrawer
              role="doctor"
              visible={visible}
              replyData={replyData}
              onClose={onClose}
              onReply={() => { reloadReplyData() }}
              onSolve={()=> { onClose(); switchTab({ 'key': '2' }) }}
            />
          }

          <Table
            scroll={{ x: 700 }}
            columns={columns}
            dataSource={data.items}
            rowClassName={(r) => {
              if (r.id == selected) {
                return 'rowSelected';
              }
              if (r.read == false) {
                return styles.rowUnread;
              }
              return '';
            }}
            onRow={(record) => ({
              onClick: () => {
                selectRow(record);
              },
            })}
            rowKey="id"
            loading={loading.effects['ehr_consultation/fetchList']}
            onChange={handleTableChange}
            pagination={{ pageSize: 20, current: page, simple: true, total: data.total }}
          />
        </div>
      </Content>
    </Layout>
  );
};

export default connect(({ ehr_consultation, loading }) => ({
  ehr_consultation,
  loading,
}))(EhrConsultation);
