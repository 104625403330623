import React, { useState, useEffect, useRef } from 'react';
import {
  Radio,
  Form,
  message,
  Popconfirm,
  Tag,
  Space,
  Drawer,
  Input,
  Button,
  Checkbox,
  Descriptions,
} from 'antd';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import { formatUnix } from '../../utils/xtime';

import styles from './index.less';
import { appointmentStatusColor } from '@/utils/tagColor';
const cx = cn.bind(styles);
const CheckboxGroup = Checkbox.Group;

const CancelAppointment = (props) => {
  const { visible, appointment, loadingCancel } = props;
  const inputEl = useRef(null);
  const [form] = Form.useForm();
  const [otherCheckFlag, setOtherCheckFlag] = useState(false);


  const onClose = () => {
    form.resetFields();
    setOtherCheckFlag(false);
    props.onClose && props.onClose();
  };

  const onFinish = (values) => {

    var data = values.reason.slice();
    data.forEach((item,index,arr)=>{
      if (item == 'Other') {
        arr[index] = values.otherReason;
      }
    })

    props.cancelAppointment &&
      props.cancelAppointment(
        {
          id: appointment.id,
          reason: data.join(","),
          refund_type: values.refund_type,
        },
        () => {
          onClose();
        },
      );
  };


  const onChangeOtherReason = (e) => {
    setOtherCheckFlag(e.target.checked)
  }

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      destroyOnClose={true}
      className={cx({ cancelDrawer: true })}
      title={
        <div className={cx({ title: true })}>
          <h3>Cancel Appointment</h3>
          <Button
            type="danger"
            loading={loadingCancel}
            onClick={() => {
              form.submit();
            }}
          >
            Confirm
          </Button>
        </div>
      }
      width="600"
      placement="right"
      closable={false}
    >
      <div className={cx({ description: true })}>
        <Descriptions title="" layout="vertical" size="small" column={3}>
          <Descriptions.Item label="Appointmented At">
            <b>{formatUnix(appointment.appointed_at)}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Tag color={appointmentStatusColor(appointment.status)}>{appointment.status_name}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Type">
            <Tag>{appointment.appointment_type_name}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Patient">
            {appointment.patient && appointment.patient.first_name}{' '}
            {appointment.patient && appointment.patient.last_name}
          </Descriptions.Item>
          <Descriptions.Item label="Doctor">
            {appointment.doctor && appointment.doctor.name}{' '}
            {appointment.doctor && appointment.doctor.title}
          </Descriptions.Item>
          <Descriptions.Item label="Timezone">{appointment.timezone}</Descriptions.Item>
        </Descriptions>
      </div>

      <Form className={cx({ form: true })} layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="reason"
          label="Reason"
          rules={[
            {
              required: true,
              message: 'Reason is required',
            },
          ]}
        >
          <CheckboxGroup  >
            <ul>
              <li>
              <Checkbox value="Provider Availability Change">Provider Availability Change</Checkbox>
              </li>
              <li>
                <Checkbox value="Patient cannot afford to continue">Patient cannot afford to continue</Checkbox>
              </li>
              <li>
              <Checkbox value="Patient wants to use insurance">Patient wants to use insurance</Checkbox>
              </li>
              <li>
                <Checkbox value="Patient wants to see another clinician elsewhere">Patient wants to see another clinician elsewhere</Checkbox>
              </li>
              <li>
                <Checkbox value="Patient is moving to another state or country">Patient is moving to another state or country</Checkbox>
              </li>
              <li>
                <Checkbox value="Appointment time no longer works for patient">Appointment time no longer works for patient</Checkbox>
              </li>
              <li>
                <Checkbox value="Patient wants to reschedule">Patient wants to reschedule</Checkbox>

              </li>
              <li>
                <Checkbox value="No Intake Form">No Intake Form</Checkbox>
              </li>
              <li>
                <Checkbox value="Other" onChange={onChangeOtherReason}>Other {otherCheckFlag ?
                  <Form.Item name="otherReason" rules={[
                    {
                      required: true,
                      message: 'content is required',
                    },
                  ]}>
                    <Input.TextArea showCount maxLength={200}  placeholder="Please Explain" rows={4} style={{ width: 500, marginLeft: 20, marginTop: 1 }} />
                  </Form.Item>
                  : null}</Checkbox>
              </li>
            </ul>


          </CheckboxGroup>


        </Form.Item>

        <Form.Item
          name="refund_type"
          label="Refund"
          rules={[
            {
              required: true,
              message: 'Refund type is required',
            },
          ]}
          initialValue={1}
        >
          <Radio.Group>
            <Radio value={1}>All</Radio>
            <Radio value={2}>Remained</Radio>
            <Radio value={3}>None</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CancelAppointment;
