import React, { useState, useEffect } from 'react';
import { Space, Select, Checkbox, Form, InputNumber, Button, Row, Col } from 'antd';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import { createFromIconfontCN } from '@ant-design/icons';

import defaultSettings from '../../../defaultSettings';
import styles from './index.less';
const { Option } = Select;
const cx = cn.bind(styles);
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const NumberFilter = (props) => {
  var defaultVisible = props.form.getFieldValue(props.name) != null
  const [visible, setVisible] = useState(defaultVisible);
  useEffect(() => { setVisible(defaultVisible)}, [defaultVisible] )
  const [opt, setOpt] = useState('equals');
  const { name, label } = props;
  const onChange = (v) => {
    setOpt(v);
  };

  props.reset$.useSubscription(() => {
    setVisible(false);
  });

  return (
    <div className={cx({ filtersItem: true })}>
      <div className={cx({ filtersItemSwitch: true })}>
        <Checkbox
          checked={visible}
          onChange={(v) => {
            setVisible(v.target.checked);
          }}
        >
          {label}
        </Checkbox>
      </div>
      <div className={cx({ filtersItemBox: true, visible: visible })}>
        {visible && (
          <>
            <div className={cx({ select: true })}>
              <Select value={opt} onChange={onChange} size="small">
                <Option value="equals">is equal to</Option>
                <Option value="between">is between</Option>
                <Option value="greaterThan">is greater than</Option>
                <Option value="lessThan">is less than</Option>
              </Select>
            </div>
            {opt === 'equals' && (
              <div className={cx({ box: true })}>
                <Space>
                  <IconFont type="icon-down-right" />
                  <Form.Item name={name} rules={[{ required: true }]}>
                    <InputNumber precision={0} size="small" />
                  </Form.Item>
                </Space>
              </div>
            )}

            {opt === 'between' && (
              <div className={cx({ box: true })}>
                <Space>
                  <IconFont type="icon-down-right" />
                  <Form.Item name={`${name}[gte]`} rules={[{ required: true }]}>
                    <InputNumber precision={0} size="small" />
                  </Form.Item>
                  <span>and</span>
                  <Form.Item name={`${name}[lte]`} rules={[{ required: true }]}>
                    <InputNumber precision={0} size="small" />
                  </Form.Item>
                </Space>
              </div>
            )}

            {opt === 'greaterThan' && (
              <div className={cx({ box: true })}>
                <Space>
                  <IconFont type="icon-down-right" />
                  <Form.Item name={`${name}[gte]`} rules={[{ required: true }]}>
                    <InputNumber precision={0} size="small" />
                  </Form.Item>
                </Space>
              </div>
            )}
            {opt === 'lessThan' && (
              <div className={cx({ box: true })}>
                <Space>
                  <IconFont type="icon-down-right" />
                  <Form.Item name={`${name}[lte]`} rules={[{ required: true }]}>
                    <InputNumber precision={0} size="small" />
                  </Form.Item>
                </Space>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default NumberFilter;
