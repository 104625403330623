import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { connect, Link } from 'umi';
import NumberFormat from 'react-number-format';
import ReactCodesInput from '@/components/CodeInput';
import cn from 'classnames/bind';
import EmailValidator from 'email-validator';
import {CodeInput} from './login'
import { ReactComponent as MindfulLogo } from '@/assets/mindful-logo.svg';
import { ReactComponent as Logo } from '@/assets/logo.svg';
import styles from './login.less';
const cx = cn.bind(styles);


const Login = (props) => {
  const { dispatch, loading } = props;

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('vertical');
  const [emailDisabled, setEmailDisabled] = useState(true);
  const [email, setEmail] = useState(true);
  const [code, setCode] = useState("");
  const [codeDisabled, setCodeDisabled] = useState(true);
  const [valcodeFormEnabled, setValcodeFormEnabled] = useState(false);

  const isDone = window.location.href.includes('donefirst.com');

  const onEmailChanged = (v) => {
    if (EmailValidator.validate(v.target.value)) {
      setEmail(v.target.value);
      setEmailDisabled(false);
    } else {
      setEmail('');
      setEmailDisabled(true);
    }
  };

  const onCodeChanged = (value) => {
      setCode(value);
      if (/\d{6}/.test(value)) {
        setCodeDisabled(false);
      } else {
        setCodeDisabled(true);
      }
  };

  const getVerificationCode = () => {
    dispatch({
      type: 'login/sendEmailVerifyCode',
      payload: {
        email,
      },
      callback: () => {
        setValcodeFormEnabled(true);
      },
    });
  };

  const doLogin = () => {
    if (EmailValidator.validate(email) == false) {
      message.error('Please input your email.');
      return;
    }

    if (/\d{6}/.test(code) == false) {
      message.error('Please input your verification code.');
      return;
    }

    dispatch({
      type: 'login/loginByEmail',
      payload: {
        email,
        code,
      },
      callback: () => {},
    });
  };

  return (
    <div className={styles.loginRoot}>
      <div className={styles.loginHeader}>
        <div className={styles.loginWrap}>
          { isDone ? <Logo className={styles.logo} /> : <MindfulLogo /> }
        </div>
      </div>
      <div className={styles.loginWrap}>
        <div className={styles.loginBox}>
          <div className={styles.heading}>Sign in to your account</div>
          <Form layout={formLayout} form={form}>
            <div className={cx({ phoneForm: true, phoneFormShow: !valcodeFormEnabled })}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email',
                  },
                ]}
              >
                <Input size="large" onChange={onEmailChanged} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  size="large"
                  block
                  disabled={emailDisabled}
                  loading={loading.effects['login/sendEmailVerifyCode']}
                  onClick={getVerificationCode}
                >
                  Get Verification Code
                </Button>
              </Form.Item>
              <div className={cx({ links: true })}>
                <Link to="/user/login">Login by Phone</Link>
              </div>
              <div className={cx({ links: true })}>
                <Link to="/user/password_login">Login by Password</Link>
              </div>
            </div>
            <div className={cx({ valcodeForm: true, valcodeFormShow: valcodeFormEnabled })}>
              <Form.Item
                label="Enter your 6-digit verification code"
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Please input your verificication code',
                  },
                ]}
              >
                <CodeInput
                value={code}
                onChange={onCodeChanged} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                  disabled={codeDisabled}
                  onClick={doLogin}
                  loading={loading.effects['login/loginByEmail']}
                >
                  Sign In
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default connect(({ login, loading }) => ({
  login,
  loading,
}))(Login);
