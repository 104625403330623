import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link } from 'umi';
import {useRequest} from 'ahooks';
import {
  Modal,
  Radio,
  Space,
  Popover,
  InputNumber,
  Table,
  Row,
  Col,
  Button,
  Tag,
  DatePicker,
  Card,
  Checkbox, Input, message,
  Tabs
} from 'antd';

const { TabPane } = Tabs;

import {  ExclamationCircleOutlined } from '@ant-design/icons';
import numeral from 'numeral';
import { useAsyncEffect, useSetState } from 'ahooks';
import { useEventEmitter } from 'ahooks';
import MembershipHistory from './MembershipHistory'
import MembershipExtendHistory from './MembershipExtendHistory'
import MembershipInfo from './MembershipInfo'
import ScheduleAppointment from '@/components/ScheduleAppointment';
import Filters from '@/components/Filters';
import {
  srvPatientMembershipFee,
  srvSavePatientCustomPrice,
  srvCancelPatientMembership,
  srvResumePatientMembership,
  srvPatientFirstMembership,
  srvActivatePatientMembership,
  srvPatientCurrentMembership,
  srvPatientLastMembership,
  srvPatientCurrentTherapySubscription,
  srvPatientCurrentCoachingSubscription,
  srvPausePatientMembership, srvPatientNextPausedMembership,
  srvGetPatientCancelMoreYearFlag,
} from '@/services/admin';

import styles from './index.less';
import {formatUnix} from "@/utils/xtime";
const cx = cn.bind(styles);

const reasonOptions = [
  'My symptoms are gone.',
  'I am going to see another provider elsewhere.',
  "I am moving to another region. Done. doesn't provide service there.",
  'I am traveling for a while.',
  'I have enough medication.',
  'Other (please explain below)',
].map((item) => ({ label: item, value: item }));

const reasonDeactivateOptions = [
  {label:'Discharge Patient',value:'Discharge Patient'
  },
  {label:'Problem scheduling an appointment',value:'Problem scheduling an appointment',secondaryReasons:
  [
    {label:'Appointment was canceled or moved',value:'My appointment was canceled or moved'},
    {label:'Had trouble finding the office for my in-person location',value:'I had trouble finding the office for my in-person location'},
    {label:'Provider has low availability for follow-ups',value:'My provider has low availability for follow-ups'},
    {label:'Other (Please specify)',value:'Other (Please specify)'},
  ]},
   {label: 'Customer support/Technical issue',value:'Customer support/Technical issue',secondaryReasons:
   [
     {label:'Have difficulty reaching customer service',value:'I have difficulty reaching customer service'},
     {label:'Customer service was not effective',value:'Customer service was not effective'},
     {label:'The system is hard to use',value:'The system is hard to use'},
     {label:'Appointment audio/video quality ',value:'Appointment audio/video quality'},
     {label:'Other (Please specify)',value:'Other (Please specify)'},
   ]},
  {label: 'Insurance coverage',value:'Insurance coverage'},
  {label: 'No longer need Done',value:'I no longer need Done',secondaryReasons:
  [
    {label:'Prefer in-person appointments over telehealth',value:'I Prefer in-person appointments over telehealth'},
    {label:'Want to switch to another Telehealth company',value:'I want to switch to another Telehealth company'},
    {label:'Want to switch to my primary care doctor',value:'I want to switch to my primary care doctor'},
    {label:'Want to switch to mental health specialist or private practice',value:'I want to switch to mental health specialist or private practice'},
    {label:'ADHD symptoms are gone',value:'My ADHD symptoms are gone'},
    {label:"Haven/'t seen any results from my treatment",value:"I haven/'t seen any results from my treatment"},
    {label:'Have more than enough medication',value:'I have more than enough medication'},
    {label:'Other (Please specify)',value:'Other (Please specify)'},
  ]},
  {label: 'The service did not meet the expectations',value:'The service did not meet my expectations',secondaryReasons:
  [
    {label:'Do not want to be charged by a monthly fee',value:'I do not want to be charged by a monthly fee'},
    {label:'Prefer a therapist rather than a prescriber',value:'I prefer a therapist rather than a prescriber'},
    {label:'Other (Please specify)',value:'Other (Please specify)'},
  ]},
  {label: 'No longer able to afford Done',value:'I’m no longer able to afford Done',secondaryReasons:
  [
    {label:'Found a cheaper alternative',value:'I’ve found a cheaper alternative'},
    {label:'Experiencing financial hardship',value:'I’m experiencing financial hardship'},
    {label:'Other (Please specify)',value:'Other (Please specify)'},
  ]},
  {label: 'Problems getting prescription',value:'Problems getting my prescription',secondaryReasons:
  [
    {label:'The pharmacy denied / refused to fill the prescription',value:'The pharmacy denied / refused to fill my prescription'},
    {label:'Was told there is a shortage of the medication prescribed',value:'I was told there is a shortage of the medication I was prescribed'},
    {label:"Provider didn/'t refill my medication in a timely manner",value:"My provider didn/'t refill my medication in a timely manner"},
    {label:'Other (Please specify)',value:'Other (Please specify)'},
  ]},
  {label: 'Unhappy with the provider',value:'I’m unhappy with my provider',secondaryReasons:
  [
    {label:"Did not agree with the provider/'s treatment plan or their choice of medication",value:"Did not agree with the provider/'s treatment plan or their choice of medication"},
    {label:"Provider didn/'t refill in a timely manner",value:"Provider didn/'t refill in a timely manner"},
    {label:"Provider was unprofessional, or was dismissive of the patient/'s concerns",value:"Provider was unprofessional, or was dismissive of the patient/'s concerns"},
    {label:'Other (Please specify)',value:'Other (Please specify)'},
  ]},
  {label: 'Moving to another state',value:'I’m moving to another state'},
  {label: 'Other (Please specify)',value:'Other (Please specify)'},
];

const filters = [
  {
    name: 'membership_type',
    label: 'Type',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'status',
    label: 'Status',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'begin_date',
    label: 'Begin Date',
    type: 'date',
  },
  {
    name: 'end_date',
    label: 'End Date',
    type: 'date',
  },
];

function getMembershipSinceDate(firstMembership) {
  if (firstMembership.begin_date !== undefined) {
    return firstMembership.begin_date
  }
}

const Memberships = (props) => {
  const { active, id, detail, memberships, membership_statuses, membership_types, loadingMemberships,membershipExtendHistorys,loadMembershipExtendHistorys} = props;
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [extendPage, setExtendPage] = useState(1);
  const [therapySubscription, setTherapySubscription] = useState({});
  const [coachingSubscription, setCoachingSubscription] = useState({});
  const [membership, setMembership] = useState({});
  const [lastMembership, setLastMembership] = useState({});
  const [membershipFee, setMembershipFee] = useState(0);
  const [customPrice, setCustomPrice] = useState(0);
  const [priceInput, setPriceInput] = useState(false)
  const reset$ = useEventEmitter();
  const [showMembershipHistory, setShowMembershipHistory] = useState(false)
  const [firstMembership, setFirstMembership] = useState({})
  const [state, setState] = useSetState({isModalOpen: false, reason: [], otherReason: '', pausedTips: null,isOpenMoreYearModal: false,isOpenLessYearModal: false,isChargeFailModal: false})
  const [deactivateState, setDeactivateState] = useSetState({isModalOpen: false, reason: '',secondaryReason: '', otherReason: '',})
  const [selectedOption, setSelectedOption] = useState({label:'',value:''});
  const [selectedSecondaryOption, setSelectedSecondaryOption] = useState({label:'',value:''});
  const radioValueRef = useRef("0");
  const [isSecondaryOption, setIsSecondaryOption] = useState(false)
  const [membershipPrice, setMembershipPrice] = useState("");
  const [adjustmentReason, setAdjustmentReason] = useState("");
  const [showAdjustmentReason, setShowAdjustmentReason] = useState(false)
  const [membershipPriceRadio,setMembershipPriceRadio]=useState("");
  const [showScheduleAppointment,setShowScheduleAppointment]= useState(false);
  const [resumeFlag,setResumeFlag]=useState(false);
  const [activeKey, setActiveKey] = useState('1');

  const loadMembership = async () => {
    try {
      const res = await srvPatientCurrentMembership({ id });
      if (res.success) {
        setMembership(res.data);
        getPausedNextMembership()
      }
      const last = await srvPatientLastMembership({id});
      if (last.success) {
        setLastMembership(last.data);
      }
    } catch (err) {}
  };

  useAsyncEffect(async () => {
    if(active) {
      await loadMembership();
      srvPatientMembershipFee({id}).then((res) => {
        if (res.code === 200) {
          setMembershipFee(res.data)
        }
      })
      srvPatientFirstMembership({id}).then((res) => {
        if (res.code === 200 && res.data != null) {
          setFirstMembership(res.data)
        }
      })
      srvPatientCurrentTherapySubscription({ id }).then((res) => {
        if (res.code === 200 && res.data != null) {
          setTherapySubscription(res.data)
        }
      })
      srvPatientCurrentCoachingSubscription({ id }).then((res) => {
        if (res.code === 200 && res.data != null) {
          setCoachingSubscription(res.data)
        }
      })
    }
  },[active]);

  async function getPausedNextMembership() {
    srvPatientNextPausedMembership({ id }).then(({data,code})=>{
      if (code === 200 && data?.begin_date) {
        setState({pausedTips: `Pause scheduled on ${formatUnix(data.begin_date,"MMMM DD, YYYY")}`  })
      } else {
        setState({pausedTips: null})
      }
    }).catch((err)=>{
      setState({pausedTips: null})
    });
  }

  function handleOk() {
    const reason = state.reason
    if(!!state.otherReason) {
      reason.push(state.otherReason)
    }
    if (reason.length === 0) {
      message.info("Please select a reason")
      return
    }
    srvPausePatientMembership({ id, reason: reason.join(' ') }).then((res)=>{
      if (res.code === 200) {
        setState({reason: [], otherReason: ''})
        toggleModal()
        loadMembership()
      }
    })
  }

  function toggleModal() {
    setState({isModalOpen: !state.isModalOpen})
  }


  function toggleDeactivateModal() {
    setDeactivateState({isModalOpen: !deactivateState.isModalOpen})
  }

  const cancelMembership = async () => {
    let reason = deactivateState.reason
    if(!!deactivateState.secondaryReason) {
      reason = reason+' - '+deactivateState.secondaryReason;
    }
    if(!!deactivateState.otherReason) {
      reason = reason+' - ' +deactivateState.otherReason;
    }

    Modal.confirm({
      title: "Do you want to deactivate this patient's membership?",
      icon: <ExclamationCircleOutlined />,
      onOk: async function () {
        try {
          const res = await srvCancelPatientMembership({ id,reason: reason});
          if (res.success) {
            toggleDeactivateModal();
            await loadMembership(res.data);
            
            reset$.emit();
            
          }
        } catch (err) {}
      },
      onCancel() {},
    });
  };

  const resumeMembership = async () => {
    if (detail.subscription_type === 2 && detail.subscription_end_date > new Date().getTime()/1000 + 2*24*3600) {
      Modal.confirm({
        title: "Do you want to resume this patient's membership?",
        icon: <ExclamationCircleOutlined />,
        onOk: async function () {
          try {
            const res = await srvResumePatientMembership({ id });
            if (res.success) {
              await loadMembership(res.data);
              reset$.emit();
            }
          } catch (err) { }
        },
        onCancel() { },
      });
      return
    }
    setResumeFlag(true);
    setMembershipPrice(7900);
    setMembershipPriceRadio(7900);
    setState({ isOpenLessYearModal: true });
  };

  function onRadioChange(e) {
    radioValueRef.current = e.target.value;
  }

  const activateMembership = async () => {
    getCancelMoreYearFlagReq.run({id})
  };
  const getCancelMoreYearFlagReq = useRequest(srvGetPatientCancelMoreYearFlag, {
    manual: true,
    onSuccess: (ret) => {
      setResumeFlag(false);
      if (ret.data) {
        setMembershipPriceRadio(9900);
        setMembershipPrice(9900);
        setState({ isOpenMoreYearModal: true });
      } else {
        setMembershipPrice(7900);
        setMembershipPriceRadio(7900);
        setState({ isOpenLessYearModal: true });
      }
    },
  });
  const changeMemberPrice = (e) => {
    setMembershipPriceRadio(e.target.value);
    if (e.target.value === 'Custom') {
      setShowAdjustmentReason(true);
    }else{
      setMembershipPrice(Number(Number(e.target.value).toFixed()));
      setShowAdjustmentReason(false);
    }
  }
  const submitReactiveMembership=()=>{
    if(membershipPriceRadio===''){
      message.warning("Please select membership fee.")
      return;
    }
    if(membershipPriceRadio==='Custom'){
      if(membershipPrice===''){
        message.warning("Please input membership fee.");
        return;
      }
      if(adjustmentReason===''){
        message.warning("Please add note.");
        return;
      }
    }
    if(resumeFlag){
      resumeMembershipReq.run({ id,membership_price: parseInt(membershipPrice),reason: adjustmentReason,patient_detail_reactive_flag: true,charged:true });
      return;
    }
    reactiveReq.run({ id,membership_price: parseInt(membershipPrice),reason: adjustmentReason,patient_detail_reactive_flag: true });
  }
  const validBeforeSchedule=()=>{
    if(membershipPriceRadio===''){
      message.warning("Please select membership fee(includes re-initial appointment).")
      return;
    }
    if(membershipPriceRadio==='Custom'){
      if(membershipPrice===''){
        message.warning("Please input membership fee(includes re-initial appointment).");
        return;
      }
      if(adjustmentReason===''){
        message.warning("Please add note.");
        return;
      }
    }
    setShowScheduleAppointment(true);
  }
  const reactiveReq = useRequest(srvActivatePatientMembership, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.data) {
        if (ret.data.success) {
          if(state.isOpenMoreYearModal){
            loadMembership();
            setState({ isOpenMoreYearModal: false });
            setShowScheduleAppointment(true);
            setShowAdjustmentReason(false);
          }
          if(state.isOpenLessYearModal){
            setState({ isOpenLessYearModal: false });
            setShowAdjustmentReason(false);
            loadMembership();
            reset$.emit();
          }
        } else {
          setState({ isOpenMoreYearModal: false });
          setState({ isOpenLessYearModal: false });
          setShowAdjustmentReason(false);
          setState({ isChargeFailModal: true });
        }
      }
    },
  });
  const resumeMembershipReq = useRequest(srvResumePatientMembership, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.data) {
        if (ret.data.success) {
          if(state.isOpenLessYearModal){
            setState({ isOpenLessYearModal: false });
            setShowAdjustmentReason(false);
            loadMembership();
            reset$.emit();
            if(detail.subscription_type===2){
              location.reload();
            }
          }
        } else {
          setState({ isOpenMoreYearModal: false });
          setState({ isOpenLessYearModal: false });
          setShowAdjustmentReason(false);
          setState({ isChargeFailModal: true });
        }
      }
    },
  });
  useEffect(() => {
    loadMembership();
    setPage(1);
    setExtendPage(1);
    props.loadMemberships({ page: 1, page_size: 20 }, () => {});
    props.loadMembershipTypes();
    props.loadMembershipStatuses();
    props.loadMembershipExtendHistorys({ page: 1, page_size: 20 }, () => {});
  }, [active, id]);

  /*
  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    props.loadMemberships({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => {});
  };*/

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    props.loadMemberships(
      { page: pagination.current, page_size: pagination.pageSize, filters: JSON.stringify(query) },
      () => {},
    );
  };
  const handleExtendTableChange = (pagination, filters, sorter) => {
    setExtendPage(pagination.current);
    props.loadMembershipExtendHistorys(
      { page: pagination.current, page_size: pagination.pageSize, filters: JSON.stringify(query) },
      () => {},
    );
  };

  const toggleShowMembershipHistory = () => {
    setShowMembershipHistory(!showMembershipHistory)
  }

  const f = () => {
    for (const v of filters) {
      if (v.name === 'membership_type' && v.items.length === 0) {
        v.items = membership_types;
        continue;
      }
      if (v.name === 'status' && v.items.length === 0) {
        v.items = membership_statuses;
        continue;
      }
    }
    return filters;
  };

  const onChangeCustomerPrice = (value) => {
    setCustomPrice(value)
  }
  
  const isOkButtonDisabled = () => {
    if (
      (selectedOption.value === 'Other (Please specify)' ||
        selectedSecondaryOption.value === 'Other (Please specify)') &&
      deactivateState.otherReason === ''
    ) {
      return true;
    }

    if (selectedOption.value && !isSecondaryOption) {
      return false;
    }
    if (isSecondaryOption && selectedSecondaryOption.value) {
      return false;
    }

    return true;
  };
  const saveCustomePrice = () => {
    if (customPrice !== 0) {
      let data = {}
      data.subscription_fee = customPrice
      data.subscription_type = detail.subscription_type
      data.id = detail.id
      srvSavePatientCustomPrice(data).then((res)=>{
        //setMembershipEndDate(newEndDate)
        //setDateInput(false)
        if (res.code === 200) {
          setPriceInput(false)
          setMembershipFee(customPrice)
        }
      })
    } else {
      setPriceInput(false)
    }
  }

  const onTabChange = (key) => {
    setActiveKey(key);
  }

  let membershipSince = getMembershipSinceDate(firstMembership)
  let membershipHistoryExisted = (memberships.items.length > 0) ? true : false
  return (
    <div className={cx({ subcontainer: true })}>
      <div className={cx({ header: true, noBottomBorder: true })}>
        <h3>Membership</h3>
        {/*
        <Space>

          <Filters reset$={reset$} filters={f()} onFilter={onFilter} />
        </Space>
        */}
      </div>
      <Tabs defaultActiveKey="1" activeKey={activeKey} onTabClick={onTabChange}>
        <TabPane tab="Medication" key="1">
          {active && (
            <MembershipInfo
              therapySubscription={therapySubscription}
              membership={membership}
              lastMembership={lastMembership}
              detail={detail}
              pausedTips={state.pausedTips}
              toggleShowMembershipHistory={toggleShowMembershipHistory}
              pauseMembership={toggleModal}
              cancelMembership={toggleDeactivateModal}
              resumeMembership={resumeMembership}
              activateMembership={activateMembership}
              showMembershipHistory={showMembershipHistory}
              membershipSince={membershipSince}
              membershipHistoryExisted={membershipHistoryExisted}
              membershipFee={membershipFee}
              onChangeCustomerPrice={onChangeCustomerPrice}
              saveCustomePrice={saveCustomePrice}
              customPrice={customPrice}
              priceInput={priceInput}
              setPriceInput={setPriceInput}
            />
          )}

          {showMembershipHistory ? (
            <>
            <div className={cx({ subcontainer: true })}>
              <h3 className={cx({ membershipSubHeader: true })}>Membership History</h3>
              <MembershipHistory
                memberships={memberships.items}
                loading={loadingMemberships}
                onChange={handleTableChange}
                pagination={{ pageSize: 20, current: page, simple: true, total: memberships.total }}
              />
            </div>
                    <div className={cx({ subcontainer: true })}>
                    <h3 className={cx({ membershipSubHeader: true })}>Membership Extend History</h3>
                    <MembershipExtendHistory
                MembershipExtendHistorys={membershipExtendHistorys.items}
                loading={loadMembershipExtendHistorys}
                onChange={handleExtendTableChange}
                pagination={{ pageSize: 20, current: extendPage, simple: true, total: membershipExtendHistorys.total }}
              />
                  </div>
                  </>
          ) : (
            <></>
          )}          
        </TabPane>
        <TabPane tab="Therapy" key="2">
          {therapySubscription && therapySubscription.status === 2 && (
            <>
              <hr style={{ marginTop: "40px", marginBottom: "16px" }} />
              <h3 className={cx({ membershipSubHeader: true })}>Therapy Membership Status</h3>
              <div className={cx({ membereshipItem: true })}>
                <div className={cx({ leftItem: true })}> Current Membership Cycle </div>
                <div className={cx({ rightItem: true, itemContent: true })}> {formatUnix(therapySubscription.start_time, "MMM DD, YYYY")} - {formatUnix(therapySubscription.end_time, "MMM DD, YYYY")} </div>
              </div>
              <div className={cx({ membereshipItem: true })}>
                <div className={cx({ leftItem: true })}> Remaining Session This Cycle </div>
                <div className={cx({ rightItem: true, itemContent: true })}> {therapySubscription.sessions_remaining} / {therapySubscription.sessions_total} </div>
              </div>
            </>
          )}
        </TabPane>
        <TabPane tab="Coaching" key="3">
          {coachingSubscription && coachingSubscription.status === 2 && (
            <>
              <hr style={{ marginTop: "40px", marginBottom: "16px" }} />
              <h3 className={cx({ membershipSubHeader: true })}>Therapy Membership Status</h3>
              <div className={cx({ membereshipItem: true })}>
                <div className={cx({ leftItem: true })}> Current Membership Cycle </div>
                <div className={cx({ rightItem: true, itemContent: true })}> {formatUnix(coachingSubscription.start_time, "MMM DD, YYYY")} - {formatUnix(coachingSubscription.end_time, "MMM DD, YYYY")} </div>
              </div>
              <div className={cx({ membereshipItem: true })}>
                <div className={cx({ leftItem: true })}> Remaining Session This Cycle </div>
                <div className={cx({ rightItem: true, itemContent: true })}> {coachingSubscription.sessions_remaining} / {coachingSubscription.sessions_total} </div>
              </div>
            </>
          )}
        </TabPane>
      </Tabs>

      <Modal
        title="Membership pause scheduled "
        visible={state.isModalOpen}
        onOk={handleOk}
        onCancel={toggleModal}
        width={600}
      >
        <Checkbox.Group
          value={state.reason}
          style={{ display: 'flex', flexDirection: 'column' }}
          onChange={(v) => {
            setState({ reason: v });
          }}
          options={reasonOptions}
        />
        <Input.TextArea
          value={state.otherReason}
          onChange={(e) => setState({ otherReason: e.target.value })}
          placeholder="I would like to pause my membership because..."
          autoSize={{ minRows: 3, maxRows: 10 }}
          style={{ marginTop: '20px' }}
        />
      </Modal>

      <Modal
        title="Deactivate Membership "
        visible={deactivateState.isModalOpen}
        onOk={cancelMembership}
        onCancel={toggleDeactivateModal}
        width={600}
        okButtonProps={{ disabled: isOkButtonDisabled() }}
        okText="Confirm"
      >
        <Radio.Group
          value={selectedOption.value}
          style={{ display: 'flex', flexDirection: 'column' }}
          onChange={(v) => {
            const selectedValue = v.target.value;
            const selectedOption = reasonDeactivateOptions.find(
              (option) => option.value === selectedValue,
            );
            setIsSecondaryOption(selectedOption.secondaryReasons !== undefined);
            setSelectedOption(selectedOption);
            setSelectedSecondaryOption({ label: '', value: '' });
            setDeactivateState({ reason: selectedValue, secondaryReason: '' });
          }}
        >
          {reasonDeactivateOptions.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
              {option.secondaryReasons && selectedOption.value === option.value && (
                <Radio.Group
                  value={selectedSecondaryOption.value}
                  style={{ display: 'flex', flexDirection: 'column', marginLeft: 24, marginTop: 8 }}
                  onChange={(v) => {
                    const selectedValue = v.target.value;
                    const selectedOption = option.secondaryReasons.find(
                      (option) => option.value === selectedValue,
                    );

                    setSelectedSecondaryOption(selectedOption);
                    deactivateState.secondaryReason = [selectedValue];
                  }}
                >
                  {option.secondaryReasons.map((opt) => (
                    <Radio key={opt.value} value={opt.value}>
                      {opt.label}
                    </Radio>
                  ))}
                </Radio.Group>
              )}
            </Radio>
          ))}
        </Radio.Group>
        {(selectedOption.value === 'Other (Please specify)' || selectedSecondaryOption.value==='Other (Please specify)') && (
          <Input.TextArea
            value={deactivateState.otherReason}
            onChange={(e) => setDeactivateState({ otherReason: e.target.value })}
            placeholder="Reason for deactivation"
            autoSize={{ minRows: 3, maxRows: 10 }}
            style={{ marginTop: '20px' }}
          />
        )}
      </Modal>
      <Modal
        visible={state.isOpenLessYearModal}
        className={cx({ reactiveMembership: true })}
        onCancel={() => {
          setState({ isOpenLessYearModal: false });
          setResumeFlag(false);
          setShowAdjustmentReason(false);
        }}
        destroyOnClose={true}
        footer={null}
      >
        <p
          style={{
            color: '#000000',
            fontSize: '16px',
            fontWeight: '600',
            marginBottom: '20px',
            paddingLeft: '24px',
            paddingRight: '24px',
          }}
        >
          Do you want to activate this patient’s membership?
        </p>
        <p style={{
          color: '#000000 80%', marginBottom: '20px', paddingLeft: '24px',
          paddingRight: '24px'
        }}>
          Please select the monthly membership fee for the first month. If you select 0, we will charge the patient $1 and return it to validate the payment method.
        </p>
        <div style={{
          backgroundColor: '#F8FAFC', paddingLeft: '24px',
          paddingRight: '24px', paddingTop: '20px', paddingBottom: '20px'
        }} >
          <p style={{ marginBottom: '10px' }}>Membership Fee:</p>
          <Space>
            <Radio.Group onChange={changeMemberPrice} defaultValue={'7900'}>
              <Radio value="7900">79</Radio>
              <Radio value="3950">39.5</Radio>
              <Radio value="0">0</Radio>
              <Radio value="Custom">Custom</Radio>
            </Radio.Group>
            {showAdjustmentReason && (<InputNumber min={0} onChange={(value) => { setMembershipPrice(Number(Number(value * 100).toFixed())); }}
              max={300} style={{ width: '120px', borderRadius: '4px', borderColor: '#D4D4D8' }}
            />)}
          </Space>
          {showAdjustmentReason && (<>
            <p style={{ marginBottom: '7px', marginTop: '5px' }}>Please enter the reason for the adjustment:</p>
            <Input.TextArea
              onChange={(event) => { setAdjustmentReason(event.target.value); }}
              style={{ borderRadius: '2px' }}
              rows={4}
              placeholder="Add notes here."
              maxLength={20000}
            />
          </>)}
        </div>
        <div style={{
          display: 'flex', justifyContent: 'space-between', color: '#000000', marginTop: '20px', paddingLeft: '24px',
          paddingRight: '24px'
        }}>
          <p onClick={() => { setState({ isOpenLessYearModal: false });setResumeFlag(false);setShowAdjustmentReason(false); }}
            style={{ color: '#566BCD', cursor: 'pointer' }}
          >
            Cancel
          </p>
          <Button
            key="confirm"
            style={{ width: '180px', borderRadius: '4px' }}
            onClick={() => {
              submitReactiveMembership();
            }}
            type="primary"
            loading={reactiveReq.loading}
          >
            Confirm
          </Button>
        </div>
      </Modal>
      <Modal
        visible={state.isOpenMoreYearModal}
        className={cx({ reactiveMembership: true })}
        onCancel={() => {
          setState({ isOpenMoreYearModal: false });
        }}
        footer={null}
      >
        <p
          style={{
            color: '#000000',
            fontSize: '16px',
            fontWeight: '600',
            marginBottom: '20px',
            paddingLeft: '24px',
            paddingRight: '24px',
          }}
        >
          Do you want to activate this patient’s membership?
        </p>
        <p style={{
          color: '#000000 80%', marginBottom: '20px', paddingLeft: '24px',
          paddingRight: '24px'
        }}>
          The patient's membership has been inactive for over 12 months, a re-initial appointment is required. Please schedule an appointment for the patient.        </p>
        <div style={{
          backgroundColor: '#F8FAFC', paddingLeft: '24px',
          paddingRight: '24px', paddingTop: '20px', paddingBottom: '20px'
        }} >
          <p style={{ marginBottom: '10px' }}>Membership Fee(includes re-initial appointment):</p>
          <Space>
            <Radio.Group onChange={changeMemberPrice} defaultValue={'9900'}>
              <Radio value="9900">99</Radio>
              <Radio value="Custom">Custom</Radio>
            </Radio.Group>
            {membershipPriceRadio === 'Custom' && (<InputNumber onChange={(value) => { setMembershipPrice(Number(Number(value * 100).toFixed()));  }}
              min={0} max={300} style={{ width: '120px', borderRadius: '4px', borderColor: '#D4D4D8' }}
            />)}
          </Space>
          {showAdjustmentReason && (<>
            <p style={{ marginBottom: '7px', marginTop: '5px' }}>Please enter the reason for the adjustment:</p>
            <Input.TextArea
              onChange={(event) => { setAdjustmentReason(event.target.value); }}
              style={{ borderRadius: '2px' }}
              rows={4}
              placeholder="Add notes here."
              maxLength={20000}
            />
          </>)}

        </div>
        <div style={{
          display: 'flex', justifyContent: 'space-between', color: '#000000', marginTop: '20px', paddingLeft: '24px',
          paddingRight: '24px'
        }}>
          <p onClick={() => { setState({ isOpenMoreYearModal: false }); }}
            style={{ color: '#566BCD', cursor: 'pointer' }}
          >
            Cancel
          </p>
          <Button
            key="confirm"
            style={{ width: '250px', borderRadius: '4px' }}
            onClick={() => {
              validBeforeSchedule();
            }}
            type="primary"
          >
            Confirm and Go to Schedule
          </Button>
        </div>
      </Modal>
      <Modal
        visible={state.isChargeFailModal}
        onCancel={() => {
          setState({ isChargeFailModal: false });
        }}
        footer={null}
      >
        <p
          style={{
            color: '#000000',
            fontSize: '16px',
            fontWeight: '600',
            marginBottom: '20px',
          }}
        >
          The charge failed.
        </p>
        <p style={{
          color: '#000000 80%', marginBottom: '20px'
        }}>
          Please inform the patient to update the payment method and try again.</p>
        <div style={{
          display: 'flex', justifyContent: 'space-between', color: '#000000', marginTop: '20px'
        }}>
          <p>
          </p>
          <Button
            key="confirm"
            style={{ width: '250px', borderRadius: '4px' }}
            onClick={() => {
              setState({ isChargeFailModal: false });
            }}
            type="primary"
          >
           OK
          </Button>
        </div>
      </Modal>
      {showScheduleAppointment && (
        <ScheduleAppointment
          visible={showScheduleAppointment}
          id={id}
          detail={detail}
          active={active}
          customerFee={parseInt(membershipPrice)}
          adjustmentReason={adjustmentReason}
          onClose={() => {
            setShowScheduleAppointment(false);
            setState({ isOpenMoreYearModal: false });
            loadMembership();
            reset$.emit();
          }}
          showChargeFailModal={() => { setState({ isChargeFailModal: true }); }}
          reInitialFlag={true}
        />
      )}

    </div>
  );
};

export default Memberships;
