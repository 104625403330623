import React, { useEffect, useState, useRef } from 'react';
import Icon, { createFromIconfontCN } from '@ant-design/icons';
import { Menu } from 'antd';
import classNames from 'classnames';
import useMergeValue from 'use-merge-value';

import { isUrl, getOpenKeysFromMenuData } from '@/utils/utils';
import MenuCounter from './Counter';
import { getSelectedMenuKeys } from './SiderMenuUtils';
import defaultSettings from '../../../../defaultSettings';
import './index.less';

let firstConsole = true;

const { SubMenu } = Menu;

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const getIcon = (icon) => {
  if (typeof icon === 'string' && icon !== '') {
    if (isUrl(icon)) {
      return (
        <Icon component={() => <img src={icon} alt="icon" className="aster-sider-menu-icon" />} />
      );
    }
    if (icon.startsWith('icon-')) {
      return <IconFont type={icon} />;
    }
    if (firstConsole) {
      firstConsole = false;
    }
  }
  return icon;
};

class MenuUtil {
  constructor(props) {
    this.props = props;
  }

  getNavMenuItems = (menusData) =>
    menusData
      .filter((item) => item.name && !item.hideInMenu)
      .map((item) => this.getSubMenuOrItem(item))
      .filter((item) => item);

  getSubMenuOrItem = (item) => {
    if (
      Array.isArray(item.children) &&
      !item.hideChildrenInMenu &&
      item.children.some((child) => child && !!child.name)
    ) {
      const { name } = item;
      const { subMenuItemRender } = this.props;

      //  get defaultTitle by menuItemRender
      const defaultTitle = item.icon ? (
        <span>
          {getIcon(item.icon)}
          <span>{name}</span>
        </span>
      ) : (
        name
      );

      // subMenu only title render
      const title = subMenuItemRender
        ? subMenuItemRender({ ...item, isUrl: false }, defaultTitle)
        : defaultTitle;

      return (
        <SubMenu title={title} key={item.key || item.path} onTitleClick={item.onTitleClick}>
          {this.getNavMenuItems(item.children)}
        </SubMenu>
      );
    }
    return <Menu.Item key={item.key || item.path}>{this.getMenuItemPath(item)}</Menu.Item>;
  };

  /**
   * Judge whether it is http link.return a or Link
   * @memberof SiderMenu
   */
  getMenuItemPath = (item) => {
    const itemPath = this.conversionPath(item.path || '/');
    const icon = getIcon(item.icon);
    const { location = { pathname: '/' }, onCollapse, menuItemRender } = this.props;
    const { target } = item;
    // if local is true formatMessage all name。
    const { name } = item;
    let defaultItem = (
      <>
        {icon}
        <span>{name}</span>
      </>
    );
    const isHttpUrl = isUrl(itemPath);
    // Is it a http link
    if (isHttpUrl) {
      defaultItem = (
        <a href={itemPath} target={target}>
          {icon} <span>{name}</span>
        </a>
      );
    }

    if (menuItemRender) {
      return menuItemRender(
        {
          ...item,
          isUrl: isHttpUrl,
          itemPath,
          replace: itemPath === location.pathname,
          onClick: () => onCollapse && onCollapse(true),
        },
        defaultItem,
      );
    }
    return defaultItem;
  };

  conversionPath = (path) => {
    if (path && path.indexOf('http') === 0) {
      return path;
    }
    return `/${path || ''}`.replace(/\/+/g, '/');
  };
}

const getOpenKeysProps = (openKeys = [], { layout, collapsed }) => {
  let openKeysProps = {};
  if (openKeys && !collapsed && layout === 'sidemenu') {
    openKeysProps = {
      openKeys,
    };
  }
  return openKeysProps;
};

const BaseMenu = (props) => {
  const {
    theme,
    mode,
    location = {
      pathname: '/',
    },
    className,
    handleOpenChange,
    style,
    menuData,
    menu = { locale: true },
    iconfontUrl,
    selectedKeys: propsSelectedKeys,
    onSelect,
    openKeys: propsOpenKeys,
  } = props;
  const defaultOpenKeysRef = useRef([]);

  const { pathname } = location;

  const { flatMenuKeys, flatMenus } = MenuCounter.useContainer();
  const [defaultOpenAll, setDefaultOpenAll] = useState(menu.defaultOpenAll);

  const [openKeys, setOpenKeys] = useMergeValue(
    () => {
      if (menu.defaultOpenAll) {
        return getOpenKeysFromMenuData(menuData) || [];
      }
      if (propsOpenKeys === false) {
        return false;
      }
      return [];
    },
    {
      value: propsOpenKeys === false ? undefined : propsOpenKeys,
      onChange: (keys) => {
        if (JSON.stringify(keys) !== JSON.stringify(openKeys)) {
          handleOpenChange?.(keys);
        }
      },
    },
  );

  useEffect(() => {
    if (!flatMenus || flatMenuKeys.length === 0) {
      return;
    }
    if (menu.defaultOpenAll || propsOpenKeys === false) {
      return;
    }
    const keys = getSelectedMenuKeys(location.pathname || '/', flatMenus, flatMenuKeys || []);
    setOpenKeys(keys);
  }, [flatMenus, flatMenuKeys.join('-')]);

  const [selectedKeys, setSelectedKeys] = useMergeValue([], {
    value: propsSelectedKeys,
    onChange: onSelect
      ? (keys) => {
          if (onSelect && keys) {
            onSelect(keys);
          }
        }
      : undefined,
  });

  useEffect(() => {
    // reset IconFont
    if (iconfontUrl) {
      IconFont = createFromIconfontCN({
        scriptUrl: iconfontUrl,
      });
    }
  }, [iconfontUrl]);

  useEffect(() => {
    if (!flatMenus || flatMenuKeys.length === 0) {
      return () => null;
    }

    // Only update if keys have actually changed
    const keys = getSelectedMenuKeys(pathname || '/', flatMenus, flatMenuKeys || []);
    if (JSON.stringify(keys) === JSON.stringify(selectedKeys)) {
      return;
    }

    // Throttle updates using setTimeout
    const timeoutId = setTimeout(() => {
      setSelectedKeys(keys);
      if (!defaultOpenAll && propsOpenKeys !== false) {
        setOpenKeys(keys);
      } else {
        setDefaultOpenAll(false);
      }
    }, 100); // 100ms delay to prevent rapid updates

    return () => {
      clearTimeout(timeoutId);
    };
  }, [pathname, flatMenuKeys.join('-')]);

  const openKeysProps = getOpenKeysProps(openKeys, props);
  const cls = classNames(className, {
    'top-nav-menu': mode === 'horizontal',
  });
  const menuUtils = new MenuUtil(props);

  const postData = props.postMenuData ? props.postMenuData(menuData) : menuData;

  if (props.openKeys === false && !props.handleOpenChange) {
    const keys = getSelectedMenuKeys(location.pathname || '/', flatMenus, flatMenuKeys || []);
    defaultOpenKeysRef.current = keys;
    if (keys.length < 1) {
      return null;
    }
  }

  return (
    <Menu
      {...openKeysProps}
      key="Menu"
      mode={mode}
      defaultOpenKeys={defaultOpenKeysRef.current}
      theme={theme}
      selectedKeys={selectedKeys}
      style={style}
      className={cls}
      onOpenChange={setOpenKeys}
      {...props.menuProps}
    >
      {menuUtils.getNavMenuItems(postData)}
    </Menu>
  );
};

BaseMenu.defaultProps = {
  postMenuData: (data) => data || [],
};

export default BaseMenu;
