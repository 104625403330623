import React from 'react';
import { connect, history } from 'umi';
import classNames from 'classnames';

import { Avatar, Menu, Spin, Dropdown } from 'antd';
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';

import styles from './AvatarDropdown.less';

const HeaderDropdown = ({ overlayClassName: cls, ...restProps }) => (
  <Dropdown overlayClassName={classNames(styles.container, cls)} {...restProps} />
);

class AvatarDropdown extends React.Component {
  onMenuClick = (event) => {
    const { key } = event;

    if (key === 'logout') {
      const { dispatch } = this.props;

      if (dispatch) {
        dispatch({
          type: 'login/logout',
        });
      }

      return;
    }

    history.push(`/account/${key}`);
  };

  render() {
    // {menu && (
    //   <Menu.Item key="center">
    //     <UserOutlined />
    //     Profile
    //   </Menu.Item>
    // )}

    const {
      currentUser = {
        avatar: '',
        name: '',
      },
      menu,
    } = this.props;
    currentUser.avatar = 'https://storage.googleapis.com/done_api_static/icons/user.svg';
    const menuHeaderDropdown = (
      <Menu className={styles.menu} selectedKeys={[]} onClick={this.onMenuClick}>
        {(menu&&(currentUser.role==='doctor'||currentUser.permissions?.settings_flag)) && (
          <Menu.Item key="settings">
            <SettingOutlined />
            Settings
          </Menu.Item>
        )}
        {menu && <Menu.Divider />}
        <Menu.Item key="logout">
          <LogoutOutlined />
          Logout
        </Menu.Item>
      </Menu>
    );
    const avatarDom =
      currentUser && currentUser.id ? (
        <HeaderDropdown overlay={menuHeaderDropdown}>
          <span className={`${styles.action} ${styles.account}`}>
            <Avatar size="small" className={styles.avatar} src={currentUser.avatar} alt="avatar" />
            <span className={styles.name}>{currentUser.real_name}</span>
          </span>
        </HeaderDropdown>
      ) : (
        <span className={`${styles.action} ${styles.account}`}>
          <Spin
            size="small"
            style={{
              marginLeft: 8,
              marginRight: 8,
            }}
          />
        </span>
      );

    return <div className={styles.right}>{avatarDom}</div>;
  }
}

export default connect(({ account }) => ({
  currentUser: account.currentUser,
}))(AvatarDropdown);
