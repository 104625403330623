import React, { useState, useRef, useEffect } from 'react';
import { Button, Spin, Drawer, Slider, Input, Checkbox, Select, Radio, Form, Row, Col } from 'antd';
import cn from 'classnames/bind';
import numeral from 'numeral';
import { AsYouType } from 'libphonenumber-js';
import { useRequest, useMount, useUpdate, useUpdateEffect, useSetState } from 'ahooks';
import zippo from 'zippo';

import { srvRXNTPharmacyList, srvRXNTPharmacyFuzzySearch, srvStates } from '@/services/admin';
import styles from './RecommendedList.less';
const cx = cn.bind(styles);

const maxItems = '1000';

const EditPharmacyRecommendedDrawer = (props) => {
  const { visible, loading, initialValues } = props;
  const [form] = Form.useForm();

  const zipcodeRef = useRef(null);
  const filterRef = useRef(null)
  const nameRef = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const addressRef = useRef(null);
  const phoneRef = useRef(null);
  const faxRef = useRef(null);
  const websiteRef = useRef(null);
  const adminNoteRef = useRef(null);
  const patientNoteRef = useRef(null);
  const [states, setStates] = useState([]);
  const [selectedPharmacies, setSelectedPharmacies] = useState({});
  const [stateWideDelivery, setStateWideDelivery] = useState(false);
  const [filter, setFilter] = useState('');
  const [RXNTPharmacy, setRXNTPharmacy] = useState({
    totalPharmacies: 0,
    pharmacies: [],
  });
  const [data, setData] = useSetState({
    id: initialValues.id,
    rxnt_pharmacy_id: initialValues.rxnt_pharmacy_id,
    admin_note: initialValues.admin_note,
    patient_note: initialValues.patient_note,
    name: initialValues.name,
    zipcode: initialValues.zipcode,
    city: initialValues.city,
    state: initialValues.state,
    address: initialValues.address,
    phone: initialValues.phone,
    fax: initialValues.fax,
    website: initialValues.website,
    is_accepts_insurance: initialValues.is_accepts_insurance,
    is_delivery_available: initialValues.is_delivery_available,
    delivery_wide_states: initialValues.delivery_wide_states ? initialValues.delivery_wide_states.split(',') : [],
    is_only_cash: initialValues.is_only_cash,
    successful_refill_prescription: initialValues.successful_refill_prescription,
    delivery_fee: initialValues.delivery_fee,
    longitude: initialValues.longitude,
    latitude: initialValues.latitude,
  });
  const [isDeliveryAvailable, setIsDeliveryAvailable] = useState(false);
  const forceUpdate = useUpdate();

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        let delivery_wide_states = '';
        if (values.delivery_wide_states && values.delivery_wide_states.length > 0) {
          delivery_wide_states = values.delivery_wide_states.join(',')
        }
        const data = Object.assign({}, values, { delivery_wide_states, id: initialValues?.id, })
        props.onFinish && props.onFinish(data, () => {
        });
      });
  };

  const onValuesChange = (changedValues, allValues) => {
    setData(allValues);
  };

  const onDrawerClose = () => {
    props.onClose && props.onClose();
  };

  const statesReq = useRequest(srvStates, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setStates(ret.data);
      }
    },
  });

  const fuzzySearchPharmacyReq = useRequest(srvRXNTPharmacyFuzzySearch, {
    debounceWait: 500,
    manual: true,
    onSuccess: (ret, params) => {
      setRXNTPharmacy(ret.data);
      setSelectedPharmacies({});
    },
  });

  const listReq = useRequest(srvRXNTPharmacyList, {
    debounceWait: 500,
    manual: true,
    onSuccess: (ret, params) => {
      setRXNTPharmacy(ret.data);
      setSelectedPharmacies({});
    },
  });

  const stateOpts = states.map((v, i) => {
    return (
      <Select.Option key={i} value={v.state}>
        {v.state}
      </Select.Option>
    );
  });

  const onSelect = (pharmacy) => {
    const key = `P${pharmacy.id}`
    if (selectedPharmacies[key]) {
      delete selectedPharmacies[key];
    } else {
      selectedPharmacies[key] = pharmacy;
    }
    form.setFieldsValue({
      zipcode: pharmacy.zipcode,
      name: pharmacy.name,
      city: pharmacy.city,
      state: pharmacy.state,
      address: pharmacy.address1,
      phone: pharmacy.phone,
      fax: pharmacy.fax,
      rxnt_pharmacy_id: pharmacy.pharmacy_id,
    })
    setSelectedPharmacies(selectedPharmacies);
    forceUpdate();
  }

  const loadData = () => {
    const cond = {
      zip: data.zipcode,
      name: data.name,
      city: data.city,
      state: data.state,
      address: data.address,
      phone: data.phone,
      fax: data.fax,
      maxItems,
      admin: props.admin,
    };
    let keys = [];
    Object.keys(cond).forEach(key => {
      if (cond[key]) {
        keys.push(key);
      } else {
        delete cond[key];
      }
    })
    if (keys.some(k => ['zip', 'name', 'city', 'state', 'address', 'phone', 'fax'].includes(k)) && filter == "") {
      listReq.run(cond);
    }
  }

  useMount(async () => {
    await statesReq.runAsync();
  });

  useEffect(() => {
    if (initialValues && initialValues.id) {
      let states = [];
      initialValues.is_delivery_state_wide = false;
      if (initialValues.delivery_wide_states) {
        states = initialValues.delivery_wide_states.split(',');
        initialValues.is_delivery_state_wide = true;
        setStateWideDelivery(true);
      }
      if (initialValues.is_delivery_available){
        setIsDeliveryAvailable(true);
      }
      form.setFieldsValue({ ...initialValues, delivery_wide_states: states })
    } else {
      form.setFieldsValue({ is_accepts_insurance: true })
    }
  }, [initialValues]);

  useEffect(() => {
    if (filter) {
      fuzzySearchPharmacyReq.run({ keyword: filter });
    }
  }, [filter])

  useUpdateEffect(() => {
    loadData();
  }, [data.name, data.city, data.state, data.address, data.phone, data.fax])

  let pharmacies = RXNTPharmacy.pharmacies;

  const listItemsDom = pharmacies.map((v) => {
    const val = numeral(v.distance).format('0.00');
    return (
      <div
        key={v.id}
        className={cx({ item: true, rowSelected: selectedPharmacies && Object.keys(selectedPharmacies).includes(`P${v.id}`) })}
        onClick={() => { onSelect(v); }}
      >
        <div className={cx({ itemLeft: true })}>
          <div className={cx({ itemTitle: true })}>{v.name}</div>
          <div className={cx({ itemEntity: true })}>
            <span>ID: </span>
            {v.ncpdp}
          </div>
          <div className={cx({ itemEntity: true })}>{v.address1}</div>
          <div className={cx({ itemEntity: true })}>
            {v.city}, {v.state}, {v.zipcode}
          </div>
        </div>
        <div className={cx({ itemRight: true })}>
          <div className={cx({ itemEntity: true, isHide: true })}>
            <span>Distance:</span>
            {val} mi
          </div>
          <div className={cx({ itemEntity: true })}>{new AsYouType('US').input(v.phone)}</div>
          <div className={cx({ itemEntity: true })}>{new AsYouType('US').input(v.fax)}</div>
        </div>
      </div>
    );
  });

  return (
    <Drawer
      className={cx({ editPharmacyRecommendedDrawer: true })}
      title={
        <div className={cx({ title: true })}>
          <h3>{initialValues && initialValues.id ? 'Edit' : 'Add'} Pharmacy</h3>
          <Button type="primary" loading={loading} onClick={() => {
            form.submit();
          }}>
            Confirm
          </Button>
        </div>
      }
      width="600"
      placement="right"
      closable={false}
      onClose={onDrawerClose}
      visible={visible}
      destroyOnClose={true}
    >
      <Spin tip="Loading..." spinning={loading}>
        <Form
          layout="vertical"
          form={form}
          initialValues={initialValues}
          onFinish={onFinish}
          onValuesChange={onValuesChange}
        >

          {!(initialValues && initialValues.id) && (
            <div className={cx({ search: true })}>
              <div className={cx({ fieldsGroupFull: true })}>
                <Form.Item
                  label="Search by pharmacy name, zipcode, phone, state"
                  placerhoder="Search by pharmacy name, zipcode, phone, state"
                  ref={filterRef}
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
          )}

          {(filter || RXNTPharmacy?.totalPharmacies > 0) && <div className={cx({ itemContainer: true })}>{listItemsDom}</div>}

          <div className={cx({ search: true })}>
            <div className={cx({ fieldsGroupFull: true })}>
              <Form.Item
                label="Note"
                name="admin_note"
              >
                <Input.TextArea />
              </Form.Item>
            </div>
          </div>

          <div className={cx({ search: true })}>
            <div className={cx({ fieldsGroupFull: true })}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please input pharmacy name',
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </div>
          </div>

          <div className={cx({ search: true })}>
            <div className={cx({ fieldsGroupFull: true })}>
              <Form.Item
                label="Zipcode"
                name="zipcode"
                rules={[
                  {
                    required: true,
                    message: 'Please input zipcode',
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </div>
          </div>

          <div className={cx({ search: true })}>
            <div className={cx({ fieldsGroup: true })}>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: 'Please input city',
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </div>

            <div className={cx({ fieldsGroup: true, })}>
              <Form.Item
                label="State"
                name="state"
                rules={[
                  {
                    required: true,
                    message: 'Please input state',
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </div>
          </div>

          <div className={cx({ search: true })}>
            <div className={cx({ fieldsGroupFull: true })}>
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  {
                    required: true,
                    message: 'Please input address',
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </div>
          </div>

          <div className={cx({ search: true })}>
            <div className={cx({ fieldsGroup: true })}>
              <Form.Item
                label="Phone"
                name="phone"
              >
                <Input disabled={true} />
              </Form.Item>
            </div>

            <div className={cx({ fieldsGroup: true, })}>
              <Form.Item
                label="Fax"
                name="fax"
              >
                <Input disabled={true} />
              </Form.Item>
            </div>
          </div>

          <div className={cx({ search: true })}>
            <div className={cx({ fieldsGroupFull: true })}>
              <Form.Item
                label="Website"
                name="website"
              >
                <Input />
              </Form.Item>
            </div>
          </div>

          <div className={cx({ search: true })}>
            <div className={cx({ fieldsGroupFull: true })}>
              <div className={cx({ fieldsTitle: true })}>Other options</div>
              <Form.Item
                name="is_accepts_insurance"
              >
                <Radio.Group defaultValue={true}>
                  <Radio value={true}>Insurance accepted</Radio>
                  <Radio value={false}>Insurance not accepted</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="is_delivery_available"
                valuePropName="checked"
                onChange={(e) => {
                  setIsDeliveryAvailable(e.target.checked);
                  if (!e.target.checked) {
                    setStateWideDelivery(false);
                    form.setFieldsValue({ is_delivery_state_wide: false, delivery_wide_states: [] })
                  }
                }}
              >
                <Checkbox>Delivery available</Checkbox>
              </Form.Item>
              {isDeliveryAvailable && (
                <Row align="middle" gutter={8}>
                  <Col>
                    <span style={{ fontWeight: 'bold', }}>Delivery Fee</span>
                  </Col>
                  <Col>
                    <Form.Item
                      name="delivery_fee"
                      style={{ marginBottom: 0 }}
                    >
                      <Input placeholder="Enter" style={{ width: '150px' }} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Form.Item
                name="is_delivery_state_wide"
                valuePropName="checked"
                onChange={(e) => {
                  setStateWideDelivery(e.target.checked);
                  if (e.target.checked) {
                    form.setFieldsValue({ is_delivery_available: true, delivery_wide_states: [] })
                  } else {
                    form.setFieldsValue({ is_delivery_state_wide: false, delivery_wide_states: [] })
                  }
                }}
              >
                <Checkbox>State wide delivery</Checkbox>
              </Form.Item>
              {stateWideDelivery && (
                <Form.Item
                  name="delivery_wide_states"
                  style={{
                    width: 'calc(100% - 28px)',
                    margin: '5px 24px',
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input address',
                    },
                  ]}
                >
                  <Select mode="multiple" allowClear>
                    {stateOpts}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                name="is_only_cash"
                valuePropName="checked"
              >
                <Checkbox>Cash only</Checkbox>
              </Form.Item>
            </div>
          </div>

          <div className={cx({ search: true })}>
            <div className={cx({ fieldsGroupFull: true })}>
              <Form.Item
                label="Note to patient"
                name="patient_note"
              >
                <Input.TextArea />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name="successful_refill_prescription"
            valuePropName="checked"
          >
            <Checkbox>This pharmacy successfully refilled the prescription</Checkbox>
          </Form.Item>
          <Form.Item
            label="Longitude"
            name="longitude"
          >
            <Input placeholder="Enter" style={{ width: '150px' }} />
          </Form.Item>
          <Form.Item
            label="Latitude"
            name="latitude"
          >
            <Input placeholder="Enter" style={{ width: '150px',marginBottom:'25px' }} />
          </Form.Item>
          <Form.Item
            name="id"
            hidden={true}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="rxnt_pharmacy_id"
            hidden={true}
          >
            <Input />
          </Form.Item>

        </Form>
      </Spin>
    </Drawer>
  );
};
export default EditPharmacyRecommendedDrawer;
