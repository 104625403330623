import React, { useState, useEffect } from 'react';
import {Popover, List, Row, Col, Tag, Space, Divider,Tooltip} from 'antd';
import GenderPie from "@/components/DrugRecommend/Charts/GenderPie";
import {history} from "umi";
import AgeBar from "@/components/DrugRecommend/Charts/AgeBar";
import cn from 'classnames/bind';
import styles from './index.less';

const cx = cn.bind(styles);

const DrugRecommend = (props) => {
  //The passed value props
  const {items: {items, total, records, conditions, ageMapArray, genderMapArray}} = props;
  const [description, setDescription] = useState("")
  const [conds, setConds] = useState([])
  const color = [ "blue", "gray", "orange", "green","purple", "yellow"];

  const conditionJsx = conds.map((item, index) => {

    return (
        <Tag color={color[index]} key={item}>{item}</Tag>
    )
  });

  const content = (
    <div>
      <Divider plain={true} orientation="left"> reference basis </Divider>
      <Row gutter={24}>
        <Space className={cx({ conditions: true })}>
          {conditionJsx}
        </Space>
      </Row>
      <Divider plain={true} orientation="left"> analysis results </Divider>
      <Row gutter={24}>
        <Col span={6}>
          <h2>similar samples</h2>
          <List
            column={1}
            size="small"
            bordered
            footer={<span><b>total</b>:{total}</span>}
            dataSource={records}
            renderItem={item => (
              <List.Item onClick={() => {
                history.push({
                  pathname: "/patient/" + item.id
                })
              }}>
                <a>{item.name}</a>
              </List.Item>
            )}
          />
        </Col>
        <Col span={10}>
          <h2>drug distribution ratio</h2>
          <List
            size="small"
            bordered
            dataSource={items}
            renderItem={item => (
              <List.Item>
                <Tooltip title={item.drugName}>
                  <span>{item.drugName.length > 25 ? item.drugName.substr(0, 25).concat("...") : item.drugName}</span>
                </Tooltip>
                <span>{(item.patientProportion * 100).toFixed(0) + '%'}</span>
              </List.Item>
            )}
          />
        </Col>
        <Col span={8}>
          <div>
            <GenderPie record={genderMapArray}/>
          </div>
          <Divider plain={true}/>
          <div>
            <AgeBar record={ageMapArray}/>
          </div>
        </Col>
      </Row>

    </div>
  );

  useEffect(() => {
    let descriptionText = "";
    if (items != undefined) {
      if (items.length > 0) {
        let i = 0;
        items.forEach(element => {
          if (i < 3) {
            descriptionText += i+1+". "+element.drugName + "(" + (element.patientProportion * 100).toFixed(0) + "%" + ");  ";
            i++;
          } else {
            return
          }
        });
      }else {
        return
      }
      setDescription(descriptionText + "...")

      if (typeof (conditions) != "undefined") {
        let transArray = new Array();
        let ageRange = new Array();
        let scoreRange = new Array();
        for (let key in conditions) {
          let value = conditions[key];
          key = key.replace("CONDITION_", "");
          if (key === 'AGE_RANGE'|| key==='ASRS_SCORE') {
            transArray.push(key + ":" + value[0]+"-"+value[1]);
          }else {
            transArray.push(key + ":" + value);
          }
        }
        setConds(transArray);
      }
    }

  }, [items]);



  return (
    <div>
      <Popover content={content} title="distribution ratio of medication use">
          <span className={cx({ content: true })}> <b>suggest</b>:<a> {description}</a></span>
      </Popover>
    </div>

  );

}


export default DrugRecommend;
