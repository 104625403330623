import { stringify } from 'querystring';
import { history } from 'umi';
import { message } from 'antd';
import {
  srvAppointments,
  srvAppointmentOrders,
  srvIntStatusItems,
  srvAppointmentDetail,
  srvAppointmentRescheduleCalendar,
  srvRescheduleAppointment,
  srvCancelAppointment,
  srvMarkAppointmentProviderNoShow,
  srvAdminChangePastAppointmentStatus,
} from '@/services/admin';

const Model = {
  namespace: 'appointment_detail',
  state: {
    data: {
      logs: [],
      zoom_event:[],
      bind_user:{},
    },
    orders: [],
    rescheduleCalendar: {},
  },
  effects: {
    *fetchData({ payload, callback }, { call, put }) {
      const res = yield call(srvAppointmentDetail, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveData', payload: res.data });

      callback && callback(res.data);
    },

    *rescheduleAppointment({ payload, callback }, { call, put, select }) {
      const res = yield call(srvRescheduleAppointment, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *cancelAppointment({ payload, callback }, { call, put, select }) {
      const res = yield call(srvCancelAppointment, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *markAppointmentProviderNoShow({ payload, callback }, { call, put, select }) {
      const res = yield call(srvMarkAppointmentProviderNoShow, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *changePastAppointmentStatus({ payload, callback }, { call, put, select }) {
      const res = yield call(srvAdminChangePastAppointmentStatus, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *fetchAppointmentOrders({ payload }, { call, put }) {
      const res = yield call(srvAppointmentOrders, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveOrders', payload: res.data });
    },
    *fetchAppointmentRescheduleCalendar({ payload }, { call, put }) {
      const res = yield call(srvAppointmentRescheduleCalendar, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveAppointmentRescheduleCalendar', payload: res.data });
    },
  },
  reducers: {
    saveData(state, { payload }) {
      return { ...state, data: payload };
    },
    saveAppointmentRescheduleCalendar(state, { payload }) {
      return { ...state, rescheduleCalendar: payload };
    },
    saveOrders(state, { payload }) {
      return { ...state, orders: payload };
    },
  },
};
export default Model;
