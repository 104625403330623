import React from 'react';
import { Modal } from 'antd';

const DeletePatientModal = ({ onOk, onCancel, ...modalProps }) => {
  const modalOpts = {
    ...modalProps,
    title: 'Confirm delete patient info',
    okText: 'Confirm Deletion',
    closable: false,
    onOk: onOk,
    onCancel: onCancel,
  };

  return (
    <Modal {...modalOpts} destroyOnClose={true}>
      <p>
        Once deleted, the patient's info is removed and they can use their information to create a
        new account.
      </p>
      <p>The patient's membership will be deactivated as well.</p>
    </Modal>
  );
};

export default DeletePatientModal;
