import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link } from 'umi';
import { Space, Popover, Input, Table, Row, Col, Button } from 'antd';
import moment from 'moment';
import { SearchOutlined, createFromIconfontCN } from '@ant-design/icons';
import numeral from 'numeral';
import { AsYouType } from 'libphonenumber-js';

import Filters from '@/components/Filters';
import defaultSettings from '../../../defaultSettings';

import { formatUnix } from '../../utils/xtime';
import { getPatientName } from '@/utils/utils';
import styles from './index.less';
const cx = cn.bind(styles);

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const filters = [
  {
    name: 'user_email',
    label: 'Patient Email',
    type: 'text',
  },
  {
    name: 'user_phone',
    label: 'Patient Phone',
    type: 'text',
  },
  {
    name: 'status',
    label: 'Status',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'requested_at',
    label: 'Date',
    type: 'date',
  },
];

const Patient = (props) => {
  const { active, treatment_requests, treatment_request_statuses, loadingTreatmentRequests } = props;
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'requested_at',
      key: 'requested_at',
      ellipsis: true,
      render: (v, r) => {
        return formatUnix(r.requested_at);
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        return r.status_name;
      },
    },
    {
      title: 'Patient',
      dataIndex: 'user_id',
      key: 'user_id',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        const userName = getPatientName(r.patient);
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.user_id}?admin=true`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/patient/${r.user_id}?admin=true`}>{r.patient.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.patient.phone)}
                </div>
                <div className="popover-user-item address">
                  <IconFont className="icon" type="icon-address" />
                  {r.patient.zipcode}
                </div>
                <div className="popover-user-item sex">
                  <IconFont className="icon" type="icon-sex" />
                  {r.patient.gender}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <a href="#">{userName}</a>
          </Popover>
        );
      },
    },
  ];

  useEffect(() => {
    setPage(1);
    props.loadTreatmentRequests({ page: 1, page_size: 20 }, () => {});
    props.loadTreatmentRequestsStatuses();
  }, [active]);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    props.loadTreatmentRequests({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => {});
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    props.loadTreatmentRequests(
      { page: pagination.current, page_size: pagination.pageSize, filters: JSON.stringify(query) },
      () => {},
    );
  };

  const f = () => {
    for (const v of filters) {
      if (v.name === 'status' && v.items.length === 0) {
        v.items = treatment_request_statuses;
        continue;
      }
    }
    return filters;
  };

  return (
    <div className={cx({ subcontainer: true })}>
      <div className={cx({ subheader: true })}>
        <h3>Prescription Renewals</h3>
        <Space>
          <Filters filters={f()} onFilter={onFilter} />
        </Space>
      </div>

      <Table
        columns={columns}
        dataSource={treatment_requests.items}
        rowKey="id"
        loading={loadingTreatmentRequests}
        onChange={handleTableChange}
        pagination={{ pageSize: 20, current: page, simple: true, total: treatment_requests.total }}
      />
    </div>
  );
};

export default Patient;
