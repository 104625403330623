import React from 'react';
import { Tree } from 'antd';
import {
  ProFormItem,
  ProFormSelect,
  ProFormDependency,
  ProFormText,
  ProFormCheckbox,
  ProFormTextArea,
  ProFormUploadDragger,
} from '@ant-design/pro-form';
import { initialCompletedOpts } from '../meta';
import CPTCodesFormItem from '@/components/CPTCodesFormItem';
import LastRefillDateFormItem from '@/components/LastRefiiDateFormItem';
import NextFollowUpDateFormItem from '@/components/NextFollowUpDateFormItem';
import { useNoteStore } from '@/stores';

export default function GenericNote(props) {
  const { form } = props;
  const { patient } = useNoteStore();
  return (
    <>
      <ProFormSelect
        mode="multiple"
        name="diagnostic_code"
        label="Diagnosis"
        placeholder="Select Diagnostic Code"
        options={initialCompletedOpts}
        fieldProps={{
          filterOption: (inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1,
        }}
      />
      <CPTCodesFormItem form={form} patient={patient} noteType={'FollowUp'} initialValues={{}} />
      <LastRefillDateFormItem
        form={form}
        patient={patient}
        noteType={'FollowUp'}
        initialValues={{}}
      />
      <ProFormText name="rx" label="Rx" placeholder="Enter Rx" />
      <ProFormCheckbox
        name="no_controlled_substance_needed"
        label="Patient does not need controlled substances (in-person evaluation/referral not required)."
        valuePropName="checked"
      />
      <ProFormText
        name="attempted_medications"
        label="Attempted Medications"
        placeholder="Enter Attempted Medications"
      />
      <NextFollowUpDateFormItem form={form} patient={patient} />
      <ProFormTextArea
        name="provider_notes"
        label="Provider Notes"
        placeholder="Enter Provider Notes"
        fieldProps={{
          rows: 16,
          maxLength: 20000,
        }}
      />
      <ProFormTextArea
        name="message_to_patient"
        label="Message To Patient"
        placeholder="Enter Message To Patient"
        fieldProps={{
          rows: 16,
          maxLength: 20000,
        }}
      />
    </>
  );
}
