import React, { useState, useEffect } from 'react';
import cn from 'classnames/bind';
import { connect, Link, history, useSelector } from 'umi';
import { Table, Button, Modal, Popover, Tooltip, Checkbox, message, Tabs } from 'antd';
const { TabPane } = Tabs;
import styles from './index.less';
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });
import { useRequest, useSetState, useMount } from 'ahooks';
import defaultSettings from '../../../defaultSettings';
import { createFromIconfontCN, EnvironmentFilled, CalendarFilled } from '@ant-design/icons';
import { useRouteMatch } from 'react-router-dom';
import { formatUnix } from '@/utils/xtime';
import {
  srvPatientPendingTreatmentRequest,
  srvGetRecommendDrug,
  srvPatientDetail,
  srvGetDocumentURL,
  srvOccupyRequests,
  srvFetchRxntPrescriptionOrder,
} from '@/services/patient';
import { useFlag } from '@unleash/proxy-client-react';
import moment from 'moment';
import ViewNote from '@/components/ViewNote';
import AddNote from '@/components/AddNote';
import CallReasonModal from '@/components/PatientInfoPane/CallReasonModal';
const UrgentRefill = (props) => {
  const { dispatch, loading } = props;
  const { urgentRequests } = props.doctor;
  const [page, setPage] = useState(1);
  const [columns, setColumns] = useState(defaultColumns);
  const [showApproveModel, setShowApproveModel] = useState(false);
  const cx = cn.bind(styles);
  const [selected, setSelected] = useState({});
  const [noteVisible, setNoteVisible] = useState(false);
  const [patient, setPatient] = useState({});
  const [currentPrescriptionRequest, setCurrentPrescriptionRequest] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [state, setState] = useSetState({
    showNewPatientAlert: false,
    showNewPatientAlertByPrescription: false,
    RXNTCallbackLink: '',
    approveSourceAction: '',
  });
  const flagPrescriptionAI = useFlag('enable_ehr_prescription_ai');
  const match = useRouteMatch();
  const currentPath = match.url;
  const prefix = currentPath.substring(0, currentPath.lastIndexOf('/'));
  const [callTarget, setCallTarget] = useState('patient');
  const [callPatient, setCallPatient] = useState({});
  const setVisible = (name, value) => {
    _setVisible((prev) => ({ ...prev, [name]: value }));
  };
  const [visible, _setVisible] = useState({
    callReason: false,
  });
  const { currentUser } = useSelector((state) => state.account);
  const { oncall$ } = useSelector((state) => state.call);
  const defaultColumns = [
    {
      title: 'PATIENT',
      dataIndex: 'patient_name',
      key: 'patient_name',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        if (!r.patient) {
          return null;
        }
        const userName = `${r.patient.first_name} ${r.patient.last_name}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.patient.id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      setCallTarget('patient');
                      setVisible('callReason', true);
                      setCallPatient(r.patient);
                    }}
                  >
                    Call Patient
                  </Button>
                </div>
                <div className="popover-user-item birthdate">
                  <CalendarFilled className="icon" />
                  {r.patient.birthdate &&
                    moment.unix(r.patient.birthdate).utc().format('MM/DD/YYYY')}
                </div>
                <div className="popover-user-item sex">
                  <IconFont className="icon" type="icon-sex" />
                  {r.patient.gender}
                </div>
                <div className="popover-user-item state">
                  <EnvironmentFilled className="icon" />
                  {r.patient.state}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <Link to={`/patient/${r.patient.id}`} style={{ color: '#1a1f36', fontWeight: 500 }}>
              {userName}
            </Link>
          </Popover>
        );
      },
    },
    {
      title: 'STATE',
      dataIndex: 'state',
      key: 'state',
      ellipsis: true,
      width: 70,
      render: (v, r) => {
        if (!r.patient) {
          return null;
        }
        return r.patient.state;
      },
    },
    {
      title: 'CLINICIAN',
      dataIndex: 'doctor_id',
      key: 'doctor_id',
      ellipsis: true,
      width: 160,
      render: (v, r) => {
        if (r.doctor) {
          const userName = `${r.doctor.name} ${r.doctor.title}`;
          return userName;
        }
        return '';
      },
    },
    {
      title: 'NOTE',
      dataIndex: 'note',
      key: 'note',
      width: 270,
      render: (v, r) => {
        var note = '';
        if (v) {
          note = 'patient note：\n' + v + '\nNew pharmacy after change: ' + r.pharmacy_name;
          if (r.admin_note) {
            note = note + '\n\n' + 'Admin note：\n' + r.admin_note;
          }
        } else {
          if (r.admin_note) {
            note = 'Admin note：\n' + r.admin_note;
          }
        }
        return <p style={{ whiteSpace: 'pre-wrap' }}>{note}</p>;
      },
    },
    {
      title: 'REQUIRE TIME',
      dataIndex: 'requested_at',
      key: 'requested_at',
      sorter: true,
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        return formatUnix(r.requested_at);
      },
    },
    {
      title: 'DAYS PENDING',
      dataIndex: 'days_pending',
      key: 'days_pending',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        return <p style={{ fontWeight: '700' }}>{v}</p>;
      },
    },
    {
      title: 'Status',
      ellipsis: true,
      width: 120,
      dataIndex: 'status_name',
      key: 'status_name',
      fixed: 'right',
      render: (v, r) => {
        if (r.status == 8) {
          return (
            <Tooltip title="Syncing failed, please refresh or prescribe in RXNT again">{v}</Tooltip>
          );
        }
        return <div>{v}</div>;
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      fixed: 'right',
      width: 280,
      render: (v, r) => {
        let statusButton = '';
        if (r.status == 1 || r.status == 5 || r.status == 8) {
          statusButton = (
            <>
              {(r.status == 1 ||
                (r.status == 5 && r.requested_at <= moment().unix()) ||
                r.status == 8) &&
                (r.has_occupy_flag ? (
                  <Tooltip title="The current request is being processed">
                    <Button
                      disabled={r.has_occupy_flag}
                      size="small"
                      type="primary"
                      onClick={() => {
                        selectRow(r);
                      }}
                    >
                      Approve
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    disabled={r.has_occupy_flag}
                    size="small"
                    type="primary"
                    onClick={() => {
                      selectRow(r);
                    }}
                  >
                    Approve
                  </Button>
                ))}
            </>
          );
        } else if (r.status == 2 && !r.note_added && !r.is_closed) {
          statusButton = (
            <Button
              type="danger"
              disabled={r.has_occupy_flag}
              onClick={() => {
                selectRow(r);
              }}
            >
              Add Notes
            </Button>
          );
        }
        return (
          <div style={{ display: 'flex' }}>
            <Button
              style={{ marginRight: '10px' }}
              size="small"
              onClick={() => {
                history.push(`/patient/${r.patient.id}`);
              }}
            >
              View Chart
            </Button>
            {r.pdmp_object_key && (
              <Button
                size="small"
                style={{ marginRight: '10px' }}
                onClick={() => {
                  downloadDocument(r.pdmp_object_key);
                }}
              >
                View PDMP
              </Button>
            )}
            {statusButton}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setPage(1);
    setColumns(defaultColumns);
    loadList({ page: 1, page_size: 20 }, () => { });
  }, []);

  useMount(() => {
    if (
      history.location.query.ord_id &&
      String(history.location.query.ord_id).trim() != '' &&
      /^\d+$/.test(history.location.query.ord_id)
    ) {
      syncRxntPrescription
        .runAsync({ order_id: String(history.location.query.ord_id).trim() })
        .then(() => {
          history.replace(`${history.location.pathname}`);
        });
    }
  });
  const loadList = (payload, callback) => {
    dispatch({ type: 'doctor/fetchUrgentRefillRequests', payload, callback });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let tempSort = 0;
    if (sorter && sorter.field == 'requested_at' && sorter.order) {
      tempSort = sorter.order === 'ascend' ? -1 : 1;
    }
    setPage(pagination.current);
    loadList({
      page: pagination.current,
      page_size: pagination.pageSize,
      sort: tempSort,
    });
  };
  const onApprove = (request, source) => {
    let condition = { user_id: request.patient.id };
    if (request && request.id) condition = { request_id: request.id };
    loadPatientPendingTreatmentRequestReq.runAsync(condition).then(() => {
      setState({ approveSourceAction: source });
      setNoteVisible(true);
    });
  };

  const syncRxntPrescription = useRequest(srvFetchRxntPrescriptionOrder, {
    manual: true,
    onSuccess: (ret, params) => {
      loadList({ page: 1, page_size: 20 }, () => { });
    },
  });

  const downloadDocument = (object_key) => {
    srvGetDocumentURL({
      object_key,
    }).then((res) => {
      let url = res.data;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'download');
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const selectRow = (row) => {
    setSelected(row);
    setShowApproveModel(true);
  };

  const confimDealRefill = () => {
    //attempt to occupy the refill request
    const id = selected.id;
    srvOccupyReq.run({ id });
  };

  const loadData = () => {
    const id = selected.patient.id;
    loadPatientInfoReq.run({ id });
    loadPatientPendingTreatmentRequestReq.run({ user_id: id });
    if (flagPrescriptionAI) {
      srvGetRecommendDrugReq.run({ user_id: id });
    }
  };

  const loadPatientInfoReq = useRequest(srvPatientDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setPatient(ret.data);
      }
    },
  });
  const srvGetRecommendDrugReq = useRequest(srvGetRecommendDrug, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setRecommendList(ret.data);
      }
    },
  });

  const srvOccupyReq = useRequest(srvOccupyRequests, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (ret.data.has_occupy_flag) {
          message.error('The current request is locked, please try again later', 10);
          setShowApproveModel(false);
          loadList();
          return;
        }
        loadData();
        setShowApproveModel(false);
        setIsChecked(false);
        onApprove(selected, 'PrescriptionMedications');
      }
    },
  });

  const loadPatientPendingTreatmentRequestReq = useRequest(srvPatientPendingTreatmentRequest, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setCurrentPrescriptionRequest(ret.data);
        let link = `/api/ehr/rxnt/sso?return_url=${Buffer.from(window.location.href).toString(
          'base64',
        )}&patient_id=${patient.id}&order_type=1`;
        if (ret.data && ret.data.id) {
          link = `/api/ehr/rxnt/sso?return_url=${Buffer.from(window.location.href).toString(
            'base64',
          )}&patient_id=${patient.id}&order_type=2&treatment_request_id=${ret.data.id}`;
        }
        setState({ RXNTCallbackLink: link });
      }
    },
  });

  const makeCall = (target, reason) => {
    const data = {
      patient_id: callPatient.id,
      from_user_id: currentUser.id,
      target,
      first_name: callPatient.first_name,
      last_name: callPatient.last_name,
      reason,
    };

    oncall$.emit(data);
  };

  return (
    <div className={cx({ container: true })} style={{paddingLeft:'0px'}}>
      <div
        style={{
          borderRadius: '4px',
          backgroundColor: '#F0F5FF',
          paddingBottom: '8px',
          paddingLeft: '12px',
          paddingTop: '8px',
          paddingRight: '12px',
          marginBottom: '20px',
        }}
      >
        <p style={{ fontSize: '14px' }}>
          Special instructions： Please double-check the patient's chart and PDMP before
          refilling and prescribe medication based on their current condition. You will earn
          $5 for each refill, And your{' '}
          <Link to={`/ehr_dashboard`} style={{ color: '#566BCD', fontWeight: 500 }}>
            dashboard
          </Link>{' '}
          will display your extra income. If you have any questions, please contact your PA.
        </p>
      </div>
      <Table
        columns={columns}
        dataSource={urgentRequests.items}
        size={'small'}
        scroll={{ x: 1000 }}
        rowKey="id"
        loading={loading.effects['doctor/fetchUrgentRefillRequests']}
        onChange={handleTableChange}
        pagination={{
          pageSize: 20,
          current: page,
          simple: true,
          total: urgentRequests.total,
        }}
      />
      {patient && patient.id && noteVisible && (
        <>
          {currentPrescriptionRequest && currentPrescriptionRequest.note_added ? (
            <ViewNote
              RXNTCallbackLink={state.RXNTCallbackLink}
              onRefresh={loadData}
              noteVisible={noteVisible}
              setNoteVisible={setNoteVisible}
              prescriptionInfo={currentPrescriptionRequest}
              patientInfo={patient}
              urgentRefillFlag={true}
            />
          ) : (
            <AddNote
              addNoteType={
                currentPrescriptionRequest && currentPrescriptionRequest.id
                  ? 'approvePrescriptionRequest'
                  : 'approvePrescriptionWithoutRequest'
              }
              sourceAction={state.approveSourceAction}
              RXNTCallbackLink={state.RXNTCallbackLink}
              onRefresh={loadData}
              noteVisible={noteVisible}
              setNoteVisible={setNoteVisible}
              prescriptionInfo={currentPrescriptionRequest}
              patientInfo={patient}
              urgentRefillFlag={true}
              originName={selected.doctor.name}
            />
          )}
        </>
      )}
      <Modal
        visible={showApproveModel}
        onCancel={() => {
          setShowApproveModel(false);
        }}
        onOk={() => {
          setShowApproveModel(false);
        }}
        footer={null}
      >
        <p
          style={{
            color: '#000000',
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '20px',
          }}
        >
          Approve the refill request
        </p>
        <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}>
          <span>
            &nbsp;I have checked the patient's PDMP and chart and confirmed that I can prescribe for
            this patient.
          </span>
        </Checkbox>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: '#000000',
            marginTop: '10px',
          }}
        >
          <p
            style={{ color: '#566BCD', cursor: 'pointer' }}
            onClick={() => {
              setShowApproveModel(false);
              setIsChecked(false);
            }}
          >
            Cancel
          </p>
          <Button
            key="confirm"
            onClick={() => {
              confimDealRefill();
            }}
            disabled={!isChecked}
            type="primary"
          >
            Confirm
          </Button>
        </div>
      </Modal>
      <CallReasonModal
        visible={visible.callReason}
        filterFirstReason={true}
        onOk={(reason) => {
          setVisible('callReason', false);
          makeCall(callTarget, reason);
        }}
        onCancel={() => {
          setVisible('callReason', false);
        }}
      />
    </div>
  );
};

export default connect(({ doctor, loading }) => ({
  doctor,
  loading,
}))(UrgentRefill);
