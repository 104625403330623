import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox, Space } from 'antd';

import { useMount, useUpdateEffect, useSetState } from 'ahooks';
import ExclusiveCheckboxWithTextInput from './ExclusiveCheckboxWithTextInput';
import { socialHistoryItems } from './meta';

const SocialHistoryFormItems = (props) => {
  const { form, draft, setDraft } = props;

  // useUpdateEffect(() => {
  // }, [draft]);

  const onItemChange = (e, name, exclusive, items = []) => {
    if (e.target.checked) {
      let fields = {}
      items.forEach(v => {
        if (exclusive) {
          fields[v.value] = v.exclusive ? true : false;
          if (v.hasInput) fields[`${v.value}_text`] = '';
        } else {
          if (v.value == name) fields[v.value] = true;
          if (v.exclusive) fields[v.value] = false;
        }
      })
      if (Object.keys(fields).length > 0) {
        form.setFieldsValue(fields);
        setDraft(Object.assign({ ...draft }, fields));
      }
    }
  }

  return (
    <>
      <Form.Item
        name="social_occupation"
        label={'Occupation'}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="social_work_school_schedule"
        label={'Work/school schedule'}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="social_life_stressors_events"
        label={'Significant life stressors/events'}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="social_relationship_status"
        label={'Relationship status'}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="social_children"
        label={'Children'}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="social_support"
        label={'Social support'}
      >
        <Input />
      </Form.Item>

      <h4>Legal issues/incarcerations/probation/parole</h4>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px'}} >
        {socialHistoryItems.map((v, index) => {
          return (
            <div key={index} style={{ flex: '0 1 auto', padding: '2px 20px 2px 0px' }}>
              <ExclusiveCheckboxWithTextInput name={v.value} label={v.label} exclusive={v.exclusive} hasInput={v.hasInput}
                onChange={(e) => onItemChange(e, v.value, v.exclusive, socialHistoryItems)} form={form} draft={draft} setDraft={setDraft} />
            </div>
          )
        })}
      </div>
    </>
  )
};

export default SocialHistoryFormItems;
