import { useEffect, useRef } from 'react';
import useLatest from './useLatest';

export default function useInterval(fn, delay, options = { immediate: true }) {
  const immediate = options.immediate;

  const fnRef = useLatest(fn);

  useEffect(() => {
    if (typeof delay !== 'number' || delay < 0) return;
    if (immediate) {
      fnRef.current();
    }
    const timer = setInterval(() => {
      fnRef.current();
    }, delay);
    return () => {
      clearInterval(timer);
    };
  }, [delay]);
}
