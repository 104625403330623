import React, { useEffect, useState, useRef } from 'react';
import { Form, Space, Select, Input, Divider, Button, Typography } from 'antd';



const OthersCPTCodes = [
  {
    label: 'Others',
    options: [
      {
        label: '99421: Async - Online Digital E/M (5-10 min cumulative for up to 7 days)',
        value: '994210',
      },
      {
        label: '99422: Async - Online Digital E/M (11-20 min cumulative for up to 7 days)',
        value: '994220',
      },
      {
        label: '99423: Async - Online Digital E/M (21+ min cumulative for up to 7 days)',
        value: '994230',
      },
      {
        label: '99484: Behavioral Health Integration (20+ min)',
        value: '99484',
      },
    ]
  },
]

const CPTCodes = [
  {
    label: 'Most Recommended',
    options: [
      {
        label: '90792: Psych Diagnostic Interview Exam (typically 25-60 min)',
        value: '907920',
      },
      {
        label: '99212: Follow-up(sync) or Transfer - Minimal Complexity (10-19 min)',
        value: '992120',
      },
      {
        label: '99213: Follow-up(sync) or Transfer - Low Complexity (20-29 min)',
        value: '992130',
      },
    ]
  },
  {
    label: 'Initial Evaluation',
    options: [
      {
        label: '90792: Psych Diagnostic Interview Exam (typically 25-60 min)',
        value: '90792',
      },
      {
        label: '99202: New Patient E/M - Minimal Complexity (15-29 min)',
        value: '99202',
      },
      {
        label: '99203: New Patient E/M - Low Complexity (30-44 min)',
        value: '99203',
      },
      {
        label: '99204: New Patient E/M - Medium Complexity (45-59 min)',
        value: '99204',
      },
      {
        label: '99205: New Patient E/M - High Complexity (60-74 min)',
        value: '99205',
      },
    ],
  },
  {
    label: 'Follow-Up Evaluation',
    options: [
      {
        label: '99213: Follow-up - Total time 20-29 min including works before and after visit / Low Complexity',
        value: '99213',
      },
      {
        label: '99212: Follow-up - Total time 10-19 min including works before and after visit / Straightforward Complexity',
        value: '99212',
      },
      {
        label: '99214: Follow-up - Total time 30-39 min including works before and after visit / Moderate Complexity',
        value: '99214',
      },
    ],
  },
  {
    label: 'Refill Request Note',
    options: [
      {
        label: '99421: Async - Online Digital E/M (5-10 min cumulative for up to 7 days)',
        value: '99421',
      },
      {
        label: '99422: Async - Online Digital E/M (11-20 min cumulative for up to 7 days)',
        value: '99422',
      },
      {
        label: '99423: Async - Online Digital E/M (21+ min cumulative for up to 7 days)',
        value: '99423',
      },
    ]
  },
  {
    label: 'Psychotherapy',
    options: [
      {
        label: '90791: Diagnostic Psychotherapy Evaluation (typically 25-60 min)',
        value: '90791',
      },
      {
        label: '90837: Individual Psychotherapy (53+ min session duration)',
        value: '90837',
      },
      {
        label: '90834: Individual Psychotherapy (38-52 min session duration)',
        value: '90834',
      },
      {
        label: '90832: Individual Psychotherapy (16-37 min session duration)',
        value: '90832',
      },
      {
        label: '90846: Family Psychotherapy Patient Not Present (26+ min)',
        value: '90846',
      },
      {
        label: '90847: Family Psychotherapy Patient Present (26+ min)',
        value: '90847',
      },
      {
        label: '90853: Group Psychotherapy (26+ min)',
        value: '90853',
      },
    ]
  },
  ...OthersCPTCodes,
]

const InitialEvaluationCPTCodes = [
  {
    label: 'Most Recommended',
    options: [
      {
        label: '90792: Psych Diagnostic Interview Exam (typically 25-60 min)',
        value: '90792',
      },
    ]
  },
  {
    label: 'Others',
    options: [
      {
        label: '90791: Diagnostic Psychotherapy Evaluation (typically 25-60 min)',
        value: '90791',
      },
      {
        label: '99202: New Patient E/M - Minimal Complexity (15-29 min)',
        value: '99202',
      },
      {
        label: '99203: New Patient E/M - Low Complexity (30-44 min)',
        value: '99203',
      },
      {
        label: '99204: New Patient E/M - Medium Complexity (45-59 min)',
        value: '99204',
      },
      {
        label: '99205: New Patient E/M - High Complexity (60-74 min)',
        value: '99205',
      },
      {
        label: '99212: Follow-up - Total time 10-19 min including works before and after visit / Straightforward Complexity',
        value: '99212',
      },
      {
        label: '99213: Follow-up - Total time 20-29 min including works before and after visit / Low Complexity',
        value: '99213',
      },
      {
        label: '99214: Follow-up - Total time 30-39 min including works before and after visit / Moderate Complexity',
        value: '99214',
      },
      {
        label: '99215: Follow-up or transfer (40-54 min)',
        value: '99215',
      },
      {
        label: '90846: Family Psychotherapy Patient Not Present (26+ min)',
        value: '90846',
      },
      {
        label: '90847: Family Psychotherapy Patient Present (26+ min)',
        value: '90847',
      },
      {
        label: '99421: Async - Online Digital E/M (5-10 min cumulative for up to 7 days)',
        value: '99421',
      },
      {
        label: '99422: Async - Online Digital E/M (11-20 min cumulative for up to 7 days)',
        value: '99422',
      },
      {
        label: '99423: Async - Online Digital E/M (21+ min cumulative for up to 7 days)',
        value: '99423',
      },
      {
        label: '99484: Behavioral Health Integration (20+ min)',
        value: '99484',
      },
      {
        label: '90785: Interactive Complexity',
        value: '90785',
      },
      {
        label: '90832: Individual Psychotherapy (16-37 min session duration)',
        value: '90832',
      },
      {
        label: '90833: Individual Psychotherapy (16-37 min)',
        value: '90833',
      },
      {
        label: '90834: Individual Psychotherapy (38-52 min session duration)',
        value: '90834',
      },
      {
        label: '90836: Individual Psychotherapy (38-52 min)',
        value: '90836',
      },
      {
        label: '90837: Individual Psychotherapy (53+ min session duration)',
        value: '90837',
      },
      {
        label: '90838: Individual Psychotherapy (53+ min)',
        value: '90838',
      },
    ]
  },
]

const FollowUpEvaluationCPTCodes = [
  {
    label: 'Most Recommended',
    options: [
      {
        label: '99213: Follow-up - Total time 20-29 min including works before and after visit / Low Complexity',
        value: '99213',
      },
      {
        label: '99212: Follow-up - Total time 10-19 min including works before and after visit / Straightforward Complexity',
        value: '99212',
      },
      {
        label: '99214: Follow-up - Total time 30-39 min including works before and after visit / Moderate Complexity',
        value: '99214',
      },
    ],
  },
  {
    label: 'Others',
    options: [
      {
        label: '90792: Psych Diagnostic Interview Exam (typically 25-60 min)',
        value: '90792',
      },
      {
        label: '90791: Diagnostic Psychotherapy Evaluation (typically 25-60 min)',
        value: '90791',
      },
      {
        label: '99202: New Patient E/M - Minimal Complexity (15-29 min)',
        value: '99202',
      },
      {
        label: '99203: New Patient E/M - Low Complexity (30-44 min)',
        value: '99203',
      },
      {
        label: '99204: New Patient E/M - Medium Complexity (45-59 min)',
        value: '99204',
      },
      {
        label: '99205: New Patient E/M - High Complexity (60-74 min)',
        value: '99205',
      },
      {
        label: '99215: Follow-up or transfer (40-54 min)',
        value: '99215',
      },
      {
        label: '90846: Family Psychotherapy Patient Not Present (26+ min)',
        value: '90846',
      },
      {
        label: '90847: Family Psychotherapy Patient Present (26+ min)',
        value: '90847',
      },
      {
        label: '99421: Async - Online Digital E/M (5-10 min cumulative for up to 7 days)',
        value: '99421',
      },
      {
        label: '99422: Async - Online Digital E/M (11-20 min cumulative for up to 7 days)',
        value: '99422',
      },
      {
        label: '99423: Async - Online Digital E/M (21+ min cumulative for up to 7 days)',
        value: '99423',
      },
      {
        label: '99484: Behavioral Health Integration (20+ min)',
        value: '99484',
      },
      {
        label: '90785: Interactive Complexity',
        value: '90785',
      },
      {
        label: '90832: Individual Psychotherapy (16-37 min session duration)',
        value: '90832',
      },
      {
        label: '90833: Individual Psychotherapy (16-37 min)',
        value: '90833',
      },
      {
        label: '90834: Individual Psychotherapy (38-52 min session duration)',
        value: '90834',
      },
      {
        label: '90836: Individual Psychotherapy (38-52 min)',
        value: '90836',
      },
      {
        label: '90837: Individual Psychotherapy (53+ min session duration)',
        value: '90837',
      },
      {
        label: '90838: Individual Psychotherapy (53+ min)',
        value: '90838',
      },
    ]
  },
]

const AsyncFollowUpNoteCPTCodes = [
  {
    label: 'Most Recommended',
    options: [
      {
        label: '99421: Async - Online Digital E/M (5-10 min cumulative for up to 7 days)',
        value: '99421',
      },
      {
        label: '99422: Async - Online Digital E/M (11-20 min cumulative for up to 7 days)',
        value: '99422',
      },
      {
        label: '99423: Async - Online Digital E/M (21+ min cumulative for up to 7 days)',
        value: '99423',
      },
    ]
  },
  {
    label: 'Others',
    options: [
      {
        label: '90792: Psych Diagnostic Interview Exam (typically 25-60 min)',
        value: '90792',
      },
      {
        label: '90791: Diagnostic Psychotherapy Evaluation (typically 25-60 min)',
        value: '90791',
      },
      {
        label: '99202: New Patient E/M - Minimal Complexity (15-29 min)',
        value: '99202',
      },
      {
        label: '99203: New Patient E/M - Low Complexity (30-44 min)',
        value: '99203',
      },
      {
        label: '99204: New Patient E/M - Medium Complexity (45-59 min)',
        value: '99204',
      },
      {
        label: '99205: New Patient E/M - High Complexity (60-74 min)',
        value: '99205',
      },
      {
        label: '99212: Follow-up - Total time 10-19 min including works before and after visit / Straightforward Complexity',
        value: '99212',
      },
      {
        label: '99213: Follow-up - Total time 20-29 min including works before and after visit / Low Complexity',
        value: '99213',
      },
      {
        label: '99214: Follow-up - Total time 30-39 min including works before and after visit / Moderate Complexity',
        value: '99214',
      },
      {
        label: '99215: Follow-up or transfer (40-54 min)',
        value: '99215',
      },
      {
        label: '90846: Family Psychotherapy Patient Not Present (26+ min)',
        value: '90846',
      },
      {
        label: '90847: Family Psychotherapy Patient Present (26+ min)',
        value: '90847',
      },
      {
        label: '99484: Behavioral Health Integration (20+ min)',
        value: '99484',
      },
      {
        label: '90785: Interactive Complexity',
        value: '90785',
      },
      {
        label: '90832: Individual Psychotherapy (16-37 min session duration)',
        value: '90832',
      },
      {
        label: '90833: Individual Psychotherapy (16-37 min)',
        value: '90833',
      },
      {
        label: '90834: Individual Psychotherapy (38-52 min session duration)',
        value: '90834',
      },
      {
        label: '90836: Individual Psychotherapy (38-52 min)',
        value: '90836',
      },
      {
        label: '90837: Individual Psychotherapy (53+ min session duration)',
        value: '90837',
      },
      {
        label: '90838: Individual Psychotherapy (53+ min)',
        value: '90838',
      },
    ]
  },
]

const GenericTherapyNoteCPTCodes = [
  {
    label: 'Most Recommended',
    options: [
      {
        label: '90791: Diagnostic Psychotherapy Evaluation (typically 25-60 min)',
        value: '90791',
      },
      {
        label: '90837: Individual Psychotherapy (53+ min session duration)',
        value: '90837',
      },
      {
        label: '90834: Individual Psychotherapy (38-52 min session duration)',
        value: '90834',
      },
      {
        label: '90832: Individual Psychotherapy (16-37 min session duration)',
        value: '90832',
      },
    ]
  },
  {
    label: 'Others',
    options: [
      {
        label: '90792: Psych Diagnostic Interview Exam (typically 25-60 min)',
        value: '90792',
      },
      {
        label: '99202: New Patient E/M - Minimal Complexity (15-29 min)',
        value: '99202',
      },
      {
        label: '99203: New Patient E/M - Low Complexity (30-44 min)',
        value: '99203',
      },
      {
        label: '99204: New Patient E/M - Medium Complexity (45-59 min)',
        value: '99204',
      },
      {
        label: '99205: New Patient E/M - High Complexity (60-74 min)',
        value: '99205',
      },
      {
        label: '99212: Follow-up - Total time 10-19 min including works before and after visit / Straightforward Complexity',
        value: '99212',
      },
      {
        label: '99213: Follow-up - Total time 20-29 min including works before and after visit / Low Complexity',
        value: '99213',
      },
      {
        label: '99214: Follow-up - Total time 30-39 min including works before and after visit / Moderate Complexity',
        value: '99214',
      },
      {
        label: '99215: Follow-up or transfer (40-54 min)',
        value: '99215',
      },
      {
        label: '90846: Family Psychotherapy Patient Not Present (26+ min)',
        value: '90846',
      },
      {
        label: '90847: Family Psychotherapy Patient Present (26+ min)',
        value: '90847',
      },
      {
        label: '99421: Async - Online Digital E/M (5-10 min cumulative for up to 7 days)',
        value: '99421',
      },
      {
        label: '99422: Async - Online Digital E/M (11-20 min cumulative for up to 7 days)',
        value: '99422',
      },
      {
        label: '99423: Async - Online Digital E/M (21+ min cumulative for up to 7 days)',
        value: '99423',
      },
      {
        label: '99484: Behavioral Health Integration (20+ min)',
        value: '99484',
      },
      {
        label: '90785: Interactive Complexity',
        value: '90785',
      },
      {
        label: '90833: Individual Psychotherapy (16-37 min)',
        value: '90833',
      },
      {
        label: '90836: Individual Psychotherapy (38-52 min)',
        value: '90836',
      },
      {
        label: '90838: Individual Psychotherapy (53+ min)',
        value: '90838',
      },
    ]
  }
]

export const CPTCodesMap = {
  '90791': '90791: Diagnostic Psychotherapy Evaluation (typically 25-60 min)',
  '90792': '90792: Psych Diagnostic Interview Exam (typically 25-60 min)',
  '99202': '99202: New Patient E/M - Minimal Complexity (15-29 min)',
  '99203': '99203: New Patient E/M - Low Complexity (30-44 min)',
  '99204': '99204: New Patient E/M - Medium Complexity (45-59 min)',
  '99205': '99205: New Patient E/M - High Complexity (60-74 min)',
  '99212': '99212: Follow-up - Total time 10-19 min including works before and after visit / Straightforward Complexity',
  '99213': '99213: Follow-up - Total time 20-29 min including works before and after visit / Low Complexity',
  '99214': '99214: Follow-up - Total time 30-39 min including works before and after visit / Moderate Complexity',
  '99215': '99215: Follow-up or transfer (40-54 min)',
  '90846': '90846: family psychotherapy patient not present (26+ min)',
  '90847': '90847: family psychotherapy patient present (26+ min)',
  '99421': '99421: Async - Online Digital E/M (5-10 min cumulative for up to 7 days)',
  '99422': '99422: Async - Online Digital E/M (11-20 min cumulative for up to 7 days)',
  '99423': '99423: Async - Online Digital E/M (21+ min cumulative for up to 7 days)',
  '99484': '99484: Behavioral health integration (20+ min)',
  '90785': '90785: Interactive Complexity',
  '90832': '90832: Individual Psychotherapy (16-37 min session duration)',
  '90833': '90833: Individual Psychotherapy (16-37 min)',
  '90834': '90834: Individual Psychotherapy (38-52 min session duration)',
  '90836': '90836: Individual Psychotherapy (38-52 min)',
  '90837': '90837: Individual Psychotherapy (53+ min session duration)',
  '90838': '90838: Individual Psychotherapy (53+ min)',
}

const CPTCodesFormItem = (props) => {
  const { form, noteType, initialValues = {} } = props;

  const [cptCodes, setCptCodes] = useState([]);
  const [addonCodes, setAddonCodes] = useState([]);
  const [codes, setCodes] = useState(CPTCodes.flatMap(c => c.options.map(option => option.value)));
  const [code, setCode] = useState('');
  const [cptCodeRequired, setCptCodeRequired] = useState(true)
  const [addCodeButtonDisable, setAddCodeButtonDisable] = useState(true)
  const [addCodeErrorVisible, setAddCodeErrorVisible] = useState(false)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const CPTCodeRef = useRef(null);
  const inputRef = useRef(null);

  const onCodeChange = (e) => {
    let value = e.target.value;
    if (value.length > 5) value = value.slice(0, 5);
    setCode(value);
    if (value.length === 5) {
      if (codes.includes(value)) {
        setAddCodeErrorVisible(true);
        setAddCodeButtonDisable(true);
      } else {
        setAddCodeErrorVisible(false);
        setAddCodeButtonDisable(false);
      };
      return;
    }
    setAddCodeErrorVisible(false);
    setAddCodeButtonDisable(true);
  };

  const addCodeAndSelect = (e) => {
    e.preventDefault();
    const value = code; //inputRef.current.state.value
    const cpt_codes = [].concat(cptCodes)
    cpt_codes[cpt_codes.length-1]?.options.push({ label: value, value })
    setCptCodes(cpt_codes);
    setAddonCodes([]);
    setCodes([...codes, value])
    form.setFieldsValue({ cpt_code: value });
    setDropdownVisible(false);
    setCode('');
  };

  const onCPTCodeChange = (value) => {
    let cpt_code = value;
    if (value.length > 5) {
      cpt_code = value.slice(0, 5);
    }
    form.setFieldsValue({ cpt_code: cpt_code, addon_code: null });
    let codes = [];
    if (['90791', '90792'].includes(cpt_code)) {
      codes = [
        {
          label: '90785: Interactive Complexity',
          value: '90785',
        }
      ]
    } else if (['99202', '99203', '99204', '99205', '99212', '99213', '99214', '99215'].includes(cpt_code)) {
      codes = [
        {
          label: '90833: Individual Psychotherapy (16-37 min)',
          value: '90833',
        },
        {
          label: '90836: Individual Psychotherapy (38-52 min)',
          value: '90836',
        },
        {
          label: '90838: Individual Psychotherapy (53+ min)',
          value: '90838',
        },
      ]
    }
    setAddonCodes(codes);
  };

  useEffect(() => {
    if (noteType) {
      let codes = [];
      if (noteType == 'InitialEvaluation') {
        codes = InitialEvaluationCPTCodes;
      } else if (noteType == 'FollowUp') {
        codes = FollowUpEvaluationCPTCodes;
      } else if (noteType == 'Refill') {
        codes = AsyncFollowUpNoteCPTCodes;
      } else if (noteType == 'TherapistGeneric') {
        codes = GenericTherapyNoteCPTCodes;
      } else {
        codes = CPTCodes;
      }
      setCptCodes(codes);
      setCptCodeRequired(!(noteType == 'PlainRefill' || noteType == 'Discharge/Transfer' || noteType == 'Refill' || noteType == 'AsyncVideoFollowUp'))
      setAddonCodes([])
      form.setFieldsValue({ cpt_code: null, addon_code: null })
    }
  }, [noteType]);

  useEffect(() => {
    if (initialValues && initialValues.cpt_code > 0) {
      form.setFieldsValue({ cpt_code: initialValues.cpt_code });
      onCPTCodeChange(initialValues.cpt_code);
      form.setFieldsValue({ addon_code: initialValues.addon_code });
    }
  }, [initialValues]);


  return (
    <>
      <Form.Item
        name="cpt_code"
        label="CPT Code"
        rules={[
          {
            required: cptCodeRequired,
            message: 'CPT Code is required',
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Select Code"
          placement="bottomLeft"
          ref={CPTCodeRef}
          onChange={onCPTCodeChange}
          open={dropdownVisible}
          onDropdownVisibleChange={(visible) => setDropdownVisible(visible)}
          options={cptCodes}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: '8px 0',
                }}
              />
              <Space
                style={{
                  padding: '0 8px 4px',
                }}
              >
                <Input
                  placeholder="Please Enter Other Code"
                  style={{ width: 200, }}
                  // ref={inputRef}
                  value={code}
                  onChange={onCodeChange}
                />
                <Button type="primary" disabled={addCodeButtonDisable} onClick={addCodeAndSelect}>
                  Add Code
                </Button>
                {addCodeErrorVisible && (
                  <Typography.Text type="danger">code already exists!</Typography.Text>
                )}
              </Space>
            </>
          )}
        />
      </Form.Item>

      {addonCodes && addonCodes.length > 0 && (
        <Form.Item
          name="addon_code"
          label="Add-on Code"
        >
          <Select
            placeholder="Select Code"
            allowClear={true}
            options={addonCodes}
          />
        </Form.Item>
      )}
    </>
  )
};

export default CPTCodesFormItem;
