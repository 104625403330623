import { create } from 'zustand';
import { Modal } from 'antd';

export const useChartAuditStore = create((set, get) => ({
  visible: false,
  isMinimized: false,
  auditInfo: {},
  chartAuditEvent: null,
  open: (auditInfo) => {
    // current audit is not finished, need confirm
    if (get().visible && get().auditInfo.audit_status === 1) {
      Modal.warning({
        title: 'You have an unfinished chart audit',
        content: 'Please complete it before starting a new one.',
        okText: 'Continue Audit',
        onOk: () => {
          set({ isMinimized: false });
        },
      });
      return;
    }
    set({ visible: true, isMinimized: false, auditInfo });
  },
  close: () => {
    set({ visible: false, isMinimized: false, auditInfo: {} });
  },
  toggleMinimize: () => {
    set((state) => ({ isMinimized: !state.isMinimized }));
  },
  setAuditInfo: (auditInfo) => set({ auditInfo }),
  setChartAuditEvent: (chartAuditEvent) => set({ chartAuditEvent }),
}));
