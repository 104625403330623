import React, { useEffect, useState } from 'react';
import { Button, Modal, Drawer, Descriptions, Spin, Space, Form, Input, message } from 'antd';
import { FilePdfOutlined, } from '@ant-design/icons';
import { useFlag } from '@unleash/proxy-client-react';

import { useRequest } from 'ahooks';
import moment from 'moment';

import PDFPreview from '@/components/PDFPreview';
import RemindProviderPDMPCredentialsAuthorizeAdminAccessModal from './RemindAuthorizeAdminAccessModal';

import { srvCheckPDMP, srcLogPDMP, srvDoctorRemindMark } from '@/services/patient';

import './index.css';


const PDMPPane = (props) => {
  const { id, showUI = 'Modal' } = props;
  const { innerWidth: width, innerHeight: height } = window;
  const [form] = Form.useForm();
  const [pdmp, setPdmp] = useState({});
  const [report, setReport] = useState({});
  const [viewPDMPModalVisible, setViewPDMPModalVisible] = useState(false);
  const [viewPDMPDrawerVisible, setViewPDMPDrawerVisible] = useState(false);
  const [verfiyIdentityModalVisible, setVerfiyIdentityModalVisible] = useState(false);
  const [adminUnauthorizedModelVisible, setAdminUnauthorizedModelVisible] = useState(false);
  const [htmlVisible, setHtmlVisible] = useState(false);
  const [pdfVisible, setPdfVisible] = useState(false);

  const flagPDMP = useFlag('ab_test_enable_pdmp');

  const checkPDMPReq = useRequest(srvCheckPDMP, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success && ret.data) {
        if (ret.data.patient_report !== "") {
          setReport(JSON.parse(ret.data.patient_report));
        }
        setVerfiyIdentityModalVisible(ret.data.is_verfiy_doctor_identity);
        setAdminUnauthorizedModelVisible(ret.data.is_remind_admin_unauthorized);
        setViewPDMPModalVisible(showUI === 'Modal' && !ret.data.is_verfiy_doctor_identity && !ret.data.is_remind_credentials_consent && !ret.data.is_remind_admin_unauthorized);
        setViewPDMPDrawerVisible(showUI === 'Drawer' && !ret.data.is_verfiy_doctor_identity && !ret.data.is_remind_credentials_consent && !ret.data.is_remind_admin_unauthorized);
        if (ret.data.verfiy_doctor_identity_message != "") {
          message.error(ret.data.verfiy_doctor_identity_message);
        }
        setPdmp(ret.data);
      }
    },
  });

  const markDoctorRemind = useRequest(srvDoctorRemindMark, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) { }
    },
  });

  const logPDMPReq = useRequest(srcLogPDMP, {
    manual: true,
    onSuccess: (ret, params) => {},
  });

  const renderScores = (scores) => {
    return (
      <Descriptions title="Scores" column={2}>
        {scores.map((v, i) => {
          return (
            <Descriptions.Item key={i} label={v.ScoreType}>{v.ScoreValue}</Descriptions.Item>
          )
        })}
      </Descriptions>
    )
  }

  const renderMessages = (items) => {
    return (
      <Descriptions title="Messages" column={2}>
        {items.map((v, i) => {
          return (
            <Descriptions.Item key={i} label={v.MessageType}>{v.MessageText}</Descriptions.Item>
          )
        })}
      </Descriptions>
    )
  }

  return (
    <div>
      {flagPDMP && (
        <Button
          size="small"
          type="primary"
          loading={checkPDMPReq.loading}
          onClick={() => {
            checkPDMPReq.run({ user_id: id });
          }}
        >
          Check PDMP
        </Button>
      )}

      {pdmp && (
        <>
          {verfiyIdentityModalVisible && (
            <Modal
              visible={verfiyIdentityModalVisible}
              width={560}
              height={500}
              onCancel={() => {
                setVerfiyIdentityModalVisible(false);
              }}
              onOk={() => {
                form
                  .validateFields()
                  .then((values) => {
                    checkPDMPReq.runAsync(Object.assign({ user_id: id }, values))
                      .catch((errorInfo) => { console.log('hhh:', errorInfo) });
                  });
              }}
            >
              <Spin tip="Loading..." spinning={checkPDMPReq.loading}>
                <h2 style={{ fontWeight: 'bold', marginBottom: '15px' }}>Verify Your Identity</h2>
                <p style={{ marginBottom: '15px' }}>
                  To access the PDMP report for this patient, please verify the following information. Please ensure that you use the license for the state where this patient is located : {pdmp.patient_state}.
                </p>
                <Form
                  form={form}
                  layout="vertical"
                  autoComplete="off"
                  style={{ margin: 0 }}
                >
                  <Form.Item label="DEA" name="dea"
                    rules={[
                      {
                        required: true,
                        message: 'DEA is required',
                      },
                      {
                        pattern: /[A-z]{1}[A-z9]{1}\d{7}([-].[A-z\\d]*)?/,
                        message: 'Invalid DEA'
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Medical License Number" name="license"
                    rules={[
                      {
                        required: true,
                        message: 'Medical License Number is required',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="NPI" name="npi"
                    rules={[
                      {
                        required: true,
                        message: 'NPI is required',
                      },
                      {
                        pattern: /^\d{10}/,
                        message: 'Invalid NPI'
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Form>
                <p style={{ marginTop: '15px', marginBottom: '5px' }}>Disclaimer: Your credentials are protected and required by state regulations.</p>
              </Spin>
            </Modal>
          )}

          {viewPDMPModalVisible && !pdmp.is_verfiy_doctor_identity && !pdmp.is_remind_credentials_consent && !pdmp.is_remind_admin_unauthorized && (
            <Modal title="View PDMP"
              className="pdmpModal"
              visible={viewPDMPModalVisible}
              width={650}
              onCancel={() => { setViewPDMPModalVisible(false); }}
              onOk={() => { setViewPDMPModalVisible(false); }}
              footer={null} >

              <Descriptions title="Patient Report" column={1}>
                <Descriptions.Item label="Report prepared">{pdmp.pull_at ? moment.unix(pdmp.pull_at).format('MM/DD/YYYY') : ''}</Descriptions.Item>
                <Descriptions.Item label="Last accessed">{pdmp.last_access_at ? moment.unix(pdmp.last_access_at).format('MM/DD/YYYY') : ''}</Descriptions.Item>
              </Descriptions>

              <div className="tools">
                <Space>
                  {pdmp.html_url && (<Button size="small" type="primary" onClick={() => {
                    if (pdmp && pdmp.id) logPDMPReq.run({ id: pdmp.id, type: htmlVisible ? 'EndView' : 'StartView' });
                    setHtmlVisible(!htmlVisible);
                  }}>
                    {htmlVisible ? 'close' : 'open'} HTML
                  </Button>
                  )}
                  {pdmp.pdf_url && (<Button size="small" type="primary" onClick={() => {
                    setPdfVisible(!pdfVisible);
                  }}>
                    {pdfVisible ? 'close' : 'open'} PDF
                  </Button>
                  )}
                </Space>
                {pdmp.pdf_url && (
                  <div className="downloadReport">
                    <a target="_blank" href={pdmp.pdf_url}>Download Report <FilePdfOutlined /></a>
                  </div>
                )}
              </div>

              {report && report.NarxScores && report.NarxScores.Score && renderScores(report.NarxScores.Score)}

              {report && report.NarxMessages && report.NarxMessages.Message && renderMessages(report.NarxMessages.Message)}

              {report && report.Message && (
                <p>{report.Message}</p>
              )}

              {pdmp.pdf_url && (
                <Modal title="View PDF"
                  visible={pdfVisible}
                  width={1000}
                  height={1000}
                  onCancel={() => { setPdfVisible(false); }}
                  onOk={() => { setPdfVisible(false); }}
                  footer={null} >
                  <PDFPreview file={pdmp.pdf_url} width={800} />
                </Modal>
              )}

              {pdmp.html_url && (
                <Modal title="View HTML"
                  visible={htmlVisible}
                  width={1200}
                  height={1000}
                  onCancel={() => {
                    if (pdmp && pdmp.id) logPDMPReq.run({ id: pdmp.id, type: 'EndView' });
                    setHtmlVisible(false);
                  }}
                  onOk={() => {
                    if (pdmp && pdmp.id) logPDMPReq.run({ id: pdmp.id, type: 'EndView' });
                    setHTMLVisible(false);
                  }}
                  footer={null} >
                  <iframe src={pdmp.html_url} width="100%" height={1000} style={{ maxWidth: '100%' }} allow="fullscreen"></iframe>
                </Modal>
              )}

            </Modal>
          )}

          {viewPDMPDrawerVisible && !pdmp.is_verfiy_doctor_identity && !pdmp.is_remind_credentials_consent && !pdmp.is_remind_admin_unauthorized && (
            <Drawer
              title="View PDMP"
              width={1000}
              closable={false}
              onClose={() => setViewPDMPDrawerVisible(false)}
              visible={viewPDMPDrawerVisible}
            >
              {pdmp.pdf_url && (<PDFPreview file={pdmp.pdf_url} width={800} />) }
              {pdmp.html_url && (<iframe src={pdmp.html_url} width="100%" height={1000} style={{ maxWidth: '100%' }} allow="fullscreen"></iframe>)}
            </Drawer>
          )}

          {pdmp.is_remind_credentials_consent && (
            <RemindProviderPDMPCredentialsAuthorizeAdminAccessModal
              onCancel={() => {
                markDoctorRemind.run({ key: 'PDMPCredentialsConsentSilent' })
              }}
              onOk={() => {
                markDoctorRemind.run({ key: 'PDMPCredentialsConsentRemind' })
              }}
            />
          )}

          {adminUnauthorizedModelVisible && pdmp.is_remind_admin_unauthorized && (
            <Modal title="Clinician Consent Required"
              width={520}
              visible={adminUnauthorizedModelVisible}
              okText="Ok"
              onOk={() => {
                setAdminUnauthorizedModelVisible(false);
              }}
              onCancel={() => {
                setAdminUnauthorizedModelVisible(false);
              }}
            >
              <p style={{ marginBottom: '20px' }}>You do not have permission to access the PDMP report. The clinician has not yet authorized consent for the use of their credentials.</p>
            </Modal>
          )}
        </>
      )}
    </div>
  )
};

export default PDMPPane;