import {
  srvDoctors,
  srvAddTeam,
  srvTeams,
  srvDeleteTeam,
} from '@/services/admin';

const Model = {
  namespace: 'team',
  state: {
    doctors: [],
    teams: []
  },
  effects: {
    *fetchDoctorList({ payload }, { call, put }) {
      const res = yield call(srvDoctors, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveList', payload: res.data.items });
    },
    *addTeam({ payload }, { call, put }) {
      const res = yield call(srvAddTeam, payload);
        if (!res.success) {
          return;
        }
      window.location.reload();
    },
    *fetchTeamList({ payload }, {call, put}) {
      const res = yield call(srvTeams, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveTeamList', payload: res.data });
    },
    *deleteTeam({ payload, callback }, {call, put}) {
      const res = yield call(srvDeleteTeam, payload);
      if (!res.success) {
        return;
      }
      window.location.reload();
    }
  },
  reducers: {
    saveList(state, { payload }) {
      return { ...state, doctors: payload };
    },
    saveTeamList( state,{ payload}) {
      return { ...state, teams: payload}
    }
  },
};
export default Model;
