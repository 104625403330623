const { pathToRegexp } = require('path-to-regexp');
export const getFlatMenuKeys = (menuData = []) => {
  let keys = [];
  menuData.forEach((item) => {
    if (!item) {
      return;
    }
    keys.push(item.path || '/');
    if (item.children) {
      keys = keys.concat(getFlatMenuKeys(item.children));
    }
  });
  return keys;
};

export const getFlatMenus = (menuData = []) => {
  let menus = {};
  menuData.forEach((item) => {
    if (!item || item.hideInMenu) {
      return;
    }
    menus[item.path || '/'] = item;
    if (item.children && !item.hideChildrenInMenu) {
      menus = { ...menus, ...getFlatMenus(item.children) };
    }
  });
  return menus;
};

export const genKeysToArray = (menuKey) => {
  const keys = menuKey.split('-');
  const keyArray = [];
  keys.forEach((key, index) => {
    if (index === 0) {
      keyArray.push(key);
      return;
    }
    keyArray.push(keys.slice(0, index + 1).join('-'));
  });
  return keyArray;
};
export const getMenuMatches = (flatMenuKeys = [], path) =>
  flatMenuKeys
    .filter((item) => {
      if (item === '/' && path === '/') {
        return true;
      }
      if (item !== '/' && item) {
        // /a
        if (pathToRegexp(`${item}`).test(path)) {
          return true;
        }
        // /a/b/b
        if (pathToRegexp(`${item}(.*)`).test(path)) {
          return true;
        }
      }
      return false;
    })
    .sort((a, b) => {
      // 如果完全匹配放到最后面
      if (a === path) {
        return 10;
      }
      if (b === path) {
        return -10;
      }
      return a.substr(1).split('/').length - b.substr(1).split('/').length;
    })
    .pop();

export const getSelectedMenuKeys = (pathname, flatMenus = {}, flatMenuKeys) => {
  const menuPathKey = getMenuMatches(flatMenuKeys, pathname || '/');
  if (!menuPathKey) {
    return [];
  }

  const menuItem = flatMenus[menuPathKey] || { parentKeys: '', key: '' };
  const keys = [...(menuItem.parentKeys || [])];
  // NOTE: hacks
  if (!menuItem.name && !keys.length) {
     if (menuItem.key.endsWith("/:id")) {
       keys.push(menuItem.key.substring(0, menuItem.key.length-4))
     }
  }
  if (menuItem.key) {
    keys.push(menuItem.key);
  }


  return keys;
};
