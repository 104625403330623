import React, { useEffect, useState } from 'react';
import { Form, Space, Select, Tooltip, Input, Checkbox, DatePicker } from 'antd';
import { formatUnix, formatUnixDay, unixToMomentWithTZ } from '@/utils/xtime';
import moment from 'moment';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { srvPatientDetail } from '@/services/patient';

import CheckPDMPPane from '@/components/PDMPPane';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

export function formatLastRefillDate(v) {
  let name = '';
  if (v === -1) {
    name = 'Not available'
  } else if (v > 0) {
    name = formatUnixDay(v);
  }
  return name;
}


const LastRefillDatePicker = ({ value, onChange }) => {
  const [checked, setChecked] = useState(value === -1);
  const [dateValue, setDateValue] = useState(
    value && value !== -1 ? unixToMomentWithTZ(value) : null
  );

  useEffect(() => {
    if (value === -1) {
      setChecked(true);
      setDateValue(null);
    } else if (value && value !== -1) {
      setChecked(false);
      setDateValue(unixToMomentWithTZ(value));
    }
  }, [value]);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);

    if (isChecked) {
      setDateValue(null);
      onChange(-1);
    } else {
      onChange(null);
    }
  };

  const handleDateChange = (date) => {
    setDateValue(date);
    if (date) {
      onChange(moment(date).unix());
    } else {
      onChange(null);
    }
  };

  return (
    <Space>
      <DatePicker
        format="MM/DD/YYYY"
        value={dateValue}
        onChange={handleDateChange}
        disabled={checked}
        showToday
      />
      <Checkbox checked={checked} onChange={handleCheckboxChange}>
        Not available
      </Checkbox>
      <Tooltip
        placement="top"
        title={`As a reminder, please only select the "Not Available" option if no prescription record is found in the patient's PDMP report.`}
      >
        <QuestionCircleOutlined />
      </Tooltip>
    </Space>
  );
};

const LastRefillDateFormItem = (props) => {
  const { form, patient, noteType, initialValues } = props;

  const [checked, setChecked] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState(null);

  const loadPatientInfoReq = useRequest(srvPatientDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        const lastRefillDate = ret.data?.latest_pdmp_fill_at
        if (lastRefillDate == -1) {
          setChecked(true);
          setDatePickerValue(null);
          form.setFieldsValue({ last_refill_date: -1 })
        } else if (lastRefillDate > 0) {
          setDatePickerValue(unixToMomentWithTZ(lastRefillDate));
          form.setFieldsValue({ last_refill_date: lastRefillDate });
        }
      }
    },
  });

  useEffect(() => {
    if (patient && patient.id) {
      loadPatientInfoReq.run({ id: patient.id })
    }
  }, [noteType, patient])

  return (

    <div className={cx({ lastRefillDateFormItem: true })}>
      <Form.Item
        label="Last Refill Date"
        name="last_refill_date"
        rules={[{
          required: true,
          message: 'Please select last refill date.',
        }]}
      >
        <LastRefillDatePicker />
      </Form.Item>

      {patient?.id && (
        <div className={cx({ lastRefillDateCheckPDMPPane: true })}>
          <CheckPDMPPane id={patient.id} showUI={"Drawer"} />
        </div>
      )}
    </div>

  )

};

export default LastRefillDateFormItem;


