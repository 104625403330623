import React,{ useState,useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';

function AgeBar(props) {
  const {record = []} = props;
  const [option, setOption] = useState({});
  const seriesData = record.map(x => (x.value ? x.value : 0));
  const xAxisData = record.map(c => c.name)
  useEffect(() => {
    setOption({
      title: {
        text: 'Age Bar',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        type: 'category',
        data: xAxisData
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: seriesData,
          type: 'bar'
        }
      ]
    })
  },[record])
  return (
    <div>
      <ReactEcharts
        option={option}
        notMerge
        lazyUpdate
      />
    </div>
  );
}

export default AgeBar;
