import React, { useState, useEffect } from 'react';
import { List } from 'antd';
import moment from 'moment';
import DataSorter from '@/components/DataSorter';
import orderBy from 'lodash/orderBy';
import { ProviderDashboardSection, PatientLink } from './index';
import ConsultationDrawer from '@/components/ConsultationDrawer';
import { srvAssignedConsultations, srvPatientConsultationReplies } from '@/services/patient';

const ConsultationItem = (props) => {
  const { consultation, selectQuestion } = props;


  const currentTime = moment().unix();
  const timeDiff = consultation.patient_reply_at > consultation.assigned_at ? (currentTime - consultation.patient_reply_at) : (currentTime - consultation.assigned_at);
  const isOverDue = timeDiff > 24 * 3600;
 
  const overDueStyle = isOverDue ? {color: '#EC5C55', fontWeight: 'bolder', backgroundColor: '#fefafa'} : {}


  return (
    <div className="detail-table-item" style={overDueStyle} onClick={(e) => {
      selectQuestion(consultation.id);
    }}>
      <div className="detail-patient-name">
        <PatientLink name={consultation.patient.name} id={consultation.patient.id} type="consultation"></PatientLink>
      </div>
      <div className="detail-requested-date">{consultation.assigned_at ?   moment.unix(consultation.assigned_at).fromNow() : moment.unix(consultation.created_at).fromNow()}</div>
      <a
        onClick={(e) => {
          selectQuestion(consultation.id);
        }}
        className="detail-requested-prescription"
      >
        {consultation.title}
      </a>
      <a
        onClick={(e) => {
          selectQuestion(consultation.id);
        }}
        className="detail-patient-consultation-content"
      >
        {consultation.content}
      </a>
    </div>
  );
};

const PationConsultationList = (props) => {
  const { consultationList, onRefresh } = props;
  const [visible, setVisible] = useState(true);
  const [selected, setSelected] = useState('');
  const [replyData, setReplyData] = useState({});
  const [sorter, setSorter] = useState(null);

  const selectQuestion = (id) => {
    srvPatientConsultationReplies({
      id: id,
    })
      .then((res) => {
        setReplyData(res.data);
        setSelected(id);
        setVisible(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onClose = () => {
    setVisible(false);
    //setReplyData({});
    setSelected('');
  };

  const renderItem = (consultation) => {
    return (
      <ConsultationItem
        selectQuestion={selectQuestion}
        setVisible={setVisible}
        key={consultation.id}
        consultation={consultation}
      />
    )
  };

  return (
    <>
      <List
        itemLayout="vertical"
        style={{overflowX: 'auto',overflowY:"hidden"}}
        pagination={consultationList.length > 0 ? { pageSize: 10 } : false}
        locale={{ emptyText: ' ' }}
        dataSource={consultationList}

        header={
          <div className="detail-table-header">
            <div className="detail-patient-name">Patient</div>
            <div className="detail-requested-date">
              <DataSorter title="Time Assigned" sorter={sorter} setSorter={setSorter} />
            </div>
            <div className="detail-requested-prescription">Title</div>
            <div className="detail-patient-consultation-content">Subject</div>
          </div>
        }
        renderItem={renderItem}
      />
      {selected != '' && (
        <ConsultationDrawer
          role="doctor"
          visible={visible}
          replyData={replyData}
          onClose={onClose}
          onReply={() => {
            selectQuestion(selected);
          }}
          onSolve={() => {
            onClose();
          }}
        />
      )}
    </>
  );
};

const PatientConsultation = (props) => {
  const [patientConsultationList, setPatientConsultationList] = useState([]);

  const refresh = () => {
    srvAssignedConsultations({
      status: 1,
      filter_flag: 1
    }).then((res) => {
      if (res.data !== null) {
        setPatientConsultationList(res.data.items);
      }
    });
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <>
      <ProviderDashboardSection
        sectionName="Pending Consultation Message"
        itemNumber={patientConsultationList.length}
        viewAllUrl={"/ehr_consultation"}
      >
        <PationConsultationList
          onRefresh={refresh}
          consultationList={patientConsultationList}
        ></PationConsultationList>
      </ProviderDashboardSection>
    </>
  );
};

export default PatientConsultation;
