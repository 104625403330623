import React, { useState } from 'react';
import cn from 'classnames/bind';

import styles from './index.less';
const cx = cn.bind(styles);

const Marker = (props) => {
  return (
    <div className={cx(styles.markerWrapper, { hover: props.$hover })}>
      <div className={styles.markerArrow} />
      <div className={styles.markerIcon} />
    </div>
  );
};

export default Marker;
