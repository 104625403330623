import React, {useEffect, useState} from 'react';
import {Form, Input, Button, Row, Col, Select, DatePicker, Typography, Drawer, Alert, Space, message, Upload} from 'antd';
import cn from 'classnames/bind';
import styles from './WalletAccountSettingForm.css';
const cx = cn.bind(styles);
import './WalletAccountSettingForm.css';
import {useRequest} from "ahooks";
import {
    srvGetStripeAccountSetting,
    srvSaveStripeAccountSetting,
    srvUpdatePayoutAccountStatus,
    srvUploadStripeFile,
    srvGetProviderImgUrl,
    srvSaveStripeCompanyAccountSetting,
    srvGetStripeCompanyAccountSetting,
    srvSaveRepresentativePerson,
    srvSaveOwnerPerson
} from "@/services/account";
import moment from "moment";
import { UploadOutlined } from '@ant-design/icons';
import {
    srvUploadToken,
  } from '@/services/patient';
import WalletAccountList from "@/components/Dashboard/WalletAccountList";
import { LoadingOutlined, PlusOutlined, FileTextOutlined } from '@ant-design/icons';
const { Title, Paragraph } = Typography;
const { Option } = Select;

const WalletAccountSettingForm = (props) => {
    
    const { accountSettingVisible, setAccountSettingVisible, providerProfile,accountListVisible,closeAccountList } = props;
    const [form] = Form.useForm();
    const [companyForm] = Form.useForm();
    const [representativeForm] = Form.useForm();
    const [ownerForm] = Form.useForm();
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [accountType,setAccountType]=useState('Personal');
    const [connectID,setConnectID]=useState('');
    const [uploadLoading, setUploadLoading] = useState({
        rep_front: false,
        rep_back: false,
        owner_front: false,
        owner_back: false
      });    
    const [url, _setUrl] = useState({
        rep_front_url: false,
        rep_back_url: false,
        owner_front_url: false,
        owner_back_url: false,
    });
    const [docId, _setDocId] = useState({
        rep_front_doc_id: '',
        rep_back_doc_id: '',
        owner_front_doc_id: '',
        owner_back_doc_id: '',
    });
    const [objectKey, _setObjectKey] = useState({
        rep_front_object_key: '',
        rep_back_object_key: '',
        owner_front_object_key: '',
        owner_back_object_key: '',
    });
    const stateList = [
        { label: 'AL' }, { label: 'AK' }, { label: 'AZ' }, { label: 'AR' },
        { label: 'CA' }, { label: 'CO' }, { label: 'CT' }, { label: 'DE' },
        { label: 'FL' }, { label: 'GA' }, { label: 'HI' }, { label: 'ID' },
        { label: 'IL' }, { label: 'IN' }, { label: 'IA' }, { label: 'KS' },
        { label: 'KY' }, { label: 'LA' }, { label: 'ME' }, { label: 'MD' },
        { label: 'MA' }, { label: 'MI' }, { label: 'MN' }, { label: 'MS' },
        { label: 'MO' }, { label: 'MT' }, { label: 'NE' }, { label: 'NV' },
        { label: 'NH' }, { label: 'NJ' }, { label: 'NM' }, { label: 'NY' },
        { label: 'NC' }, { label: 'ND' }, { label: 'OH' }, { label: 'OK' },
        { label: 'OR' }, { label: 'PA' }, { label: 'RI' }, { label: 'SC' },
        { label: 'SD' }, { label: 'TN' }, { label: 'TX' }, { label: 'UT' },
        { label: 'VT' }, { label: 'VA' }, { label: 'WA' }, { label: 'WV' },
        { label: 'WI' }, { label: 'WY' }
    ];
    const titleContent = (
        <div style={{ display: 'flex', alignItems: 'center',justifyContent:accountListVisible?'space-between':'' }}>
            <span>Account Settings</span>
            {!accountListVisible?<Select className="customSelect" disabled={connectID} value={accountType} onChange={(value) => { setAccountType(value);}} style={{ width: '200px', marginLeft: '20px' }}>
                <Option value="Personal">Personal account</Option>
                {(providerProfile.enable_business_payout||connectID)&&<Option value="Business">Business account</Option>}
            </Select>:<Button type="default" onClick={()=>addCount()} style={{ marginRight: 8 }}>Add Account</Button>}
        </div>
    );
    const addCount=()=>{
        form.resetFields();
        companyForm.resetFields();
        closeAccountList();
        setConnectID('');
    }
    const onBeforeUpload = async (file, fieldKeyObject, fileKeyDocId, fileUrl, imgUrl, fileType) => {
        setUploadLoading(prev => ({ ...prev, [fileType]: true }));
    
        try {
            const tokenRes = await srvUploadToken({ type: 1, file_name: file.name });
            const objectKey = tokenRes.data.object_key;
            _setObjectKey(prev => ({ ...prev, [fieldKeyObject]: objectKey }));
            const content = await file.arrayBuffer();
            await fetch(tokenRes.data.upload_url, {
                method: 'PUT',
                headers: { 'Content-Type': file.type },
                body: content,
            });
            const formData = new FormData();
            formData.append('file', file);
            const stripeFileRes = await srvUploadStripeFile(formData);
            _setDocId(prev => ({ ...prev, [fileKeyDocId]: stripeFileRes.data.id }));
    
            const urlRes = await srvGetProviderImgUrl({ key: objectKey });
            form.setFieldsValue({ [fileUrl]: urlRes.data });
            _setUrl(prev => ({ ...prev, [imgUrl]: urlRes.data }));
    
        } catch (err) {
            message.error(`upload error : ${err.message || 'unknow error'}`);
        } finally {
            setUploadLoading(prev => ({ ...prev, [fileType]: false }));
        }
    };
    const onSubmit = () => {
        if (accountType === 'Personal') {
            form.validateFields().then((values) => {
                // birthdate to timestamp string
                values.connect_id=connectID;
                values.birthdate = values.birthdate.unix().toString();
                if (!values.country) {
                    values.country = 'US';
                }
                switch (values.country) {
                    case 'United States':
                        values.country = 'US';
                        break;
                    default:
                        values.country = 'US';
                }
                values.payout_type = 'Personal';
                saveAccountSetting.run(values);
            }).catch((errorInfo) => {
                console.log('Validation Failed:', errorInfo);
            });
        } else {
            companyForm.validateFields().then((values)=>{
                values.connect_id=connectID;
                saveCompanyAccountSetting.run(values);
            }).catch((errorInfo) => {
                console.log('Validation Failed:', errorInfo);
            });
        }
    };
    
    useEffect(() => {
    }, [accountSettingVisible]);
    
    // const saveAccountSetting = useRequest(srvSaveStripeAccountSetting, {
    //     manual: true,
    //     onSuccess: (ret, params) => {
    //         if (ret.success) {
    //             message.success('successfully')
    //             setAccountSettingVisible(false);
    //             accountSettingCallback();
    //         }
    //     },
    // });
    const toEditAccount = (type,connectID)=>{
        closeAccountList();
        setAccountType(type);
        setConnectID(connectID);
        if(type==="Business"){
            getCompanyAccountSetting.run({ connect_id: connectID })
        }else{
            getAccountSetting.run({ connect_id: connectID })
        }
    }
    const saveAccountSetting = useRequest(srvSaveStripeAccountSetting, {
        manual: true,
        onSuccess: async (ret, params) => {
            if (ret.success) {
                if(!ret.data.connect_id){
                    message.error('save fail');
                    return;
                }
                let attempts = 0;
                const maxAttempts = 10;
                setRefreshLoading(true);
                const intervalId = setInterval(async () => {
                    if (attempts >= maxAttempts) {
                        setRefreshLoading(false);
                        clearInterval(intervalId);
                        message.info('Your account is currently being created, please be patient and wait',4);
                        setAccountSettingVisible(false);
                        return;
                    }

                    const statusResult = await srvUpdatePayoutAccountStatus({ connect_id: ret.data.connect_id });
                    if (statusResult.success) {
                        if (statusResult.data.status === 'verified') {
                            setRefreshLoading(false);
                            clearInterval(intervalId);
                            message.success('Status updated successfully');
                            setAccountSettingVisible(false);
                        } else if (statusResult.data.status !== 'pending') {
                            setRefreshLoading(false);
                            clearInterval(intervalId);
                            message.error(statusResult.data.detail,4);
                        }
                    } else {
                        message.error(statusResult.message);
                    }
                    attempts++;
                }, 1000);
            } else {
                message.error('Failed to save account settings');
            }
        },
    });
    const saveCompanyAccountSetting = useRequest(srvSaveStripeCompanyAccountSetting, {
        manual: true,
        onSuccess: async (ret, params) => {
            if (ret.success) {
                if(ret.data.connect_id){
                    setConnectID(ret.data.connect_id);
                }else{
                    message.error('Failed to save account settings')
                    return;
                }
                representativeForm.validateFields().then((values)=>{
                    values.front_doc_id=docId.rep_front_doc_id;
                    values.back_doc_id=docId.rep_back_doc_id;
                    values.front_object_key=objectKey.rep_front_object_key;
                    values.back_object_key=objectKey.rep_back_object_key;
                    values.birthdate=values.birthdate.format('YYYY-MM-DD');
                    values.percent_owner=parseFloat(values.percent_owner);
                    values.connect_id=connectID?connectID:ret.data.connect_id;
                    SaveRepresentativePersonReq.run(values);
                })
            } else {
                message.error('Failed to save account settings');
            }
        },
    });
    const SaveRepresentativePersonReq = useRequest(srvSaveRepresentativePerson, {
        manual: true,
        onSuccess: async (ret, params) => {
            if (ret.success) {
                ownerForm.validateFields().then((values)=>{
                   values.front_doc_id=docId.owner_front_doc_id;
                   values.back_doc_id=docId.owner_back_doc_id;
                   values.front_object_key=objectKey.owner_front_object_key;
                   values.back_object_key=objectKey.owner_back_object_key;
                   values.birthdate=values.birthdate.format('YYYY-MM-DD');
                   values.percent_owner=parseFloat(values.percent_owner);
                   values.connect_id=connectID;
                   SaveOwnerPersonReq.run(values);   
                })
            } else {
                message.error('Failed to save account settings');
            }
        },
    });
    const SaveOwnerPersonReq = useRequest(srvSaveOwnerPerson, {
        manual: true,
        onSuccess: async (ret, params) => {
            if (ret.success) {
                setAccountSettingVisible(false);
                message.success('Saved successfully.');
            } else {
                message.error('Failed to save account settings');
            }
        },
    });
    const getCompanyAccountSetting = useRequest(srvGetStripeCompanyAccountSetting, {
        manual: true,
        onSuccess: (ret) => {
            if (ret.success) {
                let data = ret.data;
                companyForm.setFieldsValue({
                    company_name: data?.company_name,
                    tax_id: data?.tax_id,
                    mcc: data?.mcc,
                    web_url: data?.web_url,
                    email: data?.email,
                    phone: data?.phone,
                    address_line1: data?.address_line1,
                    address_city: data?.address_city,
                    address_state: data?.address_state,
                    zipcode: data?.zipcode,
                    routing_number: data?.routing_number,
                    bank_account: data?.bank_account,
                    bank_hold_name: data?.bank_hold_name
                });
                representativeForm.setFieldsValue({
                    first_name: data?.rep_first_name,
                    last_name: data?.rep_last_name,
                    birthdate: data?.rep_birthdate ? moment(data.rep_birthdate) : null,
                    relation_ship_title: data?.rep_relation_ship_title,
                    percent_owner: data?.rep_percent_owner,
                    id_number: data?.rep_id_number,
                    email: data?.rep_email,
                    phone: data?.rep_phone,
                    address_line: data?.rep_address_line,
                    address_city: data?.rep_address_city,
                    address_state: data?.rep_address_state,
                    address_postal_code: data?.rep_address_postal_code,
                    front_doc_id: data?.rep_front_doc_id,
                    front_object_key: data?.rep_front_object_key,
                    back_doc_id: data?.rep_back_doc_id,
                    back_object_key: data?.rep_back_object_key
                });
                _setDocId(prev => ({
                    ...prev,
                    rep_front_doc_id: data?.rep_front_doc_id,
                    rep_back_doc_id: data?.rep_back_doc_id,
                    owner_front_doc_id: data?.owner_front_doc_id,
                    owner_back_doc_id: data?.owner_back_doc_id
                  }));
                  
                  _setUrl(prev => ({
                    ...prev,
                    rep_front_url: data?.rep_front_url,
                    rep_back_url: data?.rep_back_url,
                    owner_front_url: data?.owner_front_url,
                    owner_back_url: data?.owner_back_url
                  }));
                ownerForm.setFieldsValue({
                    first_name: data?.owner_first_name,
                    last_name: data?.owner_last_name,
                    birthdate: data?.owner_birthdate ? moment(data.owner_birthdate) : null,
                    percent_owner: data?.owner_percent_owner,
                    id_number: data?.owner_id_number,
                    email: data?.owner_email,
                    phone: data?.owner_phone,
                    address_line: data?.owner_address_line,
                    address_city: data?.owner_address_city,
                    address_state: data?.owner_address_state,
                    address_postal_code: data?.owner_address_postal_code,
                    front_doc_id: data?.owner_front_doc_id,
                    front_object_key: data?.owner_front_object_key,
                    back_doc_id: data?.owner_back_doc_id,
                    back_object_key: data?.owner_back_object_key
                });
                _setUrl(prev => ({
                    ...prev,
                    rep_front_url: data?.rep_front_url
                        ? data.rep_front_url
                        : '',
                    rep_back_url: data?.rep_back_url
                        ? data.rep_back_url
                        : '',
                    owner_front_url: data?.owner_front_url
                        ? data.owner_front_url
                        : '',
                    owner_back_url: data?.owner_back_url
                        ? data.owner_back_url
                        : ''
                }));
            } else {
                message.error(ret.message);
            }
        },
    });

    
    const getAccountSetting = useRequest(srvGetStripeAccountSetting, {
        manual: true,
        onSuccess: (ret) => {
            if (ret.success) {
                let data = ret.data;
                form.setFieldsValue({
                    first_name: data?.first_name || providerProfile?.first_name,
                    last_name: data?.last_name || providerProfile?.last_name,
                    email: data?.email || providerProfile?.email,
                    phone: data?.phone || providerProfile?.phone,
                    birthdate: (data.birthdate && data.birthdate !== '0') ? moment.unix(data.birthdate) : null,
                    ssn_last4: data.ssn_last4,
                    address_line1: data?.address_line1 || providerProfile?.address_line1,
                    city: data?.city || providerProfile?.city,
                    state: data?.state || providerProfile?.state,
                    zipcode: data?.zipcode || providerProfile?.zipcode,
                    routing_number: data.routing_number,
                    bank_account: data.bank_account,
                    bank_hold_name: data.bank_hold_name,
                    id_number: data.id_number,
                    invoice_name: data.invoice_name,
                });
            } else {
                message.error(ret.message);
            }
        },
    });
    
    
    const personFormDom = () => {
        return (
            <div style={{ padding: 24 }}>
                <Space
                    direction="vertical"
                    style={{
                        width: '100%',
                    }}
                >
                    <Alert
                        message="Please fill in this information to verify your identity and keep your account secure."
                        type="info"
                        style={{ backgroundColor: '#E6F7FF', borderColor: '#91D5FF' }}
                    />
                </Space>
                <Form className="account-form" layout="vertical" form={form}>
                    <Title level={4}  style={{ marginBottom: '16px', fontSize: '16px'}} >Personal Details</Title>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="first_name" label="Legal First Name" rules={[{ required: true, message: 'Please enter your first name' }]}>
                                <Input placeholder="Legal first name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="last_name" label="Legal Last Name" rules={[{ required: true, message: 'Please enter your last name' }]}>
                                <Input placeholder="Legal last name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item 
                                name="email" 
                                label="Email" 
                                rules={[
                                { required: true, message: 'Please enter your email' },
                                { type: 'email', message: 'Please enter a valid email address' }
                            ]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item 
                                name="phone" 
                                label="Phone Number" 
                                rules={[
                                    { required: true, message: 'Please enter your phone number' },
                                    { pattern: '^[0-9]{3}[0-9]{3}[0-9]{4}$', message: 'Invalid Phone' }
                                ]}
                            >
                                <Input placeholder="Phone number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="birthdate" label="Date of Birth" rules={[{ required: true, message: 'Please select your date of birth' }]}>
                                <DatePicker style={{ width: '100%' }} placeholder="Select date" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item 
                                name="id_number" 
                                label="Social Security Number" 
                                rules={[
                                    { required: true, message: 'Please enter your social security number' },
                                    { pattern: /^\d{9}$/, message: 'Social security number must be 9 digits' },
                                ]}
                            >
                                <Input placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="address_line1" label="Address" rules={[{ required: true, message: 'Please enter your address' }]}>
                                <Input placeholder="Street Address" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item name="city" label="City" rules={[{ required: true, message: 'Please enter your city' }]}>
                                <Input placeholder="City" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="state" label="State" rules={[{ required: true, message: 'Please select your state' }]}>
                                <Select placeholder="Select state">
                                    {stateList.map((state, index) => (
                                        <Option key={index} value={state.label}>
                                            {state.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item 
                                name="zipcode" 
                                label="Zipcode" 
                                rules={[
                                    { required: true, message: 'Zipcode is required' },
                                    { pattern: '^[0-9]{5}$', message: 'Invalid Zipcode' },
                                ]}
                            >
                                <Input placeholder="Zipcode" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item 
                                name="routing_number" 
                                label="Routing Number" 
                                rules={[
                                    { required: true, message: 'Please enter your routing number' },
                                    { pattern: /^\d{9}$/, message: 'Routing number must be 9 digits' },
                                ]}>
                                <Input placeholder=""/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item 
                                name="bank_account" 
                                label="Account Number" 
                                rules={[
                                    { required: true, message: 'Please enter your account number' },
                                    { pattern: /^\d{1,17}$/, message: 'Account number must be between 1 and 17 digits' },
                                ]}>
                                <Input placeholder=""/>
                            </Form.Item>
                        </Col>
                        
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="bank_hold_name" label="Holder Name" rules={[{ required: true, message: 'Please enter the holder name' }]}>
                                <Input placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="invoice_name" label="Invoice Name">
                                <Input placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
    const businessFormDom = () => {
        return (
            <div>
                <Form className="account-form" layout="vertical" form={companyForm}>
                    <Title level={4} style={{ marginBottom: '16px', fontSize: '16px' }} >Company Information</Title>
                    <Row gutter={5}>
                        <Col span={24}>
                            <Form.Item name="company_name" label="Company Name" rules={[{ required: true, message: 'Please enter company Name' }]}>
                                <Input placeholder="Company Name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={10}>
                            <Form.Item name="tax_id" label="TaxID" rules={[{ required: true, message: 'Please enter taxID' }, {
                                pattern: /^\d{9}$/,
                                message: "Tax ID must be 9 digits without special characters."
                            }]}>
                                <Input placeholder="TaxID" />
                            </Form.Item>
                        </Col>
                        <Col span={14}>
                            <Form.Item name="web_url" label="Web URL" rules={[{ required: true, message: 'Please enter web url' }]}>
                                <Input placeholder="Web URL" />
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item name="mcc" label="Mcc" rules={[{ required: true, message: 'Please enter mcc' }, {
                                pattern: /^\d{4}$/,
                                message: 'must be at most 4 characters'
                            }]}>
                                <Input maxLength={4}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }} placeholder="Mcc" />
                            </Form.Item>
                        </Col> */}
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                label="Company Email"
                                rules={[
                                    { required: true, message: 'Please enter company Email' },
                                    { type: 'email', message: 'Please enter a valid email address' }
                                ]}
                            >
                                <Input placeholder="Company Email" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="phone"
                                label="Company Phone Number"
                                rules={[
                                    { required: true, message: 'Please enter company phone number' },
                                    { pattern: '^[0-9]{3}[0-9]{3}[0-9]{4}$', message: 'Invalid Phone' }
                                ]}
                            >
                                <Input placeholder="Company Phone Number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="address_line1" label="Address" rules={[{ required: true, message: 'Please enter company address' }]}>
                                <Input placeholder="Street Address" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item name="address_city" label="City" rules={[{ required: true, message: 'Please enter company city' }]}>
                                <Input placeholder="City" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="address_state" label="State" rules={[{ required: true, message: 'Please select company state' }]}>
                                <Select placeholder="Select state">
                                    {stateList.map((state, index) => (
                                        <Option key={index} value={state.label}>
                                            {state.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="zipcode"
                                label="Zipcode"
                                rules={[
                                    { required: true, message: 'Zipcode is required' },
                                    { pattern: '^[0-9]{5}$', message: 'Invalid Zipcode' },
                                ]}
                            >
                                <Input placeholder="Zipcode" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="routing_number"
                                label="Routing Number"
                                rules={[
                                    { required: true, message: 'Please enter your routing number' },
                                    { pattern: /^\d{9}$/, message: 'Routing number must be 9 digits' },
                                ]}>
                                <Input placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="bank_account"
                                label="Account Number"
                                rules={[
                                    { required: true, message: 'Please enter your account number' },
                                    { pattern: /^\d{1,17}$/, message: 'Account number must be between 1 and 17 digits' },
                                ]}>
                                <Input placeholder="" />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="bank_hold_name" label="Holder Name" rules={[{ required: true, message: 'Please enter the holder name' }]}>
                                <Input placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Form className="account-form" layout="vertical" form={representativeForm}>
                    <Title level={4} style={{ marginBottom: '16px', fontSize: '16px' }} >Company Representative</Title>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item name="first_name" label="Legal First Name" rules={[{ required: true, message: 'Please enter legal first name' }]}>
                                <Input placeholder="Legal First Name" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="last_name" label="Legal Last Name" rules={[{ required: true, message: 'Please enter legal last name' }]}>
                                <Input placeholder="Legal Last Name" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="birthdate" label="Date of Birth" rules={[{ required: true, message: 'Please select date of birth' }]}>
                                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} placeholder="Select date" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="relation_ship_title" label="Title(CEO,Manager,Parnter)" rules={[{ required: true, message: 'Please enter title' }]}>
                                <Input placeholder="Title" />
                            </Form.Item>
                        </Col>
                        {/* <Col span={6}>
                            <Form.Item name="percent_owner" label="Percent Ownership" rules={[
                                {
                                    required: true,
                                    message: 'Please enter percent ownership'
                                },
                                {
                                    pattern: /^\d+(\.\d{1,2})?$/,
                                    message: 'Two decimal places are allowed at most.'
                                }
                            ]}>
                                <Input addonAfter="%"
                                    onKeyPress={(event) => {
                                        const charCode = event.which || event.keyCode;
                                        if (
                                            (charCode < 48 || charCode > 57) && // 0-9
                                            charCode !== 46
                                        ) {
                                            event.preventDefault();
                                        }
                                    }} placeholder="Percent Ownership" />
                            </Form.Item>
                        </Col> */}
                        <Col span={12}>
                            <Form.Item name="id_number" label="Social Security Number" rules={[
                                    { required: true, message: 'Please enter social security number' },
                                    { pattern: /^\d{9}$/, message: 'Social security number must be 9 digits' },
                                ]}>
                                <Input placeholder="Social Security Number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    { required: true, message: 'Please enter Email' },
                                    { type: 'email', message: 'Please enter a valid email address' }
                                ]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="phone"
                                label="Phone Number"
                                rules={[
                                    { required: true, message: 'Please enter phone number' },
                                    { pattern: '^[0-9]{3}[0-9]{3}[0-9]{4}$', message: 'Invalid Phone' }
                                ]}
                            >
                                <Input placeholder="Phone Number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="address_line" label="Address" rules={[{ required: true, message: 'Please enter company address' }]}>
                                <Input placeholder="Street Address" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item name="address_city" label="City" rules={[{ required: true, message: 'Please enter company city' }]}>
                                <Input placeholder="City" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="address_state" label="State" rules={[{ required: true, message: 'Please select company state' }]}>
                                <Select placeholder="Select state">
                                    {stateList.map((state, index) => (
                                        <Option key={index} value={state.label}>
                                            {state.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="address_postal_code"
                                label="Zipcode"
                                rules={[
                                    { required: true, message: 'Zipcode is required' },
                                    { pattern: '^[0-9]{5}$', message: 'Invalid Zipcode' },
                                ]}
                            >
                                <Input placeholder="Zipcode" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="front_object_key_" label="ID Front Photo" rules={[{ required: true, message: 'Please enter company address' }]}>
                                <Upload
                                    name="upload"
                                    showUploadList={false}
                                    beforeUpload={(file) => onBeforeUpload(file,  "rep_front_object_key", "rep_front_doc_id","front_url","rep_front_url","rep_front")}
                                >
                                  {uploadLoading.rep_front ?(<LoadingOutlined />):url.rep_front_url? <img src={url.rep_front_url} alt="avatar"  className="identity-img-small"  /> :<Button icon={<UploadOutlined />}>Upload</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="back_object_key" label="ID Back Photo" rules={[{ required: true, message: 'Please enter company address' }]}>
                                <Upload
                                    name="upload"
                                    showUploadList={false}
                                    beforeUpload={(file) => onBeforeUpload(file,"rep_back_object_key", "rep_back_doc_id","back_url","rep_back_url","rep_back")}
                                >
                                     {uploadLoading.rep_back ?(<LoadingOutlined />):url.rep_back_url? <img src={url.rep_back_url} alt="avatar"  className="identity-img-small"  /> :<Button icon={<UploadOutlined />}>Upload</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Form className="account-form" layout="vertical" form={ownerForm}>
                    <Title level={4} style={{ marginBottom: '16px', fontSize: '16px' }} >Company Owner</Title>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item name="first_name" label="Legal First Name" rules={[{ required: true, message: 'Please enter legal first name' }]}>
                                <Input placeholder="Legal First Name" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="last_name" label="Legal Last Name" rules={[{ required: true, message: 'Please enter legal last name' }]}>
                                <Input placeholder="Legal Last Name" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="birthdate" label="Date of Birth" rules={[{ required: true, message: 'Please select date of birth' }]}>
                                <DatePicker format="YYYY-MM-DD"  style={{ width: '100%' }} placeholder="Select date" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        {/* <Col span={12}>
                            <Form.Item name="percent_owner" label="Percent Ownership" rules={[
                                {
                                    required: true,
                                    message: 'Please enter percent ownership'
                                },
                                {
                                    pattern: /^\d+(\.\d{1,2})?$/,
                                    message: 'Two decimal places are allowed at most.'
                                }
                            ]}>
                                <Input addonAfter="%"
                                    onKeyPress={(event) => {
                                        const charCode = event.which || event.keyCode;
                                        if (
                                            (charCode < 48 || charCode > 57) && // 0-9
                                            charCode !== 46
                                        ) {
                                            event.preventDefault();
                                        }
                                    }} placeholder="Percent Ownership" />
                            </Form.Item>
                        </Col> */}
                        <Col span={24}>
                            <Form.Item name="id_number" label="Social Security Number" rules={[
                                    { required: true, message: 'Please enter social security number' },
                                    { pattern: /^\d{9}$/, message: 'Social security number must be 9 digits' },
                                ]}>
                                <Input placeholder="Social Security Number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    { required: true, message: 'Please enter Email' },
                                    { type: 'email', message: 'Please enter a valid email address' }
                                ]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="phone"
                                label="Phone Number"
                                rules={[
                                    { required: true, message: 'Please enter phone number' },
                                    { pattern: '^[0-9]{3}[0-9]{3}[0-9]{4}$', message: 'Invalid Phone' }
                                ]}
                            >
                                <Input placeholder="Phone Number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="address_line" label="Address" rules={[{ required: true, message: 'Please enter company address' }]}>
                                <Input placeholder="Street Address" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item name="address_city" label="City" rules={[{ required: true, message: 'Please enter company city' }]}>
                                <Input placeholder="City" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="address_state" label="State" rules={[{ required: true, message: 'Please select company state' }]}>
                                <Select placeholder="Select state">
                                    {stateList.map((state, index) => (
                                        <Option key={index} value={state.label}>
                                            {state.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="address_postal_code"
                                label="Zipcode"
                                rules={[
                                    { required: true, message: 'Zipcode is required' },
                                    { pattern: '^[0-9]{5}$', message: 'Invalid Zipcode' },
                                ]}
                            >
                                <Input placeholder="Zipcode" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="front_object_key" label="ID Front Photo" rules={[{ required: true, message: 'Please enter company address' }]}>
                                <Upload
                                    name="upload"
                                    showUploadList={false}
                                    beforeUpload={(file) => onBeforeUpload(file, "owner_front_object_key", "owner_front_doc_id","front_url","owner_front_url","owner_front")}
                                >
                                     {uploadLoading.owner_front ?(<LoadingOutlined />):url.owner_front_url? <img src={url.owner_front_url} alt="avatar" className="identity-img-small"  /> :<Button icon={<UploadOutlined />}>Upload</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="back_object_key" label="ID Back Photo" rules={[{ required: true, message: 'Please enter company address' }]}>
                                <Upload
                                    name="upload"
                                    showUploadList={false}
                                    beforeUpload={(file) => onBeforeUpload(file, "owner_back_object_key", "owner_back_doc_id","back_url","owner_back_url","owner_back")}
                                >
                                     {uploadLoading.owner_back ?(<LoadingOutlined />):url.owner_back_url? <img src={url.owner_back_url} alt="avatar" className="identity-img-small" /> :<Button icon={<UploadOutlined />}>Upload</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
    const onTableClose = () => {
        setAccountSettingVisible(false);
    }
    
    function footerDom() {
        return (
            <Row justify="space-between">
                <Col>
                    <Button type="link" onClick={onTableClose}>Cancel</Button>
                </Col>
                <Col>
                    <Button type="primary" onClick={onSubmit} loading={saveAccountSetting.loading||refreshLoading||saveCompanyAccountSetting.loading
                    ||SaveRepresentativePersonReq.loading||SaveOwnerPersonReq.loading}>Save and Continue</Button>
                </Col>
            </Row>
        );
    }
    
    return (
        <Drawer
            className={cx({ noteDrawer: true })}
            width={650}
            title={titleContent}
            placement="right"
            closable={false}
            setNoteVisible={setAccountSettingVisible}
            onClose={onTableClose}
            visible={accountSettingVisible}
            destroyOnClose={true}
            footer={accountListVisible ? null : footerDom()}
        >
            {accountListVisible?<WalletAccountList
                accountListVisible={accountListVisible}
                toEditAccount={toEditAccount}
            />: <>{accountType === 'Personal' && personFormDom()}
                {accountType === 'Business' && businessFormDom()}</>}
        </Drawer>
    )

    
};

export default WalletAccountSettingForm;
