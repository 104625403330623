import React, { useEffect, useState, useMemo, useCallback } from 'react';

import { srvRxntStatus } from '@/services/patient';
import { useLocation } from 'umi';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';

const useRxnt = (active, id) => {
  const [rxntStatus, setRxntStatus] = useState({});

  const loadRxntStatusReq = useRequest(srvRxntStatus, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setRxntStatus(ret.data);
      }
    },
  });

  const loadData = useCallback(() => {
    if (active) {
      loadRxntStatusReq.run({ id });
    }
  }, []);

  const reloadRxnt = useCallback(() => {
    loadData();
  }, []);

  return useMemo(() => ({ rxntStatus }), [rxntStatus]);
};

export default useRxnt;
