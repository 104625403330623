import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Checkbox } from 'antd';

const { TextArea } = Input;

import { useMount, useUpdateEffect } from 'ahooks';

import {
  initialCompletedOpts,
} from '@/components/AddNote/meta.js';

const DiagnosticCodeFormItem = (props) => {

  const { name, label, form, draft, setDraft } = props;

  const [diagnosisWriteInRequired, setDiagnosisWriteInRequired] = useState(false)

  const onDiagnosticCodeChange = (values) => {
    const hasOther = values.includes('Other (please specify below)');
    setDiagnosisWriteInRequired(hasOther)
    // console.log('onDiagnosticCodeChange:', values, hasOther)
    if (!hasOther) {
      form.setFieldsValue({
        other_diagnosis_notes: undefined,
      });
    }
  }

  useUpdateEffect(() => {
    if (draft && draft.diagnostic_code) {
      setDiagnosisWriteInRequired(draft.diagnostic_code.includes('Other (please specify below)'));
    }
  }, [draft]);

  return (
    <div>
      <Form.Item
        name="diagnostic_code"
        label={label}
        rules={[
          {
            required: true,
            message: 'Diagnostic Code is required',
          },
        ]}
      >
        <Select
          mode={'multiple'}
          placeholder="Select Diagnostic Code"
          options={initialCompletedOpts}
          onChange={onDiagnosticCodeChange}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        />
      </Form.Item>
      {diagnosisWriteInRequired && (
        <Form.Item
          name="other_diagnosis_notes"
          rules={[
            {
              required: diagnosisWriteInRequired,
              message: 'Must provide additional diagnosis notes',
            },
          ]}
        >
          <TextArea rows={10} placeholder="" maxLength={1000} />
        </Form.Item>
      )}
    </div>
  )
};

export default DiagnosticCodeFormItem;