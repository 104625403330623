import React, { useState,useEffect } from 'react';
import { Modal, Typography, Row, Col, Button, message, Select,Spin } from 'antd';
import { srvCreatePayout } from "@/services/account";
import { useRequest } from "ahooks";
import {
    srvDoctorAccounts
} from '@/services/account';
const { Title, Text, Link } = Typography;

const CashOutPopover = (props) => {
    const { showCashOutModel, setShowCashOutModel, currentBalance, cashOutCallback } = props;
    const [accounts, setAccounts] = useState([]);
    const [connectID, setConnectID] = useState('');
    const createPayout = useRequest(srvCreatePayout, {
        manual: true,
        onSuccess: (ret) => {
            if (ret.success) {
                message.success('Cash out successfully');
                setShowCashOutModel(false);
                cashOutCallback();
            }
        },
    });

    const onConfirm = async () => {
        if(connectID===''){
            message.error("Please select an account first.");
            return;
        }
        await createPayout.run({connect_id: connectID});
    }
    useEffect(() => {
        if(showCashOutModel){
            loadDoctorAccountsReq.run();
        }else{
            setAccounts([]);
            setConnectID('');
        }
    }, [showCashOutModel])
    const loadDoctorAccountsReq = useRequest(srvDoctorAccounts, {
        manual: true,
        onSuccess: (ret, params) => {
            if (ret.success) {
                if (ret.data.length > 0) {
                    setAccounts(ret.data);
                    if(ret.data.length===1){
                        setConnectID(ret.data[0].connect_id);
                    }
                }
            }
        },
    });
    return (
        <Modal
            style={{zIndex:'1000'}}
            visible={showCashOutModel}
            onCancel={() => setShowCashOutModel(false)}
            footer={null}
            width={600}
        >
            <Title level={4}>Cash Out</Title>
            <Spin spinning={loadDoctorAccountsReq.loading} tip="Loading...">
            <Row style={{ marginBottom: 16 }}>
                <Col span={24}>
                    <Text strong>Account</Text>
                    <Select
                        id="account_id"
                        style={{ width: '100%' }}
                        value={connectID}
                        onChange={(v) => {
                            setConnectID(v);
                        }}
                    >
                        {accounts.map((v) => (
                            <Select.Option value={v.connect_id} key={v.connect_id}>
                                {v.account_type+' account - *******'+v.bank_account.slice(-3)}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
                <Col span={12}>
                    <Text strong>Current Balance</Text>
                    <div style={{ fontSize: '16px', marginTop: 8 }}>{currentBalance}</div>
                </Col>
                <Col span={12}>
                    <Text strong>Estimated Payout Date</Text>
                    <div style={{ fontSize: '16px', marginTop: 8 }}>1-2 business days</div>
                </Col>
            </Row>
            </Spin>
            <Row justify="space-between" style={{ marginTop: 24 }}>
                <Col>
                    <Button onClick={() => setShowCashOutModel(false)}>Cancel</Button>
                </Col>
                <Col>
                    <Button type="primary" onClick={onConfirm} loading={createPayout.loading}>Confirm</Button>
                </Col>
            </Row>
        </Modal>
    );
}

export default CashOutPopover;