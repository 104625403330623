import {
  srvStates,
  srvAdmins,
  srvAddAdmin,
  srvUpdateAdmin,
  srvActivateAdmin,
  srvDeactivateAdmin,
  srvRequests,
  srvDischargeOrTransfer
} from '@/services/admin';

import{
  srvDoctorRequests
} from '@/services/patient';
import { getAuthority } from '@/utils/authority';

const Model = {
  namespace: 'admin',
  state: {
    data: {
      items: [],
      total: 0,
    },
    requests:{
      items: [],
      total: 0,
    }
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const res = yield call(srvAdmins, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveList', payload: res.data });
    },
    *fetchRequest({ payload }, { call, put }) {
      var res;
      const roles = JSON.parse(localStorage.getItem('done-authority'));
      if (Array.isArray(roles)&& roles.includes('approver')){
        res = yield call(srvDoctorRequests, payload); 
      }else{
       res = yield call(srvRequests, payload); 
      }
      
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveRequestList', payload: res.data });
    },
    *addAdmin({ payload, callback }, { call, put, select }) {
      const res = yield call(srvAddAdmin, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *dischargeOrTransferPatient({ payload, callback }, { call, put }) {
      const res = yield call(srvDischargeOrTransfer, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *updateAdmin({ payload, callback }, { call, put, select }) {
      const res = yield call(srvUpdateAdmin, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *activateAdmin({ payload, callback }, { call, put, select }) {
      const res = yield call(srvActivateAdmin, payload);
      if (!res.success) {
        return;
      }

      if (callback) {
        callback();
      }
    },
    *deactivateAdmin({ payload, callback }, { call, put, select }) {
      const res = yield call(srvDeactivateAdmin, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
  },
  reducers: {
    saveList(state, { payload }) {
      return { ...state, data: payload };
    },
    saveRequestList(state, { payload }) {
      return { ...state, requests: payload };
    },
  },
};

export default Model;
