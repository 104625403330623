import React, { useEffect } from 'react';
import {
  Space,
  Input,
  Select,
  Row,
  Col,
  Button,
  Form,
  Checkbox,
  Divider,
  Tooltip, Radio,
  InputNumber,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import cn from 'classnames/bind';
import styles from './InitialEvaluationNotesForm.less';
const cx = cn.bind(styles);
import { useRequest, useMount, useLocalStorageState } from 'ahooks';

import { srvEditPatientTreatmentPlanHistory } from '@/services/patient';

import { initialCompletedOpts } from '@/components/AddNote/meta.js';
import CheckboxInput from '@/components/InitialEvaluationNote/CheckboxInput';
import RadioGroupInput from '@/components/InitialEvaluationNote/RadioGroupInput';
import PatientReviewRadioGroupInput from "@/components/InitialEvaluationNote/PatientReviewRadioGroupInput";
import AddADHDSubmenu from "@/components/InitialEvaluationNote/ADHDsubmenu";
import DynamicRadioGroupInput from "@/components/InitialEvaluationNote/DynamicRadioGroupInput";
import { PRE_CONSULTATION_REVIEW } from '@/constants'
const { TextArea } = Input;

const InitialEvaluationNotesForm = (props) => {
  const { form, item, onClose } = props;

  const [draft, setDraft] = useLocalStorageState(`InitialEvaluationNote#${item.user_id}`, {});

  useMount(() => {
  });

  useEffect(() => {
    form.setFieldsValue(draft);
  }, [draft]);



  const editHistoryReq = useRequest(srvEditPatientTreatmentPlanHistory, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setDraft({});
      }
      onClose();
    },
  });
  let valueString;
  const handleChange = (value) => {
    valueString = value.toString()
    form.setFieldsValue({
      diagnostic_code: valueString,
    })
  }


  const onAdd = async (values) => {
    try {
      // Transform data
      values.struct_note_data.appointment_start_time = item.StructNoteCommonData.appointment_start_time
      values.struct_note_data.appointment_end_time = item.StructNoteCommonData.appointment_end_time

      // Ensure values exist before calling toString()
      values.prev_medications = values.prev_medications?.toString() || ''
      values.current_medications = values.current_medications?.toString() || ''
      values.rx = values.rx?.toString() || ''

      values.struct_note_data.appointment_start_time = values.struct_note_data.appointment_start_time?.toString() || ''
      values.struct_note_data.appointment_end_time = values.struct_note_data.appointment_end_time?.toString() || ''

      // Boolean conversions
      values.patient_review_no_concerns = values.patient_review_no_concerns === 1 ? false : true
      values.struct_note_data.patient_endorsement = values.struct_note_data.patient_endorsement === 1
      values.drug_allergy = values.drug_allergy === 1
      values.struct_note_data.patient_endorse_psych_history = values.struct_note_data.patient_endorse_psych_history === 1

      // Handle PCP appointment date
      values.struct_note_data.most_recent_pcp_appointment = values.struct_note_data.most_recent_pcp_appointment
        ? Number(values.struct_note_data.most_recent_pcp_appointment.toString().replaceAll('-',''))
        : 0

      // Handle arrays
      if (Array.isArray(values.diagnostic_code)) {
        values.diagnostic_code = values.diagnostic_code.sort().join(';')
      }

      if (Array.isArray(values.review_of_adhd)) {
        values.review_of_adhd = values.review_of_adhd.sort().join(',')
      }

      // Fix patient measurements
      values.patient_height = values.patient_height ? Number(values.patient_height) : 0
      values.patient_weight = values.patient_weight ? Number(values.patient_weight) : 0

      const data = { ...values, id: item.id }
      await editHistoryReq.run(data)
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  const onValuesChange = (changedValues, allValues) => {
    setDraft(allValues);
  };
  if (item.patient_review_no_concerns === true){
    item.patient_review_no_concerns = 0
  }
  if (item.patient_review_no_concerns === false){
    item.patient_review_no_concerns = 1
  }
  if (item.StructNoteCommonData.patient_endorsement === true) {
    item.StructNoteCommonData.patient_endorsement = 'Endorses'
  }
  if (item.StructNoteCommonData.patient_endorsement === false) {
    item.StructNoteCommonData.patient_endorsement = 'Denies'
  }
  if (item.drug_allergy === true) {
    item.drug_allergy = 1
  }
  if (item.drug_allergy === false) {
    item.drug_allergy = 0
  }

  let formattedDate = ""
  for (let char in item.StructNoteCommonData.most_recent_pcp_appointment.toString()) {

    formattedDate += item.StructNoteCommonData.most_recent_pcp_appointment.toString()[char]
    if (char === '3' || char === '5') {
      formattedDate += '-'
    }
  }
  item.StructNoteCommonData.most_recent_pcp_appointment = formattedDate

  if (item.StructNoteCommonData.patient_endorse_psych_history === true) {
    item.StructNoteCommonData.patient_endorse_psych_history = 1
  }
  if (item.StructNoteCommonData.patient_endorse_psych_history === false) {
    item.StructNoteCommonData.patient_endorse_psych_history = 0
  }

  return (
    <div className={cx({ container:true })}>
      <Form
        className={cx({ form: true })}
        layout="vertical"
        form={form}
        onFinish={onAdd}
        onValuesChange={onValuesChange}
        initialValues={item}
        scrollToFirstError={true}
      >
        <div className={cx({ header: true })}>
          <Row>
            <label>Appointment metrics</label>
          </Row>
          <Row>
            <label key={["struct_note_data", "appointment_start_time"]}>Start: {item.StructNoteCommonData.appointment_start_time}</label>
          </Row>
          <Row>
            <label key={["struct_note_data", "appointment_end_time"]}>End: {item.StructNoteCommonData.appointment_end_time}</label>
          </Row>
          <Row>
            <Space/>
            <Space/>
          </Row>
          <h3>Pre-consultation Review</h3>
          <Space direction="vertical">
            {
              PRE_CONSULTATION_REVIEW.map((item, index) => {
                return (
                  <Form.Item name={item.value} valuePropName="checked" noStyle key={item.value}>
                    <Checkbox>{item.label}</Checkbox>
                  </Form.Item>
                )
              })
            }
            <Row>
              <PatientReviewRadioGroupInput
                name="patient_review_no_concerns"
                label="Patient Review"
                form={form}
                draft={draft}
                setDraft={setDraft}
                initialValue={item.patient_review_no_concerns}
              />
            </Row>

          </Space>
        </div>
        <Divider />

        <div className={cx({ header: true })}>

          <h3>Chief Complaints</h3>
          <Form.Item name="chief_complaint">
            <TextArea rows={3} maxLength={1000} />
          </Form.Item>
          <Form.Item name="history_of_present_illness" label={"history of present illness"}>
            <TextArea rows={3} maxLength={1000} />
          </Form.Item>
        </div>
        <Divider />

        <divider className={cx({ header: true })}>
          <Space align="baseline">
            <h3>Presenting Psychiatric Symptoms</h3>
            <Tooltip
              placement="top"
              title="Check items that meet patients’ situation"
              className={cx({ tip: true })}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
          <AddADHDSubmenu
            name="adhd"
            form={form}
            draft={draft}
            setDraft={setDraft}
            isClickedDefault={item.adhd}
          />
          <Form.Item name="adhd_write_in" >
            <TextArea rows={5} span={2} placeholder="" maxLength={1000} />
          </Form.Item>
          <Row>
            <Col span={10}>
              <CheckboxInput
                name="depression"
                label="Depression"
                form={form}
                draft={draft}
                setDraft={setDraft}
              />
            </Col>
          </Row>
          <Form.Item name="depression_write_in" >
            <TextArea rows={5} placeholder="" maxLength={1000} />
          </Form.Item>
          <Row>
            <Col span={10}>
              <CheckboxInput
                name="anxiety"
                label="Anxiety"
                form={form}
                draft={draft}
                setDraft={setDraft}
              />
            </Col>
          </Row>
          <Form.Item name="anxiety_write_in" >
            <TextArea rows={5} placeholder="" maxLength={1000} />
          </Form.Item>
          <Divider/>
          <CheckboxInput
            name={["struct_note_data","mania_hypomania"]}
            label="Mania/Hypomania"
            form={form}
            draft={draft}
            setDraft={setDraft}
            initialValue={false}
          />
          <Tooltip
            placement="top"
            title="Note down more details and symptoms"
            className={cx({ tip: true })}
          >
            <QuestionCircleOutlined />
          </Tooltip>
          <Form.Item name="mania_hypomania_write_in" >
            <TextArea rows={5} placeholder="" maxLength={1000} />
          </Form.Item>
          <h3>Other psychiatric symptoms</h3>
          <Row>
            <CheckboxInput
              name={["struct_note_data", "panic"]}
              label="Panic"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.panic}
            />
            <CheckboxInput
              name={["struct_note_data","insomnia"]}
              label="Insomnia"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.insomnia}
            />
            <CheckboxInput
              name={["struct_note_data","mania_hypomania"]}
              label="Mania/Hypomania"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.mania_hypomania}
            />
            <CheckboxInput
              name={["struct_note_data","other_psych_symptoms"]}
              label="Other symptoms of concern"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.other_psych_symptoms}
            />
          </Row>

          <Form.Item name={["struct_note_data", "psychiatric_symptoms_write_in"]} rules={[
            {
              required: form.getFieldValue(['struct_note_data', 'other_psych_symptoms']),
              message: 'Additional details regarding patient psychiatric symptoms are required'
            },
            {
              validator: (_, reviewValue) => {
                if (form.getFieldValue(['struct_note_data', 'other_psych_symptoms']) && reviewValue === '') {
                  return Promise.reject();
                } else {
                  return Promise.resolve()
                }
              }
            }
          ]}>
            <TextArea rows={2} placeholder="" maxLength={1000} span={2} />
          </Form.Item>
          <h3>Substance Use</h3>
          <Row>
            <CheckboxInput

              name={["struct_note_data", "caffeine_use"]}
              label="Caffeine"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.caffeine_use}
            />
            <CheckboxInput

              name={["struct_note_data", "alcohol_use"]}
              label="Alcohol"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.alcohol_use}
            />
            <CheckboxInput
              name={["struct_note_data", "nicotine_product_use"]}
              label="Nicotine Products"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.nicotine_product_use}
            />
            <CheckboxInput
              name={["struct_note_data", "marijuana_product_use"]}
              label="Marijuana Products"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.marijuana_product_use}
            />
          </Row>
          <Row>
            <CheckboxInput
              name={["struct_note_data", "other_substance_use"]}
              label="Any other substance including, but not limited to: sedatives; opiods; hallucinogens; club drugs"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.other_substance_use}
            />
          </Row>
          <Row>
            <CheckboxInput
              name={["struct_note_data", "substance_abuse_program"]}
              label="Currently in a substance abuse program"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.substance_abuse_program}
            />
          </Row>
          <Form.Item name={["struct_note_data", "substance_abuse_write_in"]} rules={[
            {
              required:  true,
              message: 'Substance use additional detail is required'
            },
          ]} initialValue={item.StructNoteCommonData.substance_abuse_write_in}>
            <TextArea rows={2} placeholder="" maxLength={1000} />
          </Form.Item>
          <Row>
            <h3>Psychiatric History</h3>
          </Row>
          <Row>
            <CheckboxInput
              name="past_history_of_suicide_attempts"
              label="Past History of suicide attempts"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.past_history_of_suicide_attempts}
            />
            <CheckboxInput
              name="homicidal_ideation"
              label="Homicidal ideation"
              form={form}
              draft={draft}
              setDraft={setDraft}
            />
            <CheckboxInput
              name="history_of_trauma_or_abuse"
              label="History of trauma/abuse"
              form={form}
              draft={draft}
              setDraft={setDraft}
            />
          </Row>
          <Row>
            <CheckboxInput
              name="self_harm"
              label="Self-injurious behaviors (ie: cutting)"
              form={form}
              draft={draft}
              setDraft={setDraft}
            />
            <CheckboxInput
              name={["struct_note_data", "psych_hospitalization"]}
              label="Past History of Psychiatric Hospitalizations"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.psych_hospitalization}
            />
          </Row>
          <Row>
            <CheckboxInput
              name="previous_adhd_diagnosis"
              label="Previous diagnosis of ADHD"
              form={form}
              draft={draft}
              setDraft={setDraft}
            />
            <CheckboxInput
              name="family_adhd_history"
              label="Family history of ADHD"
              form={form}
              draft={draft}
              setDraft={setDraft}
            />
          </Row>
          <Form.Item name="psychiatric_history_write_in" label={'Psychiatric history comments'} initialValue={item.StructNoteCommonData.psychiatric_history_write_in}>
            <TextArea rows={5} span={2} placeholder="" maxLength={1000} />
          </Form.Item>
          <Form.Item label={"Patient endorses or denies any history of, or current concerns relating to: " +
            "trauma or abuse, intrusive thoughts, paranoid or delusional thoughts, psychosis, A/V hallucinations, " +
            "cognitive alterations or acute decline in memory, or eating disorder behaviors."}
                     name={["struct_note_data", "patient_endorse_psych_history"]} initialValue={item.StructNoteCommonData.patient_endorse_psych_history} >
            <Radio.Group>
              <Radio value={0} col={10}>Denies</Radio>
              <Radio value={1} col={10} span={2}>Endorses</Radio>

            </Radio.Group>
          </Form.Item>
          <Form.Item name="current_psy_and_ther" label={'Current Psychiatrist and Therapist'}>
            <Input />
          </Form.Item>
          <Row>

            <Col span={10}>
              <Row>
                <h4>Previous medications  </h4>
                <Tooltip
                  placement="right"
                  title="Prefilled information from patient intake form"
                  className={cx({ tip: true })}
                >

                  <QuestionCircleOutlined />
                </Tooltip>
              </Row>
              <Form.Item
                name="prev_medications"
                label={
                  <Space align="baseline" />
                }
              >
                <TextArea/>
              </Form.Item>
            </Col>
            <Col span={10} offset={2}>
              <Row>
                <h4>Current Medications and Supplements  </h4>
                <Tooltip
                  placement="right"
                  title="Prefilled information from patient intake form"
                  className={cx({ tip: true })}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Row>
              <Form.Item
                name="current_medications"
                label={
                  <Space align="baseline" />
                }
              >
                <TextArea/>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <h3>Medication & Physical History</h3>
          <Row>
            <RadioGroupInput
              name="drug_allergy"
              label="Medicine allergy"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.drug_allergy}
            />
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item

                name={["struct_note_data", "current_pcp"]}
                label={'Current PCP'}
                initialValue={item.StructNoteCommonData.current_pcp}
              >
                <Input placeholder={'Primary Care Provider'}/>
              </Form.Item>
            </Col>
            <Col span={10} offset={2}>
              <Form.Item
                name={["struct_note_data", "most_recent_pcp_appointment"]}
                label={'Last PCP appointment date'}
                initialValue={item.StructNoteCommonData.most_recent_pcp_appointment}
              >
                <Input type="date"/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item name="patient_weight" label="Weight">
                <InputNumber
                  defaultValue="1"
                  min="0"
                  max="1000"
                  step="0.1"
                  stringMode
                  style={{ width: '100%' }}/>
              </Form.Item>
            </Col>
            <Col span={10} offset={2}>
              <Form.Item name="patient_height" label="Height">
                <InputNumber
                  defaultValue="1"
                  min="0"
                  max="1000"
                  step="0.1"
                  stringMode
                  style={{ width: '100%' }}/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <h4>Reported Medical Conditions</h4>
          </Row>
          <Row>
            <CheckboxInput
              name={["struct_note_data", "heart_arrhythmias"]}
              label="Heart arrhythmias"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.heart_arrhythmias}
            />
            <CheckboxInput
              name={["struct_note_data", "hypertension"]}
              label="Hypertension"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.hypertension}
            />
            <CheckboxInput
              name={["struct_note_data", "stroke"]}
              label="Stroke"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.stroke}
            />
          </Row>
          <Row>
            <CheckboxInput
              name={["struct_note_data", "seizure"]}
              label="Seizures"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.seizure}
            />
            <CheckboxInput
              name={["struct_note_data", "other_major_neuro_or_cardio_disorders"]}
              label="Major neurological, cardiac or other disorder(s)"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.other_major_neuro_or_cardio_disorders}
            />
          </Row>
          <Form.Item name={["struct_note_data", "reported_medical_conditions"]} label="reported medical conditions" initialValue={item.StructNoteCommonData.reported_medical_conditions_write_in}>
            <TextArea rows={5} span={2} placeholder="" maxLength={1000} />
          </Form.Item>
          <Row>
            <h4>Patient endorses or denies the presence of other physical medical conditions</h4>
          </Row>
          <Row>
            <DynamicRadioGroupInput
              name={["struct_note_data", "patient_endorsement"]}
              label="Patient endorses or denies other medical conditions"
              form={form}
              draft={draft}
              setDraft={setDraft}
              checkboxValues={['Endorses', 'Denies']}
              initialValue={item.StructNoteCommonData.patient_endorsement}

            />
          </Row>
          <Row>
            <h4>Family Planning</h4>
          </Row>
          <Row>
            <CheckboxInput
              name={["struct_note_data", "pregnant_or_family_planning"]}
              label="Currently pregnant or family planning"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.pregnant_or_family_planning}
            />
            <CheckboxInput
              name={["struct_note_data", "currently_breastfeeding"]}
              label="Currently Breastfeeding"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.currently_breastfeeding}
            />
            <CheckboxInput
              name={["struct_note_data", "currently_using_birth_control"]}
              label="Currently Using Birth Control"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.currently_using_birth_control}
            />
          </Row>
          <Form.Item name={["struct_note_data", "family_planning_write_in"]} initialValue={item.StructNoteCommonData.family_planning_write_in}>
            <TextArea rows={2} placeholder="" maxLength={1000} />
          </Form.Item>
          <Divider/>
          <h3>Social & Family History</h3>
          <Form.Item name="social_family_history" initialValue={item.StructNoteCommonData.social_family_history}>
            <TextArea rows={10} maxLength={1000} />
          </Form.Item>
          <Divider/>

          <h3>Mental Status Exam</h3>
          <Space/>
          <Row>
            <label> Observation</label>
          </Row>
          <Row>
            <DynamicRadioGroupInput
              name={["struct_note_data", "observation_appearance"]}
              label="Appearance"
              form={form}
              draft={draft}
              setDraft={setDraft}
              checkboxValues={['Neat', 'Disheveled', 'Inappropriate', 'Bizarre', 'Other']}
              initialValue={item.StructNoteCommonData.observation_appearance}
            />
          </Row>
          <DynamicRadioGroupInput
            name={["struct_note_data", "observation_speech"]}
            label="Speech"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Normal', 'Tangential', 'Impoverished', 'Pressured', 'Other']}
            initialValue={item.StructNoteCommonData.observation_speech}
          />
          <DynamicRadioGroupInput
            name={["struct_note_data", "observation_eye_contact"]}
            label="Eye Contact"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Normal', 'Intense', 'Avoidant', 'Other']}
            initialValue={item.StructNoteCommonData.observation_eye_contact}
          />
          <DynamicRadioGroupInput
            name={["struct_note_data", "observation_motor_activity"]}
            label="Motor Activity"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Normal', 'Restless', 'Tics', 'Slowed', 'Other']}
            initialValue={item.StructNoteCommonData.observation_motor_activity}
          />
          <DynamicRadioGroupInput
            name={["struct_note_data", "observation_affect"]}
            label="Affect"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Full', 'Constricted', 'Labile', 'Flat', 'Other']}
            initialValue={item.StructNoteCommonData.observation_affect}
          />
          <Divider/>
          <label>Cognition</label>

          <DynamicRadioGroupInput
            name={["struct_note_data", "cognition_orientation_impair"]}
            label="Orientation Impairment"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['None', 'Person', 'Place', 'Time', 'Other']}
            initialValue={item.StructNoteCommonData.cognition_orientation_impair}
          />
          <DynamicRadioGroupInput
            name={["struct_note_data", "cognition_memory_impair"]}
            label="Memory Impairment"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Normal', 'Short-Term', 'Long-Term', 'Other']}
            initialValue={item.StructNoteCommonData.cognition_memory_impair}
          />
          <DynamicRadioGroupInput
            name={["struct_note_data", "cognition_attention"]}
            label="Attention"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Normal', 'Distracted', 'Other']}
            initialValue={item.StructNoteCommonData.cognition_attention}
          />
          <Divider/>
          <label>Perception</label>

          <DynamicRadioGroupInput
            name={["struct_note_data", "perception_hallucinations"]}
            label="Hallucinations"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Normal', 'Auditory', 'Visual', 'Other']}
            initialValue={item.StructNoteCommonData.perception_hallucinations}
          />
          <DynamicRadioGroupInput
            name={["struct_note_data", "perception_other"]}
            label="Other"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['None', 'Derealization', 'Depersonalization']}
            initialValue={item.StructNoteCommonData.perception_other}
          />
          <Divider/>
          <label>Thoughts</label>

          <DynamicRadioGroupInput
            name={["struct_note_data", "thoughts_suicidality"]}
            label="Suicidality"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['None', 'Ideation', 'Plain', 'Intent', 'Self-Harm']}
            initialValue={item.StructNoteCommonData.thoughts_suicidality}
          />
          <DynamicRadioGroupInput
            name={["struct_note_data", "thoughts_homicidality"]}
            label="Homicidality"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['None', 'Agressive', 'Intent', 'Plan']}
            initialValue={item.StructNoteCommonData.thoughts_homicidality}
          />
          <DynamicRadioGroupInput
            name={["struct_note_data", "thoughts_delusions"]}
            label="Delusions"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['None', 'Grandiose', 'Paranoid', 'Religious', 'Plan']}
            initialValue={item.StructNoteCommonData.thoughts_delusions}
          />
          <Divider/>
          <DynamicRadioGroupInput
            name={["struct_note_data", "behavior"]}
            label="Behavior"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Cooperative', 'Shy/Guarded', 'Hyperactive', 'Agitated', 'Agressive', 'Paranoid', 'Bizarre', 'Withdrawn']}
            initialValue={item.StructNoteCommonData.behavior}
          />
          <DynamicRadioGroupInput
            name={["struct_note_data", "insight"]}
            label="Insight"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Good', 'Fair', 'Poor']}
            initialValue={item.StructNoteCommonData.insight}
          />
          <DynamicRadioGroupInput
            name={["struct_note_data", "judgement"]}
            label="Judgement"
            form={form}
            draft={draft}
            setDraft={setDraft}
            checkboxValues={['Good', 'Fair', 'Poor']}
            initialValue={item.StructNoteCommonData.judgement}
          />
          <Row>
            <label>Mood</label>
            <CheckboxInput
              name={["struct_note_data", "mood_euthymic"]}
              label="Euthymic"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.mood_euthymic}
            />
            <CheckboxInput
              name={["struct_note_data", "mood_anxious"]}
              label="Anxious"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.mood_anxious}
            />
            <CheckboxInput
              name={["struct_note_data", "mood_angry"]}
              label="Angry"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.mood_angry}
            />
            <CheckboxInput
              name={["struct_note_data", "mood_depressed"]}
              label="Depressed"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.mood_depressed}
            />
            <CheckboxInput
              name={["struct_note_data", "mood_euphoric"]}
              label="Euphoric"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.mood_euphoric}
            />
            <CheckboxInput
              name={["struct_note_data", "mood_irritable"]}
              label="Irritable"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.mood_irritable}
            />
            <CheckboxInput
              name={["struct_note_data", "mood_other"]}
              label="Other"
              form={form}
              draft={draft}
              setDraft={setDraft}
              initialValue={item.StructNoteCommonData.mood_other}
            />
          </Row>
          <Form.Item name={["struct_note_data", "mood_write_in"]} initialValue={item.StructNoteCommonData.mood_write_in}>
            <TextArea rows={5} span={2} placeholder="" maxLength={1000} />
          </Form.Item>

          <div className={cx({ header: true })}>
            <h3>Diagnosis*</h3>
            <Form.Item
              name="diagnostic_code"
              rules={[
                {
                  required: true,
                  message: 'Diagnostic Code is required',
                },
              ]}
            >
              <Select
                mode={"multiple"}
                placeholder="Select Diagnostic Code"
                options={initialCompletedOpts}
                onChange={handleChange}
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
            <Form.Item name="other_diagnosis_notes" >
              <TextArea rows={10} placeholder="" maxLength={1000} />
            </Form.Item>



          </div>
          <Divider />

          <div className={cx({ header: true })}>
            <h3>Treatment & Prescription</h3>
            <div className={cx({ rxTitle: true })}>
              <label htmlFor="rx" title="Rx" className={cx({ rxLabel: true })}>
                Rx
              </label>
            </div>
            <Form.Item
              name="rx"
              rules={[
                {
                  required: false,
                  message: 'Rx is required',
                },
              ]}
            >
              <Input placeholder="Rx" />
            </Form.Item>
            <Form.Item name="no_controlled_substance_needed" valuePropName="checked">
              <Checkbox>
              <span>&nbsp;Patient does not need controlled substances (in-person evaluation/referral not required).</span>
              </Checkbox>
           </Form.Item>
            <Form.Item
              name="provider_notes"
              label={
                <Space align="baseline">
                  <h4>Treatment Notes</h4>
                  <Tooltip
                    placement="top"
                    title="Edit the template below with your patient's details"
                    className={cx({ tip: true })}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Space>
              }
              rules={[
                {
                  required: true,
                  message: 'Treatment notes is required',
                },
              ]}
            >
              <TextArea rows={8}  maxLength={5000} defaultValue={'provider notes'} />
            </Form.Item>

            <Form.Item name="patient_agree_treatment_plan" valuePropName="checked" noStyle rules={[{ required: true }]}>
              <Checkbox>Patient agrees to start the treatment plan</Checkbox>
            </Form.Item>
          </div>
          <Divider />

          <div>
            <Space align="baseline">
              <h3>First Message to Patient</h3>
              <Tooltip
                placement="top"
                title="Edit the template below with your patient's details"
                className={cx({ tip: true })}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </Space>

            <Form.Item name="message_to_patient" label="Message To Patient">
              <TextArea rows={8} maxLength={20000} />
            </Form.Item>
          </div>
          <Form.Item name="clinician_accuracy_testament" valuePropName="checked" label="Clinician Accuracy Testament" rules={[{ required: true }]}>
            <Checkbox>I attest to the accuracy and completeness of this note.</Checkbox>
          </Form.Item>
          <Divider />

          <div className={cx({ header: true })}>

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Save note & send message
              </Button>
            </Form.Item>
          </div>
        </divider>
      </Form>
    </div>
  );
};

export default InitialEvaluationNotesForm;
