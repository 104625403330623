export const INATTENTIVENESS = [
  {
    label: 'Problems following through on directions/unfinished tasks',
    value: 'adhd_following_through_unfinished_tasks',
  },
  { label: 'Difficulty with organization', value: 'adhd_difficulty_with_organization' },
  { label: 'Is often forgetful', value: 'adhd_forgetful' },
  {
    label: 'Reluctance to engage in tasks requiring sustained attention',
    value: 'adhd_reluctance_engage_sustained_attention',
  },
  {
    label: 'Failing to give close attention to detail/careless mistakes',
    value: 'adhd_failing_attention_to_detail',
  },
  { label: 'Difficulty sustaining attention', value: 'adhd_sustained_attention' },
  { label: 'Problems listening when spoken to directly', value: 'adhd_problems_listening' },
  {
    label: 'Misplaces things necessary for tasks and activities',
    value: 'adhd_misplaced_necessary',
  },
  { label: 'Easily distracted by extraneous stimuli', value: 'adhd_distracted_external_stimuli' },
];

export const HYPERACTIVITY_IMPULSIVITY = [
  { value: 'adhd_fidget_squirms', label: 'Fidgets/squirms' },
  { value: 'adhd_always_on_the_go', label: 'Always on the go, driven by a motor' },
  {
    value: 'adhd_leaves_seat_when_unexpected',
    label: 'Often leaves seat when remaining seated is expected',
  },
  { value: 'adhd_restlessness', label: 'Restlessness' },
  {
    value: 'adhd_trouble_engaging_leisure_activity',
    label: 'Trouble engaging in leisure activities',
  },
  { value: 'adhd_talks_excessively', label: 'Talks excessively' },
  {
    value: 'adhd_blurts_out_answers_or_completes_others_sentences',
    label: 'Blurts out answers/completes other people sentences',
  },
  { value: 'adhd_difficulty_waiting_for_turn', label: 'Difficulty waiting their turn' },
  { value: 'adhd_interrupts_or_intrudes_on_others', label: 'Interrupts or intrudes on others' },
];

export const OTHER_PSYCHIATRIC_SYMPTOMS = [
  { value: 'panic', label: 'Panic' },
  { value: 'insomnia', label: 'Insomnia' },
  { value: 'other_psych_symptoms', label: 'Other symptoms of concern' },
];

export const SUBSTANCE_USE = [
  { value: 'caffeine_use', label: 'Caffeine' },
  { value: 'alcohol_use', label: 'Alcohol' },
  { value: 'nicotine_product_use', label: 'Nicotine Products' },
  { value: 'marijuana_product_use', label: 'Marijuana Products' },
  {
    value: 'other_substance_use',
    label:
      'Any other substance including, but not limited to: sedatives; opioids; hallucinogens; club drugs',
  },
  { value: 'substance_abuse_program', label: 'Currently in a substance abuse program' },
];

export const PSYCHIATRIC_HISTORY = [
  { value: 'past_history_of_suicide_attempts', label: 'Past history of suicide attempts' },
  { value: 'homicidal_ideation', label: 'Homicidal ideation' },
  { value: 'history_of_trauma_or_abuse', label: 'History of trauma or abuse' },
  { value: 'self_harm', label: 'Self-injurious behaviors (ie: cutting)' },
  { value: 'psych_hospitalization', label: 'Past History of Psychiatric Hospitalizations' },
  { value: 'previous_adhd_diagnosis', label: 'previous adhd diagnosis' },
  { value: 'family_adhd_history', label: 'family history adhd' },
];

export const REPORTED_MEDICAL_CONDITIONS = [
  { value: 'heart_arrhythmias', label: 'Heart arrhythmias' },
  { value: 'hypertension', label: 'Hypertension' },
  { value: 'stroke', label: 'Stroke' },
  { value: 'seizure', label: 'Seizure' },
  {
    value: 'other_major_neuro_or_cardio_disorders',
    label: 'Major neurological, cardiac or other disorder(s)',
  },
];

export const FAMILY_PLANNING = [
  { value: 'pregnant_or_family_planning', label: 'Currently pregnant or family planning' },
  { value: 'currently_breastfeeding', label: 'Currently Breastfeeding' },
  { value: 'currently_using_birth_control', label: 'Currently Using Birth Control' },
];

export const MOOD = [
  { value: 'mood_euthymic', label: 'Euthymic' },
  { value: 'mood_anxious', label: 'Anxious' },
  { value: 'mood_angry', label: 'Angry' },
  { value: 'mood_depressed', label: 'Depressed' },
  { value: 'mood_euphoric', label: 'Euphoric' },
  { value: 'mood_irritable', label: 'Irritable' },
  { value: 'mood_other', label: 'Other' },
];

export const PRE_CONSULTATION_REVIEW = [
  {
    label: 'ASRS, PHQ-9, GAD-7 and other screening results reviewed.',
    value: 'screening_results_reviewed',
  },
  {
    label: 'Patient PDMP reviewed.',
    value: 'patient_pdmp_reviewed',
  },
  {
    label: 'Patient consent to be seen via telemedicine.',
    value: 'consent_telemedicine',
  },
];

export const TREATMENT_PLAN_NOTE_TYPES = {
  INITIAL_EVALUATION: 1,
  FOLLOW_UP_EVALUATION: 2,
  REFILL_REQUEST_NOTE: 3,
  TRANSFER: 4,
  DISCHARGE: 5,
  THERAPIST_GENERIC: 6,
  COVERAGE: 7,
  ERROR_NOTE: 0,
  PLAIN_NOTE: 8,
  ASYNC_VIDEO: 9,
  COACHING_NOTE: 10,
  TRANSFER_OF_CARE: 11,
};

export const PRE_INITIAL_APPOINTMENT_START_REVIEW = [
  {
    label: 'Patient presents for a psychiatric intake appointment.',
    value: 'presents_psychiatric_intake_appointment',
  },
  {
    label: 'Patient consents to treatment and consultation via telemedicine (remote video visit).',
    value: 'consent_telemedicine_treatment_consultation',
  },
  {
    label: 'Patient was informed of limitations of telemedicine; oral and written consent were received.',
    value: 'informed_received_limitations_telemedicin',
  },
];

export const PRE_INITIAL_APPOINTMENT_END_REVIEW = [
  {
    label: 'Reviewed therapeutic duration of medication, dosing strategies, side effects, and assessing improvements in symptoms.',
    value: 'reviewed_therapeutic_medication_symptoms',
  },
  {
    label: 'Patient to follow up with PCP for routine health monitoring and maintenance.',
    value: 'agrees_health_monitoring_maintenance',
  },
  {
    label: 'Patient agrees to coordinate care as needed.',
    value: 'agrees_coordinate_care',
  },
  {
    label: 'Discussed various treatment options in detail.Risk vs Benefits, potential side effects, alternatives including no treatment discussed.',
    value: 'discussed_various_treatment_options',
  },
  {
    label: 'Patient agrees to the treatment plan.',
    value: 'agrees_treatment_plan',
  },
];

