import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox, Space } from 'antd';

import { useMount, useUpdateEffect } from 'ahooks';

// import cn from 'classnames/bind';
// import styles from './CheckboxInput.less';
// const cx = cn.bind(styles);

const CheckboxTextInputFormItem = (props) => {
  const { name, label, form, draft, setDraft, layout = 'vertical' } = props;

  // useUpdateEffect(() => {
  //   if (draft && draft[name]) setChecked(true);
  // }, [draft]);

  const onInputChange = (e) => {
    const value = String(e.target.value).trim();
    // console.log('onInputChange:', e, value, value.length);
    const field = {}
    field[name] = value.length ? true : false;
    field[`${name}_text`] = value;
    form.setFieldsValue(field);
    setDraft(Object.assign({ ...draft }, field));
  };

  const onCheckboxChange = (e) => {
  }

  if (layout === 'horizontal') { 
    return (
      <Form.Item>
        <Space style={{ width: '100%' }}>
          <Form.Item name={name} valuePropName="checked" noStyle>
            <Checkbox onChange={onCheckboxChange}>{label}</Checkbox>
          </Form.Item>
          <Form.Item name={`${name}_text`} noStyle>
            <Input onChange={onInputChange} />
          </Form.Item>
        </Space>
      </Form.Item>
    )
  }

  return (
    <Form.Item>
      <Form.Item name={name} valuePropName="checked" noStyle>
        <Checkbox onChange={onCheckboxChange}>{label}</Checkbox>
      </Form.Item>
      <Form.Item name={`${name}_text`} noStyle>
        <Input style={{ marginLeft: '24px', width: 'calc(100% - 24px)' }} onChange={onInputChange} />
      </Form.Item>
    </Form.Item>
  )
};

export default CheckboxTextInputFormItem;
