import React, { useState, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link,useAccess } from 'umi';
import { formatUnix } from '@/utils/xtime.js';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import request from 'umi-request';
import moment from 'moment';
import defaultSettings from '../../../defaultSettings';
import { AsYouType } from 'libphonenumber-js';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import PDFViewer from '@/components/PDFViewer';
import { useFlag } from '@unleash/proxy-client-react';
import {
  EllipsisOutlined,
  SearchOutlined,
  createFromIconfontCN,
  FileTextOutlined,
  CalendarFilled,
  EnvironmentFilled,
} from '@ant-design/icons';

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

import {
  Spin,
  Modal,
  Space,
  Popover,
  Input,
  Table,
  Row,
  Button,
  message,
  Drawer,
  Alert,
  Form,
  Checkbox,
  Select,
} from 'antd';

import { srvUploadToken } from '@/services/patient';

import Filters from '@/components/Filters';
import PDFPreview from '@/components/PDFPreview';

import styles from './index.less';
const cx = cn.bind(styles);

import {
  srvAdminMedicalFiles,
  srvAdminGetMedicalFileLink,
  srvAdminApproveMedicalFileRequest,
  srvAdminMedicalFileUploadToken,
  srvAdminGetMedicalFile,
  srvAdminUpdateMedicalFile,
  srvEsPatients,
  srvAdminUpdateMedicalFileRequestDoctor,
} from '@/services/admin';

const filters = [
  {
    name: 'patient_name',
    label: 'Patient Name',
    type: 'text',
  },
  {
    name: 'doctor_name',
    label: 'Doctor Name',
    type: 'text',
  },
  {
    name: 'status',
    label: 'Status',
    type: 'checkbox',
    items: [
      { label: 'New', value: 0 },
      { label: 'RevisionNeeded', value: 1 },
      { label: 'PendingProviderApproval', value: 2 },
      { label: 'PendingProviderApproval (Overdue)', value: 5 },
      { label: 'Approved', value: 3 },
    ],
  },
  {
    name: 'file_type',
    label: 'File Type',
    type: 'checkbox',
    items: [
      { label: 'Medical Record', value: 0 },
      { label: 'Assessment Result', value: 1 },
    ],
  },
];

const MedicalFiles = (props) => {
  const [filterState, setFilterState] = useState({});
  const [query, setQuery] = useState({});

  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedMedicalFile, setSelectedMedicalFile] = useState({});
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedClinicianId, setSelectedClinicianId] = useState(null);
  const access=useAccess();
  const handleSearch = async (value) => {
    const filterContent = {};
    filterContent.name = value;
    filterContent.role = 'doctor';
    const response = await srvEsPatients({
      filters: JSON.stringify(filterContent),
      page: 1,
      page_size: 10,
    });
    if (response.success) {
      setOptions(response.data.items.map(item => ({
        label: item.name,
        value: item.doctor_id,
      })));
    }
  }
  const handleChangeClinician = () => {
    assignDoctorReq.run({
      id: selectedMedicalFile.id,
      doctor_id: selectedClinicianId,
    });
  }
  const assignDoctorReq = useRequest(srvAdminUpdateMedicalFileRequestDoctor, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setShowChangeModal(false);
        refreshList();
      }
    },
  });

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => {});
  };

  const listReq = useRequest(srvAdminMedicalFiles, {
    manual: true,
    onSuccess: (ret, params) => {
      setData(ret.data);
    },
  });

  useMount(() => {
    loadList({ page: 1, page_size: 20 });
  });

  const loadList = (params) => {
    listReq.run(params);
  };

  const refreshList = () => {
    loadList({ page: page, page_size: 20 });
  };

  const columns = [
    {
      title: 'Patient',
      dataIndex: 'patient_name',
      key: 'patient_name',
      ellipsis: true,
      render: (v, r) => {
        if (!r.patient) {
          return null;
        }
        const userName = `${r.patient.first_name} ${r.patient.last_name}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.user_id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/patient/${r.user_id}`}>{r.patient.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.patient.phone)}
                </div>
                <div className="popover-user-item birthdate">
                  <CalendarFilled className="icon" />
                  {r.patient.birthdate &&
                    moment.unix(r.patient.birthdate).utc().format('MM/DD/YYYY')}
                </div>
                <div className="popover-user-item sex">
                  <IconFont className="icon" type="icon-sex" />
                  {r.patient.gender}
                </div>
                <div className="popover-user-item state">
                  <EnvironmentFilled className="icon" />
                  {r.patient.state}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <a href="#">{userName}</a>
          </Popover>
        );
      },
    },
    {
      title: 'Provider',
      dataIndex: 'doctor_name',
      key: 'doctor_name',
      ellipsis: true,
      render: (v, r) => {
        if (!r.doctor) {
          return '';
        }
        const userName = `${r.doctor.name} ${r.doctor.title}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/doctor/detail/${r.doctor.id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/doctor/detail/${r.doctor.id}`}>{r.doctor.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.doctor.phone)}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <a href="#">{userName}</a>
          </Popover>
        );
      },
    },
    {
      title: 'File Type',
      dataIndex: 'file_type',
      key: 'file_type',
      ellipsis: true,
      render: (v, r) => {
        return r.file_type_name;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status_name',
      width: 250,
      key: 'status_name',
      render: (v, r) => {
        return r.status_name;
      },
    },
    {
      title: 'Created At',
      ellipsis: true,
      dataIndex: 'created_at',
      width: 200,
      key: 'created_at',
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      render: (v, r) => {
        return (
          <Space>
            <Button
              style={{ marginRight: '10px' }}
              onClick={() => {
                setSelectedMedicalFile(r);
                setShowDrawer(true);
              }}
            >
              View
            </Button>
            {(r.status == 0 || r.status == 1) && (
              <Button
                type="primary"
                onClick={() => {
                  setSelectedMedicalFile(r);
                  setShowApproveModal(true);
                }}
              >
                Approve
              </Button>
            )}
            {(access.medicalFileAssigner&&r.status != 3) && <Button
              type="primary"
              onClick={() => {
                setSelectedMedicalFile(r);
                setShowChangeModal(true);
              }}
            >
              Assign
            </Button>
            }
          </Space>
        );
      },
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadList({
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: JSON.stringify(query),
    });
  };

  return (
    <>
      <div className={cx({ container: true })}>
        <div className={cx({ header: true })}>
          <h3>Medical File Requests</h3>

          <Space>
            <Filters filters={filters} initialFilters={filterState} onFilter={onFilter} />
          </Space>
        </div>

        <Table
          columns={columns}
          dataSource={data.items}
          rowKey="id"
          scroll={{ x: 800 }}
          onChange={handleTableChange}
          pagination={{
            pageSize: 20,
            total: data.total,
            simple: true,
            current: page,
            showTotal: (total, range) => {
              return `${range[0]}-${range[1]} of ${total} items`;
            },
          }}
        />

        {showDrawer && (
          <MedicalFileDrawer
            onClose={() => {
              setShowDrawer(false);
              setSelectedMedicalFile({});
            }}
            medicalFile={selectedMedicalFile}
            showApproveModal={() => {
              setShowApproveModal(true);
            }}
          />
        )}
        {showApproveModal && (
          <MedicalRequestApproveModal
            medicalFile={selectedMedicalFile}
            showApproveModal={showApproveModal}
            closeDrawer={() => {
              setShowDrawer(false);
            }}
            closeModal={() => {
              setShowApproveModal(false);
            }}
            refreshList={refreshList}
          />
        )}
        <Modal
          visible={showChangeModal}
          onCancel={() => setShowChangeModal(false)}
          onOk={() => setShowChangeModal(false)}
          footer={null}
        >
          <p style={{
            color: '#000000',
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '20px'
          }}>
            Change Assign Clinician
          </p>
          <Select style={{ width: '100%', marginBottom: '20px' }}
            showSearch
            placeholder={`Search Clinician`}
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={(value, option) => {
              setSelectedClinicianId(value);
            }}
          >
            {options.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>

          <div style={{ display: 'flex', justifyContent: 'space-between', color: '#000000' }}>
            <Button
              key="cancel"
              onClick={() => setShowChangeModal(false)}
              type="link"
            >
              Cancel
            </Button>
            <Button
              key="confirm"
              onClick={handleChangeClinician}
              type="primary"
              disabled={!selectedClinicianId}
              loading={assignDoctorReq.loading}
            >
              Update
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
};

const MedicalFileDrawer = (props) => {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [link, setLink] = useState('');
  const [revisions, setRevisions] = useState([]);
  const [checkedValues, setCheckedValues] = useState([]);
  const [showFileOptions, setShowFileOptions] = useState(false);
  const [showEditOption, setShowEditOption] = useState(false);
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const getReq = useRequest(srvAdminGetMedicalFile, {
    manual: true,
    onSuccess: (ret, params) => {
      setRevisions(ret.data.items);
      setLink(ret.data.link);
      setFileName(ret.data.file_name);
      setCheckedValues(ret.data.selected);
      setShowEditOption(!ret.data.re_uploaded && ret.data.file_type === 0);
      if (ret.data.file_name == '') {
        setTimeout(() => {
          getReq.run({ id: props.medicalFile.id });
        }, 3000);
      }
    },
  });

  useMount(() => {
    getReq.run({ id: props.medicalFile.id });
  });

  const handleReuploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleCheckboxChange = (values) => {
    setCheckedValues(values);
  };

  const updateReq = useRequest(srvAdminUpdateMedicalFile, {
    manual: true,
    onSuccess: (ret, params) => {
      getReq.run({ id: props.medicalFile.id });
      setShowFileOptions(false);
    },
  });
  // Function to handle file selection
  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      srvAdminMedicalFileUploadToken({ id: props.medicalFile.id }).then(async (res) => {
        var file = files[0];
        const fileBuffer = await file.arrayBuffer();

        try {
          request
            .put(res.data.upload_url, {
              data: fileBuffer,
              headers: { 'Content-Type': file.type },
            })
            .then(function () {
              getReq.run({ id: props.medicalFile.id });
            });
        } catch (error) {
          console.log(error);
          return;
        }
      });
    }
  };

  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase(),
    );
  };

  return (
    <>
      <Drawer
        title={toTitleCase(props.medicalFile.file_type_name)}
        placement="right"
        closable={true}
        setNoteVisible={() => {}}
        onClose={props.onClose}
        visible={true}
        destroyOnClose={true}
        width="1000"
      >
        <Alert
          message={
            <p style={{ color: '#6b7284' }}>
              Please thoroughly review the document. To make any necessary changes, select 'Edit
              File Options' to modify the content, creating an updated version. Alternatively, you
              can manually create a new file and then click 'Re-upload' to submit the revised
              document. After ensuring all details are accurate, click 'Approve' to finalize.
            </p>
          }
          type="info"
        />

        {revisions.length > 0 && (
          <>
            <h4 style={{ marginBottom: '20px', marginTop: '30px', fontWeight: '600' }}>
              Provide Notes
            </h4>
            <div style={{ marginBottom: '30px' }}>
              {revisions.map((r) => (
                <div className={cx({ revisionItem: true })} key={r.created_at}>
                  <span>
                    {r.doctor_name} {moment.unix(r.created_at).format('MM/DD/YYYY')}
                  </span>
                  <p>{r.note}</p>
                </div>
              ))}
            </div>
          </>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '40px',
          }}
        >
          <h4 style={{ marginBottom: '20px', marginTop: '10px', fontWeight: '600' }}>Details</h4>
          {showEditOption && (
            <Button
              onClick={(e) => {
                setShowFileOptions(true);
              }}
              size="small"
            >
              Edit File Options
            </Button>
          )}
        </div>

        {fileName !== '' && (
          <div className={cx({ pdfSection: true })}>
            {getReq.loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spin size="large" />
              </div>
            ) : (
              <PDFViewer fileUrl={link} />
              // <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              //   <div style={{ height: 'calc(100vh - 350px)', overflow: 'hidden' }}>
              //     <div
              //       style={{
              //         alignItems: 'center',
              //         backgroundColor: '#eeeeee',
              //         borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              //         display: 'flex',
              //         justifyContent: 'center',
              //         padding: '4px',
              //       }}
              //     >
              //       <ZoomOutButton />
              //       <ZoomPopover />
              //       <ZoomInButton />
              //     </div>
              //     <Viewer
              //       fileUrl={link}
              //       plugins={[zoomPluginInstance]}
              //       onError={(error) => {
              //         console.error('PDF viewer error:', error);
              //         message.error('Failed to load PDF file');
              //       }}
              //     />
              //   </div>
              // </Worker>
            )}
          </div>
        )}

        {fileName === '' && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin />
          </div>
        )}

        <div className={cx({ buttonsWrapper: true })}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <Button style={{ marginRight: '10px' }} onClick={handleReuploadClick}>
            Re-upload
          </Button>
          {(props.medicalFile.status === 0 || props.medicalFile.status === 1) && (
            <Button
              type="primary"
              onClick={() => {
                props.showApproveModal();
              }}
            >
              Approve
            </Button>
          )}
        </div>
      </Drawer>

      <Modal
        visible={showFileOptions}
        onCancel={() => {
          setShowFileOptions(false);
        }}
        onOk={() => {
          setShowFileOptions(false);
        }}
        footer={null}
      >
        <p
          style={{
            color: '#000000',
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '20px',
          }}
        >
          File Options
        </p>
        <p style={{ color: '#000000 80%', marginBottom: '20px' }}>
          Please confirm the details to be included in the file. The selected items will appear in
          the newly generated file. Save your selection to automatically create the updated file.
        </p>
        <Checkbox.Group
          style={{ marginBottom: '20px' }}
          options={[
            { label: 'NPI', value: 'ProviderNpi' },
            { label: "Provider's Email", value: 'ProviderEmail' },
            { label: "Provider's Phone Number", value: 'ProviderPhone' },
            { label: "Provider's License (patient's state only)", value: 'ProviderLicenses' },
          ]}
          value={checkedValues}
          onChange={handleCheckboxChange}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', color: '#000000' }}>
          <Button
            key="cancel"
            onClick={() => {
              setShowFileOptions(false);
            }}
            type="link"
          >
            Cancel
          </Button>
          ,
          <Button
            key="confirm"
            style={{ width: '180px', borderRadius: '4px' }}
            onClick={() => {
              updateReq.run({ id: props.medicalFile.id, selected: checkedValues });
            }}
            type="primary"
            loading={updateReq.loading}
          >
            Save
          </Button>
        </div>
      </Modal>
    </>
  );
};

const MedicalRequestApproveModal = (props) => {
  const approveReq = useRequest(srvAdminApproveMedicalFileRequest, {
    manual: true,
    onSuccess: (ret, params) => {
      props.refreshList();
      props.closeModal();
      props.closeDrawer();
    },
  });

  const handleOk = () => {
    approveReq.run({
      id: props.medicalFile.id,
    });
  };

  const handleCancel = () => {
    props.closeModal();
  };

  return (
    <div style={{ zIndex: 100000000 }}>
      <Modal
        style={{ zIndex: 10000000 }}
        title="Are you sure?"
        visible={props.showApproveModal}
        onOk={handleOk}
        okText="Confirm"
        onCancel={handleCancel}
      >
        <p>Are you sure you want to approve this?</p>
      </Modal>
    </div>
  );
};

export default MedicalFiles;
