import React  from 'react';
import { Table } from 'antd';
import { formatUnix} from '../../utils/xtime';


const MembershipHistory = (props) => {
  const {memberships, 
    loading,
    onChange,
    pagination,
  } = props
  const columns = [
    {
      title: 'Membership Cycle',
      key: 'cycle',
      dataIndex: 'begin_date',
      width: 250,
      render: (v, r) => {
        let cycle = formatUnix(r.begin_date, "MMM DD, YYYY") + " - " +  formatUnix(r.end_date, "MMM DD, YYYY")
        return cycle
      }
    },
    {
      title: 'Used consultation',
      dataIndex: 'used_consultation_num',
      key: 'used_consultation_num',
      ellipsis: true,
    },
    {
      title: 'Follow Up',
      dataIndex: 'followup',
      key: 'followup',
      ellipsis: true,
      width: 120,
      render: (v, r) => {
        return r.followup ? 'Yes' : 'No';
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        return r.status_name;
      },
    },
  ];
  return (
    <div style={{width: "730px"}}>
    <Table
          columns={columns}
          dataSource={memberships}
          rowKey="id"
          loading={loading}
          onChange={onChange}
          pagination={pagination}
        />
    </div>
  )
}

export default MembershipHistory;
