import React, { useState, useEffect, useRef } from 'react';
import { Form, Space, Input, DatePicker, TimePicker } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

import moment from 'moment';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

const DateTimeRangePickerList = (props) => {

  const { form, rescheduleType, activeAppointment } = props;

  const addRef = useRef(null);

  useEffect(() => {
    const additional_dates = form.getFieldValue('additional_dates');
    if (rescheduleType === 'additional') {
      if (additional_dates === undefined && addRef && addRef.current) addRef.current.click();
    } else {
      form.setFieldsValue({ additional_dates: undefined });
    }
  }, [rescheduleType]);

  const onDisabledDate = (current) => {
    const start = moment(activeAppointment.start).startOf('day');
    const end = moment(activeAppointment.start).add(5, 'days').endOf('day')
    return current && (current < start || current >= end);
  }

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };


  const onDisabledTimeRange = (current, type) => {
    // return current && (type === 'start' && current <= moment().toNow());
    // // return { disabledHours?: () => number[]; }
    // if (type === 'start') {
    //   return {
    //     disabledHours: () => range(0, 23).splice(4, 20),
    //     disabledMinutes: () => range(30, 60),
    //     disabledSeconds: () => [],
    //   };
    // }
    // return {
    //   disabledHours: () => range(0, 60).splice(20, 4),
    //   disabledMinutes: () => range(0, 31),
    //   disabledSeconds: () => [],
    // };
  }

  const onCheckDatesSame = () => {
    const selectedDates = [];
    const additional_dates = form.getFieldValue('additional_dates');
    if (additional_dates && additional_dates.length > 0) {
      additional_dates.forEach(v => {
        if (v && v.date) selectedDates.push(moment(v.date).format('YYYY-MM-DD'))
      });
    }
    if (selectedDates.length >= 0 && selectedDates.length === [...new Set(selectedDates)].length) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Date can't be the same"));
  };

  return (
    <div className={cx({ additionalTime: true, additionalHide: rescheduleType != 'additional' })}>
      <Form.List name="additional_dates">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Space
                key={field.key}
                style={{
                  display: 'flex',
                }}
                align="baseline"
              >

                <Form.Item
                  {...field}
                  name={[field.name, 'date']}
                  rules={[
                    {
                      required: true,
                      message: 'Missing Date',
                    },
                    {
                      validator: onCheckDatesSame,
                    },
                  ]}
                  style={{
                    marginBottom: 0,
                  }}
                >
                  <DatePicker
                    placeholder="Select Date"
                    format="YYYY-MM-DD"
                    disabledDate={onDisabledDate}
                  />
                </Form.Item>
                <Form.Item
                  {...field}
                  name={[field.name, 'time_range']}
                  rules={[
                    {
                      required: true,
                      message: 'Missing Time Range',
                    },
                  ]}
                  style={{
                    marginBottom: 0,
                  }}
                >
                  <TimePicker.RangePicker
                    minuteStep={15}
                    format={'HH:mm'}
                    disabledTime={onDisabledTimeRange}
                    style={{ width: '100%' }} />
                </Form.Item>

                <MinusCircleOutlined onClick={() => { remove(field.name) }} />

              </Space>
            ))}
            <Form.Item>
              <PlusCircleOutlined style={{ fontSize: '18px' }} onClick={() => add()} ref={addRef} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  )
}

export default DateTimeRangePickerList;