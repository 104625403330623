import React, { useState, useEffect } from 'react';
import {
  srcUpdateOneDayRule,
} from '@/services/account';
import { Select, TimePicker, Button, Modal } from 'antd';
import { useRequest } from 'ahooks';
const UpdateDayRule = (props) => {
  const { editDayRuleVisible, dayRuleInfo, day, appts, closeEditDayRuleVisible } = props;
  const [updatedDayRuleInfo, setUpdatedDayRuleInfo] = useState(dayRuleInfo || [{}]);
  const removeOneDayPlan = (index) => {
    if (updatedDayRuleInfo.length < 2) {
      return
    }
    const newDayRuleInfo = [...updatedDayRuleInfo];
    newDayRuleInfo.splice(index, 1);
    setUpdatedDayRuleInfo(newDayRuleInfo);
  };
  const updateOneDayPlan = (value, index) => {
    const newDayRuleInfo = [...updatedDayRuleInfo];
    newDayRuleInfo[index] = { ...newDayRuleInfo[index], 'time_plan_appointment_type_id': value };
    setUpdatedDayRuleInfo(newDayRuleInfo);
  };
  const addOnedayPlan = () => {
    const newDayRuleInfo = [...updatedDayRuleInfo];
    newDayRuleInfo.push({});
    setUpdatedDayRuleInfo(newDayRuleInfo);
  };
  const handleTimeRangeChange = (timeRange, index) => {
    var time = '';
    if (timeRange) {
      time = moment(timeRange[0]).format('hh:mma') + '-' + moment(timeRange[1]).format('hh:mma');
    }
    const newDayRuleInfo = { ...updatedDayRuleInfo };
    newDayRuleInfo[index] = { ...newDayRuleInfo[index], 'time_range': time };
    setUpdatedDayRuleInfo(newDayRuleInfo);
  };


  return (
    <Modal
      visible={editDayRuleVisible}
      onCancel={
        closeEditDayRuleVisible}
      onOk={closeEditDayRuleVisible}
      footer={null}
      width={800}
    >
      <p
        style={{
          color: '#000000',
          fontSize: '20px',
          fontWeight: '600',
          marginBottom: '20px',
        }}
      >
        Hours for {day}
      </p>
      <p>Set Hours</p>
      <div style={{ flexDirection: 'column', marginTop: '20px' }}>
        {updatedDayRuleInfo.map((v, index) => {
          const selectedValue = v.time_plan_appointment_type_id || undefined;
          const selectedTimeRange = v.time_range
          return (
            <div key={index} style={{ display: 'flex', marginTop: '5px' }}>
              <Select placeholder="Select a option" style={{ width: '400px' }}
                onChange={(value) => updateOneDayPlan(value, index)}
                options={appts.map((appt) => ({ label: appt.name, value: appt.appointment_type_id }))}
                value={selectedValue}
              >
              </Select>
              <TimePicker.RangePicker
                use12Hours
                format="h:mm:ss A"
                style={{ marginLeft: '20px', width: '250px' }}
                onChange={(timeRange) => handleTimeRangeChange(timeRange, index)}
                value={selectedTimeRange}
              />
              <button
                style={{
                  position: 'relative',
                  width: '16px',
                  height: '16px',
                  borderRadius: '50%',
                  border: '1px solid #ccc',
                  background: 'none',
                  fontSize: '0',
                  marginLeft: '10px',
                  marginTop: '8px '
                }}
                onClick={() => removeOneDayPlan(index)}
              >
                <span
                  style={{
                    fontSize: '24px',
                    color: '#555',
                    position: 'absolute',
                    top: '30%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  -
                </span>
              </button>
              {index === 0 && (
                <button style={{ marginLeft: '50px', fontSize: '24px' }} onClick={() => addOnedayPlan()}>
                  +
                </button>
              )}
            </div>
          )
        })}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', color: '#000000', marginTop: '20px' }}>
        <p style={{ color: '#566BCD', cursor: 'pointer' }} onClick={closeEditDayRuleVisible}>Cancel</p>
        <Button key="confirm" type="primary" style={{ width: '150px' }} onClick={() => { }}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateDayRule;
