import React, { useState, useEffect } from 'react';
import { Form, Input, InputNumber, Space, Select, Divider } from 'antd';

import { useMount, useUpdateEffect, useSetState } from 'ahooks';

const { TextArea } = Input;

const ChiefComplaintFormItems = (props) => {
  const { form, draft, setDraft, patient, patientAge } = props;

  return (
    <>
      <Space>
        <Form.Item name="chief_complaint_firstname" initialValue={patient ? patient.first_name : ''}>
          <Input placeholder="First Name" />
        </Form.Item>
        <span style={{ display: 'block', height: '48px', lineHeight: '32px', margin: '0 5px' }}>is a</span>
        <Form.Item name="chief_complaint_age" initialValue={patient ? patientAge : ''}>
          <InputNumber placeholder="Age" />
        </Form.Item>
        <span style={{ display: 'block', height: '48px', lineHeight: '32px', margin: '0 5px' }}>y/o</span>
        <Form.Item name="chief_complaint_gender" initialValue={['male', 'female'].includes(patient?.gender) ? patient?.gender : 'other'}>
          <Select
            allowClear
          >
            <Option value="male">Male</Option>
            <Option value="female">Female</Option>
            <Option value="other">Other</Option>
          </Select>
        </Form.Item>
      </Space>

      <Form.Item
        name="chief_complaint"
        label={'who presents for an initial psychiatric evaluation with chief complaints of:'}
        required={true}
      >
        <TextArea rows={3} maxLength={1000} />
      </Form.Item>

      <Divider />

      <Form.Item
        name="history_of_present_illness"
        label={'History of Present Illness'}
      >
        <TextArea rows={3} maxLength={1000} />
      </Form.Item>

      <Form.Item
        name="currently_medications_prescribed"
        label={'Current psychiatric medications prescribed'}
      >
        <TextArea rows={3} maxLength={1000} />
      </Form.Item>

      <Form.Item
        name="currently_side_effects_reported"
        label={'Current side effects reported'}
      >
        <TextArea rows={3} maxLength={1000} />
      </Form.Item>

      <Form.Item
        name="past_medication_trials"
        label={'Past medication trials'}
      >
        <TextArea rows={3} maxLength={1000} />
      </Form.Item>
    </>
  )
};

export default ChiefComplaintFormItems;
