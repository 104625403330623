import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox } from 'antd';

import { useMount, useUpdateEffect } from 'ahooks';

const ExclusiveCheckboxWithTextInput = (props) => {
  const { name, label, hasInput = false, inputRequired = false, onChange, form, draft, setDraft } = props;

  const onCheckboxChange = (e) => {
    if (e.target.checked === false) {
      form.validateFields([name]);
      const field = {}
      field[name] = false;
      if (hasInput) field[`${name}_text`] = '';
      form.setFieldsValue(field);
      setDraft(Object.assign({ ...draft }, field));
    }
    if (onChange) onChange(e);
    // console.log('e.target.checked:', e.target.checked)
  }



  return (
    <div>
      <Form.Item name={name} valuePropName="checked" noStyle>
        <Checkbox onChange={onCheckboxChange}>{label}</Checkbox>
      </Form.Item>
      {hasInput && Boolean(form.getFieldValue(name)) && (
        <Form.Item name={`${name}_text`}
          validateTrigger={'onBlur'}
          rules={[
            {
              required: inputRequired,
              message: `${label} is required`,
            },
          ]}
          noStyle>
          <Input type={'text'} style={{ marginLeft: '24px', width: 'calc(100% - 24px)' }} />
        </Form.Item>
      )}
    </div>
  )
};

export default ExclusiveCheckboxWithTextInput;