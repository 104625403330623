import React, { useState, useEffect } from 'react';
import { Modal, Input, Form, Select, Button, Alert, InputNumber } from 'antd';

import numeral from 'numeral';
import { useRequest } from 'ahooks';
import { srvPaymentItem } from '@/services/admin';

import { srvGetAllProductList, srvGetPatientPaymentMethod, srvAddPayment } from '@/services/admin';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

const CreatePaymentModal = (props) => {
  const { closeCreateModal, id } = props;
  const [form] = Form.useForm();
  const [productList, setProductList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [productMap, setProductMap] = useState({});
  const [paymentMethod, setPaymentMethod] = useState('');
  const [productID, setProductID] = useState('');
  const [chargeFailFlag, setChargeFailFlag] = useState(false);
  const fetchPaymentReq = useRequest(srvPaymentItem, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data) {
          setPayment(ret.data);
          form.setFieldsValue({
            amount: numeral((ret.data.amount - ret.data.amount_refunded) / 100).format('0,0.00'),
          });
        } else {
          setPayment({});
        }
      }
    },
  });

  useEffect(() => {
    getPaymentMethodReq.run({ id });
    getAllProductReq.run();
  }, []);
  const getAllProductReq = useRequest(srvGetAllProductList, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.data) {
        const uniqueProducts = [...new Set(ret.data.map((item) => item.product))];
        const resultProducts = uniqueProducts.map((product) => ({ value: product }));
        setProductList(resultProducts);
        const tempProductMap = {};
        uniqueProducts.forEach((product) => {
          tempProductMap[product] = ret.data.filter((item) => item.product === product);
        });
        setProductMap(tempProductMap);
      }
    },
  });
  const getPaymentMethodReq = useRequest(srvGetPatientPaymentMethod, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.data) {
        if(ret.data.source==="PAYPAL"){
          setPaymentMethod(ret.data.first_name + "'s PayPal");
          return;
        }
        setPaymentMethod('**** ' + ret.data.last_4);
      }
    },
  });
  const onFinish = (data) => {
    data.patient_id = id;
    data.amount = Number(Number(data.amount * 100).toFixed());
    createPaymentReq.run(data);
  };
  const createPaymentReq = useRequest(srvAddPayment, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.data) {
        closeCreateModal(false);
      } else {
        setChargeFailFlag(true);
      }
    },
  });
  const handleFormChange = () => {
    setChargeFailFlag(false); // Hide the Alert when form changes
  };

  const changeProduct = (value) => {
    var temp = productMap[value];
    setCategoryList(
      temp.map((category) => ({
        label: category.category,
        value: category.product_id,
        price: category.price,
      })),
    );
    form.setFieldsValue({
      product_id: '',
      amount: '',
    });
    setProductID('');
    setChargeFailFlag(false);
  };
  const onCheckRefundAmount = (_, value) => {
    const amount = Number(Number(value * 100).toFixed());
    if (amount > 0 && amount<100001) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Amount is wrong'));
  }

  return (
    <Modal
      visible={true}
      footer={null}
      onCancel={() => {
        closeCreateModal(false);
      }}
    >
      <p
        style={{
          color: '#000000',
          fontSize: '16px',
          fontWeight: '600',
          marginBottom: '20px',
        }}
      >
        Create Payment
      </p>
      <Form
        form={form}
        onValuesChange={handleFormChange}
        onFinish={onFinish}
        className={cx({ modalForm: true })}
        layout="vertical"
      >
        {chargeFailFlag && (
          <Alert
            style={{ marginBottom: '20px' }}
            message="Sorry, payment failed."
            type="error"
            showIcon
          />
        )}
        <p style={{ marginBottom: '10px', color: '#333333', fontWeight: '600' }}>Payment Method</p>
        <Select style={{ width: '100%', marginBottom: '20px' }} disabled={true} value={1}>
          <Select.Option value={1} key={1}>
            {paymentMethod}
          </Select.Option>
        </Select>
        <Form.Item
          label="Billing Category"
          name="product_id"
          rules={[{ required: true, message: 'Billing Category' }]}
        >
          <Select
            options={productList}
            onChange={changeProduct}
            style={{ marginBottom: '20px' }}
          />

          <Select
            value={productID}
            options={categoryList}
            onChange={(value) => {
              const selectedOption = categoryList.find((option) => option.value === value);
              form.setFieldsValue({
                product_id: value,
                amount: '',
              });
              setChargeFailFlag(false);
              setProductID(value);
              if (selectedOption.price) {
                form.setFieldsValue({
                  amount: parseInt(selectedOption.price, 10),
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label="Amount"
          name="amount"
          rules={[
            {
              required: true,
              message: 'Amount is required',
            },
            {
              validator: onCheckRefundAmount,
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} min={0} />
        </Form.Item>
        <Form.Item label="Note" name="note">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          color: '#000000',
          marginTop: '20px',
        }}
      >
        <p
          onClick={() => {
            closeCreateModal();
          }}
          style={{ color: '#566BCD', cursor: 'pointer' }}
        >
          Cancel
        </p>
        <Button
          key="confirm"
          style={{ width: '180px', borderRadius: '4px' }}
          disabled={chargeFailFlag}
          onClick={() => {
            setChargeFailFlag(false);
            form.validateFields().then(() => {
              form.submit();
            });
          }}
          type="primary"
          loading={createPaymentReq.loading}
        >
          Create Payment
        </Button>
      </div>
    </Modal>
  );
};

export default CreatePaymentModal;
