import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link } from 'umi';
import {
  Input,
  Card,
  Table,
  Descriptions,
  Tag,
  Space,
  Button,
  Modal,
  Row,
  Col,
  Timeline,
  Popover,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import {
  ExclamationCircleOutlined,
  CreditCardFilled,
  createFromIconfontCN,
} from '@ant-design/icons';
import numeral from 'numeral';
import { AsYouType } from 'libphonenumber-js';
import { stringify } from 'querystring';

import RescheduleAppointment from '@/components/RescheduleAppointment';
import CancelAppointment from '@/components/CancelAppointment';
import { appointmentStatusColor, paymentStatusColor } from '@/utils/tagColor';
import defaultSettings from '../../../defaultSettings';

import { formatUnix, formatAppointmentAt } from '../../utils/xtime';
import { getPatientName } from '@/utils/utils';
import styles from './index.less';
import zoomIcon from '@/assets/zoom.svg';

const { confirm } = Modal;
const cx = cn.bind(styles);

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const columns = [
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    ellipsis: true,
    width: 80,
    render: (v, r) => {
      const val = numeral(v / 100).format('0,0.00');
      return <span style={{ color: '#1a1f36', fontWeight: 500 }}>{val}</span>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true,
    width: 150,
    render: (v, r) => {
      if (r.status == 3) {
        return (
          <Popover
            content={<div className="popover-user">{r.reason}</div>}
            title="Reason"
            trigger="hover"
          >
            <Tag color={paymentStatusColor(r.status)}>{r.status_name}</Tag>
          </Popover>
        );
      }
      return <Tag color={paymentStatusColor(r.status)}>{r.status_name}</Tag>;
    },
  },
  {
    title: 'Stripe/Paypal ID',
    dataIndex: 'external_charge_id',
    key: 'external_charge_id',
    ellipsis: true,
  },
  {
    width: 150,
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    ellipsis: true,
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    ellipsis: true,
    width: 200,
    render: (v, r) => {
      return formatUnix(r.created_at);
    },
  },
];

const AppointmentDetail = (props) => {
  const { dispatch, appointment_detail, loading } = props;
  const { data, orders, rescheduleCalendar } = appointment_detail;
  const id = props.match.params.id;
  const [rescheduleVisible, setRescheduleVisible] = useState(false);
  const [cancelVisible, setCancelVisible] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    dispatch({
      type: 'appointment_detail/fetchData',
      payload: { id },
      callback: (data) => {
        dispatch({
          type: 'appointment_detail/fetchAppointmentRescheduleCalendar',
          payload: {
            appointment_id: data.id,
            timezone: data.timezone,
            days: 4,
            date: moment().format('YYYYMMDD'),
            dir: 'next',
            from_admin: 1,
          },
        });
      },
    });
    dispatch({
      type: 'appointment_detail/fetchAppointmentOrders',
      payload: { id },
      callback: (data) => {},
    });
  };

  const loadRescheduleCalendar = (payload) => {
    dispatch({
      type: 'appointment_detail/fetchAppointmentRescheduleCalendar',
      payload: payload,
    });
  };

  const secondsToDhms = (seconds) => {
    const s = Number(seconds);
    var d = Math.floor(s / (3600 * 24));
    var h = Math.floor(s % (3600 * 24) / 3600);
    var m = Math.floor(s % 3600 / 60);
    let displayContent = <></>
    if (d > 0) {
      displayContent = <>
        <div> {d} </div>
        <div className="dashboard-item-number-unit"> {d == 1 ? "day" : "days"}   </div>
        {
          h > 0 ? (
            <>
              <div> {h} </div>
              <div className="dashboard-item-number-unit"> {h == 1 ? "hr" : "hrs"}   </div>
            </>) : <></>
        }
      </>
    } else if (h > 0) {
      displayContent = <>
        <div> {h} </div>
        <div className="dashboard-item-number-unit"> {h == 1 ? "hr" : "hrs"}   </div>
        {m > 0 ? (
          <>
            <div> {m} </div>
            <div className="dashboard-item-number-unit"> {m == 1 ? "min" : "mins"}   </div>
          </>) : <></>
        }
      </>
    } else if (m>0){
      displayContent = <>
        <div> {m} </div>
        <div className="dashboard-item-number-unit"> {m > 0 ? (m == 1 ? "min" : "mins") : ""}   </div>
      </>
    }else {
      displayContent = <>
        <div> {s} </div>
        <div className="dashboard-item-number-unit">  {"seconds"}   </div>
      </>
    }
    return displayContent
  }

  const meetingUuid = data.zoom_event && data.zoom_event.length > 0 ? data.zoom_event[0].meeting_uuid : '';
  const meetingUrl = meetingUuid ? `https://zoom.us/account/metrics/pastmeetingdetail?meeting_id=${meetingUuid}` : '';

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <div>
          <div className={cx({ appointmentSubheader: true })}>
            <Space>
              <CreditCardFilled />
              Appointment
            </Space>
          </div>
          <div className={cx({ appointmentTitle: true })}>
            <Space>
              <span className={cx({ appointmentDate: true })}>
                {data &&
                  <Tooltip title={formatAppointmentAt(data, data.timezone) + ' ' + data.timezone} placement="bottom">
                    {formatAppointmentAt(data)}
                  </Tooltip>
                }
              </span>
              <Tag color={appointmentStatusColor(data.status)}>{data.status_name}</Tag>
            </Space>
          </div>
        </div>
        <Space>
          {(data.status === 1 || data.status === 2) && (
            <Button
              type="primary"
              onClick={() => {
                setRescheduleVisible(true);
              }}
            >
              Reschedule
            </Button>
          )}
          {(data.status === 1 || data.status === 2) && (
            <Button
              type="danger"
              onClick={() => {
                setCancelVisible(true);
              }}
            >
              Cancel
            </Button>
          )}
        </Space>
      </div>

      <div className={cx({ content: true })}>
        <Descriptions title="" layout="vertical" size="small" column={5}>
          <Descriptions.Item label="Type">
            <Tag>{data.appointment_type_name}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Timezone">{data.timezone}</Descriptions.Item>
          <Descriptions.Item label="Created At">
            {data && formatUnix(data.created_at)}
          </Descriptions.Item>
          <Descriptions.Item label="Patient">
            { data.patient &&
              <Link to={`/patient/${data.patient.id}`} style={{color: '#5469d4'}}>{getPatientName(data.patient)}</Link>
            }
          </Descriptions.Item>
          <Descriptions.Item label="Doctor">
            {data.doctor && data.doctor.name} {data.doctor && data.doctor.title}
          </Descriptions.Item>
        </Descriptions>

        { data.doctor_location &&
          <>
            <div className={cx({ sectionHeader: true })}>
              <h4>Location</h4>
            </div>
            <Descriptions title="" layout="vertical">
              <Descriptions.Item label="Address">
                {data.doctor_location.address_line1 + ' ' + data.doctor_location.address_line2}
              </Descriptions.Item>
              <Descriptions.Item label="City">{data.doctor_location.city}</Descriptions.Item>
              <Descriptions.Item label="State">{data.doctor_location.state}</Descriptions.Item>
              <Descriptions.Item label="Zipcode">{data.doctor_location.zipcode}</Descriptions.Item>
              <Descriptions.Item label="Latitude">{!!data.doctor_location.lat && data.doctor_location.lat}</Descriptions.Item>
              <Descriptions.Item label="Longitude">
                {!!data.doctor_location.lng && data.doctor_location.lng}
              </Descriptions.Item>
              <Descriptions.Item label="Navigation Instruction">
                {data.doctor_location.navigation_instruction}
              </Descriptions.Item>
            </Descriptions>
          </>
        }

        <div className={cx({ sectionHeader: true })}>
          <h4>Timeline</h4>
        </div>
        <Timeline>
          {data.logs.map((v, i) => {
            return (
              <Timeline.Item key={i}>
                <div style={{ marginBottom: '10px' }}>
                  <Tag>{v.log_type_name}</Tag>
                </div>
                {data && formatUnix(v.created_at)}
              </Timeline.Item>
            );
          })}
        </Timeline>
        <div className={cx({ sectionHeader: true })}>
          <h4>Zoom Active Metric</h4>
        </div>
        <Descriptions title="" layout="vertical" size="small" column={5}>
          <Descriptions.Item label="Bind Flag">
            {data.bind_user?.bind_flag? "true" : "false" }

          </Descriptions.Item>
          <Descriptions.Item label="Join Zoom User">
            {data.bind_user?.zoom_user_name}
          </Descriptions.Item>
          <Descriptions.Item label="Waiting Time">
            {data.bind_user&&secondsToDhms(data.bind_user.waiting_second)}
          </Descriptions.Item>
          <Descriptions.Item label="Duration Time">{data.bind_user&&secondsToDhms(data.bind_user.duration_second)}</Descriptions.Item>

        </Descriptions>

        <div className={cx({ sectionHeader: true })}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4 style={{ margin: 0 }}>Zoom Meeting Timeline</h4>
            {meetingUuid && (
                <a
                    href={meetingUrl}
                    target="_blank"
                    style={{
                      marginLeft: '10px',
                      marginTop: '6px',
                      fontSize: '12px'
                    }} rel="noreferrer"
                >
                    <img src={zoomIcon} style={{ width: '20px', height: '20px' }} />
                </a>
            )}
          </div>
        </div>
        <Timeline>
          {data.zoom_event&&data.zoom_event.map((v, i) => {
            return (
              <Timeline.Item key={i}>
                <div style={{ marginBottom: '10px' }}>
                  <Tag>{v.event_name}  {v.participant_name}</Tag>
                </div>
                {data && formatUnix(v.event_timestamp)}
              </Timeline.Item>
            );
          })}
        </Timeline>

        <div className={cx({ sectionHeader: true })}>
          <h4>Orders</h4>
        </div>
        {orders.map((v, i) => {
          const val = numeral(v.amount / 100).format('0,0.00');
          return (
            <Card
              title={<div className={cx({ cardtitle: true })}> {v.product_id_name}</div>}
              size="small"
              style={{ marginBottom: '16px' }}
              key={i}
            >
              <Descriptions title="" layout="vertical" size="small" column={4}>
                <Descriptions.Item label="Status">
                  <Tag>{v.status_name}</Tag>
                </Descriptions.Item>
                <Descriptions.Item label="Amount">{val}</Descriptions.Item>
                <Descriptions.Item label="Created At">
                  {data && formatUnix(v.created_at)}
                </Descriptions.Item>
                <Descriptions.Item label="Due Date">
                  {data && formatUnix(v.final_payment_at)}
                </Descriptions.Item>
              </Descriptions>
              <div className={cx({ subsectionHeader: true })}>
                <h4>Payments</h4>
              </div>
              <Table
                columns={columns}
                dataSource={v.payments}
                rowKey="id"
                loading={loading.effects['appointment_detail/fetchAppointmentOrders']}
                pagination={false}
              />
            </Card>
          );
        })}
        { rescheduleVisible &&
            <RescheduleAppointment
            visible={rescheduleVisible}
            appointment={data}
            rescheduleCalendar={rescheduleCalendar}
            loadingReschedule={loading.effects['appointment_detail/rescheduleAppointment']}
            onClose={() => {
              setRescheduleVisible(false);
            }}
            rescheduleAppointment={(payload, callback) => {
              dispatch({
                type: 'appointment_detail/rescheduleAppointment',
                payload: payload,
                callback: () => {
                  dispatch({ type: 'appointment_detail/fetchData', payload: { id } });
                  callback();
                },
              });
            }}
            loadRescheduleCalendar={loadRescheduleCalendar}
          />
        }
        <CancelAppointment
          visible={cancelVisible}
          appointment={data}
          loadingCancel={loading.effects['appointment_detail/cancelAppointment']}
          onClose={() => {
            setCancelVisible(false);
          }}
          cancelAppointment={(payload, callback) => {
            dispatch({
              type: 'appointment_detail/cancelAppointment',
              payload: payload,
              callback: () => {
                dispatch({ type: 'appointment_detail/fetchData', payload: { id } });
                dispatch({ type: 'appointment_detail/fetchAppointmentOrders', payload: { id } });
                callback();
              },
            });
          }}
        />
      </div>
    </div>
  );
};

export default connect(({ appointment_detail, loading }) => ({
  appointment_detail,
  loading,
}))(AppointmentDetail);
