import React, { useState, useCallback, useEffect } from 'react';
import { Input, Tabs, Spin,Collapse } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import cn from 'classnames/bind';
import moment from 'moment';
const { Panel } = Collapse;
import { useRequest } from 'ahooks';
import InfiniteScroll from 'react-infinite-scroll-component';

import { srvPatients,srvTherapistPatientsList,srvTherapistPatients,srvTherapistPatientDetail, srvRefillRequestPatients,srvTransferPatients,srvPatientDetail} from '@/services/patient';
import styles from './index.less';
const cx = cn.bind(styles);
const { Search } = Input;
const { TabPane } = Tabs;

const PatientList = (props) => {
  const { group, scope, onSelect: propsOnSelect, selectedID, searchable, query, requestStatusFilter } = props;

  const [patients, setPatients] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [requestStatus, setRequestStatus] = useState('1');
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [transferPatients, setTransferPatients] = useState([]);
  const [activeKey,setActiveKey]=useState([]);
  const [selectPatient,setSelectPatient]=useState({});
  const loadPatientsReq = useRequest(srvPatients, {
    debounceWait: 1000,
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setPatients(patients.concat(ret.data.items))
        setTotal(ret.data.total)
      }
    },
  });
  const loadTherapistPatientsReq = useRequest(srvTherapistPatientsList, {
    debounceWait: 1000,
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setPatients(patients.concat(ret.data.items))
        setTotal(total+ret.data.total)
      }
    },
  });
  const handleCollapseChange = (key) => {
    if (activeKey.length === 0) {
      if (key[0] === '1') {
        getTransferPatient();
      } else {
        getCurrentPatient();
      }
    } else {
      if (key.length > activeKey.length) {
        const add = key.filter(item => !activeKey.includes(item))[0];
        if (add === '1') {
          getTransferPatient();
        } else {
          getCurrentPatient();
        }
      } else {
        const remove = activeKey.filter(item => !key.includes(item))[0];
        if (remove === '1') {
          setTransferPatients([]);
        } else {
          setPatients([]);
        }
      }
    }
    setActiveKey(key);
  };

  const loadCurrentPatientsReq = useRequest(srvTransferPatients, {
    debounceWait: 1000,
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setPatients(patients.concat(ret.data.items))
        setTotal(ret.data.total)
      }
    },
  });
  const loadCurrentTherapistPatientsReq = useRequest(srvTherapistPatients, {
    debounceWait: 1000,
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setPatients(patients.concat(ret.data.items))
        setTotal(ret.data.total)
      }
    },
  });
  const loadTransferTherapistPatientsReq = useRequest(srvTherapistPatients, {
    debounceWait: 1000,
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setTransferPatients(transferPatients.concat(ret.data.items))
        setTotal(ret.data.total)
      }
    },
  });
  const loadTransferPatientsReq = useRequest(srvTransferPatients, {
    debounceWait: 1000,
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setTransferPatients(transferPatients.concat(ret.data.items))
        setTotal(ret.data.total)
      }
    },
  });
  const getCurrentPatient = () => {
    let condition = { page };
    loadCurrentPatientsReq.run({ ...condition, filters: JSON.stringify({ transfer_status: '0' }), page_size: 100 });
  }

  const getCurrentTherapistPatient = () => {
    let condition = { page };
    loadCurrentTherapistPatientsReq.run({ ...condition, filters: JSON.stringify({ transfer_status: '0' }), page_size: 100 });
  }

  const getTransferTherapistPatient = () => {
    let condition = { page };
    loadTransferTherapistPatientsReq.run({ ...condition, filters: JSON.stringify({ transfer_status: '4' }), page_size: 100 });
  }

  srvTherapistPatients
  const getTransferPatient = () => {
    let condition = { page };
    loadTransferPatientsReq.run({ ...condition, filters: JSON.stringify({ transfer_status: '4' }), page_size: 100 });
  }
  const loadRefillRequestPatientsReq = useRequest(srvRefillRequestPatients, {
    debounceWait: 1000,
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (page > 1) {
          setPatients(patients.concat(ret.data.items))
        } else {
          setPatients(ret.data.items)
        }
        setTotal(ret.data.total)
      }
    },
  });
  const getPatientDetail = () => {
    loadPatientDetailReq.run({ id: selectedID });
  }

  const loadPatientDetailReq = useRequest(srvPatientDetail, {
    debounceWait: 1000,
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success && ret.data!==null) {
        setSelectPatient(ret.data);
      }
    },
  });

  let searchScope = query ? "all" : scope

  useEffect(() => {
    let condition = { query, group, searchScope, page }
    if (keyword) condition.query = keyword;
    if (group === 'request' && requestStatusFilter) {
      loadRefillRequestPatientsReq.run({ status: requestStatus, query: condition.query, page: condition.page });
    } else {
      setActiveKey(['1','2'])
      if (requestStatusFilter) {
        loadPatientsReq.run(condition);
        loadTherapistPatientsReq.run(condition);
      } else {
        getTransferPatient();
        getCurrentPatient();
        getTransferTherapistPatient();
        getCurrentTherapistPatient();
      }
      if (selectedID !== '') {
        getPatientDetail();
      }
    }
  }, [group, scope, query, keyword, requestStatus, page]);

  const onClick = (id) => {
    propsOnSelect && propsOnSelect(id);
  };

  const onSearch = (e) => {
    setKeyword(e.target.value);
  };

  const onRequestTabChange = (key) => {
    setPatients([])
    setPage(1)
    setRequestStatus(key);
  };

  const patientAges = (birthdate) => {
    return moment().diff(moment.unix(birthdate), 'years', false)
  }

  const patientGender = (gender) => {
    if(gender == 'male') {
      return 'M'
    } else if (gender == 'female') {
      return 'F'
    }
  }

  const fetchNext = () => {
    setPage(page + 1)
  }

  const formatDate = (birthdate, inUTC) =>  {
    return inUTC? moment.unix(birthdate).utc().format('MM/DD/YYYY') : moment.unix(birthdate).local().format('MM/DD/YYYY')
  }

  const patientsDom = patients.map((p) => {
    return (
      <div className={cx({ patientItem: true })} key={p.id}>
        <a href="#" onClick={() => onClick(p.id)} className={cx({ active: selectedID === p.id })}>
          <div className={cx({ avatar: true })}>
            <span className={cx({ avatarInitial: true })}>
              {p.first_name && p.first_name.length > 0 && p.first_name.substr(0, 1).toUpperCase()}
            </span>
          </div>
          <div className={cx({ patientBody: true })}>
            <h6>{p.name}</h6>
            <div style={{ display: 'flex' }}>
              <div className={cx({ patientBodyAccessory: true })}>
                <span style={{ paddingTop: 'center' }}>{patientAges(p.birthdate)}, {patientGender(p.gender)}</span>
              </div>
          { selectedID === p.id &&
          <div>
          <div className={cx({ break: true })}/>
          <div className={cx({ patientBodyAccessory: true })}>
            <span>{"DOB " + formatDate(p.birthdate, true)}</span>
                  {requestStatusFilter && (<div>              {p.latest_past_appointed_at > 0 && (
                    <span>{"Last app. on " + formatDate(p.latest_past_appointed_at, false)}</span>
                  )}
                    {selectPatient.latest_pdmp_fill_at > 0 && (
                      <span>{"Last Fill Date: " + formatDate(p.latest_pdmp_fill_at, false)}</span>
                    )}</div>)}
                  {!requestStatusFilter && (<div>              {selectPatient.latest_past_appointed_at > 0 && (
                    <span>{"Last app. on " + formatDate(selectPatient.latest_past_appointed_at, false)}</span>
                  )}
                    {selectPatient.latest_pdmp_fill_at > 0 && (
                      <span>{"Last Fill Date: " + formatDate(selectPatient.latest_pdmp_fill_at, false)}</span>
                    )}</div>)}
          </div>
          </div>
          }
            </div>
          </div>
        </a>
      </div>
    );
  });

  const transferDom = transferPatients.map((p) => {
    return (
      <div className={cx({ patientItem: true })} key={p.id}>
        <a href="#" onClick={() => onClick(p.id)} className={cx({ active: selectedID === p.id })}>
          <div className={cx({ avatar: true })}>
            <span className={cx({ avatarInitial: true })}>
              {p.first_name && p.first_name.length > 0 && p.first_name.substr(0, 1).toUpperCase()}
            </span>
          </div>
          <div className={cx({ patientBody: true })}>
            <h6>{p.name}</h6>
            <div style={{ display: 'flex' }}>
              <div className={cx({ patientBodyAccessory: true })}>
                <span style={{ paddingTop: 'center' }}>{patientAges(p.birthdate)}, {patientGender(p.gender)}</span>
          </div>
          {selectedID === p.id &&
            <div>
              <div className={cx({ break: true })} />
              <div className={cx({ patientBodyAccessory: true })}>
                <span>{"DOB " + formatDate(p.birthdate, true)}</span>
              {selectPatient.latest_past_appointed_at > 0 && (
                <span>{"Last app. on " + formatDate(selectPatient.latest_past_appointed_at, false)}</span>
              )}
              {selectPatient.latest_pdmp_fill_at > 0 && (
                <span>{"Last Fill Date: " + formatDate(selectPatient.latest_pdmp_fill_at, false)}</span>
              )}
              </div>
            </div>
          }
            </div>
          </div>
        </a>
      </div>
    );
  });

  return (
    <div className={cx({ patientList: true })}>
      { requestStatusFilter && (
        <Tabs
          centered
          tabBarGutter={0}
          defaultActiveKey="1"
          activeKey={requestStatus}
          onChange={onRequestTabChange}
          className={cx({ prescriptionRequestStatusTabs: true })}
        >
          <TabPane tab={(<span>Pending</span>)} key="1" />
          <TabPane tab={(<span>Completed</span>)} key="2" />
        </Tabs>
      )}
      { searchable &&
        <div className={cx({ searchBar: true })}>
          <Input prefix={<SearchOutlined />} bordered={false} onChange={onSearch} />
        </div>
      }
      {
        query.length > 0 && patients.length === 0 &&
        <p style={{padding: '15px'}}>Sorry, no results are found for <b>{query}</b></p>
      }
      <div className={cx({ })} id="scrollableDiv" style={{
        height: '96vh',
        overflow: 'auto',
      }}>
        <InfiniteScroll
          dataLength={patients.length} //This is important field to render the next data
          next={ fetchNext }
          hasMore={patients.length < total}
          scrollThreshold="0.8"
          scrollableTarget='scrollableDiv'
        >
          {requestStatusFilter && (<>{patientsDom}</>)}
          {!requestStatusFilter && (
            <Collapse
              defaultActiveKey={['1']}
              style={{ background: 'white', marginTop: '10px' }}
              ghost={true}
              bordered={false}
              expandIconPosition="right"
              activeKey={activeKey}
              onChange={handleCollapseChange}
            >
              <Panel header={<div style={{ color: '#cdd2d6' }}>New Transfers</div>} key="1">
                {transferDom}
              </Panel>
              <Panel header={<div style={{ color: '#cdd2d6' }}>Current Patients</div>} key="2">
                {patientsDom}
              </Panel>
            </Collapse>)}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default PatientList;
