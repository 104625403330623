import React, { useState } from 'react';
import { Space, Button, Tabs, Tooltip } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import cn from 'classnames/bind';
import { useRequest, useMount, useUpdateEffect, useEventEmitter } from 'ahooks';

import { srvFetchRxntPrescriptionHistories } from '@/services/patient';
import RxntPrescriptionHistory from './RxntPrescriptionHistory';
import AyvaPrescriptionHistory from './AyvaPrescriptionHistory';
import styles from './PrescriptionHistory.less';
const cx = cn.bind(styles);

const PrescriptionHistory = (props) => {
  const { uid, active, reloadRxnt$, onData } = props;
  const [activeKey, setActiveKey] = useState('1');
  const fetchRxntPrescriptionHistories = useRequest(srvFetchRxntPrescriptionHistories, {
    manual: true,
    onSuccess: (ret, params) => {
      reloadRxnt$.emit();
    },
  });
  const onTabChange = (v) => {
    setActiveKey(v);
  };
  return (
    <>
      <h3>
        <Space>
          Prescription History
          <Tooltip title="Sync data from RXNT">
            <Button
              className={cx(styles.syncBtn)}
              onClick={() => {
                fetchRxntPrescriptionHistories.run({ id: uid });
              }}
              loading={fetchRxntPrescriptionHistories.loading}
            >
              <SyncOutlined />
            </Button>
          </Tooltip>
        </Space>
      </h3>
      <Tabs defaultActiveKey="1"
        activeKey={activeKey}
        onTabClick={onTabChange}>
        <Tabs.TabPane tab="RXNT" key="1">
          <RxntPrescriptionHistory uid={uid} active={activeKey ==='1'}  reloadRxnt$={reloadRxnt$} onData={onData} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Ayva" key="2">
          <AyvaPrescriptionHistory uid={uid} active={activeKey ==='2'} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default PrescriptionHistory;
