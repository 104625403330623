import React, { useEffect } from 'react';
import { Button, Select, Space, TimePicker } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { useSetState } from 'ahooks';
import moment from 'moment';

export default function AppointmentSetHoursField({ data, onChange, onDelete,apptTypes }) {
  const [state, setState] = useSetState({
    appointment_type_id: '',
    date_range: [],
  });

  useEffect(() => {
    console.log('##', data);
    const { appointment_type_id, date_range } = data;

    if (date_range?.split('-').length < 2) return;
    const start = moment(date_range?.split('-')[0], 'hh:mma');
    const end = moment(date_range?.split('-')[1], 'hh:mma');

    setState({
      appointment_type_id,
      date_range: [start, end],
    });
  }, [data]);

  function trigger() {
    const { appointment_type_id, date_range } = state;

    const start = moment(date_range[0]).format('hh:mma');
    const end = moment(date_range[1]).format('hh:mma');
    onChange({
      appointment_type_id,
      date_range: `${start}-${end}`,
    });
  }

  function onSelectChange(v) {
    setState({ appointment_type_id: v });
    const { date_range } = state;
    let dateRangeText = '';
    if (date_range.length > 0) {
      const start = moment(date_range[0]).format('hh:mma');
      const end = moment(date_range[1]).format('hh:mma');
      dateRangeText = `${start}-${end}`;
    }

    onChange({
      appointment_type_id: v,
      date_range: dateRangeText,
    });
  }
  function onRangePickerChange(v) {
    setState({ date_range: v });
    const { appointment_type_id } = state;

    if (!v) {
      onChange({
        appointment_type_id,
        date_range: '',
      });
      return;
    }

    const start = moment(v[0]).format('hh:mma');
    const end = moment(v[1]).format('hh:mma');
    onChange({
      appointment_type_id,
      date_range: `${start}-${end}`,
    });
  }
  return (
    <div
      style={{ display: 'flex', marginTop: '5px', alignItems: 'center' }}
    >
      <Select
        value={state.appointment_type_id}
        style={{ width: 400,marginRight:'10px' }}
        onChange={onSelectChange}
        options={apptTypes.map((appt) => ({
          label: appt.name,
          value: appt.appointment_type_id,
        }))}
      />
      <TimePicker.RangePicker
        value={state.date_range}
        use12Hours
        format="hh:mma"
        onChange={onRangePickerChange}
        minuteStep={15}
      />
      <Button
        type="text"
        icon={<MinusCircleOutlined />}
        style={{ marginBottom: '5px', marginLeft: '5px' }}
        onClick={onDelete}
      />
    </div>
  );
}
