import React, {useState,useEffect} from 'react';
import { Radio, Space, Row, Col, Modal, Select, Descriptions, Input,InputNumber, Form, DatePicker } from 'antd';
import cn from 'classnames/bind';
import numeral from 'numeral';
import moment from 'moment-timezone';
import { getUserTimezone } from '@/utils/xtime';

import styles from './index.less';
import { relativeTimeRounding } from 'moment';
const cx = cn.bind(styles);

const AdjustTreatmentRequestDateModal = ({ treatmentRequest, onOk, ...modalProps }) => {
  const [adjustReason, setAdjustReason] = useState();
  const [adjustNote, setAdjustNote] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fillDate, setFillDate] = useState(0);
  const [newDate, setNewDate] = useState(0);
  const [newDateValue, setNewDateValue] = useState('');
  const [disableNewDate, setDisableNewDate] = useState(true);
  const [adjustProviderReason, setAdjustProviderReason] = useState();
  const [showProviderChildOptions, setShowProviderChildOptions] = useState(false);
  const [showProviderChild1Options, setShowProviderChild1Options] = useState(false);
  const [showProviderChild2Options, setShowProviderChild2Options] = useState(false);
  const [limitDays, setLimitDays] = useState(30);
  const [duration, setDuration] = useState(0);
  const [form] = Form.useForm();
  const timeZone = getUserTimezone();

  const handleOk = () => {
    if(adjustReason == undefined) {
      setErrorMessage("Please choose reason")
      return;
    }
    if(adjustReason == 1) {
      if(fillDate == 0) {
        setErrorMessage("Please fill in the fill date");
        return;
      }
    }
    if(newDate == 0) {
      setErrorMessage("Please fill in the new date");
      return;
    }
    if(adjustReason == 2 && adjustNote == "") {
      setErrorMessage("Please enter reason");
      return;
    }
    var reason = ''
    if(adjustReason == 2) {
      reason = adjustNote
    } else if (adjustReason == 3) {
      if (adjustProviderReason == undefined) {
        setErrorMessage("Please choose provider's request reason");
        return;
      }
      if (adjustProviderReason == 31){
        reason="Need Follow-Up:"
      }

      if (adjustProviderReason == 32){
        reason="Need TOC:"
      }


      const slackLinkValue = form.getFieldValue('slackLink');
      if (!slackLinkValue){
        setErrorMessage("Please input your relevant slack link!");
        return;
      }


      reason += slackLinkValue;

    } else {
      reason = 'Request is too early'
    }

    onOk(
      fillDate,
      newDate,
      reason
    )

    setLimitDays(30)
    setDuration(0)
    setNewDateValue('')
    setShowProviderChildOptions(false)
    setAdjustReason(undefined)
  }

  const modalOpts = {
    ...modalProps,
    onOk: handleOk,
  };
  const adjustReasonOnChange = e => {
    setNewDateValue('')
    if (e.target.value === 3) {
      setShowProviderChildOptions(true);
      console.log(Math.round((modalOpts?.treatment_request?.created_at+ 30*86400-moment().valueOf()/1000)/86400))
      setLimitDays( Math.round((modalOpts?.treatment_request?.created_at+ 30*86400-moment().valueOf()/1000)/86400))
    } else if (e.target.value == 1) {
      setShowProviderChildOptions(false);
      setLimitDays(0);
    } else if (e.target.value == 2) {
      setShowProviderChildOptions(false);
      setLimitDays(30);
    }

    setErrorMessage("");
    setAdjustReason(e.target.value);
    if(e.target.value != 1) {
      setDisableNewDate(false)
    }else {
      setDisableNewDate(true)
    }
  };


  const adjustProviderReasonOnChange = e => {
    if (e.target.value === 31) {
      setShowProviderChild1Options(true);
      setShowProviderChild2Options(false);
    } else if (e.target.value === 32) {
      setShowProviderChild1Options(false);
      setShowProviderChild2Options(true);
    }

    setErrorMessage("");
    setAdjustProviderReason(e.target.value);
  };

  const adjustNoteOnChange = e => {
    setErrorMessage("");
    setAdjustNote(e.target.value);
  };

  const onFillDateChange = (date, dateString) => {
    setErrorMessage("");
    var dateInTimezone = moment.tz(dateString, timeZone);
    setFillDate(dateInTimezone.unix());
    if (duration != 0) {
      var _newDate = dateInTimezone.clone().add(duration-5, 'd')
      setNewDateValue(_newDate);
      setNewDate(_newDate.unix())
      setDisableNewDate(true)
    }

  }

  const onDurationNumberChange = (data) => {
    var newData = data;
    if (data > 90) {
      newData = 90;
    } else if (data < 1) {
      newData = 1;
    }else {
      setDuration(newData);
    }


    if (fillDate != 0) {
      setNewDate(fillDate + (newData - 5) * 86400);
      setNewDateValue(moment.unix(newDate));
      setDisableNewDate(true);
    }
  }

  const onNewDateChange = (date, dateString) => {
    setErrorMessage("");
    setNewDate(moment.tz(dateString, timeZone).unix())
    setNewDateValue(date)
  }
  const disabledDate = (current) => {
    if (limitDays == null) {
      return;
    }
    const currentDate = moment();
    const ninetyDaysAhead = moment().add(limitDays, 'days');
    return  current > ninetyDaysAhead;
  };

  useEffect(() => {

  }, [limitDays]);



  return (
    <Modal {...modalOpts} destroyOnClose = {true} className={cx({ adjustTreatmentRequestModal: true })}>
      <div className={cx({ formGroup: true })}>
        <label>Reason</label>
        <p>Please provide a reason for this adjustment</p>
        <Radio.Group onChange={adjustReasonOnChange} value = {adjustReason}>
          <Space direction="vertical">
            <Radio value={1}>Request is too early</Radio>
            <Radio value={3}>Provider's request
              {showProviderChildOptions && (
                <div style={{ marginLeft: '10px',marginTop:'10px',marginBottom: '-20px'}}>
                  <Radio.Group onChange={adjustProviderReasonOnChange} value = {adjustProviderReason}  >
                    <Space direction="vertical">
                      <Radio value={31}>Need Follow up
                        {showProviderChild1Options && (
                          <div >
                            <Form form={form}>
                              <Form.Item name="slackLink" rules={[{ required: true,message: 'Please input your relevant slack link!' }]} style={{ marginLeft: '10px',marginTop:'10px',marginBottom: '-20px'}}>
                                <Input
                                  placeholder="the relevant slack link requested by Provider"
                                  maxLength={400}
                                  style={{ width: '500px', marginLeft: '15px' }}
                                />
                              </Form.Item>
                            </Form>
                          </div>
                        )}
                      </Radio>
                      <Radio value={32}>Need TOC
                        {showProviderChild2Options && (
                          <div >
                            <Form form={form}>
                              <Form.Item name="slackLink" rules={[{ required: true,message: 'Please input your relevant slack link!' }]} style={{ marginLeft: '10px',marginTop:'10px',marginBottom: '-20px'}}>
                                <Input
                                  placeholder="the relevant slack link requested by Provider"
                                  maxLength={400}
                                  style={{ width: '500px', marginLeft: '15px' }}
                                />
                              </Form.Item>
                            </Form>
                          </div>
                        )}
                      </Radio>
                    </Space>
                  </Radio.Group>
              </div>
              )}
            </Radio>

            <Radio value={2}>Other</Radio>
          </Space>
        </Radio.Group>
        { adjustReason === 2 &&
          <Input.TextArea placeholder="Please specify..." onChange={adjustNoteOnChange} value = {adjustNote} style={{marginBottom:'10px'}}/>
        }
        { adjustReason == 1 &&
          <div className={cx({ formGroup: true })}>
            <label>Last PDMP Fill Date:</label>
            <DatePicker onChange={onFillDateChange} disabledDate={disabledDate}  style={{ width: '150px', marginLeft: '15px' }}/>
          </div>
        }
        { adjustReason == 1 &&
          <div className={cx({ formGroup: true })}>
            <label >Last Prescription Medication Duration:</label>
            <InputNumber
              onChange={onDurationNumberChange}
              min={1} max={90}
              style={{ width: '150px', marginLeft: '15px' }}
            /> days
          </div>

        }
        <div className={cx({ formGroup: true })}>
          <label>New Date:</label>
          <DatePicker onChange={onNewDateChange} value={newDateValue} disabled={disableNewDate} disabledDate={disabledDate} style={{ width: '150px', marginLeft: '15px' }} />
        </div>
      </div>

      {errorMessage.length > 0 && <p style={{color: 'red'}}>{errorMessage}</p>}
    </Modal>
  );
};

export default AdjustTreatmentRequestDateModal;
