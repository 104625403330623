import { useEffect } from 'react';
import { ProCard, ProDescriptions, ProList } from '@ant-design/pro-components';
import { Button, Col, Row, Typography, Divider } from 'antd';
import { Link } from 'umi';
import { useSetState } from 'ahooks';
import moment from 'moment';
import { useNoteStore } from '@/stores';
import { initIntakeformInfo, findIntakeFormAnswer } from '@/components/PatientInfoPane/intakeform';
import PatientDrawers from '@/components/InitialEvaluationNote/PatientDrawers';

const { Title } = Typography;

export default function PatientInfoCard() {
  const { loading, patient } = useNoteStore();
  const [state, setState] = useSetState({
    additionalInfo: [],
    previousMedications: [],
  });

  useEffect(() => {
    if (!patient) return;

    const intakeformAnswers = JSON.parse(patient.intake_form.options);
    const assessmentAnswers = JSON.parse(patient.assessment.options);
    const list = initIntakeformInfo().map((i) => {
      i.answer = findIntakeFormAnswer(i, intakeformAnswers, assessmentAnswers);
      return i;
    });
    setState({
      additionalInfo: list,
      previousMedications: [
        {
          title:
            'Do you have any experience with medications used to treat other behavioral health conditions?',
          answer: patient.intake_form.previous_adhd_medications,
        },
        {
          title: 'Are you currently taking any prescription medications?',
          answer: patient.intake_form.current_medications,
        },
        {
          title: 'Do you have any allergies to medication?',
          answer: patient.intake_form.allergic_medications,
        },
      ],
    });
  }, [patient]);

  const patientInfoColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'birthdate',
      renderText: (birthdate) => {
        return moment().diff(moment.unix(birthdate), 'years');
      },
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
    },
    {
      title: 'DOB',
      dataIndex: 'birthdate',
      renderText: (birthdate) => {
        return moment.unix(birthdate).utc().format('MM/DD/YYYY');
      },
    },
    {
      title: 'State',
      dataIndex: ['address', 'state'],
    },
  ];

  const assessmentResultsData = [
    {
      key: '1',
      title: 'ASRS',
      content: patient?.assessment?.conclusion || patient?.intake_form?.asrsb || '-',
    },
    {
      key: '2',
      title: 'PHQ-9',
      content: patient?.intake_form?.phq_9 || '-',
    },
    {
      key: '3',
      title: 'GAD-7',
      content: patient?.intake_form?.gad_7 || '-',
    },
  ];

  function toggleIntakeDrawer(item) {
    if (item.title === 'ASRS') {
      toggleIntakeASRS();
    } else if (item.title === 'PHQ-9') {
      toggleIntakePHQ();
    } else if (item.title === 'GAD-7') {
      toggleIntakeGAD();
    }
  }

  function toggleIntakeASRS() {
    setState({ intakeASRSVisible: !state.intakeASRSVisible });
  }

  function toggleIntakeGAD() {
    setState({ intakeGADVisible: !state.intakeGADVisible });
  }

  function toggleIntakePHQ() {
    setState({ intakePHQVisible: !state.intakePHQVisible });
  }

  return (
    <>
      <ProCard
        title="Patient Chart"
        colSpan="40%"
        loading={loading}
        bordered
        extra={
          <Link to={`/patient/${patient.id}?tab=1`}>
            <Button type="link" size="small">
              View
            </Button>
          </Link>
        }
        bodyStyle={{ overflowY: 'scroll' }}
        style={{
          height: '100%',
        }}
      >
        <ProDescriptions
          title="Patient Chart"
          layout="vertical"
          dataSource={patient}
          columns={patientInfoColumns}
        />
        <Title level={5}>Assessment Results</Title>
        {assessmentResultsData.map((item) => {
          return (
            <Row key={item.key}>
              <Col span={6} style={{ fontWeight: 'bold' }}>
                {item.title}
              </Col>
              <Col span={12}>{item.content}</Col>
              <Col span={6}>
                <Button type="link" size="small" onClick={() => toggleIntakeDrawer(item)}>
                  Detail
                </Button>
              </Col>
            </Row>
          );
        })}
        <Divider />
        <ProDescriptions
          title="Additional Information"
          layout="vertical"
          dataSource={state.additionalInfo}
          column={1}
          colon={false}
        >
          {state.additionalInfo.map((item) => {
            return (
              <ProDescriptions.Item label={item.title} key={item.key}>
                {item.answer}
              </ProDescriptions.Item>
            );
          })}
        </ProDescriptions>
        <Divider />
        <ProDescriptions title="Previous Medications" layout="vertical" column={1} colon={false}>
          {state.previousMedications.map((item) => {
            return (
              <ProDescriptions.Item label={item.title} key={item.key}>
                {item.answer}
              </ProDescriptions.Item>
            );
          })}
        </ProDescriptions>
      </ProCard>
      <PatientDrawers
        patientInfo={patient}
        onIntakeASRSClose={toggleIntakeASRS}
        intakeASRSVisible={state.intakeASRSVisible}
        onIntakeGADClose={toggleIntakeGAD}
        intakeGADVisible={state.intakeGADVisible}
        onIntakePHQClose={toggleIntakePHQ}
        intakePHQVisible={state.intakePHQVisible}
      />
    </>
  );
}
