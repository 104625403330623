import { stringify } from 'querystring';
import { history } from 'umi';
import { message } from 'antd';
import {srvCalendarAppointments, srvDoctorBlockRemove, srvDoctorBlocks} from '@/services/patient';

const Model = {
  namespace: 'ehr_calendar',
  state: {
    calendarAppointments: [],
    saveCalendarBlocks: [],
  },
  effects: {
    *fetchCalendarAppointments({ payload }, { call, put }) {
      const res = yield call(srvCalendarAppointments, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveCalendarAppointments', payload: res.data });
    },
    *fetchDoctorBlocks({ payload }, { call, put }) {
      const res = yield call(srvDoctorBlocks, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveCalendarBlocks', payload: res.data });
    },
    *removeBlock({ payload, callback }, { call, put }) {
      const res = yield call(srvDoctorBlockRemove, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
  },
  reducers: {
    saveCalendarAppointments(state, { payload }) {
      return { ...state, calendarAppointments: payload };
    },
    saveCalendarBlocks(state, { payload }) {
      return { ...state, saveCalendarBlocks: payload };
    },
  },
};
export default Model;
