import React, { useState } from 'react';
import { connect} from 'umi';
import { Tabs } from 'antd';
import UrgentRefill from './urgent_refill';
import CoverageConsultation from './coverage_consultation';
import cn from 'classnames/bind';
const { TabPane } = Tabs;
import styles from './index.less';
import defaultSettings from '../../../defaultSettings';
import { createFromIconfontCN } from '@ant-design/icons';
const Coverage = (props) => {
  const cx = cn.bind(styles);
  const [activeKey, setActiveKey] = useState('1');
  const onTabChange = (v) => {
    setActiveKey(v);
  };

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Care Coverage</h3>
      </div>
        <Tabs defaultActiveKey="1" activeKey={activeKey} onTabClick={onTabChange}>
          <TabPane tab="Prescription Renewal Requests" key="1">
            {activeKey==='1'&&<UrgentRefill />}
          </TabPane>
          <TabPane tab="Consultation Messages" key="2">
          {activeKey==='2'&&<CoverageConsultation />}
          </TabPane>
        </Tabs>
    </div>
  );
};

export default connect(({ doctor, loading }) => ({
  doctor,
  loading,
}))(Coverage);
