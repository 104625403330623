import React, { useState, useEffect } from 'react';
import { Form, Input, InputNumber, Space, DatePicker } from 'antd';

import moment from "moment-timezone";

import CheckboxTextInputFormItem from './CheckboxTextInputFormItem';
import { timeSpentItems } from './meta'


const TimeSpentFormItems = (props) => {
  const { form, draft, setDraft } = props;

  const appointment_start_time = Form.useWatch(['struct_note_data', 'appointment_start_time'], form);

  useEffect(() => {
    if (appointment_start_time) {
      form.setFieldsValue({
        'spent_day_date': moment(appointment_start_time)
      })
    }
  }, [draft])

  return (
    <>
      <Space>
        <span style={{ display: 'block', height: '48px', lineHeight: '32px', margin: '0 5px' }}>A total of</span>
        <Form.Item name="spent_total_mins">
          <InputNumber placeholder="" min={0} precision={0} step={1} />
        </Form.Item>
        <span style={{ display: 'block', height: '48px', lineHeight: '32px', margin: '0 5px' }}>minutes was spent on</span>
        <Form.Item name="spent_day_date">
          <DatePicker format="MM/DD/YYYY" showTime={false} showToday={true} size="default" />
        </Form.Item>
      </Space>

      <h4 style={{ marginBottom: '15px' }}>conducting the following (check the  ones that apply):</h4>
      {timeSpentItems.map((v, index) => {
        return <CheckboxTextInputFormItem key={index} name={v.value} label={v.label} layout={'horizontal'} form={form} draft={draft} setDraft={setDraft} />
      })}
    </>
  )
};

export default TimeSpentFormItems;
