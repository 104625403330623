import { Redirect } from 'umi';
import { connect, history } from 'umi';

const HomePage = (props) => {
  const { account } = props;
  if (account.currentUser.role === 'admin') {
    return <Redirect to="/dashboard" />;
  } else {
    return <Redirect to="/home" />;
  }
};

export default connect(({ account, loading }) => ({
  account,
  loading,
}))(HomePage);
