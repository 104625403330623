import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link } from 'umi';
import { Drawer, Space, Popover, Input, Table, Row, Col, Button } from 'antd';
import moment from 'moment';
import { SearchOutlined, createFromIconfontCN } from '@ant-design/icons';
import numeral from 'numeral';
import { AsYouType } from 'libphonenumber-js';

import Filters from '@/components/Filters';
import defaultSettings from '../../../defaultSettings';
import ConsultationDrawer from '@/components/ConsultationDrawer';

import { formatUnix } from '../../utils/xtime';
import styles from './index.less';
const cx = cn.bind(styles);

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const filters = [
  {
    name: 'user_email',
    label: 'Patient Email',
    type: 'text',
  },
  {
    name: 'user_phone',
    label: 'Patient Phone',
    type: 'text',
  },
  {
    name: 'consultation_type',
    label: 'Type',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'created_at',
    label: 'Date',
    type: 'date',
  },
];

const Patient = (props) => {
  const {
    active,
    consultations,
    consultation_types,
    loadingConsultations,
    replyData,
    loadReplies,
    loadingReplies,
    loadingSendMessage,
  } = props;
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState('');
  const [selectedType,setSelectedType]=useState(-1);
  const inputEl = useRef(null);

  const onClose = () => {
    setVisible(false);
    setSelected('');
  };

  const selectRow = (record) => {
    setSelected(record.id);
    setSelectedType(record.consultation_type);
    setVisible(true);
    loadReplies({ id: record.id });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
    },
    {
      title: 'Type',
      dataIndex: 'consultation_type',
      key: 'consultation_type',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return r.consultation_type_name;
      },
    },
    {
      title: 'Replies',
      dataIndex: 'reply_num',
      key: 'reply_num',
      ellipsis: true,
      width: 70,
    },
    {
      title: 'Patient',
      dataIndex: 'user_id',
      key: 'user_id',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        if (r.patient) {
          const userName = r?.patient?.first_name ? `${r?.patient?.first_name} ${r?.patient?.last_name}` : r?.patient?.name;
          return (
            <Popover
              content={
                <div className="popover-user">
                  <div className="popover-user-item username">
                    <IconFont className="icon" type="icon-username" />
                    <Link to={`/patient/${r.patient.id}?admin=true`}>{userName}</Link>
                  </div>
                  <div className="popover-user-item email">
                    <IconFont className="icon" type="icon-email" />
                    <Link to={`/patient/${r.patient.id}?admin=true`}>{r.patient.email}</Link>
                  </div>
                  <div className="popover-user-item phone">
                    <IconFont className="icon" type="icon-phone" />
                    {new AsYouType('US').input(r.patient.phone)}
                  </div>
                  <div className="popover-user-item address">
                    <IconFont className="icon" type="icon-address" />
                    {r.patient.zipcode}
                  </div>
                  <div className="popover-user-item sex">
                    <IconFont className="icon" type="icon-sex" />
                    {r.patient.gender}
                  </div>
                </div>
              }
              title={userName}
              trigger="hover"
            >
              <a href="#">{userName}</a>
            </Popover>
          );
        }
        return '';

      },
    },

    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: 140,
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
    },
  ];

  const reloadReplyData = () => {
    loadReplies({ id: replyData.consultation.id });
    props.loadConsultations(
      { page: 1, page_size: 20, filters: JSON.stringify(query) },
      () => {},
    );
  }

  useEffect(() => {
    setPage(1);
    props.loadConsultations({ page: 1, page_size: 20 }, () => {});
    props.loadConsultationTypes();
  }, [active]);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    props.loadConsultations({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => {});
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    props.loadConsultations(
      { page: pagination.current, page_size: pagination.pageSize, filters: JSON.stringify(query) },
      () => {},
    );
  };

  const f = () => {
    for (const v of filters) {
      if (v.name === 'consultation_type' && v.items.length === 0) {
        v.items = consultation_types;
        continue;
      }
    }
    return filters;
  };

  return (
    <div className={cx({ subcontainer: true })}>
      <div className={cx({ subheader: true })}>
        <h3>Consultations</h3>
        <Space>
          <Filters filters={f()} onFilter={onFilter} />
        </Space>
      </div>

      { selected != "" &&
        <ConsultationDrawer
          role="admin"
          visible={visible}
          replyData={replyData}
          onClose={onClose}
          selectedType={selectedType}
          onReply={() => { reloadReplyData() }}
        />
      }

      <Table
        columns={columns}
        dataSource={consultations.items}
        rowClassName={(r) => {
          if (r.id == selected) {
            return 'rowSelected';
            // return styles.rowSelected;
          }
          return '';
        }}
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
        rowKey="id"
        loading={loadingConsultations}
        onChange={handleTableChange}
        pagination={{ pageSize: 20, current: page, simple: true, total: consultations.total }}
      />
    </div>
  );
};

export default Patient;
