import React, { useEffect, useState } from 'react';
import { connect, history, Link } from 'umi';
import cn from 'classnames/bind';
import { Statistic, Row, Col, Modal, Button } from 'antd';

import { useRequest } from 'ahooks';
import {
  srvSystemMigration,
} from '@/services/patient';

import styles from './index.less';
const cx = cn.bind(styles);

const Dashboard = (props) => {
  const { account } = props;
  
  const isDone = window.location.href.includes('donefirst.com');

  const [showSystemMigration, setShowSystemMigration] = useState(isDone && !account?.currentUser?.user_classification);

  const goSystemMigration = useRequest(srvSystemMigration, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setShowSystemMigration(false);
        if (ret.data && ret.data.redirect_url) {
          window.location.href = ret.data.redirect_url;
        }
      }
    },
  });

  return (
    <div className={cx({ dashboard: true })}>
      <div className={cx({ header: true })}>
        <h3>Today</h3>
      </div>
      <Row gutter={16}>
        <Col span={8}>
          <Statistic title="Upcoming Appointments" value={0} />
        </Col>
        <Col span={8}>
          <Statistic title="Refill Requests" value={0} />
        </Col>
        <Col span={8}>
          <Statistic title="Consultation" value={0} />
        </Col>
      </Row>


      {showSystemMigration && (
        <Modal title="Important: EHR System Domain Migration"
          style={{ zIndex: 999999 }}
          open={showSystemMigration}
          closable={true}
          onCancel={() => { setShowSystemMigration(false) }}
          footer={[
            <Button key="OK" type="primary" style={{ width: 200 }} loading={goSystemMigration.loading} onClick={() => {
              goSystemMigration.run({ client_id: '77eae7aaebf39fd0c8bef84e58b37cfd' });
            }}>
              Go to Mindful EHR
            </Button>
          ]}
        >
          <p>Dear Care Team Member,</p>
          <br />
          <p>We are excited to inform you that our EHR system will be migrating to a new domain: <a href='https://ehr.getmindfulhealth.com/' style={{ color: '#566BCD', textDecoration: 'underline' }}>https://ehr.getmindfulhealth.com/</a></p>
          <br />
          <h3 style={{ fontWeight: 'bold', marginBottom: '5px' }}>What this means for you:</h3>
          <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
            <li><b>More Efficient Collaboration:</b> The new domain will provide a more convenient platform for your team collaboration.</li>
            <li><b>Secure and Reliable Data:</b> All your patient data will be securely protected in the new system.</li>
          </ul>
          <br />
          <h3 style={{ fontWeight: 'bold', marginBottom: '5px' }}>Important Notes:</h3>
          <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
            <li><b>Update Your Bookmarks:</b> Please be sure to click on the link <a href='https://ehr.getmindfulhealth.com/' style={{ color: '#566BCD', textDecoration: 'underline' }}>https://ehr.getmindfulhealth.com/</a> to update your browser bookmarks to ensure you can access the system normally after the migration.</li>
            <li><b>Technical Support:</b> We will provide online training to help you quickly familiarize yourself with the new system. The training time and format will be notified separately.</li>
          </ul>
          <br />
          <p>Thank you for your understanding and cooperation.</p>
        </Modal>
      )}
    </div>
  );
};

export default connect(({ account, loading }) => ({
  account,
  loading,
}))(Dashboard);
