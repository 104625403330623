

import { Drawer, Space, Descriptions, Input, Radio, Select, Table, Row, Col, Button, Layout, Form, Checkbox, Tag, Divider, Tooltip,  } from 'antd';
import { Link } from 'umi';

import moment from 'moment';
import cn from 'classnames/bind';
import styles from './PatientSider.less';
const cx = cn.bind(styles);

const PatientSider = (props) => {

  const {
    showASRS,
    showIntakePHQ,
    showIntakeGAD,
    intakeformInfo,
    patientInfo,
    sync2AyvaReq,
    onSyncPatient2Ayva,
  } = props;

  const intakeformInfoJsx = (data) => {
    return data.map((i) => (
      <Descriptions.Item label={i.title} key={i.key}>
        {i.answer}
      </Descriptions.Item>
    ));
  };

  const assessmentResultsColumns = [
    {
      title: 'name',
      dataIndex: 'name',
    },
    {
      title: 'value',
      dataIndex: 'value',
    },
    {
      title: 'action',
      dataIndex: 'action',
    },
  ]

  const assessmentResultsData = [
    {
      key: '1',
      name: 'ASRS',
      value:
        (patientInfo.assessment && patientInfo.assessment.conclusion) ||
        (patientInfo.intake_form && patientInfo.intake_form.asrsb),
      action: (patientInfo.assessment || patientInfo.intake_form) && (
        <Button size="small" type="link" onClick={showASRS}>
          Detail
        </Button>
      )
    },
    {
      key: '2',
      name: 'PHQ-9',
      value: patientInfo.intake_form && patientInfo.intake_form.phq_9,
      action: patientInfo.intake_form && (
        <Button size="small" type="link" onClick={showIntakePHQ}>
          Detail
        </Button>
      ),
    },
    {
      key: '3',
      name: 'GAD-7',
      value: patientInfo.intake_form && patientInfo.intake_form.gad_7,
      action: patientInfo.intake_form && (
        <Button size="small" type="link" onClick={showIntakeGAD}>
          Detail
        </Button>
      )
    },
  ]

  const patientInfoHeader = () => {
    return (
      <h2>
        Patient Chart
        <Link to={`/patient/${patientInfo.id}?tab=1`} className={cx({ link: true })} target="_blank">
          View
        </Link>
      </h2>
    );
  };

  return (
    <div className={cx({ container: true })}>

      <Descriptions title={patientInfoHeader()} layout="vertical">
        <Descriptions.Item label="Name">{patientInfo.first_name} {patientInfo.last_name}</Descriptions.Item>
        <Descriptions.Item label="Age">
          {patientInfo.birthdate && moment().diff(moment.unix(patientInfo.birthdate), 'years')} &nbsp; {patientInfo.patient_type == 1 && (<Tag color="red">Pediatric</Tag>)} {patientInfo.patient_type == 3 && (<Tag color="red">Insomnia</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="Gender">{patientInfo.gender}</Descriptions.Item>
        <Descriptions.Item label="DOB">
          {patientInfo.birthdate && moment.unix(patientInfo.birthdate).utc().format('MM/DD/YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label="State">{patientInfo.address && patientInfo.address.state}</Descriptions.Item>
        <Descriptions.Item label="Ayva">
          <Space>
            {patientInfo.ayva_id}
            {patientInfo.ayva_id == 0 && (<Button
              size="small"
              type="primary"
              onClick={onSyncPatient2Ayva}
              loading={sync2AyvaReq.loading}
            >
              Sync to Ayva
            </Button> )}
          </Space>
        </Descriptions.Item>
      </Descriptions>

      <Table title={() => (<h3>Assessment Results</h3>)} columns={assessmentResultsColumns} dataSource={assessmentResultsData} bordered={false} showHeader={false} pagination={false} />

      <Descriptions layout='vertical' title="Additional Information" colon={false} column={1}>
        {intakeformInfoJsx(intakeformInfo)}
      </Descriptions>

      {
        patientInfo.patient_type != 1 &&
        <Descriptions title="Previous medications" layout="vertical" column={1} colon={false}>
          <Descriptions.Item label="Do you have any experience with medications used to treat other behavioral health conditions?">
            {patientInfo.intake_form && patientInfo.intake_form.previous_adhd_medications}
          </Descriptions.Item>
          <Descriptions.Item label="Are you currently taking any prescription medications?">
            {patientInfo.intake_form && patientInfo.intake_form.current_medications}
          </Descriptions.Item>
          <Descriptions.Item label="Do you have any allergies to medication?">
            {patientInfo.intake_form && patientInfo.intake_form.allergic_medications}
          </Descriptions.Item>
        </Descriptions>
      }
    </div>
  )
}

export default PatientSider;