import React, { useState, useEffect } from 'react';
import { Form, Input, Radio, Space } from 'antd';

import { useMount, useUpdateEffect } from 'ahooks';

const RadioGroupWithTextInput = (props) => {

  const { name, label, options = [], form, draft, setDraft } = props;
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedOption, setSelectedOption] = useState({});

  const onRadioGroupChange = (e) => {
    // console.log('onRadioGroupChange:', e);
    setSelectedValue(e.target.value);
    options.forEach(v => {
      if (e.target.value === v.value) {
        setSelectedOption(v);
        const field = {}
        field[name] = e.target.value;
        field[`${name}_text`] = undefined;
        form.setFieldsValue(field);
        setDraft(Object.assign({ ...draft }, field));
      }
    })
  }

  useEffect(() => {
    options.forEach(v => {
      if (selectedValue === v.value) setSelectedOption(v);
    })
  }, [selectedValue]);

  // draft sync to form
  useUpdateEffect(() => {
    if (draft && draft.hasOwnProperty(name)) {
      setSelectedValue(draft[name]);
    }
  }, [draft]);

  return (
    <div style={{ marginBottom: '16px' }}>
      <Form.Item name={name} label={label} style={{ marginBottom: '0px' }}>
        <Radio.Group options={options} onChange={onRadioGroupChange} style={{ margin: '3px auto' }} />
      </Form.Item>
      {selectedOption?.hasInput && Boolean(form.getFieldValue(name)) && (
        <Form.Item name={`${name}_text`}
          validateTrigger={'onBlur'}
          rules={[
            {
              required: selectedOption?.inputRequired || false,
              message: `${label} is required`,
            },
          ]}
          noStyle>
          <Input type={'text'} />
        </Form.Item>
      )}
    </div>
  )
};

export default RadioGroupWithTextInput;