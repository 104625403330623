import React, { useState, useEffect } from 'react';
import { Spin, Descriptions, Table, Drawer, Input, Button } from 'antd';
import { history, Link } from 'umi';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';

import {
  srvPatientTreatmentPlanHistoryEditLogDetail,
  srvPatientTreatmentPlanHistoryEditLogs,
} from '@/services/patient';
import styles from './ProviderNotes.less';
import { formatUnix, formatNextFollowUpMonth } from '@/utils/xtime';
import { isJSON } from '@/utils/utils.js';
import InitialEvaluationDetailView from '@/components/InitialEvaluationNote/InitialEvaluationDetailView';
import { CPTCodesMap } from '@/components/CPTCodesFormItem';
import { formatLastRefillDate } from '@/components/LastRefiiDateFormItem';
const cx = cn.bind(styles);

const EditNoteLog = (props) => {
  const { visible, onClose, itemID } = props;
  const [data, setData] = useState([]);
  const [treatmentPlanHistory, setTreatmentPlanHistory] = useState({});

  const loadLogReq = useRequest(srvPatientTreatmentPlanHistoryEditLogs, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setData(ret.data);
      }
    },
  });

  const getLogDetailReq = useRequest(srvPatientTreatmentPlanHistoryEditLogDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        const data = ret.data ? ret.data : {};
        setTreatmentPlanHistory(data);

      }
    },
  });
  const selectRow = (r) => {
    getLogDetailReq.run({ id: r.id });
  };

  useMount(() => {
    loadLogReq.run({ id: itemID });
  });

  const columns = [
    {
      title: 'Action',
      dataIndex: 'action',
      ellipsis: true,
      key: 'action',
      width: 150,
    },
    {
      title: 'User',
      dataIndex: 'action_user_name',
      ellipsis: true,
      key: 'action_user_name',
    },
    {
      title: 'Time',
      dataIndex: 'updated_at',
      key: 'updated_at',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return formatUnix(r.updated_at);
      },
    },
  ];

  const renderClinicianNotesDom = (note) => {
    if (note.note_type === 1) {
      return (
        <InitialEvaluationDetailView note={note} />
      )
    }
    return <div className={styles.lineBreak}>{note.provider_notes}</div>
  }

  const renderAddendum = () => {
    return treatmentPlanHistory && treatmentPlanHistory.Addendums && treatmentPlanHistory.Addendums.length ? (
      treatmentPlanHistory.Addendums.map((addendum) => (
        <Descriptions title="Addendums" layout="vertical" column={1} size="small" bordered={true}>
          <Descriptions.Item
            label={
              addendum.creator_type +
              ' Addendum - ' +
              addendum.creator_name +
              ' ' +
              addendum.creator_title +
              ' ' +
              moment.unix(addendum.created_at).format('MM/DD/YYYY')
            }
          >
            {addendum.addendum}
          </Descriptions.Item>
        </Descriptions>
      ))
    ) : (
      <></>
    );
  };

  return (
    <Drawer
      className={cx({ noteRevisionDrawer: true })}
      title="Note History"
      width="600"
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      destroyOnClose={true}
    >
      <Spin tip="Loading..." spinning={getLogDetailReq.loading}>
        <h3>History</h3>
        <Table
          columns={columns}
          dataSource={data}
          rowKey="id"
          loading={loadLogReq.loading}
          pagination={false}
          onRow={(record) => ({
            onClick: (evt) => {
              selectRow(record);
            },
          })}
        />

        <h3>Detail</h3>

        {treatmentPlanHistory.id && (
          <>
            <Descriptions
              title="Diagnostics"
              layout="vertical"
              column={2}
              size="small"
              bordered={true}
            >
              <Descriptions.Item label="Diagnostic Code">
                {treatmentPlanHistory.diagnostic_code}
              </Descriptions.Item>
              <Descriptions.Item label="Updated At">
                {treatmentPlanHistory.updated_at &&
                  moment.unix(treatmentPlanHistory.updated_at).format('MMM D h:mm A')}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions
              title="Diagnostics"
              layout="vertical"
              column={1}
              size="small"
              bordered={true}
            >
              <Descriptions.Item label="Rx">{treatmentPlanHistory.rx}</Descriptions.Item>
              <Descriptions.Item label="Diagnostic Code">
                {treatmentPlanHistory.diagnostic_code}
              </Descriptions.Item>
              <Descriptions.Item label="Last Refill Date">{formatLastRefillDate(treatmentPlanHistory.last_refill_date)}</Descriptions.Item>
              <Descriptions.Item label="CPT Code">{CPTCodesMap[treatmentPlanHistory.cpt_code] ? CPTCodesMap[treatmentPlanHistory.cpt_code] : treatmentPlanHistory.cpt_code}</Descriptions.Item>
              {treatmentPlanHistory.addon_code && (
                <Descriptions.Item label="Add-on Code">{CPTCodesMap[treatmentPlanHistory.addon_code] ? CPTCodesMap[treatmentPlanHistory.addon_code] : treatmentPlanHistory.addon_code}</Descriptions.Item>
              )}
              <Descriptions.Item label="Attempted Medications">
                {treatmentPlanHistory.prev_medications}
              </Descriptions.Item>
              <Descriptions.Item label="Clinician Notes">
                {renderClinicianNotesDom(treatmentPlanHistory)}
              </Descriptions.Item>
              <Descriptions.Item label="Next Follow Up Date">
                {treatmentPlanHistory.next_follow_up_date > 0 ? formatUnix(treatmentPlanHistory.next_follow_up_date, 'MMM D, YYYY') : ''}
              </Descriptions.Item>
              <Descriptions.Item label="I‘d like to remind this patient to schedule their next follow-up appointment in">
                {formatNextFollowUpMonth(treatmentPlanHistory.next_follow_up_month)}
              </Descriptions.Item>
              <Descriptions.Item label="Message To Patient">
                <div className={styles.lineBreak}>{treatmentPlanHistory.message_to_patient}</div>
              </Descriptions.Item>
            </Descriptions>
            {renderAddendum()}
          </>
        )}
      </Spin>
    </Drawer>
  );
};

export default EditNoteLog;
