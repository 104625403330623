import { transformRoute } from '@umijs/route-utils';

function fromEntries(iterable) {
  return [...iterable].reduce((obj, [key, val]) => {
    // eslint-disable-next-line no-param-reassign
    obj[key] = val;
    return obj;
  }, {});
}

export default (routes, menu, menuDataRender) => {
  const { menuData, breadcrumb } = transformRoute(routes, menu && menu.locale || false, null, true);
  if (!menuDataRender) {
    return {
      breadcrumb: fromEntries(breadcrumb),
      breadcrumbMap: breadcrumb,
      menuData,
    };
  }
  const renderData = transformRoute(menuDataRender(menuData), menu && menu.locale || false, null, true);
  return {
    breadcrumb: fromEntries(renderData.breadcrumb),
    breadcrumbMap: renderData.breadcrumb,
    menuData: renderData.menuData,
  };
};
