import React, { useState } from 'react';
import { Space, Button, Alert, Modal } from 'antd';
import { Link } from 'umi';
import cn from 'classnames/bind';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';

import { srvRxntStatus } from '@/services/patient';
import RxntAccountModel from './RxntAccountModel';
import styles from './RxntStatus.less';
const cx = cn.bind(styles);

const RxntStatus = (props) => {
  const { active, id } = props;
  const [rxntStatus, setRxntStatus] = useState({});
  const [rxntAccountModalVisible, setRxntAccountModalVisible] = useState(false);

  const loadRxntStatusReq = useRequest(srvRxntStatus, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setRxntStatus(ret.data);
      }
    },
  });

  const loadData = () => {
    if (active) {
      loadRxntStatusReq.run({ id });
    }
  };

  useMount(() => {
    loadData();
  });

  useUpdateEffect(() => {
    loadData();
  }, [id, active]);

  return (
    <div className={cx({ ayvaStatus: true })}>
      {rxntStatus.patient_rxnt_id && rxntStatus.patient_rxnt_id === '0' && (
        <Alert
          type="info"
          description={
            <Space>
              <div>
                This patient is not exist in RXNT yet, you can click <b>Sync to RXNT</b> button in{' '}
                <Link to={`/patient/${id}?tab=1`} className={cx({ link: true })}>
                  Patient Chart
                </Link>{' '}
                or contact admin.
              </div>
            </Space>
          }
        />
      )}
      {!rxntStatus.need_individual_account && !rxntStatus.is_doctor_authorized && (
        <Alert
          type="info"
          description={
            <Space>
              <span>
                In order to continue presciption workflow in EHR, please fill your RXNT credentials.
              </span>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setRxntAccountModalVisible(true);
                }}
              >
                Fill RXNT credentials
              </Button>
            </Space>
          }
        />
      )}
      {rxntStatus.need_individual_account && !rxntStatus.is_doctor_authorized && (
        <Alert
          type="info"
          description={
            <Space>
              <span>
                You don't have an RXNT account to prescribe to Texas patients, please contact your
                PRM.
              </span>
            </Space>
          }
        />
      )}
      {rxntAccountModalVisible && (
        <RxntAccountModel
          visible={rxntAccountModalVisible}
          onOk={(loginResp) => {
            setRxntAccountModalVisible(false);
            loadData();
          }}
          onCancel={() => {
            setRxntAccountModalVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default RxntStatus;
