import React, { useEffect } from 'react';
import { Button, Space, Form } from 'antd';
import { ProCard, ProForm, ProFormSelect, ProFormDependency } from '@ant-design/pro-components';
import { ShrinkOutlined, CloseOutlined, ArrowsAltOutlined } from '@ant-design/icons';
import { useSetState, useMount } from 'ahooks';
import { useNoteStore, useUserStore } from '@/stores';
import { getPatientName } from '@/utils/utils';
import styles from './New.less';
import classNames from 'classnames';
import { TREATMENT_PLAN_NOTE_TYPES } from '@/constants';
import { PatientInfoCard, InitialNote, GenericNote, TransferDischargeNote } from './components';
import { getLabelFromValue } from '@/utils/utils';
import DraggableResizableWindow from '@/components/common/DraggableResizableWindow';

const cn = classNames.bind(styles);

const AnimatedDialog = () => {
  const { profile } = useUserStore();
  const {
    visible,
    toggle,
    patient,
    noteTypeOptions,
    setNoteTypeOptions,
    loading,
    setNoteFormInstance,
  } = useNoteStore();
  const [state, setState] = useSetState({
    isMinimized: false,
  });

  const [form] = Form.useForm();

  useMount(() => {
    setNoteFormInstance(form);
  });

  useEffect(() => {
    if (profile) {
      setNoteTypeOptions();
    }
  }, [profile]);

  useEffect(() => {
    if (!visible) {
      setState({
        isMinimized: false,
      });
    }
  }, [visible]);

  const noteType = Form.useWatch('note_type', form);
  const isInitialEvaluation = noteType === TREATMENT_PLAN_NOTE_TYPES.INITIAL_EVALUATION;

  const handleMinimize = () => {
    setState({
      isMinimized: !state.isMinimized,
    });
  };

  function onFinish(values) {
    console.log(values);
  }

  if (!visible) {
    return null;
  }

  const minimizedContent = (
    <div
      className="handle"
      style={{
        width: '100%',
        display: 'inline-flex',
        padding: '13px 20px',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#FBFBFB',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
        cursor: 'move',
      }}
    >
      <bold style={{ fontSize: '14px', fontWeight: 'bold' }}>
        {getLabelFromValue(form.getFieldValue('note_type'), noteTypeOptions)}
      </bold>
      <span style={{ fontSize: '12px' }}>{getPatientName(patient)}</span>
      <div>
        <Button type="text" icon={<ArrowsAltOutlined />} onClick={handleMinimize} />
        <Button type="text" icon={<CloseOutlined />} onClick={toggle} />
      </div>
    </div>
  );

  const mainContent = (
    <ProCard
      title={getPatientName(patient)}
      bordered
      headStyle={{ background: '#FBFBFB', paddingBottom: '18px', cursor: 'move' }}
      bodyStyle={{ overflow: 'hidden' }}
      style={{
        height: '100%',
        boxShadow:
          '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
      }}
      extra={
        <Space>
          <Button type="text" icon={<ShrinkOutlined />} onClick={handleMinimize} />
          <Button type="text" icon={<CloseOutlined />} onClick={toggle} />
        </Space>
      }
      split="vertical"
    >
      {isInitialEvaluation && <PatientInfoCard />}

      <ProCard
        colSpan={isInitialEvaluation ? '60%' : '100%'}
        style={{
          height: '100%',
        }}
        bodyStyle={{ overflowY: 'scroll' }}
        loading={loading}
      >
        <ProForm
          form={form}
          initialValues={{ note_type: 1 }}
          onValuesChange={(changeValues) => console.log(changeValues)}
          submitter={{
            searchConfig: {
              submitText: 'Sign and Submit',
            },
            resetButtonProps: {
              style: {
                display: 'none',
              },
            },
            submitButtonProps: {},
          }}
          onFinish={onFinish}
        >
          <ProFormSelect
            name="note_type"
            label={<span style={{ fontWeight: 'bold' }}>Provider Note</span>}
            options={noteTypeOptions}
            placeholder="Select Note Type"
            onChange={(value) => {
              console.log(value);
              form.resetFields();
              form.setFieldsValue({
                note_type: value,
              });
            }}
          />
          <ProFormDependency name={['note_type']}>
            {({ note_type }) => {
              if (note_type === TREATMENT_PLAN_NOTE_TYPES.INITIAL_EVALUATION) {
                return <InitialNote form={form} />;
              } else if (
                [
                  TREATMENT_PLAN_NOTE_TYPES.DISCHARGE,
                  TREATMENT_PLAN_NOTE_TYPES.TRANSFER,
                ].includes(note_type)
              ) {
                return <TransferDischargeNote form={form} />;
              } else {
                return <GenericNote form={form} />;
              }
            }}
          </ProFormDependency>
        </ProForm>
      </ProCard>
    </ProCard>
  );

  return (
    <DraggableResizableWindow
      visible={visible}
      isMinimized={state.isMinimized}
      minWidth={isInitialEvaluation ? 1000 : 600}
      minHeight={500}
      dragHandleClassName={state.isMinimized ? "handle" : "ant-pro-card-header"}
      minimizedContent={minimizedContent}
      className={cn('addCaseModal')}
    >
      {mainContent}
    </DraggableResizableWindow>
  );
};

export default AnimatedDialog;
