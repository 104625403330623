import React, { useEffect } from 'react';
import { connect } from 'umi';
import { Descriptions, Tag } from 'antd';
import cn from 'classnames/bind';
import { AsYouType } from 'libphonenumber-js';

import { DescriptionsSkeleton } from '@/components/ProSkeleton';

import styles from './index.less';
const cx = cn.bind(styles);

const AdminDetail = (props) => {
  const id = props.match.params.id;
  const {
    dispatch,
    admin_detail: { detail: adminInfo },
    loading,
  } = props;

  useEffect(() => {
    dispatch({ type: 'admin_detail/fetchData', payload: { id }, callback: () => {} });
  }, []);

  if (loading.effects['admin_detail/fetchData']) {
    return <DescriptionsSkeleton active={loading} />;
  }

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Admin Info</h3>
      </div>
      <Descriptions layout="vertical">
        <Descriptions.Item label="Name">{adminInfo.name}</Descriptions.Item>
        <Descriptions.Item label="Email">{adminInfo.email}</Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag>{adminInfo.status === 1 ? 'ACTIVE' : 'INACTIVE'}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Phone">
          {adminInfo.phone && new AsYouType('US').input(adminInfo.phone)}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default connect(({ admin_detail, loading }) => ({
  admin_detail,
  loading,
}))(AdminDetail);
