import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { formatUnix } from '../../utils/xtime';
import './PatientTimeline.css';

import { srvAdminGetPaitentTimeline } from '@/services/admin';

const { Option } = Select;

const timelineType = [
  {
    id: 1,
    name: 'Overview',
    color: 'none',
    selected: 1,
  },
  {
    id: 2,
    name: 'Appointment',
    color: '#EA445A',
    selected: 0,
  },
  {
    id: 3,
    name: 'Payment',
    color: '#62B70B',
    selected: 0,
  },
  {
    id: 4,
    name: 'Membership-related',
    color: '#F5B85D',
    selected: 0,
  },
  {
    id: 5,
    name: 'Communication',
    color: '#4EB8FF',
    selected: 0,
  },
  {
    id: 6,
    name: 'Other',
    color: '#8191DA',
    selected: 0,
  },
  {
    id: 7,
    name: 'ZohoTickets',
    color: '#FF8CDF',
    selected: 0,
  }
];

export default function PatientTimeline(props) {
  const { id, active } = props;
  const [timelineItems, setTimelineItems] = useState([]);
  const [timelineTypes, setTimelineTypes] = useState(timelineType);
  const [selectedType, setSelectedType] = useState('Overview');
  const [timelineSpanDays, setTimelineSpanDays] = useState(30);

  const getStartEndTimestamp = (timelineSpanDays) => {
    const tz = 'America/Los_Angeles';
    const now = moment().tz(tz);
    const timelineEndTimeUnix = now.unix();
    let timelineStartTimeUnix = 0;
    if (timelineSpanDays) {
      const start = now.add(-(1 * timelineSpanDays), 'days');
      timelineStartTimeUnix = start.unix();
    }
    return [timelineStartTimeUnix, timelineEndTimeUnix];
  };

  useEffect(() => {
    const [start, end] = getStartEndTimestamp(timelineSpanDays);
    srvAdminGetPaitentTimeline({ id, start, end }).then((res) => {
      if (res.code === 200) {
        setTimelineItems(res.data);
      }
    });
  }, [id, active, timelineSpanDays]);

  if (!active) {
    return null;
  }

  const getDotColor = (type) => {
    var timelineItem = timelineType.find((item) => item.name === type);
    if (!timelineItem) {
      return '#e0e6d8';
    }
    return timelineItem.color;
  };

  const handleChangeTimelineSpanDays = (value) => {
    let spanDays = parseInt(value);
    if (spanDays !== NaN) {
      setTimelineSpanDays(spanDays);
    }
  };

  const selectType = (timelineTypeID, timelineTypeName) => {
    setTimelineTypes((prevTimelineTypes) =>
      prevTimelineTypes.map((item) => ({
        ...item,
        selected: item.id === timelineTypeID ? 1 : 0,
      }))
    );
    setSelectedType(timelineTypeName);
  };

  const TimelineElement = (props) => {
    const { item } = props;

    let description = (
      <div className="timeline-item-detail">
          <span className="timeline-item-dot-time">
            {formatUnix(item.occurence_time, 'hh:mmA')}
          </span>
          <span>{item.description}</span>
      </div>
    
    );

    if (item.type == 'ZohoTickets') {
      const json = JSON.parse(item.description);
      description = (
        <div className="timeline-item-detail">
          <span className="timeline-item-dot-time">
            {formatUnix(item.occurence_time, 'hh:mmA')}
          </span>
          <span className="timeline-item-dot-zoho">Subject: <a target="_blank" href={json.web_url} style={{ textDecoration: 'underline' }}>{json.subject}</a></span>
          <div>
            <span className="timeline-item-dot-zoho">Ticket #{json.ticket_number}</span>
          </div>
        
          <div>
            <span className="timeline-item-dot-zoho">Status: {json.status}</span>
          </div>
        </div>
      )
    }

    return (
      <div className="timeline-item">
        <div className="timeline-item-day">{formatUnix(item.occurence_time, 'DD')}</div>
        <div className="timeline-item-dot">
          <div className="timeline-item-dot-up" />
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5" cy="5" r="5" fill={getDotColor(item.type)} />
          </svg>
          <div className="timeline-item-dot-up" />
        </div>
          {description}
      </div>
    );
  };

  // sort items by date descending
  timelineItems.sort((a, b) => {
    return b.occurence_time - a.occurence_time;
  });

  // group by month
  const groupedResults = _.groupBy(timelineItems, (item) =>
    formatUnix(item.occurence_time, 'MMM YYYY'),
  );

  const timelineTypeJsx = timelineTypes.map((timelineType) => {
    return (
      <div key={timelineType.id} className="timeline-type">
        {timelineType.color !== 'none' && (
          <svg
            className="timeline-type-svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5" cy="5" r="5" fill={timelineType.color} fillOpacity="0.6" />
          </svg>
        )}
        <p
          onClick={() => selectType(timelineType.id, timelineType.name)}
          className={
            'timeline-type-text ' + (timelineType.selected === 1 ? 'timeline-type-text-select' : '')
          }
        >
          {timelineType.name}{' '}
        </p>
      </div>
    );
  });

  const timelineJsx = Object.entries(groupedResults).map(([key, value]) => {
    const items = value.filter((item) => selectedType === 'Overview' || item.type === selectedType);

    return !!items.length ? (
      <div key={key}>
        <div className="timeline-month">{key}</div>
        {items.map((item, index) => (
          <TimelineElement key={`${item.description}_${index}`} item={item} />
        ))}
      </div>
    ) : null;
  });

  return (
    <>
      <div className="timeline-tab-header">{timelineTypeJsx}</div>

      <div className="timeline-content">
        <div className="timeline-time-selected">
          <Select defaultValue="30" style={{ width: 130 }} onChange={handleChangeTimelineSpanDays}>
            <Option value="30">Last 30 days</Option>
            <Option value="60">Last 60 days</Option>
            <Option value="90">Last 90 days</Option>
            <Option value="0">All</Option>
          </Select>
        </div>
        {timelineJsx}
      </div>
    </>
  );
}
