import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'umi';
import { message, Space, Button, Table, Popover } from 'antd';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import { FormOutlined } from '@ant-design/icons';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import { SearchOutlined, createFromIconfontCN } from '@ant-design/icons';
import { AsYouType } from 'libphonenumber-js';
import copy from 'copy-to-clipboard';
import { useEventEmitter } from 'ahooks';

import defaultSettings from '../../../defaultSettings';
import Filters from '@/components/Filters';
import { srvAdminUserSurveies } from '@/services/admin';
import { formatUnix } from '../../utils/xtime';
import styles from './index.less';
const cx = cn.bind(styles);

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const filters = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
  },
  {
    name: 'email',
    label: 'Email',
    type: 'text',
  },
  {
    name: 'phone',
    label: 'Phone',
    type: 'text',
  },
  {
    name: 'created_at',
    label: 'Date',
    type: 'date',
  },
];

const Survey = (props) => {
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState({});
  const reset$ = useEventEmitter();

  const listReq = useRequest(srvAdminUserSurveies, {
    manual: true,
    onSuccess: (ret, params) => {
      setData(ret.data);
    },
  });

  const copyLink = (linkStr) => {
    copy(linkStr, {
      debug: true,
      message: 'copied',
      format: 'text/plain',
    });

    message.info('Copied');
  };

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 70,
      render: (v, r) => {
        return r.status_name;
      },
    },
    {
      title: 'Patient',
      dataIndex: 'user_id',
      key: 'user_id',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        const userName = `${r.patient.first_name} ${r.patient.last_name}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.user_id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/patient/${r.user_id}`}>{r.patient.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.patient.phone)}
                </div>
                <div className="popover-user-item address">
                  <IconFont className="icon" type="icon-address" />
                  {r.patient.zipcode}
                </div>
                <div className="popover-user-item sex">
                  <IconFont className="icon" type="icon-sex" />
                  {r.patient.gender}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <Link to={`/patient/${r.user_id}`} style={{ color: '#1a1f36', fontWeight: 500 }}>
              {userName}
            </Link>
          </Popover>
        );
      },
    },
    {
      title: 'Form Type',
      dataIndex: 'form_type',
      key: 'form_type',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return r.form_type_name;
      },
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      render: (v, r) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              copyLink(r.link);
            }}
          >
            Copy URL
          </Button>
        );
      },
    },
    {
      title: 'Date',
      ellipsis: true,
      dataIndex: 'created_at',
      width: 200,
      key: 'created_at',
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
    },
  ];

  const f = () => {
    return filters;
  };

  useMount(() => {
    loadList(null, { page: 1, page_size: 20 });
  });

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList(q, { page: 1, page_size: 20 });
  };

  const loadList = (filters, params) => {
    let filtersData = filters ? filters : {};
    let filtersObj = { ...filtersData };
    listReq.run({ filters: JSON.stringify(filtersObj), ...params });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadList(query, { page: pagination.current, page_size: pagination.pageSize });
  };

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>User Surveys</h3>
        <Space>
          <Filters reset$={reset$} filters={f()} onFilter={onFilter} />
        </Space>
      </div>

      <Table
        columns={columns}
        dataSource={data.items}
        rowKey="id"
        scroll={{ x: 800 }}
        loading={listReq.loading}
        onChange={handleTableChange}
        pagination={{
          pageSize: 20,
          total: data.total,
          simple: true,
          current: page,
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} of ${total} items`;
          },
        }}
      />
    </div>
  );
};

export default Survey;
