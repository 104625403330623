import React, { useEffect, useState } from 'react'
import { Button, Tooltip, Table, Modal, Image, Space, Popover } from 'antd';
import { LeftOutlined, RightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import CompensationModel from '@/assets/compensation-model.png';
import { useRequest } from 'ahooks';
import { srvDoctorPatientStats, srvDoctorPayrollMetric, srvDoctorDashboardMetricWarningLock, srvDoctorDashboardProviderNoShow } from '@/services/account';
import { formatUnix } from '../../utils/xtime';
import moment from 'moment';
import { useFlag } from '@unleash/proxy-client-react';
import { providerDataDescription } from './provider_data.js';
import "./ProviderDashboard.css";
import ProviderDashboardMetricWarningLock from './ProviderDashboardMetricWarningLock';
import { history,Link } from 'umi';

const secondsToDhms = (seconds) => {
  const s = Number(seconds);
  var d = Math.floor(s / (3600 * 24));
  var h = Math.floor(s % (3600 * 24) / 3600);
  var m = Math.floor(s % 3600 / 60);
  let displayContent = <></>
  if (d > 0) {
    displayContent = <>
      <div> {d} </div>
      <div className="dashboard-item-number-unit"> {d == 1 ? "day" : "days"}   </div>
      {
        h > 0 ? (
          <>
            <div> {h} </div>
            <div className="dashboard-item-number-unit"> {h == 1 ? "hr" : "hrs"}   </div>
          </>) : <></>
      }
    </>
  } else if (h > 0) {
    displayContent = <>
      <div> {h} </div>
      <div className="dashboard-item-number-unit"> {h == 1 ? "hr" : "hrs"}   </div>
      {m > 0 ? (
        <>
          <div> {m} </div>
          <div className="dashboard-item-number-unit"> {m == 1 ? "min" : "mins"}   </div>
        </>) : <></>
      }
    </>
  } else {
    displayContent = <>
      <div> {m} </div>
      <div className="dashboard-item-number-unit"> {m > 0 ? (m == 1 ? "min" : "mins") : ""}   </div>
    </>
  }
  return displayContent
}

function DashboardItem(props) {
  const { itemValue, itemName, itemDescription } = props

  return (
    <Tooltip placement="top" title={itemDescription}>
      <div className="dashboard-item">
        <div className="dashboard-item-number">
          {itemValue}
        </div>
        <div className="dashboard-item-name">
          {itemName}
        </div>
      </div>
    </Tooltip>
  )
}

function PayrollItem(props) {
  const { itemPrefix = '', itemValue, itemName, itemDescription } = props;
  return (
    <Tooltip placement="top" title={itemDescription}>
      <div className="dashboard-items">
        <div className="dashboard-items-number"><span className="dollar">{itemPrefix}</span>{itemValue}</div>
        <div className="dashboard-items-name">{itemName}</div>
      </div>
    </Tooltip>
  );
}

function ProviderMetricTable(props) {
  const { providerMetrics } = props;
  return (
    <div className="dashboards">
      <div className={"dashboard-items-list"}>
        <DashboardItem
          itemName={'New patients'}
          itemValue={(providerMetrics?.new_patient_for_payroll && providerMetrics?.new_patient_for_payroll > 0) ? providerMetrics?.new_patient_for_payroll || '0' : '-'}
          itemDescription={(providerMetrics?.new_patient_for_payroll && providerMetrics?.new_patient_for_payroll > 0) ? providerDataDescription.newPatient : ""}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Member panel'}
          itemValue={(providerMetrics?.member_panel && providerMetrics?.member_panel > 0) ? providerMetrics?.member_panel || '0' : '-'}
          itemDescription={(providerMetrics?.member_panel && providerMetrics?.member_panel > 0) ? providerDataDescription.memberPanel : ""}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Patient conversion'}
          itemValue={(providerMetrics?.patient_conversion_rate && providerMetrics?.patient_conversion_rate > 0) ? `${(providerMetrics?.patient_conversion_rate * 100 || 0).toFixed(0)}%` : '-'}
          itemDescription={(providerMetrics?.patient_conversion_rate && providerMetrics?.patient_conversion_rate > 0) ? (providerMetrics?.patient_conversion_rate_is_red ? providerDataDescription.patient_conversion_rate_warning : providerDataDescription.patientConversion) : providerDataDescription.patientConversion}
          warning={(providerMetrics?.patient_conversion_rate && providerMetrics?.patient_conversion_rate > 0) ? providerMetrics?.patient_conversion_rate_is_red : false}
          bigValue={true}
        />
        <DashboardItem
          itemName={(
            <Space>
              Patient rating
              {/* {(providerMetrics?.rating_average_star && providerMetrics?.rating_average_star > 0) && (
                <Button type="link" style={{ paddingLeft: '15px', paddingRight: '5px' }} onClick={() => {
                  history.push(`/ehr_dashboard/patient_review?year=${providerMetrics.year}&month=${providerMetrics.month}`);
                }}>View details</Button>
              )} */}
            </Space>
          )}
          itemValue={(providerMetrics?.rating_average_star && providerMetrics?.rating_average_star > 0) ? `${providerMetrics?.rating_average_star || 0}/5` : '-'}
          ranking={(providerMetrics?.follow_up_rating_5_star_rate && providerMetrics?.follow_up_rating_5_star_rate > 0) ? `(${(providerMetrics?.follow_up_rating_5_star_rate * 100 || 0).toFixed(0)}% 5-star)` : ''}
          rankingWarning={(providerMetrics?.follow_up_rating_5_star_rate && providerMetrics?.follow_up_rating_5_star_rate > 0) ? providerMetrics?.follow_up_rating_5_star_rate_rate_is_red : false}
          itemDescription={(providerMetrics?.rating_average_star && providerMetrics?.rating_average_star > 0) ? (providerMetrics?.rating_average_star_is_red ? providerDataDescription.rating_average_star_is_warning : providerDataDescription.patientReview) : providerDataDescription.patientReview}
          warning={(providerMetrics?.rating_average_star && providerMetrics?.rating_average_star > 0) ? providerMetrics?.rating_average_star_is_red : false}
          bigValue={true}
        />
      </div>

      <div className={"dashboard-items-list"}>
        <DashboardItem
          itemName={'Refill response rate (within 72 hrs)'}
          itemValue={(providerMetrics?.refill_response_rate && providerMetrics?.refill_response_rate > 0) ? `${(providerMetrics?.refill_response_rate * 100 || 0).toFixed(0)}%` : '-'}
          itemDescription={(providerMetrics?.refill_response_rate && providerMetrics?.refill_response_rate > 0) ? (providerMetrics?.refill_response_rate_is_red ? providerDataDescription.refill_response_rate_warning : providerDataDescription.refill_response_rate) : providerDataDescription.refill_response_rate}
          warning={(providerMetrics?.refill_response_rate && providerMetrics?.refill_response_rate > 0) ? providerMetrics?.refill_response_rate_is_red : false}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Consultation response rate (within 72 hrs)'}
          itemValue={(providerMetrics?.consulation_response_rate && providerMetrics?.consulation_response_rate > 0) ? `${(providerMetrics?.consulation_response_rate * 100 || 0).toFixed(0)}%` : '-'}
          itemDescription={(providerMetrics?.consulation_response_rate && providerMetrics?.consulation_response_rate > 0) ? (providerMetrics?.consulation_response_rate_is_red ? providerDataDescription.consulation_response_rate_is_warning : providerDataDescription.consultation_response_rate) : providerDataDescription.consultation_response_rate}
          warning={(providerMetrics?.consulation_response_rate && providerMetrics?.consulation_response_rate > 0) ? providerMetrics?.consulation_response_rate_is_red : false}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Chart notes completion rate (within 24 hrs)'}
          itemValue={(providerMetrics?.note_completion_rate && providerMetrics?.note_completion_rate > 0) ? `${(providerMetrics?.note_completion_rate * 100 || 0).toFixed(0)}%` : '-'}
          itemDescription={(providerMetrics?.note_completion_rate && providerMetrics?.note_completion_rate > 0) ? (providerMetrics?.note_completion_rate_is_red ? providerDataDescription.note_completion_rate_is_warning : providerDataDescription.note_completion_rate) : providerDataDescription.note_completion_rate}
          warning={(providerMetrics?.note_completion_rate && providerMetrics?.note_completion_rate > 0) ? providerMetrics?.note_completion_rate_is_red : false}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Canceled member(Medication non-adherence/attrition rate)'}
          itemValue={(providerMetrics?.membership_cancellation && providerMetrics?.membership_cancellation > 0) ? providerMetrics?.membership_cancellation || '0' : '-'}
          ranking={(providerMetrics?.membership_cancellation_rate && providerMetrics?.membership_cancellation_rate > 0) ? `(${(providerMetrics?.membership_cancellation_rate * 100 || 0).toFixed(0)}%)` : ''}
          rankingWarning={(providerMetrics?.membership_cancellation_rate && providerMetrics?.membership_cancellation_rate > 0) ? providerMetrics?.membership_cancellation_rate_is_red : false}
          itemDescription={(providerMetrics?.membership_cancellation && providerMetrics?.membership_cancellation > 0) ? (providerMetrics?.membership_cancellation_is_red ? providerDataDescription.membership_cancellation_is_warning : providerDataDescription.membership_cancellation_rate) : providerDataDescription.membership_cancellation_rate}
          warning={(providerMetrics?.membership_cancellation && providerMetrics?.membership_cancellation > 0) ? providerMetrics?.membership_cancellation_is_red : false}
          bigValue={true}
        />
      </div>

      <div className={"dashboard-items-list"}>
        <DashboardItem
          itemName={'Average Zoom appointment duration(60 min)'}
          itemValue={(providerMetrics?.appointment_average_duration_60_mins && providerMetrics?.appointment_average_duration_60_mins > 0) ? `${(providerMetrics?.appointment_average_duration_60_mins || 0)}` : '-'}
          ranking={(providerMetrics?.appointment_average_duration_60_mins && providerMetrics?.appointment_average_duration_60_mins > 0) ? `min` : ''}
          itemDescription={providerDataDescription.appointment_average_duration_60_mins}
          warning={false}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Average Zoom appointment duration(30 min)'}
          itemValue={(providerMetrics?.appointment_average_duration_30_mins && providerMetrics?.appointment_average_duration_30_mins > 0) ? `${(providerMetrics?.appointment_average_duration_30_mins || 0)}` : '-'}
          ranking={(providerMetrics?.appointment_average_duration_30_mins && providerMetrics?.appointment_average_duration_30_mins > 0) ? `min` : ''}
          itemDescription={providerDataDescription.appointment_average_duration_30_mins}
          warning={false}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Average Zoom appointment duration(15 min)'}
          itemValue={(providerMetrics?.appointment_average_duration_15_mins && providerMetrics?.appointment_average_duration_15_mins > 0) ? `${(providerMetrics?.appointment_average_duration_15_mins || 0)}` : '-'}
          ranking={(providerMetrics?.appointment_average_duration_15_mins && providerMetrics?.appointment_average_duration_15_mins > 0) ? `min` : ''}
          itemDescription={providerDataDescription.appointment_average_duration_15_mins}
          warning={false}
          bigValue={true}
        />
        <DashboardItem
          itemName={'Chart audit score'}
          itemValue={(providerMetrics?.chart_audit_average_score && providerMetrics?.chart_audit_average_score > 0) ? providerMetrics?.chart_audit_average_score || 0 : '-'}
          ranking={(providerMetrics?.chart_audit_average_score && providerMetrics?.chart_audit_average_score > 0) ? `/3` : ''}
          itemDescription={providerDataDescription.chart_audit_average_score}
          warning={false}
          bigValue={true}
        />
      </div>
    </div>
  );
}

function ProviderStatTable(props) {
  const { providerStat } = props
  return (<>
    <div className="dashboard-item-list">
      <DashboardItem
        itemName={"New patients"}
        itemValue={providerStat.new_patient_for_payroll}
        itemDescription={providerDataDescription.newPatient}
      />
      <DashboardItem
        itemName={"Member panel"}
        itemValue={providerStat.member_panel}
        itemDescription={providerDataDescription.memberPanel}
      />
      <DashboardItem
        itemName={"Patient conversion"}
        itemValue={providerStat.patient_conversion + "%"}
        itemDescription={providerDataDescription.patientConversion}
      />
      <DashboardItem
        itemName={"Patient review"}
        itemValue={providerStat.provider_rating + "/5"}
        itemDescription={providerDataDescription.patientReview}
      />
    </div>
    <div className="dashboard-item-list">
      <DashboardItem
        itemName={"Refill fulfillment"}
        itemValue={secondsToDhms(providerStat.refill_fulfillment)}
        itemDescription={providerDataDescription.refillFulfillment}
      />
      <DashboardItem
        itemName={"Message response"}
        itemValue={secondsToDhms(providerStat.message_response)}
        itemDescription={providerDataDescription.messageResponse}
      />
      <DashboardItem
        itemName={"Note completion"}
        itemValue={secondsToDhms(providerStat.note_completion)}
        itemDescription={providerDataDescription.noteCompletion}
      />
      <DashboardItem
        itemName={"Canceled member"}
        itemValue={providerStat.canceled_member}
        itemDescription={providerDataDescription.canceledMember}
      />
    </div>
  </>)
}

function ProviderPayrollTable(props) {
  const { providerStat, doctorID, year, month, jobType,showCompensationModel } = props;
  const [payrollModalVisible, setPayrollModalVisible] = useState(false);
  const [payrollMetric, setPayrollMetric] = useState([]);
  const [providerNoShowData, setProviderNoShowData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const fetchProviderNoShowReq = useRequest(srvDoctorDashboardProviderNoShow, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data != null && ret.data.items) {
          setProviderNoShowData(ret.data);
        }
      }
    },
  })

  const fetchDoctorPayrollMetric = useRequest(srvDoctorPayrollMetric, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data != null && ret.data.items) {
          setPayrollMetric(ret.data.items);
        }
      }
    },
  });

  useEffect(() => {
    if (doctorID && providerStat && providerStat.year && providerStat.month) {
      fetchProviderNoShowReq.run({ year: providerStat.year, month: providerStat.month, doctor_id: doctorID, page: currentPage, page_size: 10 })
      fetchDoctorPayrollMetric.run({ year: providerStat.year, month: providerStat.month, doctor_id: doctorID });
    }
  }, [doctorID, providerStat]);

  const dealUrgentRefillColumns = [
    {
      title: 'PATIENT NAME',
      dataIndex: 'patient_name',
      key: 'patient_name',
      width: 120,
      render: (v, r) => {
        return (
          <Link to={`/patient/${r.patient_id}`} style={{ color: '#1a1f36', fontWeight: 500 }}>
            {r.patient_name}
          </Link>
        );
      },
    },
    {
      title: 'STATE',
      dataIndex: 'state',
      key: 'state',
      width: 220,
    },
    {
      title: 'Processing date',
      dataIndex: 'prescription_success_date',
      key: 'prescription_success_date',
      width: 220,
      render: (v, r) => {
        return formatUnix(r.prescription_success_date);
      },
    },
  ];
  const dealConsultationColumns = [
    {
      title: 'PATIENT NAME',
      dataIndex: 'patient_name',
      key: 'patient_name',
      width: 120,
      render: (v, r) => {
        return (
          <Link to={`/patient/${r.patient_id}`} style={{ color: '#1a1f36', fontWeight: 500 }}>
            {r.patient_name}
          </Link>
        );
      },
    },
    {
      title: 'STATE',
      dataIndex: 'state',
      key: 'state',
      width: 220,
    },
    {
      title: 'Solved date',
      dataIndex: 'solved_date',
      key: 'solved_date',
      width: 220,
      render: (v, r) => {
        return formatUnix(r.solved_date);
      },
    },
  ];

  const columns = [
    {
      title: 'Date',
      dataIndex: 'day',
      key: 'day',
      width: 120,
      render: (v, r) => {
        return <div>{moment(`${r.year}-${r.month}-${r.day}`, 'YYYY-MM-DD').format("MMM DD")}</div>;
      },
    },
    {
      title: () => {
        return (
          <Space>
            New patients
            <Tooltip title={providerDataDescription.newPatient}>
              <QuestionCircleOutlined style={{ color: "#5469D4", fontSize: '14px', lineHeight: '20px', marginBottom: '4px' }} />
            </Tooltip>
          </Space>
        )
      },
      dataIndex: 'new_patient',
      key: 'new_patient',
      width: 220,
      render: (v, r) => {
        return (
          <Tooltip title={r.new_patient_names}>
            <span>{r.new_patient}</span>
          </Tooltip>
        )
      }
    },
    {
      title: () => {
        return (
          <Space>
            Member panel renewal
            <Tooltip title={providerDataDescription.memberPanel}>
              <QuestionCircleOutlined style={{ color: "#5469D4", fontSize: '14px', lineHeight: '20px', marginBottom: '4px' }} />
            </Tooltip>
          </Space>
        )
      },
      dataIndex: 'member_panel_renewal',
      key: 'member_panel_renewal',
      render: (v, r) => {
        let names = '';
        if (r.member_panel_one_day_incr_names != '') {
          names += '+ ' + r.member_panel_one_day_incr_names;
        }
        if (r.member_panel_one_day_incr_names != '' && r.member_panel_one_day_decr_names != '') {
          names += "\n"
        }
        if (r.member_panel_one_day_decr_names != '') {
          names += '- ' + r.member_panel_one_day_decr_names;
        }
        let finalNames = names;
        if (names != '') {
          finalNames = () => {
            return <span dangerouslySetInnerHTML={{ __html: names.split('\n').join('<br/>') }}></span>
          }
        }
        return (
          <Tooltip title={finalNames}>
            <span>{r.member_panel_renewal > 0 ? `+${r.member_panel_renewal}` : r.member_panel_renewal}</span>
          </Tooltip>
        )
      },
    },
    {
      title: 'Transfer/extended follow-up',
      dataIndex: 'transfer_extended_follow_up',
      key: 'transfer_extended_follow_up',
      render: (v, r) => {
        let namesArray = [].concat(
          r.extended_follow_up_names.split(","),
          r.transfer_of_care_names.split(",")
        ).filter((t) => t.trim());
        let nameCounts = new Map();
        namesArray.forEach(name => {
          name = name.trim();
          if (nameCounts.has(name)) {
            nameCounts.set(name, nameCounts.get(name) + 1);
          } else {
            nameCounts.set(name, 1);
          }
        });
        let finalNames = Array.from(nameCounts).map(([name, count]) => {
          if (count < 2) return name;
          return `${name}(${count})`;
        }).join(',');
        return (
          <Tooltip title={finalNames}>
            <span>{r.transfer_extended_follow_up}</span>
          </Tooltip>
        )
      },
    },
    {
      title: () => {
        return (
          <Space>
            Coverage(refill)
            <Tooltip title={providerDataDescription.urgentRefillApproveIncome}>
              <QuestionCircleOutlined style={{ color: "#5469D4", fontSize: '14px', lineHeight: '20px', marginBottom: '4px' }} />
            </Tooltip>
          </Space>
        )
      },
      dataIndex: 'urgent_refill_approve',
      key: 'urgent_refill_approve',
      render: (v, r) => {
        return (<Popover
          content={
            <div className="popover-user">
              <Table columns={dealUrgentRefillColumns} rowKey="id" dataSource={r.urgent_refill_approve.items} bordered={false} pagination={false} />
            </div>
          }
          trigger="hover"
        >
          <span>{r.urgent_refill_approve ? r.urgent_refill_approve.total : 0}</span>
        </Popover>);
      },
    }, 
    {
      title: () => {
        return (
          <Space>
            Coverage(consultation)
            <Tooltip title={providerDataDescription.urgentConsultationSolvedIncome}>
              <QuestionCircleOutlined style={{ color: "#5469D4", fontSize: '14px', lineHeight: '20px', marginBottom: '4px' }}/>
            </Tooltip>
          </Space>
        )
      },
      dataIndex: 'urgent_consultation_solved',
      key: 'urgent_consultation_solved',
      render: (v, r) => {
        return (<Popover
          content={
            <div className="popover-user">
              <Table columns={dealConsultationColumns} rowKey="id" dataSource={r.urgent_consultation_solved.items} bordered={false} pagination={false} />
            </div>
          }
          trigger="hover"
        >
          <span>{r.urgent_consultation_solved ? r.urgent_consultation_solved.total : 0}</span>
        </Popover>);
      },
  }
  ];

  const providerNoShowColumns = [
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Patient',
      dataIndex: 'patient',
      key: 'patient',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Button type="link" onClick={() => handleButtonClick(record)}>
          View
        </Button>
      ),
    },
  ];

  const providerNoShowSource = providerNoShowData?.items
    ? providerNoShowData.items.map((item, index) => ({
      key: index,
      time: moment(item.appointed_at * 1000).format('MM/DD/YYYY'),
      type: item.appointment_type_name,
      patient: item.user_name,
      user_id: item.user_id,
    }))
    : [];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    fetchProviderNoShowReq.run({ year: year, month: month, doctor_id: doctorID, page: page, page_size: pageSize })
  };

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const handleButtonClick = (record) => {
    // redirect to patient appointment page
    history.push(`/patient/${record.user_id}?tab=4`);
  };

  return (
    <div className="dashboards">
      {jobType != 2 && (
        <>
          <div className="dashboard-items-list">
            <Tooltip placement="top" title={''}>
              <div className="dashboard-items dashboard-items-monthly-payroll">
                <div className="dashboard-items-number">
                  <span className="dollar">$</span>
                  {Number(providerStat.monthly_payroll).toFixed(2)}
                </div>
                <div className="dashboard-items-name">
                  Monthly payment
                  {showCompensationModel&&<Button
                    type="link"
                    onClick={() => {
                      setPayrollModalVisible(true);
                    }}
                  >
                    Compensation model
                  </Button>}
                </div>
              </div>
            </Tooltip>
            <Tooltip placement="top" title={providerNoShowData?.total > 0 ? "" : "# of appointments that were marked as provider no-show."}>
              <div className="dashboard-items dashboard-items-monthly-payroll">
                <div className={providerNoShowData?.total > 0 ? "dashboard-items-number warning" : "dashboard-items-number"}
                  onMouseEnter={() => setPopoverVisible(true)}
                >
                  <span className="dollar" />{providerNoShowData?.total ? Number(providerNoShowData?.total).toFixed(0) : 0}</div>
                <div className="dashboard-items-name">
                  Provider no-show
                </div>
                <Popover
                  content={
                    <>
                      <Table
                        columns={providerNoShowColumns}
                        dataSource={providerNoShowSource}
                        pagination={{
                          current: currentPage,
                          onChange: handlePageChange,
                          pageSize: 10,
                        }}
                      />
                      {providerNoShowData?.total > 0 && (<div style={{ width: '300px', wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                        Any no-show significantly impacts the patient experience. Please avoid no-show or reschedule your appointment in advance if there's any emergencies.
                      </div>)}
                    </>
                  }
                  placement="bottom"
                  trigger="hover"
                  visible={popoverVisible}
                  onVisibleChange={handlePopoverVisibleChange}
                />
              </div>
            </Tooltip>
            <Tooltip placement="top" title={""}>
              <div className="dashboard-items dashboard-items-hourly-rate">
                <div className="dashboard-items-number">
                  <span className="dollar">$</span>
                  {Number(providerStat.hourly_rate).toFixed(2)}
                </div>
                <div className="dashboard-items-name">Hourly rate</div>
              </div>
            </Tooltip>
          </div>
          <div className="dashboard-items-list">
            <PayrollItem
              itemName={'New patients'}
              itemPrefix={'$'}
              itemValue={Number(providerStat.new_patients_income).toFixed(2)}
              itemDescription={providerDataDescription.newPatient}
            />
            <PayrollItem
              itemName={'Member panel'}
              itemPrefix={'$'}
              itemValue={Number(providerStat.member_panel_income).toFixed(2)}
              itemDescription={providerDataDescription.memberPanel}
            />
            <PayrollItem
              itemName={'Transfer/extended follow-up'}
              itemPrefix={'$'}
              itemValue={Number(providerStat.transfer_extended_follow_up_income).toFixed(2)}
              itemDescription={providerDataDescription.transferExtendedFollowUpIncome}
            />
            <PayrollItem
              itemName={'Exceptions'}
              itemPrefix={'$'}
              itemValue={Number(providerStat.exception_income).toFixed(2)}
              itemDescription={providerDataDescription.exceptionIncome}
            />
          </div>
        </>
      ) || null}
      <div className="dashboard-items-list">
        <PayrollItem
          itemName={'Coverage fee'}
          itemPrefix={'$'}
          itemValue={Number(providerStat.coverage_income).toFixed(2)}
          itemDescription={providerDataDescription.urgentRefillApproveIncome}
        />
        <PayrollItem
        />
       <PayrollItem
        />
        <PayrollItem
        />
      </div>
      <div className="dashboard-items-table">
        <Table
          columns={columns}
          rowKey="id"
          dataSource={payrollMetric}
          bordered={false}
          pagination={false}
        />
      </div>

      <Modal
        width={800}
        visible={payrollModalVisible}
        footer={null}
        onCancel={() => {
          setPayrollModalVisible(false);
        }}
      >
        <Image
          width="100%"
          className="compensation-model"
          preview={false}
          alt={'Compensation Model'}
          src={CompensationModel}
        />
      </Modal>
    </div>
  );
}

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function DashboardDateSelector(props) {
  const { currentIndex, numTotal, onClickPrev, onClickNext, month, year } = props
  return (
    <div className="dashboard-date-selector">
      <Button className="dashboard-date-selector-button" disabled={currentIndex < 1} onClick={onClickPrev} type="primary" shape="circle" size="small">
        <LeftOutlined />
      </Button>
      <div className="dashboard-date-selector-month">
        {months[month - 1] + ' ' + year}
      </div>
      <Button className="dashboard-date-selector-button" disabled={currentIndex >= numTotal - 1} onClick={onClickNext} type="primary" shape="circle" size="small">
        <RightOutlined />
      </Button>
    </div>
  )
}

const ProviderDashboard = (props) => {
  const { id, active, doctorInfo } = props;
  const [providerPatientStats, setProviderPatientStats] = useState([])
  const [showWarning, setShowWarning] = useState(false);
  const [dashboardWarningTwoDays, setDashboardWarningTwoDays] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0)

  const flagShowUrgentRefill = useFlag('enable_ehr_show_urgent_refill');

  const fetchDoctorPatientStats = useRequest(srvDoctorPatientStats, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data && ret.data.items && ret.data.items.length > 0) {
          setProviderPatientStats(ret.data.items);
        }
      }
    }
  })

  const fetchDoctorMetricWarningLock = useRequest(srvDoctorDashboardMetricWarningLock, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data && (ret.data.rating_star_is_warning ||
          (ret.data.ugrent_refill_is_warning && flagShowUrgentRefill) ||
          ret.data.provider_no_show_is_warning ||
          ret.data.rating_star_is_lock ||
          ret.data.ugrent_refill_is_lock ||
          ret.data.provider_no_show_is_lock)) {
          setDashboardWarningTwoDays(ret.data);
          setShowWarning(true);
        }
      }
    }
  })

  useEffect(() => {
    if (id && active) {
      fetchDoctorPatientStats.run({ doctor_id: id });
      fetchDoctorMetricWarningLock.run({ doctor_id: id });
    }
  }, [id, active])

  useEffect(() => {
    if (providerPatientStats.length)
      setCurrentIndex(providerPatientStats.length - 1);
  }, [providerPatientStats])

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        {(providerPatientStats.length === 0) ? <div>No available data now.</div> :
          <>
            {(showWarning && dashboardWarningTwoDays) &&
              <ProviderDashboardMetricWarningLock dashboardMetricsWarningData={dashboardWarningTwoDays} />}
            <DashboardDateSelector
              currentIndex={currentIndex}
              numTotal={providerPatientStats.length}
              month={providerPatientStats[currentIndex].month}
              year={providerPatientStats[currentIndex].year}
              onClickPrev={() => setCurrentIndex(currentIndex - 1)}
              onClickNext={() => setCurrentIndex(currentIndex + 1)}
            />
            <ProviderMetricTable providerMetrics={providerPatientStats[currentIndex]} />
            {(providerPatientStats.length > 0 && (providerPatientStats[currentIndex].year >= 2023 || (providerPatientStats[currentIndex].year == 2022 && providerPatientStats[currentIndex].month > 10))) && (
              <ProviderPayrollTable
                providerStat={providerPatientStats[currentIndex]}
                jobType={doctorInfo?.job_type}
                showCompensationModel={doctorInfo?.show_compensation_flag}
                doctorID={id}
                month={providerPatientStats[currentIndex].month}
                year={providerPatientStats[currentIndex].year}
              />
            )}
          </>
        }
      </div>
    </div>
  )
}

export default ProviderDashboard
