import React, { useState, useEffect, createRef } from 'react';
import cn from 'classnames/bind';
import { connect, Link, history } from 'umi';
import { Badge, Space, Popover, Table, Layout, Menu, Button,Modal,Input,DatePicker } from 'antd';
import { createFromIconfontCN, FileTextOutlined, CalendarFilled, EnvironmentFilled } from '@ant-design/icons';
import { AsYouType } from 'libphonenumber-js';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import Filters from '@/components/Filters';
import defaultSettings from '../../../defaultSettings';
import UploadFileModal from '@/components/UploadFileModal';
import { downloadDocument } from '@/utils/utils';
import { formatUnix,unixToMomentWithTZ,formatUnixDay } from '@/utils/xtime';
import moment from 'moment';
import styles from './index.less';
import {
  srvUpdateDispute,
  srvGetPatientAppointments,
} from '@/services/admin';

const { TextArea } = Input;
const cx = cn.bind(styles);
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });
const { Sider, Content } = Layout;
const filters = [
  {
    name: 'user_email',
    label: 'Patient Email',
    type: 'text',
  },
  {
    name: 'user_phone',
    label: 'Patient Phone',
    type: 'text',
  },
  {
    name: 'doctor_email',
    label: 'Doctor Email',
    type: 'text',
  },
  {
    name: 'doctor_phone',
    label: 'Doctor Phone',
    type: 'text',
  },
  {
    name: 'status',
    label: 'Status',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'requested_at',
    label: 'Date',
    type: 'date',
  },
  {
    name: 'dispute_status',
    label: 'Dispute Status',
    type: 'checkbox',
    items: [
      { label: 'Dispute', value: 'Dispute' },
      { label: 'Approved', value: 'Approved' },
      { label: 'Denied', value: 'Denied' },
    ],
  },
  {
    name: 'dispute_reason',
    label: 'Didpute Purpose',
    type: 'checkbox',
    items: [
      { label: 'Duplicate request or already issued refill', value: 1 },
      { label: 'Refill request made too early', value: 2 },
      { label: 'Medical questions or needing guidance', value: 3 },
      { label: 'Upcoming appointment already scheduled', value: 4 },
      { label: 'Other', value: 5 },
    ],
  },
];

const Patient = (props) => {
  const disputeReasonMap = {
    1: 'Duplicate request or already issued refill',
    2: 'Refill request made too early',
    3: 'Medical questions or needing guidance',
    4: 'Upcoming appointment already scheduled',
    5: 'Other',
    6: 'Follow-up appointment required',
    7: 'Transfer of care appointment required'
  };
  const [adminNote,setAdminNote]=useState('');
  const defaultColumns = [
    {
      title: 'Date',
      dataIndex: 'requested_at',
      key: 'requested_at',
      ellipsis: true,
      width: 180,
      render: (v, r) => {
        return formatUnix(r.requested_at);
      },
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      width: 200,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 120,
      render: (v, r) => {
        return r.status_name;
      },
    },
    {
      title: 'Patient',
      dataIndex: 'user_id',
      key: 'user_id',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        const userName = `${r.patient.first_name} ${r.patient.last_name}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.user_id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/patient/${r.user_id}`}>{r.patient.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.patient.phone)}
                </div>
                <div className="popover-user-item birthdate">
                  <CalendarFilled className="icon" />
                  {r.patient.birthdate && moment.unix(r.patient.birthdate).utc().format('MM/DD/YYYY')}
                </div>
                <div className="popover-user-item sex">
                  <IconFont className="icon" type="icon-sex" />
                  {r.patient.gender}
                </div>
                <div className="popover-user-item state">
                  <EnvironmentFilled className="icon" />
                  {r.patient.state}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <Link to={`/patient/${r.user_id}`} style={{ color: '#1a1f36', fontWeight: 500 }}>
              {userName}
            </Link>
          </Popover>
        );
      },
    },
    {
      title: 'Clinician',
      dataIndex: 'doctor_id',
      key: 'doctor_id',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        if(!r.doctor){
          return '';
        }
        const userName = `${r.doctor.name} ${r.doctor.title}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/doctor/detail/${r.doctor.id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/doctor/detail/${r.doctor.id}`}>{r.doctor.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.doctor.phone)}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <a href="#">{userName}</a>
          </Popover>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      fixed:'right',
      width: 200,
      render: (v, r) => {
        return (
          <Space>
            {(r.pdmp_object_key&& r.dispute_id === "0") && (
              <Button
                type="text"
                className={cx({ pdmpDownload: true })}
                icon={<FileTextOutlined />}
                onClick={() => {
                  downloadDocument(r.pdmp_object_key);
                }}
              />
            )}
            {r.dispute_id === "0" && (
              <Button
                type="primary"
                size="small"
                danger
                onClick={() => {
                  showUploadFileModal(r);
                }}
              >
                Upload PDMP
              </Button>
            )}
            {r.dispute_id != "0" && r.dispute_status === 'Dispute' && (
              <>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    setSelectRequest(r);
                    setDenyVisible(true);
                  }}
                >
                  Deny
                </Button>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    approveDispute(r);
                  }}
                >
                  Approve
                </Button>
              </>
            )}
          </Space>
        );
      },
    },
  ];

  const removedColumns = [{
    title: 'Removed Date',
    dataIndex: 'updated_at',
    key: 'updated_at',
    ellipsis: true,
    width: 200,
    render: (v, r) => {
      return r.updated_at ? formatUnix(r.updated_at) : '';
    },
  }]
  const disputeColumns = [
    {
      title: 'Date',
      dataIndex: 'dispute_created_at',
      key: 'dispute_created_at',
      ellipsis: true,
      width: 180,
      render: (v, r) => {
        return formatUnix(r.dispute_created_at);
      },
    },
    {
      title: 'DISPUTE PURPOSE',
      dataIndex: 'dispute_reason',
      key: 'dispute_reason',
      ellipsis: true,
      width: 300,
      render: (v, r) => {
        return disputeReasonMap[v];
      },
    },
    {
      title: 'PROVIDER NOTE',
      dataIndex: 'dispute_note',
      key: 'dispute_note',
      ellipsis: true,
      width: 200,
    },
    {
      title: 'Status',
      dataIndex: 'dispute_status',
      key: 'dispute_status',
      ellipsis: true,
      width: 120,
    },
    {
      title: 'ADMIN NOTE',
      dataIndex: 'dispute_admin_note',
      key: 'dispute_admin_note',
      ellipsis: true,
      width: 200,
    },
  ];


  const { dispatch, active, treatment_request, loading } = props;
  const { data, treatment_request_statuses,disputeData } = treatment_request;
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [currentTab, setCurrentTab] = useState('1');
  const [columns, setColumns] = useState(defaultColumns);
  const [uploadFileModalVisible, setUploadFileModalVisible] = useState(false);
  const [currentTreatmentRequest, setCurrentTreatmentRequest] = useState({});
  var filtersEl = createRef(null);
  const [denyVisible, setDenyVisible] = useState(false);
  const [approveDisputeVisible, setApproveDisputeVisible] = useState(false);
  const [remindNoApptVisibile, setRemindNoApptVisibile] = useState(false);
  const [selectRequest, setSelectRequest] = useState({});
  const [refillDate, setRefillDate] = useState(null);
  const [showDispute, setShowDispute] = useState(false);
  const showUploadFileModal = (item) => {
    setCurrentTreatmentRequest(item);
    setUploadFileModalVisible(true);
  }

  const switchTab = (e) => {
    let column = defaultColumns.slice();
    let actionColumn = column.pop();
    if (e.key === '5') {
      column = column.concat(removedColumns);
    }
    setCurrentTab(e.key);
    if (e.key === '6'){
      setShowDispute(true);
      column = disputeColumns.concat(defaultColumns.slice(-3));
      setColumns(column);
    }else{
      setShowDispute(false);
      setColumns(column.concat(actionColumn));
    }
    setPage(1);
    loadList({ status: parseInt(e.key), page: 1, page_size: 20, filters: JSON.stringify(query) }, () => { });

  }

  useMount(() => {
    refresh();
  }, [active]);

  useUpdateEffect(() => {
    refresh();
  }, [active]);

  const refresh = () => {
    setPage(1);
    loadList({ status: parseInt(currentTab), page: 1, page_size: 20, filters: JSON.stringify(query) }, () => {});
    dispatch({ type: 'treatment_request/fetchTreatmentRequestStatuses' });
  };

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList({ status: parseInt(currentTab), page: 1, page_size: 20, filters: JSON.stringify(q) }, () => {});
  };

  const loadList = (payload, callback) => {
    dispatch({ type: 'treatment_request/fetchList', payload, callback });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadList({
      status: parseInt(currentTab),
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: JSON.stringify(query),
    });
  };

  const f = () => {
    for (const v of filters) {
      if (v.name === 'status' && v.items.length === 0) {
        v.items = treatment_request_statuses;
        continue;
      }
    }
    return filters;
  };

  const changeAdminNote = e => {
    setAdminNote(e.target.value);
  };

  const denyDispute=()=>{
    const param={
      id: selectRequest.dispute_id,
      dispute_admin_note: adminNote,
      status: 'Denied'
    }
    updateDisputeReq.run(param);
  }

  const updateDisputeReq = useRequest(srvUpdateDispute, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setDenyVisible(false);
        setApproveDisputeVisible(false);
        loadList({ status: 6, page: 1, page_size: 20, filters: JSON.stringify(query) }, () => { });
      } else {
        message.error(ret.msg);
      }
    },
  });

  const approveDispute = (refill) => {
    setSelectRequest(refill);
    setApproveDisputeVisible(true);
  }
  const submitApprove = () => {
    const param = {
      id: selectRequest.dispute_id,
      dispute_admin_note: adminNote,
      status: 'Approved',
      dispute_admin_refill_date: refillDate
    }
    updateDisputeReq.run(param);
  }

  const channgeRefillDate = (date, dateString) => {
    setRefillDate(date.unix());
  }


  return (
    <div className={cx({ subcontainer: true })}>
      <div className={cx({ subheader: true })}>
        <h3>Prescription Renewals</h3>
        <Space>
          {data && data.new_total > 0 && (
            <a
              onClick={() => {
                filtersEl.current.setFilters({ status: [1] });
              }}
            >
              {data.new_total} New
            </a>
          )}
          <Filters ref={filtersEl} filters={f()} onFilter={onFilter} />
        </Space>
      </div>

      <Layout className={cx({ layout: true })}>
        <Sider theme="light" className={cx({ tabs: true })}>
          <Menu selectedKeys={[currentTab]} onClick={switchTab}>
            <Menu.Item key="1">
              New <Badge className={cx({ newCount: true })} count={data.new_total} />
            </Menu.Item>
            <Menu.Item key="2">Urgent</Menu.Item>
            <Menu.Item key="3">Pending</Menu.Item>
            <Menu.Item key="4">Completed</Menu.Item>
            <Menu.Item key="5">Removed</Menu.Item>
            <Menu.Item key="6">Dispute</Menu.Item>
          </Menu>
        </Sider>
        <Content theme="light">
        {!showDispute && (<Table
            columns={columns}
            dataSource={data.items}
            rowKey="id"
            scroll={{ x: 800 }}
            onRow={(record) => ({
              onClick: (evt) => {
                evt.stopPropagation();
                if (evt.target && evt.target.tagName.toUpperCase() == 'TD'&&currentTab != '6') {
                  history.push(`/patient/${record.patient.id}?tab=3`);
                  return;
                }
              },
            })}
            loading={loading.effects['treatment_request/fetchList']}
            onChange={handleTableChange}
            pagination={{ pageSize: 20, current: page, simple: true, total: data.total }}
          />)}
          {showDispute && (<Table
            columns={columns}
            dataSource={disputeData.items}
            rowKey="dispute_id"
            scroll={{ x: 800 }}
            loading={loading.effects['treatment_request/fetchList']}
            onChange={handleTableChange}
            pagination={{ pageSize: 20, current: page, simple: true, total: disputeData.total }}
          />)}
        </Content>
      </Layout>

      {uploadFileModalVisible && (
        <UploadFileModal
          user_id={currentTreatmentRequest.user_id}
          visible={uploadFileModalVisible}
          okText="Upload PDMP"
          onOk={(payload, callback) => {
            dispatch({
              type: 'treatment_request/updateTreatmentRequestPDMP',
              payload: {
                id: currentTreatmentRequest.id,
                pdmp_object_key: payload.file_object_key,
                pdmp_fill_at: payload.last_fill_date,
                pdmp_notes: payload.additional_notes,
              },
              callback: () => {
                setUploadFileModalVisible(false);
                refresh();
              },
            });
          }}
          onCancel={() => {
            setUploadFileModalVisible(false);
          }}
        />
      )}
      <Modal visible={denyVisible} onCancel={() => setDenyVisible(false)} footer={null}>
        <p
          style={{
            color: '#000000',
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '20px',
          }}
        >
          Dispute Denied
        </p>
        <p>
          This dispute has been denied. This refill request will be marked as active in the
          Provider’s EHR Prescription Renewals.
        </p>
        <p style={{ marginTop: '20px', marginBottom: '20px' }}>
          Please send a note to the Provider so they can manage this urgently
        </p>
        <TextArea placeholder="Add note" rows={4} style={{ borderRadius: '6px' }} value={adminNote} onChange={changeAdminNote} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: '#000000',
            marginTop: '10px',
          }}
        >
          <p
            style={{ color: '#566BCD', cursor: 'pointer' }}
            onClick={() => {
              setDenyVisible(false);
            }}
          >
            Cancel
          </p>
          <Button style={{ borderRadius: '5px' }} key="confirm" onClick={() => {denyDispute();}} type="primary">
            Deny Dispute
          </Button>
        </div>
      </Modal>
      <Modal
        visible={approveDisputeVisible}
        onCancel={() => setApproveDisputeVisible(false)}
        footer={null}
      >
        <p
          style={{
            color: '#000000',
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '20px',
          }}
        >
          Approve Dispute
        </p>
        <p style={{marginBottom:'20px'}}><span style={{color:'#000000CC',fontWeight:'bold'}}>Dispute Reason: </span> {disputeReasonMap[selectRequest.dispute_reason]}</p>
        {selectRequest.dispute_reason === 2 && (
          <p> <span style={{color:'#000000CC',fontWeight:'bold'}}>Refill Due Date: </span>{formatUnixDay(selectRequest.dispute_refill_date)}</p>
        )}
        <p style={{ marginTop: '20px', marginBottom: '20px' }}>
        <span style={{color:'#000000CC',fontWeight:'bold'}}>Dispute Note: </span><span style={{ whiteSpace: 'pre-line' }}>{selectRequest.dispute_note}</span>
        </p>
        {selectRequest.dispute_reason === 1 && (
          <>
            <p>
              Upon dispute approval, this refill request will be marked as “Removed” in the
              Provider’s EHR Prescription Renewals.
            </p>
            <p style={{color:'#000000CC',fontWeight:'bold',marginTop:'20px'}}>Before Dispute Approval: </p>
            <p>1.Check PDMP to validate the refill has been issued.</p>
            <p>2.Check the clinician note to confirm the refill request has been documented.</p>
          </>
        )}
        {selectRequest.dispute_reason === 2 && (
          <>
            <p>
              1.This refill request will go back to the provider's "Refill Requests" with the
              correct date marked.
            </p>
            <p>2.Provider can send the the prescription to the pharmacy with the correct date. </p>
            <p>3.The patient will be informed of the date they can pick up.</p>
            <DatePicker
              style={{ width: '260px',marginTop:'20px',marginBottom:'20px' }}
              placeholder="Select correct refill due date"
              onChange={channgeRefillDate}
              defaultValue={refillDate ? unixToMomentWithTZ(refillDate) : null}
              disabledDate={(current) =>
                current &&
                (current.isBefore(moment()) || current.isAfter(moment().add(3, 'months')))
              }
            />
            <p style={{ color: '#999999' }}>
              If this date is set by the admin, this will become the actual refill request due date
              int he provider's EHR.
            </p>
          </>
        )}
        <TextArea placeholder="Add note" rows={4} style={{ borderRadius: '6px',marginTop:'40px' }}  value={adminNote} onChange={changeAdminNote}/>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: '#000000',
            marginTop: '10px',
          }}
        >
          <p
            style={{ color: '#566BCD', cursor: 'pointer' }}
            onClick={() => {
              setApproveDisputeVisible(false);
            }}
          >
            Cancel
          </p>
          <Button style={{ borderRadius: '5px' }} key="confirm" onClick={() => {
            submitApprove();
          }} type="primary">
            Approve Dispute
          </Button>
        </div>
      </Modal>
      <Modal visible={remindNoApptVisibile} onCancel={() => setRemindNoApptVisibile(false)} footer={null}>
        <p style={{marginTop:'20px'}}>
          Before approval,please contact the patient to make a follow up appointment with the provider.
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: '#000000',
            marginTop: '10px',
          }}
        >
          <div></div>
          <Button style={{ borderRadius: '5px' }} key="confirm" onClick={() => {
            setRemindNoApptVisibile(false);
            }} type="primary">
            Okay
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default connect(({ treatment_request, loading }) => ({
  treatment_request,
  loading,
}))(Patient);
