import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox, Space } from 'antd';

import { useMount, useUpdateEffect, useSetState } from 'ahooks';
import ExclusiveCheckboxWithTextInput from './ExclusiveCheckboxWithTextInput';
import { psychiatricHistoryItems } from './meta';

const PsychiatricHistoryFormItems = (props) => {
  const { form, draft, setDraft } = props;

  // useUpdateEffect(() => {
  // }, [draft]);


  const onItemChange = (e, name, exclusive, items = []) => {
    if (e.target.checked) {
      let fields = {}
      items.forEach(v => {
        if (exclusive) {
          fields[v.value] = v.exclusive ? true : false;
          if (v.hasInput) fields[`${v.value}_text`] = '';
        } else {
          if (v.value == name) fields[v.value] = true;
          if (v.exclusive) fields[v.value] = false;
        }
      })
      if (Object.keys(fields).length > 0) {
        form.setFieldsValue(fields);
        setDraft(Object.assign({ ...draft }, fields));
      }
    }
  }

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px'}} >
        {psychiatricHistoryItems.map((v, index) => {
          return (
            <div key={index} style={{ flex: '0 1 auto', padding: '2px 20px 2px 0px' }}>
              <ExclusiveCheckboxWithTextInput name={v.value} label={v.label} exclusive={v.exclusive} hasInput={v.hasInput}
                onChange={(e) => onItemChange(e, v.value, v.exclusive, psychiatricHistoryItems)} form={form} draft={draft} setDraft={setDraft} />
            </div>
          )
        })}
      </div>

      <Form.Item
        name="current_therapist"
        label={'Current therapist'}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="current_psychiatric_provider"
        label={'Current psychiatric provider'}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="previous_dx_adhd"
        label={'Previous dx of ADHD'}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="previous_dx_other_disorders"
        label={'Previous dx of any other psychiatric disorders'}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="previous_psychological_testing"
        label={'Previous psychological testing'}
      >
        <Input />
      </Form.Item>
    </>
  )
};

export default PsychiatricHistoryFormItems;
