import React, { useEffect, useState } from 'react';
import {Button, Popover, Tag, Modal, Radio, List, Space, Dropdown, Alert, message, Menu} from 'antd';
import DataSorter from '@/components/DataSorter';
import RescheduleAppointmentWithTimeSlotsModal from '@/components/RescheduleAppointmentWithTimeSlotsModal';
import InsufficientFollowUpAvailabilityModal from './InsufficientFollowUpAvailabilityModal'
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { formatPhoneNumber, downloadDocument, getPatientName } from '@/utils/utils';
import { formatUnix } from '@/utils/xtime.js';
import AddNote from '@/components/AddNote';
import { ProviderDashboardSection, PatientLink } from './index';
import {
  srvMarkAppointmentNoShow,
  revertAppointmentStatus,
  srvCalendarAppointments, srvMarkProviderAppointmentNoShow,
} from '@/services/patient';
import {
  srvDoctorFollowUpMonthlyTimeSlot,
  srvDoctorCompleteFollowUpTimeSlot,
  srvDoctorMarkFollowUpTimeSlotNotified,
} from '@/services/account';

import './AppointmentList.css';
import {Link,useSelector} from "umi";
import {EllipsisOutlined} from "@ant-design/icons";
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });
import defaultSettings from '../../../defaultSettings';
import { createFromIconfontCN, EnvironmentFilled, CalendarFilled } from '@ant-design/icons';
import CallReasonModal from '@/components/PatientInfoPane/CallReasonModal';
import { formatAppointmentAt } from '../../utils/xtime';
const AppontmentsCustomerHeader = (props) => {
  const { setStartAndEndDate } = props;
  const onChange = (e) => {
    let value = e.target.value;
    if (value === 'today') {
      setStartAndEndDate(moment().startOf('day').unix(), moment().startOf('day').unix());
    } else if (value === 'yesterday') {
      setStartAndEndDate(
        moment().subtract(1, 'days').startOf('day').unix(),
        moment().subtract(1, 'days').endOf('day').unix(),
      );
    } else {
      setStartAndEndDate(moment().startOf('month').unix(), moment().endOf('month').unix());
    }
  };

  return (
    <div>
      <Radio.Group onChange={onChange} defaultValue="today">
        <Radio.Button value="today">Today</Radio.Button>
        <Radio.Button value="yesterday">Yesterday</Radio.Button>
        <Radio.Button value="c">This Month</Radio.Button>
      </Radio.Group>
    </div>
  );
};

const NoShowAction = (props) => {
  const { appointment, onRefresh } = props;
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [noShowConfirmModal, setNoShowConfirmModal] = useState(false);
  const [providerNoShowConfirmModal, setProviderNoShowConfirmModal] = useState(false);
  const [rescheduleAppointmentModal, setRescheduleAppointmentModal] = useState(false);
  const [rescheduleAppointmentID, setRescheduleAppointmentID] = useState('');
  const [displayNoShowButton, setDisplayNoShowButton] = useState(false)

  useEffect(() => {
    setDisplayNoShowButton(checkIfDisplayNoShowButton(appointment.appointed_at))

    const interval = setInterval(() => {
      const ifDisplayNoShow = checkIfDisplayNoShowButton(appointment.appointed_at)
      if (ifDisplayNoShow) {
        setDisplayNoShowButton(true)
        clearInterval(interval)
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const checkIfDisplayNoShowButton = (appointedAt) => {
    const now = moment().unix()
    return now > appointedAt
  }

  const markNoShow = () => {
    srvMarkAppointmentNoShow({
      id: appointment.id,
    })
      .then(() => {
        onRefresh();
      })
      .catch(() => {
        onRefresh();
      })
      .finally(() => {
        setNoShowConfirmModal(false);
      });
  };

  const markProviderNoShow = () => {
    srvMarkProviderAppointmentNoShow({
      id: appointment.id,
    })
      .then(() => {
        onRefresh();
      })
      .catch(() => {
        onRefresh();
      })
      .finally(() => {
        setProviderNoShowConfirmModal(false);
      });
  };

  const onVisibleChange = (visible) => {
    if (visible === false) {
      setPopoverVisible(visible);
    }
  };
  const checkToShowPopOver = () => {
    let appointmentAt = appointment.appointed_at;
    let now = moment().unix();
    if (appointment.appointment_type === 2) {    //follow up
      if (now - appointmentAt < 600) {
        setPopoverVisible(true);
      }
    } else {
      if (now - appointmentAt < 60 * 25) {
        setPopoverVisible(true);
      }
    }

  };
  return (
    !displayNoShowButton ? <></> :
      <>
        <Popover
          content={
            'Please call the patient at ' +
            formatPhoneNumber(appointment.patient.phone) +
            ' if they are 4 minutes late.'
          }
          trigger="click"
          visible={popoverVisible}
          onVisibleChange={onVisibleChange}
        >
          {/*<Button*/}
          {/*  onMouseOver={checkToShowPopOver}*/}
          {/*  type="default"*/}
          {/*  size="small"*/}
          {/*  onClick={() => setNoShowConfirmModal(true)}*/}
          {/*>*/}
          {/*  No-Show*/}
          {/*</Button>*/}
          <Dropdown
            overlay={
              <div className="noShowOpt">
                <div className="noShowOptItem">
                  <a
                    onMouseOver={checkToShowPopOver}
                    type="default"
                    size="small"
                    onClick={() => setNoShowConfirmModal(true)}
                  >
                    Patient No-Show
                  </a>
                  <div>
                    <a
                      onMouseOver={checkToShowPopOver}
                      type="default"
                      size="small"
                      onClick={() => setProviderNoShowConfirmModal(true)}
                    >
                      I was unable to join the appointment
                    </a>
                  </div>
                </div>
              </div>
            }
            trigger={['click']}
          >
            <Button size="small">
              No-Show
            </Button>
          </Dropdown>
        </Popover>
        <Modal
          title="Confirm No Show"
          visible={noShowConfirmModal}
          okText="Submit"
          onOk={() => markNoShow()}
          onCancel={() => {
            setNoShowConfirmModal(false);
          }}
        >
          <p>The patient did not show up within 10 minutes after their appointment time.</p>
        </Modal>

        <Modal
          title="Confirm No Show"
          width={800}
          visible={providerNoShowConfirmModal}
          okText="Provider No Show Without Rescheduling"
          okButtonProps={{ style: { backgroundColor: "#949DA3", borderColor: "#e5e7eb" } }}
          onOk={() => markProviderNoShow()}
          onCancel={() => {
            setProviderNoShowConfirmModal(false);
          }}
        >
          <p>In the event that you are unable to attend the scheduled appointment, we appreciate your consideration in marking arrangements for a
            <Button type="link" size='small' onClick={() => {
              setRescheduleAppointmentID(appointment.id)
              setRescheduleAppointmentModal(true)
              setProviderNoShowConfirmModal(false)
            }}>reschedule.</Button>
            This ensures an optimal treatment experience for the patient. Your cooperation is valued. Thank you.</p>
        </Modal>

        <RescheduleAppointmentWithTimeSlotsModal
          visible={rescheduleAppointmentModal}
          onRefresh={() => {
            onRefresh()
            setRescheduleAppointmentID("")
            setRescheduleAppointmentModal(false)
          }}
          onCancel={() => {
            setRescheduleAppointmentID("")
            setRescheduleAppointmentModal(false)
          }}
          activeAppointmentId={rescheduleAppointmentID}
        />
      </>
  );
};

const AddNoteAction = (props) => {
  const { addNote, appointment } = props;
  return (
    <Button
      type="primary"
      size="small"
      onClick={() => {
        addNote(true, appointment);
      }}
    >
      Add Notes
    </Button>
  );
};

const RevertStatusAction = (props) => {
  const { onRefresh, appointment } = props;
  const [revertStatusConfirmModal, setRevertStatusConfirmModal] = useState(false);
  const [displayUndoButton, setDisplayUndoButton] = useState(false)

  useEffect(() => {
    setDisplayUndoButton(checkIfDisplayUndoButton(appointment.updated_at))

    const interval = setInterval(() => {
      const ifDisplayUndo = checkIfDisplayUndoButton(appointment.updated_at)
      if (ifDisplayUndo) {
        setDisplayUndoButton(true)
        clearInterval(interval)
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const checkIfDisplayUndoButton = (updatedAt) => {
    const now = moment().unix()
    return (now > updatedAt + 30 * 60) ? false : true
  }

  const revertStatus = (id) => {
    revertAppointmentStatus({ id })
      .then(() => {
        setRevertStatusConfirmModal(false);
      })
      .catch(() => {
        setRevertStatusConfirmModal(false);
      })
      .finally(() => {
        onRefresh();
      });
  };
  return (
    !displayUndoButton ? <></> :
      <>
        <Button
          size="small"
          type="link"
          block
          onClick={() => setRevertStatusConfirmModal(true)}
        >
          Undo
        </Button>
        <Modal
          title="Confirm Status Revert"
          visible={revertStatusConfirmModal}
          okText="Submit"
          onOk={() => revertStatus(appointment.id)}
          onCancel={() => {
            setRevertStatusConfirmModal(false);
          }}
        >
          <p>Are you sure you want to revert this appointment to a confirmed status?</p>
        </Modal>
      </>
  );
};

const AppointmentSection = (props) => {
  const { pendingNotes } = props;
  const [appointmentOnTodayPatientList, setAppointmentOnTodayPatientList] = useState([]);
  const [startDate, setStartDate] = useState(pendingNotes ? 0 : moment().startOf('day').unix());
  const [endDate, setEndDate] = useState(
    pendingNotes ? moment().startOf('day').unix() : moment().endOf('day').unix(),
  );
  const [timeSlot, setTimeSlot] = useState({})
  const [showInsufficientModalByDefault, setShowInsufficientModalByDefault] = useState(false)
  useEffect(() => {
    refresh();
  }, [startDate, endDate]);

  const setStartAndEndDate = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const refresh = () => {
    let parameters = {
      start: startDate,
      end: endDate,
    };

    if (pendingNotes === 1) {
      parameters.pending_notes = 1;
    } else {
      parameters.pending_notes = 0;
    }

    srvCalendarAppointments(parameters).then((res) => {
      if (res.data !== null) {
        setAppointmentOnTodayPatientList(res.data);
      }
    });

    if (pendingNotes == 0) {
      srvDoctorFollowUpMonthlyTimeSlot().then((res)=>{
        if (res.data) {
          setTimeSlot(res.data)
          if (res.data && res.data.to_be_booked > res.data.remaining) {
            var now = moment()
            if (now.date() < 15) {
              if (!res.data.notify_first) {
                setShowInsufficientModalByDefault(true)
              }
            } else {
              if (!res.data.notify_fifth) {
                setShowInsufficientModalByDefault(true)
              }
            }
          }
        }
      })
    }
  };

  let showAppontmentStatus = 1;
  let showTimeDiff = false;
  let timeFormat = 'MMM DD hh:mm a z';
  if (pendingNotes === 1) {
    showAppontmentStatus = 0;
    timeFormat = 'MMM DD hh:mm a';
    showTimeDiff = true;
  }

  return (
    <>
      {pendingNotes ? (
        <ProviderDashboardSection
          sectionName="Pending Patient Notes"
          itemNumber={appointmentOnTodayPatientList.length}
        >
          <AppointmentList
            pendingNotes={pendingNotes}
            timeFormat={timeFormat}
            showTimeDiff={showTimeDiff}
            onRefresh={refresh}
            appointments={appointmentOnTodayPatientList}
            showAppointmentStatus={showAppontmentStatus}
          />
        </ProviderDashboardSection>
      ) : (
        <ProviderDashboardSection
          sectionName="Your Appointments Today"
          itemNumber={appointmentOnTodayPatientList.length}
          viewAllUrl={"/ehr_appointment"}
        >
          {/*customerHeader = {<AppontmentsCustomerHeader setStartAndEndDate={setStartAndEndDate}></AppontmentsCustomerHeader>}*/}

          <AppointmentList
            pendingNotes={pendingNotes}
            timeFormat={timeFormat}
            showTimeDiff={showTimeDiff}
            onRefresh={refresh}
            appointments={appointmentOnTodayPatientList}
            showAppointmentStatus={showAppontmentStatus}
            timeSlot={timeSlot}
            showInsufficientModalByDefault={showInsufficientModalByDefault}
          />
        </ProviderDashboardSection>
      )}
    </>
  );
};

const AppointmentList = (props) => {
  const {
    appointments,
    onRefresh,
    showAppointmentStatus,
    timeFormat,
    showTimeDiff,
    pendingNotes,
    timeSlot,
    showInsufficientModalByDefault,
    showLocationFlag,
  } = props;
  const [noteVisible, setNoteVisible] = useState(false);
  const [currentAppointment, setCurrentAppointment] = useState(false);
  const [treatmentPlan, setTreatmentPlan] = useState({});
  const [sorter, setSorter] = useState(null);
  const [insufficientModalVisible, setInsufficientModalVisible] = useState(showInsufficientModalByDefault);
  const { oncall$ } = useSelector((state) => state.call);
  const [callPatient, setCallPatient] = useState({});
  const { currentUser } = useSelector((state) => state.account);
  const makeCall = (target, reason) => {
    const data = {
      patient_id: callPatient.id,
      from_user_id: currentUser.id,
      target,
      first_name: callPatient.first_name,
      last_name: callPatient.last_name,
      reason,
    };

    oncall$.emit(data);
  };
  const [callTarget, setCallTarget] = useState('patient');
  const setVisible = (name, value) => {
    _setVisible((prev) => ({ ...prev, [name]: value }));
  };
  const [visible, _setVisible] = useState({
    callReason: false,
  });
  useEffect(function(){
    if (showInsufficientModalByDefault) {
      setInsufficientModalVisible(true)
    }
  }, [showInsufficientModalByDefault])

  function setAddNotePanelVisiblity(visible, appointment) {
    if (visible === true) {
      setCurrentAppointment(appointment);
    } else {
      setCurrentAppointment(false);
    }
    setNoteVisible(visible);
  }

  function getAction(appointment) {
    let status = appointment.status;
    let statusButtons = [];
    if (status === 2 || (status === 6 && pendingNotes === 1)) {
      statusButtons.push(
        <AddNoteAction
          addNote={setAddNotePanelVisiblity}
          appointment={appointment}
        />
      );
    }
    if (moment().unix() > appointment.appointed_at && appointment.appointment_type != 19) {
      statusButtons.push(<NoShowAction onRefresh={onRefresh} appointment={appointment} />)
    }
    let extraButtons = [];
    if (appointment.pdmp_object_key) {
      statusButtons.push(
        <Button
          type="primary"
          size="small"
          danger
          onClick={() => {
            downloadDocument(appointment.pdmp_object_key);
          }}
        >
          View PDMP
        </Button>
      );
    }
    if (statusButtons.length > 2) {
      let dropdownMenu = (
        <Menu>
          {statusButtons.slice(2).map((button, index) => (
            <Menu.Item key={index}>{button}</Menu.Item>
          ))}
        </Menu>
      );

      extraButtons.push(
        <Dropdown overlay={dropdownMenu}>
          <Button size="small" style={{width:'40px'}}>...</Button>
        </Dropdown>
      );

      statusButtons = statusButtons.slice(0, 2);
    }

    return (
      <Space>
        {statusButtons}
        {extraButtons}
      </Space>
    );
  }

  const renderItem = (appointment) => {
    const isOverDue = appointment?.appointed_at && (moment().unix()-appointment?.appointed_at) > 24 * 3600 ? true : false
    const overDueStyle = isOverDue ? {color: '#EC5C55', fontWeight: 'bolder', backgroundColor: '#fefafa'} : {}

    return (
      <div key={appointment.id} className="detail-table-item" style={overDueStyle}>
        <div className="detail-patient-name">
          {appointment.patient && (
            <Popover
              content={
                <div className="popover-user">
                  <div className="popover-user-item username">
                    <IconFont className="icon" type="icon-username" />
                    <Link to={`/patient/${appointment.patient.id}`}>{getPatientName(appointment.patient)}</Link>
                  </div>
                  <div className="popover-user-item phone">
                    <IconFont className="icon" type="icon-phone" />
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        setCallTarget('patient');
                        setVisible('callReason', true);
                        setCallPatient(appointment.patient);
                      }}
                    >
                      Call Patient
                    </Button>
                  </div>
                  <div className="popover-user-item birthdate">
                    <CalendarFilled className="icon" />
                    {appointment.patient.birthdate &&
                      moment.unix(appointment.patient.birthdate).utc().format('MM/DD/YYYY')}
                  </div>
                  <div className="popover-user-item sex">
                    <IconFont className="icon" type="icon-sex" />
                    {appointment.patient.gender}
                  </div>
                  <div className="popover-user-item state">
                    <EnvironmentFilled className="icon" />
                    {appointment.patient.state}
                  </div>
                </div>
              }
              title={getPatientName(appointment.patient)}
              trigger="hover"
            >
              <Link to={`/patient/${appointment.patient.id}`} style={{fontWeight: 500 }}>
                <div>{getPatientName(appointment.patient)}</div>
              </Link>
            </Popover>
          )}
          {!appointment.patient&&(<p />)}
        </div>
        <div className="detail-appointment-type">{appointment.appointment_type_name}</div>
        <div className="detail-appointment-date">
          {formatUnix(appointment.appointed_at, timeFormat)}
          {showTimeDiff ? ' (' + moment.unix(appointment.appointed_at).fromNow() + ') ' : null}
        </div>
        <div className="detail-appointment-status">
          <div>{appointment.status_name}</div>
          {
            (appointment.status === 5) &&
            <div className="detail-appointment-undo-button">
              <RevertStatusAction appointment={appointment} onRefresh={onRefresh} />
            </div>
          }
        </div>
        {currentUser.support_in_person && <div className='detail-appointment-location'>
          {
            (appointment.doctor_city != '' || appointment.doctor_state != '') &&
            <>
              {appointment.doctor_address_line1} {appointment.doctor_address_line2} {appointment.doctor_city} {appointment.doctor_state}
            </>
          }
        </div>}
        {appointment.status && (
          <div className="detail-appointment-notes">
            {appointment.pdmp_notes}
          </div>
        )}
        <div className="detail-action">{getAction(appointment)}</div>
      </div>
    )
  };

  const showInsufficientModal = (e) => {
    setInsufficientModalVisible(true)
  }

  const hideInsufficientModal = () => {
    setInsufficientModalVisible(false)
    srvDoctorMarkFollowUpTimeSlotNotified()
  }

  const completeUpdateTimeSlot = () => {
    if(currentUser.doctor_id === currentUser.external_calendar_id){
      history.push(`/ehr_calendar`);
      return;
    }
    srvDoctorCompleteFollowUpTimeSlot().then(function(){
      setInsufficientModalVisible(false)
      message.info('Informed carea team succesfully!');
    })
    srvDoctorMarkFollowUpTimeSlotNotified()
  }

  return (
    <>
      { timeSlot && timeSlot.to_be_booked > timeSlot.remaining &&
        <>
          <Alert
            style={{marginBottom: '20px '}}
            onClick={showInsufficientModal}
          message={
            <p>
              You have limited availability for this month’s follow up(sync). To accommodate more patients, please <a rel="noreferrer" target={
                currentUser.doctor_id === currentUser.external_calendar_id
                  ? '_self'
                  : '_blank'
              } href={currentUser.doctor_id === currentUser.external_calendar_id  ? '/ehr_calendar' : currentUser.scheduling_url} onClick={(e) => { e.stopPropagation() }} style={{ color: '#566BCD', fontWeight: 'bold' }}>click here</a> to fill in your additional available time and contact your PA to add more time slots. Please add at least <span style={{ color: '#E2445F' }}>{timeSlot.to_be_booked - timeSlot.remaining}</span> more time slots.
            </p>
          }
            type="info"
          />
          <InsufficientFollowUpAvailabilityModal
            timeSlot={timeSlot}
            schedulingUrl={currentUser.scheduling_url}
            isModalOpen={insufficientModalVisible}
            onCancel={hideInsufficientModal}
            completeUpdateTimeSlot={completeUpdateTimeSlot}
          />
        </>
      }

      <List
        itemLayout="vertical"
        style={{minWith:'1200px',overflowX: 'auto',overflowY:'hidden'}}
        pagination={appointments.length > 0 ? { pageSize: 10 } : false}
        locale={{ emptyText: ' ' }}
        dataSource={orderBy(appointments, ['appointed_at'], [sorter || 'desc'])}
        header={
          <div className="detail-table-header">
            <div className="detail-patient-name">Patient</div>
            <div className="detail-appointment-type">Type</div>
            <div className="detail-appointment-date">
              <DataSorter title="Time" sorter={sorter} setSorter={setSorter} />
            </div>
            <div className="detail-appointment-status">Status</div>
            {currentUser.support_in_person && <div className="detail-appointment-location">Location</div>}
            <div className="detail-appointment-notes">Admin Notes</div>
            <div className="detail-action">Actions</div>
          </div>
        }
        renderItem={renderItem}
      />

      {noteVisible && currentAppointment && (
        <AddNote
          sourceAction={`AppointmentTypes-${currentAppointment.appointment_type}`}
          onRefresh={onRefresh}
          noteVisible={noteVisible}
          setNoteVisible={setAddNotePanelVisiblity}
          appointment={currentAppointment}
          patientInfo={currentAppointment.patient}
        />
      )}
      <CallReasonModal
        visible={visible.callReason}
        filterFirstReason={true}
        onOk={(reason) => {
          setVisible('callReason', false);
          makeCall(callTarget, reason);
        }}
        onCancel={() => {
          setVisible('callReason', false);
        }}
      />
    </>
  );
};

export { AppontmentsCustomerHeader, AppointmentList, AppointmentSection };
