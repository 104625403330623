import {
  queryCurrent,
  srvDoctorStat,
  srvAdminStat,
  srvGetTermsAndConditions,
  srvAgreeTermsAndConditions,
} from '@/services/account';
import { message } from 'antd';
import { setAuthority } from '@/utils/authority';
import { delay } from '@/utils/utils';
import * as Sentry from "@sentry/react";

var globalInterval = null;

const UserModel = {
  namespace: 'account',
  state: {
    currentUser: {},
    doctorStat: {
      appointments_num: 0,
      requests_num: 0,
      consultations_num: 0,
      medical_file_request_num: 0,
    },
    adminStat: {
      consultations_num: 0,
    },
  },
  effects: {
    *fetchCurrent({ callback }, { call, put }) {
      const resp = yield call(queryCurrent);
      if (!resp.success) {
        return;
      }
      yield put({
        type: 'saveCurrentUser',
        payload: resp.data,
      });
      const role=resp.data.role;
      const roles = [role];
      if(resp.data.role==='admin'){
        const permissions=resp.data.permissions;
        const truePermissions = Object.keys(permissions).filter(key => permissions[key] === true);
        truePermissions.forEach(permission => {
          roles.push(permission);
        });
      }
      if (resp.data.approver_flag) {
        roles.push('approver');
      }
      if (resp.data.accept_co_services) {
        roles.push('urgentRefiller');
      }
      if (resp.data.enable_chart_audit) {
        roles.push('chartAuditor');
      }
      if (resp.data.enable_admin_access) {
        roles.push('adminAccesser');
      }
      setAuthority(roles, resp.data.id);
      window.sessionStorage.setItem('timezone', resp.data.timezone);
      localStorage.setItem('name', resp.data.name);
      callback && callback(resp.data);
      Sentry.setUser({
        id: resp.data.id,
        email: resp.data.email,
        name: resp.data.name,
      });
    },

    *fetchTermsAndConditions({ callback }, { call, put }) {
      const resp = yield call(srvGetTermsAndConditions);
      if (!resp.success) {
        return;
      }
      callback && callback(resp.data);
    },

    *agreeTermsAndConditions({ payload, callback }, { call, put }) {
      const resp = yield call(srvAgreeTermsAndConditions, payload);
      if (!resp.success) {
        return;
      }
      callback && callback();
    },

    *fetchDoctorStat({ payload }, { call, fork, cancelled, put, cancel }) {
      globalInterval = yield fork(function* () {
        try {
          while (true) {
            const resp = yield call(srvDoctorStat, payload);
            if (!resp.success) {
              return;
            }

            yield put({ type: 'saveDoctorStat', payload: resp.data });

            yield delay(10000);
          }
        } catch (err) {
          yield cancel(globalInterval);
        } finally {
          if (yield cancelled()) {
          }
        }
      });
    },
    *fetchAdminStat(_, { call, fork, cancelled, put, cancel }) {
      globalInterval = yield fork(function* () {
        try {
          while (true) {
            const resp = yield call(srvAdminStat);
            if (!resp.success) {
              return;
            }

            yield put({ type: 'saveAdminStat', payload: resp.data });

            yield delay(10000);
          }
        } catch (err) {
          yield cancel(globalInterval);
        } finally {
          if (yield cancelled()) {
          }
        }
      });
    },
    *clearInterval(_, { cancel }) {
      yield cancel(globalInterval);
    },
  },
  reducers: {
    saveCurrentUser(state, action) {
      return { ...state, currentUser: action.payload || {} };
    },
    saveDoctorStat(state, action) {
      return { ...state, doctorStat: action.payload };
    },
    saveAdminStat(state, action) {
      return { ...state, adminStat: action.payload };
    },
  },
};
export default UserModel;
