import { Redirect } from 'umi';
import { connect, history,useAccess } from 'umi';

import AdminPatientDetail from '../patient/AdminPatientDetail';
import DoctorPatientDetail from '../ehr/DoctorPatient';

const PatientDetailPage = (props) => {
  const { account, location } = props;
  const access=useAccess();
  if (location.pathname.indexOf('/ehr_patient') !== -1) {
    return <Redirect to={`${location.pathname.replace('/ehr_patient', '/patient')}${location.search}`} />;
  }
  if (account.currentUser.role === 'admin' && history.location.query.admin) {
    const { admin, ...restQuery } = history.location.query; 
    return <Redirect to={{ pathname: location.pathname, query: restQuery }} />;
  }
  if (account.currentUser.role === 'admin' || (history.location.query.admin && access.adminAccesser)) {
    return <AdminPatientDetail parentProps={props} />;
  } else {
    return <DoctorPatientDetail parentProps={props} />;
  }
};

export default connect(({ account, loading }) => ({
  account,
  loading,
}))(PatientDetailPage);
