import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link } from 'umi';
import { Tag, Space, Popover, Input, Table, Row, Col, Button, Dropdown, Tooltip, Layout, Menu, Badge } from 'antd';
import { AlertTwoTone } from '@ant-design/icons';
import moment from 'moment';
import { EllipsisOutlined, SearchOutlined, createFromIconfontCN, FileTextOutlined, CalendarFilled, EnvironmentFilled } from '@ant-design/icons';
import { AsYouType } from 'libphonenumber-js';
import Filters from '@/components/Filters';
import { appointmentStatusColor } from '@/utils/tagColor';
import defaultSettings from '../../../defaultSettings';
import RescheduleAppointment from '@/components/RescheduleAppointment';
import CancelAppointment from '@/components/CancelAppointment';
import ProviderNoShowModal from '@/components/ProviderNoShowModal';
import UploadFileModal from '@/components/UploadFileModal';
import ChangePastAppointmentStatus from '@/components/ChangePastAppointmentStatus';
import AppointmentQuestionResponseDrawer from '@/components/AppointmentQuestionResponseDrawer';

import { downloadDocument, getPatientName } from '@/utils/utils';
import { formatAppointmentAt, formatUnix } from '@/utils/xtime';
import { useRequest } from 'ahooks';
import styles from './index.less';
import {
  srvSyncAppointmentInfoToApero
  } from '@/services/admin';
const cx = cn.bind(styles);
const { Sider, Content } = Layout;
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const filters = [
  {
    name: 'user_email',
    label: 'Patient Email',
    type: 'text',
  },
  {
    name: 'user_phone',
    label: 'Patient Phone',
    type: 'text',
  },
  {
    name: 'doctor_email',
    label: 'Doctor Email',
    type: 'text',
  },
  {
    name: 'doctor_phone',
    label: 'Doctor Phone',
    type: 'text',
  },
  {
    name: 'appointed_at',
    label: 'Date',
    type: 'date',
  },
  {
    name: 'appointment_type',
    label: 'Type',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'status',
    label: 'Status',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'zoom_role',
    label: 'Zoom No Show ',
    type: 'checkbox',
    items: [{"value":"provider","label":"Provider No Show"},{"value":"patient","label":"Patient No Show"}],
  },
  {
    name: 'zoom_no_show_reason',
    label: 'No Show Reason',
    type: 'checkbox',
    items: [{"value":"no_show_at_five_minute","label":"No Show At Five Minute"},{"value":"no_show_in_half_hour","label":"No Show In Half Hour"},{"value":"late_at_five_minute","label":"Late At Five Minute"},{"value":"end_early_at_five_minute","label":"End Early At Five Minute"}],
  },
];

const Appointment = (props) => {
  const { dispatch, appointment, loading } = props;
  const { data, rescheduleCalendar, appointment_types, appointment_statuses } = appointment;
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [currentTab, setCurrentTab] = useState('1');
  const [uploadFileModalVisible, setUploadFileModalVisible] = useState(false);
  const [rescheduleVisible, setRescheduleVisible] = useState(false);
  const [cancelVisible, setCancelVisible] = useState(false);
  const [changeStatusVisible, setChangeStatusVisible] = useState(false);
  const [noShowModalVisible, setNoShowModalVisible] = useState(false);
  const [app, setApp] = useState({});
  const [viewAppointmentVideoID, setViewAppointmentVideoID] = useState(null);
  const [viewAppointmentDoctorName, setViewAppointmentDoctorName] = useState('');

  const columns = [
    {
      title: 'Appointment Time',
      dataIndex: 'appointed_at',
      key: 'appointed_at',
      width: 250,
      ellipsis: true,
      render: (v, r) => {
        const start = moment.unix(r.appointed_at);
        return (
          <div className={cx({timeSlot: r.admin_flag })} style={{ color: '#1a1f36', fontWeight: 500 }}>
            <Tooltip
              title={
                <div>
                  {r.admin_flag && <p>schedule by: {r.action_user_name}</p>}
                  <p>Patient time and time zone:</p>
                  <p>{formatAppointmentAt(r, r.timezone)}</p>
                </div>
              }
              placement="bottom"
            >
              {formatAppointmentAt(r)}
            </Tooltip>
            {r.no_show_records &&
              <span style={{margin:5}}><Tooltip title={r.no_show_records[0].role + ' ' +r.no_show_records[0].reason_type}><AlertTwoTone twoToneColor="#eb2f96"/> </Tooltip></span>
            }
          </div>
        );
      },
    },

    {
      title: 'Type',
      dataIndex: 'appointment_type',
      key: 'appointment_type',
      ellipsis: true,
      width: 250,
      render: (v, r) => {
        return r.appointment_type_name;
      },
    },
    {
      title: 'Patient',
      dataIndex: 'user_first_name',
      key: 'user_first_name',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        if(!r.patient) {
          return null;
        }
        const userName = getPatientName(r.patient);
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.user_id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/patient/${r.user_id}`}>{r.patient.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.patient.phone)}
                </div>
                <div className="popover-user-item birthdate">
                  <CalendarFilled className="icon" />
                  {r.patient.birthdate && moment.unix(r.patient.birthdate).utc().format('MM/DD/YYYY')}
                </div>
                <div className="popover-user-item sex">
                  <IconFont className="icon" type="icon-sex" />
                  {r.patient.gender}
                </div>
                <div className="popover-user-item state">
                  <EnvironmentFilled className="icon"/>
                  {r.patient.state}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <a href="#">{userName}</a>
          </Popover>
        );
      },
    },
    {
      title: 'Clinician',
      dataIndex: 'doctor.name',
      key: 'doctor.name',
      ellipsis: true,
      width: 210,
      render: (v, r) => {
        if(!r.doctor){
          return "";
        }
        const userName = `${r.doctor.name} ${r.doctor.title}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/doctor/detail/${r.doctor_id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/doctor/detail/${r.doctor_id}`}>{r.doctor.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.doctor.phone)}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <a href="#">{userName}</a>
          </Popover>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      ellipsis: true,
      width: 140,
      key: 'status',
      render: (v, r) => {
        return <Tag color={appointmentStatusColor(r.status)}>{r.status_name}</Tag>;
      },
    },
    {
      title: 'waiting_time',
      dataIndex: 'patient_waiting_time',
      key: 'patient_waiting_time',
      ellipsis: true,
      width: 110,
      render: (v, r) => {
        if (r.zoom_user_bind != null) {
          if (r.zoom_user_bind.check_flag) {
            return r.zoom_user_bind?.waiting_second && secondsToDhms(r.zoom_user_bind?.waiting_second);
          } else {
            return 'Calculating';
          }
        }
        return '';
      },
    },
    {
      title: 'duration_time',
      dataIndex: 'patient_duration_time',
      key: 'patient_duration_time',
      ellipsis: true,
      width: 120,
      render: (v, r) => {
        if (r.zoom_user_bind != null) {
          if (r.zoom_user_bind.bind_flag) {
            return r.zoom_user_bind?.duration_second&&secondsToDhms(r.zoom_user_bind?.duration_second);
          } else {
            return 'Calculating';
          }
        }
        return '';
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 210,
      fixed: 'right',
      render: (v, r) => {
        return (
          <Space>
            {r.pdmp_object_key && (
              <Button
                type="text"
                className={cx({ pdmpDownload: true })}
                icon={<FileTextOutlined />}
                onClick={() => {
                  downloadDocument(r.pdmp_object_key);
                }}
              />
            )}
            <Button
              type="primary"
              size="small"
              danger
              onClick={() => {
                showUploadFileModal(r);
              }}
            >
              Upload PDMP
            </Button>
            <Dropdown
              overlay={
                <div className={cx({ appointmentOpt: true })}>
                  {(r.status === 1 || r.status === 2) && (
                    <div
                      className={cx({ appointmentOptItem: true })}
                      onClick={() => showCancelPanel(r)}
                    >
                      <a href="#">Cancel</a>
                    </div>
                  )}
                  {r.status !== 6 && (
                    <div
                      className={cx({ appointmentOptItem: true })}
                      onClick={() => showReschedulePanel(r)}
                    >
                      <a href="#">Reschedule</a>
                    </div>
                  )}
                  {r.appointment_type == 19 &&
                    <div
                      className={cx({ appointmentOptItem: true })}
                      onClick={() =>
                        {
                          setViewAppointmentVideoID(r.id)
                          setViewAppointmentDoctorName(r.doctor_name)
                        }
                      }
                    >
                      <a href="#">View Video</a>
                    </div>
                  }
                  {
                    (
                      <div
                        className={cx({ appointmentOptItem: true })}
                        onClick={() => showChangeStatusModal(r)}
                      >
                        <a href="#">Change Status</a>
                      </div>
                    )
                  }
                  <div className={cx({ appointmentOptItem: true })}>
                    <Link to={`/appointment/${r.id}`}>View Appointment Details</Link>
                  </div>
                  <div
                    className={cx({ appointmentOptItem: true })}
                    // onClick={() => syncAppointmentToApero(r)}
                    aria-disabled="true"
                    style={{ pointerEvents: 'none', opacity: 0.5 }}
                  >
                    <a href="#">Sync to Apero</a>
                  </div>
                </div>
              }
              trigger={['click']}
            >
              <Button size="small">
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const showCancelPanel = (item) => {
    setApp(item);
    setCancelVisible(true);
  };

  const showReschedulePanel = (item) => {
    setApp(item);
    dispatch({
      type: 'appointment/fetchAppointmentRescheduleCalendar',
      payload: {
        appointment_id: item.id,
        doctor_location_id: item.doctor_location_id,
        timezone: item.timezone,
        days: 4,
        date: moment().format('YYYYMMDD'),
        dir: 'next',
      },
    });
    setRescheduleVisible(true);
  };

  const syncAppointmentToApero = (item) => {
    syncAppointmentInfoToApero.run({ id:item.id });
  };

  const syncAppointmentInfoToApero = useRequest(srvSyncAppointmentInfoToApero, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        refresh();
      }
    },
  });

  const showProviderNoShowModal = (item) => {
    setApp(item);
    setNoShowModalVisible(true);
  };

  const showChangeStatusModal = (item) => {
    setApp(item);
    setChangeStatusVisible(true);
  };

  const showUploadFileModal = (item) => {
    setApp(item);
    setUploadFileModalVisible(true);
  }

  const switchTab = (e) => {
    setCurrentTab(e.key);
    setPage(1);
    loadList({ status: parseInt(e.key), page: 1, page_size: 20, filters: JSON.stringify(query) }, () => { });
  }

  useEffect(() => {
    setPage(1);
    loadList({ status: parseInt(currentTab), page: 1, page_size: 20 }, () => {});
    dispatch({ type: 'appointment/fetchAppointmentTypes' });
    dispatch({ type: 'appointment/fetchAppointmentStatuses' });
  }, []);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList({ status: parseInt(currentTab), page: 1, page_size: 20, filters: JSON.stringify(q) }, () => {});
  };

  const loadList = (payload, callback) => {
    dispatch({ type: 'appointment/fetchList', payload, callback });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadList({
      status: parseInt(currentTab),
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: JSON.stringify(query),
    });
  };

  const f = () => {
    for (const v of filters) {
      if (v.name === 'appointment_type' && v.items.length === 0) {
        v.items = appointment_types;
        continue;
      }

      if (v.name === 'status' && v.items.length === 0) {
        v.items = appointment_statuses;
      }
    }
    return filters;
  };

  const secondsToDhms = (seconds) => {
    const s = Number(seconds);
    var d = Math.floor(s / (3600 * 24));
    var h = Math.floor(s % (3600 * 24) / 3600);
    var m = Math.floor(s % 3600 / 60);
    let displayContent = <></>
    if (d > 0) {
      displayContent = <>
        <span> {d} </span>
        <span className="dashboard-item-number-unit"> {d == 1 ? "day" : "days"}   </span>
        {
          h > 0 ? (
            <>
              <span> {h} </span>
              <span className="dashboard-item-number-unit"> {h == 1 ? "hr" : "hrs"}   </span>
            </>) : <></>
        }
      </>
    } else if (h > 0) {
      displayContent = <>
        <span> {h} </span>
        <span className="dashboard-item-number-unit"> {h == 1 ? "hr" : "hrs"}   </span>
        {m > 0 ? (
          <>
            <span> {m} </span>
            <span className="dashboard-item-number-unit"> {m == 1 ? "min" : "mins"}   </span>
          </>) : <></>
        }
      </>
    } else if (m>0){
      displayContent = <>
        <span> {m} </span>
        <span className="dashboard-item-number-unit">  {m > 0 ? (m == 1 ? "min" : "mins") : ""}   </span>
      </>
    }else {
      displayContent = <>
        <span> {s} </span>
        <span className="dashboard-item-number-unit">   {"second"}   </span>
      </>
    }
    return displayContent
  }


  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Appointments</h3>
        <Space>
          <Filters filters={f()} onFilter={onFilter} />
        </Space>
      </div>

      <Layout className={cx({ layout: true })}>
        <Sider theme="light" className={cx({ tabs: true })}>
          <Menu selectedKeys={[currentTab]} onClick={switchTab}>
            <Menu.Item key="1">New <Badge className={cx({ newCount: true })} count={data.new_total} /></Menu.Item>
            <Menu.Item key="2">Upcoming</Menu.Item>
            <Menu.Item key="3">Canceled</Menu.Item>
            <Menu.Item key="4">Completed</Menu.Item>
          </Menu>
        </Sider>
        <Content theme="light">
          <Table
            columns={columns}
            dataSource={data.items}
            rowKey="id"
            scroll={{ x: 1200 }}
            rowClassName={(r) => {
              if (r.id == app.id) {
                return 'rowSelected';
              }
              return '';
            }}
            loading={loading.effects['appointment/fetchList']}
            onChange={handleTableChange}
            pagination={{ pageSize: 20, current: page, simple: true, total: data.total }}
          />
        </Content>
      </Layout>

      { rescheduleVisible &&
          <RescheduleAppointment
          visible={rescheduleVisible}
          appointment={app}
          rescheduleCalendar={rescheduleCalendar}
          loadingReschedule={loading.effects['appointment/rescheduleAppointment']}
          onClose={() => {
            setRescheduleVisible(false);
          }}
          rescheduleAppointment={(payload, callback) => {
            dispatch({
              type: 'appointment/rescheduleAppointment',
              payload: payload,
              callback: () => {
                dispatch({ type: 'appointment/fetchAppointmentItem', payload: { id: payload.id } });
                callback();
              },
            });
          }}
          loadRescheduleCalendar={(payload) => {
            dispatch({
              type: 'appointment/fetchAppointmentRescheduleCalendar',
              payload: payload,
            });
          }}
        />
      }
      <CancelAppointment
        visible={cancelVisible}
        appointment={app}
        loadingCancel={loading.effects['appointment/cancelAppointment']}
        onClose={() => {
          setCancelVisible(false);
        }}
        cancelAppointment={(payload, callback) => {
          dispatch({
            type: 'appointment_detail/cancelAppointment',
            payload: payload,
            callback: () => {
              dispatch({ type: 'appointment/fetchAppointmentItem', payload: { id: payload.id } });
              callback();
            },
          });
        }}
      />
      {noShowModalVisible && (
        <ProviderNoShowModal
          id={app.id}
          visible={noShowModalVisible}
          onOk={(payload, callback) => {
            setNoShowModalVisible(false);

            dispatch({
              type: 'appointment_detail/markAppointmentProviderNoShow',
              payload: payload,
              callback: () => {
                dispatch({ type: 'appointment/fetchAppointmentItem', payload: { id: payload.id } });
                callback();
              },
            });
          }}
          onCancel={() => {
            setNoShowModalVisible(false);
          }}
        />
      )}
      {changeStatusVisible && (
        <ChangePastAppointmentStatus
          visible={changeStatusVisible}
          appointment={app}
          changePastAppointmentStatus={(payload, callback) => {
            dispatch({
              type: 'appointment_detail/changePastAppointmentStatus',
              payload: payload,
              callback: () => {
                dispatch({ type: 'appointment/fetchAppointmentItem', payload: { id: payload.id } });
                callback();
              },
            });
          }}
          onClose={() => {
            setChangeStatusVisible(false);
          }}
        />
      )}
      {uploadFileModalVisible && (
        <UploadFileModal
          user_id={app.user_id}
          visible={uploadFileModalVisible}
          okText="Upload PDMP"
          onOk={(payload, callback) => {
            dispatch({
              type: 'appointment/updateAppointmentPDMP', payload: {
                id: app.id,
                pdmp_object_key: payload.file_object_key,
                pdmp_fill_at: payload.last_fill_date,
                pdmp_notes: payload.additional_notes
              }, callback: () => {
                setUploadFileModalVisible(false);
                loadList({ status: parseInt(currentTab), page: 1, page_size: 20, filters: JSON.stringify(query) }, () => { });
              },
            });
          }}
          onCancel={() => {
            setUploadFileModalVisible(false);
          }}
        />
      )}
      {
        viewAppointmentVideoID && (
          <AppointmentQuestionResponseDrawer onClose={()=> { setViewAppointmentVideoID(null)} } appointmentId={viewAppointmentVideoID} doctorName={viewAppointmentDoctorName} />
        )
      }
    </div>
  );
};

export default connect(({ appointment, loading }) => ({
  appointment,
  loading,
}))(Appointment);
