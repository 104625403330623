import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Radio, Drawer, Button, Collapse, DatePicker, InputNumber, Spin } from 'antd';

import cn from 'classnames/bind';
import moment from 'moment-timezone';
import { useRequest } from 'ahooks';
import { timeZones } from '@/utils/xtime';
import isEmpty from 'lodash/isEmpty';

import {
  srvPatientStateDoctors,
  srvPatientTherapists,
  srvAdminScheduleAppointmentRequest,
} from '@/services/admin';

import { srvPatientPendingAppointmentRequest, srvDoctorAppointmentRequestPreferredTimes } from '@/services/patient';

import styles from './index.less';
const { Panel } = Collapse;
const cx = cn.bind(styles);

const ScheduleAppointmentRequest = (props) => {
  const { visible, detail: patientInfo, appointmentRequest } = props;

  const [activeDoctor, setActiveDoctor] = useState({});
  const [doctors, setDoctors] = useState([]);

  const [appointmentType, setAppoitmentType] = useState({ id: 1, name: 'Re-initial Consultation(30min)' });

  const [timezone, setTimezone] = useState('America/Los_Angeles');
  const [appointmentRequestWithPrice, setAppointmentRequestWithPrice] = useState({});

  const [calendar, setCalendar] = useState({});
  const [calendarPage, setCalendarPage] = useState(1);
  const [calendarDates, setCalendarDates] = useState([]);

  const [appointedAt, setAppointedAt] = useState(0);

  useEffect(() => {
    if (calendar && calendar.dates && calendar.dates.length > 0) {
      const dates = calendar.dates.slice((calendarPage - 1) * 3, calendarPage * 3)
      setCalendarDates(dates)
    }
  }, [calendar, calendarPage]);

  const stateDoctorsReq = useRequest(srvPatientStateDoctors, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setDoctors(ret.data);
        if (ret.data.length > 0) {
          setActiveDoctor(ret.data[0]);
        } else {
          setActiveDoctor({});
        }
      }
    },
  });

  const stateTherapyDoctorsReq = useRequest(srvPatientTherapists, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setDoctors(ret.data);
        if (ret.data.length > 0) {
          setActiveDoctor(ret.data[0]);
        } else {
          setActiveDoctor({});
        }
      }
    },
  });

  const fetchPendingAppointmentRequestReq = useRequest(srvPatientPendingAppointmentRequest, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setAppointmentRequestWithPrice(ret.data);
      }
    },
  });

  const fetchDoctorAppointmentRequestPreferredTimesReq = useRequest(srvDoctorAppointmentRequestPreferredTimes, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setCalendar(ret.data);
      }
    },
  });

  const scheduleAppointmentRequestReq = useRequest(srvAdminScheduleAppointmentRequest, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setAppointedAt(0);
        onClose();
      }
    },
  });

  const onClose = () => {
    setAppointedAt(0);
    props.onClose && props.onClose();
  };

  const onFinish = () => {
    scheduleAppointmentRequestReq.run({
      request_id: appointmentRequest.id,
      appointment_preferred_at: appointedAt,
      doctor_id: activeDoctor.id,
    });
  };

  const changeCustomDateTime = (date, dateString) => {
    setAppointedAt(moment.tz(dateString, timezone).unix());
  };

  const renderClinicianSelection = () => {
    return (
      <Select
        name="doctor"
        id="doctor"
        style={{ width: '100%' }}
        value={activeDoctor.id}
        loading={stateDoctorsReq.loading}
        onChange={(v) => {
          for (const x of doctors) {
            if (x.id == v) {
              setActiveDoctor(x);
            }
          }
        }}
      >
        {doctors.map((v) => (
          <Select.Option value={v.id} key={v.id}>
            {v.name}
          </Select.Option>
        ))}
      </Select>
    )
  }

  useEffect(() => {
    console.log('useEffect:activeDoctor,appointmentType:', activeDoctor.id, appointmentType)
    if (activeDoctor && activeDoctor.id && appointmentType) {
      fetchDoctorAppointmentRequestPreferredTimesReq.run({ id: appointmentRequest.id, doctor_id: activeDoctor.id });
    }
  }, [activeDoctor, appointmentType])

  useEffect(() => {
    if (patientInfo && patientInfo.id && appointmentRequest && appointmentRequest.id) {
      fetchPendingAppointmentRequestReq.run({ id: appointmentRequest.id });
      if (patientInfo.time_zone !== '') {
        setTimezone(props.detail.time_zone);
      }
      if ([15, 16, 17, 18].includes(appointmentRequest.appointment_type)) {
        if (appointmentRequest.appointment_type == 15) {
          setAppoitmentType({ id: 15, name: 'ADHD Coaching and Therapy Session 45 Min' });
        } else if (appointmentRequest.appointment_type == 16) {
          setAppoitmentType({ id: 16, name: 'ADHD Coaching and Therapy Session 30 Min' });
        } else if (appointmentRequest.appointment_type == 17) {
          setAppoitmentType({ id: 17, name: 'Initial ADHD Coaching and Therapy Evaluation 45 Min' });
        } else if (appointmentRequest.appointment_type == 18) {
          setAppoitmentType({ id: 18, name: 'Initial ADHD Coaching and Therapy Evaluation 30 Min' });
        } 
        stateTherapyDoctorsReq.run({ id: patientInfo.id });
      } else {
        if (patientInfo.patient_type === 1) {
          setAppoitmentType({ id: 5, name: 'Pediatric Consultation 50 Min' });
        } else if (patientInfo.patient_type === 3) {
          setAppoitmentType({ id: 11, name: 'Insomnia Consultation 40 Min' });
        } else {
          setAppoitmentType({ id: 1, name: 'Re-initial Consultation(30min)' });
        }
        stateDoctorsReq.run({ id: patientInfo.id });
      }
    }
  }, [patientInfo, appointmentRequest])

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      destroyOnClose={true}
      className={cx({ rescheduleDrawer: true })}
      title={
        <div className={cx({ title: true })}>
          <h3>Schedule Appointment</h3>
          {appointedAt > 0 && !isEmpty(activeDoctor) && (
            <Button type="primary" loading={scheduleAppointmentRequestReq.loading} onClick={onFinish}>
              Confirm
            </Button>
          )}
        </div>
      }
      width="600"
      placement="right"
      closable={false}
    >
      <Spin spinning={fetchPendingAppointmentRequestReq.loading || stateDoctorsReq.loading }>
        <div className={cx({ description: true })}>
          <Row style={{ marginBottom: '10px' }}>
            <Col span={6}>Clinician:</Col>
            <Col span={18}>
              {renderClinicianSelection()}
            </Col>
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            <Col span={6}>Meeting Type:</Col>
            <Col span={18}>Online</Col>
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            <Col span={6}>Appointment Type:</Col>
            <Col span={18}>{appointmentType && appointmentType.name}</Col>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col span={6}>Charge Fee:</Col>
            <Col span={18}>{appointmentRequestWithPrice && appointmentRequestWithPrice.remained_price}</Col>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col span={6}>Time Zone:</Col>
            <Col span={18}>
              <Select
                name="timezone"
                id="timezone"
                style={{ width: '100%' }}
                value={timezone}
                onChange={(v) => {
                  setTimezone(v);
                }}
              >
                {timeZones.map((v, i) => {
                  return (
                    <Select.Option key={i} value={v[0]}>
                      {v[1]}
                    </Select.Option>
                  );
                })}
              </Select>
            </Col>
          </Row>

        </div>

        <Collapse defaultActiveKey={['1']} ghost={true} bordered={false} expandIconPosition="right">
          <Panel header="Date&Time" key="1">
            {calendar && calendar.dates && calendar.dates.length > 0 && (
              <div className={cx({ calendar: true })}>
                <div className={cx({ calendarOp: true })}>
                  <Button
                    disabled={calendarPage === 1}
                    onClick={() => {
                      setCalendarPage(calendarPage - 1)
                    }}
                  >
                    Prev
                  </Button>
                  <Button
                    disabled={calendarPage === 2}
                    onClick={() => {
                      setCalendarPage(calendarPage + 1)
                    }}
                  >
                    Next
                  </Button>
                </div>
                <div className={cx({ calendarDates: true })}>
                  {calendarDates.map((v, idx) => {
                    const timesDom =
                      v.times &&
                      v.times.map((x, i) => {
                        const start_time =  moment.unix(x).tz(timezone).format('hh:mm A')
                        const end_time = moment.unix(x).tz(timezone).add(25, 'minutes').format('hh:mm A');
                        return (
                          <div className={cx({ timeSlot: true })} key={i}>
                            <Button
                              size="small"
                              onClick={() => {
                                setAppointedAt(x);
                              }}
                              type={appointedAt > 0 && appointedAt === x ? 'danger' : 'default'}
                            >
                              {start_time} - {end_time}
                            </Button>
                          </div>
                        );
                      });
                    return (
                      <div className={cx({ dateColumn: true })} key={idx}>
                        <div className={cx({ dateSlot: true })}>
                          {moment(v.date, 'YYYYMMDD').format('MMM D')}
                        </div>
                        <div className={cx({ dateSlot: true })}>
                          {moment(v.date, 'YYYYMMDD').format('ddd')}
                        </div>
                        {timesDom}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </Panel>
          <Panel header="Other time" key="2">
            <Row>
              <Col span={12}>
                <label>Date Time</label>
                <div>
                  <DatePicker
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    minuteStep={5}
                    size="default"
                    style={{ width: '80%' }}
                    onChange={changeCustomDateTime}
                  />
                </div>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Spin>
    </Drawer>
  );
};

export default ScheduleAppointmentRequest;
