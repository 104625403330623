import React, { useState, useEffect, useRef } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'; 
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { LeftCircleOutlined, RightCircleOutlined, RedoOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';


import './index.css';

const PDFPreview = (props) => {

  const { file, width } = props

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1);

  const onLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  }

  const onLoadError = (e) => {
    console.log('onLoadError:', e)
  }

  const onPageControl = (n) => {
    let page = pageNumber + n;
    if (page <= 1) page = 1;
    if (numPages && page >= numPages) page = numPages;
    setPageNumber(page);
  }

  const onRotateControl = (n) => {
    let r = rotate + n;
    if (r >= 360) r = 0;
    setRotate(r);
  }
  
  const onScaleControl = (n) => {
    let r = scale + n;
    if (r >= 1.5) r = 1.5
    if (r <= 0.5) r = 0.5
    setScale(r);
  }

  return (
    <div className="document" onContextMenu={e => e.preventDefault()}>
      <Document file={file} onLoadSuccess={onLoadSuccess} onLoadError={onLoadError} >
        {/* {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))} */}
        <Page pageNumber={pageNumber} rotate={rotate} scale={scale} width={width} />
        <div className="pageControls">
          <button type="button" onClick={() => onPageControl(-1)}><LeftCircleOutlined /></button>
          <span>{pageNumber} of {numPages}</span>
          <button type="button" onClick={() => onPageControl(1)}><RightCircleOutlined /></button>
          <span>  </span>
          <button type="button" onClick={() => onRotateControl(90)}><RedoOutlined /></button>
          <span>  </span>
          <button type="button" onClick={() => onScaleControl(-0.25)}><ZoomOutOutlined /></button>
          <span> { 100 * scale } %</span>
          <button type="button" onClick={() => onScaleControl(0.25)}><ZoomInOutlined /></button>
        </div>
      </Document>
    </div>
  )

}

export default PDFPreview;