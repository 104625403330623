import React, { useState, useEffect } from 'react';
import {
  Radio,
  DatePicker,
  Space,
  Select,
  Checkbox,
  Form,
  InputNumber,
  Button,
  Row,
  Col,
} from 'antd';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import { createFromIconfontCN } from '@ant-design/icons';
const { Option } = Select;

import defaultSettings from '../../../defaultSettings';
import styles from './index.less';
const cx = cn.bind(styles);
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const DateFilter = (props) => {
  const [visible, setVisible] = useState(false);
  const [opt, setOpt] = useState('inTheLast');
  const { form, name, label } = props;
  const onChange = (v) => {
    setOpt(v);
  };

  props.reset$.useSubscription(() => {
    setVisible(false);
  });

  return (
    <div className={cx({ filtersItem: true })}>
      <div className={cx({ filtersItemSwitch: true })}>
        <Checkbox
          checked={visible}
          onChange={(v) => {
            setVisible(v.target.checked);
          }}
        >
          {label}
        </Checkbox>
      </div>
      <div className={cx({ filtersItemBox: true, visible: visible })}>
        {visible && (
          <>
            <div className={cx({ select: true })}>
              <Select value={opt} onChange={onChange} size="small">
                <Option value="inTheLast">is in the last</Option>
                <Option value="equals">is equal to</Option>
                <Option value="between">is between</Option>
                <Option value="isAfter">is after</Option>
                <Option value="isAfterOrOn">is on or after</Option>
                <Option value="isBefore">is before</Option>
                <Option value="isBeforeOrOn">is before or on</Option>
              </Select>
            </div>

            {opt === 'inTheLast' && (
              <div className={cx({ box: true })}>
                <Space>
                  <IconFont type="icon-down-right" />
                  <Form.Item name={name} rules={[{ required: true }]}>
                    <InputNumber precision={0} size="small" />
                  </Form.Item>
                  <Form.Item name="last_type" initialValue="days">
                    <Select size="small">
                      <Option value="days">days</Option>
                      <Option value="months">months</Option>
                    </Select>
                  </Form.Item>
                </Space>
              </div>
            )}

            {opt === 'equals' && (
              <div className={cx({ box: true })}>
                <Space>
                  <IconFont type="icon-down-right" />
                  <Form.Item name={`${name}[eq]`} rules={[{ required: true }]}>
                    <DatePicker
                      size="small"
                      format="MM/DD/YYYY"
                      showTime={false}
                      showToday={true}
                    />
                  </Form.Item>
                </Space>
              </div>
            )}

            {opt === 'between' && (
              <div className={cx({ box: true, between: true })}>
                <Space>
                  <Form.Item name={`${name}[gte]`} rules={[{ required: true }]}>
                    <DatePicker
                      size="small"
                      format="MM/DD/YYYY"
                      showTime={false}
                      showToday={true}
                    />
                  </Form.Item>
                  <span>and</span>
                  <Form.Item name={`${name}[lte]`} rules={[{ required: true }]}>
                    <DatePicker
                      size="small"
                      format="MM/DD/YYYY"
                      showTime={false}
                      showToday={true}
                    />
                  </Form.Item>
                </Space>
              </div>
            )}

            {opt === 'isAfter' && (
              <div className={cx({ box: true })}>
                <Space>
                  <IconFont type="icon-down-right" />
                  <Form.Item name={`${name}[gt]`} rules={[{ required: true }]}>
                    <DatePicker
                      size="small"
                      format="MM/DD/YYYY"
                      showTime={false}
                      showToday={true}
                    />
                  </Form.Item>
                </Space>
              </div>
            )}

            {opt === 'isAfterOrOn' && (
              <div className={cx({ box: true })}>
                <Space>
                  <IconFont type="icon-down-right" />
                  <Form.Item name={`${name}[gte]`} rules={[{ required: true }]}>
                    <DatePicker
                      size="small"
                      format="MM/DD/YYYY"
                      showTime={false}
                      showToday={true}
                    />
                  </Form.Item>
                </Space>
              </div>
            )}

            {opt === 'isBefore' && (
              <div className={cx({ box: true })}>
                <Space>
                  <IconFont type="icon-down-right" />
                  <Form.Item name={`${name}[lt]`} rules={[{ required: true }]}>
                    <DatePicker
                      size="small"
                      format="MM/DD/YYYY"
                      showTime={false}
                      showToday={true}
                    />
                  </Form.Item>
                </Space>
              </div>
            )}

            {opt === 'isBeforeOrOn' && (
              <div className={cx({ box: true })}>
                <Space>
                  <IconFont type="icon-down-right" />
                  <Form.Item name={`${name}[lte]`} rules={[{ required: true }]}>
                    <DatePicker
                      size="small"
                      format="MM/DD/YYYY"
                      showTime={false}
                      showToday={true}
                    />
                  </Form.Item>
                </Space>
              </div>
            )}

            <div className={cx({ box: true })}>
              <Space>
                <span>Time zone: </span>
                <Form.Item name="timezone" initialValue="pdt" rules={[{ required: true }]}>
                  <Radio.Group optionType="default">
                    <Radio value="pdt">PDT</Radio>
                    <Radio value="utc">UTC</Radio>
                  </Radio.Group>
                </Form.Item>
              </Space>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DateFilter;
