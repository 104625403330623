import React, { useState } from 'react';
import { Button } from 'antd';
import cn from 'classnames/bind';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';

import { srvRxntStatus } from '@/services/patient';
import styles from './PrescriptionPane.less';
const cx = cn.bind(styles);

const PresciptionPane = (props) => {
  const { id, active, onApprove, pendingPrescriptionReqeust } = props;
  const [rxntStatus, setRxntStatus] = useState({});

  const loadRxntStatusReq = useRequest(srvRxntStatus, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setRxntStatus(ret.data);
      }
    },
  });

  const loadData = () => {
    if (active) {
      loadRxntStatusReq.run({ id });
    }
  };

  useMount(() => {
    loadData();
  });

  useUpdateEffect(() => {
    loadData();
  }, [active, id]);

  return (
    <div className={cx({ rxntContainer: true })}>
      {rxntStatus.patient_rxnt_id != '' && (
        <Button
          type="primary"
          disabled={!rxntStatus.is_doctor_authorized || rxntStatus.patient_rxnt_id === '0' || props.referralRequiredDisableRXNTPerscribe}
          onClick={() => {
            let link = `/api/ehr/rxnt/sso?return_url=${Buffer.from(window.location.href).toString('base64')}&patient_id=${id}&order_type=1`;
            if (pendingPrescriptionReqeust && pendingPrescriptionReqeust.id) {
              link = `/api/ehr/rxnt/sso?return_url=${Buffer.from(window.location.href).toString('base64')}&patient_id=${id}&order_type=2&treatment_request_id=${pendingPrescriptionReqeust.id}`;
              onApprove(pendingPrescriptionReqeust, 'PrescribeInRXNT', link);
            } else {
              window.location.href = link; // when no refill request, inital appointment, follow up appointment
            }
          }}
        >
          Prescribe in RXNT
        </Button>
      )}
    </div>
  );
};

export default PresciptionPane;
