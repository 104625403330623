import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Space, Select, Input, Form, message, Typography } from 'antd';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const RxntAccountEdit = (props) => {

  const { form, selectStates = [], onOk } = props;
  const [states, setStates] = useState(selectStates);

  useEffect(() => {
    setStates(selectStates);
  }, [selectStates])


  const onChange = (key, name) => {
    const rxnt_accounts = form.getFieldValue('rxnt_accounts')
    if (rxnt_accounts[key] && rxnt_accounts[key]['id']) {
      rxnt_accounts[key]['is_update'] = true;
      form.setFieldsValue({ rxnt_accounts })
    }
  }

  const onRemove = (key, index, remove) => {
    const rxnt_accounts = form.getFieldValue('rxnt_accounts')
    if (rxnt_accounts[index] && rxnt_accounts[index]['id']) {
      rxnt_accounts[index]['is_del'] = true;
      setStates([].concat(states, [rxnt_accounts[index]['state']]));
      form.setFieldsValue({ rxnt_accounts })
    } else {
      remove(index);
    }
  }

  const onAdd = (add) => {
    if (states && states.length > 0) {
      add();
    }
  }

  return (
    <Row gutter={16}>
      <Col span={12}>
   
        <Space
          style={{
            display: 'flex',
            marginBottom: 5,
          }}
          align="baseline"
        >
          <Form.Item style={{
            width: 130,
            marginBottom: 5,
          }}>
            <Typography.Text>
              State
            </Typography.Text>
          </Form.Item>
          <Form.Item style={{
            width: 180,
            marginBottom: 5,
          }}>
            <Typography.Text>
              Username
            </Typography.Text>
          </Form.Item >
          <Form.Item style={{
            width: 180,
            marginBottom: 5,
          }}>
            <Typography.Text>
              Password
            </Typography.Text>
          </Form.Item>
        </Space>
      
        <Space
          style={{
            display: 'flex',
          }}
          align="baseline"
        >
          <Form.Item style={{
            width: 130,
          }}>
            <Typography.Text>
              default
            </Typography.Text>
          </Form.Item>
          <Form.Item
            name="rxnt_user_name"
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="rxnt_password"
          >
            <Input type={"password"} placeholder="" />
          </Form.Item>
        </Space>
        
        <Form.List name="rxnt_accounts">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Space
                  key={field.key}
                  hidden={form.getFieldValue(['rxnt_accounts', field.name, 'is_del'])}
                  style={{
                    display: 'flex',
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...field}
                    onChange={() => onChange(field.name, 'state')}
                    name={[field.name, 'state']}
                    rules={[
                      {
                        required: true,
                        message: 'Missing state',
                      },
                    ]}
                  >
                    <Select
                      style={{
                        width: 130,
                      }}
                    >
                      {(states || []).map((item) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, 'rxnt_user_name']}
                    onChange={() => onChange(field.name, 'rxnt_user_name')}
                    rules={[
                      {
                        required: true,
                        message: 'Missing username',
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    onChange={() => onChange(field.name, 'rxnt_password')}
                    name={[field.name, 'rxnt_password']}
                    rules={[
                      {
                        required: true,
                        message: 'Missing password',
                      },
                    ]}
                  >
                    <Input type={"password"} placeholder="" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    hidden={true}
                    name={[field.name, 'id']}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    hidden={true}
                    name={[field.name, 'is_update']}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    hidden={true}
                    name={[field.name, 'is_del']}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => onRemove(field.key, field.name, remove)} />
                </Space>
              ))}
              <Form.Item>
                <Space>
                  <Button type="primary" onClick={() => onAdd(add)} disabled={!states.length}>
                    + Add Another
                  </Button>
                  {onOk && (
                    <Button type="primary" onClick={onOk}>
                    Save
                    </Button>
                  )}
                </Space>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Col>
    </Row>
  );
};

export default RxntAccountEdit;
