// /userInfo/2144/id => ['/userInfo','/userInfo/2144,'/userInfo/2144/id']
// eslint-disable-next-line import/prefer-default-export
export function urlToList(url) {
  if (!url || url === "/") {
    return ["/"];
  }
  const urlList = url.split("/").filter(i => i);
  return urlList.map((urlItem, index) => `/${urlList.slice(0, index + 1).join("/")}`);
}

export function thumbUrl(url, width) {
  const idx = url.lastIndexOf("/");
  const prePath = url.substring(0, idx + 1);
  const filename = url.substring(idx + 1);

  return `${prePath}thumb/${width}/${filename}`;
}
