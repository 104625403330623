import React, { useState, useEffect, createRef } from 'react';
import cn from 'classnames/bind';
import { connect, Link } from 'umi';
import { Space, Popover, Table, Tag, Layout, Menu,Form,Button,Drawer,Descriptions,Input} from 'antd';
import { useRequest } from 'ahooks';
import { createFromIconfontCN } from '@ant-design/icons';
import { AsYouType } from 'libphonenumber-js';
import {
  srvGetDisputeReviewList,
  srvAddDisputeReview
} from '@/services/admin';

import Filters from '@/components/Filters';
import defaultSettings from '../../../defaultSettings';

import ConsultationDrawer from '@/components/ConsultationDrawer';

import { formatUnix } from '../../utils/xtime';
import styles from './index.less';
const cx = cn.bind(styles);

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const { Sider, Content } = Layout;

const filters = [
  {
    name: 'user_email',
    label: 'Patient Email',
    type: 'text',
  },
  {
    name: 'user_phone',
    label: 'Patient Phone',
    type: 'text',
  },
  {
    name: 'doctor_email',
    label: 'Doctor Email',
    type: 'text',
  },
  {
    name: 'doctor_phone',
    label: 'Doctor Phone',
    type: 'text',
  },
  {
    name: 'consultation_type',
    label: 'Type',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'created_at',
    label: 'Date',
    type: 'date',
  },
  {
    name: 'reply_num',
    label: 'Reply Count',
    type: 'number'
  },
  {
    name: 'status',
    label: 'Status',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'provider_status',
    label: 'Provider status',
    type: 'checkbox',
    items: [{"label":"off-boarded","value":2}],
  }
];
const disputeFilters = [
  {
    name: 'user_name',
    label: 'Patient Name',
    type: 'text',
  },
  {
    name: 'doctor_name',
    label: 'Doctor Name',
    type: 'text',
  },
  {
    name: 'dispute_status',
    label: 'Dispute Status',
    type: 'checkbox',
    items: [
      { label: 'In-Dispute', value: 1 },
      { label: 'Approved', value: 2 },
      { label: 'Denied', value: 3 },
    ],
  },
  {
    name: 'rating_submission_date',
    label: 'Rating Submission Date',
    type: 'date',
  },
  {
    name: 'rating_score',
    label: 'Rating Score',
    type: 'checkbox',
    items: [
      { label: 1, value: 2 },
      { label: 2, value: 2 },
      { label: 3, value: 3 },
      { label: 4, value: 4 },
      { label: 5, value: 5 },
    ],
  }
];
var defaultFilters = {};

const defaultColumns = [
  // {
  //   title: 'Status',
  //   dataIndex: 'status_name',
  //   key: 'status_name',
  //   width: 100,
  //   render: (v, r) => {
  //     if(r.status == 2) {
  //       return <Tag color='grey'>{r.status_name}</Tag>
  //     } else {
  //       return ''
  //     }
  //   }
  // },
  // {
  //   title: 'Assigned To',
  //   dataIndex: 'assigned_to',
  //   key: 'assigned_to',
  //   ellipsis: true,
  //   width: 150,
  //   render: (v, r) => {
  //     return (
  //       <div>
  //       { r.assigned_to &&
  //         <span>{r.assigned_to.name}</span>
  //       }
  //       </div>
  //     )
  //   }
  // },
  {
    title: 'Type',
    dataIndex: 'consultation_type',
    key: 'consultation_type',
    ellipsis: true,
    width: 300,
    render: (v, r) => {
      return String(r.consultation_type_name)
        .toLowerCase()
        .replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
    },
  },
  {
    title: 'Replies',
    dataIndex: 'reply_num',
    key: 'reply_num',
    ellipsis: true,
    width: 70,
    render: (v, r) => {
      return <p style={r.is_urgent_flag ? { color: '#EC5C55' } : {}}>{v}</p>;
    },
  },
  {
    title: 'Patient',
    dataIndex: 'user_id',
    key: 'user_id',
    ellipsis: true,
    width: 150,
    render: (v, r) => {
      if (r == null || r.patient == null) {
        return '';
      }
      let userName = `${r.patient.first_name} ${r.patient.last_name}`;
      if (!r.patient.first_name&&!r.patient.last_name) {
        userName = r.patient.name;
      }
      return (
        <Popover
          content={
            <div className="popover-user">
              <div className="popover-user-item username">
                <IconFont className="icon" type="icon-username" />
                <Link to={`/patient/${r.patient.id}`}>{userName}</Link>
              </div>
              <div className="popover-user-item email">
                <IconFont className="icon" type="icon-email" />
                <Link to={`/patient/${r.patient.id}`}>{r.patient.email}</Link>
              </div>
              <div className="popover-user-item phone">
                <IconFont className="icon" type="icon-phone" />
                {new AsYouType('US').input(r.patient.phone)}
              </div>
              <div className="popover-user-item address">
                <IconFont className="icon" type="icon-address" />
                {r.patient.zipcode}
              </div>
              <div className="popover-user-item sex">
                <IconFont className="icon" type="icon-sex" />
                {r.patient.gender}
              </div>
            </div>
          }
          title={userName}
          trigger="hover"
        >
          <a href="#" style={r.is_urgent_flag ? { color: '#EC5C55' } : {}}>
            {userName}
          </a>
        </Popover>
      );
    },
  },
  {
    title: 'Clinician',
    dataIndex: 'doctor_id',
    key: 'doctor_id',
    ellipsis: true,
    width: 200,
    render: (v, r) => {
      if(!r.doctor){
        return '';
      }
      const userName = `${r.doctor.name} ${r.doctor.title}`;
      return (
        <Popover
          content={
            <div className="popover-user">
              <div className="popover-user-item username">
                <IconFont className="icon" type="icon-username" />
                <Link to={`/doctor/detail/${r.doctor.id}`}>{userName}</Link>
              </div>
              <div className="popover-user-item email">
                <IconFont className="icon" type="icon-email" />
                <Link to={`/doctor/detail/${r.doctor.id}`}>{r.doctor.email}</Link>
              </div>
              <div className="popover-user-item phone">
                <IconFont className="icon" type="icon-phone" />
                {new AsYouType('US').input(r.doctor.phone)}
              </div>
            </div>
          }
          title={userName}
          trigger="hover"
        >
          <a href="#" style={r.is_urgent_flag ? { color: '#EC5C55' } : {}}>
            {userName}
          </a>
        </Popover>
      );
    },
  },
  {
    title: 'Sent Date',
    dataIndex: 'created_at',
    key: 'created_at',
    ellipsis: true,
    width: 200,
    render: (v, r) => {
      return <p style={r.is_urgent_flag ? { color: '#EC5C55' } : {}}>{formatUnix(r.created_at)}</p>;
    },
  },
];

const headerColumns = [  {
  title: 'Subject',
  dataIndex: 'title',
  key: 'title',
  ellipsis: true,
}];

const urgentColumns = [{
  title: 'Last Reply Date',
  dataIndex: 'last_reply_at',
  key: 'last_reply_at',
  ellipsis: true,
  width: 200,
  render: (v, r) => {
    return r.last_reply_at ? formatUnix(r.last_reply_at) : '';
  },
}];



const solvedColumns = [{
  title: 'Solve Date',
  dataIndex: 'solved_at',
  key: 'solved_at',
  ellipsis: true,
  width: 200,
  render: (v, r) => {
    return r.solved_at ? formatUnix(r.solved_at) : '';
  },
}]

const medicationColumns = [
  {
    title: 'Status',
    dataIndex: 'status_name',
    key: 'status_name',
    ellipsis: true,
    width: 150,
    render: (v, r) => {
      if (r.status === 1) {
        if (r.assigned_to) {
          return <p style={r.is_urgent_flag ? { color: '#EC5C55' } : {}}>Assigned</p>;
        } else if (r.reply_num > 0) {
          return <p style={r.is_urgent_flag ? { color: '#EC5C55' } : {}}>Pending Assign</p>;
        } else {
          return (
            <p style={r.is_urgent_flag ? { color: '#EC5C55' } : {}}>
              {String(r.status_name)
                .toLowerCase()
                .replace(/( |^)[a-z]/g, (L) => L.toUpperCase())}
            </p>
          );
        }
      } else {
        return (
          <p style={r.is_urgent_flag ? { color: '#EC5C55' } : {}}>
            {String(r.status_name)
              .toLowerCase()
              .replace(/( |^)[a-z]/g, (L) => L.toUpperCase())}
          </p>
        );
      }
    },
  },
  {
    title: 'Medication Issue Type',
    dataIndex: 'consultation_type',
    key: 'consultation_type',
    ellipsis: true,
    width: 400,
    render: (v, r) => {
      if (r.content?.split('\n\n')[0].includes('Other')) {
        return <p style={r.is_urgent_flag ? { color: '#EC5C55',textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' } : {}}>Other</p>;
      } else {
        return (
          <p style={r.is_urgent_flag ? { color: '#EC5C55',textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' } : {}}>
            {r.content?.split('\n\n')[0].split('Describe the issue:')[0]}
          </p>
        );
      }
    },
  },
  {
    title: 'Note',
    dataIndex: 'consultation_type',
    key: 'consultation_type',
    ellipsis: true,
    width: 200,
    render: (v, r) => {
      if (r.content?.split('\n\n')[0].includes('Other:')) {
        return (
          <p
            style={
              r.is_urgent_flag
                ? { color: '#EC5C55', textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }
                : { textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }
            }
          >
            {r.content?.split('\n\n')[0].split('Other:')[1]}
          </p>
        );
      } else {
        return (
          <p
            style={
              r.is_urgent_flag
                ? { color: '#EC5C55', textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }
                : { textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }
            }
          >
            {r.content?.split('\n\n')[0].split('Describe the issue:')[1]}
          </p>
        );
      }
    },
  },
];

const Patient = (props) => {
  const { dispatch, consultation, loading } = props;
  const { data, consultation_types, consultation_statuses, replyData } = consultation;
  const [selected, setSelected] = useState('');
  const [selectedType,setSelectedType]=useState(-1);
  const [visible, setVisible] = useState(false);
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [messageTab, setMessageTab] = useState('0');
  const [currentTab, setCurrentTab] = useState('1');
  const [columns, setColumns] = useState(defaultColumns);
  var filtersEl = createRef(null);
  const [form] = Form.useForm();
  const [showDisputeModal, setShowDisputeModal] = useState(false)
  const [selectedReview, setSelectedReview] = useState({})
  const [disputeData, setDisputeData] = useState({})
  const [disputeVisible, setDisputeVisible] = useState(false);
  const [disputeApproved, setDisputeApproved] = useState(false)

  const disputeColumns = [
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      ellipsis: false,
      width: 200,
      render: (v, r) => {
        return r.provider_comment;
      },
    },
    {
      title: 'Rating Score',
      dataIndex: 'rating',
      key: 'rating',
      ellipsis: false,
      width: 100,
    },
    {
      title: 'Rating Submission Date',
      dataIndex: 'rating_date',
      key: 'rating_date',
      ellipsis: false,
      width: 200,
      render: (v, r) => {
        return <p>{formatUnix(r.rating_date)}</p>;
      },
    },
    {
      title: 'Corresponding Appointment Date',
      dataIndex: 'appointed_at',
      key: 'appointed_at',
      ellipsis: false,
      width: 240,
      render: (v, r) => {
        if(r.appointed_at===0){
          return '';
        }
        return <p>{formatUnix(r.appointed_at)}</p>;
      },
    },
    {
      title: 'Patient Review',
      dataIndex: 'comment',
      key: 'comment',
      ellipsis: false,
      width: 300,
    },
    {
      title: 'Patient',
      dataIndex: 'user_id',
      key: 'user_id',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        if (r == null || r.patient == null) {
          return '';
        }
        const userName = `${r.patient.first_name} ${r.patient.last_name}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.patient.id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/patient/${r.patient.id}`}>{r.patient.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.patient.phone)}
                </div>
                <div className="popover-user-item address">
                  <IconFont className="icon" type="icon-address" />
                  {r.patient.zipcode}
                </div>
                <div className="popover-user-item sex">
                  <IconFont className="icon" type="icon-sex" />
                  {r.patient.gender}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <a href="#" style={r.is_urgent_flag ? { color: '#EC5C55' } : {}}>
              {userName}
            </a>
          </Popover>
        );
      },
    },
    {
      title: 'Clinician',
      dataIndex: 'doctor_id',
      key: 'doctor_id',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        if(!r.doctor){
          return '';
        }
        const userName = `${r.doctor.name} ${r.doctor.title}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/doctor/detail/${r.doctor.id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/doctor/detail/${r.doctor.id}`}>{r.doctor.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.doctor.phone)}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
             <a href="#" >
            {userName}
          </a>
          </Popover>
        );
      },
    },
    {
      title: 'Sent Date',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return <p>{formatUnix(r.created_at)}</p>;
      },
    },
    {
      title: 'State',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        if (r.dispute_status===1){
          return "In-Dispute"
        }else if (r.dispute_status===2){
          return "Approved"
        }else{
          return "Denied"
        }
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 200,
      fixed: 'right',
      render: (v, r) => {
        return (
          r.dispute_status==1 &&
          <Space>
            <Button
              onClick={() => {
                setSelectedReview(r);
                form.setFieldsValue({ comment: '' });
                setShowDisputeModal(true);
              }}
            >
              Deny
            </Button>
  
            <Button
              style={{ marginRight: '5px' }}
              onClick={() => {
                setSelectedReview(r);
                form.setFieldsValue({ comment: '' });
                setShowDisputeModal(true);
              }}
            >
              Approve
            </Button>
          </Space>
        );
      },
    },
  ];

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
    setSelected('');
  };
  const onDisputeClose = () => {
    setDisputeVisible(false);
    setSelectedReview('');
  };
  

  const loadDetail = (v) => {
    setVisible(true);
  };

  const selectRow = (record) => {
    setSelected(record.id);
    setSelectedType(record.consultation_type);
    record.read = true;
    setVisible(true);
    dispatch({ type: 'consultation/fetchConsultationReplies', payload: { id: record.id } });
  };

  const selectDisputeRow = (record) => {
    setSelectedReview(record);
    setSelectedType(record.form_type_name);
    setDisputeVisible(true);
    record.dispute_result_read = true;
  };
  

  const sendMessage = () => { };

  const switchTab = (e) => {
    let column = [];
    if (e.key === '2') {
      column = urgentColumns;
    } else if (e.key === '4') {
      column = solvedColumns;
    }
    setCurrentTab(e.key);
    if (e.key === '5') {
      setColumns(medicationColumns.concat(defaultColumns).filter((item) => item.title != 'Type'));
    } else if (e.key == '6') {
      setColumns(headerColumns.concat(defaultColumns).filter(item => item.title != 'Patient'))
    } else {
      setColumns(headerColumns.concat(defaultColumns).concat(column));
    }
    setPage(1);
    if (e.key=='7'){
      loadDisputeList.run({})
    }else{
      loadList({ status: parseInt(e.key), page: 1, page_size: 20, filters: JSON.stringify(query) }, () => { });
    }
    
  }

  useEffect(() => {
    setPage(1);
    loadList({ status: parseInt(currentTab), page: 1, page_size: 20 }, () => {});
    dispatch({ type: 'consultation/fetchConsultationTypes' });
    dispatch({ type: 'consultation/fetchConsultationStatuses' });
  }, []);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList({ status: parseInt(currentTab), page: 1, page_size: 20, filters: JSON.stringify(q) }, () => {});
  };

  const loadList = (payload, callback) => {
    dispatch({ type: 'consultation/fetchList', payload, callback });
  };

  const loadDisputeList = useRequest(srvGetDisputeReviewList, {
    manual: true,
    onSuccess: (ret, params) => {
      setDisputeData(ret.data)
    }
  })

  const addAdminDisputeResult = useRequest(srvAddDisputeReview, {
    manual: true,
    onSuccess: (ret, params) => {
      setDisputeVisible(false)
      loadDisputeList.run({
        page: page,
        page_size: 10,
        filters: JSON.stringify(query),
      });
    }
  })

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadList({
      status: parseInt(currentTab),
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: JSON.stringify(query),
    });
  };
  const addDisputeResult = () => {
    addAdminDisputeResult.run({survey_result_id:selectedReview.survey_result_id,admin_comment:form.getFieldValue('comment'),dispute_status:disputeApproved})
  };
  

  const handleDisputeTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadDisputeList.run({
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: JSON.stringify(query),
    });
  };
  

  const f = () => {
    for (const v of filters) {
      if (v.name === 'consultation_type' && v.items.length === 0) {
        v.items = consultation_types;
        continue;
      }
      if(v.name == 'status' && v.items.length===0) {
        var temp=[
          {"value":11,"label":"OPEN"},{"value":2,"label":"SOLVED"},{"value":3,"label":"CLOSED"},{"value":9,"label":"ASSIGNED"},{"value":10,"label":"PENDING ASSIGN"}
        ]
        v.items =temp;
        continue;
      }
    }
    return filters;
  };

  const reloadReplyData = () => {
    loadList({ status: parseInt(currentTab), page: 1, page_size: 20, filters: JSON.stringify(query) }, () => {});
    dispatch({
      type: 'consultation/fetchConsultationReplies',
      payload: { id: replyData.consultation.id },
    });
  }

  const disputeFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadDisputeList.run({
      page: page,
      page_size: 10,
      filters: JSON.stringify(q),
    });
  };

  return (
    <Layout>
      <Sider theme="light">
        <Menu selectedKeys={[currentTab]} onClick={switchTab}>
          <Menu.Item key="1">Inbox</Menu.Item>
          <Menu.Item key="2">Urgent</Menu.Item>
          <Menu.Item key="3">Provider Inbox</Menu.Item>
          <Menu.Item key="7">Patient Review Dispute</Menu.Item>
          <Menu.Item key="4">Solved</Menu.Item>
          <hr style={{ marginLeft: '3px', marginRight: '3px' }} />
          <Menu.Item key="5">Medication Issue</Menu.Item>
          <Menu.Item key="6">Availability Management</Menu.Item>
        </Menu>
      </Sider>
      <Content theme="light">
        <div className={cx({ container: true })}>
          <div className={cx({ header: true })}>
            {currentTab != '7' && (
              <>
                <h3>Consultations</h3>
                <Space>
                  {data && data.unreply_total > 0 && (
                    <a
                      onClick={() => {
                        filtersEl.current.setFilters({ reply_num: 0 });
                      }}
                    >
                      {currentTab === '5' ? data.total : data.unreply_total} Tickets
                    </a>
                  )}
                  <Filters
                    ref={filtersEl}
                    filters={f()}
                    onFilter={onFilter}
                    defaultFilters={defaultFilters}
                  />
                </Space>
              </>
            )}
            {currentTab == '7' && (
              <>
                <h3>Patient Review Disputes</h3>
                <Space>{<a>{disputeData.total} Disputes</a>}
                <Filters
                    ref={filtersEl}
                    filters={disputeFilters}
                    onFilter={disputeFilter}
                    defaultFilters={defaultFilters}
                  /></Space>
              </>
            )}
          </div>

          {selected != '' && (
            <ConsultationDrawer
              role="admin"
              visible={visible}
              replyData={replyData}
              onClose={onClose}
              selectedType={selectedType}
              onReply={() => {
                reloadReplyData();
              }}
            />
          )}

          {selectedReview != '' && (
            <Drawer
              className={cx({ historyDrawer: true })}
              title="Patient Review Dispute"
              width="600"
              placement="right"
              closable={false}
              onClose={onDisputeClose}
              visible={disputeVisible}
            >
              <>
                {selectedReview && selectedReview.dispute_status == 1 && (
                  <div style={{ height: 'calc(100% - 200px)', overflow: 'auto' }}>
                    <p>Patient Name: {selectedReview.patient && selectedReview.patient.name}</p>
                    <p>Patient Email: {selectedReview.patient && selectedReview.patient.email}</p>
                    <br />
                    <Descriptions
                      title=""
                      layout="vertical"
                      column={1}
                      size="small"
                      bordered={true}
                    >
                      <Descriptions.Item label="Patient Review">
                        {selectedReview.comment}
                      </Descriptions.Item>
                      <br />
                      <Descriptions.Item label="Dispute Reason">
                        {selectedReview.provider_comment}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                )}
                {selectedReview && selectedReview.dispute_status != 1 && (
                  <div style={{ overflow: 'auto' }}>
                    <p>Patient Name: {selectedReview.patient && selectedReview.patient.name}</p>
                    <p>Patient Email: {selectedReview.patient && selectedReview.patient.email}</p>
                    <br />
                    <Descriptions
                      title=""
                      layout="vertical"
                      column={1}
                      size="small"
                      bordered={true}
                    >
                      <Descriptions.Item label="Patient Review">
                        {selectedReview.comment}
                      </Descriptions.Item>
                      <br />
                      <Descriptions.Item label="Dispute Reason">
                        {selectedReview.provider_comment}
                      </Descriptions.Item>

                      <Descriptions.Item label="Admin Comment">
                        {selectedReview.admin_comment}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                )}

                {selectedReview && selectedReview.dispute_status == 1 && (
                  <Form layout="vertical" form={form} onFinish={addDisputeResult}>
                    <div
                      style={{
                        position: 'fixed',
                        bottom: 0,
                        background: 'white',
                        height: '200px',
                        width: '550px',
                      }}
                    >
                      <Form.Item
                        name={'comment'}
                        label="Note:"
                        style={{ marginLeft: 'auto', marginRight: 'auto', width: '550px' }}
                      >
                        <Input.TextArea rows={3} style={{ width: '550px' }} maxLength={20000} />
                      </Form.Item>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                          type="link"
                          onClick={() => {
                            setDisputeApproved(false);
                            form.submit();
                          }}
                          class="right-button"
                        >
                          Deny
                        </Button>
                        <Button
                          type="primary"
                          onClick={() => {
                            setDisputeApproved(true);
                            form.submit();
                          }}
                          class="right-button"
                        >
                          Approve
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </>
            </Drawer>
          )}

          {currentTab != '7' && (
            <Table
              columns={columns}
              dataSource={data.items}
              size={'small'}
              scroll={{ x: 800 }}
              rowClassName={(r) => {
                if (r.id == selected) {
                  return 'rowSelected';
                  // return styles.rowSelected;
                }
                if (r.read == false && r.assigned_to == null && !r.is_urgent_flag) {
                  return styles.rowUnread;
                }
                if (r.is_urgent_flag) {
                  return styles.urgent;
                }
                return '';
              }}
              onRow={(record) => ({
                onClick: () => {
                  selectRow(record);
                },
              })}
              rowKey="id"
              loading={loading.effects['consultation/fetchList']}
              onChange={handleTableChange}
              pagination={{ pageSize: 20, current: page, simple: true, total: data.total }}
            />
          )}
          {currentTab == '7' && (
            <Table
              columns={disputeColumns}
              dataSource={disputeData.items}
              size={'small'}
              scroll={{ x: 800 }}
              rowClassName={(r) => {
                if (selectedReview && r.id == selectedReview.id) {
                  return 'rowSelected';
                  // return styles.rowSelected;
                }
                if (r.dispute_result_read == false) {
                  return styles.rowUnread;
                }
                return '';
              }}
              onRow={(record) => ({
                onClick: () => {
                  selectDisputeRow(record);
                },
              })}
              rowKey="id"
              loading={loadDisputeList.loading}
              onChange={handleDisputeTableChange}
              pagination={{ pageSize: 10, current: page, simple: true, total: disputeData.total }}
            />
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default connect(({ consultation, loading }) => ({
  consultation,
  loading,
}))(Patient);
