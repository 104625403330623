import React, {useCallback, useState} from 'react';
import {Input, Tag, Tooltip} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { useRequest, useMount, useUpdateEffect } from 'ahooks';

import { debounce } from '@/utils/utils';

import { formatAppointmentAt } from '../../utils/xtime';

import { srvFetchAllAppointments } from '@/services/patient';

import styles from './index.less';
import cn from 'classnames/bind';

import moment from 'moment-timezone';

const cx = cn.bind(styles);

const AppointmentsList = (props) => {
  const { onSelect } = props
  const [appointments, setAppointments] = useState([]);

  const debouncedSearch = useCallback(
		debounce(nextValue => search(nextValue), 1000),
		[], // will be created only once initially
	);

  const onSearch = (e) => {
    const value = e.target.value;
    debouncedSearch(value)
  };

  const search = (q) => {
    loadAppointmentsReq.run({
      query: q
    })
  }

  const loadData = () => {
    loadAppointmentsReq.run()
  }

  const loadAppointmentsReq = useRequest(srvFetchAllAppointments, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setAppointments(ret.data);
      }
    },
  })
  useMount(()=>{
    loadData()
  })

  const appointmentsDom = (appointments) => {
    return (
      <div className={cx({ list: true })}>
      {
        appointments.map((p)=>{
          return (

              <a className={cx({appointmentItem: true, active: props.selectedID == p.patient.id, timeSlot: p.admin_flag })} key={p.id} onClick={ () => { onSelect(p.patient.id) } }>
                {p.admin_flag ? (
                    <Tooltip
                        title={<div>{p.admin_flag && <p>schedule by: {p.action_user_name}</p>}</div>}
                        placement="bottom"
                    >
                      <p className={cx({patientName: true})}>{p.patient.name}</p>
                      <p className={cx({appointmentTime: true})}>{formatAppointmentAt(p)}</p>
                    </Tooltip>
                ) : (
                    <>
                      <p className={cx({patientName: true})}>{p.patient.name}</p>
                      <p className={cx({appointmentTime: true})}>{formatAppointmentAt(p)}</p>
                    </>
                )}
              </a>
                
          )
      })
      }
      </div>
    )
  }

  return (
    <div className={cx({ appointmentsList: true })}>
      <div className={cx({ searchBar: true })}>
        <Input prefix={<SearchOutlined />} bordered={false} onChange={onSearch} />
      </div>
      { appointmentsDom(appointments) }
    </div>
  )
}

export default AppointmentsList