import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox, InputNumber } from 'antd';

import { useMount, useUpdateEffect, useSetState } from 'ahooks';
import ExclusiveCheckboxWithTextInput from './ExclusiveCheckboxWithTextInput';

import { moodHxItems } from './meta';

const { TextArea } = Input;

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

const MoodFormItems = (props) => {
  const { form, draft, setDraft } = props;

  // useUpdateEffect(() => {
  // }, [draft]);

  const onItemChange = (e, name, exclusive, items = []) => {
    if (e.target.checked) {
      let fields = {}
      items.forEach(v => {
        if (exclusive) {
          fields[v.value] = v.exclusive ? true : false;
          if (v.hasInput) fields[`${v.value}_text`] = '';
        } else {
          if (v.value == name) fields[v.value] = true;
          if (v.exclusive) fields[v.value] = false;
        }
      })
      if (Object.keys(fields).length > 0) {
        form.setFieldsValue(fields);
        setDraft(Object.assign({ ...draft }, fields));
      }
    }
  }

  return (
    <>
      <Form.Item
        className={cx({ 'horizontalLayout': true })}
        labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
        name="mood_irritability"
        label={'Irritability (rate 1-10)'}
      >
        <InputNumber min={1} max={10} style={{ width: '50%' }} />
      </Form.Item>
      <Form.Item
        className={cx({ 'horizontalLayout': true })}
        labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
        name="mood_impulsivity"
        label={'Impulsivity (rate 1-10)'}
      >
        <InputNumber min={1} max={10} style={{ width: '50%' }} />
      </Form.Item>
      <Form.Item
        className={cx({ 'horizontalLayout': true })}
        labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
        name="mood_depression"
        label={'Depression (rate 1-10)'}
      >
        <InputNumber min={1} max={10} style={{ width: '50%' }} />
      </Form.Item>

      <h4>Hx of cycling/grandiosity/hypersexuality/energy with lack of sleep</h4>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px' }} >
        {moodHxItems.map((v, index) => {
          return (
            <div key={index} style={{ flex: '0 1 auto', padding: '2px 20px 2px 0px' }}>
              <ExclusiveCheckboxWithTextInput name={v.value} label={v.label} exclusive={v.exclusive} hasInput={v.hasInput}
                onChange={(e) => onItemChange(e, v.value, v.exclusive, moodHxItems)} form={form} draft={draft} setDraft={setDraft} />
            </div>
          )
        })}
      </div>

      <Form.Item
        className={cx({ 'horizontalLayout': true })}
        labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
        name="mood_anxiety_level"
        label={'Anxiety level (rate 1-10)'}
      >
        <InputNumber min={1} max={10} style={{ width: '50%' }} />
      </Form.Item>
      <Form.Item
        name="mood_sleep_quality"
        label={'Sleep'}
      >
        <TextArea rows={3} maxLength={1000} />
      </Form.Item>
      <Form.Item
        name="mood_appetite"
        label={'Appetite'}
      >
        <TextArea rows={3} maxLength={1000} />
      </Form.Item>
      <Form.Item
        name="mood_overall_impression"
        label={'Overall impression'}
      >
        <TextArea rows={3} maxLength={1000} />
      </Form.Item>
    </>
  )
};

export default MoodFormItems;
