import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link } from 'umi';
import { Row, Col, Form, Divider, Select, Popover, Input, Tabs, Modal, Button, Switch } from 'antd';
import moment from 'moment';
import { SearchOutlined, createFromIconfontCN } from '@ant-design/icons';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';

import AppointmentQuestionDrawer from '@/components/AppointmentQuestionDrawer';

import { srvUpdateSettings } from '../../services/account';
import styles from './settings.less';
import { timeZones } from '../../utils/xtime';

import { useFlag } from '@unleash/proxy-client-react';

const { TabPane } = Tabs;
const cx = cn.bind(styles);

const Settings = (props) => {
  const { dispatch, account, loading } = props;
  const [form] = Form.useForm();
  const data = {};
  const [showAppointmentQuestion, setShowAppointmentQuestion] = useState(false);

  const updateSettingsReq = useRequest(srvUpdateSettings, {
    manual: true,
    onSuccess: (ret, params) => {
      dispatch({
        type: 'account/fetchCurrent',
        callback: (data) => {},
      });
    },
  });

  const { currentUser } = account;

  let enableAppointmentQuestion = useFlag('ehr_enable_appointment_question');

  const onSave = (values) => {
    updateSettingsReq.run({ ...values });
  };

  const cusLayout = {
    // labelCol: { span: 8 },
    wrapperCol: { span: 6 },
  };
  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Settings</h3>
      </div>
      {currentUser && currentUser.id && (
        <Form
          {...cusLayout}
          layout="horizontal"
          form={form}
          initialValues={currentUser}
          onFinish={onSave}
        >
          <Form.Item
            name="timezone"
            label="Timezone"
            layout="vertical"
            rules={[
              {
                required: true,
                message: 'Timezone is required',
              },
            ]}
          >
            <Select>
              {timeZones.map(([value, display], index) => (
                <Select.Option value={value} key={index}>
                  {display}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* <EmailNotification /> */}
          <Form.Item
            label="Email notifications"
            name="notifiable"
            valuePropName="checked"
            style={{ margin: 0 }}
          >
            <Switch checkedChildren="on" unCheckedChildren="off" />
          </Form.Item>
          <div className={cx({ emailNotificationExtra: true })}>
            You will get email notifications when patients message you.
          </div>

          <Form.Item
            label="Appointments within 12 hours"
            name="allow_appointment_within12h"
            valuePropName="checked"
            style={{ margin: 0 }}
          >
            <Switch checkedChildren="on" unCheckedChildren="off" />
          </Form.Item>
          <div className={cx({ emailNotificationExtra: true })}>
            When you turn on, patients can book an appointment with you within 12 hours. However
            appointments within 4 hours are not allowed.
          </div>
          <Divider />
          <Form.Item
            label="I am open to receiving transfer patients"
            name="accept_transfer_patients"
            valuePropName="checked"
            style={{ margin: 0 }}
          >
            <Switch checkedChildren="on" unCheckedChildren="off" />
          </Form.Item>
          <div className={cx({ emailNotificationExtra: true })}>
            When this toggle is on, new transfer patients will be automatically assigned to you.
            Transfer bonus will be awarded after you fulfill the prescription renewal request of a transfer patient.
          </div>

          { enableAppointmentQuestion && 
            <>
              <Divider/>

              <Form.Item
                label="Accept async video follow-up appointment"
                name="accept_async_follow_up_appointment"
                valuePropName="checked"
                style={{ margin: 0 }}
              >
                <Switch checkedChildren="on" unCheckedChildren="off" />
              </Form.Item>
              <div className={cx({ emailNotificationExtra: true })}>
                When this toggle is on, your patients can either make sync follow-up appointments or async follow-up appointments by uploading follow-up videos.
              </div>

              <div className={cx({ btnBox: true })}>
                <Button onClick={()=>{ setShowAppointmentQuestion(true) }} loading={updateSettingsReq.loading}>
                  Customize Your Questionnaire
                </Button>
              </div>
            </>
          }

          <div className={cx({ btnBox: true })}>
            <Button type="primary" htmlType="submit" loading={updateSettingsReq.loading}>
              Save Changes
            </Button>
          </div>
        </Form>
      )}
      { showAppointmentQuestion && <AppointmentQuestionDrawer onClose={()=> { setShowAppointmentQuestion(false) }} /> }
    </div>
  );
};

export default connect(({ account, loading }) => ({
  account,
  loading,
}))(Settings);
