import React, { useState, useEffect } from 'react';
import { List, Button, Popover } from 'antd';
import moment from 'moment';
import DataSorter from '@/components/DataSorter';
import orderBy from 'lodash/orderBy';
import { ProviderDashboardSection, PatientLink } from './index';
import { srvGetMedicalFileRequests } from '@/services/patient';
import { MedicalFileDrawer, MedicalRequestApproveModal, MedicalFileRevisionModal } from "../ehr_medical_files"
import { createFromIconfontCN, EnvironmentFilled, CalendarFilled } from '@ant-design/icons';
import { history,Link,useSelector,connect } from 'umi';

import defaultSettings from '../../../defaultSettings';
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const MedicalFileItem = (props) => {
  const { medicalFile, selectMedicalFile, setSelectedMedicalFile, setShowDrawer, setShowApproveModal } = props; 

  let patientInfo = medicalFile.patient

  if(!patientInfo) {
    return <></>
  }

  return (
    <div className="detail-table-item" onClick={(e) => {
      selectMedicalFile(medicalFile.id);
    }}>
      <div className="medical-file-patient-name">
        <Popover
          content={
            <div className="popover-user">
              <div className="popover-user-item username">
                <IconFont className="icon" type="icon-username" />
                <Link to={`/patient/${patientInfo.id}`}>{patientInfo.name}</Link>
              </div>
              <div className="popover-user-item phone">
                <IconFont className="icon" type="icon-phone" />
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setCallTarget('patient');
                    setVisible('callReason', true);
                    setCallPatient(patientInfo);
                  }}
                >
                  Call Patient
                </Button>
              </div>
              <div className="popover-user-item birthdate">
                <CalendarFilled className="icon" />
                {patientInfo.birthdate &&
                  moment.unix(patientInfo.birthdate).utc().format('MM/DD/YYYY')}
              </div>
              <div className="popover-user-item sex">
                <IconFont className="icon" type="icon-sex" />
                {patientInfo.gender}
              </div>
              <div className="popover-user-item state">
                <EnvironmentFilled className="icon" />
                {patientInfo.state}
              </div>
            </div>
          }
          title={patientInfo.name}
          trigger="hover"
        >
          <Link to={`/patient/${patientInfo.id}`}>
            <div>{patientInfo.name}</div>
          </Link>
        </Popover>
      </div>
      <div className='medical-file-status'>
        {medicalFile.status_name}
      </div>
      <div className='medical-file-file-type'>
        {medicalFile.file_type_name}
      </div>
      <div className="medical-file-date">{moment.unix(medicalFile.created_at).fromNow()}</div>
      <div className='medical-file-actions'>
        <Button style={{marginRight: '10px'}} onClick={()=>{
            setSelectedMedicalFile(medicalFile)
            setShowDrawer(true)
          }}>View</Button>
          {
            medicalFile.status == 2 && 
              <Button type="primary" onClick={()=>{
                setSelectedMedicalFile(medicalFile)
                setShowApproveModal(true)
              }}>Approve</Button>
          }
      </div>
    </div>
  );
};

const MedicalFilesList = (props) => {
  const { medicalFilesList, setSelectedMedicalFile, setShowApproveModal, onRefresh, setShowDrawer } = props;
  const [visible, setVisible] = useState(true);
  const [selected, setSelected] = useState('');
  const [sorter, setSorter] = useState(null);

  const selectMedicalFile = (id) => {
    // srvPatientConsultationReplies({
    //   id: id,
    // })
    //   .then((res) => {
    //     setReplyData(res.data);
    //     setSelected(id);
    //     setVisible(true);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  };

  const onClose = () => {
    setVisible(false);
    //setReplyData({});
    setSelected('');
  };

  const renderItem = (medicalFile) => {
    return (
      <MedicalFileItem
        selectMedicalFile={selectMedicalFile}
        setSelectedMedicalFile={setSelectedMedicalFile}
        setVisible={setVisible}
        setShowDrawer={setShowDrawer}
        key={medicalFile.id}
        medicalFile={medicalFile}
        setShowApproveModal={setShowApproveModal}
      />
    )
  };

  return (
    <>
      <List
        itemLayout="vertical"
        style={{overflowX: 'auto',overflowY:"hidden"}}
        pagination={medicalFilesList.length > 0 ? { pageSize: 10 } : false}
        locale={{ emptyText: ' ' }}
        dataSource={medicalFilesList}

        header={
          <div className="detail-table-header">
            <div className="medical-file-patient-name">Patient</div>
            <div className="medical-file-status">
              Status
            </div>
            <div className="medical-file-file-type">File Type</div>
            <div className="medical-file-date">Created At</div>
            <div className='medical-file-actions'></div>
          </div>
        }
        renderItem={renderItem}
      />
      {selected != '' && (
        <></>
      )}
    </>
  );
};

const MeidcalFiles = (props) => {
  const [medicalFiles, setMedicalFiles] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false)
  const [selectedMedicalFile, setSelectedMedicalFile] = useState({})
  const [showApproveModal, setShowApproveModal] = useState(false)
  const [showRevisionModal, setShowRevisionModal] = useState(false)

  const refresh = () => {
    srvGetMedicalFileRequests({
      page: 1,
      page_size: 10,
      filters: JSON.stringify({status: [2]}),
    }).then((res) => {
      if (res.data !== null) {
        setMedicalFiles(res.data.items);
      }
    });
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <>
      <ProviderDashboardSection
        sectionName="Medical File Requests"
        itemNumber={medicalFiles.length}
        viewAllUrl={"/ehr_medical_files"}
      >
        <MedicalFilesList
          onRefresh={refresh}
          medicalFilesList={medicalFiles}
          setSelectedMedicalFile={setSelectedMedicalFile}
          setShowDrawer={setShowDrawer}
          setShowApproveModal={setShowApproveModal}
        ></MedicalFilesList>
        {showDrawer && <MedicalFileDrawer
                 onClose={()=>{ setShowDrawer(false); setSelectedMedicalFile({})}}
                 medicalFile={selectedMedicalFile}
                 showApproveModal={()=>{ setShowApproveModal(true)}}
                 showRevisionModal={()=>{ setShowRevisionModal(true)}}
                 />}
        {showApproveModal &&      <MedicalRequestApproveModal
                medicalFile={selectedMedicalFile}
                showApproveModal={showApproveModal}
                closeDrawer={()=>{ setShowDrawer(false) }}
                closeModal={()=>{ setShowApproveModal(false)}}
                refreshList={refresh}
              />}
        {showRevisionModal &&       <MedicalFileRevisionModal
        medicalFile={selectedMedicalFile}
        showRevisionModal={showRevisionModal}
        closeDrawer={()=>{ setShowDrawer(false) }}
        closeModal={()=>{ setShowRevisionModal(false)}}
        refreshList={refresh}
      />}
      </ProviderDashboardSection>
    </>
  );
};

export default MeidcalFiles;
