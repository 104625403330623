import React, {useState} from 'react';
import { Radio, Space, Row, Col, Modal, Select, Descriptions, Input, Form } from 'antd';
import cn from 'classnames/bind';
import numeral from 'numeral';

import styles from './index.less';
import { relativeTimeRounding } from 'moment';
const cx = cn.bind(styles);

const RemoveTreatmentRequestModal = ({ treatmentRequest, onOk, ...modalProps }) => {
  const [removeReason, setRemoveReason] = useState();
  const [removeNote, setRemoveNote] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleOk = () => {
      if (![1,2,3].includes(removeReason)){
          setErrorMessage("Please select a remove reason");
          return
      } else if (removeReason === 3 && removeNote.length == 0){
          setErrorMessage("If choosing \"other\", please specify a reason ");
          return
      } else {
          // remove reason validated
          onOk(removeReason, removeNote);
      }
  };

  const removeReasonOnChange = e => {
    setErrorMessage("");
    setRemoveReason(e.target.value);
  };

  const removeNoteOnChange = e => {
      setErrorMessage("");
    setRemoveNote(e.target.value);
  };

  const modalOpts = {
    ...modalProps,
    onOk: handleOk,
  };

  return (
    <Modal {...modalOpts} destroyOnClose = {true} className={cx({ removeTreatmentRequestModal: true })}>
      <Radio.Group onChange={removeReasonOnChange} value = {removeReason}>
        <Space direction="vertical">
          <Radio value={1}>Expired (from long time ago)</Radio>
          <Radio value={2}>Not needed (new prescription/duplicated request)</Radio>
          <Radio value={3}>Other</Radio>
          {removeReason === 3 ? <Input placeholder="Please specify..." onChange={removeNoteOnChange} value = {removeNote}/> : null}
        </Space>
      </Radio.Group>
      {errorMessage.length > 0 && <p style={{color: 'red'}}>{errorMessage}</p>}
    </Modal>
  );
};

export default RemoveTreatmentRequestModal;
