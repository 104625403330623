import React, { useRef, useState, useEffect, useMemo } from 'react';
import { AutoComplete, Button, Form, Input, Checkbox, Radio, Select, Tooltip, Modal, Row, Col } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';
import { useRequest, useMount, useUpdate, useLocalStorageState,useUnmount } from 'ahooks';
import moment from 'moment';
import { useFlag } from '@unleash/proxy-client-react';
import _ from 'lodash'
import cn from 'classnames/bind';
import styles from './index.less';
import CPTCodesFormItem from '@/components/CPTCodesFormItem';
import LastRefillDateFormItem from '@/components/LastRefiiDateFormItem';
import NextFollowUpDateFormItem from '@/components/NextFollowUpDateFormItem';
import { getNoteTemplatesForPatient } from '@/utils/utils';
import { AppointmentResponseContent } from '@/components/AppointmentQuestionResponseDrawer'
import { formatUnix } from '@/utils/xtime.js';
import {
  otherReason,
  completedOpts,
  initialCompletedOpts,
  getRealReason,
  noDiagnoseReason,
  seenPatientOptions,
} from './meta';

import isEqual from 'lodash/isEqual';

import {
  srvAddTreatmentPlan,
  srvMarkNoDiagnosis,
  srvApproveTreatmentRequest,
  srvPatientTreatmentPlan,
  srvUserFeatures,
  srvMarkAppointmentToBeTransfer,
  srvMarkAppointmentNoShow,
  srvEditPatientTreatmentPlanHistory,
  srvPatientDraftTreatmentPlan,
  srvAddDraftTreatmentPlan,
  srvPatientDetail,
  srvRxntPrescriptionHistory
} from '@/services/patient';
import { srvApproveTreatmentRequest as srvAdminApproveTreatmentRequest } from '@/services/admin';

const cx = cn.bind(styles);
let exitFormValues = {};
let exitInitialValues = {};
let exitSubmit = false;

const MultiNotesForm = (props) => {
  const {
    form,
    admin,
    patient,
    providerNote,
    prescriptionInfo,
    appointmentResponse,
    appointment,
    addNoteType,
    sourceAction,
    RXNTCallbackLink,
    setNoteVisible,
    onRefresh,
    urgentRefillFlag,
    originName,
  } = props;

  let flagDraftClinicianNote = useFlag('draft_clinician_note');
  const [isNoDiagnosed, setIsNoDiagnosed] = useState(false);
  const [isSeenPatient, setIsSeenPatient] = useState(true);
  const [isShowConfirmTransfer, setIsShowConfirmTransfer] = useState(false);
  const [isNotRightPatient, setIsNotRightPatient] = useState(false);
  const [showMoreReason, setShowMoreReason] = useState(false);
  const [treatmentPlan, setTreatmentPlan] = useState(null);
  const [draft, setDraft] = useState({});
  const [showNoDiagnoseOption, setShowNoDiagnoseOption] = useState(false);
  const [submitLabel, setSubmitLabel] = useState('Sign and Submit');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isDraft, setIsDraft] = useState(true);
  const [autoSaveTimer, setAutoSaveTimer] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [patientInfo, setPatientInfo] = useState({})
  const draftNoteID = React.useRef(0);
  const update = useUpdate();
  const flagLockClinicianNote = useFlag('lock_clinician_note');
  const flagNextFollowUpDate = useFlag('enable_next_follow_up_date');
  const [showUrgentRefillRemider, setShowUrgentRefillRemider] = useState(false);
  const [loading, setLoading] = useState(false)
  const treatmentPlanReq = useRequest(srvPatientTreatmentPlan, {
    manual: true,
    onSuccess: (ret) => {
      setTreatmentPlan(ret.data);
      fillData(draft, ret.data);
    },
  });

  const markNoDiagnosisReq = useRequest(srvMarkNoDiagnosis, {
    manual: true,
    onSuccess: () => {
      setDraft({});
      setNoteVisible(false);
      onRefresh();
    },
  });

  const markAppointmentToBeTransfer = useRequest(srvMarkAppointmentToBeTransfer, {
    manual: true,
    onSuccess: () => {
      setDraft({});
      setNoteVisible(false);
      onRefresh();
    },
  });

  const addNoteReq = useRequest(srvAddTreatmentPlan, {
    manual: true,
    onSuccess: (ret) => {
        setDraft({});
        setNoteVisible(false);
        // when no request Pending status & providerNote == Refill & addNoteType = approvePrescriptionWithoutRequest & sourceAction = PrescribeInRXNT
        if (
          (providerNote == 'Refill' || providerNote == 'PlainRefill') &&
          sourceAction === 'PrescribeInRXNT' &&
          addNoteType === 'approvePrescriptionWithoutRequest' &&
          patientInfo.country != 'ca'
        ) {
          let link = `/api/ehr/rxnt/sso?return_url=${Buffer.from(window.location.href).toString(
            'base64',
          )}&patient_id=${patient.id}&order_type=1`;
          if (RXNTCallbackLink) link = RXNTCallbackLink;
          window.location.href = link;
        } else {
          onRefresh();
        }
    },
  });

  const editHistoryReq = useRequest(srvEditPatientTreatmentPlanHistory, {
    manual: true,
    onSuccess: (ret) => {
      if (!isDraft){
        setDraft({});
        onRefresh();
        setNoteVisible(false);
      }
    },
  });


  const getDraftReq = useRequest(srvPatientDraftTreatmentPlan, {
    manual: true,
    onSuccess: (ret) => {
        if(ret.data){
          const data = ret.data
          data.struct_note_data = ret.data.StructNoteCommonData
          const diagnostic_code = ret.data.diagnostic_code.split(";")
          data.diagnostic_code = diagnostic_code
          delete data.next_follow_up_date
          delete data.next_follow_up_month
          delete data.last_refill_date
          setDraft(data);
          draftNoteID.current=ret.data.id
          // update();
        }else{
          treatmentPlanReq.run({ id: patient.id });
        }
    },
  });

  const addDraftNoteReq = useRequest(srvAddDraftTreatmentPlan, {
    manual: true,
    onSuccess: (ret) => {
      const splitArray = ret.data.split(', ');
      draftNoteID.current = splitArray[1];
    },
  });

  const approveTreatmentRequestReq = useRequest(
    admin ? srvAdminApproveTreatmentRequest : srvApproveTreatmentRequest,
    {
      manual: true,
      onSuccess: () => {
        setDraft({});
        // when request Pending status & sourceAction in (PendingPrescriptionRenewal, PrescriptionMedications, PrescribeInRXNT)
        if (
          (prescriptionInfo.status == 1 || (prescriptionInfo.status == 5 && prescriptionInfo.requested_at <= moment().unix()) || prescriptionInfo.status == 8) &&
          ['PendingPrescriptionRenewal', 'PrescriptionMedications', 'PrescribeInRXNT'].includes(sourceAction) &&
          patientInfo.country != 'ca'
        ) {
          setNoteVisible(false);
          if (!urgentRefillFlag) {
            let link = `/api/ehr/rxnt/sso?return_url=${Buffer.from(window.location.href).toString('base64')}&patient_id=${patient.id}&order_type=2&treatment_request_id=${prescriptionInfo.id}`;
            if (RXNTCallbackLink) link = RXNTCallbackLink;
            window.location.href = link;
          } else {
            let link = `/api/ehr/rxnt/sso?return_url=${Buffer.from(window.location.href).toString('base64')}&patient_id=${patient.id}&order_type=2&treatment_request_id=${prescriptionInfo.id}`;
            window.location.href = link;
          }
        } else {
          setNoteVisible(false);
          onRefresh();
        }
      },
    },
  );

  useEffect(() => {
    if (providerNote == 'PlainRefill') {
      setDraft({});
      form.resetFields()
    }

    if (flagDraftClinicianNote){
      // setDraft({})
      // form.resetFields()
      // clearInterval(autoSaveTimer);
      draftNoteID.current = 0
      var note_type;
      if (providerNote == 'FollowUp') {
        note_type = 2;
      } else if (providerNote == 'Refill') {
        note_type = 3;
      } else if (providerNote == 'PlainRefill') {
        note_type = 8;
      } else if (providerNote == 'AsyncVideoFollowUp') {
        note_type = 9;
      } else if (providerNote == 'CoachingNote') {
        note_type = 10;
      } else if (providerNote == 'TransferOfCare') {
        note_type = 11;
      }
      if (urgentRefillFlag) {
        note_type = 7; // Coverage Note
      }
      const data = { patient_id: patient.id, note_type: note_type };
      getDraftReq.run({ ...data });
    }

    if (!flagDraftClinicianNote && patient && patient.id) {
      treatmentPlanReq.run({ id: patient.id });
    }
  }, [patient, providerNote]);

  useUnmount(() => {
    if (flagDraftClinicianNote) {
      if (!exitSubmit && formValues && Object.keys(formValues).length > 0 && !isEqual(initialValues, formValues)) {
        setDraft(formValues);
        form.validateFields()
          .then(() => {
            setIsDraft(true);
            onAddNote(formValues);
          })
      }
    }
  });

  useEffect(() => {
    form.setFieldsValue(draft);
    if(!patient.provider_name){
      loadPatientInfo.run({id: patient.id});
    }
    // update();
  }, [draft, form]);

  useMount(() => {
    const initial = form.getFieldsValue();
    setInitialValues(initial);
    exitInitialValues = initial;
  });

  const handleClose = (e) => {
    if (flagDraftClinicianNote) {
      if (!exitSubmit && exitFormValues && Object.keys(exitFormValues).length > 0 && !isEqual(exitInitialValues, exitFormValues)) {
        e.returnValue = 'Are you sure you want to leave?';
      }
    }
  };


  const loadPatientInfo = useRequest(srvPatientDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setPatientInfo(ret.data);
        return ret.data;
      }
    },
  });

  const fillData = async (draft, treatmentPlan) => {
    const hasDraft = draft && draft.hasOwnProperty('notes') && draft.hasOwnProperty('msg');
    const hasTreatmentPlan = treatmentPlan && treatmentPlan.id;
    const template = getNoteTemplatesForPatient(patient)
    const tmpl = template[providerNote] || {};

    let provider_notes = tmpl.provider_notes
    if (tmpl){
      if (providerNote === 'FollowUp' || providerNote === 'TransferOfCare' || providerNote == 'AsyncVideoFollowUp'){
        const prescriptionHistory = await srvRxntPrescriptionHistory({ page: 1,
          page_size: 10,
          filters: JSON.stringify({ user_id: patient.id })});
        provider_notes = _.template(tmpl.provider_notes)({
          first_name: patient.first_name,
          age: moment().diff(moment.unix(patient.birthdate), 'years'),
          gender: patient.gender,
          pre_prescription: _.isEmpty(prescriptionHistory.data?.items) ? '' : prescriptionHistory.data?.items.filter(item=>moment.unix(item.start_date).isSame(moment.unix(prescriptionHistory.data?.items[0].start_date),'day') ).map(item=>item.drug.name).join('、'),
          appointment_date: appointment?.appointed_at ? formatUnix(appointment?.appointed_at, 'MM/DD/YYYY') : 'MM/DD/YYYY',
          provider_name_with_title: patient?.provider_name ? `${patient?.provider_name} ${patient?.provider_title}`: patientInfo?.provider_name ? `${patientInfo?.provider_name} ${patientInfo?.provider_title}`: '',
        })
      }
    }

    let notes = '';
    let msg = '';

    if (['approvePrescriptionRequest', 'approvePrescriptionWithoutRequest'].includes(addNoteType)) {
        setShowNoDiagnoseOption(false);
        if (patientInfo && patientInfo.country && patientInfo.country === 'ca') {
          setSubmitLabel('Sign and Submit');
        } else if (prescriptionInfo?.status !== 2) {
          setSubmitLabel('add notes and prescribe in RXNT');
        }

      // if (prescriptionInfo.status != 2 && patientInfo.country != 'ca') {
      //   setSubmitLabel('add notes and prescribe in RXNT');
      // }

      if (hasTreatmentPlan) {
        if(tmpl.message_to_patient){
          treatmentPlan.message_to_patient = tmpl.message_to_patient.replace(
            'DONE_FIRST_NAME',
            patient ? patient.first_name : '',
          );
        }
        treatmentPlan.provider_notes = provider_notes;
      }
    }

    if (hasTreatmentPlan && patient.doctor_name == treatmentPlan.doctor_name) {
      notes = hasDraft ? draft.notes : treatmentPlan.provider_notes;
      msg = hasDraft ? draft.msg : treatmentPlan.message_to_patient;
    } else {
      notes = hasDraft ? draft.notes : provider_notes;
      msg = hasDraft
        ? draft.msg
        :  _.template(tmpl.message_to_patient)({
          first_name: patient?.first_name,
          provider_name: patient?.provider_name?patient.provider_name:patientInfo.provider_name,
          pharmacy: patient?.pharmacy?.name ? `${patient?.pharmacy?.name} ${patient?.pharmacy?.address_1} ${patient?.pharmacy?.address_2 || ''} ${patient?.pharmacy?.city} ${patient?.pharmacy?.state} ${patient?.pharmacy?.country || ''}` : 'None',
          provider_name_with_title: patient?.provider_name ? `${patient?.provider_name} ${patient?.provider_title}`: patientInfo?.provider_name ? `${patientInfo?.provider_name} ${patientInfo?.provider_title}`: '',
        });
        if(urgentRefillFlag){
          notes = hasDraft ? draft.notes : tmpl.urgent_refill_provider_notes.replace('PRIMARY_NAME', originName)
          .replace('COVER__NAME', localStorage.getItem('name'));
          msg = hasDraft
            ? draft.msg
            : tmpl.urgent_refill_message_to_patient
                .replace('PATIENT_NAME', patient ? patient.name : '')
                .replace('COVER__NAME', localStorage.getItem('name'));
        }
    }

    if (
      ['approvePrescriptionRequest', 'approvePrescriptionWithoutRequest'].includes(addNoteType) &&
      hasTreatmentPlan
    ) {
      form.setFieldsValue({
        provider_notes: notes,
        message_to_patient: msg,
        diagnostic_code: treatmentPlan.diagnostic_code,
        rx: treatmentPlan.rx,
      });
    } else {
      form.setFieldsValue({ provider_notes: notes, message_to_patient: msg });
    }

    if (hasTreatmentPlan) {
      form.setFieldsValue({
        three_month_renewal: treatmentPlan.three_month_renewal,
      });
    }

    // update();
  };

  const updateDraftNotes = (notes) => {
    const hasDraft = draft && draft.hasOwnProperty('notes') && draft.hasOwnProperty('msg');
    if (hasDraft) {
      setDraft({ ...draft, notes });
    } else {
      setDraft({ msg: form.getFieldValue('message_to_patient'), notes });
    }
  };

  const updateDraftMsg = (msg) => {
    const hasDraft = draft && draft.hasOwnProperty('notes') && draft.hasOwnProperty('msg');
    if (hasDraft) {
      setDraft({ ...draft, msg });
    } else {
      setDraft({ notes: form.getFieldValue('provider_notes'), msg });
    }
  };


  const onValuesChange = (changedValues, allValues) => {
    setFormValues(allValues);
    setDraft(allValues);
    exitFormValues = allValues;
  };

  const onAddNote = (values) => {
    if (values.cpt_code && values.cpt_code.length > 5) {
      values.cpt_code = values.cpt_code.slice(0, 5)
    }

    if (values.rx && Object.prototype.toString.call(values.rx) === '[object Array]') {
      values.rx = values.rx.map((v) => v.trim()).join(';');
    }

    if (values.diagnostic_code && Object.prototype.toString.call(values.diagnostic_code) === '[object Array]') {
      values.diagnostic_code = values.diagnostic_code.map((v) => v.trim()).join(';');
    }

    console.log('onAddNote.values', values)
    if(flagDraftClinicianNote){
      onAddNoteWithDraft(values)
      return
    }
    values.note_status = 2

    if (providerNote == 'FollowUp') {
      values.note_type = 2;
    } else if (providerNote == 'TransferOfCare') {
      values.note_type = 11;
    } else if (providerNote == 'Refill') {
      values.note_type = 3;
    } else if (providerNote == 'PlainRefill') {
      values.note_type = 8;
    } else if (providerNote == 'AsyncVideoFollowUp') {
      values.note_type = 9;
    }
    if (urgentRefillFlag) {
      values.note_type = 7;
    }

    if (addNoteType === 'approvePrescriptionRequest') {
      values.id = prescriptionInfo.id;
      approveTreatmentRequestReq.run({ ...values });
    } else {
      var uid = appointment ? appointment.patient.id : patient.id;
      values.user_id = uid;
      if (appointment) {
        values.appointment_id = appointment.id;
      }
      if (values.no_diagnose && values.seen_patient_options === 'Y') {
        values.id = uid;
        var reason = getRealReason(values);
        values.reason = reason + '\n' + values.addition_notes_for_no_diagnose;
        markNoDiagnosisReq.run({ ...values });
      } else if (values.no_diagnose && values.seen_patient_options === 'N') {
        values.appointment_id = appointment.id;
        markAppointmentToBeTransfer.run({ ...values });
      } else {
        addNoteReq.run({ ...values });
      }
    }
  };

  const onAddNoteWithDraft = (values) => {
    if (providerNote == 'FollowUp') {
      values.note_type = 2;
    } else if (providerNote == 'TransferOfCare') {
      values.note_type = 11;
    } else if (providerNote == 'Refill') {
      values.note_type = 3;
    } else if (providerNote == 'PlainRefill') {
      values.note_type = 8;
    } else if (providerNote == 'AsyncVideoFollowUp') {
      values.note_type = 9;
    }
    if(urgentRefillFlag){
      values.note_type = 7;
    }

    if (values.rx && Object.prototype.toString.call(values.rx) === '[object Array]') {
      values.rx = values.rx.map((v) => v.trim()).join(';');
    }

    if (values.diagnostic_code && Object.prototype.toString.call(values.diagnostic_code) === '[object Array]') {
      values.diagnostic_code = values.diagnostic_code.map((v) => v.trim()).join(';');
    }

    if (isDraft) {
      //Draft 1
      values.note_status = 1;
      values.user_id = patient.id;
      //Add or update note
      if (draftNoteID.current != 0) {
        const data = { ...values, id: draftNoteID.current };
        editHistoryReq.run(data);
      } else {
        addDraftNoteReq.run({ ...values });
      }
    } else {
      //Submitted 2,
      values.note_status = 2;
      //Add or update note
      if (addNoteType === 'approvePrescriptionRequest') {
        values.id = prescriptionInfo.id;
        const data = { ...values, note_id: draftNoteID.current ? draftNoteID.current.toString() : '0'};
        approveTreatmentRequestReq.run({ ...data });
      } else {
        var uid = appointment ? appointment.patient.id : patient.id;
        values.user_id = uid;
        if (appointment) {
          values.appointment_id = appointment.id;
        }

        if (values.no_diagnose && values.seen_patient_options === 'Y') {
          values.id = uid;
          var reason = getRealReason(values);
          values.reason = reason + '\n' + values.addition_notes_for_no_diagnose;
          markNoDiagnosisReq.run({ ...values, note_id: draftNoteID.current ? draftNoteID.current.toString() : '0' });
        } else if (values.no_diagnose && values.seen_patient_options === 'N') {
          values.appointment_id = appointment.id;
          markAppointmentToBeTransfer.run({ ...values });
          const data = { ...values, note_id: draftNoteID.current ? draftNoteID.current.toString() : '0' };
          addNoteReq.run(data);
        } else {
          const data = { ...values, note_id: draftNoteID.current ? draftNoteID.current.toString() : '0' };
          addNoteReq.run({ ...data });
        }
      }
    }
  };

  const setNotContinuePatient = (e) => {
    const checked = e.target.checked;
    setIsNotRightPatient(checked);
  };

  const onReasonChanged = (e) => {
    const reason = e.target.value;
    if (reason === 'c') {
      setShowMoreReason(true);
    } else {
      setShowMoreReason(false);
    }
  };

  const onSeenPatientChanged = (e) => {
    const op = e.target.value;
    if (op === 'Y') {
      setIsNoDiagnosed(true);
      setIsSeenPatient(true);
    } else {
      setIsNoDiagnosed(false);
      setIsSeenPatient(false);
    }
  };

  const handleConfirmationOK = () =>{
    if (flagDraftClinicianNote){
      clearInterval(autoSaveTimer);
      setIsDraft(false)
    }
    setShowConfirmation(false)
    if(urgentRefillFlag){
      setShowUrgentRefillRemider(true);
    }else{
      setSubmitted(true);
      exitSubmit = true;
      form.submit();
    }

  }

  const handleConfirmUrgentRxnt = () => {
    setSubmitted(true);
    exitSubmit = true;
    form.submit();
    setShowUrgentRefillRemider(false);
  }

  const handleConfirmationClose = () =>{
    setShowConfirmation(false)
  }

  const onDrugsSearch = debounce((val) => {
    if (!val || val === '') {
      return;
    }
    searchDrugsReq.run({ query: val });
  }, 1000);

  return (
    <Row>
      {appointmentResponse != null &&
        <Col span={10}>
          <div
            className={cx({ appointmentResponseWraper: true })}
          >
            <AppointmentResponseContent response={appointmentResponse} />
          </div>
        </Col>
      }
      <Col span={(appointmentResponse != null) ? 14 : 24}>
        <Form
          layout="vertical"
          form={form}
          onFinish={onAddNote}
          className={cx({ multiNotesForm: true })}
          onValuesChange={onValuesChange}
        >
          <Modal
            visible={showConfirmation}
            maskClosable={false}
            footer={[
              <div key={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button key="cancel" onClick={handleConfirmationClose}>
                  Cancel
                </Button>
                <Button key="confirm" type="primary" onClick={handleConfirmationOK}>
                  Confirm
                </Button>
              </div>,
            ]}
            title="Are you sure?"
            onCancel={() => {
              setShowConfirmation(false);
            }}
          >
            <p>
              Once you submit this note, you will not be able to delete or make further modifications.
              {['FollowUp', 'TransferOfCare', 'Refill', 'Coverage Note'].includes(providerNote) ? "Please ensure that the patient’s PDMP record has been reviewed before submitting the note. This step is critical to maintaining compliance and ensuring safe prescribing practices." : ""}
            </p>
          </Modal>
          <Modal
            visible={showUrgentRefillRemider}
            onCancel={() => {
              setShowUrgentRefillRemider(false);
            }}
            footer={null}
          >
            <p
              style={{
                color: '#000000',
                fontSize: '20px',
                fontWeight: '600',
                marginBottom: '20px',
              }}
            >
              Reminder for Prescribing in RXNT
            </p>
            <p style={{ marginBottom: '20px' }}>
              To ensure the pharmacy accepts the prescription, please indicate in RXNT that you are
              prescribing under a PHE exemption and have reviewed the patient's PDMP.
            </p>
            <div style={{ display: 'flex', justifyContent: 'space-between', color: '#000000' }}>
              <div />
              <Button key="confirm" onClick={handleConfirmUrgentRxnt} type="primary" style={{ width: '200px' }}>
                <p style={{ color: 'white' }}>
                  Continue
                </p>
              </Button>
            </div>
          </Modal>
          {!isNoDiagnosed && (
            <>
              <Form.Item
                name="diagnostic_code"
                label="Diagnostic Code"
                rules={[
                  {
                    required: true,
                    message: 'Diagnostic Code is required',
                  },
                ]}
              >
                <Select
                  mode={'multiple'}
                  placeholder="Select Diagnostic Code"
                  options={initialCompletedOpts}
                  filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </Form.Item>
            </>
          )}

          <CPTCodesFormItem
            form={form}
            patient={patient}
            noteType={providerNote}
            initialValues={draft}
          />

          {['FollowUp', 'TransferOfCare', 'Refill', 'Coverage Note'].includes(providerNote) && (
            <LastRefillDateFormItem form={form} patient={patient} noteType={providerNote} initialValues={draft} />
          )}

          {!isNoDiagnosed && (
            <>
              <div className={cx({ rxTitle: true })}>
                <label htmlFor="rx" title="Rx" className={cx({ rxLabel: true })}>
                  Rx
                </label>
              </div>
              <Form.Item
                name="rx"
                rules={[
                  {
                    required: false,
                    message: 'Rx is required',
                  },
                ]}
              >
                <Input placeholder="Rx" maxLength={1000} />
              </Form.Item>
              <Form.Item name="no_controlled_substance_needed" valuePropName="checked">
                <Checkbox>
                  <span>
                    &nbsp;Patient does not need controlled substances (in-person evaluation/referral not
                    required).
                  </span>
                </Checkbox>
              </Form.Item>
            </>
          )}

          <Form.Item name="attempted_medications" label="Attempted Medications">
            <Input placeholder="attempted_medications" maxLength={20000} />
          </Form.Item>

          {(flagNextFollowUpDate && !urgentRefillFlag && ['FollowUp', 'TransferOfCare'].includes(providerNote)) && <NextFollowUpDateFormItem form={form} patient={patient} />}

          <Form.Item
            name="provider_notes"
            label="Provider Notes"
            rules={[
              {
                required: true,
                message: 'Provider Notes is required',
              },
            ]}
          >
            <Input.TextArea
              rows={16}
              placeholder=""
              onChange={(v) => {
                updateDraftNotes(v.target.value);
              }}
              maxLength={20000}
            />
          </Form.Item>
          <Form.Item name="message_to_patient" label="Message To Patient">
            <Input.TextArea
              rows={16}
              placeholder=""
              onChange={(v) => {
                updateDraftMsg(v.target.value);
              }}
              maxLength={20000}
            />
          </Form.Item>

          <div className="add-note-confirm">
            {showNoDiagnoseOption && appointment && (
              <Form.Item name="no_diagnose" valuePropName="checked">
                <Checkbox
                  onChange={(e) => {
                    setNotContinuePatient(e);
                  }}
                >
                  <span className="add-note-checkbox">
                    I don't want to continue to see this patient
                  </span>
                </Checkbox>
              </Form.Item>
            )}
            {!isNoDiagnosed && !isNotRightPatient && (
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    form.validateFields().then(() => {
                      if (flagLockClinicianNote) {
                        setShowConfirmation(true);
                      } else {
                        form.submit();
                      }
                    })
                  }}
                  loading={
                    approveTreatmentRequestReq.loading ||
                    markNoDiagnosisReq.loading ||
                    addNoteReq.loading ||
                    markAppointmentToBeTransfer.loading ||
                    loadPatientInfo.loading
                  }
                >
                  {submitLabel}
                </Button>
              </Form.Item>
            )}
          </div>
          {isNotRightPatient && (
            <>
              <Form.Item
                name="seen_patient_options"
                className={cx({ noDiagnoseReason: true })}
                rules={[
                  {
                    required: true,
                    message: 'the option is required',
                  },
                ]}
              >
                <Radio.Group onChange={onSeenPatientChanged} options={seenPatientOptions} />
              </Form.Item>
            </>
          )}

          {!isSeenPatient && (
            <Form.Item>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  setIsShowConfirmTransfer(true);
                }}
              >
                Sign and Submit
              </Button>
            </Form.Item>
          )}

          <Modal
            title="Confirm Transfer"
            visible={isShowConfirmTransfer}
            okText="Submit"
            onOk={() => {
              form.validateFields().then(() => {
                if (flagLockClinicianNote) {
                  setShowConfirmation(true);
                } else {
                  form.submit();
                }
              });
            }}
            onCancel={() => {
              setIsShowConfirmTransfer(false);
            }}
          >
            <p>You are transferring the patient. Please inform your PA of the transferring request.</p>
          </Modal>

          {isNoDiagnosed && (
            <>
              <h3>Why do you want to remove this patient from your panel?</h3>
              <Form.Item
                name="no_diagnose_reason"
                className={cx({ noDiagnoseReason: true })}
                rules={[
                  {
                    required: true,
                    message: 'the reason is required',
                  },
                ]}
              >
                <Radio.Group onChange={onReasonChanged} options={noDiagnoseReason} />
              </Form.Item>
              {showMoreReason && (
                <Form.Item name="no_diagnose_more_reason" initialValue={['d']}>
                  <Checkbox.Group
                    className={cx({ noDiagnoseMoreReason: true })}
                    options={otherReason}
                  />
                </Form.Item>
              )}
              <Form.Item
                style={{ marginTop: 16, backgroundColor: '#F4F4F4' }}
                name="addition_notes_for_no_diagnose"
              >
                <Input.TextArea
                  style={{ backgroundColor: '#F4F4F4', borderRadius: 4 }}
                  rows={3}
                  placeholder="Any additional notes or comments about this patient?"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    form.validateFields().then(() => {
                      if (flagLockClinicianNote) {
                        setShowConfirmation(true);
                      } else {
                        form.submit();
                      }
                    });
                  }}
                  loading={markNoDiagnosisReq.loading}
                >
                  Sign and Submit
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </Col>
    </Row>
  );
};

export default MultiNotesForm;
