import React, { useState } from 'react';
import { Modal, Table, Space, Tooltip, Button } from 'antd';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import { useFlag } from '@unleash/proxy-client-react';
import { useEventEmitter, useMount, useRequest, useUpdateEffect } from 'ahooks';
import { SyncOutlined } from '@ant-design/icons';

import {history} from 'umi';

import styles from './PrescriptionHistory.less';
import { formatUnix } from '@/utils/xtime';
import {
  srvRxntActiveMedList,
  srvCancelAyvaRxOrder,
  srvFetchRxntActiveMeds,
  srvUserFeatures,
  srvFetchRxntPrescription,
} from '@/services/patient';
import {useActiveMedsStore} from "@/stores/useActiveMedsStore";
const cx = cn.bind(styles);

const pageSize = 10;

const RxntActiveMedList = (props) => {
  const { admin, uid, active, reloadRxnt$, onData } = props;
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const {activeMeds, setActiveMeds} = useActiveMedsStore();

  const fetchRxntActiveMedReq = useRequest(srvFetchRxntActiveMeds, {
    manual: true,
    onSuccess: (ret) => {
      reloadRxnt$.emit();
    },
  });

  const historyReq = useRequest(srvRxntActiveMedList, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setData(ret.data);
        onData(ret.data)
        setActiveMeds(ret.data);
      }
    },
  });

  const userFeaturesReq = useRequest(srvUserFeatures, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        // setThreeMonthRenewal(!!ret.data.three_month_renewal);
      }
    },
  });

  reloadRxnt$.useSubscription(() => {
    historyReq.run({ user_id: uid });
  });

  useMount(() => {
    historyReq.run({ user_id: uid });
  });

  useUpdateEffect(() => {
    setPage(1);
    historyReq.run({ user_id: uid });
  }, [active, uid]);

  const columns = [
    {
      title: 'Drug Name',
      dataIndex: 'id',
      key: 'id',
      render: (v, r) => {
        return (
          <div>
            <div>
              <b>{r.drug?.name}</b>(RxNorm code: {r.rx_norm_name})
            </div>
            {r.detail && (
              <div>
                <div>{r.detail.dosage}</div>
                <div>
                  <b>Qty/Dur:</b> {r.detail.duration_amount} {r.detail.duration_unit}{' '}
                  <b>Refills:</b> {r.detail.no_of_refills}
                </div>
                <div>
                  <b>Day Supply:</b> {r.detail.days_supply}
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Last Rx Date',
      dataIndex: 'date_added',
      key: 'date_added',
      width: 90,
      render: (v, r) => {
        if (!r.detail) {
          return;
        }
        if (r.detail.created_at <= 0) {
          return;
        }
        return <div>{formatUnix(r.detail.created_at, 'MM/DD/YYYY')}</div>;
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      width: 90,
      render: (v, r) => {
        return <div>{formatUnix(v, 'MM/DD/YYYY')}</div>;
      },
    },
    {
      title: 'Added By',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      render: (v, r) => {
        return (
          <div className={cx({ discharged: r.is_discharged })}>
            <div>{r.doctor_first_name}</div>
            <div>{r.doctor_last_name}</div>
          </div>
        );
      },
    },
    {
      title: 'Last Modified Date',
      dataIndex: 'id',
      key: 'id',
      width: 90,
      render: (v, r) => {
        if (!r.detail) {
          return;
        }
        if (r.detail.last_modified_date <= 0) {
          return;
        }
        return <div>{formatUnix(r.detail.last_modified_date, 'MM/DD/YYYY')}</div>;
      },
    },
    {
      title: 'Notes',
      dataIndex: 'start_date',
      key: 'start_date',
      width: 90,
      render: (v, r) => {
        return <div>{r.omments}</div>;
      },
    },
  ];

  return (
    <>
      <h3>
        <Space>
          Active Medications
          <Tooltip title="Sync data from RXNT">
            <Button
              className={cx(styles.syncBtn)}
              onClick={() => {
                fetchRxntActiveMedReq.run({ id: uid });
              }}
              loading={fetchRxntActiveMedReq.loading}
            >
              <SyncOutlined />
            </Button>
          </Tooltip>
        </Space>
      </h3>
      <Table columns={columns} dataSource={data} rowKey="id" loading={history.loading} />
    </>
  );
};
RxntActiveMedList.defaultProps = {
  admin: false,
};

export default RxntActiveMedList;
