import React, { useRef, useState, useEffect, useMemo } from 'react';
import {
  AutoComplete,
  Button,
  Form,
  Input,
  Checkbox,
  Radio,
  Select,
  Tooltip,
  Modal,
  Alert,
  Space,
  Row,
  Col,
} from 'antd';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarOutlined } from '@ant-design/icons';
import { useRequest, useMount, useUpdate, useLocalStorageState, useUnmount } from 'ahooks';
import moment from 'moment';
import { useFlag } from '@unleash/proxy-client-react';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);
import { getNoteTemplatesForPatient } from '@/utils/utils';
import ScheduleTherapyAppointment from '@/components/ScheduleTherapyAppointment';
import { formatUnix } from '@/utils/xtime.js';
import { QuestionCircleOutlined } from '@ant-design/icons';

import {
  srvAddTreatmentPlan,
  srvEditPatientTreatmentPlanHistory,
  srvPatientDraftTreatmentPlan,
  srvAddDraftTreatmentPlan,
} from '@/services/patient';
import { srvAppointmentCalendar, srvTherapistSession,srvLastTherapyAppointment, srvGetPatientTherapyRecurringAppointmentOption } from '@/services/account';

let exitFormValues = {};
let exitInitialValues = {};
let exitSubmit = false;

const CoachingNoteForm = (props) => {
  const {
    form,
    admin,
    patient,
    providerNote,
    prescriptionInfo,
    appointment,
    addNoteType,
    sourceAction,
    RXNTCallbackLink,
    setNoteVisible,
    onRefresh,
    providerProfile,
    showTherapyScheduleAppointment,
    recurringData,
    setTmpAppointedAt,
  } = props;

  let flagDraftClinicianNote = useFlag('draft_clinician_note');
  const [draft, setDraft] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isDraft, setIsDraft] = useState(true);
  const [autoSaveTimer, setAutoSaveTimer] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [formValues, setFormValues] = useState({});
  const draftNoteID = React.useRef(0);
  const update = useUpdate();
  const [calendar, setCalendar] = useState({});

  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [availability, setAvailability] = useState([]);
  const [appointedAt, setAppointedAt] = useState(0);
  const [appointmentType, setAppointmentType] = useState(23);
  const [nextMonth, setNextMonth] = useState(0);
  const [nextAppointmentOption, setNextAppointmentOption] = useState({})
  const [allowNextAppointment, setAllowNextAppointment] = useState(true)

  const [defaultCptCode, setDefaultCptCode] = useState('');

  const fetchDates = (dir, currentMonth,appointmentType) => {
    const currentMonthInt = moment(currentMonth).format('YYYYMMDD');
    appointmentCalendarReq.run({
      doctor_id: providerProfile.doctor_id,
      appointment_type: appointmentType, // therapy session
      timezone: patient.time_zone,
      days: 31,
      date: currentMonthInt,
      dir: dir,
    });
  };

  const initCalendar = (appointmentType) => {
    appointmentCalendarReq.run({
      doctor_id: providerProfile.doctor_id,
      appointment_type: appointmentType, // therapy session
      timezone: patient.time_zone,
      days: 31,
      date: moment().startOf('month').format('YYYYMMDD'),
      dir: 'next',
    });
  };
  const therapySessionReq = useRequest(srvTherapistSession, {
    manual: true,
    onSuccess: (ret, params) => {
      onRefresh();
    },
    onError: (e) => {
      throw e;
    },
  });

  const lastTherapyAppointmentReq = useRequest(srvLastTherapyAppointment, {
    manual: true,
    onSuccess: (ret, params) => {
    },
    onError: (e) => {
      throw e;
    },
  });

  const appointmentCalendarReq = useRequest(srvAppointmentCalendar, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setCalendar(ret.data);
      }
    },
  });

  const addNoteReq = useRequest(srvAddTreatmentPlan, {
    manual: true,
    onSuccess: (ret) => {
      setDraft();
      setNoteVisible(false);
      onRefresh();
    },
  });

  const editHistoryReq = useRequest(srvEditPatientTreatmentPlanHistory, {
    manual: true,
    onSuccess: (ret) => {
      if (!isDraft) {
        setDraft({});
        onRefresh();
        setNoteVisible(false);
      }
    },
  });

  const getDraftReq = useRequest(srvPatientDraftTreatmentPlan, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.data) {
        const data = ret.data;
        setDraft(data);
        draftNoteID.current = ret.data.id;
        update();
      } else {
        fillData();
      }
    },
  });

  const addDraftNoteReq = useRequest(srvAddDraftTreatmentPlan, {
    manual: true,
    onSuccess: (ret) => {
      const splitArray = ret.data.split(', ');
      draftNoteID.current = splitArray[1];
    },
  });

  const nextAppointmentOptionReq = useRequest(srvGetPatientTherapyRecurringAppointmentOption, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setNextAppointmentOption(ret.data)
        setAllowNextAppointment(ret.data.allow_next_appointment)
      }
    }
  })

  const isDateTimeAvailable = (datetime) => {
    if (!datetime) {
      return;
    }
    const selectedDate = datetime.toDateString();
    const matchingAvailability = availability.find((item) => item.date === selectedDate);
    return matchingAvailability ? matchingAvailability.times : [];
  };

  // Function to filter available dates
  const filterDate = (date) => {
    if (date < new Date()) {
      return false;
    }
    const formattedDate = date.toDateString();
    return availability.some((item) => item.date === formattedDate);
  };
  // Function to filter available times
  const filterTime = (time) => {
    if (!selectedDateTime) {
      return false;
    }
    const dateTimes = isDateTimeAvailable(selectedDateTime);
    if (dateTimes.length>0) {
      const isAvailable = dateTimes.some(
        (item) => item.calendarTime.toLocaleString('en-US', { timeZone: patient.time_zone }) === time.toLocaleString('en-US'),
      );
      return isAvailable;
    }
    return false;
  };

  useEffect(() => {
    if (Object.keys(calendar).length > 0) {
      const convertedAvailability = calendar.dates.map((availabilityItem) => {
        const dateMoment = moment(availabilityItem.date, 'YYYYMMDD');
        const date = dateMoment.toDate().toDateString();

        const times = availabilityItem.times.map((epoch) => {
          const timeMoment = new Date(epoch * 1000);
          return { calendarTime: moment.tz(timeMoment, patient.time_zone).toDate(), time: epoch };
        });

        return { date, times };
      });
      setAvailability(convertedAvailability);
    }
  }, [calendar]);

  useEffect(() => {
    if (flagDraftClinicianNote) {
      setDraft({});
      form.resetFields();
      clearInterval(autoSaveTimer);
      draftNoteID.current = 0;
      const note_type = 10;
      const data = { patient_id: patient.id, note_type: note_type };
      getDraftReq.run({ ...data });
    }
  }, [patient, providerNote]);

  useUnmount(() => {
    if (flagDraftClinicianNote) {
      if (
        !submitted &&
        formValues &&
        Object.keys(formValues).length > 0 &&
        !Object.is(initialValues, formValues)
      ) {
        setDraft(formValues);
        form.validateFields().then(() => {
          setIsDraft(true);
          onAddNoteWithDraft(formValues);
        });
      }
      // Clean up the timer on component unmount
      clearInterval(autoSaveTimer);
    }
  });

  useEffect(() => {
    form.setFieldsValue(draft);
    update();
  }, [draft, form]);

  useMount(async() => {
    var response
    response = await lastTherapyAppointmentReq.runAsync({id: patient.id})
    const initial = form.getFieldsValue();
    setInitialValues(initial);
    exitInitialValues = initial;

    initCalendar(23);
    setAppointmentType(23);

    if(response.data) {
      // initial type
      if([17, 18].indexOf(response.data.appointment_type) != -1) {
        setDraft({
          ...draft,
          cpt_code: '90791'
        })
      } else {
        var duration = response.data.duration_minutes
        if (duration) {
          if(duration >= 53) {
            setDraft({
              ...draft,
              cpt_code: '90837'
            })
          } else if (duration >= 38) {
            setDraft({
              ...draft,
              cpt_code: '90834'
            })
          } else if (duration >= 16) {
            setDraft({
              ...draft,
              cpt_code: '90832'
            })
          }
        }
      }
    }
    
    nextAppointmentOptionReq.run({patient_id: patient.id })
  });

  const handleClose = (e) => {
    if (flagDraftClinicianNote) {
      if (
        !exitSubmit &&
        exitFormValues &&
        Object.keys(exitFormValues).length > 0 &&
        !Object.is(exitInitialValues, exitFormValues)
      ) {
        e.returnValue = 'Are you sure you want to leave?';
      }
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      handleClose(e);
      e.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const fillData = (draft, treatmentPlan) => {
    const hasDraft = draft && draft.hasOwnProperty('notes') && draft.hasOwnProperty('msg');
    const template = getNoteTemplatesForPatient(patient)
    const tmpl = template[providerNote] || {};
    if (tmpl) {
      tmpl.provider_notes = tmpl.provider_notes.replace(
        'DATE_OF_SERVICE',
        appointment ? formatUnix(appointment.appointed_at, 'YYYY-MM-DD') : 'DATE_OF_SERVICE',
      );
    }
    let notes = '';

    notes = hasDraft ? draft.notes : tmpl.provider_notes;
    form.setFieldsValue({ provider_notes: notes });

    update();
  };

  const onValuesChange = (changedValues, allValues) => {
    setFormValues(allValues);
    setDraft(allValues);
    exitFormValues = allValues;
  };

  const onAddNoteWithDraft = async (values) => {
    if (values.cpt_code && values.cpt_code.length > 5) {
      values.cpt_code = values.cpt_code.slice(0, 5);
    }
    values.note_type = 10;
    if (isDraft) {
      //Draft 1
      values.note_status = 1;
      values.user_id = patient.id;
      //Add or update note
      if (draftNoteID.current != 0) {
        const data = { ...values, id: draftNoteID.current };
        editHistoryReq.run(data);
      } else {
        addDraftNoteReq.run({ ...values });
      }
    } else {
      //Submitted 2,
      values.note_status = 2;
      //Add or update note

      var uid = appointment ? appointment.patient.id : patient.id;
      values.user_id = uid;
      if (appointment) {
        values.appointment_id = appointment.id;
      }
      const data = {
        ...values,
        note_id: draftNoteID.current ? draftNoteID.current.toString() : '0',
      };
      if(recurringData.is_recurring && recurringData.appointed_at > 0) {
        data.is_recurring = true
      }
      if (recurringData.appointed_at > 0) {
        data.next_follow_up_date = recurringData.appointed_at
        data.next_follow_up_month = nextMonth
        data.next_appointment_type = recurringData.appointment_type
        data.recurring_type = recurringData.recurring_type
      }
      addNoteReq
        .runAsync({ ...data })
        .catch((e) => {
          throw e;
        });
    }
  };

  const handleConfirmationOK = () => {
    if (flagDraftClinicianNote) {
      clearInterval(autoSaveTimer);
      setIsDraft(false);
    }
    setSubmitted(true);
    exitSubmit = true;
    form.submit();
    setShowConfirmation(false);
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  if(showTherapyScheduleAppointment) {
    return (<ScheduleTherapyAppointment form={form} providerProfile={providerProfile} patient={patient} setTmpAppointedAt={setTmpAppointedAt} nextAppointmentOption={nextAppointmentOption} />)
  }

  const scheduleDateText = () => {
    if(recurringData.appointment_type_name && recurringData.appointed_at) {
      return recurringData.appointment_type_name + ", " + moment.unix(recurringData.appointed_at).format('ddd, MMM D h:mm A')
    }
    return ''
  }

  const initialCptCodeValues = defaultCptCode != '' ? { cpt_code: defaultCptCode } : draft

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onAddNoteWithDraft}
      className={cx({ multiNotesForm: true })}
      onValuesChange={onValuesChange}
    >
      <Space
        direction="vertical"
        style={{
          width: '100%',
        }}
      >
        <Alert
          message="Confidentiality Note: Remind yourself to maintain confidentiality and take necessary precautions to protect the client's privacy"
          type="info"
          closable
        />
        <Alert
          message="For compliance, keep your notes clean and don't leave text in the template that you will not use"
          type="info"
          closable
        />
      </Space>
      <br />
      <hr />
      <br />
      <Modal
        visible={showConfirmation}
        maskClosable={false}
        footer={[
          <div key={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button key="cancel" onClick={handleConfirmationClose}>
              Cancel
            </Button>
            <Button key="confirm" type="primary" onClick={handleConfirmationOK}>
              Confirm
            </Button>
          </div>,
        ]}
        title="Are you sure?"
        onCancel={() => {
          setShowConfirmation(false);
        }}
      >
        <p>
          Once you submit this note, you will not be able to delete or make further modifications.
        </p>
      </Modal>

      <Form.Item
        name="provider_notes"
        label="Coaching Notes"
        rules={[
          {
            required: true,
            message: 'Coaching Notes is required',
          },
        ]}
      >
        <Input.TextArea rows={20} placeholder="" maxLength={20000} />
      </Form.Item>

      <Form.Item name="next_appointment">
        <div style={{display: 'flex', alignItems: 'center'}}>
          <label htmlFor="appointment" style={{width: '200px'}}>Next Appointment</label>
          <Input
            placeholder="Select Date"
            onFocus={()=>{ props.showSchedule() }}
            value={scheduleDateText()}
            disabled={!allowNextAppointment}
            suffix={
              <CalendarOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            }
          />
          <Tooltip
            placement="top"
            title="You are booking a future therapy session for this patient. The appointment will be automatically confirmed after you submit the note. The patient will receive an email confirmation and be charged 48 hours before the appointment."
            className={cx({ tip: true })}
          >
            <QuestionCircleOutlined style={{marginLeft: '10px'}} />
          </Tooltip>
        </div>
        {
          recurringData.is_recurring &&
          <p>Your appointment will recur {recurringData.recurring_type == 1 ? 'Weekly' : 'Bi-Weekly'}</p>
        }
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="button"
          disabled={addNoteReq.loading}
          onClick={() => {
            setShowConfirmation(true);
          }}
        >
          Sign and Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CoachingNoteForm;
