import React, { useEffect, useState } from 'react';
import { connect,history } from 'umi';
import { useSetState, useRequest } from 'ahooks';

import {
  Modal,
  Switch,
  Button,
  Space,
  Popover,
  Typography,
  Popconfirm,
  Tooltip,
  Alert,
  message,
  Divider,
  List, Descriptions, Drawer
} from 'antd';
import ReactPlayer from 'react-player';
import {ExclamationCircleOutlined, QuestionCircleOutlined} from '@ant-design/icons';

import { QRCodeSVG } from 'qrcode.react';
import moment from 'moment';
import BigCalendar from '@/components/BigCalendar/Calendar';
import RescheduleAppointmentWithTimeSlotsModal from '@/components/RescheduleAppointmentWithTimeSlotsModal';
import CalendarMessagesDrawer from './CalendarMessagesDrawer';
import 'moment-timezone';
import {
  srvUpdateSettings,
  srvGetSubscriptionCode,
  srvSetSubscriptionCode,
  srvThisWeekRule,
  srvGoogleCalendarAuth,
  srvGetDoctorGoogleCalendar,
  srvDeleteDoctorGoogleCalendar,
  srvCheckDoctorGoogleCalendar, srvCheckDoctorNeedReAuthGoogleCalendar,
} from '../../services/account';

import {
  srvDoctorFollowUpMonthlyTimeSlot,
  srvDoctorCompleteFollowUpTimeSlot,
} from '@/services/account';

import { useFlag } from '@unleash/proxy-client-react';

import cn from 'classnames/bind';
import styles from './index.less';
import SyncToGoogleCalendar from "@/components/GoogleCalendar/syncToGoogleCalendar";

const cx = cn.bind(styles);

const { Title, Link, Text } = Typography;

const EHRCalendar = (props) => {
  const { dispatch, ehr_calendar, account, loading } = props;
  const { currentUser } = account;
  const [state, setState] = useSetState({
    helpModalOpen: false,
    switch: currentUser.allow_appointment_within12h,
    hasSubscriptionCode: false,
    subscriptionUrl: '',
  });
  const [dateRange, setDateRange] = useState({});
  const [messagesVisible, setMessagesVisible] = useState(false);
  const [activeAppointment, setActiveAppointment] = useState(false);
  const [timeSlot, setTimeSlot] = useState({})
  const [ruleData, setRuleData] = useState([])
  const [removeBlockedVisible, setRemoveBlockedVisible] = useState(false);
  const [removeBlockedId, setRemoveBlockedId] = useState(0);
  const flagEnableAutoReschedule = useFlag('enable_auto_reschedule');
  moment.tz.setDefault(currentUser?.timezone);
  const [showSyncToGoogleCalendar, setShowSyncToGoogleCalendar] = useState(false);
  const enableGoogleCalendar = useFlag('enable_google_calendar');



  const handleGoogleAuth = useRequest(srvGoogleCalendarAuth, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        // MESSAGE: Google Calendar added successfully
        message.success('Google Calendar added successfully');
      } else {
        // MESSAGE: Google Calendar added failed
        message.error('Google Calendar added failed');
      }
    }
  })

  const checkDoctorNeedReAuthGoogleCalendar = useRequest(srvCheckDoctorNeedReAuthGoogleCalendar, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data) {
          setShowSyncToGoogleCalendar(true)
        }
      }
    }
  })


  const handleAuthCallback = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const redirect_url = `${GOOGLE_REDIRECT_URL}`;
    if (code) {
      handleGoogleAuth.run({code: code, redirect_url: redirect_url});
    }
  };

  useEffect(() => {
    var payload;
    if (currentUser && currentUser.doctor_id === currentUser.external_calendar_id) {
      payload = {
        start: moment().add('-7', 'days').unix(),
        end: moment().add('7', 'days').unix(),
      };
    } else {
      payload = {
        start: moment().add('-15', 'days').unix(),
        end: moment().add('30', 'days').unix(),
      };
    }
    onRefresh(payload);
    getCalendarUrl();
    getDateRangeRuleReq.run({
      start: moment().add('-9', 'days').unix(),
      end: moment().add('9', 'days').unix(),
    });
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      handleAuthCallback(code);
    }
    const alertGoogleSync = urlParams.get('alert_google_sync');
    if (alertGoogleSync) {
      setShowSyncToGoogleCalendar(true)
    }
    checkDoctorNeedReAuthGoogleCalendar.run();
  }, []);

  const updateSettingsReq = useRequest(srvUpdateSettings, {
    manual: true,
    onSuccess: (ret, params) => {
      dispatch({
        type: 'account/fetchCurrent',
        callback: (data) => {},
      });
    },
  });

  const getDateRangeRuleReq = useRequest(srvThisWeekRule, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (!ret.data || ret.data?.length === 0) {
          setRuleData([]);
        }
        if (ret.data.length > 0) {
          const tempResult = [];
          ret.data.forEach(obj => {
            obj.items.forEach(item => {
              if (item.date_range.includes(';')) {
                const ranges = item.date_range.split(';');
                ranges.forEach(range => {
                  const newItem = {
                    date: obj.date,
                    appointment_type_name: item.appointment_type_name,
                    appointment_type_id: item.appointment_type_id,
                    date_range: range.trim()
                  };
                  const [startStr, endStr] = range.split('-');
                  const [startHour, startMinute] = getHoursAndMinutes(startStr);
                  const [endHour, endMinute] = getHoursAndMinutes(endStr);
                  const startDateTime = parseInt(obj.date) + (startHour % 12 + (startStr.includes('pm') ? 12 : 0)) * 3600 + startMinute * 60;
                  const endDateTime = parseInt(obj.date) + (endHour % 12 + (endStr.includes('pm') ? 12 : 0)) * 3600 + endMinute * 60;
                  newItem.start_date = startDateTime;
                  newItem.end_date = endDateTime;
                  tempResult.push(newItem);
                });
              } else {
                const newItem = {
                  date: obj.date,
                  appointment_type_name: item.appointment_type_name,
                  appointment_type_id: item.appointment_type_id,
                  date_range: item.date_range
                };
                const [startStr, endStr] = item.date_range.split('-');
                const [startHour, startMinute] = getHoursAndMinutes(startStr);
                const [endHour, endMinute] = getHoursAndMinutes(endStr);
                const startDateTime = parseInt(obj.date) + (startHour % 12 + (startStr.includes('pm') ? 12 : 0)) * 3600 + startMinute * 60;
                const endDateTime = parseInt(obj.date) + (endHour % 12 + (endStr.includes('pm') ? 12 : 0)) * 3600 + endMinute * 60;
                newItem.start_date = startDateTime;
                newItem.end_date = endDateTime;
                tempResult.push(newItem);
              }
            });
          });
          setRuleData(tempResult);
        }
      }
    },
  });
  function getHoursAndMinutes(timeStr) {
    const [hours, minutes] = timeStr.match(/\d+/g).map(Number);
    return [hours, minutes];
  }
  const onSelectEvent = (arg) => {
    // if (moment(arg.start).unix() > moment().unix())
    // appointment confirmed status
    if (arg.isBlock) {
      // show block remove alert
      setRemoveBlockedId(arg.id);
      // setRemoveBlockedVisible(true);
      Modal.confirm({
        title: 'Do you want to remove this Block?',
        content: '',
        onOk() {
          onBlockRemove();
        },
        onCancel() {
          console.log('');
        },
      });
      return;
    }
    if (arg.appointment_status == 2) {
      setActiveAppointment(arg);
    }
  };

  const onBlockRemove = () => {
    dispatch({
      type: 'ehr_calendar/removeBlock',
      payload: {
        id: removeBlockedId,
      },
      callback: () => {
        setRemoveBlockedVisible(false);
        onRefresh();
      },
    });
  }

  const onRefresh = (payload, callback) => {
    if (payload) setDateRange(payload);
    dispatch({
      type: 'ehr_calendar/fetchCalendarAppointments',
      payload: payload || dateRange,
      callback,
    });

    dispatch({
      type: 'ehr_calendar/fetchDoctorBlocks',
      payload: payload || dateRange,
      callback,
    });

    srvDoctorFollowUpMonthlyTimeSlot().then((res)=>{
      if (res.data) {
        setTimeSlot(res.data)
      }
    })
    const rangeRuleParam={
      start: payload.start-172800,
      end: payload.end+172800,
    }
    getDateRangeRuleReq.run(rangeRuleParam);
  };

  function onSwitchChange(checked) {
    setState({
      switch: checked,
    });
    Modal.info({
      title: checked
        ? 'You have opened the available time slots that are within 4 to12 hours. Please stay updated on your email and calendar when new early appointments are booked.'
        : 'You will not receive new appointments that will begin within 12 hours.',
      okText: 'understood',

      onOk() {
        updateSettingsReq.run({
          allow_appointment_within12h: checked,
          notifiable: currentUser.notifiable,
          timezone: currentUser.timezone,
        });
      },
    });
  }

  function toggleModal() {
    setState({ helpModalOpen: !state.helpModalOpen });
  }

  async function getCalendarUrl() {
    const res = await srvGetSubscriptionCode();
    const subscriptionUrl = `${API_URL}/calendar/gen/${res.data}`.replace('https', 'webcal');
    setState({
      hasSubscriptionCode: res.data != 0,
      subscriptionUrl,
    });
  }

  async function genCalendarUrl() {
    const res = await srvSetSubscriptionCode();
    const subscriptionUrl = `${API_URL}/calendar/gen/${res.data}`.replace('https', 'webcal');
    setState({
      hasSubscriptionCode: true,
      subscriptionUrl,
    });
  }

  const content = () => {
    return (
      <div className={cx({ subscription: true })}>
        {state.hasSubscriptionCode ? (
          <>
            <QRCodeSVG value={state.subscriptionUrl} style={{ margin: '12px' }} />
            <Text strong copyable={{ text: state.subscriptionUrl }}>
              <Link
                href={state.subscriptionUrl}
                target="_blank"
                ellipsis
                style={{ maxWidth: '300px' }}
              >
                {state.subscriptionUrl}
              </Link>
            </Text>
            <Popconfirm
              placement="top"
              title="Are you sure to reset subscription link?"
              onConfirm={genCalendarUrl}
              okText="Yes"
              cancelText="No"
            >
              <Button type="text" danger>
                Reset
              </Button>
            </Popconfirm>
          </>
        ) : (
          <Button type="primary" onClick={genCalendarUrl}>
            Generate Subscription Link
          </Button>
        )}
      </div>
    );
  };

  const informCareTeam = () => {
    srvDoctorCompleteFollowUpTimeSlot().then(function(){
      message.info('Informed carea team succesfully!');
    })
  }

  return (
    <>
      <div className={cx({ ehrCalendar: true })}>
        <div className={cx({ top: true })}>
          {enableGoogleCalendar ? (
            <div>
              <Button onClick={() =>{
                setShowSyncToGoogleCalendar(true)
              }}>Sync to Google Calendar</Button>
              {currentUser.enable_open_new_acuity && (<Button onClick={() => {
                history.push(`/ehr_calendar/manage_availability`);
              }} style={{ marginLeft: '10px' }}>Availability</Button>)}
            </div>
          ):(
            <Popover placement="bottomLeft" trigger="click" content={content} title="Subscription">
              <Button>Calendar on my phone</Button>
              {currentUser.enable_open_new_acuity && (<Button onClick={() => {
                history.push(`/ehr_calendar/manage_availability`);
              }} style={{ marginLeft: '10px' }}>Availability</Button>)}
            </Popover>
            )}
          <Space>
            <span>Accept new appointments within 12 hours</span>
            <Switch checked={state.switch} onChange={onSwitchChange} />
            <Button
              onClick={() => {
                setMessagesVisible(true);
              }}
            >
              Changing log
            </Button>
            <Tooltip placement="bottomRight" title="How to reschedule?">
              <QuestionCircleOutlined
                onClick={toggleModal}
                style={{ verticalAlign: 'text-bottom', fontSize: '14px', color: '#5469d4' }}
              />
            </Tooltip>
          </Space>
        </div>

        {currentUser.doctor_id!=currentUser.external_calendar_id && (<Alert
          style={{marginBottom: '20px', backgroundColor: '#E0F5FF'}}
          type="info"
          message={
            <p className='clearfix'>
              Follow-up to be booked/Remaining availability: {timeSlot.to_be_booked}/<span style={timeSlot && timeSlot.to_be_booked > timeSlot.remaining ? {color: '#E2445F'} : {}}>{timeSlot.remaining}</span>
              {timeSlot.to_be_booked > timeSlot.remaining && (
                  <>
                    <a href={currentUser.scheduling_url} rel="noreferrer" target="_blank" style={{marginLeft: '10px', color: '#566BCD'}}>Add time slots</a>
                    <Button style={{float: 'right'}} onClick={informCareTeam}>Inform Care Team</Button>
                  </>
              )}
            </p>
          }
        />)}
        {(currentUser.doctor_id === currentUser.external_calendar_id && timeSlot && timeSlot.to_be_booked > timeSlot.remaining) && (
          <Alert
            style={{ marginBottom: '20px', backgroundColor: '#FFF1F0', borderColor: '#EA445A59' }}
            type="info"
            message={
              <p className='clearfix'>
                Remaining availability/Follow-up to be booked: <span style={timeSlot && timeSlot.to_be_booked > timeSlot.remaining ? { color: '#E2445F' } : {}}>{timeSlot.remaining}</span>/{timeSlot.to_be_booked}{timeSlot.appointment_requests_count > 0 && (
                  <span>,requests for initial appointment: <span style={{ color: 'blue', fontWeight: '500', cursor: 'pointer' }} onClick={() => {
                    history.push(`/ehr_extra_earn/appointment_request`);
                  }}>{timeSlot.appointment_requests_count}</span>.</span>
                )}
                <p>You have limited availability for this month’s follow up(sync). To accommodate more patients, please add more hours to your availability.</p>
              </p>
            }
          />
        )}
        {(currentUser.doctor_id === currentUser.external_calendar_id && (Object.keys(timeSlot).length === 0 || timeSlot.to_be_booked <= timeSlot.remaining)) && (
          <Alert
            style={{ marginBottom: '20px', backgroundColor: '#E0F5FF' }}
            type="info"
            message={
              <p className='clearfix'>
                Remaining availability/Follow-up to be booked: <span>{timeSlot.remaining}</span>/{timeSlot.to_be_booked}{timeSlot.appointment_requests_count > 0 && (
                  <span>,requests for initial appointment: <span style={{ color: 'blue', fontWeight: '500', cursor: 'pointer' }} onClick={() => {
                    history.push(`/ehr_extra_earn/appointment_request`);
                  }}>{timeSlot.appointment_requests_count}</span>.</span>
                )}
              </p>
            }
          />
        )}
        <BigCalendar
          onSelectEvent={onSelectEvent}
          loadCalendarAppointments={(payload, callback) => {
            onRefresh(payload, callback);
          }}
          calendarAppointments={ehr_calendar.calendarAppointments}
          loadingCalendarAppointments={loading.effects['ehr_calendar/fetchCalendarAppointments']}
          providerTimezone={currentUser?.timezone}
          canUseTimes={ruleData}
          calendarBlocks={ehr_calendar.saveCalendarBlocks}
        />
        <CalendarMessagesDrawer
          dateRange={dateRange}
          messagesVisible={messagesVisible}
          setMessagesVisible={setMessagesVisible}
        />
        {flagEnableAutoReschedule && (
          <RescheduleAppointmentWithTimeSlotsModal
            visible={ activeAppointment && activeAppointment.appointment_id}
            onRefresh={() => {
              onRefresh()
              setActiveAppointment(false)
            }}
            onCancel={() => {
              setActiveAppointment(false)
            }}
            activeAppointmentId={activeAppointment ? activeAppointment.appointment_id: ""}
          />
        )}
      </div>
      <Modal
        visible={state.helpModalOpen}
        centered
        destroyOnClose
        onCancel={toggleModal}
        width="auto"
        modalRender={() => (
          <div style={{ position: 'relative', pointerEvents: 'auto' }}>
            <ReactPlayer
              controls
              url="https://storage.googleapis.com/done_api_static/help_docs/reschedule_by_providers.mp4"
            />
          </div>
        )}
      />
      {showSyncToGoogleCalendar && (
          <SyncToGoogleCalendar
              visible={showSyncToGoogleCalendar}
              onClose={() => {
                setShowSyncToGoogleCalendar(false)
              }}
          />
      )}
    </>
  );
};

export default connect(({ ehr_calendar, account, loading }) => ({
  ehr_calendar,
  account,
  loading,
}))(EHRCalendar);
