// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/app/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelAccount0 from '/app/src/models/account.js';
import ModelCall1 from '/app/src/models/call.js';
import ModelGlobal2 from '/app/src/models/global.js';
import ModelSettings3 from '/app/src/models/settings.js';
import ModelDetail4 from '/app/src/pages/admin/models/detail.js';
import ModelList5 from '/app/src/pages/admin/models/list.js';
import ModelDetail6 from '/app/src/pages/appointment/models/detail.js';
import ModelList7 from '/app/src/pages/appointment/models/list.js';
import ModelDetail8 from '/app/src/pages/doctor/models/detail.js';
import ModelList9 from '/app/src/pages/doctor/models/list.js';
import ModelEhr10 from '/app/src/pages/ehr/models/ehr.js';
import ModelDetial11 from '/app/src/pages/patient/models/detial.js';
import ModelList12 from '/app/src/pages/patient/models/list.js';
import ModelDetail13 from '/app/src/pages/payment/models/detail.js';
import ModelList14 from '/app/src/pages/payment/models/list.js';
import ModelPharmacyBlacklist15 from '/app/src/pages/pharmacies/models/pharmacy_blacklist.js';
import ModelPharmacyManagement16 from '/app/src/pages/pharmacies/models/pharmacy_management.js';
import ModelPharmacyRecommendedList17 from '/app/src/pages/pharmacies/models/pharmacy_recommended_list.js';
import ModelList18 from '/app/src/pages/referral/models/list.js';
import ModelList19 from '/app/src/pages/tags/models/list.js';
import ModelTeam20 from '/app/src/pages/team/models/team.js';
import ModelModel21 from '/app/src/pages/consultation/model.js';
import ModelModel22 from '/app/src/pages/ehr_calendar/model.js';
import ModelModel23 from '/app/src/pages/ehr_consultation/model.js';
import ModelModel24 from '/app/src/pages/reinstatement/model.js';
import ModelModel25 from '/app/src/pages/renewal/model.js';
import ModelModel26 from '/app/src/pages/user/model.js';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'account', ...ModelAccount0 });
app.model({ namespace: 'call', ...ModelCall1 });
app.model({ namespace: 'global', ...ModelGlobal2 });
app.model({ namespace: 'settings', ...ModelSettings3 });
app.model({ namespace: 'detail', ...ModelDetail4 });
app.model({ namespace: 'list', ...ModelList5 });
app.model({ namespace: 'detail', ...ModelDetail6 });
app.model({ namespace: 'list', ...ModelList7 });
app.model({ namespace: 'detail', ...ModelDetail8 });
app.model({ namespace: 'list', ...ModelList9 });
app.model({ namespace: 'ehr', ...ModelEhr10 });
app.model({ namespace: 'detial', ...ModelDetial11 });
app.model({ namespace: 'list', ...ModelList12 });
app.model({ namespace: 'detail', ...ModelDetail13 });
app.model({ namespace: 'list', ...ModelList14 });
app.model({ namespace: 'pharmacy_blacklist', ...ModelPharmacyBlacklist15 });
app.model({ namespace: 'pharmacy_management', ...ModelPharmacyManagement16 });
app.model({ namespace: 'pharmacy_recommended_list', ...ModelPharmacyRecommendedList17 });
app.model({ namespace: 'list', ...ModelList18 });
app.model({ namespace: 'list', ...ModelList19 });
app.model({ namespace: 'team', ...ModelTeam20 });
app.model({ namespace: 'model', ...ModelModel21 });
app.model({ namespace: 'model', ...ModelModel22 });
app.model({ namespace: 'model', ...ModelModel23 });
app.model({ namespace: 'model', ...ModelModel24 });
app.model({ namespace: 'model', ...ModelModel25 });
app.model({ namespace: 'model', ...ModelModel26 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
