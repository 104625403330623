
import React, { useState, useEffect, useRef } from 'react';
import { Layout, Drawer, Button, Spin, Col, Row } from 'antd';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';

import NotesView from '@/components/DoctorChartAuditDrawer/ChartNotesView'
import NotesAudit from '@/components/DoctorChartAuditDrawer/ChartNotesAudit';

import styles from './index.less';

const { Sider, Content } = Layout;

const DoctorChartAuditDrawer = (props) => {
  const { audit, visible, setVisible, onRefresh } = props;

  const renderTitle = () => {
    return (
      <Row>
        <Col span={12}>Clinician Note</Col>
        <Col span={12} style={{ paddingLeft: '8px', borderleft: '1px solid #f0f0f0' }}>Chart Audit</Col>
      </Row>
    )
  }

  return (
    <Drawer
      title={renderTitle()}
      width={1280}
      className={styles.AuditDrawer}
      visible={visible}
      closable={false}
      onClose={() =>
        setVisible(false)
      }
    >
      <Layout>
        <Sider
          theme="light"
          width={600}
          style={{
            overflow: 'auto',
            height: 'calc(100vh - 60px)',
            position: 'relative',
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          {audit && audit.note_id && (
            <NotesView id={audit.note_id} />
          )}
        </Sider>
        <Content
          theme="light"
          style={{
            backgroundColor: '#fff',
            overflow: 'auto',
            height: 'calc(100vh - 60px)',
            position: 'relative',
            right: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <NotesAudit
            audit={audit}
            visible={visible}
            setVisible={setVisible}
            onClose={() => {
              if (onRefresh) onRefresh();
              if (setVisible) setVisible(false);
            }}
          />
        </Content>
      </Layout>
    </Drawer>
  );
};
export default DoctorChartAuditDrawer;