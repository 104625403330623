import React, { useState, useEffect, useRef } from 'react';
import { connect, history, Link } from 'umi';
import { Space, Table, Button, Tabs } from 'antd';

import Filters from '@/components/Filters';
import ProviderAcceptAppointmentRequestModal from '@/components/ProviderAcceptAppointmentRequestModal';
import { srvDoctorAppointmentRequests, srvDoctorAcceptAppointmentRequest } from '@/services/patient';

import { useRequest, useMount } from 'ahooks';
import moment from 'moment';
import { formatUnix } from '@/utils/xtime.js';


import styles from './appointment_request.less';
import cn from 'classnames/bind';
const cx = cn.bind(styles);

const AppointmentRequestPane = (props) => {
  const { activeKey, active, dispatch, loading } = props;
  const [data, setData] = useState({ total: 0, items: [] });
  const [query, setQuery] = useState({});
  const [sort, setSort] = useState(0);
  const [page, setPage] = useState(1);

  const [acceptVisible, setAcceptVisible] = useState(false);
  const [request, setRequest] = useState({});

  useEffect(() => {
    if (active) {
      setPage(1);
      loadList(null, { page: 1, page_size: 20 });
    }
  }, [ activeKey, active ]);

  const appointmentRequestListReq = useRequest(srvDoctorAppointmentRequests, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setData(ret.data);
      }
    },
  });

  const acceptAppointmentRequestReq = useRequest(srvDoctorAcceptAppointmentRequest, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        loadList(null, { page: 1, page_size: 20 });
        setAcceptVisible(false);
      }
    },
  });

  const loadList = (filters, params) => {
    let filtersData = filters ? filters : {};
    let filtersObj = { ...filtersData };
    appointmentRequestListReq.run({ key: activeKey, filters: JSON.stringify(filtersObj), ...params });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (sorter && sorter.field == "requested_at" && sorter.order) {
      setSort(sorter.order === "ascend" ? 1 : -1);
    }
    loadList(query, { page: pagination.current, page_size: pagination.pageSize, sort });
  };

  const columns = [
    {
      title: 'Request Date/Time',
      ellipsis: true,
      dataIndex: 'requested_at',
      sorter: true,
      key: 'requested_at',
      width: 150,
      render: (v, r) => {
        return formatUnix(r.requested_at, 'MM/DD/YYYY h:mm A');
      },
    },
    {
      title: 'Patient Name',
      dataIndex: 'patient_id',
      key: 'patient_id',
      ellipsis: true,
      width: 120,
      render: (v, r) => {
        return r.patient_name;
      },
    },
    {
      title: 'State',
      dataIndex: 'patient_state',
      key: 'patient_state',
      ellipsis: true,
      width: 60,
    },
    {
      title: 'status',
      dataIndex: 'status_name',
      key: 'status_name',
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Date/Time Accepted',
      ellipsis: true,
      dataIndex: 'appointment_preferred_at',
      sorter: true,
      key: 'appointment_preferred_at',
      width: 150,
      render: (v, r) => {
        if (!r.appointment_preferred_at) {
          return '-';
        }
        return formatUnix(r.appointment_preferred_at, 'MM/DD/YYYY h:mm A');
      },
    },
    {
      title: 'Patients’ Preferred Times',
      dataIndex: 'patient_preferred_times',
      key: 'patient_preferred_times',
      width: 300,
      render: (v, r) => {
        if (!r.patient_preferred_times) {
          return ''
        }
        let appts = [];
        const times = JSON.parse(r.patient_preferred_times);
        for (const v of times) {
          appts.push(`${formatUnix(v.start_time, 'MM/DD/YYYY h:mm A')} - ${formatUnix(v.end_time, 'h:mm A')}`);
        }
        return <div dangerouslySetInnerHTML={{ __html: appts.join('<br/>') }}></div>;
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        return (
          <Button
            size="small"
            type="primary"
            disabled={r.status === 2}
            onClick={() => {
              setRequest(r);
              setAcceptVisible(true);
            }}
          >
            {r.status === 2 ? 'Accepted' : 'Accept'}
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data.items}
        rowClassName={(r) => {
          return '';
        }}
        scroll={{ x: 800 }}
        rowKey="id"
        loading={appointmentRequestListReq.loading}
        onChange={handleTableChange}
        pagination={{ pageSize: 20, current: page, simple: true, total: data.total }}
      />

      {acceptVisible && (
        <ProviderAcceptAppointmentRequestModal
          visible={acceptVisible}
          request={request}
          onCancel={() => {
            setAcceptVisible(false);
          }}
          onOk={(values) => {
            acceptAppointmentRequestReq.run({ ...values });
          }}
        />
      )}
    </div>
  );
};

export default connect(({ loading }) => ({
  loading,
}))(AppointmentRequestPane);
