import {
  srvPharmacyRecommended, srvSavePharmacyRecommended, srvDelPharmacyRecommended,
} from '@/services/admin';

const Model = {
  namespace: 'pharmacy_recommended',
  state: {
    data: {
      items: [],
      total: 0,
    },
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const res = yield call(srvPharmacyRecommended, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveList', payload: res.data });
    },
    *savePharmacyRecommended({ payload, callback }, { call, put, select }) {
      const res = yield call(srvSavePharmacyRecommended, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *delPharmacyRecommended({ payload, callback }, { call, put, select }) {
      const res = yield call(srvDelPharmacyRecommended, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
  },
  reducers: {
    saveList(state, { payload }) {
      return { ...state, data: payload };
    },
  },
};

export default Model;
