import { srvAdminDetail } from '@/services/admin';

const Model = {
  namespace: 'admin_detail',
  state: {
    detail: {},
  },
  effects: {
    *fetchData({ payload }, { call, put }) {
      const res = yield call(srvAdminDetail, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveData', payload: res.data });
    },
  },
  reducers: {
    saveData(state, { payload }) {
      return { ...state, detail: payload };
    },
  },
};
export default Model;
