import React from 'react';

import ProviderDashboard from './provider_dashboard';
import PatientReview from './patient_review';

const EhrDashboard = (props) => {
  const { location } = props;
  if (location.pathname.indexOf('/ehr_dashboard/patient_review') !== -1) {
    return <PatientReview parentProps={props} />;
  } else {
    return <ProviderDashboard parentProps={props} />;
  }
};

export default EhrDashboard;