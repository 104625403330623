import React,{ useState,useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';

import {Spin, Empty, Space} from 'antd'


const GenderPie = (props) => {
  const {record} = props;
  const [option, setOption] = useState({});

  useEffect(() => {
    setOption({
      title: {
        text: 'Gender Distribution',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical',
        bottom: '0',
        left:'0'
      },
      series: [
        {
          name: 'Gender Form',
          type: 'pie',
          radius: '50%',
          data: record,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]

    })
  },[record])


  return (
    <div>
      <ReactEcharts
      option={option}
      notMerge
    />
    </div>

  );
};

export default GenderPie;
