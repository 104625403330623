import { stringify } from 'querystring';
import { history } from 'umi';
import { message } from 'antd';
import { srvReferrals, srvIntStatusItems } from '@/services/admin';

const Model = {
  namespace: 'referral',
  state: {
    data: {
      items: [],
      total: 0,
    },
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const res = yield call(srvReferrals, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveList', payload: res.data });
    },
  },
  reducers: {
    saveList(state, { payload }) {
      return { ...state, data: payload };
    },
  },
};
export default Model;
