import { stringify } from 'querystring';
import { history } from 'umi';
import { message } from 'antd';

import {
    srvAssignedConsultations,
    srvPatientConsultationReplies,
    srvSendConsultationReply,
    srvAssignConsultation,
    srvUrgentConsultations,
} from '@/services/patient';

import {
    srvIntStatusItems,
    
} from '@/services/admin';

const Model = {
  namespace: 'ehr_consultation',
  state: {
    data: {
      total: 0,
      items: [],
    },
    urgentData: {
      total: 0,
      items: [],
    },
    consultation_types: [],
    replyData: {
      consultation: {},
      consultation_replies: [],
    },
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const res = yield call(srvAssignedConsultations, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveList', payload: res.data });
    },
    *fetchUrgentList({ payload }, { call, put }) {
      const res = yield call(srvUrgentConsultations, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveUrgentList', payload: res.data });
    },
    *fetchConsultationTypes({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'consultation_type' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveConsultationTypes', payload: res.data });
    },
    *fetchConsultationReplies({ payload }, { call, put, select }) {
      const res = yield call(srvPatientConsultationReplies, { id: payload.id });
      if (!res.success) {
        yield put({
          type: 'saveConsultationReplies',
          payload: {
            consultation: {},
            consultation_replies: [],
          },
        });
        return;
      }
      yield put({ type: 'saveConsultationReplies', payload: res.data });
    },
    *replyConsultation({ payload, callback }, { call, put, select }) {
      const res = yield call(srvSendConsultationReply, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *assignConsultation({ payload, callback}, { call, put, select }) {
      const res = yield call(srvAssignConsultation, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    }
  },
  reducers: {
    saveList(state, { payload }) {
      return { ...state, data: payload };
    },
    saveUrgentList(state, { payload }) {
      return { ...state, urgentData: payload };
    },
    saveConsultationTypes(state, { payload }) {
      return { ...state, consultation_types: payload };
    },
    saveConsultationReplies(state, { payload }) {
      return { ...state, replyData: payload };
    },
  },
};
export default Model;
