import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import {connect, history, Link} from 'umi';
import {
  Modal,
  Space,
  Popover,
  Input,
  Table,
  Row,
  Button,
  message,
  Drawer,
  Form,
  Checkbox, Tooltip
} from 'antd';
import moment from 'moment';
import {
  ExclamationCircleOutlined,
  createFromIconfontCN,
} from '@ant-design/icons';
import { useEventEmitter } from 'ahooks';
import {formatUnixDayOnlyInUTCTZ} from '@/utils/xtime';
import Filters from '@/components/Filters';
import defaultSettings from '../../../defaultSettings';
import styles from './index.less';
import {srvApproveReferrals, srvRejectReferrals, srvViewReferrals} from "@/services/admin";
const cx = cn.bind(styles);
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const filters = [
  {
    name: 'patient_name',
    label: 'Patient Name',
    type: 'text',
  },
  {
    name: 'clinician_name',
    label: 'Clinician Name',
    type: 'text',
  },
];

const Referral = (props) => {
  const { dispatch, referral, loading } = props;
  const {data} = referral;
  const [query, setQuery] = useState({});
  const [filterState, setFilterState] = useState({});
  const [page, setPage] = useState(1);
  const [item, setItem] = useState({});
  const [visible, _setVisible] = useState({
    referralHistory: false,
    rejection: false,
    viewReferral: false,})
  const [referralViewURL, setReferralViewURL] = useState('');
  const [referralID, setReferralID] = useState(0)
  const [form] = Form.useForm();
  const [inPersonHistoryURL, setInPersonHistoryURL] = useState()
  const reset$ = useEventEmitter();
  const [rejectionModalShow, setRejectionModalShow] = useState(false);
  const [missingDEA, setMissingDEA] = useState(false);
  const [missingNPI, setMissingNPI] = useState(false);
  const [referralExpired, setReferralExpired] = useState(false);
  const [writeInHasValue, setWriteInHasValue] = useState(false);

  const setVisible = (name, value) => {
    _setVisible((prev) => ({ ...prev, [name]: value }));
  };

  const onApproveClick = (id) => {
    Modal.confirm({
      title: 'Do you want to approve this Referral? Patient will be notified via text message of approval.',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        srvApproveReferrals({id}).then(message.success('referral approved')).then(location.reload())
      },
      onCancel() {},
    });
  };

  const onViewClick = (id) => {
    openViewModal()
    srvViewReferrals({id}).then((res) => {
      const data = res.data
      setReferralViewURL(data)
    })
  };

  const onDrawerClose = () => {

    setVisible('viewReferral', false)
    setReferralViewURL('')
  }

  const openRejectionModal = () => {
    setVisible('referralHistory', false)
    setRejectionModalShow(true)
  }

  const openViewModal = () => {
    setVisible('viewReferral', true)
  }


  const onRejectClick = (values) => {
    setReferralID(values)
    openRejectionModal()
    return values.id

  };

  const rejectionSubmitClicked = (data) => {
    Modal.confirm({
      title: 'Do you want to reject this Referral? Patient will be notified via text message of rejection.',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        srvRejectReferrals({...data}).then(message.success('Rejection and reasons successfully saved')).then(setRejectionModalShow(false)).then(location.reload())
      },
      onCancel() {},
    });


  };

  const columns = [
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      width: 100,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.state - b.state,
      render: (v, r) => {
        let response = 'New'
        if (r.state === 2) {
          response = 'Approved'
        }
        if (r.state === 3) {
          response = 'Rejected'
        }
        return response
        }
    },
    {
      title: 'Patient',
      dataIndex: 'user_name',
      key: 'referral_name',
      ellipsis: true,
      width: 180,
      render: (v, r) => {
        const userName = `${r.user_name}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.user_id}`}>{userName}</Link>
                </div>
              </div>
            }
            title={r.user_name}
            trigger="hover"
          >
            <Link to={`/patient/${r.user_id}`} style={{ color: '#1a1f36', fontWeight: 500 }}>
              {r.user_name}
            </Link>
          </Popover>
        );
      },
    },
    {
      title: 'Date of Visit',
      dataIndex: 'date_of_evaluation',
      key: 'referral_date_of_evaluation',
      ellipsis: true,
      width: 120,
      render: (v, r) => {
        return formatUnixDayOnlyInUTCTZ(r.date_of_evaluation);
      },
    },
    {
      title: 'Clinician',
      dataIndex: 'in_person_clinician',
      key: 'referral_in_person_clinician',
      width: 250,
      ellipsis: true,
    },
    {
      title: 'Date Uploaded',
      dataIndex: 'created_at',
      key: 'referral_created_at',
      width: 100,
      render: (v, r) => {
        return (<span style={{ color: '#1a1f36', fontWeight: 500 }}>
            <Tooltip
              title={
                <div>
                  <p>Upload date and time are shown in PST</p>
                </div>
              }
              placement="bottom"
            >
              {moment.unix(r.created_at).tz('America/Los_Angeles').format('MM/DD/YYYY h:mm A')}
            </Tooltip>
          </span>)
      },
    },
    {
      title: 'Reviewed By',
      dataIndex: 'admin_name',
      key: 'reviewer_name',
      width: 100,
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 80,
      render: (v, r) => {
        return (<Button type={"primary"} onClick={(values) => (
          onViewClick(v.id))
        }>View</Button>)
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 100,
      render: (v, r) => {const id = v.id
        return (<Button onClick={() => (
          onApproveClick(id))
          }>Approve</Button>)
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 100,
      render: (v, r) => {
        const id = v.id
        return <Button onClick={() => (
          onRejectClick(id))}>Reject</Button>
      },
    },
  ];

  useEffect(() => {
    const query = (history.location.state && history.location.state.filterState) || {};
    setFilterState(query);
    setQuery(query);

    setPage(1);
    loadList({ page: 1, page_size: 20, filters: JSON.stringify(query) }, () => {});
    dispatch({ type: 'referral/fetchReferralStatuses' });
  }, []);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => {});
  };

  const loadList = (payload, callback) => {
    dispatch({ type: 'referral/fetchList', payload, callback });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadList({
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: JSON.stringify(query),
    });
  };

  const validate = () => {

  }

  const f = () => {
    for (const v of filters) {
      if (v.name === 'status' && v.items.length === 0) {
      }else if (v.name === 'doctor_tags' && v.items.length === 0) {
      }
    }
    return filters;
  };

  return (
    <div className={cx({ container: true })}>
      <Drawer
        className={cx({ identityDrawer: true })}
        title="Patient Referral"
        width="600"
        placement="right"
        closable={false}
        onClose={onDrawerClose}
        visible={visible.viewReferral}
        destroyOnClose
      >
        <div className={cx({ identity: true })}>
          <embed src={referralViewURL} alt={'Patient Referral'} style={{height: 600, width: 600}}/>
        </div>
      </Drawer>
      {rejectionModalShow &&(
        <>
          <div className="upload-modal" style={{paddingLeft: 30, paddingTop: 5, height: 450, width: 450}}>
            <div style={{fontSize: 16, fontWeight: 'bold', paddingBottom: 0}}>{"Reject the referral"}</div>
            <div>
              <Form onFinish={rejectionSubmitClicked} form={form}>
                <p>Please tell patient the reasons why they have been rejected.</p>
                <Form.Item name={'referral_record_id'} initialValue={referralID}/>
                <Form.Item name={'missing_dea_number'} valuePropName="checked" initialValue={false}>
                  <Checkbox onChange={(e) => setMissingDEA(e.target.checked)}>Missing provider's DEA number</Checkbox>
                </Form.Item>
                <Form.Item name={'missing_npi_number'} valuePropName="checked" initialValue={false}>
                  <Checkbox onChange={(e) => setMissingNPI(e.target.checked)}>Missing provider's NPI</Checkbox>
                </Form.Item>
                <Form.Item name={'referral_expired'} valuePropName="checked" initialValue={false}>
                  <Checkbox onChange={(e) => setReferralExpired(e.target.checked)}>The last in-person exam was 23 months ago</Checkbox>
                </Form.Item>
                <Row style={{paddingBottom: 0}}>
                  <Form.Item name={'rejection_write_in'} initialValue={''}>
                    <Input.TextArea onChange={(e) => {if (e.target.value !== ''){
                    setWriteInHasValue(true)
                    } else {
                      setWriteInHasValue(false)
                    }
                    }}/>
                  </Form.Item>
                </Row>
                <Row>
                  <div style={{}}>
                    <Button onClick={() =>
                    {

                      setRejectionModalShow(false)
                    }}>
                      Cancel
                    </Button>
                  </div>
                  <div >
                    <Button onClick={() => {
                      form.submit();
                    }} disabled={(!missingDEA && !missingNPI && !referralExpired && !writeInHasValue)} type={"primary"} style={{position: "absolute", marginLeft: 230}}>
                      Confirm
                    </Button>
                  </div>
                </Row>
              </Form>
            </div>


          </div>
          <div className="upload-mask"></div>
        </>
      )}
      <div className={cx({ header: true })}>
        <h3>Referrals</h3>
        <Space>
          <Filters reset$={reset$} filters={f()} initialFilters={filterState} onFilter={onFilter} />
        </Space>
      </div>

      <Table
        className="rowSelectTable"
        columns={columns}
        dataSource={data.items}
        rowClassName={(r) => {
          if (r.id === item.id) {
            return 'rowSelected';
          }
          return '';
        }}
        rowKey="id"
        loading={loading.effects['referral/fetchList']}
        onChange={handleTableChange}
        pagination={{ pageSize: 20, current: page, simple: true, total: data.total }}
      />
    </div>
  );
};

export default connect(({ referral, loading }) => ({
  referral,
  loading,
}))(Referral);
