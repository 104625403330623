import React, { useState, useEffect } from 'react';
import cn from 'classnames/bind';
import { connect, Link, history, useSelector } from 'umi';
import { Table, Button, Modal, Popover, Tooltip, Checkbox, message, Tabs } from 'antd';
const { TabPane } = Tabs;
import styles from './index.less';
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });
import defaultSettings from '../../../defaultSettings';
import { createFromIconfontCN, EnvironmentFilled, CalendarFilled } from '@ant-design/icons';
import { formatUnix } from '../../utils/xtime';
import { getPatientName } from '@/utils/utils';
import ConsultationDrawer from '@/components/ConsultationDrawer';
import CallReasonModal from '@/components/PatientInfoPane/CallReasonModal';
import moment from 'moment';

const CoverageConsultation = (props) => {
  const { dispatch, ehr_consultation, loading } = props;
  const { urgentData, consultation_types, replyData } = ehr_consultation;
  const cx = cn.bind(styles);
  const [page, setPage] = useState(1);
  const [callTarget, setCallTarget] = useState('patient');
  const [callPatient, setCallPatient] = useState({});
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const setFieldVisible = (name, value) => {
    _setFieldVisible((prev) => ({ ...prev, [name]: value }));
  };
  const [fieldVisible, _setFieldVisible] = useState({
    callReason: false,
  });
  const { currentUser } = useSelector((state) => state.account);
  const { oncall$ } = useSelector((state) => state.call);
  const columns = [
    {
      title: 'Patient',
      dataIndex: 'user_id',
      key: 'user_id',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        if (r.consultation_type == 13) {
          return ""
        }
        if (!r.patient) {
          return null;
        }
        const userName = getPatientName(r.patient);
        return (
          <Popover
            content={
              <div className="popover-user" onClick={(e) => {
                e.stopPropagation();
              }}>
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.patient.id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      setCallTarget('patient');
                      setFieldVisible('callReason', true);
                      setCallPatient(r.patient);
                    }}
                  >
                    Call Patient
                  </Button>
                </div>
                <div className="popover-user-item birthdate">
                  <CalendarFilled className="icon" />
                  {r.patient.birthdate &&
                    moment.unix(r.patient.birthdate).utc().format('MM/DD/YYYY')}
                </div>
                <div className="popover-user-item sex">
                  <IconFont className="icon" type="icon-sex" />
                  {r.patient.gender}
                </div>
                <div className="popover-user-item state">
                  <EnvironmentFilled className="icon" />
                  {r.patient.state}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
          <p style={{ cursor: "pointer" }} onClick={()=>{
            history.push(`/patient/${r.user_id}`);
          }}>{userName}</p>
          </Popover>
        );
      },
    },
    {
      title: 'Time Assigned',
      dataIndex: 'assigned_at',
      key: 'assigned_at',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return <p>{formatUnix(r.assigned_at)}</p>;
      },
    },
    {
      title: 'Type',
      dataIndex: 'consultation_type',
      key: 'consultation_type',
      ellipsis: true,
      width: 300,
      sorter: true,
      render: (v, r) => {
        return String(r.consultation_type_name)
          .toLowerCase()
          .replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
      },
    },
    {
      title: 'Subject',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      width: 200,
    },
  ];

  const selectRow = (record) => {
    setSelected(record.id);
    record.read = true;
    setVisible(true);
    dispatch({ type: 'ehr_consultation/fetchConsultationReplies', payload: { id: record.id } });
  };

  const onClose = () => {
    setVisible(false);
    setSelected('');
    setPage(1);
    loadList({page: 1, page_size: 20 }, () => {});
  };



  const reloadReplyData = () => {
    loadList({page: 1, page_size: 20 }, () => {});
    dispatch({
      type: 'ehr_consultation/fetchConsultationReplies',
      payload: { id: replyData.consultation.id },
    });
  }
  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSortedInfo(sorter)
    loadList({
      page: pagination.current,
      page_size: pagination.pageSize,
      sort: sorter.order
    });
  };

  const makeCall = (target, reason) => {
    const data = {
      patient_id: callPatient.id,
      from_user_id: currentUser.id,
      target,
      first_name: callPatient.first_name,
      last_name: callPatient.last_name,
      reason,
    };

    oncall$.emit(data);
  };

  useEffect(() => {
    setPage(1);
    loadList({page: 1, page_size: 20 }, () => {});
  }, []);

  const loadList = (payload, callback) => {
    dispatch({ type: 'ehr_consultation/fetchUrgentList', payload, callback });
  };
  return (
    <div className={cx({ container: true })} style={{paddingLeft:'0px'}}>
          { selected != "" &&
            <ConsultationDrawer
              role="doctor"
              hidenAssign={true}
              visible={visible}
              replyData={replyData}
              onClose={onClose}
              onReply={() => { reloadReplyData() }}
              onSolve={()=> { onClose();}}
            />
          }
          <Table
            scroll={{ x: 700 }}
            columns={columns}
            dataSource={urgentData.items}
            rowClassName={(r) => {
              if (r.id == selected) {
                return 'rowSelected';
              }
              if (r.read == false) {
                return styles.rowUnread;
              }
              return '';
            }}
            onRow={(record) => ({
              onClick: () => {
                selectRow(record);
              },
            })}
            rowKey="id"
            loading={loading.effects['ehr_consultation/fetchUrgentList']}
            onChange={handleTableChange}
            pagination={{ pageSize: 20, current: page, simple: true, total: urgentData.total }}
          />
      <CallReasonModal
        visible={fieldVisible.callReason}
        filterFirstReason={true}
        onOk={(reason) => {
          setFieldVisible('callReason', false);
          makeCall(callTarget, reason);
        }}
        onCancel={() => {
          setFieldVisible('callReason', false);
        }}
      />

    </div>
  );
};

export default connect(({ ehr_consultation, loading }) => ({
  ehr_consultation,
  loading,
}))(CoverageConsultation);
