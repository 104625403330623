import React, { useState, useEffect } from 'react';
import { connect } from 'umi';
import { useRequest } from 'ahooks';
import { Button, Modal, Space, Table, message, Dropdown } from 'antd';
import { ExclamationCircleOutlined, EllipsisOutlined } from '@ant-design/icons';

import { srvSavePharmacyRecommended } from '@/services/admin';
import EditPharmacyRecommendedDrawer from './EditPharmacyRecommendedDrawer';
import Filters from '@/components/Filters';

import cn from 'classnames/bind';
import styles from './RecommendedList.less';
const cx = cn.bind(styles);

const RecommendedList = (props) => {
  const {
    dispatch,
    pharmacy_recommended: { data },
    loading,
    active,
  } = props;
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [pharmacyDrawerVisible, setPharmacyDrawerVisible] = useState(false);
  const [pharmacy, setPharmacy] = useState({});

  const filters = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
    },
    {
      name: 'city',
      label: 'City',
      type: 'text',
    },
    {
      name: 'state',
      label: 'State',
      type: 'text',
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'text',
    },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 250,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      ellipsis: true,
      width: 250,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 120,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 80,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 150,
    },
    {
      title: 'Note',
      dataIndex: 'admin_note',
      key: 'admin_note',
      width: 250,
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 50,
      render: (v, r) => {
        return (
          <Dropdown
            overlay={
              <div className={cx({ doctorOpt: true })}>
                <div className={cx({ doctorOptItem: true })} onClick={() => {
                  setPharmacy(r);
                  setPharmacyDrawerVisible(true);
                }}>
                  <a href="#">Edit</a>
                </div>
                <div className={cx({ doctorOptItem: true })} onClick={() => confirmDel(r)}>
                  <a href="#">Delete</a>
                </div>
              </div>
            }
            trigger={['click']}
          >
            <Button size="small">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const loadList = (payload, callback) => {
    dispatch({ type: 'pharmacy_recommended/fetchList', payload, callback });
  };

  useEffect(() => {
    if (active) {
      setPage(1);
      loadList({ page: 1, page_size: 20, filters: JSON.stringify(query) }, () => { });
    }
  }, [active]);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => { });
  };

  const confirmDel = (r) => {
    Modal.confirm({
      title: 'Do you want to del these pharmacy?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch({
          type: 'pharmacy_recommended/delPharmacyRecommended',
          payload: { id: r.id },
          callback: () => {
            loadList({ page, page_size: 20 }, () => { });
          },
        });
      },
      onCancel() { },
    });
  };

  const savePharmacyRecommendedReq = useRequest(srvSavePharmacyRecommended, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        message.success('successfully save');
        loadList({ page: 1, page_size: 20 }, () => { });
        setPharmacyDrawerVisible(false);
      } else {
        message.error(ret.msg);
      }
    },
  });

  const savePharmacyRecommended = (v, cb) => {
    if (v && Object.keys(v).length > 0) {
      savePharmacyRecommendedReq.runAsync(v).then(cb);
    }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadList({
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: JSON.stringify(query)
    });
  };

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Pharmacy Recommended</h3>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              setPharmacy({});
              setPharmacyDrawerVisible(true)
            }}
          >
            Add
          </Button>
          <Filters filters={filters} onFilter={onFilter} />
        </Space>
      </div>

      <Table
        className="rowSelectTable"
        columns={columns}
        dataSource={data.items}
        rowKey="id"
        loading={loading.effects['pharmacy_recommended/fetchList']}
        onChange={handleTableChange}
        pagination={{
          pageSize: 20,
          current: page,
          simple: true,
          total: data.total,
        }}
      />

      {pharmacyDrawerVisible && (
        <EditPharmacyRecommendedDrawer
          visible={pharmacyDrawerVisible}
          loading={savePharmacyRecommendedReq.loading}
          onFinish={savePharmacyRecommended}
          onClose={() => {
            setPharmacyDrawerVisible(false);
          }}
          initialValues={pharmacy}
        />
      )}
    </div>
  );
};

export default connect(({ pharmacy_recommended, loading }) => ({
  pharmacy_recommended,
  loading,
}))(RecommendedList);
