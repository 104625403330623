import { Tree } from 'antd';
import { useState, useEffect } from 'react';

const FormTree = ({ value, onChange, treeData }) => {
  const [parentMap, setParentMap] = useState(new Map());
  const [childrenMap, setChildrenMap] = useState(new Map());

  // Build parent node mapping: maps each node to its parent's key
  const buildParentMap = (data) => {
    const map = new Map();
    const traverse = (node, parentKey) => {
      map.set(node.key, parentKey);
      if (node.children) {
        node.children.forEach(child => traverse(child, node.key));
      }
    };
    data.forEach(node => traverse(node, null));
    return map;
  };

  // Build children node mapping: maps each node to a list of its children's keys
  const buildChildrenMap = (data) => {
    const map = new Map();
    const traverse = (node) => {
      if (node.children) {
        map.set(node.key, node.children.map(child => child.key));
        node.children.forEach(child => traverse(child));
      } else {
        map.set(node.key, []);
      }
    };
    data.forEach(node => traverse(node));
    return map;
  };

  useEffect(() => {
    setParentMap(buildParentMap(treeData));
    setChildrenMap(buildChildrenMap(treeData));
  }, [treeData]);

  // Get the list of parent keys for a given node
  const getParents = (key) => {
    const parents = [];
    let currentKey = key;
    while (currentKey && parentMap.has(currentKey)) {
      currentKey = parentMap.get(currentKey);
      if (currentKey) {
        parents.push(currentKey);
      }
    }
    return parents;
  };

  // Get the list of children keys for a given node
  const getChildren = (key) => childrenMap.get(key) || [];

  // Check if a node is a leaf node
  const isLeaf = (key) => childrenMap.get(key).length === 0;

  // Handle checkbox state changes
  const handleCheck = (checkedKeys, { node }) => {
    const { key } = node;
    let newCheckedKeys = new Set(checkedKeys.checked);

    if (isLeaf(key)) {
      // If it's a leaf node
      if (newCheckedKeys.has(key)) {
        // When selecting a leaf node, add all parent nodes
        const parents = getParents(key);
        parents.forEach(parentKey => newCheckedKeys.add(parentKey));
      } else {
        // When deselecting a leaf node, no additional processing needed
      }
    } else {
      // If it's a parent node
      if (!newCheckedKeys.has(key)) {
        // When deselecting a parent node, remove all child nodes
        const childrenKeys = getChildren(key);
        childrenKeys.forEach(childKey => newCheckedKeys.delete(childKey));
      } else {
        // When selecting a parent node, no additional processing needed
      }
    }

    onChange(Array.from(newCheckedKeys));
  };

  return (
    <Tree
      checkable
      autoExpandParent
      checkStrictly={true}
      checkedKeys={value}
      onCheck={handleCheck}
      treeData={treeData}
    />
  );
};

export default FormTree;
