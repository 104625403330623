import React, { useState } from 'react';
import { Checkbox } from 'antd';

import cn from 'classnames/bind';
import styles from './InitialEvaluationDetailView.less';
const cx = cn.bind(styles);

import { PreConsultationReviewOpts, ADHDReviewOpts, PsychiatricHistoryOpts, PatientVitalsOpts, SocialHistoryOpts, MedicationHistoryOpts } from './meta.js'

const InitialEvaluationDetailView = (props) => {
  const { note } = props;
  const { diagnostic_code, chief_complaint, treatment_goals, other_psychiatric_notes,  other_adhd_patterns, other_diagnosis_notes, allergic_drugs,other_medication_notes} = note;

  const renderPreConsultationReview = () => {
    return PreConsultationReviewOpts.map(v => {
      if (!note[v.key]) return;
      return <li key={v.key} className={cx({ checkbox: true })}>{v.label}</li>
    })
  }

  const renderPsychiatricHistory = () => {
    return PsychiatricHistoryOpts.map(v => {
      if ((!note[v.key]) && (!note.StructNoteCommonData[v.key])) return;
      if ((note[v.key] === true) || (note.StructNoteCommonData[v.key] === true)) return <li key={v.key} className={cx({ checkbox: true })}>{v.label}</li>;
      const text = ['age_of_psychiatric_symptoms', 'age_of_first_treatment', 'sleep_status', 'current_psy_and_ther', 'history_of_present_illness', 'psychotic_history', 'adhd_write_in', 'depression_write_in', 'anxiety_write_in', 'psych_symptoms_write_in', 'current_medications', 'previous_meds', 'psychiatric_history_write_in', 'social_family_history', 'chief_complaint', 'prev_medications', 'mania_hypomania_write_in'];
      const structText =['appointment_start_time', 'appointment_end_time', 'current_pcp', 'family_planning_write_in', 'mood_write_in', 'most_recent_pcp_appointment', 'psychiatric_symptoms_write_in', 'reported_medical_conditions', 'social_family_history', 'observation_appearance', 'observation_speech', 'observation_eye_contact', 'observation_motor_activity', 'observation_affect', 'cognition_orientation_impair', 'cognition_memory_impair', 'cognition_attention', 'perception_hallucinations', 'perception_other', 'thoughts_suicidality', 'thoughts_homicidality', 'thoughts_delusions', 'behavior', 'insight', 'judgement', 'substance_abuse_write_in']
      if (text.includes(v.key)) return <li key={v.key}>{v.label}: {note[v.key]}</li>;
      if (structText.includes(v.key)) return <li key={v.key}>{v.label}: {note.StructNoteCommonData[v.key]}</li>;
      return <li key={v.key} className={cx({ checkbox: true })}>{v.label}: {note[v.key]}</li>
    })
  }

  const renderADHDReview = () => {
    const result = note.review_of_adhd.split(',');
    return ADHDReviewOpts.map(v => {
      if (result.includes(String(v.id))) {
        return <li key={v.id} className={cx({ checkbox: true })}>{v.label}</li>
      }
    })
  }

  return (
    <div className={cx({ view: true })}>
      <h4>Pre-consultation Review ASRS</h4>
      <ul>
        {renderPreConsultationReview()}
      </ul>
      {chief_complaint && (`Chief complaints: ${chief_complaint}`)}
      <br />
      {treatment_goals && (`Goals for treatment: ${treatment_goals}`)}
      <h4>Psychiatric History</h4>
      <ul>
        {renderPsychiatricHistory()}
      </ul>
      {other_psychiatric_notes && (`other_notes: ${other_psychiatric_notes}`)}
      <h4>Patient Vitals</h4>
      <ul>
      {PatientVitalsOpts.map(v => {
        if (!note[v.key]) return;
        return (<li key={v.key}>{`${v.label}: ${note[v.key]}`}</li>);
      })}
      </ul>
      <h4>Social History</h4>
      <ul>
      {SocialHistoryOpts.map(v => {
        if (!note[v.key]) return;
        return (<li key={v.key}>{`${v.label}: ${note[v.key]}`}</li>)
      })}
      </ul>
      <h4>Review of history and symptom patterns consistent with typical ADHD</h4>
      <ul>
        {renderADHDReview()}
        {other_adhd_patterns && (<li className={cx({ checkbox: true })}>Other symptoms or patterns: {other_adhd_patterns}</li>)}
      </ul>

      <h4>Diagnosis</h4>
      Diagnostic Code: {diagnostic_code}
      <br />
      {other_diagnosis_notes && (`other_notes: ${other_diagnosis_notes}`)}
      <h4>Medication History</h4>
      Medicine allergy: {allergic_drugs ? `Allergic to ${allergic_drugs}` : 'No known drug allergies'}
      <ul>
        {MedicationHistoryOpts.map(v => {
          if (!note[v.key]) return;
          return (<li key={v.key}>{`${v.label}: ${note[v.key]}`}</li>);
        })}
      </ul>
      {other_medication_notes && (`other_notes: ${other_medication_notes}`)}
      <h4>Treatment & Prescription</h4>
      Prescription:
      <br />
      <h4>Treatment notes: </h4>
      <div>{note.provider_notes}</div>
    </div>
  )
};

export default InitialEvaluationDetailView;
