import React, { useState } from 'react';
import { Space, Button, Table, Switch, DatePicker, Select} from 'antd';
import cn from 'classnames/bind';
import { FormOutlined } from '@ant-design/icons';
import { useRequest, useMount } from 'ahooks';
import moment from 'moment';
import { ReconciliationOutlined } from '@ant-design/icons';
import AreaChangeLogsDrawer from "@/pages/area/AreaChangeLogsDrawer";


import PharmacyDrawer from '@/components/PharmacyDrawer';
import { srvSetInPersonFollowUpRule, srvInPersonFollowUpRuleList, srvSetInPersonWaiverEndDate, srvAreaList, srvToogleArea, srvToogleAreaInPersonVisit, srvToogleAreaUrgentRefillMetricLock, srvToogleAreaPediatric, srvToogleAreaInsomnia, srvToogleAreaPDMP, srvSetAreaPharmacy, srvToogleAreaHybridCare } from '@/services/admin';
import styles from './index.less';
const cx = cn.bind(styles);

const AreaList = (props) => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState('');
  const [pharmacyVisible, setPharmacyVisible] = useState(false);
  const [followUpRules, setFollowUpRules] = useState([]);
  const listReq = useRequest(srvAreaList, {
    manual: true,
    onSuccess: (ret, params) => {
      setData(ret.data);
    },
  });
  const [messagesVisible, setMessagesVisible] = useState(false);
  const followUpRulesReq = useRequest(srvInPersonFollowUpRuleList, {
    manual: true,
    onSuccess: (ret, params) => {
      setFollowUpRules(ret.data);
    },
  });
  const toggleAreaReq = useRequest(srvToogleArea, {
    manual: true,
    onSuccess: (ret, params) => {
      listReq.run();
    },
  });
  const toggleAreaInPersonVisitReq = useRequest(srvToogleAreaInPersonVisit, {
    manual: true,
    onSuccess: (ret, params) => {
      listReq.run();
    },
  });
  const toggleAreaUrgentRefillMetricLockReq = useRequest(srvToogleAreaUrgentRefillMetricLock, {
    manual: true,
    onSuccess: (ret, params) => {
      listReq.run()
    }
  })
  const toggleAreaPediatricReq = useRequest(srvToogleAreaPediatric, {
    manual: true,
    onSuccess: (ret, params) => {
      listReq.run()
    }
  })
  const toggleAreaInsomniaReq = useRequest(srvToogleAreaInsomnia, {
    manual: true,
    onSuccess: (ret, params) => {
      listReq.run()
    }
  })
  const toggleAreaPDMPReq = useRequest(srvToogleAreaPDMP, {
    manual: true,
    onSuccess: (ret, params) => {
      listReq.run()
    }
  })
  const toggleAreaHybridCareReq = useRequest(srvToogleAreaHybridCare, {
    manual: true,
    onSuccess: (ret, params) => {
      listReq.run()
    }
  })
  const setPharmacyReq = useRequest(srvSetAreaPharmacy, {
    manual: true,
    onSuccess: (ret, params) => {
      listReq.run();
    },
  });

  const setInPersonWaiverEndDate = useRequest(srvSetInPersonWaiverEndDate, {
    manual: true,
    onSuccess: (ret, params) => {
      listReq.run();
    },
  });

  const setInPersonFollowUpRule = useRequest(srvSetInPersonFollowUpRule, {
    manual: true,
    onSuccess: (ret, params) => {
      listReq.run();
    },
  });

  const columns = [
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Default Pharmacy',
      dataIndex: 'pharmacy_id',
      key: 'pharmacy_id',
      ellipsis: true,
      render: function (t, r) {
        const info = JSON.parse(r.pharmacy_info.length ? r.pharmacy_info : '{}');
        return (
          <>
            <Space>
              <div className={cx({ pharmacyInfo: true })}>{info.pharmStoreName || info.name}</div>
              <Button
                type="link"
                onClick={() => {
                  setSelected(r.id);
                  setPharmacyVisible(true);
                }}
              >
                <FormOutlined />
              </Button>
            </Space>
          </>
        );
      },
    },
    {
      title: 'END DATE OF IN-PERSON WAIVER',
      dataIndex: 'in_person_waiver_end_date',
      key: 'in_person_waiver_end_date',
      ellipsis: true,
      render: function (v, r) {
        const dateValue = v === 0 ? null : moment.unix(v);
        return(
          <DatePicker value={dateValue} onChange={(date) => {
            setInPersonWaiverEndDate.run({id: r.id,waiver_end_date:date !== null ? date.unix() : 0})

          }}/>
        )
      }
    },
    {
      title: 'IN-PERSON FOLLOW-UP RULE',
      dataIndex: 'in_person_follow_up_rule_id',
      key: 'in_person_follow_up_rule',
      render: function (v, r) {
        return(
          <Select placeholder="Follow Up Rules" value={r.in_person_follow_up_rule_name} style={{ width: '50%' }} onChange={
            (_,v) => {
              setInPersonFollowUpRule.run({id: r.id,in_person_follow_up_rule_id:parseInt(v.key), in_person_follow_up_rule_name:v.value})
            }
          }>
              {
                followUpRules.map((v)=>{
                  const text = v.rule_name
                  return (
                    <Select.Option key={v.id} value={v.rule_name} >
                      {text}
                    </Select.Option>
                  );
                })
              }
          </Select>
        )
      }
    },
    {
      title: 'urgent refill pause initial',
      dataIndex: 'urgent_refill_metric_lock',
      key: 'urgent_refill_metric_lock',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        return (
          <Switch
            checked={v}
            onChange={(checked) => {
              toggleAreaUrgentRefillMetricLockReq.run({ id: r.id });
            }}
          />
        );
      },
    },
    {
      title: 'PEDIATRIC',
      dataIndex: 'support_pediatric',
      key: 'support_pediatric',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        return (
          <Switch
            checked={v}
            onChange={(checked) => {
              toggleAreaPediatricReq.run({ id: r.id });
            }}
          />
        );
      },
    },
    {
      title: 'INSOMNIA',
      dataIndex: 'support_insomnia',
      key: 'support_insomnia',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        return (
          <Switch
            checked={v}
            onChange={(checked) => {
              toggleAreaInsomniaReq.run({ id: r.id });
            }}
          />
        );
      },
    },
    {
      title: 'PDMP',
      dataIndex: 'support_pdmp',
      key: 'support_pdmp',
      ellipsis: true,
      width: 60,
      render: (v, r) => {
        return (
          <Switch
            checked={v}
            onChange={(checked) => {
              toggleAreaPDMPReq.run({ id: r.id });
            }}
          />
        );
      },
    },
    {
      title: 'Clinicians',
      dataIndex: 'doctor_count',
      key: 'doctor_count',
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      key: 'enabled',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        return (
          <Switch
            checked={v}
            onChange={(checked) => {
              toggleAreaReq.run({ id: r.id });
            }}
          />
        );
      },
    },

    {
      title: 'In-Person Visit',
      dataIndex: 'in_person_enabled',
      key: 'in_person_enabled',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        return (
          <Switch
            checked={v}
            disabled={!r.enabled}
            onChange={(checked) => {
              toggleAreaInPersonVisitReq.run({ id: r.id });
            }}
          />
        );
      },
    },
    {
      title: 'Hybrid Care',
      dataIndex: 'support_hybrid_care',
      key: 'support_hybrid_care',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        return (
          <Switch
            disabled={!r.enabled}
            checked={v}
            onChange={(checked) => {
              toggleAreaHybridCareReq.run({ id: r.id });
            }}
          />
        );
      },
    }
  ];

  useMount(() => {
    listReq.run({});
    followUpRulesReq.run({})
  });

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3 style={{ display: 'inline', marginRight: '10px' }}>Areas
                  {
             (
              <Button type="link" style={{color:'#808080'}} icon={<ReconciliationOutlined />} onClick={() => {
                setMessagesVisible(true);
               }} />
            )
          }
        </h3>

      </div>
      <AreaChangeLogsDrawer
        messagesVisible={messagesVisible}
        setMessagesVisible={setMessagesVisible}
      />
      <Table
        dataSource={data}
        columns={columns}
        rowKey="id"
        rowClassName={(r) => {
          if (r.id == selected) {
            return 'rowSelected';
          }
          return '';
        }}
        loading={listReq.loading || toggleAreaInPersonVisitReq.loading ||toggleAreaReq.loading || setPharmacyReq.loading || toggleAreaHybridCareReq.loading}
        pagination={false}
      />
      <PharmacyDrawer
        visible={pharmacyVisible}
        onFinish={(pharmacyID, pharmacyInfo) => {
          setPharmacyReq.run({
            id: selected,
            pharmacy_id: pharmacyID,
            pharmacy_info: JSON.stringify(pharmacyInfo),
          });
          setPharmacyVisible(false);
          setSelected('');
        }}
        onClose={() => {
          setPharmacyVisible(false);
        }}
      />
    </div>
  );
};
export default AreaList;
