import React, { useState, useEffect } from 'react';

import { List, Table, Drawer } from 'antd';

import { formatUnix } from '@/utils/xtime';
import { useRequest, useMount } from 'ahooks';

import { srvRescheduleLogs } from '@/services/patient';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

const CalendarMessagesDrawer = (props) => {

  const { dateRange, messagesVisible, setMessagesVisible,doctor_id } = props;

  const [rescheduleLogs, setRescheduleLogs] = useState({ total: 0, items: [], });

  const fetchRescheduleLogsReq = useRequest(srvRescheduleLogs, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data && ret.data.items) {
          setRescheduleLogs(ret.data);
        }
      }
    },
  });

  useEffect(() => {
    if (messagesVisible && dateRange && dateRange.start && dateRange.end) {
      fetchRescheduleLogsReq.run({
        page: 1, page_size: 10000, filters: JSON.stringify({ "created_at[gte]": dateRange.start, "created_at[lte]": dateRange.end }),doctor_id:doctor_id
      });
    }
  }, [messagesVisible, dateRange])

  const columns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: false,
      width: 180,
      render: (v, r) => {
        const time = r.updated_at > r.created_at ? r.updated_at : r.created_at;
        return formatUnix(time);
      },
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: false,
      // width: 150,
      render: (v, r) => {
        if (r.log_type === 4||r.log_type===5){
          return r.content;
        }
        let reschedule_log_type = '';
        if (r.log_type == 1) {
          reschedule_log_type = 'received your rescheduling request but has not confirmed.';
        } else if (r.log_type == 2) {
          reschedule_log_type = `has been rescheduled to ${formatUnix(r.new_appointed_at)}`;
        } else if (r.log_type == 3) {
          reschedule_log_type = 'did not select your other available time and has been assigned to another provider.';
        }
        return (
          <span>Patient {r.patient_name} ({String(r.appointment_type_name).replace('(', ' ').replace(')', '')}) {reschedule_log_type}</span>
        );
      },
    },
  ];

  return (
    <Drawer width={700} title="Change Log" visible={messagesVisible} size="large" placement="right" onClose={() => { setMessagesVisible(false) }}>

      <Table
        columns={columns}
        dataSource={rescheduleLogs.items}
        showHeader={false}
        bordered={false}
        pagination={false}
        rowKey="id"
        loading={fetchRescheduleLogsReq.loading}
      />

    </Drawer>
  );
};

export default CalendarMessagesDrawer;
