import { stringify } from 'querystring';
import { history } from 'umi';
import { message } from 'antd';
import { srvPatients, srvStates, srvTransferWaitlistPatients, } from '@/services/admin';

const Model = {
  namespace: 'patient',
  state: {
    data: {
      items: [],
      total: 0,
    },
    states: [],
    transfer_waitlist: {
      items: [],
      total: 0,
    }
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const res = yield call(srvPatients, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveList', payload: res.data });
    },
    *fetchStates({ payload }, { call, put }) {
      const res = yield call(srvStates, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveStates', payload: res.data });
    },
    *fetchTransferWaitlist({ payload }, { call, put }) {
      const res = yield call(srvTransferWaitlistPatients, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveTransferWaitlist', payload: res.data });
    },
  },
  reducers: {
    saveList(state, { payload }) {
      return { ...state, data: payload };
    },
    saveStates(state, { payload }) {
      return { ...state, states: payload };
    },
    saveTransferWaitlist(state, { payload }) {
      return { ...state, transfer_waitlist: payload };
    },
  },
};
export default Model;


