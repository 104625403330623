import React  from 'react';
import { Table } from 'antd';
import { formatUnix} from '../../utils/xtime';


const MembershipExtendHistory = (props) => {
  const {MembershipExtendHistorys, 
    loading,
    onChange,
    pagination,
  } = props

  function formatDate(timestamp) {  
    const date = new Date(timestamp*1000);  
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];  
    const day = date.getDate();  
    const month = months[date.getMonth()];  
  
    let ordinalSuffix = 'th';  
    if (day % 10 === 1 && day % 100 !== 11) {  
        ordinalSuffix = 'st';  
    } else if (day % 10 === 2 && day % 100 !== 12) {  
        ordinalSuffix = 'nd';  
    } else if (day % 10 === 3 && day % 100 !== 13) {  
        ordinalSuffix = 'rd';  
    }  
  
    return `${month} ${day}${ordinalSuffix}`;  
} 
  const columns = [
    {
      title: 'Event',
      key: 'id',
      dataIndex: 'content',
      width: 250,
      render: (v, r) => {
        var operator='admin';
        if(r.operator_id==='0'){
          operator='robot';
        }
        const actionTime=formatDate(r.created_at);
        const origin_date=formatDate(r.origin_date);
        const target_date=formatDate(r.target_date);
        var  content=actionTime+' '+operator+' has extend the patient’s billing date for '+r.weeks+' week （from '+origin_date+' to '+target_date+ ')'
        return <>
        <span>{content}</span>
      </>
      },
    },

  ];
  return (
    <div style={{width: "730px"}}>
    <Table
          columns={columns}
          dataSource={MembershipExtendHistorys}
          rowKey="id"
          loading={loading}
          onChange={onChange}
          pagination={pagination}
        />
    </div>
  )
}

export default MembershipExtendHistory;
