import React, { useState, useEffect } from 'react';
import { Form, Input, Space } from 'antd';
import moment from 'moment';

import { useMount, useUpdateEffect, useSetState } from 'ahooks';

const { TextArea } = Input;

const RatingScalesReviewedFormItems = (props) => {
  const { form, patient, draft, setDraft, initialValue } = props;

  return (
    <>
      <h4 style={{ marginBottom: '15px' }}>Date of submitted screenings: {patient?.intake_form?.updated_at ? moment.unix(patient?.intake_form?.updated_at).format('MM/DD/YYYY') : ''}</h4>
      <Form.Item
        name="asrsa"
        label={'ASRS Part A'}
      >
        <TextArea rows={3} maxLength={1000} />
      </Form.Item>
      <Form.Item
        name="asrsb"
        label={'ASRS Part B'}
      >
        <TextArea rows={3} maxLength={1000} />
      </Form.Item>
      <Form.Item
        name="phq_9"
        label={'PHQ-9'}
      >
        <TextArea rows={3} maxLength={1000} />
      </Form.Item>
      <Form.Item
        name="gad_7"
        label={'GAD-7'}
      >
        <TextArea rows={3} maxLength={1000} />
      </Form.Item>
    </>
  )
};

export default RatingScalesReviewedFormItems;
