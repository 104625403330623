import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Table, Button, Row, Col, Alert, Space, Dropdown, Tooltip, message, Checkbox } from 'antd';
import { createFromIconfontCN, EllipsisOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useRequest, useMount, useUpdateEffect, useEventEmitter, useSetState } from 'ahooks';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { srvFetchDoctorPatientPharmacistNoteTemplate } from '@/services/patient';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);


const PharmacistNoteTemplate = (props) => {
  const { active, id } = props;

  const [template, setTemplate] = useState({});
  const [copyStr, setCopyStr] = useState('');

  const fetchDoctorPatientPharmacistNoteTemplateReq = useRequest(srvFetchDoctorPatientPharmacistNoteTemplate, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setTemplate(ret.data);
        setCopy(ret.data);
      }
    }
  })

  const setCopy = (data) => {
    let text = '';
    if (data.last_seen_date!='') {
      text += 'Last seen date: ' + data.last_seen_date + '\n';  
    }
    if (data.get_medicine_mode_name) {
      text += 'Patient request: ' + data.get_medicine_mode_name + '\n';  
    }
    setCopyStr(text);
  }

  const onCopy = () => {
    copy(copyStr, {
      debug: true,
      message: 'copied',
      format: 'text/plain',
    });
    message.info('Copied');
  };

  useEffect(() => {
    if (id) {
      fetchDoctorPatientPharmacistNoteTemplateReq.run({ id })
    }
  }, [id, active])


  return (
    <div className={cx({ pharmacistNote: true })}>
      <h4>
        Template for Pharmacist note
        <Tooltip title={"template for pharmacist note"}>
          <QuestionCircleOutlined style={{ color: "#5469D4", marginLeft: '5px' }} />
        </Tooltip>
      </h4>
      {template.last_seen_date!='' && (
        <p><b>Last seen date: </b>{template.last_seen_date}</p>
      )}
      {template.get_medicine_mode_name === 'Delivery' && (
        <p><b>Patient request: </b>{template.get_medicine_mode_name}</p>
      )}
      {template.has_pending_bridge_refill && (
        <p>patient is moving, need last bridge refill before transfer</p>
      )}
      <p className={cx({ copyInfo: true })} style={template.is_rxout_pharmacy_flag ? {height:'45px'} : null}>
        Please paste all information in Rxnt Pharmacist note.        <Button
          type="primary"
          onClick={onCopy}
          disabled={copyStr == ''}
        >
          Copy all information above
        </Button><br />
      <span style={{color:'black',fontSize:'13px'}}>*If the Last seen date is over 6 months, please request a follow up with patient before prescribing.</span>
      </p>
    </div>
  )
}

export default PharmacistNoteTemplate;
