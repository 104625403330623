import React, { useState, useEffect } from 'react';
import { Modal, List } from 'antd';

import { useRequest } from 'ahooks';
import moment from 'moment';
import { formatUnix } from '@/utils/xtime.js';

import styles from './index.less';
import cn from 'classnames/bind';
const cx = cn.bind(styles);

import { srvDoctorAppointmentRequestPreferredTimes } from '@/services/patient';

const ProviderAcceptAppointmentRequestModal = (props) => {

  const { visible, request, onCancel, onOk } = props;

  const [appointmentType, setAppointmentType] = useState(0);
  const [appointmentPreferredAt, setAppointmentPreferredAt] = useState(0);
  const [times, setTimes] = useState([]);

  useEffect(() => {
    if (request && request.id) {
      setAppointmentType(request.appointment_type)
      appointmentRequestPreferredTimesReq.run({ id: request.id })
    }
  }, [request]);

  const appointmentRequestPreferredTimesReq = useRequest(srvDoctorAppointmentRequestPreferredTimes, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        const times = [];
        for (const day of ret.data?.dates) {
          for (const v of day.times) {
            times.push(v);
          }
        }
        setTimes(times);
        setAppointmentPreferredAt(times[0]);
      }
    },
  });

  const onAppointmentChange = (v) => {
    setAppointmentPreferredAt(v);
  };

  const renderItem = (v) => {
    const start_time = formatUnix(v, 'MM/DD/YYYY hh:mm A')
    let end = moment.unix(v);
    if (appointmentType === 2 || appointmentType === 7 || appointmentType === 13 || appointmentType === 14) {
      end = end.add('10', 'minutes');
    } else if (appointmentType === 5 || appointmentType === 20 || appointmentType === 21) {
      end = end.add('50', 'minutes');
    } else if (appointmentType === 6) {
      end = end.add('25', 'minutes');
    } else if (appointmentType === 11) {
      end = end.add('40', 'minutes');
    } else if (appointmentType === 15 || appointmentType === 17 || appointmentType === 22 || appointmentType === 23) {
      end = end.add('45', 'minutes');
    } else if (appointmentType === 16 || appointmentType === 18) {
      end = end.add('30', 'minutes');
    } else {
      end = end.add('25', 'minutes');
    }
    const end_time = formatUnix(end.unix(), 'hh:mm A');
    return (
      <a
        href='#'
        className={cx({ appointmentPreferredTime: true, active: v == appointmentPreferredAt })}
        onClick={() => {
          onAppointmentChange(v);
        }}
      >
        {start_time} - {end_time}
      </a>
    )
  }

  return (
    <Modal title="Accept Appointment"
      width={620}
      visible={visible}
      okText="Confirm"
      onOk={() => onOk({
        request_id: request?.id,
        appointment_preferred_at: appointmentPreferredAt,
      })}
      okButtonProps={{ style: { display: times.length > 0 ? 'inline' : 'none' } }}
      onCancel={onCancel}
    >
      <h3 style={{ fontWeight: 'bold' }}>
        {times.length > 0 ? 'Please confirm these appointment details:' : 'Unfortunately, you are fully booked during the time slots requested by the patient.'}
      </h3>
      <p>Patient: {request?.patient_name}</p>
      <p>Select Appointment Date/Time:</p>
      <List
        grid={{ column: 2 }}
        pagination={times.length > 0 ? {
          pageSize: 20,  total: times.length, showSizeChanger: false, showQuickJumper: false
        } : false}
        locale={{ emptyText: ' ' }}
        dataSource={times}
        renderItem={renderItem}
        loading={appointmentRequestPreferredTimesReq.loading}
      />
      <p style={{ marginTop: '15px' }}>
        {times.length > 0 ? 'This action will send the patient a notification request to confirm.' : 'You are unable to accept this patient’s request.'}
      </p>
    </Modal>
  );
};

export default ProviderAcceptAppointmentRequestModal;
