import { stringify } from 'querystring';
import { history } from 'umi';
import { message } from 'antd';
import { srvTreatmentRequests, srvIntStatusItems, srvAdminUpdateTreatmentRequestPDMP } from '@/services/admin';

const Model = {
  namespace: 'treatment_request',
  state: {
    data: {
      total: 0,
      new_total: 0,
      items: [],
    },
    treatment_request_statuses: [],
    disputeData: {
      total: 0,
      new_total: 0,
      items: [],
    },
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const res = yield call(srvTreatmentRequests, payload);
      if (!res.success) {
        return;
      }
      if(payload.status===6){
        yield put({ type: 'saveDisputeList', payload: res.data });
        return;
      }
      yield put({ type: 'saveList', payload: res.data });
    },
    *fetchTreatmentRequestStatuses({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'treatment_request_status' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveTreatmentRequestStatuses', payload: res.data });
    },
    *updateTreatmentRequestPDMP({ payload, callback }, { call, put }) {
      const res = yield call(srvAdminUpdateTreatmentRequestPDMP, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
  },
  reducers: {
    saveList(state, { payload }) {
      return { ...state, data: payload };
    },
    saveTreatmentRequestStatuses(state, { payload }) {
      return { ...state, treatment_request_statuses: payload };
    },
    saveDisputeList(state, { payload }) {
      return { ...state, disputeData: payload };
    },
  },
};
export default Model;

