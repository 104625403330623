import React, { useEffect, useState } from 'react';
import {Button, Table, Modal, Space, Dropdown, Row, Col, Tooltip, Tag} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import momenttemp from 'moment';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import { downloadDocument } from '@/utils/utils';
import { formatAppointmentAt } from '../../utils/xtime';

import CheckPDMPPane from '@/components/PDMPPane';
import RescheduleAppointmentWithTimeSlotsModal from '@/components/RescheduleAppointmentWithTimeSlotsModal';
import AppointmentQuestionResponseDrawer from '@/components/AppointmentQuestionResponseDrawer';

import {
  srvMarkAppointmentNoShow,
  srvMarkProviderAppointmentNoShow,
  srvPatientUpcomingAppointments,
  srvPatientPastAppointments,
  revertAppointmentStatus,
} from '@/services/patient';
import styles from './Appointments.less';
const cx = cn.bind(styles);

const Appointments = (props) => {
  const { id, active } = props;
  const [upcoming, setUpcoming] = useState([]);
  const [past, setPast] = useState([]);
  const [noShowConfirmModal, setNoShowConfirmModal] = useState(false)
  const [providerNoShowConfirmModal, setProviderNoShowConfirmModal] = useState(false);
  const [rescheduleAppointmentModal, setRescheduleAppointmentModal] = useState(false);
  const [revertStatusConfirmModal, setRevertStatusConfirmModal] = useState(false)
  const [currentAppointmentID, setCurrentAppointmentID] = useState("")
  const [currentAppointmentDoctorName, setCurrentAppointmentDoctorName] = useState("")
  const [showAppointmentQuestionResponseDrawer, setShowAppointmentQuestionResponseDrawer] = useState(false);

  const upcomingReq = useRequest(srvPatientUpcomingAppointments, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setUpcoming(ret.data);
      }
    },
  });

  const pastReq = useRequest(srvPatientPastAppointments, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setPast(ret.data);
      }
    },
  });

  const noshowReq = useRequest(srvMarkAppointmentNoShow, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setNoShowConfirmModal(false)
        setCurrentAppointmentID("")
        refresh();
      }
    },
    onError: () => {
      setNoShowConfirmModal(false)
      setCurrentAppointmentID("")
      refresh();
    }
  });

  const refresh = () => {
    if (active) {
      upcomingReq.run({ id });
      pastReq.run({ id });
    }
  };

  useMount(() => {
    refresh();
  });

  useUpdateEffect(() => {
    refresh();
  }, [active, id]);

  const markNoShow = (id) => {
    noshowReq.run({ id });
  };

  const markProviderNoShow = (id) => {
    srvMarkProviderAppointmentNoShow({ id })
      .then(() => {
        setProviderNoShowConfirmModal(false)
        setCurrentAppointmentID("")
        refresh();
      })
      .catch(() => {
        setProviderNoShowConfirmModal(false)
        setCurrentAppointmentID("")
      })
  };

  const revertStatus = (id) => {
    revertAppointmentStatus({ id }).then(() => {
      setRevertStatusConfirmModal(false)
      setCurrentAppointmentID("")
      refresh();
    }).catch((err) => {
      setRevertStatusConfirmModal(false)
      setCurrentAppointmentID("")
    })
  }

  const columns = [
    {
      title: 'Appointment Time',
      dataIndex: 'appointed_at',
      key: 'appointed_at',
      width: 300,
      render: (v, r) => {
        return (
            <div className={cx({timeSlot: r.admin_flag })} style={{ display: 'flex', alignItems: 'center' }}>
              
              {r.admin_flag ? (
                  <Tooltip
                      title={
                        <div>
                          <p>schedule by: {r.action_user_name}</p>
                        </div>
                      }
                      placement="bottom"
                  >
                    {formatAppointmentAt(r)}
                  </Tooltip>
              ) : (
                  <>
                    {formatAppointmentAt(r)}
                  </>
              )}
            </div>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'appointment_type_name',
      key: 'appointment_type_name',
      ellipsis: true,
      width: 400,
    },
    {
      title: 'Status',
      dataIndex: 'status_name',
      ellipsis: true,
      width: 100,
      key: 'status_name',
    },
    {
      title: 'Clinician',
      dataIndex: 'doctor_name',
      ellipsis: true,
      width: 200,
      key: 'provider',
      render: (v, r) => {
        return (
          <div>
            {v + (!r.doctor_title ? '' : `, ${r.doctor_title}`)}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      ellipsis: true,
      width: 160,
      key: 'id',
      render: (v, r) => {
        let statusButton = '';
        if (r.status == 2 && r.appointment_type != 19) {
          statusButton = (momenttemp().unix() > r.appointed_at ? 
            (<Dropdown
              overlay={
                <div className={cx({ opt: true })}>
                  <div
                    className={cx({ optItem: true })}
                    onClick={() => {
                      setCurrentAppointmentID(r.id)
                      setNoShowConfirmModal(true)
                    }}
                  >
                    <a href="#">Patient No-Show</a>
                  </div>
                  <div
                    className={cx({ optItem: true })}
                    onClick={() => {
                      setCurrentAppointmentID(r.id)
                      setProviderNoShowConfirmModal(true)
                    }}
                  >
                    <a href="#">I was unable to join the appointment</a>
                  </div>
                </div>
              }
              trigger={['click']}
            >
                <Button type="danger">No Show</Button>
            </Dropdown>):null
          );
        }
        return (
          <Space>
            {r.pdmp_object_key && (
              <Button
                type="primary"
                danger
                onClick={() => {
                  downloadDocument(r.pdmp_object_key);
                }}
              >
                View PDMP
              </Button>
            )}
            {statusButton}
            {r.appointment_type == 19 && <>
              <Button
                type="primary"
                onClick={()=>{
                  setCurrentAppointmentID(r.id)
                  setCurrentAppointmentDoctorName(r.doctor_name)
                  setShowAppointmentQuestionResponseDrawer(true)
                }}
              >View Video</Button>
            </>}
          </Space>
        )
      },
    },
    {
      title: 'Admin Note',
      ellipsis: true,
      width: 200,
      dataIndex: 'pdmp_notes',
      key: 'pdmp_notes',
    },
    {
      title: 'Location',
      ellipsis: false,
      width: 200,
      dataIndex: 'location',
      key: 'location',
      render: (v, r) => {
        return <>
          {
            (r.city != '' || r.state != '') &&
            <span>{r.doctor_address_line1} {r.doctor_address_line2} {r.doctor_city} {r.doctor_state}</span>
          }
        </>
      }
    },
  ];
  return (
    <div className={cx({ container: true })}>
      <Row justify="space-between" style={{ borderBottom: '1px solid #e3e8ee' }}>
        <Col>
          <h3 style={{ boxShadow: 'none' }}>Future Appointments</h3>
        </Col>
        <Col>
          <CheckPDMPPane id={id} />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={upcoming}
        rowKey="id"
        loading={upcomingReq.loading}
        scroll={{ x: true }}
        pagination={false}
      />
      <br />
      <h3>Past Appointments</h3>
      <Table
        columns={columns}
        dataSource={past}
        rowKey="id"
        scroll={{ x: true }}
        loading={pastReq.loading}
        pagination={{ pageSize: 5, simple: true }}
      />
      <Modal title="Confirm No Show"
        visible={noShowConfirmModal}
        okText="Submit"
        onOk={() => markNoShow(currentAppointmentID)}
        onCancel={() => {
          setCurrentAppointmentID("")
          setNoShowConfirmModal(false)
        }}>
        <p>The patient did not show up within 10 minutes after their appointment time.</p>
      </Modal>
      <Modal
        title="Confirm No Show"
        width={800}
        visible={providerNoShowConfirmModal}
        okText="Provider No Show Without Rescheduling"
        okButtonProps={{ style: { backgroundColor: "#949DA3", borderColor: "#e5e7eb" } }}
        onOk={() => markProviderNoShow(currentAppointmentID)}
        onCancel={() => {
          setCurrentAppointmentID("")
          setProviderNoShowConfirmModal(false)
        }}
      >
        <p>In the event that you are unable to attend the scheduled appointment, we appreciate your consideration in marking arrangements for a
          <Button type="link" size='small' onClick={() => {
          setRescheduleAppointmentModal(true)
          setProviderNoShowConfirmModal(false)
          }}>reschedule.</Button>
          This ensures an optimal treatment experience for the patient. Your cooperation is valued. Thank you.
          </p>
      </Modal>
      <RescheduleAppointmentWithTimeSlotsModal
        visible={rescheduleAppointmentModal}
        onRefresh={() => {
          refresh()
          setCurrentAppointmentID("")
          setRescheduleAppointmentModal(false)
        }}
        onCancel={() => {
          setCurrentAppointmentID("")
          setRescheduleAppointmentModal(false)
        }}
        activeAppointmentId={currentAppointmentID}
      />
      <Modal title="Confirm Status Revert"
        visible={revertStatusConfirmModal}
        okText="Submit"
        onOk={() => revertStatus(currentAppointmentID)}
        onCancel={() => {
          setCurrentAppointmentID("")
          setRevertStatusConfirmModal(false)
        }}>
        <p>Are you sure you want to revert this appointment to a confirmed status?</p>
      </Modal>
      { showAppointmentQuestionResponseDrawer &&
        <AppointmentQuestionResponseDrawer
          onClose={()=> { setShowAppointmentQuestionResponseDrawer(false) }}
          appointmentId={currentAppointmentID}
          doctorName={currentAppointmentDoctorName}
        />
      }
    </div>
  );
};

export default Appointments;
