import React from 'react';
// import classNames from 'classnames';
import { Layout } from 'antd';

// import Settings from '../../../config/defaultSettings';
import GlobalHeader from './GlobalHeader';
import './Header.less';

const { Header } = Layout;

export default (props) => {
  const { onCollapse, className: propsClassName, style } = props;

  return (
    <Header
      style={{
        padding: 0,
        width: '100%',
        zIndex: 9,
        right: undefined,
        height: 56,
        ...style,
      }}
      theme="dark"
      className={propsClassName}
    >
      <GlobalHeader onCollapse={onCollapse} {...props} />
    </Header>
  );
};
