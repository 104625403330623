import React, { useState, useEffect } from 'react';
import cn from 'classnames/bind';
import { connect,useAccess } from 'umi';
import {Tabs, Button, Popover, Tooltip, Space, Modal} from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { QuestionCircleOutlined } from '@ant-design/icons';
import BigCalendar from '@/components/BigCalendar/Calendar';
import Appointments from './appointments';
import Consultations from './consultations';
import DoctorInfo from './doctor_info';
import TreatmentPlans from './treatment_plans';
import Patients from './patients';
import TreatmentRequests from './treatment_requests';
import ProviderDashboard from './ProviderDashboard';
import ManageAvailability from './../ehr_calendar/ManageAvailability'
import CalendarMessagesDrawer from './../ehr_calendar/CalendarMessagesDrawer';
import { useRequest,useSetState } from 'ahooks';
import {
  srvDoctorDetail,
} from '@/services/admin';
import {
  srvCheckDoctorGoogleCalendarResync,
  srvResetTimeslot, srvSyncAppointmentToGoogleCalendar,
  srvThisWeekRule,
} from '../../services/account';
import styles from './index.less';
import RescheduleAppointment from '@/components/RescheduleAppointment';
const { TabPane } = Tabs;
const cx = cn.bind(styles);


const DoctorDetail = (props) => {
  const { dispatch, doctor_detail, loading } = props;
  const { detail } = doctor_detail;
  const [activeKey, setActiveKey] = useState('1');
  const [acuityActiveKey, setAcuityActiveKey] = useState('1');
  const [doctorDetail,setDoctorDetail]=useState(null);
  const [loadManageAvailability, setLoadManageAvailability] = useState(false);
  const [resetTimeSlotsVisible, setResetTimeSlotsVisible] = useState(false);
  const [resetTimeSlotsVisibleLoading, setResetTimeSlotsVisibleLoading] = useState(false);
  const [showResyncGoogleCalendarVisible, setShowResyncGoogleCalendarVisible] = useState(false);
  const [resyncGoogleCalendarVisible, setResyncGoogleCalendarVisible] = useState(false);
  function toggleModal() {
    setState({ helpModalOpen: !state.helpModalOpen });
  }
  const [state, setState] = useSetState({
    helpModalOpen: false,
  });
  const id = props.match.params.id;
  const [messagesVisible, setMessagesVisible] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [rescheduleVisible, setRescheduleVisible] = useState(false);
  const [appointment, setAppointment] = useState({});
  const [ruleData, setRuleData] = useState([])
  const [removeBlockedVisible, setRemoveBlockedVisible] = useState(false);
  const [removeBlockedId, setRemoveBlockedId] = useState(0);
  const access=useAccess();
  const [showSetUpCompleteVisible, setShowSetUpCompleteVisible] = useState(true);
  useEffect(() => {
    dispatch({ type: 'doctor_detail/fetchData', payload: { id }, callback: () => {} });
    srvCheckDoctorGoogleCalendarResyncReq.run();
    const start = moment().add('-15', 'days').unix();
    const end = moment().add('30', 'days').unix();
    setDateRange({
      start: moment().add('-15', 'days').unix(),
      end: moment().add('30', 'days').unix(),
    });
    dispatch({
      type: 'doctor_detail/fetchCalendarAppointments',
      payload: {
        doctor_id: id,
        start,
        end,
      },
    });
    dispatch({
        type: 'doctor_detail/fetchDoctorBlocks',
        payload: {
          doctor_id: id,
          start,
          end,
        },
    });
    const rangeRuleParam = {
      start: start - 172800,
      end: end + 172800,
      doctor_id: id,
    }
    getDateRangeRuleReq.run(rangeRuleParam);
    // reset active tab to Clinician Info
    setActiveKey('1')
  }, [id]);

  const sendReply = (payload, callback) => {
    dispatch({ type: 'doctor_detail/replyConsultation', payload, callback });
  };
  const onSelectEvent = (arg) => {
    if (access.adminAccesser) {
      return
    }
    if (arg.isBlock) {
      // show block remove alert
      setRemoveBlockedId(arg.id);
      // setRemoveBlockedVisible(true);
      Modal.confirm({
        title: 'Do you want to remove this Block?',
        content: '',
        onOk() {
          onBlockRemove();
        },
        onCancel() {
          console.log('');
        },
      });
      return
    }
    if (arg.appointment_status == 2) {
      setAppointment(arg);
      loadRescheduleCalendar({
        appointment_id: arg.id,
        timezone: arg.timezone,
        days: 4,
        date: moment().format('YYYYMMDD'),
        dir: 'next',
        from_admin: 1,
      });
      setRescheduleVisible(true);
    }
  };

  const onBlockRemove = () => {
    dispatch({
      type: 'doctor_detail/removeBlock',
      payload: {
        id: removeBlockedId,
      },
      callback: () => {
        setRemoveBlockedVisible(false);
        dispatch({
            type: 'doctor_detail/fetchDoctorBlocks',
            payload: {
                doctor_id: id,
                start: dateRange.start,
                end: dateRange.end,
            },
        });
      },
    });
  }

  const loadRescheduleCalendar=(payload, callback) => {
    dispatch({
      type: 'doctor_detail/fetchAppointmentRescheduleCalendar',
      payload: { ...payload },
      callback,
    });
  }

  const rescheduleAppoinment=(payload, callback) => {
    dispatch({
      type: 'doctor_detail/rescheduleAppointment',
      payload: { ...payload },
      callback,
    });
  }
  const loadAppointment=(payload, callback) => {
    dispatch({
      type: 'doctor_detail/fetchAppointmentItem',
      payload: { ...payload },
      callback,
    });
  }
  const  loadCalendarAppointments= (payload, callback) => {
    payload.doctor_id = id;
    dispatch({
      type: 'doctor_detail/fetchCalendarAppointments',
      payload,
      callback,
    });
    const rangeRuleParam={
      start: payload.start-172800,
      end: payload.end+172800,
      doctor_id: id,
  }
    getDateRangeRuleReq.run(rangeRuleParam);
  }
  const onTabChange = (v) => {
    setActiveKey(v);
    setLoadManageAvailability(false);
    if(v==='7'){
      setShowSetUpCompleteVisible(true);
    }
  };
  const doctorDetailReq = useRequest(srvDoctorDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success && ret.data) {
        var detail=ret.data;
        detail.doctor_id=detail.id;
        detail.timezone=detail.time_zone;
        setDoctorDetail(detail);
        setLoadManageAvailability(true);
      }
    },
  });

  const srvCheckDoctorGoogleCalendarResyncReq = useRequest(srvCheckDoctorGoogleCalendarResync, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setShowResyncGoogleCalendarVisible(ret.data);
      }
    },
  })

  const srvSyncAppointmentToGoogleCalendarReq = useRequest(srvSyncAppointmentToGoogleCalendar, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setResyncGoogleCalendarVisible(false);
      }
    },
  })

  const srvResetTimeslotReq = useRequest(srvResetTimeslot, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setTimeout(() => {
          setResetTimeSlotsVisible(false);
          setResetTimeSlotsVisibleLoading(false);
        }, 60000);
      }
    },
  });

  const updateSetUpShow = (value)=>{
    setShowSetUpCompleteVisible(value);
  }
  const getDateRangeRuleReq = useRequest(srvThisWeekRule, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (!ret.data || ret.data?.length === 0) {
          setRuleData([]);
        }
        if (ret.data.length > 0) {
          const tempResult = [];
          ret.data.forEach(obj => {
            obj.items.forEach(item => {
              if (item.date_range.includes(';')) {
                const ranges = item.date_range.split(';');
                ranges.forEach(range => {
                  const newItem = {
                    date: obj.date,
                    appointment_type_name: item.appointment_type_name,
                    appointment_type_id: item.appointment_type_id,
                    date_range: range.trim()
                  };
                  const [startStr, endStr] = range.split('-');
                  const [startHour, startMinute] = getHoursAndMinutes(startStr);
                  const [endHour, endMinute] = getHoursAndMinutes(endStr);
                  const startDateTime = parseInt(obj.date) + (startHour % 12 + (startStr.includes('pm') ? 12 : 0)) * 3600 + startMinute * 60;
                  const endDateTime = parseInt(obj.date) + (endHour % 12 + (endStr.includes('pm') ? 12 : 0)) * 3600 + endMinute * 60;
                  newItem.start_date = startDateTime;
                  newItem.end_date = endDateTime;
                  tempResult.push(newItem);
                });
              } else {
                const newItem = {
                  date: obj.date,
                  appointment_type_name: item.appointment_type_name,
                  appointment_type_id: item.appointment_type_id,
                  date_range: item.date_range
                };
                const [startStr, endStr] = item.date_range.split('-');
                const [startHour, startMinute] = getHoursAndMinutes(startStr);
                const [endHour, endMinute] = getHoursAndMinutes(endStr);
                const startDateTime = parseInt(obj.date) + (startHour % 12 + (startStr.includes('pm') ? 12 : 0)) * 3600 + startMinute * 60;
                const endDateTime = parseInt(obj.date) + (endHour % 12 + (endStr.includes('pm') ? 12 : 0)) * 3600 + endMinute * 60;
                newItem.start_date = startDateTime;
                newItem.end_date = endDateTime;
                tempResult.push(newItem);
              }
            });
          });
          setRuleData(tempResult);
        }
      }
    },
  });
  function getHoursAndMinutes(timeStr) {
    const [hours, minutes] = timeStr.match(/\d+/g).map(Number);
    return [hours, minutes];
  }

  return (
    <div className={cx({ container: true })}>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeKey}
        onTabClick={onTabChange}
        // tabBarExtraContent={OperationsSlot}
      >
        <TabPane tab="Clinician Info" key="1">
          <DoctorInfo
            id={id}
            active={activeKey === '1'}
            loading={loading.effects['doctor_detail/fetchDetail']}
            doctorInfo={detail}
          />
        </TabPane>
        <TabPane tab="Patients" key="2">
          <Patients
            id={id}
            active={activeKey === '2'}
            loadingPatients={loading.effects['doctor_detail/fetchPatients']}
            loadingAutoTransfer={loading.effects['doctor_detail/autoTransfer']}
            loadPatients={(payload, callback) => {
              const filters = payload.filters ? payload.filters : '{}';
              let filtersObj = JSON.parse(filters);
              filtersObj = { ...filtersObj, doctor_id: id };
              payload.filters = JSON.stringify(filtersObj);
              dispatch({
                type: 'doctor_detail/fetchPatients',
                payload,
                callback,
              });
            }}
            autoTransfer={(payload, callback) => {
              dispatch({
                type: 'doctor_detail/autoTransfer',
                payload: { ...payload, doctor_id: id },
                callback,
              });
            }}
            loadTransferOpts={(payload, callback) => {
              dispatch({
                type: 'doctor_detail/fetchDoctorTransferOpts',
                payload: { id },
                callback,
              });
            }}
            patients={doctor_detail.patients}
            transferOpts={doctor_detail.transferOpts}
          />
        </TabPane>
        <TabPane tab="Clinician Notes" key="3">
          <TreatmentPlans
            id={id}
            active={activeKey === '3'}
            loadTreatmentPlanHistories={(payload, callback) => {
              const filters = payload.filters ? payload.filters : '{}';
              let filtersObj = JSON.parse(filters);
              filtersObj = { ...filtersObj, doctor_id: id };
              payload.filters = JSON.stringify(filtersObj);
              dispatch({
                type: 'doctor_detail/fetchTreatmentPlanHistories',
                payload,
                callback,
              });
            }}
            loadTreatmentPlanTypes={(payload, callback) => {
              dispatch({
                type: 'doctor_detail/fetchTreatmentPlanTypes',
                payload: { ...payload },
                callback,
              });
            }}
            loadTreatmentPlanStatuses={(payload, callback) => {
              dispatch({
                type: 'doctor_detail/fetchTreatmentPlanStatuses',
                payload: { ...payload },
                callback,
              });
            }}
            treatment_plan_histories={doctor_detail.treatment_plan_histories}
            treatment_plan_statuses={doctor_detail.treatment_plan_statuses}
            treatment_plan_types={doctor_detail.treatment_plan_types}
            loadingTreatmentPlanHistories={
              loading.effects['doctor_detail/fetchTreatmentPlanHistories']
            }
            loadingHistory={loading.effects['doctor_detail/fetchTreatmentPlanHistory']}
            treatmentPlanHistory={doctor_detail.treatmentPlanHistory}
            loadTreatmentPlanHistory={(payload, callback) => {
              dispatch({
                type: 'doctor_detail/fetchTreatmentPlanHistory',
                payload: { ...payload },
                callback,
              });
            }}
          />
        </TabPane>
        <TabPane tab="Prescription Renewals" key="4">
          <TreatmentRequests
            id={id}
            active={activeKey === '4'}
            loadTreatmentRequests={(payload, callback) => {
              const filters = payload.filters ? payload.filters : '{}';
              let filtersObj = JSON.parse(filters);
              filtersObj = { ...filtersObj, doctor_id: id };
              payload.filters = JSON.stringify(filtersObj);
              dispatch({
                type: 'doctor_detail/fetchTreatmentRequests',
                payload,
                callback,
              });
            }}
            loadTreatmentRequestsStatuses={(payload, callback) => {
              dispatch({
                type: 'doctor_detail/fetchTreatmentRequestStatuses',
                payload: { ...payload },
                callback,
              });
            }}
            treatment_requests={doctor_detail.treatment_requests}
            treatment_request_statuses={doctor_detail.treatment_request_statuses}
            loadingTreatmentRequests={loading.effects['doctor_detail/fetchTreatmentRequests']}
          />
        </TabPane>
        <TabPane tab="Appointments" key="5">
          <Appointments
            id={id}
            active={activeKey === '5'}
            loadAppointments={(payload, callback) => {
              const filters = payload.filters ? payload.filters : '{}';
              let filtersObj = JSON.parse(filters);
              filtersObj = { ...filtersObj, doctor_id: id };
              payload.filters = JSON.stringify(filtersObj);
              dispatch({
                type: 'doctor_detail/fetchAppointments',
                payload,
                callback,
              });
            }}
            loadAppointmentTypes={(payload, callback) => {
              dispatch({
                type: 'doctor_detail/fetchAppointmentTypes',
                payload: { ...payload },
                callback,
              });
            }}
            loadAppointmentStatuses={(payload, callback) => {
              dispatch({
                type: 'doctor_detail/fetchAppointmentStatuses',
                payload: { ...payload },
                callback,
              });
            }}
            appointments={doctor_detail.appointments}
            appointment_statuses={doctor_detail.appointment_statuses}
            appointment_types={doctor_detail.appointment_types}
            loadingAppointments={loading.effects['doctor_detail/fetchAppointments']}
            rescheduleCalendar={doctor_detail.calendar}
            loadingRescheduleAppointment={loading.effects['doctor_detail/rescheduleAppointment']}
            loadRescheduleCalendar={(payload, callback) => {
              dispatch({
                type: 'doctor_detail/fetchAppointmentRescheduleCalendar',
                payload: { ...payload },
                callback,
              });
            }}
            loadAppointmentItem={(payload, callback) => {
              dispatch({
                type: 'doctor_detail/fetchAppointmentItem',
                payload: { ...payload },
                callback,
              });
            }}
            loadingCancelAppointment={loading.effects['doctor_detail/cancelAppointment']}
            rescheduleAppointment={(payload, callback) => {
              dispatch({
                type: 'doctor_detail/rescheduleAppointment',
                payload: { ...payload },
                callback,
              });
            }}
            cancelAppointment={(payload, callback) => {
              dispatch({
                type: 'doctor_detail/cancelAppointment',
                payload: { ...payload },
                callback,
              });
            }}
          />
        </TabPane>
        <TabPane tab="Consultations" key="6">
          <Consultations
            id={id}
            active={activeKey === '6'}
            loadConsultations={(payload, callback) => {
              const filters = payload.filters ? payload.filters : '{}';
              let filtersObj = JSON.parse(filters);
              filtersObj = { ...filtersObj, doctor_id: id };
              payload.filters = JSON.stringify(filtersObj);
              dispatch({
                type: 'doctor_detail/fetchConsultations',
                payload,
                callback,
              });
            }}
            loadConsultationTypes={(payload, callback) => {
              dispatch({
                type: 'doctor_detail/fetchConsultationTypes',
                payload: { ...payload },
                callback,
              });
            }}
            consultations={doctor_detail.consultations}
            consultation_types={doctor_detail.consultation_types}
            loadingConsultations={loading.effects['doctor_detail/fetchConsultations']}
            replyData={doctor_detail.replyData}
            sendReply={sendReply}
            loadReplies={(payload, callback) => {
              dispatch({
                type: 'doctor_detail/fetchConsultationReplies',
                payload: { ...payload },
                callback,
              });
            }}
            loadingReplies={loading.effects['doctor_detail/fetchConsultationReplies']}
            loadingSendMessage={loading.effects['doctor_detail/replyConsultation']}
          />
        </TabPane>
        <TabPane tab="Calendar" key="7">
          {
            showSetUpCompleteVisible && <div className={cx({ header: true })}>
              {!loadManageAvailability && (
                <Button
                  onClick={() => {
                    doctorDetailReq.run({ id });
                  }}
                  style={{ marginLeft: '10px' }}
                >
                  Availability
                </Button>
              )}
              {(loadManageAvailability && showSetUpCompleteVisible) && (
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                    <Button
                        onClick={() => {
                          setLoadManageAvailability(false);
                        }}
                    >
                      Setup complete
                    </Button>
                    <Button
                        onClick={() => {
                          setResetTimeSlotsVisible(true);
                        }}
                    >
                      Resync time slots
                    </Button>
                  </div>
              )}
              {(showResyncGoogleCalendarVisible) && (
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  <Button
                    onClick={() => {
                      setResyncGoogleCalendarVisible(true);
                    }}
                  >
                    Resync Google Calendar
                  </Button>
                </div>
              )}
              {!loadManageAvailability && (
                <Space>
                  <Button
                    onClick={() => {
                      setMessagesVisible(true);
                    }}
                  >
                    Changing log
                  </Button>
                  <Tooltip placement="bottomRight" title="How to reschedule?">
                    <QuestionCircleOutlined
                      onClick={toggleModal}
                      style={{ verticalAlign: 'text-bottom', fontSize: '14px', color: '#5469d4' }}
                    />
                  </Tooltip>
                </Space>
              )}
            </div>
          }
          {!loadManageAvailability && (
            <>
              <BigCalendar
                id={id}
                active={activeKey === '7'}
                loadCalendarAppointments={(payload, callback) => {
                  if (payload) setDateRange(payload);
                  payload.doctor_id = id;
                  dispatch({
                    type: 'doctor_detail/fetchCalendarAppointments',
                    payload,
                    callback,
                  });
                  dispatch({
                    type: 'doctor_detail/fetchDoctorBlocks',
                    payload,
                    callback,
                  });
                const rangeRuleParam={
                    start: payload.start-172800,
                    end: payload.end+172800,
                    doctor_id: id,
                }
                getDateRangeRuleReq.run(rangeRuleParam);
                }}
                providerTimezone={detail.time_zone}
                calendarAppointments={doctor_detail.calendarAppointments}
                loadingCalendarAppointments={
                  loading.effects['doctor_detail/fetchCalendarAppointments']
                }
                onSelectEvent={onSelectEvent}
                canUseTimes={ruleData}
                calendarBlocks={doctor_detail.saveCalendarBlocks}
              />
              <CalendarMessagesDrawer
                dateRange={dateRange}
                messagesVisible={messagesVisible}
                setMessagesVisible={setMessagesVisible}
                doctor_id={id}
              />
            </>
          )}
          {loadManageAvailability && <ManageAvailability doctorDetail={doctorDetail} updateSetUpShow={updateSetUpShow}/>}
          {
            rescheduleVisible && (
              <RescheduleAppointment
                visible={rescheduleVisible}
                appointment={appointment}
                rescheduleCalendar={doctor_detail.calendar}
                onClose={() => {
                  setRescheduleVisible(false);
                }}
                rescheduleAppointment={(payload, callback) => {
                  rescheduleAppoinment(payload, () => {
                    const start = moment().add('-15', 'days').unix();
                    const end = moment().add('30', 'days').unix();
                    loadCalendarAppointments({start,end})
                    callback && callback();
                  });
                }}
                loadingReschedule={loading.effects['doctor_detail/rescheduleAppointment']}
                loadRescheduleCalendar={props.loadRescheduleCalendar}
              />
            )
          }
        </TabPane>
        <TabPane tab="Dashboard" key="8">
          <ProviderDashboard id={id} active={activeKey === '8'} doctorInfo={detail} />
        </TabPane>
      </Tabs>
      <Modal
        title="Resync Time Slots"
        visible={resetTimeSlotsVisible}
        confirmLoading={resetTimeSlotsVisibleLoading}
        onOk={() => {
              setResetTimeSlotsVisibleLoading(true);
              srvResetTimeslotReq.run({ doctor_id: id });
        }}
        onCancel={() => {
          setResetTimeSlotsVisible(false);
        }}
        >
        <p>Are you sure you want to sync the time slots? </p>
        <p>After submission, it will take 2-3 minutes to take effect. Please be patient and avoid performing the operation repeatedly.</p>
      </Modal>
      <Modal
        title="Resync Google Calendar"
        visible={resyncGoogleCalendarVisible}
        confirmLoading={srvSyncAppointmentToGoogleCalendarReq.loading}
        onOk={() => {
          srvSyncAppointmentToGoogleCalendarReq.run({ doctor_id: id });
        }}
        onCancel={() => {
          setResyncGoogleCalendarVisible(false);
        }}
      >
        <p>Are you sure you want to sync the google calendar? </p>
        <p>After submission, it will take 2-3 minutes to take effect. Please be patient and avoid performing the operation repeatedly.</p>
      </Modal>
    </div>
  );
};

export default connect(({ doctor_detail, loading }) => ({
  doctor_detail,
  loading,
}))(DoctorDetail);
