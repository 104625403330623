import React, { useMemo } from 'react';
import classNames from 'classnames';
import Omit from 'omit.js';

import { useDeepCompareEffect } from '@/utils/utils';

import SiderMenu from './SiderMenu';
import { getFlatMenus } from './SiderMenuUtils';
import MenuCounter from './Counter';

import './index.less';

const SiderMenuWrapper = (props) => {
  const { menuData, style, className } = props;

  const { setFlatMenus, setFlatMenuKeys } = MenuCounter.useContainer();

  // Memoize flat menus calculation
  const flatMenus = useMemo(() => {
    if (!menuData || menuData.length < 1) return null;
    return getFlatMenus(menuData);
  }, [menuData]);

  useDeepCompareEffect(() => {
    if (!flatMenus) return;

    // Set flat menus and keys synchronously
    setFlatMenus(flatMenus);
    setFlatMenuKeys(Object.keys(flatMenus));
  }, [flatMenus]);

  const omitProps = Omit(props, ['className', 'style']);

  return (
    <SiderMenu
      className={classNames('aster-sider-menu', className)}
      {...omitProps}
      style={style}
    />
  );
};

SiderMenuWrapper.defaultProps = {
  onCollapse: () => undefined,
};

export default React.memo(SiderMenuWrapper);
