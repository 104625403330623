import { stringify } from 'querystring';
import { history } from 'umi';
import { message } from 'antd';
import {
  srvAddDoctor,
  srvUpdateDoctor,
  srvStates,
  srvDoctors,
  srvDeactiveDoctor,
  srvActiveDoctor,
  srvSetPassword,
  srvIntStatusItems,
  srvTags
} from '@/services/admin';
import {
  srvUrgentRefillRequests
} from '@/services/patient';
const Model = {
  namespace: 'doctor',
  state: {
    data: {
      items: [],
      total: 0,
    },
    urgentRequests: {
      items: [],
      total: 0,
    }, 
    states: [],
    doctor_tags: [],
    doctor_statuses: []
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const res = yield call(srvDoctors, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveList', payload: res.data });
    },
    *fetchUrgentRefillRequests({ payload }, { call, put }) {
      const res = yield call(srvUrgentRefillRequests, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveUrgentRefillRequest', payload: res.data });
    },
    *fetchStates({ payload }, { call, put }) {
      const res = yield call(srvStates, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveStates', payload: res.data });
    },
    *fetchDoctorStatuses({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'user_status' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveDoctorStatuses', payload: res.data });
    },
    *addDoctor({ payload, callback }, { call, put, select }) {
      const res = yield call(srvAddDoctor, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *updateDoctor({ payload, callback }, { call, put, select }) {
      const res = yield call(srvUpdateDoctor, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *activeDoctor({ payload, callback }, { call, put, select }) {
      const res = yield call(srvActiveDoctor, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },

    *setPassword({ payload, callback }, { call, put, select }) {
      const res = yield call(srvSetPassword, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *deactiveDoctor({ payload, callback }, { call, put, select }) {
      const res = yield call(srvDeactiveDoctor, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *fetchDoctorTags({ payload }, { call, put }) {
      const res = yield call(srvTags, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveDoctorTags', payload: res.data?.items });
    },
  },
  reducers: {
    saveList(state, { payload }) {
      return { ...state, data: payload };
    },
    saveStates(state, { payload }) {
      return { ...state, states: payload };
    },
    saveDoctorStatuses(state, { payload }) {
      return { ...state, doctor_statuses: payload}
    },
    saveDoctorTags(state, { payload }) {
      return { ...state, doctor_tags: payload };
    },
    saveUrgentRefillRequest(state, { payload }) {
      return { ...state, urgentRequests: payload };
    },
  },
};
export default Model;
