import React, { useState, useRef } from 'react';
import { Modal, Input, Checkbox, Form, Upload, Row, Col, DatePicker, Space } from 'antd';
import { LoadingOutlined, PlusOutlined, FileTextOutlined } from '@ant-design/icons';
import request from 'umi-request';
import moment from 'moment';
import { formatUnix } from '@/utils/xtime';
import styles from './index.less';

import {
  srvUploadToken,
  srvUploadDocument,
} from '@/services/patient';

const UploadFileModal = ({ user_id, initialValues, onOk, onCancel, ...modalProps }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [checked, setChecked] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState(null);

  const onClose = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  const onFinish = async () => {
    let values = {}
    try {
      values = await form.validateFields();
    } catch (error) {
      return;
    }
    const { file, last_fill_date, additional_notes } = values;
    setLoading(true);
    let res = {};
    try {
      res = await srvUploadToken({ type: 1 });
    } catch (error) {
      return;
    }
    let objectKey = res.data.object_key;
    const fileBuffer = await file.arrayBuffer();

    try {
      request.put(res.data.upload_url, {
        data: fileBuffer,
        headers: { 'Content-Type': file.type },
      })
    } catch (error) {
      return;
    }

    await srvUploadDocument({
      user_id: user_id,
      file_name: file.name,
      file_type: file.type,
      file_desc: additional_notes,
      object_key: objectKey,
    })

    setLoading(false);
    onOk({
      file_object_key: objectKey,
      last_fill_date: last_fill_date,
      additional_notes
    });
  };

  const onBeforeUpload = (file) => {
    form.setFieldsValue({ file, });
    setUploadFile(file);
    return false;
  };

  const modalOptions = {
    ...modalProps,
    onOk: onFinish,
    onCancel: onClose,
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
    }
  };

  const onCheckboxChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      setDatePickerValue(null);
      form.setFieldsValue({ last_fill_date: -1 })
    } else {
      form.setFieldsValue({ last_fill_date: datePickerValue ? moment(datePickerValue).unix() : null })
    }
  };

  const onDatePickerChanged = (n) => {
    setDatePickerValue(n);
    form.setFieldsValue({ last_fill_date: n ? moment(n).unix() : null })
  }

  const DatePickerInput = (props) => (
    <Space>
      <Input type='hidden' {...props} />
      <DatePicker format="MM/DD/YYYY" showTime={false} showToday={true} size="default" disabled={checked} onChange={onDatePickerChanged} value={datePickerValue} />
      <Checkbox checked={checked} onChange={onCheckboxChange}>Not available</Checkbox>
    </Space>
  );

  return (
    <Modal {...modalOptions} destroyOnClose={true} className={styles.uploadModal}>
      <h2 className={styles.title}>Upload PDMP</h2>

      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        autoComplete="off"
        style={{ margin: 0 }}
        onFinish={onFinish}
      >
        <Row>
          <Col span={6}>
            <Form.Item
              name="file"
              noStyle
              rules={[{ required: true }]}
            />
            <Upload
              name="upload"
              className={styles.uploader}
              listType="picture-card"
              showUploadList={false}
              beforeUpload={onBeforeUpload}
              onChange={handleChange}
            >
              {uploadFile ? (
                <div className={styles.uploaded}> <FileTextOutlined /> </div>
              ) : (
                <div>
                  {loading ? <LoadingOutlined /> : 'Upload a file'}
                </div>
              )}
            </Upload>
          </Col>
          <Col span={18}>
            <Form.Item
              label="Last Refill Date"
              name="last_fill_date"
              rules={[{
                required: true,
                message: 'Please select last refill date.',
              }]}
            >
              <DatePickerInput />
            </Form.Item>

            <Form.Item label="Additional Notes" name="additional_notes" >
              <Input.TextArea rows={2} />
            </Form.Item>

          </Col>
        </Row>

      </Form>
    </Modal>
  );
};

export default UploadFileModal;
