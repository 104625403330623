import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link } from 'umi';
import { useMount, useUpdateEffect } from 'ahooks';
import { Tag, Space, Popover, Input, Table, Row, Col, Button, Dropdown } from 'antd';
import { SearchOutlined, createFromIconfontCN, EllipsisOutlined } from '@ant-design/icons';
import numeral from 'numeral';

import Filters from '@/components/Filters';
import defaultSettings from '../../../defaultSettings';
import { paymentStatusColor } from '@/utils/tagColor';

import { formatUnix } from '../../utils/xtime';
import styles from './index.less';
const cx = cn.bind(styles);

import RefundModal from '../payment/RefundModal';
import CreatePaymentModal from '../payment/CreatePaymentModal';

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const filters = [
  {
    name: 'amount',
    label: 'Amount',
    type: 'number',
  },
  {
    name: 'product_id',
    label: 'Product',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'status',
    label: 'Status',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'created_at',
    label: 'Date',
    type: 'date',
  },
];

const Patient = (props) => {
  const { id, active, payments, loadingPayments, payment_products, payment_statuses } = props;
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [refundModalVisible, setRefundModalVisible] = useState(false);
  const [refund, setRefund] = useState({});
  const [createPaymentModalVisible,setCreatePaymentModalVisible]= useState(false);

  const columns = [
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      ellipsis: true,
      width: 80,
      render: (v, r) => {
        const val = numeral(v / 100).format('0,0.00');
        return <span style={{ color: '#1a1f36', fontWeight: 500 }}>{val}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        if (r.status == 3) {
          return (
            <Popover
              content={<div className="popover-user">{r.reason}</div>}
              title="Reason"
              trigger="hover"
            >
              <Tag color={paymentStatusColor(r.status)}>{r.status_name}</Tag>
            </Popover>
          );
        }
        return <Tag color={paymentStatusColor(r.status)}>{r.status_name}</Tag>;
      },
    },
    // {
    //   title: 'Product',
    //   dataIndex: 'id',
    //   key: 'id',
    //   ellipsis: true,
    //   width: 190,
    //   render: (v, r) => {
    //     if (r.order) {
    //       return <Tag>{r.order.product_id_name}</Tag>;
    //     }
    //     return '';
    //   },
    // },
    {
      title: 'Billing Category',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 190,
      render: (v, r) => {
        if (r.order) {
          return r.order.product_id_name;
        }
        return '';
      },
    },
    {
      title: 'Operator',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 190,
      render: (v, r) => {
        if (r.order) {
          return r.order.operator;
        }
        return '';
      }
    },
    {
        title: 'Payment Method',
        dataIndex: 'payment_method_last_4',
        key: 'payment_method_last_4',
        ellipsis: true,
        width: 190,
    },
    {
      title: 'Stripe/Paypal ID',
      dataIndex: 'external_charge_id',
      key: 'external_charge_id',
      ellipsis: true,
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      ellipsis: true,
    },
    {
      title: 'Payment Deadline',
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        if (r.order) {
          return formatUnix(r.order.final_payment_at);
        }
        return '';
      },
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 50,
      render: (v, r) => {
        return (
          <Dropdown
            overlay={
              <div className={cx({ paymentOpt: true })}>
                {(r.status === 2 || r.status === 7 || r.status === 8 || (r.status === 6 && r.amount > r.amount_refunded)) && (
                  <div
                    className={cx({ paymentOptItem: true })}
                    onClick={() => {
                      showRefundModal(r);
                    }}
                  >
                    <a href="#">Refund</a>
                  </div>
                )}
                <div className={cx({ paymentOptItem: true })}>
                  <Link to={`/payment/${r.id}`}>View Payment Details</Link>
                </div>
              </div>
            }
            trigger={['click']}
          >
            <Button size="small">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const loadData = () => {
    if (active) {
      setPage(1);
      props.loadPayments({ page: 1, page_size: 20 }, () => { });
      props.loadPaymentProducts();
      props.loadPaymentStatuses();
    }
  };

  useMount(() => {
    loadData();
  });

  useUpdateEffect(() => {
    loadData();
  }, [active, id]);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    props.loadPayments({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => {});
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    props.loadPayments(
      { page: pagination.current, page_size: pagination.pageSize, filters: JSON.stringify(query) },
      () => {},
    );
  };

  const f = () => {
    for (const v of filters) {
      if (v.name === 'product_id' && v.items.length === 0) {
        v.items = payment_products;
        continue;
      }

      if (v.name === 'status' && v.items.length === 0) {
        v.items = payment_statuses;
      }
    }
    return filters;
  };

  let modalProps = {
    visible: refundModalVisible,
    item: refund,
    maskClosable: false,
    closable: false,
    okText: 'Refund',
    width: 700,
    onOk: (payload) => {
      props.refundPayment(payload, () => {
        props.loadPayments(
          { page: page, page_size: 20, filters: JSON.stringify(query) },
          () => { },
        );
        setRefund({});
        setRefundModalVisible(false);
      });
    },
    onCancel: () => {
      setRefund({});
      setRefundModalVisible(false);
    },
    okButtonProps: {
      style: {
        with:'180px !important',
        border: 'none',
        borderRadius: '4px',
        marginBottom: '15px',
        marginTop: '10px',
      },
    },
    cancelButtonProps: {
      style: {
        backgroundColor: '#ffffff',
        color: '#566BCD',
        border: 'none',
        float: 'left',
        textAlign: 'left',
        marginBottom: '15px',
        marginTop: '10px',
      },
    },
  };

  const showRefundModal = (item) => {
    setRefund(item);
    setRefundModalVisible(true);
  };
  const closeCreatePaymentModal = ()=>{
    setCreatePaymentModalVisible(false);
    loadData();
  }

  return (
    <div className={cx({ subcontainer: true })}>
      <div className={cx({ header: true })}>
        <h3>Payments</h3>
        <Space>
          <Button type="primary" onClick={()=>{setCreatePaymentModalVisible(true)}}>
            Create Payment
          </Button>
          <Filters filters={f()} onFilter={onFilter} />
        </Space>
      </div>

      <Table
        scroll={{ x: 800 }}
        columns={columns}
        dataSource={payments.items}
        rowKey="id"
        loading={loadingPayments}
        onChange={handleTableChange}
        pagination={{ pageSize: 20, current: page, simple: true, total: payments.total }}
      />

      {refundModalVisible && <RefundModal {...modalProps} />}
      {createPaymentModalVisible&&<CreatePaymentModal closeCreateModal={closeCreatePaymentModal} id={id}/>}
    </div>
  );
};

export default Patient;
