import GoogleCalendarAuth from "@/components/GoogleCalendar/index";
import {Button, Divider, List, Modal, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {useRequest} from "ahooks";
import {srvDeleteDoctorGoogleCalendar, srvGetDoctorGoogleCalendar} from "@/services/account";
import { ReactComponent as GoogleLogo } from '@/assets/google.svg';
const { Title, Link, Text } = Typography;
const SyncToGoogleCalendar = ({ visible, onClose }) => {



    const [addedCalendars, setAddedCalendars] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);


    const handleRemoveGoogleAccount = () => {
        deleteGoogleCalendar.run({id: selectedItemId});
        setShowRemoveConfirm(false);
        setSelectedItemId(null);
    }

    const getGoogleCalendarList = useRequest(srvGetDoctorGoogleCalendar, {
        manual: true,
        onSuccess: (ret) => {
            if (ret.success) {
                setAddedCalendars(ret.data);
            }
        }
    })

    const deleteGoogleCalendar = useRequest(srvDeleteDoctorGoogleCalendar, {
        manual: true,
        onSuccess: (ret) => {
            if (ret.success) {
                getGoogleCalendarList.run();
            }
        }
    })

    useEffect(() => {
        getGoogleCalendarList.run();
    }, []);

    return (
        <>
            <Modal
                visible={visible}
                maskClosable={false}
                footer={[
                    <div key={1} style={{ display: 'flex', justifyContent: 'space-between' }}>

                    </div>,
                ]}
                title="Sync to Google Calendar"
                onCancel={onClose}
                width={600}
            >
                <p>
                    Please add Google Calendar to stay updated on your appointments. Once added, changes on your EHR calendar will be synced to Google Calendar.
                </p>
                <div key={1} style={{ display: 'flex', justifyContent: 'space-between', marginTop:'20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <GoogleLogo style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                        <span>Add Google Calendar</span>
                    </div>
                    ,
                    <GoogleCalendarAuth reAuth={false}/>
                </div>
                {addedCalendars.length > 0 && (
                    <>
                        <Divider />
                        <Text strong>Calendar Added:</Text>
                        <List
                            dataSource={addedCalendars}
                            renderItem={(item) => (
                                <List.Item
                                    actions={[
                                      item.need_reauth && <GoogleCalendarAuth reAuth={true}/>,
                                        <Button key="remove" onClick={() => {
                                            setSelectedItemId(item.id);
                                            setShowRemoveConfirm(true)
                                        }}>
                                            Remove
                                        </Button>,
                                    ]}
                                >
                                    {item.google_account} {item.need_reauth && <Text type="danger">(Invalid)</Text>}
                                </List.Item>
                            )}
                        />
                    </>
                )}

            </Modal>
            <Modal
                visible={showRemoveConfirm}
                maskClosable={false}
                title="Remove account?"
                onCancel={() => {
                    setShowRemoveConfirm(false);
                    setSelectedItemId(null);
                }}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button key="cancel" onClick={() => {
                            setShowRemoveConfirm(false);
                            setSelectedItemId(null);
                        }}>
                            Cancel
                        </Button>
                        <Button key="confirm" type="primary" onClick={handleRemoveGoogleAccount}>
                            Yes
                        </Button>
                    </div>
                }
            >
                <p>Once removed, any changes to your EHR calendar will not be synced to Google Calendar.</p>
            </Modal>
        </>

    )
}

export default SyncToGoogleCalendar;
