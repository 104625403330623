import React, { useState, useEffect, useRef } from 'react';
import { Space, Table, Button, Tabs } from 'antd';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import Filters from '@/components/Filters';
import DoctorChartAuditDrawer from '@/components/DoctorChartAuditDrawer';

import { srvChartAuditHistory, srvChartAuditHistoryCount, srvChartAuditNote } from '@/services/patient';
import { useChartAuditStore } from '@/stores';
import { useRequest } from 'ahooks';
import { formatUnix } from '@/utils/xtime.js';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function DashboardDateSelector(props) {
  const { currentIndex, numTotal, onClickPrev, onClickNext, month, year } = props
  return (
    <div className="dashboard-date-selector">
      <Button className="dashboard-date-selector-button" disabled={currentIndex < 1} onClick={onClickPrev} type="primary" shape="circle" size="small">
        <LeftOutlined />
      </Button>
      <div className="dashboard-date-selector-month">
        {months[month - 1] + ' ' + year}
      </div>
      <Button className="dashboard-date-selector-button" disabled={currentIndex >= numTotal - 1} onClick={onClickNext} type="primary" shape="circle" size="small">
        <RightOutlined />
      </Button>
    </div>
  )
}

const EHRChartAuditHistory = () => {

  const {open: openChartAudit} = useChartAuditStore();
  const [data, setData] = useState({ total: 0, items: [] });
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);

  const [historyCount, setHistoryCount] = useState([])
  const [currentIndex, setCurrentIndex] = useState(-1)

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [audit, setAudit] = useState({});

  const fetchChartAuditHistoryCountReq = useRequest(srvChartAuditHistoryCount, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (ret.data != null && ret.data.items && ret.data.items.length > 0) {
          setHistoryCount(ret.data.items);
          setCurrentIndex(ret.data.items.length - 1);
        }
      }
    },
  });

  const fetchChartAuditHistoryReq = useRequest(srvChartAuditHistory, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setData(ret.data);
      }
    },
  });

  const fetchChartAuditNoteReq = useRequest(srvChartAuditNote, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setAudit(ret.data);
        setDrawerVisible(true);
        // openChartAudit(ret.data);
      }
    },
  });

  const loadList = (filters, params) => {
    let filtersData = filters ? filters : {};
    let filtersObj = { ...filtersData };
    fetchChartAuditHistoryReq.run({ filters: JSON.stringify(filtersObj), ...params });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadList(query, {
      page: pagination.current, page_size: pagination.pageSize,
      year: historyCount[currentIndex]?.year, month: historyCount[currentIndex]?.month
    });
  };

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList(q, { page: 1, page_size: 20, year: historyCount[currentIndex]?.year, month: historyCount[currentIndex]?.month, });
  };

  useEffect(() => {
    if (currentIndex >= 0) {
      setPage(1);
      loadList(query, { page: 1, page_size: 20, year: historyCount[currentIndex]?.year, month: historyCount[currentIndex]?.month });
    }
  }, [currentIndex])

  useEffect(() => {
    fetchChartAuditHistoryCountReq.run()
  }, []);

  const filters = [
    {
      name: 'doctor_name',
      label: 'Provider',
      type: 'text',
    },
    {
      name: 'audit_status',
      label: 'Audit Status',
      type: 'checkbox',
      items: [
        { label: 'Qualified', value: 1 },
        { label: 'Unqualified', value: 2 },
      ],
    },
  ];

  const columns = [
    {
      title: 'Provider',
      dataIndex: 'doctor_id',
      key: 'doctor_id',
      ellipsis: true,
      width: 120,
      render: (v, r) => {
        return r.doctor_name;
      },
    },
    {
      title: 'Average Score',
      dataIndex: 'average_score',
      key: 'average_score',
      ellipsis: true,
      width: 60,
      render: (v, r) => {
        if (r.average_score > 0) {
          return (r.average_score / 10).toFixed(1);
        } else {
          return r.average_score;
        }
      },
    },
    {
      title: 'Audit Status',
      dataIndex: 'audit_status_name',
      key: 'audit_status_name',
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Chart 1',
      dataIndex: 'audit_note_id_1',
      key: 'audit_note_id_1',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        if (r.audit_note_status_1 != 2) return '';
        return (
          <Button
            size="small"
            type="default"
            onClick={() => {
              fetchChartAuditNoteReq.run({ id: r.audit_note_id_1 })
            }}
          >
            View Chart 1
          </Button>
        );
      },
    },
    {
      title: 'Chart 2',
      dataIndex: 'audit_note_id_2',
      key: 'audit_note_id_2',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        if (r.audit_note_status_2 != 2) return '';
        return (
          <Button
            size="small"
            type="default"
            onClick={() => {
              fetchChartAuditNoteReq.run({ id: r.audit_note_id_2 })
            }}
          >
            View Chart 2
          </Button>
        );
      },
    },
    {
      title: 'Chart 3',
      dataIndex: 'audit_note_id_3',
      key: 'audit_note_id_3',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        if (r.audit_note_status_3 != 2) return '';
        return (
          <Button
            size="small"
            type="default"
            onClick={() => {
              fetchChartAuditNoteReq.run({ id: r.audit_note_id_3 })
            }}
          >
            View Chart 3
          </Button>
        );
      },
    },
    {
      title: 'Chart 4',
      dataIndex: 'audit_note_id_4',
      key: 'audit_note_id_4',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        if (r.audit_note_status_4 != 2) return '';
        return (
          <Button
            size="small"
            type="default"
            onClick={() => {
              fetchChartAuditNoteReq.run({ id: r.audit_note_id_4 })
            }}
          >
            View Chart 4
          </Button>
        );
      },
    },
    {
      title: 'Next Review',
      ellipsis: true,
      dataIndex: 'next_review_at',
      key: 'next_review_at',
      width: 120,
      render: (v, r) => {
        if (!r.next_review_at) {
          return '';
        }
        return formatUnix(r.next_review_at, 'MMM YYYY');
      },
    },
  ];

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        {/* <Space> */}
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div>
            <h3 style={{ width: '182px' }}>Chart Audit History</h3>
          </div>
          <div>
            {historyCount[currentIndex] && (
              <DashboardDateSelector
                currentIndex={currentIndex}
                numTotal={historyCount.length}
                month={historyCount[currentIndex]?.month}
                year={historyCount[currentIndex]?.year}
                onClickPrev={() => setCurrentIndex(currentIndex - 1)}
                onClickNext={() => setCurrentIndex(currentIndex + 1)}
              />
            )}
          </div>
          <Filters filters={filters} onFilter={onFilter} />
          </div>
        {/* </Space> */}
      </div>

      <Table
        columns={columns}
        dataSource={data.items}
        rowClassName={(r) => {
          return '';
        }}
        scroll={{ x: 800 }}
        rowKey="id"
        loading={fetchChartAuditHistoryReq.loading}
        onChange={handleTableChange}
        pagination={{ pageSize: 20, current: page, simple: true, total: data.total }}
      />

      {drawerVisible && (
        <DoctorChartAuditDrawer
          visible={drawerVisible}
          setVisible={setDrawerVisible}
          audit={audit}
          onRefresh={() => {}}
        />
      )}
    </div>
  )
}

export default EHRChartAuditHistory;
