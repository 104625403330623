import { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link } from 'umi';
import { formatUnix } from '@/utils/xtime.js';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import moment from 'moment';
import PDFViewer from '@/components/PDFViewer';

import { createFromIconfontCN, EnvironmentFilled, CalendarFilled } from '@ant-design/icons';
import defaultSettings from '../../../defaultSettings';
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

import {
  Modal,
  Space,
  Popover,
  Input,
  Table,
  Row,
  Button,
  message,
  Drawer,
  Form,
  Alert,
  Checkbox,
  Tooltip,
} from 'antd';

import Filters from '@/components/Filters';
import PDFPreview from '@/components/PDFPreview';
import styles from './index.less';
const cx = cn.bind(styles);

import {
  srvGetMedicalFileRequests,
  srvDoctorApproveMedicalFileRequest,
  srvDoctorGetMedicalFileLink,
  srvDoctorAddMedicalFileRevision,
} from '@/services/patient';

const filters = [
  {
    name: 'patient_name',
    label: 'Patient Name',
    type: 'text',
  },
  {
    name: 'file_type',
    label: 'File Type',
    type: 'checkbox',
    items: [
      { label: 'Medical Record', value: 0 },
      { label: 'Assessment Result', value: 1 },
    ],
  },
];

const EhrMedicalFiles = (props) => {
  const [filterState, setFilterState] = useState({});
  const [query, setQuery] = useState({});

  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedMedicalFile, setSelectedMedicalFile] = useState({});
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRevisionModal, setShowRevisionModal] = useState(false);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => {});
  };

  const listReq = useRequest(srvGetMedicalFileRequests, {
    manual: true,
    onSuccess: (ret, params) => {
      setData(ret.data);
    },
  });

  useMount(() => {
    loadList({ page: 1, page_size: 20 });
  });

  const loadList = (params) => {
    listReq.run(params);
  };

  const refreshList = () => {
    loadList({ page: page, page_size: 20 });
  };

  const columns = [
    {
      title: 'Patient',
      dataIndex: 'patient_name',
      key: 'patient_name',
      ellipsis: true,
      render: (v, r) => {
        if (!r.patient) {
          return null;
        }
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/patient/${r.patient?.id}`}>{r.patient?.name}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      setCallTarget('patient');
                      setVisible('callReason', true);
                      setCallPatient(r.patient);
                    }}
                  >
                    Call Patient
                  </Button>
                </div>
                <div className="popover-user-item birthdate">
                  <CalendarFilled className="icon" />
                  {r.patient?.birthdate &&
                    moment.unix(r.patient?.birthdate).utc().format('MM/DD/YYYY')}
                </div>
                <div className="popover-user-item sex">
                  <IconFont className="icon" type="icon-sex" />
                  {r.patient?.gender}
                </div>
                <div className="popover-user-item state">
                  <EnvironmentFilled className="icon" />
                  {r.patient?.state}
                </div>
              </div>
            }
            title={r.patient?.name}
            trigger="hover"
          >
            <Link to={`/patient/${r.patient?.id}`} style={{ fontWeight: 500 }}>
              <div>{r.patient_name}</div>
            </Link>
          </Popover>
        );
      },
    },
    {
      title: 'File Type',
      dataIndex: 'file_type',
      key: 'file_type',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return r.file_type_name;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status_name',
      key: 'status_name',
      width: 250,
      render: (v, r) => {
        return r.status_name;
      },
    },
    {
      title: 'Created At',
      ellipsis: true,
      dataIndex: 'created_at',
      width: 200,
      key: 'created_at',
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      render: (v, r) => {
        return (
          <Space>
            <Button
              style={{ marginRight: '10px' }}
              onClick={() => {
                setSelectedMedicalFile(r);
                setShowDrawer(true);
              }}
            >
              View
            </Button>
            {r.status == 2 && (
              <Button
                type="primary"
                onClick={() => {
                  setSelectedMedicalFile(r);
                  setShowApproveModal(true);
                }}
              >
                Approve
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadList({
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: JSON.stringify(query),
    });
  };

  return (
    <>
      <div className={cx({ container: true })}>
        <div className={cx({ header: true })}>
          <h3>Medical File Requests</h3>

          <Space>
            <Filters filters={filters} initialFilters={filterState} onFilter={onFilter} />
          </Space>
        </div>

        <Table
          columns={columns}
          dataSource={data.items}
          rowKey="id"
          scroll={{ x: 800 }}
          onChange={handleTableChange}
          pagination={{
            pageSize: 20,
            total: data.total,
            simple: true,
            current: page,
            showTotal: (total, range) => {
              return `${range[0]}-${range[1]} of ${total} items`;
            },
          }}
        />

        {showDrawer && (
          <MedicalFileDrawer
            onClose={() => {
              setShowDrawer(false);
              setSelectedMedicalFile({});
            }}
            medicalFile={selectedMedicalFile}
            showApproveModal={() => {
              setShowApproveModal(true);
            }}
            showRevisionModal={() => {
              setShowRevisionModal(true);
            }}
          />
        )}
        {showApproveModal && (
          <MedicalRequestApproveModal
            medicalFile={selectedMedicalFile}
            showApproveModal={showApproveModal}
            closeModal={() => {
              setShowApproveModal(false);
            }}
            closeDrawer={() => {
              setShowDrawer(false);
            }}
            refreshList={refreshList}
          />
        )}
        {showRevisionModal && (
          <MedicalFileRevisionModal
            medicalFile={selectedMedicalFile}
            showRevisionModal={showRevisionModal}
            closeDrawer={() => {
              setShowDrawer(false);
            }}
            closeModal={() => {
              setShowRevisionModal(false);
            }}
            refreshList={refreshList}
          />
        )}
      </div>
    </>
  );
};

const MedicalFileDrawer = (props) => {
  const [link, setLink] = useState('');

  const getLinkReq = useRequest(srvDoctorGetMedicalFileLink, {
    manual: true,
    onSuccess: (ret, params) => {
      setLink(ret.data);
    },
  });

  useMount(() => {
    getLinkReq.run({ id: props.medicalFile.id });
  });

  useMount(async () => {});

  const handleRequestRevision = () => {
    props.showRevisionModal();
  };

  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase(),
    );
  };

  return (
    <>
      <Drawer
        title={toTitleCase(props.medicalFile.file_type_name)}
        placement="right"
        closable={true}
        setNoteVisible={() => {}}
        onClose={props.onClose}
        visible={true}
        destroyOnClose={true}
        width="1000"
      >
        {props.medicalFile.status == 2 && (
          <Alert
            message={
              <p style={{ color: '#6b7284' }}>
                Please review the file carefully. If any changes are needed, click 'Request
                Revision' and provide specific details. The file will be sent to the care team for
                updates and returned to you for re-review once revisions are complete. When
                everything looks correct, click 'Approve' to confirm.
              </p>
            }
            type="info"
          />
        )}

        <h4 style={{ marginBottom: '30px', marginTop: '30px', fontWeight: '600' }}>Details</h4>

        {
          link != '' && <PDFViewer fileUrl={link} />
          // <div className={cx({ pdfSection: true })}>
          //   <iframe
          //     src={`${link}#toolbar=0`}
          //     onContextMenu={(e) => e.preventDefault()}
          //     style={{ pointerEvents: 'none' }}
          //     width="100%"
          //     height="100%"
          //     title="PDF Viewer"
          //   />
          // </div>
        }

        <div className={cx({ buttonsWrapper: true })}>
          {props.medicalFile.status == 2 && (
            <>
              <Button style={{ marginRight: '10px' }} onClick={handleRequestRevision}>
                Request Revision
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  props.showApproveModal();
                }}
              >
                Approve
              </Button>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

const MedicalRequestApproveModal = (props) => {
  const approveReq = useRequest(srvDoctorApproveMedicalFileRequest, {
    manual: true,
    onSuccess: (ret, params) => {
      props.refreshList();
      props.closeModal();
      if (props.closeDrawer) {
        props.closeDrawer();
      }
    },
  });

  const handleOk = () => {
    approveReq.run({
      id: props.medicalFile.id,
    });
  };

  const handleCancel = () => {
    props.closeModal();
  };

  return (
    <>
      <Modal
        title="Are you sure?"
        style={{ zIndex: 10000000 }}
        visible={props.showApproveModal}
        onOk={handleOk}
        okText="Confirm"
        onCancel={handleCancel}
      >
        <p>Are you sure you want to approve this?</p>
      </Modal>
    </>
  );
};

const MedicalFileRevisionModal = (props) => {
  const [textValue, setTextValue] = useState('');

  const sendRevisionReq = useRequest(srvDoctorAddMedicalFileRevision, {
    manual: true,
    onSuccess: (ret, params) => {
      props.refreshList();
      props.closeDrawer();
      props.closeModal();
    },
  });

  const handleOk = () => {
    if (textValue.length > 0) {
      sendRevisionReq.run({
        id: props.medicalFile.id,
        note: textValue,
      });
    } else {
      alert('Empty notes');
    }
  };

  const handleCancel = () => {
    props.closeModal();
  };

  return (
    <>
      <Modal
        title="Request Revision"
        visible={props.showRevisionModal}
        onOk={handleOk}
        okButtonProps={{ disabled: textValue.length == 0 }}
        okText="Send"
        onCancel={handleCancel}
      >
        <p style={{ marginBottom: '10px' }}>
          Please provide specific details.The file will be sent to the care team for updates and
          returned to you for re-review once revisions are complete.
        </p>
        <Input.TextArea
          rows={4}
          placeholder="Add notes here"
          value={textValue}
          onChange={(e) => setTextValue(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default EhrMedicalFiles;

export { MedicalFileDrawer, MedicalRequestApproveModal, MedicalFileRevisionModal };
