import React, { useState } from 'react';
import { Table, Space, Button, Tabs, Tooltip } from 'antd';
import { formatUnix } from '@/utils/xtime';

const InitialPrescription = (props) => {
  const columns = [
    {
      title: 'Time',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (v, r) => {
        return (
          <div>{formatUnix(v, 'MM/DD/YYYY')}</div>
        )
      },
    },
    {
      title: 'Admin Notes',
      dataIndex: 'admin_notes',
      key: 'admin_notes',
      render: (v, r) => {
        return (<></>)
      },
    },
    {
      title: 'Status',
      dataIndex: 'state_name',
      key: 'state_name',
      render: (v, r) => {
        return <div>{v} {r.synced_rxnt && "(Prescribing)"}</div>;
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (v, r) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              let link = `/api/ehr/rxnt/sso?return_url=${Buffer.from(window.location.href).toString('base64')}&patient_id=${props.uid}&referral_id=${r.id}&order_type=1`;
              window.location.href = link;
            }}
          >
            Prescribe
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <h3>
        <Space>
          Initial Prescription
        </Space>
      </h3>

      <Table columns={columns} dataSource={props.data} rowKey="id" />
    </>
  )
}

export default InitialPrescription