import React, { useState } from 'react';
import {Form, Modal, Table, Button, Dropdown, Menu, Space, Tooltip, message} from 'antd';
import { createFromIconfontCN, EllipsisOutlined } from '@ant-design/icons';
import { useFlag } from '@unleash/proxy-client-react';
import { useRequest, useMount, useUpdateEffect, useEventEmitter } from 'ahooks';
import cn from 'classnames/bind';
import { downloadDocument } from '@/utils/utils';
import { formatUnix } from '@/utils/xtime';
import styles from './Medications.less';
import {
  srvPatientTreatmentRequests,
  srvOverridePatientRefillDate,
  srvGetPaitentDischargeInfo,
  srvUserFeatures,
  srvAddTreatmentRequestHistory,
  srvGetPatientCurrentMembership, srvOverridePatientRefillDateV2, srvRxntActiveMedList,
} from '@/services/patient';
import AddNote from '@/components/AddNote';
import CheckPDMPPane from '@/components/PDMPPane';
import OverrideModal from './OverrideRefillDateModal';
import AdjustTreatmentRequestDateModal from '../../pages/patient/AdjustTreatmentRequestDateModal';
import DisputeModal from './DisputeModal';
import { useSelector } from 'umi';
import moment from 'moment';
import {useActiveMedsStore} from "@/stores/useActiveMedsStore";

const cx = cn.bind(styles);

const RenewalRequest = (props) => {
  const { id, active, patient, reloadRxnt$, onApprove } = props;
  const [requestNoteVisible, setRequestNoteVisible] = useState(false);
  const [requestNote, setRequestNote] = useState('');
  const[requestedMedicationsVisible,setRequestedMedicationsVisible]=useState(false);
  const[requestedMedications,setRequestedMedications]=useState('');

  const [discharge, setDischarge] = useState({});
  const [overrideModalVisible, setOverrideModalVisible] = useState(false);
  const [threeMonthRenewal, setThreeMonthRenewal] = useState(false);

  const [refillRequests, setRefillRequests] = useState([]);
  const [isMemberActive, setIsMemberActive] = useState(false);

  // Adjust
  const [treatmentRequestToAdjust, setTreatmentRequestToAdjust] = useState();
  const [adjustTreatmentRequestDateModalVisible, setAdjustTreatmentRequestDateModalVisible] =
    useState(false);

  const flagThreeMonthRenewal = useFlag('three_month_renewal');
  const [disputeVisible, setDisputeVisible] = useState(false);
  const [allowTransferApptInDispute, setAllowTransferApptInDispute] = useState(false);
  const [allowFollowUpApptInDispute, setAllowFollowUpApptInDispute] = useState(false);
  const [selected,setSelected]=useState(null);
  const { currentUser } = useSelector((state) => state.account);
  const [disputeForm] = Form.useForm();
  const loadData = () => {
    if (active) {
      loadRefillRequestsReq.run({ id });
      loadDischargeInfoReq.run({ id });
      userFeaturesReq.run({ id: id });
    }
  };

  // const [activeMeds, setActiveMeds] = useState([]);

  // const historyReq = useRequest(srvRxntActiveMedList, {
  //   manual: true,
  //   onSuccess: (ret) => {
  //     if (ret.success) {
  //       setActiveMeds(ret.data);
  //     }
  //   },
  // });

  reloadRxnt$.useSubscription(() => {
    loadData();
  });

  useMount(() => {
    loadData();
    loadMembership.run({id : id})
    // historyReq.run({ user_id: id });
  });

  useUpdateEffect(() => {
    loadData();
  }, [active, id]);

  const userFeaturesReq = useRequest(srvUserFeatures, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setThreeMonthRenewal(!!ret.data.three_month_renewal);
      }
    },
  });

  const loadDischargeInfoReq = useRequest(srvGetPaitentDischargeInfo, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (ret.data) {
          setDischarge(ret.data);
        } else {
          setDischarge({});
        }
      }
    },
  });

  const overrideNextRefillDateReq = useRequest(srvOverridePatientRefillDate, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setOverrideModalVisible(false);
        loadData();
      }
    },
  });

  const overrideNextRefillDateReqV2 = useRequest(srvOverridePatientRefillDateV2, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setOverrideModalVisible(false);
        message.success('Next refill date updated');
        loadData();
      } else {
        message.error(ret.msg);
      }
    },
  });

  const formatRequestedMedications = (v) => {
    if (!v) {
      return '';
    }
    var requestedMedicationsText = v.refill_active_med_items.map((item) => item.rxnt_active_med_name).join(', \n')
    return requestedMedicationsText;
  }

  const loadRefillRequestsReq = useRequest(srvPatientTreatmentRequests, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (currentUser.enable_refill_individual_medications) {
          ret.data.forEach((item) => {
            item.requested_medication = formatRequestedMedications(item);
          });
        }
        setRefillRequests(ret.data);
      }
    },
  });

  const addTreatmentRequestHistoryReq = useRequest(srvAddTreatmentRequestHistory, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        loadData();
      }
    },
  });

  const loadMembership = useRequest(srvGetPatientCurrentMembership, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (ret.data.status === 2) {
          setIsMemberActive(true);
        }
      }
    },
  })

  const closeDispute=()=>{
    disputeForm.resetFields();
    setDisputeVisible(false);
  }

  const fresh=()=>{
    setDisputeVisible(false);
    loadRefillRequestsReq.run({ id });
  }

  const approveRxnt=()=>{
    setDisputeVisible(false);
    const link = `/api/ehr/rxnt/sso?return_url=${Buffer.from(
      window.location.href,
    ).toString('base64')}&patient_id=${id}&order_type=2&treatment_request_id=${
      selected.id
    }`;
    onApprove(selected, 'PrescriptionMedications', link);
  }

  let adjustTreatmentRequestDateModalProps = {
    treatment_request: treatmentRequestToAdjust,
    visible: adjustTreatmentRequestDateModalVisible,
    maskClosable: false,
    closable: false,
    okText: 'Confirm',
    width: 600,
    title: 'Adjust Renewal Request Date',
    onOk: (fillDate, newDate, reason) => {
      addTreatmentRequestHistoryReq.run({
        treatment_request_id: treatmentRequestToAdjust.id,
        fill_date: fillDate,
        new_date: newDate,
        reason: reason,
      });
      setAdjustTreatmentRequestDateModalVisible(false);
    },
    onCancel: () => {
      setAdjustTreatmentRequestDateModalVisible(false);
    },
  };

  let overrideModalProps = {
    visible: overrideModalVisible,
    item: discharge,
    destroyOnClose: true,
    maskClosable: false,
    closable: false,
    // activeMeds: activeMeds,
    patientId: id,
    enableV2: currentUser.enable_refill_individual_medications,
    okText: 'Confirm',
    width: 400,
    title: 'Override next prescription fill Date',
    onOk: async (payload) => {
      if (currentUser.enable_refill_individual_medications) {
        await overrideNextRefillDateReqV2.run({user_id: payload.user_id, items: payload.items});
      } else {
        await overrideNextRefillDateReq.run({ user_id: id, next_refill_date: payload.next_refill_date, override_next_refill_date: payload.override_next_refill_date });
      }
      await loadDischargeInfoReq.run({id});
      // await historyReq.run({ user_id: id });
    },
    onCancel: () => {
      setOverrideModalVisible(false);
    },
  };

  const columns = [
    {
      title: 'Request Time',
      dataIndex: 'requested_at',
      ellipsis: true,
      width: 180,
      key: 'requested_at',
      render: (v, r) => {
        return formatUnix(r.requested_at);
      },
    },
    {
      title: 'Patient Note',
      dataIndex: 'note',
      ellipsis: true,
      key: 'note',
      width: 250,
      render: (v, r) => {
        if (r.status === 1) {
          var frontNote = '';
          if (r.consultation_id != '0') {
            frontNote = 'Please reissue the prescription.\n';
          }
          if (v) {
            return (
              <a
                style={{ whiteSpace: 'pre-wrap' }}
                onClick={() => {
                  setRequestNote(
                    'Please reissue the prescription\n' +
                      r.note +
                      '\nNew pharmacy after change: ' +
                      r.pharmacy_name,
                  );
                  setRequestNoteVisible(true);
                }}
              >
                {frontNote + v + '\nNew pharmacy after change: ' + r.pharmacy_name}
              </a>
            );
          } else {
            return (
              <a
                style={{ whiteSpace: 'pre-wrap' }}
                onClick={() => {
                  setRequestNote(frontNote + 'New pharmacy after change: ' + r.pharmacy_name);
                  setRequestNoteVisible(true);
                }}
              >
                {frontNote + 'New pharmacy after change: ' + r.pharmacy_name}
              </a>
            );
          }
        } else {
          return (
            <a
              onClick={() => {
                setRequestNote(r.note);
                setRequestNoteVisible(true);
              }}
            >
              {v}
            </a>
          );
        }
      },
    },
    {
      title: 'Side Effects',
      dataIndex: 'side_effect',
      ellipsis: true,
      width: 90,
      key: 'side_effect',
    },
    {
      title: 'Patient needs medication change',
      dataIndex: 'need_change',
      ellipsis: true,
      width: 180,
      key: 'need_change',
    },
    currentUser.enable_refill_individual_medications && {
      title: 'Requested Medication',
      dataIndex: 'requested_medication',
      ellipsis: true,
      width: 250,
      key: 'requested_medication',
      render: (v, r) => {
          return (
            <a
              onClick={() => {
                setRequestedMedications(v);
                setRequestedMedicationsVisible(true);
              }}
            >
              {v}
            </a>
          );
      },
    },
    {
      title: 'Admin Note',
      ellipsis: true,
      dataIndex: 'admin_note',
      key: 'admin_note',
      width: 250,
      render: (v, r) => {
        const notes = (
          <>
            <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: String(r.admin_note).replace(/\\n/g, '<br/>') }}></div>
            <div>{r.pdmp_notes}</div>
          </>
        )
        return (
          <a
            onClick={() => {
              setRequestNote(notes);
              setRequestNoteVisible(true);
            }}
          >
            {v}
          </a>
        );
      },
    },
    {
      title: 'Note Added',
      ellipsis: true,
      width: 100,
      dataIndex: 'note_added',
      key: 'note_added',
      render: (v, r) => {
        let text = '';
        if (r.is_closed) {
          text = 'Closed';
        } else {
          if (r.note_added) {
            text = 'Yes';
            if (r.note_type == '2') {
              text += '(Follow-up)';
            }
          } else {
            text = 'No';
          }
        }
        return text;
      },
    },
    {
      title: 'Approved By',
      ellipsis: true,
      width: 150,
      dataIndex: 'approver_name',
      key: 'approver_name',
      render: (v, r) => {
        if (r.status === 2) {
          return <div>{v}</div>;
        }
      },
    },
    {
      title: 'Status',
      ellipsis: true,
      width: 110,
      dataIndex: 'status_name',
      key: 'status_name',
      fixed: 'right',
      render: (v, r) => {
        if (r.status == 8) {
          return (
            <Tooltip title="Syncing failed, please refresh or prescribe in RXNT again">
              {v}
            </Tooltip>
          );
        }
        return <div>{v}</div>;
      },
    },
    {
      title: '',
      dataIndex: 'id',
      ellipsis: false,
      width: 400,
      key: 'id',
      fixed: 'right',
      render: (v, r) => {
        let statusButton = '';
        if (r.status == 1 || r.status == 5 || r.status == 8) {
          statusButton = (
            <>
              {(r.status == 1 ||
                (r.status == 5 && r.requested_at <= moment().unix()) ||
                r.status == 8) &&
                (r.has_occupy_flag ? (
                  <Tooltip title="The current request is being processed">
                    <Button
                      type="primary"
                      disabled={r.has_occupy_flag}
                      onClick={() => {
                        const link = `/api/ehr/rxnt/sso?return_url=${Buffer.from(
                          window.location.href,
                        ).toString('base64')}&patient_id=${id}&order_type=2&treatment_request_id=${
                          r.id
                        }`;
                        onApprove(r, 'PrescriptionMedications', link);
                      }}
                    >
                      Approve and Add Notes
                    </Button>
                  </Tooltip>
                ) : (
                  <>
                    <Button
                      type="primary"
                      onClick={() => {
                        const link = `/api/ehr/rxnt/sso?return_url=${Buffer.from(
                          window.location.href,
                        ).toString('base64')}&patient_id=${id}&order_type=2&treatment_request_id=${
                          r.id
                        }`;
                        onApprove(r, 'PrescriptionMedications', link);
                      }}
                    >
                      Approve and Add Notes
                    </Button>
                  </>
                ))}
              {(r.has_dispute_flag&&currentUser.enable_view_refill_dispute) && <Button disabled>In-Dispute</Button>}
              {(!r.has_dispute_flag&&currentUser.enable_view_refill_dispute) && (
                <Button
                  onClick={() => {
                    setSelected(r);
                    setAllowTransferApptInDispute(r.show_need_transfer_appt_reason)
                    setAllowFollowUpApptInDispute(r.show_need_follow_up_appt_reason)
                    setDisputeVisible(true);
                  }}
                >
                  Dispute
                </Button>
              )}
              {/* <Dropdown
                overlay={
                  <Menu
                    onClick={(e) => {
                      if (e.key == 'edit') {
                        setTreatmentRequestToAdjust(r);
                        setAdjustTreatmentRequestDateModalVisible(true);
                      }
                    }}
                  >
                    <Menu.Item key="edit">Edit</Menu.Item>
                  </Menu>
                }
              >
                <Button size="small">
                  <EllipsisOutlined />
                </Button>
              </Dropdown> */}
            </>
          );
        } else if (r.status == 2 && !r.note_added && !r.is_closed) {
          statusButton = (
            <Button
              type="danger"
              onClick={() => {
                onApprove(r, 'PrescriptionMedications', '');
              }}
            >
              Add Notes
            </Button>
          );
        }

        return (
          <Space>
            {r.pdmp_object_key && (
              <Button
                type="primary"
                danger
                onClick={() => {
                  downloadDocument(r.pdmp_object_key);
                }}
              >
                View PDMP
              </Button>
            )}
            {statusButton}
          </Space>
        );
      },
    },
  ].filter(Boolean);

  return (
    <>
      {/* <div className={cx({ next_follow_up_date: true })}>
        {patient.next_follow_up_date > 0 && (
          <p>
            Next follow-up date{' '}
            <b> {formatUnix(patient.next_follow_up_date, 'MMM D, YYYY')}</b>
          </p>
        )}
      </div> */}
      {(refillRequests.length > 0 || (patient.country === 'ca' && isMemberActive)) && (
        <>
          <h3 className={cx({ renewal_header: true })}>
            <div className={cx({ renewal_header_title: true })}>Prescription Renewal Requests</div>
            {(discharge.id || (patient.country === 'ca' && isMemberActive)) && (
              <>
                {(threeMonthRenewal || flagThreeMonthRenewal) && discharge.three_month_renewal && (
                  <div className={cx({three_month_renewal_tag: true})}>
                    <span>3-month prescribed</span>
                  </div>
                )}

                {currentUser.enable_refill_individual_medications && (
                  <div className={cx({renewal_header_override: true})}
                       style={{display: 'flex', minWidth: 'max-content'}}>
                    <span style={{flex: '0 1 auto', height: '100%'}}>
                      Earliest medication refill date{' '}
                      <b>{formatUnix(discharge.override_next_refill_date !== 0 ? discharge.override_next_refill_date : discharge.next_refill_date, 'MMM D')}</b>
                    </span>
                    <Button
                      onClick={() => {
                        setOverrideModalVisible(true);
                      }}
                      loading={overrideNextRefillDateReq.loading}
                      type="primary"
                      size="small"
                      style={{flex: '0 1 auto'}}
                    >
                      Override
                    </Button>
                  </div>
                )}
                {!currentUser.enable_refill_individual_medications && (
                  <div className={cx({renewal_header_override: true})}>
                  <span>
                    Next prescription fill date{' '}
                    <b>{formatUnix(discharge.next_refill_date, 'MMM D')}</b>
                  </span>
                    <Button
                      onClick={() => setOverrideModalVisible(true)}
                      loading={overrideNextRefillDateReq.loading}
                      type="primary"
                      size="small"
                    >
                      Override
                    </Button>
                  </div>
                )}
              </>
            )}
          </h3>
          <div className={cx({summary: true})}>
            <div>Patients agree to the following conditions:</div>
            <ul>
              <li>• My symptoms are stable.</li>
              <li>• My medication(s) are not causing intolerable side effects.</li>
              <li>
                • My clinician will review my request and decide if an appointment is needed for a
                prescription renewal.
              </li>
            </ul>
            <CheckPDMPPane id={id}/>
          </div>
          <Table
            columns={columns}
            dataSource={refillRequests}
            rowKey="id"
            scroll={{ x: 1200 }}
            loading={loadRefillRequestsReq.loading}
            pagination={{ pageSize: 5, simple: true }}
          />
        </>
      )}

      {overrideModalVisible && <OverrideModal {...overrideModalProps} />}

      <Modal
        title="Note"
        visible={requestNoteVisible}
        width={800}
        destroyOnClose={true}
        onCancel={() => {
          setRequestNoteVisible(false);
        }}
        onOk={(v) => {
          setRequestNoteVisible(false);
        }}
        okText="Ok"
      >
        <div>{requestNote}</div>
      </Modal>
      <Modal
        title="Requested Medication"
        visible={requestedMedicationsVisible}
        width={800}
        destroyOnClose={true}
        onCancel={() => {
          setRequestedMedicationsVisible(false);
        }}
        onOk={(v) => {
          setRequestedMedicationsVisible(false);
        }}
        okText="Ok"
      >
        <div>{requestedMedications}</div>
      </Modal>
      {adjustTreatmentRequestDateModalVisible && (
        <AdjustTreatmentRequestDateModal {...adjustTreatmentRequestDateModalProps} />
      )}
      <DisputeModal disputeVisible={disputeVisible} closeDispute={closeDispute} request={selected} fresh={fresh} approve={approveRxnt} disputeForm={disputeForm} allowTransferApptInDispute={allowTransferApptInDispute} allowFollowUpApptInDispute={allowFollowUpApptInDispute}/>
    </>
  );
};

export default RenewalRequest;
