import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  message,
  Steps,
  Popconfirm,
  Tag,
  Select,
  Space,
  Drawer,
  Divider,
  Button,
  Radio,
  Descriptions,
  Collapse,
  DatePicker,
  Tooltip
} from 'antd';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import { useMount, useMap } from 'ahooks';

import { QuestionCircleOutlined } from '@ant-design/icons';

import styles from './index.less';
import {
  srvPatientUpcommingAppointments,
  srvTransferDoctors,
  srvAppointmentCalendar,
  srvDoctorTransfer,
  srvDoctorTransferWithCancel,
} from '@/services/admin';

const { Panel } = Collapse;
const cx = cn.bind(styles);
const { Step } = Steps;

const Calendar = (props) => {
  const { calendar, appointment_type, timezone, doctor_id, appointedAt, setAppointedAt } = props;
  const fetchDates = (dir) => {
    setAppointedAt(0);
    props.loadCalendar &&
      props.loadCalendar({
        appointment_type: appointment_type,
        timezone: timezone,
        doctor_id: doctor_id,
        days: 4,
        date: dir == 'next' ? calendar.end_date : calendar.start_date,
        dir: dir,
      });
  };

  return (
    <div>
      {calendar.start_date && (
        <div className={cx({ calendar: true })}>
          <div className={cx({ calendarOp: true })}>
            <Button
              disabled={!calendar.has_prev}
              onClick={() => {
                fetchDates('prev');
              }}
            >
              Prev
            </Button>
            <Button
              disabled={!calendar.has_next}
              onClick={() => {
                fetchDates('next');
              }}
            >
              Next
            </Button>
          </div>
          <div className={cx({ calendarDates: true })}>
            {calendar.dates.map((v, idx) => {
              const timesDom =
                v.times &&
                v.times.map((x, i) => {
                  return (
                    <div className={cx({ timeSlot: true })} key={i}>
                      <Button
                        size="small"
                        onClick={() => {
                          props.chooseAppointedAt(x);
                        }}
                        type={appointedAt > 0 && appointedAt === x ? 'danger' : 'default'}
                      >
                        {moment
                          .unix(x)
                          .tz(timezone)
                          .format('hh:mm A')}
                      </Button>
                    </div>
                  );
                });
              return (
                <div className={cx({ dateColumn: true })} key={idx}>
                  <div className={cx({ dateSlot: true })}>
                    {moment(v.date, 'YYYYMMDD')
                      .tz(timezone)
                      .format('MMM D')}
                  </div>
                  <div className={cx({ dateSlot: true })}>
                    {moment(v.date, 'YYYYMMDD')
                      .tz(timezone)
                      .format('ddd')}
                  </div>
                  {timesDom}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const TransferPatient = (props) => {
  const { visible, patientID, onAutoTransfer } = props;
  const [appointments, setAppointments] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [targetDoctor, setTargetDoctor] = useState('');
  const [current, setCurrent] = React.useState(0);
  const [method, setMethod] = React.useState(1);
  const [refundType, setRefundType] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [filteredDoctors, setFilteredDoctors] = useState([]);

  const [
    scheduleVisibleMap,
    { set: setScheduleVisibleMap, setAll: setAllScheduleVisibleMap, get: getScheduleVisibleMap },
  ] = useMap([]);

  const [
    calendarMap,
    { set: setCalendarMap, setAll: setAllCalendarMap, get: getCalendarMap },
  ] = useMap([]);

  const [
    scheduleMap,
    { set: setScheduleMap, setAll: setAllScheduleMap, get: getScheduleMap },
  ] = useMap([]);

  const [
    outOfAvailabilityMap,
    { set: setOutOfAvailabilityMap, setAll: setAllOutOfAvailabilityMap, get: getOutOfAvailabilityMap },
  ] = useMap([]);


  useMount(async () => {
    try {
      const res = await srvPatientUpcommingAppointments({ id: patientID });
      if (res.success) {
        const appts = [];
        for (const v of res.data) {
          if (![15, 16, 17, 18].includes(v.appointment_type)) {
            appts.push(v);
          }
        }
        setAppointments(appts);

        var map = [];
        var outOfAvailability = [];
        for (const v of appts) {
          map.push([v.id, false]);
          outOfAvailability.push([v.id, false]);
        }

        var scheduleMap = [];
        for (const v of appts) {
          scheduleMap.push([v.id, 0]);
        }

        var calendarMap = [];
        for (const v of appts) {
          calendarMap.push([v.id, {}]);
        }

        setAllScheduleVisibleMap(map);
        setAllScheduleMap(scheduleMap);
        setAllCalendarMap(calendarMap);
        setAllOutOfAvailabilityMap(outOfAvailability);
      }

      const res2 = await srvTransferDoctors({ id: patientID });
      if (res2.success) {
        setDoctors(res2.data);
        setFilteredDoctors(res2.data)
      }
    } catch (err) {}
  });

  const onClose = () => {
    props.onClose && props.onClose();
  };

  const steps = [
    {
      title: 'Choose Doctor',
    },
    {
      title: 'Transfer',
    },
  ];
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onDoctorSelected = (v) => {
    setTargetDoctor(v);
  };

  const onMethodChange = (e) => {
    setMethod(e.target.value);
  };

  const onRefundTypeChange = (e) => {
    setRefundType(e.target.value);
  };

  const onSearch = (value) => {
      const filtered = doctors.filter((doctor) =>
        doctor.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredDoctors(filtered);
  };

  const toggleReschedulePanel = (app) => {
    const visible = getScheduleVisibleMap(app.id);
    if (!visible) {
      loadCalendar(app.id, {
        appointment_type: app.appointment_type,
        timezone: app.timezone,
        doctor_id: targetDoctor,
        days: 4,
        date: moment().format('YYYYMMDD') * 1,
        dir: 'next',
      });
    }

    setScheduleVisibleMap(app.id, !visible);
  };

  const loadCalendar = async (appointmentID, payload) => {
    try {
      const res = await srvAppointmentCalendar({ ...payload });
      if (res.success) {
        setCalendarMap(appointmentID, res.data);
      }
    } catch (err) {}
  };

  const commit = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      if (method == 1) {
        const payload = {
          patient_id: props.patientID,
          target_doctor_id: targetDoctor,
          items: [],
        };
        if (targetDoctor == '') {
          message.error('Please choose doctor first');
          return;
        }
        for (const v of appointments) {
          if (getScheduleMap(v.id) === 0) {
            message.error('Please set reschedule time.');
            return;
          }

          payload.items.push({
            appointment_id: v.id,
            time: getScheduleMap(v.id),
            out_of_availability: getOutOfAvailabilityMap(v.id),
          });
        }

        await srvDoctorTransfer(payload);

        props.refresh && props.refresh();
        props.onClose && props.onClose();
      } else {
        if (targetDoctor == '') {
          message.error('Please choose doctor first');
          return;
        }
        const payload = {
          patient_id: props.patientID,
          target_doctor_id: targetDoctor,
          refund_type: refundType,
        };

        await srvDoctorTransferWithCancel(payload);

        props.refresh && props.refresh();
        props.onClose && props.onClose();
      }
    } catch (err) {}

    setLoading(false);
  };

  const directCommit = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      if (appointments.length == 0) {
        const payload = {
          patient_id: props.patientID,
          target_doctor_id: targetDoctor,
          items: [],
        };
        if (targetDoctor == '') {
          message.error('Please choose doctor first');
          return;
        }
        await srvDoctorTransfer(payload);

        props.refresh && props.refresh();
        props.onClose && props.onClose();
      }
    } catch (err) {}

    setLoading(false);
  };

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      destroyOnClose={true}
      className={cx({ transferDrawer: true })}
      title={`Transfer Patient`}
      width="600"
      placement="right"
      closable={false}
    >
      <div className={cx({ transferPatient: true })}>
        <div className={cx({ targetTransferTitle: true })}>Transfer to target clinician</div>
        {current == 0 && (
          <div>
            <Row gutter={24}>
              <Col span={12}>
                <Select style={{ width: '100%' }} value={targetDoctor} onChange={onDoctorSelected} filterOption={false} onSearch={onSearch} showSearch={true}>
                  {filteredDoctors.map((v) => {
                    const text = `${v.name} ${v.title} ${ 
                      !v.accept_transfer_patients ? '(Not Accept Transfer)' : ''
                    }`;
                    return (
                      <Select.Option key={v.id} value={v.id}>
                        <Tooltip title={text}>{text}</Tooltip>
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
              <Col span={8} offset={4}>
                {appointments.length > 0 && (
                  <Button type="primary" disabled={targetDoctor == ''} onClick={() => next()}>
                    Next
                  </Button>
                )}
                {appointments.length == 0 && (
                  <Button type="primary" onClick={directCommit} loading={loading}>
                    Commit
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        )}
        {current == 1 && (
          <div className={cx({ appointments: true })}>
            <div className={cx({ method: true })}>
              <Radio.Group onChange={onMethodChange} value={method}>
                <Radio.Button value={1}>Reschedule</Radio.Button>
                <Radio.Button value={2}>Cancel</Radio.Button>
              </Radio.Group>
            </div>
            <div className={cx({ sectionHeader: true })}>
              <h4>Appointments</h4>
            </div>
            {appointments.map((v, i) => {
              return (
                <div key={i} className={cx({ appointmentItem: true })}>
                  <Descriptions title="" layout="vertical" size="small" column={3}>
                    <Descriptions.Item label="Type">{v.appointment_type_name}</Descriptions.Item>
                    <Descriptions.Item label="Timezone">{v.timezone}</Descriptions.Item>
                    <Descriptions.Item label="Time">
                      {moment.unix(v.appointed_at).format('MMM D h:mm A')}
                    </Descriptions.Item>
                  </Descriptions>

                  {method == 1 && (
                    <div className={cx({ rescheduleBox: true })}>
                      <Row>
                        <Col span={6}>
                          <Space>
                            <Button onClick={() => toggleReschedulePanel(v)}>Reschedule</Button>
                          </Space>
                        </Col>
                        <Col span={8}>
                          {getScheduleMap(v.id) !== 0 && (
                            <Tag>
                              {moment
                                .unix(getScheduleMap(v.id))
                                .tz(v.timezone)
                                .format('MMM D h:mm A')}
                            </Tag>
                          )}
                        </Col>
                      </Row>
                      {getScheduleVisibleMap(v.id) == true && (
                        <>
                          <Collapse
                            defaultActiveKey={['1']}
                            ghost={true}
                            bordered={false}
                            expandIconPosition="right"
                            style={{ marginTop: '10px' }}
                          >
                            <Panel header="Date&Time" key="1">
                              <Calendar
                                calendar={getCalendarMap(v.id)}
                                appointment_type={v.appointment_type}
                                timezone={v.timezone}
                                doctor_id={targetDoctor}
                                appointedAt={getScheduleMap(v.id)}
                                setAppointedAt={(val) => {
                                  setScheduleMap(v.id, val);
                                }}
                                chooseAppointedAt={(val) => {
                                  setScheduleMap(v.id, val);
                                  setScheduleVisibleMap(v.id, false);
                                  setOutOfAvailabilityMap(v.id, false);
                                }}
                                loadCalendar={(payload) => {
                                  loadCalendar(v.id, payload);
                                }}
                              />
                            </Panel>
                            <Panel header="Other time" key="2">
                              <Row>
                                <Col span={16}>
                                  <label>Date / Time</label>
                                  <div>
                                    <DatePicker
                                      showTime
                                      size="default"
                                      format="YYYY-MM-DD HH:mm"
                                      minuteStep={5}
                                      style={{ width: '80%' }}
                                      onChange={(date, dateString) => {
                                        setScheduleMap(moment.tz(dateString, v.timezone).unix());
                                        setOutOfAvailabilityMap(v.id, true);
                                        setScheduleVisibleMap(v.id, false);
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Panel>
                          </Collapse>
                        </>
                      )}
                    </div>
                  )}
                </div>
              );
            })}

            {method == 2 && (
              <>
                <div className={cx({ sectionHeader: true })} style={{ marginTop: 16 }}>
                  <h4>Cancel Options</h4>
                </div>
                <div className={cx({ appointmentItem: true })}>
                  <Row>
                    <Col span={6}>Refund Type</Col>
                    <Col span={18}>
                      <Radio.Group value={refundType} onChange={onRefundTypeChange}>
                        <Radio value={1}>All</Radio>
                        <Radio value={2}>Remained</Radio>
                        <Radio value={3}>None</Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                </div>
              </>
            )}

            <div className={cx({ btnBox: true })}>
              <Space>
                <Button onClick={() => prev()}>Prev</Button>
                <Button type="danger" onClick={commit} loading={loading}>
                  Confirm
                </Button>
              </Space>
            </div>
          </div>
        )}

        <Divider />
        <Row gutter={24}>
          <Col span={12}>
            <Space className={cx({ autoTransferTitle: true })}>
              Auto-transfer
              <Tooltip title={'If you select auto-transfer, we will automatically match the patient with an  appropriate provider. If there is no available provider, the patient will be added to the awaiting transfer list, until a provider becomes available.'}>
                <QuestionCircleOutlined style={{ color: "#5469D4", fontSize: '14px', lineHeight: '28px', marginBottom: '6px' }} />
              </Tooltip>
            </Space>
          </Col>
          <Col span={8} offset={4}>
            <Button type="primary" onClick={onAutoTransfer}>
              Auto-Transfer
            </Button>
          </Col>
        </Row>
      </div>
    </Drawer>
  );
};

export default TransferPatient;
