import { Redirect } from 'umi';
import { connect, history } from 'umi';
import React, { useState } from 'react';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

import Blacklist from './Blacklist';
import RecommendedList from './RecommendedList';
import PharmacyManage from './PharmacyManagement';
const Pharmacies = (props) => {
  const { account, location } = props;
  const [activeKey, setActiveKey] = useState('1');
  const onTabChange = (v) => {
    setActiveKey(v);
  };
  return (
    <div className={cx({ container: true })}>
      <Tabs defaultActiveKey="1" activeKey={activeKey} onTabClick={onTabChange}>
        <TabPane tab="Pharmacy Management" key="1">
          <PharmacyManage parentProps={props} activeKey={activeKey} active={activeKey === '1'} />
        </TabPane>
        <TabPane tab="Pharmacy Blacklist" key="2">
          <Blacklist parentProps={props} activeKey={activeKey} active={activeKey === '2'} />
        </TabPane>
        <TabPane tab="Recommended Pharmacies" key="3">
          <RecommendedList parentProps={props} activeKey={activeKey} active={activeKey === '3'} />
        </TabPane>
      </Tabs>
    </div>
  )
};

export default connect(({ account, loading }) => ({
  account,
  loading,
}))(Pharmacies);
