export default class DashboardUtil {
  static getOtherWarningList(dataList) {
    var result = [];
    dataList.forEach((data) => {
      var warning = [];
      if (data.membership_cancellation_rate_is_red) {
        warning.push({
          name: 'Membership cancellation rate',
          value: `${data.membership_cancellation_rate}%`,
        });
      }
      if (data.effecttive_working_hours_is_red) {
        let hours = 0;
        let mins = 0;
        if (data.effecttive_working_hours) {
          hours = Math.floor(data.effecttive_working_hours / 3600);
          mins = Math.floor((data.effecttive_working_hours % 3600) / 60);
        }
        warning.push({
          name: 'Effective working hours',
          value: `${hours}hrs ${mins}mins`,
        });
      }
      if (data.initial_rating_5_star_rate_is_red) {
        warning.push({
          name: '5-star review(initial)',
          value: `${data.initial_rating_5_star_rate}%`,
        });
      }
      if (data.follow_up_rating_5_star_rate_is_red) {
        warning.push({
          name: 'Member 5-star review',
          value: `${data.follow_up_rating_5_star_rate}%`,
        });
      }
      if (data.rating_average_star_is_red) {
        warning.push({
          name: 'Patient review',
          value: `${data.rating_average_star}/5`,
        });
      }
      if (data.initial_average_duration_is_red) {
        warning.push({
          name: 'Average duration(initial)',
          value: data.initial_average_duration,
        });
      }
      if (data.follow_up_average_duration_is_red) {
        warning.push({
          name: 'Average duration(follow-up)',
          value: data.follow_up_average_duration,
        });
      }
      if (data.provider_no_show_is_red) {
        warning.push({
          name: 'Provider no-show',
          value: data.provider_no_show,
        });
      }
      if (data.patient_conversion_rate_is_red) {
        warning.push({
          name: 'Patient conversion',
          value: `${data.patient_conversion_rate}%`,
        });
      }
      if (data.note_completion_rate_is_red) {
        warning.push({
          name: 'Chart notes completion rate (within 24 hrs)',
          value: `${data.note_completion_rate}%`,
        });
      }
      if (data.refill_response_rate_is_red) {
        warning.push({
          name: 'Refill response rate (within 72 hrs)',
          value: `${data.refill_response_rate}%`,
        });
      }
      if (data.ugrent_refill_is_red) {
        warning.push({
          name: 'Urgen refill (>24 hrs)',
          value: data.ugrent_refill,
        });
      }
      if (data.consulation_response_rate_is_red) {
        warning.push({
          name: 'Consultation response rate (within 72 hrs)',
          value: `${data.consulation_response_rate}%`,
        });
      }
      if (data.unsolved_consulation_is_red) {
        warning.push({
          name: 'Unsolved consultation (>48 hrs)',
          value: data.unsolved_consulation,
        });
      }
      result.push(warning);
    });

    return result;
  }
}
