import React, { useState, useEffect } from 'react';
import { Drawer, Form, Space, Select, DatePicker, Alert, Row, Col, Button, Spin } from 'antd';
import {useMount, useRequest} from 'ahooks';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);
import MultiNotesForm from './MultiNotesForm';
import TherapistGenericNoteForm from './TherapistGenericNoteForm';
import CoachingNoteForm from './CoachingNoteForm';
import InitialEvaluationNote from '@/components/InitialEvaluationNote';
import moment from "moment";
import TransferForm from './TransferForm';
import { queryCurrent } from '@/services/account';

import {srvPatientDetail,srvPatientLastConfirmedFollowUpAppointment} from '@/services/patient';


import {
  srvGetPatientAppointmentQuestionResponse
} from '@/services/account';

const AddNote = (props) => {
  const {
    noteVisible,
    setNoteVisible,
    appointment,
    RXNTCallbackLink,
    onRefresh,
    addNoteType,
    sourceAction,
    prescriptionInfo,
    patientInfo, // required
    admin,
    treatmentPlanHistories,
    urgentRefillFlag,
    originName,
  } = props;
  const [form] = Form.useForm();

  const [providerNote, setProviderNote] = useState('FollowUp'); // InitialEvaluation
  const [drawerWeight, setDrawerWeight] = useState(600);
  const [providerProfile, setProviderProfile] = useState({});
  const [appointmentResponse, setAppointmentResponse] = useState(null)
  const [disableProviderNote, setDisabledProviderNote] = useState(false)
  const [patient, setPatient] = useState({});
  const [showTherapyScheduleAppointment, setShowTherapyScheduleAppointment] = useState(false)
  const [nextAppointedAt, setNextAppointedAt] = useState(0)
  const [tmpAppointedAt, setTmpAppointedAt] = useState(0)
  const [recurringData, setRecurringDate] = useState({})
  const [isCurrentDoctor, setIsCurrentDoctor] = useState(true)

  const loadPatientInfoReq = useRequest(srvPatientDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setPatient(ret.data);
        setIsCurrentDoctor(ret.data.is_current_doctor)
      }
    },
  });

  const fetchProviderProfile = useRequest(queryCurrent, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setProviderProfile(ret.data);
        if (ret.data.doctor_type===3){
          setProviderNote('TherapistGeneric');
        } else if (ret.data.doctor_type === 5) {
          setProviderNote('CoachingNote');
        }
      }
    },
  });

  const setRefillNoteTypeBasedOnLastAppointment = useRequest(srvPatientLastConfirmedFollowUpAppointment, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (
          ret.data &&
          moment.unix(ret.data.date).isAfter(moment().subtract(30, 'days')) &&
          moment.unix(ret.data.date).isBefore(moment.now())
        ) {
          setProviderNote('FollowUp');
          return;
        }
      }
      setProviderNote('Refill');
    },
  });

  const fetchAppointmentResponse = useRequest(srvGetPatientAppointmentQuestionResponse, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if(ret.data) {
          setAppointmentResponse({
            questions: JSON.parse(ret.data.questions),
            answers: JSON.parse(ret.data.answers)
          })
          setProviderNote('AsyncVideoFollowUp')
          setDisabledProviderNote(true)
        }
      }
    }
  })

  useEffect(() => {
    onDrawerWeightChange(providerNote);
    if(providerProfile.doctor_type == 3) {
      setProviderNote('TherapistGeneric')
    } else if (providerProfile.doctor_type == 5) {
      setProviderNote('CoachingNote')
    }
  }, [providerNote, prescriptionInfo, appointmentResponse, providerProfile]);


  useMount(async () => {
    await loadPatientInfoReq.runAsync({ id: patientInfo.id, });
    await fetchProviderProfile.runAsync();
    if (providerProfile.doctor_type===3){
      setProviderNote('TherapistGeneric')
      return
    } else if (providerProfile.doctor_type===5) {
      setProviderNote('CoachingNote')
      return
    }

    switch (sourceAction) {
      case 'PendingPrescriptionRenewal':
      case 'PrescriptionMedications':
      case 'PrescribeInRXNT':
        await setRefillNoteTypeBasedOnLastAppointment.runAsync({ id: patientInfo.id})
        break;
      case 'ClinicianNotes':
        if (!isCurrentDoctor) {
          setProviderNote('PlainRefill')
        } else if (treatmentPlanHistories && treatmentPlanHistories.length === 0) {
          setProviderNote('InitialEvaluation');
        }
        break;
      case 'AppointmentTypes-2': // FollowUp
        setProviderNote('FollowUp');
        break;
      // case 'ClinicianNotes':
      case 'AppointmentTypes-1': // Initial(Online)
      case 'AppointmentTypes-4': // Initial(In-person)
      case 'AppointmentTypes-5': // Pediatric Consultation(50min)
      case 'AppointmentTypes-20': // InitialOnline50Min
      case 'AppointmentTypes-21': // InitialInPerson50Min
        setProviderNote('InitialEvaluation');
        break;
      default:
        break;
    }

    if(prescriptionInfo && prescriptionInfo.appointment_response) {
      setAppointmentResponse(prescriptionInfo.appointment_response)
      setProviderNote('AsyncVideoFollowUp')
    } else {
      fetchAppointmentResponse.run({ appointment_id: 0, patient_id: patientInfo.id })
    }
  });

  // let startApptTime = 0
  // let endApptTime = 0
  let inPersonApptointment = false
  let showBanner = true
  const inPersonApptTypes = [4, 7, 8, 14]
  // below is the logic for showing banner if adding notes from provider home screen
  if (appointment !== undefined){
    // startApptTime = moment.unix(appointment.appointed_at)
    // endApptTime = moment.unix(appointment.appointed_at + (60*25))
    // form.setFieldValue(
    //   ['struct_note_data', 'appointment_start_time'],
    //   startApptTime
    // );
    // form.setFieldValue(
    //   ['struct_note_data', 'appointment_end_time'],
    //   endApptTime
    // );
    if (inPersonApptTypes.includes(appointment.appointment_type)) {
      inPersonApptointment = true
    }
  }
  if (inPersonApptointment === true || patient.phe_endate > moment.utc().unix() || patient.phe_endate === 0){
    showBanner = false
  }

  const showReferralBanner= () => {
    if (showBanner === false || providerProfile.doctor_type===3 || providerProfile.doctor_type===5) {
      // no need to show banner
    } else if (patient.in_person_follow_up_end_date < moment.utc().unix()) {

        return (
          <Row direction="vertical" style={{ width:'100%', paddingTop: 10}}>
            <Alert message={"Remind the patient to upload the telemedicine referral form to the website if CII medication is needed for treatment."} type={"error"} style={{width: '100%'}} closable={true}/>
          </Row>
        )
      } else {
        return (
          <Row direction="vertical" style={{ width:'100%', paddingTop: 10}}>
            <Alert message={"The patient has submitted telemedicine referral form."} type={"info"} style={{width: '100%'}} closable={true}/>
          </Row>
        )
      }
    }

  const onDrawerWeightChange = (value) => {
    if (value === 'InitialEvaluation') {
      setDrawerWeight('70%');
    } else if (value == 'AsyncVideoFollowUp' && appointmentResponse) {
      setDrawerWeight(1200);
    } else {
      setDrawerWeight(600);
    }
  }

  const onNoteClose = () => {
    form.resetFields();
    setNoteVisible(false);
  };

  const onNoteChange = (value) => {
    setProviderNote(value);
  }

  const noteHeaderDom = () => {
    if (urgentRefillFlag){
      return (
        <Row direction="vertical" style={{ width:'100%'}}>
          <Col style={{ float:'left', width:'50%', marginTop:'20px' }}>
            Provider Note
            <Select
              placeholder="Select a option"
              value={"Coverage Note"}
              style={{ width: '200px', }}
              disabled
            >
              <Select.Option value="Coverage Note">Coverage Note</Select.Option>
            </Select>
          </Col>
          <Space/>
          {showReferralBanner()}
        </Row>
      )
    }
    if (providerNote === 'InitialEvaluation') {
      return (
        <Form form={form} layout="vertical">
        <Row direction="vertical" style={{ width: '100%' }}>
          <Col
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              marginTop: '20px',
            }}
          >
            <Form.Item layout="vertical" label="Provider Note">
              <Select
                placeholder="Select a option"
                onChange={onNoteChange}
                value={providerNote}
                style={{ width: '200px' }}
                disabled={disableProviderNote}
              >
                {providerProfile.doctor_type === 3 && (
                  <Select.Option value="TherapistGeneric">Generic Therapy Note</Select.Option>
                )}
                {providerProfile.doctor_type === 5 && (
                  <Select.Option value="CoachingNote">Coaching Note</Select.Option>
                )}
                {providerProfile.doctor_type !== 3 && providerProfile.doctor_type !== 5 && (
                  <>
                    <Select.Option value="InitialEvaluation">Initial Evaluation</Select.Option>
                    <Select.Option value="FollowUp">Follow-Up Evaluation</Select.Option>
                    <Select.Option value="TransferOfCare">
                      Follow-Up: Transfer of Care
                    </Select.Option>
                    <Select.Option value="Refill">Refill Request Note</Select.Option>
                    <Select.Option value="PlainRefill">Plain Note</Select.Option>
                    <Select.Option value="Transfer">Transfer</Select.Option>
                    <Select.Option value="Discharge">Discharge</Select.Option>
                    {appointmentResponse && (
                      <Select.Option value="AsyncVideoFollowUp">
                        Async Video Follow-Up
                      </Select.Option>
                    )}
                  </>
                )}
              </Select>
            </Form.Item>
            <div style={{ display: 'flex' }}>
              <Form.Item
                name={['struct_note_data', 'appointment_start_time']}
                label="Appointment start time"
                rules={[
                  { required: true, message: 'appointment start time is required' },
                  {
                    validator: (_, reviewValue) => {
                      if (reviewValue === 0 || reviewValue === '0') {
                        return Promise.reject();
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
                style={{ marginLeft: '50px', marginRight: 'auto' }}
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm"
                  showTime
                />
              </Form.Item>
              <Form.Item
                name={['struct_note_data', 'appointment_end_time']}
                label="Appointment end time"
                rules={[
                  { required: true, message: 'appointment end time is required' },
                  {
                    validator: (_, reviewValue) => {
                      if (reviewValue === 0 || reviewValue === '0') {
                        return Promise.reject();
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
                style={{ marginLeft: '50px', marginRight: 'auto' }}

              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm"
                  showTime
                />
              </Form.Item>
            </div>
          </Col>

          {showReferralBanner()}
        </Row>
      </Form>
      );
    }
    if (providerNote === 'Transfer' || providerNote === 'Discharge') {
      return (
        <div>
        <h4 style={{ display: 'inline' }}>Provider Note
          <Select
            placeholder="Discharge/Transfer"
            style={{ width: '200px', marginLeft: '10px' }}
            onChange={onNoteChange}
            value={providerNote}
            disabled={disableProviderNote}
          >
            {(providerProfile.doctor_type===3) &&
              <Select.Option value="TherapistGeneric">Generic Therapy Note</Select.Option>
            }
            {(providerProfile.doctor_type===5) &&
              <Select.Option value="CoachingNote">Coaching Note</Select.Option>
            }
            {
              (providerProfile.doctor_type!==3&&providerProfile.doctor_type!==5) &&
              <>
                <Select.Option value="InitialEvaluation">Initial Evaluation</Select.Option>
                <Select.Option value="FollowUp">Follow-Up Evaluation</Select.Option>
                <Select.Option value="TransferOfCare">Follow-Up: Transfer of Care</Select.Option>
                <Select.Option value="Refill">Refill Request Note</Select.Option>
                <Select.Option value="PlainRefill">Plain Note</Select.Option>
                <Select.Option value="Transfer">Transfer</Select.Option>
                <Select.Option value="Discharge">Discharge</Select.Option>
              </>
            }
          </Select>
        </h4>
      </div>
      )
    }
    if ((providerNote == 'TherapistGeneric' || providerNote == 'CoachingNote') && showTherapyScheduleAppointment) {
      return (
        <Row direction="vertical" style={{ width: '100%'}}>
          <Col span={24}>
            <div className={cx({therapyScheduleApptHeader: true})}>
              <div>
                Schedule Appointment
              </div>
              <div>
                <Button type="link" onClick={()=>{
                  setShowTherapyScheduleAppointment(false)
                  setTmpAppointedAt(0);
                }}>Cancel</Button>
                <Button
                  type="primary"
                  disabled={!(tmpAppointedAt > 0)}
                  onClick={()=> {
                    console.log(form.getFieldsValue())
                    setRecurringDate(form.getFieldsValue());
                    setShowTherapyScheduleAppointment(false)
                    setNextAppointedAt(tmpAppointedAt)
                    setTmpAppointedAt(0);
                  }
                  }>Confirm</Button>
              </div>
            </div>
          </Col>
        </Row>
      )
    }

    return (
      <>
      <Row direction="vertical" style={{ width:'100%'}}>
        <Col style={{ float:'left', width:'100%', marginTop:'20px' }}>
          Provider Note &nbsp;
          <Select
            placeholder="Select a option"
            onChange={onNoteChange}
            value={providerNote}
            style={{ width: '200px', }}
            disabled={disableProviderNote}
          >
              {(providerProfile.doctor_type===3) &&
                <Select.Option value="TherapistGeneric">Generic Therapy Note</Select.Option>
              }
              {(providerProfile.doctor_type===5) &&
                <Select.Option value="CoachingNote">Coaching Note</Select.Option>
              }
              {
                (providerProfile.doctor_type !==3&&providerProfile.doctor_type !==5) &&
                <>
                {isCurrentDoctor &&
                  <>
                    <Select.Option value="InitialEvaluation">Initial Evaluation</Select.Option>
                    <Select.Option value="FollowUp">Follow-Up Evaluation</Select.Option>
                    <Select.Option value="TransferOfCare">Follow-Up: Transfer of Care</Select.Option>
                  <Select.Option value="Refill">Refill Request Note</Select.Option>
                  </>
                }
                  <Select.Option value="PlainRefill">Plain Note</Select.Option>
                  {isCurrentDoctor &&
                    <>
                      <Select.Option value="Transfer">Transfer</Select.Option>
                      <Select.Option value="Discharge">Discharge</Select.Option>
                    </>
                  }
                  {appointmentResponse &&
                    <Select.Option value="AsyncVideoFollowUp">Async Video Follow-Up</Select.Option>
                  }
                </>
              }
          </Select>
        </Col>
        <Space/>
        {showReferralBanner()}
      </Row>
      <Row style={{ width: '100%', marginTop: '5px', fontSize: '12px', color: 'red' }}>
        {!isCurrentDoctor && (providerNote != 'TherapistGeneric' && providerNote != 'CoachingNote') &&
          "The patient is not assigned to you, so you can only add a plain note here."
        }
      </Row>
      </>
    )
  }

  const noteContentDom = () => {
    // console.log('noteContentDom:', providerNote, appointment, patient)
    // Pediatric Consultation(50min)、Initial(In-person)、Initial(Online)
    // appointment && [1, 4, 5].includes(appointment.appointment_type)
    if (providerNote === 'TherapistGeneric') {
      return (
        <TherapistGenericNoteForm
        form={form}
        admin={admin}
        patient={patient}
        appointment={appointment}
        providerNote={providerNote}
        prescriptionInfo={prescriptionInfo}
        addNoteType={addNoteType}
        sourceAction={sourceAction}
        RXNTCallbackLink={RXNTCallbackLink}
        setNoteVisible={setNoteVisible}
        onRefresh={onRefresh}
        providerProfile={providerProfile}
        showTherapyScheduleAppointment={showTherapyScheduleAppointment}
        showSchedule={()=>{ setShowTherapyScheduleAppointment(true) }}
        recurringData={recurringData}
        nextAppointedAt={nextAppointedAt}
        setNextAppointedAt={setNextAppointedAt}
        tmpAppointedAt={tmpAppointedAt}
        setTmpAppointedAt={setTmpAppointedAt}
        />
      );
    }
    if(providerNote === 'CoachingNote') {
      return (
        <CoachingNoteForm
        form={form}
        admin={admin}
        patient={patient}
        appointment={appointment}
        providerNote={providerNote}
        prescriptionInfo={prescriptionInfo}
        addNoteType={addNoteType}
        sourceAction={sourceAction}
        RXNTCallbackLink={RXNTCallbackLink}
        setNoteVisible={setNoteVisible}
        onRefresh={onRefresh}
        providerProfile={providerProfile}
        showTherapyScheduleAppointment={showTherapyScheduleAppointment}
        showSchedule={()=>{ setShowTherapyScheduleAppointment(true) }}
        recurringData={recurringData}
        nextAppointedAt={nextAppointedAt}
        setNextAppointedAt={setNextAppointedAt}
        tmpAppointedAt={tmpAppointedAt}
        setTmpAppointedAt={setTmpAppointedAt}
        />
      );
    }
    if (providerNote === 'InitialEvaluation') {
      return (
        <InitialEvaluationNote
          form={form}
          patient={patient}
          appointment={appointment}
          setNoteVisible={setNoteVisible}
          onRefresh={onRefresh}
        />
      );
    }
    if(providerNote==='Transfer'||providerNote==='Discharge'){
      return (
        <div key={providerNote}>
          <TransferForm transferForm={form} patient={patient} patientID={patient.id} setNoteVisible={setNoteVisible} noteType={providerNote} onRefresh={onRefresh} appointment={appointment}/>
        </div>
        );
    }
    return (
      <MultiNotesForm
        form={form}
        admin={admin}
        patient={patient}
        appointment={appointment}
        providerNote={providerNote}
        prescriptionInfo={prescriptionInfo}
        appointmentResponse={providerNote == 'AsyncVideoFollowUp' ? appointmentResponse : null}
        addNoteType={addNoteType}
        sourceAction={sourceAction}
        RXNTCallbackLink={RXNTCallbackLink}
        setNoteVisible={setNoteVisible}
        onRefresh={onRefresh}
        urgentRefillFlag={urgentRefillFlag}
        originName={originName}
      />
    );
  };
    return (
    <Drawer
      className={cx({ noteDrawer: true })}
      width={drawerWeight}
      title={noteHeaderDom()}
      placement="right"
      closable={false}
      onClose={onNoteClose}
      visible={noteVisible}
      destroyOnClose={true}
      loading={loadPatientInfoReq.loading}
    >
        {patient?.id ? noteContentDom() : <Spin tip="Loading..." spinning={loadPatientInfoReq.loading}><div style={{ width: '100px', height: '100px', margin: '100px auto' }} /></Spin> }
    </Drawer>
  );
};

AddNote.defaultProps = {
  admin: false,
};

export default AddNote;
