import React,{useMemo} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import cn from 'classnames/bind';
import moment from 'moment';
import 'moment-timezone';
import { useSelector } from 'umi';

import styles from './index.less';
const cx = cn.bind(styles);

const BigCalendar = (props) => {
  const { loadingCalendarAppointments, loadCalendarAppointments, calendarAppointments, onSelectEvent,providerTimezone,canUseTimes, calendarBlocks} = props;
  const { currentUser } = useSelector((state) => state.account);
  moment.tz.setDefault(providerTimezone);
  const { defaultDate, getNow, localizer, myEvents, scrollToTime } = useMemo(() => {
    moment.tz.setDefault(providerTimezone);
    const events = calendarAppointments.map((v) => {
      const start = moment.unix(v.appointed_at);
      let end = moment.unix(v.appointed_at);
      if (v.appointment_type === 2 || v.appointment_type === 7) {
        end = end.add('10', 'minutes');
      } else if (v.appointment_type === 5  || v.appointment_type === 20 || v.appointment_type === 21) {
        end = end.add('50', 'minutes');
      } else if (v.appointment_type === 6) {
        end = end.add('25', 'minutes');
      } else if (v.appointment_type === 11) {
        end = end.add('40', 'minutes');
      } else if (v.appointment_type === 15 || v.appointment_type === 17 || v.appointment_type === 22 || v.appointment_type === 23) {
        end = end.add('45', 'minutes');
      } else if (v.appointment_type === 16 || v.appointment_type === 18) {
        end = end.add('30', 'minutes');
      } else {
        end = end.add('25', 'minutes');
      }
      return {
        start: start.toDate(),
        end: end.toDate(),
        title: `${v.patient.first_name} ${v.patient.last_name} (${v.appointment_type_name})`,
        patient_id: v.user_id,
        patient_name: `${v.patient.first_name} ${v.patient.last_name}`,
        appointment_id: v.id,
        appointment_status: v.status,
        appointment_type_name: v.appointment_type_name,
        timezone: v.timezone,
        resource: v.id,
        doctor: v.doctor,
        doctor_location_id: v.doctor_location_id,
        doctor_id: v.doctor_id,
        appointment_type: v.appointment_type,
        id: v.id,
        admin_flag: v.admin_flag,
      };
    });
    const ruleData = canUseTimes.map((item) => {
      const start = moment.unix(item.start_date);
      const end = moment.unix(item.end_date);
      return {
        title: item.appointment_type_name,
        start: start.toDate(),
        end: end.toDate(),
        isCanUse: true,
      }
    })
    const blockData = calendarBlocks.map((item) => {
        const start = moment.unix(item.start_time);
        const end = moment.unix(item.end_time);
        return {
            id: item.id,
            title: "Blocked",
            start: start.toDate(),
            end: end.toDate(),
            isBlock: true,
        }
    })
    const allEvents = [...events, ...ruleData, ...blockData];
    return {
      defaultDate: moment().toDate(),
      getNow: () => moment().toDate(),
      localizer: momentLocalizer(moment),
      myEvents: allEvents,
      scrollToTime: moment().toDate(),
    };
  }, [providerTimezone, calendarAppointments,canUseTimes,calendarBlocks]);
  const calendarStyle = () => {
    return {
      style: {
      backgroundColor: '#D9D9D980',
      borderColor: '#d9d9d9',
      }
    }
  }
  const eventPropGetter=(event, start, end, isSelected) => {
      let newStyle = {
        backgroundColor: event.isCanUse ? 'white' : '#3174ad',
        color: event.isCanUse ? '#cccccc' : '#fff',
        zIndex: event.isCanUse ? 0 : 999,
        maxWidth: event.isCanUse ?'100%':'95%',
        borderRadius: event.isCanUse ?'0':'',
      };
      return {
        style: newStyle,
      };
  }

  const onSelectSlot = (arg) => {
  }

  const onNavigate = (arg) => {};

  const onView = (arg) => {};

  const onDrillDown = (arg) => {};

  const onRangeChange = (arg) => {
    var start, end;
    if (Array.isArray(arg)) {
      if (arg.length === 1) {
        start = moment(arg[0]).unix();
        end = moment(arg[0])
          .add(1, 'days')
          .unix();
      } else {
        start = moment(arg[0]).unix();
        end = moment(arg[arg.length - 1])
          .add(1, 'days')
          .unix();
      }
    } else {
       start = moment(arg.start).unix();
       end = moment(arg.end).unix();
    }

    loadCalendarAppointments({start, end})
  };

  const eventWrapper = ({ event, children }) => {
    if (event.isBlock) {
      return <div className="block" style={{ borderRadius: '0',zIndex: 0}}>{children}</div>;
    }
    if (event.isCanUse) {
      return <div className="can-use"  style={{ borderRadius: '0',zIndex: 0}}>{children}</div>
    }
    if (event.admin_flag) {
      return <div className="time-slot" style={{zIndex: 999 }}>{children}</div>
    }
    return <div style={{zIndex: 999 }}>{children}</div>;
  }
  return (
    <div className={cx({ subcontainer: true })} style={{height: 'calc(100vh - 210px)'}}>
      <Calendar
        localizer={localizer}
        events={myEvents}
        defaultDate={defaultDate}
        getNow={getNow}
        scrollToTime={scrollToTime}
        defaultView={(currentUser.doctor_id === currentUser.external_calendar_id && currentUser.enable_open_new_acuity) ? 'week' : 'month'}
        views={['month', 'day', 'week']}
        step={15}
        startAccessor="start"
        endAccessor="end"
        popup={false}
        popupOffset={{ x: 30, y: 20 }}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        onNavigate={onNavigate}
        onView={onView}
        onRangeChange={onRangeChange}
        eventPropGetter={eventPropGetter}
        dayPropGetter={calendarStyle}
        components={{
          eventWrapper: eventWrapper
        }}
        />
    </div>
  );
};
export default BigCalendar;
