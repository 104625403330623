import React, { useEffect, useState } from 'react';
import { connect, history, Link } from 'umi';
import { Col, Row, Modal, Button } from 'antd';
import PendingPrescriptionRenewal from './PendingPrescriptionRenewal';
import { AppointmentSection } from './AppointmentList';
import AllAssignedTaskSection from './AllAssignedTaskSection';
import PatientConsultation from './PatientConsultation';
import InitialPrescription from './InitialPrescription';
import MedicalFiles from './MedicalFiles';
import PatientReview from './PatientReview';
import './index.css';
import RemindProviderAcceptTransferPatientModal from '@/components/RemindProviderAcceptTransferPatientModal';
import RemindProviderPDMPCredentialsAuthorizeAdminAccessModal from '@/components/PDMPPane/RemindAuthorizeAdminAccessModal';
import HomeWarningMetrics from '@/components/Dashboard/HomeWarningMetrics';
import { useRequest } from 'ahooks';
import {
  srvGetDoctorDashboardMetric,
  srvCheckDoctorAcceptNewPatients,
  srvDoctorReinstatementLastUnread,
  srvUpdateDoctorWarningUnlockReinstatementRemind,
  srvDoctorRemind,
  srvDoctorRemindMark,
  srvSystemMigration,
  srvGetUrgentRefillPopFlag,
  srvGetSurveyDisputeResultNotification
} from '@/services/patient';
import {
  srcCheckNeedWeekly, srvCheckDoctorGoogleCalendar,
} from '@/services/account';
import DashboardUtil from '@/utils/dashboard.js';
import { useFlag } from '@unleash/proxy-client-react';
import AppointmentRequest from './AppointmentRequest';
import SyncToGoogleCalendarBanner from "@/components/GoogleCalendar/syncToGoogleCalendarBanner";

const PatientLink = (props) => {
  const { name, id, type } = props;
  let url = 'patient';
  if (type == 'prescription') url = 'ehr_renewal';
  return (
    <a type="text" href={`${url}/${id}?tab=1#`}>
      {name}
    </a>
  );
};

const ProviderDashboardSection = (props) => {
  const { sectionName, itemNumber, customerHeader, viewAllUrl } = props;
  const [isShow, setIsShowDetail] = useState(true);

  useEffect(() => {
    if (itemNumber > 0) {
      setIsShowDetail(true);
    }
  }, [itemNumber]);

  const onToggle = (e) => {
    setIsShowDetail((state) => !state);
  };

  let arrowStyle = 'flip-arrow';
  if (isShow) {
    arrowStyle = '';
  }
  return (
    <>
      <div className="section-container">
        <div className="section-header">
          <div className="section-header-title"> {sectionName}</div>
          {itemNumber >= 0 && <div className="section-header-count" style={{ marginRight: '15px', }}>{itemNumber}</div>}
          {viewAllUrl && <a type="primary" size="small" className="ant-btn ant-btn-primary ant-btn-sm" href={viewAllUrl}>View All</a>}
          {customerHeader && <div className="section-header-customer"> {customerHeader} </div>}
          <div className="section-header-collapse">
            <svg
              onClick={onToggle}
              className={arrowStyle}
              width="16"
              height="10"
              viewBox="0 0 16 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.58416C0 8.95967 0.140567 9.3198 0.390789 9.58537C0.641087 9.85086 0.980518 10 1.33444 10C1.68836 10 2.02779 9.85086 2.27809 9.58537L8.00806 3.50585L13.738 9.58537C13.9898 9.84333 14.3269 9.98607 14.6769 9.98285C15.0268 9.97962 15.3616 9.83069 15.6091 9.56812C15.8565 9.30556 15.9969 8.95037 15.9999 8.57906C16.003 8.20775 15.8685 7.85003 15.6253 7.58294L8.71357 0.702329C8.32341 0.313931 7.69271 0.313931 7.30255 0.702328L0.390789 7.58294C0.140567 7.84851 0 8.20864 0 8.58416Z"
                fill="#3F3F46"
              />
            </svg>
          </div>
        </div>
        {isShow && <div className="section-detail">{props.children}</div>}
      </div>
    </>
  );
};

const ProviderHome = (props) => {
  const { account, dispatch } = props;

  const isDone = window.location.href.includes('donefirst.com');
  
  const [showWarning, setShowWarning] = useState(false);
  const [dashboardMetricsWarningData, setDashboardMetricsWarningData] = useState(null);
  const [isYesterday, setIsYesterday] = useState(false);
  const [otherWarningData, setOtherWarningData] = useState(null);
  const [remind, setRemind] = useState(null);
  const [isAcceptNewPatientReminder, setIsAcceptNewPatientReminder] = useState(false);
  const [showUrgentRefillModel, setShowUrgentRefillModel] = useState(false);
  const [showSystemMigration, setShowSystemMigration] = useState(isDone && !account?.currentUser?.user_classification);
  const [showDisputeRejectedNotificationModel, setShowDisputeRejectedNotificationModel] = useState(false);
  const [showDisputeApprovedNotificationModel, setShowDisputeApprovedNotificationModel] = useState(false);
  const [disputeNotificationData, setDisputeNotificationData] = useState({});
  const [firstLoginTipsModel, setFirstLoginTipsModel] = useState(false)
  const [reinstatementResult, setReinstatementResult] = useState({
    show: false,
    consultation_id: 0,
    status: 0,
    reason: '',
    title: '',
    content: '',
  });
  const [showSyncToGoogleCalendarBanner, setShowSyncToGoogleCalendarBanner] = useState(false);

  const enableGoogleCalendar = useFlag('enable_google_calendar');
  const flagShowUrgentRefill = useFlag('enable_ehr_show_urgent_refill');

  const getOtherWarningData = () => {
    if (otherWarningData == null) {
      return null;
    }
    // currentIndex = last index
    let currentIndex = otherWarningData.length - 1;
    let data = otherWarningData[currentIndex];
    if (data && data.length > 0) {
      return data;
    }
  }

  const fetchDoctorMetric = useRequest(srvGetDoctorDashboardMetric, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data) {
          let warning = DashboardUtil.getOtherWarningList(ret.data.items);
          setOtherWarningData(warning);
        }
      }
    },
  });

  const updateDoctorWarningUnlockReinstatementRemind = useRequest(srvUpdateDoctorWarningUnlockReinstatementRemind, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        // message.success('Apply successfully');
      }
    }
  })

  const fetchDoctorSurveySurveyResultNotification = useRequest(srvGetSurveyDisputeResultNotification, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.data) {
        setDisputeNotificationData(ret.data)
        if (ret.data.status === 3) {
          setShowDisputeRejectedNotificationModel(true)
        } else {
          setShowDisputeApprovedNotificationModel(true)
        }
      }

    },
  });

  const fetchDoctorRemind = useRequest(srvDoctorRemind, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data) {
          setRemind(ret.data)
        }
      }
    },
  });

  const markDoctorRemind = useRequest(srvDoctorRemindMark, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) { }
    },
  });

  const goSystemMigration = useRequest(srvSystemMigration, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setShowSystemMigration(false);
        if (ret.data && ret.data.redirect_url) {
          window.location.href = ret.data.redirect_url;
        }
      }
    },
  });


  const ReinstatementStatus = {
    Default: 0,
    Approved: 1,
    Denied: 2,
  };

  const handleAcceptNewPatientCancel = () => {
    setIsAcceptNewPatientReminder(false);
    setReinstatementResult({
      show: false,
    });
  }

  const handleAcceptNewPatientOk = async () => {
    setIsAcceptNewPatientReminder(false);
    setReinstatementResult({
      show: false,
    });
    await updateDoctorWarningUnlockReinstatementRemind.runAsync();
  }

  const fetchCheckDoctorReinstatementLastUnread = useRequest(srvDoctorReinstatementLastUnread, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data) {
          if (ret.data.consultation_id && ret.data.consultation_id > 0) {
            var contentReason = '';
            if (ret.data.status === ReinstatementStatus.Approved) {
              contentReason = '<p>You are able to accept new patients now. Kindly keep an eye on your service quality and efficiency to prevent any future pauses in accepting new patients.</p>';
              if (ret.data.reason) {
                contentReason += '<p>Care team left you a note: ' + ret.data.reason + '</p>';
              }
              setReinstatementResult({
                show: true,
                consultation_id: ret.data.consultation_id,
                status: ret.data.status,
                reason: ret.data.reason,
                title: 'Your application has been approved.',
                content: contentReason,
              });
            } else if (ret.data.status === ReinstatementStatus.Denied) {
              contentReason = '<p>Kindly keep an eye on your service quality and efficiency. </p>';
              if (ret.data.reason) {
                contentReason += '<p>Care team left you a note: ' + ret.data.reason + '</p>';
              }
              setReinstatementResult({
                show: true,
                consultation_id: ret.data.consultation_id,
                status: ret.data.status,
                reason: ret.data.reason,
                title: 'Your application has been denied.',
                content: contentReason,
              });
            }
          }
        }
      }
    }
  })

  // const fetchDoctorWaringData = useRequest(srvGetDoctorDashboardMetricWarningDaysByLastTwoDays, {
  //   manual: true,
  //   onSuccess: (ret) => {
  //     if (ret.success) {
  //       if (ret.data != null && ret.data.show_warning) {
  //         setShowWarning(true);
  //         setDashboardMetricsWarningData(ret.data);
  //         setIsYesterday(ret.data.is_yesterday);
  //       }
  //     }
  //   }
  // })

  const fetchCheckDoctorAcceptNewPatients = useRequest(srvCheckDoctorAcceptNewPatients, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data && (ret.data.rating_star_is_warning ||
          (ret.data.ugrent_refill_is_warning && flagShowUrgentRefill) ||
          ret.data.provider_no_show_is_warning ||
          ret.data.rating_star_is_lock ||
          ret.data.ugrent_refill_is_lock ||
          ret.data.provider_no_show_is_lock)) {
          setDashboardMetricsWarningData(ret.data);
          setShowWarning(true);
        }
      }
    }
  })

  const checkIsBindGoogleCalendar = useRequest(srvCheckDoctorGoogleCalendar, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (!ret.data) {
          setShowSyncToGoogleCalendarBanner(true)
        }
      }
    }
  })

  useEffect(() => {
    processPatientStatsAndMetrics();
    fetchCheckDoctorReinstatementLastUnread.run({});
    srvOccupyReq.run();
    fetchDoctorRemind.run();
    srcCheckNeedWeeklyReq.run();
    fetchDoctorSurveySurveyResultNotification.run();
    if (enableGoogleCalendar) {
      checkIsBindGoogleCalendar.run();
    }
  }, []);
  const srvOccupyReq = useRequest(srvGetUrgentRefillPopFlag, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (ret.data) {
          setShowUrgentRefillModel(ret.data.result);
        }
      }
    },
  });
  const srcCheckNeedWeeklyReq = useRequest(srcCheckNeedWeekly, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (ret.data) {
          setFirstLoginTipsModel(ret.data.need_set_weekly);
        }
      }
    },
  });

  const processPatientStatsAndMetrics = async () => {
    await fetchCheckDoctorAcceptNewPatients.runAsync({});
    await fetchDoctorMetric.runAsync();
  }


  return (
    <div>
      <Row gutter={16} className="provider-home-container provider-home">
        <Col xl={17} xs={24} lg={24}>
          {showSyncToGoogleCalendarBanner && (<SyncToGoogleCalendarBanner />)}
        </Col>
        <Col span={24}>
          {(showWarning) && <HomeWarningMetrics isHome={true} dashboardMetricsWarningData={dashboardMetricsWarningData} isYesterday={isYesterday} viewAllUrl="/ehr_dashboard" />}
        </Col>
        <Col xl={17} xs={24} lg={24}>
          <AppointmentSection style={{ overflowX: 'auto' }} pendingNotes={0} currentUser={account.currentUser}></AppointmentSection>
          <AppointmentRequest style={{ overflowX: 'auto' }} ></AppointmentRequest>
          <AppointmentSection style={{ overflowX: 'auto' }} pendingNotes={1}></AppointmentSection>
          <PendingPrescriptionRenewal style={{ overflowX: 'auto' }}></PendingPrescriptionRenewal>
          <InitialPrescription style={{ overflowX: 'auto' }}></InitialPrescription>
          <MedicalFiles style={{ overflowX: 'auto' }}></MedicalFiles>
          <PatientConsultation style={{ overflowX: 'auto' }}></PatientConsultation>
          <AllAssignedTaskSection></AllAssignedTaskSection>
        </Col>
        <Col xs={24} xl={7} >
          <PatientReview style={{ overflowX: 'auto' }} />
        </Col>
      </Row>
      {account && account.currentUser && !account.currentUser.accept_transfer_patients && !account.currentUser.accept_transfer_patients_remind && account.currentUser.doctor_type != 3 && account.currentUser.doctor_type != 5 && (
        <RemindProviderAcceptTransferPatientModal onRefresh={(payload, callback) => {
          dispatch({
            type: 'account/fetchCurrent',
            payload: { ...payload },
            callback,
          });
        }} />
      )}
      {remind && !remind.pdmp_credentials_consent_remind_at && (
        <RemindProviderPDMPCredentialsAuthorizeAdminAccessModal
          onCancel={() => {
            markDoctorRemind.run({ key: 'PDMPCredentialsConsentSilent' })
          }}
          onOk={() => {
            markDoctorRemind.run({ key: 'PDMPCredentialsConsentRemind' })
          }}
        />
      )}
      {remind && !remind.appointment_request_remind_at && (
        <Modal
          visible={true}
          closeIcon={<></>}
          footer={[
            <Button key="ok" type="primary" onClick={() => {
              markDoctorRemind.run({ key: 'AppointmentRequestRemind' })
            }}>
              <Link to={`/ehr_extra_earn/appointment_request`} style={{ color: 'white' }}>
                extra earning
              </Link>
            </Button>,
          ]}
        >
          <p style={{ paddingTop: '20px' }}>
            New opportunity of extra earning! There are new patients awaiting for the initial appointments, please choose and accept the available time slots ASAP to increase your panel size. The new appointment requests are open to all providers.
          </p>
        </Modal>
      )}
      <Modal
        visible={showUrgentRefillModel}
        onCancel={() => {
          setShowUrgentRefillModel(false);
        }}
        onOk={() => {
          setShowUrgentRefillModel(false);
        }}
        footer={null}
      >
        <p
          style={{
            color: '#000000',
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '20px',
          }}
        >
          There are some urgent refill that needs to be addressed
        </p>
        <p style={{ marginBottom: '20px' }}>
          Do you want to help another provider with Prescription Renewal Requests ? You will
          receive a <span style={{ fontWeight: '900' }}>$5</span> reward for each processing completed!
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between', color: '#000000' }}>
          <p style={{ color: '#566BCD', cursor: 'pointer' }} onClick={() => {
            setShowUrgentRefillModel(false);
          }}>Not now</p>
          <Button key="confirm" onClick={() => { }} type="primary" style={{ width: '200px' }}>
            <Link to={`/ehr_extra_earn/urgent_refill`} style={{ color: 'white' }}>
              Have a look
            </Link>
          </Button>
        </div>
      </Modal>
      <Modal
        title={reinstatementResult.title}
        visible={reinstatementResult.show}
        onCancel={handleAcceptNewPatientCancel}
        maskClosable={false}
        footer={[
          <Button key="ok" type="primary" onClick={handleAcceptNewPatientOk}>
            OK
          </Button>,
        ]}
      >
        <p dangerouslySetInnerHTML={{ __html: reinstatementResult.content }}></p>
      </Modal>
      <Modal title="Your review dispute has been approved"
        visible={showDisputeApprovedNotificationModel}
        closable={true}
        onCancel={() => { setShowDisputeApprovedNotificationModel(false) }}
        footer={[

          <Button key="OK" type="primary" style={{ width: 200 }} onClick={() => {
            setShowDisputeApprovedNotificationModel(false)
          }}>
            OK
          </Button>
        ]}
      >
        <p>This review has been removed from your metrics.</p>
        <br />
        <p>Kindly keep an eye on your service quality and efficiency to prevent any negative reviews.</p>
      </Modal>
      <Modal title="Your review dispute was denied."
        visible={showDisputeRejectedNotificationModel}
        closable={true}
        onCancel={() => { setShowDisputeRejectedNotificationModel(false) }}
        footer={[
          <Button key="OK" type="primary" style={{ width: 200 }} onClick={() => {
            setShowDisputeRejectedNotificationModel(false)
          }}>
            OK
          </Button>
        ]}
      >
        <p>This is a valid patient review. Please correct the issue with the patient.</p>
        <br />
        <p>Kindly keep an eye on your service quality and efficiency to prevent any negative reviews.</p>
        <br />
        <p> <b>Admin Note:</b> {disputeNotificationData.admin_comment}</p>
      </Modal>
      <Modal
        visible={firstLoginTipsModel}
        onCancel={() => {
          setFirstLoginTipsModel(false);
        }}
        onOk={() => {
          setFirstLoginTipsModel(false);
        }}
        footer={null}
      >
        <p
          style={{
            color: '#000000',
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '20px',
          }}
        >
          Please set your regular hours
        </p>
        <p style={{ marginBottom: '20px' }}>
          You can now set your availability in calendar. Please set the regular hours first, and then you can select a specific date to change or override the hours for that date.
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between', color: '#000000' }}>
          <p></p>
          <Button key="confirm" onClick={() => {
            history.push(`/ehr_calendar/add_availability`);
          }} type="primary" style={{ width: '200px' }}>
            Set regular hours
          </Button>
        </div>
      </Modal>

      {showSystemMigration && (
        <Modal title="Important: EHR System Domain Migration"
          style={{ zIndex: 999999 }}
          open={showSystemMigration}
          closable={true}
          onCancel={() => { setShowSystemMigration(false) }}
          footer={[
            <Button key="OK" type="primary" style={{ width: 200 }} loading={goSystemMigration.loading} onClick={() => {
              goSystemMigration.run({ client_id: '77eae7aaebf39fd0c8bef84e58b37cfd' });
            }}>
              Go to Mindful EHR
            </Button>
          ]}
        >
          <p>Dear Provider,</p>
          <br />
          <p>We are excited to inform you that our EHR system will be migrating to a new domain: <a href='https://ehr.getmindfulhealth.com/' style={{ color: '#566BCD', textDecoration: 'underline'}}>https://ehr.getmindfulhealth.com/</a></p>
          <br />
          <h3 style={{ fontWeight: 'bold', marginBottom: '5px' }}>What this means for you:</h3>
          <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
            <li><b>Smoother User Experience:</b> The new domain will provide you with faster access speeds and a more stable system performance.</li>
            <li><b>Seamless Data Migration:</b> All your patient data, medical records, and other information will be seamlessly migrated to the new system.</li>
          </ul>
          <br />
          <h3 style={{ fontWeight: 'bold', marginBottom: '5px' }}>Important Notes:</h3>
          <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
            <li><b>Update Your Bookmarks:</b> Please be sure to click on the link <a href='https://ehr.getmindfulhealth.com/' style={{ color: '#566BCD', textDecoration: 'underline' }}>https://ehr.getmindfulhealth.com/</a>  to update your browser bookmarks to ensure you can access the system normally after the migration.</li>
            <li><b>Technical Support:</b> If you encounter any problems during the migration, please contact our technical support team at <a href='mailto:hr@getmindfulhealth.com' style={{ color: '#566BCD', textDecoration: 'underline' }}>hr@getmindfulhealth.com</a>.</li>
          </ul>
          <br />
          <p>Thank you for your understanding and cooperation.</p>
        </Modal>
      )}
    </div>
  );
};

export { ProviderDashboardSection, PatientLink };

// export default ProviderHome
export default connect(({ account, loading }) => ({
  account,
  loading,
}))(ProviderHome);