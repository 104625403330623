import React,{ useState} from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Modal,Button } from 'antd';
import cn from 'classnames/bind';
import styles from './index.less';
import { useSetState } from 'ahooks';
import moment from 'moment';
import { formatUnix } from '@/utils/xtime';
import { Tag} from 'antd';
const cx = cn.bind(styles);

const MessageItem = ({ data, selectedType, opened,role }) => {
  const [state, setState] = useSetState({
    opened: !!opened,
  });
  const v = data;
  const operateTypeMap = {
    1: 'opened',
    2: 'assigned',
    3: 'solved',
    4: 'closed',
  };

  function toggle() {
    setState({ opened: !state.opened });
  }

  const renderSendBy = (v) => {
    var sendBy = ' by ' + v.doctor_name + ', ' + v.doctor_title;
    if (v.sender_role === 2) {
      return ' Customer Success Team';
    } else {
      if(v.coverage_reply_flag){
        return sendBy+' (covering provider)';
      }
      return sendBy;
    }
  };
  const operationBy = (v) => {
    const operateType = operateTypeMap[v.operator_type] || '';
    return "ticket " + operateType+" by "+v.operator_name+ " at "+formatUnix(v.created_at);
  }

  const linkify = (_content) => {
    var content = _content.replace(/width:\s*\d+px/gm, "width: auto").replace(/min-width:\s*\d+px/gm, "min-width: auto").replace(/&nbsp;/gm, " ");
    if (selectedType !== 13) {
      return content;
    }
    return content.replace(
      /(?!<a[^>]*>[^<])(((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?))(?![^<]*<\/a>)/gi,
      ' <a style="color: #5469d4" href="$1">$1</a> ',
    );
  };

  const displayMessage = (v) => {
    if (v.content_type === 2) {
      if (v.consultation_file && v.consultation_file.file_type === 'application/pdf') {
        return (
          <p
            className={cx({
              messageItem: true,
              messageItemOpen: state.opened,
            })}
          >
            <a className="blue-link" href={v.consultation_file.url} target="_blank" rel="noreferrer">
              View {v.consultation_file.file_name}
            </a>
          </p>
        );
      } else {
        return <a href={v.consultation_file.url} target="_blank" rel="noopener noreferrer">
        <img className="img-fluid" alt="consultation file" src={v.consultation_file.url} />
      </a>;
      }
    }
    if (showCollapse) {
      return (
        <>
          <p
            className={cx({
              messageItem: true,
              messageItemOpen: state.opened,
            })}
            dangerouslySetInnerHTML={{
              __html: v.content && linkify(v.content).split('\n').join('<br/>'),
            }}
          />
          <div
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
            onClick={toggle}
          >
            <DownOutlined size={28} className={cx({ arrow: true, arrowActive: state.opened })} />
          </div>
        </>
      );
    }
    return (
      <p
        className={cx({
          messageItem: true,
          messageItemOpen: state.opened,
        })}
        dangerouslySetInnerHTML={{
          __html: v.content && linkify(v.content).split('\n').join('<br/>'),
        }}
      />
    );
  };

  const showCollapse = v?.content?.length > 100;
  if (v.operator_type) {
    return (
      <div className={cx({ statusMarker: true })}>
        <hr />
        <div className={cx({ status: true })}>
          <Tag color="#89929C" className={cx({ tag: true })}>{operationBy(v)}</Tag>
        </div>
        <hr />
      </div>
    )
  }
  return (
    <div className={cx({ messageRight: v.sender_role !== 1, messageLeft: v.sender_role === 1 })}>
      <div className={cx({ avatar: true })}>
        {v.sender_role === 2 && <img src="https://storage.googleapis.com/done_api_static/care_avatar.png" />}
        {(v.sender_role !== 1 && v.sender_role !== 2) && <img src={v.doctor_avatar_url} />}
        {v.sender_role === 1 && (
          <div className={cx({ avatar: true })}>
            <div />
          </div>
        )}
      </div>
      <div
        className={cx({
          bubble: true,
          internalNote: v.reply_type === 1,
        })}
      >
      {displayMessage(v)}
      </div>
      <span className={cx({ messageItemTime: true })}>
        {(role != 'admin'&&moment.unix(v.created_at).fromNow())}
        {(role === 'admin'&&formatUnix(v.created_at))}
        {v.sender_role !== 1 && renderSendBy(v)}
      </span>
    </div>
  );
};

export default MessageItem;
