import React, { useEffect, useState } from 'react';
import {Button, Popover, Tag, Modal, Radio, List, Space, Dropdown, Alert} from 'antd';
import { useSelector,history } from 'umi';
const InsufficientFollowUpAvailabilityModal = (props) => {
  const { currentUser } = useSelector((state) => state.account);
  const handleOk = () => {
    console.log('ok')
  }

  const clickHere = () => {
    console.log('click here')
  }

  return (
      <Modal title="Insufficient availability for follow up(sync)" visible={props.isModalOpen} onOk={handleOk} okText="Complete and inform care-team" cancelText={''} footer={null} onCancel={props.onCancel}>
      {currentUser.doctor_id != currentUser.external_calendar_id && (
        <p>
          You have limited availability for this month’s follow up(sync). To accommodate more patients, please <a href={props.schedulingUrl} rel="noreferrer" target="_blank" style={{ color: '#566BCD', fontWeight: 'bold' }}>click here</a> to fill in your additional available time and contact your PA to add more time slots.
        </p>
      )}
      {currentUser.doctor_id === currentUser.external_calendar_id && (
        <p>
          You have limited availability for this month’s follow up(sync). To accommodate more patients, please <a href='/ehr_calendar' rel="noreferrer" target="_self" style={{ color: '#566BCD', fontWeight: 'bold' }}>click here</a> to fill in your additional available time and contact your PA to add more time slots.
        </p>
      )}
      <p style={{ marginTop: '15px' }}>
        Please add at least <span style={{ color: '#E2445F' }}>{props.timeSlot.to_be_booked - props.timeSlot.remaining}</span> more time slots.
      </p>
      <div style={{ textAlign: 'right' }}>
        {currentUser.doctor_id === currentUser.external_calendar_id && (
          <Button type="primary" style={{ marginTop: '10px' }} onClick={() => {
            history.push('/ehr_calendar');
          }}><span>
              add more hours
            </span></Button>)}
        {currentUser.doctor_id != currentUser.external_calendar_id && (
          <Button type="primary" style={{ marginTop: '10px' }} onClick={props.completeUpdateTimeSlot}><span>
            Complete and inform care-team
          </span> </Button>)}
      </div>
      </Modal>
  )
}

export default InsufficientFollowUpAvailabilityModal;