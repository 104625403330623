import { Button, message, Modal, Space } from 'antd';
import { AppointmentSetHoursField } from '@/pages/ehr_calendar/components/index';
import { PlusCircleOutlined } from '@ant-design/icons';
import React, { forwardRef, useEffect, useImperativeHandle,useState } from 'react';
import { useSetState } from 'ahooks';
import moment from 'moment/moment';
import isEmpty from 'lodash/isEmpty';
import { srvEditTimePlanOneDay } from '@/services/admin';
import { srcCheckDayChange } from '@/services/account';
import { useSelector } from 'umi';
import { useRequest } from 'ahooks';
import { history, Link } from 'umi';

function EditHoursModal({ currentDay, data, onSave, apptTypes,calculate,currentUser }, ref) {
  const [state, setState] = useSetState({
    selectedDate: '',
    appointment: {},
    appointmentList: [],
    isModalOpen: false,
  });
  const [resultRule, setResultRule] = useState({});
  const [conflictAppts, setConflictAppts] = useState([]);
  const [rescheduleTipsVisible, setRescheduleTipsVisible] = useState(false);
  useEffect(() => {
    setState({
      appointmentList: isEmpty(data)? []: data.flatMap((item) => {
        const { appointment_type_id, dateRange } = item;
        const dateRanges = dateRange.split(';');

        return dateRanges.map((range) => ({
          appointment_type_id,
          date_range: range,
        }));
      }),
    });
  }, [data]);

  useImperativeHandle(ref, () => ({
    toggle,
  }));

  function toggle() {
    setState({
      isModalOpen: !state.isModalOpen,
    });
  }
  function onFieldChange(d, index) {
    const { appointmentList } = state;
    appointmentList[index] = d;
    setState({ appointmentList });
  }
  function onAdd() {
    setState({
      appointmentList: [
        ...state.appointmentList,
        {
          appointment_type_id: '',
          date_range: '',
        },
      ],
    });
  }

  function onDeleteItem(index) {
    const { appointmentList } = state;
    appointmentList.splice(index, 1);
    setState({ appointmentList });
  }
  function mergeAppointmentList(appointmentList) {
    const mergedList = [];
    const mergedMap = new Map();

    for (const item of appointmentList) {
      const { appointment_type_id, date_range } = item;

      if (mergedMap.has(appointment_type_id)) {
        const mergedItem = mergedMap.get(appointment_type_id);
        mergedItem.time_range += `;${date_range}`;
      } else {
        const newItem = {
          time_plan_appointment_type_id: appointment_type_id,
          time_range: date_range,
        };
        mergedMap.set(appointment_type_id, newItem);
        mergedList.push(newItem);
      }
    }
    return mergedList;
  }
  async function handleOk() {
    if(parseInt(currentDay)+86400<new Date().getTime()/1000){
      message.warning("The selected time slot is in the past. Please choose a future time slot.");
      return;
    }
    const isAllFilled = state.appointmentList.every(
      (item) => !isEmpty(item.appointment_type_id) && !isEmpty(item.date_range),
    );
    if (isAllFilled) {
      const params = {
        date: parseInt(currentDay),
        item: mergeAppointmentList(state.appointmentList),
        doctor_id: currentUser.doctor_id,
        just_check_booked: false,
      };
      setResultRule(params);
      checkChangeAvailablityReq.run(params);
    } else {
      message.warn('Please fill all fields');
    }
  }

  const checkChangeAvailablityReq = useRequest(srcCheckDayChange, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success && ret.data) {
        if (ret.data.has_not_contains_booked) {
          setConflictAppts(ret.data.items);
          setRescheduleTipsVisible(true);
        } else {
          srvEditTimePlanOneDay(resultRule)
            .then(() => {
              message.success('Saved successfully');
              onSave();
              toggle();
              calculate();
            })
            .catch((error) => {});
        }
      }
    },
  });

  return (
    <>
      <Modal
        title={`Hours for ${moment.unix(currentDay).tz(currentUser.timezone).format('MMM D, YYYY')}`}
        visible={state.isModalOpen}
        onOk={handleOk}
        onCancel={toggle}
        destroyOnClose
        width={900}
        zIndex={100}
      >
        Set Hours
        <div style={{display:'flex'}}>
          <div>
            {state.appointmentList.map((item, index) => (
              <AppointmentSetHoursField
                key={item.index}
                data={item}
                onChange={(d) => onFieldChange(d, index)}
                onDelete={() => onDeleteItem(index)}
                apptTypes={apptTypes}
              />
            ))}
          </div>
          <PlusCircleOutlined onClick={onAdd}  style={{ fontSize:'17px',marginLeft:'40px',marginTop:'16px'}}/>
        </div>
      </Modal>
      <Modal
        visible={rescheduleTipsVisible}
        onCancel={() => {
          setRescheduleTipsVisible(false);
        }}
        onOk={() => {
          setRescheduleTipsVisible(false);
        }}
        zIndex={1001}
        footer={null}
      >
        <p
          style={{
            color: '#000000',
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '20px',
          }}
        >
          Confirm the Changes
        </p>
        <p style={{ marginBottom: '20px' }}>
          The following scheduled appointments are not within your available times, but they will be
          maintained. If you are unable to participate, please reschedule.
          {conflictAppts.map((v, index) => {
            let sentence = '';
            sentence +=
              `${v.date}`.replace(/"/g, '') +
              `(` +
              `${v.appointment_type_name}` +
              `)` +
              ` with ` +
              `${v.patient_name} `;
              return <p>{sentence}</p>;
          })}
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between', color: '#000000' }}>
          <p
            style={{ color: '#566BCD', cursor: 'pointer' }}
            onClick={() => {
              setRescheduleTipsVisible(false);
              history.push(`/ehr_calendar`);
            }}
          >
            Reschedule the appointment
          </p>
          <Button key="confirm" onClick={() => {
            srvEditTimePlanOneDay(resultRule)
              .then(() => {
                setRescheduleTipsVisible(false);
                message.success('Saved successfully');
                onSave();
                toggle();
              })
              .catch((error) => { });
          }} type="primary" style={{ width: '200px' }}>
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default forwardRef(EditHoursModal);
