import React, { useState, useEffect } from 'react';
import { Form, Drawer, Input, Button, Select, Checkbox } from 'antd';

import debounce from 'lodash.debounce';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';

import {
  srvPatientTreatmentPlanHistoryDetail,
  srvEditPatientTreatmentPlanHistory,
} from '@/services/patient';

import cn from 'classnames/bind';
import styles from './ProviderNotes.less';
const cx = cn.bind(styles);

import CPTCodesFormItem from '@/components/CPTCodesFormItem';

const MultiNotesForm = (props) => {
  const { form, item, onClose } = props;

  const editHistoryReq = useRequest(srvEditPatientTreatmentPlanHistory, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        onClose();
      }
    },
  });

  const onAdd = async (values) => {
    try {
      if (values.cpt_code && values.cpt_code.length > 5) {
        values.cpt_code = values.cpt_code.slice(0, 5);
      }
      if (values.rx && Object.prototype.toString.call(values.rx) === '[object Array]') {
        values.rx = values.rx.map((v) => v.trim()).join(';');
      }
      let data = { ...values, id: item.id };
      await editHistoryReq.run(data);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <Form layout="vertical" form={form} initialValues={item} onFinish={onAdd}>
      <Form.Item
        name="diagnostic_code"
        label="Diagnostic Code"
        rules={[
          {
            required: true,
            message: 'Diagnostic Code is required',
          },
        ]}
      >
        <Input placeholder="Diagnostic Code" />
      </Form.Item>

      <CPTCodesFormItem form={form} noteType={(item.note_type === 3 || item.note_type === 8) ? 'Refill' : 'FollowUp'} initialValues={item} />

      <Form.Item
        name="rx"
        label="Rx"
        rules={[
          {
            required: false,
            message: 'Rx is required',
          },
        ]}
      >
        <Input placeholder="Rx" />
      </Form.Item>
      <Form.Item name="no_controlled_substance_needed" valuePropName="checked">
           <Checkbox>
            <span>&nbsp;Patient does not need controlled substances (in-person evaluation/referral not required).</span>
            </Checkbox>
      </Form.Item>
      <Form.Item
        name="provider_notes"
        label="Clinician Notes"
        rules={[
          {
            required: true,
            message: 'Clinician Notes is required',
          },
        ]}
      >
        <Input.TextArea wrap="on" rows={5} placeholder="Clinician Notes" />
      </Form.Item>
      <Form.Item name="message_to_patient" label="Message To Patient">
        <Input.TextArea rows={5} placeholder="Message To Patient" />
      </Form.Item>

      <div className={cx({ btnBox: true })}>
        <Button type="primary" htmlType="submit" loading={editHistoryReq.loading}>
          Save Changes
        </Button>
      </div>
    </Form>
  );
};

export default MultiNotesForm;
