import React, { useState } from 'react';
import { useMount } from 'ahooks';
import { useDebouncedCallback } from 'use-debounce';
import { Loader } from '@googlemaps/js-api-loader';
import { AutoComplete, Spin, Button, Select } from 'antd';

const autocompletionRequestBuilder = (autocompletionRequest, input, sessionToken) => {
  const { bounds, location, ...rest } = autocompletionRequest;

  const res = {
    input,
    ...rest,
  };

  if (sessionToken) {
    res.sessionToken = sessionToken;
  }

  if (sessionToken) {
    res.sessionToken = sessionToken;
  }

  if (bounds) {
    res.bounds = new google.maps.LatLngBounds(...bounds);
  }

  if (location) {
    res.location = new google.maps.LatLng(location);
  }

  return res;
};

const GeoSuggestAutoComplete = (props) => {
  const {
    value,
    apiOptions,
    apiKey,
    debounce,
    withSessionToken,
    minLengthAutocomplete,
    autocompletionRequest,
    disabled,
    placeholder,
  } = props;

  const [placesService, setPlacesService] = useState(undefined);
  const [sessionToken, setSessionToken] = useState(undefined);
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);

  const fetchSuggestions = useDebouncedCallback((value, cb) => {
    if (!placesService) return cb([]);
    if (value.length < minLengthAutocomplete) return cb([]);

    const autocompletionReq = { ...autocompletionRequest };

    placesService.getPlacePredictions(
      autocompletionRequestBuilder(autocompletionReq, value, withSessionToken && sessionToken),
      (suggestions) => {
        cb(
          (suggestions || []).map((suggestion) => ({
            label: suggestion.description,
            value: suggestion.description,
            data: suggestion,
          })),
        );
      },
    );
  }, debounce);

  const initializeService = () => {
    if (!window.google)
      throw new Error('[react-google-places-autocomplete]: Google script not loaded');
    if (!window.google.maps)
      throw new Error('[react-google-places-autocomplete]: Google maps script not loaded');
    if (!window.google.maps.places)
      throw new Error('[react-google-places-autocomplete]: Google maps places script not loaded');

    setPlacesService(new window.google.maps.places.AutocompleteService());
    setSessionToken(new google.maps.places.AutocompleteSessionToken());
  };
  useMount(async () => {
    try {
      if (!window.google || !window.google.maps || !window.google.maps.places) {
        await new Loader({ apiKey, ...{ libraries: ['places'], ...apiOptions } }).load();
      }
      initializeService();
    } catch (e) {
      console.log(e);
    }
  });

  const fetchLocation = async (val) => {
    setData([]);
    setFetching(true);
    fetchSuggestions(val, (suggestions) => {
      setData(suggestions);
      setFetching(false);
    });
  };

  const geocodeByAddress = (address) => {
    const OK = window.google.maps.GeocoderStatus.OK;
    const geocoder = new window.google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      geocoder.geocode({ address }, (results, status) => {
        if (status !== OK) {
          reject(status);
        }
        resolve(results);
      });
    });
  };
  const handleSelect = async (addr) => {
    const [ret] = await geocodeByAddress(addr);
    const lat = ret.geometry.location.lat();
    const lng = ret.geometry.location.lng();
    let cityObj = ret.address_components.find((comp) => comp.types.includes('locality'));
    let stateObj = ret.address_components.find((comp) =>
      comp.types.includes('administrative_area_level_1'),
    );
    let countryObj = ret.address_components.find((comp) => comp.types.includes('country'));
    let townObj = ret.address_components.find((comp) => comp.types.includes('postal_town'));
    let zipObj = ret.address_components.find((comp) => comp.types.includes('postal_code'));
    const fullAddress = ret.formatted_address;
    const city = cityObj && cityObj.long_name;
    const state = stateObj && stateObj.long_name;
    const stateShort = (stateObj && stateObj.short_name) || state;
    const country = countryObj && countryObj.long_name;
    const countryShort = (countryObj && countryObj.short_name) || country;
    const town = (townObj && townObj.long_name) || '';
    const zip = (zipObj && zipObj.long_name) || '';

    let streetObj = ret.address_components.find((comp) => comp.types.includes('street_number'));
    let routeObj = ret.address_components.find((comp) => comp.types.includes('route'));
    const street = (streetObj && streetObj.long_name) || '';
    const route = (routeObj && routeObj.long_name) || '';
    const addressLine1 = `${street} ${route}`;

    props.onAddressSelect({
      zip,
      lat,
      lng,
      fullAddress,
      city,
      town,
      state,
      stateShort,
      country,
      countryShort,
      shortName: `${city || town}, ${stateShort || state || ''}${
        countryShort !== 'US' ? ' ' + countryShort : ''
      }`,
    });
    props.onChange(addressLine1);
  };
  return (
    <div>
      <AutoComplete
        backfill={true}
        showSearch
        value={value}
        options={data}
        onSearch={fetchLocation}
        notFoundContent="no results"
        onSelect={(v) => {
          handleSelect(v);
        }}
        onChange={props.onChange}
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  );
};

GeoSuggestAutoComplete.defaultProps = {
  value: '',
  disabled: false,
  placeholder: 'Address',
  apiOptions: {},
  apiKey: 'AIzaSyALFmDoVNb5DrQbvRkAUghtQw1dvqZgg6Q',
  debounce: 300,
  withSessionToken: false,
  minLengthAutocomplete: 0,
  autocompletionRequest: {
    componentRestrictions: {
      country: 'us',
    },
    types: ['address'],
  },
  onChange: (val) => {},
  onAddressSelect: (val) => {},
};

export default GeoSuggestAutoComplete;
