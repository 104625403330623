import React, { useState, useEffect, useMemo } from 'react';
import './ProviderDashboardMetricWarningLock.css';
import { useFlag } from '@unleash/proxy-client-react';

const ProviderDashboardMetricWarningLock = (props) => {
  const { dashboardMetricsWarningData } = props;
  const [warningContent, setWarningContent] = useState('');

  const flagShowUrgentRefill = useFlag('enable_ehr_show_urgent_refill');

  useEffect(() => {
    if (dashboardMetricsWarningData) {
      setWarningContentContent(dashboardMetricsWarningData);
    }
  }, [dashboardMetricsWarningData]);

  const setWarningContentContent = (dashboardMetricsWarningData) => {
    var warningContent = '';

    if (dashboardMetricsWarningData) {
      if (dashboardMetricsWarningData.is_warning_lock_metrics) {
        warningContent += '<p>Due to performance not meeting the criteria, you have been paused to see new patients.</p>';
        warningContent += '<ul style="list-style-type: disc;margin-left: 30px;">';
        if (dashboardMetricsWarningData.provider_no_show_is_lock) {
          warningContent += '<li>In light of multiple provider no-shows recently, you have been paused to see new patients for the next 7 days.<br/>If the alert is in error, please apply for reinstatement and provide an explanation.</li>';
        }
        if (dashboardMetricsWarningData.ugrent_refill_is_lock) {
          warningContent += '<li>In light of unsolved urgent refills, accepting new patients is temporarily paused.<br/>For patients needing follow-up, contact your PA for scheduling. If slots are unavailable, opt for a phone call or a temporary prescription. Ensure to submit follow-up notes when prescribing for these patients.<br/>Please prioritize addressing them within 3 days and apply for reinstatement to lift ongoing restrictions.</li>';
        }
        if (dashboardMetricsWarningData.rating_star_is_lock) {
          warningContent += '<li>Your average patient ratings for the past two days fell below 3. New patient appointments have been paused until your daily average reaches 3 again.<br/>Please consult your Lead NP for guidance on improving your patient experience.<br/>For any questions or concerns, please apply for reinstatement.</li>';
        }
        warningContent += '</ul>';
      } else if (dashboardMetricsWarningData.is_warning_metrics) {
        warningContent += '<p>Please note that you don’t meet the criteria for certain metrics. Kindly improve your service quality and efficiency.</p>';
        warningContent += '<ul style="list-style-type: disc;margin-left: 30px;">';
        if (dashboardMetricsWarningData.provider_no_show_is_warning) {
          warningContent += '<li>Provider no-shows have occurred. Another no-show within 30 days may lead to a one-week pause on new appointments.<br/>Please reschedule your appointments in EHR if you’re unable to make them. (<a href="https://drive.google.com/file/d/1IRhrUo2YL5_f62tKSp9RYg5M-5JwQCrK/view?usp=sharing" target="_blank" style="color: #5469d4">Tutorial</a>)</li>';
        }
        if (dashboardMetricsWarningData.ugrent_refill_is_warning && flagShowUrgentRefill) {
          let text = 'new patient appointments could potentially be paused';
          if (dashboardMetricsWarningData.enabled_urgent_refill_metric_lock) {
            text = 'new patient appointments will be paused';
          }
          warningContent += `<li>You have unsolved urgent refills. <br/>If this persists for two consecutive days, ${text}.<br/>For patients needing follow-up, contact your PA for scheduling. If slots are unavailable, opt for a phone call or a temporary prescription. Ensure to submit follow-up notes when prescribing for these patients.</li>`;
        }
        if (dashboardMetricsWarningData.rating_star_is_warning) {
          warningContent += '<li>Your average patient rating from yesterday fell below 3.<br/>If this persists for two consecutive days, new patient appointments will be temporarily paused.<br/>Please consult your Lead NP for guidance on improving your patient experience.</li>';
        }
        warningContent += '</ul>';
      }

    }

    setWarningContent(warningContent)
  }

  return (
    <div className="section-container-metrics-no-border">
      {warningContent && <div className="warning-box">
        <p dangerouslySetInnerHTML={{ __html: warningContent }}></p>
      </div>}
    </div>
  );
};

export default ProviderDashboardMetricWarningLock;