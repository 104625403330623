import { create } from 'zustand';
import { srvPatientDetail } from '@/services/patient';

export const usePatientStore = create((set) => ({
  patient: {},
  setPatient: async (id) => {
    console.log('🚀 ~ setPatient: ~ id:', id);
    const res = await srvPatientDetail({ id });
    console.log('🚀 ~ setPatient: ~ res:', res);
    set({ patient: res.data });
  },
}));
