import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link,useAccess } from 'umi';
import {Modal, Dropdown, Space, Popover, Input, Table, Row, Col, Button, Tag} from 'antd';
import moment from 'moment';
import {
  ExclamationCircleOutlined,
  EllipsisOutlined,
  SearchOutlined,
  createFromIconfontCN,
} from '@ant-design/icons';
import { AsYouType } from 'libphonenumber-js';
import { useEventEmitter } from 'ahooks';
import { timeZones } from '@/utils/xtime';

import Filters from '@/components/Filters';
import defaultSettings from '../../../defaultSettings';
import PasswordModal from './PasswordModal';

import styles from './index.less';
import {
  srvEsPatients
} from '@/services/admin';
const cx = cn.bind(styles);

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const filters = [
  {
    name: 'name',
    label: 'Name',
    type: 'async_select',
    fetchOptions: async (value) => {
      const filterContent = {};
      filterContent.name = value;
      filterContent.role='doctor';
      const response = await srvEsPatients({
        filters: JSON.stringify(filterContent),
        page: 1,
        page_size: 10,
      });
      if (response.success) {
        return response.data.items.map(item => ({
          label: item.name,
          value: item.name,
        }));
      }
      return [];
    },
  },
  {
    name: 'email',
    label: 'Email',
    type: 'async_select',
    fetchOptions: async (value) => {
      const filterContent = {};
      filterContent.email = value;
      filterContent.role='doctor';
      const response = await srvEsPatients({
        filters: JSON.stringify(filterContent),
        page: 1,
        page_size: 10,
      });
      if (response.success) {
        return response.data.items.map(item => ({
          label: item.email,
          value: item.email,
        }));
      }
      return [];
    },
  },
  {
    name: 'support_state',
    label: 'State Supported',
    type: 'select',
    items: [],
  },
  {
    name: 'phone',
    label: 'Phone',
    type: 'async_select',
    fetchOptions: async (value) => {
      const filterContent = {};
      filterContent.phone = value;
      filterContent.role='doctor';
      const response = await srvEsPatients({
        filters: JSON.stringify(filterContent),
        page: 1,
        page_size: 10,
      });
      if (response.success) {
        return response.data.items.map(item => ({
          label: item.phone,
          value: item.phone,
        }));
      }
      return [];
    },
  },
  {
    name: 'status',
    label: 'Status',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'doctor_tags',
    label: 'Doctor Tags',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'patient_count',
    label: 'Patient Count',
    type: 'number',
  },
];

const Doctor = (props) => {
  const access=useAccess();
  const { dispatch, doctor, loading } = props;
  const {data, states, doctor_statuses, doctor_tags} = doctor;
  const [query, setQuery] = useState({});
  const [filterState, setFilterState] = useState({});
  const [page, setPage] = useState(1);
  const [item, setItem] = useState({});
  const [modelVisible, setModalVisible] = useState(false);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);

  const reset$ = useEventEmitter();

  const columns = [
    {
      title: 'Type',
      dataIndex: 'doctor_type',
      key: 'doctor_type',
      width: 100,
      render: (v, r) => {
        if (r.doctor_type == 0) {
          return <span>Provider</span>;
        } else if (r.doctor_type == 1) {
          return <span>Collab</span>;
        } else if (r.doctor_type == 2) {
          return <span>Student</span>;
        } else if (r.doctor_type == 3) {
        return <span>Therapist</span>;
      }  else if (r.doctor_type == 5) {
        return <span>Coach</span>;
      }
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      render: (v, r) => {
        return (
          <span >
          <Link to={`/doctor/detail/${r.id}`}>
            {r.name} {r.title}
          </Link>
            &nbsp;
            {
              r.doctor_tag_names?.map(tagName => {
                 return (<Tag color={"geekblue"}>{tagName}</Tag>)
              })
            }
          </span>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      width: 180,
    },
    {
      title: 'Support States',
      dataIndex: 'support_states',
      key: 'support_states',
      width: 100,
      ellipsis: true,
    },
    {
      title: 'PEDIATRIC',
      dataIndex: 'support_pediatric',
      key: 'support_pediatric',
      width: 50,
      ellipsis: true,
      render: (v, r) => {
        if (r.support_pediatric) {
          return 'Y';
        } else {
          return 'N';
        }
      },
    },
    {
      title: 'INSOMNIA',
      dataIndex: 'support_insomnia',
      key: 'support_insomnia',
      ellipsis: true,
      width: 50,
      render: (v, r) => {
        if (r.support_insomnia) {
          return 'Y';
        } else {
          return 'N';
        }
      },
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
      width: 120,
      render: (v, r) => {
        return new AsYouType('US').input(r.phone);
      },
    },
    {
      title: 'Active Patients',
      dataIndex: 'patients_count',
      key: 'patients_count',
      ellipsis: true,
      width: 70,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 70,
      render: (v, r) => {
        return r.status_name;
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      fixed: 'right',
      ellipsis: true,
      width: 50,
      render: (v, r) => {
        if (access.adminAccesser) {
          return
        }
        return (
          <Dropdown
            overlay={
              <div className={cx({ doctorOpt: true })}>
                <div className={cx({ doctorOptItem: true })}>
                  <a href="#" onClick={(ev) => goEdit(r, ev)}>
                    Edit
                  </a>
                </div>
                <div className={cx({ doctorOptItem: true })} onClick={() => showPasswordModal(r)}>
                  <a href="#">SetPassword</a>
                </div>
                {r.status == 1 && (
                  <div className={cx({ doctorOptItem: true })} onClick={() => confirmDeactivate(r)}>
                    <a href="#">Deactivate</a>
                  </div>
                )}
                {r.status == 2 && (
                  <div className={cx({ doctorOptItem: true })} onClick={() => confirmActivate(r)}>
                    <a href="#">Activate</a>
                  </div>
                )}
              </div>
            }
            trigger={['click']}
          >
            <Button size="small">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    const query = (history.location.state && history.location.state.filterState) || {};
    setFilterState(query);
    setQuery(query);

    setPage(1);
    loadList({ page: 1, page_size: 20, filters: JSON.stringify(query) }, () => {});
    dispatch({ type: 'doctor/fetchStates', payload: {} });
    dispatch({ type: 'doctor/fetchDoctorStatuses' });
    dispatch({ type: 'doctor/fetchDoctorTags', payload: { page: 1, page_size: 100, filters: JSON.stringify({tag_type:"doctor"})} });
  }, []);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => {});
  };

  const loadList = (payload, callback) => {
    dispatch({ type: 'doctor/fetchList', payload, callback });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadList({
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: JSON.stringify(query),
    });
  };
  const f = () => {
    for (const v of filters) {
      if (v.name === 'status' && v.items.length === 0) {
        v.items = doctor_statuses;
      } else if (v.name === 'support_state' && v.items.length === 0) {
        v.items = states.map((s) => ({ ...s, label: s.state, value: s.state }));
      }else if (v.name === 'doctor_tags' && v.items.length === 0) {
        v.items = doctor_tags.map((s) => ({ ...s, label: s.tag_name, value: s.id }));
      }
    }
    return filters;
  };

  const onAdd = () => {
    history.push(`/doctor/add`);
  };

  const goEdit = (r, ev) => {
    ev.preventDefault();
    history.push(`/doctor/edit/${r.id}`, { filterState: query });
  };

  const showPasswordModal = (r) => {
    let data = { ...r };
    data.support_states = r.support_states.split(',');
    setItem(data);
    setPasswordModalVisible(true);
  };

  let passwordModalProps = {
    visible: passwordModalVisible,
    item: { ...item },
    states: states,
    destroyOnClose: true,
    maskClosable: false,
    closable: false,
    okText: 'Confirm',
    width: 600,
    title: 'Set Password',
    onOk: (payload) => {
      dispatch({
        type: 'doctor/setPassword',
        payload: { ...payload, id: item.id },
        callback: () => {
          setItem({});
          setPasswordModalVisible(false);
          reset$.emit();
        },
      });
    },
    onCancel: () => {
      setItem({});
      setPasswordModalVisible(false);
    },
  };

  const confirmDeactivate = (r) => {
    Modal.confirm({
      title: 'Do you Want to deactivate these doctor?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch({
          type: 'doctor/deactiveDoctor',
          payload: { id: r.id },
          callback: () => {
            reset$.emit();
          },
        });
      },
      onCancel() {},
    });
  };

  const confirmActivate = (r) => {
    Modal.confirm({
      title: 'Do you Want to activate these doctor?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch({
          type: 'doctor/activeDoctor',
          payload: { id: r.id },
          callback: () => {
            reset$.emit();
          },
        });
      },
      onCancel() {},
    });
  };

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Clinicians</h3>
        <Space>
          {(!access.adminAccesser) && (
              <Button type="primary" onClick={onAdd}>
                Add
              </Button>
            )}

          <Filters reset$={reset$} filters={f()} initialFilters={filterState} onFilter={onFilter} />
        </Space>
      </div>

      <Table
        className="rowSelectTable"
        columns={columns}
        scroll={{ x: 800 }}
        dataSource={data.items}
        rowClassName={(r) => {
          if (r.id == item.id) {
            return 'rowSelected';
          }
          return '';
        }}
        onRow={(record) => ({
          onClick: () => {
            // history.push(`/doctor/${record.id}`);
          },
        })}
        rowKey="id"
        loading={loading.effects['doctor/fetchList']}
        onChange={handleTableChange}
        pagination={{ pageSize: 20, current: page, simple: true, total: data.total }}
      />
      {passwordModalVisible && <PasswordModal {...passwordModalProps} />}
    </div>
  );
};

export default connect(({ doctor, loading }) => ({
  doctor,
  loading,
}))(Doctor);
