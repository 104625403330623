import React, { useState, useRef } from 'react';
import { Button, Spin, Drawer, Slider, Input } from 'antd';
import cn from 'classnames/bind';
import numeral from 'numeral';
import { AsYouType } from 'libphonenumber-js';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import zippo from 'zippo';

import { srvPharmacyList, srvRXNTPharmacyList } from '@/services/admin';
import styles from './index.less';
const cx = cn.bind(styles);

const marks = {
  5: '5 mi',
  10: '10 mi',
  25: '25 mi',
  50: '50 mi',
};

const maxItems = '1000';

const PharmacyDrawer = (props) => {
  const { visible } = props;
  const onDrawerClose = () => {
    props.onClose && props.onClose();
  };
  const [data, setData] = useState({
    totalPharmacies: 0,
    pharmacies: [],
  });
  const zipcodeRef = useRef(null);
  const filterRef = useRef(null)
  const distanceRef = useRef(null);
  const nameRef = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const addressRef = useRef(null);
  const phoneRef = useRef(null);
  const faxRef = useRef(null);
  const [zipcode, setZipcode] = useState(props.zipcode);
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [fax, setFax] = useState('');
  const [filter, setFilter] = useState('');
  const [pharmacy, setPharmacy] = useState({});
  const [distance, setDistance] = useState(5);

  const listReq = useRequest(srvRXNTPharmacyList, {
    debounceWait: 500,
    manual: true,
    onSuccess: (ret, params) => {
      setData(ret.data);
    },
  });

  const onFinish = () => {
    if (pharmacy.id) {
      props.onFinish && props.onFinish(pharmacy.ncpdp + '', pharmacy);
    }
  };

  const loadData = () => {
    const cond = {
      zip: zipcode,
      name: name,
      city: city,
      state: state,
      address: address,
      // radius: distance,
      phone: phone,
      fax: fax,
      maxItems,
      admin: props.admin,
    };
    let keys = [];
    Object.keys(cond).forEach(key => {
      if (cond[key]) {
        keys.push(key);
      } else {
        delete cond[key];
      }
    })
    if (keys.some(k => ['zip', 'name', 'city', 'state', 'address', 'phone', 'fax'].includes(k))) {
      listReq.run(cond);
    }
  }

  useMount(() => {
    if (zipcode) {
      loadData();
    }
  });
  
  useUpdateEffect(() => {
    loadData();
  }, [name, city, state, address, phone, fax])

  let pharmacies =  data.pharmacies;

  if (filter.trim().length >0) {
    pharmacies= pharmacies.filter(v=>{
      return v.city.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        v.address1.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        v.address2.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        v.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        v.ncpdp.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    })
  }


  const listItemsDom = pharmacies.map((v) => {
    const val = numeral(v.distance).format('0.00');
    return (
      <div
        key={v.id}
        className={cx({ item: true, rowSelected: pharmacy.id && pharmacy.id == v.id })}
        onClick={() => {
          setPharmacy(v);
        }}
      >
        <div className={cx({ itemLeft: true })}>
          <div className={cx({ itemTitle: true })}>{v.name}</div>
          <div className={cx({ itemEntity: true })}>
            <span>ID: </span>
            {v.ncpdp}
          </div>
          <div className={cx({ itemEntity: true })}>{v.address1}</div>
          <div className={cx({ itemEntity: true })}>
            {v.city}, {v.state}, {v.zipcode}
          </div>
        </div>
        <div className={cx({ itemRight: true })}>
          <div className={cx({ itemEntity: true, isHide: true })}>
            <span>Distance:</span>
            {val} mi
          </div>
          <div className={cx({ itemEntity: true })}>{new AsYouType('US').input(v.phone)}</div>
          <div className={cx({ itemEntity: true })}>{new AsYouType('US').input(v.fax)}</div>
        </div>
      </div>
    );
  });

  return (
    <Drawer
      className={cx({ pharmacyDrawer: true })}
      title={
        <div className={cx({ title: true })}>
          <h3>Pharmacies</h3>
          {pharmacy && pharmacy.id > 0 && (
            <Button type="primary" loading={listReq.loading} onClick={onFinish}>
              Confirm
            </Button>
          )}
        </div>
      }
      width="600"
      placement="right"
      closable={false}
      onClose={onDrawerClose}
      visible={visible}
      destroyOnClose={true}
    >
      <Spin tip="Loading..." spinning={listReq.loading}>
        <div className={cx({ search: true })}>
          <div className={cx({ fieldsGroupFull: true })}>
            <div className={cx({ fieldsTitle: true })}>Name:</div>
            <Input
              ref={nameRef}
              placerhoder="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={cx({ search: true })}>
          <div className={cx({ fieldsGroupFull: true })}>
            <div className={cx({ fieldsTitle: true })}>Zip code:</div>
            <Input
              ref={zipcodeRef}
              placerhoder="Zipcode"
              value={zipcode}
              onBlur={() => {
                loadData();
              }}
              onChange={(e) => {
                setZipcode(e.target.value);
                if (zippo.validate(e.target.value)) {
                  loadData();
                }
              }}
            />
          </div>

          <div className={cx({ fieldsGroup: true, isHide: true })}>
            <div className={cx({ fieldsTitle: true, })}>Search within...</div>
            <Slider
              min={5}
              max={50}
              step={null}
              value={distance}
              marks={marks}
              defaultValue={5}
              onChange={(val) => {
                setDistance(val);
                if (zippo.validate(zipcode)) {
                  listReq.run({
                    zip: zipcode,
                    radius: val,
                    address,
                    maxItems,
                    admin: props.admin,
                  });
                }
              }}
            />
          </div>
        </div>

        <div className={cx({ search: true })}>
          <div className={cx({ fieldsGroup: true })}>
            <div className={cx({ fieldsTitle: true })}>City:</div>
            <Input
              ref={cityRef}
              placerhoder="City"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
          </div>

          <div className={cx({ fieldsGroup: true, })}>
            <div className={cx({ fieldsTitle: true, })}>State:</div>
            <Input
              ref={stateRef}
              placerhoder="State"
              value={state}
              onChange={(e) => {
                setState(e.target.value);
              }}
            />
          </div>
        </div>

        <div className={cx({ search: true })}>
          <div className={cx({ fieldsGroupFull: true })}>
            <div className={cx({ fieldsTitle: true })}>Address:</div>
            <Input
              ref={addressRef}
              placerhoder="Address"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
          </div>
        </div>

        <div className={cx({ search: true })}>
          <div className={cx({ fieldsGroup: true })}>
            <div className={cx({ fieldsTitle: true })}>Phone:</div>
            <Input
              ref={phoneRef}
              placerhoder="Phone"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </div>

          <div className={cx({ fieldsGroup: true, })}>
            <div className={cx({ fieldsTitle: true, })}>Fax:</div>
            <Input
              ref={faxRef}
              placerhoder="Fax"
              value={fax}
              onChange={(e) => {
                  etFax(e.target.value);
              }}
            />
          </div>
        </div>

        <div className={cx({ search: true })}>
          <div className={cx({ fieldsGroupFull: true })}>
            <div className={cx({ fieldsTitle: true })}>Search by pharmacy, city, or street address</div>
            <Input
              ref={filterRef}
              placerhoder="Pharmacy, city, or street address"
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={cx({ itemContainer: true })}>{listItemsDom}</div>
      </Spin>
    </Drawer>
  );
};
PharmacyDrawer.defaultProps = {
  zipcode: '',
};
export default PharmacyDrawer;
