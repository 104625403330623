import {
  srvStates,
  srvAdmins,
  srvAddAdmin,
  srvUpdateAdmin,
  srvActivateAdmin,
  srvDeactivateAdmin, srvTags, srvAddTags, srvDelTags,
} from '@/services/admin';

const Model = {
  namespace: 'tags',
  state: {
    data: {
      items: [],
      total: 0,
    },
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const res = yield call(srvTags, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveList', payload: res.data });
    },
    *addTags({ payload, callback }, { call, put, select }) {
      const res = yield call(srvAddTags, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *updateTags({ payload, callback }, { call, put, select }) {
      const res = yield call(srvUpdateTags, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *deleteTags({ payload, callback }, { call, put, select }) {
      const res = yield call(srvDelTags, payload);
      if (!res.success) {
        return;
      }

      if (callback) {
        callback();
      }
    },
  },
  reducers: {
    saveList(state, { payload }) {
      return { ...state, data: payload };
    },
  },
};

export default Model;
