import React, { useState, useEffect } from 'react';
import { Form, Input, InputNumber, Space, Row, Col, Divider, DatePicker } from 'antd';

import { useMount, useUpdateEffect, useSetState } from 'ahooks';
import ExclusiveCheckboxWithTextInput from './ExclusiveCheckboxWithTextInput';
import { medicalHistoryItems } from './meta';
import RadioGroupWithTextInput from './RadioGroupWithTextInput';

const { TextArea } = Input;

const MedicalHistoryFormItems = (props) => {
  const { form, draft, setDraft, } = props;

  // const [radioGroupValue, setRadioGroupValue] = useState(null);

  // useUpdateEffect(() => {
  //   if (draft?.medical_weight_change_6m != "") {
  //     setRadioGroupValue(draft?.medical_weight_change_6m === 'No' ? 'No' : 'Yes')
  //   }
  // }, [draft]);

  const onItemChange = (e, name, exclusive, items = []) => {
    if (e.target.checked) {
      let fields = {}
      items.forEach(v => {
        if (exclusive) {
          fields[v.value] = v.exclusive ? true : false;
          if (v.hasInput) fields[`${v.value}_text`] = '';
        } else {
          if (v.value == name) fields[v.value] = true;
          if (v.exclusive) fields[v.value] = false;
        }
      })
      if (Object.keys(fields).length > 0) {
        form.setFieldsValue(fields);
        setDraft(Object.assign({ ...draft }, fields));
      }
    }
  }

  return (
    <>
      <Form.Item
        name="medical_conditions"
        label={'Medical conditions'}
      >
        <TextArea rows={3} maxLength={1000} />
      </Form.Item>

      <h4>Hx of cardiac, arrhythmias, hypersomnolence, HTN, sleep apnea, seizure,cva/stroke,thrombotic events</h4>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px'}} >
        {medicalHistoryItems.map((v, index) => {
          return (
            <div key={index} style={{ flex: '0 1 auto', padding: '2px 20px 2px 0px' }}>
              <ExclusiveCheckboxWithTextInput name={v.value} label={v.label} exclusive={v.exclusive} hasInput={v.hasInput}
                onChange={(e) => onItemChange(e, v.value, v.exclusive, medicalHistoryItems)} form={form} draft={draft} setDraft={setDraft} />
            </div>
          )
        })}
      </div>

      <Row>
        <Col span={10}>
          <Form.Item name={'medical_current_pcp'} label={'Current PCP'}>
            <Input maxLength={200} />
          </Form.Item>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item
            name={'medical_last_seen_date'}
            label={'Date last seen'}
          >
            <DatePicker format="MM/DD/YYYY" showTime={false} showToday={true} size="default" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="current_medical_medications"
        label={'Current Medical Medications'}
      >
        <TextArea rows={3} maxLength={1000} />
      </Form.Item>

      <Form.Item
        name="medical_vitamins_supplements"
        label={'Vitamins/supplements'}
      >
        <TextArea rows={3} maxLength={1000} />
      </Form.Item>

      <Row>
        <Col span={10}>
          <Form.Item name="patient_height" label="Height (inches)">
            <InputNumber
              min="1"
              max="1000"
              step="0.1"
              addonAfter="in"
              stringMode
              style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name="patient_weight" label="Weight (pounds)" >
            <InputNumber
              min="1"
              max="1000"
              step="0.1"
              addonAfter="lb"
              stringMode
              style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      {/* <Form.Item
        label={'Reports significant weight gain or loss in the last 6 months:'}
      >
        <Radio.Group onChange={onRadioGroupChange} value={radioGroupValue} style={{ margin: '3px auto' }}>
          <Radio value={'Yes'}>Yes</Radio>
          <Radio value={'No'}>No</Radio>
          <Radio value={'Other'}>Other</Radio>
        </Radio.Group>
        <Form.Item name="medical_weight_change_6m"
          validateTrigger={'onBlur'}
          rules={[
            {
              required: true,
              message: `required`,
            },
          ]}
          noStyle>
          <Input type={'text'} />
        </Form.Item>
      </Form.Item> */}

      <RadioGroupWithTextInput
        name={'medical_weight_change_6m'}
        label={'Reports significant weight gain or loss in the last 6 months:'}
        options={[
          { label: 'Yes', value: true, hasInput: true },
          { label: 'No', value: false },
        ]}
        form={form}
        draft={draft}
        setDraft={setDraft}
      />

      <Divider />

      <h3>Allergies:</h3>
      <Form.Item
        name="allergies"
      >
        <TextArea rows={3} maxLength={1000} />
      </Form.Item>
    </>
  )
};

export default MedicalHistoryFormItems;
