export const PreConsultationReviewOpts = [
  { key: 'screening_results_reviewed',  label: 'ASRS, PHQ-9, GAD-7 and other screening results reviewed.' },
  { key: 'consent_telemedicine', label: 'Patient consent to be seen via telemedicine.', },
  { key: 'no_suspicious_found', label: 'No suspicious activities found in PMP review.', },
  { key: 'presents_psychiatric_intake_appointment', label: 'Patient presents for a psychiatric intake appointment.' },
  { key: 'consent_telemedicine_treatment_consultation', label: 'Patient consents to treatment and consultation via telemedicine (remote video visit).', },
  { key: 'limitations_telemedicin_informed_received', label: 'Patient was informed of limitations of telemedicine; oral and written consent were received.', },
];

export const PsychiatricHistoryOpts = [
  { key: 'appointment_start_time', label: 'Appointment Start Time', },
  { key: 'appointment_end_time', label: 'Appointment End Time', },
  { key: 'psych_hospitalization', label: 'Psychiatric hospitalization', },
  { key: 'history_of_present_illness', label: 'History of present illness', },
  { key: 'adhd', label: 'ADHD', },
  { key: 'adhd_write_in', label: 'ADHD comment field', }, //text
  { key: 'adhd_following_through_unfinished_tasks', label: 'problems following through on directions/unfinished tasks', },
  { key: 'adhd_difficulty_with_organization', label: 'difficulty with orginzation', },
  { key: 'adhd_forgetful', label:'Is often forgetful'},
  { key: 'adhd_reluctance_engage_sustained_attention', label: 'Reluctance to engage in tasks requiring sustained attention', },
  { key: 'adhd_failing_attention_to_detail', label:'Failing to give close attention to detail/careless mistakes'},
  { key: 'adhd_sustained_attention', label: 'Difficulty sustaining attention', },
  { key: 'adhd_problems_listening', label: 'Problems listening when spoken to directly', },
  { key: 'adhd_misplaced_necessary', label: 'Misplaces things necessary for tasks and activities', },
  { key: 'adhd_distracted_external_stimuli', label: 'Easily distracted by extraneous stimuli', },
  { key: 'adhd_fidget_squirms', label: 'Fidgets/squirms', },
  { key: 'adhd_always_on_the_go', label: 'Always on the go, driven by a motor', },
  { key: 'adhd_leaves_seat_when_unexpected', label: 'Often leaves seat when remaining seated is expected', },
  { key: 'adhd_restlessness', label: 'Restlessness', },
  { key: 'adhd_trouble_engaging_leisure_activity', label: 'Trouble engaging in leisure activities', },
  { key: 'adhd_talks_excessively', label: 'Talks excessively', },
  { key: 'adhd_blurts_out_answers_or_completes_others_sentences', label: 'Blurts out answers/completes other people sentences', },
  { key: 'adhd_difficulty_waiting_for_turn', label: 'Difficulty waiting their turn', },
  { key: 'adhd_interrupts_or_intrudes_on_others', label: 'Interrupts or intrudes on others', },
  { key: 'depression', label: 'Depression', },
  { key: 'depression_write_in', label: 'depression comment field', }, // text
  { key: 'anxiety', label: 'Anxiety', },
  { key: 'anxiety_write_in', label: 'anxiety comment field', }, //text
  { key: 'panic', label: 'Panic', },
  { key: 'insomnia', label: 'insomnia', },
  { key: 'mania_hypomania', label: 'Mania/Hypomania', },
  { key: 'mania_hypomania_write_in', label: 'Mania/Hypomania write in'}, //text
  { key: 'other_psych_symptoms', label: 'other symptoms of concern', },
  { key: 'psychiatric_symptoms_write_in', label: 'psychiatric symptoms field', }, //text
  { key: 'caffeine_use', label: 'caffeine use', },
  { key: 'alcohol_use', label: 'alcohol use', },
  { key: 'nicotine_product_use', label: 'nicotine products', },
  { key: 'marijuana_product_use', label: 'marijuana product use', },
  { key: 'other_substance_use', label: 'other substance use', },
  { key: 'substance_abuse_program', label: 'currently in a substance abuse program', },
  { key: 'substance_abuse_write_in', label: 'Substance abuse comment field: '},
  { key: 'past_history_of_suicide_attempts', label: 'past history of suicide attempts', },
  { key: 'homicidal_ideation', label: 'homicidal ideation', },
  { key: 'history_of_trauma_or_abuse', label: 'history of trauma or abuse', },
  { key: 'self_harm', label: 'Self-Injurious behavior', },
  { key: 'previous_adhd_diagnosis', label: 'previous adhd diagnosis', },
  { key: 'family_adhd_history', label: 'family history adhd', },
  { key: 'psychiatric_history_write_in', label: 'psychiatric history field', }, //text
  { key: 'current_psy_and_ther', label: 'Current Psychiatrist and Therapist', },
  { key: 'allergic_drugs', label: 'drug allergen', }, //text
  { key: 'prev_medications', label: 'previous medications', }, // text
  { key: 'current_medications', label: 'current medications', }, // text
  { key: 'current_pcp', label: 'current pcp', }, // text
  { key: 'most_recent_pcp_appointment', label: 'most recent pcp appt', }, //text
  { key: 'heart_arrhythmias', label: 'heart arrhthymias', },
  { key: 'hypertension', label: 'hypertension', },
  { key: 'stroke', label: 'stroke', },
  { key: 'seizure', label: 'seizure', },
  { key: 'other_major_neuro_or_cardio_disorders', label: 'other major nuero or cardio disorders', },
  { key: 'reported_medical_conditions_write_in', label: 'reported medical conditions', }, // text
  { key: 'patient_endorsement', label: 'patient endorse', },
  { key: 'pregnant_or_family_planning', label: 'pregnant or family planning', },
  { key: 'currently_breastfeeding', label: 'currently breastfeeding', },
  { key: 'currently_using_birth_control', label: 'currently using birth control', },
  { key: 'family_planning_write_in', label: 'family planning comment field', },
  { key: 'social_family_history', label: 'social and family history', }, // text
  { key: 'observation_appearance', label: 'Observation appearance'},
  { key: 'observation_speech', label: 'observation speech'},
  { key: 'observation_eye_contact', label: 'observation eye contact'},
  { key: 'observation_motor_activity', label: 'observation motor activity'},
  { key: 'observation_affect', label: 'observation affect'},
  { key: 'cognition_orientation_impair', label: 'cognition orientation impairment'},
  { key: 'cognition_memory_impair', label: 'cognition memory impairment'},
  { key: 'cognition_attention', label: 'cognition attention'},
  { key: 'perception_hallucinations', label: 'perception hallucinations'},
  { key: 'perception_other', label: 'perception other'},
  { key: 'thoughts_suicidality', label: 'thoughts suicidality'},
  { key: 'thoughts_homicidality', label: 'thoughts homicdality'},
  { key: 'thoughts_delusions', label: 'thoughts delusions'},
  { key: 'behavior', label: 'Behavior'},
  { key: 'insight', label: 'Insight'},
  { key: 'judgement', label: 'Judgement'},
  { key: 'mood_euthymic', label: 'Mood Euthymic'},
  { key: 'mood_anxious', label: 'Mood Anxious'},
  { key: 'mood_angry', label: 'Mood Angry'},
  { key: 'mood_depressed', label: 'Mood Depressed'},
  { key: 'mood_euphoric', label: 'Mood Euphoric'},
  { key: 'mood_irritable', label: 'Mood Irritable'},
  { key: 'mood_other', label: 'Mood Other'},
  { key: 'mood_write_in', label: 'Mood Write In'},
  { key: 'chief_complaint', label: 'Chief Complaint: '},// text
  { key: 'patient_review_concerns', label: 'Patient Review Concerns'}, //text
  { key: 'patient_review_no_concerns', label: 'Patient Reviewed'},
  { key: 'patient_endorse_psych_history', label: 'Patient endorses or denies any history of, or current concerns relating to: trauma or abuse, intrusive thoughts, paranoid or delusional thoughts, psychosis, A/V hallucinations, cognitive alterations or acute decline in memory, or eating disorder behaviors.'},
  { key: 'patient_agree_treatment_plan', label: 'Patient agrees to start the treatment plan'}





];


export const PatientVitalsOpts = [
  { key: 'patient_weight', label: 'weight' },
  { key: 'patient_height', label: 'height' },
  { key: 'other_vitals_notes', label: 'other_notes' },
]

export const SocialHistoryOpts = [
  { key: 'education', label: 'Education' },
  { key: 'disabilities', label: 'Accommodations/Disabilities' },
  { key: 'current_employment', label: 'Current employment/school' },
  { key: 'work_performance', label: 'School/Work performance' },
  { key: 'live_with', label: 'Live with' },
  { key: 'family_involvement', label: 'Family involvement' },
  { key: 'marital_status', label: 'Marital status' },
  { key: 'children', label: 'Children & ages' },
  { key: 'substance_use', label: 'Substance use (alcohol, marijuana, drugs etc.)' },
  { key: 'financial_issues', label: 'Financial issues' },
  { key: 'other_social_history_notes', label: 'other_notes' },
]

export const ADHDReviewOpts = [
  { id: 1, label: 'Easily distracted when trying to complete a task' },
  { id: 2, label: 'Often starting multiple projects without completion', },
  { id: 3, label: 'Has difficulty completing a task before distraction to start another', },
  { id: 4, label: 'Difficulty with motivation leading to procrastination regarding activities that are experienced as uninteresting or non stimulating', },
  { id: 5, label: 'Difficulty with reading, retention and recall of information', },
  { id: 6, label: 'Describes advancing through multiple pages without recall due to distraction, needing to repeat readings to retain information', },
  { id: 7, label: 'Experiences difficulty following conversations without being distracted', },
  { id: 8, label: 'Often experiences a general feeling of being restless, needing to move, difficulty sitting for prolonged periods of time', },
];

export const MedicationHistoryOpts = [
  { key: 'drug allergy', label: 'Previous medications' },
  { key: 'drug allergen', label: 'Current medications' },
  { key: 'current_pcp', label: 'Current medications' },
  { key: 'most_recent_pcp_appointment', label: 'Current medications' },
  { key: 'heart_arrhythmias', label: 'Current medications' },
  { key: 'hypertension', label: 'Current medications' },
  { key: 'stroke', label: 'Current medications' },
  { key: 'seizures', label: 'Current medications' },
  { key: 'other major neuro or cardio disorders', label: 'Current medications' },
  { key: 'patient_endorse', label: 'Current medications' },
  { key: 'patient_deny', label: 'Current medications' },
  { key: 'currently_pregnant', label: 'Current medications' },
  { key: 'currently_breastfeeding', label: 'Current medications' },
  { key: 'currently_using_birth_control', label: 'Current medications' },
  { key: 'family_planning_write_in', label: 'Current medications' },
];

export const dispenseUnits = [
  {
    uid: 10,
    displayVal: 'Application(s)',
    dosageDescSingular: 'Applications',
    dosageDescPlural: 'Applications',
  },
  { uid: 20, displayVal: 'Apply', dosageDescSingular: 'Apply', dosageDescPlural: 'Apply' },
  { uid: 1, displayVal: 'Bottle(s)', dosageDescSingular: 'Bottle', dosageDescPlural: 'Bottles' },
  {
    uid: 5,
    displayVal: 'Capsule(s)',
    dosageDescSingular: 'Capsule',
    dosageDescPlural: 'Capsules',
  },
  { uid: 11, displayVal: 'Cream(s)', dosageDescSingular: 'Cream', dosageDescPlural: 'Creams' },
  { uid: 2, displayVal: 'Drop(s)', dosageDescSingular: 'Drop', dosageDescPlural: 'Drops' },
  {
    uid: 8,
    displayVal: 'Emulsion(s)',
    dosageDescSingular: 'Emulsion',
    dosageDescPlural: 'Emulsions',
  },
  { uid: 21, displayVal: 'Film(s)', dosageDescSingular: 'Film', dosageDescPlural: 'Films' },
  { uid: 13, displayVal: 'Foam', dosageDescSingular: 'Foam', dosageDescPlural: 'Foam' },
  { uid: 14, displayVal: 'Gel(s)', dosageDescSingular: 'Gel', dosageDescPlural: 'Gels' },
  { uid: 9, displayVal: 'GM(s)', dosageDescSingular: 'GM', dosageDescPlural: 'Gms' },
  { uid: 15, displayVal: 'Gram(s)', dosageDescSingular: 'Gram', dosageDescPlural: 'Grams' },
  {
    uid: 16,
    displayVal: 'Granule(s)',
    dosageDescSingular: 'Granule',
    dosageDescPlural: 'Granules',
  },
  {
    uid: 17,
    displayVal: 'Inhaler(s)',
    dosageDescSingular: 'Inhaler',
    dosageDescPlural: 'Inhalers',
  },
  { uid: 3, displayVal: 'ml(s)', dosageDescSingular: 'ml', dosageDescPlural: 'mls' },
  { uid: 18, displayVal: 'Ounce(s)', dosageDescSingular: 'Ounce', dosageDescPlural: 'Ounces' },
  { uid: 19, displayVal: 'oz(es)', dosageDescSingular: 'oz', dosageDescPlural: 'ozes' },
  {
    uid: 6,
    displayVal: 'Package(s)',
    dosageDescSingular: 'Package',
    dosageDescPlural: 'Packages',
  },
  { uid: 4, displayVal: 'Tablet(s)', dosageDescSingular: 'Tablet', dosageDescPlural: 'Tablets' },
  { uid: 12, displayVal: 'Tube(s)', dosageDescSingular: 'Tube', dosageDescPlural: 'Tubes' },
  { uid: 7, displayVal: 'Unit(s)', dosageDescSingular: 'Unit', dosageDescPlural: 'Units' },
];

export const drugFrequencies = [
  { uid: 78, displayVal: '1 TIME ONLY', count: 1.0, code: '1XONLY' },
  { uid: 79, displayVal: '1 TO 2 TIMES DAILY', count: 2.0, code: 'Q12D' },
  { uid: 80, displayVal: '15 MINUTES BEFORE MEALS', count: 3.0, code: '15MINAC' },
  { uid: 81, displayVal: '2 TIMES DAILY', count: 2.0, code: 'BID2' },
  { uid: 82, displayVal: '2 TO 3 TIMES DAILY', count: 3.0, code: 'Q23D' },
  { uid: 83, displayVal: '3 TIMES DAILY', count: 3.0, code: 'TID' },
  { uid: 84, displayVal: '3 TIMES DAILY AFTER MEALS', count: 3.0, code: 'TIDPC' },
  { uid: 85, displayVal: '3 TIMES DAILY AS NEEDED', count: 3.0, code: 'TIDP' },
  { uid: 86, displayVal: '3 TIMES DAILY BEFORE MEALS', count: 3.0, code: 'TIDAC' },
  { uid: 87, displayVal: '3 TIMES DAILY WITH FOOD', count: 3.0, code: 'TIDW' },
  { uid: 88, displayVal: '3 TIMES DAILY WITH MEALS', count: 3.0, code: 'TIDWM' },
  {
    uid: 89,
    displayVal: '3 TIMES WEEKLY 4 TIMES DAILY 30 MIN BEFORE MEALS AND BED',
    count: 2.0,
    code: 'T4DWM',
  },
  { uid: 90, displayVal: '4 TIMES DAILY', count: 4.0, code: 'QID' },
  { uid: 91, displayVal: '4 TIMES DAILY AS NEEDED', count: 4.0, code: 'QIDP' },
  {
    uid: 92,
    displayVal: '4 TIMES DAILY BEFORE MEALS AND AT BEDTIME',
    count: 4.0,
    code: 'QIDACHS',
  },
  { uid: 93, displayVal: '4 TIMES WEEKLY', count: 0.57, code: '4XWK' },
  { uid: 94, displayVal: '4 TO 6 TIMES DAILY', count: 6.0, code: 'Q46D' },
  { uid: 95, displayVal: '5 TIMES DAILY', count: 5.0, code: '5XD' },
  { uid: 96, displayVal: 'AFTER MEALS AND AT BEDTIME', count: 4.0, code: 'PC&HS' },
  { uid: 97, displayVal: 'AS DIRECTED BY PHYSICIAN', count: 1.0, code: 'ADBP' },
  { uid: 98, displayVal: 'AS NEEDED', count: 1.0, code: 'PRN' },
  { uid: 99, displayVal: 'AT BEDTIME', count: 1.0, code: 'QHS' },
  { uid: 100, displayVal: 'AT BEDTIME AS NEEDED', count: 1.0, code: 'QHSP' },
  { uid: 101, displayVal: 'BEFORE MEALS AND AT BEDTIME', count: 4.0, code: 'ACHS' },
  { uid: 102, displayVal: 'DAILY', count: 1.0, code: 'QD' },
  { uid: 103, displayVal: 'DAILY AS NEEDED', count: 1.0, code: 'QDP' },
  { uid: 104, displayVal: 'DAILY FOR 5 DAYS', count: 1.0, code: 'QDX5DA' },
  { uid: 105, displayVal: 'DAILY ON EMPTY STOMACH', count: 1.0, code: 'QDE' },
  { uid: 106, displayVal: 'DAILY WITH FOOD', count: 1.0, code: 'QDW' },
  { uid: 107, displayVal: 'EVERY 12 HOURS', count: 2.0, code: 'Q12H' },
  { uid: 108, displayVal: 'EVERY 12 HOURS AS NEEDED', count: 2.0, code: 'Q12HP' },
  { uid: 109, displayVal: 'EVERY 12 HOURS FOR 2 DOSES', count: 2.0, code: 'Q12HX2DOSES' },
  { uid: 111, displayVal: 'EVERY 12 HOURS ON EMPTY STOMACH', count: 2.0, code: 'Q12HE' },
  { uid: 110, displayVal: 'EVERY 12 HOURS WITH FOOD', count: 2.0, code: 'Q12HW' },
  { uid: 112, displayVal: 'EVERY 15 MIN, MAY REPEAT 5 TIMES', count: 6.0, code: 'Q15MINMRX5' },
  { uid: 113, displayVal: 'EVERY 2 WEEKS', count: 0.07, code: 'GQ2W' },
  { uid: 114, displayVal: 'EVERY 3 DAYS', count: 0.33, code: 'Q3D' },
  { uid: 115, displayVal: 'EVERY 3 HOURS', count: 8.0, code: 'Q3H' },
  {
    uid: 116,
    displayVal: 'EVERY 3 HOURS WHILE AWAKE (6 DOSES) FOR 7 DAYS',
    count: 6.0,
    code: 'Q3HWAX7D',
  },
  { uid: 117, displayVal: 'EVERY 3 TO 6 HOURS', count: 8.0, code: 'Q36H' },
  { uid: 118, displayVal: 'EVERY 3 TO 6 HOURS AS NEEDED', count: 8.0, code: 'Q36HP' },
  { uid: 119, displayVal: 'EVERY 30 MINUTES AS NEEDED', count: 48.0, code: 'Q30MINP' },
  { uid: 120, displayVal: 'EVERY 4 HOURS', count: 6.0, code: 'Q4H' },
  { uid: 121, displayVal: 'EVERY 4 HOURS AFTER MEALS', count: 6.0, code: 'Q4HPPC' },
  { uid: 122, displayVal: 'EVERY 4 HOURS AS NEEDED', count: 6.0, code: 'Q4HP' },
  { uid: 123, displayVal: 'EVERY 4 HOURS WHILE AWAKE', count: 4.0, code: 'Q4HWA' },
  { uid: 124, displayVal: 'EVERY 4 TO 6 HOURS', count: 6.0, code: 'Q4-6H' },
  { uid: 125, displayVal: 'EVERY 4 TO 6 HOURS AS NEEDED', count: 6.0, code: 'Q46HP' },
  { uid: 126, displayVal: 'EVERY 6 HOURS', count: 4.0, code: 'Q6H' },
  { uid: 127, displayVal: 'EVERY 6 HOURS AS NEEDED', count: 4.0, code: 'Q6HP' },
  { uid: 128, displayVal: 'EVERY 6 HOURS ON EMPTY STOMACH', count: 4.0, code: 'Q6HE' },
  { uid: 129, displayVal: 'EVERY 6 HOURS WITH FOOD', count: 4.0, code: 'Q6HW' },
  { uid: 130, displayVal: 'EVERY 6 TO 8 HOURS', count: 4.0, code: 'Q68H' },
  { uid: 131, displayVal: 'EVERY 6 TO 8 HOURS AS NEEDED', count: 4.0, code: 'Q68HP' },
  { uid: 132, displayVal: 'EVERY 8 HOURS', count: 3.0, code: 'Q8H' },
  { uid: 133, displayVal: 'EVERY 8 HOURS AS NEEDED', count: 3.0, code: 'Q8HP' },
  { uid: 134, displayVal: 'EVERY 8 HOURS ON AN EMPTY STOMACH', count: 3.0, code: 'Q8HE' },
  { uid: 135, displayVal: 'EVERY 8 TO 12 HOURS', count: 3.0, code: 'Q812H' },
  { uid: 136, displayVal: 'EVERY 8 TO 12 HOURS AS NEEDED', count: 3.0, code: 'Q812HP' },
  { uid: 137, displayVal: 'EVERY MORNING', count: 1.0, code: 'QAM' },
  { uid: 138, displayVal: 'EVERY MORNING AS NEEDED', count: 1.0, code: 'QAMP' },
  { uid: 155, displayVal: 'EVERY OTHER DAY', count: 0.5, code: 'QOD' },
  { uid: 139, displayVal: 'EVERY WEEK', count: 0.14, code: 'QW' },
  { uid: 140, displayVal: 'NOW AND REPEAT 1 TIME', count: 2.0, code: 'STATREPEAT' },
  { uid: 141, displayVal: 'NOW, THEN 1 EVERY 24 HOURS', count: 1.0, code: 'NOW1Q24H' },
  { uid: 142, displayVal: 'NOW. MAY REPEAT IN 2 HOURS AS NEEDED', count: 2.0, code: 'STATR2HP' },
  { uid: 143, displayVal: 'ONCE DAILY', count: 1.0, code: 'ONCEDAILY' },
  { uid: 144, displayVal: 'ONCE IN THE EVENING', count: 1.0, code: 'QDHS' },
  {
    uid: 145,
    displayVal: 'ONCE OR TWICE DAILY FOR 2 TO 3 WEEKS THEN EVERY OTHER DAY THEREAFTER',
    count: 2.0,
    code: 'Q12DX23WQOD',
  },
  { uid: 146, displayVal: 'ONCE OR TWICE DAILY FOR 7 DAYS', count: 2.0, code: 'Q12DX7D' },
  { uid: 147, displayVal: 'TWICE DAILY', count: 2.0, code: 'BID' },
  { uid: 148, displayVal: 'TWICE DAILY AS NEEDED', count: 2.0, code: 'BIDP' },
  { uid: 149, displayVal: 'TWICE DAILY WITH FOOD', count: 2.0, code: 'BIDW' },
  { uid: 150, displayVal: 'TWICE DAILY WITH WATER', count: 2.0, code: 'BIDWATER' },
  { uid: 151, displayVal: 'TWICE WEEKLY', count: 0.28, code: '2XW' },
  { uid: 152, displayVal: 'TWICE WEEKLY FOR 6 MONTHS', count: 0.28, code: '2XWKX6M' },
  { uid: 153, displayVal: 'WITH DINNER', count: 1.0, code: 'D' },
  { uid: 154, displayVal: 'WITH EACH MEAL OR SNACK', count: 3.0, code: 'QMS' },
];

export const moodHxItems = [
  { label: 'Denies', value: 'mood_hx_denies', exclusive: true },
  { label: 'Cycling', value: 'mood_hx_cycling' },
  { label: 'Grandiosity', value: 'mood_hx_grandiosity' },
  { label: 'Hypersexuality', value: 'mood_hx_hypersexuality' },
  { label: 'Energy with lack of sleep', value: 'mood_hx_lack_sleep' }
]

export const familyMentalHealthItems = [
  { label: 'Denies', value: 'family_mental_health_denies', exclusive: true },
  { label: 'IP', value: 'family_mental_health_ip', hasInput: true, },
  { label: 'OP', value: 'family_mental_health_op', hasInput: true, },
  { label: 'Other', value: 'family_mental_health_other', hasInput: true, }
]

export const familyMedicalHistoryItems = [
  { label: 'Denies', value: 'family_medical_history_denies', exclusive: true },
  { label: 'Suicide attempts', value: 'family_medical_history_suicide', hasInput: true, },
  { label: 'Psychosis', value: 'family_medical_history_psychosis', hasInput: true, },
  { label: 'Bipolar d/o', value: 'family_medical_history_bipolar', hasInput: true, },
  { label: 'SMI', value: 'family_medical_history_smi', hasInput: true, },
  { label: 'Other', value: 'family_medical_history_other', hasInput: true, }
]

export const medicalHistoryItems = [
  { label: 'Cardiac', value: 'medical_cardiac', hasInput: true, },
  { label: 'Arrhythmias', value: 'medical_arrhythmias', hasInput: true, },
  { label: 'Hypersomnolence', value: 'medical_hypersomnolence', hasInput: true, },
  { label: 'HTN', value: 'medical_htn', hasInput: true, },
  { label: 'Sleep apnea', value: 'medical_sleep_apnea', hasInput: true, },
  { label: 'Seizure', value: 'medical_seizure', hasInput: true, },
  { label: 'CVA/Stroke', value: 'medical_cva_stroke', hasInput: true, },
  { label: 'Thrombotic Events', value: 'medical_thrombotic_events', hasInput: true, },
  { label: 'Denies', value: 'medical_denies', exclusive: true },
]

export const psychiatricHistoryItems = [
  { label: 'Denies', value: 'psychiatric_history_denies', exclusive: true },
  { label: 'Inpatient', value: 'psychiatric_history_inpatient' },
  { label: 'IOP', value: 'psychiatric_history_iop' },
  { label: 'PHP', value: 'psychiatric_history_php' },
  { label: 'OP', value: 'psychiatric_history_op' },
  { label: 'Other', value: 'psychiatric_history_other' }
]

export const socialHistoryItems = [
  { label: 'Denies', value: 'social_denies', exclusive: true },
  { label: 'Legal issues', value: 'social_legal_issues', hasInput: true, },
  { label: 'Incarcerations', value: 'social_incarcerations', hasInput: true, },
  { label: 'Probation', value: 'social_probation', hasInput: true, },
  { label: 'Parole', value: 'social_parole', hasInput: true, },
  { label: 'Other', value: 'social_other', hasInput: true, }
]

export const substanceUseHistoryItems = [
  { label: 'Caffeine', value: 'substance_use_caffeine' },
  { label: 'Nicotine/Tobacco', value: 'substance_use_nicotine_tobacco', },
  { label: 'ETOH', value: 'substance_use_etoh' },
  { label: 'Other substances (opioids, meth, cocaine, benzos, etc)', value: 'substance_use_others' },
]

export const substanceUseHistoryFemaleItems = [
  { label: 'Pregnancy', value: 'female_pregnancy', hasInput: true, },
  { label: 'Plag Pregnancy', value: 'female_plag_pregnancy', hasInput: true, },
  { label: 'Breastfeedingnnin', value: 'female_breastfeeding', hasInput: true, },
]

export const riskItems = [
  { label: 'None', value: 'risk_none', exclusive: true },
  { label: 'Suicidal ideation only', value: 'risk_suicidal_ideation', hasInput: true, },
  { label: 'Suicidal intent', value: 'risk_suicidal_intent', hasInput: true, },
  { label: 'Suicidal planning', value: 'risk_suicidal_planning', hasInput: true, },
  { label: 'History of suicide attempts', value: 'risk_suicide_attempts', hasInput: true, },
  { label: 'History of impulsive self harm', value: 'risk_self_harm', hasInput: true, },
  { label: 'Other', value: 'risk_other', hasInput: true, }
]

export const protectiveItems = [
  { label: 'None', value: 'protective_none', exclusive: true },
  { label: 'Married', value: 'protective_married', hasInput: true, },
  { label: 'Has children', value: 'protective_children', hasInput: true, },
  { label: 'Other strong interpersonal bonds family/friends', value: 'protective_interpersonal_bonds', hasInput: true, },
  { label: 'Future oriented', value: 'protective_oriented', hasInput: true, },
  { label: 'Social support system', value: 'protective_social_support', hasInput: true, },
  { label: 'Cultural/Spiritual/Religious beliefs that preclude suicide', value: 'protective_preclude_suicide', hasInput: true, },
  { label: 'Other', value: 'protective_other', hasInput: true, }
]


export const harmOthersItems = [
  { label: 'None', value: 'harm_others_none', exclusive: true },
  { label: 'Ideation only', value: 'harm_others_ideation', hasInput: true, },
  { label: 'Intent', value: 'harm_others_intent', hasInput: true, },
  { label: 'Specific target', value: 'harm_others_specific_target', hasInput: true, },
  { label: 'History of violence towards others', value: 'harm_others_violence_towards', hasInput: true, },
  { label: 'Other', value: 'harm_others_other', hasInput: true, }
]

export const mseGeneralItems = [
  { label: 'A&O x 3 or:', value: 'mse_ao3' },
  { label: 'Pleasant and cooperative or: ', value: 'mse_pleasant_cooperative' },
  { label: 'Appears healthy, well groomed with good hygiene or: ', value: 'mse_healthy_groomed_hygiene' },
  { label: 'No psychomotor anomalies noted or: ', value: 'mse_no_psychomotor_nomalies' },
]

export const mseSpeechItems = [
  { label: 'Speech normal rate, volume and prosody or: ', value: 'mse_speech_volume_prosody' },
]

export const mseMoodAffectItems = [
  { label: 'Mood(patient’s own words): ', value: 'mse_mood_patient_words' },
  { label: 'Affect: ', value: 'mse_affect' },
]

export const mseThoughtProcessItems = [
  { label: 'TP: linear, goal directed and coherent or: ', value: 'mse_goal_directed_coherent' },
  { label: 'Memory and cognition appear grossly intact or:', value: 'mse_memory_cognition_intact' },
]

export const mseThoughtContenItems = [
  { label: 'TC: No SI/HI/AH/VH/delusions or: ', value: 'mse_no_shav_delusions' },
]

export const mseInsightJudgmentItems = [
  { label: 'Insight and judgment: intact or: ', value: 'mse_insight_judgment_intact' },
]


export const timeSpentItems = [
  { label: 'Face to face', value: 'spent_face_to_face' },
  { label: 'Chart preparation', value: 'spent_chart_preparation' },
  { label: 'Counseling', value: 'spent_counseling' },
  { label: 'Reporting test results', value: 'spent_reporting_test_results' },
  { label: 'Ordering medication', value: 'spent_ordering_medication' },
  { label: 'Visit documentation', value: 'spent_visit_documentation' },
  { label: 'Care coordination', value: 'spent_care_coordination' },
  { label: 'Reviewing separately obtained history', value: 'spent_reviewing_separately_obtained' },
  { label: 'Completing referrals', value: 'spent_completing_referrals' },
]

export const InitialNoteExclusiveItems = [
  ...moodHxItems,
  ...familyMentalHealthItems,
  ...familyMedicalHistoryItems,
  ...medicalHistoryItems,
  ...socialHistoryItems,
  ...substanceUseHistoryFemaleItems,
  ...psychiatricHistoryItems,
  ...riskItems,
  ...protectiveItems,
  ...harmOthersItems,
]