import React, { useState, useRef, useEffect } from 'react';
import { Button, Spin, Drawer, Slider, Input, Form, message } from 'antd';
import cn from 'classnames/bind';
import numeral from 'numeral';
import { AsYouType } from 'libphonenumber-js';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import zippo from 'zippo';

import { srvPharmacyList, srvRXNTPharmacyList } from '@/services/admin';
import styles from './index.less';
const cx = cn.bind(styles);

const marks = {
  5: '5 mi',
  10: '10 mi',
  25: '25 mi',
  50: '50 mi',
};

const maxItems = '1000';

const CaPharmacyDrawer = (props) => {
  const { visible } = props;
  const onDrawerClose = () => {
    props.onClose && props.onClose();
  };
  const [data, setData] = useState({
    totalPharmacies: 0,
    pharmacies: [],
  });
  const zipcodeRef = useRef(null);
  const filterRef = useRef(null)
  const distanceRef = useRef(null);
  const nameRef = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const addressRef = useRef(null);
  const phoneRef = useRef(null);
  const faxRef = useRef(null);
  const [zipcode, setZipcode] = useState(props.zipcode);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [fax, setFax] = useState('');
  const [filter, setFilter] = useState('');
  const [pharmacy, setPharmacy] = useState(props.pharmacy);
  const [distance, setDistance] = useState(5);

	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [address, setAddress] = useState('');


  useEffect(() => {
    setCity(pharmacy?.city || '');
    setState(pharmacy?.state || '');
    setAddress(pharmacy?.address_1 || '');
  }, [pharmacy]);

	const onFinish = () => {
		if (!city || !state || !address) {
			message.error('Please fill in all fields');
			return;
		}
		// Submit the form
    props.onFinish && props.onFinish({
      city,
      state,
      address,
    });
	};







  return (
    <Drawer
      className={cx({ pharmacyDrawer: true })}
      title={
        <div className={cx({ title: true })}>
          <h3>Pharmacies</h3>
        </div>
      }
      width="600"
      placement="right"
      closable={false}
      onClose={onDrawerClose}
      visible={visible}
      destroyOnClose={true}
    >

        <Form onFinish={onFinish}>
        <Form.Item
          label="City"
          rules={[{ required: true, message: 'Please input your city!' }]}
        >
          <Input
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />
        </Form.Item>

        <Form.Item
          label="State"
          rules={[{ required: true, message: 'Please input your state!' }]}
        >
          <Input
            value={state}
            onChange={(e) => {
              setState(e.target.value);
            }}
          />
        </Form.Item>

        <Form.Item
          label="Address"
          rules={[{ required: true, message: 'Please input your address!' }]}
        >
          <Input
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
CaPharmacyDrawer.defaultProps = {
  zipcode: '',
};
export default CaPharmacyDrawer;
