import { message } from 'antd';
import { connect, history } from 'umi';
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as Sentry from "@sentry/react";
import pkg from '../package.json';
import moment from 'moment';

moment.locale('en');
Sentry.init({
  dsn: "https://efe21bff70989e38dc945427625c06db@o4506114276720640.ingest.us.sentry.io/4506114279276544",
  integrations: [],
  release: pkg.version,
  environment: process.env.DEPLOY_ENV,
});

export const dva = {
  config: {
    onError(err) {
      err.preventDefault();
      console.error(err.message);
    },
  },
};

export const request = {
  middlewares: [],

  errorConfig: {
    adaptor: (resp) => {
      if (resp.code === 401) {
        history.push('/user/login');
        return {
          success: resp.success,
          data: resp.data,
          errorCode: resp.code,
          errorMessage: resp.msg,
          showType: 0,
        };
      }
      return {
        success: resp.success,
        data: resp.data,
        errorCode: resp.code,
        errorMessage: resp.msg,
      };
    },
  },
};
export async function getInitialState() {
  return {};
}
