import { LoadingOutlined, PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useMount, useRequest } from 'ahooks';
import { Button, Col, Form, Input, message, Row, Spin, Select, Space, Switch, Upload, InputNumber, DatePicker, Tabs  } from 'antd';
import ImgCrop from 'antd-img-crop';
const { TextArea } = Input;
import cn from 'classnames/bind';
import GoogleMapReact from 'google-map-react';
import { useState } from 'react';
import { history } from 'umi';

import GeoSuggestAutoComplete from '@/components/GeoSuggest';
import {
  srvAddDoctor,
  srvDoctorDetail,
  srvStates,
  srvUpdateDoctor,
  srvCollabDoctors,
  srvDoctorTags
} from '@/services/admin';
import { srvUploadToken } from '@/services/patient';
import { getDoctorAvatarURL } from '@/utils/utils';
import { timeZones } from '@/utils/xtime';
import styles from './index.less';
import RxntAccountEdit from './RxntAccountEdit';
import Marker from './marker';
import ProviderIncomeEdit from './ProviderIncomeEdit';
import moment from 'moment';
import DoctorLocation from './DoctorLocation';
const cx = cn.bind(styles);

const DoctorEdit = (props) => {
  moment.tz.setDefault('America/Los_Angeles')

  const [item, setItem] = useState({});
  const [states, setStates] = useState([]);
  const [tags, setTags] = useState([]);
  const [providerType, setProviderType] = useState(0);
  const [collabDoctors, setCollabDoctors] = useState([]);
  const [collabs, setCollabs] = useState([]);
  const [keyCount, setKeyCount] = useState(0);

  const [selectStates, setSelectStates] = useState([]);
  const [draggable, setDraggable] = useState(true);
  const [marker, setMarker] = useState({
    position: {
      lat: 34.0522,
      lng: -118.2437,
    },
  });
  const [supportPediatric, setSupportPediatric] = useState(false);
  const [timeplanFlag, setTimeplanFlag] = useState(true);
  const [supportInsomnia, setSupportInsomnia] = useState(false);
  const [supportInPerson, setSupportInPerson] = useState(false);
  const [supportTherapy, setSupportTherapy] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [acuityBak, setAcuityBak] = useState({});
  const [originTimeplanFlag, setOriginTimeplanFlag] = useState(false);
  const [jobType, setJobType] = useState(0);
  const [defaultStates, setDefaultStates] = useState('');
  const [locations, setLocations] = useState([{}]);

  const id = props.match.params.id;
  const [enableOpenNewAcuity, setEnableOpenNewAcuity] = useState(false);
  const itemReq = useRequest(srvDoctorDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (!ret.data.support_states) {
          ret.data.support_states = [];
        }
        if (!ret.data.doctor_tags) {
          ret.data.doctor_tags = [];
        }
        if (ret.data.doctor_type === 5) {
          ret.data.support_states = 'All States';
        }
        if (ret.data.doctor_type === 5 || ret.data.doctor_type === 3) {
          setSupportTherapy(true);
        }
        if (ret.data.support_states.length > 0) {
          ret.data.support_states = ret.data.support_states.split(',');
        }
        if (ret.data.start_date) {
          ret.data.start_date = moment.unix(ret.data.start_date)
        }
        if (ret.data.end_date) {
          ret.data.end_date = moment.unix(ret.data.end_date)
        }
        if (ret.data.doctor_locations) {
          ret.locations = ret.data.doctor_locations
          setLocations(ret.data.doctor_locations)
        }

        setItem(ret.data);
        if (ret.data.support_pediatric) {
          setSupportPediatric(true);
        }
        if (ret.data.support_in_person) {
          setSupportInPerson(true);
        }
        if (ret.data.supervising_doctors && ret.data.supervising_doctors.length > 0) {
          loadCollab(ret.data.supervising_doctors);
        }
        if (ret.data.support_insomnia) {
          setSupportInsomnia(true)
        }
        if (ret.data.support_therapy) {
          setSupportTherapy(true)
        }

        if (ret.data.doctor_status === 0) {
          delete ret.data.doctor_status;
        }

        setProviderType(ret.data.doctor_type);
        form.setFields([{name:'doctor_type', value:ret.data.doctor_type}])

        if (ret.data.support_states && ret.data.support_states.length > 0) {
          const selectedStates = (ret.data.rxnt_accounts || []).filter(a => a && !a.is_del).map(a => a.state);
          const selectStates = (ret.data.support_states || []).filter(s => !selectedStates.includes(s));
          setSelectStates(selectStates);
        }

        setAvatarUrl(
          getDoctorAvatarURL(ret.data.id, ret.data.first_name + ret.data.last_name, ret.data.title),
        );
        setTimeplanFlag(ret.data.timeplan_flag)
        setJobType(ret.data.job_type);
        setDefaultStates(ret.data.support_states);
        setAcuityBak(ret.data.acuity_bak)
        setOriginTimeplanFlag(ret.data.timeplan_flag);
        setEnableOpenNewAcuity(ret.data.enable_open_new_acuity);
      }
    },
  });

  const statesReq = useRequest(srvStates, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setStates(ret.data);
      }
    },
  });


  const tagsReq = useRequest(srvDoctorTags, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setTags(ret.data.items);
      }
    },
  });

  const collabDoctorsReq = useRequest(srvCollabDoctors, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setCollabDoctors(ret.data.items);
      }
    },
  });

  const updateReq = useRequest(srvUpdateDoctor, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        message.success('Clinician Updated');
      } else {
        message.error(ret.msg);
      }
    },
  });

  const addReq = useRequest(srvAddDoctor, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        message.success('Clinician Added');
        history.goBack();
      } else {
        message.error(ret.msg);
      }
    },
  });

  const getKey = () => {
    const key = keyCount;
    setKeyCount(keyCount + 1);
    return key;
  }

  const loadCollab = (doctors) => {
    doctors.forEach((doctor) => {
      const collab = collabs.find((c) => {
        return c.id === doctor.collab_doctor_id;
      })
      if (collab === undefined) {
        collabs.push(
          {
            key:getKey(),
            id: doctor.collab_doctor_id,
            name: doctor.name,
            name_error: null,
            states: [doctor.state],
            states_error: null,
          }
        );
      } else {
        collab.states.push(doctor.state);
      }
    });
  }

  const validateCollabs = (cs) => {
    let error = false;
    cs.forEach((c, i) => {
      if (c.id != null && c.id !== undefined && c.states.length === 0) {
        setCollabStatesError(i, 'must include at least one Supported State')
        error = true;
      }
      if ((c.id == null || c.id == undefined) && c.states.length > 0) {
        setCollabNameError(i, 'must select an MD')
        error = true;
      }
    });
    return error;
  }

  const formatSupervisingDoctors = (cs) => {
    let supervisingDocs = [];
    cs.forEach((c) => {
      if(c.id != null && c.states.length > 0) {
        c.states.forEach((state) => {
          supervisingDocs.push({ doctor_id: c.id, state: state });
        });
      }
    });
    return supervisingDocs;
  }

  const handleOk = () => {
    // clear existing error msgs
    collabs.forEach((_, i) => {
      setCollabStatesError(i, null);
      setCollabNameError(i, null);
    });

    // validate collab errors and update error msgs
    if (validateCollabs(collabs.slice())) {
      return;
    }

    // format for save
    const supervisingDoctors = providerType === 0 ?
      formatSupervisingDoctors(collabs) : [];

    form
      .validateFields()
      .then((values) => {
        if (item.id) {
          values.id = item.id;
        }
        if (values.external_appointment_type_id === '') {
          values.external_appointment_type_id = '0';
        }
        if (values.external_appointment_50min_type_id === '') {
          values.external_appointment_50min_type_id = '0';
        }
        if (values.external_follow_up_appointment_type_id === '') {
          values.external_follow_up_appointment_type_id = '0';
        }
        if (values.external_calendar_id === '') {
          values.external_calendar_id = '0';
        }

        if (values.doctor_locations) {
          for (let i = 0; i < values.doctor_locations.length; i++) {
            if (values.doctor_locations[i].in_person_appointment_type_id == '') {
              values.doctor_locations[i].in_person_appointment_type_id = '0';
            }
            if (values.doctor_locations[i].in_person_appointment_50min_type_id == '') {
              values.doctor_locations[i].in_person_appointment_50min_type_id = '0';
            }

            if (values.doctor_locations[i].in_person_follow_up_appointment_type_id == '') {
              values.doctor_locations[i].in_person_follow_up_appointment_type_id = '0';
            }
          }
        }

        if (!values.support_states) {
          values.support_states = [];
        }
        if (values.doctor_type===5){
          values.support_states=['All States'];
        }

        if (values.start_date) {
          values.start_date = values.start_date.unix()
        }
        if (values.end_date) {
          values.end_date = values.end_date.unix()
        }
        if (values.id) {
          updateReq.run({
            ...values,
            support_states: values.support_states.join(','),
            supervising_doctors: supervisingDoctors,
          });
        } else {
          addReq.run({
            ...values,
            support_states: values.support_states.join(','),
            supervising_doctors: supervisingDoctors,
          });
        }
      })
      .catch((error) => {
        message.error(error['errorFields'][0]['errors'][0])
        if (error['errorFields'][0]['name'][0] !== 'doctor_locations'){
          form.scrollToField(error['errorFields'][0]['name'], { behavior: 'smooth'})
        }
      });
  };

  const handleReturn = () => {
    const filterState = (history.location.state && history.location.state.filterState) || {};
    history.push(`/doctor`, { filterState });
  };

  const stateOpts = states.map((v, i) => {
    return (
      <Select.Option key={i} value={v.state}>
        {v.state}
      </Select.Option>
    );
  });


  const tagsOpts = tags.map((v, i) => {
    return (
      <Select.Option key={i} value={v.id}>
        {v.tag_name}
      </Select.Option>
    );
  });

  const shouldFilterDoc = (doc, currentCollab, selectedCollabs) => {
    let remove = false;
    selectedCollabs.forEach((collab) => {
      if(doc.id == collab.id && doc.id !== currentCollab.id) {
        remove = true;
      }
    });
    return remove;
  }

  const filteredDoctorOpts = (currentCollab, selectedCollabs) => {
    return collabDoctors.filter(doc => doc.id !== item.id).map((v, i) => {
      return (
        <Select.Option
          key={i}
          value={v.id}
          label={`${v.name}, ${v.title}`}
          disabled={shouldFilterDoc(v, currentCollab, selectedCollabs)}
        >
          {`${v.name}, ${v.title}`}
        </Select.Option>
      );
    });
  };

  const timeZoneOpts = timeZones.map((v, i) => {
    return (
      <Select.Option key={i} value={v[0]}>
        {v[1]}
      </Select.Option>
    );
  });

  const onDragMarker = (childKey, childProps, mouse) => {
    setDraggable(false);
    setMarker({
      position: {
        lat: mouse.lat,
        lng: mouse.lng,
      },
    });
  };

  const onDragendMarker = async (childKey, childProps, mouse) => {
    setDraggable(true);

    const location = {
      lat: marker.position.lat,
      lng: marker.position.lng,
    };
    const geocoder = new window.google.maps.Geocoder();
    const res = await geocoder.geocode({ location });

    if (!res.results.length) {
      return;
    }

    const ret = res.results[0];

    let cityObj = ret.address_components.find((comp) => comp.types.includes('locality'));
    let stateObj = ret.address_components.find((comp) =>
      comp.types.includes('administrative_area_level_1'),
    );
    let zipObj = ret.address_components.find((comp) => comp.types.includes('postal_code'));
    const city = cityObj && cityObj.long_name;
    const state = stateObj && stateObj.long_name;
    const stateShort = (stateObj && stateObj.short_name) || state;
    const zip = (zipObj && zipObj.long_name) || '';

    let streetObj = ret.address_components.find((comp) => comp.types.includes('street_number'));
    let routeObj = ret.address_components.find((comp) => comp.types.includes('route'));
    const street = (streetObj && streetObj.long_name) || '';
    const route = (routeObj && routeObj.long_name) || '';
    const addressLine1 = `${street} ${route}`;

    setMarker({ ...marker, text: ret.formated_address });

    var data = {};
    if (zip && zip.length) {
      data.zipcode = zip;
    } else {
      data.zipcode = '';
    }

    if (stateShort && stateShort.length) {
      data.state = stateShort.toUpperCase();
    } else {
      data.state = '';
    }

    if (city && city.length) {
      data.city = city;
    } else {
      data.city = '';
    }

    const lat = ret.geometry.location.lat();
    const lng = ret.geometry.location.lng();
    data.lat = lat;
    data.lng = lng;
    data.address_line1 = addressLine1;
    form.setFieldsValue(data);
  };

  const onChildMouseClick = (key) => {
    setCenter({
      lat: key.lat,
      lng: key.lng,
    });
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.support_states || changedValues.rxnt_accounts) {
      const selectedStates = (allValues.rxnt_accounts || []).filter(a => a && !a.is_del).map(a => a.state);
      const selectStates = (allValues.support_states || []).filter(s => !selectedStates.includes(s));
      setSelectStates(selectStates);
    }
  }

  useMount(async () => {
    await(load()).then(() => {
      setLoading(false);
    })
  });

  const load = async () => {
    if (id && id.length) {
      await itemReq.runAsync({ id });
    }else {
      setItem(
        {
          accept_transfer_patients:true,
          accept_new_patients:true,
        });
    }
    await statesReq.runAsync();
    await tagsReq.runAsync({ page: 1, page_size: 100, filters: JSON.stringify({tag_type:"doctor"})});
    await collabDoctorsReq.runAsync();
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setUploading(true);
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setUploading(false);
        setAvatarUrl(url);
      });
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const uploadButton = (
    <div>
      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const uploadFile = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const file_name = `${item.id}_${item.first_name}${item.last_name}${item.title}.png`;
    srvUploadToken({ type: 4, file_name }).then((res) => {
      const { upload_url } = res.data;

      file.arrayBuffer().then((content) => {
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': file.type },
          body: content,
        };

        fetch(upload_url, requestOptions)
          .then(() => {
            onSuccess("Ok");
            message.success('successfully uploaded')})
          .catch((err) => {
            onError({ err });
            console.error('Upload failed:', err);
            message.error(`Failed to upload: ${err.message || 'Unknown error'}`);
          });
      });
    });
  };

  const addRow = () => {
    setCollabs([...collabs,{key:getKey(), id: null, name: null, states: []}]);
  }

  const deleteRow = (i) => {
    setCollabs([...collabs.slice(0,i), ...collabs.slice(i+1)]);
  }

  const setCollab = (i, did) => {
    let doc = collabDoctors.find((doctor) => {return doctor.id  == did})
    let collab = collabs[i];
    collab.id = did;
    collab.name = doc.name;
    collab.name_error = null;

    setCollabs([...collabs.slice(0,i), collab, ...collabs.slice(i+1)]);
  }

  const setCollabStates = (i, state) => {
    let collab = collabs[i];
    if(!collab.states.includes(state)) {
      collab.states.push(state);
    }
    collab.states_error = null;

    setCollabs([...collabs.slice(0,i), collab, ...collabs.slice(i+1)]);
  }

  const setCollabStatesError = (i, error) => {
    let collab = collabs[i];
    collab.states_error = error;

    setCollabs([...collabs.slice(0,i), collab, ...collabs.slice(i+1)]);
  }

  const setCollabNameError = (i, error) => {
    let collab = collabs[i];
    collab.name_error = error;

    setCollabs([...collabs.slice(0,i), collab, ...collabs.slice(i+1)]);
  }

  const removeCollabState = (i, state) => {
    let collab = collabs[i];
    let index = collab.states.indexOf(state);

    if(index != -1) {
      collab.states.splice(index, 1);
    }

    setCollabs([...collabs.slice(0,i), collab, ...collabs.slice(i+1)]);
  }

  const clearCollabStates = (i) => {
    let collab = collabs[i];
    collab.states = [];
    collab.states_error = null;

    setCollabs([...collabs.slice(0,i), collab, ...collabs.slice(i+1)]);
  }

  let isProvider = (providerType == 0);
  let isTherapist = (providerType == 3);

  if(loading) {
    return (
      <div className={cx({ container: true })} style={{textAlign: 'center', marginTop: 40}}>
        <Spin size="large" />
      </div>
    );
  }

  const validateNPI = (rule, value) => {
    if (value && value !== 'N/A' && !/^[0-9]{10}$/.test(value)) {
      return Promise.reject('Invalid NPI number');
    }
    return Promise.resolve();
  };

  const addNewLocation = () => {
    setLocations([...locations, {}])
  }

  const editLocationsTab = (targetKey, action) => {
    if (action == 'add') {
      addNewLocation()
    }
  }

  const removeLocation = (index) => {
    if (locations[index].id && !locations[index].deletable) {
      alert("Can't delete location because it has pending appointments")
      return
    }
    setLocations(
      locations.filter((location, i)=>{
        return i !== index
      })
    )
  }

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>
          {item.id ? `Clinician Edit (${item.first_name} ${item.last_name})` : `Clinician Add`}
        </h3>
        <Space>
          <Button type="default" onClick={handleReturn}>
            Return
          </Button>
          <Button type="primary" onClick={handleOk}>
            Save
          </Button>
        </Space>
      </div>
      <Form
        form={form}
        initialValues={item}
        onValuesChange={onValuesChange}
        preserve={false}
        className={cx({ modalForm: true })}
        layout="vertical"
      >
        <div className={styles.section}>
          <Row gutter={16}>
            <Col span={4}>
              <ImgCrop rotate grid>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  // action={uploadFile}
                  accept="image/*"
                  customRequest={uploadFile}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  onPreview={onPreview}
                >
                  {avatarUrl ? (
                    <img src={avatarUrl} alt="avatar" style={{ width: '100%' }} />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </ImgCrop>
            </Col>
            <Col span={4}>
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[{ required: true, message: 'First Name is required' }]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[{ required: true, message: 'Last Name is required' }]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Gender"
                name="gender"
                rules={[{ required: true, message: 'Gender is required' }]}
              >
                <Select
                  options={[
                    {
                      value: 'male',
                      label: 'male',
                    },
                    {
                      value: 'female',
                      label: 'female',
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Title is required' }]}
              >
                <Input placeholder="Title"/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item
                label="Provider Type"
                name="doctor_type"
                rules={[{ required: true, message: 'Provider Type is required' }]}
              >
                <Select
                  placeholder="Select Provider Type"
                  onChange={(value) => {
                    setProviderType(value);
                    if(value===5){
                      form.setFieldsValue({support_states:'All States'});
                      form.setFieldsValue({npi:'N/A'});
                    }
                    if (value != 5) {
                      const supportStates = form.getFieldValue('support_states');
                      if (supportStates?.length > 0) {
                        const filteredSupportStates = supportStates.filter(state => state !== 'All States');
                        form.setFieldsValue({ support_states: filteredSupportStates });
                      }
                    }
                    if(value===5||value===3){
                      setSupportTherapy(true);
                    }else{
                      setSupportTherapy(false);
                    }
                  }}
                >
                  <Select.Option value={0}>Provider</Select.Option>
                  <Select.Option value={1}>Collab</Select.Option>
                  <Select.Option value={2}>Student</Select.Option>
                  <Select.Option value={3}>Therapist</Select.Option>
                  <Select.Option value={5}>ADHD Coach</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Job Type"
                name="job_type"
                rules={[{ required: true, message: 'Job Type is required' }]}
              >
                <Select
                  placeholder="Select Job Type"
                  onChange={(value)=>{
                    setJobType(value);
                  }}
                >
                  <Select.Option value={0}>Default</Select.Option>
                  <Select.Option value={1}>Part Time</Select.Option>
                  <Select.Option value={2}>Full Time</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Email is required' },
                  { type: 'email', message: 'Invalid Email' },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  { required: true, message: 'Phone is required' },
                  { pattern: '^[0-9]{3}[0-9]{3}[0-9]{4}$', message: 'Invalid Phone' },
                ]}
              >
                <Input placeholder="Phone" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Time Zone"
                name="time_zone"
                rules={[{ required: true, message: 'Time Zone is required' }]}
              >
                <Select placeholder="Time Zone">{timeZoneOpts}</Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item
                label="Zoom Personal Meeting ID"
                name="zoom_personal_meeting_id"
                normalize={(value) => value.replace(/\s+/g, '')}
                rules={[{
                  pattern: /^(?!^(http(s)?:)?\/\/)/,
                  message: 'Meeting ID should not be a URL'
                }]}
              >
                <Input controls={false}  placeholder="Zoom Personal Meeting ID" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Online Consultation URL" name="online_consultation_url">
                <Input placeholder="Online Consultation URL" />
              </Form.Item>
            </Col>
            {!timeplanFlag && (<Col span={6}>
              <Form.Item
                label="External Calendar Link"
                name="external_calendar_link"
                rules={[{ required: isProvider, message: 'External Calendar Link is required' }]}
              >
                <Input placeholder="External Calendar Link" />
              </Form.Item>
            </Col>)}

          </Row>

          <Row gutter={16}>
            <Col span={4}>
              <Form.Item
                label="Slack Support Channel ID"
                name="slack_support_channel_id"
                normalize={(value) => value.replace(/\s+/g, '')}
              >
                <Input placeholder="Slack Support Channel ID" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Urgent To-Do List Link" name="task_url">
                <Input placeholder="Urgent To-Do List Link" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="NPI"
                name="npi"
                rules={[
                  { validator: validateNPI }
                ]}
              >
                <Input placeholder="NPI" disabled={providerType===5}/>
              </Form.Item>
            </Col>
            <Col span={6}>
              {
                providerType != 5 && (<Form.Item
                  label="Medical License/Furnishing Number"
                  name="medical_licenses"
                >
                  <Select
                    placeholder="Medical License/Furnishing Number"
                    mode="tags"
                  />
                </Form.Item>)
              }
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              {
                providerType === 5 && (
                  <Form.Item label="States Supported" name="support_states" defaultValue='All States'>
                    <Input placeholder="All States" defaultValue='All States' disabled />
                  </Form.Item>
                )}
              {
                providerType != 5 && (
                  <Form.Item label="States Supported" name="support_states">
                    <Select placeholder="States Supported" mode="multiple">
                      {stateOpts}
                    </Select>
                  </Form.Item>
                )}
            </Col>
            <Col span={8}>

              <Form.Item label="Doctor Tag" name="doctor_tags">
                <Select placeholder="Doctor Tag"  mode="multiple" allowClear>
                  {tagsOpts}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
            {providerType != 5 && (<Form.Item
                label="PA"
                name="pa"
              ><Input placeholder="PA" /></Form.Item>)}
              {providerType === 5 && (<Form.Item
                label="PA"
                name="pa"
              ><TextArea placeholder="PA" /></Form.Item>)}
            </Col>
          </Row>
          {(providerType === 5 || providerType == 3) && <>
              <Row gutter={16}>
                <Col span={8}>                  
                  <Form.Item
                    label="Professional Background"
                    name="professional_background"
                  >
                    <TextArea placeholder="Professional Background" showCount maxLength={1500} />
                  </Form.Item>
                </Col>
                <Col span={8}>                  
                  <Form.Item
                    label="Expertise"
                    name="expertise"
                  >
                    <TextArea placeholder="Expertise" showCount maxLength={1500} />
                  </Form.Item>
                </Col>
                <Col span={8}>                  
                  <Form.Item
                    label="Provider Bio"
                    name="provider_bio"
                  >
                    <TextArea placeholder="Provider Bio" showCount maxLength={1500} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          }

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="PRM"
                name="prm"
              >
                <Input placeholder="PRM" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Scheduling URL"
                name="scheduling_url"
              >
                <Input placeholder="Scheduling URL" />
              </Form.Item>
            </Col>
          </Row>

          {form.getFieldValue('doctor_type') == 0 &&
            <Row gutter={16}>
              <Col span={24}>
                <Space style={{color: 'black', padding: '0 0 8px'}}>
                  Supervising Clinicians
                </Space>
                <div style={{marginBottom: '24px'}}>
                  {collabs.length == 0 &&
                    <Row gutter={16} style={{paddingBottom: '10px', paddingLeft: '8px'}}>
                      <Button onClick = {addRow}>
                        + Add Supervising Clinician
                      </Button>
                    </Row>
                  }
                  {collabs.map((collab, i) => {
                    return (
                      <Form.Item key={collab.id ?? collab.key} label="" name={`supervising_doctor_${i}`}>
                        <Row gutter={16}>
                          <Col span={8}>
                            <Select
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option != null && (option.children).toLowerCase().includes(input.toLowerCase())
                              }
                              placeholder="Select MD"
                              onSelect={(did) => setCollab(i, did)}
                              style={collab.name_error ? {border: "1px solid red", borderRadius: "3px"} : {}}
                              value={collab.id}
                            >
                              {filteredDoctorOpts(collab, collabs)}
                            </Select>
                            {collab.name_error &&
                              <span style={{color: 'red'}}>{collab.name_error}</span>
                            }
                          </Col>
                          <Col span={6}>
                            <Select
                              placeholder="States Supported"
                              mode="multiple"
                              defaultValue={collab.states}
                              onSelect={(state) => setCollabStates(i, state)}
                              onDeselect={(state) => removeCollabState(i, state)}
                              onClear={() => clearCollabStates(i)}
                              style={collab.states_error ? {border: "1px solid red", borderRadius: "3px"} : {}}
                              allowClear
                            >
                              {stateOpts}
                            </Select>
                            {collab.states_error &&
                              <span style={{color: 'red'}}>{collab.states_error}</span>
                            }
                          </Col>
                          <Col span={10}>
                            <Space size={16}>
                              <Button onClick = {() => deleteRow(i)} danger type="dashed">
                                Delete
                              </Button>
                              { i == collabs.length - 1 &&
                                <Button onClick = {addRow} >
                                  + Add Row
                                </Button>
                              }
                            </Space>
                          </Col>
                        </Row>
                      </Form.Item>
                    );
                  })}
                </div>
              </Col>
            </Row>
          }
          <Row gutter={12}>
            <Col span={3}>
              <Form.Item
                name="start_date"
                label="Start Date"
                format="L"
                rules={[
                ]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                name="end_date"
                label="Last Day"
                format="L"
                rules={[
                ]}
                tooltip={"When a provider sets their last day, the system will remove all inactive patients associated with that provider."}
              >

                <DatePicker/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item
                label="Auto Calendar"
                name="timeplan_flag"
                valuePropName="checked"
                tooltip={"Use acuity if closed"}

              >
                <Switch
                  disabled={!enableOpenNewAcuity || originTimeplanFlag}
                  onChange={(v) => {
                    setTimeplanFlag(v);
                    if (!v&&acuityBak) {
                      form.setFieldsValue({
                        external_calendar_id: acuityBak.external_calendar_id,
                        external_appointment_type_id:acuityBak.external_appointment_type_id,
                        external_follow_up_appointment_type_id:acuityBak.external_follow_up_appointment_type_id,
                        external_paid_follow_up_type_id:acuityBak.external_paid_follow_up_type_id,
                        external_pediatric_consultation_type_id: acuityBak.external_pediatric_consultation_type_id,
                        external_pediatric_follow_up_type_id:acuityBak.external_pediatric_follow_up_type_id,
                        external_insomnia_consultation_type_id: acuityBak.external_insomnia_consultation_type_id,
                        external_insomnia_follow_up_type_id:acuityBak.external_insomnia_follow_up_type_id,
                        external_initial_therapist_evaluation_45_mins_type_id: acuityBak.external_initial_therapist_evaluation_45_mins_type_id,
                        external_initial_therapist_evaluation_30_mins_type_id:acuityBak.external_initial_therapist_evaluation_30_mins_type_id,
                        external_therapy_session_45_mins_type_id:acuityBak.external_therapy_session_45_mins_type_id,
                        external_therapy_session_30_mins_type_id:acuityBak.external_therapy_session_30_mins_type_id,
                      });
                    }

                  }}
                  checkedChildren="on"
                  unCheckedChildren="off"
                  defaultChecked={timeplanFlag}
                />
              </Form.Item>
            </Col>
            {!timeplanFlag && (
            <Col span={4}>
              <Form.Item
                label="External Calendar ID"
                name="external_calendar_id"
                rules={[{ required: isProvider || isTherapist, message: 'External Calendar ID is required' }]}

              >
                <Input placeholder="External Calendar ID" />
              </Form.Item>
            </Col>
            )}
            {!timeplanFlag && (
            <Col span={4}>
              <Form.Item
                label="External Appointment(25min) Type ID"
                name="external_appointment_type_id"
                rules={[
                  { required: isProvider, message: 'External Appointment(25min) Type ID is required' },
                ]}
              >
                <Input placeholder="External Appointment(25min) Type ID" />
              </Form.Item>
            </Col>
            )}
            {!timeplanFlag && (
            <Col span={4}>
              <Form.Item
                label="External Appointment(50min) Type ID"
                name="external_appointment_50min_type_id"
                rules={[
                  { required: isProvider, message: 'External Appointment(50min) Type ID is required' },
                ]}
              >
                <Input placeholder="External Appointment(50min) Type ID" />
              </Form.Item>
            </Col>
            )}
            {!timeplanFlag && (
            <Col span={4}>
              <Form.Item
                label="External FollowUp Appointment Type ID"
                name="external_follow_up_appointment_type_id"
                rules={[
                  {
                    required: isProvider,
                    message: 'External FollowUp Appointment Type ID is required',
                  },
                ]}
              >
                <Input placeholder="External FollowUp Appointment Type ID" />
              </Form.Item>
            </Col>
            )}
            {!timeplanFlag && (
            <Col span={4}>
              <Form.Item
                label="External Paid FollowUp(25min) Type ID"
                name="external_paid_follow_up_type_id"
              >
                <Input placeholder="External Paid FollowUp(25min) Type ID" />
              </Form.Item>
            </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item
                label="Pediatric Supported"
                name="support_pediatric"
                valuePropName="checked"
              >
                <Switch
                  onChange={(v) => {
                    setSupportPediatric(v);
                  }}
                  checkedChildren="on"
                  unCheckedChildren="off"
                />
              </Form.Item>
            </Col>
            {!timeplanFlag && (
            <Col span={6}>
              <Form.Item
                label="Pediatric Consultation(50min) Type ID"
                name="external_pediatric_consultation_type_id"
              >
                <Input
                  disabled={!supportPediatric}
                  placeholder="Pediatric Consultation(50min) Type ID"
                />
              </Form.Item>
            </Col>
            )}
            {!timeplanFlag && (
            <Col span={6}>
              <Form.Item
                label="Pediatric Follow-up(25min) Type ID"
                name="external_pediatric_follow_up_type_id"
              >
                <Input
                  disabled={!supportPediatric}
                  placeholder="Pediatric Follow-up(25mins) Type ID"
                />
              </Form.Item>
            </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item
                label="Insomnia Supported"
                name="support_insomnia"
                valuePropName="checked"
              >
                <Switch
                  onChange={(v) => {
                    setSupportInsomnia(v);
                  }}
                  checkedChildren="on"
                  unCheckedChildren="off"
                />
              </Form.Item>
            </Col>
            {!timeplanFlag && (
            <Col span={6}>
              <Form.Item
                label="Insomnia Consultation(40min) Type ID"
                name="external_insomnia_consultation_type_id"
              >
                <Input
                  disabled={!supportInsomnia}
                  placeholder="Insomnia Consultation(40min) Type ID"
                />
              </Form.Item>
            </Col>
            )}
            {!timeplanFlag && (
            <Col span={6}>
              <Form.Item
                label="Insomnia Follow-up(15min) Type ID"
                name="external_insomnia_follow_up_type_id"
              >
                <Input
                  disabled={!supportInsomnia}
                  placeholder="Insomnia Follow-up(15mins) Type ID"
                />
              </Form.Item>
            </Col>
             )}
          </Row>
          <Row gutter={16}>
            {!timeplanFlag && (
            <Col span={5}>
              <Form.Item
                label="Initial ADHD Coaching and Therapy Evaluation Min ID"
                name="external_initial_therapist_evaluation_45_mins_type_id"
              >
                <Input
                  disabled={!supportTherapy}
                  placeholder="Initial ADHD Coaching and Therapy Evaluation 45 Min ID"
                />
              </Form.Item>
            </Col>
            )}
            {!timeplanFlag && (
            <Col span={5}>
              <Form.Item
                label="Initial ADHD Coaching and Therapy Evaluation 30 Min ID"
                name="external_initial_therapist_evaluation_30_mins_type_id"
              >
                <Input
                  disabled={!supportTherapy}
                  placeholder="Initial ADHD Coaching and Therapy Evaluation 30 Min ID"
                />
              </Form.Item>
            </Col>
            )}
            {!timeplanFlag && (
            <Col span={5}>
              <Form.Item
                label="ADHD Coaching and Therapy Session(45min) Type ID"
                name="external_therapy_session_45_mins_type_id"
              >
                <Input
                  disabled={!supportTherapy}
                  placeholder="ADHD Coaching and Therapy Session(45min) Type ID"
                />
              </Form.Item>
            </Col>
            )}
            {!timeplanFlag && (
            <Col span={5}>
              <Form.Item
                label="ADHD Coaching and Therapy Session 30 Min ID"
                name="external_therapy_session_30_mins_type_id"
              >
                <Input
                  disabled={!supportTherapy}
                  placeholder="ADHD Coaching and Therapy Session 30 Min ID"
                />
              </Form.Item>
            </Col>
            )}
          </Row>
          <Form.Item
            label="Accepted Transfer Patients"
            name="accept_transfer_patients"
            valuePropName="checked">
            <Switch
              checkedChildren="on"
              unCheckedChildren="off"
            />
          </Form.Item>
          <Form.Item
            label="Accept New Patients"
            name="accept_new_patients"
            valuePropName="checked">
            <Switch
              checkedChildren="on"
              unCheckedChildren="off"
            />
          </Form.Item>
          <Form.Item
            label="Agree to Provide Coverage for Other Providers and Accepts Coverage from Them"
            name="accept_co_services"
            valuePropName="checked">
            <Switch
              checkedChildren="on"
              unCheckedChildren="off"
            />
          </Form.Item>
          <Form.Item style={{marginTop:'-20px'}}>
          <p>When the toggle is turned on, the provider can give coverage for providers who are unable to renew prescriptions or reply to consultation messages within 24 hours, and receive coverage by another provider.</p>
          </Form.Item>
          {jobType != 2 && (
            <Form.Item
              label="Supports withdrawals via EHR"
              name="enable_payout_flag"
              valuePropName="checked">
              <Switch
                checkedChildren="on"
                unCheckedChildren="off"
              />
            </Form.Item>
          )}
          {[0, 1].includes(providerType) && (
            <Form.Item
              label="Chart Audit"
              name="enable_chart_audit"
              valuePropName="checked">
              <Switch
                checkedChildren="on"
                unCheckedChildren="off"
              />
            </Form.Item>
          )}
        </div>

        <div className={styles.RXNTAccounts}>
          <h3>RXNT Accounts</h3>
          <RxntAccountEdit form={form} selectStates={selectStates} />
        </div>

        <div className={styles.providerCompensation}>
          <h3>Provider Compensation</h3>
          <ProviderIncomeEdit form={form} />
        </div>

        <div className={styles.doctorHomeLocation}>
          <h3>Doctor Home Location</h3>
          <Row gutter={16}>
                <Col span={4}>
                  <Form.Item
                    label="State"
                    name="state"
                    rules={[{ required: true, message: 'City is required' }]}
                  >
                    <Select placeholder="State">{stateOpts}</Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[{ required: true, message: 'City is required' }]}
                  >
                    <Input placeholder="City" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="Zip Code"
                    name="zipcode"
                    rules={[
                      { required: true, message: 'Zipcode is required' },
                      // { pattern: '^[0-9]{5}$', message: 'Invalid Zipcode' },
                    ]}
                  >
                    <Input placeholder="Zip Code" />
                  </Form.Item>
                </Col>
            </Row>
        </div>
        <div className={styles.doctorLocations}>
        <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label="In-person Supported"
                name="support_in_person"
                valuePropName="checked"
              >
                <Switch
                  onChange={(v) => {
                    setSupportInPerson(v);
                  }}
                  checkedChildren="on"
                  unCheckedChildren="off"
                />
              </Form.Item>
            </Col>
            </Row>
          <Tabs
            type="editable-card"
            hideAdd={false}
            onEdit={editLocationsTab}
            closable='false'
          >
            {locations.map((location, index)=> {
              return (
                <Tabs.TabPane
                  forceRender={true}
                  tab={"Location " + (index + 1)}
                  key={"key-"  + (index + 1)}
                  closeIcon={<></>}
                >
                  <DoctorLocation form={form} index={index} location={location} deleteable={locations.length > 1} remove={removeLocation} support_in_person={supportInPerson}/>
                </Tabs.TabPane>
              )
            })}
          </Tabs>
        </div>
      </Form>
    </div>
  );
};

export default DoctorEdit;
