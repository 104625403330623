import React, { useState, useEffect } from 'react';
import { Tooltip, Checkbox, Form, Input, Button, Row, Col } from 'antd';
import cn from 'classnames/bind';
import moment from 'moment-timezone';

import styles from './index.less';
const cx = cn.bind(styles);

const CheckboxGroupFilter = (props) => {
  const { form, name, label, items, hasError } = props;
  const defaultVisible = !!form.getFieldValue(name);
  const [visible, setVisible] = useState(defaultVisible);

  useEffect(() => {
    setVisible(defaultVisible);
  }, [defaultVisible]);

  props.reset$.useSubscription(() => {
    setVisible(false);
  });

  return (
    <div className={cx({ filtersItem: true })}>
      <div className={cx({ filtersItemSwitch: true })}>
        <Checkbox
          checked={visible}
          onChange={(v) => {
            setVisible(v.target.checked);
          }}
        >
          {label}
        </Checkbox>
      </div>
      <div className={cx({ filtersItemBox: true, visible: visible })}>
        {visible && (
          <Form.Item
            name={name}
            valuePropName="value"
            className={cx({ checkboxlist: true })}
            rules={[{ required: true }]}
          >
            <Checkbox.Group name={name} options={items} />
          </Form.Item>
        )}
      </div>
    </div>
  );
};

export default CheckboxGroupFilter;
