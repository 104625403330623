import React, { useEffect, useState } from 'react';
import { Button, message, Spin  } from 'antd';
import cn from 'classnames/bind';
import styles from './WalletAccountList.css';
const cx = cn.bind(styles);
import './WalletAccountSettingForm.css';
import { srvDeleteConnect,srvDoctorAccounts } from "@/services/account";
import { useRequest } from "ahooks";
const WalletAccountList = (props) => {
    const { toEditAccount } = props;
    const [accounts, setAccounts] = useState([]);
    useEffect(() => {
        if(accounts.length===0){
            loadDoctorAccountsReq.run();
        }
    }, [accounts]);
    const editAccount = (type,connectID,can_update_flag) => {
        if (!can_update_flag) {
            message.warning('Your account cannot be edited due to an ongoing withdrawal');
            return;
        }
        toEditAccount(type,connectID);
    }
    const remove = (connectID,can_update_flag) => {
        if(!can_update_flag){
            message.warning('Your account cannot be deleted due to an ongoing withdrawal');
            return;
        }
        if (window.confirm('Are you sure you want to remove this account?')) {
            deleteConnectReq.run({ connect_id: connectID });
        }
    }
    const deleteConnectReq = useRequest(srvDeleteConnect, {
        manual: true,
        onSuccess: (ret) => {
            if (ret.success) {
                message.success('Remove successfully');
                loadDoctorAccountsReq.run();
            } else {
                message.error(ret.message);
            }
        },
        onError: (error) => {
            message.error(error.message);
        }
    });
    const loadDoctorAccountsReq = useRequest(srvDoctorAccounts, {
        manual: true,
        onSuccess: (ret, params) => {
            if (ret.success) {
                if (ret.data.length > 0) {
                    setAccounts(ret.data);
                }
            }
        },
    });
    const AccountItem = ({ account}) => (
        <div className={cx('content')}>
            <div className={cx('account-info')}>
                <span className={cx('bank-number')}>
                    {'*******' + (account?.bank_account?.slice(-3) || '')}
                </span>
            </div>
            <div className={cx('account-type')}>{account?.account_type} Account</div>
            <div className={cx('actions')}>
                <Button type="link" onClick={() => editAccount(account?.account_type,account?.connect_id,account?.can_update_flag)} className={cx('edit-btn')}>Edit</Button>
                <Button type="link" onClick={() => remove(account?.connect_id,account?.can_update_flag)} className={cx('edit-btn')}>Remove</Button>
            </div>
        </div>
    );
    return (<>
        <Spin spinning={loadDoctorAccountsReq.loading} tip="Loading...">
            {accounts.map(account => (
                <AccountItem key={account.connect_id} account={account} />
            ))}
        </Spin>
    </>
    );

};

export default WalletAccountList;
