import { stringify } from 'querystring';
import { history } from 'umi';
import { message } from 'antd';
import { srvPayments, srvIntStatusItems, srvPaymentItem, srvRefund } from '@/services/admin';

const Model = {
  namespace: 'payment',
  state: {
    data: {
      total: 0,
      items: [],
    },
    payment_products: [],
    payment_statuses: [],
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const res = yield call(srvPayments, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveList', payload: res.data });
    },
    *fetchPaymentProducts({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'product_id' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'savePaymentProducts', payload: res.data });
    },
    *fetchPaymentStatuses({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'payment_status' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'savePaymentStatuses', payload: res.data });
    },
    *refundPayment({ payload, callback }, { call, put, select }) {
      const res = yield call(srvRefund, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *fetchPaymentItem({ payload }, { call, put, select }) {
      const res = yield call(srvPaymentItem, { id: payload.id });
      if (!res.success) {
        return;
      }
      const { data } = yield select((state) => state.payment);

      for (let i = 0; i < data.items.length; i++) {
        if (res.data.id === data.items[i].id) {
          data.items[i] = res.data;
        }
      }

      yield put({ type: 'saveList', payload: { total: data.total, items: [...data.items] } });
    },
  },
  reducers: {
    saveList(state, { payload }) {
      return { ...state, data: payload };
    },
    savePaymentProducts(state, { payload }) {
      return { ...state, payment_products: payload };
    },
    savePaymentStatuses(state, { payload }) {
      return { ...state, payment_statuses: payload };
    },
  },
};
export default Model;
