import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Badge, Form, Dropdown, Button, Checkbox} from 'antd';

import { useEventEmitter } from 'ahooks';

import cn from 'classnames/bind';
import styles from './CheckboxFilter.less';
const cx = cn.bind(styles);

const Filters = forwardRef((props, ref) => {
  const { title, name, filters, initialFilters } = props;
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [count, setCount] = useState(0);
  const toggleVisible = () => {
    setVisible(!visible);
  };

  if (props.reset$) {
    props.reset$.useSubscription(() => {
      reset();
    });
  }

  const reset$ = useEventEmitter();

  const itemsDom = () => {
    return (
      <div className={cx({ checkboxFiltersItemBox: true, visible: visible })}>
        <Form.Item
          name={name}
          valuePropName="value"
          className={cx({ checkboxFiltersList: true })}
        >
          <Checkbox.Group name={name} options={filters} />
        </Form.Item>
      </div>
    )
  };

  const onFinish = (values) => {
    console.log('onFinish:', values)
    const data = collectFilters(values);
    setCount(data.filterCount);
    props.onFilter && props.onFilter(data.query);
    setVisible(false);
  };

  const collectFilters = (values) => {
    var query = {};
    let filterCount = 0;
    if (values[name] && values[name].length) {
      query[name] = values[name];
      filterCount = values[name].length;
    }
    return {
      query,
      filterCount,
    };
  };

  const onFinishFailed = (errorInfo) => { };

  const reset = () => {
    form.resetFields();
    reset$.emit();
    setCount(0);
    props.onFilter && props.onFilter({});
  };

  const onVisibleChange = (v) => {
    setVisible(v);
  };

  useImperativeHandle(ref, () => ({
    setFilters: (fieldsValue) => {
      form.setFieldsValue(fieldsValue);
      onFinish(fieldsValue);
    },
  }));

  useEffect(() => {
    if (initialFilters) {
      form.setFieldsValue(initialFilters);
      const data = collectFilters(initialFilters);
      setCount(data.filterCount);
    }
  }, [initialFilters]);

  return (
    <div className={cx({ checkboxFilters: true })}>
      <Dropdown
        visible={visible}
        onVisibleChange={onVisibleChange}
        forceRender
        overlay={
          <div className={cx({ checkboxFiltersDropdown: true })}>
            <Form layout="vertical" form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <div className={cx({ checkboxFiltersHeader: true })}>{title}</div>
              <div className={cx({ checkboxFiltersContent: true })}>{itemsDom()}</div>
              <div className={cx({ checkboxFiltersFooter: true })}>
                <Button size="small" type="text" onClick={reset} style={{ paddingLeft: 0 }}>
                  Reset
                </Button>
                <Button size="small" type="primary" htmlType="submit">
                  Ok
                </Button>
              </div>
            </Form>
          </div>
        }
        trigger={['click']}
      >
        <Badge count={count}>
          <Button onClick={toggleVisible}>Filters</Button>
        </Badge>
      </Dropdown>
    </div>
  );
});
Filters.defaultProps = {
  filters: [],
};
export default Filters;
