import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Empty, Form, Input, message, Modal, Spin} from 'antd';
import cn from 'classnames/bind';
import moment from 'moment';

import styles from './Medications.less';
import {useRequest} from "ahooks";
import {srvRxntActiveMedList} from "@/services/patient";
import {useActiveMedsStore} from "@/stores/useActiveMedsStore";
import {useSelector} from "umi";



const cx = cn.bind(styles);

const OverrideModal = ({
                         item = {},
                         states = [],
                         onOk,
                         patientId,
                         enableV2,
                         ...modalProps
                       }) => {
  const [form] = Form.useForm();
  const [activeMeds, setActiveMeds] = useState([]);

  const historyReq = useRequest(srvRxntActiveMedList, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setActiveMeds(ret.data);
      } else {
        message.error(ret.msg);
      }
    },
  });

  useEffect(async () => {
    await historyReq.run({user_id: patientId});
  }, [patientId]);

  useEffect(() => {
    if (activeMeds.length > 0) {
      form.setFieldsValue({
        medications: activeMeds.map(m => ({
          nextRefillDate: calculateNextRefillDate(m)
        }))
      });
    }
  }, [activeMeds]);

  const calculateNextRefillDate = (medication) => {
    var medItem = activeMeds.find(m => m.id === medication.id);
    if (!medItem) return null;
    if (medItem.override_next_refill_date > 0) {
      return moment.unix(medItem.override_next_refill_date);
    } else if (medItem.next_refill_date > 0) {
      return moment.unix(medItem.next_refill_date);
    } else {
      return moment();
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (values.next_refill_date) {
          values.next_refill_date = moment(values.next_refill_date).unix();
          values.override_next_refill_date = values.next_refill_date;
        }
        onOk(values);
      })
      .catch((errorInfo) => {});
  };

  const handleOkV2 = () => {
    form
      .validateFields()
      .then((values) => {
        const transformedValues = {
          user_id: patientId,
          items: values.medications
            .map(m => {
              return {
                med_id: m.id,
                next_refill_date: calculateNextRefillDate(m).unix(),
                override_next_refill_date: m.nextRefillDate ? m.nextRefillDate.unix() : 0,
              };
            })
            .filter(Boolean)
        };
        onOk(transformedValues);
        historyReq.run({ user_id: patientId });
      })
      .catch((errorInfo) => {
        console.log('Failed:', errorInfo);
      });
  };

  const modalOpts = {
    ...modalProps,
    onOk: enableV2 ? handleOkV2 : handleOk,
  };

  const initialValues = {
    medications: activeMeds.map(m => ({
      nextRefillDate: calculateNextRefillDate(m),
    }))
  };

  const data = {};
  if (item.next_refill_date) {
    data.next_refill_date = moment.unix(item.next_refill_date);
  }

  const validateDate = (value) => {
    if (!value) return false;
    const date = moment(value);
    const today = moment().startOf('day');
    return date.isSameOrAfter(today);
  };
  if (enableV2) {
    return (
      <Modal
        {...modalOpts}
        title="Modify next refill date for medications"
        className={cx({overrideModal: true})}
        width={700}
        okButtonProps={{
          disabled: activeMeds.length === 0,
          ...modalOpts.okButtonProps
        }}
        footer={
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 24px'
          }}>
            <Button onClick={modalOpts.onCancel} type="link">
              {modalOpts.cancelText || 'Cancel'}
            </Button>
            <Button
              type="primary"
              onClick={modalOpts.onOk}
              disabled={activeMeds.length === 0}
              {...modalOpts.okButtonProps}
            >
              {modalOpts.okText || 'OK'}
            </Button>
          </div>
        }
      >
        <div>
          <p>A patient can request a prescription renewal up to 3 days before their next medication refill date.</p>
          <p>
            If the medication is no longer needed, please update the patient's active medication in{' '}
            <a
              href={`/api/ehr/rxnt/sso?return_url=${btoa(window.location.href)}&patient_id=${patientId}&order_type=1`}
              style={{color: 'blue', textDecoration: 'underline'}}
            >
              RXNT
            </a>
          </p>
        </div>

        <Form
          form={form}
          initialValues={initialValues}
          preserve={false}
          className={cx({modalForm: true})}
          layout="vertical"
        >
          <Form.List name="medications">
            {(fields) => {
              // if (historyReq.loading) return <Spin />;
              if (fields.length === 0) return <Empty />;
              return (<table style={{width: '100%', margin: '16px 0'}}>
                <tbody>
                {fields.map(({key, name, ...restField}, index) => {
                  const medication = activeMeds[index];
                  return (
                    <tr key={medication?.id || index} style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 16,
                      marginBottom: 16
                    }}>
                      <Form.Item
                        name={[name, 'id']}
                        initialValue={medication?.id}
                        hidden
                        noStyle
                      >
                        <Input type="hidden"/>
                      </Form.Item>
                      <Form.Item
                        name={[name, 'drug']}
                        initialValue={medication?.drug?.name}
                        hidden
                        noStyle
                      >
                        <Input type="hidden"/>
                      </Form.Item>
                      <td style={{
                        flex: '0 0 auto',
                        minWidth: 300,
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        paddingRight: 16
                      }}>
                        {medication?.drug?.name}
                      </td>
                      <td style={{
                        flex: '0 1 auto',
                        minWidth: 200
                      }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'nextRefillDate']}
                          style={{marginBottom: 0}}
                          rules={[
                            ({getFieldValue}) => ({
                              validator(_, value) {
                                // compare with initial value, if not changed, return success
                                if (value && value.isSame(initialValues.medications[index].nextRefillDate)) {
                                  return Promise.resolve();
                                }
                                if (validateDate(value)) {
                                  return Promise.resolve();
                                }
                                return Promise.reject('Must be today or later.');
                              },
                            }),
                          ]}
                        >
                          <DatePicker
                            format="YYYY-MM-DD"
                            disabledDate={current => current && current.isBefore(moment().startOf('day'))}
                            allowClear
                          />
                        </Form.Item>
                      </td>
                    </tr>
                  );
                })}
                </tbody>
              </table>)
            }}
          </Form.List>
        </Form>
      </Modal>
    );
  } else {
    return (
      <Modal {...modalOpts} className={cx({ overrideModal: true })}>
        <div className={cx({ desc: true })}>
          Patient can request a prescription renewal 3 days before their next prescription fill date
        </div>
        <Form
          form={form}
          initialValues={data}
          preserve={false}
          className={cx({ modalForm: true })}
          layout="vertical"
        >
          <Form.Item
            name="next_refill_date"
            label="Next prescription fill Date"
            format="MM/DD/YYYY"
            rules={[
              {
                required: true,
                message: 'Next Refill Date is required',
              },
            ]}
          >
            <DatePicker   disabledDate={current => current && (current.isBefore(moment()) || current.isAfter(moment().add(3, 'months')))}/>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
};

export default OverrideModal;
