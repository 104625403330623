import React, { useState, useEffect, createRef, useRef, forwardRef, useImperativeHandle } from 'react';
import { useRequest } from 'ahooks';
import cn from 'classnames/bind';
import { connect, Link } from 'umi';
import { Space, Popover, Table, Tag, Layout, Menu, Button } from 'antd';

import { createFromIconfontCN } from '@ant-design/icons';
import { AsYouType } from 'libphonenumber-js';

import Filters from '@/components/Filters';
import defaultSettings from '../../../defaultSettings';

import ConsultationDrawer from '@/components/ConsultationDrawer';

import {
  srvConfirmConsultationReinstatement,
} from '@/services/admin';

import { formatUnix } from '../../utils/xtime';
import styles from './index.less';
const cx = cn.bind(styles);

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const { Sider, Content } = Layout;

// const patientReinstatementRef = createRef(null);

// const handleViewClick = (record) => {
//   patientReinstatementRef.current.selectRow(record);
// };

// const handleApproveClick = (record) => {
//   patientReinstatementRef.current.sendApprove(record);
// }

// const handleDenyClick = (record) => {
//   patientReinstatementRef.current.sendDeny(record);
// }

const filters = [
  {
    name: 'doctor_name',
    label: 'Doctor Name',
    type: 'text',
  },
  {
    name: 'reinstatement_status',
    label: 'Reinstatement status',
    type: 'checkbox',
    items: [{ "label": "Approved", "value": 1 }, { "label": "Denied", "value": 2 }, { "label": "Pending", "value": 0 }],
  }
];
var defaultFilters = {};



const headerColumns = [{
  title: 'Subject',
  dataIndex: 'title',
  key: 'title',
  ellipsis: true,
}];

const urgentColumns = [{
  title: 'Last Reply Date',
  dataIndex: 'last_reply_at',
  key: 'last_reply_at',
  ellipsis: true,
  width: 200,
  render: (v, r) => {
    return r.last_reply_at ? formatUnix(r.last_reply_at) : '';
  },
}];

const solvedColumns = [{
  title: 'Solve Date',
  dataIndex: 'solved_at',
  key: 'solved_at',
  ellipsis: true,
  width: 200,
  render: (v, r) => {
    return r.solved_at ? formatUnix(r.solved_at) : '';
  },
}]

const medicationColumns = [
  {
    title: 'Status',
    dataIndex: 'status_name',
    key: 'status_name',
    ellipsis: true,
    width: 150,
    render: (v, r) => {
      if (r.status === 1) {
        if (r.assigned_to) {
          return 'Assigned';
        } else if (r.reply_num > 0) {
          return 'Pending Assign';
        } else {
          return String(r.status_name)
            .toLowerCase()
            .replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
        }
      } else {
        return String(r.status_name)
          .toLowerCase()
          .replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
      }
    },
  },
];

const PatientReinstatement = forwardRef((props, ref) => {
  const { dispatch, consultation, loading } = props;
  const { data, consultation_types, consultation_statuses, replyData } = consultation;
  const [selected, setSelected] = useState('');
  const [selectedType, setSelectedType] = useState(-1);
  const [visible, setVisible] = useState(false);
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [messageTab, setMessageTab] = useState('0');
  const [currentTab, setCurrentTab] = useState('7');

  const ReinstatementStatus = {
    Default: 0,
    Approved: 1,
    Denied: 2,
  };



  const defaultColumns = [
    {
      title: 'Replies',
      dataIndex: 'reply_num',
      key: 'reply_num',
      ellipsis: true,
      width: 70,
    },
    {
      title: 'Clinician',
      dataIndex: 'doctor_id',
      key: 'doctor_id',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        if(!r.doctor){
          return '';
        }
        const userName = `${r.doctor.name} ${r.doctor.title}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/doctor/detail/${r.doctor.id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/doctor/detail/${r.doctor.id}`}>{r.doctor.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.doctor.phone)}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <a href="#">{userName}</a>
          </Popover>
        );
      },
    },
    {
      title: 'Sent Date',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status_name',
      key: 'status_name',
      ellipsis: true,
      width: 50,
      render: (v, r) => {
        if (r.reinstatement_status === ReinstatementStatus.Approved) {
          return <Tag color="green">Approved</Tag>;
        } else if (r.reinstatement_status === ReinstatementStatus.Denied) {
          return <Tag color="red">Denied</Tag>;
        } else {
          return <Tag color="blue">Pending</Tag>;
        }
      }
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return (
          <Space>
            <Button type="primary" onClick={() => { selectRow(r) }}>View</Button>
            {/* {r.reinstatement_status === ReinstatementStatus.Default && (
              <>
                <Button onClick={() => { sendApprove(r.id, "") }}>Approve</Button>
                <Button onClick={() => { sendDeny(r.id, "") }}>Deny</Button>
              </>

            ) || null} */}
          </Space>
        );
      },
    }
  ];



  const [columns, setColumns] = useState(defaultColumns);
  const [orderBy, setOrderBy] = useState({
    order_field: "",
    order_by: "",
  });
  var filtersEl = createRef(null);





  const reinstatementReq = useRequest(srvConfirmConsultationReinstatement, {
    manual: true,
    onSuccess: (ret, params) => {
      loadList({ status: parseInt(currentTab), page: 1, page_size: 20, order_field: orderBy.order_field, order_by: orderBy.order_by }, () => { });
      props.onReply()
    }
  })

  const sendDeny = (consultation_id, reason) => {
    reinstatementReq.run({
      consultation_id: consultation_id,
      status: ReinstatementStatus.Denied,
      reason: reason,
    })
  };

  const sendApprove = (consultation_id, reason) => {
    reinstatementReq.run({
      consultation_id: consultation_id,
      status: ReinstatementStatus.Approved,
      reason: reason,
    })
  };

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
    setSelected('');
  };

  const loadDetail = (v) => {
    setVisible(true);
  };

  const selectRow = (record) => {
    setSelected(record.id);
    setSelectedType(record.consultation_type);
    record.read = true;
    setVisible(true);
    dispatch({ type: 'consultation/fetchConsultationReplies', payload: { id: record.id } });
  };

  const sendMessage = () => { };

  const switchTab = (e) => {
    let column = [];
    if (e.key === '2') {
      column = urgentColumns;
    } else if (e.key === '4') {
      column = solvedColumns;
    }
    setCurrentTab(e.key);
    if (e.key === '5') {
      setColumns(headerColumns.concat(medicationColumns).concat(defaultColumns));
    } else {
      setColumns(headerColumns.concat(defaultColumns).concat(column));
    }
    loadList({ status: parseInt(e.key), page: 1, page_size: 20, filters: JSON.stringify(query), order_field: orderBy.order_field, order_by: orderBy.order_by }, () => { });
  }

  useEffect(() => {
    setPage(1);
    loadList({ status: parseInt(currentTab), page: 1, page_size: 20, order_field: orderBy.order_field, order_by: orderBy.order_by }, () => { });
    dispatch({ type: 'consultation/fetchConsultationTypes' });
    dispatch({ type: 'consultation/fetchConsultationStatuses' });
  }, []);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList({ status: parseInt(currentTab), page: 1, page_size: 20, filters: JSON.stringify(q), order_field: orderBy.order_field, order_by: orderBy.order_by }, () => { });
  };

  const loadList = (payload, callback) => {
    dispatch({ type: 'consultation/fetchList', payload, callback });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    var order_by = ''
    if (sorter && sorter.order) {
      order_by = sorter.order === "ascend" ? "ASC" : "DESC"
    }
    var order_field = ''
    if (sorter && sorter.field) {
      order_field = sorter.field
    }
    setOrderBy({
      order_field: order_field,
      order_by: order_by
    })
    loadList({
      status: parseInt(currentTab),
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: JSON.stringify(query),
      order_field: order_field,
      order_by: order_by
    });
  };

  const f = () => {
    for (const v of filters) {
      if (v.name === 'consultation_type' && v.items.length === 0) {
        v.items = consultation_types;
        continue;
      }
      if (v.name == 'status' && v.items.length === 0) {
        var temp = [
          { "value": 11, "label": "OPEN" }, { "value": 2, "label": "SOLVED" }, { "value": 3, "label": "CLOSED" }, { "value": 9, "label": "ASSIGNED" }, { "value": 10, "label": "PENDING ASSIGN" }
        ]
        v.items = temp;
        continue;
      }
    }
    return filters;
  };

  const reloadReplyData = () => {
    loadList({ status: parseInt(currentTab), page: 1, page_size: 20, filters: JSON.stringify(query), order_field: orderBy.order_field, order_by: orderBy.order_by }, () => { });
    dispatch({
      type: 'consultation/fetchConsultationReplies',
      payload: { id: replyData.consultation.id },
    });
  }

  return (
    <Layout>
      <Sider theme="light" style={{ display: 'none' }}>
        <Menu selectedKeys={[currentTab]} onClick={switchTab}>
          <Menu.Item key="7">Reinstatement Application</Menu.Item>
        </Menu>
      </Sider>
      <Content theme="light">
        <div className={cx({ container: true })}>
          <div className={cx({ header: true })}>
            <h3>Consultations</h3>
            <Space>
              {data && data.unreply_total > 0 && (
                <a onClick={() => { filtersEl.current.setFilters({ 'reply_num': 0 }) }}>{data.unreply_total} Tickets</a>
              )}
              <Filters ref={filtersEl} filters={f()} onFilter={onFilter} defaultFilters={defaultFilters} />
            </Space>
          </div>

          {selected != "" &&
            <ConsultationDrawer
              role="admin"
              visible={visible}
              replyData={replyData}
              onClose={onClose}
              selectedType={selectedType}
              onDeny={sendDeny}
              onApprove={sendApprove}
              onReply={() => { reloadReplyData() }}
            />
          }

          <Table
            columns={columns}
            dataSource={data.items}
            size={"small"}
            scroll={{ x: 800 }}
            rowClassName={(r) => {
              if (r.id == selected) {
                return 'rowSelected';
                // return styles.rowSelected;
              }
              if (r.read == false && r.assigned_to == null) {
                return styles.rowUnread;
              }
              return '';
            }}
            // onRow={(record) => ({
            //   onClick: () => {
            //     selectRow(record);
            //   },
            // })}
            rowKey="id"
            loading={loading.effects['consultation/fetchList']}
            onChange={handleTableChange}
            pagination={{ pageSize: 20, current: page, simple: true, total: data.total }}
          />
        </div>
      </Content>
    </Layout>
  );
});

export default connect(({ consultation, loading }) => ({
  consultation,
  loading,
}))(PatientReinstatement);
