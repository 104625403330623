import React, { useState } from 'react';
import { Form, Modal, Button, Space, Tooltip, Radio, Input, DatePicker, message } from 'antd';
import moment from 'moment';
const { TextArea } = Input;
import cn from 'classnames/bind';
import styles from './Medications.less';
import {
  srvSubmitRefillDispute
} from '@/services/patient';
import { useRequest } from 'ahooks';
const cx = cn.bind(styles);
const DisputeModal = (props) => {
  const {disputeVisible, closeDispute,request,fresh,approve,disputeForm, allowTransferApptInDispute, allowFollowUpApptInDispute } = props;
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [validNote, setValidNote] = useState(false);
  const sumitDispute = () => {
    disputeForm.validateFields().then((values) => {
      if(request.request_id){
        values.request_id = request.request_id;
      }else{
        values.request_id = request.id;
      }
      values.user_id = request.user_id;
      if(values.dispute_refill_date){
        values.dispute_refill_date=values.dispute_refill_date.unix();
      }
      subRefillDisputeReq.run(values);
    });
  };
  const subRefillDisputeReq= useRequest(srvSubmitRefillDispute, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        closeDispute(true);
        fresh();
      }
    },
  });
  return (
    <>
      <Modal visible={disputeVisible} onCancel={closeDispute} footer={null}>
        <p
          style={{
            color: '#000000',
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '20px',
          }}
        >
          Dispute Purpose
        </p>
        <p style={{ marginBottom: '10px', color: '#000000CC' }}>
          Please select the appropriate reason(s)
        </p>
        <Form form={disputeForm} preserve={false}>
          <Form.Item
            name="dispute_reason"
            rules={[
              {
                required: true,
                message: 'select the appropriate reason',
              },
            ]}
          >
            <Radio.Group onChange={(e) => {
              setValidNote(e.target.value === 5);
            }}>
              <Space direction="vertical">
                <Radio
                  value={1}
                  onChange={(e) => {
                    setShowDatePicker(false);
                    setShowContent(false);
                  }}
                >
                  Duplicate request or already issued refill
                </Radio>
                <Radio
                  value={2}
                  onChange={(e) => {
                    setShowDatePicker(true);
                    setShowContent(false);
                  }}
                >
                  Refill request made too early
                  <Tooltip
                    placement="top"
                    title="Before this dispute can be submitted 
          it is mandatory that the calendar date 
          is the correct refill due date."
                    className={cx({ tip: true })}
                  >
                    <span
                      style={{
                        marginLeft: '10px',
                        display: 'inline-flex',
                        width: '16px',
                        height: '16px',
                        borderRadius: '50%',
                        backgroundColor: '#E3EBFF',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '12px',
                        color: '#5469D4',
                      }}
                    >
                      ?
                    </span>
                  </Tooltip>
                </Radio>
                {showDatePicker && (
                  <div style={{display:'flex'}}>
                  <Form.Item
                    name="dispute_refill_date"
                    rules={[
                      {
                        required: true,
                        message: 'Select correct refill due date',
                      },
                    ]}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <DatePicker
                      style={{ width: '260px' }}
                      placeholder="Select correct refill due date"
                      disabledDate={(current) =>
                        current &&
                        (current.isBefore(moment()) || current.isAfter(moment().add(3, 'months')))
                      }
                    />
                  </Form.Item>
                  <Form.Item> <span style={{ color: 'red', marginLeft: '5px' }}>*</span></Form.Item>
                  </div>
                )}

                <Radio
                  value={3}
                  onChange={(e) => {
                    setShowDatePicker(false);
                    setShowContent(false);
                  }}
                >
                  Medical questions or needing guidance
                </Radio>

                {allowFollowUpApptInDispute && (
                  <Radio
                    value={6}
                    onChange={(e) => {
                      setShowDatePicker(false);
                      setShowContent(false);
                    }}
                  >
                    Follow-up appointment required
                  </Radio>
                )}

                {allowTransferApptInDispute && (
                  <Radio
                    value={7}
                    onChange={(e) => {
                      setShowDatePicker(false);
                      setShowContent(false);
                    }}
                  >
                    Transfer of care appointment required
                  </Radio>
                )}

                {showContent && (
                  <Form.Item style={{paddingLeft:'25px'}}>
                    <p style={{ color: '#999999' }}>
                      Ensure there is no gap in patient's treatment plan while waiting for the
                      upcoming appointment. Please send the correct gap prescription if possible.
                    </p>
                    <p style={{ color: '#999999', marginTop: '20px' }}>
                      <em>
                        *If the patient has not been seen in more than a year, consider if the
                        patient should be seen before a gap treatment is approved.
                      </em>
                    </p>
                  </Form.Item>
                )}
                <Radio
                  value={5}
                  onChange={(e) => {
                    setShowDatePicker(false);
                    setShowContent(false);
                  }}
                >
                  Other
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="dispute_note"             rules={[
              {
                required: validNote,
                message: 'Please enter a note',
              },
            ]}>
            <TextArea placeholder="Note" rows={4} style={{ borderRadius: '6px' }} />
          </Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              color: '#000000',
              marginTop: '10px',
            }}
          >
            <p style={{ color: '#566BCD', cursor: 'pointer' }} onClick={() => {
              closeDispute();
            }}>
              Cancel
            </p>
            <Button
              style={{ borderRadius: '5px' }}
              key="confirm"
              onClick={() => {
                if(disputeForm.getFieldValue('dispute_reason') === 4){
                  approve();
                  return;
                }
                sumitDispute();
              }}
              type="primary"
            >
              {disputeForm.getFieldValue('dispute_reason') === 4
                ? 'Prescribe in RXNT'
                : 'Submit Dispute'}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default DisputeModal;
