import {Button} from "antd";
import {history} from "umi";
import "./syncToGoogleCalendarBanner.css";
import {CloseOutlined} from "@ant-design/icons";
import {useState} from "react";

const SyncToGoogleCalendarBanner = () => {


    const [showTips, setShowTips] = useState(true);

    const handleClose = () => {
        setShowTips(false);
    };

    if (!showTips) {
        return null;
    }
    
    return (
        <div className="tips-box">
            <p className="tips-font">
                <span>Make sure you never miss an appointment – sync with Google Calendar now. </span>
                <Button className="apply-btn" type="link" onClick={() => {
                    history.push("/ehr_calendar?alert_google_sync=true");
                }}>Sync now</Button>
            </p>
            <CloseOutlined className="close-icon" onClick={handleClose} />
        </div>
        );
    }

export default SyncToGoogleCalendarBanner;