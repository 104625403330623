import React, { useState, useEffect } from 'react';
import { connect, Link } from 'umi';
import { useRequest } from 'ahooks';
import {Drawer, Form, Button, Input, Modal, Row, Col, Space, Switch, Table, message, Select, Dropdown} from 'antd';
import cn from 'classnames/bind';
import { useFlag } from '@unleash/proxy-client-react';

import {EditOutlined, DeleteTwoTone, ExclamationCircleOutlined, EllipsisOutlined} from '@ant-design/icons';
import { AsYouType } from 'libphonenumber-js';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import {srvAddTags, srvUpdateTags, srvDelTags} from '@/services/admin';

import styles from './index.less';

const Tags = (props) => {
  const [form] = Form.useForm();
  const cx = cn.bind(styles);
  const {
    dispatch,
    tags: { data },
    loading,
  } = props;
  const [selectedTag, setSelectedTag] = useState({});
  const [page, setPage] = useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const columns = [
    {
      title: 'Tag Name',
      dataIndex: 'tag_name',
      key: 'tag_name',
      ellipsis: true,
      width: 180,
    },
    {
      title: 'Tag Type',
      dataIndex: 'tag_type',
      key: 'tag_type',
      width: 180,
      render: (v, r) => {
        return r.tag_type == 'doctor' ? "Doctor" : "Patient";
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 50,
      render: (v, r) => {
        return (
          <Dropdown
            overlay={
              <div className={cx({ doctorOpt: true })}>
                <div className={cx({ doctorOptItem: true })}>
                  <a href="#" onClick={(ev) => handleDrawerOpen(r, ev)}>
                    Edit
                  </a>
                </div>
                <div className={cx({ doctorOptItem: true })} onClick={() => confirmDel(r)}>
                  <a href="#">Delete</a>
                </div>
              </div>
            }
            trigger={['click']}
          >
            <Button size="small">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const loadList = (payload, callback) => {
    dispatch({ type: 'tags/fetchList', payload, callback });
  };

  useEffect(() => {
    setPage(1);
    loadList({ page: 1, page_size: 20 }, () => {});
  }, []);


  const confirmDel = (r) => {
    Modal.confirm({
      title: 'Do you want to del these tags?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch({
          type: 'tags/deleteTags',
          payload: { id: r.id },
          callback: () => {
            loadList({ page, page_size: 20 }, () => {});
          },
        });
      },
      onCancel() {},
    });
  };

  const updateTags = useRequest(srvUpdateTags, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        message.success('update success');
        loadList({ page, page_size: 20 }, () => {});
      } else {
        message.error(ret.msg);
      }
    },
  });

  const handleDrawerOpen = (tag) => {
    form.resetFields();
    setIsDrawerOpen(true);

    if (tag && tag.id) {
      setSelectedTag(tag);
      form.setFieldsValue(tag);
    } else {
      setSelectedTag({});
      form.setFieldsValue({ name: '', email: '' });
    }
  };


  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadList({
      page: pagination.current,
      page_size: pagination.pageSize,
    });
  };

  const handleDrawerClose = () => {
    setSelectedTag({});
    setIsDrawerOpen(false);
  };

  const handleSave = async () => {
    form
      .validateFields()
      .then((values) => {
        if (selectedTag.id) {
          values.id = selectedTag.id;
          values.tag_type=selectedTag.tag_type;
        }

        if (values.id) {
          updateTags.run(values);
        } else {
          Promise.all(
            values.tags.map(async (tag) => {
              await srvAddTags({
                ...tag,
              });
            }),
          )
            .then(() => {
              message.success('Tag(s) successfully added');
              loadList({ page, page_size: 20 }, () => {});
            })
            .catch((err) => {
              console.error(err.message);
            });
        }

        handleDrawerClose();
      })
      .catch((errorInfo) => {});
  };

  const initialValues = selectedTag.id ? selectedTag : { tags: [{ tagName: '', tagType: '' }] };

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Tags</h3>
        <Space>
          <Button
            type="primary"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => handleDrawerOpen(null)}
          >
            Add
          </Button>
          <Drawer
            title={<h3>{selectedTag.id ? `Edit` : `Add a new Tag`}</h3>}
            width={400}
            onClose={handleDrawerClose}
            visible={isDrawerOpen}
          >
            <Form
              form={form}
              initialValues={initialValues}
              preserve={false}
              className={cx({ modalForm: true })}
              layout="vertical"
            >
              {selectedTag.id ? (
                <Form.Item
                  label="Tag Name"
                  name="tag_name"
                  rules={[{ required: true, message: 'Tag Name is required' }]}
                >
                  <Input placeholder="Tag Name" />
                </Form.Item>
              ) : (
                <Form.List name="tags">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Row key={key} gutter={16}>
                          <Col xs={24} sm={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'tag_name']}
                            rules={[{ required: true, message: 'Name is required' }]}
                          >
                            <Input placeholder="Tag Name" />
                          </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              {...restField}
                              name={[name, 'tag_type']}
                              rules={[{ required: true, message: 'type is required' }]}
                            >
                              <Select placeholder="Tag Type" >
                                <Select.Option value='doctor'>Doctor</Select.Option>
                                <Select.Option value='patient'>Patient</Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          </Row>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          className={cx({ addButton: true })}
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add field
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              )}
            </Form>

            <div className={cx({ footer: true })}>
              <Button onClick={handleDrawerClose}>Cancel</Button>
              <Button onClick={handleSave} type="primary">
                Save
              </Button>
            </div>
          </Drawer>
        </Space>
      </div>

      <Table
        className="rowSelectTable"
        columns={columns}
        dataSource={data.items}
        rowKey="id"
        loading={loading.effects['tags/fetchList']}
        onChange={handleTableChange}
        pagination={{
          pageSize: 20,
          current: page,
          simple: true,
          total: data.total,
        }}
      />
    </div>
  );
};

export default connect(({ tags, loading }) => ({
  tags,
  loading,
}))(Tags);
