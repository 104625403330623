import React, { useState, useEffect } from 'react';
import { Checkbox, Modal, Input, Form, Button, Select } from 'antd';
import { useRequest } from 'ahooks';
import { srvUpdatePatientStudentVerification, srvGetPatientStudentVerifiction } from '@/services/admin';

const StudentVerificationModal = ({ patientId, onOk, ...modalProps }) => {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});

  const onFinish = () => {
    form.validateFields().then((values) => {
      updateVerification.run({
        user_id: patientId,
        school: values.university,
        email: values.email,
        status: values.status
      })
    });
  };

  const modalOpts = {
    ...modalProps,
    onOk: onFinish,
  };

  const updateVerification = useRequest(srvUpdatePatientStudentVerification, {
    manual: true,
    onSuccess: (ret) => {
      onOk();
    },
  });

  const getVerification = useRequest(srvGetPatientStudentVerifiction, {
    manual: true,
    onSuccess: (ret) => {
      if (ret && ret.data) {
        setInitialValues({
          university: ret.data.school,
          email: ret.data.email,
          status: '' + ret.data.status
        })  
      }
    },
  });

  useEffect(() => {
    if (patientId) {
      getVerification.run({ user_id: patientId })
    }
  }, [patientId]);

  return (
    <Modal {...modalOpts} destroyOnClose={true} footer={''}>
      <h2 style={{marginBottom: '20px'}}>Student Information</h2>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="University"
          name="university"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Student Email"
          name="email"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Status" name="status">
          <Select>
            <Select.Option value="2">Unverified</Select.Option>
            <Select.Option value="1">Verified</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StudentVerificationModal;
