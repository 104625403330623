import React, { useState, useRef, useEffect } from 'react';

const DoneVideo = (props)=>{
  const { videoUrl } = props;

  const videoRef = React.useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    console.log('@@@',video)
    if(!video){
      return
    }
    video.currentTime = 60;

    const handleTimeUpdate = () => {
      const duration = video.duration;
      console.log('duration: ', duration);

      if (video.currentTime >= duration) {
        video.currentTime = 0;
      }
    };

    video.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);

  return (<>
     <video ref={videoRef} controls>
        <source src={videoUrl} type="video/mp4" />
      </video>
  </>)
}

export default DoneVideo;