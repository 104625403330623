import React, { useEffect, useState } from 'react';
import { Redirect, Helmet, Link, connect } from 'umi';
import { Badge, Result, Button } from 'antd';
import { useMount, useUnmount } from 'ahooks';
import { useFlagsStatus, useUnleashContext } from '@unleash/proxy-client-react';

import PageLoading from '@/components/PageLoading';
import Authorized from '@/utils/Authorized';
import { getAuthorityFromRouter } from '@/utils/utils';
import getMenuData from '@/utils/getMenuData';
import getPageTitle from '@/utils/getPageTitle';
import Layout from './Layout';
import AvatarDropdown from './AvatarDropdown';
import { useUserStore } from '@/stores';

const noMatch = (
  <Result
    status={403}
    title="403"
    subTitle="Sorry, you are not authorized to access this page."
    extra={
      <Button type="primary">
        <Link to="/user/login">Go Login</Link>
      </Button>
    }
  />
);

/**
 * use Authorized check all menu item
 */
const menuDataRender = (menuList) =>
  menuList.map((item) => {
    const localItem = { ...item, children: item.children ? menuDataRender(item.children) : [] };
    return Authorized.check(item.authority, localItem, null);
  });
const BasicLayout = (props) => {
  const {
    dispatch,
    children,
    location = {
      pathname: '/',
    },
    account,
  } = props;

  const { doctorStat, adminStat } = account;
  const { flagsReady, flagsError } = useFlagsStatus();
  const updateContext = useUnleashContext();
  const { setProfile } = useUserStore();

  const isDone = window.location.href.includes('donefirst.com');

  useMount(async () => {
    // if (account.currentUser.role == 'doctor') {
    //   dispatch({ type: 'account/fetchDoctorStat', payload: { id: account.currentUser.id } });
    // } else {
    //   dispatch({ type: 'account/fetchAdminStat' });
    // }
    setProfile();

    if (account?.currentUser?.user_classification && isDone) {
      window.location.href = String(window.location.href).replace('donefirst.com', 'getmindfulhealth.com');
      return;
    }

    await updateContext({
      userId: account.currentUser.id,
      properties: {
        name: account.currentUser.name,
        email: account.currentUser.email,
        role: account.currentUser.role,
      },
    });

    if (account.currentUser && window.freshpaint) {
      var state = [];
      if (account.currentUser.support_states) {
        state = account.currentUser.support_states.split(',');
      }
      window.freshpaint.identify({
        email: account.currentUser.email,
        name: account.currentUser.name,
        role: account.currentUser.role,
        state: state,
        created_at: account.currentUser.created_at,
        membership_status: null,
        membership_start_date: null,
        assigned_clinician: null,
      });
    }
  });

  // useUnmount(()=>{
  //   dispatch({ type: 'account/clearInterval' });
  // })

  const handleMenuCollapse = (payload) => {
    if (dispatch) {
      dispatch({
        type: 'global/changeLayoutCollapsed',
        payload,
      });
    }
  }; // get children authority

  const authorized = getAuthorityFromRouter(props.route.routes, location.pathname || '/') || {
    authority: undefined,
  };

  const { breadcrumb } = getMenuData(props.route.routes);
  const title = getPageTitle({
    pathname: location.pathname,
    breadcrumb,
    ...props,
  });

  if (account?.currentUser?.role == 'admin' && (location.pathname.indexOf('/ehr_') === 0 || location.pathname.indexOf('/overdue_patient') === 0)) {
    let uri = ''
    if (location.pathname.indexOf('/ehr_appointment') === 0) {
      uri = `${location.pathname.replace('/ehr_appointment', '/patient')}?tab=4`;
    } else if (location.pathname.indexOf('/ehr_renewal') === 0) {
      uri = `${location.pathname.replace('/ehr_renewal', '/patient')}?tab=3`;
    } else if (location.pathname.indexOf('/overdue_patient') === 0) {
      uri = `${location.pathname.replace('/overdue_patient', '/patient')}?tab=1`;
    }
    if (uri.length > 0) return <Redirect to={uri} />;
  }

  if (!flagsReady) {
    return <PageLoading />;
  }

  return (
    <Layout
      theme="light"
      onCollapse={handleMenuCollapse}
      collapsed={props.collapsed}
      breadcrumbRender={(routers = []) => [
        {
          path: '/',
          breadcrumbName: 'Done',
        },
        ...routers,
      ]}
      menuItemRender={(menuItemProps, defaultDom) => {
        if (menuItemProps.isUrl || menuItemProps.children || !menuItemProps.path) {
          return defaultDom;
        }
        return (
          <>
            <Link to={menuItemProps.path}>{defaultDom}</Link>
            {menuItemProps.itemPath == '/ehr_consultation' && (
              <Badge count={doctorStat.consulations_num} />
            )}
            {menuItemProps.itemPath == '/ehr_renewal' && <Badge count={doctorStat.requests_num} />}
            {menuItemProps.itemPath == '/ehr_extra_earn' && (
              <Badge count={doctorStat.appt_request_and_refill_num} />
            )}
            {menuItemProps.itemPath == '/appointment_request' && (
              <Badge count={adminStat.appointment_requests_num} />
            )}
            {menuItemProps.itemPath == '/consultation' && (
              <Badge count={adminStat.consulations_num} />
            )}
            {menuItemProps.itemPath == '/renewal/request' && (
              <Badge count={adminStat.cancallation_num} />
            )}
            {menuItemProps.itemPath == '/overdue_patient' && !!doctorStat.overdue_patients_num && (
              <Badge count={doctorStat.overdue_patients_num} />
            )}
            {menuItemProps.itemPath == '/ehr_medical_files' && (
              <Badge count={doctorStat.medical_file_request_num} />
            )}
            {menuItemProps.itemPath == '/ehr_chart_audit' && (
              <Badge count={doctorStat.chart_audits_num} />
            )}
            {menuItemProps.itemPath == '/review_approve' && (
              <Badge count={doctorStat.discharge_requests_num} />
            )}
            {menuItemProps.itemPath == '/medical_files' && (
              <Badge count={adminStat.medical_file_requests_num} />
            )}
          </>
        );
      }}
      menuDataRender={menuDataRender}
      rightContentRender={() => <AvatarDropdown menu={true} />}
      {...props}
    >
      <Authorized authority={authorized.authority} noMatch={noMatch}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={title} />
        </Helmet>
        {children}
      </Authorized>
    </Layout>
  );
};

export default connect(({ global, account, settings }) => ({
  collapsed: global.collapsed,
  account,
  settings,
}))(BasicLayout);
