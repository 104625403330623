import React from 'react';
import { Checkbox, Descriptions, Spin } from 'antd';
import { formatUnix } from '@/utils/xtime';
import isEmpty from 'lodash/isEmpty';
import {
  FAMILY_PLANNING,
  HYPERACTIVITY_IMPULSIVITY,
  INATTENTIVENESS,
  MOOD,
  OTHER_PSYCHIATRIC_SYMPTOMS,
  PSYCHIATRIC_HISTORY,
  REPORTED_MEDICAL_CONDITIONS,
  SUBSTANCE_USE,
  PRE_INITIAL_APPOINTMENT_START_REVIEW,
  PRE_INITIAL_APPOINTMENT_END_REVIEW,
} from '@/constants';

import {
  moodHxItems,
  familyMentalHealthItems,
  familyMedicalHistoryItems,
  medicalHistoryItems,
  psychiatricHistoryItems,
  socialHistoryItems,
  substanceUseHistoryItems,
  substanceUseHistoryFemaleItems,
  mseGeneralItems,
  mseSpeechItems,
  mseMoodAffectItems,
  mseThoughtProcessItems,
  mseThoughtContenItems,
  mseInsightJudgmentItems,
  riskItems,
  protectiveItems,
  harmOthersItems,
  timeSpentItems,
 } from '@/components/InitialEvaluationNote/meta';

import { CPTCodesMap } from '@/components/CPTCodesFormItem';
import { formatLastRefillDate } from '@/components/LastRefiiDateFormItem';
export default function NotesDetail({ data }) {

  const renderStructNoteV2 = (data) => {
    const notes = [
      {
        value: PRE_INITIAL_APPOINTMENT_START_REVIEW.filter((v) => data[v.value]).map((v) => v.label).join('\n')
      },
      {
        label: 'Chief Complaint:',
        value: `${data.chief_complaint_firstname} is a ${data.chief_complaint_age} y/o ${data.chief_complaint_gender} who presents for an initial psychiatric evaluation with chief complaints of ${data.chief_complaint}`
      },
      {
        value: `History of Present Illness: ${data.history_of_present_illness}
        Current psychiatric medications prescribed: ${data.currently_medications_prescribed}
        Current side effects reported: ${data.currently_side_effects_reported}
        Past medication trials: ${data.past_medication_trials}`
      },
      {
        label: 'Rating Scales Reviewed:',
        value: `ASRS Part A: ${data.asrsa}
          ASRS Part B: ${data.asrsb}
          PHQ-9: ${data.phq_9}
          GAD-7: ${data.gad_7}`
      },
      {
        label: 'Mood:',
        value: `Irritability (rate 1-10): ${data.mood_irritability}
          Impulsivity (rate 1-10): ${data.mood_impulsivity}
          Depression (rate 1-10): ${data.mood_depression}
          Hx of cycling/grandiosity/hypersexuality/energy with lack of sleep
          ${moodHxItems.filter((v) => data[v.value]).map((v) => v.label).join(', ')}
          Anxiety level (rate 1-10): ${data.mood_anxiety_level}
          Sleep:\n${data.mood_sleep_quality}
          Appetite:\n${data.mood_appetite}
          Overall impression:\n${data.mood_overall_impression}`
      },
      {
        label: 'Psychiatric History:',
        value: `${psychiatricHistoryItems.filter((v) => data[v.value]).map((v) => v.label).join(', ')}
          Current therapist: ${data.current_therapist}
          Current psychiatric provider: ${data.current_psychiatric_provider}
          Previous dx of ADHD: ${data.previous_dx_adhd}
          Previous dx of any other psychiatric disorders: ${data.previous_dx_other_disorders}
          Previous psychological testing: ${data.previous_psychological_testing}`
      },
      {
        label: 'Suicidal Ideation:',
        value: `Risk: ${riskItems.filter((v) => data[v.value]).map((v) => {
          return v.label + (data[`${v.value}_text`] ? `(${data[`${v.value}_text`]})` : '');
        }).join(', ')}
          Protective factors: ${protectiveItems.filter((v) => data[v.value]).map((v) => {
          return v.label + (data[`${v.value}_text`] ? `(${data[`${v.value}_text`]})` : '');
        }).join(', ')}
          Thoughts of Harm to others: ${harmOthersItems.filter((v) => data[v.value]).map((v) => {
          return v.label + (data[`${v.value}_text`] ? `(${data[`${v.value}_text`]})` : '');
        }).join(', ')}`
      },
      {
        label: 'Medical History:',
        value: `Medical conditions: ${data.medical_conditions}
          Hx of cardiac, arrhythmias, hypersomnolence, HTN, sleep apnea, seizure,cva/stroke,thrombotic events:
          ${medicalHistoryItems.filter((v) => data[v.value]).map((v) => {
            return v.label + (data[`${v.value}_text`] ? `(${data[`${v.value}_text`]})` : '');
          }).join(', ')}
          Current PCP: ${data.medical_current_pcp}
          Date last seen: ${data.medical_last_seen_date}
          Current Medical Medications: ${data.current_medical_medications}
          Vitamins/supplements: ${data.medical_vitamins_supplements}
          Height (inches): ${data.patient_height}
          Weight (pounds): ${data.patient_weight}
          Reports significant weight gain or loss in the last 6 months:
          ${data.medical_weight_change_6m ? 'Yes' : 'No'} ${data['medical_weight_change_6m_text'] ? `(${data['medical_weight_change_6m_text']})` : ''}`
      },
      {
        label: 'Allergies:',
        value: `${data.allergies}`
      },
      {
        label: 'Social History:',
        value: `Occupation: ${data.social_occupation}
          Work/school schedule: ${data.social_work_school_schedule}
          Significant life stressors/events: ${data.social_life_stressors_events}
          Relationship status: ${data.social_relationship_status}
          Children: ${data.social_children}
          Social support: ${data.social_support}
          Legal issues/incarcerations/probation/parole:
          ${socialHistoryItems.filter((v) => data[v.value]).map((v) => {
            return v.label + (data[`${v.value}_text`] ? `(${data[`${v.value}_text`]})` : '');
          }).join(', ')}`
      },
      {
        label: 'Family History:',
        value: `Any immediate family hx of IP or OP mental health admission:
          ${familyMentalHealthItems.filter((v) => data[v.value]).map((v) => {
            return v.label + (data[`${v.value}_text`] ? `(${data[`${v.value}_text`]})` : '');
          }).join(', ')}
          Any immediate family hx of suicide attempts, psychosis, bipolar d/o, SMI:
          ${familyMedicalHistoryItems.filter((v) => data[v.value]).map((v) => {
            return v.label + (data[`${v.value}_text`] ? `(${data[`${v.value}_text`]})` : '');
          }).join(', ')}`
      },
      {
        label: 'Substance use history:',
        value: `Check box for substance then describe the amount/frequency of use:
          ${substanceUseHistoryItems.filter((v) => data[v.value]).map((v) => {
            return v.label + (data[`${v.value}_text`] ? `(${data[`${v.value}_text`]})` : '');
          }).join(', ')}
          Current use of Medication Assisted Treatment (MAT: Suboxone/buprenorphine, methadone, naltrexone, Vivitrol):
          ${data.substance_use_current_mat ? 'Yes' : 'No'} ${data['substance_use_current_mat_text'] ? `(${data['substance_use_current_mat_text']})` : ''}
          History of substance use disorder:
          ${data.substance_use_disorder_history ? 'Yes' : 'No'} ${data['substance_use_disorder_history_text'] ? `(${data['substance_use_disorder_history_text']})` : ''}
          Pregnancy/Planning Pregnancy/Breastfeeding (female only)
          ${substanceUseHistoryFemaleItems.filter((v) => data[v.value]).map((v) => {
            return v.label + (data[`${v.value}_text`] ? `(${data[`${v.value}_text`]})` : '');
          }).join(', ')}`
      },
      {
        label: 'PDMP/Cures review:',
        value: `${data.pdmp_cures_review}`
      },
      {
        label: 'MSE',
        value: `General
        ${mseGeneralItems.filter((v) => data[v.value]).map((v) => {
          return v.label + (data[`${v.value}_text`] ? `(${data[`${v.value}_text`]})` : '');
        }).join(', ')}
        Speech
        ${mseSpeechItems.filter((v) => data[v.value]).map((v) => {
          return v.label + (data[`${v.value}_text`] ? `(${data[`${v.value}_text`]})` : '');
        }).join(', ')}
        Mood and Affect
        ${mseMoodAffectItems.filter((v) => data[v.value]).map((v) => {
          return v.label + (data[`${v.value}_text`] ? `(${data[`${v.value}_text`]})` : '');
        }).join(', ')}
        Thought Process
        ${mseThoughtProcessItems.filter((v) => data[v.value]).map((v) => {
          return v.label + (data[`${v.value}_text`] ? `(${data[`${v.value}_text`]})` : '');
        }).join(', ')}
        Thought Content
        ${mseThoughtContenItems.filter((v) => data[v.value]).map((v) => {
          return v.label + (data[`${v.value}_text`] ? `(${data[`${v.value}_text`]})` : '');
        }).join(', ')}
        Insight and Judgment
        ${mseInsightJudgmentItems.filter((v) => data[v.value]).map((v) => {
          return v.label + (data[`${v.value}_text`] ? `(${data[`${v.value}_text`]})` : '');
        }).join(', ')}`
      },
      {
        label: 'Time Spent:',
        value: `A total of ${data.spent_total_mins} minutes was spent on ${data.spent_day_date}
          ${timeSpentItems.filter((v) => data[v.value]).map((v) => {
            return v.label + (data[`${v.value}_text`] ? `(${data[`${v.value}_text`]})` : '');
          }).join(', ')}`
      },
      {
        label: 'Treatment Plan:',
        value: `Medication plan:
          ${data.rx}
          ${data.no_controlled_substance_needed ? 'Patient does not need controlled substances (in-person evaluation/referral not required).' : ''}
          ${data.provider_notes}
          Next follow-up(sync): ${data.next_follow_up_date > 0 ? formatUnix(data.next_follow_up_date, 'MMM D, YYYY') : ''}
          `
      },
      {
        value: `${PRE_INITIAL_APPOINTMENT_END_REVIEW.filter((v) => data[v.value]).map((v) => v.label).join('\n')}`
      },
    ];

    return notes;
  }

  const renderInitialNoteV1 = (data) => {
    return [
      { value: 'Patient present for initial psychiatric consultation' },
      {
        value:
          'Patient consents to treatment and consultation via telemedicine (remote video visit); patient was informed of limitations of telemedicine; oral and written consent received.',
      },
      {
        label: 'Chief Complaint:',
        value: data.chief_complaint,
      },
      {
        label: 'Medical history reviewed:',
        value: `Medicine allergy: ${
          data.allergic_drugs
            ? `Allergic to ${data.allergic_drugs}`
            : 'No known drug allergies'
        }\nCurrent Psychiatrist and Therapist: ${
          data.current_psy_and_ther
        }\nCurrent Medications and Supplements: ${
          data.current_medications
        }\nPrevious Medications: ${data.prev_medications}\nCurrent PCP: ${data.StructNoteCommonData.current_pcp}\nLast PCP appointment: ${
          data.StructNoteCommonData.most_recent_pcp_appointment
        }\nWeight in pounds: ${data.patient_weight}\nHeight in inches: ${
          data.patient_height
        }\nReported Medical Conditions: ${
          REPORTED_MEDICAL_CONDITIONS.filter((v) => data.StructNoteCommonData[v.value])
            .map((v) => v.label)
            .join(', ') +
          '\n' +
          data.StructNoteCommonData.reported_medical_conditions_write_in
        }`,
      },
      {
        label: 'Psychiatric history: ',
        value:
          PSYCHIATRIC_HISTORY.filter((v) => data[v.value])
            .map((v) => v.label)
            .join(', ') +
          '\n' +
          data.psychiatric_history_write_in,
      },
      {
        label: 'Substance use: ',
        value:
          SUBSTANCE_USE.filter((v) => data?.StructNoteCommonData[v.value])
            .map((v) => v.label)
            .join(', ') +
          '\n' +
          data?.StructNoteCommonData.substance_abuse_write_in,
      },
      {
        label: 'History of Present Illness:',
        value: data.history_of_present_illness,
      },
      {
        label: 'Patient seen via telemedicine.  ASRS and other screening results reviewed.',
        value: ' ',
      },
      {
        label: 'Presenting Psychiatric Symptoms',
        value: [
          {
            title: 'ADHD: ',
            checked: true,
            value:
              [...INATTENTIVENESS, ...HYPERACTIVITY_IMPULSIVITY]
                .filter((v) => data[v.value])
                .map((v) => v.label)
                .join(', ') +
              (data.adhd_write_in?`\n${data.adhd_write_in}`:'')
          },
          {
            title: 'Depression: ',
            checked: true,
            value: data.depression_write_in,
          },
          {
            title: 'Anxiety: ',
            checked: true,
            value: data.anxiety_write_in,
          },
          {
            title: 'Mania/Hypomania: ',
            checked: true,
            value: data.mania_hypomania_write_in,
          },
          {
            title: 'Other Psychiatric Symptoms: ',
            checked: true,
            value:
              OTHER_PSYCHIATRIC_SYMPTOMS.filter((v) => data.StructNoteCommonData[v.value])
                .map((v) => v.label)
                .join(', ') +
              `\n ${data?.StructNoteCommonData.psychiatric_symptoms_write_in}`,
          },
        ]
          .filter((v) => v.checked)
          .map((v) => v.title + v.value)
          .join('\n'),
      },
      {
        value: `Patient endorses or denies any history of, or current concerns relating to: trauma or abuse, intrusive thoughts, paranoid or delusional thoughts, psychosis, A/V hallucinations, cognitive alterations or acute decline in memory, or eating disorder behaviors.\n${
          data?.StructNoteCommonData.patient_endorse_psych_history ? 'Endorses' : 'Denies'
        }`,
      },
      {
        value: `Patient endorses or denies the presence of other physical medical conditions:\n${
          data.StructNoteCommonData.patient_endorsement ? 'Endorses': 'Denies'
        }${data.StructNoteCommonData.patient_endorsement_detail?'\n'+data.StructNoteCommonData.patient_endorsement_detail:''}`,
      },
      {
        label: 'Social & Family History: ',
        value: data.social_family_history,
      },
      {
        label: 'Family planning: ',
        value:
          FAMILY_PLANNING.filter((v) => data.StructNoteCommonData[v.value])
            .map((v) => v.label)
            .join(', ') + ` ${data.StructNoteCommonData.family_planning_write_in}`,
      },
      {
        label: 'Mental Status Exam:',
        value: `Appearance\n${[
          {
            label: 'Appearance',
            value: data.StructNoteCommonData.observation_appearance,
          },
          {
            label: 'Speech',
            value: data.StructNoteCommonData.observation_speech,
          },
          {
            label: 'Eye Contact',
            value: data.StructNoteCommonData.observation_eye_contact,
          },
          {
            label: 'Motor Activity',
            value: data.StructNoteCommonData.observation_motor_activity,
          },
          {
            label: 'Affect',
            value: data.StructNoteCommonData.observation_affect,
          },
        ]
          .map((v) => v.label + ': ' + v.value)
          .join('\n')}`,
      },
      {
        value: `Cognition\n${[
          {
            label: 'Orientation Impairment',
            value: data.StructNoteCommonData.cognition_orientation_impair,
          },
          {
            label: 'Memory Impairment',
            value: data.StructNoteCommonData.cognition_memory_impair,
          },
          {
            label: 'Attention',
            value: data.StructNoteCommonData.cognition_attention,
          },
        ]
          .map((v) => v.label + ': ' + v.value)
          .join('\n')}`,
      },
      {
        value: `Perception\n${[
          {
            label: 'Hallucinations',
            value: data.StructNoteCommonData.perception_hallucinations,
          },
          {
            label: 'Other',
            value: data.StructNoteCommonData.perception_other,
          },
        ]
          .map((v) => v.label + ': ' + v.value)
          .join('\n')}`,
      },
      {
        value: `Thoughts${[
          {
            label: 'Suicidality',
            value: data.StructNoteCommonData.thoughts_suicidality,
          },
          {
            label: 'Homicidality',
            value: data.StructNoteCommonData.thoughts_homicidality,
          },
          {
            label: 'Delusions',
            value: data.StructNoteCommonData.thoughts_delusions,
          },
        ]
          .map((v) => v.label + ': ' + v.value)
          .join('\n')}`,
      },
      {
        value: [
          {
            label: 'Behavior',
            value: data.StructNoteCommonData.behavior,
          },
          {
            label: 'Insight',
            value: data.StructNoteCommonData.insight,
          },
          {
            label: 'Judgement',
            value: data.StructNoteCommonData.judgement,
          },
        ]
          .map((v) => v.label + ': ' + v.value)
          .join('\n'),
      },
      {
        label: 'Mood',
        value:
          MOOD.filter((v) => data.StructNoteCommonData[v.value])
            .map((v) => v.label)
            .join(', ') + ` ${data.StructNoteCommonData.mood_write_in}`,
      },
      {
        label: 'Patient reviewed: ',
        value: 'ASRS, PHQ-9, GAD-7 and other screening results reviewed.\nPatient PDMP reviewed.\n' + (data.patient_review_no_concerns
          ? 'Reviewed with no Concerns'
          : 'Reviewed with Concerns') + (data.patient_review_concerns ? `\n${data.patient_review_concerns}`: ''),
      },
      {
        label: 'Diagnosis:',
        value: data.diagnostic_code + '\n' + data.other_diagnosis_notes + '\n' + data.diagnostic_code_notes,
      },
      {
        label: 'Rx: ',
        value:
          data.rx + (data.no_controlled_substance_needed
            ? '\nPatient does not need controlled substances (in-person evaluation/referral not required).'
            : ''),
      },
      { value: data.provider_notes },
      {
        label: 'Next follow up date: ',
        value:
          data.next_follow_up_date > 0
            ? formatUnix(data.next_follow_up_date, 'MMM D, YYYY')
            : '',
      },
    ];
  };

  const renderClinicianNotes = (data) => {
    if (data.initial_note_id !== "0") {
      return renderStructNoteV2(data);
    } else if (data.note_type == 1) { // INITIAL EVALUATION
      return renderInitialNoteV1(data);
    }
  };

  const renderDiagnosisCode = (data) => {
    let codes = [];
    if (data.diagnostic_code) {
      codes = data.diagnostic_code.split(';');
      if (!codes.length) codes = data.diagnostic_code.split(',');
      codes = codes.filter((v) => v.trim() !== 'Other (please specify below)');
    }
    return codes.join('\n') + '\n' + data.other_diagnosis_notes;
  }

  const list = isEmpty(data) ? [] : [
    {
      label: 'NOTES TYPE',
      values: [
        {
          label: '',
          value: data.note_type_name,
        },
      ],
      span: 2,
    },
    {
      label: 'Updated At',
      values: [
        {
          label: '',
          value: data.updated_at > 0 ? formatUnix(data.updated_at) : '',
        },
      ],
      span: 2,
    },
    {
      label: 'RX',
      values: [
        { value: data.rx },
        // {
        //   value: data.no_controlled_substance_needed
        //     ? 'Patient does not need controlled substances (in-person evaluation/referral not required).'
        //     : '',
        //   checkbox: true,
        // },
      ],
    },
    {
      label: 'Last Refill Date',
      values: [{ value: formatLastRefillDate(data.last_refill_date) }],
    },
    {
      label: 'Diagnosis Code',
      values: [
        {
          value: renderDiagnosisCode(data),
        },
      ],
    },
    {
      label: 'CPT Code',
      values: [{ value: CPTCodesMap[data.cpt_code] }],
    },
    {
      label: 'Clinician Notes',
      values: renderClinicianNotes(data),
    },
    {
      label: 'Message To Patient',
      values: [{ value: data.message_to_patient }],
    },
  ];

  const renderItem = (v, index, count) => {
    if (!v.value) return null;
    const isLast = count === index + 1;
    return (
      <div style={{ marginBottom: isLast ? 0 : 24 }} key={index}>
        {v.label && (
          <h5 style={{ marginTop: '6px', color: '#8C93A2', fontWeight: 700 }}>
            {v.label}
          </h5>
        )}
        <p>
          {v.checkbox ? (
            <Checkbox defaultChecked disabled>
              {v.value}
            </Checkbox>
          ) : (
            <span style={{ fontWeight: 400, whiteSpace: 'pre-line' }}>{v.value}</span>
          )}
        </p>
      </div>
    );
  }

  return (
    <Spin tip="Loading..." spinning={isEmpty(data)}>
      <Descriptions bordered layout="vertical" size="small">
        {list.map((item) => (
          <Descriptions.Item label={item.label} span={item.span || 3} key={item.label}>
            {item.values.map((v, index) => {
              // console.log('index:', item.label, index, v)
              return renderItem(v, index, item.values.length);
            })}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Spin>
  );
}
