import { request } from 'umi';
import { stringify } from 'querystring';

export async function queryCurrent() {
  return request('/api/ehr/user/profile');
}

export async function srvUpdateSettings(data) {
  return request('/api/ehr/user/settings', {
    method: 'post',
    data: data,
  });
}
export async function srvUpdateSetting(data) {
  return request('/api/ehr/user/setting', {
    method: 'post',
    data: data,
  });
}

export async function srvGetSubscriptionCode() {
  return request('/api/ehr/patient/appointment/calendar/subscription_code');
}

export async function srvSetSubscriptionCode(data) {
  return request('/api/ehr/patient/appointment/calendar/subscription_code', {
    method: 'post',
    data: data,
  });
}

export async function srvTherapistSession(data) {
  return request('/api/ehr/appointment/therapist_session', {
    method: 'post',
    data: data,
  });
}

export async function srvLastTherapyAppointment(param) {
  return request(`/api/ehr/patient/appointment/last/therapy?${stringify(param)}`);
}

export async function srvAppointmentCalendar(param) {
  return request(`/api/ehr/calendar/availability?${stringify(param)}`);
}


export async function srvTherapyRecurringCalendarAvailability(param) {
  return request(`/api/ehr/therapy_recurring/calendar/availability?${stringify(param)}`);
}

export async function srvDoctorStat(param) {
  return request(`/api/ehr/user/stat?${stringify(param)}`);
}

export async function srvAdminStat(param) {
  return request(`/api/admin/user/stat?${stringify(param)}`);
}

export async function srvGetTermsAndConditions() {
  return request('/api/ehr/terms_and_conditions');
}

export async function srvAgreeTermsAndConditions(data) {
  return request('/api/ehr/terms_and_conditions/agree', {
    method: 'post',
    data: data,
  });
}

export async function srvDoctorTaskURL(param) {
  return request(`/api/ehr/user/task_url?${stringify(param)}`);
}

export async function srvDoctorRxntAccounts() {
  return request('/api/ehr/doctor_rxnt_accounts');
}

export async function srvUpdateDoctorRxntAccount(data) {
  return request('/api/ehr/doctor_rxnt_account', {
    method: 'post',
    data: data,
  });
}

export async function srvDoctorPatientStats(param) {
  return request(`/api/ehr/doctor_patient_stat?${stringify(param)}`);
}

export async function srvDoctorPayrollMetric(param) {
  return request(`/api/ehr/doctor_payroll_metric?${stringify(param)}`);
}

export async function srvDoctorDashboardMetricWarningLock(param) {
  return request(`/api/ehr/doctor/accept_new_patient/check?${stringify(param)}`);
}

export async function srvDoctorDashboardProviderNoShow(param) {
  return request(`/api/ehr/doctor_dashboard_provider_noshow?${stringify(param)}`);
}

export async function srvDoctorFollowUpMonthlyTimeSlot(param) {
  return request(`/api/ehr/followup_time_slot?${stringify(param)}`);
}

export async function srvDoctorCompleteFollowUpTimeSlot(data) {
  return request('/api/ehr/followup_time_slot/complete', {
    method: 'post',
    data: data,
  });
}

export async function srvDoctorMarkFollowUpTimeSlotNotified(data) {
  return request('/api/ehr/followup_time_slot/mark_as_notified', {
    method: 'post',
    data: data,
  });
}

export async function srcTimePlanTypeList(param) {
  return request(`/api/ehr/timeplan/type/list?${stringify(param)}`);
}

export async function srcUpdateTimePlanRuleWeek(data) {
  return request('/api/ehr/timeplan/rule/weekly', {
    method: 'post',
    data: data,
  });
}

export async function srvResetTimeslot(param){
    return request(`/api/ehr/timeplan/debug/regenerate_bitmap_and_zset?${stringify(param)}`);
}

export async function srcSaveProcessStatus(param) {
  return request(`/api/ehr/timeplan/rule/weekly/save_process_status?${stringify(param)}`);
}

export async function srcGetWeekRule(param) {
  return request(`/api/ehr/timeplan/rule/weekly?${stringify(param)}`);
}
export async function srcUpdateOneDayRule(data) {
  return request('/api/ehr/timeplan/rule/one_day', {
    method: 'post',
    data: data,
  });
}

export async function srcCheckNeedWeekly() {
  return request('/api/ehr/timeplan/doctor/need_weekly');
}

export async function srcCheckWeeklyRule(data) {
  return request('/api/ehr/timeplan/rule/weekly/check', {
    method: 'post',
    data: data,
  });
}

export async function srcCheckWeeklyChange(data) {
  return request('/api/ehr/timeplan/rule/weekly/check_change', {
    method: 'post',
    data: data,
  });
}

export async function srcCheckDayChange(data) {
  return request('/api/ehr/timeplan/rule/one_day/check_change', {
    method: 'post',
    data: data,
  });
}

export async function srcCacheWekkRule(data) {
  return request('/api/ehr/timeplan/cache/rule/weekly', {
    method: 'post',
    data: data,
  });
}


export async function srcGetCacheWeekRule(param) {
  return request(`/api/ehr/timeplan/cache/rule/weekly?${stringify(param)}`);
}


export async function srcGetTimeRangeRule() {
  return request('/api/ehr/timeplan/rule/week/days/list');
}

export async function srvThisWeekRule(param) {
  return request(`/api/ehr/timeplan/rule/week/days/list?${stringify(param)}`);
}

export async function srcGetFollowupCalFlag() {
  return request('/api/ehr/timeplan/followup/cal/flag');
}

export async function srvGetDoctorAgreement() {
  return request('/api/ehr/doctor_agreement/get');
}

export async function srvSignDoctorAgreement(data) {
  return request('/api/ehr/doctor_agreement/sign', {
    method: 'post',
    data: data,
  });
}

export async function srvGoogleCalendarAuth(param) {
  return request('/api/google/auth', {
    method: 'post',
    data: param,
  });
}

export async function srvGetDoctorGoogleCalendar() {
  return request(`/api/google/doctor/calendar`);
}

export async function srvDeleteDoctorGoogleCalendar(param) {
  return request(`/api/google/doctor/calendar/delete?${stringify(param)}`, {
    method: 'post'
  });
}

export async function srvCheckDoctorNeedReAuthGoogleCalendar() {
  return request(`/api/google/doctor/calendar/check_re_auth`);
}

export async function srvGetGoogleClientId() {
  return request(`/api/google/client_id`);
}

export async function srvCheckDoctorGoogleCalendar() {
  return request(`/api/google/doctor/calendar/check`);
}

export async function srvCheckDoctorGoogleCalendarResync() {
  return request(`/api/google/doctor/calendar/check_resync`);
}

// /google/syncAppointmentToGoogleCalendar
export async function srvSyncAppointmentToGoogleCalendar(param) {
  return request(`/api/google/syncAppointmentToGoogleCalendar?${stringify(param)}`);
}

export async function srvGetDoctorAppointmentQuestion() {
  return request('/api/ehr/appointment_question/list');
}

export async function srvUpdateDoctorAppointmentQuestion(data) {
  return request('/api/ehr/appointment_question/update', {
    method: 'post',
    data: data,
  });
}

export async function srvGetPatientAppointmentQuestionResponse(params) {
  return request(`/api/ehr/patient/appointment_question_response?${stringify(params)}`);
}

export async function srvGetPatientAsyncVideoUrl(params) {
  return request(`/api/ehr/patient/async_video_url?${stringify(params)}`);
}

export async function srvSaveStripeAccountSetting(data) {
  return request('/api/strip/account/setting/save', {
    method: 'post',
    data: data,
  });
}

export async function srvGetStripeAccountSetting(param) {
  return request(`/api/strip/account/setting?${stringify(param)}`, {
    method: 'post',
  });
}

export async function srvGetWalletDetail() {
  return request('/api/wallet/detail');
}

export async function srvCreatePayout(param) {
  return request(`/api/payout/create?${stringify(param)}`, {
    method: 'post',
  });
}

export async function srvUpdatePayoutStatus(data) {
  return request('/api/payout/status/update', {
    method: 'post',
  });
}

export async function srvRetryPayout(param) {
  return request(`/api/payout/retry?${stringify(param)}`, {
    method: 'post',
  });
}

// /payout/account/status/update
export async function srvUpdatePayoutAccountStatus(param) {
  return request(`/api/payout/account/status/update?${stringify(param)}`, {
    method: 'post',
  });
}

export async function srvGetPatientTherapyRecurringAppointmentOption(param) {
  return request(`/api/ehr/patient/therapy_recurring_appointment/option?${stringify(param)}`);
}

export async function srvGetSixMonthFlag(param) {
  return request(`/api/ehr/timeplan/six_month/flag?${stringify(param)}`);
}
export async function srvDoctorAccounts() {
  return request(`/api/ehr/doctor/accounts`);
}

export async function srvUploadStripeFile(data) {
  return request('/api/ehr/doctor/stripe/uploadFile', {
    method: 'post',
    data: data,
  });
}

export async function srvGetProviderImgUrl(param) {
  return request(`/api/ehr/doctor/getProviderImgUrlByObejctKey?${stringify(param)}`);
}
export async function srvSaveStripeCompanyAccountSetting(data) {
  return request('/api/strip/account/setting/save/company', {
    method: 'post',
    data: data,
  });
}
export async function srvGetStripeCompanyAccountSetting(param) {
  return request(`/api/strip/company/account/setting?${stringify(param)}`, {
    method: 'post',
  });
}
export async function srvSaveRepresentativePerson(data) {
  return request('/api/ehr/doctor/person/representative/add', {
    method: 'post',
    data: data,
  });
}
export async function srvSaveOwnerPerson(data) {
  return request('/api/ehr/stripe/company/owner/add', {
    method: 'post',
    data: data,
  });
}
export async function srvDeleteConnect(param) {
  return request(`/api/doctor/connect/delete?${stringify(param)}`, {
    method: 'post',
  });
}
