import { useState } from 'react';
import { createContainer } from 'unstated-next';

function useMenuCounter() {
    const [flatMenus, setFlatMenus] = useState(undefined);
    const [flatMenuKeys, setFlatMenuKeys] = useState([]);
    return {
        flatMenus,
        setFlatMenus,
        flatMenuKeys,
        setFlatMenuKeys,
    };
}
const MenuCounter = createContainer(useMenuCounter);
export default MenuCounter;

