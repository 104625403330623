import React from 'react';
import { ConfigProvider, Layout } from 'antd';
import ResizeObserver from 'rc-resize-observer';

import { debounce } from '@/utils/utils';

const { Content } = Layout;

class WrapContent extends React.Component {
  shouldComponentUpdate(_) {
    if (_.contentHeight !== this.props.contentHeight) {
      return true;
    }
    if (_.children !== this.props.children) {
      return true;
    }
    return false;
  }

  render() {
    const { style, className, children, isChildrenLayout } = this.props;
    return (
      <Content className={className} style={style}>
        <ConfigProvider
          getPopupContainer={() => {
            if (isChildrenLayout && this.ref) {
              return this.ref;
            }
            return document.body;
          }}
        >
          <div
            ref={(ele) => {
              this.ref = ele;
            }}
            className="aster-basicLayout-children-content-wrap"
          >
            {children}
          </div>
        </ConfigProvider>
      </Content>
    );
  }
}

class ResizeObserverContent extends React.Component {
  resize = debounce(({ height }) => {
    const { contentHeight } = this.state;
    if (height && contentHeight !== height && Math.abs((contentHeight || 0) - height) > 1) {
      if (this.rafId) {
        window.cancelAnimationFrame(this.rafId);
      }
      this.rafId = window.requestAnimationFrame(() => {
        if (this.state.contentHeight !== height) {
          this.setState({
            contentHeight: height,
          });
        }
      });
    }
  }, 200);

  constructor(props) {
    super(props);
    this.state = {
      contentHeight: null,
    };
    this.rafId = null;
  }

  componentWillUnmount() {
    if (this.resize && this.resize.cancel) {
      this.resize.cancel();
    }
    if (this.rafId) {
      window.cancelAnimationFrame(this.rafId);
    }
  }

  render() {
    const { contentHeight } = this.state;
    return (
      <div
        style={{
          minHeight: contentHeight || 'auto',
          position: 'relative',
        }}
      >
        <ResizeObserver onResize={this.resize}>
          <WrapContent {...this.props} contentHeight={contentHeight} />
        </ResizeObserver>
      </div>
    );
  }
}

export default ResizeObserverContent;
