import {
  srvPharmacyBlacklist, srvAddPharmacyBlacklist, srvDelPharmacyBlacklist,
} from '@/services/admin';

const Model = {
  namespace: 'pharmacy_blacklist',
  state: {
    data: {
      items: [],
      total: 0,
    },
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const res = yield call(srvPharmacyBlacklist, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveList', payload: res.data });
    },
    *addPharmacyBlacklist({ payload, callback }, { call, put, select }) {
      const res = yield call(srvAddPharmacyBlacklist, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *delPharmacyBlacklist({ payload, callback }, { call, put, select }) {
      const res = yield call(srvDelPharmacyBlacklist, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
  },
  reducers: {
    saveList(state, { payload }) {
      return { ...state, data: payload };
    },
  },
};

export default Model;
