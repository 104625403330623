import React, { useState, useEffect } from 'react';
import { connect } from 'umi';
import { useRequest } from 'ahooks';
import { Button, Modal,  Space, Table, message,  Dropdown} from 'antd';
import { ExclamationCircleOutlined, EllipsisOutlined} from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';

import { srvAddPharmacyBlacklist } from '@/services/admin';
import AddPharmacyBlacklistDrawer from './AddPharmacyBlacklistDrawer';
import Filters from '@/components/Filters';

import cn from 'classnames/bind';
import styles from './Blacklist.less';
const cx = cn.bind(styles);

const PharmacyBlacklist = (props) => {
  const {
    dispatch,
    pharmacy_blacklist: { data },
    loading,
    active,
  } = props;
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [pharmacyDrawerVisible, setPharmacyDrawerVisible] = useState(false);

  const filters = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
    },
    {
      name: 'city',
      label: 'City',
      type: 'text',
    },
    {
      name: 'state',
      label: 'State',
      type: 'text',
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'text',
    },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 250,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      ellipsis: true,
      width: 250,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 120,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 80,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 150,
    },
    {
      title: 'Refuse Reason',
      dataIndex: 'refuse_reason',
      key: 'refuse_reason',
      width: 350,
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 50,
      render: (v, r) => {
        return (
          <Dropdown
            overlay={
              <div className={cx({ doctorOpt: true })}>
                <div className={cx({ doctorOptItem: true })} onClick={() => confirmDel(r)}>
                  <a href="#">Delete</a>
                </div>
              </div>
            }
            trigger={['click']}
          >
            <Button size="small">
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const loadList = (payload, callback) => {
    dispatch({ type: 'pharmacy_blacklist/fetchList', payload, callback });
  };

  useEffect(() => {
    if (active) {
      setPage(1);
      loadList({ page: 1, page_size: 20, filters: JSON.stringify(query) }, () => { });
    }
  }, [active]);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => { });
  };

  const confirmDel = (r) => {
    Modal.confirm({
      title: 'Do you want to del these pharmacy?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch({
          type: 'pharmacy_blacklist/delPharmacyBlacklist',
          payload: { id: r.id },
          callback: () => {
            loadList({ page, page_size: 20 }, () => {});
          },
        });
      },
      onCancel() {},
    });
  };

  const addPharmacyBlacklistReq = useRequest(srvAddPharmacyBlacklist, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        message.success('successfully added');
        loadList({ page: 1, page_size: 20 }, () => { });
        setPharmacyDrawerVisible(false);
      } else {
        message.error(ret.msg);
      }
    },
  });

  const addPharmacyBlacklist = (v, cb) => {
    const values = { refuse_reason: v.refuse_reason, pharmacies: [] };
    if (v.pharmacies && v.pharmacies.length > 0) {
      v.pharmacies.forEach(pharmacy => {
        values.pharmacies.push({
          name: pharmacy.name, address: `${pharmacy.address1}${pharmacy.address2 ? ' ' + pharmacy.address2 : ''}`,
          city: pharmacy.city, state: pharmacy.state, phone: pharmacy.phone, rxnt_pharmacy_id: pharmacy.id,
        })
      });
      addPharmacyBlacklistReq.runAsync(values).then(cb);
    }
  }


  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadList({
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: JSON.stringify(query)
    });
  };

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Pharmacy Blacklist</h3>
        <Space>
          <Button
            type="primary"
            onClick={() => { setPharmacyDrawerVisible(true) }}
          >
            Add
          </Button>
          <Filters filters={filters} onFilter={onFilter} />
        </Space>
      </div>

      <Table
        className="rowSelectTable"
        columns={columns}
        dataSource={data.items}
        rowKey="id"
        loading={loading.effects['pharmacy_blacklist/fetchList']}
        onChange={handleTableChange}
        pagination={{
          pageSize: 20,
          current: page,
          simple: true,
          total: data.total,
        }}
      />

      <AddPharmacyBlacklistDrawer
        visible={pharmacyDrawerVisible}
        onFinish={addPharmacyBlacklist}
        onClose={() => {
          setPharmacyDrawerVisible(false);
        }}
        admin={true}
      />
    </div>
  );
};

export default connect(({ pharmacy_blacklist, loading }) => ({
  pharmacy_blacklist,
  loading,
}))(PharmacyBlacklist);
