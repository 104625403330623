import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, useRouteMatch } from 'umi';
import cn from 'classnames/bind';
import { formatUnix, unixToMomentWithTZ } from '../../utils/xtime';
import { InputNumber, Button, DatePicker, Space, Typography, Modal, message,Input } from 'antd';
import styles from './index.less';
import { SettingOutlined } from '@ant-design/icons';
import {
  srvCancelPatientMembership,
  srvChangePatientSubscriptionType,
  srvExtendPatientMembershipEndDate,
  srvExtendMembershipByWeek,
} from '@/services/admin';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useRequest } from 'ahooks';
const cx = cn.bind(styles);

const { Text } = Typography;

function disabledDate(current, enddate) {
  // Can not select days before today and today
  return current && current <= enddate;
}

const MembershipHistoryButton = (props) => {
  const { toggleShowMembershipHistory, showMembershipHistory } = props

  return (
    <div className={cx({ rightItem: true })}>
      <Button size="small" onClick={toggleShowMembershipHistory}>
        {
          showMembershipHistory ? "Hide History" : "Show History"
        }
      </Button></div>)

}
const MembershipInfo = (props) => {
  const dispatch = useDispatch();
  // const patient_detail = useSelector((state) => state.patient_detail);
  const match = useRouteMatch();

  const { therapySubscription, membership, lastMembership, detail, pausedTips, toggleShowMembershipHistory,
    showMembershipHistory, activateMembership, cancelMembership, membershipSince, membershipFee,
    membershipHistoryExisted, onChangeCustomerPrice, saveCustomePrice, priceInput, setPriceInput,
    resumeMembership, pauseMembership
  } = props
  const [dateInput, setDateInput] = useState(false)
  const [membershipEndDate, setMembershipEndDate] = useState(0)
  const [currentMembershipCycleEndDate, setCurrentMembershipCycleEndDate] = useState(0)
  const [membershipStatus, setMembershipStatus] = useState(1)
  const [newEndDate, saveNewEndDate] = useState(0)
  const [extendWeekInput, setExtendWeekInput] = useState(false)
  const [extendWeek,setExtendWeek]=useState(1);
  useEffect(() => {
    if (membership !== null) {
      setMembershipStatus(membership.status)
      setCurrentMembershipCycleEndDate(membership.end_date)
      let membershipEndDate = 0
      if (detail.subscription_end_date !== undefined && detail.subscription_end_date !== 0) {
        membershipEndDate = detail.subscription_end_date
      } else {
        membershipEndDate = membership.end_date
        if (lastMembership !== null) {
          membershipEndDate = lastMembership.end_date
        }
      }
      setMembershipEndDate(membershipEndDate)
    } else {
      setMembershipStatus(3)
    }
  }, [detail, membership, lastMembership])

  let statusJsx = <></>
  if (membershipStatus === 2 || membershipStatus === 1) {
    statusJsx = <><div className={cx({ centerItem: true })}>Active</div>
      <div className={cx({ rightItem: true })}>
        <Space>
          {!pausedTips &&
            <Button size="small" onClick={pauseMembership}>
              Pause
            </Button>
          }
          <Button size="small" onClick={cancelMembership}>
            Deactivate
          </Button>
        </Space>
        <Text type="warning" style={{ display: 'block', marginTop: '10px' }}>{pausedTips}</Text>
      </div></>
  } else if (membershipStatus === 4) {
    statusJsx =
      <>
        <div className={cx({ centerItem: true })}>Paused until {formatUnix(membershipEndDate, "MMMM DD, YYYY")} </div>
        <div className={cx({ rightItem: true })}>
          <Button size="small" onClick={resumeMembership} disabled={membership?.cancel_reason==='pause by provider submit discharge note or into waitinglist'}>
            Resume
          </Button>
          <Button style={{ marginLeft: "15px" }} size="small" onClick={cancelMembership}>
            Deactivate
          </Button>
        </div>
      </>
  } else {
    statusJsx =
      <>
        <div className={cx({ centerItem: true })} color="#f50">Inactive</div>
        <div className={cx({ rightItem: true })}>
          <Button size="small" onClick={activateMembership}>
            Activate
          </Button>
        </div>
      </>
  }
  let subscriptionType = "Monthly"
  if (detail.subscription_type === 2) {
    subscriptionType = "Annual"
  }

  const showDateInput = () => {
    setDateInput(true)
  }
  const showExtendWeekInput = () => {
    setExtendWeekInput(true)
  }
  const hideDateInput = () => {
    setDateInput(false)
  }
  const hideExtendWeekInput = () => {
    setExtendWeekInput(false)
  }


  const saveSelectedEndDate = (date, dateString) => {
    saveNewEndDate(date.unix())
  }

  const saveEndDate = () => {
    if (newEndDate !== 0) {
      let data = {}
      data.subscription_end_date = newEndDate
      data.id = detail.id
      srvExtendPatientMembershipEndDate(data).then(() => {
        setMembershipEndDate(newEndDate)
        setDateInput(false)
      })
    } else {
      setDateInput(false)
    }
  }
  const saveExtendWeek = () => {
    if (extendWeek > 0) {
      let data = {}
      data.user_id = detail.id;
      data.weeks = Number(extendWeek);
      saveExtendWeekReq.run(data);
    } else {
      message.warning("The value entered must be greater than 0");
    }
  }

  
  const saveExtendWeekReq = useRequest(srvExtendMembershipByWeek, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setExtendWeekInput(false);
        message.success("operation is successful");
      }else{
        message.error("operation failed");
      }
    },
  });
  let membershipHistoryItem = <></>

  if (membershipHistoryExisted) {
    membershipHistoryItem = (
      <div className={cx({ membereshipItem: true })}>
        <div className={cx({ leftItem: true })}> Member Since </div>
        <div className={cx({ centerItem: true })}>{formatUnix(membershipSince, "MMM DD, YYYY")}</div>
        <MembershipHistoryButton
          toggleShowMembershipHistory={toggleShowMembershipHistory}
          showMembershipHistory={showMembershipHistory} />
      </div>
    )
  } else {
    membershipHistoryItem = (
      <div className={cx({ membereshipItem: true })}>
        <div className={cx({ leftItem: true })}> Member Since </div>
        <div className={cx({ centerItem: true })}>Not started yet</div>
      </div>
    )
  }

  function onChangeSubscription() {
    console.log("detail", detail)
    const isSubscriptionMonthly = detail?.subscription_type == 1
    Modal.confirm({
      title: isSubscriptionMonthly ? `Do you want to Upgrade this patient to the Annual Plan?` : "Do you want to Downgrade this patient to the Monthly Plan? Please also confirm if the patient's Next Payment Date needs to be updated following the change",
      icon: <ExclamationCircleOutlined />,
      onOk: async function () {
        await srvChangePatientSubscriptionType({ id: detail.id, subscription_type: isSubscriptionMonthly ? 2 : 1 })
        await dispatch({ type: 'patient_detail/fetchData', payload: { id: match.params.id }, callback: () => { } });
        message.info('Success. Please Set Next Payment Date ')
      },
      onCancel() { },
    });


  }

  return (
    <div className={cx({ membereshipInfo: true })}>
      <div className={cx({ membereshipItem: true })}>
        <div className={cx({ leftItem: true })}> Membership Status </div>
        {statusJsx}
      </div>
      {(membership && membership.status === 4) &&
        <div className={cx({ membereshipItem: true })}>
          <div className={cx({ leftItem: true })}> Paused Reason </div>
          <div className={cx({ rightItem: true })}>
            {membership.cancel_reason}
          </div>
        </div>
      }
      {membership ? (membership.status !== 3) && (<div>
        <div className={cx({ membereshipItem: true })}>
          <div className={cx({ leftItem: true })}> Subscription Plan </div>
          <div className={cx({ centerItem: true })}> {subscriptionType} </div>
          <div className={cx({ rightItem: true })}>
            <Button size="small" onClick={onChangeSubscription}>{detail?.subscription_type == 2 ? 'Downgrade Monthly Membership' : 'Upgrade Annual Membership'}</Button>
          </div>
        </div>
        <div className={cx({ membereshipItem: true })}>
          <div className={cx({ leftItem: true })}>Next Payment Date</div>
          {dateInput ?
            <>
              <div className={cx({ centerItem: true })}>
                <DatePicker
                  onChange={saveSelectedEndDate}
                  disabledDate={(current) => disabledDate(current, unixToMomentWithTZ(currentMembershipCycleEndDate))}
                  defaultValue={unixToMomentWithTZ(membershipEndDate)} />
              </div>
              <div className={cx({ rightItem: true })}>
                <Button className={cx({ rightThirdItem: true })} onClick={saveEndDate} size="small">save</Button>
                <Button onClick={hideDateInput} size="small">cancel</Button>
              </div>
            </>
            : extendWeekInput ?
              <>
                <div className={cx({ centerItem: true })}>{formatUnix(membershipEndDate, "MMM DD, YYYY")}</div>
                <div className={cx({ rightItem: true })}>
                  <div className={cx({ centerItem: true })}>
                    <Input size="small" style={{width:'180px',marginRight:'10px'}} addonBefore="Extend" addonAfter="Week" value={extendWeek} onChange={(e) => {
                          let value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            setExtendWeek(value);
                          }else{
                            message.warning('Only numbers can be entered');
                            setExtendWeek('');
                          }
                    }} />
                  </div>
                </div>
                <div className={cx({ rightItem: true })}>
                  <Button className={cx({ rightThirdItem: true })} onClick={saveExtendWeek} size="small">save</Button>
                  <Button onClick={hideExtendWeekInput} size="small">cancel</Button>
                </div>
              </> :
              <>
                <div className={cx({ centerItem: true })}>{formatUnix(membershipEndDate, "MMM DD, YYYY")}</div>
                <div className={cx({ rightItem: true })}>
                  <Space>
                    <Button size="small" onClick={showDateInput}>Override</Button>
                    {membershipStatus === 2 && <Button size="small" onClick={showExtendWeekInput}>Extend</Button>}
                  </Space>
                </div>
              </>
          }
        </div>
        <div className={cx({ membereshipItem: true })}>
          <div className={cx({ leftItem: true })}> Subscription Fee </div>
          {priceInput ?
            <>
              <div className={cx({ centerItem: true })}>
                <InputNumber defaultValue={membershipFee} onChange={onChangeCustomerPrice}> </InputNumber>
              </div>
              <div className={cx({ rightItem: true })}>
                <Button className={cx({ rightThirdItem: true })} onClick={saveCustomePrice} size="small">save</Button>
                <Button onClick={() => { setPriceInput(false) }} size="small">cancel</Button>
              </div>
            </> :
            <>
              <div className={cx({ centerItem: true })}> {"$"}{membershipFee} </div>
              <div className={cx({ rightItem: true })}>
                <Button size="small" onClick={() => { setPriceInput(true) }}>Override</Button>
              </div>
            </>
          }
        </div>
        <div className={cx({ membereshipItem: true })}>
          <div className={cx({ leftItem: true })}> Member Since </div>
          <div className={cx({ centerItem: true })}>{formatUnix(membershipSince, "MMM DD, YYYY")}</div>
          <MembershipHistoryButton
            toggleShowMembershipHistory={toggleShowMembershipHistory}
            showMembershipHistory={showMembershipHistory} />
        </div>
        <div className={cx({ membereshipItem: true })}>
          <div className={cx({ leftItem: true })}> Current Membership Cycle </div>
          <div className={cx({ rightItem: true, itemContent: true })}> {formatUnix(membership.begin_date, "MMM DD, YYYY")} - {formatUnix(membership.end_date, "MMM DD, YYYY")} </div>
        </div>
        <hr style={{ marginTop: "40px", marginBottom: "16px" }} />
        <h3 className={cx({ membershipSubHeader: true })}>Current Cycle Benefits Status</h3>
        <div className={cx({ membereshipItem: true })}>
          <div className={cx({ leftItem: true })}> Consultations Asked </div>
          <div className={cx({ rightItem: true })}> {membership.used_consultation_num} </div>
        </div>
        <div className={cx({ membereshipItem: true })}>
          <div className={cx({ leftItem: true })}> Follow-up used </div>
          <div className={cx({ rightItem: true })}> {membership.followup ? "Yes" : "No"} </div>
        </div>
        {/*
          <div className={cx({membereshipItem: true})}>
            <div className={cx({leftItem: true})}> Refill requested </div>
            <div className={cx({rightItem: true})}> {membership.followup ? "Yes":"No"} </div>
          </div> */}
      </div>
      ) : membershipHistoryItem
      }
    </div>
  )
}

export default MembershipInfo
