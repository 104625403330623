import React from 'react';
import { Skeleton, Card } from 'antd';
import useMediaQuery from 'use-media-antd-query';

const MediaQueryKeyEnum = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 3,
  xl: 3,
  xxl: 4,
};

const DescriptionsLargeItemSkeleton = ({ active }) => (
  <div
    style={{
      marginTop: 32,
    }}
  >
    <Skeleton.Button active={active} size="small" style={{ width: 100, marginBottom: 16 }} />
    <div
      style={{
        width: '100%',
        justifyContent: 'space-between',
        display: 'flex',
      }}
    >
      <div
        style={{
          flex: 1,
          marginRight: 24,
          maxWidth: 300,
        }}
      >
        <Skeleton
          active={active}
          paragraph={false}
          title={{
            style: { marginTop: 0 },
          }}
        />
        <Skeleton
          active={active}
          paragraph={false}
          title={{
            style: { marginTop: 8 },
          }}
        />
        <Skeleton
          active={active}
          paragraph={false}
          title={{
            style: { marginTop: 8 },
          }}
        />
      </div>
      <div
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            maxWidth: 300,
            margin: 'auto',
          }}
        >
          <Skeleton
            active={active}
            paragraph={false}
            title={{
              style: { marginTop: 0 },
            }}
          />
          <Skeleton
            active={active}
            paragraph={false}
            title={{
              style: { marginTop: 8 },
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

const DescriptionsItemSkeleton = ({ size, active }) => {
  const colSize = useMediaQuery();
  const arraySize = size === undefined ? MediaQueryKeyEnum[colSize] || 3 : size;
  return (
    <div
      style={{
        width: '100%',
        justifyContent: 'space-between',
        display: 'flex',
      }}
    >
      {new Array(arraySize).fill(null).map((_, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          style={{
            flex: 1,
            paddingLeft: index === 0 ? 0 : 24,
            paddingRight: index === arraySize - 1 ? 0 : 24,
          }}
        >
          <Skeleton
            active={active}
            paragraph={false}
            title={{
              style: { marginTop: 0 },
            }}
          />
          <Skeleton
            active={active}
            paragraph={false}
            title={{
              style: { marginTop: 8 },
            }}
          />
          <Skeleton
            active={active}
            paragraph={false}
            title={{
              style: { marginTop: 8 },
            }}
          />
        </div>
      ))}
    </div>
  );
};

export const DescriptionsSkeleton = ({ active }) => (
  <Card
    bordered={false}
    style={{
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
    }}
  >
    <Skeleton.Button active={active} size="small" style={{ width: 100, marginBottom: 16 }} />
    <DescriptionsItemSkeleton active={active} />
    <DescriptionsLargeItemSkeleton active={active} />
  </Card>
);
