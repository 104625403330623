import React, { useState, useRef, useEffect } from 'react';
import { Button, Spin, Drawer, Slider, Input, Form, message } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
const { TextArea } = Input;
import {useMount, useRequest} from 'ahooks';
import {
  srvGetPatientAppointmentQuestionResponse,
  srvGetPatientAsyncVideoUrl
} from '@/services/account';

import DoneVideo from "@/components/DoneVideo";

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

const PlayVideo = (props) => {
  const { videoKey } = props
  const [videoUrl, setVideoUrl] = useState(null)

  const play = () => {
    fetchVideoUrl.run({
      key: videoKey
    })
  }

  const fetchVideoUrl = useRequest(srvGetPatientAsyncVideoUrl, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if(ret.data) {
          setVideoUrl(ret.data)
        }
      }
    }
  })

  const renderContent = () => {
    if(videoUrl) {
      return (
        <DoneVideo videoUrl={videoUrl} />
      )
    } else {
      return (
        <Button icon={<PlayCircleOutlined />} size="large" onClick={play}>
          Play Video
        </Button>
      )
    }
  }

  return <>
    {renderContent()}
  </>
}

export const AppointmentResponseContent = (props) => {
  const { response, doctorName } = props

  const titleOfQuestionKey = (key) => {
    const question = response.questions.find((q) => { return q.uniq_key == key })
    if (question) {
      let regex = /%s/;
      if(regex.test(question.title)) {
        return question.title.replace(/%s/g, doctorName)
      } else {
        return question.title
      }
    } else {
      return 'N/A'
    }
  }

  const isVideoKey = (key) => {
    const question = response.questions.find((q) => { return q.uniq_key == key })
    if (question && parseInt(question.question_type) == 2) {
      return true
    }
    return false
  }

  const renderAnswer = (answer) => {
    if(isVideoKey(answer.question_key)) {
      return <>
        <PlayVideo videoKey={answer.value} />
      </>
    } else {
      return answer.value
    }
  }

  return <>
    {
      response && response.answers && response.answers.map((answer, index)=>{
          return (
            <div
              key={index}
              className={cx({ appointmentQuestionWraper: true })}
            >
              <div
                className={cx({ appoinmentQuestionTitle: true })}
              >
                {titleOfQuestionKey(answer.question_key)}
              </div>
              <div
                className={cx({ appointmentQuestionResponse: true })}
              >
                { renderAnswer(answer) }
              </div>
            </div>
          )
        })
      }
  </>
}

const AppointmentQuestionResponseDrawer = (props)=>{
  const { appointmentId, onClose, visible, doctorName } = props;

  const [response, setResponse] = useState({})

  useEffect(()=>{
    fetchResponse.run({appointment_id: appointmentId })
  }, [appointmentId])

  const fetchResponse = useRequest(srvGetPatientAppointmentQuestionResponse, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if(ret.data) {
          setResponse({
            questions: JSON.parse(ret.data.questions),
            answers: JSON.parse(ret.data.answers)
          })
        }
      }
    }
  })

  return <>
    <Drawer
      title={
        <div>
          <h3>Async Follow-Up Video</h3>
        </div>
      }
      closable={true}
      onClose={onClose}
      visible={true}
      width="600"
    >
      <AppointmentResponseContent response={response} doctorName={doctorName}/>
    </Drawer>
  </>
}

export default AppointmentQuestionResponseDrawer;
