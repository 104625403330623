import React, { useState } from 'react';
import {  Typography } from 'antd';

const { Paragraph } = Typography;

import './index.css';

const ParagraphText = (props) => {
  const { text } = props;
  const [ellipsis, setEllipsis] = useState(true);

  const onSee = () => {
    setEllipsis(!ellipsis);
  }

  return (
    <Paragraph
    >
      <span className={ellipsis ? 'ellipsis' : ''}>
        {text}
      </span>
      {text && <a className='see' onClick={onSee}> see {ellipsis ? 'more' : 'less'}</a>}
    </Paragraph>
  )
}

export default ParagraphText;
