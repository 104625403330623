import React, { useState, useEffect } from 'react';
import { connect, Link, history, useRouteMatch, useParams } from 'umi';
import { Layout,  Menu, } from 'antd';

const { Sider, Content } = Layout;

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

import AppointmentRequest from './appointment_request';
import Coverage from './coverage';
const EhrExtraEarn = (props) => {
  const { account, location } = props;
  const { currentUser } = account;

  const params = useParams();

  const [currentTab, setCurrentTab] = useState('appointment_request');

  const switchTab = (e) => {
    setCurrentTab(e.key);
    history.push(`/ehr_extra_earn/${e.key}`);
  };

  useEffect(() => {
    if (params && params.id) {
      setCurrentTab(params.id);
    }
  }, [params]);

  const renderContent = () => {
    if (currentTab == 'appointment_request') {
      return <AppointmentRequest />
    } else if (currentTab == 'coverage' && currentUser?.accept_co_services) {
      return <Coverage />
    }
  } 

  return (
    <Layout>
      <Sider theme="light">
        <Menu selectedKeys={[currentTab]} onClick={switchTab}>
          <Menu.Item key="appointment_request">Appointment Request</Menu.Item>
          {currentUser?.accept_co_services && <Menu.Item key="coverage">Care Coverage</Menu.Item> }
        </Menu>
      </Sider>
      <Content theme="light">
        <div className={cx({ container: true })}>{renderContent()}</div>
      </Content>
    </Layout>
  );
};

export default connect(({ account, loading }) => ({
  account,
  loading,
}))(EhrExtraEarn);
