import React, { useState } from 'react';
import { Form, Modal, Tabs, Table, Button, Row, Col, Alert, Space, Dropdown, Menu, message,Checkbox } from 'antd';
import { createFromIconfontCN, EllipsisOutlined } from '@ant-design/icons';

import cn from 'classnames/bind';
import { useRequest, useMount, useUpdateEffect, useEventEmitter, useSetState } from 'ahooks';
import { useFlag } from '@unleash/proxy-client-react';
import moment from 'moment';

import { srvGetRecommendDrug, srvSyncRxntPrescription, srvPatientDetail, srvPatientPendingTreatmentRequest, srvGetPatientApprovedReferrals, srvOccupyRequests } from '@/services/patient';
import RxntStatus from './RxntStatus';
import PrescriptionPane from './PrescriptionPane';
import PrescriptionHistory from './PrescriptionHistory';
import RxntActiveMedList from './RxntActiveMedList';
import RenewalRequest from './RenewalRequest';
import InitialPrescription from './InitialPrescription';

import styles from './Medications.less';
import { formatUnix } from '@/utils/xtime';
import useRxnt from './useRxnt';
import DrugRecommend from '@/components/DrugRecommend';
import AddNote from '@/components/AddNote';
import ViewNote from '@/components/ViewNote';
import PharmacistNoteTemplate from '@/components/PharmacistNoteTemplate';
const cx = cn.bind(styles);

const Medications = (props) => {
  const { active, id } = props;

  const flagPrescriptionAI = useFlag('enable_ehr_prescription_ai');
  const reloadRxnt$ = useEventEmitter();
  const { rxntStatus } = useRxnt(active, id);
  const [patient, setPatient] = useState({});
  const [currentPrescriptionRequest, setCurrentPrescriptionRequest] = useState(null);
  const [sourceAction, setSourceAction] = useState(null)
  const [noteVisible, setNoteVisible] = useState(false);
  const [recommendList, setRecommendList] = useState({});
  const [referrals, setReferrals] = useState([])
  const [showApproveModel, setShowApproveModel] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selected, setSelected] = useState({});
  const [urgentRefillFlag, setUrgentRefillFlag] = useState(false);
  const [state, setState] = useSetState({
    showNewPatientAlert: false,
    showNewPatientAlertByPrescription: false,
    RXNTCallbackLink: '',
    approveSourceAction: '',
  });

  const loadPatientInfoReq = useRequest(srvPatientDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setPatient(ret.data);
      }
    },
  });

  const loadPatientPendingTreatmentRequestReq = useRequest(srvPatientPendingTreatmentRequest, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setCurrentPrescriptionRequest(ret.data);
        let link = `/api/ehr/rxnt/sso?return_url=${Buffer.from(window.location.href).toString('base64')}&patient_id=${patient.id}&order_type=1`;
        if (ret.data && ret.data.id) {
          link = `/api/ehr/rxnt/sso?return_url=${Buffer.from(window.location.href).toString('base64')}&patient_id=${id}&order_type=2&treatment_request_id=${ret.data.id}`;
        }
        setState({ RXNTCallbackLink: link });
      }
    },
  });

  const srvGetRecommendDrugReq = useRequest(srvGetRecommendDrug, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setRecommendList(ret.data);
      }
    },
  });

  const srvGetPendingReferralReq = useRequest(srvGetPatientApprovedReferrals, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setReferrals(ret.data.items)
      }
    }
  })

  const loadData = () => {
    if (active) {
      loadPatientInfoReq.run({ id });
      loadPatientPendingTreatmentRequestReq.run({ user_id: id })
      if (flagPrescriptionAI) {
        srvGetRecommendDrugReq.run({ user_id: id });
      }
      srvGetPendingReferralReq.run({ id: id });
    }
  };

  const onApprove = (request, source, link) => {
    setUrgentRefillFlag(false);
    const udata = window.sessionStorage.getItem('done-uid');
    if (udata && udata != '' && udata != 'undefined' && request && JSON.stringify(request.doctor_user_id) !== udata && request.urgent_refill_flag) {
      setSelected(request);
      setShowApproveModel(true);
    } else if (request.urgent_refill_flag) {
      const id = request.id;
      srvOccupyNoUrgentReq.run({ id });
    } else {
      let condition = { user_id: id };
      if (request && request.id) condition = { request_id: request.id };
      loadPatientPendingTreatmentRequestReq.runAsync(condition).then(() => {
        setState({ approveSourceAction: source });
        setNoteVisible(true);
      });
    }
  }

  const confirmApprove=(urgentFlag)=>{
    setUrgentRefillFlag(urgentFlag);
    let condition = { user_id: id };
    if (selected && selected.id) condition = { request_id: selected.id };
    loadPatientPendingTreatmentRequestReq.runAsync(condition).then(() => {
      setState({ approveSourceAction: 'PrescriptionMedications' });
      setNoteVisible(true);
    });

  }
  const confimDealRefill = () => {
    //attempt to occupy the refill request
    const id = selected.id;
    srvOccupyReq.run({ id });
  };
  const srvOccupyReq = useRequest(srvOccupyRequests, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (ret.data.has_occupy_flag) {
          message.error('The current request is locked, please try again later', 10);
          setShowApproveModel(false);
          loadList();
          return;
        }
        loadData();
        setShowApproveModel(false);
        setIsChecked(false);
        confirmApprove(true);
      }
    },
  });

  const srvOccupyNoUrgentReq = useRequest(srvOccupyRequests, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (ret.data.has_occupy_flag) {
          message.error('The current request is locked, please try again later', 10);
          loadList();
          return;
        }
        loadData();
        confirmApprove(false);
      }
    },
  });


  useMount(() => {
    loadData();
  });

  useUpdateEffect(() => {
    loadData();
  }, [active, id]);

  reloadRxnt$.useSubscription(() => {
    loadData();
  });

  function onMedData(activeMedList) {
    setState({ showNewPatientAlert: false });
    if (activeMedList.length === 0) {
      setState({ showNewPatientAlert: true });
    }
  }

  function onPrescriptionHistoryData(prescriptionHistory) {
    setState({ showNewPatientAlertByPrescription: false });
    if (prescriptionHistory.total === 0) {
      setState({ showNewPatientAlertByPrescription: true });
    }
  }

  var disableRXNTPerscribe = false
  if (patient.in_person_follow_up_end_date!==0 && patient.in_person_follow_up_end_date < moment.utc().unix()){
    disableRXNTPerscribe = true
  }
  return (
    <div className={cx({ container: true })}>
      {disableRXNTPerscribe && (
        <Alert message={"This patient needs an in-person evaluation or primary care referral in order to get prescribed CII medication."} type={"error"} closable={true}/>
      )}
      {state.showNewPatientAlert && state.showNewPatientAlertByPrescription && patient.country !== 'ca' &&  (
        <Alert
          style={{ minHeight: '46px', marginBottom: '10px', paddingLeft: '8px' }}
          type="error"
          closable
          description="For patients new to stimulant treatment, general guidelines for clinical best practices are to start with low dose, extended release formulations. As always, please follow your best clinical discretion for all medication decisions."
        />
      )}
      {patient.country !== 'ca' && (
          <>
            <h3>Prescribe</h3>
            {flagPrescriptionAI && <DrugRecommend items={recommendList} />}
            {active && patient && patient.rxnt_id != '0' && (
              <PharmacistNoteTemplate
                id={id}
                active={active}
              />
            )}
             <PrescriptionPane
              id={id}
              active={active}
              onApprove={onApprove}
              pendingPrescriptionReqeust={currentPrescriptionRequest}
              rxntStatus={rxntStatus}
              onRefresh={() => {
                loadData();
              }}
              referralRequiredDisableRXNTPerscribe={disableRXNTPerscribe}
            />
          </>
      )}

      {patient && patient.id && noteVisible && (
        <>
          { currentPrescriptionRequest && currentPrescriptionRequest.note_added ? (
            <ViewNote
              RXNTCallbackLink={state.RXNTCallbackLink}
              onRefresh={loadData}
              noteVisible={noteVisible}
              setNoteVisible={setNoteVisible}
              prescriptionInfo={currentPrescriptionRequest}
              patientInfo={patient}
              urgentRefillFlag={urgentRefillFlag}
            />
          ) : (
            <AddNote
              addNoteType={currentPrescriptionRequest && currentPrescriptionRequest.id ? 'approvePrescriptionRequest' : 'approvePrescriptionWithoutRequest'}
              sourceAction={state.approveSourceAction}
              RXNTCallbackLink={state.RXNTCallbackLink}
              onRefresh={loadData}
              noteVisible={noteVisible}
              setNoteVisible={setNoteVisible}
              prescriptionInfo={currentPrescriptionRequest}
              patientInfo={patient}
              urgentRefillFlag={urgentRefillFlag}
              originName={selected.provider_name}
            />
          )}
        </>
      )}

      <RenewalRequest id={id} active={active} patient={patient} reloadRxnt$={reloadRxnt$} onApprove={onApprove} />
      <RxntActiveMedList uid={id} active={active} reloadRxnt$={reloadRxnt$} onData={onMedData} />
      <PrescriptionHistory uid={id} active={active} reloadRxnt$={reloadRxnt$} onData={onPrescriptionHistoryData} />
      <InitialPrescription uid={id} data={referrals} />
      <Modal
            visible={showApproveModel}
            onCancel={() => {
              setShowApproveModel(false);
            }}
            onOk={() => {
              setShowApproveModel(false);
            }}
            footer={null}
          >
            <p
              style={{
                color: '#000000',
                fontSize: '20px',
                fontWeight: '600',
                marginBottom: '20px',
              }}
            >
              Approve the refill request
            </p>
            <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}>
              <span>
                &nbsp;I have checked the patient's PDMP and chart and confirmed that I can prescribe
                for this patient.
              </span>
            </Checkbox>
            <div style={{ display: 'flex', justifyContent: 'space-between', color: '#000000',marginTop:'10px' }}>
              <p style={{ color: '#566BCD', cursor: 'pointer' }} onClick={() => {
                setShowApproveModel(false);
                setIsChecked(false);
              }}>Cancel</p>
              <Button
                key="confirm"
                onClick={() => {
                  confimDealRefill();
                }}
                disabled={!isChecked}
                type="primary"
              >
                Confirm
              </Button>
            </div>
          </Modal>
    </div>
  );
};

export default Medications;
