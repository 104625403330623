import React, { useEffect, useState } from 'react';
import { Button, Tooltip, Space, List, Popover,Menu,Dropdown,Form } from 'antd';
import { useRequest, useMount } from 'ahooks';
import { downloadDocument } from '@/utils/utils';
import { formatUnix } from '@/utils/xtime.js';
import { ProviderDashboardSection, PatientLink } from './index';
import AddNote from '@/components/AddNote';
import ViewNote from '@/components/ViewNote';
import ParagraphText from '@/components/ParagraphText';
import DataSorter from '@/components/DataSorter';
import { history,Link,useSelector,connect } from 'umi';
import moment from 'moment';
import {
  srvFetchRxntPrescriptionOrder,
  srvPatientPendingTreatmentRequest,
  srvRefillRequestPatients,
  srvOccupyRequests,
} from '@/services/patient';
import './index.css';
import { createFromIconfontCN, EnvironmentFilled, CalendarFilled } from '@ant-design/icons';
import defaultSettings from '../../../defaultSettings';
import CallReasonModal from '@/components/PatientInfoPane/CallReasonModal';
import DisputeModal from './../../components/PrescriptionPane/DisputeModal';
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });
const PendingPrescriptionItem = (props) => {
  const { patientInfo, approveAction, loadings,setCallTarget,setVisible,setCallPatient} = props;
  const syncRxntPrescription = useRequest(srvFetchRxntPrescriptionOrder, {
    manual: true,
    onSuccess: (ret, params) => {},
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const { currentUser } = useSelector((state) => state.account);

  const handleDropdownClick = () => {
    setShowDropdown(!showDropdown);
  };
  useMount(() => {
    if (history.location.query.ord_id && String(history.location.query.ord_id).trim() != '' && /^\d+$/.test(history.location.query.ord_id)) {
      syncRxntPrescription.runAsync({ order_id: String(history.location.query.ord_id).trim() }).then(() => {
        props.onRefresh();
        history.replace(`${history.location.pathname}`);
      });
    }
  });

  const renderRequestStatus = (v) => {
    if (v == 'FAILED') {
      return (
        <Tooltip title="Syncing failed, please refresh or prescribe in RXNT again">
          {v}
        </Tooltip>
      )
    }
    return v;
  }
  const subPatientNote = (v) => {
    if (v.bridge_refill_flag){
      if(v.patient_note){
        v.patient_note += `\n`+`patient is moving, need last bridge refill before transfer`
      }else{
        v.patient_note=`patient is moving, need last bridge refill before transfer`
      }
    }
    let patientNote = v.patient_note;
    if (v.status === 1) {
      const reissuePrescriptionNote = v.consultation_id != '0' ? 'Please reissue the prescription.' : '';
      if(patientNote){
        patientNote+=`\n`+reissuePrescriptionNote
      }else{
        patientNote=reissuePrescriptionNote
      }
      if(patientNote){
        patientNote+=`\n`+'New pharmacy after change: ' + v.pharmacy_name
      }else{
        patientNote='New pharmacy after change: ' + v.pharmacy_name
      }
    }
    return <ParagraphText text={patientNote} />;
  };
  const getRefillAction = (patientInfo) => {
    let statusButtons = [];
    let extraButtons = [];
    if (patientInfo.pdmp_object_key) {
      statusButtons.push(<Button
        type="primary"
        size="small"
        danger
        onClick={() => {
          downloadDocument(patientInfo.pdmp_object_key);
        }}
      >
        View PDMP
      </Button>)
    }
    if (patientInfo.request_status == 1 || (patientInfo.request_status == 5 && patientInfo.requested_at <= moment().unix()) || patientInfo.request_status == 8) {
      if (patientInfo.has_occupy_flag) {
        statusButtons.push(<Tooltip title="The current request is being processed">
          <Button
            type="primary"
            size="small"
            disabled={patientInfo.has_occupy_flag}
            onClick={() => {
              approveAction(true, patientInfo);
            }}
            loading={loadings[patientInfo.id]}
          >
            Approve
          </Button>
        </Tooltip>)
      } else {
        statusButtons.push(<Button
          type="primary"
          size="small"
          disabled={patientInfo.has_occupy_flag}
          onClick={() => {
            approveAction(true, patientInfo);
          }}
          loading={loadings[patientInfo.id]}
        >
          Approve
        </Button>)
      }
    }
    if (patientInfo.request_status == 2 && !patientInfo.note_added) {
      statusButtons.push(<Button
        type="primary"
        size="small"
        onClick={() => {
          approveAction(true, patientInfo);
        }}
        loading={loadings[patientInfo.id]}
      >
        Add Notes
      </Button>)
    }
    if (statusButtons.length > 2) {
      let dropdownMenu = (
        <Menu>
          {statusButtons.slice(2).map((button, index) => (
            <Menu.Item key={index}>{button}</Menu.Item>
          ))}
        </Menu>
      );

      extraButtons.push(
        <Dropdown overlay={dropdownMenu} visible={showDropdown} onClick={handleDropdownClick}>
          <Button size="small" style={{ width: '40px' }}>...</Button>
        </Dropdown>
      );

      statusButtons = statusButtons.slice(0, 2);
    }

    return (
      <Space>
        {statusButtons}
        {extraButtons}
      </Space>
    );
  }
  const formatRefillRequestDisplayFormat = (v) => {
    if (!v) {
      return '';
    } else if(patientInfo.requested_at >= moment().unix()) {
      return formatUnix(patientInfo.requested_at)
    } else {
      const fromNow = moment.unix(patientInfo.requested_at).fromNow()
      return `${fromNow}`
    }
  }
  const formatRequestedMedications = (v) => {
    if (!v) {
      return '';
    }
    var requestedMedicationsText = v.refill_active_med_items.map((item) => item.rxnt_active_med_name).join(', \n')
    return requestedMedicationsText;
  }
  const isOverDue = patientInfo?.requested_at && (moment().unix()-patientInfo.requested_at) > 24 * 3600 ? true : false
  const overDueStyle = ((isOverDue&&patientInfo?.request_status_name!='IN-DISPUTE')) ? {color: '#EC5C55', fontWeight: 'bolder', backgroundColor: '#fefafa'} : {}

  return (
    <div className="detail-table-item" style={overDueStyle}>
      <div className="detail-patient-name">
        <Popover
          content={
            <div className="popover-user">
              <div className="popover-user-item username">
                <IconFont className="icon" type="icon-username" />
                <Link to={`/patient/${patientInfo.id}`}>{patientInfo.name}</Link>
              </div>
              <div className="popover-user-item phone">
                <IconFont className="icon" type="icon-phone" />
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setCallTarget('patient');
                    setVisible('callReason', true);
                    setCallPatient(patientInfo);
                  }}
                >
                  Call Patient
                </Button>
              </div>
              <div className="popover-user-item birthdate">
                <CalendarFilled className="icon" />
                {patientInfo.birthdate &&
                  moment.unix(patientInfo.birthdate).utc().format('MM/DD/YYYY')}
              </div>
              <div className="popover-user-item sex">
                <IconFont className="icon" type="icon-sex" />
                {patientInfo.gender}
              </div>
              <div className="popover-user-item state">
                <EnvironmentFilled className="icon" />
                {patientInfo.state}
              </div>
            </div>
          }
          title={patientInfo.name}
          trigger="hover"
        >
          <Link to={`/patient/${patientInfo.id}`}>
            <div>{patientInfo.name}</div>
          </Link>
        </Popover>
      </div>
      <div className="detail-patient-status">
        {renderRequestStatus(patientInfo.request_status_name)}
      </div>
      <div className="detail-requested-date">{formatRefillRequestDisplayFormat(patientInfo.requested_at)} </div>
      {currentUser.enable_refill_individual_medications && (
        <div className="detail-requested-prescription">{formatRequestedMedications(patientInfo)}</div>
      )}
      <div className="detail-requested-notes">
        <div>{patientInfo.admin_note}</div>
        <div>{patientInfo.pdmp_notes}</div>
      </div>
      <div className="detail-patient-note" style={{ whiteSpace: 'pre-wrap'}}>
        <Space>
          {subPatientNote(patientInfo)}
        </Space>
      </div>
      <div className="detail-patient-status">{patientInfo.side_effect}</div>
      <div className="detail-patient-status">{patientInfo.need_change}</div>
      <div className="detail-action">
        {getRefillAction(patientInfo)}
      </div>
    </div>
  );
};

const PendingPrescription = (props) => {
  const { patientList, onRefresh } = props;
  const [currentPrescriptionRequest, setCurrentPrescriptionRequest] = useState(null);
  const [patientInfo, setPatientInfo] = useState(null);
  const [noteVisible, setNotePanelVisiblity] = useState(false);
  const [timeSorter, setTimeSorter] = useState(null);
  const [statusSorter, setStatusSorter] = useState(null);
  const [loadings, setLoadings] = useState({});
  const [select, setSelect] = useState(null);
  const [callPatient, setCallPatient] = useState({});
  const { currentUser } = useSelector((state) => state.account);
  const { oncall$ } = useSelector((state) => state.call);
  const makeCall = (target, reason) => {
    const { account, dispatch } = props;
    const data = {
      patient_id: callPatient.id,
      from_user_id: currentUser.id,
      target,
      first_name: callPatient.first_name,
      last_name: callPatient.last_name,
      reason,
    };

    oncall$.emit(data);
  };


  const [callTarget, setCallTarget] = useState('patient');
  const setVisible = (name, value) => {
    _setVisible((prev) => ({ ...prev, [name]: value }));
  };
  const [visible, _setVisible] = useState({
    callReason: false,
  });
  const approveAction = (visible, request) => {
    console.log('aaa',request)
    if (visible === true) {
      if (request.urgent_refill_flag) {
        const id = request.request_id;
        setSelect(request);
        const loading = {};
        loading[request.id] = true;
        setLoadings(loading);
        srvOccupyNoUrgentReq.run({ id });
      } else {
        const loading = {};
        loading[request.id] = true;
        setLoadings(loading);
        srvPatientPendingTreatmentRequest({ request_id: request.request_id }).then((res) => {
          if (res.data !== null) {
            setCurrentPrescriptionRequest(res.data);
            setPatientInfo(request);
            setNotePanelVisiblity(visible);
            setLoadings({});
          }
        });
      }
    } else {
      setCurrentPrescriptionRequest(false);
      setPatientInfo({});
      setNotePanelVisiblity(visible);
    }
  };

  const srvOccupyNoUrgentReq = useRequest(srvOccupyRequests, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (ret.data.has_occupy_flag) {
          message.error('The current request is locked, please try again later', 10);
          return;
        }
        srvPatientPendingTreatmentRequest({ request_id: select.request_id }).then((res) => {
          if (res.data !== null) {
            setCurrentPrescriptionRequest(res.data);
            setPatientInfo(select);
            setNotePanelVisiblity(true);
            setLoadings({});
          }
        });
      }
    },
  });

  const renderItem = (patientWithPrescriptionInfo) => {
    return (
      <PendingPrescriptionItem
        key={patientWithPrescriptionInfo.request_id}
        approveAction={approveAction}
        patientInfo={patientWithPrescriptionInfo}
        onRefresh={onRefresh}
        loadings={loadings}
        setCallTarget={setCallTarget}
        setVisible={setVisible}
        setCallPatient={setCallPatient}
      />
    );
  };

  const sortBy = (data, requestStatusSorter, requestedAtSorter) => {
    const requestStatusSortOrderAsc = [8, 1, 2];  // failed, new, success
    const requestStatusSortOrderDesc = [2, 1, 8];
  if (requestStatusSorter !== null || requestedAtSorter !== null) {
    data.sort((a, b) => {
      if (a.dispute_approve_flag && !b.dispute_approve_flag) {
        return 1;
      } else if (!a.dispute_approve_flag && b.dispute_approve_flag) {
        return -1;
      } else if (a.dispute_approve_flag && b.dispute_approve_flag) {
        return 0;
      }
      let statusComparison = 0;
      if (requestStatusSorter !== null) {
        const requestStatusSortOrder = requestStatusSorter === 'desc' ? requestStatusSortOrderDesc : requestStatusSortOrderAsc;
        const statusAIndex = requestStatusSortOrder.indexOf(a.request_status);
        const statusBIndex = requestStatusSortOrder.indexOf(b.request_status);
        statusComparison = statusAIndex - statusBIndex;
      }
      if (statusComparison !== 0) {
        return statusComparison;
      }
      if (requestedAtSorter !== null) {
        return requestedAtSorter === 'desc' ? b.requested_at - a.requested_at : a.requested_at - b.requested_at;
      }

      return 0;
    });
  }
    return data;
  }

  return (
    <>
      <List
        itemLayout="vertical"
        pagination={patientList.length > 0 ? { pageSize: 10 } : false}
        locale={{ emptyText: ' ' }}
        style={{overflowX: 'auto',overflowY:'hidden'}}
        dataSource={sortBy(patientList, statusSorter, timeSorter)}
        header={
          <div className="detail-table-header">
            <div className="detail-patient-name">Patient</div>
            <div className="detail-patient-status">
              <DataSorter title="Status" sorter={statusSorter} setSorter={setStatusSorter} />
            </div>
            <div className="detail-requested-date">
              <DataSorter title="Time Requested" sorter={timeSorter} setSorter={setTimeSorter} />
            </div>
            {currentUser.enable_refill_individual_medications && (
            <div className="detail-requested-prescription">Requested Medication</div>
            )}
            <div className="detail-requested-notes">Admin Notes</div>
            <div className="detail-patient-note">Patient Note</div>
            <div className="detail-patient-status">Side Effects</div>
            <div className="detail-patient-status">Patient needs medication change</div>
            <div className="detail-action">Action</div>
          </div>
        }
        renderItem={renderItem}
      />

      {patientInfo && currentPrescriptionRequest && (
        <>
          {currentPrescriptionRequest.note_added ? (
            <ViewNote
              onRefresh={onRefresh}
              noteVisible={noteVisible}
              setNoteVisible={setNotePanelVisiblity}
              prescriptionInfo={currentPrescriptionRequest}
              patientInfo={patientInfo}
            />
          ) : (
            <AddNote
              addNoteType={'approvePrescriptionRequest'}
              sourceAction={'PendingPrescriptionRenewal'}
              onRefresh={onRefresh}
              noteVisible={noteVisible}
              setNoteVisible={setNotePanelVisiblity}
              prescriptionInfo={currentPrescriptionRequest}
              patientInfo={patientInfo}
            />
          )}
        </>
      )}
      <CallReasonModal
        visible={visible.callReason}
        filterFirstReason={true}
        onOk={(reason) => {
          setVisible('callReason', false);
          makeCall(callTarget, reason);
        }}
        onCancel={() => {
          setVisible('callReason', false);
        }}
      />
    </>
  );
};

const PendingPrescriptionRenewal = (props) => {
  const [pendingPrescriptionPatientList, setPendingPrescriptionPatientList] = useState({});
  const refresh = () => {
    srvRefillRequestPatients({
      status: 3
    }).then((res) => {
      if (res.data !== null) {
        setPendingPrescriptionPatientList(res.data);
      }
    });
  };

  useEffect(() => {
    refresh();
  }, []);
  return (
    <ProviderDashboardSection
      sectionName="Pending Prescription Renewal Requests"
      itemNumber={pendingPrescriptionPatientList.total}
      viewAllUrl={'/ehr_renewal'}
    >
      <PendingPrescription onRefresh={refresh} patientList={pendingPrescriptionPatientList.items || []}/>
    </ProviderDashboardSection>
  );
};

export default PendingPrescriptionRenewal;
