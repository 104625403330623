import React, { useState } from 'react';
import { Button, Table } from 'antd';
import { history, connect } from 'umi';
import cn from 'classnames/bind';
import { useUpdateEffect, useRequest, useMount } from 'ahooks';
import { Device, Call } from '@twilio/voice-sdk';

import { srvGetTwilioCallLogPaged } from '@/services/patient';
import { formatUnix } from '@/utils/xtime';
import styles from './index.less';
const cx = cn.bind(styles);

const EHRVoice = (props) => {
  const { active, id } = props;
  const [data, setData] = useState({
    total: 0,
    items: [],
  });
  const [query, setQuery] = useState({ patient_id: id });
  const [page, setPage] = useState(1);

  const listReq = useRequest(srvGetTwilioCallLogPaged, {
    manual: true,
    onSuccess: (ret, params) => {
      setData(ret.data);
    },
  });
  const columns = [
    {
      title: 'Date',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
    },
    {
      title: 'Provider',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        return `${r.from_user_name}`;
      },
    },
    {
      title: 'Patient',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        return `${r.patient.first_name} ${r.patient.last_name}`;
      },
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      ellipsis: true,
      width: 100,
      render:(v, r)=>{
        return new Date(v * 1000).toISOString().slice(14, 19)
      }
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      ellipsis: true,
    },
  ];

  useMount(() => {
    setPage(1);
    loadList({ page: 1, page_size: 20, filters: JSON.stringify(query) });
  });

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList({ page: 1, page_size: 20, filters: JSON.stringify(q) }, () => {});
  };

  useUpdateEffect(() => {
    setPage(1);
    setQuery({ patient_id: id });
    loadList({ page: 1, page_size: 20, filters: JSON.stringify({ patient_id: id }) }, () => {});
  }, [active, id]);

  const loadList = (payload) => {
    listReq.run(payload);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadList({
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: JSON.stringify(query),
    });
  };

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Call Logs</h3>
      </div>
      <Table
        dataSource={data.items}
        columns={columns}
        rowKey="id"
        rowClassName={(r) => {
          return '';
        }}
        loading={listReq.loading}
        onChange={handleTableChange}
        pagination={{ pageSize: 20, current: page, simple: true, total: data.total }}
      />
    </div>
  );
};

export default connect(({ call }) => ({ call }))(EHRVoice);
