import React, { useEffect, useState } from 'react';
import {
  srvPatientIdentity,
  srvUploadToken,
  srvUploadPatientIdentity,
  srvGetPaitentDocument,
  srvDeletePaitentDocument,
  srvUploadDocument,
  srvGetDocumentURL,
  srvGetPaitentInsurance,
  srvUploadInsurance,
  srvGetPaitentConsultationDocument,
  srvPatientConsultationReplies,
  srvGetConsultationDocumentURL,
  srvGetPaitentDiagnosisProof,
  srvUploadDiagnosisProof
} from '@/services/patient';
import {
  srvConsultationReplies,
  srvRequestPatientReUploadIdentity,
} from '@/services/admin';
import ConsultationDrawer from '@/components/ConsultationDrawer';
import { history,useAccess } from 'umi';
import { Upload, Button, Spin, Modal, message } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useRequest, useMount, useUnmount, useUpdateEffect, useUnmountedRef } from 'ahooks';

import request from 'umi-request';

import { useFlag } from '@unleash/proxy-client-react';

import './PatientDocument.css';

const { Dragger } = Upload;

const UploadFile = (props) => {
  const { closePanel, loading, uploadFileCategory, uploadFile } = props;

  let uploadTitle = 'Attach File';
  if (uploadFileCategory === 'identity') {
    uploadTitle = 'Replace File';
  }

  let acceptFileCategory = 'image/*';
  if (uploadFileCategory === 'document') {
    acceptFileCategory = '';
  }

  return (
    <>
      <div className="upload-file-modal">
        {loading && <Spin className="loading" size="large" delay={500} />}
        <div className="upload-header">{uploadTitle}</div>
        <div className="upload-content">
          <Dragger accept={acceptFileCategory} className="upload-panel" customRequest={uploadFile}>
            <p className="ant-upload-text">
              Drag and drop your file here{' '}
              <span className="upload-link">or upload from your computer</span>
            </p>
          </Dragger>
        </div>
      </div>
      <div onClick={closePanel} className="upload-file-mask" />
    </>
  );
};

const downloadDocument = (objectKey) => {
  srvGetDocumentURL({
    object_key: objectKey,
  }).then((res) => {
    let url = res.data;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'download');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

const downloadConsultationDocument = (objectKey) => {
  srvGetConsultationDocumentURL({
    object_key: objectKey,
  }).then((res) => {
    let url = res.data;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'download');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

const PhotoButtons = (props)=> {
  const access=useAccess();
  const {
    thumbnailName,
    photoCategoryName,
    setShowUploadFile,
    setUploadFileCategory,
    previewPhoto,
    hasPhoto,
  } = props;

  let thumbnailDetail = hasPhoto ? (
    <>
      <img className="identity-img-small" />
      <div className="replace-button">
        <Button
          type="primary"
          onClick={previewPhoto}
        >
          Preview
        </Button>
        <>
          {!(history.location.query.admin && access.adminAccesser) && (
            <>
              <Button
                className="file-delete-button"
                size="small"
                danger
                type="text"
                onClick={() => {
                  deleteDocument(file.id);
                }}
              >
                Delete
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setShowUploadFile(true);
                  setUploadFileCategory(photoCategoryName);
                }}
              >
                Replace
              </Button>
            </>
          )}
        </>
      </div>
    </>
  ) : (
    <>
      <img className="identity-img-small" />
      <div className="replace-button">
        <Button
          type="primary"
          onClick={() => {
            setShowUploadFile(true);
            setUploadFileCategory(photoCategoryName);
          }}
        >
          Upload
        </Button>
      </div>
    </>
  );

  return (
    <div className="img-container">
      <div> {thumbnailName}</div>
      {thumbnailDetail}
    </div>
  );
}

const InsurancePhotoButtons = (props) => {
  const {
    hasInsuranceBack,
    hasInsuranceFront,
    previewPhoto,
  } = props;

  const previewBack = ()=> {
    previewPhoto('back')
  }

  const previewFront = ()=> {
    previewPhoto('front')
  }

  return (
    <>
      <PhotoButtons
        thumbnailName="Insurance Front"
        photoCategoryName="insurance-front"
        hasPhoto={hasInsuranceFront}
        previewPhoto={previewFront}
       />
      <PhotoButtons
        thumbnailName="Insurance Back"
        photoCategoryName="insurance-back"
        hasPhoto={hasInsuranceBack}
        previewPhoto={previewBack}
       />
    </>
  );
};

const PhotoThumbnail = (props) => {
  const access=useAccess();
  const {
    thumbnailName,
    imgSrc,
    photoCategoryName,
    setShowPreview,
    setShowUploadFile,
    setUploadFileCategory,
    setpreviewPhotoURL,
    setPreviewFileType,
  } = props;
  const [showReplaceButton, setShowReplaceButton] = useState(false);
  const [fileContentType, setFileContentType] = useState('');

  useEffect(() => {
    if (imgSrc === '') {
      return;
    }

    fetch(imgSrc)
      .then((res) => {
        const contentType = res.headers.get('Content-Type');
        if (contentType === 'application/pdf') {
          setPreviewFileType(contentType);
          setFileContentType(contentType);
        }
      })
      .catch((e) => {
        window.sendErrorLog(e);
      });
  }, [imgSrc]);

  let thumbnailDetail = imgSrc ? (
    <>
      {fileContentType === 'application/pdf' ? (
        <object
          onMouseEnter={() => setShowReplaceButton(true)}
          className="identity-img-small"
          data={imgSrc}
          type="application/pdf"
         />
      ) : (
        <img
          onMouseEnter={() => setShowReplaceButton(true)}
          className="identity-img-small"
          src={imgSrc}
         />
      )}
      {showReplaceButton && (
        <div className="replace-button">
          <Button
            type="primary"
            onClick={() => {
              setShowPreview(true);
              setpreviewPhotoURL(imgSrc);
            }}
          >
            Preview
          </Button>
          {!(history.location.query.admin && access.adminAccesser) && (
            <Button
              type="primary"
              onClick={() => {
                setShowUploadFile(true);
                setUploadFileCategory(photoCategoryName);
              }}
            >
              Replace
            </Button>
          )}
        </div>
      )}
    </>
  ) : (
    <>
      <img onMouseEnter={() => setShowReplaceButton(true)} className="identity-img-small" />
        {(showReplaceButton && !(history.location.query.admin && access.adminAccesser)) && (
        <div className="replace-button">
          <Button
            type="primary"
            onClick={() => {
              setShowUploadFile(true);
              setUploadFileCategory(photoCategoryName);
            }}
          >
            Upload
          </Button>
        </div>
      )}
    </>
  );

  return (
    <div className="img-container" onMouseLeave={() => setShowReplaceButton(false)}>
      <div> {thumbnailName}</div>
      {thumbnailDetail}
    </div>
  );
};

const InsurancePhoto = (props) => {
  const {
    insurancePhoto,
    setShowPreview,
    setpreviewPhotoURL,
    setShowUploadFile,
    setUploadFileCategory,
  } = props;
  let photoFrontUrl = '';
  let photoBackUrl = '';

  for (let i = 0; i < insurancePhoto.length; i++) {
    if (insurancePhoto[i].photo_side === 1) {
      photoFrontUrl = insurancePhoto[i].photo_url;
    } else {
      photoBackUrl = insurancePhoto[i].photo_url;
    }
  }
  return (
    <>
      <PhotoThumbnail
        thumbnailName="Insurance Front"
        photoCategoryName="insurance-front"
        imgSrc={photoFrontUrl}
        setShowUploadFile={setShowUploadFile}
        setUploadFileCategory={setUploadFileCategory}
        setShowPreview={setShowPreview}
        setpreviewPhotoURL={setpreviewPhotoURL}
       />
      <PhotoThumbnail
        thumbnailName="Insurance Back"
        photoCategoryName="insurance-back"
        imgSrc={photoBackUrl}
        setShowUploadFile={setShowUploadFile}
        setUploadFileCategory={setUploadFileCategory}
        setShowPreview={setShowPreview}
        setpreviewPhotoURL={setpreviewPhotoURL}
       />
    </>
  );
};

const FileList = (props) => {
  const access=useAccess();
  const documents = props.documents;
  const deleteDocument = props.deleteDocument;
  const isConsultationDocuments = props.isConsultationDocuments;
  const [replyData, setReplyData] = useState({ consultation: {}, consultation_replies: [] });
  const [selectedType,setSelectedType]=useState(-1);
  const [visible, setVisible] = useState(false);
  const repliesReq = useRequest(props.admin?srvConsultationReplies:srvPatientConsultationReplies, {
    manual: true,
    onSuccess: (ret, params) => {
      setReplyData(ret.data);
      setSelectedType(ret.data.consultation.consultation_type);
    },
  });
  const onClose = () => {
    setVisible(false);
  };
  const documentsJsx = documents.map((file) => (

    <div key={file.id} className="file-item">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7608 11.9906H5.97825C5.67821 11.9906 5.43474 12.2458 5.43474 12.5604V12.8442C5.43474 13.159 5.67821 13.4142 5.97825 13.4142H15.7608C16.0608 13.4142 16.3043 13.1588 16.3043 12.8442V12.5604C16.3046 12.2458 16.0608 11.9906 15.7608 11.9906ZM10.6355 17.125H5.95484C5.66764 17.125 5.43476 17.3814 5.43476 17.6974V17.9854C5.43476 18.3012 5.66764 18.5575 5.95484 18.5575H10.6355C10.9227 18.5575 11.1556 18.3015 11.1556 17.9854V17.6974C11.1556 17.3814 10.9227 17.125 10.6355 17.125ZM20.5949 2.53906e-05H1.14415C0.512053 2.53906e-05 0 0.530385 0 1.18478V24.791C0 25.4451 0.512078 25.9758 1.14415 25.9758H14.9898C15.2007 25.9318 15.5739 25.8031 15.9668 25.422L21.1621 20.2118C21.1621 20.2118 21.6131 19.7771 21.7394 19.0539V1.18475C21.7391 0.530385 21.2267 0 20.5949 0V2.53906e-05ZM20.3046 20.0337C20.3037 20.0192 20.3017 20.0049 20.3003 19.9906L20.3046 19.9863V20.0337ZM20.3046 18.8401H15.8623C14.8421 18.8401 14.5904 19.1468 14.5904 20.1203V23.055L14.5898 23.0556V24.5521H2.0637C1.71623 24.5521 1.43475 24.2607 1.43475 23.902V2.07434C1.43475 1.71486 1.71623 1.42396 2.0637 1.42396H19.6756C20.0231 1.42396 20.3046 1.71486 20.3046 2.07434V18.8402V18.8401ZM15.7608 6.56183H5.97825C5.67821 6.56183 5.43474 6.81787 5.43474 7.1339V7.42193C5.43474 7.73769 5.67821 7.99401 5.97825 7.99401H15.7608C16.0608 7.99401 16.3043 7.73797 16.3043 7.42193V7.1339C16.3046 6.81815 16.0608 6.56183 15.7608 6.56183Z"
          fill="#083EA8"
        />
      </svg>
      <a
        onClick={() => {
          if(isConsultationDocuments){
            downloadConsultationDocument(file.object_key)
          }else{
            downloadDocument(file.object_key);
          }

        }}
        className="file-name"
      >
        {file.file_name}{' '}
      </a>
      {isConsultationDocuments ? (
        <Button
          className="file-consultation-button"
          size="small"
          onClick={() => {
            repliesReq.run({ id: file.consultation_id });
            setVisible(true);
          }}
        >
          View Consultation
        </Button>
      ) : (
          <>
            {!(history.location.query.admin && access.adminAccesser) && (
              <Button
                className="file-delete-button"
                size="small"
                danger
                type="text"
                onClick={() => {
                  deleteDocument(file.id);
                }}
              >
                Delete
              </Button>)}
          </>
      )}
      {<ConsultationDrawer
          role={props.admin?"admin":"doctor"}
          visible={visible}
          replyData={replyData}
          userId={props.patientID}
          onClose={onClose}
          selectedType={selectedType}
          onReply={() => { repliesReq.run({ id: replyData.consultation.id }) }}
        />}
    </div>
  ));

  return <div className="file-list">{documentsJsx}</div>;
};

const Patientdocument = (props) => {
  const access=useAccess();
  const { patientID, hasIdentity, hasInsuranceBack, hasInsuranceFront, refresh,previousDiagnosisFlag  } = props;
  const [identityUrl, setIdentityUrl] = useState('');
  const [showUplodFile, setShowUploadFile] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [consultationDocuments, setConsultationDocuments] = useState([]);
  const [reuploadIDModalVisible, setReuploadIDModalVisible] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [fileIDToDelete, setFileIDToDelete] = useState('');
  const [uploadFileCategory, setUploadFileCategory] = useState('');
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [insurancePhoto, setInsruancePhoto] = useState([]);
  const [previewPhotoURL, setpreviewPhotoURL] = useState('');
  const [previewFileType, setPreviewFileType] = useState('');
  const [previousDiagnosisProofUrl, setPreviousDiagnosisProofUrl] = useState('');
  const unmountedRef = useUnmountedRef();

  const ehrPatientDocumentsDisplay = useFlag('ehr_patient_documents');

  const getPaitentDocumentReq = useRequest(srvGetPaitentDocument, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (!unmountedRef.current) {
          setDocuments(ret.data);
        }
      }
    },
  });
  const getPaitentConsultationDocumentReq = useRequest(srvGetPaitentConsultationDocument, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (!unmountedRef.current) {
          setConsultationDocuments(ret.data);
        }
      }
    },
  });

  const getPaitentInsuranceReq = useRequest(srvGetPaitentInsurance, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (!unmountedRef.current) {
          setInsruancePhoto(ret.data);
        }
      }
    },
  });

  const getPaitentDiagnosisProofReq = useRequest(srvGetPaitentDiagnosisProof, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (!unmountedRef.current) {
          setPreviousDiagnosisProofUrl(ret.data?.file_url);
        }
      }
    },
  });

  const getPaitentIdentityReq = useRequest(srvPatientIdentity, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.data) {
        if (!unmountedRef.current) {
          setIdentityUrl(ret.data.url);

          if (ehrPatientDocumentsDisplay) {
            fetchPreviewInfo(ret.data.url);
          }
        }
      }
    },
  });

  const requestReUploadIDReq = useRequest(srvRequestPatientReUploadIdentity, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setReuploadIDModalVisible(false);
        message.success('successfully reqeusted');
      }
    },
  });

  const fetchPreviewInfo = (url)=>{
    fetch(url)
      .then((res) => {
        const contentType = res.headers.get('Content-Type');
        if (contentType === 'application/pdf') {
          setPreviewFileType(contentType);
          setFileContentType(contentType);
        }
        setShowPreview(true);
        setpreviewPhotoURL(url);
      })
      .catch((e) => {
        window.sendErrorLog(e);
      });
  }

  const loadData = async () => {
    if (patientID) {
      getPaitentDocumentReq.run({ uid: patientID });
      getPaitentConsultationDocumentReq.run({ uid: patientID });
      if (!ehrPatientDocumentsDisplay) {
        getPaitentInsuranceReq.run({ uid: patientID });
        getPaitentIdentityReq.run({ id: patientID });
        getPaitentDiagnosisProofReq.run({ uid: patientID });
      }
    }
  };

  useMount(() => {
    loadData();
  });

  useUpdateEffect(() => {
    loadData();
  }, [patientID]);

  const deleteDocument = (id) => {
    if (id === '') {
      return;
    }
    setConfirmLoading(true);
    srvDeletePaitentDocument({
      document_id: id,
    })
      .then((res) => {
        if (res.code === 200) {
          setDocuments(documents.filter((item) => item.id !== id));
          setDeleteConfirm(false);
          setConfirmLoading(false);
          setFileIDToDelete('');
        }
      })
      .catch(() => {
        setConfirmLoading(false);
      });
  };

  const deleteDocumentConfirm = (id) => {
    setDeleteConfirm(true);
    setFileIDToDelete(id);
  };

  const actionAfterUploadToStorageSuccessfully = (objectKey, file, onSuccess, onError) => {
    if (uploadFileCategory === 'identity') {
      srvUploadPatientIdentity({ user_id: patientID, object_key: objectKey }).then((res) => {
        srvPatientIdentity({
          id: patientID,
        }).then((res) => {
          if (res.code === 200) {
            if (res.data != null) {
              onSuccess('OK');
              setIdentityUrl(res.data.url);

              if (ehrPatientDocumentsDisplay) {
                refresh()
              }
            }
          }
        });
        setUploadFileLoading(false);
        setShowUploadFile(false);
      });
    } else if (
      uploadFileCategory === 'insurance-back' ||
      uploadFileCategory === 'insurance-front'
    ) {
      srvUploadInsurance({
        uid: patientID,
        file_name: file.name,
        file_type: file.type,
        object_key: objectKey,
        photo_side: uploadFileCategory === 'insurance-front' ? 'front' : 'back',
      })
        .then((res) => {
          srvGetPaitentInsurance({
            uid: patientID,
          }).then((res) => {
            if (res.code === 200) {
              setInsruancePhoto(res.data);
              setUploadFileLoading(false);
              setShowUploadFile(false);
              onSuccess('ok');

              if (ehrPatientDocumentsDisplay) {
                refresh()
              }
            }
          });
        })
        .catch((err) => {
          setUploadFileLoading(false);
          onError({ err });
        });
    } else if (uploadFileCategory === 'document') {
      srvUploadDocument({
        user_id: patientID,
        file_name: file.name,
        file_type: file.type,
        object_key: objectKey,
      })
        .then((res) => {
          srvGetPaitentDocument({
            uid: patientID,
          }).then((res) => {
            if (res.code === 200) {
              setDocuments(res.data);
            }
          });
          setUploadFileLoading(false);
          setShowUploadFile(false);
          onSuccess('ok');
        })
        .catch((err) => {
          onError({ err });
          setUploadFileLoading(false);
        });
    }else if (
      uploadFileCategory === 'proof-of-previous-diagnosis'
    ) {
      srvUploadDiagnosisProof({
        uid: patientID,
        file_name: file.name,
        file_type: file.type,
        object_key: objectKey,
      })
        .then((res) => {
          srvGetPaitentDiagnosisProof({
            uid: patientID,
          }).then((res) => {
            if (res.code === 200) {
              setPreviousDiagnosisProofUrl(res.data?.file_url);
              setUploadFileLoading(false);
              setShowUploadFile(false);
              onSuccess('ok');

              if (ehrPatientDocumentsDisplay) {
                refresh()
              }
            }
          });
        })
        .catch((err) => {
          setUploadFileLoading(false);
          onError({ err });
        });
    }
  };

  const uploadFile = (uploadPara) => {
    let file = uploadPara.file;
    let onSuccess = uploadPara.onSuccess;
    let onError = uploadPara.onError;
    setUploadFileLoading(true);
    let objectKey = '';
    srvUploadToken({ type: 1 }).then((res) => {
      objectKey = res.data.object_key;
      file.arrayBuffer().then((content) => {
        request
          .put(res.data.upload_url, {
            data: content,
            headers: { 'Content-Type': file.type },
          })
          .then(() => {
            actionAfterUploadToStorageSuccessfully(objectKey, file, onSuccess, onError);
          })
          .catch((err) => {
            onError(err);
            setUploadFileLoading(false);
          });
      });
    });
  };

  const previewIdentity = ()=> {
    getPaitentIdentityReq.run({ id: patientID })
  }
  const previewPreviousDiagnosisProof = ()=> {
    getPaitentDiagnosisProofReq.run({ id: patientID })
  }

  const previewInsurancePhoto = (photoType) => {
    getPaitentInsuranceReq.runAsync({ uid: patientID }).then((data)=>{
      let photoFrontUrl = '';
      let photoBackUrl = '';
      const photos = data.data
      for (let i = 0; i < photos.length; i++) {
        if (photos[i].photo_side === 1) {
          photoFrontUrl = photos[i].photo_url;
        } else {
          photoBackUrl = photos[i].photo_url;
        }
      }
      if (photoType == 'back') {
        fetchPreviewInfo(photoBackUrl)
      } else if (photoType == 'front') {
        fetchPreviewInfo(photoFrontUrl)
      }
    });
  }

  const identityAndInsurancePhotos = ()=>{
    if (ehrPatientDocumentsDisplay) {
      return (
        <>
          <PhotoButtons
            thumbnailName="ID"
            photoCategoryName="identity"
            imgSrc={identityUrl}
            hasPhoto={hasIdentity}
            previewPhoto={previewIdentity}
           />
          <InsurancePhotoButtons
            previewPhoto={previewInsurancePhoto}
            hasInsuranceBack={hasInsuranceBack}
            hasInsuranceFront={hasInsuranceFront}
           />
            {previousDiagnosisProofUrl&&<PhotoButtons
            thumbnailName="Proof of Previous Diagnosis"
            photoCategoryName="proof-of-previous-diagnosis"
            imgSrc={previousDiagnosisProofUrl}
            hasPhoto={previousDiagnosisFlag}
            previewPhoto={previewPreviousDiagnosisProof}
           />}
        </>
      )
    } else {
      return (
        <>
          <div style={{ display: 'inline-table', }}>
            <PhotoThumbnail
              thumbnailName="ID"
              photoCategoryName="identity"
              imgSrc={identityUrl}
              setShowUploadFile={setShowUploadFile}
              setUploadFileCategory={setUploadFileCategory}
              setShowPreview={setShowPreview}
              setPreviewFileType={setPreviewFileType}
              setpreviewPhotoURL={setpreviewPhotoURL}
             />
            {identityUrl && (
              <div style={{ marginTop: '2px' }}>
                <a href='#' style={{ color: '#566BCD' }} onClick={() => { setReuploadIDModalVisible(true); }}>Request ID Re-upload</a>
              </div>
            )}
          </div>
          <InsurancePhoto
            insurancePhoto={insurancePhoto}
            setShowPreview={setShowPreview}
            setPreviewFileType={setPreviewFileType}
            setpreviewPhotoURL={setpreviewPhotoURL}
            setShowUploadFile={setShowUploadFile}
            setUploadFileCategory={setUploadFileCategory}
           />
          {previousDiagnosisProofUrl&&
          <PhotoThumbnail
            thumbnailName="Proof of Previous Diagnosis"
            photoCategoryName="proof-of-previous-diagnosis"
            imgSrc={previousDiagnosisProofUrl}
            setShowUploadFile={setShowUploadFile}
            setUploadFileCategory={setUploadFileCategory}
            setShowPreview={setShowPreview}
            setPreviewFileType={setPreviewFileType}
            setpreviewPhotoURL={setpreviewPhotoURL}
           />}
        </>
      )
    }
  }

  return (
    <div className="patient-document">
      <div className="document-header">
        <div className="document-title">Media & File Attachments</div>
        {!(history.location.query.admin && access.adminAccesser) && (
          <Button
            type="primary"
            onClick={() => {
              setShowUploadFile(true);
              setUploadFileCategory('document');
            }}
          >
            {' '}
            Attach File
          </Button>
        )}
      </div>
      <div>
        { identityAndInsurancePhotos() }
        <FileList documents={documents} deleteDocument={deleteDocumentConfirm} userID={patientID}  />
        <FileList documents={consultationDocuments} isConsultationDocuments={true} userID={patientID} admin={props.admin} />
      </div>
      {showUplodFile && (
        <UploadFile
          closePanel={() => {
            setShowUploadFile(false);
          }}
          loading={uploadFileLoading}
          patientID={patientID}
          uploadFileCategory={uploadFileCategory}
          uploadFile={uploadFile}
         />
      )}
      {showPreview && (
        <>
          <div className="img-preview-file-container">
            <CloseCircleOutlined
              className="close-button"
              onClick={() => {
                setShowPreview(false);
              }}
            />
            {previewFileType === 'application/pdf' ? (
              <object
                className="identity-img-large"
                data={previewPhotoURL}
                type="application/pdf"
               />
            ) : (
              <img className="identity-img-large" src={previewPhotoURL} />
            )}
          </div>
          <div
            onClick={() => {
              setShowPreview(false);
            }}
            className="upload-file-mask"
          />
        </>
      )}
      <Modal
        visible={deleteConfirm}
        onOk={() => {
          deleteDocument(fileIDToDelete);
        }}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setDeleteConfirm(false);
        }}
      >
        <p>Do you really want to delete this file?</p>
      </Modal>

      <Modal
        title='Request the patient to re-upload their ID'
        okText='Send'
        visible={reuploadIDModalVisible}
        loading={requestReUploadIDReq.loading}
        onOk={() => {
          requestReUploadIDReq.run({ user_id: patientID })
        }}
        onCancel={() => {
          setReuploadIDModalVisible(false);
        }}
      >
        <p>After sending, the patient will receive a message asking them to re-upload the ID.</p>
      </Modal>
    </div>
  );
};

export default Patientdocument;
