import React, { useState, useEffect } from 'react';
import { Drawer, Form, Space, Input, Button, Typography,Modal } from 'antd';

import { CPTCodesMap } from '@/components/CPTCodesFormItem';
import { formatLastRefillDate } from '@/components/LastRefiiDateFormItem';
import { formatUnix, formatNextFollowUpMonth } from '../../utils/xtime';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

const ViewNote = (props) => {
  const {
    noteVisible,
    setNoteVisible,
    RXNTCallbackLink,
    onRefresh,
    prescriptionInfo: prescription,
    patientInfo: patient,
    urgentRefillFlag
  } = props;

  const [form] = Form.useForm();
  const [showUrgentRefillRemider, setShowUrgentRefillRemider] = useState(false);

  useEffect(() => {
    if (prescription && prescription.id) {
      form.setFieldsValue(prescription);
    }
  }, [prescription])
  const handleConfirmUrgentRxnt = () => {
    let link = `/api/ehr/rxnt/sso?return_url=${Buffer.from(window.location.href).toString(
      'base64',
    )}&patient_id=${patient.id}&order_type=2&treatment_request_id=${prescription.id}`;
    window.location.href = link;
  };

  const noteHeaderDom = () => {
    let text = 'Refill Request Note';
    if (prescription.note_type == '1') {
      text = 'Initial Evaluation';
    } else if (prescription.note_type == '2') {
      text = 'Follow-Up Evaluation';
    } else if (prescription.note_type == '11') {
      text = 'Follow-Up: Transfer of Care';
    } else if (prescription.note_type == '3') {
      text = 'Refill Request Note';
    } else if (prescription.note_type == '4') {
      text = 'Transfer Note';
    } else if (prescription.note_type == '5') {
      text = 'Discharge Note';
    } else if (prescription.note_type == '7') {
      text = 'Coverage Note';
    } else if (prescription.note_type == '8') {
      text = 'Plain Note'
    }
      return (
      <div className={cx({ title: true })}>
        {text}
        {prescription.status != 2 && (
          <Button
            type="primary"
            onClick={() => {
              if (prescription.note_added) {
                if (!urgentRefillFlag) {
                  let link = `/api/ehr/rxnt/sso?return_url=${Buffer.from(
                    window.location.href,
                  ).toString('base64')}&patient_id=${
                    patient.id
                  }&order_type=2&treatment_request_id=${prescription.id}`;
                  if (RXNTCallbackLink) link = RXNTCallbackLink;
                  window.location.href = link;
                } else {
                  setShowUrgentRefillRemider(true);
                }
              } else {
                onRefresh();
              }
            }}
          >
            Prescribe in RXNT
          </Button>
        )}
      </div>
    );
  }

  const noteContentDom = () => {
    return (
      <Form
        layout="vertical"
        form={form}
        className={cx({ multiNotesForm: true })}
      >
        <Form.Item
          name="diagnostic_code"
          label="Diagnostic Code"
        >
          <Typography.Text>
            {prescription.diagnostic_code && <pre>{prescription.diagnostic_code}</pre>}
          </Typography.Text>
        </Form.Item>
        <div className={cx({ rxTitle: true })}>
          <label htmlFor="rx" title="Rx" className={cx({ rxLabel: true })}>
            Rx
          </label>
        </div>
        <Form.Item
          name="rx"
        >
          <Typography.Text>
            {prescription.rx && <pre>{prescription.rx}</pre>}
          </Typography.Text>
        </Form.Item>

        <Form.Item
          name="last_refill_date"
          label="Last Refill Date"
        >
          <Typography.Text>
            {formatLastRefillDate(prescription.last_refill_date)}
          </Typography.Text>
        </Form.Item>

        <Form.Item
          name="cpt_code"
          label="CPT Code"
        >
          <Typography.Text>
            <pre>{CPTCodesMap[prescription.cpt_code] ? CPTCodesMap[prescription.cpt_code] : prescription.cpt_code}</pre>
          </Typography.Text>
        </Form.Item>

        {prescription.addon_code && (
          <Form.Item
            name="addon_code"
            label="Add-on Code"
          >
            <Typography.Text>
              <pre>{CPTCodesMap[prescription.addon_code] ? CPTCodesMap[prescription.addon_code] : prescription.addon_code}</pre>
            </Typography.Text>
          </Form.Item>
        )}

        <Form.Item
          name="attempted_medications"
          label="Attempted Medications">
          <Typography.Text>
            {prescription.attempted_medications && <pre>{prescription.attempted_medications}</pre>}
          </Typography.Text>
        </Form.Item>

        {
          prescription.note_type != '6' && prescription.next_follow_up_date > 0 && prescription.next_follow_up_month > 0 &&
          <>
            <Form.Item
              name="next_follow_up_date"
              label="Next Follow Up Date"
            >
              <Typography.Text>
                {prescription.next_follow_up_date > 0 && <pre>{formatUnix(prescription.next_follow_up_date, 'MMM D, YYYY')}</pre>}
              </Typography.Text>
            </Form.Item>

            <Form.Item
              name="next_follow_up_month"
              label="I‘d like to remind this patient to schedule their next follow-up appointment in"
            >
              <Typography.Text>
                {formatNextFollowUpMonth(prescription.next_follow_up_month)}
              </Typography.Text>
            </Form.Item>
          </>
        }

        <Form.Item
          name="provider_notes"
          label="Provider Notes"
        >
          <Typography.Paragraph>
            {prescription.provider_notes && <pre>{prescription.provider_notes}</pre>}
          </Typography.Paragraph>
        </Form.Item>
        <Form.Item
          name="message_to_patient"
          label="Message To Patient">
          <Typography.Paragraph>
            {prescription.message_to_patient && <pre>{prescription.message_to_patient}</pre>}
          </Typography.Paragraph>
        </Form.Item>
      </Form>
    )
  }

  const onNoteClose = () => {
    setNoteVisible(false);
  };

  return (
    <>
    <Drawer
      className={cx({ noteDrawer: true })}
      width={600}
      title={noteHeaderDom()}
      placement="right"
      closable={false}
      onClose={onNoteClose}
      visible={noteVisible}
      destroyOnClose={true}
    >
      {noteContentDom()}
    </Drawer>
      <Modal
        visible={showUrgentRefillRemider}
        onCancel={() => {
          setShowUrgentRefillRemider(false);
        }}
        footer={null}
      >
        <p
          style={{
            color: '#000000',
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '20px',
          }}
        >
          Reminder for Prescribing in RXNT
        </p>
        <p style={{ marginBottom: '20px' }}>
          To ensure the pharmacy accepts the prescription, please indicate in RXNT that you are
          prescribing under a PHE exemption and have reviewed the patient's PDMP.
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between', color: '#000000' }}>
          <div></div>
          <Button key="confirm" onClick={handleConfirmUrgentRxnt} type="primary" style={{ width: '200px' }}>
            <p style={{ color: 'white' }}>
              Continue
            </p>
          </Button>
        </div>
      </Modal>
      </>
  );
};

ViewNote.defaultProps = {
  admin: false,
};

export default ViewNote;
