import React, { useState, useEffect } from 'react';
import { Select, TimePicker, Button, Modal, message } from 'antd';
import { history, useSelector } from 'umi';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  srcTimePlanTypeList,
  srcUpdateTimePlanRuleWeek,
  srcGetWeekRule,
  srcCheckWeeklyRule,
  srcCheckWeeklyChange,
  srcGetCacheWeekRule, srcSaveProcessStatus, srvGetSixMonthFlag,
} from '@/services/account';
import styles from './index.less';
import moment from 'moment';
import cn from 'classnames/bind';
import { useRequest } from 'ahooks';
import UpdateDayRule from '@/components/BigCalendar/UpdateDayRule';
const cx = cn.bind(styles);
const AddAvailablity = (props) => {
  const { doctorDetail, closeAddAvail } = props;
  const { currentUser } = useSelector((state) => state.account);
  const resultUser = doctorDetail ? doctorDetail : currentUser;
  const [appts, setAppts] = useState([]);
  const [rescheduleTipsVisible, setRescheduleTipsVisible] = useState(false);
  const [insufficientTimeTipsVisible, setInsufficientTimeTipsVisible] = useState(false);
  const [lessThanHours, setLessThanHours] = useState(35);
  const [noSaveTipsVisible, setNoSaveTipsVisible] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [wantPath, setWantPath] = useState('');
  const [weekRule, setWeekRule] = useState({
    sunday_source: [],
    monday_source: [],
    tuesday_source: [],
    wednesday_source: [],
    thursday_source: [],
    friday_source: [],
    saturday_source: [],
  });
  const [resultRule, setResultRule] = useState({});
  const [conflictAppts, setConflictAppts] = useState([]);
  const param = [
    { key: 'sunday_source', label: 'Sun' },
    { key: 'monday_source', label: 'Mon' },
    { key: 'tuesday_source', label: 'Tue' },
    { key: 'wednesday_source', label: 'Wed' },
    { key: 'thursday_source', label: 'Thu' },
    { key: 'friday_source', label: 'Fri' },
    { key: 'saturday_source', label: 'Sat' },
  ];
  
  const [saveProcessSuffix, setSaveProcessSuffix] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [autoCreateMonth, setAutoCreateMonth] = useState(' three');
  const [totalHours, setTotalHours] = useState(0);
  
  const addPlan = (day) => {
    const newWeekRule = { ...weekRule };
    if (!newWeekRule[day.key]) {
      newWeekRule[day.key] = [];
    }
    newWeekRule[day.key].push({});
    setWeekRule(newWeekRule);
    setIsDirty(true);
  };
  const removePlan = (day, index) => {
    const newWeekRule = { ...weekRule };
    newWeekRule[day.key].splice(index, 1);
    setWeekRule(newWeekRule);
    setIsDirty(true);
  };
  const updatePlan = (value, day, index) => {
    const newWeekRule = { ...weekRule };
    if (!newWeekRule[day.key]) {
      newWeekRule[day.key] = [];
    }
    newWeekRule[day.key][index] = {
      ...newWeekRule[day.key][index],
      time_plan_appointment_type_id: value,
    };
    setWeekRule(newWeekRule);
    setIsDirty(true);
  };
  const editAvailablityReq = useRequest(srcUpdateTimePlanRuleWeek, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setSaveProcessSuffix(ret.data);
        // setIsDirty(false);
        // if (doctorDetail) {
        //   closeAddAvail();
        //   return;
        // }
        // history.push(`/ehr_calendar/manage_availability?add_success=true`);
        setSaveLoading(true);
        checkEditAvailablityStatusReq.run({ key_suffix: ret.data });
        
      }
    },
  });
  
  const checkEditAvailablityStatusReq = useRequest(srcSaveProcessStatus, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        //  Processing: 1,  try again at 2 seconds
        // 	Success:    2,  go to success page
        // 	Failed:     3,  failed alert and close loading
        if (ret.data === 1) {
          setTimeout(() => {
            checkEditAvailablityStatusReq.run({ key_suffix: saveProcessSuffix });
          }, 2000);
        } else if (ret.data === 2) {
          setIsDirty(false);
          setSaveLoading(false)
          if (doctorDetail) {
            closeAddAvail();
            return;
          }
          history.push(`/ehr_calendar/manage_availability?add_success=true`);
        } else if (ret.data === 3) {
          setIsDirty(false);
          setSaveLoading(false)
          message.error('Save failed');
        }
      }
    },
  });
  

  const checkAvailablityReq = useRequest(srcCheckWeeklyRule, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success && ret.data) {
        if (ret.data === 'less_than_35_hours') {
          setLessThanHours(35);
          setInsufficientTimeTipsVisible(true);
          setNoSaveTipsVisible(false);
        } else if (ret.data === 'less_than_10_hours') {
          setLessThanHours(10);
          setInsufficientTimeTipsVisible(true);
          setNoSaveTipsVisible(false);
        } else {
          checkChangeAvailablityReq.run(resultRule);
        }
      }
    },
  });

  const checkChangeAvailablityReq = useRequest(srcCheckWeeklyChange, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success && ret.data) {
        if (ret.data.has_not_contains_booked) {
          setConflictAppts(ret.data.items);
          setRescheduleTipsVisible(true);
        } else {
          editAvailablityReq.run(resultRule);
        }
      }
    },
  });

  const submitWeekRule = () => {
    let isValid = true;
    param.map((day, dayIndex) => {
      const timePlans = weekRule[day.key];
      if (timePlans) {
        timePlans.map((v, index) => {
          if (!v.time_plan_appointment_type_id) {
            message.warning('Please select an appointment type for ' + day.label);
            isValid = false;
            return;
          }

          if (!v.time_range) {
            message.warning('Please select a time range for ' + day.label);
            isValid = false;
            return;
          }
        });
      }
    });
    if (!isValid) {
      return;
    }
    var filteredData = Object.fromEntries(
      Object.entries(weekRule).map(([key, value]) => {
        if (Array.isArray(value)) {
          return [key, value.filter((obj) => Object.keys(obj).length > 0)];
        }
        return [key, value];
      }),
    );
    filteredData.doctor_id = resultUser.doctor_id;
    setResultRule(filteredData);
    if (resultUser.job_type && resultUser.job_type === 2) {
      checkAvailablityReq.run(filteredData);
    } else if (resultUser.job_type === 1 || resultUser.job_type === 0) {
      checkAvailablityReq.run(filteredData);
    } else {
      checkChangeAvailablityReq.run(filteredData);
    }
  };
  const fetchApptPlanListReq = useRequest(srcTimePlanTypeList, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setAppts(ret.data);
      }
    },
  });
  const fetchWeekRuleReq = useRequest(srcGetWeekRule, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success && ret.data) {
        setWeekRule(ret.data);
        setTotalHours(ret.data.total_hours);
      }
    },
  });
  const fetchWeekRuleCacheReq = useRequest(srcGetCacheWeekRule, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data) {
          setWeekRule(ret.data);
        } else {
          const doctor_id = resultUser.doctor_id;
          fetchWeekRuleReq.run({ doctor_id });
        }
      }
    },
  });
  
  const fetchSixMonthFlag = useRequest(srvGetSixMonthFlag, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setAutoCreateMonth(ret.data === true ? ' six' : ' three');
      }
    },
  });
  
  
  const handleTimeRangeChange = (timeRange, day, index) => {
    const time = timeRange
      ? `${moment(timeRange[0]).format('hh:mma')}-${moment(timeRange[1]).format('hh:mma')}`
      : '';
    setWeekRule((prevWeekRule) => {
      const newWeekRule = { ...prevWeekRule };
      if (!newWeekRule[day.key]) {
        newWeekRule[day.key] = [];
      }
      newWeekRule[day.key][index] = { ...newWeekRule[day.key][index], time_range: time };
      return newWeekRule;
    });
    setIsDirty(true);
  };
  useEffect(() => {
    if (!isDirty && !wantPath) {
      const doctor_id = resultUser.doctor_id;
      fetchApptPlanListReq.run({ doctor_id });
      fetchWeekRuleCacheReq.run({ doctor_id });
      fetchSixMonthFlag.run({doctor_id});
    }
    const unblock = history.block((location) => {
      if (isDirty) {
        setWantPath(location.pathname);
        setNoSaveTipsVisible(true);
        return false;
      }
    });

    return () => {
      unblock();
    };
  }, [history, isDirty]);

  const [editDayRuleVisible, setEditDayRuleVisible] = useState(false);
  const closeEditDayRuleVisible = () => {
    setEditDayRuleVisible(false);
  };

  return (
    <>
      {!doctorDetail && (
        <div className={cx({ ehrCalendar: true })}>
          <div className={cx({ top: true })}>
            <div className={cx({ container: true })}>
              <div className={cx({ header: true })}>
                <h3>Manage Availability</h3>
              </div>
              <div
                style={{
                  borderRadius: '4px',
                  backgroundColor: '#E6F7FF',
                  paddingBottom: '8px',
                  paddingLeft: '12px',
                  paddingTop: '8px',
                  paddingRight: '12px',
                  marginBottom: '20px',
                }}
              >
                {resultUser.job_type === 2 && (
                    <p style={{ fontSize: '14px' }}>
                      After saving your regular hours, they will be automatically applied for the next
                      {autoCreateMonth} months. Please provide at least 35 hours every week.{' '}
                      <span style={{ color: '#EC5C55' }}>{totalHours}</span>/35 If necessary, you can choose a
                      specific date in the calendar view to make adjustments and override the hours
                      for that particular day.
                    </p>
                )}
                {(resultUser.job_type === 1 || resultUser.job_type === 0) && (
                    <p style={{ fontSize: '14px' }}>
                      After saving your regular hours, they will be automatically applied for the next
                      {autoCreateMonth} months. Please provide at least 10 hours every week.{' '}
                      <span style={{ color: '#EC5C55' }}>{totalHours}</span>/10 If necessary, you can choose a
                      specific date in the calendar view to make adjustments and override the hours
                      for that particular day.
                    </p>
                )}
              </div>
              {param.map((day, dayIndex) => (
                <div
                  style={{ display: 'flex', marginTop: '20px', justifyConten: 'center' }}
                  key={dayIndex}
                >
                  <span style={{ color: '#232323', width: '70px', marginTop: '10px' }}>
                    {day.label}
                  </span>
                  {(!weekRule || !weekRule[day.key] || weekRule[day.key].length === 0) && (
                    <>
                      <p style={{ marginTop: '10px' }}>Unavailable</p>
                      <button
                        style={{ marginLeft: '100px', fontSize: '20px' }}
                        onClick={() => addPlan(day)}
                      >
                        +
                      </button>
                    </>
                  )}
                  <div style={{ flexDirection: 'column' }}>
                    {weekRule &&
                      weekRule[day.key] &&
                      weekRule[day.key].map((v, index) => {
                        const selectedValue = v.time_plan_appointment_type_id || undefined;
                        const selectedTimeRange = v.time_range
                          ? [
                              moment(v.time_range.split('-')[0], 'hh:mma'),
                              moment(v.time_range.split('-')[1], 'hh:mma'),
                            ]
                          : undefined;
                        return (
                          <div
                            key={index}
                            style={{ display: 'flex', marginTop: '5px', alignItems: 'center' }}
                          >
                            <Select
                              placeholder="Select a option"
                              style={{ width: '380px' }}
                              onChange={(value) => updatePlan(value, day, index)}
                              options={appts.map((appt) => ({
                                label: appt.name,
                                value: appt.appointment_type_id,
                              }))}
                              value={selectedValue}
                            ></Select>
                            <TimePicker.RangePicker
                              use12Hours
                              format="hh:mma"
                              style={{ marginLeft: '20px', width: '250px' }}
                              onChange={(timeRange) => handleTimeRangeChange(timeRange, day, index)}
                              value={selectedTimeRange}
                              minuteStep={15}
                            />
                            <Button
                              type="text"
                              icon={<MinusCircleOutlined />}
                              style={{ marginBottom: '5px', marginLeft: '5px' }}
                              onClick={() => removePlan(day, index)}
                            />
                            {index === 0 && (
                              <button
                                style={{
                                  marginLeft: '50px',
                                  fontSize: '17px',
                                  marginBottom: '5px',
                                }}
                                onClick={() => addPlan(day)}
                              >
                                <PlusCircleOutlined />
                              </button>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              ))}
              <Button
                style={{ marginTop: '20px', marginBottom: '20px' }}
                type="primary"
                htmlType="submit"
                onClick={submitWeekRule}
                loading={saveLoading}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      )}
      {doctorDetail && (
        <>
          {param.map((day, dayIndex) => (
            <div
              style={{ display: 'flex', marginTop: '20px', justifyConten: 'center' }}
              key={dayIndex}
            >
              <span style={{ color: '#232323', width: '70px', marginTop: '10px' }}>
                {day.label}
              </span>
              {(!weekRule || !weekRule[day.key] || weekRule[day.key].length === 0) && (
                <>
                  <p style={{ marginTop: '10px' }}>Unavailable</p>
                  <button
                    style={{ marginLeft: '100px', fontSize: '20px' }}
                    onClick={() => addPlan(day)}
                  >
                    +
                  </button>
                </>
              )}
              <div style={{ flexDirection: 'column' }}>
                {weekRule &&
                  weekRule[day.key] &&
                  weekRule[day.key].map((v, index) => {
                    const selectedValue = v.time_plan_appointment_type_id || undefined;
                    const selectedTimeRange = v.time_range
                      ? [
                          moment(v.time_range.split('-')[0], 'hh:mma'),
                          moment(v.time_range.split('-')[1], 'hh:mma'),
                        ]
                      : undefined;
                    return (
                      <div
                        key={index}
                        style={{ display: 'flex', marginTop: '5px', alignItems: 'center' }}
                      >
                        <Select
                          placeholder="Select a option"
                          style={{ width: '380px' }}
                          onChange={(value) => updatePlan(value, day, index)}
                          options={appts.map((appt) => ({
                            label: appt.name,
                            value: appt.appointment_type_id,
                          }))}
                          value={selectedValue}
                        ></Select>
                        <TimePicker.RangePicker
                          use12Hours
                          format="hh:mma"
                          style={{ marginLeft: '20px', width: '250px' }}
                          onChange={(timeRange) => handleTimeRangeChange(timeRange, day, index)}
                          value={selectedTimeRange}
                          minuteStep={15}
                        />
                        <Button
                          type="text"
                          icon={<MinusCircleOutlined />}
                          style={{ marginBottom: '5px', marginLeft: '5px' }}
                          onClick={() => removePlan(day, index)}
                        />
                        {index === 0 && (
                          <button
                            style={{ marginLeft: '50px', fontSize: '17px', marginBottom: '5px' }}
                            onClick={() => addPlan(day)}
                          >
                            <PlusCircleOutlined />
                          </button>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          ))}
          <Button
            style={{ marginTop: '20px', marginBottom: '20px' }}
            type="primary"
            htmlType="submit"
            onClick={submitWeekRule}
            loading={saveLoading}
          >
            Save Changes
          </Button>
        </>
      )}
      <UpdateDayRule
        editDayRuleVisible={editDayRuleVisible}
        closeEditDayRuleVisible={closeEditDayRuleVisible}
        appts={appts}
      />
      <Modal
        visible={rescheduleTipsVisible}
        onCancel={() => {
          setRescheduleTipsVisible(false);
        }}
        onOk={() => {
          setRescheduleTipsVisible(false);
        }}
        footer={null}
      >
        <p
          style={{
            color: '#000000',
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '20px',
          }}
        >
          Confirm the Changes
        </p>
        <p style={{ marginBottom: '20px' }}>
          The following scheduled appointments are not within your available times, but they will be
          maintained. If you are unable to participate, please reschedule.
          {conflictAppts.map((v, index) => {
            let sentence = '';
            sentence +=
              `${v.date}`.replace(/"/g, '') +
              `(` +
              `${v.appointment_type_name}` +
              `)` +
              ` with ` +
              `${v.patient_name} `;
            return <p>{sentence}</p>;
          })}
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between', color: '#000000' }}>
          <p
            style={{ color: '#566BCD', cursor: 'pointer' }}
            onClick={() => {
              setIsDirty(false);
              setRescheduleTipsVisible(false);
              if (doctorDetail) {
                closeAddAvail();
                return;
              }
              history.push(`/ehr_calendar`);
            }}
          >
            Reschedule the appointment
          </p>
          <Button
            key="confirm"
            onClick={() => {
              editAvailablityReq.run(resultRule);
            }}
            type="primary"
            style={{ width: '200px' }}
          >
            Confirm
          </Button>
        </div>
      </Modal>
      <Modal
        visible={insufficientTimeTipsVisible}
        onCancel={() => {
          setInsufficientTimeTipsVisible(false);
        }}
        onOk={() => {
          setInsufficientTimeTipsVisible(false);
        }}
        footer={null}
      >
        <p
          style={{
            color: '#000000',
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '20px',
          }}
        >
          Insufficient Available Time Provided
        </p>
        <p style={{ marginBottom: '20px' }}>
          Please ensure that you provide at least {lessThanHours} hours of availability per week, otherwise it
          cannot be saved.
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between', color: '#000000' }}>
          <div></div>
          <Button
            key="confirm"
            onClick={() => {
              setInsufficientTimeTipsVisible(false);
            }}
            type="primary"
            style={{ width: '200px' }}
          >
            Okay
          </Button>
        </div>
      </Modal>
      <Modal
        visible={noSaveTipsVisible}
        onCancel={() => {
          setNoSaveTipsVisible(false);
        }}
        onOk={() => {
          setNoSaveTipsVisible(false);
        }}
        footer={null}
      >
        <p
          style={{
            color: '#000000',
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '20px',
          }}
        >
          Regular Hours Not Saved
        </p>
        <p style={{ marginBottom: '20px' }}>
          You have made some changes to regular hours, do you want to save the changes?
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between', color: '#000000' }}>
          <p
            style={{ color: '#566BCD', cursor: 'pointer' }}
            onClick={() => {
              //cacheAvailablityReq.run(weekRule);
              setNoSaveTipsVisible(false);
              setIsDirty(false);
              if (doctorDetail) {
                closeAddAvail();
                return;
              }
              setTimeout(() => {
                history.push(`${wantPath}`);
              }, 200);
            }}
          >
            Cancel
          </p>
          <Button key="confirm" onClick={submitWeekRule} type="primary" style={{ width: '200px' }}>
            Yes, save changes
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default AddAvailablity;
