import StackdriverErrorReporter from 'stackdriver-errors-js';

export const errorHandler = new StackdriverErrorReporter();

if (process.env.NODE_ENV != 'development') {
  try {
    errorHandler.start({
      key: `${process.env.ERR_REPORT_KEY}`,
      projectId: 'done-280702',
      service: `done-ehr-${process.env.DEPLOY_ENV}`,
      reportUncaughtExceptions: true, // (optional) Set to false to stop reporting unhandled exceptions.
      reportUnhandledPromiseRejections: true, // (optional) Set to false to stop reporting unhandled promise rejections.
      // disabled: true                           // (optional) Set to true to not report errors when calling report(), this can be used when developing locally.
      // context: {user: 'user1'}                 // (optional) You can set the user later using setUser()
    });
  } catch(err) {
    console.error(err)
  }
}

window.sendErrorLog = function (err) {
  if (process.env.NODE_ENV == 'development') {
    console.error(err);
    return;
  }

  const udata = window.sessionStorage.getItem('done-uid');
  if (udata && udata != '' && udata != 'undefined') {
    errorHandler.setUser(udata);
  }

  errorHandler.report(err);
};


