import { request } from 'umi';
import { stringify } from 'querystring';
import exp from 'constants';

export async function srvAppointments(param) {
  return request(`/api/admin/appointment/list?${stringify(param)}`);
}
export async function srvRequests(param) {
  return request(`/api/admin/request/list?${stringify(param)}`);
}

export async function srvDischargeOrTransfer(data) {
  return request('/api/admin/discharge/patient', {
    method: 'post',
    data: data,
  });
}

export async function srvPatients(param) {
  return request(`/api/admin/patient/list?${stringify(param)}`);
}

export async function srvTransferWaitlistPatients(param) {
  return request(`/api/admin/patient/transfer_waitlist?${stringify(param)}`);
}

export async function srvDoctorPatients(param) {
  return request(`/api/admin/doctor/patient/list?${stringify(param)}`);
}

export async function srvTherapistPatients(param) {
  return request(`/api/admin/therapist/patient/list?${stringify(param)}`);
}

export async function srvDoctorPatientStats(param) {
  return request(`/api/admin/doctor/patient/stat?${stringify(param)}`);
}

export async function srvIntStatusItems(param) {
  return request(`/api/admin/status_items/list/int?${stringify(param)}`);
}

export async function srvDoctors(param) {
  return request(`/api/admin/doctor/list?${stringify(param)}`);
}

export async function srvAllDoctors() {
  return request(`/api/admin/doctor/full_list`);
}

export async function srvCollabDoctors() {
  return request(`/api/admin/doctor/list/collab`);
}

export async function srvDoctorDetail(param) {
  return request(`/api/admin/doctor/get?${stringify(param)}`);
}

export async function srvAddDoctor(data) {
  return request('/api/admin/doctor/add', {
    method: 'post',
    data: data,
  });
}

export async function srvUpdateDoctor(data) {
  return request('/api/admin/doctor/update', {
    method: 'post',
    data: data,
  });
}

export async function srvDeactiveDoctor(data) {
  return request('/api/admin/doctor/deactive', {
    method: 'post',
    data: data,
  });
}

export async function srvActiveDoctor(data) {
  return request('/api/admin/doctor/active', {
    method: 'post',
    data: data,
  });
}

export async function srvPayments(param) {
  return request(`/api/admin/payment/list?${stringify(param)}`);
}

export async function srvConsultations(param) {
  return request(`/api/admin/consultation/list?${stringify(param)}`);
}

export async function srvTreatmentRequests(param) {
  return request(`/api/admin/treatment_request/list?${stringify(param)}`);
}

export async function srvRemoveTreatmentRequest(data) {
  return request('/api/admin/treatment_request/remove', {
    method: 'delete',
    data: data,
  });
}

export async function srvMarkNoteAddedTreatmentRequest(data) {
  return request('/api/admin/treatment_request/note_added', {
    method: 'post',
    data: data,
  });
}

export async function srvMarkNewTreatmentRequest(data) {
  return request('/api/admin/treatment_request/new', {
    method: 'post',
    data: data,
  });
}

export async function srvMarkSuccessTreatmentRequest(data) {
  return request('/api/admin/treatment_request/success', {
    method: 'post',
    data: data,
  });
}

export async function srvMarkClosedTreatmentRequest(data) {
  return request('/api/admin/treatment_request/closed', {
    method: 'post',
    data: data,
  });
}

export async function srvAddTreatmentRequestHistory(data) {
  return request('/api/admin/treatment_request_history/add', {
    method: 'post',
    data: data,
  });
}

export async function srvAddAdminNoteTreatmentRequest(data) {
  return request('/api/admin/treatment_request/note', {
    method: 'post',
    data: data,
  });
}

export async function srvPatientDetail(param) {
  return request(`/api/admin/patient/get?${stringify(param)}`);
}

export async function srvPatientIdentity(param) {
  return request(`/api/admin/patient/identity?${stringify(param)}`);
}

export async function srvRequestPatientReUploadIdentity(data) {
  return request('/api/patient/identity/reupload_request', {
    method: 'post',
    data: data,
  });
}

export async function srvUpdatePatientDetail(data) {
  return request('/api/admin/patient/update', {
    method: 'post',
    data: data,
  });
}

export async function srvTreatmentPlanHistories(param) {
  return request(`/api/admin/treatment_plan/history/list?${stringify(param)}`);
}

export async function srvMemberships(param) {
  return request(`/api/admin/membership/list?${stringify(param)}`);
}

export async function srvTreatmentPlanHistoryDetail(param) {
  return request(`/api/admin/treatment_plan/history/get?${stringify(param)}`);
}

export async function srvUpdateTreatmentPlanHistory(data) {
  return request('/api/admin/treatment_plan/history/update', {
    method: 'post',
    data: data,
  });
}

export async function srvDeleteTreatmentPlanHistoryEnable() {
    return request('/api/admin/treatment_plan/history/del_abtest_flag');
}

export async function srvDeleteTreatmentPlanHistory(param) {
  return request('/api/admin/treatment_plan/history/remove', {
    method: 'delete',
    data: { id: param.id },
  });
}

export async function srvConsultationReplies(param) {
  return request(`/api/admin/consultation/reply/list?${stringify(param)}`);
}

export async function srvSendConsultationReply(data) {
  return request('/api/admin/consultation/reply/add', {
    method: 'post',
    data: data,
  });
}

export async function srvConfirmConsultationReinstatement(data) {
  return request('/api/admin/consultation/reinstatement/confirm', {
    method: 'post',
    data: data,
  });
}

export async function srvEnableResyncZohoThreads() {
  return request('/api/zoho/enable_resync_zoho_threads');
}

// srvResyncFromZohoTicket
export async function srvResyncFromZohoTicket(params) {
  return request(`/api/zoho/resync_zoho_threads?${stringify(params)}`, {
    method: 'post',
  });
}

export async function srvAssignDoctorToConsultation(data) {
  return request('/api/admin/consultation/assign', {
    method: 'post',
    data: data,
  });
}

export async function srvChangeConsultationStatus(data) {
  return request('/api/admin/consultation/status/update', {
    method: 'post',
    data: data,
  });
}

export async function srvRefund(data) {
  return request('/api/admin/payment/refund', {
    method: 'post',
    data: data,
  });
}

export async function srvPaymentItem(param) {
  return request(`/api/admin/payment/get?${stringify(param)}`);
}

export async function srvAppointmentDetail(param) {
  return request(`/api/admin/appointment/get?${stringify(param)}`);
}

export async function srvAppointmentItem(param) {
  return request(`/api/admin/appointment/get_item?${stringify(param)}`);
}
export async function srvMembershipDetail(param) {
  return request(`/api/admin/membership/get_include_deleted?${stringify(param)}`);
}

export async function srvTherapySubscriptionDetail(param) {
  return request(`/api/admin/therapy_subscription/get_include_deleted?${stringify(param)}`);
}

export async function srvRefundItems(param) {
  return request(`/api/admin/payment/refund/list?${stringify(param)}`);
}

export async function srvCalendarAppointments(param) {
  return request(`/api/admin/appointment/calendar?${stringify(param)}`);
}

export async function srvAppointmentOrders(param) {
  return request(`/api/admin/appointment/order/list?${stringify(param)}`);
}

export async function srvAppointmentRescheduleCalendar(param) {
  return request(`/api/admin/calendar/availability/reschedule?${stringify(param)}`);
}

export async function srvAppointmentCalendar(param) {
  return request(`/api/admin/calendar/availability?${stringify(param)}`);
}

export async function srvAppointmentFollowUpCalendar(param) {
  return request(`/api/admin/calendar/availability/followup?${stringify(param)}`);
}

export async function srvPatientActiveDoctor(param) {
  return request(`/api/admin/patient/active_doctor?${stringify(param)}`);
}

export async function srvRescheduleAppointment(data) {
  return request('/api/admin/appointment/reschedule', {
    method: 'post',
    data: data,
  });
}

export async function srvFollowUpAppointment(data) {
  return request('/api/admin/appointment/followup', {
    method: 'post',
    data: data,
  });
}

export async function srvTherapistSession(data) {
  return request('/api/admin/appointment/therapist_session', {
    method: 'post',
    data: data,
  });
}

export async function srvConsultationAppointment(data) {
  return request('/api/admin/appointment/consultation', {
    method: 'post',
    data: data,
  });
}

export async function srvPaidFollowUpAppointment(data) {
  return request('/api/admin/appointment/followup_paid', {
    method: 'post',
    data: data,
  });
}

export async function srvConsultationAppointmentPrice(param) {
  return request(`/api/admin/appointment/consultation/price?${stringify(param)}`);
}

export async function srvSyncUserInfoToApero(data) {
  return request('/api/admin/patient/sync_info_to_apero', {
    method: 'post',
    data: data,
  });
}

export async function srvSyncAppointmentInfoToApero(data) {
  return request('/api/admin/appointment/sync_info_to_apero', {
    method: 'post',
    data: data,
  });
}

export async function srvCancelAppointment(data) {
  return request('/api/admin/appointment/cancel', {
    method: 'post',
    data: data,
  });
}

export async function srvMarkAppointmentNoShow(data) {
  return request('/api/admin/appointment/noshow', {
    method: 'post',
    data: data,
  });
}

export async function srvMarkAppointmentProviderNoShow(data) {
  return request('/api/admin/appointment/provider_noshow', {
    method: 'post',
    data: data,
  });
}

export async function srvAdminChangePastAppointmentStatus(data) {
  return request('/api/admin/appointment/past/status/update', {
    method: 'post',
    data: data,
  });
}

export async function srvAdminChangeFollowUpToTransferOfCare(data) {
  return request('/api/admin/appointment/followup/to/transferofcare', {
    method: 'post',
    data: data,
  });
}

export async function srvAdminChangeInPersonFollowUpToTransferOfCare(data) {
  return request('/api/admin/appointment/followup/to/in_person_transferofcare', {
    method: 'post',
    data: data,
  });
}

export async function srvAdminUpdateAppointmentPDMP(data) {
  return request('/api/admin/appointment/pdmp', {
    method: 'post',
    data: data,
  });
}

export async function srvAdminUpdateTreatmentRequestPDMP(data) {
  return request('/api/admin/treatment_request/pdmp', {
    method: 'post',
    data: data,
  });
}

export async function srvStates(param) {
  return request(`/api/state/list?${stringify(param)}`);
}

export async function srvInsuranceCompanies(param) {
  return request(`/api/insurance_companies/list?${stringify(param)}`);
}

export async function srvDoctorTags(param) {
  return request(`/api/admin/tags/list?${stringify(param)}`);
}

export async function srvDoctorTransferOpts(param) {
  return request(`/api/admin/doctor/patient/transfer_opts?${stringify(param)}`);
}

export async function srvDoctorAutoTransfer(data) {
  return request('/api/admin/doctor/patient/auto_transfer', {
    method: 'post',
    data: data,
  });
}

export async function srvDoctorAutoTransferCheck(data) {
  return request('/api/admin/doctor/patient/auto_transfer_check', {
    method: 'post',
    data: data,
  });
}

export async function srvAdminAddOrUpdateUserInsuranceCardInfo(data) {
  return request('/api/admin/patient/insurance_card_info/add', {
    method: 'post',
    data: data,
  });
}

export async function srvDoctorTransfer(data) {
  return request('/api/admin/doctor/patient/transfer', {
    method: 'post',
    data: data,
  });
}

export async function srvDoctorAssign(data) {
  return request('/api/admin/doctor/patient/assign', {
    method: 'post',
    data: data,
  });
}

export async function srvDoctorTransferWithCancel(data) {
  return request('/api/admin/doctor/patient/transfer_with_cancel', {
    method: 'post',
    data: data,
  });
}

export async function srvSetPassword(data) {
  return request('/api/admin/doctor/password', {
    method: 'post',
    data: data,
  });
}

export async function srvPatientUpcommingAppointments(param) {
  return request(`/api/admin/doctor/patient/appointment/upcomming?${stringify(param)}`);
}

export async function srvTransferDoctors(param) {
  return request(`/api/admin/doctor/patient/transfer_doctors?${stringify(param)}`);
}

export async function srvPatientCurrentTherapySubscription(param) {
  return request(`/api/admin/patient/current_therapy_subscription?${stringify(param)}`);
}

export async function srvPatientCurrentCoachingSubscription(param) {
  return request(`/api/admin/patient/current_coaching_subscription?${stringify(param)}`);
}

export async function srvPatientCurrentMembership(param) {
  return request(`/api/admin/patient/current_membership?${stringify(param)}`);
}

export async function srvPatientLastMembership(param) {
  return request(`/api/admin/patient/last_active_membership?${stringify(param)}`);
}

export async function srvPatientNextPausedMembership(param) {
  return request(`/api/admin/patient/next_paused_membership?${stringify(param)}`);
}

export async function srvPausePatientMembership(data) {
  return request('/api/admin/patient/pause_membership', {
    method: 'post',
    data: data,
  });
}
export async function srvCancelPatientMembership(data) {
  return request('/api/admin/patient/cancel_membership', {
    method: 'post',
    data: data,
  });
}

export async function srvResumePatientMembership(data) {
  return request('/api/admin/patient/resume_membership', {
    method: 'post',
    data: data,
  });
}

export async function srvChangePatientSubscriptionType(data) {
  return request('/api/admin/patient/subscription_type', {
    method: 'post',
    data: data,
  });
}

export async function srvExtendPatientMembershipEndDate(data) {
  return request('/api/admin/patient/membership/subscription_enddate', {
    method: 'post',
    data: data,
  });
}

export async function srvActivatePatientMembership(data) {
  return request('/api/admin/patient/activate_membership', {
    method: 'post',
    data: data,
  });
}

export async function srvAddAccountUpgrade(data) {
  return request('/api/admin/patient/account_upgrade/add', {
    method: 'post',
    data: data,
  });
}

export async function srvAddTeam(data) {
  return request('/api/admin/team/add', {
    method: 'post',
    data: data,
  });
}

export async function srvTeams(param) {
  return request(`/api/admin/team/list?${stringify(param)}`);
}

export async function srvDeleteTeam(data) {
  return request('/api/admin/team', {
    method: 'delete',
    data: data,
  });
}

export async function srvAreaList(param) {
  return request(`/api/admin/area/list?${stringify(param)}`);
}

export async function srvInPersonFollowUpRuleList(param) {
  return request(`/api/admin/area/get_in_person_follow_up_rules?${stringify(param)}`);
}

export async function srvPharmacyList(param) {
  return request(`/api/admin/pharmacy/list?${stringify(param)}`);
}

export async function srvRXNTPharmacyList(param) {
  return request(`/api/admin/rxnt/pharmacy/list?${stringify(param)}`);
}

export async function srvRXNTPharmacyFuzzySearch(param) {
  return request(`/api/rxnt/pharmacy/search?${stringify(param)}`);
}

export async function srvSyncRxntStatusList(param) {
  return request(`/api/admin/rxnt/sync_status?${stringify(param)}`);
}

export async function srvRxntSyncError(param) {
  return request(`/api/admin/rxnt/sync_error?${stringify(param)}`);
}

export async function srvSync2Rxnt(data) {
  return request('/api/admin/rxnt/sync', { method: 'post', data: data });
}

export async function srvToogleArea(data) {
  return request('/api/admin/area/toggle', {
    method: 'post',
    data: data,
  });
}

export async function srvToogleAreaInPersonVisit(data) {
  return request('/api/admin/area/toggle_in_person_visit', {
    method: 'post',
    data: data,
  });
}

export async function srvToogleAreaUrgentRefillMetricLock(data) {
  return request('/api/admin/area/toggle/urgent_refill_metric_lock', {
    method: 'post',
    data: data,
  });
}

export async function srvToogleAreaPediatric(data) {
  return request('/api/admin/area/toggle/pediatric', {
    method: 'post',
    data: data,
  });
}

export async function srvToogleAreaInsomnia(data) {
  return request('/api/admin/area/toggle/insomnia', {
    method: 'post',
    data: data,
  });
}

export async function srvToogleAreaPDMP(data) {
  return request('/api/admin/area/toggle/pdmp', {
    method: 'post',
    data: data,
  });
}

export async function srvToogleAreaHybridCare(data) {
  return request('/api/admin/area/toggle/hybrid_care', {
    method: 'post',
    data: data,
  });
}

export async function srvSetAreaPharmacy(data) {
  return request('/api/admin/area/pharmacy', {
    method: 'post',
    data: data,
  });
}

export async function srvSetUserAddress(data) {
  return request('/api/admin/address/update', {
    method: 'post',
    data: data,
  });
}

export async function srvImpersonatePatientLogin(data) {
  return request('/api/admin/patient/impersonate_login', {
    method: 'post',
    data: data,
  });
}

export async function srvImpersonateDoctorLogin(data) {
  return request('/api/admin/doctor/impersonate_login', {
    method: 'post',
    data: data,
  });
}

export async function srvPatientMembershipFee(param) {
  return request(`/api/admin/patient/subscription_fee?${stringify(param)}`);
}

export async function srvPatientFirstMembership(param) {
  return request(`/api/admin/patient/first_membership?${stringify(param)}`);
}

export async function srvSavePatientCustomPrice(data) {
  return request('/api/admin/patient/subscription_fee', {
    method: 'post',
    data: data,
  });
}

export async function srvAdminGetDoctorTaskURL(param) {
  return request(`/api/admin/doctor/task_url?${stringify(param)}`);
}

export async function srvAdminUpdateDoctorTaskURL(data) {
  return request('/api/admin/doctor/task_url', {
    method: 'put',
    data: data,
  });
}

export async function srvAdminDeleteDoctorTaskURL(data) {
  return request('/api/admin/doctor/task_url', {
    method: 'delete',
    data: data,
  });
}

export async function srvCanScheduleFollowUp(param) {
  return request(`/api/admin/patient/can_schedule_followup?${stringify(param)}`);
}

export async function srvPatientStateDoctors(param) {
  return request(`/api/admin/doctor/list/state?${stringify(param)}`);
}

export async function srvPatientTransferStateDoctors(param) {
  return request(`/api/admin/doctor/list/state/transfer?${stringify(param)}`);
}

export async function srvPatientTherapists(param) {
  return request(`/api/admin/doctor/list/state/therapist?${stringify(param)}`);
}

export async function srvAdminGetPaitentTimeline(param) {
  return request(`/api/admin/patient/timeline?${stringify(param)}`);
}

export async function srvAdminDeletePatientAccount(data) {
  return request('/api/admin/patient/delete', {
    method: 'delete',
    data: data,
  });
}

export async function srvAdminUserSurveies(param) {
  return request(`/api/admin/user_survey/list?${stringify(param)}`);
}

export async function srvApproveTreatmentRequest(data) {
  return request('/api/admin/treatment_request/approve', { method: 'post', data: data });
}

export async function srvAdminDetail(param) {
  return request(`/api/admin/user/get?${stringify(param)}`);
}

export async function srvAdmins(param) {
  return request(`/api/admin/user/list?${stringify(param)}`);
}

export async function srvAddAdmin(data) {
  return request('/api/admin/user/add', {
    method: 'post',
    data: data,
  });
}

export async function srvUpdateAdmin(data) {
  return request('/api/admin/user/update', {
    method: 'post',
    data: data,
  });
}

export async function srvActivateAdmin(data) {
  return request('/api/admin/user/activate', {
    method: 'post',
    data: data,
  });
}

export async function srvDeactivateAdmin(data) {
  return request('/api/admin/user/deactivate', {
    method: 'post',
    data: data,
  });
}

export async function srvEsPatients(param) {
  return request(`/api/admin/es/patient/list?${stringify(param)}`);
}

export async function queryCurrentAbPolicy() {
  return request('/api/ab_policy/list');
}

export async function srvPharmacyRecommended(param) {
  return request(`/api/admin/pharmacy/recommended/list?${stringify(param)}`);
}

export async function srvSavePharmacyRecommended(data) {
  return request('/api/admin/pharmacy/recommended/save', {
    method: 'post',
    data: data,
  });
}

export async function srvDelPharmacyRecommended(data) {
  return request('/api/admin/pharmacy/recommended/del', {
    method: 'delete',
    data: data,
  });
}

export async function srvPharmacyBlacklist(param) {
  return request(`/api/admin/pharmacy/blacklist/list?${stringify(param)}`);
}

export async function srvAddPharmacyBlacklist(data) {
  return request('/api/admin/pharmacy/blacklist/add', {
    method: 'post',
    data: data,
  });
}

export async function srvDelPharmacyBlacklist(data) {
  return request('/api/admin/pharmacy/blacklist/del', {
    method: 'delete',
    data: data,
  });
}

export async function srvTags(param) {
  return request(`/api/admin/tags/list?${stringify(param)}`);
}

export async function srvAddTags(data) {
  return request('/api/admin/tags/add', {
    method: 'post',
    data: data,
  });
}

export async function srvUpdateTags(data) {
  return request('/api/admin/tags/update', {
    method: 'post',
    data: data,
  });
}

export async function srvDelTags(data) {
  return request('/api/admin/tags/delete', {
    method: 'post',
    data: data,
  });
}

export async function srvTagsDetail(param) {
  return request(`/api/admin/tags/get?${stringify(param)}`);
}

export async function srvGetOrderList(params) {
  return request('/api/admin/order/list', {
    method: 'get',
    params,
  });
}

export async function srvGetAdminDoctorLocations(params) {
  return request('/api/admin/doctor/locations/list', {
    method: 'get',
    params,
  });
}

export async function srvAdminAppointmentRequests(param) {
  return request(`/api/admin/appointment_request/list?${stringify(param)}`);
}

export async function srvAdminScheduleAppointmentRequest(data) {
  return request('/api/admin/appointment_request/schedule', {
    method: 'post',
    data,
  });
}

export async function srvAdminReactivateRequests(param) {
  return request(`/api/admin/reactivate_request/list?${stringify(param)}`);
}

export async function srvAdminPatientComments(param) {
  return request(`/api/admin/patient/comment/list?${stringify(param)}`);
}

export async function srvAdminAddPatientComment(data) {
  return request(`/api/admin/patient/comment`, { method: 'post', data: data });
}

export async function srvAdminUpdatePatientComment(data) {
  return request(`/api/admin/patient/comment`, { method: 'put', data: data });
}

export async function srvAdminDeletePatientComment(data) {
  return request(`/api/admin/patient/comment`, { method: 'delete', data: data });
}

export async function srvDeleteLocationImage(data) {
  return request('/api/ehr/upload/location/image/delete', {
    method: 'delete',
    data: data,
  });
}

export async function srvAddLocationImage(data) {
  return request('/api/ehr/upload/location/image/add', {
    method: 'post',
    data: data,
  });
}
export async function srvSetInPersonWaiverEndDate(data) {
  return request('/api/admin/area/set_in_person_waiver_end_date', {
    method: 'post',
    data: data,
  });
}

export async function srvSetInPersonFollowUpRule(data) {
  return request('/api/admin/area/set_in_person_follow_up_rule', {
    method: 'post',
    data: data,
  });
}

export async function srvUpdateLocationImage(data) {
  return request('/api/ehr/upload/location/image/update', {
    method: 'post',
    data: data,
  });
}

export async function srvReferrals(param) {
  return request(`/api/admin/referral/list?${stringify(param)}`);
}

export async function srvRejectReferrals(data) {
  return request('/api/admin/referral/reject', {
    method: 'post',
    data: data,
  });
}

export async function srvBatchTransferFromWaitlist(data) {
  return request('/api/admin/patient/batch_transfer_from_waitlist', {
    method: 'post',
    data: data,
  });
}

export async function srvApproveReferrals(data) {
  return request('/api/admin/referral/approve', {
    method: 'post',
    data: data,
  });
}

export async function srvViewReferrals(param) {
  return request(`/api/admin/referral/view?${stringify(param)}`);
}

export async function srvUploadReferral(data) {
  return request('/api/admin/referral/upload', {
    method: 'post',
    data: data,
  });
}

export async function srvAdminOverridePatientRefillDate(data) {
  return request('/api/admin/patient/treatment_request/discharge/override_refill_date', {
    method: 'post',
    data: data,
  });
}

export async function srvAdminOverridePatientRefillDateV2(data) {
  return request('/api/admin/patient/treatment_request/discharge/override_refill_date_v2', {
    method: 'post',
    data: data,
  });
}

export async function srvGetChangeLogs(param) {
  return request(`/api/admin/doctor/change/logs?${stringify(param)}`);
}

export async function srvGetTimePlanMonthList(param) {
  return request(`/api/ehr/timeplan/rule/days/list?${stringify(param)}`);
}

export async function srvEditTimePlanOneDay(data) {
  return request(`/api/ehr/timeplan/rule/one_day`, {
    method: 'post',
    data: data,
  });
}
export async function srvUpdateDispute(data) {
  return request('/api/admin/refill/dispute/update', {
    method: 'post',
    data: data,
  });
}

export async function srvGetPatientAppointments(param) {
  return request(`/api/admin/patient/appointment_request/list?${stringify(param)}`);
}

export async function srvGetDisputeReviewList(param) {
  return request(`/api/admin/dispute_review/list?${stringify(param)}`);
}

export async function srvAddDisputeReview(data) {
  return request(`/api/admin/dispute_review/add`, {
    method: 'post',
    data: data,
  });
}

export async function srvGetAllProductList() {
  return request(`/api/admin/product/list`);
}

export async function srvGetPatientPaymentMethod(param) {
  return request(`/api/admin/payment_method?${stringify(param)}`);
}

export async function srvAddPayment(data) {
  return request(`/api/admin/payment/add`, {
    method: 'post',
    data: data,
  });
}
export async function srvGetPatientCancelMoreYearFlag(param) {
  return request(`/api/admin/determine_cancel_year?${stringify(param)}`);
}
export async function srvUpdatePatientStudentVerification(data) {
  return request(`/api/admin/student_verification/update`, {
    method: 'post',
    data: data,
  });
}


export async function srvGetPatientStudentVerifiction(param) {
  return request(`/api/admin/student_verification/get?${stringify(param)}`);
}

export async function srvExtendMembershipByWeek(data) {
  return request('/api/admin/patient/membership/extends/week', {
    method: 'post',
    data: data,
  });
}

export async function srvMembershipExtendHistorys(param) {
  return request(`/api/admin/patient/membership/extends/logs?${stringify(param)}`);
}

export async function srvAdminMedicalFiles(param) {
  return request(`/api/admin/medical_file_requests?${stringify(param)}`);
}

export async function srvAdminGetMedicalFile(param) {
  return request(`/api/admin/medical_file/get?${stringify(param)}`);
}

export async function srvAdminGetMedicalFileLink(param) {
  return request(`/api/admin/medical_file/get_link?${stringify(param)}`);
}

export async function srvAdminApproveMedicalFileRequest(data) {
  return request(`/api/admin/medical_file_requests/approve?${stringify(data)}`, {
    method: 'post'
  });
}
export async function srvAdminUpdateMedicalFileRequestDoctor(data) {
  return request(`/api/admin/medical_file/doctor/assign`, {
    method: 'post',
    data: data,
  });
}

export async function srvAdminMedicalFileUploadToken(params) {
  return request(`/api/admin/medical_file/upload/token?${stringify(params)}`, {
    method: 'post'
  });
}
export async function srvAdminUpdateMedicalFile(data) {
  return request(`/api/admin/medical_file/update`, {
    method: 'post',
    data: data,
  });
}
export async function srvPharmacyManagement(param) {
  return request(`/api/ehr/admin/rxnt/pharmacys?${stringify(param)}`);
}

export async function srvSaveRxntPharmacyInfo(data) {
  return request('/api/admin/rxnt/pharmacy/save', {
    method: 'post',
    data: data,
  });
}

