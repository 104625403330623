import React, { useState, useEffect, useRef, useReducer } from 'react';
import cn from 'classnames/bind';
import {Drawer, Input, Button, Tabs, Tag, Menu, Modal, message} from 'antd';
import { useFlag } from '@unleash/proxy-client-react'
import { formatUnix } from '@/utils/xtime';
import moment from 'moment';
import { createFromIconfontCN } from '@ant-design/icons';
import { useRequest, useSetState } from 'ahooks';
import MessageItem from './MessageItem'
import { history,useAccess } from 'umi';

import defaultSettings from '../../../defaultSettings';

import styles from './index.less';

import {
  srvSendConsultationReply as srvAdminSendConsultationReply,
  srvAssignDoctorToConsultation,
  srvChangeConsultationStatus as srvAdminChangeConsultationStatus, srvEnableResyncZohoThreads, srvResyncFromZohoTicket,
} from '@/services/admin';

import {
  srvSendConsultationReply as srvDoctorSendConsultationReply,
  srvAssignConsultation,
  srvChangeConsultationStatus as srvDoctorChangeConsultationStatus,
  srvDoctorSendConsultation

} from '@/services/patient';
const cx = cn.bind(styles);

const { TabPane } = Tabs;
import { getDoctorAvatarURL } from '@/utils/utils';

const ConsultationDrawer = (props) => {
  const access=useAccess();
  const [messageTab, setMessageTab] = useState('0');
  const [state, setState] = useSetState({
    isSubmitted: false,
  });
  const [value, setValue] = useState('');
  const [internalValue, setInternalValue] = useState('');
  const [isReinstatement, setIsReinstatement] = useState(false);
  const [visible, setVisible] = useState(false);
  const [enableResyncZohoThreads, setEnableResyncZohoThreads] = useState(false);

  const enableMarkSolvedModalOnClose = useFlag('enable_mark_solved_modal_on_close')

  useEffect(() => {
    if (props.default) {
      setValue(props.default);
    }

  }, [props.default]);

  useEffect(() => {
    setVisible(props.visible)
    srvEnableResyncZohoThreadsReq.run();
  }, [props.visible]);

  useEffect(() => {
    if (props.replyData.consultation.consultation_type) {
      if (props.replyData.consultation.consultation_type == 14) {
        setIsReinstatement(true)
      } else {
        setIsReinstatement(false)
      }
    } else {
      setIsReinstatement(false)
    }
  }, [props.replyData.consultation])

  const sendReply = (payload, callback) => {
    var message;
    if (messageTab == '0') {
      message = value;
    } else {
      message = internalValue;
    }

    if (!message || !message.trim().length) {
      return;
    }

    if (!props.replyData.consultation.id || !props.replyData.consultation.id.length) {
      return;
    }

    replyConsultationReq.run({
      consultation_id: props.replyData.consultation.id,
      content: message,
      reply_type: parseInt(messageTab),
      status: payload.status
    })
  };

  const srvEnableResyncZohoThreadsReq = useRequest(srvEnableResyncZohoThreads, {
    manual: true,
    onSuccess: (ret, params) => {
      setEnableResyncZohoThreads(ret.data)
    }
  });

  const resyncFromZohoTicket = () => {
    if (!props.replyData.consultation.id || !props.replyData.consultation.id.length) {
      return;
    }
    srvResyncFromZohoTicketReq.run({
      consultation_id: props.replyData.consultation.id
    })
  }

  const srvResyncFromZohoTicketReq = useRequest(srvResyncFromZohoTicket, {
    manual: true,
    onSuccess: (ret, params) => {
      message.success('Resynced from Zoho Ticket successfully');
      props.onReply()
    }
  })


  var replyService;
  if (props.role == 'admin') {
    replyService = srvAdminSendConsultationReply
  } else {
    replyService = srvDoctorSendConsultationReply;
  }

  const replyConsultationReq = useRequest(replyService, {
    manual: true,
    onSuccess: (ret, params) => {
      props.onReply()
      setValue('');
      setInternalValue('');
    }
  })


  const sendConsultation = async () => {
    var message = value;
    if (!message || !message.trim().length) {
      return;
    }
    await sendConsultationReq.runAsync({
      title: message.substr(0, 50) + '...',
      content: message,
      user_id: props.userId,
    })
    setState({ isSubmitted: true })
  }


  const sendConsultationReq = useRequest(srvDoctorSendConsultation, {
    manual: true,
    onSuccess: (ret, params) => {
      props.onReply();
      setValue('');
    }
  })


  var changeStatusService
  if (props.role == 'admin') {
    changeStatusService = srvAdminChangeConsultationStatus
  } else {
    changeStatusService = srvDoctorChangeConsultationStatus
  }
  const changeStatusReq = useRequest(changeStatusService, {
    manual: true,
    onSuccess: (ret, params) => {
      props.onReply();
      setValue('');
      setInternalValue('');
    },
  });

  const changeStatus = async (status) => {
    await changeStatusReq.runAsync({
      consultation_id: props.replyData.consultation.id,
      status: status
    })
  }

  const assignToDoctor = () => {
    if (!props.replyData.consultation.id || !props.replyData.consultation.doctor) {
      return;
    }
    assignToDoctorReq.run({
      consultation_id: props.replyData.consultation.id,
      user_id: props.replyData.consultation.doctor.doctor_user_id,
    })
  }

  const assignToDoctorReq = useRequest(srvAssignDoctorToConsultation, {
    manual: true,
    onSuccess: (ret, params) => {
      props.onClose()
      props.onReply()
    }
  })

  const assignToCareTeam = () => {
    assignToCareTeamReq.run({
      consultation_id: props.replyData.consultation.id
    })
  }
  const assignToCareTeamReq = useRequest(srvAssignConsultation, {
    manual: true,
    onSuccess: (ret, params) => {
      props.onClose()
      props.onReply()
    }
  })

  const renderAdminHeaderDom = (props) => {
    if (
      (history.location.query.admin || props.role == 'admin') &&
      access.adminAccesser
    ) {
      return (<></>)
    }
    return (
      <>
        {
          props.replyData.consultation.status == 1 &&(!(props.hidenAssign&&!props.replyData.consultation.can_solved_flag)) &&
          <Button type="primary" onClick={() => { changeStatus(2) }}>Mark as Solved</Button>
        }
        {
          props.replyData.consultation.status == 2 &&
          <Button onClick={() => { changeStatus(1) }}>Mark as Open</Button>
        }
        {
          enableResyncZohoThreads &&
          <Button onClick={() => { resyncFromZohoTicket() }} loading={srvResyncFromZohoTicketReq.loading}>Resync from Zoho Ticket</Button>
        }
        {
          props.replyData.consultation.assigned_to == null && props.replyData.consultation.doctor != null && props.replyData.consultation.consultation_type != 13 &&
          <Button
            type="default"
            className={cx({ assignBtn: true })}
            onClick={assignToDoctor}
          >
            Assign To Clinician: {props.replyData.consultation.doctor.name}
          </Button>
        }
        {
          props.replyData.consultation.assigned_to &&
          <span>Assigned</span>
        }
      </>
    )
  }

  const renderReinstatementHeaderDom = (props) => {
    return (
      <p>Reinstatement Application</p>
    )
  }

  const renderDoctorHeaderDom = (props) => {
    return (
      <>
        {
          props.replyData.consultation.status == 1 && props.replyData.consultation.consultation_type!=12 &&(!(props.hidenAssign&&!props.replyData.consultation.can_solved_flag))&&
          <Button type="primary" onClick={() => { changeStatus(2) }} disabled={props.replyData.consultation.assigned_to_id === 0}>Mark as Solved</Button>
        }
        {
          props.replyData.consultation.status == 2 &&
          <Button onClick={() => { changeStatus(1) }} disabled={props.replyData.consultation.assigned_to_id === 0}>Mark as Open</Button>
        }
        {
          props.replyData.consultation.assigned_to_id > 0 && props.replyData.consultation.consultation_type!=12 &&!props.hidenAssign &&
          <Button
            type="default"
            className={cx({ assignBtn: true })}
            onClick={assignToCareTeam}
          >
            Assign to Care Team
          </Button>
        }
        {
          props.replyData.consultation.assigned_to_id === 0 &&!props.hidenAssign &&
          <span className={cx({ assignStatus: true })}>Assigned to Care Team</span>
        }
      </>
    )
  }

  const messageHeaderDom = () => {
    return (
      <div className={cx({ messageHeader: true })}>
        {!isReinstatement && (
          <>
            {props.role == 'admin' ? renderAdminHeaderDom(props) : renderDoctorHeaderDom(props)}
          </>
        ) || (
            renderReinstatementHeaderDom(props)
          )}
      </div>
    )
  }

  const renderMessagesList = () => {
    var displaySolvedMarker = props.replyData.consultation.status == 2
    var markerIndex = -1;
    var messages = props.replyData.consultation_replies
    if (props.replyData.consultation_update_logs?.length) {
      messages = messages.concat(props.replyData.consultation_update_logs);
    }
    messages.sort(function (a, b) {
      return a.created_at - b.created_at;
    })
    for (var i = 0; i < messages.length; i++) {
      if (messages[i].created_at > props.replyData?.consultation?.solved_at) {
        markerIndex = i;
        break;
      }
    }
    var messageDom = messages.map((v, index) => {
      const isLast = index == messages.length - 1
      if (displaySolvedMarker && index == markerIndex) {
        return (
          <div key={v.id}>
            <MessageItem data={v} selectedType={props.selectedType} opened={isLast} />
            {renderSolvedStatusMarker()}
          </div>
        )
      } else {
        return <MessageItem data={v} selectedType={props.selectedType} key={v.id} opened={isLast} role={props.role} />
      }
    });
    if (displaySolvedMarker && markerIndex == -1) {
      return (
        <div>
          {messageDom}
          {renderSolvedStatusMarker()}
        </div>
      )
    } else {
      return (
        messageDom
      )
    }
  }

  const linkify = (content) => {
    content = content.replace(/width:\s*\d+px/gm, "width: auto").replace(/min-width:\s*\d+px/gm, "min-width: auto").replace(/&nbsp;/gm, " ");
    if(props.selectedType != 13){
      return content;
    }
    return content.replace(/(?!<a[^>]*>[^<])(((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?))(?![^<]*<\/a>)/gi, '<a style="color: #5469d4" href="$1">$1</a> ');
  }

  const customMaskStyle = {
    backgroundColor: 'transparent',
  };

  const sendReplyWithStatus = (status) => {
    var message;
    if (messageTab == '0') {
      message = value;
    } else {
      message = internalValue;
    }
    if (!!message) {
      sendReply({ status: parseInt(status) })
    } else {
      alert("Can't send reply with empty message!")
      return
    }
    if (status == '2') {
        props.onSolve()
    }
    setState({ isSubmitted: true })
  }

  async function onClose () {
    await showMarkSolvedModal()
    props?.onClose()
    setState({ isSubmitted: false })
  }

  async function showMarkSolvedModal () {
    return new Promise((resolve, reject) => {
      if (enableMarkSolvedModalOnClose && state.isSubmitted && props.replyData.consultation.status === 1 && props.replyData.consultation.assigned_to_id > 0) {
        Modal.confirm({
          title: 'Is this consultation solved?',
          okText: 'Mark as solved',
          cancelText: 'Remain unsolved',
          onOk: async () => {
            await changeStatus(2)
            resolve()
          },
          onCancel: () => {
            resolve()
          },
        })
      } else {
        resolve()
      }
    })

  }

  const onSubmit = () => {
    sendReplyWithStatus(0)
  }

  const onDeny = () => {
    props.onDeny(props.replyData.consultation.id, value)
    setVisible(false)
  }

  const onApprove = () => {
    props.onApprove(props.replyData.consultation.id, value)
    setVisible(false)
  }

  const renderSolvedStatusMarker = () => {
    return (
      <div className={cx({ statusMarker: true })}>
        <hr />
        <div className={cx({ status: true })}>
          <Tag color="#89929C" className={cx({ tag: true })}>Solved{props.replyData.consultation.solved_by_name != "" && (" - " + props.replyData.consultation.solved_by_name)}</Tag>
        </div>
        <hr />
      </div>
    )
  }

  const rederReinstatementStatus = () => {
    return (
      <>
        <div className={cx({ sendbox: true })}>
          <Tabs defaultActiveKey="0" onChange={setMessageTab}>
            {(!isReinstatement && (
              <>
                <TabPane tab="Message" key="0">
                  <Input.TextArea rows={5} value={value} onChange={e => setValue(e.target.value)} className={cx({ textInput: true })} />
                </TabPane>
                {props.replyData.consultation.id && (
                  <TabPane tab="Internal note" key="1">
                    <Input.TextArea
                      rows={5}
                      value={internalValue} onChange={e => setInternalValue(e.target.value)}
                      className={cx({ internalTextInput: true })}
                    />
                  </TabPane>
                )}
              </>
            )) || (
              <>
                {(props.replyData.consultation.reinstatement_status === 0 && (
                  <>
                    <TabPane tab="Note to provider" key="0">
                      <Input.TextArea rows={5} value={value} onChange={e => setValue(e.target.value)}  className={cx({ textInput: true })} />
                    </TabPane>
                  </>
                )) || <></>}
              </>
            )}
          </Tabs>
          {!(
            (history.location.query.admin || props.role == 'admin') &&
            access.adminAccesser
          ) && (
              <>
                {' '}
                {(!isReinstatement && (
                  <div className={cx({ sendboxBtnBox: true })}>
                    {props.replyData.consultation.id ? (
                      <Button
                        type="primary"
                        onClick={onSubmit}
                        loading={replyConsultationReq.loading}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        onClick={sendConsultation}
                        loading={sendConsultationReq.loading}
                      >
                        Submit
                      </Button>
                    )}
                  </div>
                )) || (
                    <>
                      {(props.replyData.consultation.reinstatement_status === 0 && (
                        <>
                          <div className={cx({ sendboxBtnBox: true })}>
                            <a style={{ color: '#5469d4' }} onClick={onDeny}>
                              Deny
                            </a>
                            <Button type="primary" onClick={onApprove}>
                              Approve
                            </Button>
                          </div>
                        </>
                      )) || <></>}
                    </>
                  )}
              </>
            )}
        </div>
      </>
    );
  }


  return (
    <Drawer
      className={cx({ messageDrawer: true })}
      title={messageHeaderDom()}
      width="600"
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      maskStyle={customMaskStyle}
    >
      <div className={cx({ messageContainer: true })}>
        {
          props.replyData.consultation.id &&
          <div className={cx({ message: true })}
            style={(props.role == 'admin' && props.selectedType === 12) ? { marginBottom: 0 } : {}}>
            <div className={cx({ avatar: true })}>
              <div>
                {
                  props.replyData.consultation.consultation_type == 5 && props.replyData.consultation.doctor &&
                  <img
                    src={getDoctorAvatarURL(props.replyData.consultation.doctor.id, props.replyData.consultation.doctor.first_name + props.replyData.consultation.doctor.last_name, props.replyData.consultation.doctor.title)} />
                }
                {props.replyData.consultation.user_name && props.replyData.consultation.user_name.substr(0, 1)}
              </div>
            </div>
            <div className={cx({ bubble: true })}>
              <h6>{props.replyData.consultation.title}</h6>
              <p
                dangerouslySetInnerHTML={{ __html: (props.replyData.consultation.content && linkify(props.replyData.consultation.content).split('\n').join('<br/>')) }} />
              <span className={cx({ time: true })}>
                {props.replyData.consultation.created_at &&
                  moment.unix(props.replyData.consultation.created_at).fromNow()}
              </span>
            </div>
          </div>
        }
        {(props.role == 'admin' && props.selectedType === 12) && (
          <div className={cx({ message: true })} style={{ marginTop: 0 }}>
            <div style={{ backgroundColor: '#8B939B', color: '#FFFFFF' }} className={cx({ bubble: true })}>
              <p>Before changing to a new pharmacy, please contact the previous pharmacy to cancel the old prescription
                as well as check the patient PDMP.</p>
            </div>
          </div>
        )}

        {renderMessagesList()}
      </div>
      {rederReinstatementStatus()}
    </Drawer>
  );
}

export default ConsultationDrawer;
