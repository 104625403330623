import React, { useEffect, useState } from 'react';
import { Modal, Input, Form, Select, Tooltip } from 'antd';
import { useRequest, useMount, useUpdateEffect, useUnmount } from 'ahooks';
import { debounce } from 'lodash';

import { srvInsuranceCompanies, srvAdminAddOrUpdateUserInsuranceCardInfo } from '@/services/admin';
const UpdateInsuranceInfoModal = ({
  patientId,
  insuranceInfo,
  legalFirstName,
  legalLastName,
  onOk,
  onCancel,
  ...modalProps
}) => {
  const [form] = Form.useForm();
  const [insuranceCompanies, setInsuranceCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});

  const onFinish = () => {
    form.validateFields().then((values) => {
      form.resetFields();
      if (values.insurance_id) {
        values.insurance_id = values.insurance_id.toString();
      }
      const data = { id: patientId, ...values };
      if (Object.keys(selectedCompany).length!==0){
        data.insurance_company = selectedCompany.insurance_company;
        data.insurance_company_id = selectedCompany.insurance_company_id;
      }else{
        data.insurance_company = insuranceInfo.insurance_company;
      data.insurance_company_id = insuranceInfo.insurance_company_id;
      }
      
      onOk({ ...data })
      
    })
  };

  const onCancelModal = ()=>{
    form.resetFields();
    onCancel()
  }

  const modalOpts = {
    ...modalProps,
    onOk: onFinish,
    onCancel: onCancelModal
  };

  const debouncedFetchInsuranceCompanies = debounce((val) => {
    if (!val || val === '') {
      return;
    }
    fetchInsuranceCompanies.run({ query: val });
  }, 1000);

  const fetchInsuranceCompanies = useRequest(srvInsuranceCompanies, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        setInsuranceCompanies(ret.data);
      }
    },
  });

  const onSearch = (value) => {
    debouncedFetchInsuranceCompanies(value)
  };

  const onComapnyChange = (_,v) => {
    setSelectedCompany({insurance_company_id:v.key,insurance_company:v.value});
  };

  return (
    <Modal {...modalOpts} destroyOnClose={true}>
      <Form
        form={form}
        layout="vertical"
        initialValues={insuranceInfo}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Legal First Name"
          name="legal_first_name"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={legalFirstName}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Legal Last Name"
          name="legal_last_name"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={legalLastName}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Insurance Number"
          name="insurance_id"
          value={insuranceInfo && insuranceInfo.insurance_id}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input  maxLength="150"/>
        </Form.Item>
        <Form.Item label="Insurance Company" name="insurance_company">
          <Select
            style={{ width: '100%' }}
            onChange={onComapnyChange}
            filterOption={false}
            onSearch={onSearch}
            showSearch={true}
            rules={[
              {
                required: true,
              },
            ]}
          >
            {insuranceCompanies.map((v) => {
              const text = `${v.insurance_company} `;
              return (
                <Select.Option key={v.insurance_company_id} value={v.insurance_company}>
                  <Tooltip title={text}>{text}</Tooltip>
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateInsuranceInfoModal;
