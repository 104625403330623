import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames/bind';
import { connect, history, Link,useAccess } from 'umi';
import { downloadFile } from '@/utils/utils';
import {
  Drawer,
  Descriptions,
  Space,
  Popover,
  Input,
  Table,
  Row,
  Col,
  Button,
  Popconfirm,
  Form,
  Tooltip,
} from 'antd';
import moment from 'moment';
import { SearchOutlined, createFromIconfontCN,CloseCircleOutlined} from '@ant-design/icons';
import numeral from 'numeral';
import { AsYouType } from 'libphonenumber-js';
import { CPTCodesMap } from '@/components/CPTCodesFormItem';
import { formatLastRefillDate } from '@/components/LastRefiiDateFormItem';
import Filters from '@/components/Filters';
import defaultSettings from '../../../defaultSettings';
import NotesDetail from '../../components/ProviderNotesPane/NotesDetail';
import DoctorChartAuditDrawer from '@/components/DoctorChartAuditDrawer';
import { formatUnix, formatNextFollowUpMonth } from '../../utils/xtime';
import styles from './index.less';

import { useRequest } from 'ahooks';
import { useChartAuditStore } from '@/stores';

import {
  srvDeleteTreatmentPlanHistory,
  srvUpdateTreatmentPlanHistory,
  srvTreatmentPlanHistoryDetail,
  srvDeleteTreatmentPlanHistoryEnable
} from '@/services/admin';
const cx = cn.bind(styles);
import {
  srvGetDocumentURL,
} from '@/services/patient';
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const filters = [
  {
    name: 'doctor_email',
    label: 'Doctor Email',
    type: 'text',
  },
  {
    name: 'doctor_phone',
    label: 'Doctor Phone',
    type: 'text',
  },
  {
    name: 'status',
    label: 'Status',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'note_audit',
    label: 'Note Audit',
    type: 'checkbox',
    items: [
      { label: 'Audited', value: 1 },
      { label: 'Unaudited', value: 0 },
    ],
  },
  {
    name: 'plan_type',
    label: 'Type',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'created_at',
    label: 'Date',
    type: 'date',
  },
];

const Patient = (props) => {
  const {
    active,
    id,
    treatment_plan_histories,
    treatment_plan_types,
    treatment_plan_statuses,
    loadingTreatmentPlanHistories,
    treatmentPlanHistory,
    loadTreatmentPlanHistory,
    account,
  } = props;

  const { currentUser } = account;
  const { can_edit_notes } = currentUser;

  const {open: openChartAudit} = useChartAuditStore();
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [selected, setSelected] = useState('');
  const [audit, setAudit] = useState(false);
  const [deleteNoteHistoryId, setDeleteNoteHistoryId] = useState(null);
  const [noteHistoryToEdit, setNoteHistoryToEdit] = useState(null);
  const [form] = Form.useForm();
  const [selectNoteType, setSelectNoteType] = useState(0);
  const [reasons,setReasons] =useState('');
  const [bridgeRefill,setBridgeRefill]=useState('');
  const [transferTreatmentPlanHistory, setTransferTreatmentPlanHistory] = useState({});
  const [attachFiles,setAttachFiles]=useState([]);
  const [imgVisible,setImgVisible]=useState(false);
  const [imgUrl,setImgUrl]=useState('');
  const [previewFileType, setPreviewFileType] = useState('');
  const [recommendations, setRecommendations] = useState('');
  const access=useAccess();
  const [enableDeleteTreatmentPlanHistory, setEnableDeleteTreatmentPlanHistory] = useState(false);
  const onHistoryClose = () => {
    setHistoryVisible(false);
    setAudit(false);
    setSelected('');
  };

  const selectRow = (record) => {
    setSelected(record.id);
    setSelectNoteType(record.note_type);
    setHistoryVisible(true);
    if (record.audit_id != '0' && record.audit && Object.keys(record.audit).length) {
      setAudit(record.audit);
      openChartAudit(record.audit);
    }
    if (record.note_type === 4||record.note_type===5) {
      loadHistoryReq.run({ id: record.id });
    } else {
      loadTreatmentPlanHistory({ id: record.id });
    }
  };

  const renderNoteType = (r) => {
    let text = '';
    if (r.note_type == '1') {
      text = 'Initial';
    } else if (r.note_type == '2') {
      text = 'Follow-up';
    } else if (r.note_type == '3') {
      text = 'Refill Request';
    } else if (r.note_type == '4') {
      text = 'Transfer';
    } else if (r.note_type == '5') {
      text = 'Discharge';
    } else if (r.note_type == '6') {
      text = 'Generic Therapy Note';
    } else if (r.note_type == '7') {
      text = 'Coverage Note';
    } else if (r.note_type == '8') {
      text = 'Plain'
    } else if (r.note_type == '9') {
      text = 'Async Video Follow-Up'
    } else if (r.note_type == '10') {
      text = 'Coaching Note';
    } else if (r.note_type == '11') {
      text = 'Transfer Of Care';
    }
    return text;
  }

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        return r.status_name;
      },
    },
    {
      title: 'Plan Type',
      dataIndex: 'plan_type',
      key: 'plan_type',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return r.plan_type_name;
      },
    },
    {
      title: 'Notes Type',
      dataIndex: 'note_type',
      key: 'note_type',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return renderNoteType(r);
      },
    },
    {
      title: 'Diagnostic Code',
      dataIndex: 'diagnostic_code',
      ellipsis: true,
      key: 'diagnostic_code',
      width: 300,
      render: (v, r) => {
        return (
          <Tooltip placement="topLeft" title={r.diagnostic_code}>
            <div style={{ width: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {r.diagnostic_code}
            </div>
          </Tooltip>
        )
      }
    },
    {
      title: 'Rx',
      dataIndex: 'rx',
      ellipsis: true,
      key: 'rx',
    },
    {
      title: 'Clinician',
      dataIndex: 'doctor_id',
      key: 'doctor_id',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        if(!r.doctor){
          if(r.system_generated){
            return 'System genterted';
          }
          return '';
        }
        const userName = `${r.doctor.name} ${r.doctor.title}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/doctor/detail/${r.doctor.id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/doctor/detail/${r.doctor.id}`}>{r.doctor.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.doctor.phone)}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <a href="#">{userName}</a>
          </Popover>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: 200,
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
    },
    {
      title: 'Note Audit',
      dataIndex: 'audit_id',
      key: 'audit_id',
      ellipsis: true,
      width: 120,
      render: (v, r) => {
        return r.audit_id != '0' ? 'Audited' : 'Unaudited';
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: false,
      width: 120,
      render: (v, r) => {
        return (
          <Space>
            {/* <Button onClick={(e)=>{
              window.open(`/api/admin/patient/clinician_note/pdf?id=${r.id}`, '_blank');
              e.stopPropagation();
              }} size="small">Export to PDF</Button> */}
          </Space>
        );
      },
    },
  ];

  if (can_edit_notes&&!(history.location.query.admin && access.adminAccesser)) {
    columns.push({
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              type="default"
              onClick={(e) => {
                e.stopPropagation();
                editNoteHistory(record);
              }}
            >
              Edit
            </Button>
            <Popconfirm
              title="Delete Provider Note History"
              visible={record.id == deleteNoteHistoryId}
              onCancel={(e) => {
                e.stopPropagation();
                setDeleteNoteHistoryId(null);
              }}
              onConfirm={deleteNoteHistory}
            >
              <Button
                type="danger"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteNoteHistoryId(record.id);
                }}
              >
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    });
  }

  const editNoteHistory = (noteHistory) => {
    loadTreatmentPlanHistory({ id: noteHistory.id });
    setNoteHistoryToEdit(noteHistory);
  };

  const onSaveNoteHistory = (values) => {
    var payload = { ...values, id: noteHistoryToEdit.id };
    updateNoteHistoryReq.run(payload);
  };

  const updateNoteHistoryReq = useRequest(srvUpdateTreatmentPlanHistory, {
    manual: true,
    onSuccess: (ret, params) => {
      setNoteHistoryToEdit(null);
      props.loadTreatmentPlanHistories({ page: 1, page_size: 20 }, () => {});
    },
  });

  const getEnableDeleteTreatmentPlanHistory = useRequest(srvDeleteTreatmentPlanHistoryEnable, {
    manual: true,
    onSuccess: (ret, params) => {
      setEnableDeleteTreatmentPlanHistory(ret.data);
    },
  });


  const deleteNoteHistory = (e, id) => {
    e.stopPropagation();
    setDeleteNoteHistoryId(null);
    deleteNoteHistoryReq.run({ id: deleteNoteHistoryId });
  };

  const deleteNoteHistoryReq = useRequest(srvDeleteTreatmentPlanHistory, {
    manual: true,
    onSuccess: (ret, params) => {
      props.loadTreatmentPlanHistories({ page: 1, page_size: 20 }, () => {});
    },
  });
  const allReasons = [
    {
      label: 'Exclusionary or Complex Medical Condition/ Diagnosis',
      checked: false,
      key: "r1",
      options: [
        {
          key: '7',
          checked: false,
          label: 'Currently Pregnant or Breastfeeding'
        },
        {
          key: '1',
          checked: false,
          label: 'History of Heart Attack or Arrhythmias'
        },
        {
          key: '2',
          checked: false,
          label: 'History of Stroke or Seizure Disorder'
        },
        {
          key: '3',
          checked: false,
          label: 'Uncontrolled Hypertension'
        },
        {
          key: '4',
          checked: false,
          label: 'Multiple Co-morbidities and/ or Polypharmacy'
        },
        {
          key: '5',
          checked: false,
          label: 'Other Cardiac Disorder'
        },
        {
          key: '6',
          checked: false,
          label: 'Other Neurological Disorder'
        },
      ]
    },
    {
      label: 'Exclusionary or Complex Psychiatric Condition / Diagnosis',
      checked: false,
      key: 'r2',
      options: [
        {
          key: '1',
          checked: false,
          label: 'Bipolar/ Mood Disordes'
        },
        {
          key: '2',
          checked: false,
          label: 'History of Psychiatric Hospitalization'
        },
        {
          key: '3',
          checked: false,
          label: 'Severe Depression/ Suicidality'
        },
      ]
    },
    {
      label: 'No ADHD diagnosis',
      key: 'r3',
      checked: false,
      options: [
        {
          key: '1',
          checked: false,
          label: 'Patient does not meet DSM-V Criteria for relevant Psychiatric Diagnosis'
        },
      ]
    },
    {
      label: 'Non-compliance',
      key: 'r4',
      checked: false,
      options: [
        {
          key: '1',
          checked: false,
          label: 'Patient sought care from provider outside of done platform'
        },
        {
          key: '2',
          checked: false,
          label: 'patient not attending appointments or minimum follow-up as required'
        },
        {
          key: '3',
          checked: false,
          label: 'patient not taking medications as advised'
        },
      ]
    },
    {
      label: 'Use of Multiple Controlled Substances',
      key: 'r5',
      checked: false,
      options: [
        {
          key: '1',
          checked: false,
          label: 'PDMP or pt reports regular use of benzodiazepines'
        },
        {
          key: '2',
          checked: false,
          label: 'PDMP or pt reports regular use of controlled pain medication'
        },
        {
          key: '3',
          checked: false,
          label: 'PDMP or pt reports use of other stimulant medication (e.g. phentermine)'
        },
        {
          key: '4',
          checked: false,
          label: 'PDMP or pt reports use of Suboxone/Buprenorphine/Methadone'
        }, {
          key: '5',
          checked: false,
          label: 'PDMP or pt reports use of OTHER controlled substances'
        },
        {
          key: '6',
          checked: false,
          label: 'pt reports uncontrolled use of substances(e.g.alcohol,club drugs,etc.)'
        },
      ]
    },
    {
      label: 'Unacceptable Actions/ Behaviors',
      key: 'r6',
      checked: false,
      options: [
        {
          key: '1',
          checked: false,
          label: 'Aggressive, demanding, or threatening'
        },
        {
          key: '2',
          checked: false,
          label: 'Dishonesty! faulty reporting'
        },
        {
          key: '3',
          checked: false,
          label: 'Discriminatory'
        },
        {
          key: '4',
          checked: false,
          label: 'Overstepping boundaries'
        },
      ]
    }, {
      label: 'Provider left the practice',
      key: 'r8',
      checked: false,
      options: []
    },
  ];

  const toPreview = (file) => {
    srvGetDocumentURL({
      object_key: file.object_key,
    }).then((res) => {
      if (res.success) {
        fetchPreviewInfo(res.data);
      }
    })
  };
  const fetchPreviewInfo = (url) => {
    fetch(url)
      .then((res) => {
        const contentType = res.headers.get('Content-Type');
        if (contentType === 'application/pdf') {
          setPreviewFileType(contentType);
        }
        setImgUrl(url);
        setImgVisible(true);
      })
      .catch((e) => {
        window.sendErrorLog(e);
      });
  }
  const loadHistoryReq = useRequest(srvTreatmentPlanHistoryDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setTransferTreatmentPlanHistory(ret.data);
        if (ret.data !== '') {
          if (ret.data.recommendation_reasons != '') {
            var reasonTemp = '';
            var select = JSON.parse(ret.data.recommendation_reasons);
            allReasons.map((item) => {
              if (Object.keys(select).includes(item.key)) {
                reasonTemp = reasonTemp + item.label;
                if (select[item.key].length === 0) {
                  reasonTemp = reasonTemp + ':\n';
                } else {
                  var selectOptions = select[item.key];
                  if (item.options.length > 0) {
                    reasonTemp=reasonTemp+': ';
                    item.options.map((childItem) => {
                      if (selectOptions.includes(childItem.key)) {
                        reasonTemp = reasonTemp+childItem.label + '; ';
                      }
                    });
                  }

                }
              }
            });
            if (Object.keys(select).includes('r7')) {
              if (Object.keys(select).includes('other_reason')) {
                reasonTemp = reasonTemp + 'Other reason:\n' + select['other_reason'];
              } else {
                reasonTemp = reasonTemp + 'Other reason:\n';
              }
            }
            setReasons(reasonTemp);
          }
          if (ret.data.recommendations === 1) {
            setRecommendations('Recommend a transfer to another provider');
          } else if (ret.data.recommendations === 2) {
            setRecommendations('Recommend permanent discharge from the platform');
          }
          if (ret.data.bridge_refill_attestation === 1) {
            if (ret.data.bridge_refill_attestation_date === 0 || ret.data.bridge_refill_attestation_date === null) {
              setBridgeRefill('I attest that I have reviewed the patient’s PDMP and issued a bridge refill for any medications this patient requires. The refill was sent on: ');
            } else {
              setBridgeRefill('I attest that I have reviewed the patient’s PDMP and issued a bridge refill for any medications this patient requires. The refill was sent on: ' + formatUnix(ret.data.bridge_refill_attestation_date));
            }
          } else if (ret.data.bridge_refill_attestation === 2) {
            setBridgeRefill('The patient does not require a bridge refill.');
          }
          if (ret.data.attach_files != '' && ret.data.attach_files != '[]') {
            setAttachFiles(JSON.parse(ret.data.attach_files));
          }
        }
      }
    },
  });

  const deleteNoteHistoryAction = (id) => {
    deleteNoteHistoryReq.run({ id: id });
    onHistoryClose();
    setDeleteNoteHistoryId(null);
  }

  useEffect(() => {
    setPage(1);
    props.loadTreatmentPlanHistories({ page: 1, page_size: 20 }, () => {});
    props.loadTreatmentPlanStatuses();
    props.loadTreatmentPlanTypes();
    getEnableDeleteTreatmentPlanHistory.run();
  }, [active, id]);

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    props.loadTreatmentPlanHistories(
      { page: 1, page_size: 20, filters: JSON.stringify(q) },
      () => {},
    );
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    props.loadTreatmentPlanHistories(
      { page: pagination.current, page_size: pagination.pageSize, filters: JSON.stringify(query) },
      () => {},
    );
  };

  const f = () => {
    for (const v of filters) {
      if (v.name === 'status' && v.items.length === 0) {
        v.items = treatment_plan_statuses;
        continue;
      }
      if (v.name === 'plan_type' && v.items.length === 0) {
        v.items = treatment_plan_types;
        continue;
      }
    }
    return filters;
  };

  const renderAddendum = () => {
    return treatmentPlanHistory && treatmentPlanHistory.Addendums && treatmentPlanHistory.Addendums.length ? (
      treatmentPlanHistory.Addendums.map((addendum) => (
        <Descriptions title="Addendums" layout="vertical" column={1} size="small" bordered={true}>
          <Descriptions.Item
            label={
              addendum.creator_type +
              ' Addendum - ' +
              addendum.creator_name +
              ' ' +
              addendum.creator_title +
              ' ' +
              moment.unix(addendum.created_at).format('MM/DD/YYYY')
            }
          >
            <div className={styles.lineBreak}>{addendum.addendum}</div>
          </Descriptions.Item>
        </Descriptions>
      ))
    ) : (
      <></>
    );
  };

  const renderGenericNoteDescriptions = () => {
    return (
      <>
        <Descriptions title="Diagnostics" layout="vertical" column={1} size="small" bordered={true}>
              <Descriptions.Item label="Updated At">
                {treatmentPlanHistory.updated_at && formatUnix(treatmentPlanHistory.updated_at)}
              </Descriptions.Item>
              <Descriptions.Item label="CPT Code">
                {CPTCodesMap[treatmentPlanHistory.cpt_code] ? CPTCodesMap[treatmentPlanHistory.cpt_code] : treatmentPlanHistory.cpt_code}
              </Descriptions.Item>

              <Descriptions.Item label="Clinician Notes">
                <div className={styles.lineBreak}>{treatmentPlanHistory.provider_notes}</div>
              </Descriptions.Item>
            </Descriptions>

      </>
    );
  }

  const renderTransferNoteDescriptions = () => {
    return <>
      <Descriptions
        title="Diagnostics"
        layout="vertical"
        column={1}
        size="small"
        bordered={true}
      >
             {selectNoteType === 4 && (
               <>
                 <Descriptions.Item label="Note type">Transfer Note</Descriptions.Item>
               </>
             )}
             {selectNoteType === 5 && (
               <>
                 <Descriptions.Item label="Note type">Discharge Note</Descriptions.Item>
               </>
             )}
        <Descriptions.Item label="Recommendations">
          {recommendations}
        </Descriptions.Item>
        <Descriptions.Item style={{ whiteSpace: 'pre-line' }} label="Reasons For Recommendation(Discharge/Transfer)">
          {reasons}
        </Descriptions.Item>
        <Descriptions.Item label="Bridge Refill Attestation">
          {bridgeRefill}
        </Descriptions.Item>
        <Descriptions.Item label="Date of Initial Evaluation">
          {transferTreatmentPlanHistory.initial_evaluation_date > 0 ? formatUnix(transferTreatmentPlanHistory.initial_evaluation_date):""}
        </Descriptions.Item>
        <Descriptions.Item label="Current Diagnoses">
          {transferTreatmentPlanHistory.diagnostic_code}
        </Descriptions.Item>
        <Descriptions.Item label="Diagnosis Notes">
          {transferTreatmentPlanHistory.diagnostic_code_notes}
        </Descriptions.Item>
        <Descriptions.Item label="CPT Code">
          {CPTCodesMap[treatmentPlanHistory.cpt_code] ? CPTCodesMap[treatmentPlanHistory.cpt_code] : treatmentPlanHistory.cpt_code}
        </Descriptions.Item>
        {treatmentPlanHistory.addon_code && (
          <Descriptions.Item label="Add-on Code">
            {CPTCodesMap[treatmentPlanHistory.addon_code] ? CPTCodesMap[treatmentPlanHistory.addon_code] : treatmentPlanHistory.addon_code}
          </Descriptions.Item>
        )}
        <Descriptions.Item style={{ whiteSpace: 'pre-line' }} label="Medication History">
          {transferTreatmentPlanHistory.medication_history}
        </Descriptions.Item>
        <Descriptions.Item label="Medication Notes">
          {transferTreatmentPlanHistory.medication_history_notes}
        </Descriptions.Item>
        <Descriptions.Item label="Treatment Summary">
          {transferTreatmentPlanHistory.treatment_summary}
        </Descriptions.Item>
      </Descriptions>
      {renderAddendum()}
      <Descriptions
        title="Diagnostics"
        layout="vertical"
        column={1}
        size="small"
        bordered={true}
      >
        <Descriptions.Item label="Attach File">
          {attachFiles.length > 0 && (attachFiles.map((file) => (
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: 'auto' }}>{file.name}</div>
                <div style={{ color: '#5469d4', fontSize: '16px', cursor: 'pointer', marginRight: '10px' }}><span onClick={() => toPreview(file)}>Preview</span></div>
                <div style={{ color: '#5469d4', fontSize: '16px', cursor: 'pointer' }}><span onClick={() => downloadFile(file)}>Download</span></div>
              </div>
            </>
          )
          ))}
        </Descriptions.Item>
      </Descriptions>
    </>
  }

  return (
    <div className={cx({ subcontainer: true })}>
      {imgVisible && (
        <>
          <div
            className="img-preview-container"
            style={{
              position: 'absolute',
              zIndex: '9999',
              top: '0',
              bottom: '0',
              left: '0',
              right: '0',
              margigin: 'auto',
              width: '90%',
              height: '90%',
            }}
          >
            <CloseCircleOutlined
              className="close-button"
              onClick={() => {
                setImgVisible(false);
              }}
            />
            {previewFileType === 'application/pdf' ? (
              <object className="identity-img-large" data={imgUrl} type="application/pdf" />
            ) : (
              <img className="identity-img-large" src={imgUrl} />
            )}
          </div>
          <div
            onClick={() => {
              setImgVisible(false);
            }}
            className="upload-mask"
          />
        </>
      )}
      <div className={cx({ subheader: true })}>
        <h3>Clinician Notes</h3>
        <Space>
          <Filters filters={f()} onFilter={onFilter} />
        </Space>
      </div>

      {audit && audit.id && historyVisible && (
        <DoctorChartAuditDrawer
          visible={historyVisible}
          setVisible={onHistoryClose}
          audit={audit}
          onRefresh={() => {}}
        />
      )}

      {!audit && historyVisible && (
        <Drawer
          className={cx({ historyDrawer: true })}
          title={
              <div className={cx({ title: true })}>
                <h2>Clinician Notes History</h2>
                {enableDeleteTreatmentPlanHistory && (
                    <Button type="danger" onClick={() => deleteNoteHistoryAction(selected)}>delete</Button>
                )}
              </div>
          }
          width="600"
          placement="right"
          closable={false}
          onClose={onHistoryClose}
          visible={historyVisible}
          // extra={
          // <Space>
          //   {enableDeleteTreatmentPlanHistory && (
          //   <Button type="danger" onClick={() => deleteNoteHistoryAction(treatmentPlanHistory.id)}>delete</Button>
          //   )}
          // </Space>
          // }
        >
          {selectNoteType === 1 && <NotesDetail data={treatmentPlanHistory} />}
          {(selectNoteType === 4 || selectNoteType === 5) && renderTransferNoteDescriptions()}
          {selectNoteType === 6 && renderGenericNoteDescriptions()}
          {![1, 4, 5, 6].includes(selectNoteType) && (
            <>
              <Descriptions
                title="Diagnostics"
                layout="vertical"
                column={2}
                size="small"
                bordered={true}
              >
                <Descriptions.Item label="Note type">{renderNoteType(treatmentPlanHistory)}</Descriptions.Item>
                <Descriptions.Item label="Updated At">
                  {treatmentPlanHistory.updated_at && formatUnix(treatmentPlanHistory.updated_at)}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions
                title="Diagnostics"
                layout="vertical"
                column={1}
                size="small"
                bordered={true}
              >
                <Descriptions.Item label="Rx">{treatmentPlanHistory.rx}</Descriptions.Item>
                <Descriptions.Item label="Diagnostic Code">
                  {treatmentPlanHistory.diagnostic_code}
                </Descriptions.Item>
                 {[1, 2, 3, 7, 11].includes(selectNoteType) && (<Descriptions.Item label="Last Refill Date">{formatLastRefillDate(treatmentPlanHistory.last_refill_date)}</Descriptions.Item>) }
                <Descriptions.Item label="CPT Code">
                  {CPTCodesMap[treatmentPlanHistory.cpt_code]
                    ? CPTCodesMap[treatmentPlanHistory.cpt_code]
                    : treatmentPlanHistory.cpt_code}
                </Descriptions.Item>
                {treatmentPlanHistory.addon_code && (
                  <Descriptions.Item label="Add-on Code">
                    {CPTCodesMap[treatmentPlanHistory.addon_code]
                      ? CPTCodesMap[treatmentPlanHistory.addon_code]
                      : treatmentPlanHistory.addon_code}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="Attempted Medications">
                  {treatmentPlanHistory.attempted_medications}
                </Descriptions.Item>
                <Descriptions.Item label="Clinician Notes">
                  <div className={styles.lineBreak}>{treatmentPlanHistory.provider_notes}</div>
                </Descriptions.Item>
                {
                  parseInt(selectNoteType) !== 6 && treatmentPlanHistory.next_follow_up_date > 0 &&
                  <>
                    <Descriptions.Item label="Next Follow Up Date">
                      {treatmentPlanHistory.next_follow_up_date > 0
                        ? formatUnix(treatmentPlanHistory.next_follow_up_date, 'MMM D, YYYY')
                        : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="I‘d like to remind this patient to schedule their next follow-up appointment in">
                      {formatNextFollowUpMonth(treatmentPlanHistory.next_follow_up_month)}
                    </Descriptions.Item>
                  </>
                }
                <Descriptions.Item label="Message To Patient">
                  <div className={styles.lineBreak}>{treatmentPlanHistory.message_to_patient}</div>
                </Descriptions.Item>
              </Descriptions>
            </>
          )}
          {renderAddendum()}
        </Drawer>
      )}

      <Drawer
        className={cx({ historyDrawer: true })}
        visible={
          noteHistoryToEdit != null &&
          treatmentPlanHistory != null &&
          noteHistoryToEdit.id == treatmentPlanHistory.id
        }
        width="600"
        title="Edit Clinician Notes History"
        onClose={() => {
          setNoteHistoryToEdit(null);
        }}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={treatmentPlanHistory}
          onFinish={onSaveNoteHistory}
        >
          <Form.Item
            name="diagnostic_code"
            label="Diagnostic Code"
            rules={[
              {
                required: true,
                message: 'Diagnostic Code is required',
              },
            ]}
          >
            <Input placeholder="Diagnostic Code" />
          </Form.Item>
          <Form.Item
            name="rx"
            label="Rx"
            rules={[
              {
                required: true,
                message: 'Rx is required',
              },
            ]}
          >
            <Input placeholder="Rx" />
          </Form.Item>
          <Form.Item name="attempted_medications" label="Attempted Medications">
            <Input placeholder="Attempted Medications" />
          </Form.Item>
          <Form.Item
            name="provider_notes"
            label="Clinician Notes"
            rules={[
              {
                required: true,
                message: 'Clinician Notes is required',
              },
            ]}
          >
            <Input.TextArea wrap="on" rows={5} placeholder="Clinician Notes" />
          </Form.Item>
          <Form.Item name="message_to_patient" label="Message To Patient">
            <Input.TextArea rows={5} placeholder="Message To Patient" />
          </Form.Item>
          <div className={cx({ btnBox: true })}>
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          </div>
        </Form>
      </Drawer>

      <Table
        columns={columns}
        scroll={{ x: true }}
        dataSource={treatment_plan_histories.items}
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
        rowClassName={(r) => {
          if (r.id == selected) {
            return 'rowSelected';
            // return styles.rowSelected;
          }
          return '';
        }}
        rowKey="id"
        loading={loadingTreatmentPlanHistories}
        onChange={handleTableChange}
        pagination={{
          pageSize: 20,
          current: page,
          simple: true,
          total: treatment_plan_histories.total,
        }}
      />
    </div>
  );
};

export default connect(({ account, patient_detail }) => ({
  account,
  treatment_plan_histories: patient_detail.treatment_plan_histories,
  treatment_plan_types: patient_detail.treatment_plan_types,
  treatment_plan_statuses: patient_detail.treatment_plan_statuses,
  treatmentPlanHistory: patient_detail.treatmentPlanHistory,
}))(Patient);
