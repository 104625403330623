import React, { useState, useEffect, useRef } from 'react';
import { connect, history, Link } from 'umi';
import {
  Modal,
  Space,
  Popover,
  Input,
  Table,
  Row,
  Button,
  message,
  Drawer,
  Form,
  Checkbox, Tooltip
} from 'antd';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

import moment from 'moment';
import { srvChartAuditCount } from '@/services/patient';
import { useRequest, useMount } from 'ahooks';

const EHRChartAuditHome = () => {

  const [data, setData] = useState({ audit_at: moment().unix(), pending: 0, completed: 0 });

  const chartAuditCountReq = useRequest(srvChartAuditCount, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setData(ret.data);
      }
    },
  });


  useEffect(() => {
    chartAuditCountReq.run()
  }, []);

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Chart Audit</h3>
      </div>
      <div className={cx({ ChartAuditCount: true })}>
        Current Period: <span>{moment.unix(data.audit_at).format('MMM YYYY')}</span>
        Pending Audits: <span className={cx({ ChartAuditPending: true })}>{data.pending}</span>
        Completed Audits: <span>{data.completed}</span>
      </div>
      <br />
      <div className={cx({ ChartAuditNotes: true })}>
        Notes:
        <ol>
          <li>A randomized selection of charts for audit will be automatically generated on the first day of each month. The audit cycle is one month. Please ensure that all audits for the current period are completed by the last day of each month. <Link to={`/ehr_chart_audit/history`} className={cx({ link: true, ChartAuditHistoryLink: true })}>Click here to view historical audit chart results.</Link></li>
          <li>A comprehensive audit of all provider notes will be conducted every three months. If a provider's initial audit score is below 2.1, they will be subject to additional audits in the following two months.</li>
          <li>New providers will begin to be audited in the second month of their employment. Providers who are about to leave will not be included in the audit for their final month.</li>
          <li>Your identity and the audit results will be anonymized when presented to the provider. Please maintain objectivity and impartiality throughout the review process.</li>
        </ol>
      </div>
      <br />
      <Link to={`/ehr_chart_audit/notes`} className={cx({ link: true })}>
        <Button type="primary" className={cx({ ChartAuditNotesStart: true })}>Start</Button>
      </Link>
    </div>
  )
}

export default EHRChartAuditHome;
