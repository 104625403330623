import React, { useState, useEffect, useRef } from 'react';

import { Layout, Result, Button, Spin } from 'antd';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import { useFlag } from '@unleash/proxy-client-react';
import {
  initIntakeformInfo,
  findIntakeFormAnswer,
} from '@/components/PatientInfoPane/intakeform.js';

import { srvPatientDetail } from '@/services/patient';

import PatientSider from './PatientSider';
import PatientDrawers from './PatientDrawers';
import InitialEvaluationNotesForm from './InitialEvaluationNotesForm';
import InitialEvaluationNotesFormV2 from './InitialEvaluationNotesFormV2';

const { Sider, Content } = Layout;

const InitialEvaluationNote = (props) => {
  const { form, patient, appointment, setNoteVisible, onRefresh} = props;

  const flagStructNoteV2 = useFlag('struct_note_v2');
  
  const [patientInfo, setPatientInfo] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [intakeformInfo, setIntakeformInfo] = useState(() => initIntakeformInfo());
  const [intakePHQVisible, setIntakePHQVisible] = useState(false);
  const [intakeGADVisible, setIntakeGADVisible] = useState(false);
  const [intakeASRSVisible, setIntakeASRSVisible] = useState(false);

  const loadPatientInfoReq = useRequest(srvPatientDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setPatientInfo(ret.data);
        setLoadings(false);
      }
    },
  });

  useMount(() => {
    if (patient && patient.id) {
      setLoadings(true);
      loadPatientInfoReq.run({ id: patient.id });
    }
  });

  useEffect(() => {
    if (!patientInfo || Object.keys(patientInfo).length === 0) {
      return;
    }
    const intakeform = patientInfo.intake_form;
    const assessment = patientInfo.assessment;
    let intakeformAnswers = [];
    let assessmentAnswers = [];
    if (intakeform !== null) {
      if (intakeform.options != null) {
        intakeformAnswers = JSON.parse(intakeform.options);
      }

      form.setFieldsValue({
        previous_medications: intakeform.previous_adhd_medications,
        current_medications: intakeform.current_medications,
        allergic_drugs: intakeform.allergic_drugs,
        // provider_notes: notes,
        // message_to_patient: msg,
        // diagnostic_code: treatmentPlan.diagnostic_code,
        // rx: treatmentPlan.rx,
      });
    }
    if (assessment !== null) {
      if (assessment.options != null) {
        assessmentAnswers = JSON.parse(assessment.options);
      }
    }

    setIntakeformInfo((prev) =>
      prev.map((i) => {
        i.answer = findIntakeFormAnswer(i, intakeformAnswers, assessmentAnswers);
        return i;
      }),
    );
  }, [patientInfo]);

  const renderNotesForm = () => {
    return flagStructNoteV2 ? (
      <InitialEvaluationNotesFormV2
        form={form}
        patient={patient}
        appointment={appointment}
        setNoteVisible={setNoteVisible}
        onRefresh={onRefresh}
      />
    ) : (
      <InitialEvaluationNotesForm
        form={form}
        patient={patient}
        appointment={appointment}
        setNoteVisible={setNoteVisible}
        onRefresh={onRefresh}
      />
    );
  };

  return (
    <Layout>
      <Sider
        theme="light"
        width={380}
        style={{
          backgroundColor: '#FBFBFB',
          overflow: 'auto',
          height: '100vh',
          position: 'relative',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Spin tip="Loading..." spinning={loadings}>
          <PatientSider
            patientInfo={patientInfo}
            intakeformInfo={intakeformInfo}
            showASRS={() => setIntakeASRSVisible(true)}
            showIntakeGAD={() => setIntakeGADVisible(true)}
            showIntakePHQ={() => setIntakePHQVisible(true)}
          />
        </Spin>
      </Sider>
      <Content
        theme="light"
        style={{
          backgroundColor: '#fff',
          overflow: 'auto',
          height: '100vh',
          position: 'relative',
          right: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Spin tip="Loading..." spinning={loadings}>
          {renderNotesForm()}
        </Spin>
        <PatientDrawers
          patientInfo={patientInfo}
          onIntakeASRSClose={() => setIntakeASRSVisible(false)}
          intakeASRSVisible={intakeASRSVisible}
          onIntakeGADClose={() => setIntakeGADVisible(false)}
          intakeGADVisible={intakeGADVisible}
          onIntakePHQClose={() => setIntakePHQVisible(false)}
          intakePHQVisible={intakePHQVisible}
        />
      </Content>
    </Layout>
  );
};

export default InitialEvaluationNote;
