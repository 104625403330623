import React, { useState, useEffect, useRef } from 'react';
import { message, Row, Input, Button, Select } from 'antd';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import { FormOutlined } from '@ant-design/icons';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import EmailValidator from 'email-validator';
import copy from 'copy-to-clipboard';

import { srvImpersonatePatientLogin } from '@/services/admin';
import styles from './index.less';
const cx = cn.bind(styles);

const ImpersonateLogin = (props) => {
  const [data, setData] = useState({});
  const [email, setEmail] = useState('');
  const [source, setSource] = useState(1)

  const impersonateLoginReq = useRequest(srvImpersonatePatientLogin, {
    manual: true,
    onSuccess: (ret, params) => {
      setData(ret.data);
    },
  });

  const getLink = () => {
    // const emailVal = inputEl.current.state.value;
    if (!EmailValidator.validate(email)) {
      message.error('invalid email.');
      return;
    }
    impersonateLoginReq.run({ email, source });
  };

  const updateSource = (v) => {
    setSource(v)
  }

  const link = () => {
    if (data && data.user_id) {
      switch (data.env) {
        case 'prod':
          if(source == 1) {
            return `${MINDFUL_PROD_PORTAL_URL}/api/oauth/impersonate?token=${data.access_token}&expires_in=${data.expires_in}`;
          } else {
            return `${PROD_PORTAL_URL}/api/oauth/impersonate?token=${data.access_token}&expires_in=${data.expires_in}`;
          }
        case 'uat':
          if(source == 1) {
            return `${MINDFUL_UAT_PORTAL_URL}/api/oauth/impersonate?token=${data.access_token}&expires_in=${data.expires_in}`;
          } else {
            return `${UAT_PORTAL_URL}/api/oauth/impersonate?token=${data.access_token}&expires_in=${data.expires_in}`;
          }
        case 'dev':
          return `${DEV_PORTAL_URL}/api/oauth/impersonate?token=${data.access_token}&expires_in=${data.expires_in}`;
        default:
          return '';
      }
    }

    return '';
  };

  const copyLink = () => {
    const linStr = link();

    copy(linStr, {
      debug: true,
      message: 'copied',
      format: 'text/plain',
    });

    message.info('Copied');
  };

  useMount(() => {
    // impersonateLoginReq.run({});
  });

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Impersonate Patient</h3>
      </div>
      <div className={cx({ mb10: true })}>
        Choose patient source
      </div>
      <div className={cx({ mb10: true })}>
        <Select
          placeholder="Select a source"
          style={{ width: '200px', }}
          defaultValue={source}
          onChange={updateSource}
          options={
            [
              {value: 0, label: 'Done'},
              {value: 1, label: 'Mindful'},
            ]
          }
        />
      </div>
      <div className={cx({ mb10: true })}>
        Enter the patient’s email below to get a link that can login to the patient’s dashboard.
      </div>
      <div className={cx({ mb10: true })}>
        <Input placeholder="Patient's email" onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className={cx({ mb10: true })}>
        <Button type="primary" onClick={getLink} loading={impersonateLoginReq.loading}>
          Generate Link
        </Button>
      </div>
      {data && data.user_id && (
        <>
          <div className={cx({ mb10: true })}>
            Please enter the URL in your browser's incognito mode to impersonate a patient's login
            view and close the window immediately you are finished.
          </div>

          <div className={cx({ mb10: true, linkBox: true })}>{link()}</div>

          <div className={cx({ mb10: true })}>
            <Button type="primary" onClick={copyLink}>
              Copy URL
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
export default ImpersonateLogin;
