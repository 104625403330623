import { stringify } from 'querystring';
import { history } from 'umi';
import { message } from 'antd';
import {
  srvPayments,
  srvIntStatusItems,
  srvPaymentItem,
  srvRefund,
  srvAppointmentDetail,
  srvMembershipDetail,
  srvTherapySubscriptionDetail,
  srvRefundItems,
} from '@/services/admin';

const Model = {
  namespace: 'payment_detail',
  state: {
    data: {},
    refundHistories: [],
    appointment: {},
    membership: {},
    therapy_subscription: {},
    payment_products: [],
    payment_statuses: [],
  },
  effects: {
    *fetchPaymentProducts({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'product_id' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'savePaymentProducts', payload: res.data });
    },
    *fetchPaymentStatuses({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'payment_status' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'savePaymentStatuses', payload: res.data });
    },
    *refundPayment({ payload, callback }, { call, put, select }) {
      const res = yield call(srvRefund, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },

    *fetchAppointment({ payload, callback }, { call, put, select }) {
      const res = yield call(srvAppointmentDetail, { id: payload.id });
      if (!res.success) {
        return;
      }

      yield put({ type: 'saveAppointment', payload: res.data });

      if (callback) {
        callback();
      }
    },
    *fetchTherapySubscription({ payload, callback }, { call, put, select }) {
      const res = yield call(srvTherapySubscriptionDetail, { id: payload.id });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveTherapySubscription', payload: res.data });
      if (callback) {
        callback();
      }
    },
    *fetchMembership({ payload, callback }, { call, put, select }) {
      const res = yield call(srvMembershipDetail, { id: payload.id });
      if (!res.success) {
        return;
      }

      yield put({ type: 'saveMembership', payload: res.data });

      if (callback) {
        callback();
      }
    },
    *fetchData({ payload, callback }, { call, put, select }) {
      const res = yield call(srvPaymentItem, { id: payload.id });
      if (!res.success) {
        return;
      }

      yield put({ type: 'saveData', payload: res.data });

      if (callback) {
        callback(res.data);
      }
    },
    *fetchRefundHistories({ payload, callback }, { call, put, select }) {
      const res = yield call(srvRefundItems, { id: payload.id });
      if (!res.success) {
        return;
      }

      yield put({ type: 'saveRefundHistories', payload: res.data });

      if (callback) {
        callback();
      }
    },
  },
  reducers: {
    saveData(state, { payload }) {
      return { ...state, data: payload };
    },
    saveAppointment(state, { payload }) {
      return { ...state, appointment: payload };
    },
    saveMembership(state, { payload }) {
      return { ...state, membership: payload };
    },
    saveTherapySubscription(state, { payload }) {
      return { ...state, therapy_subscription: payload };
    },
    savePaymentProducts(state, { payload }) {
      return { ...state, payment_products: payload };
    },
    savePaymentStatuses(state, { payload }) {
      return { ...state, payment_statuses: payload };
    },
    saveRefundHistories(state, { payload }) {
      return { ...state, refundHistories: payload };
    },
  },
};
export default Model;
