import { stringify } from 'querystring';
import { history } from 'umi';
import { message } from 'antd';
import {
  srvPatients,
  srvFetchAllAppointments,
  srvPatientUpcomingAppointments,
  srvPatientPastAppointments,
  srvPatientDetail,
  srvPatientConsultations,
  srvPatientConsultationReplies,
  srvPatientIdentity,
  srvMarkAppointmentNoShow,
  srvAddTreatmentPlan,
  srvSendConsultationReply,
  srvDoctorSendConsultation,
  srvPatientTreatmentPlanHistories,
  srvPatientTreatmentPlanHistoryDetail,
  srvPatientTreatmentPlan,
  srvPatientTreatmentRequests,
  srvApproveTreatmentRequest,
  srvMarkNoDiagnosis,
  srvCalendarAppointments,
  srvDoctorTeams,
  srvTeamPatients, 
  srvDoctorBlocks,
} from '@/services/patient';

const Model = {
  namespace: 'ehr',
  state: {
    patients: [],
    teams: [],
    appointments: [],
    upcommingAppointments: [],
    pastAppointments: [],
    patientInfo: {},
    patientIdentity: {},
    treatmentPlanHistories: [],
    treatmentRequests: [],
    treatmentPlanHistory: {},
    treatmentPlan: {},
    consultations: [],
    replyData: {
      consultation: {},
      consultation_replies: [],
    },
    activeTab: '4',
    selectedID: '',
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const res = yield call(srvPatients, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveList', payload: res.data });
    },
    *fetchAllAppointments({ payload }, { call, put }) {
      const res = yield call(srvFetchAllAppointments, payload);
      if(!res.success) {
        return
      }
      yield put({ type: 'saveAllAppointments', payload: res.data });
    },
    *fetchDoctorTeams({ payload }, { call, put }) {
      const res = yield call(srvDoctorTeams, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveTeamList', payload: res.data });
    },
    *cleanPatients({}, { call, put, select }) {
      yield put({
        type: 'resetPatients',
      });
    },
    *fetchTeamPatients({ payload }, { call, put }) {
      const res = yield call(srvTeamPatients, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveList', payload: res.data });

    },
    *fetchPatientInfo({ payload }, { call, put, select }) {
      const res = yield call(srvPatientDetail, { id: payload.id });
      if (!res.success) {
        yield put({ type: 'savePatientInfo', payload: {} });
      } else {
        yield put({ type: 'savePatientInfo', payload: res.data });
      }
    },
    *fetchPatientIdentity({ payload, callback }, { call, put, select }) {
      const res = yield call(srvPatientIdentity, { id: payload.id });
      if (!res.success) {
        yield put({ type: 'savePatientIdentity', payload: {} });
      } else {
        yield put({ type: 'savePatientIdentity', payload: res.data });
      }

      if (callback) {
        callback();
      }
    },

    *fetchTreatmentPlanHistories({ payload, callback }, { call, put, select }) {
      const res = yield call(srvPatientTreatmentPlanHistories, { id: payload.id });
      if (!res.success) {
        yield put({ type: 'saveTreatmentPlanHistories', payload: [] });
        return;
      }
      yield put({ type: 'saveTreatmentPlanHistories', payload: res.data });

      if (callback) {
        callback();
      }
    },

    *fetchTreatmentRequests({ payload, callback }, { call, put, select }) {
      const res = yield call(srvPatientTreatmentRequests, { id: payload.id });
      if (!res.success) {
        yield put({ type: 'saveTreatmentRequests', payload: [] });
        return;
      }
      yield put({ type: 'saveTreatmentRequests', payload: res.data });

      if (callback) {
        callback();
      }
    },

    *fetchAppointments({ payload }, { call, put, select }) {
      const res = yield call(srvPatientUpcomingAppointments, { id: payload.id });
      if (!res.success) {
        yield put({ type: 'saveAppointments', payload: { upcoming: [], past: [] } });
        return;
      }
      const res2 = yield call(srvPatientPastAppointments, { id: payload.id });
      if (!res2.success) {
        yield put({ type: 'saveAppointments', payload: { upcoming: [], past: [] } });
        return;
      }
      yield put({ type: 'saveAppointments', payload: { upcoming: res.data, past: res2.data } });
    },

    *fetchTreatmentPlan({ payload, callback }, { call, put, select }) {
      const res = yield call(srvPatientTreatmentPlan, { id: payload.id });
      if (!res.success) {
        yield put({ type: 'saveTreatmentPlan', payload: {} });
      } else {
        yield put({ type: 'saveTreatmentPlan', payload: res.data });
      }

      if (callback) {
        callback();
      }
    },
    *fetchTreatmentPlanHistory({ payload }, { call, put, select }) {
      const res = yield call(srvPatientTreatmentPlanHistoryDetail, { id: payload.id });
      if (!res.success) {
        yield put({ type: 'saveTreatmentPlanHistory', payload: {} });
      } else {
        yield put({ type: 'saveTreatmentPlanHistory', payload: res.data });
      }
    },
    *fetchConsultations({ payload }, { call, put, select }) {
      const res = yield call(srvPatientConsultations, { id: payload.id });
      if (!res.success) {
        yield put({ type: 'saveConsultations', payload: [] });
        return;
      }
      yield put({ type: 'saveConsultations', payload: res.data });
    },
    *fetchConsultationReplies({ payload }, { call, put, select }) {
      const res = yield call(srvPatientConsultationReplies, { id: payload.id });
      if (!res.success) {
        yield put({
          type: 'saveConsultationReplies',
          payload: {
            consultation: {},
            consultation_replies: [],
          },
        });
        return;
      }
      yield put({ type: 'saveConsultationReplies', payload: res.data });
    },
    *replyConsultation({ payload, callback }, { call, put, select }) {
      const res = yield call(srvSendConsultationReply, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },

    *sendConsultation({ payload, callback }, { call, put, select }) {
      const res = yield call(srvDoctorSendConsultation, payload)
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },

    *showConsultationForm({}, { call, put, select }) {
      yield put({
        type: 'resetConsultation',
        payload: {
          consultation: {},
          consultation_replies: [],
        },
      });
    },

    *addTreatmentPlan({ payload, callback }, { call, put, select }) {
      const res = yield call(srvAddTreatmentPlan, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },

    *markNoShow({ payload, callback }, { call, put, select }) {
      const res = yield call(srvMarkAppointmentNoShow, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },

    *markNoDiagnosis({ payload, callback }, { call, put, select }) {
      const res = yield call(srvMarkNoDiagnosis, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *approveTreatmentRequest({ payload, callback }, { call, put, select }) {
      const res = yield call(srvApproveTreatmentRequest, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *fetchCalendarAppointments({ payload }, { call, put }) {
      const res = yield call(srvCalendarAppointments, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveCalendarAppointments', payload: res.data });
    },
  },
  reducers: {
    setSelectedID(state, { payload }) {
      return {
        ...state,
        selectedID: payload,
        upcommingAppointments: [],
        pastAppointments: [],
        patientInfo: {},
        treatmentPlans: [],
        treatmentRequests: [],
        consultations: [],
      };
    },
    activeTab(state, { payload }) {
      return { ...state, activeTab: payload };
    },
    saveList(state, { payload }) {
      return { ...state, patients: payload };
    },
    saveTeamList(state, { payload }) {
      return { ...state, teams: payload };
    },
    saveAllAppointments(state, { payload }) {
      return { ...state, appointments: payload };
    },
    saveAppointments(state, { payload }) {
      return { ...state, upcommingAppointments: payload.upcoming, pastAppointments: payload.past };
    },
    saveTreatmentPlanHistories(state, { payload }) {
      return { ...state, treatmentPlanHistories: payload };
    },
    saveTreatmentPlan(state, { payload }) {
      return { ...state, treatmentPlan: payload };
    },
    saveTreatmentPlanHistory(state, { payload }) {
      return { ...state, treatmentPlanHistory: payload };
    },
    saveTreatmentRequests(state, { payload }) {
      return { ...state, treatmentRequests: payload };
    },
    savePatientInfo(state, { payload }) {
      return { ...state, patientInfo: payload };
    },
    savePatientIdentity(state, { payload }) {
      return { ...state, patientIdentity: payload };
    },
    saveConsultations(state, { payload }) {
      return { ...state, consultations: payload };
    },
    saveConsultationReplies(state, { payload }) {
      return { ...state, replyData: payload };
    },
    saveCalendarAppointments(state, { payload }) {
      return { ...state, calendarAppointments: payload };
    },
    resetConsultation(state, { payload }) {
      return { ...state, replyData: payload };
    },
    resetPatients(state) {
      return { ...state, patients: [] };
    },
  },
};
export default Model;

