import React, { useState, useEffect, useRef } from 'react';
import { connect, history, Link } from 'umi';
import { Space, Table, Button } from 'antd';

import Filters from '@/components/Filters';
import { srvAdminAppointmentRequests, srvPatientDetail, srvAppointmentDetail, } from '@/services/admin';
import { getPatientName } from '@/utils/utils';
import { useRequest, useMount, useEventEmitter } from 'ahooks';
import moment from 'moment';
import { formatUnix } from '@/utils/xtime.js';

import RescheduleAppointment from '@/components/RescheduleAppointment';
import CancelAppointment from '@/components/CancelAppointment';
import ScheduleAppointmentRequest from '@/components/ScheduleAppointmentRequest';

import styles from './index.less';
import cn from 'classnames/bind';
const cx = cn.bind(styles);

const TherapySession = (props) => {
  const { activeKey, active, dispatch, patient_detail, loading } = props;
  const { states, rescheduleCalendar } = patient_detail;

  const [data, setData] = useState({ total: 0, items: [] });
  const [query, setQuery] = useState({});
  const [sort, setSort] = useState(0);
  const [page, setPage] = useState(1);

  const [scheduleVisible, setScheduleVisible] = useState(false);
  const [rescheduleVisible, setRescheduleVisible] = useState(false);
  const [cancelVisible, setCancelVisible] = useState(false);
  const [appointment, setAppointment] = useState({});
  const [appointmentRequest, setAppointmentRequest] = useState({});
  const [patient, setPatient] = useState({});

  const filters = [
    {
      name: 'requested_at',
      label: 'Request Date/Time',
      type: 'date',
    },
    {
      name: 'patient_name',
      label: 'Patient Name',
      type: 'text',
    },
    {
      name: 'state',
      label: 'State',
      type: 'checkbox',
      items: [],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'checkbox',
      items: [
        { label: 'Pending', value: 1 },
        { label: 'Confirming', value: 2 },
        { label: 'Confirmed', value: 3 },
        { label: 'Rescheduled', value: 4 },
        { label: 'Cancelled', value: 5 },
      ],
    },
    {
      name: 'doctor_accepted_at',
      label: 'Date/Time Accepted',
      type: 'date',
    },
    {
      name: 'doctor_name',
      label: 'Clinician Name',
      type: 'text',
    },
  ];

  useEffect(() => {
    if (active) {
      setPage(1);
      dispatch({ type: 'patient_detail/fetchStates', payload: {} });
      loadList(null, { page: 1, page_size: 20, sort });
    }
  }, [active]);

  const appointmentRequestListReq = useRequest(srvAdminAppointmentRequests, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setData(ret.data);
      }
    },
  });

  const fetchPatientReq = useRequest(srvPatientDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setPatient(ret.data);
      }
    },
  });

  const fetchAppointmentReq = useRequest(srvAppointmentDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setAppointment(ret.data);
      }
    },
  });

  const loadList = (filters, params) => {
    let filtersData = filters ? filters : {};
    let filtersObj = { ...filtersData };
    appointmentRequestListReq.run({ key: activeKey, filters: JSON.stringify(filtersObj), ...params });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (sorter && sorter.field == "requested_at" && sorter.order) {
      setSort(sorter.order === "ascend" ? 1 : -1);
    }
    loadList(query, { page: pagination.current, page_size: pagination.pageSize, sort });
  };

  const f = () => {
    for (const v of filters) {
      if (v.name === 'state' && v.items.length === 0) {
        v.items = states.map((s) => ({ ...s, label: s.state, value: s.state }));
      }
    }
    return filters;
  };

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadList(q, { page: 1, page_size: 20 });
  };

  const columns = [
    {
      title: 'Request Date/Time',
      ellipsis: true,
      dataIndex: 'requested_at',
      sorter: true,
      key: 'requested_at',
      render: (v, r) => {
        return formatUnix(r.requested_at, 'MM/DD/YYYY h:mm A');
      },
    },
    {
      title: 'Patient Name',
      dataIndex: 'patient_id',
      key: 'patient_id',
      ellipsis: true,
      render: (v, r) => {
        return <Link to={`/patient/${r.patient_id}`}>{getPatientName(r)}</Link>;
      },
    },
    {
      title: 'State',
      dataIndex: 'patient_state',
      key: 'patient_state',
      ellipsis: true,
      width: 60,
    },
    {
      title: 'status',
      dataIndex: 'status_name',
      key: 'status_name',
      ellipsis: true,
      width: 120,
    },
    {
      title: 'Date/Time Accepted',
      ellipsis: true,
      dataIndex: 'appointment_preferred_at',
      key: 'appointment_preferred_at',
      render: (v, r) => {
        if (!r.appointment_preferred_at) {
          return '-';
        }
        return formatUnix(r.appointment_preferred_at, 'MM/DD/YYYY h:mm A');
      },
    },
    {
      title: 'Patients’ Preferred Times',
      dataIndex: 'patient_preferred_times',
      key: 'patient_preferred_times',
      width: 250,
      render: (v, r) => {
        if (!r.patient_preferred_times) {
          return ''
        }
        let appts = [];
        const times = JSON.parse(r.patient_preferred_times);
        for (const v of times) {
          appts.push(`${formatUnix(v.start_time, 'MM/DD/YYYY h:mm A')} - ${formatUnix(v.end_time, 'h:mm A')}`);
        }
        return <div dangerouslySetInnerHTML={{ __html: appts.join('<br/>') }} />;
      },
    },
    {
      title: 'Clinician Name',
      dataIndex: 'doctor_id',
      key: 'doctor_id',
      ellipsis: true,
      render: (v, r) => {
        if (r.doctor_id === '0') {
          return '-';
        }
        return <Link to={`/doctor/detail/${r.doctor_id}`}>{r.doctor_name}</Link>;
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 180,
      render: (v, r) => {
        if ([4, 5].includes(r.status) || r.expired_at < moment().unix()) {
          return '';
        } else if (r.status === 3 && r.appointment_id > 0) {
          return (
            <Space>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setAppointmentRequest(r);
                  fetchAppointmentReq.runAsync({ id: r.appointment_id }).then((ret) => {
                    if (ret.success && ret.data) {
                      const payload = {
                        appointment_id: ret.data.id,
                        timezone: ret.data.timezone,
                        days: 4,
                        date: moment().format('YYYYMMDD'),
                        dir: 'next',
                        from_admin: 1,
                      }
                      dispatch({
                        type: 'patient_detail/fetchAppointmentRescheduleCalendar',
                        payload: { ...payload },
                        callback: ((ret) => {
                          showReschedulePanel(ret);
                        }),
                      });
                    }
                  });
                }}
              >
                Reschedule
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setAppointmentRequest(r);
                  fetchAppointmentReq.runAsync({ id: r.appointment_id }).then(() => {
                    showCancelPanel(r);
                  });
                }}
              >
                Cancel
              </Button>
            </Space>
          )
        } else if (r.status === 1) {
          return (
            <Button
              size="small"
              type="primary"
              onClick={() => {
                setAppointmentRequest(r);
                fetchPatientReq.runAsync({ id: r.patient_id }).then(() => {
                  showSchedulePanel(r);
                });
              }}
            >
              Schedule
            </Button>
          )
        }
      },
    },
  ];

  const showCancelPanel = () => {
    setCancelVisible(true);
  };

  const showReschedulePanel = () => {
    setRescheduleVisible(true);
  };

  const showSchedulePanel = () => {
    setScheduleVisible(true);
  };


  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Appointment Requests</h3>
        <Space>
          <Filters filters={f()} onFilter={onFilter} />
        </Space>
      </div>

      <Table
        columns={columns}
        dataSource={data.items}
        rowClassName={(r) => {
          return '';
        }}
        scroll={{ x: 800 }}
        rowKey="id"
        loading={appointmentRequestListReq.loading}
        onChange={handleTableChange}
        pagination={{ pageSize: 20, current: page, simple: true, total: data.total }}
      />

      {scheduleVisible && (
        <ScheduleAppointmentRequest
          visible={scheduleVisible}
          id={patient?.id}
          detail={patient}
          appointmentRequest={appointmentRequest}
          onClose={() => {
            setScheduleVisible(false);
            loadList(query, { page, page_size: 20, sort });
          }}
        />
      )}

      {rescheduleVisible && (
        <RescheduleAppointment
          visible={rescheduleVisible}
          appointment={appointment}
          rescheduleCalendar={rescheduleCalendar}
          loadingReschedule={loading.effects['patient_detail/rescheduleAppointment']}
          onClose={() => {
            setRescheduleVisible(false);
          }}
          rescheduleAppointment={(payload, callback) => {
            dispatch({
              type: 'patient_detail/rescheduleAppointment',
              payload: { ...payload },
              callback: () => {
                setRescheduleVisible(false);
                loadList(query, { page, page_size: 20, sort });
                callback && callback();
              },
            });
          }}
          loadRescheduleCalendar={(payload, callback) => {
            dispatch({
              type: 'patient_detail/fetchAppointmentRescheduleCalendar',
              payload: { ...payload },
              callback,
            });
          }}
        />
      )}

      {cancelVisible && (
        <CancelAppointment
          visible={cancelVisible}
          appointment={appointment}
          loadingCancel={loading.effects['patient_detail/cancelAppointment']}
          onClose={() => {
            setCancelVisible(false);
          }}
          cancelAppointment={(payload, callback) => {
            dispatch({
              type: 'patient_detail/cancelAppointment',
              payload: { ...payload },
              callback: () => {
                setCancelVisible(false);
                loadList(query, { page, page_size: 20, sort });
                callback && callback();
              },
            });
          }}
        />
      )}

    </div>
  );
};

export default connect(({ patient_detail, loading }) => ({
  patient_detail,
  loading,
}))(TherapySession);
