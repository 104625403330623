

import React, { useState } from 'react';
import { connect, history } from 'umi';
import { Spin, Descriptions } from 'antd';

import { CloseCircleOutlined, } from '@ant-design/icons';

import moment from 'moment-timezone';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import { formatUnix, formatNextFollowUpMonth } from '@/utils/xtime';
import styles from './ChartNotesView.less';
import { srvPatientTreatmentPlanHistoryDetail, srvGetDocumentURL, } from '@/services/patient';
import { srvTreatmentPlanHistoryDetail } from '@/services/admin';

import InitialEvaluationDetailView from '@/components/InitialEvaluationNote/InitialEvaluationDetailView';
import NotesDetail from '@/components/ProviderNotesPane/NotesDetail'
import { CPTCodesMap } from '@/components/CPTCodesFormItem';
import { formatLastRefillDate } from '@/components/LastRefiiDateFormItem';
import { allReasons } from '@/components/ProviderNotesPane/ProviderNotes';

const NotesView = (props) => {
  const { account, id, visible, setVisible } = props;

  const [treatmentPlanHistory, setTreatmentPlanHistory] = useState({});
  const [reasons, setReasons] = useState('');
  const [recommendations, setRecommendations] = useState('');
  const [bridgeRefill, setBridgeRefill] = useState('');
  const [attachFiles, setAttachFiles] = useState([]);
  const [imgVisible, setImgVisible] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [previewFileType, setPreviewFileType] = useState('');

  const loadHistoryReq = useRequest(account?.currentUser?.role === 'admin' ? srvTreatmentPlanHistoryDetail : srvPatientTreatmentPlanHistoryDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setTreatmentPlanHistory(ret.data);
        if (ret.data !== '') {
          if (ret.data.recommendation_reasons != '') {
            var reasonTemp = '';
            var select = JSON.parse(ret.data.recommendation_reasons);
            allReasons.map((item) => {
              if (Object.keys(select).includes(item.key)) {
                reasonTemp = reasonTemp + item.label;
                if (select[item.key].length === 0) {
                  reasonTemp = reasonTemp + ':\n';
                } else {
                  var selectOptions = select[item.key];
                  if (item.options.length > 0) {
                    reasonTemp = reasonTemp + ': ';
                    item.options.map((childItem) => {
                      if (selectOptions.includes(childItem.key)) {
                        reasonTemp = reasonTemp + childItem.label + '; ';
                      }
                    });
                  }

                }
              }
            });
            if (Object.keys(select).includes('r7')) {
              if (Object.keys(select).includes('other_reason')) {
                reasonTemp = reasonTemp + 'Other reason:\n' + select['other_reason'];
              } else {
                reasonTemp = reasonTemp + 'Other reason:\n';
              }
            }
            setReasons(reasonTemp);
          }
          if (ret.data.recommendations === 1) {
            setRecommendations('Recommend a transfer to another provider');
          } else if (ret.data.recommendations === 2) {
            setRecommendations('Recommend permanent discharge from the platform');
          }
          if (ret.data.bridge_refill_attestation === 1) {
            if (ret.data.bridge_refill_attestation_date === 0 || ret.data.bridge_refill_attestation_date === null) {
              setBridgeRefill('I attest that I have reviewed the patient’s PDMP and issued a bridge refill for any medications this patient requires. The refill was sent on: ');
            } else {
              setBridgeRefill('I attest that I have reviewed the patient’s PDMP and issued a bridge refill for any medications this patient requires. The refill was sent on: ' + formatUnix(ret.data.bridge_refill_attestation_date));
            }
          } else if (ret.data.bridge_refill_attestation === 2) {
            setBridgeRefill('The patient does not require a bridge refill.');
          }
          if (ret.data.attach_files != '' && ret.data.attach_files != '[]') {
            setAttachFiles(JSON.parse(ret.data.attach_files));
          }
        }
      }
    },
  });

  const toPreview = (file) => {
    srvGetDocumentURL({
      object_key: file.object_key,
    }).then((res) => {
      if (res.success) {
        fetchPreviewInfo(res.data);
      }
    })
  };
  const fetchPreviewInfo = (url) => {
    fetch(url)
      .then((res) => {
        const contentType = res.headers.get('Content-Type');
        if (contentType === 'application/pdf') {
          setPreviewFileType(contentType);
        }
        setImgUrl(url);
        setImgVisible(true);
      })
      .catch((e) => {
        window.sendErrorLog(e);
      });
  }

  const renderAddendum = () => {
    return treatmentPlanHistory && treatmentPlanHistory.Addendums && treatmentPlanHistory.Addendums.length ? (
      treatmentPlanHistory.Addendums.map((addendum) => (
        <Descriptions title="Addendums" layout="vertical" column={1} size="small" bordered={true}>
          <Descriptions.Item
            label={
              addendum.creator_type +
              ' Addendum - ' +
              addendum.creator_name +
              ' ' +
              addendum.creator_title +
              ' ' +
              moment.unix(addendum.created_at).format('MM/DD/YYYY')
            }
          >
            <div className={styles.lineBreak}>{addendum.addendum}</div>
          </Descriptions.Item>
        </Descriptions>
      ))
    ) : (
      <></>
    );
  };

  const renderClinicianNotesDom = (note) => {
    if (note.note_type === 1) {
      return (
        <InitialEvaluationDetailView note={note} />
      )
    }
    return <div className={styles.lineBreak}>{note.provider_notes}</div>
  }

  const renderDescriptions = () => {
    if (treatmentPlanHistory.note_type == 4) {
      return (
        <>
          <Descriptions
            layout="vertical"
            column={1}
            size="small"
            bordered={true}
          >
            <Descriptions.Item label="Note type">Transfer Note</Descriptions.Item>
            <Descriptions.Item label="Recommendations">{recommendations}</Descriptions.Item>
            <Descriptions.Item
              style={{ whiteSpace: 'pre-line' }}
              label="Reasons For Recommendation(Discharge/Transfer)"
            >
              {reasons}
            </Descriptions.Item>
            <Descriptions.Item label="Bridge Refill Attestation">{bridgeRefill}</Descriptions.Item>
            <Descriptions.Item label="Date of Initial Evaluation">
              {treatmentPlanHistory.initial_evaluation_date > 0
                ? formatUnix(treatmentPlanHistory.initial_evaluation_date)
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Current Diagnoses">
              {treatmentPlanHistory.diagnostic_code}
            </Descriptions.Item>
            <Descriptions.Item label="Diagnosis Notes">
              {treatmentPlanHistory.diagnostic_code_notes}
            </Descriptions.Item>
            <Descriptions.Item label="CPT Code">
              {CPTCodesMap[treatmentPlanHistory.cpt_code]
                ? CPTCodesMap[treatmentPlanHistory.cpt_code]
                : treatmentPlanHistory.cpt_code}
            </Descriptions.Item>
            {treatmentPlanHistory.addon_code && (
              <Descriptions.Item label="Add-on Code">
                {CPTCodesMap[treatmentPlanHistory.addon_code]
                  ? CPTCodesMap[treatmentPlanHistory.addon_code]
                  : treatmentPlanHistory.addon_code}
              </Descriptions.Item>
            )}
            <Descriptions.Item style={{ whiteSpace: 'pre-line' }} label="Medication History">
              {treatmentPlanHistory.medication_history}
            </Descriptions.Item>
            <Descriptions.Item label="Medication Notes">
              {treatmentPlanHistory.medication_history_notes}
            </Descriptions.Item>
            <Descriptions.Item label="Treatment Summary">
              {treatmentPlanHistory.treatment_summary}
            </Descriptions.Item>
          </Descriptions>
          {renderAddendum()}
          <Descriptions
            layout="vertical"
            column={1}
            size="small"
            bordered={true}
          >
            <Descriptions.Item label="Attach File">
              {attachFiles.length > 0 &&
                attachFiles.map((file) => (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <div style={{ marginRight: 'auto' }}>{file.name}</div>
                      <div
                        style={{
                          color: '#5469d4',
                          fontSize: '16px',
                          cursor: 'pointer',
                          marginRight: '10px',
                        }}
                      >
                        <span onClick={() => toPreview(file)}>Preview</span>
                      </div>
                      <div style={{ color: '#5469d4', fontSize: '16px', cursor: 'pointer' }}>
                        <span onClick={() => downloadFile(file)}>Download</span>
                      </div>
                    </div>
                  </>
                ))}
            </Descriptions.Item>
          </Descriptions>
        </>
      );
    }
    return <>
      {treatmentPlanHistory.note_type === 1 ?
        <NotesDetail data={treatmentPlanHistory} /> :
        <>
          <Descriptions
            layout="vertical"
            column={2}
            size="small"
            bordered={true}
          >
            <Descriptions.Item label="Diagnostic Code">
              {treatmentPlanHistory.diagnostic_code}
            </Descriptions.Item>
            <Descriptions.Item label="Updated At">
              {treatmentPlanHistory.updated_at && formatUnix(treatmentPlanHistory.updated_at)}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            layout="vertical"
            column={1}
            size="small"
            bordered={true}
          >
            <Descriptions.Item
              label="CPT Code">{CPTCodesMap[treatmentPlanHistory.cpt_code] ? CPTCodesMap[treatmentPlanHistory.cpt_code] : treatmentPlanHistory.cpt_code}</Descriptions.Item>
            {treatmentPlanHistory.addon_code && (
              <Descriptions.Item
                label="Add-on Code">{CPTCodesMap[treatmentPlanHistory.addon_code] ? CPTCodesMap[treatmentPlanHistory.addon_code] : treatmentPlanHistory.addon_code}</Descriptions.Item>
            )}
            <Descriptions.Item label="Last Refill Date">{formatLastRefillDate(treatmentPlanHistory.last_refill_date)}</Descriptions.Item>
            <Descriptions.Item label="Rx">{treatmentPlanHistory.rx}</Descriptions.Item>
            <Descriptions.Item label="Clinician Notes">
              {renderClinicianNotesDom(treatmentPlanHistory)}
            </Descriptions.Item>
            {
              treatmentPlanHistory.next_follow_up_date > 0 &&
              <>
                <Descriptions.Item label="Next Follow Up Date">
                  {treatmentPlanHistory.next_follow_up_date > 0 ? formatUnix(treatmentPlanHistory.next_follow_up_date, 'MMM D, YYYY') : ''}
                </Descriptions.Item>
                <Descriptions.Item label="I‘d like to remind this patient to schedule their next follow-up appointment in">
                  {formatNextFollowUpMonth(treatmentPlanHistory.next_follow_up_month)}
                </Descriptions.Item>
              </>
            }
            <Descriptions.Item label="Message To Patient">
              <div className={styles.lineBreak}>{treatmentPlanHistory.message_to_patient}</div>
            </Descriptions.Item>
          </Descriptions>
        </>
      }
      {renderAddendum()}</>
  }

  const loadData = () => {
    if (id) {
      loadHistoryReq.run({ id });
    }
  };

  useMount(() => {
    loadData();
  });

  useUpdateEffect(() => {
    loadData();
  }, [id]);

  return (
    <Spin tip="Loading..." spinning={loadHistoryReq.loading}>
      {imgVisible && (
        <>
          <div className="img-preview-container" style={{ position: 'absolute', zIndex: '9999', top: '0', bottom: '0', left: '0', right: '0', margigin: 'auto', width: '90%', height: '90%' }}>
            <CloseCircleOutlined
              className="close-button"
              onClick={() => {
                setImgVisible(false);
              }}
            />
            {previewFileType === 'application/pdf' ? (
              <object
                className="identity-img-large"
                data={imgUrl}
                type="application/pdf"
              ></object>
            ) : (
              <img className="identity-img-large" src={imgUrl} />
            )}
          </div>
          <div
            onClick={() => {
              setImgVisible(false);
            }}
            className="upload-mask"
          ></div>
        </>
      )}
      <div className={styles.NotesView}>
        {renderDescriptions()}
      </div>
    </Spin>
  )
};

export default connect(({ account, loading }) => ({
  account,
  loading,
}))(NotesView);