import React, { useState, useEffect } from 'react';
import { Space, Checkbox, Form, Input, Button, Row, Col } from 'antd';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import { createFromIconfontCN } from '@ant-design/icons';

import defaultSettings from '../../../defaultSettings';
import styles from './index.less';
const cx = cn.bind(styles);
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const TextFilter = (props) => {
  const { name, label, form } = props;
  const defaultVisible = !!form.getFieldValue(name);
  const [visible, setVisible] = useState(defaultVisible);

  props.reset$.useSubscription(() => {
    setVisible(false);
  });

  useEffect(() => {
    setVisible(defaultVisible);
  }, [defaultVisible]);

  return (
    <div className={cx({ filtersItem: true })}>
      <div className={cx({ filtersItemSwitch: true })}>
        <Checkbox
          checked={visible}
          onChange={(v) => {
            setVisible(v.target.checked);
          }}
        >
          {label}
        </Checkbox>
      </div>
      <div className={cx({ filtersItemBox: true, visible: visible })}>
        {visible && (
          <div className={cx({ box: true })}>
            <Space>
              <span className={cx({ label: true })}>is equal to</span>
              <Form.Item name={name} rules={[{ required: true }]}>
                <Input size="small" />
              </Form.Item>
            </Space>
          </div>
        )}
      </div>
    </div>
  );
};

export default TextFilter;
