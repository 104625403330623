import React, { useState,useEffect  } from 'react';
import { Modal, Space, List, Table } from 'antd';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import styles from './PrescriptionHistory.less';
import { formatUnix } from '@/utils/xtime';
import { srvEncounterList } from '@/services/patient';
const cx = cn.bind(styles);
const pageSize = 10;
const AyvaPrescriptionHistory = (props) => {
  const { admin, uid, active } = props;
  const [data, setData] = useState({ items: [], total: 0 });
  const [page, setPage] = useState(1);
  const columns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 150,
      render: (v, r) => {
        return (
          <>{formatUnix(v)}</>
        );
      },
    },
    {
      title: 'Rx',
      dataIndex: 'id',
      key: 'id',
      render: (v, r) => {
        return (
          r.rx_orders.map((x) => {
            return (
              <table className={styles.rowRxOrderItem} key={x.id}>
                <tbody>
                  <tr>
                    <td className={styles.rowStatus}>
                      <span
                        className={cx(styles.dot, {
                          success: x.ui_status == 1,
                          pending: x.ui_status == 2,
                          failed: x.ui_status == 3,
                        })}
                      />
                    </td>
                    <td className={styles.rowRx} key={`desc-${x.id}`}>
                      <div className={styles.drugDesc}>
                        {x.ord_drug_name} -- {x.ord_sig}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )
          })
        );
      },
    },
    {
      title: 'Earliest Fill Date(UTC)',
      dataIndex: 'id',
      key: 'id',
      width: 150,
      render: (v, r) => {
        return (
          r.rx_orders.map((x) => {
            return (
              <table className={styles.rowRxOrderItem} key={x.id}>
                <tbody>
                  <tr>
                    <td className={styles.rowFill} key={`fill-${x.id}`}>
                      <div className={styles.rowDetailItem}>
                        {formatEarliestFillDate(x.ord_earliest_fill_date)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )
          })
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: 200,
      render: (v, r) => {
        return (
          r.rx_orders.map((x) => {
            return (
              <table className={styles.rowRxOrderItem} key={x.id}>
                <tbody>
                  <tr>
                    <td className={styles.rowAction} key={`action-${x.id}`}>
                      <div className={styles.rowDetailItem} style={{color:'#5469d4'}}>
                        <a
                          onClick={() => {
                            showRxOrderModal(x);
                          }}
                        >
                          View
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )
          })
        );
      },
    }
  ];
  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    encounterListReq.run({
      page: pagination.current,
      page_size: pageSize,
      id: uid,
    });
  };

  const encounterListReq = useRequest(srvEncounterList, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setData(ret.data);
      }
    },
  });
  useEffect(() => {
    if (active) {
      setPage(1);
      encounterListReq.run({
        page: 1,
        page_size: pageSize,
        id: uid,
      });
    }
  }, [active, uid]);
  const showRxOrderModal = (ord) => {
    Modal.info({
      title: 'View Prescription Detail',
      width: 600,
      content: (
        <div>
          <p>
            <b>{ord.ord_drug_name}</b>
          </p>
          <p>
            {ord.ord_sig} | {ord.ord_disp_amt}
          </p>
          <p>
            {ord.ord_refills} REFILLS, {ord.ord_substitution_allowed ? 'SUBSTITUTES ALLOWED' : ''}
          </p>
          <p>Earliest Fill Date: {ord.ord_earliest_fill_date}</p>
          <p>
            <b>Notes:</b> {ord.ord_notes}
          </p>
        </div>
      ),
    });
  };

  const formatEarliestFillDate = (v) => {
    if (v == '') {
      return '';
    }

    return moment.tz(v, 'UTC').format('MMM D, YYYY');
  };

  return (
    <>
      <div className={styles.historyHeader}>
        <Space>
          <div className={styles.item}>
            <span className={cx(styles.dot, { success: true })} />
            <span>Success</span>
          </div>
          <div className={styles.item}>
            <span className={cx(styles.dot, { pending: true })} />
            <span>Pending</span>
          </div>
          <div className={styles.item}>
            <span className={cx(styles.dot, { failed: true })} />
            <span>Cancel/Failed</span>
          </div>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={data.items}
        rowKey="id"
        loading={encounterListReq.loading}
        onChange={handleTableChange}
        pagination={{ pageSize: pageSize, current: page, simple: true, total: data.total }}
      />
    </>
  );
};
AyvaPrescriptionHistory.defaultProps = {
  admin: false,
};

export default AyvaPrescriptionHistory;
