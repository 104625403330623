
import React, { useState } from 'react';
import { Checkbox, Modal, Button } from 'antd';

const RemindAuthorizeAdminAccessModal = ({ onOk, onCancel, ...modalProps }) => {

  const [modalVisible, setModalVisible] = useState(true);
  const [isChecked, setIsChecked] = useState(true);
  const [isViewDetail, setIsViewDetail] = useState(false);

  const onCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Modal title="Please confirm"
      width={630}
      visible={modalVisible}
      okText="Confirm"
      onOk={() => {
        onOk && onOk();
        setModalVisible(false);
      }}
      okButtonProps={{ disabled: !isChecked }}
      onCancel={() => {
        onCancel && onCancel();
        setModalVisible(false);
      }}
    >
      <Checkbox checked={isChecked} onChange={onCheckboxChange}>
        I authorize the credentials to be used by the care team members for related healthcare activities.<Button type='link' style={{ paddingLeft: '2px' }} onClick={() => { setIsViewDetail(!isViewDetail) }}>{isViewDetail ? 'Hide' : 'View'} detail</Button>
      </Checkbox>
      {isViewDetail && (
        <p>
          1. Authorization to Access PDMP
          <br />
          1.1 Provider hereby authorizes Care Team Member to use Provider’s credential to log into the PDMP system under Provider’s supervision. Provider's authorization for the Care Team is strictly limited to accessing the PDMP under Provider's supervision. This is done solely through the product's invocation of the provider’s license and related information to view the PDMP data for patients under the Provider's care. Provider's DEA, NPI, and other credentials will not be directly used or applied for any other purposes.
          <br />
          1.2 The Care Team Member is authorized to access patient data within the PDMP system for the purposes of coordinating appointment scheduling, prescription delivery, and other related healthcare activities as directed by the Provider:
          <br />
          1.2.1 Check the patient's last filled prescription date.
          <br />
          1.2.2 Verify whether the medication was received before contacting the pharmacy.
          <br />
          1.2.3 Determine whether the patient is receiving any other narcotics or controlled substances.
          <br />
          1.2.4 Determine whether the patient is receiving any other narcotics or controlled substances, including Schedule II medications from other providers outside our platform.
        </p>
      )}
    </Modal>
  );
};

export default RemindAuthorizeAdminAccessModal;
