import { stringify } from 'querystring';
import { history } from 'umi';
import { message } from 'antd';
import {
  srvPatients,
  srvPatientDetail,
  srvPatientIdentity,
  srvUpdatePatientDetail,
  srvStates,
  srvConsultations,
  srvAppointments,
  srvIntStatusItems,
  srvTreatmentRequests,
  srvPayments,
  srvRefund,
  srvMemberships,
  srvTreatmentPlanHistories,
  srvConsultationReplies,
  srvTreatmentPlanHistoryDetail,
  srvSendConsultationReply,
  srvAppointmentRescheduleCalendar,
  srvAppointmentFollowUpCalendar,
  srvMarkAppointmentProviderNoShow,
  srvAdminChangePastAppointmentStatus,
  srvRescheduleAppointment,
  srvCancelAppointment,
  srvAppointmentItem,
  srvMembershipExtendHistorys,
} from '@/services/admin';

const Model = {
  namespace: 'patient_detail',
  state: {
    detail: {},
    patientIdentity: {},
    states: [],
    appointment_types: [],
    appointment_statuses: [],
    appointments: {
      items: [],
      total: 0,
    },
    consultations: {
      items: [],
      total: 0,
    },
    consultation_types: [],
    treatment_requests: {
      total: 0,
      items: [],
    },
    payments: {
      total: 0,
      items: [],
    },
    payment_products: [],
    payment_statuses: [],
    treatment_plan_histories: {
      total: 0,
      items: [],
    },
    treatment_plan_types: [],
    treatment_plan_statuses: [],
    memberships: {
      total: 0,
      items: [],
    },
    membershipExtendHistorys:{
      total: 0,
      items: [],
    },
    membership_types: [],
    membership_statuses: [],
    replyData: {
      consultation: {},
      consultation_replies: [],
    },
    treatmentPlanHistory: {},
    rescheduleCalendar: {},
    followUpCalendar: {},
    activeDoctor: {},
  },
  effects: {
    *fetchData({ payload }, { call, put }) {
      const res = yield call(srvPatientDetail, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveData', payload: res.data });
    },

    *fetchPatientIdentity({ payload, callback }, { call, put, select }) {
      const res = yield call(srvPatientIdentity, { id: payload.id });
      if (!res.success) {
        yield put({ type: 'savePatientIdentity', payload: {} });
      } else {
        yield put({ type: 'savePatientIdentity', payload: res.data });
      }

      if (callback) {
        callback();
      }
    },
    *updatePatientDetail({ payload, callback }, { call, put, select }) {
      const res = yield call(srvUpdatePatientDetail, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *fetchStates({ payload }, { call, put }) {
      const res = yield call(srvStates, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveStates', payload: res.data });
    },
    *fetchConsultations({ payload }, { call, put }) {
      const res = yield call(srvConsultations, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveConsultations', payload: res.data });
    },
    *fetchConsultationTypes({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'consultation_type' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveConsultationTypes', payload: res.data });
    },
    *fetchAppointments({ payload }, { call, put }) {
      const res = yield call(srvAppointments, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveAppointments', payload: res.data });
    },
    *fetchAppointmentTypes({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'appointment_type' });
      if (!res.success) {
        return;
      }
      res.data = res.data.filter((item) => item.value !== 9);
      yield put({ type: 'saveAppointmentTypes', payload: res.data });
    },
    *fetchAppointmentStatuses({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'appointment_status' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveAppointmentStatuses', payload: res.data });
    },
    *fetchPayments({ payload }, { call, put }) {
      const res = yield call(srvPayments, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'savePayments', payload: res.data });
    },
    *refundPayment({ payload, callback }, { call, put, select }) {
      const res = yield call(srvRefund, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *fetchPaymentProducts({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'product_id' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'savePaymentProducts', payload: res.data });
    },
    *fetchPaymentStatuses({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'payment_status' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'savePaymentStatuses', payload: res.data });
    },
    *fetchTreatmentPlanHistories({ payload }, { call, put }) {
      const res = yield call(srvTreatmentPlanHistories, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveTreatmentPlanHistories', payload: res.data });
    },
    *fetchTreatmentPlanTypes({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'treatment_plan_type' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveTreatmentPlanTypes', payload: res.data });
    },
    *fetchTreatmentPlanStatuses({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'treatment_plan_status' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveTreatmentPlanStatuses', payload: res.data });
    },
    *fetchMemberships({ payload }, { call, put }) {
      const res = yield call(srvMemberships, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveMemberships', payload: res.data });
    },
    *fetchMembershipExtendHistorys({ payload }, { call, put }) {
      const res = yield call(srvMembershipExtendHistorys, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveMembershipExtendHistorys', payload: res.data });
    },
    *fetchMembershipTypes({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'membership_type' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveMembershipTypes', payload: res.data });
    },
    *fetchMembershipStatuses({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'membership_status' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveMembershipStatuses', payload: res.data });
    },
    *fetchConsultationReplies({ payload }, { call, put, select }) {
      const res = yield call(srvConsultationReplies, { id: payload.id });
      if (!res.success) {
        yield put({
          type: 'saveConsultationReplies',
          payload: {
            consultation: {},
            consultation_replies: [],
          },
        });
        return;
      }
      yield put({ type: 'saveConsultationReplies', payload: res.data });
    },
    *fetchTreatmentPlanHistory({ payload }, { call, put, select }) {
      const res = yield call(srvTreatmentPlanHistoryDetail, { id: payload.id });
      if (!res.success) {
        yield put({ type: 'saveTreatmentPlanHistory', payload: {} });
      } else {
        yield put({ type: 'saveTreatmentPlanHistory', payload: res.data });
      }
    },
    *replyConsultation({ payload, callback }, { call, put, select }) {
      const res = yield call(srvSendConsultationReply, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *rescheduleAppointment({ payload, callback }, { call, put, select }) {
      const res = yield call(srvRescheduleAppointment, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *cancelAppointment({ payload, callback }, { call, put, select }) {
      const res = yield call(srvCancelAppointment, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *fetchAppointmentRescheduleCalendar({ payload, callback }, { call, put }) {
      const res = yield call(srvAppointmentRescheduleCalendar, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveAppointmentRescheduleCalendar', payload: res.data });
      if (callback) {
        callback();
      }
    },
    *markAppointmentProviderNoShow({ payload, callback }, { call, put, select }) {
      const res = yield call(srvMarkAppointmentProviderNoShow, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *changePastAppointmentStatus({ payload, callback }, { call, put, select }) {
      const res = yield call(srvAdminChangePastAppointmentStatus, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *fetchAppointmentItem({ payload }, { call, put, select }) {
      const res = yield call(srvAppointmentItem, { id: payload.id });
      if (!res.success) {
        return;
      }
      const { appointments } = yield select((state) => state.patient_detail);

      for (let i = 0; i < appointments.items.length; i++) {
        if (res.data.id === appointments.items[i].id) {
          appointments.items[i] = res.data;
        }
      }

      yield put({
        type: 'saveAppointments',
        payload: { total: appointments.total, items: [...appointments.items] },
      });
    },
  },
  reducers: {
    saveData(state, { payload }) {
      return { ...state, detail: payload };
    },
    savePatientIdentity(state, { payload }) {
      return { ...state, patientIdentity: payload };
    },
    saveStates(state, { payload }) {
      return { ...state, states: payload };
    },
    saveAppointments(state, { payload }) {
      return { ...state, appointments: payload };
    },
    saveAppointmentTypes(state, { payload }) {
      return { ...state, appointment_types: payload };
    },
    saveAppointmentStatuses(state, { payload }) {
      return { ...state, appointment_statuses: payload };
    },
    saveConsultations(state, { payload }) {
      return { ...state, consultations: payload };
    },
    saveConsultationTypes(state, { payload }) {
      return { ...state, consultation_types: payload };
    },
    savePayments(state, { payload }) {
      return { ...state, payments: payload };
    },
    savePaymentProducts(state, { payload }) {
      return { ...state, payment_products: payload };
    },
    savePaymentStatuses(state, { payload }) {
      return { ...state, payment_statuses: payload };
    },
    saveTreatmentPlanHistories(state, { payload }) {
      return { ...state, treatment_plan_histories: payload };
    },
    saveTreatmentPlanTypes(state, { payload }) {
      return { ...state, treatment_plan_types: payload };
    },
    saveTreatmentPlanStatuses(state, { payload }) {
      return { ...state, treatment_plan_statuses: payload };
    },
    saveMemberships(state, { payload }) {
      return { ...state, memberships: payload };
    },
    saveMembershipExtendHistorys(state, { payload }) {
      return { ...state, membershipExtendHistorys: payload };
    },
    saveMembershipTypes(state, { payload }) {
      return { ...state, membership_types: payload };
    },
    saveMembershipStatuses(state, { payload }) {
      return { ...state, membership_statuses: payload };
    },
    saveConsultationReplies(state, { payload }) {
      return { ...state, replyData: payload };
    },
    saveTreatmentPlanHistory(state, { payload }) {
      return { ...state, treatmentPlanHistory: payload };
    },
    saveAppointmentRescheduleCalendar(state, { payload }) {
      return { ...state, rescheduleCalendar: payload };
    },
  },
};
export default Model;
