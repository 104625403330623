import React, { useState, useEffect } from 'react';
import { Form, Input, Radio, Space } from 'antd';

import { useMount, useUpdateEffect } from 'ahooks';

const RadioGroupInput = (props) => {

  const { name, label, form, draft, setDraft, allergy, initialValue } = props;
  const [isRequired, setIsRequired] = useState(false);
  let isAllergy;
  isAllergy = typeof allergy !== "undefined";
  const [value, setValue] = useState(isAllergy);


  const onRadioGroupChange = (e) => {
    if (e.target.value === 1){
      setIsRequired(true)
    }
    if (e.target.value === 0) {
      setIsRequired(false)
    }
    setValue(e.target.value);
    if (e.target.value === false) {
      const field = { }
      field[name] = undefined;
      form.setFieldsValue(field);
      setDraft(Object.assign({ ...draft }, field));
    }
  }

  const onInputFocus = (e) => {
    setValue(true);
  }


  // draft sync to medicines
  useUpdateEffect(() => {
    if (draft && draft[name]) setValue(true);
  }, [draft]);
  if (isAllergy) {
    return (
      <Form.Item label={label} name={name} initialValue={0}>
        <Radio.Group onChange={onRadioGroupChange} value={value}>
          <Radio value={0} col={10}>No known drug allergies</Radio>
          <Radio value={1} col={10} span={2}>
            Allergic to <Form.Item name={"allergic_drugs"} noStyle rules={[
            {
              required: isRequired,
              message: 'Must specify what patient is allergic to'
            },
            {
              validator: (_, allergyValue) => {
                if (value === 1 && allergyValue === '') {
                  return Promise.reject();
                } else {
                  return Promise.resolve()
                }
              }
            }
          ]} ><Input onFocus={onInputFocus} maxLength={200}/></Form.Item>
          </Radio>
        </Radio.Group>
      </Form.Item>
    )
  }

  return (
    <Form.Item label={label} name={name} initialValue={0}>
      <Radio.Group onChange={onRadioGroupChange} value={value}>
        <Radio value={0} col={10}>No known drug allergies</Radio>
        <Radio value={1} col={10} span={2}>
          Allergic to <Form.Item name={"allergic_drugs"} noStyle rules={[
          {
            required: isRequired,
            message: 'Must specify what patient is allergic to'        
          },
          {
            validator: (_, allergyValue) => {
              if (value === 1 && allergyValue === '') {
                return Promise.reject();
              } else {
                return Promise.resolve()
              }
            }
        }
        ]}><Input onFocus={onInputFocus} maxLength={200}  /></Form.Item>
        </Radio>
      </Radio.Group>
    </Form.Item>
  )
};

export default RadioGroupInput;
