const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const EHRTips = () => {
  const { height, width } = getWindowDimensions();

  return (
    <div>
      <iframe src="https://coda.io/embed/eFJxwIdugw/_suBtE?viewMode=embedplay" width={width - 100} height={height - 100} style={{maxWidth: '100%'}} allow="fullscreen"></iframe>
    </div>
  )
}

export default EHRTips;
