import { stringify } from 'querystring';
import { history } from 'umi';
import { message } from 'antd';

import {
  srvConsultations,
  srvConsultationReplies,
  srvIntStatusItems,
  srvSendConsultationReply,
  srvAssignDoctorToConsultation,
} from '@/services/admin';

const ReinstatementModel = {
  namespace: 'reinstatement_application',
  state: {
    data: {
      total: 0,
      items: [],
    },
    consultation_types: [],
    consultation_statuses: [],
    replyData: {
      consultation: {},
      consultation_replies: [],
    },
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const res = yield call(srvConsultations, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveList', payload: res.data });
    },
    *fetchConsultationTypes({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'consultation_type' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveConsultationTypes', payload: res.data });
    },
    *fetchConsultationReplies({ payload }, { call, put, select }) {
      const res = yield call(srvConsultationReplies, { id: payload.id });
      if (!res.success) {
        yield put({
          type: 'saveConsultationReplies',
          payload: {
            consultation: {},
            consultation_replies: [],
          },
        });
        return;
      }
      yield put({ type: 'saveConsultationReplies', payload: res.data });
    },
    *replyConsultation({ payload, callback }, { call, put, select }) {
      const res = yield call(srvSendConsultationReply, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *assignDoctor({ payload, callback }, { call, put, select }) {
      const res = yield call(srvAssignDoctorToConsultation, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *fetchConsultationStatuses({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'consultation_status' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveConsultationStatuses', payload: res.data });
    },
  },
  reducers: {
    saveList(state, { payload }) {
      return { ...state, data: payload };
    },
    saveConsultationTypes(state, { payload }) {
      return { ...state, consultation_types: payload };
    },
    saveConsultationReplies(state, { payload }) {
      return { ...state, replyData: payload };
    },
    saveConsultationStatuses(state, { payload }) {
      return { ...state, consultation_statuses: payload };
    },
  },
};
export default ReinstatementModel;
