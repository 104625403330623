export function paymentStatusColor(status) {
  switch (status) {
    case 1:
      return 'processing';
    case 2:
      return 'success';
    case 3:
      return 'error';
    case 4:
      return 'error';
    case 5:
      return 'processing';
    case 6:
      return 'success';
    case 7:
      return 'error';
    case 8:
      return 'error';
    default:
      return 'success';
  }
}

export function appointmentStatusColor(status) {
  switch (status) {
    case 1:
      return 'warning';
    case 2:
      return 'processing';
    case 3:
      return 'default';
    case 4:
      return 'success';
    case 5:
      return 'success';
    default:
      return 'success';
  }
}

export function refundStatusColor(status) {
  switch (status) {
    case 1:
      return 'error';
    case 2:
      return 'error';
    case 3:
      return 'processing';
    case 4:
      return 'success';
    default:
      return 'success';
  }
}

export function orderStatusColor(status) {
  switch (status) {
    case 1:
      return 'processing';
    case 2:
      return 'success';
    case 3:
      return 'error';
    case 4:
      return 'default';
    default:
      return 'success';
  }
}

export function consultationStatusColor(status) {
  switch (status) {
    case 1:
      return '';
    case 2:
      return 'grey';
  }
}