import React from 'react';
import { Divider, Radio, Collapse } from 'antd';
import {
  ProFormItem,
  ProFormSelect,
  ProFormDependency,
  ProFormTextArea,
  ProFormUploadDragger,
  ProFormDatePicker,
} from '@ant-design/pro-form';
import { initialCompletedOpts } from '../meta';
import CPTCodesFormItem from '@/components/CPTCodesFormItem';
import { useNoteStore } from '@/stores';
import FormTree from './FormTree';
import { TREATMENT_PLAN_NOTE_TYPES } from '@/constants';
import cn from 'classnames/bind';
import styles from './TransferDischargeNote.less';
const cx = cn.bind(styles);

function getTreeData(noteType) {
  const baseTreeData = [
    {
      title: 'Exclusionary or Complex Medical Condition/ Diagnosis',
      key: 'r1',
      children: [
        {
          title: 'Currently Pregnant or Breastfeeding',
          key: 'r1-7',
        },
        {
          title: 'History of Heart Attack or Arrhythmias',
          key: 'r1-1',
        },
        {
          title: 'History of Stroke or Seizure Disorder',
          key: 'r1-2',
        },
        {
          title: 'Uncontrolled Hypertension',
          key: 'r1-3',
        },
        {
          title: 'Multiple Co-morbidities and/ or Polypharmacy',
          key: 'r1-4',
        },
        {
          title: 'Other Cardiac Disorder',
          key: 'r1-5',
        },
        {
          title: 'Other Neurological Disorder',
          key: 'r1-6',
        },
      ],
    },
    {
      title: 'Exclusionary or Complex Psychiatric Condition / Diagnosis',
      key: 'r2',
      children: [
        {
          title: 'Bipolar/ Mood Disordes',
          key: 'r2-1',
        },
        {
          title: 'History of Psychiatric Hospitalization',
          key: 'r2-2',
        },
        {
          title: 'Severe Depression/ Suicidality',
          key: 'r2-3',
        },
      ],
    },
    {
      title: 'No ADHD diagnosis',
      key: 'r3',
      children: [
        {
          title: 'Patient does not meet DSM-V Criteria for relevant Psychiatric Diagnosis',
          key: 'r3-1',
        },
      ],
    },
    {
      title: 'Non-compliance',
      key: 'r4',
      children: [
        {
          title: 'Patient sought care from provider outside of done platform',
          key: 'r4-1',
        },
        {
          title: 'patient not attending appointments or minimum follow-up as required',
          key: 'r4-2',
        },
        {
          title: 'patient not taking medications as advised',
          key: 'r4-3',
        },
      ],
    },
    {
      title: 'Use of Multiple Controlled Substances',
      key: 'r5',
      children: [
        {
          title: 'PDMP or pt reports regular use of benzodiazepines',
          key: 'r5-1',
        },
        {
          title: 'PDMP or pt reports regular use of controlled pain medication',
          key: 'r5-2',
        },
        {
          title: 'PDMP or pt reports use of other stimulant medication (e.g. phentermine)',
          key: 'r5-3',
        },
        {
          title: 'PDMP or pt reports use of Suboxone/Buprenorphine/Methadone',
          key: 'r5-4',
        },
        {
          title: 'PDMP or pt reports use of OTHER controlled substances',
          key: 'r5-5',
        },
        {
          title: 'pt reports uncontrolled use of substances(e.g.alcohol,club drugs,etc.)',
          key: 'r5-6',
        },
      ],
    },
    {
      title: 'Unacceptable Actions/ Behaviors',
      key: 'r6',
      children: [
        {
          title: 'Aggressive, demanding, or threatening',
          key: 'r6-1',
        },
        {
          title: 'Dishonesty! faulty reporting',
          key: 'r6-2',
        },
        {
          title: 'Discriminatory',
          key: 'r6-3',
        },
        {
          title: 'Overstepping boundaries',
          key: 'r6-4',
        },
      ],
    },
  ];

  if (noteType === TREATMENT_PLAN_NOTE_TYPES.TRANSFER) {
    baseTreeData.push({
      title: 'Provider left the practice',
      key: 'r8',
    });
  }

  baseTreeData.push({
    title: 'Other',
    key: 'r7',
  });

  return baseTreeData;
}

export default function TransferDischargeNote(props) {
  const { form } = props;
  const { patient } = useNoteStore();
  return (
    <>
      <ProFormDependency name={['note_type']}>
        {({ note_type }) => {
          return (
            <ProFormItem label="Reasons For Recommendation" name="recommendation_reasons">
              <FormTree treeData={getTreeData(note_type)} />
            </ProFormItem>
          );
        }}
      </ProFormDependency>
      <ProFormDependency name={['recommendation_reasons']}>
        {({ recommendation_reasons }) => {
          return recommendation_reasons?.includes('r7') ? (
            <ProFormTextArea
              name="recommendation_notes"
              label="Recommendation Notes"
              placeholder="Enter Recommendation Notes"
            />
          ) : null;
        }}
      </ProFormDependency>
      <ProFormItem label="Bridge Refill Attestation Date" name="bridge_refill_attestation">
        <Radio.Group>
          <Radio value={1}>
            I attest that I have reviewed the patient’s PDMP and issued a bridge refill for any
            medications this patient requires. The refill was sent on:
          </Radio>
          <ProFormDependency name={['bridge_refill_attestation']}>
            {({ bridge_refill_attestation }) => {
              return bridge_refill_attestation === 1 ? (
                <ProFormDatePicker
                  name="bridge_refill_attestation_date"
                  fieldProps={{
                    style: { marginLeft: '20px' },
                    // format: 'MM/DD/YYYY',
                  }}
                />
              ) : null;
            }}
          </ProFormDependency>
          <Radio value={2}>The patient does not require a bridge refill.</Radio>
        </Radio.Group>
      </ProFormItem>
      <Divider />
      <ProFormDatePicker name="initial_evaluation_date" label="Date of Initial Evaluation" />

      <ProFormSelect
        mode="multiple"
        name="diagnostic_code"
        label="Current Diagnoses"
        placeholder="Select Diagnostic Code"
        options={initialCompletedOpts}
        style={{ marginBottom: 0 }}
        fieldProps={{
          filterOption: (inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1,
        }}
      />
      <Collapse ghost className={cx('add-more-notes-collapse')}>
        <Collapse.Panel header={<h3 style={{ color: '#5469d4' }}>Add more notes</h3>}>
          <ProFormTextArea
            name="diagnostic_code_notes"
            placeholder=""
            fieldProps={{
              rows: 5,
              maxLength: 5000,
            }}
          />
        </Collapse.Panel>
      </Collapse>

      <CPTCodesFormItem form={form} patient={patient} noteType={'FollowUp'} initialValues={{}} />

      <ProFormTextArea
        name="medication_history"
        label="Medication History"
        fieldProps={{ rows: 5, maxLength: 5000 }}
      />
      <Collapse ghost className={cx('add-more-notes-collapse')}>
        <Collapse.Panel header={<h3 style={{ color: '#5469d4' }}>Add more notes</h3>}>
          <ProFormTextArea
            name="medication_history_notes"
            placeholder=""
            fieldProps={{
              rows: 5,
              maxLength: 5000,
            }}
          />
        </Collapse.Panel>
      </Collapse>
      <ProFormTextArea
        name="treatment_summary"
        label="Treatment Summary"
        fieldProps={{ rows: 5, maxLength: 5000 }}
      />

      <ProFormUploadDragger
        label="Attach Files"
        name="attach_files"
        title="Drag and drop your file here or upload from your computer"
        description="Support pdf, png, jpg"
        accept={['application/pdf', 'image/png', 'image/jpg']}
      />
    </>
  );
}
