import React, { useState } from 'react';
import { Checkbox, Modal, Input, Form } from 'antd';
const CheckboxGroup = Checkbox.Group;

import styles from './index.less';

const ProviderNoShowModal = ({ id, initialValues, onOk, onCancel, ...modalProps }) => {
  const [form] = Form.useForm();
  const [isOtherChecked, setIsOtherChecked] = useState(false);

  const onClose = () => {
    form.resetFields();
    setIsOtherChecked(false);
    onCancel && onCancel();
  };

  const onFinish = () => {
    form.validateFields().then((values) => {
      var data = values.reason.slice();
      data.forEach((item, index, arr) => {
        if (item == 'OTHER') {
          arr[index] = values.otherReason;
        }
      });

      onOk({ id: id, reason: data.join(',') }, () => {
        onClose();
      });
    });
  };

  const modalOptions = {
    ...modalProps,
    onOk: onFinish,
    onCancel: onClose,
  };

  const onChangeOtherReason = (e) => {
    setIsOtherChecked(e.target.checked);
  };

  return (
    <Modal {...modalOptions} destroyOnClose={true}>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        autoComplete="off"
        style={{ margin: 0 }}
        onFinish={onFinish}
      >
        <Form.Item
          label="What is the reason?"
          name="reason"
          className={styles.title}
          rules={[{ required: true }]}
        >
          <CheckboxGroup className={styles.checkboxGroup}>
            <Checkbox value="INTERNET ISSUE">Internet Issue</Checkbox>
            <Checkbox value="ZOOM ISSUE">Zoom Issue</Checkbox>
            <Checkbox value="SCHEDULING">Scheduling</Checkbox>
            <Checkbox value="OTHER" onChange={onChangeOtherReason} style={{ display: 'block' }}>
              Other
              {isOtherChecked ? (
                <Form.Item
                  name="otherReason"
                  className={styles.otherInput}
                  rules={[{ required: true, message: 'content is required' }]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={200}
                    placeholder="Please Explain"
                    rows={4}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              ) : null}
            </Checkbox>
          </CheckboxGroup>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProviderNoShowModal;
