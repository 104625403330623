import React, { useState, useEffect } from 'react';
import {
  Modal,
  Popconfirm,
  Space,
  Form,
  Table,
  Drawer,
  Input,
  Button,
  Alert,
  Skeleton,
  Descriptions,
  Dropdown,
  Row,
  Col, message,
} from 'antd';
import {
  ExclamationCircleOutlined,
  EllipsisOutlined,
  SearchOutlined,
  createFromIconfontCN,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { history, Link } from 'umi';
import { useChartAuditStore } from '@/stores';
import { downloadFile } from '@/utils/utils';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import { formatUnix, formatNextFollowUpMonth } from '@/utils/xtime';
import { isJSON } from '@/utils/utils.js';

import defaultSettings from '../../../defaultSettings';
import AddNote from '@/components/AddNote';
import DoctorChartAuditDrawer from '@/components/DoctorChartAuditDrawer';
import InitialEvaluationDetailView from '@/components/InitialEvaluationNote/InitialEvaluationDetailView';
import { formatLastRefillDate } from '@/components/LastRefiiDateFormItem';
import { CPTCodesMap } from '@/components/CPTCodesFormItem';
import NotesDetail from './NotesDetail'
import EditNote from './EditNote';
import EditLog from './EditLog';
const { TextArea } = Input;
import {
  srvPatientTreatmentPlanHistories,
  srvPatientTreatmentPlanHistoryDetail,
  srvMarkNoDiagnosis,
  srvAddTreatmentPlan,
  srvPatientTreatmentPlan,
  srvAddTreatmentPlanAddendum,
  srvDelPatientTreatmentPlanHistory,
  srvEditPatientTreatmentPlanHistory,
  srvPatientDetail,
  srvGetDocumentURL,
} from '@/services/patient';
import styles from './ProviderNotes.less';
import { deleteAllDrafts } from '../../utils/localStorage';
import { useFlag } from '@unleash/proxy-client-react';
import { useNoteStore } from '@/stores';
let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });
const cx = cn.bind(styles);

export const allReasons = [
  {
    label: 'Exclusionary or Complex Medical Condition/ Diagnosis',
    checked: false,
    key: "r1",
    options: [
      {
        key: '7',
        checked: false,
        label: 'Currently Pregnant or Breastfeeding'
      },
      {
        key: '1',
        checked: false,
        label: 'History of Heart Attack or Arrhythmias'
      },
      {
        key: '2',
        checked: false,
        label: 'History of Stroke or Seizure Disorder'
      },
      {
        key: '3',
        checked: false,
        label: 'Uncontrolled Hypertension'
      },
      {
        key: '4',
        checked: false,
        label: 'Multiple Co-morbidities and/ or Polypharmacy'
      },
      {
        key: '5',
        checked: false,
        label: 'Other Cardiac Disorder'
      },
      {
        key: '6',
        checked: false,
        label: 'Other Neurological Disorder'
      },
    ]
  },
  {
    label: 'Exclusionary or Complex Psychiatric Condition / Diagnosis',
    checked: false,
    key: 'r2',
    options: [
      {
        key: '1',
        checked: false,
        label: 'Bipolar/ Mood Disordes'
      },
      {
        key: '2',
        checked: false,
        label: 'History of Psychiatric Hospitalization'
      },
      {
        key: '3',
        checked: false,
        label: 'Severe Depression/ Suicidality'
      },
    ]
  },
  {
    label: 'No ADHD diagnosis',
    key: 'r3',
    checked: false,
    options: [
      {
        key: '1',
        checked: false,
        label: 'Patient does not meet DSM-V Criteria for relevant Psychiatric Diagnosis'
      },
    ]
  },
  {
    label: 'Non-compliance',
    key: 'r4',
    checked: false,
    options: [
      {
        key: '1',
        checked: false,
        label: 'Patient sought care from provider outside of done platform'
      },
      {
        key: '2',
        checked: false,
        label: 'patient not attending appointments or minimum follow-up as required'
      },
      {
        key: '3',
        checked: false,
        label: 'patient not taking medications as advised'
      },
    ]
  },
  {
    label: 'Use of Multiple Controlled Substances',
    key: 'r5',
    checked: false,
    options: [
      {
        key: '1',
        checked: false,
        label: 'PDMP or pt reports regular use of benzodiazepines'
      },
      {
        key: '2',
        checked: false,
        label: 'PDMP or pt reports regular use of controlled pain medication'
      },
      {
        key: '3',
        checked: false,
        label: 'PDMP or pt reports use of other stimulant medication (e.g. phentermine)'
      },
      {
        key: '4',
        checked: false,
        label: 'PDMP or pt reports use of Suboxone/Buprenorphine/Methadone'
      }, {
        key: '5',
        checked: false,
        label: 'PDMP or pt reports use of OTHER controlled substances'
      },
      {
        key: '6',
        checked: false,
        label: 'pt reports uncontrolled use of substances(e.g.alcohol,club drugs,etc.)'
      },
    ]
  },
  {
    label: 'Unacceptable Actions/ Behaviors',
    key: 'r6',
    checked: false,
    options: [
      {
        key: '1',
        checked: false,
        label: 'Aggressive, demanding, or threatening'
      },
      {
        key: '2',
        checked: false,
        label: 'Dishonesty! faulty reporting'
      },
      {
        key: '3',
        checked: false,
        label: 'Discriminatory'
      },
      {
        key: '4',
        checked: false,
        label: 'Overstepping boundaries'
      },
    ]
  }, {
    label: 'Provider left the practice',
    key: 'r8',
    checked: false,
    options: []
  },
];

const ProviderNotes = (props) => {
  const { active, id } = props;
  const {open: openChartAudit} = useChartAuditStore();
  const [treatmentPlanHistories, setTreatmentPlanHistories] = useState([]);
  const [treatmentPlanHistory, setTreatmentPlanHistory] = useState({});
  const [editNoteVisible, setEditNoteVisible] = useState(false);
  const [editLogVisible, setEditLogVisible] = useState(false);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [addAddendumVisible, setAddAddendumVisible] = useState(false);
  const [patientInfo, setPatientInfo] = useState(null);
  const [selected, setSelected] = useState('');
  const [audit, setAudit] = useState(false);
  const [selectNoteType, setSelectNoteType] = useState(0);
  const [form] = Form.useForm();
  const flagLockClinicianNote = useFlag('lock_clinician_note');
  const [reasons,setReasons] =useState('');
  /* begin ayva code */
  const [noteVisible, setNoteVisible] = useState(false);
  const [bridgeRefill,setBridgeRefill]=useState('');
  const [attachFiles,setAttachFiles]=useState([]);
  const [imgVisible,setImgVisible]=useState(false);
  const [imgUrl,setImgUrl]=useState('');
  const [previewFileType, setPreviewFileType] = useState('');
  const [recommendations, setRecommendations] = useState('');

  const handleCancel = () => {
    setImgVisible(false);
  };
  const toPreview = (file) => {
    srvGetDocumentURL({
      object_key: file.object_key,
    }).then((res) => {
      if (res.success) {
        fetchPreviewInfo(res.data);
      }
    })
  };
  const fetchPreviewInfo = (url)=>{
    fetch(url)
      .then((res) => {
        const contentType = res.headers.get('Content-Type');
        if (contentType === 'application/pdf') {
          setPreviewFileType(contentType);
        }
        setImgUrl(url);
        setImgVisible(true);
      })
      .catch((e) => {
        window.sendErrorLog(e);
      });
  }

  const delTreamentPlanHistoryReq = useRequest(srvDelPatientTreatmentPlanHistory, {
    manual: true,
    onSuccess: (ret, params) => {
      loadData();
    },
  });

  const loadHistoriesReq = useRequest(srvPatientTreatmentPlanHistories, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setTreatmentPlanHistories(ret.data);
      }
    },
  });

  const loadHistoryReq = useRequest(srvPatientTreatmentPlanHistoryDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setTreatmentPlanHistory(ret.data);
        if(ret.data!==''){
          if(ret.data.recommendation_reasons!=''){
            var reasonTemp='';
            var select=JSON.parse(ret.data.recommendation_reasons);
            allReasons.map((item)=>{
              if(Object.keys(select).includes(item.key)){
                reasonTemp=reasonTemp+item.label;
                if(select[item.key].length===0){
                  reasonTemp=reasonTemp+':\n';
                }else{
                  var selectOptions=select[item.key];
                  if (item.options.length > 0) {
                    reasonTemp=reasonTemp+': ';
                    item.options.map((childItem) => {
                      if (selectOptions.includes(childItem.key)) {
                        reasonTemp = reasonTemp+childItem.label + '; ';
                      }
                    });
                  }

                }
              }
            });
            if (Object.keys(select).includes('r7')) {
              if (Object.keys(select).includes('other_reason')) {
                reasonTemp = reasonTemp + 'Other reason:\n' + select['other_reason'];
              } else {
                reasonTemp = reasonTemp + 'Other reason:\n';
              }
            }
            setReasons(reasonTemp);
          }
          if (ret.data.recommendations === 1) {
            setRecommendations('Recommend a transfer to another provider');
          } else if (ret.data.recommendations === 2) {
            setRecommendations('Recommend permanent discharge from the platform');
          }
          if(ret.data.bridge_refill_attestation===1){
            if (ret.data.bridge_refill_attestation_date === 0 || ret.data.bridge_refill_attestation_date === null) {
              setBridgeRefill('I attest that I have reviewed the patient’s PDMP and issued a bridge refill for any medications this patient requires. The refill was sent on: ');
            } else {
              setBridgeRefill('I attest that I have reviewed the patient’s PDMP and issued a bridge refill for any medications this patient requires. The refill was sent on: ' + formatUnix(ret.data.bridge_refill_attestation_date));
            }
          }else if(ret.data.bridge_refill_attestation===2){
            setBridgeRefill('The patient does not require a bridge refill.');
          }
          if(ret.data.attach_files!=''&&ret.data.attach_files!='[]'){
            setAttachFiles(JSON.parse(ret.data.attach_files));
          }
        }
      }
    },
  });


  const loadPatientInfo = useRequest(srvPatientDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setPatientInfo(ret.data);
      }
    },
  });

  const addAddendumReq = useRequest(srvAddTreatmentPlanAddendum, {
    manual: true,
    onSuccess: (ret, params) => {
      onHistoryClose()
    },
  });

  const loadData = () => {
    if (active) {
      loadHistoriesReq.run({ id });
      loadPatientInfo.run({ id });
    }
  };

  useMount(() => {
    loadData();
  });

  useUpdateEffect(() => {
    loadData();
  }, [active, id]);

  const onHistoryClose = () => {
    setHistoryVisible(false);
    setAudit(false);
    setSelected('');
    setAddAddendumVisible(false);
    form.resetFields()
  };

  const onNoteClose = () => {
    setNoteVisible(false);
  };

  const selectRow = (record) => {
    setSelected(record.id);
    setHistoryVisible(true);
    setNoteVisible(false);
    setSelectNoteType(record.note_type);
    if (record.audit_id != '0' && record.audit && Object.keys(record.audit).length) {
      setAudit(record.audit);
      // openChartAudit(record.audit);
    }
    loadHistoryReq.run({ id: record.id });
  };
  const {open} = useNoteStore()
  const openNoteDrawer = () => {
    setNoteVisible(true);
  };

  const onEditNote = (values) => {
    setSelected(values.id);
    setEditNoteVisible(true);
  };

  const onEditLog = (values) => {
    setSelected(values.id);
    setEditLogVisible(true);
  };

  const onAddAddendum = (values) => {
    setAddAddendumVisible(true);
    selectRow(values);
  };

  const addAddendum = (values) => {
    onHistoryClose()
    values.creator_type = "Provider"
    values.id = selected
    addAddendumReq.run({...values})
  };

  const onDeleteNote = (values) => {
    Modal.confirm({
      title: 'Do you Want to delete these record?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        delTreamentPlanHistoryReq.run({ id: values.id });
      },
      onCancel() {},
    });
  };

  const goToMedicationTab = () => {
    setNoteVisible(false);
    history.push(`/patient/${id}?tab=3`);
  };

  const renderClinicianNotesDom = (note) => {
    if (note.note_type === 1) {
      return (
        <InitialEvaluationDetailView note={note} />
      )
    }
    return <div className={styles.lineBreak}>{note.provider_notes}</div>
  }

  const renderDescriptions = () => {
    if(selectNoteType===4||selectNoteType===5){
       return (
         <>
           <Descriptions
             title="Diagnostics"
             layout="vertical"
             column={1}
             size="small"
             bordered={true}
           >
             {selectNoteType === 4 && (
               <>
                 <Descriptions.Item label="Note type">Transfer Note</Descriptions.Item>
               </>
             )}
             {selectNoteType === 5 && (
               <>
                 <Descriptions.Item label="Note type">Discharge Note</Descriptions.Item>
               </>
             )}
             <Descriptions.Item label="Recommendations">{recommendations}</Descriptions.Item>
             <Descriptions.Item
               style={{ whiteSpace: 'pre-line' }}
               label="Reasons For Recommendation(Discharge/Transfer)"
             >
               {reasons}
             </Descriptions.Item>
             <Descriptions.Item label="Bridge Refill Attestation">{bridgeRefill}</Descriptions.Item>
             <Descriptions.Item label="Date of Initial Evaluation">
               {treatmentPlanHistory.initial_evaluation_date > 0
                 ? formatUnix(treatmentPlanHistory.initial_evaluation_date)
                 : ''}
             </Descriptions.Item>
             <Descriptions.Item label="Current Diagnoses">
               {treatmentPlanHistory.diagnostic_code}
             </Descriptions.Item>
             <Descriptions.Item label="Diagnosis Notes">
               {treatmentPlanHistory.diagnostic_code_notes}
             </Descriptions.Item>
             <Descriptions.Item label="CPT Code">
               {CPTCodesMap[treatmentPlanHistory.cpt_code]
                 ? CPTCodesMap[treatmentPlanHistory.cpt_code]
                 : treatmentPlanHistory.cpt_code}
             </Descriptions.Item>
             {treatmentPlanHistory.addon_code && (
               <Descriptions.Item label="Add-on Code">
                 {CPTCodesMap[treatmentPlanHistory.addon_code]
                   ? CPTCodesMap[treatmentPlanHistory.addon_code]
                   : treatmentPlanHistory.addon_code}
               </Descriptions.Item>
             )}
             <Descriptions.Item style={{ whiteSpace: 'pre-line' }} label="Medication History">
               {treatmentPlanHistory.medication_history}
             </Descriptions.Item>
             <Descriptions.Item label="Medication Notes">
               {treatmentPlanHistory.medication_history_notes}
             </Descriptions.Item>
             <Descriptions.Item label="Treatment Summary">
               {treatmentPlanHistory.treatment_summary}
             </Descriptions.Item>
           </Descriptions>
           {renderAddendum()}
           <Descriptions
             title="Diagnostics"
             layout="vertical"
             column={1}
             size="small"
             bordered={true}
           >
             <Descriptions.Item label="Attach File">
               {attachFiles.length > 0 &&
                 attachFiles.map((file) => (
                   <>
                     <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                       <div style={{ marginRight: 'auto' }}>{file.name}</div>
                       <div
                         style={{
                           color: '#5469d4',
                           fontSize: '16px',
                           cursor: 'pointer',
                           marginRight: '10px',
                         }}
                       >
                         <span onClick={() => toPreview(file)}>Preview</span>
                       </div>
                       <div style={{ color: '#5469d4', fontSize: '16px', cursor: 'pointer' }}>
                         <span onClick={() => downloadFile(file)}>Download</span>
                       </div>
                     </div>
                   </>
                 ))}
             </Descriptions.Item>
           </Descriptions>
         </>
       );
    }else if(selectNoteType===6){
      return renderGenericNoteDescriptions();
    }
    return <>
      {treatmentPlanHistory.note_type === 1 ?
        <NotesDetail data={treatmentPlanHistory}/> :
        <>
          <Descriptions
            title="Diagnostics"
            layout="vertical"
            column={2}
            size="small"
            bordered={true}
          >
            <Descriptions.Item label="Note type">{renderNoteType(treatmentPlanHistory)}</Descriptions.Item>
            <Descriptions.Item label="Updated At">
              {treatmentPlanHistory.updated_at && formatUnix(treatmentPlanHistory.updated_at)}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            title="Diagnostics"
            layout="vertical"
            column={1}
            size="small"
            bordered={true}
          >
            <Descriptions.Item label="Diagnostic Code">
              {treatmentPlanHistory.diagnostic_code}
            </Descriptions.Item>
            <Descriptions.Item
              label="CPT Code">{CPTCodesMap[treatmentPlanHistory.cpt_code] ? CPTCodesMap[treatmentPlanHistory.cpt_code] : treatmentPlanHistory.cpt_code}</Descriptions.Item>
            {treatmentPlanHistory.addon_code && (
              <Descriptions.Item
                label="Add-on Code">{CPTCodesMap[treatmentPlanHistory.addon_code] ? CPTCodesMap[treatmentPlanHistory.addon_code] : treatmentPlanHistory.addon_code}</Descriptions.Item>
            )}
            <Descriptions.Item label="Last Refill Date">{formatLastRefillDate(treatmentPlanHistory.last_refill_date)}</Descriptions.Item>
            <Descriptions.Item label="Rx">{treatmentPlanHistory.rx}</Descriptions.Item>
            <Descriptions.Item label="Clinician Notes">
              {renderClinicianNotesDom(treatmentPlanHistory)}
            </Descriptions.Item>
            {
              treatmentPlanHistory.next_follow_up_date > 0 && treatmentPlanHistory.next_follow_up_month > 0 &&
              <>
                <Descriptions.Item label="Next Follow Up Date">
                  {treatmentPlanHistory.next_follow_up_date > 0 ? formatUnix(treatmentPlanHistory.next_follow_up_date, 'MMM D, YYYY') : ''}
                </Descriptions.Item>
                <Descriptions.Item label="I‘d like to remind this patient to schedule their next follow-up appointment in">
                  {formatNextFollowUpMonth(treatmentPlanHistory.next_follow_up_month)}
                </Descriptions.Item>
              </>
            }
            <Descriptions.Item label="Message To Patient">
              <div className={styles.lineBreak}>{treatmentPlanHistory.message_to_patient}</div>
            </Descriptions.Item>
          </Descriptions>
        </>
      }
      {renderAddendum()}</>
  }

  const renderGenericNoteDescriptions = () => {
    return (
      <>
        <Descriptions title="Diagnostics" layout="vertical" column={1} size="small" bordered={true}>
              <Descriptions.Item label="Updated At">
                {treatmentPlanHistory.updated_at && formatUnix(treatmentPlanHistory.updated_at)}
              </Descriptions.Item>
              <Descriptions.Item label="CPT Code">
                {CPTCodesMap[treatmentPlanHistory.cpt_code] ? CPTCodesMap[treatmentPlanHistory.cpt_code] : treatmentPlanHistory.cpt_code}
              </Descriptions.Item>
              {treatmentPlanHistory.addon_code && (
                  <Descriptions.Item
                      label="Add-on Code">{CPTCodesMap[treatmentPlanHistory.addon_code] ? CPTCodesMap[treatmentPlanHistory.addon_code] : treatmentPlanHistory.addon_code}</Descriptions.Item>
              )}
              <Descriptions.Item label="Clinician Notes">
                <div className={styles.lineBreak}>{treatmentPlanHistory.provider_notes}</div>
              </Descriptions.Item>
            </Descriptions>
            {renderAddendum()}
      </>
    );
  }

  const renderAddendum = () => {
    return treatmentPlanHistory && treatmentPlanHistory.Addendums && treatmentPlanHistory.Addendums.length ? (
      treatmentPlanHistory.Addendums.map((addendum,index) => (
        <Descriptions key={index} title="Addendums" layout="vertical" column={1} size="small" bordered={true}>
          <Descriptions.Item
            label={
              addendum.creator_type +
              ' Addendum - ' +
              addendum.creator_name +
              ' ' +
              addendum.creator_title +
              ' ' +
              moment.unix(addendum.created_at).format('MM/DD/YYYY')
            }
          >
            <div className={styles.lineBreak}>{addendum.addendum}</div>
          </Descriptions.Item>
        </Descriptions>
      ))
    ) : (
      <></>
    );
  };

  const renderNoteType = (r) => {
    let text = '';
    if (r.note_type == '1') {
      text = 'Initial';
    } else if (r.note_type == '2') {
      text = 'Follow-up';
    } else if (r.note_type == '3') {
      text = 'Refill Request';
    } else if (r.note_type == '4') {
      text = 'Transfer';
    } else if (r.note_type == '5') {
      text = 'Discharge';
    } else if (r.note_type == '6') {
      text = 'Generic Therapy Note';
    } else if (r.note_type == '7') {
      text = 'Coverage Note';
    } else if (r.note_type == '8') {
      text = 'Plain'
    } else if (r.note_type == '9') {
      text = 'Async Video Follow-Up'
    } else if (r.note_type == '10') {
      text = 'Coaching Note';
    } else if (r.note_type == '11') {
      text = 'Transfer Of Care';
    }
    return text;
  }
  const columns = [
    {
      title: 'Diagnostic Code',
      dataIndex: 'diagnostic_code',
      ellipsis: true,
      key: 'diagnostic_code',
      width: 145,
    },
    {
      title: 'Rx',
      dataIndex: 'rx',
      ellipsis: true,
      key: 'rx',
      width: 200,
    },
    {
      title: 'Notes Type',
      dataIndex: 'note_type',
      key: 'note_type',
      ellipsis: true,
      width: 100,
      render: (v, r) => {
        return renderNoteType(r);
      },
    },
    {
      title: 'Provider',
      dataIndex: 'doctor_name',
      ellipsis: true,
      key: 'provider',
      width: 120,
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      width: 150,
      render: (v, r) => {
        return formatUnix(r.created_at);
      },
    },
    {
      title: 'Note Audit',
      dataIndex: 'audit_id',
      key: 'audit_id',
      ellipsis: true,
      width: 80,
      render: (v, r) => {
        return r.audit_id != '0' ? 'Audited' : 'Unaudited';
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 50,
      render: (v, r) => {
        return (
          <Space>
            <Dropdown
              overlay={
                <div className={cx({ opt: true })}>
                  {!flagLockClinicianNote && (
                    <>
                      <div
                        className={cx({ optItem: true })}
                        onClick={() => {
                          onEditNote(r);
                        }}
                      >
                        <a href="#">Edit</a>
                      </div>
                      <div
                        className={cx({ optItem: true })}
                        onClick={() => {
                          onDeleteNote(r);
                        }}
                      >
                        <a href="#">Delete</a>
                      </div>
                    </>
                  )}
                  {flagLockClinicianNote && (
                    <div
                      className={cx({ optItem: true })}
                      onClick={() => {
                        onAddAddendum(r);
                      }}
                    >
                      <a href="#">Add An Addendum</a>
                    </div>
                  )}
                  <div
                    className={cx({ optItem: true })}
                    onClick={() => {
                      onEditLog(r);
                    }}
                  >
                    <a href="#">View History</a>
                  </div>
                </div>
              }
              trigger={['click']}
            >
              <Button size="small">
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  return (
    <div className={cx({ container: true })}>
      {imgVisible && (
        <>
          <div className="img-preview-container" style={{ position: 'absolute', zIndex: '9999', top: '0', bottom: '0', left: '0', right: '0', margigin: 'auto', width: '90%', height: '90%' }}>
            <CloseCircleOutlined
              className="close-button"
              onClick={() => {
                setImgVisible(false);
              }}
            />
            {previewFileType === 'application/pdf' ? (
              <object
                className="identity-img-large"
                data={imgUrl}
                type="application/pdf"
               />
            ) : (
              <img className="identity-img-large" src={imgUrl} />
            )}
          </div>
          <div
            onClick={() => {
              setImgVisible(false);
            }}
            className="upload-mask"
           />
        </>
      )}
      <div className={cx({ btnBox: true })}>
        <Space>
          <Button type="primary" onClick={openNoteDrawer}>
            Add Note
          </Button>
          {/* <Button type="ghost" onClick={()=>open(id)}>
            New Add Note
          </Button> */}
        </Space>
      </div>
      <h3>Clinician Notes</h3>
      <Table
        columns={columns}
        dataSource={treatmentPlanHistories}
        rowKey="id"
        loading={loadHistoriesReq.loading}
        onRow={(record) => ({
          onClick: (evt) => {
            evt.stopPropagation();
            if (evt.target && evt.target.tagName.toUpperCase() === 'TD') {
              selectRow(record);
            }
          },
        })}
        pagination={{ pageSize: 20, simple: true }}
        rowClassName={(r) => {
          if (r.id === selected) {
            return 'rowSelected';
          }
          return '';
        }}
      />
      <>
        {noteVisible && patientInfo && (
          <AddNote
            sourceAction={'ClinicianNotes'}
            onRefresh={() => {
              loadData();
            }}
            noteVisible={noteVisible}
            setNoteVisible={(v) => {
              setNoteVisible(v);
            }}
            patientInfo={patientInfo}
            treatmentPlanHistories={treatmentPlanHistories}
          />
        )}

        {audit && audit.id && historyVisible && (
          <DoctorChartAuditDrawer
            visible={historyVisible}
            setVisible={onHistoryClose}
            audit={audit}
            onRefresh={() => { }}
          />
        )}

        {!audit && historyVisible && (
          <Drawer
            className={cx({ historyDrawer: true })}
            title="Clinician Note"
            width="600"
            placement="right"
            closable={false}
            onClose={onHistoryClose}
            visible={historyVisible}
          >
            {addAddendumVisible ? (
              <>
                <div style={{ height: 'calc(100% - 200px)', overflow: 'auto' }}>
                  {renderDescriptions()}
                </div>
                <Form layout="vertical" form={form} onFinish={addAddendum}>
                  <div
                    style={{
                      position: 'fixed',
                      bottom: 0,
                      background: 'white',
                      height: '200px',
                      width: '550px',
                    }}
                  >
                    <Form.Item
                      name={'addendum'}
                      label="Addendum"
                      rules={[{ required: true, message: 'Addendum is Required' }]}
                      style={{ marginLeft: 'auto', marginRight: 'auto', width: '550px' }}
                    >
                      <TextArea rows={3} style={{ width: '550px' }} maxLength={20000} />
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          form.submit();
                        }}
                        class="right-button"
                      >
                        Sign and Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              </>
            ) : (
              renderDescriptions()
            )}
          </Drawer>
        )}

        {editNoteVisible && (
          <EditNote
            itemID={selected}
            visible={editNoteVisible}
            onClose={() => {
              setEditNoteVisible(false);
              setSelected('');
            }}
          />
        )}
        {editLogVisible && (
          <EditLog
            itemID={selected}
            visible={editLogVisible}
            onClose={() => {
              setEditLogVisible(false);
              setSelected('');
            }}
          />
        )}
      </>
    </div>
  );
};
export default ProviderNotes;
