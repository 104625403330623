import { stringify } from 'querystring';
import { history } from 'umi';
import { message } from 'antd';
import {
  srvAppointments,
  srvIntStatusItems,
  srvAppointmentRescheduleCalendar,
  srvRescheduleAppointment,
  srvCancelAppointment,
  srvAppointmentItem,
  srvAdminUpdateAppointmentPDMP,
} from '@/services/admin';

const Model = {
  namespace: 'appointment',
  state: {
    data: {
      items: [],
      total: 0,
      new_total: 0,
    },
    appointment_types: [],
    appointment_statuses: [],
    rescheduleCalendar: {},
  },
  effects: {
    *fetchList({ payload }, { call, put }) {
      const res = yield call(srvAppointments, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveList', payload: res.data });
    },
    *fetchAppointmentTypes({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'appointment_type' });
      if (!res.success) {
        return;
      }
      res.data = res.data.filter((item) => item.value !== 9);
      yield put({ type: 'saveAppointmentTypes', payload: res.data });
    },
    *fetchAppointmentStatuses({ payload }, { call, put }) {
      const res = yield call(srvIntStatusItems, { name: 'appointment_status' });
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveAppointmentStatuses', payload: res.data });
    },
    *rescheduleAppointment({ payload, callback }, { call, put, select }) {
      const res = yield call(srvRescheduleAppointment, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *cancelAppointment({ payload, callback }, { call, put, select }) {
      const res = yield call(srvCancelAppointment, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *updateAppointmentPDMP({ payload, callback }, { call, put }) {
      const res = yield call(srvAdminUpdateAppointmentPDMP, payload);
      if (!res.success) {
        return;
      }
      if (callback) {
        callback();
      }
    },
    *fetchAppointmentRescheduleCalendar({ payload }, { call, put }) {
      const res = yield call(srvAppointmentRescheduleCalendar, payload);
      if (!res.success) {
        return;
      }
      yield put({ type: 'saveAppointmentRescheduleCalendar', payload: res.data });
    },

    *fetchAppointmentItem({ payload }, { call, put, select }) {
      const res = yield call(srvAppointmentItem, { id: payload.id });
      if (!res.success) {
        return;
      }
      const { data } = yield select((state) => state.appointment);

      for (let i = 0; i < data.items.length; i++) {
        if (res.data.id === data.items[i].id) {
          data.items[i] = res.data;
        }
      }

      yield put({ type: 'saveList', payload: { total: data.total, items: [...data.items] } });
    },
  },
  reducers: {
    saveList(state, { payload }) {
      return { ...state, data: payload };
    },
    saveAppointmentTypes(state, { payload }) {
      return { ...state, appointment_types: payload };
    },
    saveAppointmentStatuses(state, { payload }) {
      return { ...state, appointment_statuses: payload };
    },
    saveAppointmentRescheduleCalendar(state, { payload }) {
      return { ...state, rescheduleCalendar: payload };
    },
  },
};
export default Model;
