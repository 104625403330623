import React, { useState, useEffect, useMemo } from 'react';
import './HomeWarningMetrics.css';
import { Table, Popover, Button, Modal, Input, message } from 'antd';
import { useRequest } from 'ahooks';
import moment from 'moment';
import { history } from "umi";
import { useFlag } from '@unleash/proxy-client-react';

import {
  srvDoctorReinstatementApplyConsultation,
  srvDoctorLastOpenReinstatementApplyConsultation,
  srvDoctorDashboardProviderNoShow,
} from '@/services/patient';

const HomeWarningMetrics = (props) => {
  const { isHome, dashboardMetricsWarningData, isYesterday, viewAllUrl, otherWarningData } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShowForm, setIsShowForm] = useState(false);
  const [reasonForReinstatement, setReasonForReinstatement] = useState('');
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [isAcceptNewPatients, setIsAcceptNewPatients] = useState(false);
  const [showApply, setShowApply] = useState(false);
  const [showApplyDisabled, setShowApplyDisabled] = useState(false);
  const [warningContent, setWarningContent] = useState('');
  const [hasLastOpen, setHasLastOpen] = useState(false);
  const [theDashboardMetricsWarningData, setTheDashboardMetricsWarningData] = useState({
    patient_view_warning: 0,
    urgent_refill_warning: 0,
    patient_view_last_two_days: [],
    urgent_refill_last_two_days: [],
    show_patient_view_last_two_days: false,
    show_urgent_refill_last_two_days: false,
  });
  const [providerNoShowCount, setProviderNoShowCount] = useState(0);
  const [pausePopupContent, setPausePopupContent] = useState('');
  const [providerNoShowData, setProviderNoShowData] = useState(null);
  
  const flagShowUrgentRefill = useFlag('enable_ehr_show_urgent_refill');

  useEffect(() => {
    GetProviderNoShowList.run({});
    if (dashboardMetricsWarningData || providerNoShowCount) {
      setTheDashboardMetricsWarningData(dashboardMetricsWarningData);
      checkShowApply();
      setWarningContentContent(dashboardMetricsWarningData, providerNoShowCount);
      setIsAcceptNewPatients(!dashboardMetricsWarningData.is_warning_lock_metrics);
      if (isHome && !dashboardMetricsWarningData.warning_lock_read && dashboardMetricsWarningData.is_warning_lock_metrics) {
        setIsModalVisible(true)
      }
    }
  }, [dashboardMetricsWarningData, providerNoShowCount]);


  const GetProviderNoShowList = useRequest(srvDoctorDashboardProviderNoShow, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data != null && ret.data.items) {
          setProviderNoShowData(ret.data);
          setProviderNoShowCount(ret.data.total);
          return ret.data;
        }
      }
    },
  });

  const sendReinstatementApply = useRequest(srvDoctorReinstatementApplyConsultation, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        message.success('Apply successfully');
      }
    }
  })
  
  const checkDoctorLastOpenReinstatementApplyConsultation = useRequest(srvDoctorLastOpenReinstatementApplyConsultation, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        if (ret.data) {
          if (ret.data.consultation_id) {
            setHasLastOpen(true);
          }
        }
      }
    }
  })

  const handleApplyClick = () => {
    setIsShowForm(true);
  };
  const handleModalOk = () => {
    setIsShowForm(true);
    setIsModalVisible(false);
    setReasonForReinstatement('');
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  }



  const handleFormSubmit = async () => {
    if (!isInputEmpty) {
      await sendReinstatementApply.runAsync({
        content: reasonForReinstatement,
      })
      setIsShowForm(false);
      checkShowApply();
    }
  }
  const handleFormCancel = () => {
    setIsShowForm(false);
  }

  useMemo(() => {
    setShowApply(!isAcceptNewPatients && !hasLastOpen)
    setShowApplyDisabled(!isAcceptNewPatients && hasLastOpen)
  }, [isAcceptNewPatients, hasLastOpen]);

  const checkShowApply = async () => {
    // await fetchCheckDoctorAcceptNewPatients.run();
    checkDoctorLastOpenReinstatementApplyConsultation.run();
  }

  const setWarningContentContent = (dashboardMetricsWarningData, providerNoShowCount) => {
    var warningContent = '';
    var popUpContent = '';
    
    if (dashboardMetricsWarningData || providerNoShowCount) {
      if (dashboardMetricsWarningData.is_warning_lock_metrics) {
        if (!isHome) {
          warningContent += '<p>Due to performance not meeting the criteria, you have been paused to see new patients.</p>';
          warningContent += '<ul style="list-style-type: disc;margin-left: 30px;">';
          if (dashboardMetricsWarningData.provider_no_show_is_lock) {
            warningContent += '<li>In light of multiple provider no-shows recently, you have been paused to see new patients for the next 7 days.<br/>If the alert is in error, please apply for reinstatement and provide an explanation.</li>';
          }
          if (dashboardMetricsWarningData.ugrent_refill_is_lock) {
            warningContent += '<li>In light of unsolved urgent refills, accepting new patients is temporarily paused.<br/>For patients needing follow-up, contact your PA for scheduling. If slots are unavailable, opt for a phone call or a temporary prescription. Ensure to submit follow-up notes when prescribing for these patients.<br/>Please prioritize addressing them within 3 days and apply for reinstatement to lift ongoing restrictions.</li>';
          }
          if (dashboardMetricsWarningData.rating_star_is_lock) {
            warningContent += '<li>Your average patient ratings for the past two days fell below 3. New patient appointments have been paused until your daily average reaches 3 again.<br/>Please consult your Lead NP for guidance on improving your patient experience.<br/>For any questions or concerns, please apply for reinstatement.</li>';
          }
          warningContent += '</ul>';
        } else {
          warningContent += '<p>Due to performance not meeting the criteria, you have been paused to see new patients.</p>';
        }
      } else if (dashboardMetricsWarningData.is_warning_metrics) {
        warningContent += '<p>Please note that you don’t meet the criteria for certain metrics. Kindly improve your service quality and efficiency.</p>';
        if (!isHome) {
          warningContent += '<ul style="list-style-type: disc;margin-left: 30px;">';
          if (dashboardMetricsWarningData.provider_no_show_is_warning) {
            warningContent += '<li>Provider no-shows have occurred. Another no-show within 30 days may lead to a one-week pause on new appointments.<br/>Please reschedule your appointments in EHR if you’re unable to make them. (<a href="https://drive.google.com/file/d/1IRhrUo2YL5_f62tKSp9RYg5M-5JwQCrK/view?usp=sharing" target="_blank" style="color: #5469d4">Tutorial</a>)</li>';
          }
          if (dashboardMetricsWarningData.ugrent_refill_is_warning && flagShowUrgentRefill) {
            let text = 'new patient appointments could potentially be paused';
            if (dashboardMetricsWarningData.enabled_urgent_refill_metric_lock) {
              text = 'new patient appointments will be paused';
            }
            warningContent += `<li>You have unsolved urgent refills. <br/>If this persists for two consecutive days, ${text}.<br/>For patients needing follow-up, contact your PA for scheduling. If slots are unavailable, opt for a phone call or a temporary prescription. Ensure to submit follow-up notes when prescribing for these patients.</li>`;
          }
          if (dashboardMetricsWarningData.rating_star_is_warning) {
            warningContent += '<li>Your average patient rating from yesterday fell below 3.<br/>If this persists for two consecutive days, new patient appointments will be temporarily paused.<br/>Please consult your Lead NP for guidance on improving your patient experience.</li>';
          }
          warningContent += '</ul>';
        }
      }

      if (dashboardMetricsWarningData.is_warning_lock_metrics) {
        popUpContent += '<p>Due to performance not meeting the criteria, you have been paused to see new patients.</p>';
        popUpContent += '<ul style="list-style-type: disc;margin-left: 30px;">';
        if (dashboardMetricsWarningData.provider_no_show_is_lock) {
          popUpContent += '<li>In light of multiple provider no-shows recently, you have been paused to see new patients for the next 7 days.<br/>If the alert is in error, please apply for reinstatement and provide an explanation.</li>';
        }
        if (dashboardMetricsWarningData.ugrent_refill_is_lock) {
          popUpContent += '<li>In light of unsolved urgent refills, accepting new patients is temporarily paused.<br/>Please prioritize addressing them within 3 days and apply for reinstatement to lift ongoing restrictions.</li>';
        }
        if (dashboardMetricsWarningData.rating_star_is_lock) {
          popUpContent += '<li>Your average patient ratings for the past two days fell below 3. New patient appointments have been paused until your daily average reaches 3 again.<br/>Please consult your Lead NP for guidance on improving your patient experience.<br/>For any questions or concerns, please apply for reinstatement.</li>';
        }
        popUpContent += '</ul>';
      }
    }
    
    setPausePopupContent(popUpContent)
    setWarningContent(warningContent)
  }

  const formatOtherWarningData = () => {
    if (!otherWarningData) {
      return null;
    }
    const itemsPerRow = 4;
    const rows = [];
    for (let i = 0; i < otherWarningData.length; i += itemsPerRow) {
      const rowItems = otherWarningData.slice(i, i + itemsPerRow);
      rows.push(
        <div key={i} className="metrics-box">
          {rowItems.map(item => (
            <div key={item.id} className="metric-item-column">
              <div className="metric-number">{item.value}</div>
              <div className="divider" />
              <div className="metric-text">{item.name}</div>
            </div>
          ))}
        </div>
      );
    }
    return (
      <>
        {rows}
      </>
    )
  }

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setReasonForReinstatement(inputValue);
    setIsInputEmpty(inputValue.trim() === '');
  };

  const patientViewColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Patient review',
      dataIndex: 'review',
      key: 'review',
    },
  ];
  const patientViewDataSource = theDashboardMetricsWarningData.patient_view_last_two_days
    ? theDashboardMetricsWarningData.patient_view_last_two_days.map((item, index) => ({
      key: index,
      date: moment(item.date * 1000).format('MM/DD/YYYY'),
      review: item.value,
    }))
    : [];

  const urgentRefillColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Urgent refill',
      dataIndex: 'review',
      key: 'review',
    },
  ];

  const urgentRefillDataSource = theDashboardMetricsWarningData.urgent_refill_last_two_days
    ? theDashboardMetricsWarningData.urgent_refill_last_two_days.map((item, index) => ({
      key: index,
      date: moment(item.date * 1000).format('MM/DD/YYYY'),
      review: item.value,
    }))
    : [];

  function gotoDashboardPage() {
    history.push(viewAllUrl);
  }

  return (
    <div className="section-container-metrics-no-border">
      {isHome && (
        <>
          <div className="section-header">
            <div className="section-header-title">Warning Metrics</div>
            {viewAllUrl && <a type="primary" size="small" className="ant-btn ant-btn-primary ant-btn-sm" onClick={gotoDashboardPage}>View All</a>}
          </div>
          {warningContent && <div className="warning-box">
            {/* {warningContent} */}
            <p dangerouslySetInnerHTML={{ __html: warningContent }}></p>
            {showApply && <Button className="apply-btn" onClick={handleApplyClick}>Apply for reinstatement</Button>}
            {showApplyDisabled && <Button className="apply-btn" disabled>Apply for reinstatement</Button>}
          </div>}
          <div className="metrics-box">
            {theDashboardMetricsWarningData.rating_star_is_warning && (<div key="patient_view_warning" className="metric-item-column">
              <div className="metric-number">{theDashboardMetricsWarningData.rating_star}</div>
              {/* <div className="divider" /> */}
              <div className="metric-text" style={{ display: 'inline-block' }}>Patient review(yesterday)</div>
              {/* {(theDashboardMetricsWarningData.show_patient_view_last_two_days && !isHome) && (<div className="metric-tips-text">Your patient review has been consistently low for{' '}
                <Popover content={<Table columns={patientViewColumns} dataSource={patientViewDataSource} pagination={false} />} placement="top" trigger="click">
                  <a style={{ color: '#EC5C55' }}>two days</a>
                </Popover>
                .
              </div>)} */}
            </div>
            )}
            {theDashboardMetricsWarningData.provider_no_show_is_warning && (<div key="provider_no_show" className="metric-item-column">
              <div className="metric-number">{theDashboardMetricsWarningData.provider_no_show}</div>
              {/* <div className="divider" /> */}
              <div className="metric-text">Provider no-show</div>
              {/* {(theDashboardMetricsWarningData.show_urgent_refill_last_two_days && !isHome) && (<div className="metric-tips-text">You have refill requests pending for more than 24 hours for{' '}
                <Popover content={<Table columns={urgentRefillColumns} dataSource={urgentRefillDataSource} pagination={false} />} placement="top" trigger="click">
                  <a style={{ color: '#EC5C55' }}>two days</a>
                </Popover>
                .
              </div>)} */}
            </div>
            )}
            {theDashboardMetricsWarningData.ugrent_refill_is_warning && flagShowUrgentRefill && (<div key="urgent_refill_warning" className="metric-item-column">
              <div className="metric-number">{theDashboardMetricsWarningData.ugrent_refill}</div>
              {/* <div className="divider" /> */}
              <div className="metric-text">Urgent refill({'>'}24 hrs)</div>
              {/* {(theDashboardMetricsWarningData.show_urgent_refill_last_two_days && !isHome) && (<div className="metric-tips-text">You have refill requests pending for more than 24 hours for{' '}
                <Popover content={<Table columns={urgentRefillColumns} dataSource={urgentRefillDataSource} pagination={false} />} placement="top" trigger="click">
                  <a style={{ color: '#EC5C55' }}>two days</a>
                </Popover>
                .
              </div>)} */}
            </div>
            )}

          </div>
        </>
      ) || (
          <>
            {warningContent && <div className="warning-box">
              {/* {warningContent} */}
              <p dangerouslySetInnerHTML={{ __html: warningContent }}></p>
              {showApply && <Button className="apply-btn" onClick={handleApplyClick} disabled={showApplyDisabled} >Apply for reinstatement</Button>}
              {showApplyDisabled && <Button className="apply-btn" disabled>Apply for reinstatement</Button>}
            </div>}
          </>
        )}
      
      <Modal
        title="Apply for reinstatement"
        visible={isShowForm}
        onCancel={handleFormCancel}
        maskClosable={false}
        footer={[
          // eslint-disable-next-line react/jsx-key
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <a type="primary" size="small" className="view-detail-a" onClick={handleFormCancel}>Cancel</a>
            ,
            <Button key="confirm" type="primary" onClick={handleFormSubmit} disabled={isInputEmpty} >
              Submit
            </Button>
          </div>,
        ]}
      >
        <Input.TextArea
          value={reasonForReinstatement}
          onChange={handleInputChange}
          rows={4}
          placeholder="Please provide reasons here."
        />
      </Modal>
      {isHome ? (
        <Modal
          title="You have been paused from accepting new patients"
          width={600}
          visible={isModalVisible}
          onCancel={handleModalCancel}
          maskClosable={false}
          footer={[
            // eslint-disable-next-line react/jsx-key
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <a type="primary" size="small" className="view-detail-a" href={viewAllUrl}>
                View details
              </a>
              <Button key="confirm" type="primary" onClick={handleModalOk}>
                Apply for reinstatement
              </Button>
            </div>,
          ]}
        >
          {/* <p>
            {pausePopupContent}
          </p> */}
          <p dangerouslySetInnerHTML={{ __html: pausePopupContent }}></p>
        </Modal>
      ) : null}
    </div>
  );
};

export default HomeWarningMetrics;