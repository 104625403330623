import React, { useState } from 'react';
import { Modal, Button, message, Input, Checkbox } from 'antd';
import cn from 'classnames/bind';

import { useRequest } from 'ahooks';

import {
  srvSignDoctorAgreement,
} from '@/services/account';

import styles from './index.less';
const cx = cn.bind(styles);

const DoctorAgreementModal = (props) => {
  const { onOk, visible, doctorAgreement } = props;
  const [showReject, setShowReject] = useState(false)
  const [rejectReason, setRejectReason] = useState('')
  const [checked, setChecked] = useState(false)

  const signReq = useRequest(srvSignDoctorAgreement, {
    manual: true,
    onSuccess: (ret) => {
      if (ret.success) {
        onOk()
      }
    },
  });

  const agree = () => {
    signReq.run({
      doctor_agreement_id: doctorAgreement.id,
      response_type: 1
    })
  }

  const reject = () => {
    if(rejectReason == '') {
      message.error('Please enter reject reason')
    } else {
      signReq.run({
        doctor_agreement_id: doctorAgreement.id,
        response_type: 2,
        reject_reason: rejectReason
      })  
    }
  }

  const onChange = ()=>{
    setChecked(!checked)
  }

  return (
    <Modal
      title={false}
      visible={visible}
      closable={false}
      footer={false}
      className={cx({ doctorAgreementModal: true })}
    >
      { !showReject &&
        <>
          <h3 className={cx({ doctorAgreementTitle: true })}>{doctorAgreement.title}</h3>
          <div className={cx({ doctorAgreementContent: true })}  dangerouslySetInnerHTML={{ __html: doctorAgreement.content }} />
          <div style={{marginTop: '20px'}}>
            <Checkbox checked={checked} onChange={onChange}>
              I have read and agree the delegation agreement
            </Checkbox>
          </div>
          <div style={{marginTop: '20px', display: 'flex', justifyContent: 'right'}}>
            <Button key="cancel" type="text" onClick={()=>{
              setShowReject(true)
            }}>Reject</Button>
            <Button key="confirm" type="default" disabled={!checked} onClick={agree}>I Agree</Button>
          </div>
        </>
      }
      {
        showReject &&
        <>
          <h3 className={cx({ doctorAgreementTitle: true })}>Reject Reason</h3>
          <p style={{marginBottom: '20px'}}>Could you please share the reason for your refusal? We value your input and want to address any concerns you may have.</p>
          <Input.TextArea rows={4} onChange={(event)=>{ setRejectReason(event.target.value) }} />
          <div style={{marginTop: '20px', display: 'flex', justifyContent: 'right'}}>
            <Button key="cancel" type="text" onClick={()=>{
              setShowReject(false)
            }}>Back</Button>
            <Button key="confirm" type="default" onClick={reject}>Submit</Button>
          </div>
        </>
      }
    </Modal>
  );
};

export default DoctorAgreementModal;
