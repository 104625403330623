export const otherReason = [
  { label: 'Seeking medication for inappropriate use', value: 'd' },
  { label: 'Requires a higher level of care and will refer to a local hospital', value: 'e' },
  { label: 'Refused my recommended treatment', value: 'f' },
];

export const completedOpts = [
  { value: 'F90.0 Predominantly Inattentive ADHD' },
  { value: 'F90.1 Predominantly Hyperactive ADHD' },
  { value: 'F90.2 Combined ADHD' },
  { value: 'F90.9 ADHD (Unspecified)' },
  { value: 'F31 Bipolar Disorder' },
  { value: 'Other' },
];

export const initialCompletedOpts = [
  { value: 'F90.0 ADHD, Predominantly Inattentive Type' },
  { value: 'F90.1 ADHD, Predominantly Hyperactive Type' },
  { value: 'F90.2 ADHD, Combined Type' },
  { value: 'F90.9 ADHD, Unspecified' },
  { value: 'F29 Unspecified Psychosis' },
  { value: 'F31 Bipolar Disorder' },
  { value: 'F33.0 MDD, Recurrent, Mild' },
  { value: 'F33.1 MDD, Recurrent, Moderate' },
  { value: 'F33.9 MDD, Recurrent, Unspecified' },
  { value: 'F32.9 MDD, Bipolar Disorder, Unspecified' },
  { value: 'F39 Unspecified Mood (Affective) Disorder' },
  { value: 'F41.0 Panic Disorder' },
  { value: 'F41.1 Generalized Anxiety Disorder' },
  { value: 'F41.9 Anxiety Disorder, Unspecified' },
  { value: 'F43.1 Post-Traumatic Stress Disorder' },
  { value: 'F43.2 Adjustment Disorder' },
  { value: 'F10.2 Alcohol Dependence' },
  { value: 'F11.2 Opioid Dependence' },
  { value: 'F15.9 Other Stimulant Use, Unspecified' },
  { value: 'F19.1 Other Psychoactive Substance Abuse Disorder' },
  { value: 'G47.0 Insomnia, Unspecified' },
  { value: 'Other (please specify below)' },
];

export const noDiagnoseReason = [
  { label: 'No behavioral health issue', value: 'a' },
  { label: 'Complex and require leadership review (will notify care team) ', value: 'b' },
  { label: 'Other reason', value: 'c' },
];

export const seenPatientOptions = [
  { label: 'I didn’t see the patient in this appointment', value: 'N' },
  { label: 'I have seen the patient in this appointment', value: 'Y' },
];

export function getRealReason(values) {
  var reason = '';
  if (values.no_diagnose_reason !== undefined) {
    switch (values.no_diagnose_reason) {
      case 'a':
        reason = 'No behavioral health issue';
        break;
      case 'b':
        reason = 'Complex and require leadership review';
        break;
      case 'c':
        reason = 'Other reason:\n';
        const no_diagnose_more_reason = values.no_diagnose_more_reason;
        for (let i = 0; i < no_diagnose_more_reason.length; i++) {
          switch (no_diagnose_more_reason[i]) {
            case 'd':
              reason = reason + 'Seeking medication for inappropriate use\n';
              break;
            case 'e':
              reason =
                reason + 'Requires a higher level of care and will refer to a local hospital\n';
              break;
            case 'f':
              reason = reason + 'Refused my recommended treatment\n';
              break;
          }
        }
    }
  }
  return reason;
}
