import React, { useState, useEffect } from 'react';
import {useMount, useRequest} from 'ahooks';
import moment from "moment";

import { Row, Col, Select, Form, Radio, Drawer, Button, Collapse, DatePicker, InputNumber, Spin, message, Input, Checkbox } from 'antd';
import { timeZones } from '@/utils/xtime';

const { Panel } = Collapse;

import { srvTherapyRecurringCalendarAvailability, srvGetPatientTherapyRecurringAppointmentOption } from '@/services/account';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

const ScheduleTherapyAppointment = (props) => {
  const { form, providerProfile, patient, setTmpAppointedAt, nextAppointmentOption } = props

  const [calendar, setCalendar] = useState({});
  const [appointedAt, setAppointedAt] = useState(0);
  const [appointmentTypes, setAppointmentTypes] = useState([
    { id: 15, name: 'ADHD Therapy Session 45 Min' }
  ])
  const [isRecurring, setIsRecurring] = useState(form.getFieldValue('is_recurring'))

  const onValuesChange = () => {

  }

  const appointmentCalendarReq = useRequest(srvTherapyRecurringCalendarAvailability, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setCalendar(ret.data);
      }
    },
  });

  const initCalendar = () => {
    appointmentCalendarReq.run({
      doctor_id: providerProfile.doctor_id,
      appointment_type: form.getFieldValue('appointment_type'),
      timezone: providerProfile.timezone,
      days: 4,
      date: moment().format('YYYYMMDD'),
      dir: 'next',
    });
  };

  const updateCalendar = (date) => {
    var startDate
    if(date > 100) {
      startDate = date
    } else {
      startDate = moment().tz(form.getFieldValue('timezone')).format('YYYYMMDD')
    }
    appointmentCalendarReq.run({
      doctor_id: providerProfile.doctor_id,
      appointment_type: form.getFieldValue('appointment_type'),
      timezone: form.getFieldValue('timezone'),
      days: 4,
      date: startDate,
      dir: 'next',
    });
  }

  const fetchDates = (dir) => {
    setAppointedAt(0);
    appointmentCalendarReq.run({
      doctor_id: providerProfile.doctor_id,
      appointment_type: form.getFieldValue('appointment_type'), // follow up
      timezone: form.getFieldValue('timezone'),
      days: 4,
      date: dir == 'next' ? calendar.end_date : calendar.start_date,
      dir: dir,
    });
  };

  const chooseTime = (x) => {
    if (x < (moment().unix() + 60 * 60 * 24 * 2)) {
      message.error('You can only book for appointment at least 48 hrs in advance. please choose an alternative time.')
      return
    }

    setAppointedAt(x);
    form.setFieldsValue({appointed_at: x})
    setTmpAppointedAt(x)
  }

  useEffect(()=>{
    if(nextAppointmentOption && nextAppointmentOption.appointment_types && nextAppointmentOption.appointment_types.length > 0) {
      setAppointmentTypes(nextAppointmentOption.appointment_types)
      if(nextAppointmentOption.is_inital) {
        updateCalendar(nextAppointmentOption.start_date)
      }
      form.setFieldsValue({appointment_type_name: nextAppointmentOption.appointment_types[0].name})
      form.setFieldsValue({appointment_type: nextAppointmentOption.appointment_types[0].id})
    }
  }, [nextAppointmentOption])

  useMount(() => {
    form.setFieldsValue({timezone: providerProfile.timezone})
    initCalendar();
  })

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{ appointment_type: 15, timezone: 'America/Los_Angeles', recurring_type: 1 }}
      onValuesChange={onValuesChange}
    >
      <Form.Item hidden name="appointment_type_name">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item hidden name="appointed_at">
        <Input type="hidden" />
      </Form.Item>
      <div className={cx({appointmentTypeAndTimezone: true })}>
        <Row style={{ marginBottom: '10px' }}>
          <Col span={6}>Appointment Type:</Col>
          <Col span={18}>
            <Form.Item name="appointment_type" rules={[{ required: true }]}>
              <Select
                style={{ width: '100%' }}
                onChange={updateCalendar}
              >
                {appointmentTypes.map((v) => (
                  <Select.Option value={v.id} key={v.id}>
                    {v.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col span={6}>Time Zone:</Col>
          <Col span={18}>
            <Form.Item name="timezone" rules={[{ required: true }]}>
              <Select
                style={{ width: '100%' }}
                onChange={updateCalendar}
              >
                {timeZones.map((v) => {
                  return (
                    <Select.Option key={v[0]} value={v[0]}>
                      {v[1]}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item name="is_recurring" valuePropName="checked" value={isRecurring} onChange={(e)=>{ setIsRecurring(e.target.checked)}}>
              <Checkbox>
                <span>
                  Make this appointment recurring
                </span>
              </Checkbox>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="recurring_type" rules={[{ required: true }]}>
              <Select
                name="timezone"
                style={{ width: '100%' }}
                disabled={!isRecurring}
              >
                <Select.Option key={1} value={1}>
                  Weekly
                </Select.Option>
                <Select.Option key={2} value={2}>
                  Bi-Weekly
                </Select.Option>
              </Select>
            </Form.Item>          
          </Col>
          <Col>
            <div style={{paddingTop: '5px'}}> &nbsp; in the next month.</div>
          </Col>
        </Row>
      </div>      

      {calendar.start_date && (
        <div className={cx({ calendar: true })}>
          <div className={cx({ calendarOp: true })}>
            <Button
              disabled={!calendar.has_prev}
              onClick={() => {
                fetchDates('prev');
              }}
            >
              Prev
            </Button>
            <Button
              disabled={!calendar.has_next}
              onClick={() => {
                fetchDates('next');
              }}
            >
              Next
            </Button>
          </div>
          <div className={cx({ calendarDates: true })}>
            {calendar.dates.map((v) => {
              const timesDom =
                v.times &&
                v.times.map((x) => {
                  return (
                    <div className={cx({ timeSlot: true })} key={x}>
                      <Button
                        size="small"
                        onClick={() => {
                          chooseTime(x)
                        }}
                        type={appointedAt > 0 && appointedAt === x ? 'danger' : 'default'}
                      >
                        {moment.tz(x * 1000, form.getFieldValue('timezone')).format('hh:mm A')}
                      </Button>
                    </div>
                  );
                });
              return (
                <div className={cx({ dateColumn: true })} key={v.date}>
                  <div className={cx({ dateSlot: true })}>
                    {moment.tz(v.date, 'YYYYMMDD', form.getFieldValue('timezone')).format('MMM D')}
                  </div>
                  <div className={cx({ dateSlot: true })}>
                    {moment.tz(v.date, 'YYYYMMDD', form.getFieldValue('timezone')).format('ddd')}
                  </div>
                  {timesDom}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Form>
  )
}

export default ScheduleTherapyAppointment;