import React, { useEffect, useState } from 'react';
import { connect, history, Link } from 'umi';
import { Button, Tooltip, Space, List, Popover } from 'antd';
import { useRequest, useMount } from 'ahooks';
import orderBy from 'lodash/orderBy';
import { formatUnix } from '@/utils/xtime.js';
import { getPatientName } from '@/utils/utils';
import { ProviderDashboardSection, PatientLink } from './index';
import DataSorter from '@/components/DataSorter';
import ProviderAcceptAppointmentRequestModal from '@/components/ProviderAcceptAppointmentRequestModal';
import {
  srvDoctorAppointmentRequests,
  srvDoctorAcceptAppointmentRequest,
} from '@/services/patient';
import './index.css';

const AppointmentRequestItem = (props) => {
  const { appointmentRequest, setRequest, setAcceptVisible } = props;

  const renderPatientPreferredTimes = (r) => {
    if(!r.patient_preferred_times) {
      return ''
    }
    let appts = [];
    const times = JSON.parse(r.patient_preferred_times);
    for (const v of times) {
      appts.push(`${formatUnix(v.start_time, 'MM/DD/YYYY h:mm A')} - ${formatUnix(v.end_time, 'h:mm A')}`);
    }
    return <div dangerouslySetInnerHTML={{ __html: appts.join('<br/>') }} />;
  }

  const renderActions =  (r) => {
    return (
      <Button
        size="small"
        type="primary"
        disabled={r.status === 2}
        onClick={() => {
          setRequest(r);
          setAcceptVisible(true);
        }}
      >
        {r.status === 2 ? 'Accepted' : 'Accept'}
      </Button>
    );
  }

  return (
    <div className="detail-table-item">
      <div className="detail-appointment-date">
        {formatUnix(appointmentRequest.requested_at, 'MM/DD/YYYY h:mm A')}
      </div>
      <div className="detail-patient-name">
        {getPatientName(appointmentRequest)}
      </div>
      <div className="detail-patient-status">
        {appointmentRequest.patient_state}
      </div>
      <div className="detail-patient-preferred-times">
        {renderPatientPreferredTimes(appointmentRequest)}
      </div>
      <div className="detail-action">
        {renderActions(appointmentRequest)}
      </div>
    </div>
  );
};

const AppointmentRequestList = (props) => {
  const { appointmentRequestList, onRefresh, sorter, setSorter } = props;

  const [acceptVisible, setAcceptVisible] = useState(false);
  const [request, setRequest] = useState({});

  const acceptAppointmentRequestReq = useRequest(srvDoctorAcceptAppointmentRequest, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (onRefresh) {
          onRefresh()
        }
        setAcceptVisible(false);
      }
    },
    onError: (err, params) => {
      if (onRefresh) {
        onRefresh()
      }
      setAcceptVisible(false);
    }
  });

  const renderItem = (appointmentRequest) => {
    return (
      <AppointmentRequestItem
        key={appointmentRequest.id}
        appointmentRequest={appointmentRequest}
        setRequest={setRequest}
        setAcceptVisible={setAcceptVisible}
      />
    )
  };

  return (
    <>
      <List
        itemLayout="vertical"
        style={{overflowX: 'auto',overflowY:'hidden'}}
        pagination={appointmentRequestList.length > 0 ? { pageSize: 10 } : false}
        locale={{ emptyText: ' ' }}
        dataSource={appointmentRequestList}
        header={
          <div className="detail-table-header">
            <div className="detail-appointment-date">
              <DataSorter title="Request Time" sorter={sorter} setSorter={setSorter} />
            </div>
            <div className="detail-patient-name">Patient Name</div>
            <div className="detail-patient-status">State</div>
            <div className="detail-patient-preferred-times">Patients’ Preferred Times</div>
            <div className="detail-action">Actions</div>
          </div>
        }
        renderItem={renderItem}
      />

      {acceptVisible && (
        <ProviderAcceptAppointmentRequestModal
          visible={acceptVisible}
          request={request}
          onCancel={() => {
            setAcceptVisible(false);
          }}
          onOk={(values) => {
            acceptAppointmentRequestReq.run({ ...values });
          }}
        />
      )}
    </>
  );
};

const AppointmentRequest = (props) => {
  const { account, dispatch, loading } = props;
  const [appointmentRequestList, setAppointmentRequestList] = useState({});
  const [sorter, setSorter] = useState(null);

  const refresh = () => {
    const cond = {};
    if (account && account?.currentUser) {
      cond.key = (account.currentUser.doctor_type == 3||account.currentUser.doctor_type == 5) ? 2 : 1;
    }
    if (sorter) {
      cond.sort = sorter === "asc" ? -1 : 1;
    }
    srvDoctorAppointmentRequests(cond).then((res) => {
      if (res.data !== null) {
        setAppointmentRequestList(res.data);
      }
    });
  };

  useEffect(() => {
    refresh();
  }, [sorter]);
  return (
    <ProviderDashboardSection
      sectionName="Initial Appointment Requests"
      itemNumber={appointmentRequestList.total}
    >
      <AppointmentRequestList
        sorter={sorter}
        setSorter={setSorter}
        onRefresh={refresh}
        appointmentRequestList={appointmentRequestList.items || []}
      />
    </ProviderDashboardSection>
  );
};

// export default AppointmentRequest;
export default connect(({ account, loading }) => ({
  account,
  loading,
}))(AppointmentRequest);
