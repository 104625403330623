import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox, Space } from 'antd';

import { useMount, useUpdateEffect, useSetState } from 'ahooks';
import CheckboxTextInputFormItem from './CheckboxTextInputFormItem';

const MSEFormItems = (props) => {
  const { form, draft, setDraft, } = props;

  return (
    <>
      <h4 style={{ marginBottom: '15px' }}>General</h4>
      <CheckboxTextInputFormItem name="mse_ao3" label="A&O x 3 or" form={form} draft={draft} setDraft={setDraft} />
      <CheckboxTextInputFormItem name="mse_pleasant_cooperative" label="Pleasant and cooperative or" form={form} draft={draft} setDraft={setDraft} />
      <CheckboxTextInputFormItem name="mse_healthy_groomed_hygiene" label="Appears healthy, well groomed with good hygiene or" form={form} draft={draft} setDraft={setDraft} />
      <CheckboxTextInputFormItem name="mse_no_psychomotor_nomalies" label="No psychomotor anomalies noted or" form={form} draft={draft} setDraft={setDraft} />

      <h4 style={{ marginBottom: '15px' }}>Speech</h4>
      <CheckboxTextInputFormItem name="mse_speech_volume_prosody" label="Speech normal rate, volume and prosody or" form={form} draft={draft} setDraft={setDraft} />

      <h4 style={{ marginBottom: '15px' }}>Mood and Affect</h4>
      <CheckboxTextInputFormItem name="mse_mood_patient_words" label="Mood(patient’s own words)" form={form} draft={draft} setDraft={setDraft} />
      <CheckboxTextInputFormItem name="mse_affect" label="Affect" form={form} draft={draft} setDraft={setDraft} />

      <h4 style={{ marginBottom: '15px' }}>Thought Process</h4>
      <CheckboxTextInputFormItem name="mse_goal_directed_coherent" label="TP: linear, goal directed and coherent or" form={form} draft={draft} setDraft={setDraft} />
      <CheckboxTextInputFormItem name="mse_memory_cognition_intact" label="Memory and cognition appear grossly intact or" form={form} draft={draft} setDraft={setDraft} />

      <h4 style={{ marginBottom: '15px' }}>Thought Content</h4>
      <CheckboxTextInputFormItem name="mse_no_shav_delusions" label="TC: No SI/HI/AH/VH/delusions or" form={form} draft={draft} setDraft={setDraft} />

      <h4 style={{ marginBottom: '15px' }}>Insight and Judgment</h4>
      <CheckboxTextInputFormItem name="mse_insight_judgment_intact" label="Insight and judgment: intact or" form={form} draft={draft} setDraft={setDraft} />
    </>
  )
};

export default MSEFormItems;
