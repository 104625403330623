import React, { useState, useEffect, useRef } from 'react';
import { message, Row, Input, Button } from 'antd';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import { FormOutlined } from '@ant-design/icons';
import { useRequest, useUpdateEffect } from 'ahooks';
import EmailValidator from 'email-validator';
import copy from 'copy-to-clipboard';

import { srvImpersonateDoctorLogin } from '@/services/admin';
import styles from './index.less';
const cx = cn.bind(styles);

const ImpersonateLogin = (props) => {
  const [data, setData] = useState({});
  const [email, setEmail] = useState('');
  const inputEl = useRef(null);

  const impersonateLoginReq = useRequest(srvImpersonateDoctorLogin, {
    manual: true,
    onSuccess: (ret, params) => {
      setData(ret.data);
    },
  });

  const getLink = () => {
    // const emailVal = inputEl.current.state.value;
    if (!EmailValidator.validate(email)) {
      message.error('invalid email.');
      return;
    }
    impersonateLoginReq.run({ email });
  };

  const link = () => {
    if (data && data.user_id) {
      switch (data.env) {
        case 'prod':
          return `${MINDFUL_PROD_EHR_URL}/api/oauth/impersonate?token=${data.access_token}&expires_in=${data.expires_in}`;
        case 'uat':
          return `${MINDFUL_UAT_EHR_URL}/api/oauth/impersonate?token=${data.access_token}&expires_in=${data.expires_in}`;
        case 'dev':
          return `${DEV_EHR_URL}/api/oauth/impersonate?token=${data.access_token}&expires_in=${data.expires_in}`;
        default:
          return '';
      }
    }

    return '';
  };

  const copyLink = () => {
    const linStr = link();

    copy(linStr, {
      debug: true,
      message: 'copied',
      format: 'text/plain',
    });

    message.info('Copied');
  };

  return (
    <div className={cx({ container: true })}>
      <div className={cx({ header: true })}>
        <h3>Impersonate Doctor</h3>
      </div>
      <div className={cx({ mb10: true })}>
        Enter the clinician’s email below to get a link that can login to the clinician’s dashboard.
      </div>
      <div className={cx({ mb10: true })}>
        <Input ref={inputEl} placeholder="Clinician's email" onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className={cx({ mb10: true })}>
        <Button type="primary" onClick={getLink} loading={impersonateLoginReq.loading}>
          Generate Link
        </Button>
      </div>
      {data && data.user_id && (
        <>
          <div className={cx({ mb10: true })}>
            Please enter the URL in your browser's incognito mode to impersonate a clinician's login
            view and close the window immediately you are finished.
          </div>

          <div className={cx({ mb10: true, linkBox: true })}>{link()}</div>

          <div className={cx({ mb10: true })}>
            <Button type="primary" onClick={copyLink}>
              Copy URL
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
export default ImpersonateLogin;
