import React, { useState, useRef } from 'react';
import { Button, Spin, Drawer, Slider, Input } from 'antd';
import cn from 'classnames/bind';
import numeral from 'numeral';
import { AsYouType } from 'libphonenumber-js';
import { useRequest, useMount, useUpdate, useUpdateEffect, useSetState } from 'ahooks';
import zippo from 'zippo';

import { srvRXNTPharmacyList } from '@/services/admin';
import styles from './Blacklist.less';
const cx = cn.bind(styles);

const marks = {
  5: '5 mi',
  10: '10 mi',
  25: '25 mi',
  50: '50 mi',
};

const maxItems = '1000';

const AddPharmacyBlacklistDrawer = (props) => {
  const { visible } = props;
  const onDrawerClose = () => {
    props.onClose && props.onClose();
  };
  const zipcodeRef = useRef(null);
  const filterRef = useRef(null)
  const nameRef = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const addressRef = useRef(null);
  const phoneRef = useRef(null);
  const faxRef = useRef(null);
  const refuseReasonRef = useRef(null);
  const [selectedPharmacies, setSelectedPharmacies] = useState({});
  const [RXNTPharmacy, setRXNTPharmacy] = useState({
    totalPharmacies: 0,
    pharmacies: [],
  });
  const [data, setData] = useSetState({
    refuseReason: '',
    name: '',
    zipcode: '',
    city: '',
    state: '',
    address: '',
    phone: '',
    filter: '',
    distance: 5,
  });

  const forceUpdate = useUpdate();

  const listReq = useRequest(srvRXNTPharmacyList, {
    debounceWait: 500,
    manual: true,
    onSuccess: (ret, params) => {
      setRXNTPharmacy(ret.data);
      setSelectedPharmacies({});
    },
  });

  const onFinish = () => {
    if (selectedPharmacies && Object.keys(selectedPharmacies).length > 0) {
      const values = { refuse_reason: data.refuseReason, pharmacies: Object.values(selectedPharmacies) };
      props.onFinish && props.onFinish(values, () => {
        setData({
          refuseReason: '',
          name: '',
          zipcode: '',
          city: '',
          state: '',
          address: '',
          phone: '',
          filter: '',
          distance: 5,
        });
        setSelectedPharmacies({});
        setRXNTPharmacy({
          totalPharmacies: 0,
          pharmacies: [],
        })
        forceUpdate();
      });
    }
  };

  const onSelect = (pharmacy) => {
    const key = `P${pharmacy.id}`
    if (selectedPharmacies[key]) {
      delete selectedPharmacies[key];
    } else {
      selectedPharmacies[key] = pharmacy;
    }
    setSelectedPharmacies(selectedPharmacies);
    forceUpdate();
  }

  const loadData = () => {
    const cond = {
      zip: data.zipcode,
      name: data.name,
      city: data.city,
      state: data.state,
      address: data.address,
      phone: data.phone,
      fax: data.fax,
      maxItems,
      admin: props.admin,
    };
    let keys = [];
    Object.keys(cond).forEach(key => {
      if (cond[key]) {
        keys.push(key);
      } else {
        delete cond[key];
      }
    })
    if (keys.some(k => ['zip', 'name', 'city', 'state', 'address', 'phone', 'fax'].includes(k))) {
      listReq.run(cond);
    }
  }

  useMount(() => {
    if (data.zipcode) {
      loadData();
    }
  });
  
  useUpdateEffect(() => {
    loadData();
  }, [data.name, data.city, data.state, data.address, data.phone, data.fax])

  let pharmacies = RXNTPharmacy.pharmacies;

  if (data.filter.trim().length > 0) {
    pharmacies = pharmacies.filter(v => {
      return v.city.toLowerCase().indexOf(data.filter.toLowerCase()) !== -1 ||
        v.address1.toLowerCase().indexOf(data.filter.toLowerCase()) !== -1 ||
        v.address2.toLowerCase().indexOf(data.filter.toLowerCase()) !== -1 ||
        v.name.toLowerCase().indexOf(data.filter.toLowerCase()) !== -1 ||
        v.ncpdp.toLowerCase().indexOf(data.filter.toLowerCase()) !== -1
    })
  }


  const listItemsDom = pharmacies.map((v) => {
    const val = numeral(v.distance).format('0.00');
    return (
      <div
        key={v.id}
        className={cx({ item: true, rowSelected: selectedPharmacies && Object.keys(selectedPharmacies).includes(`P${v.id}`) })}
        onClick={() => { onSelect(v); }}
      >
        <div className={cx({ itemLeft: true })}>
          <div className={cx({ itemTitle: true })}>{v.name}</div>
          <div className={cx({ itemEntity: true })}>
            <span>ID: </span>
            {v.ncpdp}
          </div>
          <div className={cx({ itemEntity: true })}>{v.address1}</div>
          <div className={cx({ itemEntity: true })}>
            {v.city}, {v.state}, {v.zipcode}
          </div>
        </div>
        <div className={cx({ itemRight: true })}>
          <div className={cx({ itemEntity: true, isHide: true })}>
            <span>Distance:</span>
            {val} mi
          </div>
          <div className={cx({ itemEntity: true })}>{new AsYouType('US').input(v.phone)}</div>
          <div className={cx({ itemEntity: true })}>{new AsYouType('US').input(v.fax)}</div>
        </div>
      </div>
    );
  });

  return (
    <Drawer
      className={cx({ addPharmacyBlacklistDrawer: true })}
      title={
        <div className={cx({ title: true })}>
          <h3>Add a new Pharmacy Blacklist</h3>
          {selectedPharmacies && Object.keys(selectedPharmacies).length > 0 && (
            <Button type="primary" loading={listReq.loading} onClick={onFinish}>
              Confirm
            </Button>
          )}
        </div>
      }
      width="600"
      placement="right"
      closable={false}
      onClose={onDrawerClose}
      visible={visible}
      destroyOnClose={true}
    >
      <Spin tip="Loading..." spinning={listReq.loading}>
        <div className={cx({ search: true })}>
          <div className={cx({ fieldsGroupFull: true })}>
            <div className={cx({ fieldsTitle: true })}>Refuse Reason:</div>
            <Input.TextArea
              ref={refuseReasonRef}
              placerhoder="Refuse Reason"
              value={data.refuseReason}
              onChange={(e) => {
                setData({ refuseReason: e.target.value });
              }}
            />
          </div>
        </div>
        <div className={cx({ search: true })}>
          <div className={cx({ fieldsGroupFull: true })}>
            <div className={cx({ fieldsTitle: true })}>Name:</div>
            <Input
              ref={nameRef}
              placerhoder="Name"
              value={data.name}
              onChange={(e) => {
                setData({ name: e.target.value });
              }}
            />
          </div>
        </div>
        <div className={cx({ search: true })}>
          <div className={cx({ fieldsGroupFull: true })}>
            <div className={cx({ fieldsTitle: true })}>Zip code:</div>
            <Input
              ref={zipcodeRef}
              placerhoder="Zipcode"
              value={data.zipcode}
              onBlur={() => {
                loadData();
              }}
              onChange={(e) => {
                setData({ zipcode: e.target.value });
                if (zippo.validate(e.target.value)) {
                  loadData();
                }
              }}
            />
          </div>

          <div className={cx({ fieldsGroup: true, isHide: true })}>
            <div className={cx({ fieldsTitle: true, })}>Search within...</div>
            <Slider
              min={5}
              max={50}
              step={null}
              value={data.distance}
              marks={marks}
              defaultValue={5}
              onChange={(val) => {
                setData({ distance: e.target.value });
                if (zippo.validate(zipcode)) {
                  listReq.run({
                    zip: zipcode,
                    radius: val,
                    address,
                    maxItems,
                    admin: props.admin,
                  });
                }
              }}
            />
          </div>
        </div>

        <div className={cx({ search: true })}>
          <div className={cx({ fieldsGroup: true })}>
            <div className={cx({ fieldsTitle: true })}>City:</div>
            <Input
              ref={cityRef}
              placerhoder="City"
              value={data.city}
              onChange={(e) => {
                setData({ city: e.target.value });
              }}
            />
          </div>

          <div className={cx({ fieldsGroup: true, })}>
            <div className={cx({ fieldsTitle: true, })}>State:</div>
            <Input
              ref={stateRef}
              placerhoder="State"
              value={data.state}
              onChange={(e) => {
                setData({ state: e.target.value });
              }}
            />
          </div>
        </div>

        <div className={cx({ search: true })}>
          <div className={cx({ fieldsGroupFull: true })}>
            <div className={cx({ fieldsTitle: true })}>Address:</div>
            <Input
              ref={addressRef}
              placerhoder="Address"
              value={data.address}
              onChange={(e) => {
                setData({ address: e.target.value });
              }}
            />
          </div>
        </div>

        <div className={cx({ search: true })}>
          <div className={cx({ fieldsGroup: true })}>
            <div className={cx({ fieldsTitle: true })}>Phone:</div>
            <Input
              ref={phoneRef}
              placerhoder="Phone"
              value={data.phone}
              onChange={(e) => {
                setData({ phone: e.target.value });
              }}
            />
          </div>

          <div className={cx({ fieldsGroup: true, })}>
            <div className={cx({ fieldsTitle: true, })}>Fax:</div>
            <Input
              ref={faxRef}
              placerhoder="Fax"
              value={data.fax}
              onChange={(e) => {
                setData({ fax: e.target.value });
              }}
            />
          </div>
        </div>

        <div className={cx({ search: true })}>
          <div className={cx({ fieldsGroupFull: true })}>
            <div className={cx({ fieldsTitle: true })}>Search by pharmacy, city, or street address</div>
            <Input
              ref={filterRef}
              placerhoder="Pharmacy, city, or street address"
              value={data.filter}
              onChange={(e) => {
                setData({ filter: e.target.value });
              }}
            />
          </div>
        </div>
        <div className={cx({ itemContainer: true })}>{listItemsDom}</div>
      </Spin>
    </Drawer>
  );
};
export default AddPharmacyBlacklistDrawer;
