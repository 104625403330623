import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { connect, Link } from 'umi';
import NumberFormat from 'react-number-format';
import cn from 'classnames/bind';
import IdentityValidator from 'email-validator';
import { ReactComponent as MindfulLogo } from '@/assets/mindful-logo.svg';
import { ReactComponent as Logo } from '@/assets/logo.svg';
import styles from './login.less';
const cx = cn.bind(styles);

const Login = (props) => {
  const { dispatch, loading } = props;

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('vertical');
  const [identityDisabled, setIdentityDisabled] = useState(true);
  const [identity, setIdentity] = useState('');
  const [password, setPassword] = useState('');
  const [passwordDisabled, setPasswordDisabled] = useState(true);
  const [valpasswordFormEnabled, setValpasswordFormEnabled] = useState(false);

  const isDone = window.location.href.includes('donefirst.com');

  const onIdentityChanged = (v) => {
    if (v.target.value.trim().length) {
      setIdentity(v.target.value);
      setIdentityDisabled(false);
    } else {
      setIdentity('');
      setIdentityDisabled(true);
    }
  };

  const onPasswordChanged = (v) => {
    if (v.target.value.trim().length) {
      setPassword(v.target.value);
      setPasswordDisabled(false);
    } else {
      setPassword('');
      setPasswordDisabled(true);
    }
  };

  const getVerificationPassword = () => {
    dispatch({
      type: 'login/sendIdentityVerifyPassword',
      payload: {
      },
      callback: () => {
        setValpasswordFormEnabled(true);
      },
    });
  };

  const doLogin = () => {
    if (!identity.trim().length) {
      message.error('Please input your email or phone');
      return;
    }


    if (!password.trim().length) {
      message.error('Please input your password.');
      return;
    }

    dispatch({
      type: 'login/loginByPassword',
      payload: {
        identity,
        password,
      },
      callback: () => {},
    });
  };

  return (
    <div className={styles.loginRoot}>
      <div className={styles.loginHeader}>
        <div className={styles.loginWrap}>
          { isDone ? <Logo className={styles.logo} /> : <MindfulLogo /> }
        </div>
      </div>
      <div className={styles.loginWrap}>
        <div className={styles.loginBox}>
          <div className={styles.heading}>Sign in to your account</div>
          <Form layout={formLayout} form={form}>
            <div className={cx({ phoneForm: true, phoneFormShow: !valpasswordFormEnabled })}>
              <Form.Item
                name="identity"
                label="Identity"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email or phone',
                  },
                ]}
              >
                <Input size="large" onChange={onIdentityChanged} />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password',
                  },
                ]}
              >
                <Input.Password size="large" onChange={onPasswordChanged} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                  disabled={passwordDisabled}
                  onClick={doLogin}
                  loading={loading.effects['login/loginByIdentity']}
                >
                  Sign In
                </Button>
              </Form.Item>
              <div className={cx({ links: true })}>
                <Link to="/user/login">Login by Phone</Link>
              </div>
              <div className={cx({ links: true })}>
                <Link to="/user/email_login">Login by Email</Link>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default connect(({ login, loading }) => ({
  login,
  loading,
}))(Login);
