
import { Drawer, Descriptions, } from 'antd';

import cn from 'classnames/bind';
import styles from './index.less';
const cx = cn.bind(styles);

const PatientDrawers = (props) => {

  const { patientInfo, onIntakeASRSClose, intakeASRSVisible, onIntakeGADClose, intakeGADVisible, onIntakePHQClose, intakePHQVisible } = props;

  const assessmentDom = (type) => {
    if (!patientInfo.assessment) {
      return <div />;
    }

    const data = patientInfo.assessment.data;
    const options = patientInfo.assessment.options;

    const assessmentQuestions = JSON.parse(data);
    const as = JSON.parse(options);
    let answers = {};
    for (let answer of as) {
      if (answer.key == "K0011") {
        const K0011OptionsString = assessmentQuestions.find(question => question.key == "K0011").options;
        const K0011Options = JSON.parse(K0011OptionsString).items;
        const K0011Answer = K0011Options.find(option => option.value === answer.value);
        answers[answer.key] = K0011Answer.label;
      } else {
        answers[answer.key] = answer.value;
      }
    }

    const assessmentAnswers = answers;

    return assessmentQuestions.map((v, i) => {
      if (v.key !== 'K0010') {
        return (
          <Descriptions.Item key={i} label={v.title}>
            {assessmentAnswers[v.key]}
          </Descriptions.Item>
        );
      }
    });
  };

  const intakeDom = (type) => {
    if (!patientInfo.intake_form) {
      return <div />;
    }

    const data = patientInfo.intake_form.data;
    const options = patientInfo.intake_form.options;

    let intakePHQ8Questions = [];
    let intakeGAD7Questions = [];
    let intakeASRSBQuestions = [];

    const allQuestions = JSON.parse(data);
    for (let question of allQuestions) {
      if (question.group === 'DEPRESSION' && question.type !== 'PLAIN') {
        intakePHQ8Questions.push(question);
      }
      if (question.group === 'ANXIETY' && question.type !== 'PLAIN') {
        intakeGAD7Questions.push(question);
      }
      if (question.group === 'ASRSB' && question.type !== 'PLAIN') {
        intakeASRSBQuestions.push(question);
      }
    }

    const as = JSON.parse(options);
    let answers = {};
    for (let answer of as) {
      answers[answer.key] = answer.value;
    }

    const intakeAnswers = answers;
    let intakeQuestions = [];
    if (type === 'PHQ') {
      intakeQuestions = intakePHQ8Questions;
    } else if (type === 'GAD') {
      intakeQuestions = intakeGAD7Questions;
    } else if (type === 'ASRSB') {
      intakeQuestions = intakeASRSBQuestions;
    }

    return intakeQuestions.map((v, i) => {
      return (
        <Descriptions.Item key={i} label={v.title}>
          {intakeAnswers[v.key]}
        </Descriptions.Item>
      );
    });
  };

  return (
    <>
      <Drawer
        className={cx({ assessmentDrawer: true })}
        title="Assessments"
        width="600"
        placement="right"
        closable={false}
        onClose={onIntakeASRSClose}
        visible={intakeASRSVisible}
      >
        <Descriptions title="ASRS-A" layout="vertical" column={1} size="small" bordered={true}>
          {assessmentDom()}
        </Descriptions>
        <br />
        <Descriptions title="ASRS-B" layout="vertical" column={1} size="small" bordered={true}>
          {intakeDom('ASRSB')}
        </Descriptions>
      </Drawer>
      <Drawer
        className={cx({ intakeGADDrawer: true })}
        title="GAD-7"
        width="600"
        placement="right"
        closable={false}
        onClose={onIntakeGADClose}
        visible={intakeGADVisible}
      >
        <Descriptions title="GAD-7" layout="vertical" column={1} size="small" bordered={true}>
          {intakeDom('GAD')}
        </Descriptions>
      </Drawer>
      <Drawer
        className={cx({ intakePHQDrawer: true })}
        title="PHQ-9"
        width="600"
        placement="right"
        closable={false}
        onClose={onIntakePHQClose}
        visible={intakePHQVisible}
      >
        <Descriptions title="PHQ 9" layout="vertical" column={1} size="small" bordered={true}>
          {intakeDom('PHQ')}
        </Descriptions>
      </Drawer>
    </>
  )
}


export default PatientDrawers;
