import React, { useEffect, useState } from 'react';
import { Link, connect, history,useAccess } from 'umi';
import { ReconciliationOutlined } from '@ant-design/icons';
import DoctorChangeLogsDrawer from "@/pages/doctor/DoctorChangeLogsDrawer";

import {
  Select,
  Button,
  Space,
  Drawer,
  Descriptions,
  Spin,
  Form,
  Input,
  DatePicker,
  Switch,
  Tag,
  Alert,
  Row,
  Col,
  Menu,
  Dropdown,
  Divider,
  Popover,
  message,
  Card, Table, Checkbox, Image, Modal,
} from 'antd';
import {
  EditOutlined,
  DownOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  SmileOutlined,
  FrownOutlined,
  MehOutlined, EllipsisOutlined, ExclamationCircleOutlined,
} from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import cn from 'classnames/bind';
import moment from 'moment';
import { AsYouType } from 'libphonenumber-js';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import { useFlag } from '@unleash/proxy-client-react';

import { DescriptionsSkeleton } from '@/components/ProSkeleton';
import UpdateAddressModal from './UpdateAddressModal';
import AssignProvider from './AssignProvider';
import DeletePatientModal from './DeletePatientModal';
import UpdateInsuranceInfoModal from './UpdateInsuranceInfo';
import ConsultationDrawer from '../ConsultationDrawer';
import CheckPDMPPane from '@/components/PDMPPane';
import InternalComments from './InternalComments';

import {
  srvSyncRxnt,
  srvChangePharmacy,
  srvChangeRXNTPharmacy,
  srvChangePharmacyForCa,
  srvPatientDetail,
  srvPatientIdentity,
  srvUploadToken,
  srvViewPatientReferrals,
  srvUploadPatientIdentity,
  srvUploadInsurance,
  srvGetPaitentInsurance,
  srvUploadDocument, srvGetPaitentDocument,
} from '@/services/patient';
import {
  srvUpdatePatientDetail,
  srvPatientDetail as srvAdminPatientDetail,
  srvSetUserAddress,
  srvAddAccountUpgrade,
  srvAdminDeletePatientAccount, srvRejectReferrals, srvApproveReferrals, srvUploadReferral, srvViewReferrals,srvSyncUserInfoToApero, srvAdminAddOrUpdateUserInsuranceCardInfo
} from '@/services/admin';
import PharmacyDrawer from '@/components/PharmacyDrawer';
import CaPharmacyDrawer from '@/components/CaPharmacyDrawer';
import styles from './PatientInfo.less';
import { initIntakeformInfo, findIntakeFormAnswer } from './intakeform.js';
import PatientDocument from './PatientDocument';
import CallReasonModal from './CallReasonModal';
import StudentVerificationModal from './StudentVerificationModal';
import {formatUnix, formatUnixDayOnlyInUTCTZ} from "@/utils/xtime";
import {navigate} from "react-big-calendar/lib/utils/constants";
import Dragger from "antd/es/upload/Dragger";
import request from "umi-request";
import ExportPatientPDF from './ExportPatientPDF'
import { useSelector } from 'umi';
const cx = cn.bind(styles);


const CopyButton = ({ text, value, buttonWidth }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }

  return (
    <Button size="small" style={{ width: buttonWidth }} onClick={copyToClipboard}>
      {copied ? 'Copied!' : text}
    </Button>
  );
};

const PatientInfo = (props) => {
  const { id, active, call } = props;
  const [patientInfo, setPatientInfo] = useState({});
  const [patientIdentity, setPatientIdentity] = useState(false);
  const [referralViewURL, setReferralViewURL] = useState('');
  const [referralID, setReferralID] = useState(0)
  const [examDate, setExamDate] = useState('')
  const [form] = Form.useForm();
  const [rejectionModalShow, setRejectionModalShow] = useState(false);
  const [uploadModalShow, setUploadModalShow] = useState(false);
  const [accountUpgradeModalShow, setAccountUpgradeModalShow] = useState(false);
  const [referralUploadKey, setReferralUploadKey] = useState('');
  const [referralSignedURL, setReferralSignedURL] = useState('');
  const access=useAccess();
  const { currentUser } = useSelector((state) => state.account);
  const [visible, _setVisible] = useState({
    PHQ: false,
    GAD: false,
    ASRSA: false,
    ASRSB: false,
    pediatricAssessment: false,
    pediatricIntakeForm: false,
    pediatricBehaviorAssessment: false,
    pediatricTeacherBehaviorAssessment: false,
    insomniaAssessment: false,
    insomniaIntakeForm: false,
    identity: false,
    patientForm: false,
    pharmacy: false,
    updateShippingAddress: false,
    updateInsuranceInfo: false,
    deletePatient: false,
    callReason: false,
    studentVerification: false,
    assessmentCompare: false,
    diagnosticConsultation: false,
    referralHistory: false,
    rejection: false,
    viewReferral: false,
    upload: false,
    showSyncRxntAlert: false,
    caPharmacy: false,
  });
  const [messagesVisible, setMessagesVisible] = useState(false);
  const [defaultMessage, setDefaultMessage] = useState('');


  const setVisible = (name, value) => {
    _setVisible((prev) => ({ ...prev, [name]: value }));
  };
  const [noteVisible,setNoteVisible]=useState(false);

  const [consultationType, setConsultationType] = useState(false);

  const openConsultation = (type) => {
    getDiagnosticConsultationMessageBody(type)
    setVisible('diagnosticConsultation', true);
  };

  const [selected, _setSelected] = useState({
    phq: 0,
    gad: 0,
    asrsa: 0,
    asrsb: 0,
    pediatric_behavior: 0,
    phq_compare: 0,
    gad_compare: 0,
    asrsa_compare: 0,
    asrsb_compare: 0,
    pediatric_behavior_compare: 0,
  });

  const setSelected = (name, value) => {
    _setSelected((prev) => ({ ...prev, [name]: value }));
  };

  const [assessments, _setAssessments] = useState({
    phq: [],
    gad: [],
    asrsa: [],
    asrsb: [],
    pediatric_behavior: [],
  });

  const setAssessments = (name, value) => {
    _setAssessments((prev) => ({ ...prev, [name]: value }));
  };

  const [intakeformInfo, setIntakeformInfo] = useState(() => initIntakeformInfo());
  const [patientForm] = Form.useForm();

  const [replyData, setReplyData] = useState({ consultation: {}, consultation_replies: [] });
  const [callTarget, setCallTarget] = useState('patient');
  const flagTwilioCall = useFlag('twilio_call');
  const flagRxnt = useFlag('rxnt');
  const [inPersonHistoryURL, setInPersonHistoryURL] = useState()
  const [missingDEA, setMissingDEA] = useState(false);
  const [missingNPI, setMissingNPI] = useState(false);
  const [referralExpired, setReferralExpired] = useState(false);
  const [writeInHasValue, setWriteInHasValue] = useState(false);

  const loadPatientInfoReq = useRequest(props.admin ? srvAdminPatientDetail : srvPatientDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setPatientInfo(ret.data);
      }
    },
  });

  const updatePatientInfoReq = useRequest(srvUpdatePatientDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setVisible('patientForm', false);
        refresh();
      }
    },
  });

  const syncUserInfoToApero = useRequest(srvSyncUserInfoToApero, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        refresh();
      }
    },
  });

  const loadPatientIdentityReq = useRequest(srvPatientIdentity, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setPatientIdentity(ret.data);
      }
    },
  });

  const addAccountUpgradeReq = useRequest(srvAddAccountUpgrade, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setAccountUpgradeModalShow(false);
        refresh();
      }
    },
  });

  const changePharmacyReq = useRequest(srvChangeRXNTPharmacy, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setVisible('pharmacy', false);
        refresh();
      }
    },
  });
  const changePharmacyCaReq = useRequest(srvChangePharmacyForCa, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setVisible('caPharmacy', false);
        refresh();
      }
    },
  });

  const updateUserShippingAddress = useRequest(srvSetUserAddress, {
    manual: true,
    onSuccess: (ret, params) => {
      refresh();
    },
  });

  const deletePatientAccount = useRequest(srvAdminDeletePatientAccount, {
    manual: true,
    onSuccess: (ret, params) => {
      refresh();
    },
  });

  const AddOrUpdateUserInsuranceCardInfo = useRequest(srvAdminAddOrUpdateUserInsuranceCardInfo, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success){
        setVisible('updateInsuranceInfo', false);
        refresh();
      }
    },
  });


  const changePharmacy = (pharmacyID, pharmacy) => {
    if (pharmacy && pharmacy.id) {
      pharmacy.patient_id = id;
    }
    // const data = {
    //   id,
    //   ayva_id: pharmacy.pharmNcpdpId,
    //   name: pharmacy.pharmStoreName,
    //   street: pharmacy.pharmAddressLine1,
    //   street_1: pharmacy.pharmAddressLine2,
    //   city: pharmacy.pharmCity,
    //   state: pharmacy.pharmState,
    //   zipcode: pharmacy.pharmZip,
    // };
    // changePharmacyReq.run(data);
    changePharmacyReq.run(pharmacy);
  };

  const changePharmacyCa = (pharmacy) => {
    pharmacy.patient_id = id;
    changePharmacyCaReq.run(pharmacy);
  }

  const sync2RxntReq = useRequest(srvSyncRxnt, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        refresh();
      }
    },
  });

  const makeCall = (target, reason) => {
    const { account, dispatch } = props;
    const data = {
      patient_id: id,
      from_user_id: account.currentUser.id,
      target,
      first_name: patientInfo.first_name,
      last_name: patientInfo.last_name,
      reason,
    };

    call.oncall$.emit(data);
  };

  const refresh = () => {
    if (active) {
      loadPatientInfoReq.run({ id });
    }
  };

  useMount(() => {
    refresh();
  });

  useUpdateEffect(() => {
    refresh();
  }, [active, id]);

  const admin = props.admin ? true : false;

  useEffect(() => {
    if (Object.keys(patientInfo).length === 0) {
      return;
    }

    const intakeform = patientInfo.intake_form;
    const assessment = patientInfo.assessment;
    const diagnostics = patientInfo.diagnostic_assessments;
    const pediatric_behavior = patientInfo.pediatric_behavior_assessment;

    let intakeformAnswers = [];
    let assessmentAnswers = [];

    if (intakeform !== null) {
      if (intakeform.options != null) {
        intakeformAnswers = JSON.parse(intakeform.options);
      }
    }

    if (assessment !== null) {
      if (assessment.options != null) {
        assessmentAnswers = JSON.parse(assessment.options);
      }
    }

    setIntakeformInfo((prev) =>
      prev.map((i) => {
        i.answer = findIntakeFormAnswer(i, intakeformAnswers, assessmentAnswers);
        return i;
      }),
    );

    if (patientInfo.patient_type == 0) {
      loadAllAssessments(intakeform, assessment, diagnostics);
    } else if (patientInfo.patient_type == 1) {
      loadAllAssessmentsPediatric(pediatric_behavior, diagnostics);
    }
  }, [patientInfo]);

  if (loadPatientInfoReq.loading) {
    return <DescriptionsSkeleton active={loadPatientInfoReq.loading} />;
  }

  const patientInfoHeader = () => {
    return (
      <div>
        <div className={cx({ titleContainer: true })}>
          <h5 style={{ display: 'inline', marginRight: '10px' }}>
            Patient Chart
            {admin && (
              <Button
                size="small"
                type="text"
                onClick={showPatientForm}
                icon={<EditOutlined style={{ fontSize: '15px' }} />}
              />
            )}
            {
              admin && (
                <Button type="link" style={{color:'#808080'}} icon={<ReconciliationOutlined />} onClick={() => {
                  setMessagesVisible(true);
                }} />
              )
            }
          </h5>
          {/* {admin && <ExportPatientPDF />} */}
        </div>
        <Space style={{fontSize: "12px", fontWeight:"lighter"}}>
          <span>ID: {id}</span>
          <CopyButton text={"Copy ID"} value={id} buttonWidth={"130px"} />
          <CopyButton text={"Copy EHR Link"} value={window.location.href} buttonWidth={"130px"} />
        </Space>
      </div>
    );
  };

  const addUserInfoToApero = ( id) => {
    syncUserInfoToApero.run({ id:id });
  };

  const loadAllAssessmentsPediatric = (pediatric_behavior, diagnostics) => {
    let pedBeh = [];

    if (hasAnswers('pediatric_behavior', pediatric_behavior)) {
      pedBeh.push(pediatric_behavior);
    }

    diagnostics &&
      diagnostics.forEach((diagnostic) => {
        if (diagnostic.group == 'pediatric') {
          pedBeh.push(diagnostic);
        }
      });

    setAssessments('pediatric_behavior', pedBeh);

    setSelected('pediatric_behavior', pedBeh.length - 1);
  };

  const loadAllAssessments = (intakeform, assessment, diagnostics) => {
    let asrsa = [];
    let asrsb = [];
    let gad = [];
    let phq = [];

    if (hasAnswers('asrsa', assessment)) {
      asrsa.push(assessment);
    }

    if (hasAnswers('asrsb', intakeform)) {
      asrsb.push(intakeform);
    }

    if (hasAnswers('gad', intakeform)) {
      gad.push(intakeform);
    }

    if (hasAnswers('phq', intakeform)) {
      phq.push(intakeform);
    }

    diagnostics &&
      diagnostics.forEach((diagnostic) => {
        if (diagnostic.group == 'asrs') {
          asrsa.push(diagnostic);
        } else if (diagnostic.group == 'gad7') {
          gad.push(diagnostic);
        } else if (diagnostic.group == 'phq9') {
          phq.push(diagnostic);
        }
      });

    asrsa.forEach((a, ai) => {
      asrsb.forEach((b, bi, object) => {
        // if a and b are both the intake, they will both
        // have a score value
        // and should be merged into one

        if (!!a.score && !!b.score) {
          //object.splice(bi, 1);
          a.options = JSON.stringify([...JSON.parse(a.options), ...JSON.parse(b.options)]);
          a.data = JSON.stringify([...JSON.parse(a.data), ...JSON.parse(b.data)]);

          const ascore = a.scores ? JSON.parse(a.scores).asrsa_score : a.score;
          const bscore = b.scores ? JSON.parse(b.scores).asrsb_score : b.score_asrsb;

          a.scores = JSON.stringify({ asrsa_score: ascore, asrsb_score: bscore });
        }
      });
    });

    setAssessments('asrsa', asrsa);
    setAssessments('gad', gad);
    setAssessments('phq', phq);

    setSelected('asrsa', asrsa.length - 1);
    setSelected('gad', gad.length - 1);
    setSelected('phq', phq.length - 1);
  };

  const hasAnswers = (type, assessment) => {
    if (!assessment || !assessment.data || !assessment.options) {
      return false;
    }

    const data = assessment.data;
    const allAnswers = assessment.options;

    let questions = [];

    const allQuestions = JSON.parse(data);
    for (let question of allQuestions) {
      if (type == 'phq' && question.group === 'DEPRESSION' && question.type !== 'PLAIN') {
        questions.push(question);
      }
      if (type == 'gad' && question.group === 'ANXIETY' && question.type !== 'PLAIN') {
        questions.push(question);
      }
      // older questions might not have the ASRS group attached to them, so we must also include questions without a group here
      if (
        type == 'asrsa' &&
        (question.group === 'ASRS' || question.group === '') &&
        question.type !== 'PLAIN'
      ) {
        questions.push(question);
      }
      if (type == 'asrsb' && question.group === 'ASRSB' && question.type !== 'PLAIN') {
        questions.push(question);
      }
      if (type == 'pediatric_behavior' && question.type !== 'PLAIN') {
        questions.push(question);
      }
    }

    for (let question of questions) {
      for (let answer of JSON.parse(allAnswers)) {
        if (answer.key == question.key) {
          if (answer.value && answer.value != '') {
            return true;
          }
        }
      }
    }

    return false;
  };

  const getScoreText = (assessment, type) => {
    let scoreText = '';
    let label = '';
    if (assessment.scores) {
      switch (type) {
        case 'asrsa':
          label = 'asrsa_score';
          break;
        case 'asrsb':
          label = 'asrsb_score';
          break;
        case 'phq':
          label = 'phq9_score';
          break;
        case 'gad':
          label = 'gad7_score';
          break;
      }
      scoreText = JSON.parse(assessment.scores)[label];
    } else {
      switch (type) {
        case 'asrsa':
          label = 'score';
          break;
        case 'asrsb':
          label = 'score_asrsb';
          break;
        case 'phq':
          label = 'score_depression';
          break;
        case 'gad':
          label = 'score_anxiety';
          break;
      }
      scoreText = assessment[label];
    }
    return scoreText;
  };

  const getDiagnosticText = (assessment, type) => {
    let diagText = '';
    let label = '';
    if (!(type == 'asrsa' || assessment.scores)) {
      switch (type) {
        case 'asrsb':
          label = 'asrsb';
          break;
        case 'phq':
          label = 'phq_9';
          break;
        case 'gad':
          label = 'gad_7';
          break;
      }
      diagText = assessment[label];
    } else {
      diagText = assessment.conclusion;
    }
    return diagText ? diagText.replace('No Diagnosis', '') : diagText;
  };

  const isPartBCompleted = (assessment) => {
    return assessment && assessment.scores && JSON.parse(assessment.scores)['asrsb_score'];
  };

  const scoreDom = (assessment, type) => {
    if (type == 'asrsa') {
      return (
        <React.Fragment>
          <span> Part A Score: {getScoreText(assessment, 'asrsa')} </span>
          <br />
          {isPartBCompleted(assessment) ? (
            <span> Part B Completed </span>
          ) : (
            <span> No data for Part B </span>
          )}
        </React.Fragment>
      );
    }
    return <span> Score: {getScoreText(assessment, type)} </span>;
  };

  const assessmentSelector = (name, type, assessments, selected, selectFunction, isHeader) => {
    if (!assessments || assessments.length < 1) {
      return (
        <React.Fragment>
          <h3>{name}</h3>
          {!isHeader && (
            <h5 style={{ maxWidth: '400px', padding: '10px 0', color: '#888' }}>
              No data on file for this assessment. <br />
              You can click 'Request' below to message the patient requesting that they take it.
            </h5>
          )}
        </React.Fragment>
      );
    }

    let menu = (
      <Menu onClick={({ key }) => selectFunction(key)}>
        {assessments.map((assessment, i) => {
          return (
            <Menu.Item key={i}>
              {`${moment.unix(assessments[i].updated_at).format('LL')}`}
              {` - Score: ${getScoreText(assessments[i], type)}`}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    return (
      <React.Fragment>
        <h2 style={isHeader ? { fontSize: '2.6em' } : {}}>{name}</h2>
        <Dropdown overlay={menu}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <span style={isHeader ? { fontSize: '1.2em', fontWeight: 'bold' } : {}}>
                {`${moment.unix(assessments[selected].updated_at).format('LL')}`}
              </span>
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
        <div style={{ padding: '1px 0px', paddingTop: '5px' }}>
          {scoreDom(assessments[selected], type)}
        </div>
        <div style={{ padding: '1px 0px', color: '#888' }}>
          {getDiagnosticText(assessments[selected], type)}
        </div>
      </React.Fragment>
    );
  };

  const assessmentHeader = (title, showCompare) => {
    const titleStyle = {
      height: '30px',
      float: 'right',
      textAlign: 'right',
      display: 'inline-block',
      width: showCompare ? 'auto' : '100%',
    };

    return (
      <div className="assessment-header">
        <div className="assessment-title" style={titleStyle}>
          {title}
        </div>
        {showCompare && (
          <Space>
            <Button
              type="primary"
              style={{ marginBottom: '20px' }}
              onClick={() => {
                setVisible('assessmentCompare', !visible.assessmentCompare);
              }}
            >
              Compare
              {visible.assessmentCompare ? <ZoomOutOutlined /> : <ZoomInOutlined />}
            </Button>
            {visible.assessmentCompare && (
              <Popover content={assessmentCompareKeyJsx()} title="Comparison Key">
                <Button style={{ marginBottom: '20px' }} type="secondary">
                  Compare help
                </Button>
              </Popover>
            )}
          </Space>
        )}
      </div>
    );
  };

  const onAssessmentClose = (drawer) => {
    setVisible(drawer, false);
    setVisible('assessmentCompare', false);
  };

  const getAssessmentDrawerWidth = () => {
    return visible.assessmentCompare ? '1200' : '600';
  };

  const assessmentCompareKeyJsx = () => {
    const iconStyle = {
      fontSize: '24px',
      position: 'relative',
      bottom: '2px',
      marginRight: '8px',
    };

    return (
      <div style={{ padding: '16px', fontWeight: 'bold', fontSize: '14px' }}>
        <h4 style={{ color: '#389e0d' }}>
          <SmileOutlined style={iconStyle} /> Improvement lowered the assessment score{' '}
        </h4>
        <Divider />
        <h4 style={{ color: '#f5222d' }}>
          <FrownOutlined style={iconStyle} /> Worsening symptoms increased the assessment score{' '}
        </h4>
        <Divider />
        <h4 style={{ color: '#1890ff' }}>
          <MehOutlined style={iconStyle} /> Answer changed but did not affect the overall score{' '}
        </h4>
        <Divider />
        <h4 style={{ fontWeight: 'normal' }}>
          <MehOutlined style={iconStyle} /> Answer did not change{' '}
        </h4>
        <Divider />
        <h4 style={{ color: '#595959', marginLeft: '38px' }}> Past answer changed </h4>
        <Divider />
        <h4 style={{ fontWeight: 'normal', marginLeft: '38px' }}> Past answer did not change </h4>
      </div>
    );
  };

  const assessmentDrawerJsx = (name, type) => {
    const showCompare = assessments[type] && assessments[type].length > 1;
    return (
      <div>
        {assessmentHeader(name, showCompare)}
        <Row gutter={32}>
          <Col span={visible.assessmentCompare ? 12 : 24}>
            {assessmentSelector(
              name,
              type,
              assessments[type],
              selected[type],
              (value) => setSelected(type, value),
              true,
            )}
            <Descriptions
              layout="vertical"
              column={1}
              size="small"
              bordered={true}
              style={{ marginTop: '10px' }}
            >
              {type == 'pediatric_behavior' &&
                pediatricAssessmentScaleDom(
                  assessments[type][selected[type]],
                  visible.assessmentCompare,
                  assessments[type][selected[`${type}_compare`]],
                  false,
                )}
              {assessmentDom(
                type,
                assessments[type],
                selected[type],
                visible.assessmentCompare,
                selected[`${type}_compare`],
                false,
              )}
            </Descriptions>
          </Col>
          {visible.assessmentCompare && (
            <Col span={12}>
              {assessmentSelector(
                name,
                type,
                assessments[type],
                selected[`${type}_compare`],
                (value) => setSelected(`${type}_compare`, value),
                true,
              )}
              <Descriptions
                layout="vertical"
                column={1}
                size="small"
                bordered={true}
                style={{ marginTop: '10px' }}
              >
                {type == 'pediatric_behavior' &&
                  pediatricAssessmentScaleDom(
                    assessments[type][selected[`${type}_compare`]],
                    true,
                    assessments[type][selected[type]],
                    true,
                  )}
                {assessmentDom(
                  type,
                  assessments[type],
                  selected[`${type}_compare`],
                  true,
                  selected[type],
                  true,
                )}
              </Descriptions>
            </Col>
          )}
        </Row>
      </div>
    );
  };

  const assessmentDom = (type, diagnostics, index, isCompare, compareIndex, isRightSide) => {
    if (!diagnostics || diagnostics.length < 1) {
      return <div />;
    }

    const data = diagnostics[index].data;
    const options = diagnostics[index].options;
    let assessmentAnswers = [];
    let assessmentQuestions = [];
    let answerScores = [];

    let compareAnswers = {};
    let compareScores = {};

    if (
      isRightSide &&
      isCompare &&
      diagnostics[index].updated_at == diagnostics[compareIndex].updated_at
    ) {
      return (
        <Descriptions.Item label="Please select a different assessment">
          <b>You cannot compare an assessment to itself</b>
        </Descriptions.Item>
      );
    }

    const allQuestions = JSON.parse(data);

    // there are questions that will be baked into initial assessment
    // and intake form responses that we do not want to display
    // in this view so we will simply filter them out
    // so that the questions line up with those of the
    // diagnostic assessment retake questions
    const skip = [
      // pediatric skips
      'PBA0001', // Parent/Guardian Behavioral Assessment Portion
      'PBA0013', // Upload photo of driver's license
      'PBA0014', // next, we'll need a behavioral assessment completed by you and your child's teacher...
      'PBA0015', // this is an important component of treatment and appointment cannot be completed without it...
      'P0BA56', // what is your childs current grade level
      'P0BA57', // is your child homeschooled
      'P0BA58', // what is the name of your child's school
      'P0BA59', // What is the address of the person that will be completing the secondary assessment?
      'P0BA60', // Who will be completing the secondary assessment?
      'P0BA61', // What is the email of the person that will be completing the secondary assessment?
      'P0BA62', // Authorization for Release of Patient Health Information
      'PBA0004', // Thank you, the parent portion is now complete...

      // initial assessment skips
      'K0010', // major conditions question
      'K0002', // what's your biological sex?
      'K0003', // when were you born?
      'K0011', // what's your pronoun
      'K00031', // are you 18 years or older?
      'K0013', // are you over 18 years old?
      'K000201', // what's your legal name?

      // intake form skips
      'F0002', // photo ID
      'F0003', // delivery address
      'F0046', // when were you born
      'F0005', // before I was 12...
      'F0006', // before I was 12 part II...
      'F0007', // been treated for any...
      'F0008', // experieince with ADHD meds
      'F0029', // experience with meds for other...
      'F0009', // currently taking any...
      'F0010', // drug allergies
      'F0033', // emergency contact
      'F0030', // how did you hear about us
    ];

    let group;
    switch (type) {
      case 'asrsa':
        group = 'ASRS';
        break;
      case 'asrsb':
        group = 'ASRSB';
        break;
      case 'phq':
        group = 'DEPRESSION';
        break;
      case 'gad':
        group = 'ANXIETY';
    }

    allQuestions.length > 0 &&
      allQuestions.forEach((question) => {
        // ASRS-A responses have a blank group label so we must account for that
        // pediatric behavior also have no group label

        if (
          (question.group === group ||
            (type === 'asrsa' && question.group !== 'ANXIETY' && question.group !== 'DEPRESSION') ||
            type === 'pediatric_behavior') &&
          question.type !== 'PLAIN' &&
          !skip.includes(question.key)
        ) {
          assessmentQuestions.push(question);
        }
      });

    const as = JSON.parse(options);
    let answers = {};
    for (let answer of as) {
      answers[answer.key] = answer.value;
      answerScores[answer.key] = answer.score;
    }

    assessmentAnswers = answers;

    if (isCompare) {
      const cs = JSON.parse(diagnostics[compareIndex].options);
      let answers = {};
      for (let answer of cs) {
        answers[answer.key] = answer.value;
        compareScores[answer.key] = answer.score;
      }

      compareAnswers = answers;
    }

    let firstB = -1;

    return assessmentQuestions.map((v, i) => {
      const isDifferent = isCompare && compareAnswers[v.key] !== assessmentAnswers[v.key];
      let improveColor = '#595959'; // grey-8
      let isLower = false;
      let isHigher = false;
      let isLater = false;

      if (isCompare) {
        isLower = answerScores[v.key] < compareScores[v.key];
        isHigher = answerScores[v.key] > compareScores[v.key];
        isLater = diagnostics[index].updated_at > diagnostics[compareIndex].updated_at;

        if (isLater && compareScores[v.key] !== undefined) {
          if (isLower) {
            improveColor = '#389e0d'; // green-7
          } else if (isHigher) {
            improveColor = '#f5222d'; // red-6
          } else {
            improveColor = '#1890ff'; // blue-6
          }
        }
      }

      const iconStyle = {
        fontSize: '18px',
        position: 'relative',
        top: '2px',
        marginLeft: '4px',
        float: 'right',
      };

      if (type == 'asrsa' && v.group == 'ASRSB' && firstB == -1) {
        firstB = i;
      }

      return (
        <React.Fragment key={i}>
          {type == 'asrsa' && i == 0 && (
            <Descriptions.Item label={<b>Part A</b>}>
              <i>
                Scores in the 4-6 range on Part A are indicative symptoms consistent with ADHD in
                adults.
              </i>
            </Descriptions.Item>
          )}
          {type == 'asrsa' && i == firstB && (
            <Descriptions.Item label={<b>Part B</b>}>
              <i>
                While there is no numerical cut off for scores on Part B, higher numbers are more
                consistent with ADHD in adults.
              </i>
            </Descriptions.Item>
          )}
          <Descriptions.Item key={i} label={v.title}>
            <span
              style={
                isDifferent
                  ? { fontWeight: 'bold', fontSize: '14px', color: improveColor }
                  : { fontSize: '14px' }
              }
            >
              {`${assessmentAnswers[v.key]} ${
                answerScores[v.key] > 0 ? ` (+${answerScores[v.key]})` : '(0)'
              }`}
              {isLater && isLower && <SmileOutlined style={iconStyle} />}
              {isLater && isHigher && <FrownOutlined style={iconStyle} />}
              {isLater && !isHigher && !isLower && <MehOutlined style={iconStyle} />}
            </span>
          </Descriptions.Item>
        </React.Fragment>
      );
    });
  };

  const pediatricAssessmentDom = () => {
    if (!patientInfo.pediatric_assessment) {
      return <div />;
    }

    const data = patientInfo.pediatric_assessment.data;
    const options = patientInfo.pediatric_assessment.options;

    const assessmentQuestions = JSON.parse(data);
    const as = JSON.parse(options);
    let answers = {};
    for (let answer of as) {
      if (answer.value && answer.value != '') {
        answers[answer.key] = answer.value;
      } else if (answer.values && answer.values.length > 0) {
        answers[answer.key] = answer.values
          .map((v) => {
            return v.attr + ': ' + v.value;
          })
          .join();
      }
    }

    const assessmentAnswers = answers;

    return assessmentQuestions.map((v, i) => {
      if (v.type == 'PLAIN') {
        return null;
      }
      return (
        <Descriptions.Item key={i} label={v.title}>
          {assessmentAnswers[v.key]}
        </Descriptions.Item>
      );
    });
  };

  const pediatricIntakeFormDom = () => {
    if (!patientInfo.pediatric_intake_form) {
      return <div />;
    }

    const data = patientInfo.pediatric_intake_form.data;
    const options = patientInfo.pediatric_intake_form.options;

    const assessmentQuestions = JSON.parse(data);
    const as = JSON.parse(options);
    let answers = {};
    for (let answer of as) {
      if (answer.value && answer.value != '') {
        answers[answer.key] = answer.value;
      } else if (answer.values && answer.values.length > 0) {
        answers[answer.key] = answer.values
          .map((v) => {
            return v.attr + ': ' + v.value;
          })
          .join();
      }
    }

    const assessmentAnswers = answers;

    return assessmentQuestions.map((v, i) => {
      return (
        <Descriptions.Item key={i} label={v.title}>
          {assessmentAnswers[v.key]}
        </Descriptions.Item>
      );
    });
  };

  const pediatricWeightHeight = () => {
    if (!patientInfo.pediatric_intake_form) {
      return '';
    }
    const rawOptions = patientInfo.pediatric_intake_form.options;
    const options = JSON.parse(rawOptions);
    const heightWeightResp = options.find((v) => {
      return v.key == 'PI0004';
    });
    if (heightWeightResp) {
      var display = '';
      const heightFtResp = heightWeightResp.values.find((v) => {
        return v.attr == 'height_ft';
      });
      const heightInResp = heightWeightResp.values.find((v) => {
        return v.attr == 'height_in';
      });
      if (heightFtResp && heightInResp) {
        display = display + heightFtResp.value + ' ft ' + heightInResp.value + ' in';
      }
      const weightResp = heightWeightResp.values.find((v) => {
        return v.attr == 'weight';
      });
      if (weightResp) {
        display = display + ' / ' + weightResp.value + ' lbs';
      }
      return display;
    }
  };

  const pediatricBehaviorAssessmentDom = (behavior_assessment_field) => {
    if (!patientInfo[behavior_assessment_field]) {
      return <div />;
    }

    const data = patientInfo[behavior_assessment_field].data;
    const options = patientInfo[behavior_assessment_field].options;

    const assessmentQuestions = JSON.parse(data);
    const as = JSON.parse(options);
    let answers = {};
    for (let answer of as) {
      if (answer.value && answer.value != '') {
        answers[answer.key] = answer.value;
      } else if (answer.values && answer.values.length > 0) {
        answers[answer.key] = answer.values
          .map((v) => {
            return v.attr + ': ' + v.value;
          })
          .join();
      }
    }

    const assessmentAnswers = answers;

    return assessmentQuestions.map((v, i) => {
      return (
        <Descriptions.Item key={i} label={v.title}>
          {assessmentAnswers[v.key]}
        </Descriptions.Item>
      );
    });
  };

  const pediatricTeacherAssessmentScaleDom = (assessment) => {
    if (!assessment) {
      return <div />;
    }
    var teacherScales = {
      predominantly_inattentive_subtype: {
        name: 'Predominantly Inattentive subtype',
        rules: [
          '■Must score a 2 or 3 on 6 out of 9 items on questions 1–9 AND',
          '■ Score a 4 or 5 on any of the Performance questions 36–43',
        ],
      },
      predominantly_hyperactive_impulsive_subtype: {
        name: 'Predominantly Hyperactive/Impulsive subtype',
        rules: [
          '■ Must score a 2 or 3 on 6 out of 9 items on questions 10–18 AND',
          '■ Score a 4 or 5 on any of the Performance questions 36–43',
        ],
      },
      adhd_combined_inattention_hyperactivity: {
        name: 'ADHD Combined Inattention/Hyperactivity',
        rules: ['■ Requires the above criteria on both inattention and hyperactivity/impulsivity'],
      },
      oppositional_defiant_disorder_screen: {
        name: 'Oppositional-Defiant Disorder Screen',
        rules: [
          '■Must score a 2 or 3 on 3 out of 10 items on questions 19–28 AND',
          '■ Score a 4 or 5 on any of the Performance questions 36–43',
        ],
      },
      anxiety_depression_screen: {
        name: 'Anxiety/Depression Screen',
        rules: [
          '■ Must score a 2 or 3 on 3 out of 7 items on questions 29–35 AND',
          '■ Score a 4 or 5 on any of the Performance questions 36–43',
        ],
      },
    };

    return scaleDom(teacherScales, assessment, false, null, false);
  };

  const pediatricAssessmentScaleDom = (assessment, isCompare, compareAssessment, isRightSide) => {
    if (!assessment) {
      return <div />;
    }
    var parentScales = {
      predominantly_inattentive_subtype: {
        name: 'Predominantly Inattentive subtype',
        rules: [
          '■ Must score a 2 or 3 on 6 out of 9 items on questions 1–9 AND',
          '■ Score a 4 or 5 on any of the Performance questions 48–55',
        ],
      },
      predominantly_hyperactive_impulsive_subtype: {
        name: 'Predominantly Hyperactive/Impulsive subtype',
        rules: [
          '■ Must score a 2 or 3 on 6 out of 9 items on questions 10–18 AND',
          '■ Score a 4 or 5 on any of the Performance questions 48–55',
        ],
      },
      adhd_combined_inattention_hyperactivity: {
        name: 'ADHD Combined Inattention/Hyperactivity',
        rules: ['■ Requires the above criteria on both inattention and hyperactivity/impulsivity'],
      },
      oppositional_defiant_disorder_screen: {
        name: 'Oppositional-Defiant Disorder Screen',
        rules: [
          '■ Must score a 2 or 3 on 4 out of 8 behaviors on questions 19–26 AND',
          '■ Score a 4 or 5 on any of the Performance questions 48–55',
        ],
      },
      conduct_disorder_screen: {
        name: 'Conduct Disorder Screen',
        rules: [
          '■ Must score a 2 or 3 on 3 out of 14 behaviors on questions 27–40 AND',
          '■ Score a 4 or 5 on any of the Performance questions 48–55',
        ],
      },
      anxiety_depression_screen: {
        name: 'Anxiety/Depression Screen',
        rules: [
          '■ Must score a 2 or 3 on 3 out of 7 behaviors on questions 41–47 AND',
          '■ Score a 4 or 5 on any of the Performance questions 48–55',
        ],
      },
    };

    return scaleDom(parentScales, assessment, isCompare, compareAssessment, isRightSide);
  };

  const scaleDom = (scales, assessment, isCompare, compareAssessment, isRightSide) => {
    const scores = assessment.scores ? JSON.parse(assessment.scores) : assessment;
    const compareScores = isCompare
      ? compareAssessment.scores
        ? JSON.parse(compareAssessment.scores)
        : compareAssessment
      : null;

    if (isRightSide && isCompare && assessment.updated_at == compareAssessment.updated_at) {
      return null;
    }

    return (
      <>
        {Object.keys(scales).map((v, i) => {
          const isDifferent = isCompare && scores[v] != compareScores[v];
          return (
            <Descriptions.Item key={i} label={scales[v].name}>
              <>
                <ul>
                  {scales[v].rules.map((r, j) => {
                    return <li key={j}>{r}</li>;
                  })}
                </ul>
                {scores && scores.hasOwnProperty(v) ? (
                  <b>
                    {`${isDifferent ? '* ' : ''}${
                      scores[v] ? 'Meets Criteria' : "Doesn't Meet Criteria"
                    }`}
                  </b>
                ) : (
                  <b>Data missing</b>
                )}
              </>
            </Descriptions.Item>
          );
        })}
      </>
    );
  };

  const insomniaAssessmentDom = () => {
    if (!patientInfo.insomnia_assessment) {
      return <div />;
    }

    const data = patientInfo.insomnia_assessment.data;
    const options = patientInfo.insomnia_assessment.options;

    const assessmentQuestions = JSON.parse(data);
    const as = JSON.parse(options);
    let answers = {};
    for (let answer of as) {
      if (answer.value && answer.value != '') {
        answers[answer.key] = answer.value;
      } else if (answer.values && answer.values.length > 0) {
        answers[answer.key] = answer.values
          .map((v) => {
            return v.attr + ': ' + v.value;
          })
          .join();
      }
    }

    const assessmentAnswers = answers;

    return assessmentQuestions.map((v, i) => {
      if (v.type == 'PLAIN') {
        return null;
      }
      return (
        <Descriptions.Item key={i} label={v.title}>
          {assessmentAnswers[v.key]}
        </Descriptions.Item>
      );
    });
  };

  const insomniaIntakeFormDom = () => {
    if (!patientInfo.insomnia_intake_form) {
      return <div />;
    }

    const data = patientInfo.insomnia_intake_form.data;
    const options = patientInfo.insomnia_intake_form.options;

    const assessmentQuestions = JSON.parse(data);
    const as = JSON.parse(options);
    let answers = {};
    for (let answer of as) {
      if (answer.value && answer.value != '') {
        answers[answer.key] = answer.value;
      } else if (answer.values && answer.values.length > 0) {
        answers[answer.key] = answer.values
          .map((v) => {
            return v.attr + ': ' + v.value;
          })
          .join();
      }
    }

    const assessmentAnswers = answers;

    return assessmentQuestions.map((v, i) => {
      return (
        <Descriptions.Item key={i} label={v.title}>
          {assessmentAnswers[v.key]}
        </Descriptions.Item>
      );
    });
  };

  const getDiagnosticConsultationMessageBody = (type) => {
    let assessment;
    let link;

    let base = MINDFUL_PROD_PORTAL_URL;
    let sender='clinician';
    if (props.admin||currentUser.doctor_type===5) {
      sender = 'provider';
    }
    if (currentUser.doctor_type===3) {
      sender = 'therapist';
    }

    switch (type) {
      case 'asrsa':
        assessment = 'ASRS';
        link = '/diagnostic/asrs';
        break;
      case 'phq':
        assessment = 'PHQ-9';
        link = '/diagnostic/phq9';
        break;
      case 'gad':
        assessment = 'GAD-7';
        link = '/diagnostic/gad7';
        break;
      case 'pediatric':
        assessment = 'Pediatric Behavior';
        link = '/diagnostic/pediatric';
        break;
      default:
        return '';
    }
    let name = patientInfo.first_name + ' ' + patientInfo.last_name;
    link = base + link;
    if (!patientInfo.first_name && !patientInfo.last_name) {
      name = patientInfo.name
    }
    setDefaultMessage(`${name}, \nYour ${sender} is requesting that you take the ${assessment} assessment. \nTo complete this assessment, please visit ${link} and answer the questions provided there.`);
  };

  const showIdentity = (record) => {
    loadPatientIdentityReq.run({ id });
    setVisible('identity', true);
  };

  const showReferralHistory = (record) => {
    setVisible('referralHistory', true);
  };

  const showUpload = () => {
    setUploadModalShow(true)
  };

  const showUploadModal = (record) => {
    setVisible('upload', true);
  };

  const showPatientForm = () => {
    setVisible('patientForm', true);
    patientForm.setFieldsValue({
      first_name: patientInfo.first_name,
      middle_name: patientInfo.middle_name,
      last_name: patientInfo.last_name,
      email: patientInfo.email,
      phone: patientInfo.phone,
      birthdate: moment.unix(patientInfo.birthdate).utc(),
      ayva_id: patientInfo.ayva_id,
      rxnt_id: patientInfo.rxnt_id,
      step_appointment: patientInfo.step_appointment,
      notifiable: patientInfo.notifiable,
      discharged: patientInfo.discharged,
    });
  };

  const onSavePatientInfo = () => {
    const payload = {
      id: patientInfo.id,
      first_name: patientForm.getFieldValue('first_name'),
      middle_name: patientForm.getFieldValue('middle_name'),
      last_name: patientForm.getFieldValue('last_name'),
      email: patientForm.getFieldValue('email'),
      phone: patientForm.getFieldValue('phone').replace(/\D+/g, ''),
      birthdate: patientForm.getFieldValue('birthdate').unix(),
      ayva_id: patientForm.getFieldValue('ayva_id'),
      rxnt_id: patientForm.getFieldValue('rxnt_id'),
      step_appointment: patientForm.getFieldValue('step_appointment'),
      notifiable: patientForm.getFieldValue('notifiable'),
      discharged: patientForm.getFieldValue('discharged'),
    };
    updatePatientInfoReq.run(payload);
  };
  const intakeformInfoJsx = intakeformInfo.map((i) => (
    <Descriptions.Item label={i.title} key={i.key}>
      {i.key === 'K0010' && patientInfo && patientInfo.mindoct_user_assessment
        ? (patientInfo.mindoct_user_assessment.hospitalization === 2 ? 'No' : 'Yes')
        : i.answer || ''}
    </Descriptions.Item>
  ));

  const AssessmentsJsx = (data) => {
    return data.map((i) => (
      <Descriptions.Item label={i.title} key={i.key} className={cx(i.className)}>
        {i.value}
      </Descriptions.Item>
    ));
  };

  const requestAssessmentButton = (type, assessment) => {
    return (
      <Button size="small" onClick={() => openConsultation(assessment)}>
        {assessments[type].length > 0 ? 'Request Retake' : 'Request'}
      </Button>
    );
  };

  const medicalHistoryJsx = (medicalHistory) => {
    var data = JSON.parse(medicalHistory.data);
    const options = JSON.parse(medicalHistory.options);
    data = data.filter((i) => i.type != 'IDENTITY');
    data.map((item) => {
      var option = options.find((o) => o.key == item.key);
      if (option) {
        item.value = option.value;
        item.values = option.values;
      }
    });
    return data.map((i) => (
      <Descriptions.Item label={i.title} key={i.key}>
        {i.value}
        <>
          {i.values &&
            i.values.length > 0 &&
            i.values.map((v) => (
              <>
                {v.attr} : {v.value} &nbsp;
              </>
            ))}
        </>
      </Descriptions.Item>
    ));
  };

  const homeSchoolJsx = (behaivorAssessment) => {
    if (!behaivorAssessment) {
      return;
    }
    var data = JSON.parse(behaivorAssessment.data);
    var options = JSON.parse(behaivorAssessment.options);
    var datum = data.find((i) => i.key == 'P0BA57');
    if (datum) {
      var answer = options.find((o) => o.key == 'P0BA57');
      if (answer) {
        return (
          <Descriptions.Item label={datum.title} key={datum.key}>
            {answer.value}
          </Descriptions.Item>
        );
      }
    } else {
      return <></>;
    }
  };

  const isValidBirthdate = patientInfo.birthdate && patientInfo.birthdate !== -62135596800;

  const onDiagnosticConsultationClose = () => {
    setVisible('diagnosticConsultation', false);
    setDefaultMessage("");
    // other stuff
  };

  const onDiagnosticConsultationReply = () => {
    setVisible('diagnosticConsultation', false);
    setDefaultMessage("");
    message.success(
      'Diagnostic retake request message sent. You can view this message in the Consultations pane.',
    );
  };

  const onViewClick = (values) => {
    const id = values.id
    if (values.location === 'PCP Referral') {
      setInPersonHistoryURL('#')
      openViewModal()
      if (admin) {
        srvViewReferrals({id}).then((res) => {
          const data = res.data
          setReferralViewURL(data)

        })
      } else if (admin === false){
        srvViewPatientReferrals({id}).then((res) => {
          const data = res.data
          setReferralViewURL(data)

        })
      }
    } else {
      setInPersonHistoryURL(`/patient/${patientInfo.id}?tab=4`)
    }

  };

  const onApproveClick = (approval) => {
    const id = approval.id
    Modal.confirm({
      title: 'Do you want to approve this Referral? Patient will be notified via text message of approval.',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        srvApproveReferrals({id}).then(message.success('referral approved')).then(refresh())
      },
      onCancel() {},
    });
  };

  const openRejectionModal = () => {
    setVisible('referralHistory', false)
    setRejectionModalShow(true)
  }

  const openViewModal = () => {
    setVisible('viewReferral', true)
  }


  const onRejectClick = (values) => {
    openRejectionModal()
    return values.id

  };

  const getReferralKey = (referral) => {
    let file = referral.file;
    let objectKey = '';
    srvUploadToken({ type: 6 }).then((res) => {
      setReferralUploadKey(res.data.object_key)
      setReferralSignedURL(res.data.upload_url)
      file.arrayBuffer().then((content) => {
        request
          .put(res.data.upload_url, {
            data: content,
            headers: { 'Content-Type': file.type },
          })
          .catch(() => {
            message.warn('Referral not uploaded successfully')
          });
      });
    });
  }

  const uploadClicked = (data) => {
    if (referralUploadKey !== '') {
      srvUploadReferral({
        user_id: patientInfo.id,
        referral_key: referralUploadKey,
        date_of_evaluation: data.date_of_evaluation.utc().unix(),
        in_person_clinician: data.in_person_clinician
      }).then(r => {message.success('Referral saved successfully')})
    } else {
      message.error('File must be selected to complete the upload', 10)
    }

  }

  const rejectionSubmitClicked = (data) => {
    Modal.confirm({
      title: 'Do you want to reject this Referral? Patient will be notified via text message of rejection.',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        srvRejectReferrals({...data}).then(message.success('Rejection and reasons successfully saved')).then(setRejectionModalShow(false)).then(refresh())
      },
      onCancel() {},
    });


  };

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      ellipsis: true,
      key: 'status',
      width: 80,
      render: (v, r) => {
        if (r.location === 'PCP Referral') {
          switch (r.status) {
            case 1:
              return 'New';
            case 2:
              return 'Approved';
            case 3:
              return 'Rejected';
          }
        } else {
            switch (r.status) {
              case 1:
                return 'New';
              case 2:
                return 'Confirmed';
              case 3:
                return 'Cancelled';
              case 4:
                return 'No Show';
              case 5:
                return 'No Diagnosis';
              case 6:
                return 'Completed';
              case 7:
                return 'Provider No Show';
              case 8:
                return 'To Be Transferred';
              default:
                return 'Unknown';
            }
          }
        }
    },
    {
      title: 'Date',
      dataIndex: 'appointed_at',
      ellipsis: true,
      key: 'appointed_at',
      width: 100,
      render: (v, r) => {
        return formatUnixDayOnlyInUTCTZ(r.appointed_at);
      },
    },
    {
      title: 'Clinician',
      dataIndex: 'name',
      ellipsis: true,
      key: 'name',
      width: 180,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      ellipsis: true,
      width: 100,
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 50,
      render: (v, r) => {
        return (
          <Space>
            <Dropdown
              overlay={
                <div className={cx({ opt: true })}>
                  <div
                    className={cx({ optItem: true })}
                    onClick={(res) => {
                      onViewClick(r);
                    }}
                  >


                    <a href={inPersonHistoryURL}>View</a>

                  </div>
                  {admin === true &&  r.location === 'PCP Referral' &&(
                    <div>
                      <div
                        className={cx({ optItem: true })}
                        onClick={() => {
                          onApproveClick(r);
                        }}
                      >
                        <a href="#">Approve</a>
                      </div>
                      <div
                        className={cx({ optItem: true })}
                        onClick={() => {
                          var referralID
                          referralID = onRejectClick(r);
                          setReferralID(referralID)
                        }}
                      >
                        <a href="#">Reject</a>
                      </div>
                    </div>

                  )}
                </div>
              }
              trigger={['click']}
            >
              <Button size="small">
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  return (
    <div className={cx({ container: true, admin: admin })} id="patient-chart">
      <ConsultationDrawer
        visible={visible.diagnosticConsultation}
        onClose={() => onDiagnosticConsultationClose()}
        onReply={() => onDiagnosticConsultationReply()}
        role="doctor"
        userId={id}
        replyData={replyData}
        default={defaultMessage}
      />
      <Drawer
        className={cx({ identityDrawer: true })}
        title="Patient Identity"
        width="600"
        placement="right"
        closable={false}
        onClose={() => setVisible('identity', false)}
        visible={visible.identity}
      >
        <div className={cx({ identity: true })}>
          <Spin tip="Loading..." spinning={loadPatientInfoReq.loading}>
            <img src={patientIdentity && patientIdentity.url} />
          </Spin>
        </div>
      </Drawer>
      <Drawer
        className={cx({ identityDrawer: true })}
        title="Patient Referral"
        width="600"
        placement="right"
        closable={false}
        onClose={() => setVisible('viewReferral', false)}
        visible={visible.viewReferral}
      >
        <div className={cx({ identity: true })}>
          <embed
            src={referralViewURL}
            alt={'Patient Referral'}
            style={{ height: 600, width: 600 }}
          />
        </div>
      </Drawer>
      <Drawer
        className={cx({ identityDrawer: true })}
        title="In-person Evaluations and Referrals"
        width="600"
        placement="right"
        closable={false}
        onClose={() => setVisible('referralHistory', false)}
        visible={visible.referralHistory}
      >
        <div className={cx({ referralHistory: true })}>
          <Table
            columns={columns}
            rowKey="id"
            dataSource={patientInfo.in_person_history}
            pagination={{ pageSize: 5, simple: true }}
            rowClassName={(r) => {
              if (r.id === selected) {
                return 'rowSelected';
              }

              return '';
            }}
          />
        </div>
      </Drawer>
      <Drawer
        className={cx({ assessmentDrawer: true })}
        width={getAssessmentDrawerWidth()}
        placement="right"
        closable={false}
        onClose={() => onAssessmentClose('ASRSA')}
        visible={visible.ASRSA}
      >
        {assessmentDrawerJsx('ASRS', 'asrsa')}
      </Drawer>
      <Drawer
        className={cx({ assessmentDrawer: true })}
        width="600"
        placement="right"
        closable={false}
        onClose={() => onAssessmentClose('pediatricAssessment')}
        visible={visible.pediatricAssessment}
      >
        <Descriptions
          title="Pediatric Screening Assessment"
          layout="vertical"
          column={1}
          size="small"
          bordered={true}
        >
          {pediatricAssessmentDom()}
        </Descriptions>
      </Drawer>
      <Drawer
        className={cx({ assessmentDrawer: true })}
        title="Pediatric Intake"
        width="600"
        placement="right"
        closable={false}
        onClose={() => onAssessmentClose('pediatricIntakeForm')}
        visible={visible.pediatricIntakeForm}
      >
        <Descriptions
          title="Pediatric Intake"
          layout="vertical"
          column={1}
          size="small"
          bordered={true}
        >
          {pediatricIntakeFormDom()}
        </Descriptions>
      </Drawer>
      <Drawer
        className={cx({ assessmentDrawer: true })}
        width="600"
        placement="right"
        closable={false}
        onClose={() => onAssessmentClose('insomniaAssessment')}
        visible={visible.insomniaAssessment}
      >
        <Descriptions
          title="Insomnia Screening Assessment"
          layout="vertical"
          column={1}
          size="small"
          bordered={true}
        >
          {insomniaAssessmentDom()}
        </Descriptions>
      </Drawer>
      <Drawer
        className={cx({ assessmentDrawer: true })}
        width="600"
        placement="right"
        closable={false}
        onClose={() => onAssessmentClose('insomniaIntakeForm')}
        visible={visible.insomniaIntakeForm}
      >
        <Descriptions
          title="Insomnia Intake Form"
          layout="vertical"
          column={1}
          size="small"
          bordered={true}
        >
          {insomniaIntakeFormDom()}
        </Descriptions>
      </Drawer>
      <Drawer
        className={cx({ assessmentDrawer: true })}
        width={getAssessmentDrawerWidth()}
        placement="right"
        closable={false}
        onClose={() => onAssessmentClose('pediatricBehaviorAssessment')}
        visible={visible.pediatricBehaviorAssessment}
      >
        {assessmentDrawerJsx('Pediatric Behavior Assessment', 'pediatric_behavior')}
      </Drawer>
      <Drawer
        className={cx({ assessmentDrawer: true })}
        title="Pediatric Teacher Behavior Assessment"
        width="600"
        placement="right"
        closable={false}
        onClose={() => onAssessmentClose('pediatricTeacherBehaviorAssessment')}
        visible={visible.pediatricTeacherBehaviorAssessment}
      >
        <Descriptions
          title="Pediatric Teacher Behavior Assessment"
          layout="vertical"
          column={1}
          size="small"
          bordered={true}
        >
          {pediatricTeacherAssessmentScaleDom(patientInfo.pediatric_teacher_behavior_assessment)}
          {pediatricBehaviorAssessmentDom('pediatric_teacher_behavior_assessment')}
        </Descriptions>
      </Drawer>
      <Drawer
        className={cx({ intakePHQDrawer: true })}
        width={getAssessmentDrawerWidth()}
        placement="right"
        closable={false}
        onClose={() => onAssessmentClose('PHQ')}
        visible={visible.PHQ}
      >
        {assessmentDrawerJsx('PHQ-9', 'phq')}
      </Drawer>
      <Drawer
        className={cx({ intakeGADDrawer: true })}
        width={getAssessmentDrawerWidth()}
        placement="right"
        closable={false}
        onClose={() => onAssessmentClose('GAD')}
        visible={visible.GAD}
      >
        {assessmentDrawerJsx('GAD-7', 'gad')}
      </Drawer>
      <Drawer
        className={cx({ intakeGADDrawer: true })}
        title="Edit Patient Info"
        width="600"
        visible={visible.patientForm}
        onClose={() => setVisible('patientForm', false)}
      >
        <Form layout="vertical" form={patientForm}>
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[
              {
                required: true,
                message: 'First Name is required',
              },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item name="middle_name" label="Middle Name">
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[
              {
                required: true,
                message: 'Last Name is required',
              },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Email is required',
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone"
            rules={[
              {
                required: true,
                message: 'Phone is required',
              },
            ]}
          >
            <NumberFormat className="cusInput" format="(###) ###-####" mask="_" />
          </Form.Item>
          <Form.Item
            name="birthdate"
            label="Birthdate"
            format="L"
            rules={[
              {
                required: true,
                message: 'Birthdate required',
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item name="ayva_id" label="Ayva Id">
            <NumberFormat className="cusInput" />
          </Form.Item>
          {flagRxnt && (
            <Form.Item name="rxnt_id" label="RXNT Id">
              <NumberFormat className="cusInput" />
            </Form.Item>
          )}
          <Form.Item name="step_appointment" label="Step Appointment" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item name="notifiable" label="Notifiable" valuePropName="checked">
            <Switch />
          </Form.Item>
          {patientForm.getFieldValue('discharged') && (
            <Form.Item name="discharged" label="Discharged" valuePropName="checked">
              <Switch />
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" onClick={onSavePatientInfo}>
              Save
            </Button>
            <Button
              type="default"
              onClick={() => {
                setVisible('deletePatient', true);
              }}
              style={{ marginLeft: '10px' }}
            >
              Delete
            </Button>
          </Form.Item>
        </Form>
      </Drawer>

      {patientInfo.id && <InternalComments id={patientInfo.id} admin={props.admin} />}

      {visible.showSyncRxntAlert && patientInfo.rxnt_id && patientInfo.rxnt_id === '0' && patientInfo.country != 'ca' && (
        <Alert
          style={{ marginBottom: '10px' }}
          type="info"
          description={
            <Space>
              <div>
                This patient is not exist in RXNT yet, you can click <b>Sync to RXNT</b> button in{' '}
                <Link to={`/patient/${patientInfo.id}?tab=1`} className={cx({ link: true })}>
                  Patient Chart
                </Link>
                {!admin ? ' or contact admin' : ''}.
              </div>
            </Space>
          }
        />
      )}

      <Descriptions title={patientInfoHeader()} layout="vertical">
        <Descriptions.Item label="Patient Origin:" span={3}>
          <Space>
            {patientInfo.user_classification === 1 ? <Tag color="green" style={{ marginLeft: '5px' }}>Mindful</Tag> : (
              <>
                <Tag color="red" style={{ marginLeft: '5px' }}>Done</Tag>
                {patientInfo.is_within_account_upgrade ? 'Has been added to the migration list.' : <Button size="small" type="primary" onClick={() => { setAccountUpgradeModalShow(true); }}>Add to migration list</Button>}
              </>
            )}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="Preferred Name">{patientInfo.preferred_name}</Descriptions.Item>
        <Descriptions.Item label="First Name">{patientInfo.first_name}</Descriptions.Item>
        <Descriptions.Item label="Middle Name">{patientInfo.middle_name}</Descriptions.Item>
        <Descriptions.Item label="Last Name">{patientInfo.last_name}</Descriptions.Item>
        <Descriptions.Item label="Age">
          {isValidBirthdate ? moment().diff(moment.unix(patientInfo.birthdate), 'years') : 'N/A'}
          {patientInfo.patient_type == 1 && <Tag color="red">Pediatric</Tag>}
        </Descriptions.Item>
        <Descriptions.Item label="Birthdate">
          {isValidBirthdate ? moment.unix(patientInfo.birthdate).utc().format('MM/DD/YYYY') : 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label="Gender">{patientInfo.gender}</Descriptions.Item>
        {patientInfo.gender_pronoun !== 0 && (
          <Descriptions.Item label="Preferred Gender Pronoun">
            {patientInfo.gender_pronoun_desc}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Patient Identity">
          <Button size="small" onClick={showIdentity}>
            Show
          </Button>
        </Descriptions.Item>
        {admin && (
          <>
            <Descriptions.Item label="Email">{patientInfo.email}</Descriptions.Item>
            <Descriptions.Item label="Phone">
              {patientInfo.phone && new AsYouType('US').input(patientInfo.phone)}
            </Descriptions.Item>
            <Descriptions.Item label="Insurance Information">
              <Space>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '100%' }}>

                  {patientInfo.legal_first_name+" "+patientInfo.legal_last_name+"\n"}
                  <br/>
                  {patientInfo.insurance_info && patientInfo.insurance_info.insurance_id+"\n"}
                  <br/>
                  {patientInfo.insurance_info && patientInfo.insurance_info.insurance_company}
                  {patientInfo.insurance_info && patientInfo.insurance_info.insurance_company_id!==''?'':'(Insurance company not in database)'}
                    {admin && (
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      setVisible('updateInsuranceInfo', true);
                    }}
                    style={{ alignSelf: 'flex-end' ,marginLeft:'3'}}
                  >
                    Edit
                  </Button>
                )}

                </div>
              </Space>

            </Descriptions.Item>
            <Descriptions.Item label="Next Step">{patientInfo.next_step}</Descriptions.Item>
          </>
        )}
        {admin && patientInfo.emergency_contact && (
          <>
            <Descriptions.Item label="Emergency Contact Name">
              {patientInfo.emergency_contact.full_name}
            </Descriptions.Item>
            <Descriptions.Item label="Emergency Contact Phone">
              {patientInfo.emergency_contact.phone}
            </Descriptions.Item>
            <Descriptions.Item label="Emergency Contact Relationship">
              {patientInfo.emergency_contact.relationship}
            </Descriptions.Item>
          </>
        )}
        <Descriptions.Item label="Shipping Address">
          <Space>
            <span>
              {patientInfo.address && patientInfo.address.street + ', '}
              {patientInfo.address && patientInfo.address.street_1 != ''
                ? patientInfo.address && patientInfo.address.street_1 + ', '
                : patientInfo.address && patientInfo.address.street_1}
              {patientInfo.address && patientInfo.address.city + ', '}
              {patientInfo.address && patientInfo.address.state + ', '}
              {patientInfo.address && patientInfo.address.zipcode + ', ' + patientInfo.country && patientInfo.country === 'ca' ? 'Canada' : 'United States'}
            </span>
            {admin && (
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setVisible('updateShippingAddress', true);
                }}
              >
                Edit
              </Button>
            )}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="Pharmacy Address">
          <Space>
            <span>
              {patientInfo.pharmacy && patientInfo.country != 'ca' && patientInfo.pharmacy.name + ', '}
              {patientInfo.pharmacy && patientInfo.pharmacy.address_1 + ', '}
              {patientInfo.pharmacy && patientInfo.pharmacy.address_2
                ? patientInfo.pharmacy.address_2 + ', '
                : ''}
              {patientInfo.pharmacy && patientInfo.pharmacy.city + ', '}
              {patientInfo.pharmacy && patientInfo.pharmacy.state + ', '}
              {patientInfo.pharmacy && patientInfo.country != 'ca' && patientInfo.pharmacy.zipcode + ', '}
              {patientInfo.country && patientInfo.country === 'ca' ? 'Canada' : 'United States'}
            </span>
            {!(history.location.query.admin && access.adminAccesser)&&(
            <Button
              size="small"
              type="primary"
              onClick={() => {
                if (patientInfo.rxnt_id && patientInfo.rxnt_id === '0' && patientInfo.country != 'ca') {
                  setVisible('showSyncRxntAlert', true);
                } else if (patientInfo.country === 'ca') {
                  setVisible('caPharmacy', true);
                } else
                  setVisible('pharmacy', true);
                }
              }
            >
              Change Pharmacy
            </Button>
            )}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="Payment Status">
          {patientInfo.payment_method_status && patientInfo.payment_method_status}
        </Descriptions.Item>
        <Descriptions.Item label="Clinician">
          {!admin && patientInfo.provider_name + ', ' + patientInfo.provider_title}
          {admin &&
            patientInfo.provider_id !== '0' &&
            patientInfo.provider_name + ', ' + patientInfo.provider_title}
          {admin && patientInfo.provider_id === '0' && (
            <AssignProvider
              patientId={patientInfo.id}
              onFinish={() => {
                refresh();
              }}
              inTransferWaitlist={
                patientInfo.transfer_status === 1
              }
            />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Therapist">
          {!admin && patientInfo.therapist_name + ', ' + patientInfo.therapist_title}
          {admin &&
            patientInfo.therapist_id !== '0' &&
            patientInfo.therapist_name + ', ' + patientInfo.therapist_title}
          {admin && patientInfo.therapist_id === '0' &&
            "None"
          }
        </Descriptions.Item>
        <Descriptions.Item label="Notifiable">
          {patientInfo.notifiable ? 'Yes' : 'No'}
        </Descriptions.Item>

        <Descriptions.Item label="Rxnt ID">
          <Space>
            {patientInfo.rxnt_id}
            {(patientInfo.country != 'ca'&&!(history.location.query.admin && access.adminAccesser)) && <Button
              size="small"
              type="primary"
              onClick={() => {
                sync2RxntReq.run({ id });
              }}
              loading={sync2RxntReq.loading}
            >
              Sync to Rxnt
            </Button>}
          </Space>
        </Descriptions.Item>

        {(flagTwilioCall && !admin && !(history.location.query.admin && access.adminAccesser)) && (
          <Descriptions.Item label="Phone">
            <Button
              size="small"
              type="primary"
              onClick={() => {
                setCallTarget('patient');
                setVisible('callReason', true);
              }}
            >
              Call Patient
            </Button>
          </Descriptions.Item>
        )}

        <Descriptions.Item label="PDMP">
          <CheckPDMPPane id={id} />
        </Descriptions.Item>

        {flagTwilioCall && !admin && patientInfo.emergency_contact && (
          <>
            <Descriptions.Item label="Emergency Contact">
              <div>
                {patientInfo.emergency_contact.full_name}(
                {patientInfo.emergency_contact.relationship})
              </div>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setCallTarget('emergency_contact');
                  setVisible('callReason', true);
                }}
              >
                Call
              </Button>
            </Descriptions.Item>
          </>
        )}
        {patientInfo.step_appointment && (
          <Descriptions.Item label="Patient Consent">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>Terms and Conditions: Accepted
              <Button
                size="small"
                type="primary"
                href="https://terms.donefirst.com"
                target="_blank"
                style={{ marginLeft: '8px', marginTop: '8px'}}
              >
                View Consent
              </Button>
              </div>
              <div>Insurance Consent: {patientInfo.accepted_insurance_consent ? "Accepted" : "Not Accepted" }
              <Button
                size="small"
                type="primary"
                href="https://storage.googleapis.com/done_api_static/hybrid_care/2023%20Insurance%20Agreement.docx.pdf"
                target="_blank"
                style={{ marginLeft: '8px', marginTop: '8px'}}
              >
                View Consent
              </Button></div></div>


          </Descriptions.Item>
        )}
        <Descriptions.Item label="In Person Evaluation Deadline">
          {patientInfo.in_person_follow_up_end_date === 0 ? "N/A - CII Eligible"
            : patientInfo.in_person_follow_up_end_date > moment.utc().unix() ?
            moment.unix(patientInfo.in_person_follow_up_end_date).utc().format('MM/DD/YYYY') + "- CII Eligible":
            moment.unix(patientInfo.in_person_follow_up_end_date).utc().format('MM/DD/YYYY') + "- CII Not Eligible(!)"}
        </Descriptions.Item>
        <Descriptions.Item label={'last in person evaluation date'}>
          {patientInfo.most_recent_valid_in_person_appointment_time !== 0
            ? moment
                .unix(patientInfo.most_recent_valid_in_person_appointment_time)
                .utc()
                .format('MM/DD/YYYY')
            : 'N/A'}
          <Button size="small" onClick={showReferralHistory}>
            View History
          </Button>
          {admin && (
            <Button
              style={{ marginTop: -20, marginLeft: 20 }}
              size={'small'}
              type={'primary'}
              onClick={showUpload}
            >
              Upload
            </Button>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Discharged">
          {patientInfo.discharged ? 'Yes' : 'No'}
        </Descriptions.Item>
        <Descriptions.Item label="Campaign">{patientInfo.campaign_id}</Descriptions.Item>
        {admin &&
        <Descriptions.Item label="Patient Apero IDs">
          <Space>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '100%' }}>
            {"Patient ID: " }
            { patientInfo.apero_id !== '' ? patientInfo.apero_id : '0'}
            <br/>
            {"Insurance ID: " }
            { patientInfo.insurance_info && patientInfo.insurance_info.apero_id !== '' ? patientInfo.insurance_info.apero_id : '0'}
            <Button
              size="small"
              type="primary"
              onClick={() => {
                addUserInfoToApero(id);
              }}
              loading={addUserInfoToApero.loading}
              style={{ alignSelf: 'flex-end' ,marginLeft:'3'}}
              disabled={true}
            >
              Sync to Apero
            </Button>
            </div>
          </Space>
        </Descriptions.Item>}
        {admin &&
          <Descriptions.Item label="Student Status">
            <span style={{marginRight: '10px'}}>{patientInfo.student_verify_status}</span>
            <Button
              size="small"
              type="primary"
              onClick={()=>{
                setVisible('studentVerification', true);
              }}
            >
              View
            </Button>
          </Descriptions.Item>
        }
      </Descriptions>

      {patientInfo.patient_type === 1 && patientInfo.patient_parent && (
        <Descriptions layout="vertical" colon={false}>
          <Descriptions.Item label="Parent or Guardian's Name">
            {patientInfo.patient_parent.first_name} {patientInfo.patient_parent.last_name}
          </Descriptions.Item>
          <Descriptions.Item label="Parent or Guardian's Age">
            {patientInfo.patient_parent.birthdate &&
              moment().diff(moment.unix(patientInfo.patient_parent.birthdate), 'years')}
          </Descriptions.Item>
          <Descriptions.Item label="Parent or Guardian's Birthdate">
            {patientInfo.patient_parent.birthdate &&
              moment.unix(patientInfo.patient_parent.birthdate).utc().format('MM/DD/YYYY')}
          </Descriptions.Item>
          <Descriptions.Item label="Parent or Guardian's Gender">
            {patientInfo.patient_parent.gender}
          </Descriptions.Item>
        </Descriptions>
      )}

      <Descriptions
        title="Assessment Results"
        layout="vertical"
        column={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3 }}
        colon={false}
      >
        {patientInfo.patient_type != 1 &&
          patientInfo.patient_type != 3 &&
          AssessmentsJsx([
            {
              key: 1,
              className: { asrsa: true },
              title: (
                <Space>
                  <Row gutter={[16, 8]}>
                    <Col>
                      {assessmentSelector(
                        'ASRS',
                        'asrsa',
                        assessments.asrsa,
                        selected.asrsa,
                        (value) => setSelected('asrsa', value),
                      )}
                    </Col>
                  </Row>
                </Space>
              ),
              value: (
                <Space style={{ marginBottom: 10 }}>
                  {assessments.asrsa && assessments.asrsa.length > 0 && (
                    <Button size="small" type="primary" onClick={() => setVisible('ASRSA', true)}>
                      View
                    </Button>
                  )}
                  {requestAssessmentButton('asrsa', 'asrsa')}
                </Space>
              ),
            },
            {
              key: 2,
              className: { phq: true },
              title: (
                <Space>
                  <Row gutter={[16, 8]}>
                    <Col>
                      {assessmentSelector('PHQ-9', 'phq', assessments.phq, selected.phq, (value) =>
                        setSelected('phq', value),
                      )}
                    </Col>
                  </Row>
                </Space>
              ),
              value: (
                <Space style={{ marginBottom: 10 }}>
                  {assessments.phq && assessments.phq.length > 0 && (
                    <Button size="small" type="primary" onClick={() => setVisible('PHQ', true)}>
                      View
                    </Button>
                  )}
                  {requestAssessmentButton('phq', 'phq')}
                </Space>
              ),
            },
            {
              key: 3,
              className: { gad: true },
              title: (
                <Space style={{ marginBottom: 10 }}>
                  <Row gutter={[16, 8]}>
                    <Col>
                      {assessmentSelector('GAD-7', 'gad', assessments.gad, selected.gad, (value) =>
                        setSelected('gad', value),
                      )}
                    </Col>
                    {}
                  </Row>
                </Space>
              ),
              value: (
                <Space style={{ marginBottom: 10 }}>
                  {assessments.gad && assessments.gad.length > 0 && (
                    <Button size="small" type="primary" onClick={() => setVisible('GAD', true)}>
                      View
                    </Button>
                  )}
                  {requestAssessmentButton('gad', 'gad')}
                </Space>
              ),
            },
          ])}

        {patientInfo.patient_type == 1 && (
          <>
            <Descriptions.Item>
              <Space style={{ marginBottom: 10 }}>
                <Row gutter={[16, 8]}>
                  <Col>Pediatric Screening Assessment</Col>
                  <Col>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => setVisible('pediatricAssessment', true)}
                    >
                      View
                    </Button>
                  </Col>
                </Row>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item>
              <Space style={{ marginBottom: 10 }}>
                <Row gutter={[16, 8]}>
                  <Col>
                    {assessmentSelector(
                      'Behavior Assessment - Parent',
                      'pediatric_behavior',
                      assessments.pediatric_behavior,
                      selected.pediatric_behavior,
                      (value) => setSelected('pediatric_behavior', value),
                    )}
                  </Col>
                  {assessments.pediatric_behavior && assessments.pediatric_behavior.length > 0 && (
                    <Col>
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => setVisible('pediatricBehaviorAssessment', true)}
                      >
                        View
                      </Button>
                    </Col>
                  )}
                </Row>
              </Space>
            </Descriptions.Item>
            {patientInfo.pediatric_teacher_behavior_assessment && (
              <Descriptions.Item>
                <Space style={{ marginBottom: 10 }}>
                  <Row gutter={[16, 8]}>
                    <Col>Behavior Assessment - Teacher</Col>
                    <Col>
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => setVisible('pediatricTeacherBehaviorAssessment', true)}
                      >
                        View
                      </Button>
                    </Col>
                  </Row>
                </Space>
              </Descriptions.Item>
            )}
          </>
        )}
        {patientInfo.patient_type == 3 && (
          <>
            <Descriptions.Item>
              <Space style={{ marginBottom: 10 }}>
                <Row gutter={[16, 8]}>
                  <Col>Insomnia Screening Assessment</Col>
                  <Col>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => setVisible('insomniaAssessment', true)}
                    >
                      View
                    </Button>
                  </Col>
                </Row>
              </Space>
            </Descriptions.Item>
            {patientInfo.insomnia_intake_form && (
              <Descriptions.Item>
                <Space style={{ marginBottom: 10 }}>
                  <Row gutter={[16, 8]}>
                    <Col>Insomnia Intake Form</Col>
                    <Col>
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => setVisible('insomniaIntakeForm', true)}
                      >
                        View
                      </Button>
                    </Col>
                  </Row>
                </Space>
              </Descriptions.Item>
            )}
          </>
        )}
      </Descriptions>

      {patientInfo.patient_type != 1 && (
        <Descriptions layout="vertical" title="Additional Information" colon={false}>
          {intakeformInfoJsx}
        </Descriptions>
      )}

      {patientInfo.patient_type == 1 && patientInfo.pediatric_medical_history && (
        <Descriptions layout="vertical" title="Medical History" colon={false}>
          {medicalHistoryJsx(patientInfo.pediatric_medical_history)}
          {homeSchoolJsx(patientInfo.pediatric_behavior_assessment)}
        </Descriptions>
      )}

      {patientInfo.patient_type != 1 && (
        <Descriptions title="Medications" layout="vertical" column={1} colon={false}>
          <Descriptions.Item label="Do you have any experience with medications used to treat other behavioral health conditions?">
            {patientInfo.intake_form && patientInfo.intake_form.previous_adhd_medications}
          </Descriptions.Item>
          <Descriptions.Item label="Are you currently taking any prescription medications?">
            {patientInfo.intake_form && patientInfo.intake_form.current_medications}
          </Descriptions.Item>
          <Descriptions.Item label="Do you have any allergies to medication?">
            {patientInfo.intake_form && patientInfo.intake_form.allergic_medications}
          </Descriptions.Item>
        </Descriptions>
      )}
      {rejectionModalShow && (
        <>
          <div
            className="upload-modal"
            style={{ paddingLeft: 30, paddingTop: 5, height: 450, width: 450 }}
          >
            <div style={{ fontSize: 16, fontWeight: 'bold', paddingBottom: 0 }}>
              {'Reject the referral'}
            </div>
            <div>
              <Form onFinish={rejectionSubmitClicked} form={form}>
                <p>Please tell patient the reasons why they have been rejected.</p>
                <Form.Item name={'referral_record_id'} initialValue={referralID} />
                <Form.Item name={'missing_dea_number'} valuePropName="checked" initialValue={false}>
                  <Checkbox onChange={(e) => setMissingDEA(e.target.checked)}>
                    Missing provider's DEA number
                  </Checkbox>
                </Form.Item>
                <Form.Item name={'missing_npi_number'} valuePropName="checked" initialValue={false}>
                  <Checkbox onChange={(e) => setMissingNPI(e.target.checked)}>
                    Missing provider's NPI
                  </Checkbox>
                </Form.Item>
                <Form.Item name={'referral_expired'} valuePropName="checked" initialValue={false}>
                  <Checkbox onChange={(e) => setReferralExpired(e.target.checked)}>
                    The last in-person exam was 23 months ago
                  </Checkbox>
                </Form.Item>
                <Row style={{ paddingBottom: 0 }}>
                  <Form.Item name={'rejection_write_in'} initialValue={''}>
                    <Input.TextArea
                      onChange={(e) => {
                        if (e.target.value !== '') {
                          setWriteInHasValue(true);
                        } else {
                          setWriteInHasValue(false);
                        }
                      }}
                    />
                  </Form.Item>
                </Row>
                <Row>
                  <div style={{}}>
                    <Button
                      onClick={() => {
                        setRejectionModalShow(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        form.submit();
                      }}
                      disabled={!missingDEA && !missingNPI && !referralExpired && !writeInHasValue}
                      type={'primary'}
                      style={{ position: 'absolute', marginLeft: 230 }}
                    >
                      Confirm
                    </Button>
                  </div>
                </Row>
              </Form>
            </div>
          </div>
          <div className="upload-mask" />
        </>
      )}

      {(uploadModalShow&&!(history.location.query.admin && access.adminAccesser)) && (
        <>
          <div
            className="upload-modal"
            style={{ paddingLeft: 30, paddingTop: 5, height: 450, width: 600 }}
          >
            <Form form={form} style={{ marginBottom: 0 }} onFinish={uploadClicked}>
              <div style={{ fontSize: 16, fontWeight: 'bold', paddingBottom: 0 }}>Attach File</div>
              <div>
                <Row>
                  <Col span={10}>
                    <Form.Item
                      name={'in_person_clinician'}
                      initialValue={''}
                      label={'Primary Care Provider'}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: 'Primary Care Provider Required',
                        },
                      ]}
                    >
                      <Input style={{ width: 200 }} />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name={'date_of_evaluation'}
                      initialValue={''}
                      label={'Date of last in-person evaluation'}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: 'Date of Examination Required',
                        },
                      ]}
                    >
                      <DatePicker style={{ width: 200 }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ height: 200 }}>
                  <Dragger
                    accept={'application/pdf'}
                    className="upload-panel"
                    style={{ paddingRight: 30 }}
                    customRequest={getReferralKey}
                  >
                    <p className="ant-upload-text">
                      Drag and drop your file here{' '}
                      <span className="upload-link">or upload from your computer</span>
                    </p>
                  </Dragger>
                </Row>
                <Row style={{ marginTop: 65 }}>
                  <div style={{}}>
                    <Button
                      onClick={() => {
                        setUploadModalShow(false);
                      }}
                      style={{ marginLeft: 0, marginBottom: 0 }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        form.submit();
                      }}
                      type={'primary'}
                      style={{
                        position: 'absolute',
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 300,
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </Row>
              </div>
            </Form>
          </div>
          <div className="upload-mask" />
        </>
      )}

      {accountUpgradeModalShow && (
        <Modal
          title='Add to migration list'
          okText='Confirm addition'
          visible={accountUpgradeModalShow}
          loading={addAccountUpgradeReq.loading}
          onOk={() => {
            addAccountUpgradeReq.run({ user_id: id })
          }}
          onCancel={() => {
            setAccountUpgradeModalShow(false);
          }}
        >
          <p>Upon inclusion in the migration list, the user will be notified via email about the impending domain change. To ensure a seamless transition, they will be greeted with a reminder upon their next login, guiding them to the Mindful Health domain. Would you like to confirm this addition?</p>
        </Modal>
      )}

      <DoctorChangeLogsDrawer
        targetID={id}
        messagesVisible={messagesVisible}
        setMessagesVisible={setMessagesVisible}
      />

      <PatientDocument
        patientID={id}
        identityInfo={patientIdentity}
        hasIdentity={patientInfo.has_identity}
        hasInsuranceBack={patientInfo.has_insurance_back}
        hasInsuranceFront={patientInfo.has_insurance_front}
        refresh={() => {
          refresh();
        }}
        admin={admin}
      />

      {patientInfo.id && patientInfo.address && visible.pharmacy && patientInfo.country != 'ca' && (
        <PharmacyDrawer
          visible={visible.pharmacy}
          zipcode={patientInfo.address.zipcode}
          onFinish={changePharmacy}
          loading={changePharmacyReq.loading}
          onClose={() => {
            setVisible('pharmacy', false);
          }}
          admin={admin}
        />
      )}

      {patientInfo.id && patientInfo.address && visible.caPharmacy && patientInfo.country === 'ca' && (
        <CaPharmacyDrawer
          visible={visible.caPharmacy}
          zipcode={patientInfo.address.zipcode}
          onFinish={changePharmacyCa}
          pharmacy={patientInfo.pharmacy}
          loading={changePharmacyReq.loading}
          onClose={() => {
            setVisible('caPharmacy', false);
          }}
          admin={admin}
        />
      )}

      <UpdateAddressModal
        title={'Update shipping address'}
        maskClosable={false}
        closable={false}
        address={patientInfo.address}
        visible={visible.updateShippingAddress}
        onOk={(address) => {
          const data = {
            id: patientInfo.id,
            ...address,
          };
          updateUserShippingAddress.run(data);
          setVisible('updateShippingAddress', false);
        }}
        onCancel={() => {
          setVisible('updateShippingAddress', false);
        }}
      />

      <UpdateInsuranceInfoModal
        title={'Update insurance information'}
        patientId={patientInfo.id}
        insuranceInfo={patientInfo.insurance_info}
        legalFirstName = {patientInfo.legal_first_name}
        legalLastName = {patientInfo.legal_last_name}
        visible={visible.updateInsuranceInfo}
        onOk={(values) => {
          const data = {
            user_id: patientInfo.id,
            legal_first_name: values.legal_first_name,
            legal_last_name: values.legal_last_name,
            insurance_id: values.insurance_id,
            insurance_company: values.insurance_company,
            insurance_company_id: values.insurance_company_id
          };
          AddOrUpdateUserInsuranceCardInfo.run(data);

        }}
        onCancel={() => {
          setVisible('updateInsuranceInfo', false);
        }}
      />
      <DeletePatientModal
        patientId={patientInfo.id}
        visible={visible.deletePatient}
        onOk={() => {
          setVisible('deletePatient', false);
          setVisible('patientForm', false);
          deletePatientAccount.run({
            id: patientInfo.id,
          });
        }}
        onCancel={() => {
          setVisible('deletePatient', false);
        }}
      />
      <CallReasonModal
        visible={visible.callReason}
        onOk={(reason) => {
          setVisible('callReason', false);
          makeCall(callTarget, reason);
        }}
        onCancel={() => {
          setVisible('callReason', false);
        }}
      />

      {
        admin &&
        <StudentVerificationModal
          patientId={patientInfo.id}
          visible={visible.studentVerification}
          onOk={() => {
            setVisible('studentVerification', false);
            refresh();
          }}
          onCancel={() => {
            setVisible('studentVerification', false);
          }}
        />
      }

    </div>
  );
};

export default connect(({ call, account }) => ({ call, account }))(PatientInfo);
