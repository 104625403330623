import React, { useEffect } from 'react';
import { Rnd } from 'react-rnd';
import { useDebounceFn, useSetState } from 'ahooks';

const getInitialState = (windowWidth, windowHeight, defaultWidth = 500) => ({
  position: {
    x: windowWidth - defaultWidth - 20,
    y: windowHeight * 0.04,
  },
  size: {
    width: defaultWidth,
    height: windowHeight * 0.93,
  },
  minimizedPosition: {
    x: windowWidth - 394,
    y: windowHeight - 84,
  },
});

const DraggableResizableWindow = ({
  children,
  visible,
  isMinimized,
  minWidth = 400,
  minHeight = 500,
  dragHandleClassName,
  minimizedSize = { width: 366, height: 40 },
  minimizedContent,
  style,
  onPositionChange,
  onSizeChange,
  onMinimizedPositionChange,
}) => {
  const [state, setState] = useSetState({
    ...getInitialState(window.innerWidth, window.innerHeight, minWidth),
    lastPosition: null,
    lastSize: null,
    lastMinimizedPosition: null,
  });
  useEffect(() => {
    setState({
      ...getInitialState(window.innerWidth, window.innerHeight, minWidth),
    });
  }, [minWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { run: handleResize } = useDebounceFn(
    () => {
      if (!visible) return;

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      const calculateNewPosition = (currentPos, currentSize) => {
        const rightMargin = windowWidth - (currentPos.x + currentSize.width);
        const bottomMargin = windowHeight - (currentPos.y + currentSize.height);
        const newX = rightMargin < 0 ? windowWidth - currentSize.width : currentPos.x;
        const newY = bottomMargin < 0 ? windowHeight - currentSize.height : currentPos.y;

        return {
          x: Math.max(0, Math.min(newX, windowWidth - currentSize.width)),
          y: Math.max(0, Math.min(newY, windowHeight - currentSize.height)),
        };
      };

      if (isMinimized) {
        const newMinimizedPosition = {
          x: Math.min(state.minimizedPosition.x, windowWidth - minimizedSize.width),
          y: Math.min(state.minimizedPosition.y, windowHeight - minimizedSize.height),
        };
        setState({ minimizedPosition: newMinimizedPosition });
        onMinimizedPositionChange?.(newMinimizedPosition);
      } else {
        const newSize = {
          width: Math.min(state.size.width, windowWidth * 0.95),
          height: Math.min(state.size.height, windowHeight * 0.95),
        };
        const newPosition = calculateNewPosition(state.position, newSize);
        setState({ position: newPosition, size: newSize });
        onPositionChange?.(newPosition);
        onSizeChange?.(newSize);
      }
    },
    { wait: 100 },
  );

  if (!visible) {
    return null;
  }

  if (isMinimized) {
    return (
      <Rnd
        size={minimizedSize}
        position={state.minimizedPosition}
        onDragStop={(e, d) => {
          const newPosition = { x: d.x, y: d.y };
          setState({ minimizedPosition: newPosition });
          onMinimizedPositionChange?.(newPosition);
        }}
        dragHandleClassName={dragHandleClassName}
        bounds="window"
        enableResizing={false}
      >
        {minimizedContent}
      </Rnd>
    );
  }

  return (
    <Rnd
      size={state.size}
      position={state.position}
      onDragStop={(e, d) => {
        const newPosition = { x: d.x, y: d.y };
        setState({ position: newPosition });
        onPositionChange?.(newPosition);
      }}
      onResize={(e, direction, ref, delta, position) => {
        const newSize = {
          width: ref.offsetWidth,
          height: ref.offsetHeight,
        };
        setState({
          size: newSize,
          position,
        });
        onSizeChange?.(newSize);
        onPositionChange?.(position);
      }}
      minWidth={minWidth}
      minHeight={minHeight}
      dragHandleClassName={dragHandleClassName}
      bounds="window"
      style={{ zIndex: 1000, ...style }}
    >
      {children}
    </Rnd>
  );
};

export default DraggableResizableWindow;
