import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Radio, Drawer, Button, Collapse, DatePicker, InputNumber, Spin, message, Input } from 'antd';

import cn from 'classnames/bind';
import moment from 'moment-timezone';
import { useRequest } from 'ahooks';
import { timeZones } from '@/utils/xtime';
import isEmpty from 'lodash/isEmpty';

import {
  srvPatientActiveDoctor,
  srvFollowUpAppointment,
  srvAppointmentCalendar,
  srvCanScheduleFollowUp,
  srvPatientStateDoctors,
  srvTherapistSession,
  srvConsultationAppointment,
  srvConsultationAppointmentPrice,
  srvPatientTransferStateDoctors,
  srvPatientTherapists, srvPaidFollowUpAppointment
} from '@/services/admin';
import styles from './index.less';
const { Panel } = Collapse;
const cx = cn.bind(styles);

const ScheduleAppointment = (props) => {
  const { visible, id, detail: patientInfo, reInitialFlag, showChargeFailModal, customerFee, adjustmentReason } = props;

  const [finishConsultation, setFinishConsultation] = useState(false);
  const [patientAssignedDoctor, setPatientAssignedDoctor] = useState({});

  // ***************** doctor selector **************/
  const [activeDoctor, setActiveDoctor] = useState({});
  const [doctors, setDoctors] = useState([]);
  const [doctorLocationId, setDoctorLocationId] = useState(null);

  // ***************** appointment type selector **************/
  const [appointmentTypes, setAppoitmentTypes] = useState([]);
  const [appointmentType, setAppoitmentType] = useState(0);
  const [meetType, setMeetType] = useState(0);

  const [timezone, setTimezone] = useState('America/Los_Angeles');
  const [charge, setCharge] = useState(2);
  const [defaultConsultationPrice, setDefaultConsultationPrice] = useState(0);
  const [customChargeAmount, setCustomChargeAmount] = useState(0);
  const [calendar, setCalendar] = useState({});

  // ***************** custom time selection **************/
  const [appointedAt, setAppointedAt] = useState(0);
  const [outOfAvailability, setOutOfAvailbility] = useState(false);
  const canScheduleFollowUpReq = useRequest(srvCanScheduleFollowUp, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setFinishConsultation(ret.data);
      }
    },
  });

  const stateDoctorsReq = useRequest(srvPatientStateDoctors, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setDoctors(ret.data);
        if (ret.data.length > 0) {
          let doctor = ret.data.find(doctor => doctor.id === patientAssignedDoctor.id);
          if (!doctor) {
            doctor = ret.data[0];
          }
          setActiveDoctor(doctor);
          if (doctor.doctor_locations && doctor.doctor_locations.length > 0) {
            setDoctorLocationId(doctor.doctor_locations[0].id)
          }
        } else {
          setActiveDoctor({});
        }
      }
    },
  });

  const stateTransferDoctorsReq = useRequest(srvPatientTransferStateDoctors, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setDoctors(ret.data);
      }
    },
  });

  const stateTherapyDoctorsReq = useRequest(srvPatientTherapists, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setDoctors(ret.data);
        if (ret.data.length > 0) {
          setActiveDoctor(ret.data[0]);
          if (ret.data[0].doctor_locations && ret.data[0].doctor_locations.length > 0) {
            setDoctorLocationId(ret.data[0].doctor_locations[0].id)
          }
        } else {
          setActiveDoctor({});
        }
      }
    },
  });

  const patientActiveDoctorReq = useRequest(srvPatientActiveDoctor, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (ret.data) {
          setPatientAssignedDoctor(ret.data);
          setActiveDoctor(ret.data);
          if (ret.data.doctor_locations && ret.data.doctor_locations.length > 0) {
            setDoctorLocationId(ret.data.doctor_locations[0].id)
          }
        } else {
          loadDoctors(); // no active doctor
          setPatientAssignedDoctor({});
          setActiveDoctor({});
        }
      }
    },
  });

  const paidFollowUpAppointmentReq = useRequest(srvPaidFollowUpAppointment, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setAppointedAt(0);
        onClose();
      }
    },
  });

  const consultationAppointmentReq = useRequest(srvConsultationAppointment, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if(ret.data.has_charge&&!ret.data.success){
          if (reInitialFlag) {
            onClose();
            showChargeFailModal();
          }else{
            message.error(ret.data.fail_reason?ret.data.fail_reason:'charge failed');
            onClose();
          }
          return;
        }
        setAppointedAt(0);
        onClose();
      }
    },
  });

  const therapySessionReq = useRequest(srvTherapistSession, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setAppointedAt(0);
        onClose();
      }
    },
  });

  const followUpAppointmentReq = useRequest(srvFollowUpAppointment, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setAppointedAt(0);
        onClose();
      }
    },
  });

  const appointmentCalendarReq = useRequest(srvAppointmentCalendar, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setCalendar(ret.data);
      }
    },
  });

  const consultationAppointmentPriceReq = useRequest(srvConsultationAppointmentPrice, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setDefaultConsultationPrice(ret.data);
      }
    },
  });

  const chargeFee = () => {
    if (appointmentType == 15 || appointmentType == 23) {
      return 79
    } else if (appointmentType == 16) {
      return 59
    } else if (appointmentType == 17 || appointmentType == 22) {
      return 59
    } else if (appointmentType == 18) {
      return 39
    } else if (appointmentType == 20 || appointmentType == 21 || appointmentType == 22) {
      return 399
    } else if (appointmentType == 1) {
      return 79
    } else {
      return defaultConsultationPrice
    }
  }

  const onClose = () => {
    setAppointedAt(0);
    props.onClose && props.onClose();
  };

  const onFinish = () => {
    if (appointmentType === 1 || appointmentType === 4 || appointmentType === 5 || appointmentType === 20 || appointmentType === 21) {
      consultationAppointmentReq.run({
        patient_id: id,
        charge: charge === 1 || charge === 3, // set charge to true for charging appointment fees or custom charge
        custom_charge_amount: reInitialFlag ? customerFee : customChargeAmount,
        appointment_type: appointmentType,
        time: appointedAt,
        timezone: timezone,
        doctor_id: activeDoctor.id,
        doctor_location_id: doctorLocationId,
        out_of_availability: outOfAvailability,
        reason: adjustmentReason,
        re_initial_flag: reInitialFlag,
        reactive_membership: reInitialFlag,
      });
    } else if (appointmentType === 15 || appointmentType == 16 || appointmentType == 17 || appointmentType == 18 || appointmentType == 23 || appointmentType == 22) {
      therapySessionReq.run({
        patient_id: id,
        charge: charge === 1 || charge === 3, // set charge to true for charging appointment fees or custom charge
        custom_charge_amount: customChargeAmount,
        appointment_type: appointmentType,
        time: appointedAt,
        timezone: timezone,
        doctor_id: activeDoctor.id,
        doctor_location_id: doctorLocationId,
        out_of_availability: outOfAvailability,
      });
    } else if (appointmentType === 3 || appointmentType === 8) {
      paidFollowUpAppointmentReq.run({
        patient_id: id,
        charge: charge === 1 || charge === 3, // set charge to true for charging appointment fees or custom charge
        custom_charge_amount: reInitialFlag ? customerFee : customChargeAmount,
        appointment_type: appointmentType,
        time: appointedAt,
        timezone: timezone,
        doctor_id: activeDoctor.id,
        doctor_location_id: doctorLocationId,
        out_of_availability: outOfAvailability,
        reason: adjustmentReason,
        re_initial_flag: reInitialFlag,
        reactive_membership: reInitialFlag,
      });
    } else {
      followUpAppointmentReq.run({
        patient_id: id,
        appointment_type: appointmentType,
        time: appointedAt,
        timezone: timezone,
        out_of_availability: outOfAvailability,
        doctor_location_id: doctorLocationId,
        doctor_id: activeDoctor.id,
      });
    }
  };

  const fetchDates = (dir) => {
    setAppointedAt(0);
    appointmentCalendarReq.run({
      doctor_id: activeDoctor.id,
      doctor_location_id: doctorLocationId,
      appointment_type: appointmentType, // follow up
      timezone: timezone,
      days: 4,
      date: dir == 'next' ? calendar.end_date : calendar.start_date,
      dir: dir,
    });
  };

  const initCalendar = (doctorID, _doctorLocationId, appType, tz) => {
    appointmentCalendarReq.run({
      doctor_id: doctorID,
      doctor_location_id: _doctorLocationId,
      appointment_type: appType,
      timezone: timezone,
      days: 4,
      date: moment().format('YYYYMMDD'),
      dir: 'next',
    });
  };

  const initAppointmentType = () => {
    let defaultAppointmentType = 2;
    // online and finishConsultation
    if (finishConsultation) {
      if (patientInfo.patient_type === 1) {
        setAppoitmentTypes([
          { id: 5, name: 'Pediatric Consultation 50 Min' },
          { id: 6, name: 'Pediatric FollowUp 25 Min' },
        ]);
        defaultAppointmentType = 6;
      } else if (patientInfo.patient_type === 3) {
        setAppoitmentTypes([
          { id: 11, name: 'Insomnia Consultation 40 Min' },
          { id: 12, name: 'Insomnia FollowUp 15 Min' },
        ]);
        defaultAppointmentType = 12;
      } else {
        if (meetType === 0) {
          setAppoitmentTypes([
            { id: 1, name: 'Re-initial Consultation(30min)' },
            { id: 20, name: 'Initial Consultation(60min)' },
            { id: 2, name: 'FollowUp' },
            { id: 3, name: 'FollowUp 25 Min' },
            { id: 13, name: 'Transfer of Care(30min)' },
            { id: 15, name: 'ADHD Therapy Session 45 Min' },
            { id: 16, name: 'ADHD Therapy Session 30 Min' },
            { id: 17, name: 'Initial ADHD Therapy Evaluation 45 Min' },
            { id: 18, name: 'Initial ADHD Therapy Evaluation 30 Min' },
            { id: 23, name: 'ADHD Coaching Session 45 Min' },
            { id: 22, name: 'Initial ADHD Coaching Evaluation 45 Min' },
          ]);
          defaultAppointmentType = 2;
        } else if (meetType === 1) {
          setAppoitmentTypes([
            { id: 4, name: 'Initial(In-person,30min)' },
            { id: 21, name: 'Initial(In-person,60min)' },
            { id: 7, name: 'FollowUp(In-person)' },
            { id: 8, name: 'FollowUp 25 Min(In-person)' },
            { id: 14, name: 'Transfer of Care(In-person,30min)' },
          ]);
          defaultAppointmentType = 7;
        }
      }
    } else {
      if (patientInfo.patient_type === 1) {
        setAppoitmentTypes([{ id: 5, name: 'Pediatric Consultation 50 Min' }]);
        defaultAppointmentType = 5;
      } else if (patientInfo.patient_type === 3) {
        setAppoitmentTypes([{ id: 11, name: 'Insomnia Consultation 40 Min' }]);
        defaultAppointmentType = 11;
      } else {
        if (meetType === 0) {
          setAppoitmentTypes([
            { id: 1, name: 'Re-initial Consultation(30min)' },
            { id: 20, name: 'Initial Consultation(60min)' },
            { id: 15, name: 'ADHD Therapy Session 45 Min' },
            { id: 16, name: 'ADHD Therapy Session 30 Min' },
            { id: 17, name: 'Initial ADHD Therapy Evaluation 45 Min' },
            { id: 18, name: 'Initial ADHD Therapy Evaluation 30 Min' },
            { id: 23, name: 'ADHD Coaching Session 45 Min' },
            { id: 22, name: 'Initial ADHD Coaching Evaluation 45 Min' },
          ]);
          defaultAppointmentType = 1;
        } else if (meetType === 1) {
          setAppoitmentTypes([{ id: 4, name: 'Initial(In-person,30min)' },{id: 21, name: 'Initial(In-person,60min)'}]);
          defaultAppointmentType = 4;
        }
      }
    }
    setAppoitmentType(defaultAppointmentType);
    return defaultAppointmentType;
  };

  const changeCustomDateTime = (date, dateString) => {
    setAppointedAt(moment.tz(dateString, timezone).unix());
    setOutOfAvailbility(true);
  };

  const onMeetTypeChange = (e) => {
    setMeetType(e.target.value);
  };

  const shouldLockDoctorSelection = () => {
    // lock doctor selection for schduling follow up with current provider
    if (finishConsultation && !isEmpty(patientAssignedDoctor) && [2, 3, 7, 8, 9].includes(appointmentType)) {
      return true;
    }
    if (!isEmpty(patientAssignedDoctor) && (appointmentType === 1 || appointmentType == 4 || appointmentType === 20 || appointmentType === 21)) {
      return true;
    }
    return false;
  };

  const renderClinicianSelection = () => {
    if (shouldLockDoctorSelection() && !reInitialFlag) {
      return (
        <span>
          {activeDoctor.name} {activeDoctor.title}
        </span>
      )
    }
    return (
      <Select
        name="doctor"
        id="doctor"
        style={{ width: '100%' }}
        value={activeDoctor.id}
        loading={stateDoctorsReq.loading || stateTherapyDoctorsReq.loading}
        onChange={(v) => {
          for (const x of doctors) {
            if (x.id == v) {
              setActiveDoctor(x);
              if (x.doctor_locations.length > 0) {
                setDoctorLocationId(x.doctor_locations[0].id)
              } else {
                setDoctorLocationId(null)
              }
            }
          }
        }}
      >
        {doctors.map((v) => (
          <Select.Option value={v.id} key={v.id}>
            {v.name}
            {patientInfo.coaching_id == v.id && ' (Current Coaching)'}
            {patientInfo.therapist_id == v.id && ' (Current Therapist)'}
          </Select.Option>
        ))}
      </Select>
    )
  }

  const loadDoctors = () => {
    if (appointmentType === 15 || appointmentType == 16 || appointmentType == 17 || appointmentType == 18) {
      stateTherapyDoctorsReq.run({ id: id, doctor_type: 3 });
    } else if (appointmentType === 22 || appointmentType == 23) {
      stateTherapyDoctorsReq.run({ id: id, doctor_type: 5 });
    } else {
      stateDoctorsReq.run({ id, re_initial_flag: reInitialFlag, appointment_type: appointmentType });
    }
  }

  useEffect(() => {
    if (reInitialFlag && meetType === 0) {
      setAppoitmentType(1);
    }
    if (reInitialFlag && meetType === 1) {
      setAppoitmentType(4);
    }
    if (activeDoctor && activeDoctor.id && (appointmentType || reInitialFlag)) {
      if (reInitialFlag) {
        initCalendar(activeDoctor.id, doctorLocationId, 1, timezone);
      } else {
        initCalendar(activeDoctor.id, doctorLocationId, appointmentType, timezone);
      }
      setCharge(2);
      setCustomChargeAmount(0)
    }
  }, [activeDoctor, appointmentType, reInitialFlag])

  useEffect(() => {
    initAppointmentType();
  }, [finishConsultation, meetType])

  useEffect(() => {
    if ([13, 14, 15, 16, 17, 18, 22, 23].includes(appointmentType)||reInitialFlag) {
      loadDoctors()
    } else {
      patientActiveDoctorReq.run({ id })
    }
  }, [appointmentType])

  useEffect(() => {
    if (patientInfo && patientInfo.id) {
      if (patientInfo.time_zone !== '') {
        setTimezone(props.detail.time_zone);
      }
      canScheduleFollowUpReq.run({ id })
    }
    consultationAppointmentPriceReq.run({ id })
  }, [patientInfo])

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      destroyOnClose={true}
      className={cx({ rescheduleDrawer: true })}
      title={
        <div className={cx({ title: true })}>
          <h3>Schedule Appointment</h3>
          {(appointedAt > 0 && !isEmpty(activeDoctor)) && (
            <Button type="primary" loading={(appointmentType === 1 || appointmentType === 4 || appointmentType === 5 || appointmentType === 3 || appointmentType === 8 || appointmentType === 20 || appointmentType === 21) ? consultationAppointmentReq.loading :
              (appointmentType === 15 || appointmentType == 16 || appointmentType == 17 || appointmentType == 18 || appointmentType == 22 || appointmentType == 23) ? therapySessionReq.loading : followUpAppointmentReq.loading} onClick={onFinish}>
              Confirm
            </Button>
          )}
        </div>
      }
      width="600"
      placement="right"
      closable={false}
    >
      <Spin spinning={patientActiveDoctorReq.loading || consultationAppointmentPriceReq.loading || stateDoctorsReq.loading
        || stateTransferDoctorsReq.loading || stateTherapyDoctorsReq.loading || canScheduleFollowUpReq.loading}>
        <div className={cx({ description: true })}>
          <Row style={{ marginBottom: '10px' }}>
            <Col span={6}>Clinician:</Col>
            <Col span={18}>
              {renderClinicianSelection()}
            </Col>
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            <Col span={6}>Meeting Type:</Col>
            <Col span={18}>
              <Radio.Group name="meetType" defaultValue={0} onChange={onMeetTypeChange}>
                <Radio value={0}>Online</Radio>
                <Radio value={1} disabled={!activeDoctor.support_in_person}>
                  In-person
                </Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            <Col span={6}>Appointment Type:</Col>
            <Col span={18}>
              {!reInitialFlag && (
                <Select
                  name="appointment_type"
                  id="appointment_type"
                  style={{ width: '100%' }}
                  value={appointmentType}
                  onChange={(v) => {
                    setAppoitmentType(v);
                  }}
                >
                  {appointmentTypes.map((v) => (
                    <Select.Option value={v.id} key={v.id}>
                      {v.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
              {reInitialFlag && (
                <Select
                  name="appointment_type"
                  id="appointment_type"
                  style={{ width: '100%' }}
                  value={1}
                  disabled={true}
                >
                  <Select.Option value={1} key={1}>
                    Re-Initial Consultation
                  </Select.Option>
                </Select>
              )}
            </Col>
          </Row>
          {((appointmentType == 1 || appointmentType == 4 || appointmentType == 3 || appointmentType == 8 || appointmentType == 15 || appointmentType == 16 || appointmentType == 17 || appointmentType == 18 || appointmentType == 20 || appointmentType ==21 || appointmentType == 22 || appointmentType == 23) && !reInitialFlag) && (
            <Row style={{ marginBottom: '10px' }}>
              <Col span={6}>Charge Fee:</Col>
              <Col span={18}>
                <Radio.Group
                  onChange={(e) => {
                    setCharge(e.target.value);
                    if (e.target.value == 2) {
                      setCustomChargeAmount(0);
                    } else if (e.target.value == 1) {
                      setCustomChargeAmount(chargeFee()*100)
                    } else if (e.target.value == 3) {
                      setCustomChargeAmount(defaultConsultationPrice*100)
                    }
                  }}
                  value={charge}
                >
                  <Radio value={1}>{chargeFee()}</Radio>
                  <Radio value={2}>0</Radio>
                  <Radio value={3}>
                    Custom
                    {charge === 3 ? (
                      <InputNumber
                        onChange={(value) => {
                          setCustomChargeAmount(Number(Number(value * 100).toFixed()));
                        }}
                        min={1}
                        max={300}
                        defaultValue={defaultConsultationPrice}
                        style={{ width: 80, marginLeft: 10 }}
                      />
                    ) : null}
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
          )}
          <Row style={{ marginBottom: '10px' }}>
            <Col span={6}>Time Zone:</Col>
            <Col span={18}>
              <Select
                name="timezone"
                id="timezone"
                style={{ width: '100%' }}
                value={timezone}
                onChange={(v) => {
                  setTimezone(v);
                }}
              >
                {timeZones.map((v, i) => {
                  return (
                    <Select.Option key={i} value={v[0]}>
                      {v[1]}
                    </Select.Option>
                  );
                })}
              </Select>
            </Col>
          </Row>

          {meetType == 1 && activeDoctor.doctor_locations &&
            <Row style={{ marginBottom: '10px' }}>
              <Col span={6}>Location:</Col>
              <Col span={18}>
                <Select
                  name="timezone"
                  id="timezone"
                  style={{ width: '100%' }}
                  value={doctorLocationId}
                  onChange={(v) => {
                    setDoctorLocationId(v)
                  }}
                >
                  {activeDoctor.doctor_locations.map((v, i) => {
                    return (
                      <Select.Option key={v.id} value={v.id}>
                        In-person - Location {(i + 1)} {v.address_line1} {v.address_line1} {v.city} {v.state} {v.zipcode}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          }
        </div>

        <Collapse defaultActiveKey={['1']} ghost={true} bordered={false} expandIconPosition="right">
          <Panel header="Date&Time" key="1">
            {calendar.start_date && (
              <div className={cx({ calendar: true })}>
                <div className={cx({ calendarOp: true })}>
                  <Button
                    disabled={!calendar.has_prev}
                    onClick={() => {
                      fetchDates('prev');
                    }}
                  >
                    Prev
                  </Button>
                  <Button
                    disabled={!calendar.has_next}
                    onClick={() => {
                      fetchDates('next');
                    }}
                  >
                    Next
                  </Button>
                </div>
                <div className={cx({ calendarDates: true })}>
                  {calendar.dates.map((v, idx) => {
                    const timesDom =
                      v.times &&
                      v.times.map((x, i) => {
                        return (
                          <div className={cx({ timeSlot: true })} key={i}>
                            <Button
                              size="small"
                              onClick={() => {
                                setAppointedAt(x);
                                setOutOfAvailbility(false);
                              }}
                              type={appointedAt > 0 && appointedAt === x ? 'danger' : 'default'}
                            >
                              {moment.unix(x).tz(timezone).format('hh:mm A')}
                            </Button>
                          </div>
                        );
                      });
                    return (
                      <div className={cx({ dateColumn: true })} key={idx}>
                        <div className={cx({ dateSlot: true })}>
                          {moment(v.date, 'YYYYMMDD').format('MMM D')}
                        </div>
                        <div className={cx({ dateSlot: true })}>
                          {moment(v.date, 'YYYYMMDD').format('ddd')}
                        </div>
                        {timesDom}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </Panel>
          <Panel header="Other time" key="2">
            <Row>
              <Col span={12}>
                <label>Date Time</label>
                <div>
                  <DatePicker
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    minuteStep={5}
                    size="default"
                    style={{ width: '80%' }}
                    onChange={changeCustomDateTime}
                  />
                </div>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Spin>
    </Drawer>
  );
};

export default ScheduleAppointment;
