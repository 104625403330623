import React, { useState } from 'react';
import {Form, Input, Button, Row, Checkbox, Col} from 'antd';

import { useMount, useUpdateEffect } from 'ahooks';
import CheckboxInput from "@/components/InitialEvaluationNote/CheckboxInput";

const AddADHDSubmenu = (props) => {
  const { name, form, draft, setDraft, initialValue, isClickedDefault } = props;
  const [clicked, setClicked] = useState(isClickedDefault);
  const [checked, setChecked] = useState(false);

  const onADHDCheck = () => {
    if (clicked === true){
      setClicked(false)
    }
    if (clicked === false){
      setClicked(true);
    }

  }
  const onCheckboxChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked === false) {
      form.validateFields([name]);
      const field = { }
      field[name] = undefined;
      form.setFieldsValue(field);
      setDraft(Object.assign({ ...draft }, field));
    }
  }

  useUpdateEffect(() => {
    if (draft && draft[name]) setClicked(true);
  }, [draft]);

  if (clicked) {
    return (<Row>
      <CheckboxInput
        name="adhd"
        label="ADHD"
        form={form}
        draft={draft}
        setDraft={setDraft}
        adhd={true}
        onClick={onADHDCheck}
      />
      <Col span={10}>
        <label>Inattentiveness</label>
        <CheckboxInput
          name="adhd_following_through_unfinished_tasks"
          label="Problems following through on directions/ unfinished tasks"
          form={form}
          draft={draft}
          setDraft={setDraft}
        />
        <CheckboxInput
          name="adhd_difficulty_with_organization"
          label="Difficulty with organization"
          form={form}
          draft={draft}
          setDraft={setDraft}
        />
        <CheckboxInput
          name="adhd_forgetful"
          label="Is often forgetful"
          form={form}
          draft={draft}
          setDraft={setDraft}
        />
        <CheckboxInput
          name="adhd_reluctance_engage_sustained_attention"
          label="Reluctance to engage in tasks required"
          form={form}
          draft={draft}
          setDraft={setDraft}
        />
        <CheckboxInput
          name="adhd_failing_attention_to_detail"
          label="Failing to give close attention to detail"
          form={form}
          draft={draft}
          setDraft={setDraft}
        />
        <CheckboxInput
          name="adhd_sustained_attention"
          label="Difficulty sustained attention"
          form={form}
          draft={draft}
          setDraft={setDraft}
        />
        <CheckboxInput
          name="adhd_problems_listening"
          label="Problems listening when spoken to directly"
          form={form}
          draft={draft}
          setDraft={setDraft}
        />
        <CheckboxInput
          name="adhd_misplaced_necessary"
          label="Misplaced things necessary for tasks"
          form={form}
          draft={draft}
          setDraft={setDraft}
        />
        <CheckboxInput
          name="adhd_distracted_external_stimuli"
          label="Easily distracted by extraneous stimuli"
          form={form}
          draft={draft}
          setDraft={setDraft}
        />
      </Col>
    <Col span={10}>
      <label>Hyperactivity / Impulsivity</label>
      <CheckboxInput
        name="adhd_fidget_squirms"
        label="Fidgets/squirms"
        form={form}
        draft={draft}
        setDraft={setDraft}
      />
      <CheckboxInput
        name="adhd_always_on_the_go"
        label="Always on the go, driven by a motor"
        form={form}
        draft={draft}
        setDraft={setDraft}
      />
      <CheckboxInput
        name="adhd_leaves_seat_when_unexpected"
        label="Often leaves seat when remaining seated is expected"
        form={form}
        draft={draft}
        setDraft={setDraft}
      />
      <CheckboxInput
        name="adhd_restlessness"
        label="Restlessness"
        form={form}
        draft={draft}
        setDraft={setDraft}
      />
      <CheckboxInput
        name="adhd_trouble_engaging_leisure_activity"
        label="Trouble engaging in leisure activities"
        form={form}
        draft={draft}
        setDraft={setDraft}
      />
      <CheckboxInput
        name="adhd_talks_excessively"
        label="Talks excessively"
        form={form}
        draft={draft}
        setDraft={setDraft}
      />
      <CheckboxInput
        name="adhd_blurts_out_answers_or_completes_others_sentences"
        label="Blurts out answers/completes other people sentences"
        form={form}
        draft={draft}
        setDraft={setDraft}
      />
      <CheckboxInput
        name="adhd_difficulty_waiting_for_turn"
        label="Difficulty waiting their turn"
        form={form}
        draft={draft}
        setDraft={setDraft}
      />
      <CheckboxInput
        name="adhd_interrupts_or_intrudes_on_others"
        label="Interrupts or intrudes on others"
        form={form}
        draft={draft}
        setDraft={setDraft}
      />
    </Col>
    </Row>)
  }

  return <Row>
    <CheckboxInput
      name="adhd"
      label="ADHD"
      form={form}
      draft={draft}
      setDraft={setDraft}
      adhd={true}
      onClick={onADHDCheck}
    />

  </Row>;
};

export default AddADHDSubmenu;
