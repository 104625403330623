import React, { useState } from 'react';
import cn from 'classnames/bind';
import {Link, useSelector} from 'umi';
import {Modal, Space, Popover, Table, Button, Menu, Dropdown, Form, Input, message} from 'antd';
import { createFromIconfontCN, EllipsisOutlined } from '@ant-design/icons';
import { AsYouType } from 'libphonenumber-js';
import { useRequest, useMount, useUpdateEffect } from 'ahooks';
import { useFlag } from '@unleash/proxy-client-react';
import { useEventEmitter } from 'ahooks';
import { history,useAccess } from 'umi';

import RemoveTreatmentRequestModal from './RemoveTreatmentRequestModal';
import AdjustTreatmentRequestDateModal from './AdjustTreatmentRequestDateModal';
import PrescriptionHistory from '@/components/PrescriptionPane/PrescriptionHistory';
import Filters from '@/components/Filters';
import AddNote from '@/components/AddNote';
import {
  srvAdminRxntActiveMedList,
  srvEncounterList,
  srvGetPaitentDischargeInfo, srvRxntActiveMedList,
  srvUserFeatures,
} from '@/services/patient';
import {
  srvTreatmentRequests,
  srvIntStatusItems,
  srvRemoveTreatmentRequest,
  srvMarkSuccessTreatmentRequest,
  srvMarkNewTreatmentRequest,
  srvMarkNoteAddedTreatmentRequest,
  srvMarkClosedTreatmentRequest,
  srvPatientDetail,
  srvAddTreatmentRequestHistory,
  srvAddAdminNoteTreatmentRequest,
  srvAdminOverridePatientRefillDate, srvPatientCurrentMembership, srvAdminOverridePatientRefillDateV2,
} from '@/services/admin';

import OverrideModal from '@/components/PrescriptionPane/OverrideRefillDateModal';
import defaultSettings from '../../../defaultSettings';
import { formatUnix } from '@/utils/xtime';
import styles from './index.less';
import {useActiveMedsStore} from "@/stores/useActiveMedsStore";
const cx = cn.bind(styles);

let IconFont = createFromIconfontCN({ scriptUrl: defaultSettings.iconfontUrl });

const filters = [
  {
    name: 'doctor_email',
    label: 'Doctor Email',
    type: 'text',
  },
  {
    name: 'doctor_phone',
    label: 'Doctor Phone',
    type: 'text',
  },
  {
    name: 'status',
    label: 'Status',
    type: 'checkbox',
    items: [],
  },
  {
    name: 'created_at',
    label: 'Date',
    type: 'date',
  },
];

const Patient = (props) => {
  const { active, id, admin } = props;
  const [query, setQuery] = useState({});
  const [page, setPage] = useState(1);
  const [encounters, setEncounters] = useState([]);
  const [discharge, setDischarge] = useState({});
  const [treatmentRequests, setTreatmentRequests] = useState({ total: 0, items: [] });
  const [treatmentRequestStatuses, setTreatmentRequestStatuses] = useState([]);
  const [overrideModalVisible, setOverrideModalVisible] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});
  const [noteVisible, setNoteVisible] = useState(false);
  const [selected, setSelected] = useState('');
  const [treatmentRequestToRemove, setTreatmentRequestToRemove] = useState();
  const [isRemoveTreatmentRequestModalVisible, setRemoveTreatmentRequestModalVisible] =
    useState(false);
  const [treatmentRequestToMarkSuccess, setTreatmentRequestToMarkSuccess] = useState();
  const [isMarkSuccessTreatmentRequestModalVisible, setMarkSuccessTreatmentRequestModalVisible] =
    useState();
  const [threeMonthRenewal, setThreeMonthRenewal] = useState(false);
  const flagThreeMonthRenewal = useFlag('three_month_renewal');
  const flagMarkRefillRequestClosed = useFlag('mark_refill_request_closed');
  const [adjustTreatmentRequestDateModalVisible, setAdjustTreatmentRequestDateModalVisible] =
    useState(false);
  const [treatmentRequestToAdjust, setTreatmentRequestToAdjust] = useState();
  const [treatmentRequestToAddAdminNote, setTreatmentRequestToAddAdminNote] = useState();
  const [addAdminNoteTreatmentRequestModalVisible, setAddAdminNoteTreatmentRequestModalVisible] = useState(false);
  const [treatmentRequestToMarkNew, setTreatmentRequestToMarkNew] = useState();
  const [markNewTreatmentRequestModalVisible, setMarkNewTreatmentRequestModalVisible] = useState(false);
  const [treatmentRequestToMarkNoteAdded, setTreatmentRequestToMarkNoteAdded] = useState();
  const [markNoteAddedTreatmentRequestModalVisible, setMarkNoteAddedTreatmentRequestModalVisible] = useState(false);
  const [treatmentRequestToMarkClosed, setTreatmentRequestToMarkClosed] = useState();
  const [markClosedTreatmentRequestModalVisible, setMarkClosedTreatmentRequestModalVisible] = useState(false);
  const [requestNoteVisible, setRequestNoteVisible] = useState(false);
  const [requestNote, setRequestNote] = useState('');
  const[requestedMedicationsVisible,setRequestedMedicationsVisible]=useState(false);
  const[requestedMedications,setRequestedMedications]=useState('');
  const [isMemberActive, setIsMemberActive] = useState(false);


  const [markNewForm] = Form.useForm();
  const [markNoteAddedForm] = Form.useForm();
  const [addAdminNoteForm] = Form.useForm();
  const [markClosedForm] = Form.useForm();
  const reloadRxnt$ = useEventEmitter();
  const access=useAccess();
  // const [activeMeds, setActiveMeds] = useState([])
  const userFeaturesReq = useRequest(srvUserFeatures, {
    manual: true,
    onSuccess: (ret) => {
      setThreeMonthRenewal(ret.data.three_month_renewal);
    },
  });

  // const historyReq = useRequest(srvAdminRxntActiveMedList, {
  //   manual: true,
  //   onSuccess: (ret) => {
  //     if (ret.success) {
  //       setActiveMeds(ret.data);
  //     }
  //   },
  // });


  const loadPatientInfoReq = useRequest(srvPatientDetail, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setPatientInfo(ret.data);
      }
    },
  });
  const formatRequestedMedications = (v) => {
    if (!v) {
      return '';
    }
    return v.refill_active_med_items.map((item) => item.rxnt_active_med_name).join(', \n');
  }
  const treatmentRequestListReq = useRequest(srvTreatmentRequests, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (patientInfo.enable_refill_individual_medications) {
          ret.data.items.forEach((item) => {
            item.requested_medication = formatRequestedMedications(item);
          });
        }
        setTreatmentRequests(ret.data);
      }
    },
  });

  const treatmentRequestStatusesReq = useRequest(srvIntStatusItems, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setTreatmentRequestStatuses(ret.data);
      }
    },
  });

  const encounterListReq = useRequest(srvEncounterList, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setEncounters(ret.data);
      }
    },
  });

  const loadDischargeInfoReq = useRequest(srvGetPaitentDischargeInfo, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (ret.data) {
          setDischarge(ret.data);
        } else {
          setDischarge({});
        }
      }
    },
  });

  const overrideNextRefillDateReq = useRequest(srvAdminOverridePatientRefillDate, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setOverrideModalVisible(false);
        loadData();
      }
    },
  });

  const overrideNextRefillDateReqV2 = useRequest(srvAdminOverridePatientRefillDateV2, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        setOverrideModalVisible(false);
        message.success('Next refill date updated');
        loadData();
      } else {
        message.error(ret.msg);
      }
    },
  });

  const deleteTreatmentRequestReq = useRequest(srvRemoveTreatmentRequest, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        loadData();
      }
    },
  });

  const markTreatmentRequestSuccess = useRequest(srvMarkSuccessTreatmentRequest, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        loadData();
      }
    },
  });

  const markTreatmentRequestNewReq = useRequest(srvMarkNewTreatmentRequest, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        loadData();
      }
    },
  });

  const markTreatmentRequestNoteAddedReq = useRequest(srvMarkNoteAddedTreatmentRequest, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        loadData();
      }
    },
  });

  const markTreatmentRequestClosedReq = useRequest(srvMarkClosedTreatmentRequest, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        loadData();
      }
    },
  });

  const addTreatmentRequestHistoryReq = useRequest(srvAddTreatmentRequestHistory, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        loadData();
      }
    },
  });

  const addAdminNoteTreatmentRequestHistoryReq = useRequest(srvAddAdminNoteTreatmentRequest, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        loadData();
      }
    },
  });

  const loadMembership = useRequest(srvPatientCurrentMembership, {
    manual: true,
    onSuccess: (ret, params) => {
      if (ret.success) {
        if (ret.data.status === 2) {
          setIsMemberActive(true);
        }
      }
    },
  })

  let overrideModalProps = {
    visible: overrideModalVisible,
    item: discharge,
    destroyOnClose: true,
    maskClosable: false,
    closable: false,
    // activeMeds: activeMeds,
    enableV2: patientInfo.enable_refill_individual_medications,
    okText: 'Confirm',
    patientId: id,
    width: 400,
    title: 'Override next prescription fill date',
    onOk: async (payload) => {
      if (patientInfo.enable_refill_individual_medications) {
        await overrideNextRefillDateReqV2.run({user_id: id, items: payload.items});
      } else {
        await overrideNextRefillDateReq.run({ user_id: id, next_refill_date: payload.next_refill_date, override_next_refill_date: payload.override_next_refill_date });
      }
      await loadDischargeInfoReq.run({id});
      // await historyReq.run({ user_id: id });
    },
    onCancel: () => {
      setOverrideModalVisible(false);
    },
  };

  let removeTreatmentRequestModelProps = {
    treatment_request: treatmentRequestToRemove,
    visible: isRemoveTreatmentRequestModalVisible,
    maskClosable: false,
    closable: false,
    okText: 'Confirm',
    width: 600,
    title: 'Confirm renewal request deletion',
    onOk: (removeReason, removeNote) => {
      deleteTreatmentRequestReq.run({
        id: treatmentRequestToRemove.id,
        remove_reason: removeReason,
        remove_note: removeNote,
      });
      setRemoveTreatmentRequestModalVisible(false);
    },
    onCancel: () => {
      setRemoveTreatmentRequestModalVisible(false);
    },
  };

  let adjustTreatmentRequestDateModalProps = {
    treatment_request: treatmentRequestToAdjust,
    visible: adjustTreatmentRequestDateModalVisible,
    maskClosable: false,
    closable: false,
    okText: 'Confirm',
    width: 600,
    title: 'Adjust Renewal Request Date',
    timeZone: patientInfo.time_zone == '' ? 'America/Los_Angeles' : patientInfo.time_zone,
    onOk: (fillDate, newDate, reason) => {
      addTreatmentRequestHistoryReq.run({
        treatment_request_id: treatmentRequestToAdjust.id,
        fill_date: fillDate,
        new_date: newDate,
        reason: reason,
      });
      setAdjustTreatmentRequestDateModalVisible(false);
    },
    onCancel: () => {
      setAdjustTreatmentRequestDateModalVisible(false);
    },
  };

  let markSuccessTreatmentRequestModalProps = {
    visible: isMarkSuccessTreatmentRequestModalVisible,
    maskClosable: false,
    closable: false,
    okText: 'Confirm',
    width: 600,
    title: 'Mark Renewal Request As Success',
    onOk: () => {
      markTreatmentRequestSuccess.run({ id: treatmentRequestToMarkSuccess.id });
      setMarkSuccessTreatmentRequestModalVisible(false);
    },
    onCancel: () => {
      setMarkSuccessTreatmentRequestModalVisible(false);
    },
  };

  let markNewTreatmentRequestModalProps = {
    visible: markNewTreatmentRequestModalVisible,
    maskClosable: false,
    closable: false,
    okText: 'Confirm',
    width: 600,
    title: 'Mark Renewal Request As New',
    onOk: () => {
      markNewForm
        .validateFields()
        .then((values) => {
          markTreatmentRequestNewReq.run({
            id: treatmentRequestToMarkNew.id,
            reason: values.reason,
          });
          setMarkNewTreatmentRequestModalVisible(false);
        })
    },
    onCancel: () => {
      setMarkNewTreatmentRequestModalVisible(false);
    },
  };

  let markClosedTreatmentRequestModalProps = {
    visible: markClosedTreatmentRequestModalVisible,
    maskClosable: false,
    closable: false,
    okText: 'Confirm',
    width: 600,
    title: 'Mark Renewal Request As Closed',
    onOk: () => {
      markClosedForm
        .validateFields()
        .then((values) => {
          markTreatmentRequestClosedReq.run({
            id: treatmentRequestToMarkClosed.id,
            reason: values.reason,
          });
          setMarkClosedTreatmentRequestModalVisible(false);
        })
    },
    onCancel: () => {
      setMarkClosedTreatmentRequestModalVisible(false);
    },
  };

  let markNoteAddedTreatmentRequestModalProps = {
    visible: markNoteAddedTreatmentRequestModalVisible,
    maskClosable: false,
    closable: false,
    okText: 'Confirm',
    width: 600,
    title: 'Mark Renewal Request As Note Added',
    onOk: () => {
      markNoteAddedForm
        .validateFields()
        .then((values) => {
          markTreatmentRequestNoteAddedReq.run({
            id: treatmentRequestToMarkNoteAdded.id,
            note: values.note,
          });
          setMarkNoteAddedTreatmentRequestModalVisible(false);
        })
    },
    onCancel: () => {
      setMarkNoteAddedTreatmentRequestModalVisible(false);
    },
  }

  let addAdminNoteTreatmentRequestModalProps = {
    visible: addAdminNoteTreatmentRequestModalVisible,
    maskClosable: false,
    closable: false,
    okText: 'Confirm',
    width: 600,
    title: 'Add Admin Notes',
    onOk: () => {
      addAdminNoteForm
        .validateFields()
        .then((values) => {
          addAdminNoteTreatmentRequestHistoryReq.run({
            id: treatmentRequestToAddAdminNote.id,
            note: values.note,
          });
          setAddAdminNoteTreatmentRequestModalVisible(false);
        })
    },
    onCancel: () => {
      setAddAdminNoteTreatmentRequestModalVisible(false);
    },
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'requested_at',
      key: 'requested_at',
      ellipsis: true,
      width: 180,
      render: (v, r) => {
        return formatUnix(r.requested_at);
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 90,
      render: (v, r) => {
        return r.status_name;
      },
    },
    {
      title: 'Clinician',
      dataIndex: 'doctor_id',
      key: 'doctor_id',
      ellipsis: true,
      width: 180,
      render: (v, r) => {
        const userName = `${r.doctor.name} ${r.doctor.title}`;
        return (
          <Popover
            content={
              <div className="popover-user">
                <div className="popover-user-item username">
                  <IconFont className="icon" type="icon-username" />
                  <Link to={`/doctor/detail/${r.doctor.id}`}>{userName}</Link>
                </div>
                <div className="popover-user-item email">
                  <IconFont className="icon" type="icon-email" />
                  <Link to={`/doctor/detail/${r.doctor.id}`}>{r.doctor.email}</Link>
                </div>
                <div className="popover-user-item phone">
                  <IconFont className="icon" type="icon-phone" />
                  {new AsYouType('US').input(r.doctor.phone)}
                </div>
              </div>
            }
            title={userName}
            trigger="hover"
          >
            <a href="#">{userName}</a>
          </Popover>
        );
      },
    },
    {
      title: 'Side Effects',
      dataIndex: 'side_effect',
      ellipsis: true,
      width: 90,
      key: 'side_effect',
    },
    {
      title: 'Patient needs medication change',
      dataIndex: 'need_change',
      ellipsis: true,
      width: 240,
      key: 'need_change',
    },
    patientInfo.enable_refill_individual_medications && {
      title: 'Requested Medication',
      dataIndex: 'requested_medication',
      ellipsis: true,
      width: 220,
      key: 'requested_medication',
      render: (v, r) => {
        return (
          <a
            onClick={() => {
              setRequestedMedications(v);
              setRequestedMedicationsVisible(true);
            }}
          >
            {v}
          </a>
        );
      },
    },
    {
      title: 'Admin Note',
      ellipsis: true,
      width: 250,
      dataIndex: 'admin_note',
      key: 'admin_note',
      render: (v, r) => {
        const notes = (
          <>
            <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: String(r.admin_note).replace(/\\n/g, '<br/>') }}></div>
            <div>{r.pdmp_notes}</div>
          </>
        )
        return (
          <a
            onClick={() => {
              setRequestNote(notes);
              setRequestNoteVisible(true);
            }}
          >
            {v}
          </a>
        );
      },
    },
    {
      title: 'Note Added',
      ellipsis: true,
      width: 140,
      dataIndex: 'note_added',
      key: 'note_added',
      render: (v, r) => {
        let text = '';
        if (r.is_closed) {
          text = 'Closed';
        } else {
          if (r.note_added) {
            text = 'Yes';
            if (r.note_type == '2') {
              text += '(Follow-up)';
            }
          } else {
            text = 'No';
          }
        }
        return text;
      },
    },
    {
      title: 'Admin Action',
      dataIndex: 'remove-action',
      key: 'remove-action',
      ellipsis: true,
      fixed: 'right',
      render: (v, r) => {
        if(history.location.query.admin && access.adminAccesser){
          return
        }
        const is_open = r.status == 1 || r.status == 5 || r.status == 7 || r.status == 8;
        return (
          <Space>
            {is_open && (
              <>
                {(threeMonthRenewal || flagThreeMonthRenewal) &&
                  discharge.id &&
                  discharge.three_month_renewal && (
                    <Button
                      type="link"
                      size="small"
                      onClick={() => {
                        setSelected(r);
                        setNoteVisible(true);
                      }}
                    >
                      Approve and Add Notes
                    </Button>
                  )}
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    setTreatmentRequestToMarkSuccess(r);
                    setMarkSuccessTreatmentRequestModalVisible(true);
                  }}
                >
                  Mark Success
                </Button>
              </>
            )}
            <Dropdown
              overlay={
                <Menu onClick={(e) => {
                  if (e.key == 'remove') {
                    setTreatmentRequestToRemove(r);
                    setRemoveTreatmentRequestModalVisible(true);
                  } else if (e.key == 'edit') {
                    setTreatmentRequestToAdjust(r);
                    setAdjustTreatmentRequestDateModalVisible(true);
                  } else if (e.key == 'add_admin_notes') {
                    setTreatmentRequestToAddAdminNote(r);
                    setAddAdminNoteTreatmentRequestModalVisible(true);
                  } else if (e.key == 'new') {
                    setTreatmentRequestToMarkNew(r);
                    setMarkNewTreatmentRequestModalVisible(true);
                  } else if (e.key == 'mark_note_added') {
                    setTreatmentRequestToMarkNoteAdded(r);
                    setMarkNoteAddedTreatmentRequestModalVisible(true);
                  } else if (e.key == 'mark_closed') {
                    setTreatmentRequestToMarkClosed(r);
                    setMarkClosedTreatmentRequestModalVisible(true);
                  }
                }}>
                  <Menu.Item key="edit">Edit</Menu.Item>
                  <Menu.Item key="remove" danger>Remove</Menu.Item>
                  <Menu.Item key="add_admin_notes">Add Admin Notes</Menu.Item>
                  <Menu.Item key="new">Mark New</Menu.Item>
                  {!r.note_added && (<Menu.Item key="mark_note_added">Mark Note Added</Menu.Item>)}
                  {flagMarkRefillRequestClosed && !r.is_closed && (<Menu.Item key="mark_closed">Mark Closed</Menu.Item>)}
                </Menu>
              }
            >
              <Button size="small">
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          </Space>
        );
      },
    },
  ].filter(Boolean);

  useMount(() => {
    setPage(1);
    userFeaturesReq.run({ id });
    loadData();
    loadMembership.run({id : id})
    // historyReq.run({ user_id: id });
  });

  useUpdateEffect(() => {
    loadData();
  }, [active, id]);

  const loadData = () => {
    if (active) {
      loadTreatmentRequests(null, { page: 1, page_size: 20 });
      treatmentRequestStatusesReq.run({ name: 'treatment_request_status' });
      loadDischargeInfoReq.run({ id });
      encounterListReq.run({ id });
      loadPatientInfoReq.run({ id });
    }
  };

  const loadTreatmentRequests = (filters, params) => {
    let filtersData = filters ? filters : {};
    let filtersObj = { ...filtersData, user_id: id };
    treatmentRequestListReq.run({ filters: JSON.stringify(filtersObj), ...params });
  };

  const onFilter = (q) => {
    setQuery(q);
    setPage(1);
    loadTreatmentRequests(q, { page: 1, page_size: 20 });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    loadTreatmentRequests(query, { page: pagination.current, page_size: pagination.pageSize });
  };

  const f = () => {
    for (const v of filters) {
      if (v.name === 'status' && v.items.length === 0) {
        v.items = treatmentRequestStatuses;
        continue;
      }
    }
    return filters;
  };

  return (
    <div className={cx({ subcontainer: true, prescription: true })}>
      {overrideModalVisible && <OverrideModal {...overrideModalProps} />}
      <div className={cx({ next_follow_up_date: true })}>
        {patientInfo.next_follow_up_date > 0 && (
          <p>
            Next follow-up date{' '}
            <b>{formatUnix(patientInfo.next_follow_up_date, 'MMM D, YYYY')}</b>
          </p>
        )}
      </div>
      <h3 className={cx({ renewal_header: true })}>
        <div className={cx({ renewal_header_title: true })}>Prescription Renewal Requests</div>
        {(discharge.id || (patientInfo.country === 'ca' && isMemberActive)) && (
          <>
            {flagThreeMonthRenewal && discharge.three_month_renewal && (
              <div className={cx({ three_month_renewal_tag: true })}>
                <span>3-month prescribed</span>
              </div>
            )}
            {patientInfo.enable_refill_individual_medications && (
              <div className={cx({ renewal_header_override: true })}>
                <Space>
                  <span className={cx({ renewal_header_override_title: true })}>
                    Earliest medication refill date{' '}
                    <b>{formatUnix(discharge.override_next_refill_date !== 0 ? discharge.override_next_refill_date : discharge.next_refill_date, 'MMM D')}</b>
                  </span>
                  {!(history.location.query.admin && access.adminAccesser) && (
                      <Button
                        onClick={() => {
                          setOverrideModalVisible(true);
                        }}
                        loading={overrideNextRefillDateReqV2.loading}
                        type="primary"
                      >
                        Override
                      </Button>
                    )}
                  <Filters filters={f()} onFilter={onFilter} />
                </Space>
              </div>
            )}
            {!patientInfo.enable_refill_individual_medications && (
              <div className={cx({renewal_header_override: true})}>
                <Space>
                <span className={cx({renewal_header_override_title: true})}>
                  Next prescription fill date{' '}
                  <b>{formatUnix(discharge.next_refill_date, 'MMM D')}</b>
                </span>
                  {!(history.location.query.admin && access.adminAccesser) && (
                    <Button
                      onClick={() => setOverrideModalVisible(true)}
                      loading={overrideNextRefillDateReq.loading}
                      type="primary"
                    >
                      Override
                    </Button>
                  )}
                  <Filters filters={f()} onFilter={onFilter}/>
                </Space>
              </div>
            )}
          </>
        )}
      </h3>
      <Table
        columns={columns}
        dataSource={treatmentRequests.items}
        rowKey="id"
        scroll={{
          x: 'max-content',
          y: 55 * 5,
        }}
        loading={treatmentRequestListReq.loading}
        onChange={handleTableChange}
        pagination={{pageSize: 20, current: page, simple: true, total: treatmentRequests.total}}
      />

      <PrescriptionHistory
        data={encounters.items}
        loading={encounterListReq.loading}
        uid={id}
        admin={admin}
        reloadRxnt$={reloadRxnt$}
        reload={() => {
          encounterListReq.run({id});
        }}
      />
      <RemoveTreatmentRequestModal {...removeTreatmentRequestModelProps} />
      <AdjustTreatmentRequestDateModal {...adjustTreatmentRequestDateModalProps} />
      <Modal {...markNewTreatmentRequestModalProps}>
        <Form form={markNewForm} layout={'vertical'}>
          <Form.Item
            label={'reason'}
            name={'reason'}
          >
            <Input.TextArea rows={3} placeholder="" maxLength={1000} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal {...markNoteAddedTreatmentRequestModalProps}>
        <Form form={markNoteAddedForm} layout={'vertical'}>
          <Form.Item
            label={'Reason'}
            name={'reason'}
          >
            <Input.TextArea rows={3} placeholder="" maxLength={1000} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal {...markClosedTreatmentRequestModalProps}>
        <Form form={markClosedForm} layout={'vertical'}>
          <Form.Item
            label={'reason'}
            name={'reason'}
          >
            <Input.TextArea rows={3} placeholder="" maxLength={1000} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal {...markSuccessTreatmentRequestModalProps}>
        {' '}
        <p>
          Please only mark a renewal request as successful when you have verified that a
          prescription was sent
        </p>{' '}
      </Modal>
      <Modal {...addAdminNoteTreatmentRequestModalProps}>
        <Form form={addAdminNoteForm}>
          <Form.Item
            name={'note'}
            rules={[{
              required: true,
              message: 'note is required.'
            }]}>
            <Input.TextArea rows={6} placeholder="" maxLength={2000} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Note"
        visible={requestNoteVisible}
        width={800}
        destroyOnClose={true}
        onCancel={() => {
          setRequestNoteVisible(false);
        }}
        onOk={(v) => {
          setRequestNoteVisible(false);
        }}
        okText="Ok"
      >
        <div>{requestNote}</div>
      </Modal>
      <Modal
        title="Requested Medication"
        visible={requestedMedicationsVisible}
        width={800}
        destroyOnClose={true}
        onCancel={() => {
          setRequestedMedicationsVisible(false);
        }}
        onOk={(v) => {
          setRequestedMedicationsVisible(false);
        }}
        okText="Ok"
      >
        <div>{requestedMedications}</div>
      </Modal>
      {noteVisible && patientInfo && patientInfo.id && (
        <AddNote
          addNoteType={'approvePrescriptionRequest'}
          admin={true}
          onRefresh={() => {
            loadData();
          }}
          noteVisible={noteVisible}
          setNoteVisible={(v) => {
            setNoteVisible(v);
          }}
          prescriptionInfo={selected}
          patientInfo={patientInfo}
        />
      )}
    </div>
  );
};

export default Patient;
